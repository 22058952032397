import { Box, Button, LoadingOverlay, Modal, NativeSelect, TextInput } from "@mantine/core";
import { FileWithPath, MIME_TYPES } from "@mantine/dropzone";
import { Editor as TinyMCEEditor } from '@tinymce/tinymce-react';
import { FC, FormEvent, Ref, useCallback } from "react";
import { FileRejection } from "react-dropzone";
import { FieldValues, useForm } from "react-hook-form";

import Dropzone from '@/common/components/Dropzone';
import DropzoneFiles from "@/common/components/DropzoneFiles";
import Spinner from "@/common/components/Spinner";
import { logger } from "@/common/util/ConsoleLogger";
import { useCreateCommsPageStyles } from "@/tenant-context/control-mass-comms/components/CreateCommsPage/CreateCommsPage.styles";
import { ReactComponent as SettingsIcon } from "@/tenant-context/control-mass-comms/icons/settings-icon.svg";
import { Base64EmailAttachment, EmailContent, EmailSettingsConfig } from "@/tenant-context/control-mass-comms/types/MassComms.types";
import { ProfileDocumentFile } from "@/tenant-context/control-profile/types/profile";


type Props = {
  editorRef: Ref<TinyMCEEditor>,
  emailContent: EmailContent,
  onEmailSubjectChanged: (subject: FormEvent<HTMLInputElement>) => void,
  onEmailContentChanged: (body: string) => void,
  isSettingsModalOpen: boolean,
  onSettingsModalClose: () => void,
  onSettingsModalOpen: () => void,
  onNewAttachmentSelected: (files: FileWithPath[]) => void,
  uploadRef: React.RefObject<() => void>,
  onRemoveAttachment: (file: FileWithPath | ProfileDocumentFile) => void,
  onRemoveBase64Attachment: (file: Base64EmailAttachment) => void,
  onDownloadAttachment: (file: ProfileDocumentFile) => void,
  onRejectedFiles: (files: FileRejection[]) => void,
  onSettingsSave: (data: FieldValues) => void,
  emailSettings: EmailSettingsConfig,
  isInTemplateUsageFlow: boolean,
  onEditorInit: () => void,
  isContentLoading: boolean
}

const EmailConfigurationComponent: FC<Props> = ({
  editorRef,
  emailContent,
  onEmailSubjectChanged,
  onEmailContentChanged,
  isSettingsModalOpen,
  onSettingsModalClose,
  onSettingsModalOpen,
  uploadRef,
  onNewAttachmentSelected,
  onRemoveAttachment,
  onRemoveBase64Attachment,
  onDownloadAttachment,
  onRejectedFiles,
  onSettingsSave,
  emailSettings,
  isInTemplateUsageFlow,
  onEditorInit,
  isContentLoading
}) => {

  const {
    REACT_APP_TINYMCE_EDITOR_KEY
  } = process.env as {
    REACT_APP_TINYMCE_EDITOR_KEY?: string
  };

  if (!REACT_APP_TINYMCE_EDITOR_KEY) {
    logger.error("TinyMCE API Key is missing.");
  }

  const { classes, cx } = useCreateCommsPageStyles({ isCommsSequenceNoAvailable: true });
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      originatorName: emailSettings.originatorName,
      originatorEmail: emailSettings.originatorEmail
    }
  });

  const onSubmit = useCallback(
    (data: FieldValues) => {
      onSettingsSave(data);
    },
    [onSettingsSave]
  );

  const handleCancelModal = useCallback(() => {
    onSettingsModalClose();
    setValue("originatorName", emailSettings.originatorName);
    setValue("originatorEmail", emailSettings.originatorEmail);
  }, [emailSettings.originatorEmail, emailSettings.originatorName, onSettingsModalClose, setValue]);

  return (
    <div className={ classes.tabContent }>
      <LoadingOverlay visible={ isContentLoading } overlayBlur={ 2 } loader={  <Spinner/> }/>
      <Button size="md" variant="outline" className={ classes.settingsButton } leftIcon={ <SettingsIcon /> }
        onClick={ onSettingsModalOpen }>Settings</Button>
      <div className={ classes.section }>
        <Box mt={ 'xl' }>
          <TextInput
            onChange={ onEmailSubjectChanged }
            value={ emailContent.subject }
            label={ 'Email Subject' }
          />
        </Box>
      </div>

      <div className={ classes.section }>
        <div className={ classes.contentTitle }>Content</div>
        <div className={ classes.editorWrap }>
          <TinyMCEEditor
            ref={ editorRef }
            apiKey={ REACT_APP_TINYMCE_EDITOR_KEY }
            init={ {
              skin: "oxide-dark",
              content_css: "/css/rte.css",
              plugins: 'codesample image lists media searchreplace table code',
              toolbar: 'undo redo | blocks | bold italic underline | align',
              menubar: false,
              statusbar: false
            } }
            onEditorChange={ onEmailContentChanged }
            onInit={ onEditorInit }
          />
        </div>
      

        <div className={ cx(classes.contentTitle, classes.standardMargin) }>Attachments</div>

        <Dropzone
          accept={ [MIME_TYPES.jpeg, MIME_TYPES.pdf, MIME_TYPES.doc, MIME_TYPES.docx, MIME_TYPES.png, MIME_TYPES.csv] }
          openRef={ uploadRef }
          onAcceptedFiles={ onNewAttachmentSelected }
          maxSize={ 1050000 }
          allFilesMaxSize={ 1050000 * 2 }
          maxFiles={ 10 }
          onRejectedFiles={ onRejectedFiles }
        />

        <DropzoneFiles
          removeFile={ onRemoveAttachment }
          removeBase64File={ onRemoveBase64Attachment }
          downloadFile={ onDownloadAttachment }
          files={ !isInTemplateUsageFlow ? emailContent.attachments || [] : [] }
          base64Files={ isInTemplateUsageFlow ? (emailContent.encodedAttachments || []) : [] }
        />
      </div>
      <Modal
        size={ 'xl' }
        opened={ isSettingsModalOpen }
        withCloseButton={ false }
        onClose={ onSettingsModalClose }
        centered>
        <h6>Email Settings</h6>

        <div className={ classes.standardMargin }>
          Choose originator email display name and address.
        </div>
        <form onSubmit={ handleSubmit(onSubmit) }>

          <div className={ classes.configItemsContainer }>
            <div className={ classes.configItem }>
              <div>Originator Email Display Name</div>
              { /* eslint-disable-next-line react/jsx-props-no-spreading */ }
              <div><TextInput size="lg" defaultValue={ 'Restrata Alert' } { ...register('originatorName') } /></div>
            </div>
            <div className={ cx(classes.configItem, classes.standardMarginLeft) }>
              <div>Originator Email</div>
              <NativeSelect
                // eslint-disable-next-line react/jsx-props-no-spreading
                { ...register('originatorEmail') }
                size="lg"
                defaultValue={ 'alerts@restrata.net' }
                // ToDo - real value integration
                data={ ['alerts@restrata.net', 'noreply@restrata.net'] }
                className={ classes.orignEmailSelect }
              />
            </div>
          </div>

          <div className={ cx(classes.extendedMargin, classes.modalButtons) }>
            <Button
              size='md'
              variant='outline'
              onClick={ handleCancelModal }
            >
              Cancel
            </Button>
            <Button
              size='md'
              onClick={ onSettingsModalClose }
              type={ 'submit' }
            >
              Save Settings
            </Button>
          </div>
        </form>

      </Modal>
    </div>
  );
};

export default EmailConfigurationComponent;
