import { FC } from "react";

import TagChip from '@/common/components/TagsContainer/ui/TagChip/TagChip.container';
import useUnauthorizedModal from "@/common/hooks/useUnauthorizedModal";
import { useProfileTagsSectionStyles } from '@/tenant-context/control-profile/components/ui/ProfileTagsSection/ProfileTagsSection.style';
import { ProfileTag } from '@/tenant-context/control-profile/types/profile';

type Props = {
  profileTagsListForProfile: ProfileTag[]
  handleSelect: (index: number) => void
  onRemove: (index: number) => void
  toggleShowMore: () => void
  isShowMore: boolean
  isShowMoreDisabled: boolean
    isCanEditProfileTags: boolean
}

const ProfileTagsSectionComponent: FC<Props> = ({
  profileTagsListForProfile,
  handleSelect,
  onRemove,
  toggleShowMore,
  isShowMore,
  isShowMoreDisabled,
  isCanEditProfileTags
}) => {
  const { classes } = useProfileTagsSectionStyles();

  const handleUnauthorizedAction = useUnauthorizedModal();

  if (profileTagsListForProfile.length === 0) {
    return null;
  }

  return (
    <div className={ classes.rootContainer }>
      <div className={ classes.heading }>Existing Tags</div>
      <div className={ classes.container }>
        { (profileTagsListForProfile).map((chip, index) => (
          <TagChip
            color={ chip.color }
            index={ index }
            onClick={ isCanEditProfileTags ? handleSelect: handleUnauthorizedAction }
            onRemove={ isCanEditProfileTags ? onRemove : handleUnauthorizedAction }
            label={ chip.name }
            key={ index }
          />
        )) }
      </div>
      <button
        className={ classes.seeMore }
        onClick={ toggleShowMore }
        disabled={ isShowMoreDisabled }
      >
        See { isShowMore ? 'Less' : 'More' }
      </button>
    </div>
  );
};

export default ProfileTagsSectionComponent;
