import { FC } from "react";

import { DistinguishingFeaturesModalContextProvider } from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/DistinguishingFeaturesModal/context/DistinguishingFeaturesModal.context";
import DistinguishingFeaturesModal from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/DistinguishingFeaturesModal/DistinguishingFeaturesModal.component";

const DistinguishingFeaturesModalContainer: FC = () => {
  return (
    <DistinguishingFeaturesModalContextProvider>
      <DistinguishingFeaturesModal />
    </DistinguishingFeaturesModalContextProvider>
  );
};

export default DistinguishingFeaturesModalContainer;