import { FC } from "react";

import { useRiskLabelStyles } from "@/common/components/RiskLabel/RiskLabel.styles";

type Props = {
  riskLevel: string,
  riskLevelLabel: string
}

export const RiskLabel: FC<Props> = ({
  riskLevel,
  riskLevelLabel
}) => {
  const { classes } = useRiskLabelStyles({ level: riskLevel });

  return (
    <div className={ classes.wrapper }>
      { riskLevelLabel }
    </div>
  );
};
