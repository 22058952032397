import { createStyles,MantineTheme } from "@mantine/core";

export const useIconSearchStyles= createStyles((theme: MantineTheme) => ({
  searchBoxWrapperExpanded: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 'auto',
    border: `1px solid  ${theme.colors.neutral[5]}`,
    padding: '0 16px',
    input: {
      backgroundColor: 'transparent !important',
      padding: 0,
      border: 'none !important',
      width: 340,
      height: 48,
      transition: '0.3s ease-out'
    }
  },
  searchBoxWrapperShrink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 'auto',
    border: '1px solid transparent',
    padding: '0',
    input: {
      backgroundColor: 'transparent !important',
      padding: 0,
      border: 'none !important',
      paddingRight: 1,
      width: 0,
      height: 48,
      transition: '0.3s ease-out'
    }
  }
}));
