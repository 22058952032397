export const CRITICAL_ALERT_INSTRUCTIONS = [
  {
    heading: 'What is iOS Critical Alert?',
    text: 'iOS Critical Alert is a powerful feature designed to grab your immediate attention, even when your device is in silent or Do Not Disturb mode. It allows certain applications to send critical messages that override standard notification settings.'
  },
  {
    heading: 'Proper Usage is Crucial:',
    text: 'While this feature is a valuable tool for urgent communications, it is important to use it judiciously. Overusing or misusing Critical Alerts may lead to unintended consequences, including a denial of access to this feature.'
  },
  {
    heading: 'When to Use iOS Critical Alert: ',
    text: 'Please reserve the use of iOS Critical Alert for truly critical and time-sensitive communications. This may include emergency situations, important system updates, or other essential information that requires immediate attention.'
  },
  {
    heading: 'Avoidance of Feature Denial: ',
    text: 'Apple has implemented measures to prevent abuse of the Critical Alert feature. If the system detects inappropriate or excessive use, it may restrict access to this functionality. To ensure continued access for important communications, it is imperative that Critical Alerts are used sparingly and only for genuinely critical matters.'
  },
  {
    heading: 'Your Cooperation Matters:',
    text: 'As valued users of our mass communication system, your cooperation in adhering to these guidelines is crucial for maintaining the integrity and effectiveness of our communication channels.'
  },
  {
    heading: 'Need more information?',
    text: 'If you have any questions regarding the proper usage of iOS Critical Alert within our system, please do not hesitate to reach out to our support team at support@restrata.com'
  }
];
