import { Tabs } from "@mantine/core";
import { FC, useCallback } from "react";

import GeofenceListItem
  from "@/tenant-context/control-draw/components/GeofenceList/components/GeofenceListItem";
import { useGeofenceListStyles } from "@/tenant-context/control-draw/components/GeofenceList/GeofenceList.style";
import { GeofenceListTabsTypes, ShortGeofence } from "@/tenant-context/control-draw/types/draw";

type Props = {
  allGeofenceList: ShortGeofence[]
  userGeofenceList: ShortGeofence[]
  handleOnTabChange: (value: GeofenceListTabsTypes) => void
}

const GeofenceList: FC<Props> = ({ allGeofenceList, userGeofenceList, handleOnTabChange }) => {

  const renderGeofence = useCallback((list: ShortGeofence[]) => {
    return list.map(({ tid, name, enablePrivate, tenantId }) => <GeofenceListItem
      key={ tid }
      tid={ tid }
      name={ name }
      enablePrivate={ enablePrivate }
      tenantId={ tenantId }
    />);
  }, []);

  const { classes } = useGeofenceListStyles();


  return (
    <div className={ classes.container }>
      <Tabs onTabChange={ handleOnTabChange }
        defaultValue={ GeofenceListTabsTypes.ALL_GEOFENCE }>
        <Tabs.List>
          <Tabs.Tab value={ GeofenceListTabsTypes.ALL_GEOFENCE }>List all Geofences</Tabs.Tab>
          <Tabs.Tab value={ GeofenceListTabsTypes.MY_GEOFENCE }>List my Geofences</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value={ GeofenceListTabsTypes.ALL_GEOFENCE }>
          <div className={ classes.listContainer }>
            { renderGeofence(allGeofenceList) }
          </div>
        </Tabs.Panel>
        <Tabs.Panel value={ GeofenceListTabsTypes.MY_GEOFENCE }>
          <div className={ classes.listContainer }>
            { renderGeofence(userGeofenceList) }
          </div>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default GeofenceList;
