import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const usePageStyles = createStyles((theme: MantineTheme) => ({
  pageRoot: {
    backgroundColor: theme.colors.primary[5],
    height: '100vh'
  },
  headerRoot: {
    padding: theme.spacing.xl,
    borderBottom: `1px solid ${theme.colors.neutral[7]}`,
    height: 112,
    minHeight: 112
  },
  bodyRoot: {
    padding: 32,
    flexGrow: 1,
    overflowY: 'auto'
  }
}));
