import { Box, Flex, Progress, Rating, Stack, Text } from '@mantine/core';
import { FC, useCallback, useMemo } from 'react';

import { ReactComponent as StarEmpty } from '@/common/icons/Components/rating-star-empty.svg';
import { ReactComponent as StarFilled } from '@/common/icons/Components/rating-star-filled.svg';
import CountryFlag from '@/tenant-context/common/components/CountryFlag';
import {
  useCityRiskStatusCardStyles
} from '@/tenant-context/control-reports/components/CityRiskStatusCard/CityRiskStatusCard.styles';
import { CityRiskData } from '@/tenant-context/control-reports/types/city-risk-reports';
import {
  camelCaseToTitleCase,
  getRatingValueByRatingLevel,
  getRiskColorByRiskLevel,
  getRiskLevelValueByRiskLevel
} from '@/tenant-context/control-reports/util/country-risk-reports';

type Props = {
  cityRiskData: CityRiskData;
  onClick?: (cityRiskData: CityRiskData) => void;
  currentTimestamp?: number;
}

const {
  REACT_APP_MAPBOX_ACCESS_TOKEN
} = process.env as {
  REACT_APP_MAPBOX_ACCESS_TOKEN?: string
};
const CityRiskStatusCardComponent: FC<Props> = ({
  cityRiskData,
  onClick,
  currentTimestamp
}) => {
  const getProgressColor = useMemo(() => {
    return getRiskColorByRiskLevel(cityRiskData.riskLevel);
  }, [cityRiskData.riskLevel]);

  const timeZone = useMemo(() => {
    if (!cityRiskData.localTime || (cityRiskData.localTime === "Not Available")) {
      return undefined;
    }
    const timeZoneRegex = /\b[A-Z]{3,4}\b$/;
    const match = cityRiskData.localTime.match(timeZoneRegex);

    return match ? match[0] : undefined;
  }, [cityRiskData.localTime]);

  const time = useMemo(() => {
    if (!currentTimestamp || !timeZone) {
      return undefined;
    }

    const date = new Date(currentTimestamp);
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      hour: '2-digit',
      minute: '2-digit',
      hour12: false  // 24-hour format
    };

    try {
      return new Intl.DateTimeFormat('en-US', options).format(date);
    } catch (error) {
      return undefined;
    }
  }, [currentTimestamp, timeZone]);

  const { classes } = useCityRiskStatusCardStyles({ progressColor: getProgressColor });

  const handleCardClick = useCallback(() => onClick && onClick(cityRiskData), [ onClick, cityRiskData ]);

  const mapImageLink = useMemo(() => {
    const bbox = (cityRiskData.coordinates && cityRiskData.coordinates.length === 4) ?
      `[${ cityRiskData.coordinates.join(',') }]`: '[-180,-85,180,85]';

    const style = 'smehmood/cl4feh10w000114pyksvp6mu9';
    return `https://api.mapbox.com/styles/v1/${ style }/static/${ bbox }/800x300?` +
      `padding=50,10,20&access_token=${ REACT_APP_MAPBOX_ACCESS_TOKEN }`;
  }, [cityRiskData.coordinates]);

  const riskLevel = useMemo(() => {
    return getRiskLevelValueByRiskLevel(cityRiskData.riskLevel);
  }, [cityRiskData.riskLevel]);

  return (
    <Flex gap={ 12 } className={ classes.cardRoot } direction="column" onClick={ handleCardClick }>
      <Flex justify="space-between" align="center">
        <Flex gap={ 12 } align="center">
          <Box className={ classes.flag }>
            <CountryFlag
              countryCode={ cityRiskData.countryISOCode || '' }
            />
          </Box>
          <Stack spacing={ 0 }>
            <Text size={ 14 } weight={ 700 }>{ cityRiskData.cityName }</Text>
            <Text size={ 8 }>{ cityRiskData.countryName }</Text>
          </Stack>
        </Flex>
        { time && <Text size={ 12 } weight={ 400 }>Local time: { time }</Text> }
      </Flex>
      <img src={ mapImageLink } alt="City flag" className={ classes.mapImage }/>
      <Flex className={ classes.progressBarContainer } direction="column">
        <Text size={ 12 } transform="capitalize">{ cityRiskData.riskLevel }</Text>
        <Progress value={ riskLevel } className={ classes.progressBar }/>
      </Flex>
      <Stack spacing={ 4 }>
        { Object.keys(cityRiskData?.attributes?.ratings).map((key, index) => (
          <Flex className={ classes.statusBadge } gap="xs" justify="space-between" align="center" key={ index }>
            <Text className={ classes.dimText }>{ camelCaseToTitleCase(key) }</Text>
            <Rating
              value={ getRatingValueByRatingLevel(cityRiskData?.attributes?.ratings[key]) }
              size="xs"
              className={ classes.rating }
              emptySymbol={ <StarEmpty /> }
              fullSymbol={ <StarFilled /> }
              readOnly
            />
          </Flex>
        )) }
      </Stack>
    </Flex>
  );
};

export default CityRiskStatusCardComponent;
