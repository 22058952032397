import { ColDef, ICellRendererParams } from "ag-grid-community";
import { ReactNode } from "react";

import TruncatedTextWithTooltip from "@/common/components/TruncatedTextWithTooltip";


export const columnDefs = (
  actionCellRenderer: (params: ICellRendererParams) => ReactNode
): ColDef[] => {

  return [
    {
      headerName: 'DOCUMENT',
      field: 'name',
      sortable: true,
      comparator: () => 0
    },
    {
      headerName: 'TYPE',
      field: 'documentType',
      sortable: true,
      comparator: () => 0
    },
    {
      headerName: 'NUMBER',
      field: 'number',
      sortable: true,
      comparator: () => 0
    },
    {
      headerName: 'EXPIRY DATE',
      field: 'expiryDate',
      sortable: true,
      cellRenderer: ((params: ICellRendererParams) => params.data.expiryDate? new Date(params.data.expiryDate).toLocaleDateString() : '')
    },
    {
      headerName: 'DOCUMENTS',
      field: 'documents',
      sortable: true,
      comparator: () => 0
    },
    {
      headerName: 'COMMENT',
      field: 'comments',
      sortable: true,
      resizable: false,
      cellRenderer: (
        (params: ICellRendererParams) =>
          (<TruncatedTextWithTooltip isMultiline fullText={ params.data.comments } maxLength={ 15 }/>))
    },
    {
      headerName: '',
      colId: 'action',
      resizable: false,
      type: 'centerAligned',
      sortable: false,
      cellRenderer: (params: ICellRendererParams) => actionCellRenderer(params.data._id)
    }
  ];
};