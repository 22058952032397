import { FC } from "react";

import AddLocationsForm from "../LocationDetailsForm/LocationDetailsForm.container";

const LocationDetailsTabComponent: FC = () => {

  return (
    <AddLocationsForm />
  );
};

export default LocationDetailsTabComponent;
