import { createStyles } from "@mantine/core";

export const useEditButtonStyles = createStyles(() => ({
  button: {
    border: 'none',
    cursor: 'pointer',
    background: 'transparent'
  },
  unauthorizedButton: {
    border: 'none',
    background: 'transparent',
    opacity: 0.5,
    cursor: 'not-allowed'
  }
}));