import { FileWithPath } from '@mantine/dropzone';
import { openModal } from '@mantine/modals';
import { ModalSettings } from '@mantine/modals/lib/context';
import React, { FC, useCallback } from "react";
import { FileRejection } from 'react-dropzone';
import { useSelector } from 'react-redux';

import Dropzone from '@/common/components/Dropzone';
import ProtectedFragment from '@/common/components/ProtectedFragment';
import { RequiredPolicies } from '@/common/types/permission-control';
import { RootStateWithLoading } from '@/core/store';
import AttributesModal from '@/tenant-context/control-profile/components/modals/ProfileProfileModals/AttributesModal';
import CompanyDetailsModal from '@/tenant-context/control-profile/components/modals/ProfileProfileModals/CompanyDetailsModal';
import DistinguishingFeaturesModal
  from '@/tenant-context/control-profile/components/modals/ProfileProfileModals/DistinguishingFeaturesModal';
import MedicalDetailsModal from '@/tenant-context/control-profile/components/modals/ProfileProfileModals/MedicalDetailsModal';
import PersonalDetailsModal from '@/tenant-context/control-profile/components/modals/ProfileProfileModals/PersonalDetailsModal';
import { useProfileProfileTabStyles } from '@/tenant-context/control-profile/components/tabs/ProfileProfileTab/ProfileProfileTab.style';
import ProfileAttributes from '@/tenant-context/control-profile/components/tabs/ProfileProfileTab/sections/ProfileAttributes.component';
import ProfileCompanyDetails from '@/tenant-context/control-profile/components/tabs/ProfileProfileTab/sections/ProfileCompanyDetails.component';
import ProfileDistinguishingFeatures from '@/tenant-context/control-profile/components/tabs/ProfileProfileTab/sections/ProfileDistinguishingFeatures.component';
import ProfileMedicalDetails from '@/tenant-context/control-profile/components/tabs/ProfileProfileTab/sections/ProfileMedicalDetails.component';
import ProfilePersonalDetails from '@/tenant-context/control-profile/components/tabs/ProfileProfileTab/sections/ProfilePersonalDetails.component';
import AddTagsPopover from '@/tenant-context/control-profile/components/ui/AddTagsPopover';
import EditButton from "@/tenant-context/control-profile/components/ui/EditButton";
import GenericProfileTab from '@/tenant-context/control-profile/components/ui/GenericProfileTab';
import ProfileLoadingOverlayComponent from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay/ProfileLoadingOverlay.component';
import ProfileTagsSection from '@/tenant-context/control-profile/components/ui/ProfileTagsSection';
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';
import { ProfileGeneral, ProfileGeneralSection } from '@/tenant-context/control-profile/types/profile';

type Props = {
  general?: ProfileGeneral,
  openRef: React.RefObject<() => void>,
  onNewProfilePictureSelected: (files: FileWithPath[]) => void,
  onNewProfilePictureRejected: (fileRejections: FileRejection[]) => void,
  pictureFileTypes: string[],
  maxPictureFileSize: number,
  isAddNewProfile: boolean,
  isOwnProfile: () => boolean
  isCanViewProfileTags: boolean
  isCanEditProfileTags: boolean
}

export const profileEditModalMap: Record<ProfileGeneralSection, ModalSettings> = {
  [ProfileGeneralSection.Personal]: {
    title: 'Edit Personal Details',
    children: (
      <PersonalDetailsModal />
    )
  },
  [ProfileGeneralSection.Company]: {
    title: 'Edit Company Details',
    children: (
      <CompanyDetailsModal />
    )
  },
  [ProfileGeneralSection.Medical]: {
    title: 'Edit Medical Details',
    children: (
      <MedicalDetailsModal />
    )
  },
  [ProfileGeneralSection.Attributes]: {
    title: 'Edit Personal Attributes',
    children: (
      <AttributesModal />
    )
  },
  [ProfileGeneralSection.DistinguishingFeatures]: {
    title: 'Edit Distinguishing Features',
    children: (
      <DistinguishingFeaturesModal />
    )
  }
};

const profileEditPoliciesMap: Record<ProfileGeneralSection, RequiredPolicies | RequiredPolicies[]> = {
  [ProfileGeneralSection.Personal]: ProfileRequiredPoliciesConfig.EDIT_PROFILE_PERSONAL_DETAILS,
  [ProfileGeneralSection.Company]: ProfileRequiredPoliciesConfig.EDIT_COMPANY_DETAILS_SECTION,
  [ProfileGeneralSection.Medical]: [
    ProfileRequiredPoliciesConfig.EDIT_MEDICAL_SECTION,
    ProfileRequiredPoliciesConfig.FULL_ACCESS_SENSITIVE_DATA
  ],
  [ProfileGeneralSection.Attributes]: ProfileRequiredPoliciesConfig.EDIT_ATTRIBUTES_SECTION,
  [ProfileGeneralSection.DistinguishingFeatures]: ProfileRequiredPoliciesConfig.EDIT_DISTINGUISHING_FEATURES_SECTION
};

const profileViewPoliciesMap: Record<ProfileGeneralSection, RequiredPolicies | RequiredPolicies[]> = {
  [ProfileGeneralSection.Personal]: ProfileRequiredPoliciesConfig.VIEW_PROFILE_PERSONAL_DETAILS,
  [ProfileGeneralSection.Company]: ProfileRequiredPoliciesConfig.VIEW_COMPANY_DETAILS_SECTION,
  [ProfileGeneralSection.Medical]: [
    ProfileRequiredPoliciesConfig.VIEW_MEDICAL_SECTION,
    ProfileRequiredPoliciesConfig.READ_ONLY_SENSITIVE_DATA
  ],
  [ProfileGeneralSection.Attributes]: ProfileRequiredPoliciesConfig.VIEW_ATTRIBUTES_SECTION,
  [ProfileGeneralSection.DistinguishingFeatures]: ProfileRequiredPoliciesConfig.VIEW_DISTINGUISHING_FEATURES_SECTION
};

const ProfileProfileTab: FC<Props> = ({
  general,
  openRef,
  onNewProfilePictureSelected,
  onNewProfilePictureRejected,
  pictureFileTypes,
  maxPictureFileSize,
  isAddNewProfile,
  isOwnProfile,
  isCanViewProfileTags,
  isCanEditProfileTags
}) => {
  const { classes } = useProfileProfileTabStyles();
  const isGeneralLoading = useSelector((state: RootStateWithLoading) => state.loading.effects.profile.loadGeneral);

  // eslint-disable-next-line react/no-multi-comp, react/display-name
  const createAction = useCallback((section: ProfileGeneralSection) => () => (
    <EditButton
      // eslint-disable-next-line react/jsx-no-bind
      onClick={ () => {
        openModal({ ...profileEditModalMap[section], closeOnClickOutside: false });
      } }
      requiredPolicies={ isOwnProfile() ? [] : profileEditPoliciesMap[section] }
    />
  ), [isOwnProfile]);

  if (
    !general && !isAddNewProfile
    // || !company || !medical || !personalAttributes || !distinguishingFeatures
  ) {
    return <p>Loading...</p>;
  }

  return (
    <GenericProfileTab heading="Profile">
      <div className={ classes.block }>
        <ProfileLoadingOverlayComponent
          isLoading={ isGeneralLoading }
        />
        <div className={ classes.profilePictureContainer }>
          <div className={ classes.editProfilePictureButtonWrapper }>
            { general?.profilePictureUrl && (
              <img
                className={ classes.profilePicture }
                src={ general?.profilePictureUrl }
                alt="Profile Avatar"
              />
            ) }
            <ProtectedFragment requiredPolicies={ isOwnProfile() ? [] : 
              profileEditPoliciesMap[ProfileGeneralSection.Personal] }>
              <EditButton
                className={ classes.editProfilePictureButton }
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => openRef.current?.() }
              />
            </ProtectedFragment>
          </div>
          <div className={ classes.hiddenDropzone }>
            <Dropzone
              maxFiles={ 1 }
              openRef={ openRef }
              maxSize={ maxPictureFileSize }

              onAcceptedFiles={ onNewProfilePictureSelected }
              onRejectedFiles={ onNewProfilePictureRejected }
              accept={ pictureFileTypes.map((type) => `image/${type}`) }
            />
          </div>
          { isCanEditProfileTags && <AddTagsPopover/> }
          { isCanViewProfileTags && <ProfileTagsSection/> }
        </div>
        <div
          className={ classes.contentContainer }
        >
          <ProtectedFragment requiredPolicies={ isOwnProfile()  ? 
            [] : profileViewPoliciesMap[ProfileGeneralSection.Personal] }>
            <ProfilePersonalDetails
              createAction={ createAction }
            />
          </ProtectedFragment>

          <ProtectedFragment requiredPolicies={ isOwnProfile()  ? [] :
            profileViewPoliciesMap[ProfileGeneralSection.Company] }>
            <ProfileCompanyDetails
              createAction={ createAction }
            />
          </ProtectedFragment>

          <ProtectedFragment requiredPolicies={ isOwnProfile() ? [] : 
            profileViewPoliciesMap[ProfileGeneralSection.Medical] }>
            <ProfileMedicalDetails
              createAction={ createAction }
            />
          </ProtectedFragment>

          <ProtectedFragment requiredPolicies={ isOwnProfile() ? [] : 
            profileViewPoliciesMap[ProfileGeneralSection.Attributes] }>
            <ProfileAttributes
              createAction={ createAction }
            />
          </ProtectedFragment>

          <ProtectedFragment requiredPolicies={ isOwnProfile() ? [] : 
            profileViewPoliciesMap[ProfileGeneralSection.DistinguishingFeatures] }>
            <ProfileDistinguishingFeatures
              createAction={ createAction }
            />
          </ProtectedFragment>
        </div>
      </div>
    </GenericProfileTab>
  );
};

export default ProfileProfileTab;
