/* eslint-disable no-magic-numbers,fp/no-let */
import { FileWithPath } from "@mantine/dropzone";
import { createModel } from "@rematch/core";
import { cloneDeep } from "lodash";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import theme from "@/core/styles/mantine/theme";
import { getCaseDetailsById } from "@/tenant-context/control-action-response-center/api/risk-alert";
import { ArcImpactDetail } from "@/tenant-context/control-action-response-center/types/ARC.types";
import { getProfiles, getProfilesByLookupKey } from "@/tenant-context/control-groups/api/groups";
import {
  addOrUpdateRecipients,
  createNewTemplate,
  deleteTemplate,
  getAlertSequenceNumber,
  getAllTemplates,
  getCommunicationSequence,
  getEmailStylingContent,
  getEmailStylingTemplate,
  getGroupsSearchResults,
  getProfilesPerGroup,
  getSentCommunicationList,
  getTemplateByTid,
  postCommunicationsConfig,
  sendCommunication
} from "@/tenant-context/control-mass-comms/api/mass-comms";
import {
  AddOrUpdateRecipientsRequest, AppCommunicationContent,
  Base64EmailAttachment,
  CommunicationConfigRequest,
  CommunicationLink,
  CommunicationTemplate,
  DeliveryChannel,
  DeliveryItems,
  EmailConfigKeys,
  EmailContent,
  EmailSettingsConfig,
  GroupEntity,
  ImpactedPeopleSet,
  MASS_COMMS_STEPS, MassCommsAppCommunicationsResponseItem,
  MassCommsEmailResponseItem,
  MassCommsNavigation,
  MassCommsPhoneResponseItem,
  PeopleEntity,
  PickedPeopleEntity,
  ReceiveCountByCommunicationChannel,
  RecipientMainSelection,
  ResponseMethod,
  ResponseMethodMessageContent,
  SearchedDeliveryEntities,
  SelectedConfigTab,
  SentCommsList,
  SentCommunications,
  SmsContent,
  TemplateList,
  VOICE_MESSAGE_TYPES,
  VoiceContent
} from "@/tenant-context/control-mass-comms/types/MassComms.types";
import { constructProfileSearchQueryByProfileId, generateResponseMethodsMarkup } from "@/tenant-context/control-mass-comms/utils";

import { getPeopleSearchResults } from "../api/mass-comms";
import { footerPlaceHolders, headerPlaceHolders } from "../utils/mass-comms-constants";

type MassCommsState = {
  currentCommunicatTid: string | undefined,
  communicationName: string,
  communicationSequenceNo: number | null,
  alertSequenceNumber: number | null,
  deliveryChannels: DeliveryChannel,
  emailContent: EmailContent,
  selectedResponseMethod: ResponseMethod,
  appCommsResponseMethodConfigurations: MassCommsAppCommunicationsResponseItem[],
  emailResponseMethodConfigurations: MassCommsEmailResponseItem[],
  smsResponseMethodConfigurations: MassCommsPhoneResponseItem[],
  voiceResponseMethodConfigurations: MassCommsPhoneResponseItem[],
  lastResponseMethodMessageContent: ResponseMethodMessageContent,
  templatesList: TemplateList | undefined,
  currentStep: MASS_COMMS_STEPS,
  selectedRecipientType: RecipientMainSelection,
  isDeliveryEntitySearchLoading: boolean | undefined,
  searchedDeliveryEntities: SearchedDeliveryEntities,
  selectedRecipients: Array<PickedPeopleEntity>,
  selectedGroups: Array<GroupEntity>,
  receiveCountByCommunicationChannel: ReceiveCountByCommunicationChannel,
  smsContent: SmsContent,
  massCommsNavigation: MassCommsNavigation,
  voiceContent: VoiceContent,
  selectedConfigTab: SelectedConfigTab,
  impactResponseItems: ImpactedPeopleSet,
  selectedRecipientFilterPhrase: string,
  isInTemplateManagementView: boolean,
  currentSelectedTemplate: string | undefined,
  emailSettingsConfig: EmailSettingsConfig,
  sentCommunicationList: Array<SentCommunications>,
  commsSearchText: string,
  appCommunicationContent: AppCommunicationContent
}

export const massCommsDefaultState: MassCommsState = {
  massCommsNavigation: {
    isOpen: false,
    fromArc: false,
    isSliderOpen: false
  },
  currentCommunicatTid: undefined,
  communicationName: '',
  communicationSequenceNo: null,
  alertSequenceNumber: null,
  impactResponseItems: {
    allImpacted: { isChecked: false, people: [] },
    notSafe: { isChecked: false, people: [] },
    noResponse: { isChecked: false, people: [] },
    okPeople: { isChecked: false, people: [] },
    otherPeople: { isChecked: false, people: [] }
  },
  deliveryChannels: {
    email: false,
    sms: false,
    voice: false,
    app: false
  },
  emailContent: {
    subject: '',
    body: '',
    bodyWithStyling: '',
    attachments: [],
    encodedAttachments: [],
    fontColor: ''
  },
  smsContent: {
    body: '',
    length: 0,
    units: 0
  },
  voiceContent: {
    voiceMessageType: VOICE_MESSAGE_TYPES.UPLOAD_AUDIO,
    audioContent: ""
  },
  selectedResponseMethod: 'NO_RESPONSE',
  appCommsResponseMethodConfigurations: [],
  emailResponseMethodConfigurations: [],
  smsResponseMethodConfigurations: [],
  voiceResponseMethodConfigurations: [],
  lastResponseMethodMessageContent: {
    app: undefined,
    email: undefined,
    sms: undefined
  },
  currentStep: MASS_COMMS_STEPS.CREATE_COMMS,
  selectedRecipientType: 'singleGroupSelect',
  isDeliveryEntitySearchLoading: undefined,
  searchedDeliveryEntities: {
    groups: [],
    people: []
  },
  selectedRecipients: [],
  selectedGroups: [],
  receiveCountByCommunicationChannel: {
    all: 0,
    none: 0,
    email: 0,
    sms: 0,
    voice: 0,
    app: 0,
    emailAndSms: 0,
    emailAndVoice: 0,
    smsAndVoice: 0,
    appAndEmail: 0,
    appAndSms: 0,
    appAndVoice: 0,
    appAndEmailAndSms: 0,
    appAndEmailAndVoice: 0,
    appAndSmsAndVoice: 0,
    emailAndSmsAndVoice: 0,
    allApp: 0,
    allEmail: 0,
    allSMS: 0,
    allVoice: 0
  },
  selectedConfigTab: {
    defaultTab: undefined,
    fromSummary: false
  },
  selectedRecipientFilterPhrase: '',
  isInTemplateManagementView: false,
  templatesList: [],
  currentSelectedTemplate: undefined,
  emailSettingsConfig: {
    originatorEmail: 'noreply@restrata.net',
    originatorName: 'Restrata Alert'
  },
  sentCommunicationList: [],
  commsSearchText: '',
  appCommunicationContent: {
    header: '',
    content: '',
    attachments: [],
    encodedAttachments: [],
    criticalAlertIOS: false,
    isBiometricConfirmationTurnedOn: false
  }
};

const massCommsModel = {
  name: 'massComms',
  state: cloneDeep(massCommsDefaultState),
  reducers: {
    SET_CURRENT_COMMS_TID(
      state: MassCommsState,
      payload: string
    ) {
      return {
        ...state,
        currentCommunicatTid: payload
      };
    },
    SET_MASS_COMMS_NAV(
      state: MassCommsState,
      payload: MassCommsNavigation
    ) {
      return {
        ...state,
        massCommsNavigation: payload
      };
    },

    SET_COMMS_NAME(
      state: MassCommsState,
      payload: string
    ) {
      return {
        ...state,
        communicationName: payload
      };
    },

    SET_IMPACT_RESPONSE_ITEMS(
      state: MassCommsState,
      payload: ImpactedPeopleSet
    ) {
      return {
        ...state,
        impactResponseItems: payload
      };
    },

    SET_COMMS_SEQUENCE_NO(
      state: MassCommsState,
      payload: number
    ) {
      return {
        ...state,
        communicationSequenceNo: payload
      };
    },

    SET_ALERT_SEQUENCE_NUMBER(
      state: MassCommsState,
      payload: number
    ) {
      return {
        ...state,
        alertSequenceNumber: payload
      };
    },

    SET_SELECTED_DELIVERY_CHANNELS(
      state: MassCommsState,
      payload: DeliveryChannel
    ) {
      return {
        ...state,
        deliveryChannels: payload
      };
    },

    SET_SELECTED_RESPONSE_METHOD(
      state: MassCommsState,
      payload: ResponseMethod
    ) {
      return {
        ...state,
        selectedResponseMethod: payload
      };
    },

    SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION(
      state: MassCommsState,
      payload: MassCommsAppCommunicationsResponseItem[]
    ) {
      return {
        ...state,
        appCommsResponseMethodConfigurations: payload
      };
    },

    SET_EMAIL_RESPONSE_METHOD_CONFIGURATION(
      state: MassCommsState,
      payload: MassCommsEmailResponseItem[]
    ) {
      return {
        ...state,
        emailResponseMethodConfigurations: payload
      };
    },

    SET_SMS_RESPONSE_METHOD_CONFIGURATION(
      state: MassCommsState,
      payload: MassCommsPhoneResponseItem[]
    ) {
      return {
        ...state,
        smsResponseMethodConfigurations: payload
      };
    },

    SET_VOICE_RESPONSE_METHOD_CONFIGURATION(
      state: MassCommsState,
      payload: MassCommsPhoneResponseItem[]
    ) {
      return {
        ...state,
        voiceResponseMethodConfigurations: payload
      };
    },

    SET_TEMPLATE_ITEMS(
      state: MassCommsState,
      payload: TemplateList
    ) {
      return {
        ...state,
        templatesList: payload
      };
    },

    SET_CURRENT_STEP(
      state: MassCommsState,
      payload: number
    ) {
      return {
        ...state,
        currentStep: payload
      };
    },

    SET_SELECTED_PEOPLE(
      state: MassCommsState,
      payload: Array<PickedPeopleEntity>
    ) {
      return {
        ...state,
        selectedRecipients: payload
      };
    },

    SET_SELECTED_GROUPS(
      state: MassCommsState,
      payload: Array<GroupEntity>
    ) {
      return {
        ...state,
        selectedGroups: payload
      };
    },

    SET_IS_DELIVERY_SEARCH_LOADING(
      state: MassCommsState,
      payload: boolean | undefined
    ) {
      return {
        ...state,
        isDeliveryEntitySearchLoading: payload
      };
    },

    SET_SEARCHED_DELIVERY_ENTITIES(
      state: MassCommsState,
      payload: SearchedDeliveryEntities
    ) {
      return {
        ...state,
        searchedDeliveryEntities: payload
      };
    },

    SET_EMAIL_CONTENT(
      state: MassCommsState,
      payload: EmailContent
    ) {
      return {
        ...state,
        emailContent: payload
      };
    },

    CLEAR_EMAIL_CONTENT(
      state: MassCommsState
    ) {
      return {
        ...state,
        emailContent: { subject: '',
          body: '',
          bodyWithStyling: '',
          attachments: [],
          encodedAttachments: [] }
      };
    },

    SET_SMS_CONTENT(
      state: MassCommsState,
      payload: SmsContent
    ) {
      return {
        ...state,
        smsContent: payload
      };
    },

    SET_RECEIVE_COUNT_BY_COMMUNICATION_CHANNEL(
      state: MassCommsState,
      payload: ReceiveCountByCommunicationChannel
    ) {
      return {
        ...state,
        receiveCountByCommunicationChannel: payload
      };
    },

    SET_VOICE_MESSAGE_TYPE(
      state: MassCommsState,
      payload: VOICE_MESSAGE_TYPES
    ) {
      return {
        ...state,
        voiceContent: {
          ...state.voiceContent,
          voiceMessageType: payload
        }
      };
    },

    SET_VOICE_CONTENT(
      state: MassCommsState,
      payload: string
    ) {
      return {
        ...state,
        voiceContent: {
          ...state.voiceContent,
          audioContent: payload
        }
      };
    },

    SET_LAST_RESPONSE_METHOD_MESSAGE_CONTENT(
      state: MassCommsState,
      payload: ResponseMethodMessageContent
    ) {
      return {
        ...state,
        lastResponseMethodMessageContent: payload
      };
    },
    SET_SELECTED_CONFIG_TAB(
      state: MassCommsState,
      payload: SelectedConfigTab
    ) {
      return {
        ...state,
        selectedConfigTab: payload
      };
    },

    SET_SELECTED_RECIPIENT_TYPE(
      state: MassCommsState,
      payload: RecipientMainSelection
    ) {
      return {
        ...state,
        selectedRecipientType: payload
      };
    },

    SET_SELECTED_RECIPIENTS_FILTER_PHRASE(
      state: MassCommsState,
      payload: string
    ) {
      return {
        ...state,
        selectedRecipientFilterPhrase: payload
      };
    },

    RESET_MASS_COMMS_TO_DEFAULT_STATE(
      state: MassCommsState,
      payload: MassCommsState
    ) {
      return {
        ...payload
      };
    },

    SET_IS_IN_TEMPLATE_MANAGEMENT_VIEW(
      state: MassCommsState,
      payload: boolean
    ) {
      return {
        ...state,
        isInTemplateManagementView: payload
      };
    },

    SET_CURRENT_SELECTED_TEMPLATE_ID(
      state: MassCommsState,
      payload: string
    ) {
      return {
        ...state,
        currentSelectedTemplate: payload
      };
    },

    SET_EMAIL_SETTINGS_CONFIG(
      state: MassCommsState,
      payload: EmailSettingsConfig
    ) {
      return {
        ...state,
        emailSettingsConfig: payload
      };
    },

    SET_SENT_COMMS_LIST(
      state: MassCommsState,
      payload: Array<SentCommunications>
    ) {
      return {
        ...state,
        sentCommunicationList: payload
      };
    },
    SET_SEARCHED_COMMS_TEXT(
      state: MassCommsState,
      payload: string
    ) {
      return {
        ...state,
        commsSearchText: payload
      };
    },

    SET_APP_COMMUNICATION_CONTENT(
      state: MassCommsState,
      payload: AppCommunicationContent
    ) {
      return {
        ...state,
        appCommunicationContent: payload
      };
    }
  },
  effects: (dispatch: Dispatch) => ({

    async loadPeopleSearch(searchCriteria: string, state: RootState): Promise<void> {
      const {
        massComms: {
          searchedDeliveryEntities,
          selectedRecipients,
          deliveryChannels
        }
      } = state;

      if (!searchCriteria) {
        dispatch.massComms.SET_SEARCHED_DELIVERY_ENTITIES({ ...searchedDeliveryEntities, people: [] });
        return;
      }

      dispatch.massComms.SET_IS_DELIVERY_SEARCH_LOADING(true);
      const shouldGetAppInstallationStatus = deliveryChannels.app;

      const people = await getPeopleSearchResults(searchCriteria, shouldGetAppInstallationStatus);
      people?.items?.map(searchedPerson => {
        const isAlreadyAdded = selectedRecipients
          ?.find(addedPerson => addedPerson.profileId === searchedPerson.profileId);

        // eslint-disable-next-line no-param-reassign
        searchedPerson.isSelected = !!isAlreadyAdded;

        return searchedPerson;
      });

      dispatch.massComms.SET_SEARCHED_DELIVERY_ENTITIES({ ...searchedDeliveryEntities, people: people.items });
      dispatch.massComms.SET_IS_DELIVERY_SEARCH_LOADING(false);
    },

    async loadGroupSearch(searchCriteria: string, state: RootState): Promise<void> {
      const {
        massComms: {
          searchedDeliveryEntities,
          selectedGroups
        }
      } = state;

      if (!searchCriteria) {
        dispatch.massComms.SET_SEARCHED_DELIVERY_ENTITIES({ ...searchedDeliveryEntities, groups: [] });
        return;
      }

      dispatch.massComms.SET_IS_DELIVERY_SEARCH_LOADING(true);

      const groups = await getGroupsSearchResults(searchCriteria);
      const mappedGroups = groups?.items?.map(searchedGroup => {
        const isAlreadyAdded = selectedGroups
          ?.find(addedGroup => addedGroup._id === searchedGroup._id);

        return {
          ...searchedGroup,
          isSelected: !!isAlreadyAdded
        };
      });

      dispatch.massComms.SET_SEARCHED_DELIVERY_ENTITIES({ ...searchedDeliveryEntities, groups: mappedGroups });
      dispatch.massComms.SET_IS_DELIVERY_SEARCH_LOADING(false);
    },

    async saveCommunicationsConfig(_: void, state: RootState): Promise<void> {

      const {
        commonData: {
          tenantId
        },
        riskAlerts: {
          currentRiskAlert
        },
        massComms: {
          deliveryChannels,
          communicationName,
          appCommunicationContent,
          emailContent,
          selectedResponseMethod,
          appCommsResponseMethodConfigurations,
          emailResponseMethodConfigurations,
          smsResponseMethodConfigurations,
          voiceResponseMethodConfigurations,
          smsContent,
          voiceContent,
          alertSequenceNumber,
          emailSettingsConfig,
          massCommsNavigation
        }
      } = state;

      const selectedChannels: Array<DeliveryItems> = Object.entries(deliveryChannels)
        .filter(([_key, value]) => !!value)
        .map(([key]) => {
          return key.toUpperCase() as DeliveryItems;
        });

      const finalCommunicationName = alertSequenceNumber ? `${communicationName} #${alertSequenceNumber}` : communicationName;

      const responseMethods = generateResponseMethodsMarkup(emailResponseMethodConfigurations, tenantId);
      const requestBody: CommunicationConfigRequest = {
        tenantId,
        communicationName: finalCommunicationName,
        sameContent: true,
        deliveryChannels: selectedChannels,
        appConfiguration: {
          subject: appCommunicationContent.header,
          content: appCommunicationContent.content,
          attachments: appCommunicationContent.encodedAttachments?.map(attachment => attachment.base64),
          criticalAlertIOS: appCommunicationContent.criticalAlertIOS,
          confirmWithBiometrics: appCommunicationContent.isBiometricConfirmationTurnedOn
        },
        emailConfiguration: {
          subject: emailContent.subject,
          settings: {
            originatorDisplayName: emailSettingsConfig.originatorName,
            originatorEmail: emailSettingsConfig.originatorEmail
          },
          content: emailContent.body + responseMethods,
          attachments: emailContent.encodedAttachments?.map(attachment => attachment.base64)
        },
        smsConfiguration: {
          content: smsContent.body || ''
        },
        voiceConfiguration: {
          originator: "",
          attempts: 0,
          audioType: voiceContent.voiceMessageType,
          audioContent: voiceContent.audioContent
        },
        responseMethod: {
          type: selectedResponseMethod,
          app: { configurations: appCommsResponseMethodConfigurations },
          email: { configurations: emailResponseMethodConfigurations },
          sms: { configurations: smsResponseMethodConfigurations },
          voice: { configurations: voiceResponseMethodConfigurations }
        },
        source: {
          type: massCommsNavigation.fromArc ? 'alert' : 'manual',
          id:  massCommsNavigation.fromArc ? (currentRiskAlert?.json.alert.id || '') : finalCommunicationName
        }
      };

      await postCommunicationsConfig(requestBody).then(({ tid }) => {
        dispatch.massComms.SET_CURRENT_COMMS_TID(tid);
      });
    },

    removeSelectedRecipient(profileId: string, state: RootState): void {
      const { selectedRecipients, searchedDeliveryEntities } = state.massComms;

      const updatedRecipients = selectedRecipients.filter((person) => person.profileId !== profileId);
      dispatch.massComms.SET_SELECTED_PEOPLE(updatedRecipients);

      // Unselecting removed people from search list
      const updatedDeliveryItems = { ...searchedDeliveryEntities };
      const searchedItem = updatedDeliveryItems.people.find((searchPerson) =>
        searchPerson.profileId === profileId);

      if (searchedItem) {
        searchedItem.isSelected = false;

        dispatch.massComms.SET_SEARCHED_DELIVERY_ENTITIES(updatedDeliveryItems);
      }

      dispatch.massComms.calculateChannelCount();
    },

    selectRecipients(selectedPerson: PeopleEntity, state: RootState): void {
      const { selectedRecipients, searchedDeliveryEntities } = state.massComms;
      const searchedDeliveryItems = { ...searchedDeliveryEntities };
      const foundItem = searchedDeliveryItems.people.find((searchPerson) =>
        searchPerson.profileId === selectedPerson.profileId);

      if (foundItem) {
        foundItem.isSelected = true;
        dispatch.massComms.SET_SEARCHED_DELIVERY_ENTITIES(searchedDeliveryItems);
        const selectedRecipientsList = [...selectedRecipients];
        const isItemAvailable = selectedRecipientsList.find((person) => person.profileId === selectedPerson.profileId);

        if (!isItemAvailable) {
          // Hardcoding reason as Selected until we have impacted people getter
          const person: PickedPeopleEntity = {
            ...selectedPerson,
            reason: 'Selected',
            isWithEmail: !!selectedPerson.email,
            isWithPhone: !!selectedPerson.phoneNumber,
            isAppInstalled: selectedPerson.appInstalled
          };

          // selectedRecipientsList.push(person);
          dispatch.massComms.SET_SELECTED_PEOPLE([
            ...selectedRecipientsList,
            person
          ]);
        }
      }
      dispatch.massComms.calculateChannelCount();
    },

    async addGroup(selectedGroup: GroupEntity, state: RootState): Promise<void> {
      const { searchedDeliveryEntities, selectedGroups, selectedRecipients } = state.massComms;
      const searchedDeliveryItems = { ...searchedDeliveryEntities };
      const selGroups = [...selectedGroups];
      const profilesPerGroup = await getProfilesPerGroup(selectedGroup._id);
      const adjustedProfiles = profilesPerGroup.items.map(profile => {
        return {
          ...profile,
          reason: selectedGroup.name,
          isWithEmail: !!profile.email,
          isWithPhone: !!profile.phoneNumber,
          isWithApp: profile.appInstalled,
          groupId: selectedGroup._id
        };


      });
      const selectedProfiles = [...selectedRecipients, ...adjustedProfiles];
      dispatch.massComms.SET_SELECTED_PEOPLE(selectedProfiles as PickedPeopleEntity[]);
      dispatch.massComms.calculateChannelCount();
      selGroups.push(selectedGroup);
      dispatch.massComms.SET_SELECTED_GROUPS(selGroups);
      const foundItem = searchedDeliveryItems.groups.find((searchGroup) =>
        searchGroup._id === selectedGroup._id);

      if (foundItem) {
        foundItem.isSelected = true;
        dispatch.massComms.SET_SEARCHED_DELIVERY_ENTITIES(searchedDeliveryItems);
      }
    },

    removeGroup(selectedGroup: GroupEntity, state: RootState): void {
      const { searchedDeliveryEntities, selectedGroups, selectedRecipients } = state.massComms;
      // eslint-disable-next-line fp/no-let
      let selRecipients = [...selectedRecipients];
      // eslint-disable-next-line fp/no-let
      let selGroups = [...selectedGroups];
      const searchedDeliveryItems = { ...searchedDeliveryEntities };
      selRecipients = selRecipients.filter(recipient => recipient?.groupId !== selectedGroup._id);
      selGroups = selGroups.filter(group => group._id !== selectedGroup._id);
      dispatch.massComms.SET_SELECTED_PEOPLE(selRecipients);
      dispatch.massComms.SET_SELECTED_GROUPS(selGroups);
      dispatch.massComms.calculateChannelCount();

      const foundItem = searchedDeliveryItems.groups.find((searchGroup) =>
        searchGroup._id === selectedGroup._id);

      if (foundItem) {
        foundItem.isSelected = false;
        dispatch.massComms.SET_SEARCHED_DELIVERY_ENTITIES(searchedDeliveryItems);
      }

    },

    async saveSelectedRecipients(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        },
        massComms: {
          currentCommunicatTid,
          selectedRecipients,
          communicationName,
          alertSequenceNumber
        }
      } = state;

      const communicationLinks: Array<CommunicationLink> = selectedRecipients.map((person) => {
        return {
          personId: person.profileId,
          displayValue: person.firstName,
          filter: '',
          communicationChannels: [
            {
              commType: 'EMAIL',
              commValue: ''
            }
          ]
        };
      });

      const finalCommunicationName = alertSequenceNumber ? `${communicationName} #${alertSequenceNumber}` : communicationName;

      const requestBody: AddOrUpdateRecipientsRequest = {
        parentTid: currentCommunicatTid as string,
        communicationName: finalCommunicationName,
        communicationLinks: communicationLinks
      };

      await addOrUpdateRecipients(
        tenantId,
        requestBody
      );
    },

    calculateChannelCount(_: void, state: RootState): void {
      const {
        massComms: {
          selectedRecipients,
          deliveryChannels
        }
      } = state;

      const equalsCheck = (a: (boolean | undefined)[], b: (boolean | undefined)[]) =>
        a.length === b.length &&
        a.every((v, i) => v === b[i]);

      let allChannelsCount = 0;
      let emailAndSMSCount = 0;
      let emailAndVoiceCount = 0;
      let smsAndVoiceCount = 0;
      let appOnlyCount = 0;
      let emailOnlyCount = 0;
      let voiceOnlyCount = 0;
      let smsOnlyCount = 0;
      let noChannelCount = 0;
      let appAndEmailCount = 0;
      let appAndSmsCount = 0;
      let appAndVoiceCount = 0;
      let appAndEmailAndSmsCount = 0;
      let appAndEmailAndVoiceCount = 0;
      let appAndSmsAndVoiceCount = 0;
      let emailAndSmsAndVoiceCount = 0;
      let allApp = 0;
      let allEmail = 0;
      let allSMS = 0;
      let allVoice = 0;

      selectedRecipients.forEach((person) => {

        const channelsCombination = [
          (person.isAppInstalled && deliveryChannels.app) || false,
          (person.isWithEmail && deliveryChannels.email) || false,
          (person.isWithPhone && deliveryChannels.sms) || false,
          (person.isWithPhone && deliveryChannels.voice) || false
        ];

        if (equalsCheck(channelsCombination, [true, true, true, true])) {
          allChannelsCount++;
        } else if (equalsCheck(channelsCombination, [true, true, true, false])) {
          appAndEmailAndSmsCount++;
        } else if (equalsCheck(channelsCombination, [true, true, false, true])) {
          appAndEmailAndVoiceCount++;
        } else if (equalsCheck(channelsCombination, [true, false, true, true])) {
          appAndSmsAndVoiceCount++;
        } else if (equalsCheck(channelsCombination, [false, true, true, true])) {
          emailAndSmsAndVoiceCount++;
        } else if (equalsCheck(channelsCombination, [true, true, false, false])) {
          appAndEmailCount++;
        } else if (equalsCheck(channelsCombination, [true, false, true, false])) {
          appAndSmsCount++;
        } else if (equalsCheck(channelsCombination, [true, false, false, true])) {
          appAndVoiceCount++;
        } else if (equalsCheck(channelsCombination, [false, true, true, false])) {
          emailAndSMSCount++;
        } else if (equalsCheck(channelsCombination, [false, true, false, true])) {
          emailAndVoiceCount++;
        } else if (equalsCheck(channelsCombination, [false, false, true, true])) {
          smsAndVoiceCount++;
        } else if (equalsCheck(channelsCombination, [true, false, false, false])) {
          appOnlyCount++;
        } else if (equalsCheck(channelsCombination, [false, true, false, false])) {
          emailOnlyCount++;
        } else if (equalsCheck(channelsCombination, [false, false, true, false])) {
          smsOnlyCount++;
        } else if (equalsCheck(channelsCombination, [false, false, false, true])) {
          voiceOnlyCount++;
        } else if (equalsCheck(channelsCombination, [false, false, false, false])) {
          noChannelCount++;
        }

        if (person.isAppInstalled && deliveryChannels.app) {
          allApp++;
        }
        if (person.isWithEmail && deliveryChannels.email) {
          allEmail++;
        }
        if (person.isWithPhone && deliveryChannels.sms) {
          allSMS++;
        }
        if (person.isWithPhone && deliveryChannels.voice) {
          allVoice++;
        }
      });

      const channelCount: ReceiveCountByCommunicationChannel = {
        all: allChannelsCount,
        none: noChannelCount,
        email: emailOnlyCount,
        sms: smsOnlyCount,
        voice: voiceOnlyCount,
        app: appOnlyCount,
        emailAndSms: emailAndSMSCount,
        emailAndVoice: emailAndVoiceCount,
        smsAndVoice: smsAndVoiceCount,
        appAndEmail: appAndEmailCount,
        appAndSms: appAndSmsCount,
        appAndVoice: appAndVoiceCount,
        appAndEmailAndSms: appAndEmailAndSmsCount,
        appAndEmailAndVoice: appAndEmailAndVoiceCount,
        appAndSmsAndVoice: appAndSmsAndVoiceCount,
        emailAndSmsAndVoice: emailAndSmsAndVoiceCount,
        allApp,
        allEmail,
        allSMS,
        allVoice
      };

      dispatch.massComms.SET_RECEIVE_COUNT_BY_COMMUNICATION_CHANNEL(channelCount);
    },

    async sendCommunication(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        },
        massComms: {
          communicationName,
          alertSequenceNumber
        }
      } = state;

      const finalCommunicationName = alertSequenceNumber ? `${communicationName} #${alertSequenceNumber}` : communicationName;

      await sendCommunication(
        tenantId,
        finalCommunicationName
      );
    },

    async uploadEmailAttachment(file: FileWithPath, state: RootState): Promise<void> {
      const { emailContent } = state.massComms;

      const base64EncodedFile: string = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            resolve(reader.result);
          } else {
            reject(new Error('Failed to load file'));
          }
        };
        reader.readAsDataURL(file);
      });
      const content = { ...emailContent };
      content.attachments?.push(file);
      content.encodedAttachments?.push({ name: file.name, extension: file.type, base64: base64EncodedFile });
      dispatch.massComms.SET_EMAIL_CONTENT(content);
    },

    async uploadVoiceAttachment(file: FileWithPath, _state: RootState): Promise<void> {
      const base64EncodedFile: string = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            resolve(reader.result);
          } else {
            reject(new Error('Failed to load file'));
          }
        };
        reader.readAsDataURL(file);
      });

      dispatch.massComms.SET_VOICE_CONTENT(base64EncodedFile);
    },

    async removeEmailAttachment(file: FileWithPath, state: RootState): Promise<void> {
      const { emailContent } = state.massComms;

      const base64EncodedFile: string = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            resolve(reader.result);
          } else {
            reject(new Error('Failed to load file'));
          }
        };
        reader.readAsDataURL(file);
      });
      const content = { ...emailContent };
      content.attachments = content.attachments?.filter(attch => attch !== file);
      content.encodedAttachments = content.encodedAttachments?.filter(attch => attch.base64 !== base64EncodedFile);
      dispatch.massComms.SET_EMAIL_CONTENT(content);
    },

    async removeBase64EmailAttachment(file: Base64EmailAttachment, state: RootState): Promise<void> {
      const { emailContent } = state.massComms;
      const content = { ...emailContent };
      content.encodedAttachments = content.encodedAttachments?.filter(attch => attch.base64 !== file.base64);
      dispatch.massComms.SET_EMAIL_CONTENT(content);
    },

    async uploadAppCommunicationAttachment(file: FileWithPath, state: RootState): Promise<void> {
      const { appCommunicationContent } = state.massComms;

      const base64EncodedFile: string = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            resolve(reader.result);
          } else {
            reject(new Error('Failed to load file'));
          }
        };
        reader.readAsDataURL(file);
      });
      const content = { ...appCommunicationContent };
      content.attachments?.push(file);
      content.encodedAttachments?.push({ name: file.name, extension: file.type, base64: base64EncodedFile });
      dispatch.massComms.SET_APP_COMMUNICATION_CONTENT(content);
    },

    async removeAppCommunicationAttachment(file: FileWithPath, state: RootState): Promise<void> {
      const { appCommunicationContent } = state.massComms;

      const base64EncodedFile: string = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            resolve(reader.result);
          } else {
            reject(new Error('Failed to load file'));
          }
        };
        reader.readAsDataURL(file);
      });
      const content = { ...appCommunicationContent };
      content.attachments = content.attachments?.filter(attch => attch !== file);
      content.encodedAttachments = content.encodedAttachments?.filter(attch => attch.base64 !== base64EncodedFile);
      dispatch.massComms.SET_APP_COMMUNICATION_CONTENT(content);
    },

    async removeBase64AppCommunicationAttachment(file: Base64EmailAttachment, state: RootState): Promise<void> {
      const { appCommunicationContent } = state.massComms;
      const content = { ...appCommunicationContent };
      content.encodedAttachments = content.encodedAttachments?.filter(attch => attch.base64 !== file.base64);
      dispatch.massComms.SET_APP_COMMUNICATION_CONTENT(content);
    },

    async getCommunicationSequence(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        }
      } = state;

      const sequenceResponse = await getCommunicationSequence(
        tenantId
      );

      dispatch.massComms.SET_COMMS_SEQUENCE_NO(sequenceResponse.lastCommunicationNumber);
    },

    async getAlertSequenceNumber(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        },
        riskAlerts: {
          currentRiskAlert
        }
      } = state;

      if (!currentRiskAlert) {
        return;
      }

      const alertSequenceNumber = await getAlertSequenceNumber(
        currentRiskAlert.json.alert.id,
        tenantId
      );

      dispatch.massComms.SET_ALERT_SEQUENCE_NUMBER(parseInt(alertSequenceNumber.lastAlertSequenceNumber.toString()));
    },

    prePopulateResponseContent(_: void, state: RootState): void {
      const {
        massComms: {
          deliveryChannels,
          appCommsResponseMethodConfigurations,
          emailResponseMethodConfigurations,
          smsResponseMethodConfigurations,
          appCommunicationContent,
          emailContent,
          smsContent,
          lastResponseMethodMessageContent
        }
      } = state;

      const responseMethodContent = { ...lastResponseMethodMessageContent };

      if (deliveryChannels.app) {
        const responses = appCommsResponseMethodConfigurations.map((responseMethod) => {
          return `Please click “${responseMethod.buttonLabel}” to respond as “${responseMethod.responseLabel}"`;
        });

        const responseString = responses.join(', ');

        // eslint-disable-next-line fp/no-let
        let updatedContent = appCommunicationContent.content || '';

        // TODO: handle last response method content for app
        if (lastResponseMethodMessageContent.app) {
          updatedContent = updatedContent.replaceAll(lastResponseMethodMessageContent.app, '');
        }

        const modifiedAppCommsContent = {
          ...appCommunicationContent,
          content: updatedContent.concat(`${responseString.toString()}`)
        };

        dispatch.massComms.SET_APP_COMMUNICATION_CONTENT(modifiedAppCommsContent);
        responseMethodContent.app = responseString.toString();
      }

      if (deliveryChannels.email) {
        const responses = ['<p>'].concat(emailResponseMethodConfigurations.map((responseMethod) => {
          return `Please click “[<strong>${responseMethod.buttonLabel}</strong>]” to respond as “<strong>${responseMethod.responseLabel}</strong>" ,`;
        })).concat(['</p>']);

        const responseString = responses.join('');

        // eslint-disable-next-line fp/no-let
        let updatedBody = emailContent.body || '';

        if (lastResponseMethodMessageContent.email) {
          updatedBody = updatedBody.replaceAll(lastResponseMethodMessageContent.email, '');
        }

        const modifiedEmailContent = {
          ...emailContent,
          body: updatedBody.concat(`${responseString.toString()}`)
        };

        dispatch.massComms.SET_EMAIL_CONTENT(modifiedEmailContent);
        responseMethodContent.email = responseString.toString();
      }

      if (deliveryChannels.sms) {
        const responses = smsResponseMethodConfigurations.map((responseMethod) => {
          return ` Please reply ${responseMethod.replyCode} to respond as ${responseMethod.responseLabel}`;
        });

        // eslint-disable-next-line fp/no-let
        let updatedBody = smsContent.body || '';

        if (lastResponseMethodMessageContent.sms) {
          updatedBody = updatedBody.replaceAll(lastResponseMethodMessageContent.sms, '');
        }

        const smsBody = responses.toString()
          .replaceAll(`"`, '')
          .replaceAll(`[`, '')
          .replaceAll(`]`, '')
          .replaceAll(`\n`, ' ');

        const standardSmsBodySize = 160;
        const textLength = updatedBody.concat(smsBody).length;
        const modifiedSMSContent = {
          ...smsContent,
          body: updatedBody.concat(smsBody),
          length: textLength,
          units: Math.ceil(textLength / standardSmsBodySize)
        };

        dispatch.massComms.SET_SMS_CONTENT(modifiedSMSContent);
        responseMethodContent.sms = responses.toString();
      }

      dispatch.massComms.SET_LAST_RESPONSE_METHOD_MESSAGE_CONTENT(responseMethodContent);
    },
    handlePrePopulationOnDeliveryChannelChange(payload: DeliveryChannel, state: RootState): void {
      const {
        massComms: {
          deliveryChannels,
          emailResponseMethodConfigurations,
          smsResponseMethodConfigurations,
          emailContent,
          smsContent,
          lastResponseMethodMessageContent
        }
      } = state;

      Object.entries(deliveryChannels).forEach(([key, value]) => {
        if(value !== (payload as Record<string, boolean>)[key]){
          if(key === "email"){
            if((payload as Record<string, boolean>)[key]){
              const responses = emailResponseMethodConfigurations.map((responseMethod) => {
                return `<br/> Please click “[<b>${responseMethod.buttonLabel}</b>]” to respond as “<b>${responseMethod.responseLabel}</b>`;
              });

              // eslint-disable-next-line fp/no-let
              let updatedBody = emailContent.body;

              if (lastResponseMethodMessageContent.email) {
                updatedBody = updatedBody.replaceAll(lastResponseMethodMessageContent.email, '');
              }

              const modifiedEmailContent = {
                ...emailContent,
                body: updatedBody.concat(`<br><br>${responses.toString()}`)
              };

              dispatch.massComms.SET_EMAIL_CONTENT(modifiedEmailContent);
              dispatch.massComms.SET_LAST_RESPONSE_METHOD_MESSAGE_CONTENT({
                ...lastResponseMethodMessageContent,
                email: responses.toString()
              });
            } else{

              const modifiedEmailContent = {
                ...emailContent,
                body: ''
              };

              dispatch.massComms.SET_EMAIL_CONTENT(modifiedEmailContent);
              dispatch.massComms.SET_LAST_RESPONSE_METHOD_MESSAGE_CONTENT({
                ...lastResponseMethodMessageContent,
                email: ''
              });
            }

          } else if (key === "sms"){
            if((payload as Record<string, boolean>)[key]){
              const responses = smsResponseMethodConfigurations.map((responseMethod) => {
                return ` Please reply ${responseMethod.replyCode} to respond as ${responseMethod.responseLabel}`;
              });

              // eslint-disable-next-line fp/no-let
              let updatedBody = smsContent.body || '';

              if (lastResponseMethodMessageContent.sms) {
                updatedBody = updatedBody.replaceAll(lastResponseMethodMessageContent.sms, '');
              }

              const smsBody = responses.toString()
                .replaceAll(`"`, '')
                .replaceAll(`[`, '')
                .replaceAll(`]`, '')
                .replaceAll(`\n`, ' ');

              const standardSmsBodySize = 160;
              const textLength = updatedBody.concat(smsBody).length;
              const modifiedSMSContent = {
                ...smsContent,
                body: updatedBody.concat(smsBody),
                length: textLength,
                units: Math.ceil(textLength / standardSmsBodySize)
              };

              dispatch.massComms.SET_SMS_CONTENT(modifiedSMSContent);
              dispatch.massComms.SET_LAST_RESPONSE_METHOD_MESSAGE_CONTENT({
                ...lastResponseMethodMessageContent,
                sms: responses.toString()
              });
            } else{
              const modifiedSMSContent = {
                ...smsContent,
                body: ''
              };

              dispatch.massComms.SET_SMS_CONTENT(modifiedSMSContent);
              dispatch.massComms.SET_LAST_RESPONSE_METHOD_MESSAGE_CONTENT({
                ...lastResponseMethodMessageContent,
                sms: ''
              });
            }
          }
        }
      });

      dispatch.massComms.SET_SELECTED_DELIVERY_CHANNELS(payload);
    },
    handleTabSelection(fromSummary: boolean, state: RootState): void {
      if (!fromSummary) {
        const {
          massComms: {
            deliveryChannels
          }
        } = state;
        const lastSelected = Object.entries(deliveryChannels)
          .map(([key]) => {
            if (deliveryChannels[key as DeliveryItems]) {
              return key;
            }

            return undefined;
          }).reverse().find(e => e !== undefined);

        dispatch.massComms.SET_SELECTED_CONFIG_TAB({
          fromSummary: false,
          defaultTab: lastSelected as DeliveryItems
        });
      }
    },

    changeResponseMethod(responseMethod: ResponseMethod, _state: RootState): void {
      dispatch.massComms.SET_SELECTED_RESPONSE_METHOD(responseMethod);

      if (responseMethod === 'SAFETY_POLL') {
        dispatch.massComms.SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION([
          {
            id: 1,
            buttonLabel: 'Safe',
            buttonColor: theme.colors?.risk?.[0] as string,
            safetyStatus: 'SAFE',
            responseLabel: 'SAFE'
          },
          {
            id: 2,
            buttonLabel: 'Not Safe',
            buttonColor: theme.colors?.risk?.[5] as string,
            safetyStatus: 'NOT_SAFE',
            responseLabel: 'NOT_SAFE'
          }
        ]);

        dispatch.massComms.SET_EMAIL_RESPONSE_METHOD_CONFIGURATION([
          {
            id: 1,
            buttonLabel: 'Safe',
            buttonColor: theme.colors?.risk?.[0] as string,
            safetyStatus: 'SAFE',
            responseLabel: 'SAFE'
          },
          {
            id: 2,
            buttonLabel: 'Not Safe',
            buttonColor: theme.colors?.risk?.[5] as string,
            safetyStatus: 'NOT_SAFE',
            responseLabel: 'NOT_SAFE'
          }
        ]);

        dispatch.massComms.SET_SMS_RESPONSE_METHOD_CONFIGURATION([
          { id: 1, replyCode: 1, safetyStatus: 'SAFE', responseLabel: 'SAFE' },
          { id: 2, replyCode: 2, safetyStatus: 'NOT_SAFE', responseLabel: 'NOT_SAFE' }
        ]);

        dispatch.massComms.SET_VOICE_RESPONSE_METHOD_CONFIGURATION([
          { id: 1, replyCode: 1, safetyStatus: 'SAFE', responseLabel: 'SAFE' },
          { id: 2, replyCode: 2, safetyStatus: 'NOT_SAFE', responseLabel: 'NOT_SAFE' }
        ]);
      } else if (responseMethod === 'CUSTOM_POLL') {
        dispatch.massComms.SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION([
          {
            id: 1,
            buttonLabel: '',
            buttonColor: theme.colors?.purple?.[9] as string,
            safetyStatus: 'NO_MAPPING',
            responseLabel: ''
          },
          {
            id: 2,
            buttonLabel: '',
            buttonColor: theme.colors?.purple?.[7] as string,
            safetyStatus: 'NO_MAPPING',
            responseLabel: ''
          }
        ]);

        dispatch.massComms.SET_EMAIL_RESPONSE_METHOD_CONFIGURATION([
          {
            id: 1,
            buttonLabel: '',
            buttonColor: theme.colors?.purple?.[9] as string,
            safetyStatus: 'NO_MAPPING',
            responseLabel: ''
          },
          {
            id: 2,
            buttonLabel: '',
            buttonColor: theme.colors?.purple?.[7] as string,
            safetyStatus: 'NO_MAPPING',
            responseLabel: ''
          }
        ]);

        dispatch.massComms.SET_SMS_RESPONSE_METHOD_CONFIGURATION([
          { id: 1, replyCode: 1, safetyStatus: 'NO_MAPPING', responseLabel: '' },
          { id: 2, replyCode: 2, safetyStatus: 'NO_MAPPING', responseLabel: '' }
        ]);

        dispatch.massComms.SET_VOICE_RESPONSE_METHOD_CONFIGURATION([
          { id: 1, replyCode: 1, safetyStatus: 'NO_MAPPING', responseLabel: '' },
          { id: 2, replyCode: 2, safetyStatus: 'NO_MAPPING', responseLabel: '' }
        ]);
      } else if (responseMethod === 'ACKNOWLEDGE') {
        dispatch.massComms.SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION([
          { id: 1, buttonLabel: 'ACKNOWLEDGE', buttonColor: theme.colors?.risk?.[0] as string, responseLabel: 'ACKNOWLEDGE', safetyStatus: 'ACKNOWLEDGE' }
        ]);

        dispatch.massComms.SET_EMAIL_RESPONSE_METHOD_CONFIGURATION([
          { id: 1, buttonLabel: 'ACKNOWLEDGE', buttonColor: theme.colors?.risk?.[0] as string, responseLabel: 'ACKNOWLEDGE', safetyStatus: 'ACKNOWLEDGE' }
        ]);

        dispatch.massComms.SET_SMS_RESPONSE_METHOD_CONFIGURATION([
          { id: 1, replyCode: 1, responseLabel: 'ACKNOWLEDGE', safetyStatus: 'ACKNOWLEDGE' }
        ]);

        dispatch.massComms.SET_VOICE_RESPONSE_METHOD_CONFIGURATION([
          { id: 1, replyCode: 1, responseLabel: 'ACKNOWLEDGE', safetyStatus: 'ACKNOWLEDGE' }
        ]);
      }
    },

    navigateToMassComms(navigation: MassCommsNavigation, _state: RootState): void {
      dispatch.massComms.SET_MASS_COMMS_NAV(navigation);
      if (navigation.fromArc) {
        dispatch.massComms.SET_SELECTED_RESPONSE_METHOD('SAFETY_POLL');
        dispatch.massComms.changeResponseMethod('SAFETY_POLL');
      } else if (navigation.from && navigation.from === 'look-up') {
        dispatch.massComms.SET_SELECTED_RESPONSE_METHOD('SAFETY_POLL');
        dispatch.massComms.changeResponseMethod('SAFETY_POLL');
      } else {
        dispatch.massComms.SET_SELECTED_RESPONSE_METHOD('NO_RESPONSE');
      }
    },

    resetMassCommsState(_: void, _state: RootState): void {
      dispatch.massComms.RESET_MASS_COMMS_TO_DEFAULT_STATE(massCommsDefaultState);
      dispatch.massComms.getAllTemplates();
      dispatch.massComms.SET_EMAIL_CONTENT({
        subject: '',
        body: '',
        bodyWithStyling: '',
        attachments: [],
        encodedAttachments: []
      });
      dispatch.massComms.SET_APP_COMMUNICATION_CONTENT({
        header: '',
        content: '',
        attachments: [],
        encodedAttachments: [],
        criticalAlertIOS: false,
        isBiometricConfirmationTurnedOn: false
      });
    },

    setTemplateManagementView(isInTemplateManagementView: boolean, _state: RootState): void {
      dispatch.massComms.SET_IS_IN_TEMPLATE_MANAGEMENT_VIEW(isInTemplateManagementView);
    },

    async saveTemplate(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        },
        massComms: {
          deliveryChannels,
          communicationName,
          appCommunicationContent,
          emailContent,
          selectedResponseMethod,
          appCommsResponseMethodConfigurations,
          emailResponseMethodConfigurations,
          smsResponseMethodConfigurations,
          voiceResponseMethodConfigurations,
          smsContent,
          voiceContent
        }
      } = state;

      const selectedChannels: Array<DeliveryItems> = Object.entries(deliveryChannels)
        .filter(([_key, value]) => !!value)
        .map(([key]) => {
          return key.toUpperCase() as DeliveryItems;
        });


      const requestBody: CommunicationTemplate = {
        tenantId,
        templateName: communicationName,
        sameContent: true,
        deliveryChannels: selectedChannels,
        appConfiguration: {
          subject: appCommunicationContent.header,
          content: appCommunicationContent.content,
          attachments: appCommunicationContent.encodedAttachments?.map(attachment => attachment.base64),
          criticalAlertIOS: appCommunicationContent.criticalAlertIOS,
          confirmWithBiometrics: appCommunicationContent.isBiometricConfirmationTurnedOn
        },
        emailConfiguration: {
          subject: emailContent.subject,
          settings: {
            originatorDisplayName: "originatorDisplayName",
            originatorEmail: "originatorEmail"
          },
          content: emailContent.body,
          attachments: emailContent.encodedAttachments?.map(attachment => attachment.base64)
        },
        smsConfiguration: {
          content: smsContent.body || ''
        },
        voiceConfiguration: {
          originator: "",
          attempts: 0,
          audioType: voiceContent.voiceMessageType,
          audioContent: voiceContent.audioContent
        },
        responseMethod: {
          type: selectedResponseMethod,
          app: { configurations: appCommsResponseMethodConfigurations },
          email: { configurations: emailResponseMethodConfigurations },
          sms: { configurations: smsResponseMethodConfigurations },
          voice: { configurations: voiceResponseMethodConfigurations }
        }
      };

      await createNewTemplate(
        tenantId,
        requestBody
      );

      dispatch.massComms.getAllTemplates();
    },

    async getSelectedTemplate(options: {
      templateId: string,
      isInTemplateEdit: boolean
    }, state: RootState): Promise<void> {

      const {
        templateId,
        isInTemplateEdit
      } = options;

      const {
        commonData: {
          tenantId
        }
      } = state;

      dispatch.massComms.SET_CURRENT_SELECTED_TEMPLATE_ID(templateId);

      const {
        SET_SELECTED_RESPONSE_METHOD,
        SET_SELECTED_DELIVERY_CHANNELS,
        SET_APP_COMMUNICATION_CONTENT,
        SET_EMAIL_CONTENT,
        SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION,
        SET_EMAIL_RESPONSE_METHOD_CONFIGURATION,
        SET_SMS_RESPONSE_METHOD_CONFIGURATION,
        SET_VOICE_RESPONSE_METHOD_CONFIGURATION,
        SET_SMS_CONTENT,
        SET_VOICE_CONTENT,
        SET_VOICE_MESSAGE_TYPE,
        SET_COMMS_NAME
      } = dispatch.massComms;

      const template = await getTemplateByTid(tenantId, templateId);

      const {
        deliveryChannels,
        appConfiguration,
        emailConfiguration,
        smsConfiguration,
        responseMethod,
        voiceConfiguration,
        templateName
      } = template;

      if (isInTemplateEdit) {
        SET_COMMS_NAME(templateName);
      }
      SET_SELECTED_RESPONSE_METHOD(responseMethod.type as ResponseMethod);

      // Has to use `as DeliveryItems` casting since BE stores delivery channels with uppercase, while FE does lowercase
      SET_SELECTED_DELIVERY_CHANNELS({
        email: deliveryChannels?.includes('EMAIL' as DeliveryItems) || false,
        sms: deliveryChannels?.includes('SMS' as DeliveryItems) || false,
        voice: deliveryChannels?.includes('VOICE' as DeliveryItems) || false,
        app: deliveryChannels?.includes('APP' as DeliveryItems) || false
      });

      if (appConfiguration) {
        SET_APP_COMMUNICATION_CONTENT({
          header: appConfiguration.subject,
          content: appConfiguration.content,
          criticalAlertIOS: appConfiguration.criticalAlertIOS,
          isBiometricConfirmationTurnedOn: appConfiguration.confirmWithBiometrics,
          encodedAttachments: appConfiguration.attachments?.map((base64, index) => {
            const extension = base64.split(';')[0].split('/')[1];
            const name = `Attachment - ${index + 1}`; // Because Base64 doesn't store file name
            const size = Math.ceil(base64.length / 4) * 3;

            return {
              name,
              extension,
              base64,
              size
            };
          })
        });
      }

      if (emailConfiguration) {
        SET_EMAIL_CONTENT({
          subject: emailConfiguration.subject,
          body: emailConfiguration.content,
          encodedAttachments: emailConfiguration.attachments?.map((base64, index) => {
            const extension = base64.split(';')[0].split('/')[1];
            const name = `Attachment - ${index + 1}`; // Because Base64 doesn't store file name
            const size = Math.ceil(base64.length / 4) * 3;

            return {
              name,
              extension,
              base64,
              size
            };
          })
        });
      }

      if (responseMethod.app) {
        const appResponseMethods = responseMethod.app.configurations
          .map((config, index) => {
            return {
              id: index + 1,
              responseLabel: config.responseLabel,
              responseLabelColor: config?.responseLabelColor || genericColorPalette.neutral[7],
              safetyStatus: config.safetyStatus,
              buttonLabel: config.buttonLabel,
              buttonColor: config.buttonColor
            } as MassCommsAppCommunicationsResponseItem;
          });

        SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION(appResponseMethods);
      }

      if (responseMethod.email) {
        const emailResponseMethods = responseMethod.email.configurations
          .map((config, index) => {
            return {
              id: index + 1,
              responseLabel: config.responseLabel,
              responseLabelColor: config?.responseLabelColor || genericColorPalette.neutral[7],
              safetyStatus: config.safetyStatus,
              buttonLabel: config.buttonLabel,
              buttonColor: config.buttonColor
            } as MassCommsEmailResponseItem;
          });

        SET_EMAIL_RESPONSE_METHOD_CONFIGURATION(emailResponseMethods);
      }

      if (responseMethod.sms) {
        const smsResponseMethods = responseMethod.sms.configurations
          .map((config, index) => {
            return {
              id: index + 1,
              responseLabel: config.responseLabel,
              responseLabelColor: config?.responseLabelColor || genericColorPalette.neutral[7],
              safetyStatus: config.safetyStatus,
              replyCode: config.replyCode
            } as MassCommsPhoneResponseItem;
          });

        SET_SMS_RESPONSE_METHOD_CONFIGURATION(smsResponseMethods);
      }

      if (responseMethod.voice) {
        const voiceResponseMethods = responseMethod.voice.configurations
          .map((config, index) => {
            return {
              id: index + 1,
              responseLabel: config.responseLabel,
              responseLabelColor: config?.responseLabelColor || genericColorPalette.neutral[7],
              safetyStatus: config.safetyStatus,
              replyCode: config.replyCode
            } as MassCommsPhoneResponseItem;
          });

        SET_VOICE_RESPONSE_METHOD_CONFIGURATION(voiceResponseMethods);
      }

      if (smsConfiguration) {
        SET_SMS_CONTENT({
          body: smsConfiguration.content,
          length: 100,
          units: 2
        });
      }

      if (voiceConfiguration) {
        SET_VOICE_MESSAGE_TYPE(voiceConfiguration.audioType);
        SET_VOICE_CONTENT(voiceConfiguration.audioContent);
      }
    },

    async getAllTemplates(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        }
      } = state;

      const templates: Array<CommunicationTemplate> = await getAllTemplates(tenantId);

      dispatch.massComms.SET_TEMPLATE_ITEMS(
        templates.map((template) => ({
          value: template.tid as string,
          label: template.templateName
        }))
      );
    },

    async deleteTemplate(templateId: string, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        }
      } = state;

      await deleteTemplate(tenantId, templateId);
    },
    async getEmailBodyContent(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        },
        massComms: {
          emailContent,
          emailResponseMethodConfigurations
        }
      } = state;

      const template = await getEmailStylingTemplate(tenantId);
      const stylingContent = await getEmailStylingContent(tenantId);
      // eslint-disable-next-line fp/no-let
      let templateCode = template.content;
      const headerConfig = { ...stylingContent.header };
      const footerConfig = { ...stylingContent.footer };
      Object.keys(headerConfig).forEach(headerKey => {
        if (headerPlaceHolders[headerKey as EmailConfigKeys]) {
          if (headerKey === 'padding') {
            templateCode = templateCode.replace(new RegExp(headerPlaceHolders.padding.top, 'g'), String(headerConfig.padding.top) + 'px');
            templateCode = templateCode.replace(new RegExp(headerPlaceHolders.padding.bottom, 'g'), String(headerConfig.padding.bottom) + 'px');
            templateCode = templateCode.replace(new RegExp(headerPlaceHolders.padding.left, 'g'), String(headerConfig.padding.left) + 'px');
            templateCode = templateCode.replace(new RegExp(headerPlaceHolders.padding.right, 'g'), String(headerConfig.padding.right) + 'px');
            return;
          }
          templateCode = templateCode.replace(new RegExp(headerPlaceHolders[headerKey as EmailConfigKeys], 'g'), headerConfig[headerKey as EmailConfigKeys]);
        }
      });
      Object.keys(footerConfig).forEach(footerKey => {
        if (footerPlaceHolders[footerKey as EmailConfigKeys]) {
          if (footerKey === 'padding') {
            templateCode = templateCode.replace(new RegExp(footerPlaceHolders.padding.top, 'g'), String(footerConfig.padding.top) + 'px');
            templateCode = templateCode.replace(new RegExp(footerPlaceHolders.padding.bottom, 'g'), String(footerConfig.padding.bottom) + 'px');
            templateCode = templateCode.replace(new RegExp(footerPlaceHolders.padding.left, 'g'), String(footerConfig.padding.left) + 'px');
            templateCode = templateCode.replace(new RegExp(footerPlaceHolders.padding.right, 'g'), String(footerConfig.padding.right) + 'px');
            return;
          }
          templateCode = templateCode.replace(new RegExp(footerPlaceHolders[footerKey as EmailConfigKeys], 'g'), footerConfig[footerKey as EmailConfigKeys]);

        }

      });
      const bodyPlaceholder = '{{BODY_HTML_CONTENT}}';
      const placeHolderIndex = templateCode.indexOf(bodyPlaceholder);
      if (placeHolderIndex) {
        const bodyPrimary = templateCode.slice(0, placeHolderIndex + bodyPlaceholder.length);
        const bodySecondary = templateCode.slice(placeHolderIndex + bodyPlaceholder.length);
        // eslint-disable-next-line fp/no-let
        let resMethods = '';
        emailResponseMethodConfigurations.forEach(method => resMethods = resMethods +
          `<td
                              style="cursor:pointer;width:151px;height:48px;padding:13px 0px;background-color:${method.buttonColor};font-family:arial,helvetica,sans-serif;"
                              align="center">
                              <div>
                                ${method.buttonLabel}
                              </div>
                            </td>`);
        templateCode = `${bodyPrimary}                      
                      <table id="body_html" class="body_html" style="font-family:arial,helvetica,sans-serif;border-spacing:8px;margin-top:24px;border-collapse:separate;width:100%"
                        role="presentation" cellpadding="0" cellspacing="0" width="100%" border="0">
                        <tbody>
                          <tr>
                      ${resMethods}
                          </tr>
                        </tbody>
                      </table>
        ${bodySecondary}`;
      }

      templateCode = templateCode.replace(new RegExp(bodyPlaceholder, 'g'), emailContent.body);
      templateCode = templateCode.replace(new RegExp('{{BODY_FONT_COLOR}}', 'g'), stylingContent.fontColor);
      templateCode = templateCode.replace(new RegExp('{{BODY_BG_COLOR}}', 'g'), stylingContent.bodyColor);
      templateCode = templateCode.replace(new RegExp('{{BODY_CONTENT_BG_COLOR}}', 'g'), stylingContent.contentBodyColor);
      dispatch.massComms.SET_EMAIL_CONTENT(
        { ...emailContent, bodyWithStyling: templateCode, fontColor: stylingContent.fontColor }
      );
    },
    async getImpactedPeople(_: void, state: RootState): Promise<ImpactedPeopleSet> {
      const {
        riskAlerts: {
          currentRiskAlert
        },
        massComms: {
          impactResponseItems
        }
      } = state;

      if (!currentRiskAlert) {
        return impactResponseItems;
      }

      const { source } = currentRiskAlert;
      const caseDetails: Array<ArcImpactDetail> = await getCaseDetailsById(`${ source }_${ currentRiskAlert.json.alert.id }`);

      const impactedPeople: ImpactedPeopleSet = {
        allImpacted: {
          isChecked: impactResponseItems.allImpacted.isChecked,
          people: []
        },
        okPeople: {
          isChecked: impactResponseItems.okPeople.isChecked,
          people: []
        },
        noResponse: {
          isChecked: impactResponseItems.noResponse.isChecked,
          people: []
        },
        notSafe: {
          isChecked: impactResponseItems.notSafe.isChecked,
          people: []
        },
        otherPeople: {
          isChecked: impactResponseItems.otherPeople.isChecked,
          people: []
        }
      };

      caseDetails.filter(person => person.personType !== undefined).forEach((person) => {
        if (person.profileStatus === 'SAFE') {
          impactedPeople.okPeople.people.push(person);
        } else if (person.profileStatus === 'NO_RESPONSE') {
          impactedPeople.noResponse.people.push(person);
        } else if (person.profileStatus === 'NOT_SAFE') {
          impactedPeople.notSafe.people.push(person);
        } else {
          impactedPeople.otherPeople.people.push(person);
        }
      });

      dispatch.massComms.SET_IMPACT_RESPONSE_ITEMS(impactedPeople);

      return impactedPeople;
    },

    async populateImpactedPeopleToSelectedRecipients(_: void, state: RootState): Promise<void> {
      const {
        massComms: {
          impactResponseItems
        }
      } = state;

      const selectedPersonIds: Array<string> = Object.values(impactResponseItems)
        .filter((item) => item.isChecked)
        .map((item) => item.people)
        .flat()
        .filter((person) => person.personId)
        .map((person) => person.personId as string);

      if (selectedPersonIds.length === 0) {
        dispatch.massComms.SET_SELECTED_PEOPLE([]);
        dispatch.massComms.calculateChannelCount();

        return;
      }

      const profiles = await getProfiles(
        '',
        0,
        9999,
        undefined,
        constructProfileSearchQueryByProfileId(selectedPersonIds)
      );

      const selectedRecipients: Array<PickedPeopleEntity> = profiles.items.map((profile) => {
        return {
          ...profile as PeopleEntity,
          reason: 'Impacted',
          isWithEmail: !!profile.email,
          isWithPhone: !!profile.phoneNumber,
          isWithApp: profile.appInstalled
        };
      });

      dispatch.massComms.SET_SELECTED_PEOPLE(selectedRecipients);
      dispatch.massComms.calculateChannelCount();
    },
    async populatePeoplesToRecipientListByLookupKey(lookupKey: string, _state: RootState): Promise<void> {
      try {
        const profiles = await getProfilesByLookupKey(lookupKey);

        const selectedRecipients: Array<PickedPeopleEntity> = profiles.items.map((profile) => {
          return {
            ...profile as PeopleEntity,
            reason: 'Impacted',
            isWithEmail: !!profile.email,
            isWithPhone: !!profile.phoneNumber,
            isWithApp: profile.appInstalled
          };
        });

        dispatch.massComms.SET_SELECTED_PEOPLE(selectedRecipients);
        dispatch.massComms.calculateChannelCount();
      } catch (e) {
        // TODO: Show notification
      }
    },
    async loadSentCommunications(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        }
      } = state;

      const commsList = await getSentCommunicationList(
        tenantId,
        { pageNum: 0, pageSize: 100 },
        `((responseMethod.type='NO_RESPONSE' OR responseMethod.type='SAFETY_POLL' OR responseMethod.type='CUSTOM_POLL' OR responseMethod.type='ACKNOWLEDGE') AND (source.type='manual' OR source.type='alert') AND communicationStatus='SEND' AND lastUpdatedTime is not null )`
      );

      const communcations = [...commsList];
      const manualComms = communcations.filter(comm => comm?.sourceType !== 'alert' || !comm?.sourceType || !comm?.sourceId);
      const arcComms = communcations.filter(comm => comm?.sourceType === 'alert' && comm?.sourceId);
      const groupedData = arcComms.reduce((grouped, obj) => {
        const nestedValue = obj?.sourceId;
        if (!grouped.has(nestedValue)) {
          grouped.set(nestedValue, []);
        }
        grouped.get(nestedValue).push(obj);
        return grouped;
      }, new Map());
      const groupedComms = Array.from(groupedData.values());
      groupedComms.forEach(comms => {
        if (comms.length === 1) {
          manualComms.push(comms[0]);
        } else {
          const groupedComm = comms[0];
          groupedComm.impactedComms = [];
          groupedComm.isExpanded = false;
          comms.forEach((item: SentCommsList, index: number) => {
            if (index !== 0) {
              groupedComm.impactedComms.push(item);
            }
          });
          manualComms.push(groupedComm);
        }
      });
      manualComms.sort((commA, commB) => {
        if (commA.sentDate > commB.sentDate) {
          return -1;
        }
        if (commA.sentDate < commB.sentDate) {
          return 1;
        }

        return 0;
      });
      dispatch.massComms.SET_SENT_COMMS_LIST(manualComms);
    },
    async expandCommsListRow(rowTid: string, state: RootState): Promise<void> {
      const {
        massComms: {
          sentCommunicationList
        }
      } = state;
      const commsList = [...sentCommunicationList];
      const foundComm = commsList.find(comm => comm.communicationTid === rowTid);
      if (foundComm) {
        foundComm.isExpanded = !foundComm.isExpanded;
      }
      dispatch.massComms.SET_SENT_COMMS_LIST(commsList);
    }
  })
};


export const massComms = createModel<RootModel>()(
  massCommsModel
);
