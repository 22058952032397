import { createStyles } from "@mantine/core";

export const useColorSwatchesPaletteStyles = createStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  header: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: 16
  },
  swatchesContainer: {
    display: 'grid',
    gap: 16,
    gridTemplateColumns: 'repeat(8, 1fr)'
  }
}));

