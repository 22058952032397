/* eslint-disable react/jsx-props-no-spreading */
import { FC, useContext } from "react";
import { useDispatch } from "react-redux";

import ModalControlGroup from "@/common/components/ModalControlGroup";
import { Dispatch } from "@/core/store";
import { ProfileDocumentsModalContext } from "@/tenant-context/control-profile/components/modals/ProfileDocumentsModal/ProfileDocumentsModal.context";
import SelectedProfileDocumentsTab from "@/tenant-context/control-profile/components/tabs/SelectedProfileDocumentsTab";
import ProfileDocumentsTabs from "@/tenant-context/control-profile/components/ui/ProfileDocumentsTabs";
import ProfileLoadingOverlay from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay';
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';

export type ProfileDocumentsModalFormData = unknown;

const ProfileDocumentsModal: FC = () => {
  const {
    onSubmit,
    currentUserId
  } = useContext(ProfileDocumentsModalContext);

  const {
    profile: {
      isLoggedInProfileId
    }
  } = useDispatch<Dispatch>();

  return (
    <form onSubmit={ onSubmit }>

      <ProfileLoadingOverlay />
      <ProfileDocumentsTabs />
      <SelectedProfileDocumentsTab />

      <ModalControlGroup requiredPoliciesToSubmit={ isLoggedInProfileId(currentUserId) ? [] 
        : ProfileRequiredPoliciesConfig.EDIT_DOCUMENTS }/>
    </form>
  );
};

export default ProfileDocumentsModal;