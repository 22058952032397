import { Box, createStyles, Flex, SelectItemProps, Stack, Text } from '@mantine/core';
import { FC, useMemo } from "react";

import CountryFlag from '@/tenant-context/common/components/CountryFlag';

export interface ItemProps extends SelectItemProps {
  city: string;
  country: string;
  countryIsoCode: string;
}

const CitySelectItem : FC<ItemProps>  = (
  ({ city, country, countryIsoCode, ...others }: ItemProps) => {

    const { classes } = createStyles(() => ({
      container: {
        padding: '8px 24px',
        height: 44
      },
      flag: {
        borderRadius: 2,
        overflow: 'hidden',
        height: 16,
        width: 24,
        'svg': {
          margin: 0,
          width: 'unset !important'
        }
      }
    }))();

    const isCity = useMemo(() => city !== '_', [city]);

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Flex gap={ 12 } align="center" className={ classes.container } { ...others }>
        <Box className={ classes.flag }>
          <CountryFlag
            countryCode={ countryIsoCode || '' }
          />
        </Box>
        <Stack spacing={ 0 }>
          <Text size={ 14 } weight={ 700 }>{ isCity ? city : country }</Text>
          { isCity && <Text size={ 8 }>{ country }</Text> }
        </Stack>
      </Flex>
    );
  }
);

export default CitySelectItem ;
