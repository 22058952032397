import { ICellRendererParams } from "ag-grid-community";
import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import TruncatedTextWithTooltip from "@/common/components/TruncatedTextWithTooltip";
import { DataGrid } from "@/common/types/ag-grid";
import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState } from "@/core/store";
import UpProcessesLocationsListComponent from "@/tenant-context/control-location-configuration/components/LocationList/UnProcessedLocationsList/UnProcessedLocationsList.component";

const UnProcessedLocationsList = () => {
  const navigate = useNavigate();
  const {
    manageLocations: {
      getBulkLocationUploadStatusGrid,
      downloadBulkLocationUploadStatusCSV
    }
  } = useDispatch<Dispatch>();

  const ref = useRef<DataGrid>(null);

  const { id } = useParams();

  const status = useSelector(
    (state: RootState) => state.manageLocations?.uploadedLocationsStatusGrid
  );
  const isLoading = useSelector(
    (state: RootState) => state.manageLocations?.isLoading
  );

  const columnDefs = [
    {
      headerName: "Location Name",
      sortable: true,
      field: "locationName",
      comparator: () => 0,
      autoHeight: true,
      minWidth: 300,
      cellRenderer: (params: ICellRendererParams) => {
        return <TruncatedTextWithTooltip width={ 'auto' } maxLength={ (params.colDef?.minWidth || 300) / 9 } fullText={ params.value } />;
      }
    },
    {
      headerName: "error Message",
      sortable: true,
      field: "errorMessage",
      autoHeight: true,
      comparator: () => 0,
      minWidth: 300,
      cellRenderer: (params: ICellRendererParams) => {
        return <TruncatedTextWithTooltip width={ 'auto' } maxLength={ (params.colDef?.minWidth || 300) / 9 } fullText={ params.value } />;
      }
    },
    {
      headerName: "description",
      sortable: true,
      field: "description",
      comparator: () => 0,
      autoHeight: true,
      minWidth: 300,
      cellRenderer: (params: ICellRendererParams) => {
        return <TruncatedTextWithTooltip width={ 'auto' } maxLength={ (params.colDef?.minWidth || 300) / 9 } fullText={ params.value } />;
      }
    },
    {
      headerName: "category",
      sortable: true,
      field: "category",
      comparator: () => 0,
      minWidth: 150,
      cellRenderer: (params: ICellRendererParams) => {
        return <TruncatedTextWithTooltip width={ 'auto' } maxLength={ (params.colDef?.minWidth || 150) / 9 } fullText={ params.value } />;
      }
    },
    {
      headerName: "latitude",
      sortable: true,
      field: "latitude",
      comparator: () => 0,
      minWidth: 120
    },
    {
      headerName: "longitude",
      sortable: true,
      field: "longitude",
      comparator: () => 0,
      minWidth: 120
    },
    {
      headerName: "address Line 1",
      sortable: true,
      field: "addressLine1",
      autoHeight: true,
      comparator: () => 0,
      minWidth: 300,
      cellRenderer: (params: ICellRendererParams) => {
        return <TruncatedTextWithTooltip width={ 'auto' } maxLength={ (params.colDef?.minWidth || 300) / 9 } fullText={ params.value } />;
      }
    },
    {
      headerName: "address Line 2",
      sortable: true,
      field: "addressLine2",
      comparator: () => 0,
      autoHeight: true,
      minWidth: 300,
      cellRenderer: (params: ICellRendererParams) => {
        return <TruncatedTextWithTooltip width={ 'auto' } maxLength={ (params.colDef?.minWidth || 300) / 9 } fullText={ params.value } />;
      }
    }
  ];

  const handleGoBackLocationList = useCallback(() => {
    return navigate(RouterConfig.routes.locationConfiguration.locationsList);
  }, [navigate]);

  const handleDownloadErrorReport = useCallback(() => {
    return downloadBulkLocationUploadStatusCSV();
  }, [downloadBulkLocationUploadStatusCSV]);

  return (
    <UpProcessesLocationsListComponent
      columnDefs={ columnDefs }
      getData={ getBulkLocationUploadStatusGrid }
      ref={ ref }
      handleGoBackLocationList={ handleGoBackLocationList }
      handleDownloadErrorReport={ handleDownloadErrorReport }
      status={ status }
      isLoading={ isLoading }
      id={ id }
    />
  );
};

export default UnProcessedLocationsList;
