import { TextInput } from "@mantine/core";
import { FC } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import PhoneInputWithCountrySelect from "react-phone-number-input";

import { usePhoneInputStyles } from "@/common/components/PhoneInput/PhoneInput.style";

export type Props = {
  value: string,
  onChange: (value: string) => void,
  label: string,
  error?: string,
  placeholder: string,
  registerData?: UseFormRegisterReturn
  isRequired?: boolean
  isDisabled?: boolean,
  size?: 'sm' | 'md' | 'lg'
}

const PhoneInput: FC<Props> = ({
  value,
  onChange,
  label,
  error,
  registerData,
  isRequired,
  placeholder,
  isDisabled = false,
  size = 'lg'
}) => {
  const { classes, cx } = usePhoneInputStyles({ size });

  return (
    <div className={ classes.root }>
      <label
        className={ classes.label }
      >
        { label + (isRequired ? ' *' : '') }
      </label>

      <PhoneInputWithCountrySelect
        className={ cx({
          [classes.phoneInput]: true,
          [classes.phoneInputFilled]: !!value,
          [classes.phoneInputError]: !!error
        }) }
        placeholder={ placeholder }
        defaultCountry='GB'
        value={ value }
        international
        onChange={ onChange }
        addInternationalOption={ false }
        disabled={ isDisabled }
      />

      <TextInput
        className={ classes.pseudoInput }
        value={ value || '' }
        label={ label }
        error={ error }
        size={ size }
        disabled={ isDisabled }
        // eslint-disable-next-line react/jsx-props-no-spreading
        { ...registerData }
      />
    </div>
  );
};

export default PhoneInput;
