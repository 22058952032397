import { useMantineTheme } from "@mantine/core";
import { FeatureCollection, LineString } from "geojson";
import { FC } from "react";
import { Layer, Source } from 'react-map-gl';

type Props = {
    lineToPopup: FeatureCollection<LineString>,
  };


const PopupLine: FC<Props> = ({ lineToPopup }) => {
  const theme = useMantineTheme();

  return (
    <Source
      id="r__risk_alert_popup_lines"
      type="geojson"
      data={ lineToPopup }
    >
      <Layer
        source='r__risk_alert_popup_lines'
        type="line"
        id="r__risk_alert_popup_lines"
        layout={ {
          visibility: 'visible'
        } }
        paint={ {
          'line-color': theme.white,
          'line-width': 1
        } }
      />
    </Source>
  );
};

export default PopupLine;
