import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { ArcImpactStatus } from "@/tenant-context/control-action-response-center/types/ARC.types";

type Props = {
  status: ArcImpactStatus,
  isSelected: boolean,
  isSomethingElseSelected: boolean
}

export const useImpactSummaryCardStyles = createStyles((theme: MantineTheme, props: Props) => ({
  statusCard: {
    all: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: props.isSelected ? 140 : 120,
    height: props.isSelected ? 140 : 120,
    boxShadow: theme.other.sdw.left,
    borderBottom: `3px solid ${ getCardBorderColor(props.status, theme) }`,
    backgroundColor: theme.colors.primary[4],
    opacity: props.isSomethingElseSelected ? 0.3 : 1,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.neutral[8],
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    position: "relative"
  },
  count: {
    fontSize: props.isSelected ? 40 : 32,
    fontWeight: theme.other.fw.bold,
    lineHeight: theme.other.lh.l,
    color: theme.white
  },
  text: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.neutral[5],
    lineHeight: theme.other.lh.l
  },
  eyeIcon: {
    position: "absolute",
    top: 4,
    right: 0,
    svg: {
      width: 30
    }
  }
}));

const getCardBorderColor = (status: ArcImpactStatus, theme: MantineTheme): string => {
  const statusColorMap: Record<ArcImpactStatus, string> = {
    'NOT_SAFE': theme.colors.risk[4],
    'NO_RESPONSE': theme.colors.risk[2],
    'SAFE': theme.colors.risk[1],
    'OTHER': theme.colors.neutral[4]
  };

  return statusColorMap[status];
};
