import { createModel } from '@rematch/core';

import { logger } from '@/common/util/ConsoleLogger';
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootModel, RootState } from '@/core/store';
import {
  getLookupSearchCSVData,
  getMetaDataForCharts,
  lookupSearch
} from '@/tenant-context/control-lookup-tool/api/lookup-tool';
import {
  LocationContext,
  LookupMetaDataCharts,
  LookupSearchRequestEntity, LookupToolBackupState,
  SearchResultEntity
} from '@/tenant-context/control-lookup-tool/types/Lookup.types';
import { downloadAsFile } from '@/tenant-context/control-lookup-tool/utils/DownloadFile';
import { handleLocationTypeDisplay } from '@/tenant-context/control-lookup-tool/utils/LocationType';
import {
  convertToChartsData,
  getCurrentDateTimeSuffixForFileNames
} from '@/tenant-context/control-lookup-tool/utils/LookupTool';
import { PaginatedResult } from '@/tenant-context/control-profile/types/profile';
import { ReverseGeoData } from '@/tenant-context/core/types/reverse-geocoding';

type LookupToolState = {
  searchResult: SearchResultEntity[];
  metaDataForCharts?: LookupMetaDataCharts;
  geoLocationCache: Map<string, ReverseGeoData>
  lookupSearchRequest?: LookupSearchRequestEntity
  totalItems: number;
  lookupToolBackupState?: LookupToolBackupState
}

const initialState: LookupToolState = {
  searchResult: [],
  geoLocationCache: new Map(),
  totalItems: 0
};

const LookupToolModel = {
  name: 'lookupTool',
  state: initialState,
  reducers: {
    SET_SEARCH_RESULT(state: LookupToolState, payload: SearchResultEntity[]) {
      return {
        ...state,
        searchResult: payload
      };
    },
    REMOVE_FROM_SEARCH_RESULT_BY_ID(state: LookupToolState, payload: string) {
      return {
        ...state,
        searchResult: state.searchResult.filter((item) => item.id !== payload)
      };
    },
    RESET_LOOKUP_TOOL_STATE(state: LookupToolState) {
      return {
        ...initialState,
        geoLocationCache: state.geoLocationCache
      };
    },
    SET_LOOKUP_KEY(state: LookupToolState, payload: string | undefined): LookupToolState {
      return {
        ...state,
        lookupSearchRequest: {
          ...state.lookupSearchRequest,
          lookUpKey: payload
        } as LookupSearchRequestEntity
      };
    },
    SET_META_DATA_FOR_CHARTS(state: LookupToolState, payload?: LookupMetaDataCharts) {
      return {
        ...state,
        metaDataForCharts: payload
      };
    },
    SET_GEOLOCATION_CACHE(state: LookupToolState, payload: LookupMetaDataCharts) {
      return {
        ...state,
        metaDataForCharts: payload
      };
    },
    SET_LOOKUP_SEARCH_REQUEST(state: LookupToolState, payload: LookupSearchRequestEntity) {
      return {
        ...state,
        lookupSearchRequest: payload
      };
    },
    SET_TOTAL_ITEMS(state: LookupToolState, payload: number) {
      return {
        ...state,
        totalItems: payload
      };
    },
    SET_LOOKUP_TOOL_BACKUP_STATE(state: LookupToolState, payload?: LookupToolBackupState) {
      return {
        ...state,
        lookupToolBackupState: payload
      };
    }
  },
  effects: (dispatch: Dispatch) => ({

    async getSearchResultForPage(
      payload: { gridParams: { page: number, size: number } },
      state: RootState
    ): Promise<PaginatedResult<SearchResultEntity>> {
      try {

        const {
          lookupTool: {
            lookupSearchRequest
          }
        } = state;

        if (!lookupSearchRequest) {
          return {} as PaginatedResult<SearchResultEntity>;
        }

        const response = await lookupSearch(
          { ...lookupSearchRequest },
          payload?.gridParams?.page,
          payload?.gridParams?.size
        );

        const mappedSearchResult = response.items.map((item) => {
          return {
            id: item?.personId,
            name: `${item?.personFirstName} ${item?.personLastName}`,
            locationDataType: item?.topRank?.adapterSource ? handleLocationTypeDisplay(item?.topRank?.locationContextEnum as LocationContext) : '',
            probability: item?.topRank?.probability ? Math.round(item?.topRank?.probability * 1000) / 10 : 0,
            dataAge: item?.topRank?.startTime,
            location: item?.topRank?.location.locationName || 'N/A',
            geoCoordinates: item?.topRank?.location?.point || undefined
          } as SearchResultEntity;
        });

        dispatch.lookupTool.SET_SEARCH_RESULT(mappedSearchResult);
        dispatch.lookupTool.SET_LOOKUP_KEY(response.lookUpKey);
        dispatch.lookupTool.SET_META_DATA_FOR_CHARTS(undefined);
        dispatch.lookupTool.SET_TOTAL_ITEMS(response.totalItems);

        return {
          items: mappedSearchResult,
          currentPage: response.currentPage,
          totalItems: response.totalItems,
          totalPages: response.totalPages
        };

      } catch (error) {
        logger.error('Couldn\'t load lookup search results', error);
        showNotification({
          title: 'Error',
          message: 'Couldn\'t load lookup search results',
          color: 'error'
        });
      }

      return {} as PaginatedResult<SearchResultEntity>;
    },

    async loadMetaDataForCharts(lookupKey?: string): Promise<void> {

      if (!lookupKey) {
        showNotification({
          color: 'error',
          message: 'Couldn\'t process the request'
        });

        return undefined;
      }

      try {
        const metadata = await getMetaDataForCharts(lookupKey);
        dispatch.lookupTool.SET_META_DATA_FOR_CHARTS(convertToChartsData(metadata));
      } catch (e) {
        if (e instanceof Error){
          showNotification({
            message: e?.message ?? 'An error occurred while loading metadata',
            color: 'error'
          });
        }
      }
    },

    async downloadCsvSearchResultFile(lookupKey?: string): Promise<void> {

      if (!lookupKey) {
        showNotification({
          color: 'error',
          message: 'Couldn\'t process the request'
        });

        return undefined;
      }

      try {
        const CSVData = await getLookupSearchCSVData(lookupKey);
        downloadAsFile(CSVData, `lookup-search-result-${ getCurrentDateTimeSuffixForFileNames() }.csv`);
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            message: e?.message ?? 'An error occurred while downloading file',
            color: 'error'
          });
        }
      }

    }
  })
};

export const lookupTool = createModel<RootModel>()(LookupToolModel);
