import { createModel } from "@rematch/core";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { getMiniProfileDetails, getReverseGeoLocation } from "@/tenant-context/control-profile/api/mini-profile";
import { MiniProfileDatails, ReverseGeoData, ReverseGeoLocation } from "@/tenant-context/control-profile/types/mini-profile";

type GlobalPeople = {
  peopleMiniProfiles: Map<string, MiniProfileDatails>,
  peopleGeoLocations: Map<string, ReverseGeoData>,
  geoLocationCache: Map<string, ReverseGeoData>
}
const globalPeopleModel = {
  name: 'globalPeople',
  state: {
    peopleMiniProfiles: new Map(),
    peopleGeoLocations: new Map(),
    geoLocationCache: new Map()
  } as GlobalPeople,

  reducers: {
    SET_ACCESSED_MINI_PROFILES: (state: GlobalPeople, miniProfiles: Map<string, MiniProfileDatails>) => ({
      ...state,
      peopleMiniProfiles: miniProfiles
    }),
    SET_ACCESSED_GEO_ADDRESSES: (state: GlobalPeople, geoLocations: Map<string, ReverseGeoData>) => ({
      ...state,
      peopleGeoLocations: geoLocations
    }),
    SET_GEOLOCATION_CACHE: (state: GlobalPeople, geoLocationCache: Map<string, ReverseGeoData>) => ({
      ...state,
      geoLocationCache: geoLocationCache
    })
  },
  effects: (dispatch: Dispatch) => ({
    async getMiniProfileById(profileId: string, state: RootState): Promise<MiniProfileDatails> {
      const {
        globalPeople: {
          peopleMiniProfiles
        }
      } = state;

      if (peopleMiniProfiles.has(profileId)) {
        return peopleMiniProfiles.get(profileId) as MiniProfileDatails;
      } else {
        const getProfile = await getMiniProfileDetails(profileId);
        const profilesList = new Map(peopleMiniProfiles);
        profilesList.set(profileId, getProfile);
        dispatch.globalPeople.SET_ACCESSED_MINI_PROFILES(profilesList);
        return getProfile as MiniProfileDatails;
      }
    },
    async invalidatePersonInfo(profileId: string, state: RootState): Promise<void> {
      const {
        globalPeople: {
          peopleMiniProfiles
        }
      } = state;

      if (peopleMiniProfiles.has(profileId)) {
        const profilesList = new Map(peopleMiniProfiles);
        profilesList.delete(profileId);
        dispatch.globalPeople.SET_ACCESSED_MINI_PROFILES(profilesList);
      } 
    },
    async getReverseGeoLocation(
      locationDetails: { profileId: string, lon: number, lat: number }, 
      state: RootState
    ): Promise<ReverseGeoLocation> {
      const {
        globalPeople: {
          peopleGeoLocations,
          geoLocationCache
        }
      } = state;

      const foundProfile = peopleGeoLocations.get(locationDetails.profileId);
      const geoLocationCacheItem = geoLocationCache.get(`${locationDetails.lon},${locationDetails.lat}`);

      if (foundProfile && foundProfile.lat === locationDetails.lat && foundProfile.lon === locationDetails.lon) {
        return { place_name: foundProfile.place_name };
      } else if (geoLocationCacheItem) {
        return { place_name: geoLocationCacheItem.place_name };
      } else {
        const personLocation = await getReverseGeoLocation(
          locationDetails.lon.toString(),
          locationDetails.lat.toString()
        );

        if (locationDetails.profileId !== '') {
          const locationList = new Map(peopleGeoLocations);
          locationList.set(locationDetails.profileId, {
            place_name: personLocation.place_name,
            lon: locationDetails.lon,
            lat: locationDetails.lat
          });
          dispatch.globalPeople.SET_ACCESSED_GEO_ADDRESSES(locationList);
        }

        const newGeoLocationCache = new Map(geoLocationCache);
        newGeoLocationCache.set(`${locationDetails.lon},${locationDetails.lat}`, {
          place_name: personLocation.place_name,
          lat: locationDetails.lat,
          lon: locationDetails.lon
        });
        dispatch.globalPeople.SET_GEOLOCATION_CACHE(newGeoLocationCache);

        return personLocation;
      }
    }
  })
};

export const globalPeople = createModel<RootModel>()(globalPeopleModel);
