import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";


type Props = {
  fontColor?: string
}

export const useSummaryPageStyles = createStyles((theme: MantineTheme, { fontColor }:Props) => ({
  parentContainer: {
    padding: '24px 24px 48px 24px',
    width: '100%',
    height: 'fit-content'
  },

  innerContainer: {
    display: 'flex',
    paddingTop: '48px'
  },

  tabContainer: {
    flex: 1,
    maxWidth: '50%'
  },

  graphContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    maxHeight: 600
  },

  graph: {
    display: 'flex',
    alignItems: 'center',
    flex: 25,
    height: '100%',
    width: '100%',
    minHeight: 600
  },

  countContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  countContainerAppPreview: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  appCommsSettings: {
    display: 'flex',
    gap: '32px',
    alignItems: 'center'
  },

  countTextContainer: {
    display: 'flex',
    marginRight: '12px',
    alignItems: 'center',
    svg: {
      width: '20px',
      height: '24px'
    }
  },

  countTextContainerAppPreview: {
    display: 'flex',
    gap: '0 12px',
    alignItems: 'center',
    svg: {
      width: '20px',
      height: '24px'
    }
  },

  hideDisplay: {
    display: 'none'
  },

  responseHeaderContainer: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },

  reponseTypeHeader: {
    color: theme.other.semantic.accessibility.outer
  },

  countText: {
    display: 'flex',
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fw.bold,
    marginLeft: '8px',
    color: theme.colors.neutral[6],
    'div:first-child': {
      color: theme.other.semantic.accessibility.outer
    },
    svg: {
      width: '20px',
      height: '24px'
    }
  },

  configItem: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.md,
    width: '100%',
    input: {
      height: '48px'
    },
    'div:first-child': {
      marginBottom: '7px'
    }
  },

  standardMarginLeft: {
    marginLeft: '24px'
  },

  configItemsContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '24px'
  },

  startIcon: {
    width: '14px',
    height: '24px',
    path: {
      fill: theme.colors.neutral[6]
    }
  },

  endIcon: {
    width: '20px',
    height: '24px'
  },

  contentText: {
    fontWeight: theme.other.fw.bold,
    fontSize: theme.fontSizes.md,
    marginTop: '48px',
    paddingBottom: '7px'
  },

  messageBodyContainer: {
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    padding: '24px'
  },

  messageText: {
    pointerEvents: 'none',
    overflowWrap: 'break-word'
  },

  messageBodyLight: {
    backgroundColor: theme.white
  },

  smsSpecSection: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '7px'
  },

  extendedMargin: {
    marginTop: '48px'
  },

  smsContentTitle: {
    fontWeight: theme.other.fw.bold
  },

  smsTextArea: {
    marginTop: '7px',
    textarea: {
      height: '252px',
      backgroundColor: theme.colors.neutral[9]
    }
  },

  specHeader: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.other.lh.l
  },

  specDesc: {
    marginLeft: '5px'
  },

  standardMarginTop: {
    marginTop: '24px'
  },

  smsSpecs: {
    display: 'flex',
    alignItems: 'center'
  },
  specItem: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '29px',
    fontSize: theme.fontSizes.sm
  },

  attachmentItem: {
    maxWidth: '200px',
    height: 'auto',
    marginRight: '10px',
    marginTop: '10px',
    backgroundColor: theme.colors.neutral[6],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  attachmentFileForAppComms: {
    width: 183,
    height: 170,
    backgroundColor: theme.colors.neutral[7],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  attachmentItemForAppComms: {
    width: 183,
    height: 170,
    backgroundColor: theme.colors.neutral[7],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'cover'
  },

  otherAttachmentItem: {
    textAlign: 'center',
    maxWidth: '100%'
  },

  fileIconFileName: {
    padding: '0 16px',
    textWrap: 'wrap',
    overflowWrap: 'break-word'
  },

  sectionHeader: {
    fontWeight: theme.other.fw.semibold,
    fontSize: theme.headings.sizes.h6.fontSize,
    lineHeight: theme.other.lh.s,
    flex: 1
  },

  attachmentContainer: {
    display: 'flex',
    alignItems: 'center'
  },

  attachmentContainerForAppComms: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 100,
    gap: 22
  },

  responseConfigRowContainer: {
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    padding: '0 16px'
  },

  configRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 2fr',
    margin: '16px auto',
    alignItems: 'center',
    color: theme.colors.neutral[5]
  },

  statusBox: {
    width: '150px',
    backgroundColor: 'red',
    justifyContent: 'center',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSizes.sm,
    color: theme.white
  },
  itemTextWrapper: {
    display: 'flex'
  },

  itemTextItem: {
    fontWeight: theme.other.fw.bold,
    color: theme.white,
    marginLeft: '7px'
  },
  bodyFont: {
    color: fontColor || theme.colors.neutral[9]
  },
  summaryTabContainer: {
    button: {
      '&[data-active]': {
        backgroundColor: theme.colors.neutral[8] + '!important'
      }
    }
  }
}));
