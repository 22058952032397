import { MapboxMap, MapRef } from "react-map-gl";

import hexagonRedExclamation from "@/common/icons/hexagon-red-exclamation.png";
import hexagonYellowExclamation from "@/common/icons/hexagon-yellow-exclamation.png";
import personBreadcrumbPercentageMarker from "@/common/icons/markers/People/Breadcrumb/Primary/percent-marker.png";
import personMarkerImageYellow from "@/common/icons/markers/People/Normal/Caution/Marker.png";
import personMarkerImageRed from "@/common/icons/markers/People/Normal/Critical/Marker.png";
import peopleClusterImage from "@/common/icons/markers/People/Normal/Primary/Cluster.png";
import personMarkerImage from "@/common/icons/markers/People/Normal/Primary/Marker.png";
import personHighlightEllipse from "@/common/icons/markers/People/Travel/ellipse-person.png";
import personWalkingLuggage from "@/common/icons/markers/People/Travel/person-walking-luggage.png";
import roundBlueTask from "@/common/icons/round-blue-task.png";
import roundGreenTick from "@/common/icons/round-green-tick.png";
import { MapIcon } from "@/common/types/icon";
import useMapIcons from "@/tenant-context/common/hooks/useMapIcons";

const peopleIcons: MapIcon[] = [
  // Misc
  {
    name: 'people-cluster',
    image: peopleClusterImage
  },
  {
    name: 'person-breadcrumb-percentage-marker',
    image: personBreadcrumbPercentageMarker
  },
  {
    name: 'people-travel-cluster',
    image: personWalkingLuggage
  },

  // Markers
  {
    name: 'person-marker',
    image: personMarkerImage
  },
  {
    name: 'person-marker-caution-yellow',
    image: personMarkerImageYellow
  },
  {
    name: 'person-marker-caution-red',
    image: personMarkerImageRed
  },

  // Modifiers
  {
    name: 'person-modified-on-task',
    image: roundBlueTask
  },
  {
    name: 'person-modified-checked-in',
    image: roundGreenTick
  },
  {
    name: 'person-modified-red-alert',
    image: hexagonRedExclamation
  },
  {
    name: 'person-modified-yellow-alert',
    image: hexagonYellowExclamation
  },
  {
    name: 'person-modified-bottom-highlight',
    image: personHighlightEllipse
  }
];

const usePeopleIcons = (
  map: MapboxMap | MapRef | undefined,
  isOverrideIcons = false
) => useMapIcons(peopleIcons, map,isOverrideIcons);

export default usePeopleIcons;
