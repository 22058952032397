/* eslint-disable no-magic-numbers */
import { TooltipInPortalProps } from "@visx/tooltip/lib/hooks/useTooltipInPortal";
import { FC } from "react";

import {
  useRiskTimelineGraphStyles
} from "@/tenant-context/control-risk-timeline/components/RiskTimelineGraph/RiskTimelineGraph.styles";
import { RiskTimelineTooltipData } from "@/tenant-context/control-risk-timeline/types/risk-timeline.types";

type Props = {
  TooltipInPortal: FC<TooltipInPortalProps>,
  tooltipTop: number | undefined,
  tooltipLeft: number | undefined,
  tooltipData: RiskTimelineTooltipData
}

export const RiskTimelineTooltip: FC<Props> = ({
  TooltipInPortal,
  tooltipTop,
  tooltipLeft,
  tooltipData
}) => {

  const { classes } = useRiskTimelineGraphStyles();

  return (
    <TooltipInPortal
      top={ tooltipTop }
      left={ tooltipLeft }
      unstyled
      applyPositionStyle
      className={ classes.tooltip }
    >
      <div>
        <div>
          <strong>{ tooltipData.json.alert.risk_level.name } risk</strong>
        </div>
        <div>{ tooltipData.json.alert.title }</div>
      </div>
    </TooltipInPortal>
  );
};
