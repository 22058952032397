import { InputStylesParams, MantineTheme } from "@mantine/core";

import { CSS_KEY } from "../constants";


const getIconSize = (size: string): number => {
  const iconSizeMap: Record<string, number> = {
    xs: 12,
    sm: 12,
    md: 16,
    lg: 24,
    xl: 24
  };

  return iconSizeMap[size];
};

export const CalendarStyle = {
  defaultProps: {
    size: 'lg'
  },
  styles: (theme: MantineTheme, params: InputStylesParams) => ({
    weekday: {
      color: theme.colors.neutral[5]
    },
    wrapper: {
      [`& .${CSS_KEY}-Input-icon.${CSS_KEY}-DatePicker-icon`]: {
        '& svg': {
          width: getIconSize(params.size)
        }
      }
    }
  })
};