import { ActionIcon, Divider, TextInput, Tooltip } from '@mantine/core';
import { FC, ReactElement, useMemo } from "react";

import PersonAvatar from '@/common/components/PersonAvatar/PersonAvatar.component';
import { ReactComponent as SearchIcon } from "@/common/icons/search-light.svg";
import { ReactComponent as AddIcon } from "@/common/icons/Tools/add.svg";
import { ReactComponent as CircleIcon } from "@/common/icons/Tools/circle.svg";
import { ReactComponent as CopyIcon } from "@/common/icons/Tools/copy.svg";
import { ReactComponent as PolygonIcon } from "@/common/icons/Tools/polygon.svg";
import { ReactComponent as ResetIcon } from "@/common/icons/Tools/reset.svg";
import { ReactComponent as RouteIcon } from "@/common/icons/Tools/route.svg";
import { ReactComponent as ShareIcon } from "@/common/icons/Tools/share.svg";
import { ReactComponent as SquareIcon } from "@/common/icons/Tools/square.svg";
import { useCreateGeofenceToolBarStyles } from '@/tenant-context/control-draw/components/CreateGeofenceToolBar/CreateGeofenceToolBar.styles';
import { DrawingTypes } from '@/tenant-context/control-draw/types/draw';
import LiveFeedButton from "@/tenant-context/control-live-feed/components/LiveFeedButton";

type Props = {
  selectGeofenceTool: (value: DrawingTypes) => void;
  selectedTool: DrawingTypes;
  userName: string;
  isMinified?: boolean;
  selectedTools?: DrawingTypes[];
  disabledTools?: DrawingTypes[];
  isWithLiveFeedButton?: boolean;
};

type Tool = {
  icon: ReactElement;
  toolTip: string;
  action: () => void;
  drawingType: DrawingTypes;
}

const CreateGeofenceToolBarComponent: FC<Props> = ({
  selectGeofenceTool,
  selectedTool,
  userName,
  isMinified,
  selectedTools,
  disabledTools,
  isWithLiveFeedButton = false
}) => {
  const { classes } = useCreateGeofenceToolBarStyles(isMinified || false);

  const geofenceDrawTools: Tool[] = useMemo<Tool[]>(() => {
    const allTools = [
      {
        icon: <RouteIcon/>,
        toolTip: 'Route Tool',
        action: () => {
          selectGeofenceTool(DrawingTypes.LINE);
        },
        drawingType: DrawingTypes.LINE
      },
      {
        icon: <PolygonIcon/>,
        toolTip: 'Polygon Tool',
        action: () => {
          selectGeofenceTool(DrawingTypes.POLYGON);
        },
        drawingType: DrawingTypes.POLYGON
      },
      {
        icon: <CircleIcon/>,
        toolTip: 'Circle Tool',
        action: () => {
          selectGeofenceTool(DrawingTypes.DRAG_CIRCLE);
        },
        drawingType: DrawingTypes.DRAG_CIRCLE
      },
      {
        icon: <SquareIcon/>,
        toolTip: 'Square Tool',
        action: () => {
          selectGeofenceTool(DrawingTypes.SQUARE);
        },
        drawingType: DrawingTypes.SQUARE
      },
      {
        icon: <ResetIcon/>,
        toolTip: 'Reset',
        action: () => {
          selectGeofenceTool(DrawingTypes.DELETE);
        },
        drawingType: DrawingTypes.DELETE
      }
    ];

    return selectedTools ? allTools
      .filter(tool => selectedTools.includes(tool.drawingType)) : allTools;
  }, [ selectGeofenceTool, selectedTools ]);

  return (
    <div className={ classes.toolbarContainer }>
      <div className={ classes.toolbarComponentContainer }>
        { false && <TextInput
          className={ classes.inputItem }
          placeholder="Search for POI"
          icon={ <SearchIcon/> }
          hidden
        /> }

        { geofenceDrawTools.map((tool, index) => (
          <Tooltip label={ tool.toolTip } key={ index }>
            <ActionIcon
              size="lg"
              className={ selectedTool === tool.drawingType ? classes.toolbarIconSelected : classes.toolbarIcon }
              disabled={ disabledTools?.includes(tool.drawingType) || false }
              onClick={ tool.action }
            >
              { tool.icon }
            </ActionIcon>
          </Tooltip>
        )) }

      </div>

      <div className={ classes.toolbarComponentContainer } hidden>
        <ActionIcon size="lg" className={ classes.toolbarIcon }>
          <CopyIcon/>
        </ActionIcon>
        <ActionIcon size="lg" className={ classes.toolbarIcon }>
          <ShareIcon/>
        </ActionIcon>
        <ActionIcon size="lg" className={ classes.toolbarIcon }>
          <AddIcon/>
        </ActionIcon>

        <Divider orientation="vertical" />

        <PersonAvatar
          width="35px"
          height="35px"
          borderRadius="100%"
          personName={ userName }
        />
      </div>

      { isWithLiveFeedButton && <div className={ classes.toolbarComponentContainer }>
        <LiveFeedButton/>
      </div> }
    </div>
  );
};

export default CreateGeofenceToolBarComponent;
