import { Box, Checkbox, Divider, RangeSlider, Switch } from "@mantine/core";
import React, { FC, useCallback } from "react";

import { ReactComponent as SliderIcon } from '@/common/icons/rangeSliderIcon.svg';
import { ReactComponent as RiskInfoIcon } from "@/common/icons/riskInfoIcon.svg";
import { ReactComponent as RiskLine } from "@/common/icons/riskline.svg";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import {
  RiskLineEventsSettingsStyles
} from "@/tenant-context/control-risk-config/components/RiskLineEventsSettings/RiskLineEventsSettings.styles";

import { useRiskLineEventCategories } from "./RiskLineEventsSettings.hooks";

type Props = {
  onCategoryToggle: React.ChangeEventHandler<HTMLInputElement>
  onChangeRangeFilter: (val: number []) => void
  disabledRiskFilterTypes: string[],
  onChangeGlobalCategoryToggle: React.ChangeEventHandler<HTMLInputElement>,
  isGlobalCategoryToggleOn:boolean
  onImpactRiskEventsToggleChange: React.ChangeEventHandler<HTMLInputElement>,
  riskLevelSliderLowLimit:number,
  riskLevelSliderUpperLimit:number,
  isShowOnlyImpactedAlerts:boolean
}

const marks = [
  { value: 0, label: '1', description:'Low Risk' },
  { value: 25, label: '2', description:'Moderate' },
  { value: 50, label: '3', description:'Medium' },
  { value: 75, label: '4', description:'High' },
  { value: 100, label: '5', description:'Extreme' }
];

const RiskLineEventsSettings: FC<Props> = ({
  onCategoryToggle,
  onChangeRangeFilter,
  disabledRiskFilterTypes,
  onChangeGlobalCategoryToggle,
  isGlobalCategoryToggleOn,
  onImpactRiskEventsToggleChange,
  riskLevelSliderLowLimit,
  riskLevelSliderUpperLimit,
  isShowOnlyImpactedAlerts
}) => {
  const riskCategories = useRiskLineEventCategories();

  const getRiskLevelRangeSliderLabel = useCallback(
    (value: number) => {
      return marks.find((one) => one.value === value)?.description;
    },
    []
  );

  const { classes } = RiskLineEventsSettingsStyles();

  return (
    <div className={ classes.riskEventSettingsContainer }>
      <div className={ classes.riskLineRiskAlertSwitchContent }>
        <div className={ classes.riskLineRiskAlertIconNDescription }>
          <RiskLine className={ classes.risklineIcon }/>
          <p className={ classes.riskLineRiskAlertDescription }>RiskLine Risk Alerts</p>
        </div>

        <Switch onChange = { onChangeGlobalCategoryToggle }
          checked={ isGlobalCategoryToggleOn } />
      </div>

      <h1 className={ classes.sectionHeading }>Filter by Risk Category</h1>

      <ul className={ classes.orderList }>
        { riskCategories.map(({
          Icon, id, name,categoryId
        }) => (
          <li
            key={ id }
            className={ classes.listContent }
          >
            <div className={ classes.riskLineRiskAlertIconNDescription }>
              <Icon color={ genericColorPalette.neutral[6] }/>
              <p className={ classes.riskCategoryType }>{ name }</p>
            </div>

            <Switch
              id={ `risk-category-switch-${id}` }
              checked = { !disabledRiskFilterTypes.includes(categoryId.toString()) }
              onChange={ onCategoryToggle } value={ id }/>
          </li>
        )) }
      </ul>

      <Divider
        className={ classes.divider }
        // eslint-disable-next-line react/jsx-no-bind
        sx={ (theme) => ({
          // eslint-disable-next-line no-magic-numbers
          borderTopColor: theme.colors.neutral[6]
        }) }
      />

      <div className={ classes.riskLineRiskAlertSubTopic }>Filter by Risk Rating Level</div>

      <Box mx={ 32 } my={ 64 } className={ classes.settingBlock }>
        <RangeSlider
          className={ classes.riskLevelRangeSlider }
          marks={ marks }
          labelAlwaysOn
          defaultValue = { [riskLevelSliderLowLimit, riskLevelSliderUpperLimit] }
          onChangeEnd={ onChangeRangeFilter }
          size="xs"
          step={ 25 }
          showLabelOnHover={ false }
          label={ getRiskLevelRangeSliderLabel }
          thumbChildren={ [<SliderIcon  key="1" />, <SliderIcon key="2" />] }
        />
      </Box>

      <Checkbox
        checked={ isShowOnlyImpactedAlerts }
        className={ classes.impactCalculationChkBox }
        onChange={ onImpactRiskEventsToggleChange }
        label={  <span className={ classes.checkBoxLabel }>
          <RiskInfoIcon/>
          Only show Risk Events impacting my assets and people</span> }
      />
      { /*
      <Divider
        className={ classes.divider }
        // eslint-disable-next-line react/jsx-no-bind
        sx={ (theme) => ({
          // eslint-disable-next-line no-magic-numbers
          borderTopColor: theme.colors.neutral[6]
        }) }
      />

      <Button fullWidth className={ classes.showLiveFeedBtn }>
        Show Live Feed
      </Button> */ }
    </div>
  );
};

export default RiskLineEventsSettings;
