import { FeatureCollection, Point } from "geojson";
import { FC } from "react";
import Map, { NavigationControl } from 'react-map-gl';

import ControlContainer from "@/common/controls/ControlContainer";
import { Products } from "@/common/types/products";
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import { WORLD_MAX_BOUNDS } from "@/tenant-context/common/util/constants";
import ArcSection from "@/tenant-context/control-action-response-center/components/ArcSection";
import { ReactComponent as MaximizeIcon } from '@/tenant-context/control-action-response-center/icons/maximize-icon.svg';
import { ArcCaseEvent, ArcImpactDetail, ArcPersonImpactDetail } from "@/tenant-context/control-action-response-center/types/ARC.types";
import AuthenticatedMap from "@/tenant-context/core/components/AuthenticatedMap/AuthenticatedMap.component";
import NavigateToLocationControl from "@/tenant-context/core/controls/NavigateToLocationControl";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import RiskImpactPopup from "@/tenant-context/visualisation-risk-details/components/RiskImpactPopup";
import RiskImpactLayer from "@/tenant-context/visualisation-risk-details/layers/RiskImpactLayer/RiskImpactLayer.container";
import { ImpactPosition } from "@/tenant-context/visualisation-risk-details/types/risk-impact";

import ImpactAssets from "./impactAssets";
import ImpactPeopleContainer from "./impactPeople";
import { useImpactZoneStyles } from "./ImpactZone.styles";
import ImpactZoneLayer from "./impactZoneLayer/impactZoneLayer.component";

type Props = {
  arcCaseEvent?:ArcCaseEvent,
  riskAlertsGeoData?: FeatureCollection<Point, RiskAlertEvent>,
  impactPeopleGeoData?: FeatureCollection<Point, ArcPersonImpactDetail>,
  impactAssetsGeoData?: FeatureCollection<Point, ArcImpactDetail>,
  riskAlert?: RiskAlertEvent
};

const {
  REACT_APP_MAPBOX_ACCESS_TOKEN
} = process.env as {
  REACT_APP_MAPBOX_ACCESS_TOKEN?: string
};

const ImpactZone: FC<Props> = ({
  arcCaseEvent,
  riskAlertsGeoData,
  impactPeopleGeoData,
  impactAssetsGeoData,
  riskAlert
}) => {
  const { classes } = useImpactZoneStyles();

  return (
    <ArcSection>
      <div className={ classes.impactZoneContainer }>
        <div className={ classes.impactZoneHeader }>
          <div className={ classes.impactZoneTxt }> IMPACT ZONE </div>
          <MaximizeIcon />
        </div>
        <LocalErrorBoundary>
          <AuthenticatedMap requiredProduct={ Products.CORE_SERVICE }>
            <Map
              id="impactZoneMap"
              mapboxAccessToken={ REACT_APP_MAPBOX_ACCESS_TOKEN }
              minZoom={ 2 }
              initialViewState={ {
                latitude: 30,
                longitude: 30,
                zoom: 5
              } }
              mapStyle='mapbox://styles/smehmood/cl4feh10w000114pyksvp6mu9'
              reuseMaps
              testMode
              renderWorldCopies={ false }
              maxBounds={ WORLD_MAX_BOUNDS }
              projection='mercator'
              fog={ {} }
            >
              <RiskImpactLayer
                impactPositionData={ arcCaseEvent?.geometry as ImpactPosition }
                impactRadius={ arcCaseEvent?.calculation.appliedTo }
                isImpactZoneModeEnabled={ true }
              />
              <RiskImpactPopup
                arcCaseEvent={ arcCaseEvent }
              />
              <ImpactZoneLayer
                riskAlertsGeoData={ riskAlertsGeoData }
              />

              { impactAssetsGeoData &&
              <ImpactAssets assetsGeodata={ impactAssetsGeoData } /> }

              { impactPeopleGeoData && <ImpactPeopleContainer
                peopleGeodata={ impactPeopleGeoData }
              /> }

              <ControlContainer
                className={ classes.controlContainer }
                position="top-left"
                containerId={ 'iz-top-left-control-container' }
                layout="vertical"
              >
                { riskAlert &&
                <div className={ classes.dateRange }>
                  Between { new Date(riskAlert?.json.alert.start_date).toLocaleDateString().replaceAll('/', '-') }
                  &nbsp;and { new Date(riskAlert?.json.alert.end_date).toLocaleDateString().replaceAll('/', '-') }
                </div> }
              </ControlContainer>

              <ControlContainer
                position="bottom-right"
                containerId={ 'iz-bottom-right-control-container' }
                className="mapbox-ctrl-bottom-right-control-container"
                layout="vertical"
              >
                <NavigateToLocationControl
                  lat={ arcCaseEvent?.geometry.lat }
                  lon={ arcCaseEvent?.geometry.lon }
                  radius={ arcCaseEvent?.calculation.appliedTo }
                />
              </ControlContainer>

              <NavigationControl
                position="bottom-right"
                showCompass={ false }
                style={ { order: 5 } }
              />
            </Map>
          </AuthenticatedMap>
        </LocalErrorBoundary>
      </div>
    </ArcSection>
  );
};

export default ImpactZone;
