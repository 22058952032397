import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState } from "@/core/store";

import BSOCEventsSettingsComponent from "./BSOCEventsSettings.component";

const BSOCEventsSettingsContainer: FC = () => {
  const riskLevelFilters = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.riskLevelBSOCFilters
  );

  const {
    riskEventFilterDataOptions: {
      ENABLE_BSOC_LEVEL_FILTER,
      DISABLE_BSOC_LEVEL_FILTER
    }
  } = useDispatch<Dispatch>();

  const handleRiskLevelToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value: riskLevel } = event.currentTarget;

      if (checked) {
        ENABLE_BSOC_LEVEL_FILTER(riskLevel);
      } else {
        DISABLE_BSOC_LEVEL_FILTER(riskLevel);
      }
    },
    [ENABLE_BSOC_LEVEL_FILTER, DISABLE_BSOC_LEVEL_FILTER]
  );

  const isBsocAnalyticsEnabled = useSelector((state: RootState) => state.tableauDashboard.isAnalyticsEnabled);
  const handleOpenAnalytics = useCallback(() => {
    window.open(RouterConfig.routes.tableauDashboard, '_blank');
  }, []);

  return (
    <BSOCEventsSettingsComponent
      riskLevelFilters={ riskLevelFilters }
      onRiskLevelToggle={ handleRiskLevelToggle }
      onOpenAnalytics={ handleOpenAnalytics }
      isBsocAnalyticsEnabled={ isBsocAnalyticsEnabled }
    />
  );
};

export default BSOCEventsSettingsContainer;
