import { createStyles } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";


export const useARCStyles = createStyles(() => ({
  arcContainer: {
    display: 'flex',
    height: '100vh'
  },

  arc: {
    backgroundColor: genericColorPalette.neutral[9],
    width: '100%',
    alignSelf: 'stretch',
    minHeight: '100%',
    overflowY: 'auto'
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: '4fr 3fr',
    margin: 12,
    gap: 12
  },
  notificationContainer: {
    height: '120px',
    padding: '24px 24px 0 0',
    position: 'relative',
    zIndex: 10
  }
}));
