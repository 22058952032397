import { ColDef, ICellRendererParams } from "ag-grid-community";
import { ReactNode } from "react";

import { AccessCard } from "@/tenant-context/control-profile/types/profile";

export const columnDefs = (actionIconsCellRenderer: (accessCard: AccessCard) => ReactNode): ColDef[] => [

  {
    headerName: 'Manufacture Id',
    sortable: true,
    field: 'manufactureId',
    comparator: () => 0
  },
  {
    headerName: 'Secondary Id',
    sortable: true,
    field: 'secondaryId',
    comparator: () => 0
  },
  {
    headerName: 'Access Card Type',
    sortable: true,
    field: 'accessCardType',
    comparator: () => 0
  },
  {
    headerName: 'Friendly Name',
    sortable: true,
    field: 'friendlyName',
    comparator: () => 0
  },
  {
    headerName: 'Status',
    field: 'status',
    sortable: true,
    comparator: () => 0
  },
  {
    headerName: 'Provider',
    field: 'provider',
    sortable: true,
    comparator: () => 0
  },
  {
    headerName: 'Service Type',
    field: 'serviceType',
    sortable: true,
    comparator: () => 0
  },
  {
    field: 'actionIcons',
    headerName: 'Action',
    sortable: false,
    autoHeight: true,
    cellRenderer: (params: ICellRendererParams): ReactNode => actionIconsCellRenderer(params.data),
    flex: 0.5,
    cellStyle: { justifyContent: 'flex-end' }
  }
]
;
