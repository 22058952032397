export const TEST_IDS = {
  INPUT: 'input',
  SELECT: 'select',
  BUTTON: 'btn',
  ERROR: 'error',
  LOADER: 'loader',
  SUBMIT: 'submit',
  CANCEL: 'cancel',
  RECORD: 'record',
  SEARCH: 'search',
  SORT: 'sort',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  COLORPICKER: "colorPicker",
  SWITCH: "switch"
} as const;
  
  type TestIdType = keyof typeof TEST_IDS;

export const constructTestId = (type: TestIdType, context: string, identifier?: string) => {
  return identifier ? `${TEST_IDS[type]}-${context}-${identifier}` : `${TEST_IDS[type]}-${context}`;
};