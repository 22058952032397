import { closeAllModals, openModal } from "@mantine/modals";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { FieldValues } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import { ServerDataListRef } from "@/common/components/ServerDataList/ServerDataList.container";
import { Dispatch, RootState } from "@/core/store";
import {
  CaseTypeDetailsContext
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/CaseTypeDetails.context";
import ChecklistComponent
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/Checklist/Checklist.component";
import ChecklistRowTemplate
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/ChecklistRowTemplate";
import CreateChecklistItemModal
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/CreateChecklistItemModal";
import PlaybookSelectModal
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/PlaybookSelectModal";
import {
  ChecklistItem, CopyItemsFromPlaybookItem, CreateChecklistItemParams, ItemProps
} from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

const Checklist = () => {

  const  {
    caseTypes: {
      getChecklistListData,
      deleteChecklistItem,
      SET_ACTIVE_CHECKLIST_ITEM,
      createChecklistItem,
      updateChecklistItem,
      copyItemsFromPlaybook
    },
    playbooks : {
      getPlaybooksData
    }
  } = useDispatch<Dispatch>();

  const { id } = useParams();

  const { isAuthorizedToCreateOrEdit } = useContext(CaseTypeDetailsContext);

  const ref = useRef<ServerDataListRef>(null);

  const playbooks = useSelector((state: RootState) => state.playbooks?.playbooksData);

  const playbookSelectOptions: ItemProps[] = useMemo(() => {

    return (playbooks?.items || []).map((playbook) => {
      return {
        value: playbook._id,
        label: playbook.name,
        description: playbook.description
      };
    });
  }, [playbooks?.items]);

  const cancelCreateModalHandler = useCallback(() => {
    closeAllModals();
  },[]);

  const onConfirmDeleteHandler = useCallback(async (checklistId: string) => {
    if (!checklistId || !id) {
      return;
    }

    await deleteChecklistItem({
      data: { itemTid: checklistId },
      caseTypeId: id || ''
    });

    ref.current?.refreshList();

  }, [deleteChecklistItem, id]);

  const deleteTChecklistItemHandler = useCallback(
    (checklistId = '') => {
      openConfirmationModal({
        text: "Are you sure you want to remove this checklist item?",
        title: "Confirm Removal",
        onConfirm: () => onConfirmDeleteHandler(checklistId)
      });
    },
    [onConfirmDeleteHandler]
  );

  const createChecklistItemHandler = useCallback(async (data: FieldValues) => {
    if(!id) {
      return;
    }

    const res = await createChecklistItem({
      data: {
        ...data,
        itemName: data?.itemName.trim()
      } as CreateChecklistItemParams,
      caseTypeId: id
    });

    if(res) {
      closeAllModals();
      ref.current?.refreshList();
    }

  },[createChecklistItem, id]);

  const updateChecklistItemHandler = useCallback(async (data: FieldValues) => {
    if(!id || !data?._id) {
      return;
    }

    const res = await updateChecklistItem({
      data: {
        ...data,
        itemName: data?.itemName.trim()
      } as CreateChecklistItemParams,
      caseTypeId: id,
      checklistId: data._id as string
    });

    if(res) {
      closeAllModals();
      ref.current?.refreshList();
    }

  },[id, updateChecklistItem]);

  const handleSubmitAddPlaybookItem = useCallback(async (data: FieldValues) => {
    const preparedData: CopyItemsFromPlaybookItem[] =  data?.playbookItemsList?.map((playbookId: string) => {
      const playbookItem = playbookSelectOptions?.find((option) => option.value === playbookId);
      return {
        itemName: playbookItem?.label,
        description: playbookItem?.description,
        resetWhenUserAssigned: false
      };
    });

    const res = await copyItemsFromPlaybook({
      data: { playbookItemsList: preparedData || [] },
      caseTypeId: id || ''
    });

    if(res) {
      closeAllModals();
      ref.current?.refreshList();
    }
  },[copyItemsFromPlaybook, id, playbookSelectOptions]);

  const handleCreateCheckListItem = useCallback(() => {
    SET_ACTIVE_CHECKLIST_ITEM(undefined);
    openModal({
      title: 'Create Item',
      children: (
        <CreateChecklistItemModal
          isAllowedToUpdate={ isAuthorizedToCreateOrEdit }
          cancelButtonHandler={ cancelCreateModalHandler }
          createHandler={ createChecklistItemHandler }
          updateHandler={ updateChecklistItemHandler }
          activeChecklistItem={ undefined }
        />
      ),
      closeOnClickOutside: false,
      withCloseButton: true
    });
  },[
    SET_ACTIVE_CHECKLIST_ITEM,
    cancelCreateModalHandler,
    createChecklistItemHandler,
    isAuthorizedToCreateOrEdit,
    updateChecklistItemHandler
  ]);

  const handleAddPlaybookItem = useCallback(() => {
    openModal({
      title: 'Select Playbook',
      children: (
        <PlaybookSelectModal
          cancelButtonHandler={ cancelCreateModalHandler }
          onSubmit={ handleSubmitAddPlaybookItem }
          playbookSelectOptions={ playbookSelectOptions }
        />
      ),
      closeOnClickOutside: false,
      withCloseButton: true
    });
  },[cancelCreateModalHandler, handleSubmitAddPlaybookItem, playbookSelectOptions]);

  const onRowClicked = useCallback((row: ChecklistItem) => {
    openModal({
      title: 'Edit Item',
      children: (
        <CreateChecklistItemModal
          isAllowedToUpdate={ isAuthorizedToCreateOrEdit }
          cancelButtonHandler={ cancelCreateModalHandler }
          createHandler={ createChecklistItemHandler }
          updateHandler={ updateChecklistItemHandler }
          activeChecklistItem={ row }
        />
      ),
      closeOnClickOutside: false,
      withCloseButton: true
    });
  },[cancelCreateModalHandler, createChecklistItemHandler, isAuthorizedToCreateOrEdit, updateChecklistItemHandler]);

  const renderRowTemplate = useCallback((row: ChecklistItem) => {
    return (
      <ChecklistRowTemplate
        row={ row }
        isAuthorizedToCreateOrEdit={ isAuthorizedToCreateOrEdit }
        onDeleteIconClicked={ deleteTChecklistItemHandler }
        onEditIconClicked={ onRowClicked }
      />
    );
  },[deleteTChecklistItemHandler, isAuthorizedToCreateOrEdit, onRowClicked]);

  useEffect(() => {
    getPlaybooksData({
      gridParams: {
        page: 0,
        size: 10000
      }
    });
  }, [getPlaybooksData]);

  return (
    <ChecklistComponent
      isAuthorizedToCreateOrEdit={ isAuthorizedToCreateOrEdit }
      handleAddPlaybookItem={ handleAddPlaybookItem }
      getData={ getChecklistListData }
      renderRowTemplate={ renderRowTemplate }
      handleCreateCheckListItem={ handleCreateCheckListItem }
      caseTypeId={ id || '' }
      ref={ ref }
    />
  );
};

export default Checklist;