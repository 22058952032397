import { ParentSize } from "@visx/responsive";
import { FC } from "react";

import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import ArcSection from "@/tenant-context/control-action-response-center/components/ArcSection";
import ImpactedAssetCard
  from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactedAssetCard/ImpactedAssetCard.component";
import ImpactedSummaryPieChart
  from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactedSummaryPieChart/ImpactedSummaryPieChart.component";
import { useImpactSummaryStyles } from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactSummary.styles";
import ImpactSummaryCard
  from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactSummaryCard/ImpactSummaryCard.component";
import { ArcImpactStatus, ArcPeopleImpactSummary } from "@/tenant-context/control-action-response-center/types/ARC.types";

type Props = {
  impactSummary: ArcPeopleImpactSummary;
  impactedSites: Array<{ siteName: string, siteAddress: string }>
  startDate: string;
  endDate: string;
  isOpenInImpactTab: boolean;
  selectedImpactStatus: ArcImpactStatus | undefined;
  onImpactStatusCardClicked: (status: ArcImpactStatus) => void;
}

const ImpactSummaryComponent: FC<Props> = ({
  impactSummary,
  impactedSites,
  startDate,
  endDate,
  isOpenInImpactTab,
  selectedImpactStatus,
  onImpactStatusCardClicked
}) => {

  const { classes } = useImpactSummaryStyles({ isOpenInImpactTab });

  return (
    <ArcSection>
      <LocalErrorBoundary>
        { !isOpenInImpactTab && (
          <>
            <h3 className={ classes.sectionHeading }>Impact Summary</h3>
            <div className={ classes.sectionSubHeading }>
              Between <span className="emphasis">{ startDate }</span> and <span className="emphasis">{ endDate }</span>
            </div>
          </>
        ) }

        <div className={ classes.sectionWrapper }>
          { /* People Section */ }
          <div>
            { /* People Impacted Heading */ }
            { !isOpenInImpactTab && ( <div className={ classes.subSectionHeading }>People Impacted</div>) }

            { isOpenInImpactTab && (
              <>
                <h3 className={ classes.sectionHeading }>People Impacted</h3>
                <div className={ classes.sectionSubHeading }>
                  Between <span className="emphasis">{ startDate }</span> and <span className="emphasis">{ endDate }</span>
                </div>
              </>
            ) }

            <div className={ classes.impactedPeopleWrapper }>
              <div className={ classes.pieChart }>
                <ParentSize>
                  { ({ width, height }) =>
                    <ImpactedSummaryPieChart
                      summaryData={ impactSummary }
                      width={ width }
                      height={ height }
                      isAnimated={ true }
                    /> }
                </ParentSize>
              </div>
              { impactSummary.map(({ count, impactStatus }, index) => (
                <ImpactSummaryCard
                  key={ index }
                  count={ count }
                  status={ impactStatus }
                  isSelected={ selectedImpactStatus === impactStatus }
                  isSomethingElseSelected={ selectedImpactStatus !== undefined &&
                    selectedImpactStatus !== impactStatus }
                  onClick={ onImpactStatusCardClicked }
                  isAllSelected={ selectedImpactStatus === undefined }
                />
              )) }
            </div>
          </div>

          { /* Sites Section */ }
          <div>
            { impactedSites.length > 0 && (
              <>
                { /* People Impacted Heading */ }
                { !isOpenInImpactTab && ( <div className={ classes.subSectionHeadingAssets }>Assets Impacted</div>) }

                { isOpenInImpactTab && (
                  <>
                    <h3 className={ classes.sectionHeading }>Assets Impacted</h3>
                    <div className={ classes.sectionSubHeading }>
                      Between <span className="emphasis">{ startDate }</span> and <span className="emphasis">{ endDate }</span>
                    </div>
                  </>
                ) }

                <div className={ classes.siteWrapper }>
                  { impactedSites.map(({ siteName, siteAddress }, index) => (
                    <ImpactedAssetCard
                      key={ index }
                      siteName={ siteName }
                      siteAddress={ siteAddress }
                    />
                  )) }
                </div>
              </>) }
          </div>
        </div>
      </LocalErrorBoundary>
    </ArcSection>
  );
};

export default ImpactSummaryComponent;
