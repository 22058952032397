/* eslint-disable react/jsx-no-bind */
import { Box, LoadingOverlay, Select, TextInput, Tooltip } from "@mantine/core";
import React, { useCallback } from "react";

import PaginationBlock from "@/common/components/PaginationBlock";
import { SortingConfig } from "@/common/components/ServerDataList/ServerDataList.container";
import { useServerDataListStyles } from "@/common/components/ServerDataList/ServerDataList.styles";
import Spinner from "@/common/components/Spinner";
import { ReactComponent as CloseIcon } from '@/common/icons/close.svg';
import { ReactComponent as SearchIcon } from '@/common/icons/search.svg';
import { constructTestId } from "@/core/util/test-id";

export type RowClickedEvent<T> = {
  data: T,
  rowIndex: number,
  event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined
}

type Props<T> = {
  id: string,
  data: Array<T>,
  onRowClicked?: (event: RowClickedEvent<T>) => void,
  showingFrom: number
  showingTo: number
  totalRecords: number
  activePage: number
  setActivePage: (value: number) => void
  totalPages: number
  boundaries?: number
  itemsPerPage: string
  setItemsPerPage: (value: string) => void,
  isResponsivePagination?: boolean,
  search: boolean,
  headerContent?: React.ReactNode | (() => React.ReactNode),
  handleSearchPhraseChange: (value: string) => void;
  loading: boolean,
  searchInputRef: React.RefObject<HTMLInputElement>,
  searchInputPosition?: 'left' | 'right',
  searchTooltipText?: string,
  sortingConfigs?: SortingConfig[],
  alignFilters?: 'flex-start' | 'flex-end',
  wrapFilters?: boolean,
  getRowTemplate: (row: T, index: number) => React.ReactNode,
  onSortChanged: (field: string, dir: 'asc' | 'desc' | null) => void,
  searchPlaceholder: string
}

export const ServerDataListComponent = <T, >({
  id,
  data,
  onRowClicked,
  showingFrom,
  showingTo,
  totalRecords,
  activePage,
  setActivePage,
  totalPages,
  boundaries,
  itemsPerPage,
  setItemsPerPage,
  isResponsivePagination,
  search,
  headerContent,
  handleSearchPhraseChange,
  loading,
  searchInputRef,
  searchInputPosition,
  searchTooltipText,
  sortingConfigs,
  alignFilters,
  wrapFilters,
  getRowTemplate,
  onSortChanged: onSortChangedCallback,
  searchPlaceholder
}: Props<T>) => {

  const { classes } = useServerDataListStyles(
    {
      hasData: (data?.length || 0) > 0,
      searchInputPosition: searchInputPosition,
      alignFilters,
      wrapFilters
    }
  );

  const onSortChanged = useCallback((value: string) => {
    if (!value || !value.includes('-')) {
      return;
    }

    const [field, dir] = value.split('-');
    onSortChangedCallback(field, dir as 'asc' | 'desc' | null);
  }, [onSortChangedCallback]);

  const sortingDropdown = useCallback(() => {
    if (!sortingConfigs || sortingConfigs.length === 0) {
      return null;
    }
    const options = [{
      value: `NONE-ASC`,
      label: `None`
    }].concat(sortingConfigs.map((config) => {
      return ([{
        value: `${config.field}-ASC`,
        label: `${config.label}-Ascending`
      }, {
        value: `${config.field}-DESC`,
        label: `${config.label}-Descending`
      }]);
    }).flat());

    return (
      <Select
        className={ classes.sortingDropdown }
        data={ options }
        placeholder="Sort by"
        size="lg"
        clearable
        searchable
        onChange={ onSortChanged }
        data-testid={ constructTestId("SELECT", id, "sort") }
      />
    );
  }, [classes.sortingDropdown, id, onSortChanged, sortingConfigs]);

  const handleClear = useCallback(() => {
    if (searchInputRef && searchInputRef.current) {
      // eslint-disable-next-line no-param-reassign
      searchInputRef.current.value = "";
    }
    handleSearchPhraseChange("");
  }, [handleSearchPhraseChange, searchInputRef]);

  return (
    <Box id={ id }>
      <Box className={ classes.gridHeader }>
        { search && <Box className={ classes.searchWrap }>
          <Tooltip disabled={ !searchTooltipText } withinPortal label={ searchTooltipText || '' }>
            <TextInput
              ref={ searchInputRef }
              className={ classes.searchInput }
              icon={ <SearchIcon/> }
              size="lg"
              placeholder={ searchPlaceholder }
              // eslint-disable-next-line react/jsx-no-bind
              onChange={ (evt) => handleSearchPhraseChange(evt.target.value) }
              // eslint-disable-next-line react/jsx-no-bind
              rightSection={ <CloseIcon className={ classes.clearSearch } onClick={ handleClear }
              /> }
              data-testid={ constructTestId("INPUT", id, "search") }
            /></Tooltip>
        </Box> }

        <Box display="flex" className={ classes.headerEnd }>
          { sortingDropdown() }

          { headerContent && <div
            className={ search ? classes.headerWrap : classes.flex }>{ typeof headerContent === 'function' ? headerContent() : headerContent }</div> }
        </Box>
      </Box>

      <Box sx={ { position: "relative", minHeight: 200 } }>
        <LoadingOverlay
          visible={ loading }
          overlayBlur={ 2 }
          loader={ <div className={ classes.loader }>
            <Spinner/>
            <div>Loading data...</div>
          </div> }
        />

        <Box display="flex" sx={ { flexDirection: 'column', gap: 16 } }>
          { data?.map((row, index) =>
            <Box
              key={ index }
              className={ classes.row }
              onClick={ (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => onRowClicked?.({
                data: row,
                rowIndex: index,
                event: e
              }) }
            >
              { getRowTemplate(row, index) }
            </Box>) }
        </Box>
      </Box>

      <PaginationBlock
        totalRecords={ totalRecords }
        itemsPerPage={ itemsPerPage }
        setItemsPerPage={ setItemsPerPage }
        showingFrom={ showingFrom }
        showingTo={ showingTo }
        activePage={ activePage }
        boundaries={ boundaries }
        setActivePage={ setActivePage }
        totalPages={ totalPages }
        isResponsive={ isResponsivePagination }
      />
    </Box>
  );
};
