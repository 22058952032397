import { FC } from "react";

import { ReactComponent as CarHireIcon } from "@/common/icons/car-hire-icon.svg";
import { ReactComponent as FlightIcon } from "@/common/icons/flight-icon.svg";
import { ReactComponent as HotelIcon } from "@/common/icons/hotel-icon.svg";
import { ReactComponent as JourneyIcon } from "@/common/icons/route-icon.svg";
import { ReactComponent as TaxiIcon } from "@/common/icons/taxi-icon.svg";
import { ReactComponent as RailIcon } from "@/common/icons/train-subway-icon.svg";


type Props = {
  iconName: string;
  className?: string;
}

export const TravelIcon: FC<Props> = ({ iconName, className }) => {

  switch (iconName) {
  case 'AIR':
    return <FlightIcon className={ className } />;
  case 'HOTEL':
    return <HotelIcon className={ className } />;
  case 'CAR':
    return <CarHireIcon className={ className } />;
  case 'FLIGHT':
    return <FlightIcon className={ className } />;
  case 'ACCOMMODATION':
    return <HotelIcon className={ className } />;
  case 'JOURNEY':
    return <JourneyIcon className={ className } />;
  case 'TAXI':
    return <TaxiIcon className={ className } />;
  case 'RAIL':
    return <RailIcon className={ className } />;
  default:
    return null;
  }
};
