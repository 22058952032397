import { FC, ReactNode } from "react";

import { useGenericProfileTabStyles } from "@/tenant-context/control-profile/components/ui/GenericProfileTab/GenericProfileTab.style";

type Props = {
  heading: string,
  renderAction?: () => ReactNode,
  className?: string
}

const GenericProfileTab: FC<Props> = ({
  children,
  heading,
  renderAction,
  className
}) => {
  const {
    classes
  } = useGenericProfileTabStyles();

  return (
    <div className={ classes.block }>
      <div className={ classes.heading }>
        <h4>{ heading }</h4>

        { renderAction?.() }
      </div>

      <div className={ className }>
        { children }
      </div>
    </div>
  );
};

export default GenericProfileTab;