import { Tabs } from "@mantine/core";
import { FC, useContext } from "react";

import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import AlertInsightTab from '@/tenant-context/employee-app-action-response-center/components/tabs/AlertInsightTab';
import ProfileTab from "@/tenant-context/employee-app-action-response-center/components/tabs/ProfileTab";
import SummaryTab from "@/tenant-context/employee-app-action-response-center/components/tabs/SummaryTab";
import { useEaArcStyles } from "@/tenant-context/employee-app-action-response-center/components/ui/EaArc/EaArc.style";
import EaArcNavigation from "@/tenant-context/employee-app-action-response-center/components/ui/EaArcNavigation/EaArcNavigation.component";
import { EaArcContext } from "@/tenant-context/employee-app-action-response-center/context/EaArc.context";
import { EaArcTab } from "@/tenant-context/employee-app-action-response-center/types/ea-arc";

const EaArc: FC = () => {
  const { classes } = useEaArcStyles();
  const {
    setActiveTab,
    caseOwner
  } = useContext(EaArcContext);

  return (
    <div className={ classes.root }>
      <LocalErrorBoundary>
        <Tabs
          onTabChange={ setActiveTab }
          defaultValue={ EaArcTab.Summary }
          className={ classes.panelRoot }
        >
          <EaArcNavigation caseOwner={ caseOwner } />

          <Tabs.Panel value={ EaArcTab.Summary } className={ classes.panel }>
            <SummaryTab />
          </Tabs.Panel>

          <Tabs.Panel value={ EaArcTab.Profile } className={ classes.panel }>
            <LocalErrorBoundary>
              <ProfileTab />
            </LocalErrorBoundary>
          </Tabs.Panel>

          <Tabs.Panel value={ EaArcTab.AlertInsight } className={ classes.panel }>
            <LocalErrorBoundary>
              <AlertInsightTab />
            </LocalErrorBoundary>
          </Tabs.Panel>
        </Tabs>
      </LocalErrorBoundary>
    </div>
  );
};

export default EaArc;
