import { InputStylesParams, MantineTheme } from "@mantine/core";

import { CSS_KEY, primitiveNumbers } from "../constants";

const getPadding = (size: string, theme: MantineTheme): number => {
  const paddingMap: Record<string, number> = {
    xs: theme.other.spacing.sm,
    sm: theme.other.spacing.sm,
    md: theme.other.spacing.sm,
    lg: theme.other.spacing.md,
    xl: theme.other.spacing.md
  };

  return paddingMap[size];
};

const getFontSize = (theme: MantineTheme, size: string): number => {
  const fontSizeMap: Record<string, number> = {
    xs: theme.fontSizes.xs,
    sm: theme.fontSizes.xs,
    md: theme.fontSizes.sm,
    lg: theme.fontSizes.md,
    xl: theme.fontSizes.md
  };

  return fontSizeMap[size];
};

const getMinHeight = (size: string): number => {
  const minHeightMap: Record<string, number> = {
    xs: primitiveNumbers['3x'],
    sm: primitiveNumbers['3x'],
    md: primitiveNumbers['4x'],
    lg: primitiveNumbers['6x'],
    xl: primitiveNumbers['6x']
  };

  return minHeightMap[size];
};

const getTextAreaMinHeight = (size: string): number => {
  const minHeightMap: Record<string, number> = {
    xs: primitiveNumbers['9x'],
    sm: primitiveNumbers['9x'],
    md: primitiveNumbers['12x'],
    lg: 144,
    xl: 144
  };

  return minHeightMap[size];
};

const getIconSize = (size: string): number => {
  const iconSizeMap: Record<string, number> = {
    xs: 12,
    sm: 12,
    md: primitiveNumbers['2x'],
    lg: primitiveNumbers['3x'],
    xl: primitiveNumbers['3x']
  };

  return iconSizeMap[size];
};

const getPaddingRight = (size: string, withRightSection: boolean, theme: MantineTheme): number => {
  if (withRightSection) {
    return (getPadding(size, theme) * 2) + getIconSize(size);
  }

  return getPadding(size, theme);
};

export const TextareaStyle = {
  defaultProps: {
    size: 'lg'
  },
  styles: (theme: MantineTheme, params: InputStylesParams) => ({
    root: {
      marginBottom: 24
    },
    invalid: {
      border: `1px solid ${theme.colors.neutral[5]}`
    },
    input: {
      paddingLeft: `${getPadding(params.size, theme)}px`,
      paddingRight: `${getPaddingRight(params.size, params.withRightSection, theme)}px`,
      fontSize: getFontSize(theme, params.size),
      height: "auto",
      minHeight: getMinHeight(params.size),
      color: theme.other.semantic.text.secondary,
      backgroundColor: 'transparent',
      border: `1px solid ${theme.other.semantic.border.general.lighter}`,
      lineHeight: 1,

      '&::placeholder': {
        fontSize: getFontSize(theme, params.size)
      },

      '&:hover': {
        borderColor: theme.other.semantic.border.general.light,
        '&::placeholder': {
          color: theme.other.semantic.text.primary
        }
      },

      '&:valid': {
        border: `0.5px solid ${theme.other.semantic.border.general.lighter}`,
        backgroundColor: theme.other.semantic.surfaceBackground.primary,
        borderBottom: `1px solid ${theme.other.semantic.border.general.lighter}`,
        '&::placeholder': {
          color: theme.other.semantic.text.primary
        }
      },

      '&:placeholder-shown': {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.other.semantic.border.general.lighter}`,
        '&::placeholder': {
          color: theme.other.semantic.text.secondary
        }
      },

      '&[aria-invalid="true"]': {
        border: `0.5px solid ${theme.other.semantic.border.general.lighter}`,
        backgroundColor: theme.other.semantic.surfaceBackground.primary,
        borderBottom: `1px solid ${theme.other.semantic.border.alert.error}`,
        '&::placeholder': {
          color: theme.other.semantic.text.primary
        }
      },

      '&:focus, &:focus-within': {
        border: `1px solid ${theme.other.semantic.border.general.accent[1]} !important`,
        backgroundColor: theme.other.semantic.surfaceBackground.none,
        '&::placeholder': {
          color: theme.other.semantic.text.primary
        }
      },

      '&:disabled': {
        border: 'none',
        backgroundColor: theme.other.semantic.surfaceBackground.disabled,
        color: theme.other.semantic.text.disabled,
        '&::placeholder': {
          color: theme.other.semantic.text.disabled
        }
      },

      '&:-internal-autofill-selected': {
        backgroundColor: theme.colors.neutral[8] + '!important',
        color: theme.white + '!important'
      },

      [`&.${CSS_KEY}-Textarea-input`]: {
        minHeight: getTextAreaMinHeight(params.size)
      }
    },
    rightSection: {
      color: theme.other.semantic.icon.general.secondary,
      width: getIconSize(params.size),
      right: `${getPadding(params.size, theme)}px`
    }
  })
};
