import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const useAlertInsightTabStyles = createStyles(() => ({
  root: {
    padding: '32px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: 32
  },

  videosGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 32
  },

  modal: {
    [`.${CSS_KEY}-Modal-modal`]: {
      backgroundColor: 'transparent !important',
      width: 'auto !important',
      display: 'flex',
      alignItems: 'center'
    },
    [`.${CSS_KEY}-Modal-header`]: {
      display: 'none !important'
    }
  },

  noAlertFallback: {
    display: 'flex',
    justifyContent: 'center'
  }
}));
