import { createStyles } from "@mantine/core";

export const useProfileIdentificationTabStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: '36px'
  },
  searchableSelectContainer: {
    marginTop: '24px'
  },
  tableContainer: {
    backgroundColor: theme.colors.neutral[9],
    paddingTop: '36px',
    maxHeight: '30%',
    boxSizing: 'border-box',
    width: '100%'
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center'
  },
  title: {
    color: theme.colors.neutral[5],
    fontWeight: theme.other.fw.bold,
    fontFamily: theme.fontFamily,
    fontSize: theme.fontSizes.xl,
    lineHeight: "120%"
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-between',

    '& > *:not(:last-child)': {
      marginRight: 8
    }
  },
  actionButton: {
    '&:disabled': {
      backgroundColor: 'inherit',
      border: 'none'
    }

  }
}));
