import { ChangeEvent, FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";

import SmsConfigurationComponent from "./SmsConfiguration.component";

const standardSmsBodySize = 160;

const EmailConfiguration: FC = () => {

  const { smsContent } = useSelector((state: RootState) => state.massComms);

  const {
    massComms: {
      SET_SMS_CONTENT
    }
  } = useDispatch<Dispatch>();

  const handleSmsContentChange = useCallback((event: ChangeEvent<HTMLTextAreaElement> | null) => {
    const textLength = event?.target.value.length || 0;
    SET_SMS_CONTENT({
      ...smsContent,
      body: event?.target.value,
      length: textLength || 0,
      units: Math.ceil(textLength / standardSmsBodySize)
    });

  }, [ SET_SMS_CONTENT, smsContent ]);

  return (
    <SmsConfigurationComponent
      smsContent={ smsContent }
      onSmsContentChanged={ handleSmsContentChange }
    />
  );
};

export default EmailConfiguration;
