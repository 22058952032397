/* eslint-disable max-lines */
import { callApi } from "@/common/util/api";
import { REGULAR_TOKEN_KEY, TENANT_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { SortData } from "@/tenant-context/control-groups/types/group";
import {
  ILocation
} from "@/tenant-context/control-location-configuration/types/ManageLocations.types";
import {
  AccessCard, AccessCardTabAccessible,
  AssignAccessCard, BulkProfilesFileUpload,
  BulkUpdateProfileStatus,
  Company, CompanyLog, CreateCompany,
  CreateContactDetail,
  CreateEmergencyContact,
  CreatePersonAddress,
  CreateProfileGeneral,
  MutableDistinguishingFeatures,
  MutableProfileCompanyDetails,
  MutableProfileGeneral,
  MutableProfileMedicalDetails,
  MutableProfilePersonalAttributes,
  PaginatedResult,
  ProfileAddress,
  ProfileCompany,
  ProfileConfiguration,
  ProfileContact,
  ProfileDistinguishingFeatures,
  ProfileDocumentDetails,
  ProfileEmergencyContact,
  ProfileGeneral,
  ProfileGroup,
  ProfileMedical,
  ProfilePersonalAttributes, ProfileTag,
  UpdateContactDetail,
  UpdateEmergencyContact,
  UpdatePersonAddress,
  UpdateProfileDocumentDetails, UploadedProfileStatusGrid
} from "@/tenant-context/control-profile/types/profile";

export const getTenantToken = () => {
  return browserDatabase.getItem<string>(TENANT_TOKEN_KEY);
};

export const getRegularToken = () => {
  return browserDatabase.getItem<string>(REGULAR_TOKEN_KEY);
};

type ProfileDetailsMap = {
  'UpdatePersonalDetail': MutableProfileGeneral,
  'UpdateCompanyDetail': MutableProfileCompanyDetails,
  'UpdateMedicalDetail': MutableProfileMedicalDetails
  'UpdatePersonalAttribute': MutableProfilePersonalAttributes,
  'UpdateDistinguishingFeature': MutableDistinguishingFeatures,
  'CreateContactDetail': CreateContactDetail
  'CreatePersonAddress': CreatePersonAddress,
  'UpdatePersonAddress': UpdatePersonAddress,
  'CreateEmergencyContact': CreateEmergencyContact,
  'UpdateEmergencyContact': UpdateEmergencyContact,
  'UpdateContactDetail': UpdateContactDetail,
  'CreatePersonalDetail': CreateProfileGeneral,

}

export const getPersonalDetails = (profileId: string) => callApi<
  ProfileGeneral
>(
  `/managed/platform-service/v1/person-profiles/${profileId}?include_binary_data=false`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=GetPersonalDetail'
    },
    withTenantId: false
  }
);

export const updateProfileDetails = <T extends keyof ProfileDetailsMap>(
  profileId: string,
  details: ProfileDetailsMap[T],
  domainModel: T
) => callApi<
  ProfileGeneral
>(
  `/managed/platform-service/v1/person-profiles/${profileId}`,
  {
    method: 'post',
    headers: {
      'Content-Type': `application/json;domain-model=${domainModel}`
    },
    body: JSON.stringify(details),
    withTenantId: false
  }
);

export const createProfileDetails = <T extends keyof ProfileDetailsMap>(
  details: ProfileDetailsMap[T],
  domainModel: T
) => callApi<ProfileGeneral>(
  `/managed/platform-service/v1/person-profiles`,
  {
    method: 'post',
    headers: {
      'Content-Type': `application/json;domain-model=${domainModel}`
    },
    body: JSON.stringify(details),
    withTenantId: false
  }
);

export const listContactDetails = (profileId: string) => callApi<PaginatedResult<ProfileContact>>(
  `/managed/platform-service/v1/person-profiles/${profileId}?page=0&size=10000`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=ListContactDetail'
    },
    withTenantId: false
  }
);

export const listEmergencyContacts = (profileId: string) => callApi<
  PaginatedResult<ProfileEmergencyContact>
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/emergency-contacts`,
  {
    method: 'get',
    withTenantId: false
  }
);

export const deleteEmergencyContactForProfile = (profileId: string, addressId: string) => callApi<
  void
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/emergency-contacts/${addressId}`,
  {
    method: 'delete',
    withTenantId: false
  }
);

export const listAddresses = (profileId: string) => callApi<
  PaginatedResult<ProfileAddress>
>(
  `/managed/platform-service/v1/person-profiles/${profileId}`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=ListPersonAddresses'
    },
    withTenantId: false
  }
);

export const deleteAddressForProfile = (profileId: string, addressId: string) => callApi<
  PaginatedResult<ProfileAddress>
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/person-addresses/${addressId}`,
  {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;domain-model=DeletePersonAddress'
    },
    withTenantId: false
  }
);

export const listDocuments = (profileId: string) => callApi<
  PaginatedResult<ProfileDocumentDetails>
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/person-documents`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=ListPersonDocument'
    },
    withTenantId: false
  }
);

export const listDocumentsGrid = (
  profileId: string,
  page = 0,
  size = 10,
  sort?: SortData
) => callApi<
    PaginatedResult<ProfileDocumentDetails>
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/person-documents?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}&size=${size}&page=${page}`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=ListPersonDocument'
    },
    withTenantId: false
  }
);

export const listGroups = (profileId: string) => callApi<
  PaginatedResult<ProfileGroup>
>(
  `/managed/platform-service/v1/person-profiles/${profileId}`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=ListPersonProfilePeopleGroup'
    },
    withTenantId: false
  }
);

export const listGroupsGrid = (
  profileId: string,
  page = 0,
  size = 10,
  sort?: SortData
) => callApi<
    PaginatedResult<ProfileGroup>
>(
  `/managed/platform-service/v1/person-profiles/${profileId}?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}&size=${size}&page=${page}`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=ListPersonProfilePeopleGroup'
    },
    withTenantId: false
  }
);

export const getCompanyDetails = (profileId: string) => callApi<
  ProfileCompany
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/company-details/ref:${profileId}`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=GetCompanyDetail'
    },
    withTenantId: false
  }
);

export const getMedicalDetails = (profileId: string) => callApi<
  ProfileMedical
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/medical-details/ref:${profileId}`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=GetMedicalDetail'
    },
    withTenantId: false
  }
);

export const getPersonalAttributes = (profileId: string) => callApi<
  ProfilePersonalAttributes
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/personal-attributes/ref:${profileId}`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=GetPersonalAttribute'
    },
    withTenantId: false
  }
);

export const getDistinguishingFeatures = (profileId: string) => callApi<
  ProfileDistinguishingFeatures
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/distinguishing-features/ref:${profileId}`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;domain-model=GetDistinguishingFeature'
    },
    withTenantId: false
  }
);

export const deleteDocumentForUser = (profileId: string, documentId: string) => callApi<
  void
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/person-documents/${documentId}`,
  {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;domain-model=DeletePersonDocument'
    },
    withTenantId: false
  }
);

export const createDocumentForUser = (profileId: string, formData: FormData) => callApi<
  void
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/person-documents/files`,
  {
    method: 'post',
    body: formData,
    withTenantId: false,
    withContentType: false
  }
);

export const updateDocumentDataForUser = (
  profileId: string,
  documentId: string,
  documentData: UpdateProfileDocumentDetails
) => callApi<
  void
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/person-documents/${documentId}`,
  {
    method: 'post',
    body: JSON.stringify(documentData),
    withTenantId: false
  }
);

export const uploadFilesForDocument = (profileId: string, documentId: string, filesFormData: FormData) => callApi<
  void
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/person-documents/${documentId}/files`,
  {
    method: 'post',
    body: filesFormData,
    withTenantId: false,
    withContentType: false,
    headers: {
      'X-Domain-Model': 'UploadPersonDocument'
    }
  }
);

export const getDocumentForUser = (profileId: string, documentId: string) => callApi<
  ProfileDocumentDetails
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/person-documents/${documentId}`,
  {
    method: 'get',
    withTenantId: false
  }
);

export const deleteFileFromDocumentForUser = (profileId: string, documentId: string, fileId: string) => callApi<
  void
>(
  `/managed/platform-service/v1/person-profiles/${profileId}/person-documents/${documentId}`,
  {
    method: 'post',
    headers: {
      'Content-Type': 'application/json;domain-model=DeletePersonFile'
    },
    body: JSON.stringify({
      fileIdentifier: fileId
    }),
    withTenantId: false
  }
);

export const updateProfilePictureForUser = (profileId: string, body: {
  profilePictureThumbnail: string,
  profilePicture: string
}) => callApi<
  void
>(
  `/managed/platform-service/v1/person-profiles/${profileId}?include_binary_data=false`,
  {
    method: 'post',
    headers: {
      'Content-Type': 'application/json;domain-model=UpdateProfilePicture'
    },
    body: JSON.stringify(body),
    withTenantId: false
  }
);

export const getGeneralConfig = () => callApi<
  ProfileConfiguration
>(
  `/managed/platform-service/v1/profile-configurations/PERSON_PROFILE_CONFIG_ID`,
  {
    method: 'get',
    withTenantId: false
  }
);

export const deleteContactDetailsFromProfile = (profileId: string, contactDetailsId: string) => callApi<void>(
  `/managed/platform-service/v1/person-profiles/${profileId}/contact-details/${contactDetailsId}`,
  {
    method: 'delete',
    withTenantId: false
  }
);

export const getCompanies = (
  page = 0,
  size = 100,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<Company>>(
  `/managed/platform-service/v1/companies?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    method: 'get',
    withTenantId: false
  }
);

export const getCompanyLogs = (
  companyId: string,
  page = 0,
  size = 10
) => callApi<PaginatedResult<CompanyLog>>(
  `/managed/platform-service/v1/companies/${companyId}?size=${size}&page=${page}&orderBy=N(_seqNum):DESC`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'X-Domain-Model': 'ListCompanyEvents'
    },
    withTenantId: false
  }
);

export const getCompany = (companyId: string) => callApi<Company>(
  `/managed/platform-service/v1/companies/${companyId}`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    withTenantId: false
  }
);

// ! Look for group-related APIs in groups module

export const uploadBulkUserProfilesFile = (formData: FormData) =>
  callApi<BulkProfilesFileUpload>(
    `/managed/platform-service/v1/bulk-data/files`,
    {
      method: "post",
      body: formData,
      headers: {
        "x-domain-model": "UploadBulkData"
      },
      withTenantId: false,
      withContentType: false
    }
  );

export const uploadBulkUserProfilesStatus = (data: BulkUpdateProfileStatus, query = '') => callApi<{ status?: string }>(
  `/managed/platform-service/v1/person-profiles`,
  {
    method: 'post',
    headers: {
      'Content-Type': 'application/json;domain-model=BulkProfileStatusUpdate'
    },
    body: JSON.stringify({ query, ...data }),
    withTenantId: false
  }
);

export const createProfileTag = (profileTag: ProfileTag) => callApi<
  void
>(
  `/managed/platform-service/v1/profile-tags`,
  {
    method: 'post',
    headers: {
      'Content-Type': 'application/json;domain-model=CreateProfileTag'
    },
    body: JSON.stringify(profileTag),
    withTenantId: false
  }
);

export const assignAccessCard = (
  profileId: string,
  cardId: AssignAccessCard
) => callApi<void>(
  `/managed/platform-service/v1/person-profiles/${profileId}`,
  {
    method: 'post',
    headers: {
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'AssignAccessCardToProfile'
    },
    body: JSON.stringify(cardId),
    withTenantId: false
  }
);

export const unAssignAccessCard = (
  profileId: string,
  cardId: AssignAccessCard
) => callApi<void>(
  `/managed/platform-service/v1/person-profiles/${profileId}`,
  {
    method: 'post',
    headers: {
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'UnassignedAccessCardToProfile'
    },
    body: JSON.stringify(cardId),
    withTenantId: false
  }
);

export const getAccessCardListWithProfileAssigment = (
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string,
  profileTid?: string
) => callApi<PaginatedResult<AccessCard>>(
  `/managed/platform-service/v1/person-profiles?profileAssigment=true${profileTid ? `&profileTid=${profileTid}` : ''}&${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'X-Domain-Model': 'ListAccessCardProfile'
    },
    method: 'get',
    withAuthorization: true
  }
);
export const createProfileTagList = (profileTags: ProfileTag[]) => callApi<
  void
>(
  `/managed/platform-service/v1/profile-tags`,
  {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'x-domain-model': 'CreateProfileTagList'
    },
    body: JSON.stringify( { tagList: profileTags }),
    withTenantId: false
  }
);

export const updateProfileTag = (profileTag: ProfileTag, id: string) => callApi<
  void
>(
  `/managed/platform-service/v1/profile-tags/${id}`,
  {
    method: 'post',
    headers: {
      'Content-Type': 'application/json;domain-model=UpdateProfileTag'
    },
    body: JSON.stringify(profileTag),
    withTenantId: false
  }
);

export const getProfileTags = (queryParams: {
  page?: number,
  size?: number,
  search?: string,
  orderBy?: string
}) => callApi<
  PaginatedResult<ProfileTag>
>(
  `/managed/platform-service/v1/profile-tags?page=0&size=99999`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;domain-model=ListProfileTag'
    },
    queryParams: queryParams
  }
);

export const getProfileTagsForPerson = (profileId: string) => callApi<PaginatedResult<ProfileTag>>(
  `/managed/platform-service/v1/person-profiles/${profileId}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;domain-model=ListProfileTagToProfile'
    },
    withTenantId: false
  }
);

export const createCompany = (
  data: CreateCompany
) => callApi<void>(
  `/managed/platform-service/v1/companies`,
  {
    method: 'post',
    headers: {
      'Content-Type': 'application/json;'
    },
    body: JSON.stringify(data),
    withTenantId: false
  }
);

export const updateCompany = (
  id: string,
  data: CreateCompany
) => callApi<void>(
  `/managed/platform-service/v1/companies/${id}`,
  {
    method: 'post',
    headers: {
      'Content-Type': 'application/json;'
    },
    body: JSON.stringify(data),
    withTenantId: false
  }
);

export const deleteCompany = (
  id: string
) => callApi<void>(
  `/managed/platform-service/v1/companies/${id}`,
  {
    method: 'delete',
    withTenantId: false
  }
);

export const getAccessCardPrivilege = () => callApi<AccessCardTabAccessible>(
  `/managed/platform-service/v1/person-profiles`,
  {
    method: 'GET',
    headers: {
      'X-Domain-Model': 'GetAccessCardPrivilege',
      'Content-Type': 'application/json'
    },
    withTenantId: true
  }
);

export const getProfileSources = () => callApi<PaginatedResult<string>>(
  `/managed/platform-service/v1/person-profiles`,
  {
    method: 'GET',
    headers: {
      'X-Domain-Model': 'GetDistinctSourceValues',
      'Content-Type': 'application/json'
    },
    withTenantId: true
  }
);

export const getLocationsList = (
  query?: string
) => callApi<PaginatedResult<ILocation>>(
  `location-management-service/v2/categories/All/locations?locationType=All&criticalStatusFilter=ALL&mergeNonTenant=false&pageSize=10000&pageNum=0${query ? '&query=(' + query + ')' : ''}`,
  {
    method: 'get'
  }
);

export const getBulkProfileUploadStatusGrid = (
  bulkDataId: string,
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) =>
  callApi<UploadedProfileStatusGrid>(
    `managed/platform-service/v1/bulk-data/${bulkDataId}/row-records?${
      sort?.colId ? "orderBy=" + sort.colId + ":" + sort.sort : ""
    }&search=status='ERROR'${query ? "AND(" + query + ")&" : ""}&size=${size}&page=${page}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json;domain-model=GetRowRecordsByStatus"
      }
    }
  );

export const getBulkProfileUploadStatusCSV = (bulkDataId: string) =>
  callApi<string>(
    `managed/platform-service/v1/bulk-data/${bulkDataId}/row-records/download?search=status=%27ERROR%27&page=0&size=1000`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/csv;domain-model=GetRowRecordsByStatus"
      },
      responseType: "blob"
    }
  );





