import { Box, Flex, Text } from '@mantine/core';
import { FC, useContext } from "react";

import PersonAvatar from "@/common/components/PersonAvatar";
import {
  useNavigationTenantDetailsItemStyles
} from '@/core/navigation/components/NavigationTenantDetailsItem/NavigationTenantDetailsItem.style';
import { NavigationSidebarContext } from "@/core/navigation/context/NavigationSidebar.context";


type Props = {
  isExpanded: boolean;
}

const NavigationTenantDetailsItem: FC<Props> = ({ isExpanded }) => {
  const {
    tenantDetails
  } = useContext(NavigationSidebarContext);

  const { classes } = useNavigationTenantDetailsItemStyles({ isExpanded: isExpanded });

  if (!tenantDetails) {
    return null;
  }

  return (
    <Flex align="center" className={ classes.itemRoot } justify="flex-start">
      <Box className={ classes.avatar }>
        <PersonAvatar
          width={ isExpanded ? '20px' : '24px' }
          height={ isExpanded ? '20px' : '24px' }
          personName={ tenantDetails?.name || 'Tenant' }
          personAvatarUrl={ tenantDetails.logoSmallBase64 }
        />
      </Box>
      <Flex align="center" justify="flex-start" className={ classes.shrinkableContent }>
        <Text className={ classes.name }>
          { tenantDetails.name }
        </Text>
      </Flex>
    </Flex>
  );
};

export default NavigationTenantDetailsItem;
