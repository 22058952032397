import { ActionIcon, Menu, ScrollArea, TextInput } from "@mantine/core";
import { FC, useRef } from "react";

import { ReactComponent as BarsSort } from "@/common/icons/bars-sort.svg";
import { ReactComponent as SearchIcon } from '@/common/icons/search-gray.svg';
import {
  useTravellersByCountryDrawerStyles
} from "@/tenant-context/control-travellers-search/drawers/TravellersByCountryDrawer/TravellersByCountryDrawer.styles";

import { CountriesSortType, CountrySummaryDataType } from "../../types/travelSearch.types";
import { Country } from "./components/Country.component";

type Props = {
  countryTravelersData: CountrySummaryDataType[],
  handleSort: (sortType: CountriesSortType) => () => void;
  handleSearchChange: (searchText: string) => void;
  onCountrySelected: (country: CountrySummaryDataType) => void;
}

export const TravellersByCountryDrawerComponent: FC<Props> = ({
  countryTravelersData,
  handleSort,
  handleSearchChange,
  onCountrySelected
}) => {

  const inputRef = useRef<HTMLInputElement>(null);

  const { classes } = useTravellersByCountryDrawerStyles({});

  return (
    <div>
      <div className={ classes.filtersContainer }>
        <Menu shadow="md">
          <Menu.Target>
            <ActionIcon className={ classes.sortButton } variant={ 'subtle' }>
              <BarsSort/>
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown className={ classes.dropdown }>
            <Menu.Label className={ classes.dropdownLabel }>Sort by:</Menu.Label>
            <Menu.Item
              className={ classes.dropdownItem }
              onClick={ handleSort(CountriesSortType.travellerCount) }>
              Number of Travellers
              <span className={ classes.dropdownItemDescription }>
                (high to low)
              </span>
            </Menu.Item>
            <Menu.Item
              className={ classes.dropdownItem }
              onClick={ handleSort(CountriesSortType.riskLevel) }>
              Risk Rating
              <span className={ classes.dropdownItemDescription }>
                (lowest to highest)
              </span>
            </Menu.Item>
            <Menu.Item
              className={ classes.dropdownItem }
              onClick={ handleSort(CountriesSortType.countryName) }>
              Country Name
              <span className={ classes.dropdownItemDescription }>
                A to Z
              </span>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>


        <TextInput
          className={ classes.searchInput }
          ref={ inputRef }
          icon={ <SearchIcon className={ classes.searchIcon }/> }
          // eslint-disable-next-line react/jsx-no-bind
          onChange={ (evt) => handleSearchChange(evt.target.value) }
        />

      </div>

      <div className={ classes.countriesHeader }>
        <p>COUNTRY</p>
        <p>RISK</p>
        <p>TRAVELLERS</p>
      </div>

      <ScrollArea
        scrollbarSize={ 8 }
        scrollHideDelay={ 200 }
      >
        <div className={ classes.countriesContent }>
          { countryTravelersData.map((countryData, index) => (
            <Country
              countryData={ countryData }
              key={ `${index}${countryData.countryISOCode}` }
              onClick={ onCountrySelected }
            />
          )) }
        </div>
      </ScrollArea>
    </div>
  );
};
