import { createContext, FC, useState } from "react";

import { TravelActiveTab } from "../types/travel.types";

type TravelContextType = {
  activeTab?: TravelActiveTab;
  setActiveTab: (tab?: TravelActiveTab) => void;
  activeDay?: string;
  activeTravelEndPoint?: string;
};

export const TravelContext = createContext<TravelContextType>(
  {} as TravelContextType
);

const TravelContextProvider: FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState<TravelActiveTab | undefined>(undefined);

  const activeDay = activeTab && activeTab > 2 ? "tomorrow" : "today" ;

  const activeTravelEndPoint = activeTab && activeTab % 2 === 0 ? "arriving" : "leaving";
  
  return (
    <TravelContext.Provider
      value={ {
        activeTab,
        setActiveTab,
        activeDay,
        activeTravelEndPoint
      } }
    >
      { children }
    </TravelContext.Provider>
  );
};

export default TravelContextProvider;
