import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState } from "@/core/store";
import {
  useTravellersSearchContext
} from "@/tenant-context/control-travellers-search/context/TravellersSearchContext/TravellersSearch.context";

import { TRAVELLERS_SEARCH_SUB_SLIDES } from "../../config/travellers-search.config";
import { TravellersMiniProfileDrawerComponent } from "./TravellersMiniProfilDrawer.component";


const TravellersMiniProfileDrawer = () => {
  const { userTravelItenerary } = useSelector((state: RootState) => state.userProfile);

  const {
    travellersSearch: {
      RESET_TRAVEL_COUNTRY_WISE_TRAVELLERS_STATE
    },
    userProfile: {
      getUserTravelItenerary,
      SET_ITINERARY_SHOWN_ON_MAP
    }
  } = useDispatch<Dispatch>();

  const {
    setCurrentlyOpenedSlide,
    selectedTraveller,
    resetContext
  } = useTravellersSearchContext();

  const navigate = useNavigate();

  const handleBackClick = useCallback(() => {
    setCurrentlyOpenedSlide(TRAVELLERS_SEARCH_SUB_SLIDES.COUNTRY_DETAIL_VIEW_SLIDE);
  }, [ setCurrentlyOpenedSlide ]);

  const handleProfileClick = useCallback(() => {
    if (!selectedTraveller?.profileId) {
      return;
    }

    RESET_TRAVEL_COUNTRY_WISE_TRAVELLERS_STATE();
    resetContext();

    navigate(RouterConfig.routes.bigProfile(selectedTraveller?.profileId));
  }, [selectedTraveller?.profileId, RESET_TRAVEL_COUNTRY_WISE_TRAVELLERS_STATE, resetContext, navigate]);

  const getTravelItinerary = useCallback(async () => {
    if (!selectedTraveller?.correlationId) {
      return;
    }

    return await getUserTravelItenerary(selectedTraveller?.correlationId);
  }, [getUserTravelItenerary, selectedTraveller?.correlationId]);

  const handleActiveStepper = useCallback((): number => {
    const currentUtcInMilis = Date.now();
    const currentItenerary = userTravelItenerary?.findIndex(itenerary => currentUtcInMilis > itenerary.start_date
      && currentUtcInMilis < itenerary.end_date);

    if (currentItenerary !== undefined && currentItenerary >= 0) {
      return currentItenerary;
    } else if (userTravelItenerary !== undefined && userTravelItenerary?.length > 0 &&
      currentUtcInMilis > userTravelItenerary[userTravelItenerary.length - 1].end_date
    ) {
      return userTravelItenerary?.length;
    } else {
      return 0;
    }
  }, [userTravelItenerary]);

  const handleCalculateTripProgress = useCallback((startDate: number, endDate: number): {
    progress: number,
    status: 'past' | 'present' | 'future'
  } => {
    const currentUtcInMilis = Date.now();
    if (currentUtcInMilis > startDate &&
      currentUtcInMilis < endDate) {
      const progress = currentUtcInMilis - startDate;
      const totaltrip = endDate - startDate;
      return {
        progress: Math.trunc((progress / totaltrip) * 100),
        status: 'present'
      };
    } else if (currentUtcInMilis < startDate && currentUtcInMilis < endDate) {
      return {
        progress: 0,
        status: 'future'
      };
    } else if (currentUtcInMilis > startDate && currentUtcInMilis > endDate) {
      return {
        progress: 0,
        status: 'past'
      };
    } else {
      return { progress: 0, status: 'past' };
    }
  }, []);

  useEffect(() => {
    getTravelItinerary();

    SET_ITINERARY_SHOWN_ON_MAP(true);

    return () => {
      SET_ITINERARY_SHOWN_ON_MAP(false);
    };
  }, [getUserTravelItenerary, SET_ITINERARY_SHOWN_ON_MAP, getTravelItinerary]);


  return (
    <TravellersMiniProfileDrawerComponent
      onBackClick={ handleBackClick }
      traveller={ selectedTraveller }
      openProfileClick={ handleProfileClick }

      // legacy itinerary
      travelIteneraryInfo={ userTravelItenerary }
      onCalculateTripProgress={ handleCalculateTripProgress }
      onActiveStepper={ handleActiveStepper }
    />
  );
};

export default TravellersMiniProfileDrawer;
