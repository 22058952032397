import { LocationsPeopleCount } from "@/tenant-context/visualisation-site/types/site.types";

export const getMusterLocations = (locations: LocationsPeopleCount) => {

  const masterLocations: Record<string, number> = {};

  locations?.countries.forEach((country) => {
    return country.sites.forEach((site) => {
      masterLocations[site.code] = site.musterCount;
    });
  });

  return masterLocations;
};


export const getMusterCountries = (locations: LocationsPeopleCount) => {

  const masterLocations: Record<string, number> = {};

  locations?.countries.forEach((country) => {
    return masterLocations[country.code] = country.musters;
  });
  
  return masterLocations;
};
