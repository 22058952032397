import { Stack } from "@mantine/core";
import { FC, useMemo } from "react";

import { RiskProviders } from "@/tenant-context/common/types/risk";

import { ProviderCategory } from "../../types/risk";
import { MAX_SECURITY_RISK_CATEGORIES } from "../MaxSecurityEventsSettings/MaxSecurityEventsSettings.config";
import { useDisabledRiskFilterTypes, useToggleCategoryFilter } from "./hooks";
import ProviderEvents from "./ProviderEvents.component";

const MaxSecurityEventsContainer: FC = () => {
  const items = useMemo<Array<ProviderCategory>>(
    () =>
      MAX_SECURITY_RISK_CATEGORIES.map((category) => ({
        ...category,
        categoryId: category.id
      })),
    []
  );

  const disabledRiskFilterTypes = useDisabledRiskFilterTypes();

  const handleSwitchToggle = useToggleCategoryFilter(RiskProviders.maxSecurity);

  return (
    <Stack mt={ 16 } ml={ 32 + 36 }>
      <ProviderEvents
        items={ items }
        onCategoryToggle={ handleSwitchToggle }
        disabledRiskFilterTypes={ disabledRiskFilterTypes }
      />
    </Stack>
  );
};

export default MaxSecurityEventsContainer;
