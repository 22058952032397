import { ReactComponent as Conflict } from "@/common/icons/conflict.svg";
import { ReactComponent as Crime } from "@/common/icons/crime.svg";
import { ReactComponent as Demonstrations } from "@/common/icons/demonstrations.svg";
import { ReactComponent as Health } from "@/common/icons/health.svg";
import { ReactComponent as Natural } from "@/common/icons/natural.svg";
import { ReactComponent as Political } from "@/common/icons/political.svg";
import { ReactComponent as ActiveShooter } from  '@/common/icons/RiskProviders/MaxSecurityCategories/active_shooter.svg';
import { ReactComponent as AirStrike } from  '@/common/icons/RiskProviders/MaxSecurityCategories/air_strike.svg';
import { ReactComponent as ArbitraryDetention } from  '@/common/icons/RiskProviders/MaxSecurityCategories/arbitrary_detention.svg';
import { ReactComponent as ArmedConflict } from  '@/common/icons/RiskProviders/MaxSecurityCategories/armed_conflict.svg';
import { ReactComponent as CivilUnrest } from  '@/common/icons/RiskProviders/MaxSecurityCategories/civil_unrest.svg';
import { ReactComponent as CrimeMax } from  '@/common/icons/RiskProviders/MaxSecurityCategories/crime.svg';
import { ReactComponent as Cyber } from  '@/common/icons/RiskProviders/MaxSecurityCategories/cyber.svg';
import { ReactComponent as Developing } from  '@/common/icons/RiskProviders/MaxSecurityCategories/developing.svg';
import { ReactComponent as Earthquake } from  '@/common/icons/RiskProviders/MaxSecurityCategories/earthquake.svg';
import { ReactComponent as Economics } from  '@/common/icons/RiskProviders/MaxSecurityCategories/economics.svg';
import { ReactComponent as Fire } from  '@/common/icons/RiskProviders/MaxSecurityCategories/fire.svg';
import { ReactComponent as HealthMax } from  '@/common/icons/RiskProviders/MaxSecurityCategories/health.svg';
import { ReactComponent as Holiday } from  '@/common/icons/RiskProviders/MaxSecurityCategories/holiday.svg';
import { ReactComponent as KidnapAndRansom } from  '@/common/icons/RiskProviders/MaxSecurityCategories/kidnap_and_ransom.svg';
import { ReactComponent as LaborAction } from  '@/common/icons/RiskProviders/MaxSecurityCategories/labor_action.svg';
import { ReactComponent as LargeScaleEvents } from  '@/common/icons/RiskProviders/MaxSecurityCategories/large_scale_events.svg';
import { ReactComponent as MaritimeIncidents } from  '@/common/icons/RiskProviders/MaxSecurityCategories/maritime_incidents.svg';
import { ReactComponent as MilitancyTerrorism } from  '@/common/icons/RiskProviders/MaxSecurityCategories/militancy_terrorism.svg';
import { ReactComponent as OrganisedCrime } from  '@/common/icons/RiskProviders/MaxSecurityCategories/organised_crime.svg';
import { ReactComponent as Piracy } from  '@/common/icons/RiskProviders/MaxSecurityCategories/piracy.svg';
import { ReactComponent as Politics } from  '@/common/icons/RiskProviders/MaxSecurityCategories/politics.svg';
import { ReactComponent as Protest } from  '@/common/icons/RiskProviders/MaxSecurityCategories/protest.svg';
import { ReactComponent as Rocket } from  '@/common/icons/RiskProviders/MaxSecurityCategories/rocket.svg';
import { ReactComponent as SecurityOperations } from  '@/common/icons/RiskProviders/MaxSecurityCategories/security_operations.svg';
import { ReactComponent as SupplyChainDisruptions } from  '@/common/icons/RiskProviders/MaxSecurityCategories/supply_chain_disruptions.svg';
import { ReactComponent as TravelMax } from  '@/common/icons/RiskProviders/MaxSecurityCategories/travel.svg';
import { ReactComponent as Volcano } from  '@/common/icons/RiskProviders/MaxSecurityCategories/volcano.svg';
import { ReactComponent as Weather } from  '@/common/icons/RiskProviders/MaxSecurityCategories/weather.svg';
import { ReactComponent as Travel } from "@/common/icons/travel.svg";

export const RiskCategories = [
  {
    Icon: <Political />,
    categoryId:'1'
  },
  {
    Icon: <Conflict />,
    categoryId:'2'
  },
  {
    Icon: <Demonstrations />,
    categoryId:'3'
  },

  {
    Icon: <Crime />,
    categoryId:'4'
  },
  {
    Icon: <Natural />,
    categoryId:'5'
  },
  {
    Icon: <Health />,
    categoryId:'6'
  },
  {
    Icon: <Travel />,
    categoryId:'7'
  },
  {
    categoryId: 'ActiveShooter',
    Icon: <ActiveShooter/>
  },
  {
    categoryId: 'AirStrike',
    Icon: <AirStrike/>
  },
  {
    categoryId: 'ArbitraryDetention',
    Icon: <ArbitraryDetention/>
  },
  {
    categoryId: 'ArmedConflict',
    Icon: <ArmedConflict/>
  },
  {
    categoryId: 'CivilUnrest',
    Icon: <CivilUnrest/>
  },
  {
    categoryId: 'Crime',
    Icon: <CrimeMax/>
  },
  {
    categoryId: 'Cyber',
    Icon: <Cyber/>
  },
  {
    categoryId: 'Developing',
    Icon: <Developing/>
  },
  {
    categoryId: 'Earthquake',
    Icon: <Earthquake/>
  },
  {
    categoryId: 'Economics',
    Icon: <Economics/>
  },
  {
    categoryId: 'Fire',
    Icon: <Fire/>
  },
  {
    categoryId: 'Health',
    Icon: <HealthMax/>
  },
  {
    categoryId: 'Holiday',
    Icon: <Holiday/>
  },
  {
    categoryId: 'KidnapAndRansom',
    Icon: <KidnapAndRansom/>
  },
  {
    categoryId: 'LaborAction',
    Icon: <LaborAction/>
  },
  {
    categoryId: 'LargeScaleEvents',
    Icon: <LargeScaleEvents/>
  },
  {
    categoryId: 'MaritimeIncidents',
    Icon: <MaritimeIncidents/>
  },
  {
    categoryId: 'MilitancyTerrorism',
    Icon: <MilitancyTerrorism/>
  },
  {
    categoryId: 'OrganisedCrime',
    Icon: <OrganisedCrime/>
  },
  {
    categoryId: 'Piracy',
    Icon: <Piracy/>
  },
  {
    categoryId: 'Politics',
    Icon: <Politics/>
  },
  {
    categoryId: 'Protest',
    Icon: <Protest/>
  },
  {
    categoryId: 'Rocket',
    Icon: <Rocket/>
  },
  {
    categoryId: 'SecurityOperations',
    Icon: <SecurityOperations/>
  },
  {
    categoryId: 'SupplyChainDisruptions',
    Icon: <SupplyChainDisruptions/>
  },
  {
    categoryId: 'Travel',
    Icon: <TravelMax/>
  },
  {
    categoryId: 'Volcano',
    Icon: <Volcano/>
  },
  {
    categoryId: 'Weather',
    Icon: <Weather/>
  }
];
