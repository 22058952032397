import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import {
  getActivityListData,
  getListUserSiteAndPolicies,
  getListUserSiteData
} from "@/tenant-context/control-connected-sites-dashboard/api/connectedSitesDashboard";
import {
  ConnectedSite,
  SiteWithPolicies
} from "@/tenant-context/control-connected-sites-dashboard/types/connectedSitesDashboard";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

type ConnectedSitesDashboardState = {
  siteWithPolicies?: SiteWithPolicies[]
  selectedSite: string | null
  activityList: PaginatedResult<ConnectedSite>
}

const initialState: ConnectedSitesDashboardState = {
  siteWithPolicies: [],
  selectedSite: null,
  activityList: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 0,
    items: []
  }
};

const connectedSitesDashboardModel = {
  name: 'connectedSitesDashboard',
  state: initialState,
  reducers: {
    SET_SITES_WITH_POLICIES: (state: ConnectedSitesDashboardState, siteWithPolicies: ConnectedSitesDashboardState['siteWithPolicies']) => ({
      ...state,
      siteWithPolicies
    }),
    SET_SELECTED_SITE: (state: ConnectedSitesDashboardState, selectedSite: ConnectedSitesDashboardState['selectedSite']) => ({
      ...state,
      selectedSite
    }),
    SET_ACTIVITY_LIST: (state: ConnectedSitesDashboardState, activityList: ConnectedSitesDashboardState['activityList']) => ({
      ...state,
      activityList
    })
  },
  effects: (dispatch: Dispatch) => ({
    async loadListUserSite(payload: {
      gridParams: GridParams
    }, state: RootState): Promise<PaginatedResult<ConnectedSite>> {
      const { profile: { loggedUser } } = state;
      if (typeof loggedUser?.assignedTenantUserTid !== 'string' || !loggedUser?.assignedTenantUserTid) {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 0,
          items: []
        };
      }

      return await getListUserSiteData(
        payload.gridParams.page,
        payload.gridParams.size,
        loggedUser?.assignedTenantUserTid,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );
    },

    async loadListUserSiteAndPolicies(payload: {
      gridParams: GridParams
    }, state: RootState): Promise<void> {
      const { profile: { loggedUser } } = state;
      if (typeof loggedUser?.assignedTenantUserTid !== 'string' || !loggedUser?.assignedTenantUserTid) {
        return;
      }

      await getListUserSiteAndPolicies(
        payload.gridParams.page,
        payload.gridParams.size,
        loggedUser?.assignedTenantUserTid,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      )
        .then((res) => dispatch.connectedSitesDashboard.SET_SITES_WITH_POLICIES(res.items))
        .catch(handleError);
    },

    async loadActivityList(payload: {
      gridParams: GridParams
    }, _state: RootState): Promise<PaginatedResult<ConnectedSite>> {

      const activityList = await getActivityListData(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      if (activityList.items) {
        dispatch.connectedSitesDashboard.SET_ACTIVITY_LIST(activityList);
      }

      return activityList;
    }
  })
};

export const connectedSitesDashboard = createModel<RootModel>()(connectedSitesDashboardModel);
