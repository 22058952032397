import { forwardRef, ForwardRefRenderFunction, ReactNode, Ref } from "react";

// eslint-disable-next-line import/extensions
import { useDroppedInfoBoxStyles } from "@/tenant-context/common/components/DroppableMapTopInfoBox/DroppableMapTopInfoBox.styles";
import MapTopInfoBox from "@/tenant-context/common/components/MapTopInfoBox";

type Props = {
  title: string,
  value: string | number,
  width?: string,
  bgColor?: string,
  fontColor?: string,
  children: ReactNode,
  isExpanded: boolean,
  onClick: () => void
  musterCount?: number
}

const DroppableMapTopInfoBoxComponent: ForwardRefRenderFunction<HTMLDivElement, Props> = ({
  title,
  value,
  width = '260px',
  bgColor = '#101213',
  fontColor = '#FFFFFF',
  children,
  isExpanded,
  onClick,
  musterCount
}, ref: Ref<HTMLDivElement>) => {
  const { classes } = useDroppedInfoBoxStyles({
    isExpanded
  });

  return (
    <div
      ref={ ref }
      className={ classes.wrapper }
    >
      <MapTopInfoBox
        title={ title }
        value={ value }
        width={ width }
        bgColor={ bgColor }
        fontColor={ fontColor }
        onClick={ onClick }
        isDropdownBox={ true }
        isDropdownExpanded={ isExpanded }
        musterCount={ musterCount }
      />

      <div
        className={ classes.dropdown }
      >
        { children }
      </div>
    </div>
  );
};

export default forwardRef(DroppableMapTopInfoBoxComponent);
