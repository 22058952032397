import { createStyles } from "@mantine/core";

export const useProfileAddressesTabStyles = createStyles((theme) => ({
  main: {
    display: 'grid',
    gridTemplateColumns: "1fr 1fr",
    gap: 16,
    height: '100%'
  },
  defaultAddressLabel: {
    color: theme.colors.neutral[5]
  },
  titleContainer: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',

    svg: {
      flexShrink: 0
    }
  },
  addressTitle: {
    display: 'flex',
    gap: 8,

    svg: {
      flexShrink: 0,
      marginTop: 0
    }
  },
  mapContainer: {
    marginTop: 31,
    height: '100%',
    border: `0.5px solid ${theme.colors.neutral[6]}`
  },
  edit: {
    float: 'right',
    padding: 0
  }
}));
