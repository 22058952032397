import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Dispatch } from "@/core/store";

import LocationDetailsTabComponent from "./LocationDetailsTab.component";


const LocationDetailsTab: FC = () => {
  const {
    manageLocations: {
      initializeAddLocations
    }
  } = useDispatch<Dispatch>();

  useEffect(()=>{
    initializeAddLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocationDetailsTabComponent />
  );
};

export default LocationDetailsTab;
