import { useMantineTheme } from "@mantine/core";
import { Feature, FeatureCollection, GeoJsonProperties, Point } from "geojson";
import React, { FC } from "react";
import { Layer, Source } from "react-map-gl";
import { useSelector } from "react-redux";

import { Popup } from "@/common/components/Popup/Popup.components";
import { RootState } from "@/core/store";
import { semanticColorPalette } from "@/core/styles/mantine/palettes";
import DynamicallyVisibleLayer from "@/tenant-context/common/components/DynamicallyVisibleLayer";
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import { BigMapPopupIds, useMapPopupList } from "@/tenant-context/common/hooks/useMapPopupList";
import {
  SITE_INFO_CONTROL_DRAWER_ID
} from "@/tenant-context/control-site/controls/SiteInfoControl/SiteInfoControl.component";

import CriticalLocationPopup from "../../components/CriticalLocationPopup";
import CriticalLocationClusterPopup from "../../components/CriticalLocationPopup/CriticalLocationClusterPopup.component";
import { CriticalLocationLayerConfig } from "../../config/CriticalLocationLayerConfig";

type Props = {
  geoData: FeatureCollection,
  siteGeoJson?: FeatureCollection
  onClickClosePopup: () => void;
  onClickLocationItem?: (feature: Feature<Point, GeoJsonProperties>) => void;
  onClickZoomTo: React.MouseEventHandler<HTMLButtonElement>;
  onClickMoreDetails: React.MouseEventHandler<HTMLButtonElement>;
}

const popupOffsetY = 22;

const CriticalLocationLayerComponent: FC<Props> = ({
  geoData,
  siteGeoJson,
  onClickClosePopup,
  onClickLocationItem,
  onClickZoomTo,
  onClickMoreDetails
}) => {
  const theme = useMantineTheme();
  const openDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenRightDrawerId);
  const { popups, closePopup } = useMapPopupList('bigMapPopups');

  return (
    <>
      { siteGeoJson && <Source id="sites-location-outline" type="geojson" data={ siteGeoJson }>
        <Layer
          id="site-outline"
          type="line"
          paint={ {
            "line-color": semanticColorPalette.accessibility.outer,
            "line-opacity": 1,
            "line-width": 2
          } }
        />
        <Layer
          id="site-outline-layer"
          type="fill"
          paint={ {
            "fill-color": semanticColorPalette.accessibility.outer,
            "fill-opacity": 0.2
          } }
        />
      </Source> }


      <Source
        id="r__criticalLocationSource"
        type="geojson"
        cluster={ true }
        clusterRadius={ 50 }
        clusterMaxZoom={ 14 }
        tolerance={ 0 }
        data={ geoData }
      >
        <Layer
          id={ `r__criticalLocationLayer-cluster` }
          type={ 'symbol' }
          filter={ ['has', 'point_count'] }
          paint={ {
            "text-color": '#FFFFFF'
          } }
          layout={ {
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'icon-image': 'location-cluster-marker',
            'icon-size': .8,
            'text-font': ['Arial Unicode MS Bold'],
            'text-field': '{point_count_abbreviated}',
            'text-size': 12,
            'text-anchor': 'center'
          } }
          source="r__criticalLocationSource"
        />

        { CriticalLocationLayerConfig.map((layer) => (
          <DynamicallyVisibleLayer
            key={ layer.id }
            toggleableLayerType={ layer.toggleableLayerType }
            id={ layer.id }
            type={ 'symbol' }
            paint={ {
              ...layer.paint
            } }
            layout={ layer.layout }
            filter={ [
              'all',
              ['!has', 'point_count'],
              layer.filter
            ] }
            interactive
            source="r__criticalLocationSource"
          />)) }


        { openDrawerId !== SITE_INFO_CONTROL_DRAWER_ID &&
          popups
            .filter(({ id }) => id.startsWith(BigMapPopupIds.LocationMarker))
            .map(({ id, position, data }) => (
              <Popup
                key={ id + (data as Feature<Point, GeoJsonProperties>).properties?.id }
                longitude={ position[0] }
                latitude={ position[1] }
                /* eslint-disable-next-line no-magic-numbers */
                backgroundColor={ theme.colors.neutral[8] }
                padding={ "0" }
                offset={ popupOffsetY }
                // eslint-disable-next-line react/jsx-no-bind
                onClose={ () => closePopup(id) }
                closeButton={ true }
                closeOnClick={ false }
              >
                <LocalErrorBoundary>
                  <CriticalLocationPopup
                    popupId={ id }
                    onClickZoomTo={ onClickZoomTo }
                    onClickMoreDetails={ onClickMoreDetails }
                  />
                </LocalErrorBoundary>
              </Popup>
            )) }

        { openDrawerId !== SITE_INFO_CONTROL_DRAWER_ID &&
          popups
            .filter(({ id }) => id.startsWith(BigMapPopupIds.LocationCluster))
            .map(({ id, position, data }) => (
              <CriticalLocationClusterPopup
                key={ id +
                  (data as Array<Feature<Point, GeoJsonProperties>>)
                    .map(({ properties }) => properties?.id)
                    .join() }
                popupId={ id }
                longitude={ position[0] }
                latitude={ position[1] }
                onClickClose={ onClickClosePopup }
                onClickLocationItem={ onClickLocationItem }
              />
            )) }

      </Source>
    </>
  );

};

export default CriticalLocationLayerComponent;
