import { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import { Dispatch } from "@/core/store";
import { PlaybookSource } from "@/tenant-context/employee-app-action-response-center/api/playbook";
import { PlaybookContext } from "@/tenant-context/employee-app-action-response-center/components/ui/Playbook/Playbook.context";

import Playbook from './Playbook.component';

type Props = {
  /** The place where you use a playbook: ARC, EA ARC, etc. */
  source: PlaybookSource,
};

const PlaybookProvider: FC<Props> = ({ source }) => {
  const [newAction, setNewAction] = useState('');

  const {
    playbook: {
      createAction
    }
  } = useDispatch<Dispatch>();

  const handleNewActionInput = useCallback((ev) => {
    setNewAction(ev.target.value);
  }, []);

  const handleSaveClick = useCallback(async (ev) => {
    ev.preventDefault();

    if (!newAction || !newAction?.trim()?.length) {
      return;
    }

    const isSuccessful = await createAction({ actionText: newAction, source });

    if (isSuccessful) {
      setNewAction('');
    }
  }, [createAction, newAction, source]);

  return (
    <PlaybookContext.Provider value={ useContextValue({
      handleSaveClick,
      handleNewActionInput,
      newAction
    }) }>
      <Playbook />
    </PlaybookContext.Provider>
  );
};

export default PlaybookProvider;