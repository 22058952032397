import { Button, Col, Grid, TextInput } from "@mantine/core";
import { FC } from "react";
import { FieldValues, UseFormHandleSubmit, UseFormRegister } from "react-hook-form";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";

import { useLocationsListStyles } from "../../LocationsList.styles";

interface Props {
    register: UseFormRegister<FieldValues>,
    handleSubmit: UseFormHandleSubmit<FieldValues>,
    errors: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: any;
    },
    onLocationCategoryEditSubmit: (formValues: FieldValues) => void
}

const AddCategoryComponent: FC<Props> = ({ register, handleSubmit, errors, onLocationCategoryEditSubmit  }) => {

  const { classes } = useLocationsListStyles();

  return (
    <form autoComplete="off" onSubmit={ handleSubmit(onLocationCategoryEditSubmit) }>
      <Grid>
        <Col span={ 12 }>
          <div className={ classes.section }>
            <TextInput
              label={ <span className={ classes.asterisk }>Category Name</span> }
              maxLength={ 50 }
              error={ errors?.name?.message }
              // eslint-disable-next-line react/jsx-props-no-spreading
              { ...register(
                'name',
                addErrorMessages('Location Category Name', {
                  required: true
                })
              ) }
            />
          </div>
        </Col>
      </Grid>
      <div className={ classes.extendedMargin + " " + classes.modalButtons }>
        <Button
          size='md'
          type={ 'submit' }
        >
          Save Changes
        </Button>
      </div>
    </form>
   
  );
};

export default AddCategoryComponent;