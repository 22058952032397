import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import useDrawer from '@/common/hooks/useDrawer';
import { Dispatch, RootState } from "@/core/store";
import { GeofenceVisualizeContext } from '@/tenant-context/visualisation-geofences/context/GeofenceVisualize.context';

import VisualizedGeofenceDrawerComponent from './VisualizedGeofenceDrawer.component';

const VisualizedGeofenceDrawerContainer: FC = () => {
  const {
    visibleGeofenceOnBigMap
  } = useSelector((state: RootState) => state.geofence);

  const {
    withinGeofenceData
  } = useContext(GeofenceVisualizeContext);

  const {
    geofence: {
      openEditGeofenceDrawer,
      hideGeofencesOnBigMap,
      openAllGeofencesDrawer
    }
  } = useDispatch<Dispatch>();

  const { open, close, isOpen } = useDrawer('visualized-geofence', 'right');

  const [metaData, setMetaData] = useState<{
    lastUpdatedDate: string;
    lastUpdatedTime: string;
    createdDate: string;
    createdTime: string;
  }>();

  const handleEditGeofenceClick = useCallback(() => {
    if (visibleGeofenceOnBigMap && visibleGeofenceOnBigMap.tid) {
      openEditGeofenceDrawer(visibleGeofenceOnBigMap.tid);
    }
  }, [openEditGeofenceDrawer, visibleGeofenceOnBigMap]);

  const onDrawerClose = useCallback(() => {
    hideGeofencesOnBigMap();
  }, [hideGeofencesOnBigMap]);

  const goBackToGeofences = useCallback(() => {
    hideGeofencesOnBigMap();
    openAllGeofencesDrawer();
  }, [hideGeofencesOnBigMap, openAllGeofencesDrawer]);

  useEffect(() => {
    if (!visibleGeofenceOnBigMap && isOpen) {
      close();
    }

    if (visibleGeofenceOnBigMap) {

      const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit',  month: '2-digit',  year: 'numeric' };
      const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit', hour12: true };
      
      setMetaData({
        lastUpdatedDate: new Date(visibleGeofenceOnBigMap.lastUpdatedDateTime || '').toLocaleDateString('en-GB', dateOptions),
        lastUpdatedTime: new Date(visibleGeofenceOnBigMap.lastUpdatedDateTime || '').toLocaleTimeString('en-GB', timeOptions),
        createdDate: new Date(visibleGeofenceOnBigMap.createdDateTime || '').toLocaleDateString('en-GB', dateOptions),
        createdTime: new Date(visibleGeofenceOnBigMap.createdDateTime || '').toLocaleTimeString('en-GB', timeOptions)
      });
      
      open();
    }
  }, [close, isOpen, open, visibleGeofenceOnBigMap]);

  return (
    <VisualizedGeofenceDrawerComponent
      onEditGeofenceClick={ handleEditGeofenceClick }
      selectedGeofence={ visibleGeofenceOnBigMap }
      metaData={ metaData }
      inSideGeofenceData={ withinGeofenceData }
      onDrawerClose={ onDrawerClose }
      goBackToGeofences={ goBackToGeofences }
    />
  );
};

export default VisualizedGeofenceDrawerContainer;
