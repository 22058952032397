import { FC } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import PlatformRouter from "@/platform-context/core/components/Router/Router.component";
import TenantRouter from "@/tenant-context/core/components/Router/Router.component";


const ContextRouter: FC = () => {
  const currentContext = useSelector((state: RootState) => state.sharedData.currentContext);

  if(currentContext === "tenant"){
    return <TenantRouter />;
  }
  
  return <PlatformRouter />;
};

export default ContextRouter;