import { FC, useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { formatDateTime } from "@/tenant-context/common/util/data-standardize";
import ImpactSummaryComponent from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactSummary.component";
import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";
import { generateArcCaseImpactSummary } from "@/tenant-context/control-action-response-center/helpers/arc.helper";
import { ArcImpactStatus, ArcPeopleImpactSummary } from "@/tenant-context/control-action-response-center/types/ARC.types";

type Props = {
  isOpenInImpactTab?: boolean;
}

const ImpactSummary: FC<Props> = ({
  isOpenInImpactTab = false
}) => {
  const selectedImpactStatus = useSelector((state: RootState) => state.arc.selectedImpactStatus);

  const { arcCase, riskAlert, impactedDetails, setActiveTab } = useContext(ArcContext);

  const {
    arc: {
      SET_SELECTED_IMPACT_STATUS
    }
  } = useDispatch<Dispatch>();

  const impactSummary: ArcPeopleImpactSummary = useMemo(
    () => generateArcCaseImpactSummary(arcCase),
    [ arcCase ]
  );

  const [ startDate, endDate ] = useMemo(() => {
    if (!riskAlert) {
      return [ '••••-••-••', '••••-••-••' ];
    }

    const { source } = riskAlert;

    if (source === 'bsoc') {
      return [
        new Date(riskAlert?.json.alert.start_date).toLocaleDateString().replaceAll('/','-'),
        new Date(riskAlert?.json.alert.end_date).toLocaleDateString().replaceAll('/','-')
      ];
    } else if (source === 'dataminr' || source === 'max-security') {
      return [
        formatDateTime(riskAlert?.json.alert.start_date, 'YYYY-MM-DD'),
        formatDateTime(riskAlert?.json.alert.end_date, 'YYYY-MM-DD')
      ];
    }

    return [ riskAlert?.json.alert.start_date.toString(), riskAlert?.json.alert.end_date.toString() ];
  }, [ riskAlert ]);

  const impactedSites = useMemo<{ siteName: string, siteAddress: string }[]>(() => {
    if (!impactedDetails) {
      return [];
    }

    return impactedDetails
      .filter((detail) => detail.impactedAssetType === 'LOCATION')
      .map(({ assetId }) => ({
        siteName: assetId as string,
        siteAddress: 'N/A'
      }));
  }, [ impactedDetails ]);

  const handleSelectedImpactStatus = useCallback((status: ArcImpactStatus) => {
    if (selectedImpactStatus === status) {
      SET_SELECTED_IMPACT_STATUS(undefined);

      return;
    }
    SET_SELECTED_IMPACT_STATUS(status);
    setActiveTab('impact');
  }, [SET_SELECTED_IMPACT_STATUS, selectedImpactStatus, setActiveTab]);

  return (
    <ImpactSummaryComponent
      impactSummary={ impactSummary }
      impactedSites={ impactedSites }
      startDate={ startDate }
      endDate={ endDate }
      isOpenInImpactTab={ isOpenInImpactTab }
      selectedImpactStatus={ selectedImpactStatus }
      onImpactStatusCardClicked={ handleSelectedImpactStatus }
    />
  );
};

export default ImpactSummary;
