import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { formatDateToString } from "@/common/util/format/date";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import { generateTokenKey, getExcludedTenantSites, getSiteConfigurationById, getSiteConfigurations, getSiteVersions, postSiteConfiguration, updateSiteConfiguration } from "../api/siteConfigurations";
import { SiteConfigByIdObj, SiteConfigItem,SiteConfigListType, SiteConfigObj,SiteTokenKeyObj } from "../types/siteConfiguration.types";


export type SiteConfigurationState = {
    excludedSites: Array<{label: string, value: string}>,
    buildVersions: Array<{label: string, value: string}>,
    privateKey: string
}

const siteConfigurationState: SiteConfigurationState = {
  excludedSites: [],
  buildVersions: [],
  privateKey: ''
};

const siteConfigurationModel = ({
  name: 'siteConfiguration',
  state: siteConfigurationState,
  reducers: {
    SET_EXCLUDED_SITES(state: SiteConfigurationState, payload: Array<{ label: string, value: string }>) {
      return {
        ...state,
        excludedSites: payload
      };
    },
    SET_BUILD_VERSIONS(state: SiteConfigurationState, payload: Array<{ label: string, value: string }>) {
      return {
        ...state,
        buildVersions: payload
      };
    },
    SET_CONFIG_KEYS(state: SiteConfigurationState, payload: { privateKey: string }) {
      return {
        ...state,
        privateKey: payload.privateKey
      };
    },
    CLEAR_CONFIGURATION_DETAILS(_state: SiteConfigurationState) {
      return {
        excludedSites: [],
        buildVersions: [],
        publicKey: '',
        privateKey: ''
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async getSiteConfigurationList(payload: {
      gridParams: GridParams,
    }, _state: RootState):
      Promise<PaginatedResult<SiteConfigListType>> {
      const siteConfigList = await getSiteConfigurations(
        window.location.search.split("tenantRef=")[1], 
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      return siteConfigList;
    },
    async getSiteConfiguration(config: SiteConfigByIdObj, _state: RootState):
      Promise<SiteConfigItem> {
      const siteConfigItem = await getSiteConfigurationById(config.configId, config.tenantId);
      return siteConfigItem;
    },
    async getSiteBuildVersions():
      Promise<void> {
      const siteVersions = await getSiteVersions();
      if (siteVersions && siteVersions?.length > 0) {
        dispatch.siteConfiguration.SET_BUILD_VERSIONS(siteVersions.map(ver => ({
          label: `${ver.version} version - ${formatDateToString(new Date(ver.buildTimestamp))}`, value: ver.version
        })));
      }
    },
    async loadInitialConfigurations(tenantId: string, _state: RootState): Promise<void> {
      const sites = await getExcludedTenantSites(tenantId);
      dispatch.siteConfiguration.SET_EXCLUDED_SITES(sites?.map(site => ({ label: site.name, value: site.tid })));
    },
    async generateConfigKey(reqObj: SiteTokenKeyObj, _state: RootState): Promise<string> {
      const generatedKey = await generateTokenKey(reqObj.tenantId, reqObj.siteId);
      dispatch.siteConfiguration.SET_CONFIG_KEYS({ privateKey: generatedKey.access_token });

      return generatedKey.access_token;
    },
    async submitSiteConfiguration(
      config: SiteConfigObj,
      _state: RootState
    ): Promise<boolean> {
      if(config.requestType === 'post'){
        const response = await postSiteConfiguration(config.configuration, config.tenantId);
        if(response){
          showNotification({
            title: 'Successful',
            message: 'Configuration successfully added!',
            color: 'success'
          });

          return true;
        } else {
          showNotification({
            title: 'Error',
            message: 'Error occured when adding the configuration',
            color: 'error'
          });

          return false;
        }
      } else {
        const resp = await updateSiteConfiguration(config.configuration, config.tenantId);
        if (resp){
          showNotification({
            title: 'Successful',
            message: 'Configuration successfully updated!',
            color: 'success'
          });

          return true;
        } else {
          showNotification({
            title: 'Error',
            message: 'Error occured when updating the configuration',
            color: 'error'
          });

          return false;
        }
      }

    }
  })
});

export const siteConfiguration = createModel<RootModel>()(
  siteConfigurationModel
);
