import { ICellRendererParams, RowClickedEvent } from "ag-grid-community";
import { FC, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import { DataGrid } from "@/common/types/ag-grid";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import { LocationZoneResponse } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

import { LocationInfoContext } from "../../../context/LocationInfo.context";
import ZoneTabComponent from "./ZoneTab.component";

const LocationZones: FC = () => {

  const [isLocationZoneListView, setIsLocationZoneListView] = useState(true);
  const ref = useRef<DataGrid>(null);

  const { isAuthorizedToEdit } = useContext(LocationInfoContext);

  const {
    manageLocations:{
      deleteLocationZone,
      getLocationZonesData,
      TOGGLE_MAP,
      TOGGLE_FOOTER,
      SET_SELECTED_LOCATION_ZONE
    }
  } = useDispatch<Dispatch>();

  const enteredLocationDetails = useSelector((state:RootState) => state.manageLocations.enteredLocationDetails);

  const toggleLocationZoneListView = useCallback(()=>{
    setIsLocationZoneListView(isList => !isList);
  }, []);

  const handleLocationZoneEditClick = useCallback((data: LocationZoneResponse)=>{
    SET_SELECTED_LOCATION_ZONE(data);
    setIsLocationZoneListView(false);
  }, [SET_SELECTED_LOCATION_ZONE]);

  const handleLocationZoneDelete = useCallback((confirm = false) => async (zoneId: string) =>{
    if(!confirm){
      openConfirmationModal({
        text: "Are you sure you want to remove this zone?",
        onConfirm: async () => await handleLocationZoneDelete(true)(zoneId),
        title: "Confirm Removal"
      });
    } else{
      const result = await deleteLocationZone(zoneId);
      if(result){
        ref.current?.refreshGrid();
        showNotification({
          message: 'Record removed successfully',
          color: 'success',
          title: "Success"
        });
      }
    }
  }, [deleteLocationZone]);

  useEffect(()=>{
    TOGGLE_MAP(!isLocationZoneListView);
    TOGGLE_FOOTER(false);

    return(()=>{
      TOGGLE_MAP(true);
      TOGGLE_FOOTER(true);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocationZoneListView]);

  const actionCellRenderer = useCallback((_params: ICellRendererParams): ReactNode => {
    return null;
    //temporary
    // <ActionIcon
    //   disabled={ !isAuthorizedToEdit }
    //   // eslint-disable-next-line react/jsx-no-bind
    //   onClick={ isAuthorizedToEdit? () => handleLocationZoneDelete(false)(params.data.id) : undefined }
    //   className={ classes.btn }
    // >
    //   <TrashIcon />
    // </ActionIcon>

  }, []);

  const handleRowClicked = useCallback((event: RowClickedEvent<LocationZoneResponse>) =>{
    if(event.api.getFocusedCell()?.column.getColId() !== "action"){
      if(event.data){
        handleLocationZoneEditClick(event.data);
      }
    }

  }, [handleLocationZoneEditClick]);

  return (
    <ZoneTabComponent
      onLocationZoneEditClick={ handleLocationZoneEditClick }
      actionCellRenderer= { actionCellRenderer }
      isLocationZoneListView={ isLocationZoneListView }
      toggleLocationZoneListView={ toggleLocationZoneListView }
      getData={ getLocationZonesData }
      locationCategory = { enteredLocationDetails.subCategoryName || "" }
      ref = { ref }
      onRowClicked={ handleRowClicked }
      isAuthorizedToEdit = { isAuthorizedToEdit }
    />
  );
};

export default LocationZones;
