import { FileWithPath } from "@mantine/dropzone";
import { closeAllModals } from "@mantine/modals";
import { createContext, FC, useCallback, useEffect, useState } from "react";
import { FileRejection } from 'react-dropzone';
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { useConfirmationDialog } from "@/common/hooks/useConfirmationDialog";
import useContextValue from "@/common/hooks/useContextValue";
import { toHumanReadableFileSize } from "@/common/util/file-size";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import ProfileDocumentsModal from "@/tenant-context/control-profile/components/modals/ProfileDocumentsModal/ProfileDocumentsModal.component";
import { ProfileDocumentsBasicInfoTabFormData } from "@/tenant-context/control-profile/components/tabs/ProfileDocumentsBasicInfoTab/ProfileDocumentsBasicInfoTab.component";
import { ProfileDocumentsTab } from "@/tenant-context/control-profile/components/ui/ProfileDocumentsTabs/ProfileDocumentsTabs.component";
import { ProfileDocumentDetails, ProfileDocumentFile } from "@/tenant-context/control-profile/types/profile";

type ProfileDocumentsModalContextType = {
  formControls: UseFormReturn,
  onSubmit: (ev: React.SyntheticEvent<HTMLFormElement>) => void,
  handleAcceptedFiles: (files: FileWithPath[]) => void,
  handleRejectedFiles: (files: FileRejection[]) => void,
  removeFile: (file: FileWithPath | ProfileDocumentFile) => void,
  files: FileWithPath[],
  activeTab: ProfileDocumentsTab,
  setActiveTab: (tab: ProfileDocumentsTab) => void,
  isWithoutFilesError: boolean,
  downloadFile: (file: ProfileDocumentFile) => void,
  editableDocument: ProfileDocumentDetails | undefined,
  filesFromServer: ProfileDocumentFile[],
  maxDocumentFileSizeB: number,
  currentUserId?: string | undefined
};

export const ProfileDocumentsModalContext = createContext<ProfileDocumentsModalContextType>(
  {} as ProfileDocumentsModalContextType
);

type Props = {
  mode?: 'add' | 'edit',
  editableDocumentId?: string,
  currentUserId?: string | undefined
}

const isFileFromServer = (file: FileWithPath | ProfileDocumentFile): file is ProfileDocumentFile => {
  return !!(file as ProfileDocumentFile).fileIdentifier;
};

export const ProfileDocumentsModalProvider: FC<Props> = ({
  mode = 'add',
  editableDocumentId,
  currentUserId
}) => {
  const formControls = useForm({ mode: 'onChange' });
  const maxDocumentFileSizeMb = useSelector((state: RootState) => state.profile.generalConfig?.maxDocumentFileSize);
  const maxDocumentFileSizeB = (maxDocumentFileSizeMb ?? 0) * 1024 * 1024;

  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [removingFile, setRemovingFile] = useState<FileWithPath | ProfileDocumentFile>();
  const [filesFromServer, setFilesFromServer] = useState<ProfileDocumentFile[]>([]);

  const [isWithoutFilesError, setIsWithoutFilesError] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ProfileDocumentsTab>(ProfileDocumentsTab.Files);
  const [initialEditableDocument, setInitialEditableDocument] = useState<ProfileDocumentDetails | undefined>();
  const [editableDocument, setEditableDocument] = useState<ProfileDocumentDetails | undefined>();
  const {
    profile: {
      createDocument,
      getDocument,
      deleteFileFromDocument,
      uploadFilesToDocument,
      updateDocumentDetails,
      loadDocuments,
      SET_IS_PEOPLE_DOCUMENTS_UPDATED
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    (async () => {
      if (!(mode === 'edit' && editableDocumentId)) {
        return;
      }

      const document = await getDocument(editableDocumentId);
      setEditableDocument(document);
      setInitialEditableDocument(document);
      setFilesFromServer(document.documents);
    })();

    return () => {
      setRemovingFile(undefined);
    };
  }, [editableDocumentId, getDocument, mode]);

  const handleSubmit = useCallback(async (data: unknown) => {
    const formData = data as ProfileDocumentsBasicInfoTabFormData;
    const {
      documentName: name,
      documentNumber: number,
      comments,
      documentType,
      documentExpiryDate: expiryDate
    } = formData;

    const isWithoutFiles = files.length + filesFromServer.length === 0;
    const isFormEmpty = Object.keys(formData).length === 0;
    const isFormWithErrors = formControls.formState.isValid === false;

    if (isWithoutFiles) {
      setIsWithoutFilesError(true);
      setActiveTab(ProfileDocumentsTab.Files);

      return;
    } else if (isFormWithErrors || isFormEmpty) {
      setActiveTab(ProfileDocumentsTab.BasicInfo);

      return;
    }
    const body = {
      name,
      number,
      comments,
      documentType,
      expiryDate: Number(expiryDate)
    };

    try{
      if (mode === 'add') {
        await createDocument({
          files,
          body
        });
      } else {
        const promises: Promise<unknown>[] = [];
        const initialFiles = initialEditableDocument?.documents;
  
        if (!initialFiles || !editableDocumentId) {
          return closeAllModals();
        }
  
        // 1. Erase removed files
        const removedFiles = initialFiles.filter(
          (file) => !filesFromServer.some(
            (fileFromServer) => fileFromServer.fileIdentifier === file.fileIdentifier
          )
        );
  
        removedFiles.forEach((file) => {
          promises.push(
            deleteFileFromDocument({
              documentId: editableDocumentId as string,
              fileId: file.fileIdentifier
            })
          );
        });
  
        // 2. Upload new files
        if (files.length) {
          promises.push(
            uploadFilesToDocument({ files, documentId: editableDocumentId })
          );
        }
  
        // 3. Update document data)
        promises.push(
          updateDocumentDetails({
            documentId: editableDocumentId,
            data: body
          })
        );
  
        await Promise.all(promises);
        await loadDocuments();

      }
      await closeAllModals();
      SET_IS_PEOPLE_DOCUMENTS_UPDATED(true);

     
    } catch (e) {
      if (e instanceof Error) {
        showNotification({
          message: e?.message,
          color: 'error'
        });
      }
    }
    

   
  }, [files, filesFromServer, formControls.formState.isValid,
    mode, createDocument, initialEditableDocument?.documents,
    editableDocumentId, updateDocumentDetails, loadDocuments,
    deleteFileFromDocument, uploadFilesToDocument]);

  const downloadFile = useCallback((file: ProfileDocumentFile) => {
    window.open(file.downloadURL, '_blank');
  }, []);

  const handleAcceptedFiles = useCallback((acceptedFiles: FileWithPath[]) => {
    setFiles((f) => [...f, ...acceptedFiles]);
  }, []);

  const handleRejectedFiles = useCallback((rejectedFiles: FileRejection[]) => {

    const rejectionErrorMessages: Record<string, string> = {
      "file-invalid-type": "Invalid file type was uploaded. Kindly upload a file from the supported file types.",
      "file-too-large": `File is larger than the maximum allowed size of ${toHumanReadableFileSize(maxDocumentFileSizeB ?? 0)}`
    };

    rejectedFiles.forEach((rejection) => {
      const [error] = rejection.errors;
      const errorCode = error.code;
      const errorMessage = (errorCode in rejectionErrorMessages) ? rejectionErrorMessages[errorCode] : error.message;

      showNotification({
        title: `Error in ${rejection.file.name}`,
        message: errorMessage,
        color: 'error'
      });
    });
  }, [maxDocumentFileSizeB]);

  const removeFile = useCallback(() => {
    if(!removingFile){
      return;
    }
    
    if (isFileFromServer(removingFile)) {
      setFilesFromServer(
        (f) => f.filter((fileInCollection) => fileInCollection !== removingFile)
      );
    } else {
      setFiles(
        (f) => f.filter((fileInCollection) => fileInCollection !== removingFile)
      );
    }
  }, [removingFile]);

  const { DialogComponent, openDialog } = useConfirmationDialog({
    onConfirm: removeFile,
    text: "Are you sure you want to remove this file?"
  });

  const handleDeleteFileClick = useCallback((confirm = false) => (file: FileWithPath | ProfileDocumentFile) =>{
    if(!confirm){
      setRemovingFile(file);
      openDialog();
      return;
    }

    removeFile();
  }, [openDialog, removeFile]);

  const onSubmit = formControls.handleSubmit(handleSubmit);

  return (
    <ProfileDocumentsModalContext.Provider value={ useContextValue({
      formControls,
      onSubmit,
      handleAcceptedFiles,
      handleRejectedFiles,
      files,
      removeFile: handleDeleteFileClick(false),
      activeTab,
      setActiveTab,
      isWithoutFilesError,
      downloadFile,
      editableDocument,
      filesFromServer,
      maxDocumentFileSizeB,
      currentUserId
    }) }>
      <DialogComponent />
      <ProfileDocumentsModal />
    </ProfileDocumentsModalContext.Provider>
  );
};
