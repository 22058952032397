import { createStyles } from "@mantine/core";

export const useSelectItemStyles = createStyles((theme) => ({
  locationName: {
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.other.lh.l,
    fontWeight:  theme.other.fw.bold
  },
  locationAddress: {
    marginTop: '8px',
    color: theme.colors.neutral[4],
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.other.lh.s
  }
}));
