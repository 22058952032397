import { createStyles } from "@mantine/core";


export const useProfileProfileTabStyles = createStyles((theme) => ({
  block: {
    display: 'flex',
    gap: 24,
    position: 'relative'
  },

  profilePicture: {
    objectFit: 'cover',
    marginTop: 1,
    width: 275,
    height: 275
  },

  profilePictureContainer: {
    flexShrink: 0,
    width: 275,
    height: 275,
    position: 'sticky',
    top: 24,
    backgroundColor: theme.colors.neutral[8],
    zIndex: 1
  },

  editProfilePictureButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.colors.neutral[7] + ' !important',
    padding: '6px 7px 5px 9px'
  },

  editProfilePictureButtonWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },

  contentContainer: {
    flexGrow: 1
  },

  hiddenDropzone: {
    display: 'none'
  }
}));
