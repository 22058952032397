import { createStyles } from "@mantine/core";

export const useNotificationSummaryStyles = createStyles(() => ({

  channelCell: {
    display: 'flex',
    alignItems: 'center',

    '& > *:not(:first-child)': {
      marginLeft: 8
    }
  }
}));
