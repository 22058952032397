export const EaArcPoliciesConfig = {
  OPEN_CASE: {
    'policyNames': [
      'Tenant_LoneWorker_ARC_FullAccess',
      'Tenant_LoneWorker_ARC_ReadOnly'
    ]
  },
  EDIT_PLAYBOOK: {
    policyNames: [
      'Tenant_LoneWorker_ARC_FullAccess'
    ]
  }
};
