import { createStyles } from "@mantine/core";

type Props = {
  riskLevelIndex: number,
  direction?: 'row' | 'column'
}

export const useTaskSummaryStyles = createStyles((theme, {
  riskLevelIndex,
  direction = 'row'
}: Props) => ({
  root: {
    backgroundColor: theme.colors.neutral[8],
    padding: '25px 40px',
    borderBottom: `3px solid ${ theme.colors.risk[riskLevelIndex] }`
  },

  alarmBadgeContainer: {
    display: 'flex',
    flexDirection: direction,
    gap: 10
  },

  alarmBadge: {
    marginTop: 2
  },

  label: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[5],
    textTransform: "uppercase",
    display: 'block'
  },

  value: {
    fontSize: theme.fontSizes.sm,
    letterSpacing: 0.2,
    display: 'block'
  },

  emphasized: {
    fontWeight: theme.other.fw.bold
  },

  labeledValueContainer: {

  },

  topValueBlock: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  topValueBlockRight: {
    display: 'flex',
    gap: 8,
    fontSize: theme.fontSizes.sm
  },

  bottomValueBlock: {
    flexWrap: 'wrap',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  }
}));