import { createStyles } from "@mantine/core";

export const useProfileTagsSectionStyles = createStyles((theme) => ({
  rootContainer: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
  container: {
    display: 'flex',
    padding: '16px 0',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    maxWidth: 275,
    height: 'auto',
    gap: 8,
    alignSelf: 'stretch',
    borderTop: `0.5px solid ${theme.colors.neutral[6]}`,
    borderBottom: `0.5px solid ${theme.colors.neutral[6]}`,
    minHeight: 69,
    transitionDuration: '500ms !important'
  },
  heading: {
    color: theme.colors.neutral[5],
    fontSize: 16
  },
  seeMore: {
    all: 'unset',
    color: theme.colors.info[0],
    fontSize: 14,
    padding: '10px 0',
    textAlign: 'right',
    cursor: 'pointer',
    '&[disabled]': {
      color: theme.colors.neutral[4],
      cursor: 'default'
    }
  }
}));
