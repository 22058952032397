import { FC } from 'react';

import { useSpinnerStyles } from '@/common/components/Spinner/Spinner.styles';
import spinner from '@/core/components/SSOIntegration/images/spinner.png';

const Spinner: FC = () => {

  const { classes } = useSpinnerStyles();

  return (
    <img src={ spinner } alt={ 'Loading...' } className={ classes.spinner }/>
  );
};

export default Spinner;
