import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

type Props = {
  totalVideos: number;
}

export const useVideoProgressBarStyles = createStyles((theme: MantineTheme, { totalVideos }: Props) => ({
  wrapper: {
    display: 'grid',
    gridColumnGap: 10,
    gridTemplateColumns: `repeat(${ totalVideos }, 1fr)`,
    height: 5,
    top: 18,
    position: 'absolute',
    width: '100%',
    padding: '0 8px'
  },

  videoItem: {
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.25);',
    borderRadius: 10,

    '& > div': {
      height: '100%',
      borderRadius: 10
    }
  },

  currentVideoItem: {

    '& > div': {
      width: '50%',
      backgroundColor: 'white',
      height: '100%',
      borderRadius: 10
    }
  },

  playedVideoItem: {

    '& > div': {
      backgroundColor: 'white',
      height: '100%',
      borderRadius: 10
    }
  }
}));
