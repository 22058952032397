import { Button, LoadingOverlay, Stepper } from "@mantine/core";
import { FC, useCallback } from "react";

import PersonAvatar from "@/common/components/PersonAvatar";
import Spinner from "@/common/components/Spinner";
import { ReactComponent as BackIcon } from "@/common/icons/Arrows/back-arrow.svg";
import { ReactComponent as RightIcon } from "@/common/icons/Arrows/right-arrow.svg";
import TravelSegmentWrapper from "@/tenant-context/control-profile/components/ui/TravelSegmentWrapper";
import {
  PersonTravelItenerary
} from "@/tenant-context/visualisation-people/types/people.types";

import { TravellerData } from "../../types/travelSearch.types";
import { useTravellersMiniProfileDrawerStyles } from "./TravellersMiniProfilDrawer.styles";


type Props = {
  onBackClick: () => void;
  traveller: TravellerData | undefined;
  openProfileClick: () => void;

  // legacy itineraries
  travelIteneraryInfo?: PersonTravelItenerary,
  onCalculateTripProgress: (startDate: number, endDate: number) => {
    progress: number,
    status: 'past' | 'present' | 'future'
  },
  onActiveStepper: () => number,
}

export const TravellersMiniProfileDrawerComponent: FC<Props> = ({
  onBackClick,
  traveller,
  openProfileClick,

  // legacy itineraries
  travelIteneraryInfo,
  onCalculateTripProgress,
  onActiveStepper: _onActiveStepper
}) => {

  const { classes } = useTravellersMiniProfileDrawerStyles();

  const handleProfileClick = useCallback(() => {
    openProfileClick();
  }, [openProfileClick]);

  return (
    <div>
      <button className={ classes.backIcon } onClick={ onBackClick }>
        <BackIcon width={ 16 } height={ 16 }/>
        Back to country
      </button>
      <div className={ classes.profileContainer }>
        <PersonAvatar
          width="84px"
          height="84px"
          personName={ traveller?.personName }
          personAvatarUrl={ traveller?.personProfilePictureUrl }
          className={ classes.avatar }
        />
        <div>
          <h4>{ traveller?.personName }</h4>

          { traveller?.personRole &&
            <p className={ classes.position }>{ traveller.personRole }</p> }

          { traveller?.countryOfResident && <p className={ classes.resident }>
            Resident of <span className={ classes.residentCountry }>{ traveller?.countryOfResident }</span>
          </p> }

          <a className={ classes.email } href={ `mailto:${traveller?.personName}` }>{ traveller?.personEmail }</a>
        </div>
      </div>
      <Button
        onClick={ handleProfileClick }
        rightIcon={ <RightIcon/> }
        variant={ 'outline' }
        fullWidth>
        View Full Profile
      </Button>

      { /* legacy itineraries */ }
      <div className={ classes.iteneraryContainer }>
        <LoadingOverlay
          visible={ travelIteneraryInfo === undefined }
          overlayBlur={ 2 }
          loader={ <div className={ classes.loader }>
            <Spinner/>
            <div>Searching...</div>
          </div> }
        />

        { /*<Stepper className={ classes.stepper } active={ onActiveStepper() } orientation="vertical">*/ }
        { /*  { travelIteneraryInfo?.map((itenerary, index) => {*/ }
        { /*    return (<Stepper.Step*/ }
        { /*      key={ index }*/ }
        { /*      className={ classes.stepperStep }*/ }
        { /*      icon={ <TravelIcon iconName={ itenerary.type }/> }*/ }
        { /*      completedIcon={ <TravelIcon iconName={ itenerary.type }/> }/>);*/ }
        { /*  }) }*/ }
        { /*</Stepper>*/ }


        <Stepper className={ classes.stepper } active={ 1 } orientation="vertical">
          <div className={ classes.locationsWrapper }>
            { travelIteneraryInfo?.map((location, index) => {
              const fromDate = new Date(location.start_date);
              const toDate = new Date(location.end_date);
              const segmentStatus = onCalculateTripProgress(location.start_date, location.end_date).status;
              const isCompletedSegment = segmentStatus === 'past';
              const isCurrentSegment = segmentStatus === 'present';
              const isLastSegment = index === travelIteneraryInfo.length - 1;

              return (
                <TravelSegmentWrapper key={ index }
                  location={ location }
                  isCurrentSegment={ isCurrentSegment }
                  lastSegment={ isLastSegment }
                  completedSegment={ isCompletedSegment }
                  onCalculateTripProgress={ onCalculateTripProgress(location.start_date, location.end_date) }
                  fromDate={ fromDate } toDate={ toDate }/>
              );
            }) }
          </div>
        </Stepper>
      </div>
    </div>
  );
};
