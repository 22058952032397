/* eslint-disable no-magic-numbers */
import { useMantineTheme } from "@mantine/core";
import { Axis, Orientation } from "@visx/axis";
import { localPoint } from "@visx/event";
import { GridColumns, GridRows } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear, scaleUtc } from "@visx/scale";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { FC, useCallback, useMemo, useRef } from "react";

import TimeTravelControls from "@/tenant-context/control-location-graph/components/TimeTravelControls/TimeTravelControls.container";
import { RiskEventGroup } from "@/tenant-context/control-risk-timeline/components/RiskTimelineGraph/RiskEventGroup";
import {
  useRiskTimelineGraphStyles
} from "@/tenant-context/control-risk-timeline/components/RiskTimelineGraph/RiskTimelineGraph.styles";
import { RiskTimelineTooltip } from "@/tenant-context/control-risk-timeline/components/RiskTimelineGraph/RiskTimelineTooltip";
import { TodayLine } from "@/tenant-context/control-risk-timeline/components/RiskTimelineGraph/TodayLine";
import {
  riskTimelineGraphMargin, tickFormatterDate,
  tickLabelProps
} from "@/tenant-context/control-risk-timeline/config/risk-timeline.config";
import { GroupedRiskTimelineEvents, RiskTimelineTooltipData } from "@/tenant-context/control-risk-timeline/types/risk-timeline.types";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type Props = {
  height: number;
  width: number;
  chartData: GroupedRiskTimelineEvents[];
  chartWidth: number;
  chartHeight: number;
  dates: Array<Date>;
  domain: Array<number>;
  oneTickWidth: number;
  onTimeTravelForward: () => void;
  onTimeTravelBackward: () => void;
  onTimeTravelForwardForDaily: () => void;
  onTimeTravelBackwardForDaily: () => void;
  onRiskAlertClicked: (riskItem: RiskAlertEvent) => void;
}

const RiskTimelineGraphComponent: FC<Props> = ({
  width: outerWidth,
  height,
  chartData: data,
  onTimeTravelForward,
  onTimeTravelBackward,
  onTimeTravelForwardForDaily,
  onTimeTravelBackwardForDaily,
  chartWidth,
  chartHeight,
  dates,
  domain,
  oneTickWidth,
  onRiskAlertClicked
}) => {
  const tooltipTimeoutRef = useRef<number | undefined>(undefined);
  const theme = useMantineTheme();
  const { classes } = useRiskTimelineGraphStyles();

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip
  } = useTooltip<RiskTimelineTooltipData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
    detectBounds: true
  });

  //scales
  const xScale = useMemo(
    () =>
      scaleUtc({
        domain,
        range: [0, chartWidth - (riskTimelineGraphMargin.left * 5)]
      }),
    [domain, chartWidth]
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, 100],
        range: [0, chartHeight]
      }),
    [chartHeight]
  );

  const getX = useCallback((d: Date) => xScale(d) - 14 ?? 0, [xScale]);

  const nowMarkerX = useMemo(() => {
    const now = new Date();
    return getX(new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0))) - 10; // 10 as a padding for the 'Today' text width
  }, [ getX ]);

  const onTooltipMouseLeave = useCallback(() => {
    tooltipTimeoutRef.current = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [ hideTooltip ]);

  const onTooltipMouseMove = useCallback((
    event: React.MouseEvent<SVGElement>,
    riskItem: RiskAlertEvent,
    top: number,
    groupLeftOffset: number
  ) => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    // TooltipInPortal expects coordinates to be relative to containerRef localPoint returns coordinates relative
    // to the nearest SVG, which is what containerRef is set to in this example.
    const eventSvgCoords = localPoint(event);

    if (!eventSvgCoords) {
      return;
    }

    showTooltip({
      tooltipData: riskItem,
      tooltipTop: top - 40,
      tooltipLeft: groupLeftOffset
    });
  }, [ showTooltip ]);

  if (chartWidth < 16) {
    return null;
  }

  return (
    <svg ref={ containerRef } width={ outerWidth } height={ height }>
      <Group top={ riskTimelineGraphMargin.top } left={ riskTimelineGraphMargin.left + riskTimelineGraphMargin.right }>
        <g>
          { /* Indicating current year in bottom left */ }
          <Text
            dy={ chartHeight + 50 }
            fill={ theme.colors.neutral[5] }
            fontSize={ 12 }
            fontWeight={ 600 }
            className={ classes.currentYear }
          >
            { new Date(domain[0]).getFullYear() }
          </Text>

          { /* Bottom Horizontal Axis */ }
          <GridRows
            key={ `risk-timeline-grid-rows` }
            scale={ yScale }
            stroke={ theme.colors.neutral[5] }
            strokeWidth={ 1 }
            width={ chartWidth }
            numTicks={ 1 }
            offset={ chartHeight - 5 }
          />

          { /* Vertical ticks on Horizontal Axis */ }
          <GridColumns
            key={ `risk-timeline-grid-columns` }
            scale={ xScale }
            numTicks={ dates.length }
            stroke={ theme.colors.neutral[6] }
            strokeWidth={ 1 }
            height={ 16 }
            top={ chartHeight - 5 }
          />

          { /* Christmas Trees */ }
          { data.map((risksGroupedByDate) => {
            return risksGroupedByDate.items.map((risksGroupByLevel, index) => {
              const itemsPerDate = risksGroupedByDate.items.length;
              const groupLeftOffset = getX(risksGroupedByDate.date) ;

              return (
                <RiskEventGroup
                  key={ `risk-event-group-${ risksGroupedByDate.date }-${ risksGroupByLevel.riskLevel }-${ index }` }
                  groupLeftOffset={ groupLeftOffset }
                  risksGroupedByDate={ risksGroupedByDate }
                  risksGroupByLevel={ risksGroupByLevel }
                  index={ index }
                  oneTickWidth={ oneTickWidth }
                  itemsPerDate={ itemsPerDate }
                  chartHeight={ chartHeight }
                  theme={ theme }
                  onTooltipMouseMove={ onTooltipMouseMove }
                  onTooltipMouseLeave={ onTooltipMouseLeave }
                  onRiskAlertClicked={ onRiskAlertClicked }
                />
              );
            });
          }) }

          { /* X Axis */ }
          <Axis
            orientation={ Orientation.bottom }
            hideAxisLine={ true }
            axisLineClassName={ classes.axisLine }
            hideTicks={ true }
            top={ chartHeight }
            left={ 10 }
            scale={ xScale }
            numTicks={ dates.length }
            tickTransform={ `translate(${(oneTickWidth / 2) - 15}, 0)` }
            tickFormat={ tickFormatterDate }
            // eslint-disable-next-line react/jsx-no-bind
            tickLabelProps={ () => tickLabelProps(theme) }
          />

          { /*Today Line*/ }
          <TodayLine
            nowMarkerX={ nowMarkerX }
            theme={ theme }
            chartHeight={ chartHeight }
            classes = { classes }
          />
        </g>
      </Group>

      { /* Arrows to go left and right on the timeline */ }
      <Group left={ -10 }>
        <TimeTravelControls
          chartContentHeight={ height - riskTimelineGraphMargin.top - riskTimelineGraphMargin.bottom }
          chartContentWidth={ chartWidth + (riskTimelineGraphMargin.left + riskTimelineGraphMargin.right) }
          chartMargin={ riskTimelineGraphMargin }
          onForwardClick={ onTimeTravelForward }
          onBackwardClick={ onTimeTravelBackward }
          onForwardForDailyClick={ onTimeTravelForwardForDaily }
          onBackwardForDailyClick={ onTimeTravelBackwardForDaily }
        />
      </Group>

      { tooltipOpen && tooltipData && (
        <RiskTimelineTooltip
          tooltipLeft={ tooltipLeft }
          tooltipTop={ tooltipTop }
          tooltipData={ tooltipData }
          TooltipInPortal={ TooltipInPortal }
        />
      ) }
    </svg>
  );
};

export default RiskTimelineGraphComponent;
