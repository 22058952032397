export const RouterConfig = {
  routes: {
    home: '/',
    notFound: '/404',
    login: '/login',
    stream: '/stream',
    arc: (caseId = ':caseId') => `/arc/${caseId}`,
    massComms: '/mass-comms',
    massCommsCreate: `/mass-comms/create`,
    massCommsTemplates: `/mass-comms/templates`,
    massCommsResponse: `/mass-comms/response`,
    massCommsSmsResponse: `/mass-comms/sms-nav`,
    ssoIntegration: `/sso-integration`,
    massCommsEmailTemplate: '/mass-comms/email-template',
    massCommsReports: (commsId = ':tid') => `/mass-comms/report/${commsId}`,
    peopleGroups: `/people-groups`,
    bigProfile: (userId = ':userId') => `/profile/${userId}`,
    peopleGroupsId: (groupId = ':groupId') => `/people-groups/${groupId}`,
    profileList: '/profile/profile-list',
    unProcessedProfiles: (id = ':id') => `/profile/un-processed-profiles/${id}`,
    geofenceManage: '/geofence/manage',
    lookupTool: '/lookup-tool',
    // Demos
    assetTrackingShowcase: '/demo/asset-tracking',
    icmConfig: {
      situationTypes: '/icm-config/threat-types',
      situationTypeDetails:  (id = ":id") => `/icm-config/threat-types/${id}`,
      eventsManagement: '/icm-config/events',
      severityLevels: '/icm-config/severity-levels',
      severityLevelsId: (id = ':id', type = ':type') => `/icm-config/severity-levels/${id}/${type}`,
      caseTypes: '/icm-config/case-types',
      caseTypeDetails: (id = ":id") => `/icm-config/case-types/${id}`,
      caseTypeRoleDetails: (id = ":id", roleId = ":roleId") => `/icm-config/case-types/${id}/roles/${roleId}`,
      assets: '/icm-config/assets',
      playbooks: '/icm-config/playbooks',
      playbookDetails: (id = ":id") => `/icm-config/playbooks/${id}`
    },
    systemAdmin: {
      serviceConfiguration: '/system-admin/service-configuration',
      serviceConfigurationDetails: (id = ':id') => `/system-admin/service-configuration/${id}`,
      siteConfigManagement: '/system-admin/site-configuration',
      siteConfigManagementAdd: '/system-admin/site-configuration/add',
      siteConfigManagementDetails: (id = ':id') => `/system-admin/site-configuration/${id}`
    },
    companiesManagement: {
      companies: '/companies-management/companies',
      companyId: (id = ':id') => `/companies-management/companies/${id}`
    },
    tenantAdmin:{
      tenantAccessCards: '/tenant-admin/access-cards',
      tenantAccessCardDetails: (id = ':id') => `/tenant-admin/access-cards/${id}`,
      tenantAccessCardsLayouts: '/tenant-admin/access-cards-layouts',
      tenantAccessCardLayoutDetails: (id = ':id') => `/tenant-admin/access-cards-layouts/${id}`,
      printJobList: '/tenant-admin/print-jobs',
      printJobDetails:  (id = ':id') => `/tenant-admin/print-jobs/${id}`
    },
    visitorManagement: '/visitor-management',
    visitRequest:  (id = ':id') => `/visitor-management/visit-request/${id}`,
    visitorDetails:  (id = ':id') => `/visitor-management/visitor/${id}`,
    locationConfiguration: {
      locationsList: "/location-configuration",
      addLocations: (id = ':id') => `/locations/${id}`,
      unProcessedLocations: (id = ':id') => `/location-management/un-processed-locations/${id}`

    },
    connectedSitesDashboard: '/connected-sites-dashboard',
    threatLibraryCountry: '/threat-library-country',
    threatLibraryCity: '/threat-library-city',
    locationManagement: '/location-management',
    visitorRequestDashboard: '/visitor-request-dashboard',
    showcase: '/showcase',
    tableauDashboard: '/tableau-dashboard',
    integrationMapping: {
      integrationList: '/integration-mapping',
      integrationDetails: (id = ':id') => `/integration-mapping/${id}`
    }
  },
  publicURLs: function(): string[]{
    return [this.routes.massCommsResponse, this.routes.massCommsSmsResponse];
  }
} as const;
