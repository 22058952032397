import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ChevronRight } from "@/common/icons/chevron-right.svg";
import { Dispatch, RootState } from "@/core/store";
import LiveFeedButton from "@/tenant-context/control-live-feed/components/LiveFeedButton";
import { useMassCommsHeaderStyles } from "@/tenant-context/control-mass-comms/components/MassCommsHeader/MassCommsHeader.styles";
import MassCommsWizard from "@/tenant-context/control-mass-comms/components/MassCommsStepper";

const MassCommsHeader: FC = () => {

  const {
    massCommsNavigation,
    communicationName
  } = useSelector((state: RootState) => state.massComms);

  const {
    massComms: {
      navigateToMassComms
    }
  } = useDispatch<Dispatch>();

  const { classes } = useMassCommsHeaderStyles({
    isSliderOpen: massCommsNavigation.isSliderOpen,
    isFromArc: massCommsNavigation.fromArc
  });

  const handleSliderToggle = useCallback(
    () => {
      navigateToMassComms({ ...massCommsNavigation, isSliderOpen: !massCommsNavigation.isSliderOpen });
    },
    [ navigateToMassComms, massCommsNavigation ]
  );

  return (
    <div className={ classes.headerWrapper }>
      <div className={ classes.headerTextWrapper }>
        <div
          tabIndex={ 0 }
          role={ 'button' }
          className={ classes.sliderText }
          onKeyDown={ handleSliderToggle }
          onClick={ handleSliderToggle }>
          <ChevronRight />Associated Impact Event</div>
        <div className={ classes.headerText }>Mass Communication</div>
        <div className={ classes.headerCommName }><div className={ classes.headerPrefix }>{ `${communicationName.split('-')[0] || ''}` }&nbsp;</div>{ `- ${communicationName.split('-')[1] || ''}` }</div>
      </div>
      <div className={ classes.wizard }>
        <MassCommsWizard />
      </div>
      <div className={ classes.notificationContainer }>
        <LiveFeedButton/>
      </div>
    </div>
  );
};

export default MassCommsHeader;
