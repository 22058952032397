import { FC } from "react";
import { FieldValues, useForm } from "react-hook-form";

import PlaybookSelectModalComponent
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/PlaybookSelectModal/PlaybookSelectModal.component";
import { ItemProps } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

type Props = {
  cancelButtonHandler: () => void
  onSubmit: (data: FieldValues) => void
  playbookSelectOptions: ItemProps[]
}

const PlaybookSelectModal: FC<Props>= ({
  cancelButtonHandler,
  onSubmit,
  playbookSelectOptions
}) => {

  const formControls = useForm({ mode: 'onChange' });
  
  return (
    <PlaybookSelectModalComponent
      formControls={ formControls }
      cancelButtonHandler={ cancelButtonHandler }
      onSubmit={ onSubmit }
      selectOptions={ playbookSelectOptions }
    />
  );
};

export default PlaybookSelectModal;