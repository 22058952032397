/* eslint-disable import/extensions */
import { TextInput } from "@mantine/core";
import { FC } from "react";

import { TestableComponent } from "@/common/types/testable-component";
import DroppableMapTopInfoBox from "@/tenant-context/common/components/DroppableMapTopInfoBox";
import { Location } from "@/tenant-context/common/types/location";
import {
  useCountryInfoBoxStyles
} from "@/tenant-context/widget-overview/components/CountryInfoBox/CountryInfoBox.styles";

import CountrySearchDropdown from "../CountrySearchDropdown";

type Props = {
  selectedCountry?: Location,
  countryList: Location[],
  isExpanded: boolean,
  onCountrySelected: (selectedCountry: Location) => void,
  toggleIsInfoBoxExpanded: (shouldBe?: boolean) => void,
  countryMustering?: Record<string, number>
  musterCount?: number
  setCountrySearchValue: (value: string) => void
} & TestableComponent;

const CountryInfoBoxComponent: FC<Props> = ({
  selectedCountry,
  countryList,
  isExpanded,
  onCountrySelected,
  toggleIsInfoBoxExpanded,
  countryMustering,
  musterCount,
  setCountrySearchValue,
  dataTestId = "top-menu-countries-dropdown"
}) => {
  const { classes } = useCountryInfoBoxStyles();

  return (
    <DroppableMapTopInfoBox
      title={ 'Countries' }
      value={ selectedCountry?.name ?? '' }
      isExpanded={ isExpanded }
      onToggle={ toggleIsInfoBoxExpanded }
      musterCount={ musterCount }
      data-testid={ dataTestId }
    >
      { /* eslint-disable-next-line react/jsx-no-bind */ }
      <TextInput onChange={ (e) => setCountrySearchValue(e.target.value) }
        placeholder="Search for countries.." 
        className={ classes.searchInput }
        data-testid="top-menu-countries-dropdown-search-input"
      />
      <CountrySearchDropdown
        countryList={ countryList }
        onCountrySelected={ onCountrySelected }
        countryMustering={ countryMustering }
      />
    </DroppableMapTopInfoBox>
  );
};

export default CountryInfoBoxComponent;
