import { createStyles } from "@mantine/core";

type StyleParams = {
  maxColumns: number,
  gtc?: ((maxColumns: number) => string) | string
};

export const useProfileKeyValueBlockStyles = createStyles<'block', StyleParams>((theme, {
  maxColumns,
  gtc
}) => ({
  block: {
    display: 'grid',
    alignItems: "start",
    width: '100%',
    gridTemplateColumns: gtc
      ? (typeof gtc === 'function' ? gtc(maxColumns) : gtc)
      : `1fr repeat(${maxColumns - 1}, 3fr)`,
    gap: '22px 24px'
  }
}));
