import { createStyles } from "@mantine/core";

export const useGlobalSearchResultsStyles = createStyles((theme) => ({
  list: {
    marginTop: 0,
    marginBottom: 0
  },
  resultsFound: {
    marginTop: '40px',
    paddingTop: 5,
    borderTop: `1px solid ${theme.colors.neutral[6]}`,
    opacity: .7
  },
  resultsSubDescription: {
    marginTop: '32px',
    display: 'flex',
    flexDirection: 'column',
    lineHeight:theme.other.lh.s,
    color:theme.colors.neutral[2],
    gap: 12,  
    fontFamily:theme.fontFamily,
    fontSize:theme.fontSizes.md,

    '& > span': {
      display: 'block'
    }
  }
}));
