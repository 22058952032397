import { createStyles } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

type Props = {
  isInBigOverlay: boolean;
  riskLevelIndex: number;
}

export const useMiniProfileEmergencyBlockStyles = createStyles((theme, {
  isInBigOverlay,
  riskLevelIndex
}: Props) => ({
  root: {
    width: isInBigOverlay ? 'calc(100% - 40px)' : 'unset'
  },

  alarmBlock: {
    backgroundColor: genericColorPalette.neutral[9],
    position: 'relative',
    display: 'flex',
    gap: theme.spacing.md,
    padding: theme.spacing.md
  },

  alarmLine: {
    position: 'absolute',
    height: '100%',
    left: 0,
    top: 0,
    width: 4,
    flex: '0 0 4px',
    backgroundColor: theme.colors.risk[riskLevelIndex]
  },

  badge: {
    flex: '0 0 20px'
  },

  description: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.sm
  },

  activationType: {
    margin: '0 0 8px 0'
  },

  secondaryText: {
    color: theme.colors.neutral[5]
  }
}));
