import { createStyles } from '@mantine/core';

import { CSS_KEY } from '@/core/styles/mantine/constants';

export const useCreateGeofenceToolBarStyles = createStyles((theme, isMinified: boolean) => ({
  toolbarContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    padding: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: isMinified ? 'none' : `1px solid ${theme.colors.neutral[6]} !important`
  },

  toolbarComponentContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    padding: isMinified ? 8 : '18.5px 24px',
    alignItems: 'center'
  },

  inputItem: {
    input: {
      backgroundColor: 'transparent',
      height: 48,
      width: 279,
      paddingLeft: 48,
      border: `1px solid ${theme.colors.neutral[6]} !important`
    },
    [`.${CSS_KEY}-Input-icon`]: {
      paddingLeft: 18,
      width: 'auto'
    },
    marginBottom: '0px'
  },

  toolbarIcon: {
    '*': {
      transitionDuration: '300ms'
    },
    '&:hover': {
      '& > svg': {
        '& > path': {
          fill: `${theme.colors.warning[2]} !important`
        }
      }
    }
  },

  toolbarIconSelected: {
    '*': {
      transitionDuration: '300ms'
    },
    '& > svg': {
      '& > path': {
        fill: `${theme.colors.warning[2]} !important`
      }
    }
  }

}));
