import { closeAllModals } from "@mantine/modals";
import { BaseSyntheticEvent, createContext, FC, useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import { BulkProfileListCreate,PeopleGroup } from "@/tenant-context/control-groups/types/group";
import { GroupType } from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/ProfileGroupsModal.component";
import { AddProfileToExistingGroupFormData } from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/tabs/AddToExistingGroup/AddToExistingGroup.component";
import { AddProfileToNewGroupFormData } from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/tabs/AddToNewGroup/AddToNewGroup.component";
import { PaginatedResult, ReferenceDataService } from "@/tenant-context/control-profile/types/profile";

const isNewGroup = (
  d: Record<string, unknown>
): d is AddProfileToNewGroupFormData => {
  return d.group_action === GroupType.New;
};

const isExistingGroup = (
  d: Record<string, unknown>
): d is AddProfileToExistingGroupFormData => {
  return d.group_action === GroupType.Existing;
};

type ProfilePeopleGroupsModalContextType = {
  formControls: UseFormReturn,
  onSubmit: (e: BaseSyntheticEvent) => Promise<void>,
  allServices?: PaginatedResult<ReferenceDataService>,
}

export const ProfilePeopleGroupsModalContext = createContext<ProfilePeopleGroupsModalContextType>
({} as ProfilePeopleGroupsModalContextType);

type Props = {
  isBulk?: boolean,
  bulkData?: BulkProfileListCreate
}

export const ProfilePeopleGroupsModalContextProvider: FC<Props> = ({
  isBulk,
  bulkData,
  children
}) => {
  const formControls = useForm({ mode: 'onChange' });
  const currentProfileId = useSelector((state: RootState) => state.profile.id);
  const allServices = useSelector((state: RootState) => state.groups.services);

  const {
    groups: {
      loadAllServices
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    loadAllServices();
  }, [loadAllServices]);

  const {
    groups: {
      createGroup,
      addProfileToGroup,
      SET_IS_PEOPLE_GROUP_UPDATED
    }
  } = useDispatch<Dispatch>();

  const handleSubmit = async (untypedData: unknown) => {
    const data = untypedData as
      | AddProfileToExistingGroupFormData
      | AddProfileToNewGroupFormData;
    // eslint-disable-next-line fp/no-let
    let errorMessageTitle = "Unable to complete action";

    // eslint-disable-next-line fp/no-let
    let result = true;

    try {
      if (isNewGroup(data)) {
        errorMessageTitle = "Unable to create a new group";
        const newGroup: PeopleGroup = {
          name: data?.group_name,
          isApplicableToAllService: data.isApplicable_to_all_services,
          isActive: data.is_active,
          applicableServiceList: data.applicable_services,
          groupDescription: data.group_description,
          profileTidList: currentProfileId ? [currentProfileId] : []
        };

        const groupToCreate = (isBulk && bulkData) ? { ...newGroup, ...bulkData } : newGroup;

        result = await createGroup(groupToCreate, { isBulk: isBulk });
      } else if (isExistingGroup(data)) {
        errorMessageTitle = "Unable to add the profile to the group";
        await addProfileToGroup(data.group_id, { isBulk, bulkData });
      }

      if(result){
        closeAllModals();
        SET_IS_PEOPLE_GROUP_UPDATED(true);
      }
    } catch (err) {
      if(err instanceof Error){
        showNotification({
          title: errorMessageTitle,
          message: err?.message,
          color: 'error'
        });
      }
     
    }
  };

  const onSubmit = formControls.handleSubmit(handleSubmit);

  return (
    <ProfilePeopleGroupsModalContext.Provider value={ useContextValue({
      formControls,
      onSubmit,
      allServices
    }) }>
      { children }
    </ProfilePeopleGroupsModalContext.Provider>
  );
};
