import { FC } from "react";

import { VOICE_MESSAGE_TYPES } from "@/tenant-context/control-mass-comms/types/MassComms.types";

type Props = {
	audioResult: string;
  voiceMessageType: VOICE_MESSAGE_TYPES;
}
const VoiceSummaryComponent: FC<Props> = ({
  audioResult,
  voiceMessageType
}) => {

  if (voiceMessageType === VOICE_MESSAGE_TYPES.TEXT_TO_SPEECH) {
    return <div>{ voiceMessageType }</div>;
  }

  return (
  // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio id="audio" controls src={ audioResult }>
      Your browser does not support the audio element.
    </audio>
  );
};

export default VoiceSummaryComponent;
