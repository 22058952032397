import { createContext, FC, useEffect, useState } from "react";

import useContextValue from "@/common/hooks/useContextValue";
import { getCurrentTenantDetails } from "@/tenant-context/navigation/api/navigation";
import { TenantDetails } from "@/tenant-context/navigation/types/user";

export type TenantRefContextType = {
    tenantDetails: TenantDetails | null
}

export const TenantRefContext = createContext<TenantRefContextType>
({} as TenantRefContextType);

export const TenantRefContextProvider: FC = ({ children }) => {
  const [tenantDetails, setTenantDetails] = useState<TenantDetails | null>(null);

  const tenantRef = window.location.search.split("tenantRef=")[1];

  useEffect(()=>{
    if(tenantRef){
      getCurrentTenantDetails(tenantRef).then(data => setTenantDetails(data));
    }
  }, [tenantRef]);

  return (
    <TenantRefContext.Provider value={ useContextValue({
      tenantDetails
    }) }>
      { children }
    </TenantRefContext.Provider>
  );
};
