import { createStyles, MantineTheme } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";
import {
  genericColorPalette,
  semanticColorPalette
} from "@/core/styles/mantine/palettes";

export const useLocationsListStyles = createStyles((theme: MantineTheme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  headerFilterWrap:{
    marginBottom: theme.spacing.md
  },
  headerFilterLabel:{
    color: theme.colors.neutral[5]
  },
  menuDropdown: {
    backgroundColor: theme.colors.neutral[9],
    border: `1px solid ${theme.colors.neutral[8]}`
  },
  btn: {
    all: "unset",
    backgroundColor: "unset !important",
    padding: 0,
    width: "fit-content",
    cursor: "pointer"
  },
  catIcon:{
    width: 20,
    height: 20,
    marginRight: 8
  },
  addCategory: {
    display: "flex",
    alignItems: "center",
    "& span": {
      lineHeight: "0.5"
    },
    "& svg": {
      marginRight: "0.5rem"
    }
  },
  section: {
    marginBottom: 24,
    [`& .${CSS_KEY}-Textarea-input`]: {
      minHeight: 200
    }
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "0 24px"
  },
  asterisk: {
    "&:after": {
      color: genericColorPalette.error[0],
      content: '"*"',
      display: "inline-block",
      marginLeft: 4
    },
    position: "relative"
  },
  extendedMargin: {
    marginTop: 48
  },
  modalButtons: {
    display: "flex",
    justifyContent: "flex-end",

    "& > *:not(:first-child)": {
      marginLeft: 24
    }
  },
  dropdown: {
    maxHeight: "50vh",
    overflowY: "auto",
    backgroundColor: theme.colors.neutral[8],
    border: `1px solid ${theme.colors.neutral[6]}`
  },
  menuItem: {
    "&:hover, &[data-hovered]": {
      backgroundColor: genericColorPalette.neutral[9] + " !important"
    },
    fontSize: theme.fontSizes.md
  },
  mapContainer: {
    height: "100%"
  },
  popupContainer: {
    color: theme.colors.neutral[8],
    padding: "5px 8px"
  },
  imgPlaceholder: {
    backgroundColor: genericColorPalette.neutral[9],
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      height: 75,
      fill: theme.colors.neutral[6]
    }
  },
  locationsInner: {
    flexGrow: 1,
    marginTop: 28,
    marginBottom: 24,
    display: "grid",
    gridTemplateRows: "auto 1fr",
    gridTemplateColumns: "45% 1fr",
    gap: 16,
    overflow: "hidden"
  },
  mapWrapper: {
    gridRow: "1 / span 2",
    gridColumn: "2"
  },
  listContent: {
    overflowY: "auto"
  },
  mapContent: {
    flexBasis: "55%"
  },
  pagination: {
    margin: 0
  },
  relative: {
    position: "relative"
  },
  rightBtn: {
    display: "flex",
    marginLeft: "auto"
  },
  searchInput: {
    "& svg > path": {
      // fill: theme.colors.neutral[4]
    }
  },
  searchRight: {
    all: "unset",
    cursor: "pointer",
    color: theme.colors.neutral[5]
  },
  headerWrapper: {
    backgroundColor: theme.colors.neutral[9],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${semanticColorPalette.border.general.lighter}`,
    padding: "16px 24px"
  },
  container: {
    backgroundColor: theme.colors.neutral[9],
    padding: 0,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    overflow: "hidden"
  },
  headerTextWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerText: {
    fontSize: theme.fontSizes.xl,
    fontWeight: theme.other.fw.bold
  },
  unProcessesLocationsHeader: {
    display: "flex",
    justifyContent: "space-between"
  },
  locationPage: {
    position: "relative"
  },
  mapPickContainer: {
    backgroundColor: theme.colors.neutral[8],
    border: `1px solid ${theme.colors.neutral[6]}`,
    height: "calc(100vh - 325px)"
  },
  locationsContainer: {
    backgroundColor: theme.colors.neutral[9],
    padding: 24,
    flexGrow: 1,
    overflow: "hidden"
  },
  locationsListContainer: {
    alignContent: "start",
    display: "grid",
    gap: 24,
    height: "100%",
    overflowY: "auto"
  },
  noLocationFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  locationBlock: {
    display: "flex",
    background: theme.colors.neutral[8],
    padding: 16,
    cursor: "pointer"
  },
  locationImageBlock: {
    width: "30%",
    height: 160,
    position: "relative",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "contain"
    }
  },
  locationCategory: {
    position: "absolute",
    background: theme.colors.neutral[6],
    padding: "2px 8px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "& img": {
      width: 16,
      marginRight: 8
    }
  },
  locationBody: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingLeft: 16,
    justifyContent: "space-between"
  },
  locationHeader: {
    "& h5": {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 8,
      "& a": {
        textDecoration: "none",
        color: theme.white,
        "&:hover": {
          textDecoration: "underline"
        }
      }
    },
    "& label": {
      color: theme.colors.neutral[5]
    }
  },
  locationInner: {
    "& p": {
      marginBottom: 8
    },
    "& > div": {
      color: theme.colors.neutral[5],

      "& span": {
        color: theme.colors.neutral[5]
      },
      "& label": {
        color: theme.colors.neutral[5]
      }
    }
  },
  notificationContainer: {
    height: "120px",
    padding: "24px 24px 0 0"
  },
  locationHeaderStatus: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  locationStatus: {
    display: "flex",
    gap: "6px",
    color: theme.colors.neutral[5],
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.sm
  },
  description: {
    margin: "16px 0 32px 0"
  },
  content: {
    padding: "0 24px"
  },
  link: {
    all: "unset",
    cursor: "pointer",
    color: theme.colors.info[0],
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    padding: "8px 0 40px 0",
    "& svg": {
      marginRight: 8,
      transform: "rotate(180deg)",
      "& path": {
        fill: theme.colors.info[0]
      }
    }
  }
}));
