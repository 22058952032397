import { createStyles } from "@mantine/core";


type Args = {
    hasNotifications?: boolean
}

export const useLiveFeedButtonStyles = createStyles((theme, { hasNotifications }: Args) => ({
  alertMonitorContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: '16px',
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'auto'
    }
  },
  container: {
    position: 'relative',
    zIndex: 1,
    width: hasNotifications ? '64px' : '40px',
    height: '40px',
    backgroundColor: theme.colors.neutral[9],
    border: `1px solid ${hasNotifications ? theme.colors.risk[4] : theme.colors.neutral[6]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    cursor: 'pointer'
  },
  allFeedContainer: {
    flexGrow: 1,
    height: '100%'
  },
  notificationsContainer: {
    overflowY: 'auto',
    overflowX: 'visible',
    flexGrow: 1,
    scrollbarWidth: 'none',
    gap: theme.spacing.xl,
    marginTop: theme.spacing.xl
  },
  liveFeedCount: {
    fontWeight: theme.other.fw.bold,
    color: theme.white
  },
  liveFeedCircle: {
    position: 'absolute',
    top: 10,
    right: 8,
    backgroundColor: hasNotifications ? theme.colors.risk[4] : theme.white,
    width: '8px',
    height: '8px',
    borderRadius: '50%'
  },
  drawerTitle: {
    lineHeight: '31px',
    marginBottom: '55px',
    fontSize: 26,
    fontWeight: theme.other.fw.bold,
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-12px',
      left: 0,
      width: '56px',
      height: '2px',
      backgroundColor: theme.colors.blue[6]
    }
  },
  chevronContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px'
  },
  chevron: {
    width: '14px',
    height: '24px',
    margin: 'auto'
  }
}));
