import { DatabaseMeta } from "@/tenant-context/control-profile/types/profile";
import { LWPersonStatus } from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config";

export enum EaArcTab {
  Profile = 'Profile',
  Playbook = 'Playbook',
  EventLog = 'EventLog',
  Summary = 'Summary',
  AlertInsight = 'AlertInsight'
}

export type CloseCasePayload = {
  caseId: string,
  closedByProfileId: string,
  falseAlarm: boolean,
  anyoneInjured: boolean,
  whatHappened: string,
  closedDetails: string
}

export type EaArcCase = {
  tid?: string;
  caseId: string;
  tenantId: string;
  impactedProfileId: string;
  title: string;
  description: string;
  status: string;
  loneWorkerStatus: string;
  eventStartedDataTime: number;
  duressUpdatedDataTime?: number;
  profileStatus: string;
  taskId?: string;
  caseOwnerProfileId: string;
  isUserOnTask?: boolean
  taskStartedDataTime?: number

  // TBD
  version: unknown | null;
  geometry: unknown | null;
}

export type EaTask = {
  _id: string;
  tenantId: string;
  id: string;
  status: string;
  profileTid: string;
  profileId: string;
  deviceId: string;
  deviceName: string;
  taskId: string;
  taskName: string;
  duration: number;
  lat: string;
  lon: string;
  startedDateTime: number;
  endedDateTime: number;
  extendedDateTime: number;
  missedEndedDateTime: number;
  currentMissedEndTaskGracePeriod: number;
  brn: string;
  trn: string;
  root: boolean;
  tenantRequirement: string;
  meta: DatabaseMeta;
  _type: string;
}

export type EaArcCasePersonLocationEvent = {
  personId: string;
  // TODO rename
  fistName: string;
  firstName: string;
  lastName: string;
  geoPoint: {
    lat: number;
    lon: number;
  };
  loneWorkerStatus: LWPersonStatus;
}

export type EaArcEmergencyVideoStreamEvent = {
  tenantId: string;
  profileId: string;
  downloadUrl: string;
  caseId: string;
  videoId: string;
  expireIn: number;
  coordinates: {
    lat: number;
    lon: number;
  };
  timestamp: number;
  fileSize: string;
  fileLength: string;
  fileName: string;
  fileContentType: string;
  fileFrameHeight: number;
  fileFrameWidth: number;
  status: 'UPLOADING' | 'UPLOAD_SUCCESS' | 'UPLOAD_FAILED';
  s3Path: string;
  bucketName: string;
  bucketRegion: string;
  createdDateTime: number;
  uploadedDateTime: number;
}
