import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

type Args = {
  isExpanded: boolean;
}

export const useNavigationUserDetailsItemStyles = createStyles((theme: MantineTheme, args: Args) => ({
  itemRoot: {
    borderRadius: args.isExpanded ? theme.radius.sm : 8,
    padding:  args.isExpanded ? 8 : '2px 8px',
    margin: `0 20px`,
    backgroundColor: '#1E3445',
    overflow: 'hidden',
    cursor: 'pointer',
    height: args.isExpanded ? 42 : 44,
    '&, *': {
      transition: 'all 0.2s ease-in-out'
    }
  },
  itemContent: {
    transition: 'all 0.2s ease-in-out',
    paddingLeft: args.isExpanded ? 0 : 4
  },
  shrinkableContent: {
    opacity: args.isExpanded ? 1 : 0,
    transition: 'all 0.3s ease-in-out',
    overflow: 'hidden'
  },
  userName: {
    maxWidth: 135,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.fontSizes.xs,
    fontWeight: 400,
    color: theme.colors.white[0],
    textWrap: 'nowrap'
  },
  email: {
    maxWidth: 135,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 8,
    fontWeight: 400,
    color: theme.colors.teal[5]
  },
  optionsButton: {
    height: 20,
    widthL: 20
  }
}));
