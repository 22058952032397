import { Button, Stack } from "@mantine/core";
import { FC } from "react";

import { useLegendInTree } from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/hooks";

import { ProviderCategory } from "../../types/risk";
import ProviderEvents from "./ProviderEvents.component";
import RisklineLegendComponent from "./RisklineLegend.component";

type Props = {
  items: Array<ProviderCategory>;
  onCategoryToggle: React.ChangeEventHandler<HTMLInputElement>;
  disabledRiskFilterTypes: Set<string>;
};

const RiskLineEventsComponent: FC<Props> = ({
  items,
  onCategoryToggle,
  disabledRiskFilterTypes
}) => {
  const { buttonAsLinkClasses, risklineLegendVisible, toggleRisklineLegend } = useLegendInTree();

  return (
    <Stack mt={ 16 } ml={ 32 + 36 }>
      { risklineLegendVisible && <RisklineLegendComponent /> }
      <Button
        variant="subtle"
        compact
        classNames={ buttonAsLinkClasses }
        onClick={ toggleRisklineLegend }
      >
        { risklineLegendVisible ? "Hide" : "Show" } legend
      </Button>
      <ProviderEvents
        items={ items }
        onCategoryToggle={ onCategoryToggle }
        disabledRiskFilterTypes={ disabledRiskFilterTypes }
      />
    </Stack>
  );
};

export default RiskLineEventsComponent;
