import { MantineSize, MantineTheme, StepperStylesParams } from "@mantine/core";

import { CSS_KEY } from "../constants";

const getSizeInPx = (size: MantineSize): number => {
  if (size === 'sm' || size === 'md') {
    return 24;
  }

  if (size === 'lg' || size === 'xl') {
    return 42;
  }

  return 42;
};

export const StepperComponentStyle = {
  defaultProps: {
    size: 'lg'
  },
  styles: (theme: MantineTheme, params: StepperStylesParams) => ({
    root: {
      '[data-completed]': {
        [`.${CSS_KEY}-Stepper-stepLabel`]: {
          color: theme.colors.neutral[1]
        }
      }
    },

    steps: {
      '& *': {
        letterSpacing: 0.5
      },
      alignItems: 'stretch' as const
    },

    step: {
      flexDirection: 'column' as const,

      '&[data-progress]': {
        color: theme.other.semantic.accessibility.outer
      },

      marginBottom: 16
    },

    stepIcon: {
      backgroundColor: theme.colors.neutral[8],
      border: `1px solid ${ theme.colors.neutral[6] }`,
      minWidth: getSizeInPx(params.size),
      width: getSizeInPx(params.size),
      height: getSizeInPx(params.size),
      borderRadius: '100%',
      fontWeight: theme.other.fw.regular,
      color: theme.colors.neutral[6],
      justifyContent: 'center !important' as const,

      '&[data-progress]': {
        borderColor: theme.other.semantic.accessibility.outer,
        color: theme.other.semantic.accessibility.outer
      },

      '&[data-completed]': {
        borderColor: theme.other.semantic.accessibility.outer,
        backgroundColor: theme.other.semantic.accessibility.outer
      }
    },

    stepCompletedIcon: {
      color: theme.colors.neutral[8]
    },

    stepWrapper: {
      position: 'relative' as const
    },

    stepLabel: {
      position: 'absolute' as const,
      transform: 'translateX(-50%)',
      minWidth: 'fit-content',
      fontSize: theme.fontSizes.xs,
      lineHeight: theme.other.lh.l,
      whiteSpace: 'nowrap' as const,
      fontWeight: theme.other.fw.regular,
      marginTop: theme.spacing.sm
    },

    stepBody: {
      margin: 0
    },

    separator: {
      margin: 0,
      backgroundColor: 'transparent !important',
      width: '100%',
      height: 'unset',
      position: 'relative' as const,

      '&::after': {
        content: '""',
        display: 'block',
        height: 1,
        backgroundColor: theme.colors.neutral[5],
        position: 'absolute' as const,
        top: getSizeInPx(params.size) / 2,
        width: '100%'
      }
    },

    separatorActive: {
      '&::after': {
        backgroundColor: theme.other.semantic.accessibility.outer
      }
    }
  })
};
