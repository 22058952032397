import { createStyles } from "@mantine/core";

export const useRiskEventsControlStyles = createStyles((theme) =>({
  riskProviderDetails: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems:'center'
  },

  clickCursor: {
    cursor: 'pointer'
  },

  riskEventsTitle: {
    fontFamily:theme.fontFamily,
    fontWeight:theme.other.fw.regular,
    fontSize: theme.fontSizes.md,
    lineHeight: '100%',
    marginLeft: '50px'
  },

  cleanButton: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'unset'
  },

  riskAlertHeading: {
    fontSize: 12,
    lineHeight: theme.other.lh.l
  }
}));
