import { Feature } from 'geojson';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Dispatch } from '@/core/store';
import { PopupCoordinates } from '@/tenant-context/common/types/popup.types';
import { PopupType } from '@/tenant-context/core/store/big-map/big-map.model';

const POPUP_NOT_HOVER_TOLERANCE_PERIOD = 1000;

export default function useMapPopup(popupType: PopupType = "None") {
  const [isPopupShown, setIsPopupShown] = useState(false);
  const [popupCoordinates, setPopupCoordinates] = useState<PopupCoordinates>();
  const [popupFeature, setPopupFeature] = useState<Feature>();

  const popupRef = useRef<HTMLDivElement>(null);

  const {
    bigMap: {
      setCurrentBigMapPopup
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    setCurrentBigMapPopup(isPopupShown ? popupType : "None");

    if (!isPopupShown || !popupRef.current) {
      return;
    }

    // Popup is shown -> track hover outside
    const handleMouseMove = (e: MouseEvent) => {
      if (!popupRef?.current?.contains(e.target as Node)) {
        //setIsPopupShown(false);
        window.removeEventListener('mousemove', handleMouseMove);
      }
    };

    setTimeout(() => {
      window.addEventListener('mousemove', handleMouseMove);
    }, POPUP_NOT_HOVER_TOLERANCE_PERIOD);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupShown]);

  return {
    popupCoordinates,
    popupFeature,
    isPopupShown,
    setIsPopupShown,
    setPopupCoordinates,
    setPopupFeature,
    popupRef
  } as const;
}
