import { ColDef, ICellRendererParams } from "ag-grid-community";
import { ReactNode } from "react";

import { capitalizeWord } from "@/common/util/format/string";

export const columnDefs = (
  actionCellRenderer: (params: ICellRendererParams) => ReactNode
): ColDef[] => {
  const formatServices = (concatenatedServices: string) => {
    const individualServices = concatenatedServices.split(',');
    const formattedServices = individualServices.map((svc) => {
      return svc.split('-').map(capitalizeWord).join(' ');
    });

    return formattedServices.join(', ');
  };

  return [
    {
      headerName: 'MEMBER OF GROUP',
      field: 'name',
      sortable: true,
      comparator: () => 0
    },
    {
      headerName: 'NUMBER OF PEOPLE',
      field: 'profileCount',
      sortable: true,
      comparator: () => 0
    },
    {
      headerName: 'CATEGORY',
      field: 'applicableServices',
      sortable: true,
      cellRenderer: (params: ICellRendererParams) => formatServices(params.data.applicableServices)
    },
    {
      headerName: '',
      colId: 'action',
      resizable: false,
      type: 'centerAligned',
      sortable: false,
      cellRenderer: (params: ICellRendererParams) => actionCellRenderer(params.data._id)
    }
  ];
};