import { UserPolicy } from '@/common/types/permission-control';
import { callApi } from "@/common/util/api";
import browserDatabase from '@/common/util/browser-database';
import { getRegularToken, getTenantToken } from '@/tenant-context/control-profile/api/profile';
import { PaginatedResult } from '@/tenant-context/control-profile/types/profile';
import { TenantTokenPayload } from '@/tenant-context/navigation/types/user';

import { OutsystemsTokenResponse } from '../navigation/types/navigation';
import { CONTEXT_KEY, CONTEXT_TYPES, ContextTypes } from '../store/shared-data/sharedData.types';

export const getCurrentUsersPermissionsPolicies = (): Promise<PaginatedResult<UserPolicy>> => {
  const currentContext: ContextTypes | null = browserDatabase.getItem(CONTEXT_KEY);

  const token = currentContext === CONTEXT_TYPES.platform ? getRegularToken() : getTenantToken();

  if (!token) {
    return Promise.resolve({
      currentPage: 0,
      items: [],
      totalPages: 0,
      totalItems: 0
    });
  }

  const tokenEncodedPayload = token.split('.')[1];
  const tokenPayloadJson: TenantTokenPayload = JSON.parse(atob(tokenEncodedPayload));

  const { userId } = tokenPayloadJson;

  return callApi<
   PaginatedResult<UserPolicy>
  >(
    `/managed/platform-service/v1/users/${userId}`,
    {
      method: 'get',
      queryParams: {
        size: 1000
      },
      headers: {
        'Content-Type': 'application/json;domain-model=ListUserPolicies'
      },
      withTenantId: false
    }
  );
};

export const getCurrentUsersPermissionsPoliciesForSite = 
(tkn: string, siteId: string): Promise<PaginatedResult<UserPolicy>> => {
  const currentContext: ContextTypes | null = browserDatabase.getItem(CONTEXT_KEY);

  const token = currentContext === CONTEXT_TYPES.platform ? getRegularToken() : getTenantToken();

  if (!token) {
    return Promise.resolve({
      currentPage: 0,
      items: [],
      totalPages: 0,
      totalItems: 0
    });
  }

  const tokenEncodedPayload = token.split('.')[1];
  const tokenPayloadJson: TenantTokenPayload = JSON.parse(atob(tokenEncodedPayload));

  const { userId } = tokenPayloadJson;

  return callApi<
   PaginatedResult<UserPolicy>
  >(
    `/managed/platform-service/v1/users/ref:${userId}?siteId=${siteId}`,
    {
      method: 'get',
      queryParams: {
        size: 1000
      },
      withAuthorization: false,
      headers: {
        'Content-Type': 'application/json;domain-model=ListUserSitePolicies',
        'Authorization': `Bearer ${tkn}`
      }
    }
  );
};

export const getSiteToken = (siteId: string): Promise<OutsystemsTokenResponse> => {
  return callApi<
  OutsystemsTokenResponse
  >(
    `/managed/token?grant_type=site_token&access_type=offline`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json;domain-model=ListUserSitePolicies',
        'x-SiteId': siteId
      }
    }
  );
};
