export const IntegrationMappingPoliciesConfig = {
  INTEGRATION_MAPPING: {
    policyNames: [
      'Tenant_System_Tenant-LocationConfig_FullAccess',
      'Tenant_System_Tenant-LocationConfig_ReadOnly'
    ]
  },
  EDIT_INTEGRATION_MAPPING: {
    policyNames: [
      'Tenant_System_Tenant-LocationConfig_FullAccess'
    ]
  }
};