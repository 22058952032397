import { callApi } from "@/common/util/api";
import {
  BulkProfileListCreate,
  GroupItem,
  PeopleGroup,
  Profile,
  ProfileListQuery,
  SortData
} from "@/tenant-context/control-groups/types/group";
import { PaginatedResult, ProfileGroup, ReferenceDataService } from "@/tenant-context/control-profile/types/profile";

export const getAllGroups = ({ page, size, sort, searchQuery }: ProfileListQuery) => {
  return callApi<PaginatedResult<ProfileGroup>>(
    `/managed/platform-service/v1/people-groups?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${searchQuery}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json;domain-model=ListPeopleGroup'
      },
      withTenantId: false
    }
  );
};


export const editProfileGroupParticipation = (
  profileTid: string,
  groupId: string,
  toRemove: boolean
) => callApi<void>(
  `/managed/platform-service/v1/people-groups/${groupId}`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;domain-model=AddProfileToPeopleGroup'
    },
    body: JSON.stringify({
      profileTid,
      toRemove
    }),
    withTenantId: false
  }
);

export const createGroup = (group: PeopleGroup, query?: string) => {
  const body = query ? { query, ...group } : group;
  return callApi(
    `/managed/platform-service/v1/people-groups`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;domain-model=CreatePeopleGroup'
      },
      body: JSON.stringify(body),
      withTenantId: false
    }
  );
};


export const getAllServices = () => callApi<PaginatedResult<ReferenceDataService>>(
  `/public/platform-service/v1/reference-data?search=(type='service')`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    withTenantId: false,
    withAuthorization: false
  }
);

export const deleteProfileGroup = (
  groupId: string
) => callApi<void>(
  `/managed/platform-service/v1/people-groups/${groupId}`,
  {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    withTenantId: false
  }
);

export const updateGroup = (group: PeopleGroup, groupId: string) => callApi(
  `/managed/platform-service/v1/people-groups/${groupId}`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;domain-model=UpdatePeopleGroup'
    },
    body: JSON.stringify(group),
    withTenantId: false
  }
);

export const getProfiles = (
  groupId = '',
  page = 0,
  size = 10,
  sort?: SortData,
  searchQuery = ''
) => callApi<PaginatedResult<Profile>>(
  `/managed/platform-service/v1/person-profiles?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}page=${page}&size=${size}${searchQuery}&${groupId}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;domain-model=ListCompositePersonProfile'
    },
    withTenantId: false
  }
);

export const getProfilesByLookupKey = (
  lookupKey: string,
  pageNum = 0,
  pageSize = 99999,
  orderBy = ''
) => callApi<PaginatedResult<Profile>>(
  `/lookup-service/v1/look-up/mass-comm/persons?pageNum=${pageNum}&pageSize=${pageSize}&orderBy=${orderBy}&lookUpId=${lookupKey}`,
  {
    method: 'GET'
  }
);

export const getPeopleGroup = (groupId: string) => callApi<GroupItem | undefined>(
  `/managed/platform-service/v1/people-groups/${groupId}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;domain-model=GetPeopleGroup'
    },
    withTenantId: false
  }
);

export const postBulkProfileAddToPeopleGroup = (
  groupId: string,
  data?: BulkProfileListCreate,
  query?: string
) => {
  const body = query ? { query, ...data } : data;
  return callApi<void>(
    `/managed/platform-service/v1/people-groups/${groupId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;domain-model=AddBulkProfileToPeopleGroup'
      },
      body: JSON.stringify(body),
      withTenantId: false
    }
  );
};
