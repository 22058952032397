import { createStyles, keyframes } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

const progressBar = keyframes({
  '0%': { width: '10px' },
  '25%': { width: '10px' },
  '32%': { width: '125px' },
  '35%': { width: '120px' },
  '65%': { width: '120px' },
  '72%': { width: '5px' },
  '75%': { width: '10px' },
  '100%': { width: '10px' }
});

const musterIconHeartBeat = keyframes({
  '0%': { transform: 'scale(0.75)' },
  '30%': { transform: 'scale(0.75)' },
  '40%': { transform: 'scale(1)' },
  '50%': { transform: 'scale(0.6)' },
  '65%': { transform: 'scale(0.8)' },
  '75%': { transform: 'scale(0.7)' },
  '100%': { transform: 'scale(0.75)' }
});

const musterIconOrange = keyframes({
  '0%': { width: '30px', padding: 10 },
  '25%': { width: '30px', padding: 10 },
  '32%': { width: '0px', padding: 0 },
  '65%': { width: '0px', padding: 0 },
  '72%': { width: '30px', padding: 10 },
  '100%': { width: '30px', padding: 10 }
});

export const useMusterStyles = createStyles((theme: MantineTheme) => ({

  wrapper: {
    display: "flex",
    position: 'relative',
    boxSizing: 'border-box',
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    height: '107px',
    backgroundColor: theme.colors.neutral[9],
    padding: '8px 5px',
    marginTop: '24px'
  },

  progressBar: {
    backgroundColor: theme.colors.primary[6],
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    animationName: progressBar,
    animationDuration: '5s',
    animationIterationCount: 'infinite'
  },

  musterIconOrangeContainer: {
    padding: 10,
    overflow: 'hidden',
    animationName: musterIconOrange,
    animationDuration: '5s',
    animationIterationCount: 'infinite'
  },

  musterInfo: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  icon: {
    width: '80px',
    height: '80px',
    animationName: musterIconHeartBeat,
    animationDuration: '5s',
    animationIterationCount: 'infinite'
  },

  link: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer'
  },

  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    color: theme.white,
    fontSize: theme.fontSizes.md,
    fontFamily: theme.fontFamily,
    padding: '10px'
  },

  title: {
    color: theme.colors.neutral[1],
    letterSpacing: '0.5px',
    fontFamily:theme.fontFamily,
    fontSize: theme.fontSizes.md,
    lineHeight: "19px"
  },

  count: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '100%',
    backgroundColor:theme.colors.neutral[7]
  }
}));
