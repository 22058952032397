import { createStyles } from "@mantine/core";

type Args = {
  isExpanded: boolean;
}

export const useNavigationTenantDetailsItemStyles = createStyles((theme, args: Args) => ({
  itemRoot: {
    borderRadius: theme.radius.sm,
    padding: 8,
    margin: `6px ${theme.spacing.lg}px`,
    gap: 6,
    paddingLeft: args.isExpanded ? 8 : 14
  },
  avatar: {
    height: args.isExpanded ? 20 : 24,
    width: args.isExpanded ? 20 : 24
  },
  shrinkableContent: {
    opacity: args.isExpanded ? 1 : 0,
    overflow: 'hidden'
  },
  name: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 400,
    color: theme.colors.white[0],
    textWrap: 'nowrap'
  }
}));
