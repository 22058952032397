import { FC, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import usePermission from '@/common/hooks/usePermission';
import { Dispatch, RootState } from "@/core/store";
import { BigProfileContext } from "@/tenant-context/control-profile/components/ui/BigProfile/BigProfile.context";
import ProfileTabs, { TabProps } from "@/tenant-context/control-profile/components/ui/ProfileTabs/ProfileTabs.component";
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';

export enum ProfileTab {
  Profile = 'Profile',
  EmergencyContact = 'EmergencyContact',
  PersonalContact = 'PersonalContact',
  Addresses = 'Addresses',
  Documents = 'Documents',
  PeopleGroups = 'PeopleGroups',
  ChangeLog = 'ChangeLog',
  Identification = 'IdentificationIdentification'
}

const ProfileTabsContainer: FC = () => {
  const {
    profile: {
      isLoggedInProfileId
    }
  } = useDispatch<Dispatch>();

  const hasSubscribedToAccessCardsManagement = useSelector((state: RootState) => state.profile?.isAccessCardPrivilege);
  const { activeTab, handleTabChange } = useContext(BigProfileContext);
  const policiesForProfilePersonalDetails = usePermission(ProfileRequiredPoliciesConfig.VIEW_PROFILE_PERSONAL_DETAILS);
  const policiesForEmergencyContactsTab = usePermission(ProfileRequiredPoliciesConfig.VIEW_EMERGENCY_CONTACTS_TAB);
  const policiesForContactDetails = usePermission(ProfileRequiredPoliciesConfig.VIEW_CONTACT_DETAILS);
  const policiesForAddress = usePermission(ProfileRequiredPoliciesConfig.VIEW_ADDRESS);
  const policiesForDocuments = usePermission(ProfileRequiredPoliciesConfig.VIEW_DOCUMENTS);
  const policiesForPeopleGroups = usePermission(ProfileRequiredPoliciesConfig.VIEW_PEOPLE_GROUPS);
  const policiesForPeopleIdentification = usePermission(ProfileRequiredPoliciesConfig.VIEW_IDENTIFICATION);
  const params = useParams();
  const { userId } = params;

  const tabs: TabProps[] = useMemo(() => [
    {
      value: ProfileTab.Profile,
      label: 'Profile',
      isAuthorized: isLoggedInProfileId(userId) || policiesForProfilePersonalDetails
    },
    {
      value: ProfileTab.EmergencyContact,
      label: 'Emergency Contact',
      isAuthorized: isLoggedInProfileId(userId) || policiesForEmergencyContactsTab
    },
    {
      value: ProfileTab.PersonalContact,
      label: 'Personal Contact',
      isAuthorized: isLoggedInProfileId(userId) || policiesForContactDetails
    },
    {
      value: ProfileTab.Addresses,
      label: 'Addresses',
      isAuthorized: isLoggedInProfileId(userId) || policiesForAddress
    },
    {
      value: ProfileTab.Documents,
      label: 'Documents',
      isAuthorized: isLoggedInProfileId(userId) || policiesForDocuments
    },
    {
      value: ProfileTab.PeopleGroups,
      label: 'People Groups',
      isAuthorized: isLoggedInProfileId(userId) || policiesForPeopleGroups
    },
    {
      value: ProfileTab.Identification,
      label: 'Identification',
      isAuthorized: isLoggedInProfileId(userId) || policiesForPeopleIdentification 
      || hasSubscribedToAccessCardsManagement
    }
  ], [
    isLoggedInProfileId,
    userId,
    policiesForProfilePersonalDetails,
    policiesForEmergencyContactsTab,
    policiesForContactDetails,
    policiesForAddress,
    policiesForDocuments,
    policiesForPeopleGroups,
    policiesForPeopleIdentification,
    hasSubscribedToAccessCardsManagement
  ]);

  return (
    <ProfileTabs
      activeTab={ activeTab }
      handleTabChange={ handleTabChange }
      tabs={ tabs }
    />
  );
};

export default ProfileTabsContainer;
