import { useMantineTheme } from "@mantine/core";
import { FC } from "react";

type Props = {
  riskLevelIndex: number;
  className?: string;
}

const AlarmBadge: FC<Props> = ({
  riskLevelIndex,
  className
}) => {
  const theme = useMantineTheme();

  return (
    <svg className={ className } width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.08984 8.28683C8.08984 8.76183 8.47199 9.14397 8.94699 9.14397C9.42199 9.14397 9.80413 8.76183 9.80413 8.28683V4.28683C9.80413 3.81183 9.42199 3.42969 8.94699 3.42969C8.47199 3.42969 8.08984 3.81183 8.08984 4.28683V8.28683ZM10.0898 11.4297C10.0898 10.7975 9.57913 10.2868 8.94699 10.2868C8.31484 10.2868 7.80413 10.7975 7.80413 11.4297C7.80413 12.0618 8.31484 12.5725 8.94699 12.5725C9.57913 12.5725 10.0898 12.0618 10.0898 11.4297Z"
        fill="white"/>
      <path
        d="M14.2146 1.23429C13.7503 0.467857 12.9146 0 12.0503 0H5.87531C4.97888 0 4.14316 0.467857 3.67888 1.23429L0.375307 6.66429C-0.124693 7.48571 -0.124693 8.51429 0.375307 9.33571L3.67888 14.7643C4.14316 15.5321 4.97888 16 5.87531 16H12.0503C12.9146 16 13.7503 15.5321 14.2146 14.7643L17.5196 9.33571C18.0195 8.51429 18.0195 7.48571 17.5196 6.66429L14.2146 1.23429ZM9.83602 4.28571V8.28571C9.83602 8.76071 9.42173 9.14286 8.97888 9.14286C8.47173 9.14286 8.08959 8.76071 8.08959 8.28571V4.28571C8.08959 3.81071 8.47173 3.42857 8.97888 3.42857C9.42173 3.42857 9.83602 3.81071 9.83602 4.28571ZM8.97888 10.2857C9.57888 10.2857 10.1217 10.7964 10.1217 11.4286C10.1217 12.0607 9.57888 12.5714 8.97888 12.5714C8.31459 12.5714 7.80388 12.0607 7.80388 11.4286C7.80388 10.7964 8.31459 10.2857 8.97888 10.2857Z"
        fill={ theme.colors.risk[riskLevelIndex] }
      />
    </svg>
  );
};

export default AlarmBadge;
