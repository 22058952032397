export const VisitorManagementRequiredPoliciesConfig = {
  VISITOR_MANAGEMENT_ROUTE_ACCESS: {
    policyNames: ['Tenant_Visitor_RequestManagement_ReadOnly',
      'Tenant_Visitor_RequestManagement_FullAccess',
      'Tenant_Visitor_RequestManagement_Approval']
  },
  EDIT_VISIT_REQUEST: {
    policyNames: [
      'Tenant_Visitor_RequestManagement_FullAccess'
    ]
  },
  VISITOR_MANAGEMENT_APPROVAL_ACCESS: {
    policyNames: ['Tenant_Visitor_RequestManagement_Approval']
  }
};
