import { createStyles, MantineTheme } from "@mantine/core";

type Params = {
  highlightedCountry?: boolean;
}


export const useTravellersByCountryDrawerStyles = createStyles((theme: MantineTheme, params: Params) => ({
  filtersContainer: {
    display: 'flex',
    gap: '16px',
    marginBottom: 24
  },

  dropdown: {
    padding: '16px 16px 5px',
    width: `230px !important`,
    border: `1px solid ${theme.colors.neutral[7]}`,
    backgroundColor: theme.colors.neutral[9]
  },

  dropdownLabel: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[6],
    padding: 0
  },

  dropdownItem: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[0],
    padding: '12px 0'
  },

  dropdownItemDescription: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[6],
    marginLeft: 4
  },

  searchInput: {
    width: '85%',
    marginBottom: 0,
    input: {
      minHeight: 32,
      paddingTop: 0,
      paddingBottom: 0
    }
  },

  searchIcon: {
    width: 16,
    height: 16
  },

  sortButton: {
    width: 32,
    height: 32,
    border: `1px solid ${theme.colors.neutral[7]}`
  },

  countriesHeader: {
    display: 'flex',
    fontSize: theme.fontSizes.xs,
    padding: '4px 16px',
    justifyContent: 'space-between',
    '& > p:first-of-type': {
      flexBasis: '25%'
    }
  },

  countriesContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },

  country: {
    all: "unset",
    display: 'flex',
    backgroundColor: params.highlightedCountry ? theme.colors.neutral[7] :theme.colors.neutral[9],
    padding: '16px 8px 16px 16px',
    justifyContent: 'space-between',
    cursor: 'pointer',
    fontSize: 14,
    border: params.highlightedCountry ? `1px solid ${theme.colors.neutral[7]}` : 'none',
    '& > p:first-of-type': {
      flexBasis: '45%',
      fontWeight: 700
    },
    '& > div:first-of-type': {
      flexGrow: 1
    }
  },

  riskLevelIdText:{
    position: 'absolute',
    // color: params?.riskLevelTextColor,
    fontSize: 10,
    fontWeight: theme.other.fw.bold,
    marginLeft: '6.5px',
    marginTop:'4px'
  },

  riskText: {
    fontSize: 12,
    marginLeft: 8
  },

  riskIconWrapper: {
    display: 'flex',
    alignItems: 'center'
  },

  riskLevelIcon:{
    position: 'relative'
  }
}));
