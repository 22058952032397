import { Button } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { FC, Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ReactComponent as DeleteIcon } from '@/common/icons/Tools/delete.svg';
import { Dispatch } from '@/core/store';
import { useDialogBoxModalStyles } from '@/tenant-context/control-draw/components/DeleteGeofenceWarningModal/DeleteGeofenceWarningModal.style';

type Props = {
  closeDrawer: () => void;
  geofenceId?: string;
}

const DeleteGeofenceWarningModalComponent: FC<Props> = ({ closeDrawer, geofenceId }) => {
  const { classes } = useDialogBoxModalStyles();

  const {
    geofence: {
      deleteGeofence
    }
  } = useDispatch<Dispatch>();

  const close = useCallback(() => {
    closeAllModals();
  }, []);

  const deleteGeofence_ = useCallback(() => {
    if (geofenceId) {
      deleteGeofence(geofenceId);
      closeDrawer();
      closeAllModals();
    }
  }, [closeDrawer, deleteGeofence, geofenceId]);

  return (
    <Fragment>
      <div className={ classes.messageArea }>
        <span className={ classes.dangerText }>Please Note</span>
        deleting the geofence is permanent and cannot be retrieved.
      </div>
      <div className={ classes.footer }>
        <Button variant="outline" onClick={ close } size={ 'md' }>Cancel</Button>
        <Button
          leftIcon={ <DeleteIcon/> }
          className={ classes.deleteButton }
          onClick={ deleteGeofence_ }
          size={ 'md' }
        >
          Delete
        </Button>
      </div>
    </Fragment>
  );
};

export default DeleteGeofenceWarningModalComponent;
