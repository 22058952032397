import { callApi } from "@/common/util/api";
import { SearchResult } from "@/tenant-context/control-search/types/search";

export const searchAssetsAndPeople = (query: string, rankRequestTime = 0) => callApi<SearchResult[]>(
  'asset-ranking-service/v1/location-engine/assets',
  {
    method: 'get',
    queryParams: {
      rankRequestTime: rankRequestTime,
      query: `*${ query }*`,
      count: 25
    }
  }
);
