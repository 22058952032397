import {
  ActionIcon,
  Box,
  Drawer as SimpleDrawer,
  DrawerPosition,
  Flex,
  MantineTheme,
  Text
} from "@mantine/core";
import { FC, Fragment, ReactNode, useCallback, useMemo } from "react";

import BottomDrawer from '@/common/components/BottomDrawer';
import { useDrawerControlStyles } from "@/common/components/DrawerControl/DrawerControl.styles";
import { ReactComponent as BackArrowIcon } from "@/common/icons/Arrows/back-arrow-blue.svg";
import { ReactComponent as CloseIcon } from "@/common/icons/close-x.svg";

export type DrawerControlButtonRenderer = (params: { onClick: () => void }) => ReactNode;

export const REGULAR_Z_INDEX = 200;
export const HIGH_Z_INDEX = 2000;

type Props = {
  isOpen: boolean,
  open: () => void,
  close: () => void,
  header?: ReactNode,
  footer?: ReactNode,
  isCloseButtonHidden?: boolean,
  size?: string | number,
  drawerPosition?: DrawerPosition,
  isSubDrawer: boolean,
  isWithTitleBorder: boolean,
  renderButton?: DrawerControlButtonRenderer,
  onCloseClick: () => void,
  onBackButtonClick?: () => void,
  zIndex?: number,
  id?:string,
  isDrawerExists:boolean,
  title?: string,
  explainer?: string,
  shadow?: string
  backButtonText?: ReactNode
};

const DrawerControl: FC<Props> = ({
  children,
  isOpen,
  open,
  close,
  header,
  footer,
  isCloseButtonHidden,
  size,
  renderButton,
  drawerPosition = 'right',
  isSubDrawer,
  onCloseClick,
  onBackButtonClick,
  zIndex,
  isDrawerExists: isDrawerOpen,
  title,
  explainer,
  shadow,
  backButtonText
}) => {

  const { classes } = useDrawerControlStyles(size as string);

  const Drawer = drawerPosition === 'bottom'
    ? BottomDrawer
    : SimpleDrawer;

  const isBottomDrawer = useMemo(() => drawerPosition === 'bottom', [drawerPosition]);

  const drawerHeader = useMemo(() => {
    if (isBottomDrawer) {
      return header;
    }

    if (size === 'full') {
      return header;
    }

    return (
      <Flex direction={ 'column' } className={ classes.fullWidth } gap={ 8 }>

        { /* HEADER SECTION WITH CLOSE BUTTON */ }
        <Flex direction={ 'row' } justify={ 'space-between' } align={ 'center' } pos={ 'relative' } h={ 48 }>
          <Box> { header } </Box>
          { !isCloseButtonHidden && <ActionIcon size={ 14 } onClick={ close } className={ classes.drawerCloseButtonAlign } variant={ 'subtle' }>
            <CloseIcon/>
          </ActionIcon> }
        </Flex>

        { /* GO BACK SECTION : Only appears on sub-drawers */ }
        { isSubDrawer && <Flex direction={ 'row' } align={ 'center' } gap={ 8 } pb={ 14 } className={ classes.bottomBorder }>
          <ActionIcon size={ 24 } variant={ 'subtle' }>
            <BackArrowIcon onClick={ onBackButtonClick }/>
          </ActionIcon>
          <Text size={ 'sm' } fw={ 700 }> { backButtonText } </Text>
        </Flex> }

        { /* TITLE SECTION */ }
        { title && (
          <Flex direction={ 'column' } className={ classes.drawerTitle } gap={ 8 }>
            <Text fs={ '26px' } fw={ 'bold' } className={ classes.drawerTitleFont }>{ title }</Text>
            <Box className={ classes.titleUnderLine }/>
            { explainer && <Text pb={ 36 } pt={ 8 } fs={ 'xs' } className={ classes.bottomBorder }>{ explainer }</Text> }
          </Flex>
        ) }
      </Flex>
    );
  }, [
    isBottomDrawer,
    isCloseButtonHidden,
    size,
    isSubDrawer,
    classes.drawerCloseButtonAlign,
    classes.fullWidth,
    classes.bottomBorder,
    classes.drawerTitle,
    classes.drawerTitleFont,
    classes.titleUnderLine,
    header,
    close,
    backButtonText,
    onBackButtonClick,
    title,
    explainer
  ]);

  // Too complex without any, leaving it here for now HERE
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const drawerStyles = useCallback((theme: MantineTheme, args: any) => {
    return ({
      drawer: {
        ...(drawerPosition === 'bottom' && !args?.isFull
          ? {
            padding: '0 !important',

            // In this case there is another drawer on the side
            // Handle the case when the drawer is not full width
            width: isDrawerOpen
              // 385px LG sidebar
              // 96px free area to the right (where the map buttons are)
              // 25px padding between the drawers
              ? 'calc(100% - calc(385px + 96px + 25px) - var(--navigation-sidebar-closed-width))'
              : 'calc(100% - 90px - 24px - var(--navigation-sidebar-closed-width))',

            margin: '0 calc(32px + 24px) 24px calc(24px + var(--navigation-sidebar-closed-width))',
            // boxShadow: '-40px 10px 20px 20px rgba(19, 42, 42, 1)'
            [theme.fn.smallerThan('md')]: {
              width: isDrawerOpen
                ? 'calc(100% - calc(320px + 96px + 25px) - var(--navigation-sidebar-closed-width))'
                : 'calc(100% - 90px - 24px - var(--navigation-sidebar-closed-width))'
            }
          } : {
            margin: '0 0 0 var(--navigation-sidebar-closed-width)'
            // boxShadow: '-40px 10px 20px 20px rgba(19, 42, 42, 1)'

          }
        )
      }
    });
  }, [drawerPosition, isDrawerOpen]);

  return (
    <Fragment>
      { renderButton?.({
        onClick: isOpen ? close : open
      }) }

      <Drawer
        zIndex={ zIndex ?? drawerPosition === 'bottom'
          ? HIGH_Z_INDEX
          : REGULAR_Z_INDEX }
        closeOnClickOutside={ false }
        className={ classes.drawerPanel }
        // TODO consider opening in the map
        target="#root"
        styles={ drawerStyles }
        shadow={ shadow ?? 'xl' }
        title={ isBottomDrawer ? (drawerHeader as string & ReactNode) : undefined }
        opened={ isOpen }
        size={ size }
        onClose={ onCloseClick }
        withCloseButton={ false }
        transitionDuration={ 250 }
        transitionTimingFunction="ease"
        position={ drawerPosition }
        overlayOpacity={ 0 }
        padding={ size === 'full' ? 0 : 'sm' }
      >
        { !isBottomDrawer && drawerHeader && (
          <Box mt={ -24 }>
            { drawerHeader }
          </Box>
        ) }

        <Box className={ classes.drawerContent }>
          <Box className={ classes.drawerChildren }>
            { children }
          </Box>
        </Box>

        { footer && (
          <Box>
            { footer }
          </Box>
        ) }
      </Drawer>
    </Fragment>
  );
};

export default DrawerControl;
