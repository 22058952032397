import { Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { OpenConfirmModal } from "@mantine/modals/lib/context";

type ConfirmationModalProps = {
    text: string
} & OpenConfirmModal

export const openConfirmationModal  = ({ text, onCancel, onConfirm, title, ...others }: ConfirmationModalProps) => 
  openConfirmModal({
    title: title ?? "Confirm",
    children: (<Text size={ "sm" }>{ text }</Text>),
    labels: { confirm: 'Confirm', cancel: 'Cancel' },
    cancelProps: { variant: "outline" },
    onCancel: onCancel ?? undefined,
    onConfirm: onConfirm,
    style: { zIndex: 250 }, //regular modals have 200. need to appear above regular modals.
    ...others
  });
  