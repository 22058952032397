import { FC, useCallback } from "react";
import { useSelector } from 'react-redux';

import usePermission from '@/common/hooks/usePermission';
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { RootState } from '@/core/store';
import { navigateToSitePage } from '@/tenant-context/control-site/util/util';
import MasterTab from '@/tenant-context/visualisation-site/components/MusterTab/MasterTab.component';
import { policiesToNavigateSite } from '@/tenant-context/visualisation-site/config/site-location-layer.config';

type Props ={
  musterCount: number
  siteCode?: string
}

const MasterTabContainer: FC<Props> = ({ musterCount = 3, siteCode }) => {
  const selectedSite = useSelector((state: RootState) => state.commonData?.selectedSite);
  const isSitesAuthorized = usePermission({ policyNames: policiesToNavigateSite });
  const unauthorizedAction = useUnauthorizedModal();

  const navigateToMuster = useCallback(() => {
    if (!isSitesAuthorized) {
      unauthorizedAction();
      return;
    }

    if (!selectedSite?.code) {
      return;
    }
    navigateToSitePage(siteCode ? siteCode : selectedSite.code, 'Musters');
  }, [isSitesAuthorized, selectedSite?.code, unauthorizedAction, siteCode]);

  return (
    <MasterTab musterCount={ musterCount } navigateToMuster={ navigateToMuster }/>
  );
};

export default MasterTabContainer;
