import { Group, SelectItemProps } from "@mantine/core";
import { FC } from "react";

import { useSelectItemStyles } from "@/tenant-context/widget-overview/components/MapSearch/components/SelectItem/SelectItem.styles";

export interface ItemProps extends SelectItemProps {
  description?: string;
}

const SelectItem : FC<SelectItemProps>  = (
  ({ label, description, ...others }: ItemProps) => {

    const { classes } = useSelectItemStyles();
    return (
      <div  { ...others }>
        <Group noWrap>
          <div>
            <div className={ classes.locationName }>
              { label }
            </div>
            <div className={ classes.locationAddress }>
              { description }
            </div>
          </div>
        </Group>
      </div>
    );
  }
);

export default SelectItem ;
