import { useClickOutside } from "@mantine/hooks";
import { openModal } from "@mantine/modals";
import { FC, ReactNode, useCallback, useState } from "react";

import { RequiredPolicies } from '@/common/types/permission-control';
import { capitalizeWord } from "@/common/util/format/string";
import { useButtonWithOptionsStyles } from "@/tenant-context/control-profile/components/ui/ButtonWithOptions/ButtonWithOptions.style";
import ProfileTabActionButton from "@/tenant-context/control-profile/components/ui/ProfileTabActionButton";

type Props = {
  sectionType: string,
  options?: string[],
  renderModal: (sectionType: string, type: string) => ReactNode,
  requiredPolicies?: RequiredPolicies | RequiredPolicies[]
}

const ButtonWithOptions: FC<Props> = ({
  sectionType,
  options = ['Home', 'Work'],
  renderModal,
  requiredPolicies
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useClickOutside(() => {
    setIsExpanded(false);
  });
  const { classes } = useButtonWithOptionsStyles({
    isDropdownExpanded: isExpanded
  });

  const handleClick = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const createAddHandler = (type: string) => () => {
    setIsExpanded(false);

    openModal({
      title: `ADD ${type.toUpperCase()} ${sectionType.toUpperCase()}`,
      children: renderModal(sectionType, type),
      closeOnClickOutside: false
    });
  };

  return (
    <div className={ classes.container } ref={ ref }>
      <ProfileTabActionButton
        onClick={ handleClick }
        requiredPolicies={ requiredPolicies }
        isDropDownButton
        setIsExpanded={ setIsExpanded }
        isExpanded={ isExpanded }
      >
        Add { capitalizeWord(sectionType) }
      </ProfileTabActionButton>

      <ul className={ classes.dropdown }>
        { options.map((option) => (
          <li key={ option } className={ classes.dropdownItem }>
            <button
              className={ classes.itemOption }
              onClick={ createAddHandler(option) }
            >
              { capitalizeWord(option) } { sectionType.split(' ').map(capitalizeWord).join(' ') }
            </button>
          </li>
        )) }
      </ul>
    </div>
  );
};

export default ButtonWithOptions;
