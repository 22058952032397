import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import GeofenceList from "@/tenant-context/control-draw/components/GeofenceList/GeofenceList.component";
import { GeofenceListTabsTypes } from "@/tenant-context/control-draw/types/draw";

const GeofenceListContainer = () => {

  const allShortGeofenceList = useSelector((state: RootState) => state.geofence.allShortGeofenceList?.items);

  const privateShortGeofenceList = useSelector((state: RootState) => state.geofence.privateShortGeofenceList?.items);

  const {
    geofence: {
      SET_ACTIVE_TAB
    }
  } = useDispatch<Dispatch>();

  const handleOnTabChange = useCallback((value: GeofenceListTabsTypes) => {
    SET_ACTIVE_TAB(value);
  }, [SET_ACTIVE_TAB]);

  return (
    <div>
      <GeofenceList
        allGeofenceList={ allShortGeofenceList || [] }
        userGeofenceList={ privateShortGeofenceList || [] }
        handleOnTabChange={ handleOnTabChange }
      />
    </div>
  );
};

export default GeofenceListContainer;
