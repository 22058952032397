import { createStyles } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useBigProfileStyles = createStyles((theme) => ({
  block: {
    backgroundColor: genericColorPalette.neutral[9],
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 24px',
    height: '100vh',
    overflow: 'auto'
  },
  goToPeopleList: {
    cursor: "pointer",
    color: theme.colors.info[0],
    textDecoration: 'none',
    display: "flex",
    alignItems: "center",
    '& svg':{
      marginRight: 8,
      transform: "rotate(180deg)",
      '& path':{
        fill: theme.colors.info[0]
      }
      
    }
  },
  goToPeopleListContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: '16px',
    paddingLeft: '16px'
  }
}));
