import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

type Params = {
  isExpanded?: boolean
  isMustering?: boolean
}

export const useDroppedInfoBoxStyles = createStyles((t: MantineTheme, params?: Params) => ({
  dropdown: {
    display: params?.isExpanded ? 'block' : 'none',
    width: '230px',
    fontFamily: t.fontFamily,
    backgroundColor: t.colors.neutral[9] + ' !important'
  },

  wrapper: {
    zIndex: 1
  },

  musterSite: {
    borderLeft: `4px solid ${t.colors.primary[6]}`,
    backgroundColor: t.colors.primary[7]
  },

  listItemButton: {
    all: 'unset',
    width: '100%',
    boxSizing: 'border-box',

    padding: '8px 16px',
    cursor: 'pointer',
    color: t.other.semantic.label[2],


    '&:not(:last-of-type)': {
      marginBottom: '8px'
    }
  }
}));
