import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";

import SmsSummaryComponent from "./SmsSummary.component";

const SmsSummary: FC = () => {

  const {
    smsContent,
    selectedRecipients,
    receiveCountByCommunicationChannel,
    selectedResponseMethod,
    smsResponseMethodConfigurations
  } = useSelector((state: RootState) => state.massComms);
  const {
    massComms: {
      SET_CURRENT_STEP,
      SET_SELECTED_CONFIG_TAB
    }
  } = useDispatch<Dispatch>();

  const handleStepperNavigate = useCallback((step: number) => () => {
    SET_CURRENT_STEP(step);
    SET_SELECTED_CONFIG_TAB({ defaultTab: 'sms', fromSummary: true });
  }, [SET_CURRENT_STEP, SET_SELECTED_CONFIG_TAB]);

  const expectedTotalRecipientCount = selectedRecipients.length;
  const receivingCount = selectedRecipients.length - receiveCountByCommunicationChannel.none;

  return (
    <SmsSummaryComponent
      smsContent={ smsContent }
      onStepperNavigate={ handleStepperNavigate }
      expectedTotalRecipientCount={ expectedTotalRecipientCount }
      receivingCount={ receivingCount }
      responseMethod={ selectedResponseMethod }
      smsResponseConfigs={ smsResponseMethodConfigurations }
    />
  );
};

export default SmsSummary;