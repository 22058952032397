import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useSiteSearchDropdownStyles = createStyles((t: MantineTheme) => ({
  wrapper:{
    maxHeight: '50vh',
    overflow: 'auto'
  },
  siteContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  siteName: {
    lineHeight: t.other.lh.l,
    fontSize: t.fontSizes.sm,
    color: t.other.semantic.label[2]
  },

  siteLocation: {
    fontSize: '14px',
    lineHeight: '18px',
    color: t.colors.neutral[4]
  },

  musterContainer: {
    display: 'flex',
    width: '16px',
    height: '16px',
    marginLeft: '16px'
  },

  musterCount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '16px',
    height: '16px',
    borderRadius: '100%',
    backgroundColor: t.colors.primary[6],
    lineHeight: t.other.lh.s,
    fontSize: t.fontSizes.xs,
    color: t.colors.neutral[9],
    fontWeight: t.other.fw.bold,
    paddingTop: '3px'
  }
}));
