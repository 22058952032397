import { callApi } from '@/common/util/api';
import { ViewportBbox } from "@/tenant-context/control-draw/types/draw";

import { AssetLocationDetails, AssetLocationSnapshot } from '../types/asset-location';

export const getAssetLocationSnapshots = () => callApi<AssetLocationSnapshot[]>(
  'asset-location-service/v1/asset-location-snapshots'
);

export const getAssetLocationSnapshotsForBounds = (bounds: ViewportBbox) => callApi<AssetLocationSnapshot[]>(
  'asset-location-service/v1/asset-location-snapshots/polygon',
  {
    method: 'post',
    body: JSON.stringify({
      points: bounds,
      dateBand: {
        from: new Date(0).getTime(),
        to: new Date().getTime()
      }
    })
  }
);

export const getAssetLocationDetails = (assetId: string) => callApi<AssetLocationDetails>(
  `asset-service/v1/assets/${assetId}`,
  {
    method: 'get'
  }
);
