import { Divider, ScrollArea, Text } from "@mantine/core";
import DOMPurify from "dompurify";
import { FC, ReactNode, useCallback } from "react";

import { ReactComponent as BackIcon } from "@/common/icons/Arrows/back-arrow.svg";
import { ReactComponent as ExtremeRiskIcon } from "@/common/icons/markers/RiskEvents/Extreme/Base.svg";
import { ReactComponent as HighRiskIcon } from "@/common/icons/markers/RiskEvents/High/Base.svg";
import { ReactComponent as LowRiskIcon } from "@/common/icons/markers/RiskEvents/Low/Base.svg";
import { ReactComponent as MediumRiskIcon } from "@/common/icons/markers/RiskEvents/Medium/Base.svg";
import { ReactComponent as ModerateRiskIcon } from "@/common/icons/markers/RiskEvents/Moderate/Base.svg";
import { capitalizeWord } from "@/common/util/format/string";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import { CustomColors } from "@/core/styles/mantine/palettes/types";
import CountryFlag from "@/tenant-context/common/components/CountryFlag";
import {
  CountryDetailViewFilter
} from "@/tenant-context/control-travellers-search/drawers/CountryDetailViewDrawer/components/CountryDetailViewFilter.component";
import {
  TravellerInfoCard
} from "@/tenant-context/control-travellers-search/drawers/CountryDetailViewDrawer/components/TravellerInfoCard/TravellerInfoCard.component";
import {
  useCountryDetailViewDrawerStyles
} from "@/tenant-context/control-travellers-search/drawers/CountryDetailViewDrawer/CountryDetailViewDrawer.styles";
import {
  CountryDetailData, TravellerData,
  TravellersInCountrySortType
} from "@/tenant-context/control-travellers-search/types/travelSearch.types";

type Props = {
    countryDetailData: CountryDetailData | undefined;
    travellers: Array<TravellerData>;
    onBackClick: () => void;
    handleSort: (sortType: TravellersInCountrySortType) => () => void;
    handleSearchChange: (searchText: string) => void;
    handleTravellerClick: (traveller: TravellerData) => void;
}

export const CountryDetailViewDrawerComponent: FC<Props> = ({
  countryDetailData,
  travellers,
  onBackClick,
  handleSort,
  handleSearchChange,
  handleTravellerClick
}) => {

  const { classes } = useCountryDetailViewDrawerStyles();

  const getRiskLevelIcon = useCallback((riskLevel: string, riskText: string) => {
    const riskLevelIconMap: Record<string, ReactNode> = {
      5: <ExtremeRiskIcon width={ 24 } height={ 24 }/>,
      4: <HighRiskIcon width={ 24 } height={ 24 }/>,
      3: <MediumRiskIcon width={ 24 } height={ 24 }/>,
      2: <ModerateRiskIcon width={ 24 } height={ 24 }/>,
      1: <LowRiskIcon width={ 24 } height={ 24 }/>
    };

    const riskLevelColorMap: Record<string, CustomColors> = {
      5: genericColorPalette.risk[4] as CustomColors,
      4: genericColorPalette.risk[3] as CustomColors,
      3: genericColorPalette.risk[2] as CustomColors,
      2: genericColorPalette.risk[1] as CustomColors,
      1: genericColorPalette.risk[0] as CustomColors
    };

    return <div className={ classes.riskIconWrapper }>
      { riskLevelIconMap[riskLevel] }
      <Text className={ classes.riskText } color={ riskLevelColorMap[riskLevel] }>{ capitalizeWord(riskText) }</Text>
    </div>;
  }, [classes.riskIconWrapper, classes.riskText]);

  if (!countryDetailData) {
    return null;
  }

  return (
    <div>
      <button className={ classes.backIcon } onClick={ onBackClick }>
        <BackIcon width={ 16 } height={ 16 }/>
        Back to countries
      </button>

      <div className={ classes.countryWrapper }>
        <div className={ classes.countryLabel }>
          <div className={ classes.countryName }>
            <CountryFlag width={ 24 } height={ 24 } countryCode={ countryDetailData.countryISOCode }/>
            { countryDetailData.countryName }
          </div>

          { countryDetailData.risk && getRiskLevelIcon(countryDetailData.risk.level, countryDetailData.risk.category) }
        </div>

        { countryDetailData.risk && <div className={ classes.countryRiskSummary }
          dangerouslySetInnerHTML={ { __html: DOMPurify.sanitize(countryDetailData.risk.description) } }
        ></div> }

        <Divider className={ classes.divider }/>
      </div>

      <div className={ classes.countryDetailViewFilter }>
        <CountryDetailViewFilter
          handleSort={ handleSort }
          handleSearchChange={ handleSearchChange }
        />
      </div>

      <ScrollArea>
        <div className={ classes.travellersList }>
          { travellers.map((traveller) => {
            const travellerId = `${traveller.profileId}-${traveller.tripLocation}`;
            return (<TravellerInfoCard
              key={ travellerId }
              travellerId={ travellerId }
              traveller={ traveller }
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleTravellerClick(traveller) }
            />);
          }) }
        </div>
      </ScrollArea>
    </div>
  );
};
