import { CheckboxProps } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

import { ReactComponent as CheckedIcon } from "@/common/icons/checked.svg";
import { ReactComponent as IndeterminateIcon } from "@/common/icons/indeterminate.svg";


// eslint-disable-next-line react/prop-types
const CheckboxIcon: CheckboxProps['icon'] = ({ indeterminate, className }) =>
  indeterminate ? <IndeterminateIcon className={ className } /> :  <CheckedIcon className={ className } />;

export const CheckboxComponentStyle = {
  defaultProps: {
    icon: CheckboxIcon,
    size: 'md'
  },
  styles: (theme: MantineTheme) => ({
    root: {
      input: {
        borderRadius: 1,
        backgroundColor:theme.other.semantic.icon.general.transparent,
        border:`1px solid ${theme.other.semantic.icon.general.secondary}`,
        '&:checked':{
          backgroundColor:theme.other.semantic.accent[1],
          border: 'none'
        },
        '&:hover':{
          border:`1px solid ${theme.other.semantic.icon.general.hover}`
        },
        '&:indeterminate':{
          '& + svg':{
            path:{
              fill: theme.colors.white[0]
            }
          }
        },
        '&:disabled':{
          cursor:'not-allowed',
          color: theme.other.semantic.text.disabled,

          //when disabled changing the checkbox icon color
          '& + svg':{
            path:{
              fill:theme.other.semantic.icon.general.onDisabled
            }
          },
          backgroundColor: theme.other.semantic.icon.general.transparent,
          border: `1px solid ${theme.other.semantic.icon.general.disabled}`,
        
          '&:checked':{
            backgroundColor: theme.other.semantic.icon.general.disabled,
            border: 'none'
          }
        }
      },
      
      label: {
        '& [data-disabled]':{
          color: theme.other.semantic.text.disabled
        }
      }
    },
    description: {
      color: theme.other.semantic.text.secondary
    },
    body:{
      alignItems: "center"
    }
  })
};
