import { Flex, Stack, StackProps } from "@mantine/core";
import { FC } from "react";

import {
  useDataminrLegend
} from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/DataminrLegend.hooks";


const DataminrLegendComponent: FC<StackProps> = ({ ...stackProps }) => {
  const legendItems= useDataminrLegend();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Stack { ...stackProps }>
      { legendItems.map(({ color, label, level }) => (
        <Flex key={ level } gap={ 8 } display="inline-flex" align="center">
          <div
            style={ {
              backgroundColor: color,
              width: 24,
              height: 24,
              borderRadius: "50%",
              display: "inline-block"
            } }
          />
          <span>{ label }</span>
        </Flex>
      )) }
    </Stack>
  );
};

export default DataminrLegendComponent;
