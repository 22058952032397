import { callApi } from "@/common/util/api";
import { Geofence, GeofenceStatusTypes, ShortGeofence } from "@/tenant-context/control-draw/types/draw";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

export const getGeofences = (
  page = 0,
  size = 10,
  metaData = '',
  searchQuery = ''
) => {

  return callApi<PaginatedResult<Geofence>>(
    `/location-management-service/v1/geo-fences?${metaData}pageSize=${size}&pageNum=${page}${searchQuery}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      withTenantId: true
    }
  );
};

export const getShortGeofence = (
  page = 0,
  size = 10,
  metaData = '',
  searchQuery = ''
) => {

  return callApi<PaginatedResult<ShortGeofence>>(
    `/location-management-service/v1/geo-fences/short-details?${metaData}pageSize=${size}&pageNum=${page}${searchQuery}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json'
      },
      withTenantId: true
    }
  );
};

export const deleteGeofenceById = (
  tid: string
) => {
  return callApi<void>(
    `/location-management-service/v1/geo-fences/${tid}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      withTenantId: true
    }
  );
};

export const updateGeofenceStatusById = (
  tid: string,
  status: GeofenceStatusTypes
) => {
  return callApi<Geofence>(
    `/location-management-service/v1/geo-fences/status/${tid}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        status: status
      }),
      withTenantId: true
    }
  );
};


export const createGeofence = (geofence: Geofence) => callApi<void>(
  `/location-management-service/v1/geo-fences`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(geofence),
    withTenantId: true
  }
);

export const getGeofence = (geofenceTid: string) => callApi<Geofence>(
  `/location-management-service/v1/geo-fences/${geofenceTid}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    withTenantId: true,
    withAuthorization: true
  }
);

export const updateGeofence = (geofenceTid: string, geofence: Geofence) => callApi<Geofence>(
  `/location-management-service/v1/geo-fences/${geofenceTid}`,
  {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(geofence),
    withTenantId: true,
    withAuthorization: true
  }
);

export const downloadGeoFencesCsv = () => callApi<string>(
  `/location-management-service/v1/geo-fences/export-to-csv`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'text/csv;charset=UTF-8'
    },
    responseType: 'blob'
  }
);

