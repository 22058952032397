import { createModel } from '@rematch/core';

import { logger } from '@/common/util/ConsoleLogger';
import { showNotification } from '@/common/util/notification';
import { Dispatch, RootModel, RootState } from '@/core/store';
import { getSiteConnectors } from '@/tenant-context/control-site/api/site';
import {
  getTableauDashboardToken, signRequest
} from '@/tenant-context/control-tableau/api/tableau-dashboard.api';
import { TableauDashboardConfig } from '@/tenant-context/control-tableau/config/tableau-dashboard.config';
import { TableauAuthResponse } from '@/tenant-context/control-tableau/types/tableau-dashboard';

export type TableauDashboardModelState = {
  tableauToken?: string,
  isAnalyticsEnabled?: boolean,
  tableauAuthError?: string
}

const tableauDashboardModel = {
  name: 'tableauDashboard',
  state: {
    tableauToken: undefined,
    isAnalyticsEnabled: undefined
  } as TableauDashboardModelState,
  reducers: {
    SET_TABLEAU_TOKEN: (state: TableauDashboardModelState, payload?: string) => {
      return {
        ...state,
        tableauToken: payload
      };
    },
    SET_ANALYTICS_ENABLED: (state: TableauDashboardModelState, payload?: boolean) => {
      return {
        ...state,
        isAnalyticsEnabled: payload
      };
    },
    SET_TABLEAU_AUTH_ERROR: (state: TableauDashboardModelState, payload?: string) => {
      return {
        ...state,
        tableauAuthError: payload
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async loadTableauToken(_: void, __: RootState): Promise<void> {
      // eslint-disable-next-line fp/no-let
      let token = undefined;
      try {
        token = await getTableauDashboardToken();
        dispatch.tableauDashboard.SET_TABLEAU_TOKEN(token.token);
      } catch (error) {
        showNotification({ color: 'error', message: 'Failed to load Tableau token' });
        logger.error('Failed to load Tableau token', error);
      }

      if (token) {
        try {
          await signRequest(TableauDashboardConfig.TABLEAU_DASHBOARD_URL, token.token);
        } catch (error) {
          const err = error as TableauAuthResponse;
          if (err.result?.errors?.length && err.result.errors?.length > 0) {
            dispatch.tableauDashboard.SET_TABLEAU_AUTH_ERROR(err.result.errors[0].code.toString());
            logger.error('Failed to authenticate Tableau dashboard', error);
            return;
          }
        }
      }
    },
    async loadAnalyticsEnabledStatus(_: void, state: RootState): Promise<void> {
      if (state.tableauDashboard.isAnalyticsEnabled !== undefined) {
        return;
      }
      try {
        const connectorsConfig = await getSiteConnectors();
        dispatch.tableauDashboard.SET_ANALYTICS_ENABLED(connectorsConfig.riskConnectorServices.some((connector) => {
          if (connector.adapterName === 'bsoc') {
            const tableauIntegrationEnabled = connector.configs?.tableauIntegrationEnabled;
            if (typeof tableauIntegrationEnabled === 'string') {
              return tableauIntegrationEnabled === 'true';
            }

            return tableauIntegrationEnabled;
          }

          return false;
        }));
      } catch (error) {
        dispatch.tableauDashboard.SET_ANALYTICS_ENABLED(false);
        logger.error('Failed to load analytics enabled status', error);
      }
    }
  })
};

export const tableauDashboard = createModel<RootModel>()(tableauDashboardModel);
