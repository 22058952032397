import { LookupMetaData, LookupMetaDataCharts } from '@/tenant-context/control-lookup-tool/types/Lookup.types';

export function convertToChartsData(metadata: LookupMetaData): LookupMetaDataCharts {

  const chartData: LookupMetaDataCharts = {
    locationData: [
      {
        label: 'Restrata App',
        frequency: metadata.location_data.RESTRATA_APP || 0,
        color: '#0F62FE'
      },
      {
        label: 'Travel Itineraries',
        frequency: metadata.location_data.TRAVEL_ITINERARIES || 0,
        color: '#E98949'
      },
      {
        label: 'Home Address',
        frequency: metadata.location_data.HOME_ADDRESS || 0,
        color: '#39b09b'
      },
      {
        label: 'Access Control',
        frequency: metadata.location_data.ACCESS_CONTROL || 0,
        color: '#576275'
      },
      {
        label: 'Contracted Location',
        frequency: metadata.location_data.CONTRACTED_LOCATION || 0,
        color: '#FF85AB'
      },
      {
        label: 'Manual Add',
        frequency: metadata.location_data.manual_add || 0,
        color: '#FFC847'
      },
      {
        label: 'Other',
        frequency: metadata.location_data.OTHER || 0,
        color: '#ff7847'
      } ],
    locationProbability: [
      {
        label: 'Low',
        frequency: metadata.location_probability.low,
        color: '#0F62FE'
      },
      {
        label: 'Medium',
        frequency: metadata.location_probability.medium,
        color: '#E98949'
      },
      {
        label: 'High',
        frequency: metadata.location_probability.high,
        color: '#576275'
      },
      {
        label: 'N/A',
        frequency: metadata.location_probability.other,
        color: '#FF85AB'
      }
    ],
    massCommunication: [
      {
        label: 'SMS Only',
        frequency: metadata.mass_comm_channel_breakdown.smsOnly,
        color: '#0F62FE'
      },
      {
        label: 'Email Only',
        frequency: metadata.mass_comm_channel_breakdown.emailOnly,
        color: '#E98949'
      },
      {
        label: 'None',
        frequency: metadata.mass_comm_channel_breakdown.none,
        color: '#576275'
      },
      {
        label: 'Both',
        frequency: metadata.mass_comm_channel_breakdown.both,
        color: '#FF85AB'
      }
    ]
  };

  return chartData;
}

export function mapValueToRange(min: number, max: number, value: number) {
  // Map the value from the range 0 to 100 to the range -6 to 6
  const minRangeValue = 0;
  const maxRangeValue = 100;

  // Calculate the mapped value
  return min + ((max - min) * value) / (maxRangeValue - minRangeValue);
}

export function getTimestampInXHours(x: number): number {
  // Get the current timestamp in milliseconds
  const currentTimestamp = new Date().getTime();

  // Calculate the timestamp after x hours
  return currentTimestamp + x * 60 * 60 * 1000;
}

export function getTimestampInXMonths(x: number): number {
  const currentDate = new Date();

  // Get the current year and month
  // eslint-disable-next-line fp/no-let
  let currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Calculate the future month
  // eslint-disable-next-line fp/no-let
  let futureMonth = currentMonth + x;

  // Adjust the year if the future month exceeds 11 (December)
  if (futureMonth > 11) {
    const overflowYears = Math.floor(futureMonth / 12);
    currentYear += overflowYears;
    futureMonth %= 12;
  }

  // Set the future date
  const futureDate = new Date(
    currentYear,
    futureMonth,
    currentDate.getDate(),
    currentDate.getHours(),
    currentDate.getMinutes(),
    currentDate.getSeconds(),
    currentDate.getMilliseconds()
  );

  // Get the timestamp in milliseconds
  return futureDate.getTime();
}

export function getTimeStampForMonthsByValue(minMonths: number, maxMonths: number, value: number): number {
  const min = getTimestampInXMonths(minMonths);
  const max = getTimestampInXMonths(maxMonths);

  const minRangeValue = 0;
  const maxRangeValue = 100;

  // Calculate the mapped value
  return min + ((max - min) * value) / (maxRangeValue - minRangeValue);
}

export function timestampToDateTime(timestamp: number): string {
  const date = new Date(timestamp);

  const day = date.getDate().toString().padStart(2, '0');

  // Mapping month index to month abbreviation
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const monthAbbreviation = months[date.getMonth()];

  const year = date.getFullYear();

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${day}, ${monthAbbreviation}, ${year} ${hours}:${minutes}`;
}

export function formatHoursToHoursAndMinutes(decimalHours: number, shorten = false): string {
  if (!decimalHours) {
    return 'Now';
  }
  const isNegative = decimalHours < 0;
  const absoluteHours = Math.floor(Math.abs(decimalHours));
  const minutes = Math.round((Math.abs(decimalHours) - absoluteHours) * 60);

  const hoursString = isNegative ? `-${absoluteHours}` : `${absoluteHours}`;
  const minutesString = minutes > 0 ? ` ${minutes} ${shorten? 'mins': 'minutes'}` : '';

  return `${hoursString} ${shorten? 'hrs': 'hours'}${minutesString}`;
}

export function formatMonthsToMonthsAndDays(decimalMonths: number): string {
  if (!decimalMonths) {
    return 'Now';
  }
  const isNegative = decimalMonths < 0;
  const absoluteMonths = Math.floor(Math.abs(decimalMonths));
  const days = Math.round((Math.abs(decimalMonths) - absoluteMonths) * 30); // Assuming 30 days per month

  const monthsString = isNegative ? `-${absoluteMonths}` : `${absoluteMonths}`;
  const daysString = days > 0 ? ` ${days} days` : '';

  return `${monthsString} months${daysString}`;
}

function padZero(value: number): string {
  return value < 10 ? `0${value}` : `${value}`;
}

export function getCurrentDateTimeSuffixForFileNames(): string {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = padZero(currentDate.getMonth() + 1); // Adding 1 because months are zero-based
  const day = padZero(currentDate.getDate());
  const hours = padZero(currentDate.getHours());
  const minutes = padZero(currentDate.getMinutes());

  return `${year}_${month}_${day}_${hours}${minutes}`;
}

export function getLabelCloseLimitByScreenWidth(windowSize: number): number {
  if (windowSize < 1200) {
    return 100;
  } else if (windowSize < 1300) {
    return 95;
  } else if (windowSize < 1800) {
    return 60;
  } else {
    return 40;
  }
}
