import { createStyles } from "@mantine/core";

type Props = {
  isLegendHeader: boolean,
  padding: 'l' | 'm'
};

export const useProfileBlockStyles = createStyles((theme, args?: Props) => ({
  block: {
    backgroundColor: !args?.isLegendHeader
      ? theme.colors.neutral[8]
      : 'transparent',

    border: !args?.isLegendHeader
      ? `0.5px solid ${theme.colors.neutral[6]}`
      : 'none',

    color: !args?.isLegendHeader
      ? 'inherit'
      : theme.colors.neutral[5],

    width: '100%',
    padding: '16px',
    fontSize: theme.fontSizes.md,
    position: 'relative',

    display: 'flex',

    '&:not(:first-of-type)': {
      marginTop: 16
    }
  },

  contentBlock: {
    width: '100%'
  },

  blockHeading: {
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fw.bold,
    width: '300px',
    margin: 0
  },

  contentButton: {
    all: 'unset',
    cursor: 'pointer',
    width: '100%'
  }
}));
