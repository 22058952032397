import { createStyles,MantineTheme } from "@mantine/core";

export const useGeofenceWidgetStyles = createStyles((theme: MantineTheme) => ({
  topRightContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    margin: 24,
    zIndex: 1,
    [theme.fn.largerThan(1512)]: {
      gap: 16
    },
    '& input': {
      backgroundColor: `${theme.colors.neutral[9]} !important`
    }
  }
}));
