import { Timeline } from '@mantine/core';
import { FC, useContext } from "react";
import { useSelector } from 'react-redux';

import { RootState } from '@/core/store';
import ArcSection from "@/tenant-context/control-action-response-center/components/ArcSection";
import { useActivityLogStyles } from '@/tenant-context/employee-app-action-response-center/components/ui/ActivityLog/ActivityLog.styles';
import { EaArcContext } from '@/tenant-context/employee-app-action-response-center/context/EaArc.context';

export enum KeyEvent {
  TaskStarted = 'taskStarted',
  AlarmActivated = 'alarmActivated',
  CaseOwnerAssigned = 'caseOwnerAssigned',
  CaseClosed = 'caseClosed'
}

const ActivityLog: FC = () => {
  const { classes } = useActivityLogStyles();
  const { emergencyVideos } = useContext(EaArcContext);

  const activityLog = useSelector(
    (state: RootState) => state.eaArc.activityLog
  );

  const insertAction = activityLog
    ?.find(
      (event) => event.log.operation === 'INSERT'
    );

  const isTaskStarted = !!(!!insertAction?.delta?.isUserOnTask && (insertAction
    ?.delta?.taskStartedDataTime && +insertAction
    ?.delta?.taskStartedDataTime));

  const getStartedTime = (time: string | undefined) => {
    if (typeof(time) === 'undefined' || time === '0.0') {
      return;
    }

    return time;
  };

  const taskStartedEvent = isTaskStarted ? getStartedTime(insertAction
    ?.delta?.taskStartedDataTime) : undefined;

  const alarmActivated = insertAction
    ?.log?.auditTime;

  const duressAlarmActivatedEvent = activityLog
    ?.filter(
      (event) => event.log.operation === 'UPDATE'
        && event.newValuesObject.loneWorkerStatus === 'DURESS_CANCELLED_EMERGENCY'
    )?.map((event) => {
      const time = event?.newValuesObject?.duressUpdatedDataTime;
      return Number(time) * 1000;
    })?.[0];

  const caseOwnerAssigned = activityLog
    ?.find(
      (event) => event.log.operation === 'UPDATE'
      && typeof(event.newValuesObject?.caseOwnerProfileId) !== 'undefined'
    )
    ?.log?.auditTime;

  const caseClosed = activityLog
    ?.find(
      (event) => event.log.operation === 'UPDATE'
      && event.newValuesObject?.status === 'CLOSED'
    )
    ?.newValuesObject?.auditTime;

  // This is a temporary solution until we BE has a better way to get the emergency video uploaded event. Current one
  // is not JSON parsable and is not consistent with other events.
  const emergencyVideoUploadedEvents = emergencyVideos
    ?.map((video) => ({
      title: 'Emergency Video Uploaded',
      time: video.timestamp * 1000 // Convert to milliseconds
    }));

  // Keep this commented out until BE has a better way to get the emergency video uploaded event. Please refer the above
  // const emergencyVideoUploadedEvents = activityLog
  //   ?.filter(
  //     (event) => event.log.operation === 'UPDATE'
  //     && (typeof (event.newValuesObject?.caseVideoMetaData) !== 'undefined' || event.newValuesObject?.caseVideoMetaData !== null)
  //     && event.newValuesObject?.status === 'UPLOAD_SUCCESS'
  //   )
  //   .map((event) => ({
  //     title: 'Emergency Video Uploaded',
  //     time: event.log.auditTime
  //   }));

  const keyEvents = [
    {
      title: 'Task Started',
      time: taskStartedEvent
    },
    {
      title: 'Alarm Activated',
      time: alarmActivated
    },
    {
      title: 'Duress Alarm Activated',
      time: duressAlarmActivatedEvent
    },
    {
      title: 'Case Owner Assigned',
      time: caseOwnerAssigned
    },
    {
      title: 'Case Closed',
      time: caseClosed
    },
    ...(emergencyVideoUploadedEvents || [])
  ]
    .filter(({ time }) => typeof(time) !== 'undefined');

  const renderTime = (time: number) => {
    const isTimestampMilliseconds = time.toString().length < 16;
    const date = new Date(isTimestampMilliseconds ? time : time/1000);

    return (
      <p className={ classes.time }>
        { /* Render date like DEC 6 */ }
        <span>
          { date.getDate() }
        </span>

        { ' ' }

        <span className={ classes.dim }>
          { date.toLocaleString(
            [],
            { month: 'short' }
          ).toUpperCase() }
        </span>


        { ' ' }

        { date.toLocaleTimeString(
          [],
          { hour: '2-digit', minute: '2-digit' }
        ) }
        
      </p>
    );
  };

  const isActivityLogAvailable = keyEvents.length > 0;

  return (
    <ArcSection title='Activity Log' className={ classes.root }>
      { !isActivityLogAvailable && (
        <span>Activity Log is not available for this case</span>
      ) }

      { isActivityLogAvailable && (
        <Timeline className={ classes.timeline } bulletSize={ 10 } lineWidth={ 2 }>
          { keyEvents
            .sort((a, b) => Number(b.time) - Number(a.time))
            .map((ev, i) => (
              <Timeline.Item
                key={ i }
                title={ (
                  <div className={ classes.heading }>
                    <p>{ ev.title }</p>
                    { ev.time && renderTime(Number(ev.time)) }
                  </div>
                ) }
                lineVariant="dashed"
              />
            )) }
        </Timeline>
      ) }
    </ArcSection>
  );
};

export default ActivityLog;
