import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleExclamation,
  faThumbsUp } from "@fortawesome/free-solid-svg-icons";

const icons = [
  faThumbsUp,
  faCircleExclamation
];

export const loadGlobalFontAwesomeIcons = () => {
  library.add( ...icons );
};
