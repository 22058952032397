import { createStyles } from "@mantine/core";


export const useProfileTabsStyles = createStyles((theme) => ({
  unauthorizedTab: {
    cursor: 'not-allowed',
    backgroundColor: theme.colors.neutral[6],
    '*' : {
      color: theme.colors.neutral[8]
    },
    '&:hover': {
      cursor: 'not-allowed',
      backgroundColor: theme.colors.neutral[6],
      '*' : {
        color: theme.colors.neutral[8]
      }
    }
  }
}));