import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router";

import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState } from "@/core/store";
import { mapImpactEngineRiskAlertToRiskAlertEvent } from "@/tenant-context/common/util/risk";
import { getRiskAlertById } from "@/tenant-context/control-action-response-center/api/risk-alert";
import { FeedNotification, NotificationEventType } from "@/tenant-context/control-live-feed/types/live-feed";
import { getNotificationTime } from '@/tenant-context/control-live-feed/util/live-feed';
import { GenericRiskAlertResponse } from "@/tenant-context/visualisation-risk-alerts/types/risk-alert-response";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

import LiveFeedNotificationComponent from "./LiveFeedNotification.component";

interface Props {
  notification: FeedNotification;
  isFromRight?: boolean;
  handleCloseLiveFeed?: () => void;
  position?: number;
}

const LiveFeedNotification: FC<Props> = ({ notification, isFromRight, position }) => {

  const [riskAlert, setRiskAlert] = useState<RiskAlertEvent>();

  const [reverseGeoLoc, setReverseGeoLoc] = useState('');

  const isArcOpen = useSelector((state: RootState) => state.arc.isArcOpen);

  const navigate = useNavigate();

  const match = useMatch(RouterConfig.routes.home);

  const map = useMap().current;

  const {
    arc: {
      SET_IS_ARC_OPEN,
      SET_CURRENT_CASE_ID
    },
    riskAlerts: {
      enableRiskAlertDrawer
    },
    drawer: {
      openRightDrawer,
      closeRightDrawer
    },
    eaArc: {
      openEaArc
    },
    userProfile: {
      SET_CURRENT_USER_ID
    },
    riskDetails: {
      getImpactDataForActiveRiskAlert
    },
    liveFeed: {
      removeNotificationFromLiveList,
      getReverseGeoLocation,
      SET_ALL_ALERTS_FEED_OPEN,
      CLOSE_NOTIFICATION_IN_DISPLAY_STACK
    }
  } = useDispatch<Dispatch>();


  useEffect(() => {

    if (!notification.geometry) {
      return;
    }

    const { lat, lon } = notification.geometry;

    const location = getReverseGeoLocation({
      lat, lon
    });

    location.then(loc => {
      setReverseGeoLoc(loc.place_name);
    }).catch(() => {
      setReverseGeoLoc('Unknown');
    });

  }, [getReverseGeoLocation, notification.geometry]);


  const loadRiskAlert = useCallback(async (riskId) => {

    if (riskAlert) {
      return;
    }

    const event: GenericRiskAlertResponse = await getRiskAlertById(riskId);


    const alert = mapImpactEngineRiskAlertToRiskAlertEvent(event);

    if (!alert) {
      return;
    }

    setRiskAlert(alert as RiskAlertEvent);
    return alert;
  }, [riskAlert]);

  const handleButtonClick = useCallback(
    () => {
      CLOSE_NOTIFICATION_IN_DISPLAY_STACK(notification.notificationId);
      if (notification.impactInfo) {

        loadRiskAlert(notification.eventCaseId).then((risk) => {

          if (!risk) {
            return;
          }

          SET_ALL_ALERTS_FEED_OPEN(false);

          const currentCaseId = `riskline_${risk.json.alert.id}`;
          SET_CURRENT_CASE_ID(currentCaseId);

          enableRiskAlertDrawer(risk as RiskAlertEvent);

          SET_IS_ARC_OPEN(true);
          closeRightDrawer();
        });
      }

      if (notification.profileId) {
        SET_ALL_ALERTS_FEED_OPEN(false);
        closeRightDrawer();
        SET_CURRENT_USER_ID(notification.profileId);
        openRightDrawer('mini-profile-control');
        openEaArc();
      }

      if (!match) {
        navigate(RouterConfig.routes.home);
      }
    }
    , [enableRiskAlertDrawer,
      SET_ALL_ALERTS_FEED_OPEN,
      SET_CURRENT_USER_ID,
      CLOSE_NOTIFICATION_IN_DISPLAY_STACK,
      openRightDrawer,
      closeRightDrawer,
      loadRiskAlert,
      notification,
      navigate,
      match,
      SET_CURRENT_CASE_ID,
      SET_IS_ARC_OPEN,
      openEaArc
    ]
  );

  const getPosition = useCallback(
    () => {
      if (isFromRight || !position || position === 0) {
        return undefined;
      }

      return position;
    },
    [isFromRight, position]
  );

  const handleRemoveButtonClick = useCallback(
    () => {
      removeNotificationFromLiveList(notification.notificationId);
    }
    , [removeNotificationFromLiveList, notification]
  );

  const handleZoomClick = useCallback(
    async () => {
      if (!notification.geometry || !map) {
        return;
      }

      const { lat, lon } = notification.geometry;

      const zoom = 14;

      map.flyTo({
        center: [lon, lat],
        zoom: zoom
      });

      if (notification.eventType === NotificationEventType.IMPACT) {

        await getImpactDataForActiveRiskAlert(`${notification.source}_${notification.eventTaskId}`);
      }
    }
    , [
      map,
      notification.geometry,
      getImpactDataForActiveRiskAlert,
      notification.eventType,
      notification.source,
      notification.eventTaskId
    ]
  );

  const notificationTime = useMemo(() => getNotificationTime(notification), [notification]);

  if (notification.eventType === NotificationEventType.EMERGENCY) {

    if (!notification.profileId) {
      return null;
    }

    const { personProfile, caseOwnerPersonProfile, caseOwnerProfileId } = notification;

    return (
      <LiveFeedNotificationComponent
        critically={ notification.critically }
        profilePicture={ personProfile?.profilePictureUrl }
        profileName={ `${personProfile.firstName} ${personProfile.lastName}` }
        time={ notificationTime }
        title={ notification.title }
        isFromRight={ isFromRight }
        source={ notification.source }
        location={ reverseGeoLoc }
        handleButtonClick={ handleButtonClick }
        handleRemoveButtonClick={ handleRemoveButtonClick }
        position={ getPosition() }
        caseOwnerName={ caseOwnerProfileId && `${caseOwnerPersonProfile.firstName} ${caseOwnerPersonProfile.lastName}` }
        caseOwnerPicture={ caseOwnerProfileId && caseOwnerPersonProfile.profilePictureUrl }
        caseOwnerJobTitle={ caseOwnerProfileId && caseOwnerPersonProfile.jobTitle }
        closedDataTime={ notification.closedDataTime }
        showZoomButton={ !!map && !isArcOpen }
        onZoomClick={ handleZoomClick }
        isClosed={ notification.status === 'CLOSE' }
      />
    );
  }

  if (notification.eventType === NotificationEventType.IMPACT) {

    const { impactInfo } = notification;

    if (!impactInfo) {
      return null;
    }

    // Hide bsoc events for now

    if (notification.source === 'bsoc') {
      return null;
    }

    return (
      <LiveFeedNotificationComponent
        details={ notification.description }
        time={ notificationTime }
        riskLevelId={ impactInfo.riskLevelId }
        riskLevelName={ impactInfo.riskLevelName }
        riskCategoryId={ impactInfo.riskLevelId }
        riskCategoryName={ impactInfo.categoryName }
        title={ notification.title }
        source={ notification.source }
        location={ reverseGeoLoc }
        peopleCount={ impactInfo.peopleCount }
        assetsCount={ impactInfo.assetCount }
        isFromRight={ isFromRight }
        handleButtonClick={ handleButtonClick }
        handleRemoveButtonClick={ handleRemoveButtonClick }
        position={ getPosition() }
        closedDataTime={ notification.closedDataTime }
        showZoomButton={ !!map && !isArcOpen }
        onZoomClick={ handleZoomClick }
        isClosed={ notification.status === 'CLOSE' }
      />
    );
  }

  return (
    <LiveFeedNotificationComponent
      critically={ notification.critically }
      details={ notification.description }
      time={ notificationTime }
      title={ notification.title }
      handleRemoveButtonClick={ handleRemoveButtonClick }
      position={ getPosition() }
      closedDataTime={ notification.closedDataTime }
      isClosed={ notification.status === 'CLOSE' }
    />
  );
};

export default LiveFeedNotification;
