import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const useTravelSegmentWrapperStyles = createStyles((theme) => ({
  itineraryStepperContainer: {
    display: 'flex',
    [`& .${CSS_KEY}-Step-stepWrapper`]: {
      marginRight: '24px'
    }
  },

  itineraryLine: {
    position: 'relative',
    left: -46,
    top: 100,
    zIndex: -99
  },

  itineraryLineCompleted: {
    borderTopColor: theme.other.semantic.accessibility.outer,
    borderLeftColor: theme.other.semantic.accessibility.outer
  },

  itineraryLineCurrent: {
    borderTopColor: theme.other.semantic.button.default.default,
    borderLeftColor: theme.other.semantic.button.default.default
  },

  stepper: {
    '& .mantine-Stepper-steps': {
      height: '100%',
      '& .mantine-Stepper-separator ': {
        '&::after': {
          top: 0,
          left: '50%',
          width: '1px',
          height: '100%',
          backgroundColor: theme.colors.neutral[8]
        }
      },
      '& .mantine-Stepper-separatorActive': {
        '&::after': {
          backgroundColor: theme.other.semantic.accessibility.outer
        }
      }
    }
  },
  stepperStep: {
    marginBottom: 0,
    '& > div': {
      marginBottom: 0
    }
  },

  completedStep: {
    [`& .${ CSS_KEY }-Step-stepIcon`]: {
      borderColor: theme.other.semantic.accessibility.outer,
      backgroundColor: theme.other.semantic.accessibility.outer,
      '& svg': {
        fill: theme.colors.neutral[9],

        '& *': {
          fill: theme.colors.neutral[9]
        }
      }
    }
  },

  currentStep: {
    [`& .${ CSS_KEY }-Step-stepIcon`]: {
      borderColor: theme.other.semantic.button.default.default,
      backgroundColor: theme.other.semantic.button.default.default,
      '& svg': {
        fill: theme.colors.white
      }
    }
  }
}));
