import { useMemo } from "react";

import { ReactComponent as Conflict } from "@/common/icons/conflict.svg";
import { ReactComponent as Crime } from "@/common/icons/crime.svg";
import { ReactComponent as Demonstrations } from "@/common/icons/demonstrations.svg";
import { ReactComponent as Health } from "@/common/icons/health.svg";
import { ReactComponent as Natural } from "@/common/icons/natural.svg";
import { ReactComponent as Political } from "@/common/icons/political.svg";
import { ReactComponent as Travel } from "@/common/icons/travel.svg";

export const useRiskLineEventCategories = () =>
  useMemo(
    () => [
      {
        id: "political",
        name: "Political Developments",
        Icon: Political,
        categoryId: 1
      },
      {
        id: "conflict",
        name: "Conflict and Terrorism",
        Icon: Conflict,
        categoryId: 2
      },
      {
        id: "demonstrations",
        name: "Demonstrations and Unrest",
        Icon: Demonstrations,
        categoryId: 3
      },

      {
        id: "crime",
        name: "Crime and Corruption",
        Icon: Crime,
        categoryId: 4
      },
      {
        id: "natural",
        name: "Natural and Environmental",
        Icon: Natural,
        categoryId: 5
      },
      {
        id: "health",
        name: "Health and Medical",
        Icon: Health,
        categoryId: 6
      },
      {
        id: "travel",
        name: "Travel Safety and Disruptions",
        Icon: Travel,
        categoryId: 7
      }
    ],
    []
  );
