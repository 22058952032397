import { createStyles } from "@mantine/core";

export const useSiteContactStyles = createStyles((theme) => ({

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '32px 0 24px'
  },
  info: {
    display: 'flex'
  },

  contactIcon: {
    width: '48px',
    height: '48px',
    borderRadius: '90px'
  },

  blankContactIcon: {
    width: '48px',
    height: '48px',
    borderRadius: '90px',
    background: theme.colors.neutral[6]
  },

  contactInfoContainer: {
    marginLeft: '8px'
  },

  name: {
    color: theme.colors.neutral[6]
  },

  title: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.sm
  },

  contactItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  contactName: {
    color: theme.colors.neutral[5]
  },

  contactValue: {
    color: theme.colors.neutral[0]
  },

  icon: {
    marginRight: '16px'
  }
}));
