import { createStyles, MantineTheme } from "@mantine/core";

export const useSitePlanOptionStyles = createStyles((theme: MantineTheme) => ({
  sitePlanOptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '40px 16px 24px 40px',
    backgroundColor: theme.colors.neutral[8],
    minHeight: '280px'
  },
  sitePlanOptionTitle: {
    fontSize: theme.fontSizes.lg,
    fontWeight: theme.other.fw.semibold
  },
  sitePlanChildrenWrapper: {
    margin: '40px 72px 16px 40px'
  }
}));
