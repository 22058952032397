import { createStyles } from "@mantine/core";

export const useGenericProfileTabStyles = createStyles((theme) => ({
  block: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '40px 0',
    color: theme.colors.neutral[5]
  }
}));