import { createStyles } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useModalAccentedBlockStyles = createStyles((theme) => ({
  root: {
    backgroundColor: genericColorPalette.neutral[9],
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    padding: 16,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },

  accentedPrefix: {
    color: genericColorPalette.error[0],
    fontWeight: theme.other.fw.bold
  }
}));
