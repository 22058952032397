import { createStyles } from "@mantine/core";

// Overriding the default styles of mantine's SegmentedControl
export const useSegmentedControlStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.neutral[9]
  },
  active: {
    backgroundColor: theme.colors.neutral[8]
  },
  label: {
    backgroundColor: 'transparent'
  },
  labelActive: {
    border: `1px solid ${theme.colors.neutral[6]}`,
    backgroundColor: theme.colors.neutral[8],
    color: theme.white
  }
}));

export const useButtonAsLinkStyles = createStyles(() => ({
  root: {
    width: 'max-content',
    height: '24px'
  }
}));
