import { createStyles } from "@mantine/core";

import { CSS_KEY } from '@/core/styles/mantine/constants';

export const useCityRiskReportsPageStyles = createStyles((theme) =>({
  searchBox: {
    minWidth: 440,
    [`.${CSS_KEY}-Input-input`]: {
      backgroundColor: `${theme.colors.neutral[7]} !important`,
      borderRadius: theme.radius.xl,
      border: 'none !important',
      '&:focus': {
        border: `1px solid ${ theme.colors.royalBlue[0] } !important`
      }
    },
    [`.${CSS_KEY}-Autocomplete-dropdown`]: {
      borderRadius: theme.radius.md,
      border: `1px solid ${theme.colors.neutral[7]}`,
      '*': {
        transition: 'all 0.25s ease-in-out'
      },
      [`.${CSS_KEY}-Autocomplete-item`]: {
        borderRadius: theme.radius.md,
        borderBottom: 'none'
      }
    }
  },
  headerIcons: {
    border: 'none !important',
    backgroundColor: `${theme.colors.neutral[7]}`,
    '&, *': {
      transition: 'all 0.25s ease-in-out'
    }
  },
  subHeading: {
    fontSize: 18,
    fontWeight: 700
  },
  noRiskDataText: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 700,
    color: theme.colors.neutral[6]
  },
  noRiskDataTextContainer: {
    flexGrow: 1
  },
  statusCardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: 20,
    [theme.fn.smallerThan(1544)]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.fn.smallerThan('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    }
  },
  riskStatusDrawer: {
    '*': {
      transition: 'width 0.25s ease-in-out, top 0.25s ease-in-out, bottom 0.25s ease-in-out, right 0.25s ease-in-out'
    },
    [`.${CSS_KEY}-Drawer-header`]: {
      display: 'none !important'
    },
    [`.${CSS_KEY}-Drawer-drawer`]: {
      padding: '0 !important',
      backgroundColor: `${theme.colors.neutral[8]} !important`
    },
    [`.${CSS_KEY}-Drawer-overlay`]: {
      display: 'block !important',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      backgroundColor: `#061927 !important`
    }
  },
  riskStatusDrawerSide: {
    borderRadius: theme.radius.sm,
    width: `744px !important`,
    top: 40,
    right: 40,
    bottom: 40
  },
  riskStatusDrawerExpanded: {
    top: 0,
    right: 0,
    bottom: 0,
    width: `100vw !important`,
    [`.${CSS_KEY}-Drawer-body`]: {
      marginLeft: 'var(--navigation-sidebar-closed-width)'
    }
  }
}));
