import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useImpactedAssetCardStyles = createStyles((theme: MantineTheme) => ({
  card: {
    width: 272,
    background: theme.colors.primary[0],
    boxShadow: theme.other.sdw.left,
    borderBottom: `4px solid ${ theme.colors.info[0] }`,
    cursor: 'pointer',
    marginBottom: 15
  },
  siteInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 5px 15px 25px'
  },
  siteIcon: {
    display: 'flex'
  },
  siteInfo: {
    marginLeft: 12,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  siteName: {
    color: theme.white
  },
  siteAddress: {
    color: theme.colors.neutral[5],
    lineHeight: theme.other.lh.l,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));
