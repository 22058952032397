import { Radio, Select } from "@mantine/core";
import { FC } from "react";

import TextToSpeech
  from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Channel-Configuration/VoiceConfiguration/VoiceMessageTypes/TextToSpeech";
import UploadAudioMessageComponent
  from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Channel-Configuration/VoiceConfiguration/VoiceMessageTypes/UploadAudioMessage/UploadAudioMessage.component";
import VoiceRecording
  from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Channel-Configuration/VoiceConfiguration/VoiceMessageTypes/VoiceRecording";
import { useCreateCommsPageStyles } from "@/tenant-context/control-mass-comms/components/CreateCommsPage/CreateCommsPage.styles";
import { VOICE_MESSAGE_TYPES } from "@/tenant-context/control-mass-comms/types/MassComms.types";

type Props = {
  selectedVoiceMessageType: VOICE_MESSAGE_TYPES,
  onVoiceMessageTypeChanged: (type: VOICE_MESSAGE_TYPES) => void;
}

const VoiceConfigurationComponent: FC<Props> = ({
  selectedVoiceMessageType,
  onVoiceMessageTypeChanged
}) => {

  const { classes, cx } = useCreateCommsPageStyles({ isCommsSequenceNoAvailable: true });

  return (
    <div className={ classes.tabContent }>
      <div className={ classes.extendedMargin }>
        <div className={ classes.channelConfigWrapper }>
          <div>
            <div className={ cx(classes.itemHeader) }>Call Originator</div>
            <Select
              className={ classes.originatorSelect }
              value={ '+44 7418 311445' }
              // ToDo - real value integration
              data={ ['+44 7418 311445'] }
            />
          </div>

          <div>
            <div className={ cx(classes.itemHeader) }>Attempts</div>
            <Select
              className={ classes.originatorSelect }
              value={ '1' }
              data={ ['1', '2', '3'] }
            />
          </div>
        </div>

        <div className={ classes.extendedMargin }>
          <div className={ classes.smsContentTitle }>Audio Content </div>

          <div className={ classes.voiceContentWrapper }>
            <div>Select an option to add audio content</div>

            <div>
              <Radio.Group
                className={ cx(classes.radioGroup, classes.voiceMessageTypesRadioWrapper) }
                defaultValue={ selectedVoiceMessageType }
                onChange={ onVoiceMessageTypeChanged }
              >
                <Radio value={ VOICE_MESSAGE_TYPES.UPLOAD_AUDIO } label="Upload an audio file"></Radio>
                <Radio value={ VOICE_MESSAGE_TYPES.RECORD_AUDIO } label="Record an audio"></Radio>
                <Radio value={ VOICE_MESSAGE_TYPES.TEXT_TO_SPEECH } label="Text to speech"></Radio>
              </Radio.Group>
            </div>

            <div>
              { selectedVoiceMessageType === VOICE_MESSAGE_TYPES.UPLOAD_AUDIO && (
                <UploadAudioMessageComponent />
              ) }

              { selectedVoiceMessageType === VOICE_MESSAGE_TYPES.RECORD_AUDIO && (
                <VoiceRecording />
              ) }

              { selectedVoiceMessageType === VOICE_MESSAGE_TYPES.TEXT_TO_SPEECH && (
                <TextToSpeech />
              ) }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoiceConfigurationComponent;
