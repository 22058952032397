import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

export const TransferListStyle = {
  defaultProps: {
    className: 'mantine-transferList-override'
  },
  styles: (theme: MantineTheme) => ({
    transferList: {
      backgroundColor: `${theme.other.semantic.surfaceBackground.secondary}`,
      padding: '24px !important'
    },
    transferListHeader: {
      gap: '8px !important',
      height: '48px !important',
      minHeight: '48px !important'
    },
    transferListItem: {
      padding: '8px !important',
      marginRight: '0px !important',
      marginLeft: '0px !important'
    },
    transferListBody: {
      backgroundColor: 'transparent !important',
      border: `none !important`
    },
    transferListSearch: {
      border: `1px solid ${theme.other.semantic.border.general.lighter}`,
      height: '32px !important',
      minHeight: '32px !important'
    },
    transferListTitle: {
      marginBottom:  '16px !important'
    },
    transferListControl:{
      border: `1px solid ${theme.other.semantic.border.general.lighter}`,
      height: '32px !important',
      minHeight: '32px !important',
      '&:disabled': {
        border: 'none !important'
      }
    }
  })
};