import {
  Button,
  SegmentedControl,
  Stack
} from "@mantine/core";
import React, { useCallback } from "react";

import TreeViewItem from "@/common/components/TreeViewItem";
import { RiskProvidersTree } from "@/tenant-context/control-risk-config/components/RiskProvidersTree";
import { Sections } from "@/tenant-context/control-risk-config/context/MapLayersDrawer.context";
import BusinessDataLayers from "@/tenant-context/core/controls/DataOptionsControl/components/BusinessDataLayers.container";
import ContextualLayers from "@/tenant-context/core/controls/DataOptionsControl/components/ContextualLayers.container";

import {
  useButtonAsLinkStyles,
  useSegmentedControlStyles
} from "./MapLayers.styles";

type Props = {
  openedTreeItems: Set<string>;
  onToggleTreeItem?: (isExpanded: boolean, itemValue: string) => void;
  isShowOnlyImpactedAlerts: boolean;
  onImpactRiskEventsToggleChange(checked: boolean): void;
  onToggleRiskTimelineClick(): void;
  isRiskTimelineOpened: boolean;
};

const MapLayersComponent: React.FC<Props> = ({
  openedTreeItems,
  onToggleTreeItem,
  isShowOnlyImpactedAlerts,
  onImpactRiskEventsToggleChange,
  onToggleRiskTimelineClick,
  isRiskTimelineOpened
}) => {
  const { classes: segmentedControlClasses } = useSegmentedControlStyles();
  const { classes: buttonAsLinkClasses } = useButtonAsLinkStyles();
  const handleToggleTreeItem = useCallback(
    (
      isExpanded: boolean,
      ev: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (ev.currentTarget.value) {
        onToggleTreeItem?.(isExpanded, ev.currentTarget.value);
      }
    },
    [onToggleTreeItem]
  );

  const handleImpactedAlertsToggleChange = useCallback(
    (value: string) => {
      onImpactRiskEventsToggleChange(value === "onlyImpactedAlerts");
    },
    [onImpactRiskEventsToggleChange]
  );

  return (
    <div>
      <TreeViewItem
        mt={ 16 }
        headerText="Business data"
        buttonValue={ Sections.BusinessData }
        isExpanded={ openedTreeItems.has(Sections.BusinessData) }
        onToggle={ handleToggleTreeItem }
        collapsedContent={ <BusinessDataLayers /> }
      />

      <TreeViewItem
        mt={ 40 }
        headerText="Threat data"
        buttonValue={ Sections.ThreatData }
        isExpanded={ openedTreeItems.has(Sections.ThreatData) }
        onToggle={ handleToggleTreeItem }
        collapsedContent={ <Stack spacing="md" mt={ 8 }>
          <SegmentedControl
            classNames={ segmentedControlClasses }
            radius={ 4 }
            value={ isShowOnlyImpactedAlerts ? "onlyImpactedAlerts" : "allAlerts" }
            onChange={ handleImpactedAlertsToggleChange }
            data={ [
              { label: "Impacting us", value: "onlyImpactedAlerts" },
              { label: "All events", value: "allAlerts" }
            ] }
          />

          <Stack spacing="md" ml={ 32 }>
            <RiskProvidersTree />

            <Button
              variant="subtle"
              compact
              classNames={ buttonAsLinkClasses }
              onClick={ onToggleRiskTimelineClick }
            >
              { isRiskTimelineOpened ? "Hide" : "Show" } risk timeline
            </Button>
          </Stack>
        </Stack> }
      />

      <TreeViewItem
        mt={ 40 }
        headerText="Contextual data"
        buttonValue={ Sections.ContextualData }
        isExpanded={ openedTreeItems.has(Sections.ContextualData) }
        onToggle={ handleToggleTreeItem }
        collapsedContent={ <ContextualLayers /> }
      />
    </div>
  );
};

export default MapLayersComponent;
