import { ActionIcon, Menu, TextInput } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as BarsSort } from "@/common/icons/bars-sort.svg";
import { ReactComponent as SearchIcon } from '@/common/icons/search-gray.svg';
import {
  useCountryDetailViewDrawerStyles
} from "@/tenant-context/control-travellers-search/drawers/CountryDetailViewDrawer/CountryDetailViewDrawer.styles";
import {
  TravellersInCountrySortType
} from "@/tenant-context/control-travellers-search/types/travelSearch.types";

type Props = {
  handleSort: (sortType: TravellersInCountrySortType) => () => void;
  handleSearchChange: (searchText: string) => void;
}

export const CountryDetailViewFilter: FC<Props> = ({
  handleSort,
  handleSearchChange
}) => {
  const { classes } = useCountryDetailViewDrawerStyles();

  return (
    <div className={ classes.filtersContainer }>

      <Menu shadow="md">
        <Menu.Target>
          <ActionIcon className={ classes.sortButton } variant={ 'subtle' }>
            <BarsSort/>
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown className={ classes.dropdown }>
          <Menu.Label className={ classes.dropdownLabel }>Sort by:</Menu.Label>
          <Menu.Item
            className={ classes.dropdownItem }
            onClick={ handleSort(TravellersInCountrySortType.TRIP_DATE) }>
            Trip Date
            <span className={ classes.dropdownItemDescription }>
              (recent first)
            </span>
          </Menu.Item>
          <Menu.Item
            className={ classes.dropdownItem }
            onClick={ handleSort(TravellersInCountrySortType.NAME) }>
            Traveller’s name
            <span className={ classes.dropdownItemDescription }>
              (A -{ ">" } Z)
            </span>
          </Menu.Item>
          { /*<Menu.Item*/ }
          { /*  disabled*/ }
          { /*  className={ classes.dropdownItem }*/ }
          { /*  onClick={ handleSort(TravellersInCountrySortType.RISK) }>*/ }
          { /*  City Risk Rating*/ }
          { /*  <span className={ classes.dropdownItemDescription }>*/ }
          { /*    (highest to lowest)*/ }
          { /*  </span>*/ }
          { /*</Menu.Item>*/ }
        </Menu.Dropdown>
      </Menu>


      <TextInput
        className={ classes.searchInput }
        icon={ <SearchIcon className={ classes.searchIcon }/> }
        // eslint-disable-next-line react/jsx-no-bind
        onChange={ (evt) => handleSearchChange(evt.target.value) }
      />

    </div>
  );
};
