
export const handleTravelStatus = (
  typeOfTravel: string | undefined,
  startMillis: number | undefined,
  endMillis: number | undefined
): string | null => {
  if (startMillis && endMillis && typeOfTravel) {
    const currentUtcInMilis = Date.now();
    const startMillisCorrected = Number(startMillis.toString().padEnd(13, '0'));
    const endMillisCorrected = Number(endMillis.toString().padEnd(13, '0'));

    if (currentUtcInMilis >= (startMillisCorrected - 72000000)
            && currentUtcInMilis < startMillisCorrected) {
      return typeOfTravel === 'AIR' || typeOfTravel === 'FLIGHT' ? `At Departure Airport` : `At Departure ${typeOfTravel}`;
    } else if (currentUtcInMilis > startMillisCorrected
            && currentUtcInMilis < endMillisCorrected) {
      return typeOfTravel === 'AIR' || typeOfTravel === 'FLIGHT' ? `In Flight` : `In ${typeOfTravel}`;
    } else if (currentUtcInMilis <= (endMillisCorrected + 36000000)
            && currentUtcInMilis > endMillisCorrected) {
      return typeOfTravel === 'AIR' || typeOfTravel === 'FLIGHT' ? `At Arrival Airport` : `At Arrival ${typeOfTravel}`;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const handleAdapterSourceDisplay = (name: string): string => {
  switch (name) {
  case 'gps-adapter-service':
    return 'GPS - Approximate Location';
  case 'travel-book-adapter-service':
    return 'Travel Itinerary';
  case 'site-zone-access-adapter-service':
    return 'Access Control';
  case 'person-profile-service':
    return 'Work Location';
  case '-':
    return 'GPS - App Location'; // LW App
  default:
    return name;
  }
};

export const handleLocationTypeDisplay = (name: string): string => {
  switch (name) {
  case 'gps-adapter-service':
    return 'GPS - Approximate Location';
  case 'travel-book-adapter-service':
    return 'Traveling';
  case 'site-zone-access-adapter-service':
    return 'Access Control';
  case 'person-profile-service':
    return 'Work Location';
  case '-':
    return 'GPS - App Location'; // LW App
  default:
    return name;
  }
};
