import { SwitchStylesParams } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

import { CSS_KEY } from "@/core/styles/mantine/constants";


const getThumbSize = (size: string) => {
  switch(size) {
  case 'xs':
  case 'sm':
  case 'md':
    return 6;
  case 'lg':
  case 'xl':
    return 14;
  default:
    return 14;
  }
};

const getThumbGap = (size: string) => {
  switch(size) {
  case 'xs':
  case 'sm':
  case 'md':
    return 5;
  case 'lg':
  case 'xl':
    return 6;
  default:
    return 4;
  }
};

const getTrackHeight = (size: string) => {
  switch(size) {
  case 'xs':
  case 'sm':
  case 'md':
    return 16;
  case 'lg':
  case 'xl':
    return 24;
  default:
    return 24;
  }
};

const getTrackWidth = (size: string) => {
  switch(size) {
  case 'xs':
  case 'sm':
  case 'md':
    return 32;
  case 'lg':
  case 'xl':
    return 44;
  default:
    return 44;
  }
};

const labelFontSize = (size: string) => {
  switch(size) {
  case 'xs':
  case 'sm':
  case 'md':
    return 12;
  case 'lg':
  case 'xl':
    return 16;
  default:
    return 16;
  }

};

export const SwitchComponentStyle = {
  defaultProps: {
    size: 'lg'
  },
  styles: (theme: MantineTheme, params: SwitchStylesParams) => ({
    root: {
      height: getTrackHeight(params.size)
    },
    label:{
      fontSize: labelFontSize(params.size),
      lineHeight: theme.other.lh.l,
      height: getTrackHeight(params.size)
    },
    input: {
      backgroundColor:theme.colors.neutral[5],
      borderColor:theme.colors.neutral[5],
      '&:checked': {
        '& + label':{
          backgroundColor: theme.other.semantic.accent[1],
          borderColor: theme.other.semantic.accent[1],

          [`& > .${ CSS_KEY }-Switch-thumb`]: {
            left: `calc(100% - ${getThumbSize(params.size)}px - ${getThumbGap(params.size)}px) !important`,
            backgroundColor: theme.colors.neutral[0]
          }
        },

        '&:disabled':{
          '& + label':{
            backgroundColor: theme.other.semantic.icon.general.activeDisabled,
            borderColor: theme.other.semantic.icon.general.disabled,

            [`& > .${ CSS_KEY }-Switch-thumb`]: {
              backgroundColor: theme.other.semantic.icon.general.onDisabled
            }
          }
        }
      },
      //when switch is disabled
      '&:disabled':{
        '& + label': {
          backgroundColor: theme.other.semantic.icon.general.disabled,
          borderColor: theme.other.semantic.icon.general.disabled,

          [`& > .${ CSS_KEY }-Switch-thumb`]: {
            backgroundColor: theme.colors.neutralDarkShades[1]
          }
        }
        // backgroundColor:`${theme.colors.neutral[5]} !important`,
        // borderColor:`${theme.colors.neutral[6]} !important`,
        // cursor:'unset',
        // //switch's thumb styles
        // '&:before':{
        //   backgroundColor:`${theme.colors.neutral[4]} !important`,
        //   borderColor:`${theme.colors.neutral[4]} !important`
        // },
        // //when switch is checked
        // '&:checked':{
        //   backgroundColor:`${theme.colors.neutral[6]} !important`,
        //   borderColor:`${theme.colors.neutral[6]} !important`
        // },
        // //when switch is checked thumb's styles
        // '&:checked::before':{
        //   backgroundColor:`${theme.colors.neutral[5]} !important`,
        //   borderColor:`${theme.colors.neutral[5]} !important`
        // }
      }
    },
    thumb: {
      height: getThumbSize(params.size),
      width: getThumbSize(params.size),
      left: getThumbGap(params.size),
      backgroundColor: theme.colors.neutralDarkShades[3],
      border: 'none'
    },

    track: {
      minWidth: 'unset',
      height: getTrackHeight(params.size),
      width: getTrackWidth(params.size),
      backgroundColor: theme.other.semantic.icon.general.inactive
    }
  })
};
