import { Box, Flex, Rating, Stack, Text } from '@mantine/core';
import { FC, ReactNode } from 'react';

import { ReactComponent as StarEmpty } from '@/common/icons/Components/rating-star-empty.svg';
import { ReactComponent as StarFilled } from '@/common/icons/Components/rating-star-filled.svg';
import {
  useDetailedCountryReportStyles
} from '@/tenant-context/control-reports/components/DetailedCountryReport/DetailedCountryReport.styles';
import { getRatingValueByRatingLevel } from '@/tenant-context/control-reports/util/country-risk-reports';

type Props = {
  title: string;
  reportData?: {
    [key: string]: {
      title: string;
      text: string;
      level?: string;
    };
  };
  getSubsectionIcon: (key: string) => ReactNode;
  isLevelShownAsRating?: boolean;
}

const ReportSectionComponent: FC<Props> = ({
  title,
  reportData,
  getSubsectionIcon,
  isLevelShownAsRating
}) => {
  const { classes } = useDetailedCountryReportStyles({});

  if (!reportData) {
    return null;
  }

  return (
    <Stack spacing="md">
      <Text className={ classes.reportSectionHeading }>{ title }</Text>
      <Flex gap="lg" wrap="wrap">
        { Object.keys(reportData).map((key, index) => {
          const ratingValue = getRatingValueByRatingLevel(reportData[key].level);
          return <Stack key={ index } spacing="md">
            <Flex align="center" gap={ 8 }>
              { getSubsectionIcon(key) && <Box className={ classes.titleIcon }>{ getSubsectionIcon(key) }</Box> }
              <Text className={ classes.subSectionHeading }>{ reportData[key].title }</Text>
              { isLevelShownAsRating && reportData[key].level && <Rating
                emptySymbol={ <StarEmpty /> }
                fullSymbol={ <StarFilled /> }
                readOnly
                defaultValue={ ratingValue }
              /> }
            </Flex>
            <div
              className={ classes.content }
              dangerouslySetInnerHTML={ { __html: reportData[key].text } }
            />
          </Stack>
        }) }
      </Flex>
    </Stack>
  );
};

export default ReportSectionComponent;
