import { RowClickedEvent } from "ag-grid-community/dist/lib/events";
import { FC, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import usePermission from "@/common/hooks/usePermission";
import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch } from '@/core/store';
import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";
import { ArcSentCommunication } from "@/tenant-context/control-action-response-center/types/ARC.types";
import { MassCommsPoliciesConfig } from "@/tenant-context/control-mass-comms/config/mass-comms.policies";

import NotificationSummaryComponent from "./NotificationSummary.component";

const NotificationSummary: FC = () => {

  const navigate = useNavigate();
  const { routes } = RouterConfig;

  const {
    massCommsReport: {
      SET_IS_MASS_COMMS_FROM_ARC
    } 
  } = useDispatch<Dispatch>();

  const { arcSentCommunications } = useContext(ArcContext);
  const isMassCommsReportsPermissionAvailable = usePermission(MassCommsPoliciesConfig.MC_REPORTS_PAGE);

  const handleRowClick = useCallback((event: RowClickedEvent<ArcSentCommunication>) => {
    if (isMassCommsReportsPermissionAvailable && event.data?.communicationTid) {
      navigate(routes.massCommsReports(event.data?.communicationTid));
      SET_IS_MASS_COMMS_FROM_ARC(true);
    }
  }, [ isMassCommsReportsPermissionAvailable, navigate, routes, SET_IS_MASS_COMMS_FROM_ARC ]);

  return (
    <NotificationSummaryComponent
      arcSentCommunications={ arcSentCommunications }
      onRowClicked={ handleRowClick }
    />
  );
};

export default NotificationSummary;
