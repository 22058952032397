import { FC } from "react";

import { capitalizeWord } from "@/common/util/format/string";
import { PrintJobStatus } from "@/tenant-context/control-tenant-admin/types/tenantAccessCard";

import { VisitRequestTag } from "../../types/visitorManagement";
import { useVisitRequestStyles } from "../VisitRequest/VisitRequest.styles";

type Props = {
    tag: VisitRequestTag | PrintJobStatus
}

const VisitRequestTagLabel: FC<Props> = ({ tag }) => {

  const { classes, cx } = useVisitRequestStyles();

  return (
    <div className={ cx(classes.tag, tag) }>
      { capitalizeWord(tag.split("_").join(" ")) }
    </div>
  );
};

export default VisitRequestTagLabel;