import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const useGlobalSearchBarStyles = createStyles(() => ({
  searchBar: {
    [`.${CSS_KEY}-ScrollArea-viewport > div > div`]:{
      padding:'0px !important'
    }
  }
}));
