import { FC, useCallback, useMemo } from "react";

import { ReactComponent as EyeIcon } from "@/common/icons/eye.svg";
import {
  useImpactSummaryCardStyles
} from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactSummaryCard/ImpactSummaryCard.styles";
import { ArcImpactStatus } from "@/tenant-context/control-action-response-center/types/ARC.types";

type Props = {
  count: number,
  status: ArcImpactStatus,
  isSelected: boolean,
  isSomethingElseSelected: boolean,
  isAllSelected: boolean,
  onClick: (status: ArcImpactStatus) => void
}

const ImpactSummaryCard: FC<Props> = ({
  count,
  status,
  isSelected,
  isSomethingElseSelected,
  isAllSelected,
  onClick
}) => {

  const statusText = useMemo(() => {
    const statusTextMap: Record<ArcImpactStatus, string> = {
      'NOT_SAFE': 'Not Safe',
      'NO_RESPONSE': 'No Response',
      'SAFE': 'Safe',
      'OTHER': 'Other'
    };

    return statusTextMap[status];
  }, [ status ]);

  const handleCardClicked = useCallback(() => {
    onClick(status);
  }, [ status, onClick ]);

  const { classes } = useImpactSummaryCardStyles({ status, isSelected, isSomethingElseSelected });
  return (
    <button onClick={ handleCardClicked } className={ classes.statusCard }>
      { (isAllSelected || isSelected) && <div className={ classes.eyeIcon }><EyeIcon /></div> }
      <div className={ classes.count }>{ count }</div>
      <div className={ classes.text }>{ statusText.toUpperCase() }</div>
    </button>
  );

};

export default ImpactSummaryCard;
