import { createStyles, MantineTheme } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

type Args = {
  initColor: string
}

export const useAddTagsPopoverStyles = createStyles((theme:MantineTheme, { initColor }: Args) => ({
  addTagsContainer: {
    marginTop: 24,
    position: 'relative'
  },
  popover: {
    width: 528,
    height: 'auto',
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    backgroundColor: theme.colors.neutral[8],
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    color: theme.colors.neutral[5]
  },
  heading: {
    fontSize: 16
  },
  select: {
    margin: 0,
    [`.${CSS_KEY}-MultiSelect-input`]: {
      height: 'auto !important'
    },
    [`.${CSS_KEY}-MultiSelect-values`]: {
      columnGap: 8,
      height: 'auto !important',
      maxHeight: 95,
      overflowY: 'auto'
    },
    [`.${CSS_KEY}-Badge-root`]: {
      margin: '5px 0'
    },
    [`.${CSS_KEY}-MultiSelect-dropdown`]: {
      border: `0.5px solid ${theme.colors.neutral[6]}`,
      position: 'relative',
      top: '0 !important',
      left: '0 !important',
      [`.${CSS_KEY}-MultiSelect-item`]: {
        border: 'none !important'
      }
    }
  },
  changeInitColor: {
    backgroundColor: initColor,
    marginRight: 12,
    borderRadius: 99,
    '&:hover': {
      backgroundColor: initColor,
      opacity: 0.8
    }
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
  controllers: {
    display: 'flex',
    flexDirection: 'row-reverse',
    gap: 24
  },
  colorSwatches: {
    width: 'auto',
    [`.${CSS_KEY}-ColorPicker-swatches`]: {
      gap: 16
    },
    [`.${CSS_KEY}-ColorPicker-swatch`]: {
      borderRadius: 99
    }
  },
  triggerButton: {
    all: 'unset',
    backgroundColor: 'transparent !important',
    cursor: 'pointer',
    color: theme.white,
    '&:hover': {
      '*': {
        color: `${theme.colors.warning[2]} !important`,
        '& > svg': {
          '& > path': {
            fill: `${theme.colors.warning[2]} !important`
          }
        }
      },
      backgroundColor: 'transparent !important'
    },
    '&:active, &:focus-visible': {
      all: 'unset',
      border: 'none !important'
    }
  }
}));
