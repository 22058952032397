import { createStyles } from "@mantine/core";

type Args ={
  width: number,
  height: number
}
export const useCountryFlagStyles = createStyles((theme, {
  width, height
}: Args ) => ({
  flag: {
    objectFit: 'cover',
    objectPosition: 'center',
    marginRight: 16
  },

  imgPlaceholder:{
    width,
    height,
    backgroundColor: theme.colors.neutral[6],
    marginRight: 16,
    display:  "inline-block"
  }
}));

