import { createStyles } from "@mantine/core";

import { CSS_KEY } from '@/core/styles/mantine/constants';

export const useSessionTimeoutWarningStyles = createStyles(() => ({
  modal: {
    pointerEvents: 'none',
    [`.${CSS_KEY}-Modal-modal`]: {
      pointerEvents: 'auto',
      minWidth: 400
    }
  }
}));
