import { Text, Tooltip } from '@mantine/core';
import React, { FC } from 'react';

type Props = {
  numberOfLines: number;
  width?: number | 'auto';
  children: React.ReactNode;
};
const LineClamp: FC<Props> = ({
  numberOfLines = 3,
  width = 300,
  children
}) => {
  return (
    <Tooltip
      position="top"
      transition="fade"
      label={ children }
      width={ width }
      multiline
    >
      <Text
        lineClamp={ numberOfLines }
      >
        { children }
      </Text>
    </Tooltip>
  );
};

export default LineClamp;
