import { Button, Flex, useMantineTheme } from "@mantine/core";
import { FC, useCallback } from "react";

import { Popup } from "@/common/components/Popup/Popup.components";
import { ReactComponent as CrosshairIcon } from "@/common/icons/crosshair.svg";
import { useCurrentPopup } from "@/tenant-context/common/hooks/useMapPopupList";
import { BSOCPopupContent } from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/BSOCPopupContent.component";
import { DataMinrPopupContent } from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/DataMinrPopupContent.component";
import { MaxSecurityPopupContent } from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/MaxSecurityPopupContent.component";
import { useRiskAlertPopupStyles } from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/RiskAlertPopup.styles";
import { RisklinePopupContent } from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertPopup/RisklinePopupContent.component";
import { DataMinrExternalData } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";
import { MaxSecurityExternalData } from "@/tenant-context/visualisation-risk-alerts/types/max-security";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type Props = {
  popupId: string;
  onClosePopup(): void;
  onClickRiskDetails(riskEvent: RiskAlertEvent): void;
  onClickZoomTo(riskEvent: RiskAlertEvent): void
};

export const RiskAlertPopup: FC<Props> = ({
  popupId,
  onClosePopup,
  onClickRiskDetails,
  onClickZoomTo
}) => {
  const theme = useMantineTheme();
  const { classes } = useRiskAlertPopupStyles();
  const { popup, closePopup } = useCurrentPopup<RiskAlertEvent>("bigMapPopups", popupId);

  const handleClosePopup = useCallback(
    () => {
      closePopup();
      onClosePopup();
    },
    [closePopup, onClosePopup]
  );

  const handleDetailsClick = useCallback(
    () => popup && onClickRiskDetails(popup.data),
    [popup, onClickRiskDetails]
  );

  const handleClickZoomTo = useCallback(
    () => popup && onClickZoomTo(popup.data),
    [popup, onClickZoomTo]
  );

  if (!popup) {
    return null;
  }

  const [longitude, latitude] = popup.position;
  const riskAlertData = popup.data;

  if (!riskAlertData) {
    return null;
  }

  return (
    <Popup
      longitude={ longitude }
      latitude={ latitude }
      padding="16px 0"
      offset={ 20 }
      backgroundColor={ theme.colors.neutral[8] }
      onClose={ handleClosePopup }
      closeOnClick={ false }
      closeButton={ true }
    >
      { riskAlertData.source === "riskline" && (
        <RisklinePopupContent
          classes={ classes }
          meta={ riskAlertData?.meta }
          json={ riskAlertData?.json }
          riskAlertData={ riskAlertData }
          isImpacted={ riskAlertData.isImpacted }
        />
      ) }

      { riskAlertData.source === "dataminr" && (
        <DataMinrPopupContent
          classes={ classes }
          meta={ riskAlertData?.meta }
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          json={ riskAlertData?.json }
          riskAlertData={ riskAlertData as unknown as RiskAlertEvent<DataMinrExternalData> }
          isImpacted={ riskAlertData.isImpacted }
        />
      ) }

      { riskAlertData.source === "max-security" && (
        <MaxSecurityPopupContent
          classes={ classes }
          meta={ riskAlertData?.meta }
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          json={ riskAlertData?.json }
          riskAlertData={ riskAlertData as unknown as RiskAlertEvent<MaxSecurityExternalData> }
          isImpacted={ riskAlertData.isImpacted }
        />
      ) }

      { riskAlertData.source === "bsoc" && (
        <BSOCPopupContent
          classes={ classes }
          meta={ riskAlertData?.meta }
          json={ riskAlertData?.json }
          riskAlertData={ riskAlertData }
        />
      ) }

      <Flex gap={ "xl" } justify="end" pt={ 8 } pr={ 16 }>
        <Button
          size="md"
          variant="light"
          onClick={ handleClickZoomTo }
          rightIcon={ <CrosshairIcon /> }
        >
          Zoom To
        </Button>
        <Button size="md" variant="light" onClick={ handleDetailsClick }>
          More Details
        </Button>
      </Flex>
    </Popup>
  );
};

export default RiskAlertPopup;
