import { createModel } from "@rematch/core";

import { RootModel } from "@/core/store";

import { drawerEffects } from './drawer.effects';
import { drawerReducers } from './drawer.reducers';
import { drawerState } from './drawer.state';

export const drawer = createModel<RootModel>()({
  state: drawerState,
  reducers: drawerReducers,
  effects: drawerEffects
});