import { ReactNode } from 'react';

import { PaginatedResult, ProfileGeneral } from '@/tenant-context/control-profile/types/profile';
import { TenantDetails, TenantUser, UserPolicy } from '@/tenant-context/navigation/types/user';

export type NavIconProps = {
  isMute?: boolean;
  isExpanded?: boolean;
}

export interface OutsystemsTokenResponse {
  access_token: string;
  refresh_token: string | null;
  token_type: 'bearer';
  expires_in: number;
}
export interface OfflineProxyTokenResponse {
  platformAccessToken: string;
  platformRefreshToken: string | null;
  tenantAccessToken: string;
  tenantRefreshToken: string;
}

export enum OutsystemIntegrationApplication {
  'global-case' = 'IMSGCT',
  'global-security' = 'IMSGSL',
  'global-dashboard-back-office' = 'GDBO',
  'global-dashboard-platform' = 'GD',
  'incident-management' = 'IMS',
  'incident-management-config' = 'IMS-CONFIG'
}
export enum ProductName {
  LoneWorker = 'Lone Worker',
  ICM = "Incident & Crisis Manager",
  LocationConfiguration = "Location",
  LocationManagement = "LocationManagement",
  LastMile = "Last Mile",
  CM = "Crisis Management",
  TravelPlanning = "Travel Planning",
  GlobalMap = "Global Map",
  MassComms = "Mass Comms",
  References = 'References'
}

export enum ProductArea {
  Global = 'global',
  UnifiedExperience = 'unified-experience',
  OutSystems = 'outsystems',
  ThirdParty = 'third-party',
  OfflineProxy = 'offline-proxy'
}

export enum NavigationItemId {
  Map = 'map',
  Comms = 'comms',
  People = 'users',
  Sites = 'assets',
  Location = 'location',
  LocationConfiguration = 'locationConfiguration',
  LocationManagement = 'locationManagement',
  IntegrationMapping = 'integrationMapping',
  LocationConfigurationLegacy = 'locationConfigurationLegacy',
  Travel = 'travel',
  Incident = 'icm',
  Reports = 'reports',
  Settings = 'settings',
  Support = 'support',
  Tenant = 'tenant',
  Geofence = 'geofence',
  MyProfile = 'myProfile',
  Library = 'library',
  References = 'references',
  ThreatReportsCountry = 'threatReportsCountry',
  ThreatReportsCity = 'threatReportsCity',
  CrossTenant = 'crossTenant',
  AccessCards = 'accessCards',
  CardLayouts = 'cardLayouts',
  PrintJobs = 'printJobs',
  MoreSettings = 'moreSettings',
  CompanyManagement = 'companyManagement',
  UserManagement = 'userManagement',
  UserGroups = 'userGroups',
  FunctionalRoles = 'functionalRoles',
  VerifiedCheckin = 'verifiedCheckin',
  EndTimeConfig = 'endTimeConfig',
  ConfiguredSites = 'configuredSites',
  IncidentCrisisMan = 'incidentCrisisManager',
  SitesConfig = 'sitesConfig',
  AddLocations = 'addLocations',
  PeopleUsers = 'peopleUsers',
  PeopleGroups = 'peopleGroups',
  Visitors = 'visitors',
  ControlRoomContact = 'controlRoomContact',
  VisitorRequestsDashboard = 'visitorRequestsDashboardModel',
  OfflineProxy = 'offlineProxy',
  SituationTypes = 'SituationTypes',
  None = 'none',
  SeverityLevels = 'severityLevels',
  SitesLegacy= 'sitesLegacy',
  LocationConfigLegacy = 'locationConfigLegacy',
  AssetsManagement = 'assetsManagement',
  Playbooks = 'playbooks',
  CaseTypes='caseTypes'
}

export type NavigationSidebarContextValue = {
  isExpanded: boolean;
  setIsExpanded: (isNavExpanded: boolean) => void;
  usersPolicies: PaginatedResult<UserPolicy> | undefined;
  tenantDetails?: TenantDetails | undefined;
  userDetails?: TenantUser | undefined;
  profileDetails: ProfileGeneral | undefined;
  selectedNavigationItem?: NavigationItemId;
  setSelectedNavigationItem?: (item: NavigationItemId) => void;
  selectedSubNavigationItem?: NavigationItemId;
  setSelectedSubNavigationItem?: (item?: NavigationItemId) => void;
  openedMenu?: NavigationItemId;
  setOpenedMenu?: (menu: NavigationItemId) => void;
}

export enum OutSystemsApplication {
  GlobalDashboard = 'GD',
  Sites = 'GD-SITES',
  ICM = 'IMS',
  Reporting = 'GD-REPORTING',
  LastMile = 'GDBO',
  ICMConfig = 'IMS-CONFIG'
}

export type NavigationSidebarItem = {
  // Icon?: React.ComponentType<NavIconProps>;
  title: string;
  hintMessage?: string | ReactNode;
  path?: string;
  itemId: NavigationItemId;
  area: ProductArea;
  unifiedExperienceApplication?: string;
  outsystemsApplication?: OutSystemsApplication;
  isNotReady?: boolean;
  policies?: string[];
  productName?: ProductName;
  isAlwaysEnabled?: boolean;
  type?: 'link' | 'button' | 'menu',
  isDivided?: boolean;
  icon?: ReactNode,
  subMenuItems?: Array<
    {
      sectionHeader: string,
      subItems: Array<NavigationSidebarItem>
    }>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  redirectParams?: Record<string, any>
};
