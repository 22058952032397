import { createStyles } from "@mantine/core";

export const useSSOIntegrationStyles = createStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',

    '& > svg': {
      width: '50%',
      height: '50%',
      marginTop: 24
    }
  }
}));
