import { ReactNode } from 'react';

import { ReactComponent as CivilUnrest } from "@/common/icons/Indicators/info-civil-unrest.svg";
import { ReactComponent as ArmedConflict } from "@/common/icons/Indicators/info-conflict.svg";
import { ReactComponent as Crime } from "@/common/icons/Indicators/info-crime.svg";
import { ReactComponent as Health } from "@/common/icons/Indicators/info-health.svg";
import { ReactComponent as Militancy } from "@/common/icons/Indicators/info-militancy.svg";
import { ReactComponent as Politics } from "@/common/icons/Indicators/info-politics.svg";
import { ReactComponent as Currency } from "@/common/icons/info-currency.svg";
import { ReactComponent as DomesticFlight } from "@/common/icons/info-domestic-flight.svg";
import { ReactComponent as Language } from "@/common/icons/info-language.svg";
import { ReactComponent as Population } from "@/common/icons/info-population.svg";
import { ReactComponent as Religion } from "@/common/icons/info-relegion.svg";
import { ReactComponent as Vaccination } from "@/common/icons/info-vaccination.svg";
import { ReactComponent as Virus } from "@/common/icons/info-virus.svg";
import { ReactComponent as Weather } from "@/common/icons/info-weather.svg";
import { ReactComponent as WorldFlight } from "@/common/icons/info-world-flight.svg";


export function getInfoIcon(key: string): ReactNode {
  switch (key) {
  case 'currency_rate':
    return <Currency/>;
  case 'currency_name':
    return <Currency/>;
  case 'weather':
    return <Weather/>;
  case 'total_population':
    return <Population/>;
  case 'major_religions':
    return <Religion/>;
  case 'official_languages':
    return <Language/>;
  case 'currency_code':
    return <Currency/>;
  default:
    return '';
  }
}

export function getIndicatorIcon(key: string): ReactNode {
  switch (key) {
  case 'militancy_status':
    return <Militancy/>;
  case 'political_status':
    return <Politics/>;
  case 'civil_status':
    return <CivilUnrest/>;
  case 'armed_status':
    return <ArmedConflict/>;
  case 'health_status':
    return <Health/>;
  case 'crime_status':
    return <Crime/>;
  default:
    return '';
  }
}

export function getSafetyCategoryIcon(key: string): ReactNode {
  switch (key) {
  case 'militancy':
    return <Militancy/>;
  case 'weather_and_natural_hazards':
    return <Weather/>;
  case 'armed_conflict':
    return <ArmedConflict/>;
  case 'kidnap_and_ransom':
    return <Crime/>;
  case 'security_services_and_law_enforcement':
    return <Militancy/>;
  case 'health_general':
    return <Health/>;
  case 'crime':
    return <Crime/>;
  case 'political_situation_and_stability':
    return <Politics/>;
  case 'civil_unrest':
    return <CivilUnrest/>;
  case 'organized_crime':
    return <Crime/>;
  case 'health_covid':
    return <Virus/>;
  case 'government_surveillance_and_intervention':
    return <Militancy/>;
  default:
    return null;
  }
}

export function getTravelSectionIcon(key: string): ReactNode {
  switch (key) {
  case 'domestic_air_travel':
    return <DomesticFlight/>;
  case 'visas':
    return null;
  case 'international_air_travel':
    return <WorldFlight/>;
  case 'difficulties_for_specific_nationalities':
    return null;
  case 'passports':
    return null;
  default:
    return null;
  }
}

export function getSubSectionIcon(key: string): ReactNode {
  switch (key) {
  case 'communications':
    return <Language/>;
  case 'electricity':
    return null;
  case 'travel':
    return <WorldFlight/>;
  case 'utilities':
    return null;
  case 'medical_facilities':
    return <Health/>;
  case 'drugs_and_alcohol':
    return <Vaccination/>;
  case 'lgbtq':
    return null;
  case 'currency_and_the_use_of_money':
    return <Currency/>;
  case 'female_travelers':
    return null;
  case 'traditions_and_customs':
    return null;
  case 'religion':
    return <Religion/>;
  case 'racism_and_xenophobia':
    return null;
  default:
    return null;
  }
}
