import { FC, useCallback } from "react";

import { ReactComponent as CriticalRiskIcon } from "@/common/icons/bsocRiskLevelCriticalIcon.svg";
import { ReactComponent as NonCriticalRiskIcon } from "@/common/icons/bsocRiskLevelNonCriticalIcon.svg";
import { ReactComponent as Crime } from "@/common/icons/crime.svg";
import { ReactComponent as HighImpactExplosiveActivity } from "@/common/icons/HighImpactExplosiveActivity.svg";
import { ReactComponent as UAV } from "@/common/icons/IDFUAVActivity.svg";
import { ReactComponent as IED } from "@/common/icons/IED.svg";
import { ReactComponent as SAF } from "@/common/icons/SAF.svg";
import { ReactComponent as SecurityOperations } from "@/common/icons/SecurityOperations.svg";
import { ReactComponent as ProtestsAndGatherings } from "@/common/icons/unrest.svg";
import theme from "@/core/styles/mantine/theme";
import { Sigact } from "@/tenant-context/visualisation-risk-alerts/types/risk-alert-response";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

import { useRiskAlertStatusStyles } from "./RiskAlertStatus.styles";

type Props = {
    riskAlertData?: RiskAlertEvent,
}

const riskCategories = [
  {
    Icon: <Crime />,
    categorySigact: Sigact.Crime
  },
  {
    Icon: <IED />,
    categorySigact: Sigact.IED
  },
  {
    Icon: <HighImpactExplosiveActivity />,
    categorySigact: Sigact.HighImpactExplosiveActivity
  },
  {
    Icon: <ProtestsAndGatherings />,
    categorySigact: Sigact.ProtestsAndGatherings
  },
  {
    Icon: <SAF />,
    categorySigact: Sigact.SAF
  },
  {
    Icon: <SecurityOperations />,
    categorySigact: Sigact.SecurityOperations
  },
  {
    Icon: <UAV />,
    categorySigact: Sigact.IDFUAVActivity
  }
];

export const RiskALertStatus: FC<Props> = ({
  riskAlertData
}) => {
  const getRiskLevelColor = useCallback(
    (value?: boolean) => {
      return  value ? theme.colors?.risk?.[4] : theme.colors?.risk?.[3];
    },
    []
  );

  const getRiskCategoryIcon = useCallback((sigact?:string) => {
    return !sigact ? "" : riskCategories.find((one) => one.categorySigact === sigact)?.Icon;
  },[]);

  const { classes } = useRiskAlertStatusStyles({
    barColor: getRiskLevelColor(riskAlertData?.json?.externalData?.critical_incident)
  });

  const getRiskLevelHeader = useCallback((value?: boolean) => {
    if (!value) {
      return (
        <div className={ classes.riskCriticalityRow }>
          <NonCriticalRiskIcon /> &nbsp; Non-Critical Event
        </div>) ;
    }

    return value ?
      (
        <div className={ classes.riskCriticalityRow }>
          <CriticalRiskIcon /> &nbsp; Critical Event
        </div>
      )
      :
      (
        <div className={ classes.riskCriticalityRow }>
          <NonCriticalRiskIcon /> &nbsp; Non-Critical Event
        </div>
      );
  },[classes.riskCriticalityRow]);

  return(
    <div className= { classes.riskAlertStatusContainer }>
      <div className={ classes.riskAlertStatusBar } />
      <div className= { classes.riskInfoContainer }>

        { getRiskLevelHeader(riskAlertData?.json.externalData?.critical_incident) }

        <div className={ classes.riskInfoRow }>
          { getRiskCategoryIcon(riskAlertData?.json.externalData?.sigact) }
          &nbsp; { riskAlertData?.json?.externalData?.sigact?.split(/(?=[A-Z])/).join(' ').toUpperCase() }
        </div>
        <div className={ classes.trendCode }>
          { riskAlertData?.json?.externalData?.trend_code }
        </div>
      </div>

    </div>
  );
};

export default RiskALertStatus;


