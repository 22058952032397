export enum ConnectedSitesDashboardTabsTypes {
  ACTIVITY = 'activity',
  SITE_LIST = 'siteList'
}

export type ConnectedSite = {
  tenantId: string
  id: string
  name: string
  description: string
  alias: string[]
  categoryName: string
  subCategoryName: string
  locationType: string
  locationTypeId: number
  criticalLocation: boolean
  address: Address
  timeZone: TimeZone
  imageUrl: string
  proxyType: string
  subdomain: string
  connectionProxyEnabled: boolean
  runningMusterCount: number
  tid: string
  _id?: string
  casualtiesCount: number
  pobCount: number
  lastSyncOn: number
  musteredPeopleCount: number
};

export interface SiteWithPolicies {
  siteDto: SiteDto
  policies: Policy[]
}

export interface SiteDto {
  tenantId: string
  id: string
  name: string
  description: string
  address: Address
  timeZone?: TimeZone
  tid: string
  code?: string

}

export interface Address {
  firstLine: string
  secondLine: string
  thirdLine: string
  postalCode: string
  region: string
  city: string
  country: string
}

export interface TimeZone {
  label: string
  offset: string
  timeZone: string
  value: string
}

export interface Policy {
  _id: string
  policyContext: string
  serviceCategory: string
  policyName: string
  domainOwner: string
  domainContext: string
  apiContext: string
  dependentPolicies: string[]
  brn: string
  trn: string
  root: boolean
  tenantRequirement: string
  meta: Meta
}

export interface Meta {
  createdDate: number
  updatedDate: number
  lastSeq: number
  version: string
  userId: string
  userName: string
}

export enum LocationTypes {
  LocationPin = 'Location Pin',
  ConnectedSiteBasic = "Connected Site - Basic",
  ConnectedSiteAdvanced = "Connected Site - Advance"
}