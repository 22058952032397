import { FeatureCollection } from "geojson";
import { FC, useCallback, useMemo } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import useLayerListener from "@/common/hooks/useLayerListener";
import usePermission from "@/common/hooks/usePermission";
import { Products } from "@/common/types/products";
import { Dispatch, RootState } from "@/core/store";
import { Location } from "@/tenant-context/common/types/location";
import { getClickedOnFeatures } from "@/tenant-context/common/util/map-click";
import {
  countryWiseMapPadding,
  TRAVELLERS_SEARCH_SUB_SLIDES
} from "@/tenant-context/control-travellers-search/config/travellers-search.config";
import { TravelSearchPolicies } from "@/tenant-context/control-travellers-search/config/TravellersSearch.policies";
import { useTravellersSearchContext } from "@/tenant-context/control-travellers-search/context/TravellersSearchContext/TravellersSearch.context";
import { getPersonInitials } from "@/tenant-context/visualisation-people/util/getPersonInitials";
import { countryBoundingBoxes } from "@/tenant-context/visualisation-travel/config/country-bounding-boxes";

import CountryTravelLayerComponent, { allCountryTravelRiskLayerIds } from "./CountryTravelLayer.component";

const CountryTravelLayer: FC = () => {

  const travelCountries = useSelector((state: RootState) => state.travellersSearch.countryWiseTravellers);

  const allCountries = useSelector((state: RootState) => state.commonData.allCountries);

  const selectedCountryDetails = useSelector((state: RootState) => state.travellersSearch.countryDetailData);

  const isAuthorised = usePermission([TravelSearchPolicies.TRAVEL_SEARCH_POLICY]);

  const {
    dataOptions: {
      ENABLE_LAYER_TYPE,
      DISABLE_LAYER_TYPE
    },
    travellersSearch: {
      SET_SELECTED_COUNTRY
    }
  } = useDispatch<Dispatch>();

  const {
    setCurrentlyOpenedSlide,
    highlightedCountryIso,
    setHighlightedCountryIso,
    highlightedTravellerId,
    setHighlightedTravellerId,
    getTravellersForCountry
  } = useTravellersSearchContext();

  const travelCountGeojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: travelCountries.map((travelCountry)=> {

      const country = allCountries.find(
        (countryToFind) => countryToFind.isoCountryCode === travelCountry.countryISOCode
      ) as unknown as Location;

      return {
        type: 'Feature',
        geometry: { type: 'Point', coordinates:  [country.geoPoint.lon, country.geoPoint.lat] },
        properties: {
          travellerCount: travelCountry.travellerCount
        }
      };
    })
  };

  const travellersGeoJson: FeatureCollection = {
    type: 'FeatureCollection',
    features: selectedCountryDetails ? selectedCountryDetails.travellers.map((traveller) => {

      return {
        type: 'Feature',
        geometry: { type: 'Point', coordinates:  [ traveller.personLocation.longitude, traveller.personLocation.latitude] },
        properties: {
          ...traveller,
          travellerId: `${traveller.profileId}-${traveller.correlationId}`,
          personInitials: getPersonInitials(traveller.personName)
        }
      };
    }) : []
  };

  const { current: map } = useMap();
  // const isRiskConnectorsPermissionAvailable = usePermission(CorePolicies.RISK_CONNECTOR_POLICY);

  const riskLevelWiseCountryList = useMemo(() => {
    const riskLevels = {
      "0": [],
      "1": [],
      "2": [],
      "3": [],
      "4": [],
      "5": []
    } as { [key: string]: string[] };

    travelCountries.forEach((country) => {
      switch (country?.risk?.level || 0) {
      case "1":
        riskLevels["1"].push(country.countryISOCode);
        break;
      case "2":
        riskLevels["2"].push(country.countryISOCode);
        break;
      case "3":
        riskLevels["3"].push(country.countryISOCode);
        break;
      case "4":
        riskLevels["4"].push(country.countryISOCode);
        break;
      case "5":
        riskLevels["5"].push(country.countryISOCode);
        break;
      case "0":
      default:
        riskLevels["0"].push(country.countryISOCode);
      }
    });

    return riskLevels;
  }, [ travelCountries ]);

  useLayerListener(
    'mouseenter',
    allCountryTravelRiskLayerIds,
    useCallback(
      (evt) => {
        const { layerFeatures } = getClickedOnFeatures(evt, allCountryTravelRiskLayerIds);

        if (!layerFeatures.length) {
          return;
        }

        const feature = layerFeatures[0];

        const countryISO = feature.properties?.iso_3166_1;
        const hoveredCountry = travelCountries
          .find((country) => country.countryISOCode === countryISO);

        if (!hoveredCountry) {
          return;
        }

        setHighlightedCountryIso(hoveredCountry.countryISOCode);
      },
      [travelCountries, setHighlightedCountryIso]
    )
  );

  useLayerListener(
    'mouseleave',
    allCountryTravelRiskLayerIds,
    useCallback(
      () => {
        setHighlightedCountryIso(undefined);
      },
      [setHighlightedCountryIso]
    )
  );

  useLayerListener(
    'mouseenter',
    [
      'r__countryTravellersLayer'
    ],
    useCallback(
      (evt) => {
        const { layerFeatures } = getClickedOnFeatures(evt, [
          'r__countryTravellersLayer'
        ]);

        if (!layerFeatures.length) {
          return;
        }

        const feature = layerFeatures[0];

        const travellerId = feature.properties?.travellerId;
        const hoveredPerson = selectedCountryDetails?.travellers
          .find((person) => `${person.profileId}-${person?.correlationId}` === travellerId);

        if (!hoveredPerson) {
          return;
        }

        setHighlightedTravellerId(travellerId);
      },
      [selectedCountryDetails, setHighlightedTravellerId]
    )
  );


  useLayerListener(
    'mouseleave',
    [
      'r__countryTravellersLayer'
    ],
    useCallback(
      () => {
        setHighlightedTravellerId(undefined);
      },
      [setHighlightedTravellerId]
    )
  );

  useLayerListener(
    'click',
    allCountryTravelRiskLayerIds,
    useCallback(
      (evt) => {
        const { layerFeatures } = getClickedOnFeatures(evt, allCountryTravelRiskLayerIds);

        if (!layerFeatures.length) {
          return;
        }

        const feature = layerFeatures[0];

        const countryISO = feature.properties?.iso_3166_1;
        const clickedCountry = travelCountries
          .find((country) => country.countryISOCode === countryISO);

        if (!clickedCountry) {
          return;
        }

        SET_SELECTED_COUNTRY(clickedCountry);

        getTravellersForCountry();
        setCurrentlyOpenedSlide(TRAVELLERS_SEARCH_SUB_SLIDES.COUNTRY_DETAIL_VIEW_SLIDE);

        ENABLE_LAYER_TYPE([ToggleableLayerType.TravelCountryDetailsView]);
        DISABLE_LAYER_TYPE([ToggleableLayerType.TravelCountriesView]);

        const countryBBox = countryBoundingBoxes[clickedCountry.countryISOCode as keyof typeof countryBoundingBoxes][1];

        map?.fitBounds(countryBBox as [number, number, number, number], {
          padding: countryWiseMapPadding
        });
      },
      [
        travelCountries,
        map, SET_SELECTED_COUNTRY,
        ENABLE_LAYER_TYPE,
        DISABLE_LAYER_TYPE,
        setCurrentlyOpenedSlide,
        getTravellersForCountry
      ]
    )
  );

  const subscribedProducts = useSelector((state: RootState) => state.commonData.tenantSubscribedProducts);

  const isSubscribedToTravel = useMemo(() => {
    return subscribedProducts?.some(product => product.name === Products.TRAVEL);
  }, [subscribedProducts]);


  if (!isSubscribedToTravel || !isAuthorised) {
    return null;
  }


  return (
    <CountryTravelLayerComponent
      riskLevelWiseCountries={ riskLevelWiseCountryList }
      TravelersCountData={ travelCountGeojson }
      travellersData={ travellersGeoJson }
      highlightedCountryIso={ highlightedCountryIso }
      highlightedTravellerId={ highlightedTravellerId }
    />
  );
};

export default CountryTravelLayer;
