import EventSource from "eventsource";
import { createContext, FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import usePermission from "@/common/hooks/usePermission";
import usePrevious from "@/common/hooks/usePrevious";
import { logger } from "@/common/util/ConsoleLogger";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import { MiniProfileDatails } from "@/tenant-context/control-profile/types/mini-profile";
import { createEaArcCaseEventsStream,createEmergencyVideoStream, getEaArcCaseByProfileId, getTaskById } from "@/tenant-context/employee-app-action-response-center/api/ea-arc";
import { EaArcEmergencyVideoStreamEvent } from "@/tenant-context/employee-app-action-response-center/types/ea-arc";
import { EaArcCase, EaArcCasePersonLocationEvent, EaArcTab, EaTask } from "@/tenant-context/employee-app-action-response-center/types/ea-arc";
import { EmergencyVideo, VideoMode } from "@/tenant-context/employee-app-action-response-center/types/emergency-video";
import { LWPersonStatus, PERSON_RED_ALERT_STATUSES, PERSON_YELLOW_ALERT_STATUSES } from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config";

import { EaArcPoliciesConfig } from "../config/permissions";

export enum KeyEvent {
  EmergencyStarted = 'emergencyStarted',
  TaskStarted = 'taskStarted',
  CurrentLocation = 'currentLocation'
}

type EaArcContextType = {
  activeTab: EaArcTab,
  setActiveTab: (tab: EaArcTab) => void,
  arcCase?: EaArcCase,
  task?: EaTask,
  locationEvents: EaArcCasePersonLocationEvent[],
  personLocationHistoryLine: GeoJSON.Feature<GeoJSON.LineString>,
  reset: () => void,

  currentLocationFeature?: GeoJSON.Feature<GeoJSON.Point>,
  taskStartedFeature?: GeoJSON.Feature<GeoJSON.Point>,
  emergencyStartedFeature?: GeoJSON.Feature<GeoJSON.Point>,
  loadData: () => void,
  isEmergencyVideoShown: boolean,
  setIsEmergencyVideoShown: (isShown: boolean) => void,
  emergencyVideos: EmergencyVideo[],
  caseOwner?: MiniProfileDatails
};

const ONE_SECOND = 1000;

export const EaArcContext = createContext<EaArcContextType>({} as EaArcContextType);

const generateFeatureFromEvent = (event: EaArcCasePersonLocationEvent, action: KeyEvent) => {
  const { geoPoint: { lat, lon }, loneWorkerStatus, fistName = '', lastName } = event;

  const feature = {
    type: 'Feature' as const,
    geometry: {
      type: 'Point' as const,
      coordinates: [lon, lat]
    },
    properties: {
      loneWorkerStatus,
      keyEventType: action,
      personInitials: [fistName?.[0], lastName?.[0]].join('').toUpperCase()
    }
  };

  return feature;
};

const EaArcContextProvider: FC = ({
  children
}) => {
  const [activeTab, setActiveTab] = useState(EaArcTab.Summary);
  const [arcCase, setArcCase] = useState<EaArcCase>();
  const [task, setTask] = useState<EaTask>();
  const [caseOwner, setCaseOwner] = useState<MiniProfileDatails>();
  const [locationEvents, setLocationEvents] = useState<EaArcCasePersonLocationEvent[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmergencyVideoShown, setIsEmergencyVideoShown] = useState(false);
  const [currentEmergencyVideoEvent, setCurrentEmergencyVideoEvent] = useState<EaArcEmergencyVideoStreamEvent>();
  const [emergencyVideos, setEmergencyVideos] = useState<EmergencyVideo[]>([]);

  const eventSourceRef = useRef<EventSource>();
  const emergencyVideoEventSourceRef = useRef<EventSource>();
  const intervalRef = useRef<{
    timer: NodeJS.Timer | null
  }>({
    timer: null
  });

  const currentProfileId = useSelector((state: RootState) => state.userProfile.id);
  const caseId = useSelector((state: RootState) => state.eaArc.caseId);

  const isEaArcOpen = useSelector((state: RootState) => state.eaArc.isOpen);

  const {
    eaArc: {
      SET_CASE_ID,
      SET_CASE_TID,
      loadActivityLog,
      assignCaseOwner
    },
    playbook: {
      loadActions,
      SET_ACTIONS
    },
    profile: {
      loadGeneral
    },
    globalPeople: {
      getMiniProfileById
    }
  } = useDispatch<Dispatch>();

  const personLocationHistoryLine: GeoJSON.Feature<GeoJSON.LineString> = useMemo(() => {
    const coordinates = locationEvents.map((event) => {
      const { geoPoint: { lat, lon } } = event;

      return [lon, lat];
    });

    const feature = {
      type: 'Feature' as const,
      geometry: {
        type: 'LineString' as const,
        coordinates
      },
      properties: {}
    };

    return feature;
  }, [locationEvents]);

  const {
    taskStartedFeature,
    emergencyStartedFeature,
    currentLocationFeature
  } = useMemo(() => {
    const taskStartedIndex = locationEvents.findIndex(
      (event) => event.loneWorkerStatus === LWPersonStatus.TaskStarted
        || event.loneWorkerStatus === LWPersonStatus.TaskOngoing
      || event.loneWorkerStatus === LWPersonStatus.TaskExtended
    );

    const taskStarted = locationEvents[taskStartedIndex];

    const emergencyStarted = locationEvents.find(
      (event) => [
        ...PERSON_RED_ALERT_STATUSES,
        ...PERSON_YELLOW_ALERT_STATUSES
      ].includes(
        event.loneWorkerStatus
      )
    );

    const currentLocation = locationEvents[locationEvents.length - 1];

    const taskStartedFeat = taskStarted && generateFeatureFromEvent(
      taskStarted,
      KeyEvent.TaskStarted
    );
    const emergencyStartedFeat = emergencyStarted && generateFeatureFromEvent(
      emergencyStarted,
      KeyEvent.EmergencyStarted
    );
    const currentLocationFeat = currentLocation && generateFeatureFromEvent(
      currentLocation,
      KeyEvent.CurrentLocation
    );

    const val = {
      taskStartedFeature: taskStartedFeat,
      emergencyStartedFeature: emergencyStartedFeat,
      currentLocationFeature: currentLocationFeat
    };

    return val;
  }, [locationEvents]);

  const loadCaseOwner = useCallback(async (resCase) => {
    const ownerProfile = await getMiniProfileById(resCase.caseOwnerProfileId);

    setCaseOwner(ownerProfile);

    return ownerProfile;
  }, [ getMiniProfileById]);

  const loadArcCaseForCurrentProfile = useCallback(async () => {
    if (!currentProfileId) {
      logger.log('No current profile id');
      return;
    }

    const resCase = await getEaArcCaseByProfileId(currentProfileId);
    setArcCase(resCase);
    SET_CASE_ID(resCase.caseId);
    SET_CASE_TID(resCase.tid);

    if (resCase.caseOwnerProfileId) {
      loadCaseOwner(resCase);
    }

    return resCase;
  }, [SET_CASE_ID, SET_CASE_TID, currentProfileId, loadCaseOwner]);



  useEffect(() => {
    if (caseOwner || !isEaArcOpen) {
      return;
    }

    const interval = setInterval(() => {
      loadArcCaseForCurrentProfile();
    }, ONE_SECOND);

    return () => clearInterval(interval);
  }, [loadArcCaseForCurrentProfile, caseOwner, isEaArcOpen]);

  const loadTaskForCurrentCase = useCallback(async (newCase: EaArcCase) => {
    const taskId = newCase?.taskId ?? arcCase?.taskId;

    if (!taskId) {
      return;
    }

    const resTask = await getTaskById(taskId);
    setTask(resTask);
  }, [arcCase]);

  const initializeEventStream = useCallback(() => {
    const queue: EaArcCasePersonLocationEvent[] = [];

    if (!currentProfileId) {
      logger.log('No current profile id');
      return;
    }

    const stream = createEaArcCaseEventsStream(
      currentProfileId,
      (event) => {
        queue.push(event);
      }
    );

    eventSourceRef.current = stream;

    const interval = setInterval(() => {
      if (queue.length > 0) {
        setLocationEvents((prev) => [...prev, ...queue]);
        queue.length = 0;
      }
    }, ONE_SECOND);

    intervalRef.current.timer = interval;
  }, [currentProfileId]);

  useEffect(() => {
    if (!currentProfileId || !caseId) {
      return;
    }

    const stream = createEmergencyVideoStream(
      currentProfileId,
      caseId,
      (event) => {
        setCurrentEmergencyVideoEvent(event);
      }
    );

    emergencyVideoEventSourceRef.current = stream;

    return () => {
      emergencyVideoEventSourceRef.current?.close();
    };
  }, [ caseId, currentProfileId ]);

  useEffect(() => {
    if (!currentEmergencyVideoEvent) {
      return;
    }

    const {
      status,
      downloadUrl,
      fileFrameHeight,
      fileFrameWidth,
      timestamp,
      videoId
    } = currentEmergencyVideoEvent;

    if (status === 'UPLOAD_SUCCESS') {
      const video: EmergencyVideo = {
        url: downloadUrl,
        mode: fileFrameHeight > fileFrameWidth ? VideoMode.PORTRAIT : VideoMode.LANDSCAPE,
        timestamp,
        videoId
      };

      setEmergencyVideos([
        ...emergencyVideos,
        video
      ]);

      loadActivityLog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currentEmergencyVideoEvent ]);

  const isAuthorized = usePermission(EaArcPoliciesConfig.EDIT_PLAYBOOK);

  const loadData = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    // CYCLE 1
    initializeEventStream();
    const newCase = await loadArcCaseForCurrentProfile();

    // CYCLE 2 (dependent on 1)
    if (!newCase) {
      showNotification({
        message: 'No case found for current profile',
        color: 'error'
      });

      return;
    }

    const shouldAssignCaseOwner = !newCase.caseOwnerProfileId && isAuthorized;

    await Promise.allSettled([
      loadTaskForCurrentCase(newCase),
      loadActivityLog(),
      loadActions("EA-ARC"),
      shouldAssignCaseOwner ? assignCaseOwner() : null
    ]);

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    caseId,
    assignCaseOwner,
    loadActivityLog,
    loadArcCaseForCurrentProfile,
    loadTaskForCurrentCase
  ]);

  const reset = useCallback(() => {
    setActiveTab(EaArcTab.Summary);
    setArcCase(undefined);
    setTask(undefined);
    eventSourceRef.current?.close();
    setLocationEvents([]);
    SET_ACTIONS([]);
    SET_CASE_ID(undefined);
    SET_CASE_TID(undefined);

    eventSourceRef.current?.close();
    eventSourceRef.current = undefined;

    emergencyVideoEventSourceRef.current?.close();
    emergencyVideoEventSourceRef.current = undefined;
    setIsEmergencyVideoShown(false);
    setCurrentEmergencyVideoEvent(undefined);
    setEmergencyVideos([]);

    if (intervalRef.current.timer) {
      clearInterval(intervalRef.current.timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const wasEaArcOpen = usePrevious(isEaArcOpen);

  // CYCLE : DATA LOAD
  const prevProfileId = usePrevious(currentProfileId);
  useEffect(() => {
    (async () => {
      const isProfileChanged = prevProfileId !== currentProfileId;
      const isEaArcJustOpened = isEaArcOpen && !wasEaArcOpen;

      if (
        (isEaArcOpen && isProfileChanged) // if already open and profile changed
        || isEaArcJustOpened // if just opened and profile not changed
      ) {
        await loadData();
      }
    })();
  }, [arcCase, caseId, currentProfileId, isEaArcOpen, loadData, prevProfileId, reset, wasEaArcOpen]);

  // CYCLE : DATA RESET
  useEffect(() => {
    if (wasEaArcOpen && !isEaArcOpen) {
      reset();
    }

    if (isEaArcOpen) {
      loadGeneral();
    }
  }, [currentProfileId, isEaArcOpen, reset, wasEaArcOpen, loadGeneral]);

  return (
    <EaArcContext.Provider value={ {
      activeTab,
      setActiveTab,
      arcCase,
      task,
      locationEvents,
      personLocationHistoryLine,
      taskStartedFeature,
      emergencyStartedFeature,
      currentLocationFeature,
      reset,
      loadData,
      isEmergencyVideoShown,
      setIsEmergencyVideoShown,
      emergencyVideos,
      caseOwner
    } }>
      { /* <EaArc /> */ }
      { children }
    </EaArcContext.Provider>
  );
};

export default EaArcContextProvider;
