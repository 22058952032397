import { PaginatedResult } from "@/common/types/reference-data";

export type SubLocations = Array<{
    tid: string,
    parentTid: string | null,
    meta: {
        tenantId: null,
        createdTime: number,
        updatedTime: number
    },
    name: string,
    description: string
}>;

export type AdditionalInfoObj = {
    contactList: Array<{ profileId: string, name: string }>,
    externalLinks: Array<{ linkName: string, url: string }>
}

export type EnteredLocationDetails = {
    tid?: string,
    id: string,
    code: string,
    parentId: string,
    lat: string,
    lon: string,
    description: string,
    isoCountryCode: string,
    timezones?: GeoTimeZone,
    address01: string,
    address02: string,
    locationType: string,
    locationCategory: string,
    alias: string[],
    countyRegion: string,
    city: string,
    postalCode: string,
    isCriticalLocation: boolean | string,
    subCategoryName?: string,
    name?: string,
    imageUrl?: string
    poiAdditionalInfoRequest?: AdditionalInfoObj | string,
    type?: 'EXISTING_LOCATION' | 'NEW_LOCATION'
};

export type ILocation = {
    code: string
    categoryName: string
    subCategoryName: string
    name: string
    description: string
    address: LocationAddress
    imageUrl: string | null
    timeZone: GeoTimeZone
    tid: string
    locationType?: string
    identifier?: string
    geoPoint?: {
        lat: number
        lon: number
    }
}

export type SitePlanList = {
    fileName: string
    fileSize: string
    fileType: string
    tid: string
    geoJson: string
}

export interface LocationAddress {
    firstLine: string
    secondLine: string
    thirdLine: string
    postalCode: string
    region: string
    city: string
    country: string
  }

export type AddLocationRequest = {
    id: string,
    description: string,
    locationType: string,
    criticalLocation: boolean,
    lat: string,
    lon: string,
    code: string,
    address: {
        firstLine: string,
        secondLine: string,
        postalCode: string,
        region: string,
        city: string,
        country: string
    },
    timeZone: GeoTimeZone,
    alias: string[],
    subCategoryName?: string,
    tid?: string,
    geoPoint?: {
        lat: string,
        lon: string
    },
    imageUrl?: string,
    poiAdditionalInfoRequest?: AdditionalInfoObj,
    locationAdditionalInfo?: AdditionalInfoObj
}

export type GeoTimeZone = {
    value: string,
    label: string,
    country: string | null,
    timeZone: string,
    offset: string,
}

export type LocationCategories = {
    tid: string,
    parentTid: null | string,
    meta: {
        tenantId: null | string,
        createdTime: number,
        updatedTime: number
    },
    name: string,
    description: string | null,
    icon: string | null,
    mapboxReference: null | string,
    type: string,
    tenantId: null | string
}

export type IMarker = {
  lon: number
  lat: number
  title: string
}

export type CategoryDTO = {
    value: string,
    label: string,
    type: CategoryTypes,
    tid?: string,
    icon?: string
}

export enum CategoryTypes{
    Tenant = 'TENANT_DEFINED',
    System = 'SYSTEM_DEFINED'
}

export type LocationZone = {
    name: string
    code: string
    zoneType: string
    lat: string
    lon: string
    buildingTid: string
    floorTid: string
    maxCapacityEnabled: boolean
    maxCapacity: number
    accessControlMode: string
    musterPosition: string
    musterPlacedZone: string
    mappedNormalZones: string[]
    releaseBehavior: string
    peopleGroups: string[]
    //for api
    subCategoryName: string
    locationId: string
    id: string
}
export interface LocationZoneResponse {
    tid: string
    id: string
    name: string
    code: string
    zoneType: string
    geoPoint: GeoPoint
    maxCapacityEnabled: boolean
    maxCapacity: number
    buildingTid: string
    building: string
    floorTid: string
    floor: string
    accessControlMode: string
    musterPosition: string
    musterPlacedZone: string
    mappedNormalZones: string[]
    releaseBehavior: string
    peopleGroups: string[]
    tenantId: string
    createdTime: number
    updatedTime: number
    geoJson: null | string
  }

export type GeoPoint = {
    lat: number,
    lon: number
}
export type UploadGeoJsonRequest = {
    fileName?: string
    fileType?: string
    fileSize?: string
    geoJson: string
}

export type Building = {
    name: string
    code: string
    geoPoint: {
        lat: string
        lon: string
    }
    note?: string
    buildingTid?: string
    geoJson: string | null
}

export interface BuildingListItem {
    buildingTid: string
    code: string
    name: string
    floors: string
    geoPoint: {
        lat: string
        lon: string
    }
    tenantId: string
    note?: string
    geoJson: null | string
}

export interface Meta {
    tenantId: string
    createdTime: number
    updatedTime: number
}

export interface Address {
    firstLine: string
    secondLine: string
    thirdLine: string
    postalCode: string
    region: string
    city: string
    country: string
}

export enum LocationZoneReferenceType {
    zoneType = 'ZONE_TYPE',
    musterZonePosition = 'MUSTER_ZONE_POSITION',
    releaseBehaviour = 'RELEASE_BEHAVIOUR',
    accessControlMode = 'ACCESS_CONTROL_MODE'
}

export interface LocationZoneReferenceState {
    zoneType: ILocationZoneReferenceData[],
    musterZonePosition: ILocationZoneReferenceData[],
    releaseBehaviour: ILocationZoneReferenceData[],
    accessControlMode: ILocationZoneReferenceData[]
}

export interface ILocationZoneReferenceData{
    key: string,
    value: string
}

export type LocationZoneFormOptionalFields = {
    Floor: boolean,
    MaxCapacity: boolean,
    MusterZonePosition: boolean,
    NormalZone: boolean,
    ReleaseBehaviour: boolean,
}

export enum ZoneTypes {
    NormalZone = "Normal Zone",
    MusterZone = "Muster Zone"
}
export enum MusterZonePositions {
    Inside = "Inside Normal Zone",
    Outside = "Outside Normal Zone"
}
export enum ReleaseBehaviour {
    Retain ="Retain People in Muster Zone",
    Release = "Release People to Normal Zone"
}
export enum AccessControlMode {
    Permitted = "Everyone Permitted by Default",
    Restricted = "Everyone Restricted by Default"
}

export type ISelectOptions = {
    label: string,
    value: string
}

export interface BuildingFloorItem {
    floorTid: string
    code: string
    name: string
    floors: string
    geoPoint: {
        lat: string
        lon: string
    }
    tenantId: string
}

export type ActiveMarkerLocation = {
    lat: number,
    lng: number
}
export type CreateFloorRequest = {
    name: string
    code: string
    note?: string
    geoJson?: string
}

export type FloorGeoJson = {
    geoJson: string
    fileType: string
    fileSize: string
    fileName: string
}

export interface FloorListItem {
    floorTid: string
    name: string
    code: string
    zones?: string
    note?: string
    floorGeoJson?: string
    geoJson?: FloorGeoJson
    buildingId?: string
}

export enum CriticalStatuses {
    ALL = "ALL",
    CRITICAL = "CRITICAL",
    NON_CRITICAL = "NON_CRITICAL"
}

export type BulkLocationsFileUpload = {
    bulkDataId: string
    status: string
    summary: BulkLocationsFileUploadSummary
    tenantId: string
}
export type BulkLocationsFileUploadSummary = {
    init: number
    error: number,
    success: number
    total: number
}

export type BulkLocationsFileUploadStatus = {
    fileName: string
    tenantId: string
    bulkDataId: string
    status: string
    uploadedTime: number
    summary: BulkLocationsFileUploadSummary
}

export type UploadedLocationStatus = {
    locationName: string
    description?: string
    category?: string
    latitude?: string
    longitude?: string
    addressLine1?: string
    addressLine2?: string
    errorMessage?: string
}

export type UploadedLocationStatusGridItem = {
    tenantId: string
    bulkDataId: string
    status: string
    createdTime: number
    filename: string
}
export type UploadedLocationStatusGrid =
  PaginatedResult<UploadedLocationStatusGridItem>
  & UploadedLocationStatusGridItem