import { useCallback, useContext, useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import AddBuildingFormComponent
  from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/components/AddBuildingForm/AddBuildingForm.component";
import {
  LocationInfoContext
} from "@/tenant-context/control-location-configuration/context/LocationInfo.context";
import { LocationManagementContext } from "@/tenant-context/control-location-configuration/context/LocationManagement.context";
import { Building } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

const AddBuildingForm = () => {
  const { manageLocations: {
    postCreateBuilding,
    SET_ACTIVE_BUILDING
  } } = useDispatch<Dispatch>();

  const {
    setIsAddBuildingFormOpened,
    isAddBuildingFormOpened,
    isAuthorizedToEdit,
    setBuildingView
  } = useContext(LocationInfoContext);

  const {
    setMarkerLocationExternally
  } = useContext(LocationManagementContext);

  const { activeBuilding, 
    overlayGeoJson, 
    enteredLocationDetails } = useSelector((state: RootState) => state.manageLocations);
  const { AddLocationMap: map } = useMap();
  const formControls = useForm({ mode: 'onChange' });

  const handleFormSubmit = useCallback((data: FieldValues) => {
    postCreateBuilding({
      ...data,
      name: data.name.trim().toUpperCase(),
      code: data.code.trim().toUpperCase(),
      geoJson: JSON.stringify(overlayGeoJson) || ''
    } as Building).then((res) => {
      if (res) {
        setIsAddBuildingFormOpened(false);
      }
    });
  }, [overlayGeoJson, postCreateBuilding, setIsAddBuildingFormOpened]);

  const handleFormClose = useCallback(() => {
    formControls.reset();
    setIsAddBuildingFormOpened(false);
  }, [formControls, setIsAddBuildingFormOpened]);

  const handleLatitudeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isNaN(parseFloat(event.currentTarget.value))) {
        setMarkerLocationExternally(Number(event.currentTarget.value), Number(formControls.getValues("geoPoint.lon")));
      }
    },
    [formControls, setMarkerLocationExternally]
  );

  const handleLongitudeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isNaN(parseFloat(event.currentTarget.value))) {
        setMarkerLocationExternally(Number(formControls.getValues("geoPoint.lat")), Number(event.currentTarget.value));
      }
    },
    [formControls, setMarkerLocationExternally]
  );

  const handleGoBackButtonClick = useCallback(() => {
    SET_ACTIVE_BUILDING(undefined);
    setIsAddBuildingFormOpened(false);
    setBuildingView(true);
  }, [SET_ACTIVE_BUILDING, setBuildingView, setIsAddBuildingFormOpened]);

  useEffect(() => {
    if ((activeBuilding?.geoPoint?.lon || activeBuilding?.geoPoint?.lat) && isAddBuildingFormOpened) {
      formControls.setValue('geoPoint.lat', activeBuilding?.geoPoint?.lat, { shouldValidate: true });
      formControls.setValue('geoPoint.lon', activeBuilding?.geoPoint?.lon, { shouldValidate: true });
    } else if (enteredLocationDetails.lat && enteredLocationDetails.lon) {
      formControls.setValue('geoPoint.lat', enteredLocationDetails.lat, { shouldValidate: true });
      formControls.setValue('geoPoint.lon', enteredLocationDetails.lon, { shouldValidate: true });
      setMarkerLocationExternally(Number(enteredLocationDetails.lat), Number(enteredLocationDetails.lon));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBuilding?.geoPoint, formControls, isAddBuildingFormOpened]);

  useEffect(()=> {
    return () => {
      setIsAddBuildingFormOpened(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddBuildingFormComponent
      onSubmit={ handleFormSubmit }
      formControls={ formControls }
      onClose={ handleFormClose }
      activeBuilding={ activeBuilding }
      isAuthorizedToEdit={ isAuthorizedToEdit }
      onLatitudeChange = { handleLatitudeChange }
      onLongitudeChange = { handleLongitudeChange }
      onGoBackButtonClick={ handleGoBackButtonClick }
    />
  );
};

export default AddBuildingForm;
