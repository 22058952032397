import { createStyles } from "@mantine/core";

export const useEvidenceVideoModalStyles = createStyles((theme) => ({
  container: {
    height: 'auto',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  },

  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: 'auto',
    padding: '40px 32px',
    zIndex: 99
  },

  player: {
    'video': {
      borderRadius: 20,
      border: `3px solid ${theme.colors.neutral[8]}`,
      objectFit: 'cover',
      maxWidth: '500px',
      maxHeight: '500px'
    }
  }
}));
