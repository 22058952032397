import { createStyles, keyframes } from '@mantine/core';

import { genericColorPalette } from '@/core/styles/mantine/palettes';

const spinner = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' }
});

export const useLoaderScreenStyles = createStyles(() => ({
  loaderScreenContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
    backgroundColor: `${genericColorPalette.neutral[9]}BF`, // BF is for 85% opacity (192 in HEX)
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    fontSize: 18,
    fontWeight: 400,
    gap: 32
  },

  spinnerContainer: {
    display: 'flex'
  },

  spinner: {
    animation: spinner,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    width: 48,
    height: 48
  },

  spinnerTranslate: {
    '&:nth-child(1)': {
      transform: 'translate(125%, 0%)'
    },
    '&:nth-child(2)': {
      transform: 'translate(50%, 25%)'
    },
    '&:nth-child(3)': {
      transform: 'translate(-50%, -25%)'
    },
    '&:nth-child(4)': {
      transform: 'translate(-125%, 0%)'
    }
  }
}));
