import { FC } from "react";

import { MedicalDetailsModalContextProvider } from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/MedicalDetailsModal/context/MedicalDetailsModal.context";
import MedicalDetailsModal from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/MedicalDetailsModal/MedicalDetailsModal.component";

const MedicalDetailsModalContainer: FC = () => {
  return (
    <MedicalDetailsModalContextProvider>
      <MedicalDetailsModal />
    </MedicalDetailsModalContextProvider>
  );
};

export default MedicalDetailsModalContainer;