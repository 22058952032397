export enum Products {
  LONE_WORKER = 'Lone Worker',
  INCIDENT_AND_CRISIS_MANAGER = 'Incident & Crisis Manager',
  LOCATION = 'Location',
  LAST_MILE = 'Last Mile',
  REFERENCES = 'References',
  CRISIS_MANAGEMENT = 'Crisis Management',
  TRAVEL = 'Travel',
  CORE_SERVICE = 'CoRE Service',
  MASS_COMMS = 'Mass Comms',
  CRISIS_MANAGEMENT_GLOBAL = 'Crisis Management - Global',
  INCIDENT_AND_CRISIS_MANAGER_GLOBAL = 'Incident & Crisis Manager - Global',
  ADVANCED_LOCATION_SERVICES = 'Advanced Location Services'
}
