import { FC } from "react";

import { useSummaryPageStyles } from "@/tenant-context/control-mass-comms/components/SummaryPage/SummaryPage.styles";
import {
  MassCommsAppCommunicationsResponseItem,
  MassCommsEmailResponseItem,
  MassCommsPhoneResponseItem,
  ResponseMethod
} from "@/tenant-context/control-mass-comms/types/MassComms.types";

type Props = {
  type: 'app' | 'email' | 'phone',
  selectedResponseMethod: ResponseMethod,
  responseConfigs: MassCommsEmailResponseItem[] | MassCommsPhoneResponseItem[]
}

const ResponseMethodsPreviewComponent: FC<Props> = ({
  type,
  selectedResponseMethod,
  responseConfigs
}) => {

  const { classes } = useSummaryPageStyles({});

  if (selectedResponseMethod === "NO_RESPONSE" || responseConfigs.length === 0) {
    return null;
  }

  return (
    <div>
      <div className={ classes.responseHeaderContainer }>
        <div className={ classes.contentText }>Communication Response Options</div>
        <div className={ classes.reponseTypeHeader }>{ selectedResponseMethod.replaceAll('_', ' ') }</div>
      </div>
      <div className={ classes.responseConfigRowContainer }>
        { responseConfigs.map(configItem => (
          <div className={ classes.configRow } key={ configItem.id }>
            { type === 'app' && (
              <div className={ classes.statusBox }
                style={ { backgroundColor: (configItem as MassCommsAppCommunicationsResponseItem).buttonColor } }>
                { (configItem as MassCommsAppCommunicationsResponseItem).buttonLabel }
              </div>
            ) }

            { type === 'email' && (
              <div className={ classes.statusBox }
                style={ { backgroundColor: (configItem as MassCommsEmailResponseItem).buttonColor } }>
                { (configItem as MassCommsEmailResponseItem).buttonLabel }
              </div>
            ) }

            { type === 'phone' && (
              <div className={ classes.itemTextWrapper }>
                Reply Code
                <div className={ classes.itemTextItem }>
                  { (configItem as MassCommsPhoneResponseItem).replyCode }
                </div>
              </div>
            ) }

            <div className={ classes.itemTextWrapper }>
              Poll Response Label
              <div className={ classes.itemTextItem }>
                { configItem.responseLabel.replaceAll('_', ' ') }
              </div>
            </div>

            <div className={ classes.itemTextWrapper }>
              Safety Status
              <div className={ classes.itemTextItem }>
                { configItem.safetyStatus?.replaceAll('_', ' ') }
              </div>
            </div>
          </div>)) }
      </div>
    </div>
  );
};

export default ResponseMethodsPreviewComponent;
