import { createStyles, keyframes } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

type Args = {
  isExpanded: boolean;
  subMenuCount: number;
  sectionCount: number;
}
const LogoText = keyframes({
  '0%': { transform: 'translateX(-100%)' },
  '100%': { transform: 'translateX(0)' }
});

export const useSideNavItemStyles = createStyles((theme, {
  isExpanded,
  subMenuCount,
  sectionCount
}: Args) => ({

  menuItemContainer: {
    '.tooltip': {
      display: 'none',
      backgroundColor: theme.colors.white[0],
      color: theme.colors.neutral[9],
      padding: 12,
      fontSize: 10,
      borderRadius: 8,
      width: 'auto',
      textWrap: 'nowrap',
      marginTop: 8,
      marginLeft: -8
    },
    '&:hover': {
      '.tooltip': {
        display: 'block',
        position: 'absolute',
        top: '',
        left: '100%'
      }
    }
  },

  tooltipArrow: {
    position: 'absolute',
    top: 8,
    left: -6,
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderRight: '10px solid white',
    borderBottom: '10px solid transparent'
  },

  menuItem: {
    borderRadius: 8,
    margin: `${ isExpanded ? 6 : 4}px 20px`,
    padding: 8,
    width: 'calc(100% - 40px)',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: '#1E3445'
    },
    '&[aria-expanded="true"]': {
      backgroundColor: theme.colors.teal[5],
      '&:hover': {
        backgroundColor: `${theme.colors.teal[6]}`
      }
    },
    svg: {
      width: 20
    },
    [`.${CSS_KEY}-Accordion-chevron`]: {
      overflow: 'hidden',
      display: isExpanded ? undefined : 'none'
    },
    '&[disabled]': {
      cursor: 'not-allowed !important',
      backgroundColor: theme.colors.neutral[8],
      '*': {
        color: theme.colors.neutral[6]
      },
      '& svg': {
        opacity: 0.4
      }
    }
  },

  menuItemContent: {
    paddingLeft: isExpanded ? 0 : 4,
    width: 192,
    height: isExpanded ? 24 : 28,
    '& > svg': {
      width: `${ isExpanded ? 20: 24 }px !important`,
      height: `${ isExpanded ? 20: 24 }px !important`
    }
  },

  menuTitle: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 400,
    opacity: isExpanded ? 1 : 0,
    marginLeft: 4,
    overflow: 'hidden'
  },

  menuNameSection: {
    flexGrow: 1
  },

  menuExpandIcon: {
    width: '12px !important'
  },

  menuPanel: {
    [`.${CSS_KEY}-Accordion-content`]: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      marginLeft: 32,
      marginTop: 8,
      marginBottom: 12,
      paddingLeft: 14,
      paddingTop: 0,
      paddingBottom: 0,
      borderLeft: `2px solid ${'#294357'}`
    }
  },

  menuDropdown: {
    backgroundColor: theme.colors.neutral[8],
    border: `1px solid ${theme.colors.neutral[7]}`,
    borderRadius: theme.radius.sm,
    padding: theme.spacing.md,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    '&, *': {
      transition: 'all 0ms ease !important'
    },
    '*': {
      transition: 'color 100ms ease, background-color 100ms ease !important'
    },
    [`.${CSS_KEY}-Menu-label`]: {
      color: '#FFFFFF',
      paddingLeft: '0 !important',
      fontSize: 12,
      fontWeight: 500,
      textTransform: 'capitalize'
    },
    [`.${CSS_KEY}-Menu-item`]: {
      padding: 0,
      borderRadius: 8,
      fontSize: 10,
      color: '#FFFFFFA3',
      '&:hover': {
        color: theme.colors.teal[5],
        backgroundColor: 'transparent !important'
      }
    }
  },

  subMenuPanel: {
    display: 'grid',
    gridTemplateRows: '1fr',
    flexDirection: 'column',
    gap: 12,
    marginLeft: 32,
    paddingTop: 0,
    paddingBottom: 0,
    opacity: 0,
    height: 0,
    marginTop: 0,
    marginBottom: 0,
    overflow: 'hidden'
  },

  subMenuPanelOpened: {
    height: (sectionCount > 1 ? (sectionCount * (15 + 6)) : 0)
      + (subMenuCount * 15)
      + ((sectionCount - 1) * 12)
      + ((subMenuCount - sectionCount) * 4),
    // total (height section headers + gaps between sub menu items and header)
    // +  total height of sub menu items
    // + gaps between sections
    // + gaps between menu items
    marginTop: 8,
    marginBottom: 12,
    opacity: 1
  },

  subMenuSection: {
    paddingLeft: 14,
    borderLeft: `2px solid ${'#294357'}`,
    gap: 6
  },

  subMenuSectionHeader: {
    color: '#FFFFFF',
    fontSize: 12,
    fontWeight: 500
  },

  subMenuItems: {
    gap: 4
  },

  subMenuItem: {
    height: 15,
    fontSize: 10,
    lineHeight: 12,
    [`.${CSS_KEY}-Button-label`]: {
      color: `${theme.colors.white[0]}A3`
    },
    '&:hover': {
      [`.${CSS_KEY}-Button-label`]: {
        color: theme.colors.teal[5]
      }
    }
  },

  subMenuItemSelected: {
    [`.${CSS_KEY}-Button-label`]: {
      color: `${theme.colors.teal[4]} !important`
    }
  },

  subMenuItemDisabled: {
    [`.${CSS_KEY}-Button-label`]: {
      color: `${theme.colors.neutral[6]} !important`
    },
    '&:hover': {
      cursor: 'not-allowed !important'
    }
  },

  expandedItemContainer: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    paddingRight: 16
  },

  itemIcon: {
    minWidth: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inherit',
    animationName: LogoText,
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards'
  },

  tooltipContainer: {
    padding: 8
  },

  tooltipLabel: {
    color: theme.colors.neutral[0],
    textTransform: 'uppercase'
  },

  tooltipDescription: {
    fontSize: 10,
    fontWeight: 500,
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: '100%'
  },
  navSelected: {
    backgroundColor: theme.colors.primary[4],
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    borderRight: 0
  },
  dropDownContent: {
    background: theme.colors.primary[4],
    padding: 14,
    border: 'none',
    maxHeight: "90vh",
    overflowY: "auto",
    [`.${CSS_KEY}-Menu-arrow`]: {
      border: `0.5px solid ${theme.colors.neutral[6]}`
    },
    [`.${CSS_KEY}-Text-root`]: {
      color: theme.colors.neutral[5]
    },
    [`.${CSS_KEY}-Menu-itemLabel`]: {
      whiteSpace: 'nowrap'
    },
    [`.${CSS_KEY}-Menu-item`]: {
      fontSize: theme.fontSizes.xs,
      width: 'auto',
      '&:hover': {
        backgroundColor: theme.colors.neutral[8]
      }
    },
    [`.${CSS_KEY}-Menu-divider`]: {
      margin: '5px',
      borderTop: `0.5px solid ${theme.colors.neutral[0]}`,
      opacity: 0.3
    },
    [`.${CSS_KEY}-Menu-label`]: {
      fontSize: theme.fontSizes.xs
    }
  },
  dropDownBackground: {
    height: 'auto',
    zIndex: 9999,
    background: theme.colors.primary[4],
    border: 'none',
    '&:hover': {
      backgroundColor: theme.colors.teal[6]
    }
  },
  menuItemActive: {
    backgroundColor: theme.colors.teal[5],
    '&:hover': {
      backgroundColor: theme.colors.teal[4]
    }
  },
  menuSlideIcon: {
    svg: {
      height: '15px',
      width: '15px'
    }
  },
  subMenuDisabled: {
    '*': {
      color: `${theme.colors.neutral[6]} !important`
    },
    '&:hover': {
      cursor: 'not-allowed !important'
    }
  },
  subMenuActive: {
    '*': {
      color: `${theme.colors.teal[4]} !important`
    }
  },
  menu: {
    overflow: 'hidden'
  }
}));
