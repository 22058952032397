import { MantineTheme } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const TimelineStyle = {
  defaultProps: {},
  styles: (theme: MantineTheme) => ({
    itemBullet: {
      top: 10
    },

    itemLine: {
      marginBottom: 20,
      top: 30,
      bottom: -40
    },

    itemBody: {
      backgroundColor: genericColorPalette.neutral[9],
      borderBottom: `2px solid ${theme.colors.blue[6]}`,
      padding: 8,

      position: 'relative' as const
    }
  })
};
