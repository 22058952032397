import { closeAllModals, openModal } from "@mantine/modals";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import usePermission from "@/common/hooks/usePermission";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import { LocationConfigurationPoliciesConfig } from "@/tenant-context/control-location-configuration/config/location-configuration.policies";
import { CategoryDTO, CategoryTypes } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

import LocationCategoryComponent from "./LocationCategory.component";
import AddCategoryModal from "./modals/AddCategory.container";

const SEARCH_DEBOUNCE_INTERVAL = 500;
interface Props {
    handleCategoryChange: (value: string) => void,
    inputLabel?: string,
    isForm?: boolean,
    value?: string,
    disabled?: boolean
}

const LocationCategoryContainer: FC<Props> = ({
  handleCategoryChange,
  isForm = false,
  value,
  ...others
}) => {
  const [searchValue, setSearchValue] = useState(value || (isForm ? 'Default Location' : "All Categories"));
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const {
    manageLocations:{
      deleteLocationCategory,
      getLocationCategories,
      saveLocationCategory
    }
  } = useDispatch<Dispatch>();

  const data = useSelector((state:RootState) => state.manageLocations.subCategories);
  const isLoading = useSelector((state:RootState) => state.manageLocations.isSubCategoriesLoading);

  const isAuthorized = usePermission(LocationConfigurationPoliciesConfig.LOCATION_CONFIGURATION_CATEGORY_FULL_ACCESS);

  const handleLocationCategoryDelete = useCallback((confirm = false) => async (categoryItem: CategoryDTO) =>{
    if(!confirm){
      openConfirmationModal({
        text: `Are you sure you want to remove '${categoryItem.label}' category`,
        onConfirm: async () => handleLocationCategoryDelete(true)(categoryItem),
        title: "Confirm Removal"
      });
    } else{
      const result = await deleteLocationCategory(categoryItem.label);

      if(result){
        showNotification({
          color: "success",
          title: "Success",
          message: "Location Category deleted successfully"
        });
      }

      getLocationCategories();
    }
  }, [deleteLocationCategory, getLocationCategories]);

  const handleSaveLocationCategory = useCallback(async (payload: {id: string; tid: string;})=>{
    const result = await saveLocationCategory(payload);

    if(result){
      showNotification({
        color: "success",
        title: "Success",
        message: "Changes made successfully"
      });
      getLocationCategories();
      closeAllModals();
    }
  }, [saveLocationCategory, getLocationCategories]);

  const handleLocationCategoryEdit = useCallback((categoryItem: CategoryDTO)=>{
    openModal({
      title: `Edit Location Category`,
      size: 688,
      children: (
        <AddCategoryModal
          saveLocationCategory={ handleSaveLocationCategory }
          categoryItem={ categoryItem }/>
      )
    });
  }, [handleSaveLocationCategory]);

  const handleAddLocationCategory = useCallback(()=>{
    openModal({
      title: `Add Location Category`,
      size: 688,
      children: (
        <AddCategoryModal
          saveLocationCategory={ handleSaveLocationCategory }
        />
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      withCloseButton: true
    });
  }, [handleSaveLocationCategory]);

  const handleCategorySearch = useDebouncedCallback(
    getLocationCategories,
    SEARCH_DEBOUNCE_INTERVAL
  );

  const handleLocationSearchReset = useCallback(()=>{
    setSearchValue(isForm ? "" : "All Categories");
    handleCategoryChange(isForm ? "" : "all");
    handleCategorySearch();
  }, [handleCategoryChange, handleCategorySearch, isForm]);

  const handleLocationCategorySelect = useCallback((category: string)=>{
    setSearchValue(category);
    handleCategoryChange(category);
  }, [handleCategoryChange]);

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>)=>{
    setSearchValue(event.target.value);

    handleCategorySearch(event.target.value);
  }, [handleCategorySearch]);

  useEffect(()=>{
    getLocationCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    if(value !== undefined){
      setSearchValue(value);
    }
  }, [value]);

  return (
    <LocationCategoryComponent
      searchValue={ searchValue }
      onLocationCategoryDelete={ handleLocationCategoryDelete(false) }
      onLocationCategoryEdit={ handleLocationCategoryEdit }
      onLocationCategorySelect={ handleLocationCategorySelect }
      onLocationSearchReset={ handleLocationSearchReset }
      onLocationCategoryAdd = { handleAddLocationCategory }
      onSearchChange={ handleSearchChange }
      isMenuOpened={ isMenuOpened }
      setIsMenuOpened={ setIsMenuOpened }
      isLoading = { isLoading }
      isAuthorized = { isAuthorized }
      categoryList={ !isForm ? [{
        value: "All",
        label: "All Categories",
        type: CategoryTypes.System
      }, ...data] : data }
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...others }/>
  );
};

export default LocationCategoryContainer;
