import { INDOORS_VISIBILITY_MIN_ZOOM } from "@f/map-gl-indoor/IndoorLayer";
import { LngLatBounds } from "mapbox-gl";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import Map, { Layer, Source, useMap } from 'react-map-gl';
import { useSelector } from "react-redux";

import useLayerListener from "@/common/hooks/useLayerListener";
import EmergencyTriggeredIcon from '@/common/icons/ea-arc-emergency-triggered.png';
import EndangeredPersonIcon from '@/common/icons/ea-arc-endangered-person.png';
import EmergencyTriggeredWithVideoIcon from '@/common/icons/ea-arc-poi-emergency-triggered-with-video.png';
import TaskStartedIcon from '@/common/icons/ea-arc-task-started.png';
import { Products } from "@/common/types/products";
import { RootState } from "@/core/store";
import useMapIcons from "@/tenant-context/common/hooks/useMapIcons";
import { WORLD_MAX_BOUNDS } from "@/tenant-context/common/util/constants";
import AuthenticatedMap from "@/tenant-context/core/components/AuthenticatedMap/AuthenticatedMap.component";
import MiniPopup from "@/tenant-context/employee-app-action-response-center/components/ui/MiniPopup/MiniPopup.component";
import { EaArcContext, KeyEvent } from "@/tenant-context/employee-app-action-response-center/context/EaArc.context";
import usePeopleIcons from "@/tenant-context/visualisation-people/hooks/usePeopleIcons";
import { PERSON_RED_ALERT_STATUSES, PERSON_YELLOW_ALERT_STATUSES } from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config";

const {
  REACT_APP_MAPBOX_ACCESS_TOKEN
} = process.env as {
  REACT_APP_MAPBOX_ACCESS_TOKEN?: string
};

const EXPAND_ANIMATION_DURATION = 550;

const iconLayout = (name: string) => ({
  'icon-image': name,
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
  'icon-anchor': 'bottom' as const,
  'icon-offset': [0, 2]
});

const MAP_COVERED_BY_TASK_BANNER_PX = 181;
const MAP_COVERED_BY_MAPBOX_WATERMARK = 24;
const MAP_BOUNDARY_COMFORTABLE_PADDING = 100;

const SummaryMap: FC = () => {
  const mapUrl = useSelector((state: RootState) => state.bigMap.mapStyleUrl);
  const { EaArcSummaryMap: map } = useMap();
  const {
    personLocationHistoryLine: personLocationFeatureCollection,
    currentLocationFeature,
    emergencyStartedFeature,
    activeTab,
    taskStartedFeature,
    isEmergencyVideoShown,
    setIsEmergencyVideoShown,
    emergencyVideos
  } = useContext(EaArcContext);

  const [didFitBounds, setDidFitBounds] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [isTaskStartedHover, setIsTaskStartedHover] = useState(false);
  const [isEmergencyStartedHover, setIsEmergencyStartedHover] = useState(false);
  const [isCurrentLocationHover, setIsCurrentLocationHover] = useState(false);

  const poiFeatureCollection = useMemo(() => ({
    type: 'FeatureCollection' as const,
    features: [
      currentLocationFeature,
      emergencyStartedFeature,
      taskStartedFeature
    ].filter(Boolean) as GeoJSON.Feature[]
  }), [currentLocationFeature, emergencyStartedFeature, taskStartedFeature]);

  usePeopleIcons(map, true);

  useMapIcons([
    {
      name: 'ea-arc-poi-task-started',
      image: TaskStartedIcon
    },
    {
      name: 'ea-arc-poi-emergency-triggered',
      image: EmergencyTriggeredIcon
    },
    {
      name: 'ea-arc-poi-emergency-triggered-with-video',
      image: EmergencyTriggeredWithVideoIcon
    },
    {
      name: 'ea-arc-poi-endangered-person',
      image: EndangeredPersonIcon
    }
  ], map, false);

  useEffect(() => {
    setTimeout(() => {
      setIsExpanded(true);
    }, EXPAND_ANIMATION_DURATION);
  }, []);

  useEffect(() => {
    if (!poiFeatureCollection || didFitBounds || !map) {
      return;
    }

    const { coordinates } = personLocationFeatureCollection.geometry;

    if (!coordinates.length) {
      return;
    }

    const bounds = new LngLatBounds(
      [coordinates[0][0], coordinates[0][1]],
      [coordinates[0][0], coordinates[0][1]]
    );

    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
    coordinates.forEach((coord) => {
      bounds.extend([
        coord[0], // lng
        coord[1]  // lat
      ]);
    });

    map?.fitBounds(bounds, {
      padding: {
        top: MAP_COVERED_BY_TASK_BANNER_PX + MAP_BOUNDARY_COMFORTABLE_PADDING,
        bottom: MAP_COVERED_BY_MAPBOX_WATERMARK + MAP_BOUNDARY_COMFORTABLE_PADDING,
        left: MAP_BOUNDARY_COMFORTABLE_PADDING,
        right: MAP_BOUNDARY_COMFORTABLE_PADDING
      },
      maxZoom: INDOORS_VISIBILITY_MIN_ZOOM
    });

    setDidFitBounds(true);
  }, [didFitBounds, map, personLocationFeatureCollection.geometry, poiFeatureCollection]);

  useLayerListener('mouseenter', 'ea-arc-poi-task-started', () => {
    setIsTaskStartedHover(true);
  }, map);

  useLayerListener('mouseleave', 'ea-arc-poi-task-started', () => {
    setIsTaskStartedHover(false);
  }, map);

  useLayerListener('mouseenter', 'ea-arc-poi-emergency-triggered', () => {
    setIsEmergencyStartedHover(true);
  }, map);

  useLayerListener('mouseleave', 'ea-arc-poi-emergency-triggered', () => {
    setIsEmergencyStartedHover(false);
  }, map);

  useLayerListener('click', 'ea-arc-poi-emergency-triggered', () => {
    // TODO: Show the emergency video based on the case has emergency videos or not.
    if (emergencyVideos?.length > 0) {
      setIsEmergencyVideoShown(!isEmergencyVideoShown);
    }
  }, map);

  useLayerListener('mouseenter', 'ea-arc-poi-current-location', () => {
    setIsCurrentLocationHover(true);
  }, map);

  useLayerListener('mouseleave', 'ea-arc-poi-current-location', () => {
    setIsCurrentLocationHover(false);
  }, map);

  useEffect(() => {
    map?.resize();
  }, [activeTab, map]);

  // Delay rendering to prevent the map from resizing during the animation.
  if (!isExpanded) {
    return null;
  }

  return (
    <AuthenticatedMap requiredProduct={ Products.CORE_SERVICE }>
      <Map
        id="EaArcSummaryMap"
        mapboxAccessToken={ REACT_APP_MAPBOX_ACCESS_TOKEN }
        mapStyle={ mapUrl }
        renderWorldCopies={ false }
        maxBounds={ WORLD_MAX_BOUNDS }
        initialViewState={ {
          latitude: 0,
          longitude: 0,
          zoom: 2
        } }
        reuseMaps
        dragRotate={ false }
      >
        <Source
          id="ea-arc-person-breadcrumbs"
          type="geojson"
          data={ personLocationFeatureCollection }
        >
          <Layer
            type="line"
            id="personLocation"
            paint={ {
            // dashed blue line
              'line-dasharray': [1, 2],
              'line-color': '#6BA5FC',
              'line-width': 3
            } }
          />
        </Source>

        <Source
          id='ea-arc-poi'
          type='geojson'
          data={ poiFeatureCollection }
        >
          { /* Task Started Layer */ }
          <Layer
            type="symbol"
            id="ea-arc-poi-task-started"
            filter={ ['==', ['get', 'keyEventType'], KeyEvent.TaskStarted] }
            layout={ iconLayout('ea-arc-poi-task-started') }
          />

          { taskStartedFeature && isTaskStartedHover && (
            <MiniPopup
              longitude={ taskStartedFeature?.geometry.coordinates[0] }
              latitude={ taskStartedFeature?.geometry.coordinates[1] }
            >
              Task Started
            </MiniPopup>
          ) }

          { /* Emergency Triggered Layer */ }
          <Layer
            type="symbol"
            id="ea-arc-poi-emergency-triggered"
            filter={ ['==', ['get', 'keyEventType'], KeyEvent.EmergencyStarted] }
            layout={ iconLayout(
              emergencyVideos.length > 0 ? 'ea-arc-poi-emergency-triggered-with-video' : 'ea-arc-poi-emergency-triggered'
            ) }
          />

          { emergencyStartedFeature && isEmergencyStartedHover && (
            <MiniPopup
              longitude={ emergencyStartedFeature?.geometry.coordinates[0] }
              latitude={ emergencyStartedFeature?.geometry.coordinates[1] }
            >
              Emergency Alarm Triggered { emergencyVideos.length > 0 && ('(With Evidence)') }
            </MiniPopup>
          ) }

          { /* Current Location Layer */ }
          <Layer
            type="symbol"
            id="ea-arc-poi-current-location"
            filter={ ['==', ['get', 'keyEventType'], KeyEvent.CurrentLocation] }
            paint={ {
              "text-color": '#0085FF'
            } }
            layout={ {
              'icon-allow-overlap': true,
              'text-allow-overlap': true,
              'icon-image': [
                'match',
                ['get', 'loneWorkerStatus'],
                PERSON_YELLOW_ALERT_STATUSES,
                'person-marker-caution-yellow',
                PERSON_RED_ALERT_STATUSES,
                'person-marker-caution-red',
                ['literal', 'person-marker']
              ],
              'icon-offset': [0, -7],
              'text-field': [
                'upcase',
                [
                  'get',
                  'personInitials'
                ]
              ],
              'text-size': 12,
              'text-font': ['Arial Unicode MS Bold'],
              'text-offset': [0, -1.2],
              'text-anchor': 'center'
            } }
          />

          { currentLocationFeature && isCurrentLocationHover && (
            <MiniPopup
              longitude={ currentLocationFeature?.geometry.coordinates[0] }
              latitude={ currentLocationFeature?.geometry.coordinates[1] }
            >
              Current Location
            </MiniPopup>
          ) }
        </Source>

      </Map>
    </AuthenticatedMap>
  );
};

export default SummaryMap;
