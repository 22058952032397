import { Box, Button, Checkbox, Divider, Switch } from '@mantine/core';
import React, { FC } from "react";

import { ReactComponent as BSOC } from "@/common/icons/bsoc.svg";
import { ReactComponent as RiskInfoIcon } from "@/common/icons/riskInfoIcon.svg";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import {
  BSOCEventsSettingsStyles
} from "@/tenant-context/control-risk-config/components/BSOCEventsSettings/BSOCEventsSettings.styles";

import { riskCategories, riskLevels } from './BSOCEventsSettings.config';

type Props = {
  onCategoryToggle: React.ChangeEventHandler<HTMLInputElement>
  disabledRiskFilterTypes: string[],
  onChangeGlobalCategoryToggle: React.ChangeEventHandler<HTMLInputElement>,
  isGlobalCategoryToggleOn: boolean
  onImpactRiskEventsToggleChange: React.ChangeEventHandler<HTMLInputElement>,
  isShowOnlyImpactedAlerts: boolean
  riskLevelFilters: {
    nonCritical: boolean
    critical: boolean
  }
  onRiskLevelToggle: React.ChangeEventHandler<HTMLInputElement>
  onOpenAnalytics: () => void,
  isBsocAnalyticsEnabled?: boolean
}

const BSOCEventsSettings: FC<Props> = ({
  onCategoryToggle,
  disabledRiskFilterTypes,
  onChangeGlobalCategoryToggle,
  isGlobalCategoryToggleOn,
  onImpactRiskEventsToggleChange,
  isShowOnlyImpactedAlerts,
  riskLevelFilters,
  onRiskLevelToggle,
  onOpenAnalytics: openAnalytics,
  isBsocAnalyticsEnabled
}) => {
  const { classes, cx } = BSOCEventsSettingsStyles();

  return (
    <div className={ classes.riskEventSettingsContainer }>
      <div className={ classes.bsocRiskAlertSwitchContent }>
        <div className={ classes.bsocRiskAlertIconNDescription }>
          <BSOC className={ classes.bsocIcon }/>
          <p className={ classes.bsocRiskAlertDescription }>BSOC Risk Alerts</p>
        </div>

        <Switch className={ classes.riskAlertGlobalSwitch } onChange = { onChangeGlobalCategoryToggle }
          checked={ isGlobalCategoryToggleOn } />
      </div>

      <h1 className={ classes.sectionHeading }>Filter by Risk Category</h1>

      <ul className={ classes.orderList }>
        { riskCategories.map(({
          Icon, id, name,categoryId
        }) => (
          <li
            key={ id }
            className={ classes.listContent }
          >
            <div className={ classes.bsocRiskAlertIconNDescription }>
              <Icon color={ genericColorPalette.neutral[7] }/>
              <p className={ classes.riskCategoryType }>{ name }</p>
            </div>

            <Switch
              id={ `risk-category-switch-${id}` }
              checked = { !disabledRiskFilterTypes.includes(categoryId.toString()) }
              onChange={ onCategoryToggle } value={ id }/>
          </li>
        )) }
      </ul>

      <Divider
        className={ classes.divider }
      />
      { isBsocAnalyticsEnabled && <Box mt="xl">
        <Button variant="outline" onClick={ openAnalytics } fullWidth>Analytics</Button>
      </Box> }

      <div className={ classes.bsocRiskAlertSubTopic }>Filter by Risk Rating Level</div>

      <ul className={ cx(classes.orderList, classes.riskLevelsMargin) }>
        { riskLevels.map(({
          Icon, id, name
        }) => (
          <li
            key={ id }
            className={ classes.listContent }
          >
            <div className={ classes.bsocRiskAlertIconNDescription }>
              <Icon/>
              <p className={ classes.riskCategoryType }>{ name }</p>
            </div>

            <Switch
              id={ `risk-category-switch-${id}` }
              checked={ (id === "critical" && riskLevelFilters.critical) ||
                (id === "nonCritical" && riskLevelFilters.nonCritical) }
              onChange={ onRiskLevelToggle } value={ id }/>
          </li>
        )) }
      </ul>

      <Checkbox
        checked={ isShowOnlyImpactedAlerts }
        className={ classes.impactCalculationChkBox }
        onChange={ onImpactRiskEventsToggleChange }
        label={  <span className={ classes.checkBoxLabel }>
          <RiskInfoIcon/>
          Only show Risk Events impacting my assets and people</span> }
      />

      <Divider
        className={ classes.divider }
      />
    </div>
  );
};

export default BSOCEventsSettings;
