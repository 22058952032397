import { FC, Fragment, ReactNode, useContext, useMemo } from "react";

import { useProfileKeyValueStyle } from "@/tenant-context/control-profile/components/ui/ProfileKeyValue/ProfileKeyValue.style";
import { ProfileKeyValueBlockContext, ProfileKeyValueBlockContextType } from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock/ProfileKeyValueBlock.context";

type Props = {
  name?: string | ReactNode,
  value: string | ReactNode | (string | ReactNode)[],
  mode?: "legend-header",
  firstItem?: "dimmed" | "emphasis" | "regular" | "label",
  isUpperCase?: boolean
};

const ProfileKeyValue: FC<Props> = ({
  name,
  value,
  mode,
  firstItem = 'dimmed',
  isUpperCase = true
}) => {
  const isLegendHeader = mode === 'legend-header';
  const { classes } = useProfileKeyValueStyle({
    isLegendHeader,
    firstItem,
    isUpperCase
  });

  const { maxColumns } = useContext<ProfileKeyValueBlockContextType>(ProfileKeyValueBlockContext);
  const safeValues = useMemo(() => {
    const arrayValues = Array.isArray(value)
      ? value
      : [value];
    const empties = new Array(maxColumns).fill('');

    return [...arrayValues, ...empties].slice(0, maxColumns - 1);
  }, [maxColumns, value]);

  return (
    <Fragment>
      <dt className={ classes.dt }>{ name }</dt>

      { safeValues.map((val, i) => (
        <dd key={ i } className={ classes.dd }>{ val }</dd>
      )) }
    </Fragment>
  );
};

export default ProfileKeyValue;
