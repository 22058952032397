import { callApi, TokenType } from "@/common/util/api";
import { CONTEXT_TYPES } from "@/core/store/shared-data/sharedData.types";
import {
  PaginatedResult,
  SeverityLevel, SeverityLevelDeleteItem, SeverityLevelOrder
} from "@/platform-context/control-icm-management/types/severityTypes";

export const createSeverityLevel = (severityId: string, severityLevel: SeverityLevel ) => callApi<SeverityLevel>(
  `/managed/icm-service/v1/severities/${severityId}`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;domain-model=CreateSeverityLevel'
    },
    body: JSON.stringify(severityLevel),
    withTenantId: false
  }
);

export const getSeverityLevel = (severityId: string, severityLevelId: string, type: string) => callApi<SeverityLevel>(
  `/managed/icm-service/v1/severities/${severityId}/severity-levels/${severityLevelId}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;domain-model=GetSeverityLevel'
    },
    tokenType:type === CONTEXT_TYPES.tenant ? TokenType.TENANT : TokenType.ACCESS ,
    withTenantId: false
  }
);

export const updateSeverityLevel = (
  severityId: string,
  severityLevelId: string,
  severityLevel: SeverityLevel
) => callApi<SeverityLevel>(
  `/managed/icm-service/v1/severities/${severityId}/severity-levels/${severityLevelId}`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;domain-model=UpdateSeverityLevel'
    },
    body: JSON.stringify(severityLevel),
    withTenantId: false
  }
);

export const getSeverityLevelList = (
  severityId: string,
  type?: string
) => callApi<PaginatedResult<SeverityLevel>>(
  `managed/icm-service/v1/severities/${severityId}/severity-levels?page=0&size=100`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;domain-model=ListSeverityLevel'
    },
    tokenType: type === CONTEXT_TYPES.tenant ? TokenType.TENANT : TokenType.ACCESS,
    withTenantId: false
  }
);

export const postSeverityLevelOrder = (
  severityId: string,
  severityLevel: SeverityLevelOrder,
  type?: string
) => callApi<void>(
  `/managed/icm-service/v1/severities/${severityId}`,
  {
    method: 'POST',
    tokenType: type === CONTEXT_TYPES.tenant ? TokenType.TENANT : TokenType.ACCESS,
    headers: {
      'Content-Type': 'application/json;domain-model=UpdateSeverityLevelOrder'
    },
    body: JSON.stringify(severityLevel),
    withTenantId: false
  }
);

export const deleteSeverityLevel = (
  severityId: string,
  severityLevel: SeverityLevelDeleteItem
) => callApi<void>(
  `/managed/icm-service/v1/severities/${severityId}`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;domain-model=DeleteSeverityLevelAndUpdateCount'
    },
    body: JSON.stringify(severityLevel),
    withTenantId: false
  }
);
