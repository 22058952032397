import { ActionIcon, TypographyStylesProvider } from "@mantine/core";
import { FC } from "react";

import ResponseMethodsPreviewComponent
  from "@/tenant-context/control-mass-comms/components/SummaryPage/Summary-Channels/ResponseMethodsPreview.component";
import { ReactComponent as EditIcon } from "@/tenant-context/control-mass-comms/icons/editIcon.svg";
import { ReactComponent as MassPeople } from "@/tenant-context/control-mass-comms/icons/massPeople.svg";
import {
  MASS_COMMS_STEPS, MassCommsPhoneResponseItem,
  ResponseMethod,
  SmsContent
} from "@/tenant-context/control-mass-comms/types/MassComms.types";

import { useSummaryPageStyles } from "../../SummaryPage.styles";


type Props = {
  smsContent: SmsContent,
  expectedTotalRecipientCount: number,
  receivingCount: number,
  responseMethod: ResponseMethod,
  smsResponseConfigs: MassCommsPhoneResponseItem[],
  onStepperNavigate: (step: number) => (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

const SmsSummaryComponent: FC<Props> = ({
  smsContent,
  onStepperNavigate,
  expectedTotalRecipientCount,
  responseMethod,
  smsResponseConfigs,
  receivingCount
}) => {

  const { classes, cx } = useSummaryPageStyles({});

  return (
    <div>
      <div className={ classes.countContainer }>
        <div className={ classes.countTextContainer }>
          <MassPeople />
          <div className={ classes.countText }>
            <div>{ receivingCount }</div>
            &nbsp;/&nbsp;{ expectedTotalRecipientCount }
          </div>
        </div>
        <ActionIcon><EditIcon onClick={ onStepperNavigate(MASS_COMMS_STEPS.CREATE_COMMS) }/></ActionIcon>
      </div>

      <ResponseMethodsPreviewComponent
        type="phone"
        selectedResponseMethod={ responseMethod }
        responseConfigs={ smsResponseConfigs }
      />

      <div className={ cx(classes.smsSpecSection, classes.standardMarginTop) }>
        <div className={ classes.smsContentTitle }>Content</div>
        <div className={ classes.smsSpecs }>
          <div className={ classes.specItem }>
            <div className={ classes.specHeader }>Message Length</div>
            <div className={ classes.specDesc }>{ `${smsContent.length} Characters` }</div>
          </div>
          <div className={ classes.specItem }>
            <div className={ classes.specHeader }>SMS Units</div>
            <div className={ classes.specDesc }>{ smsContent.units }</div>
          </div>
        </div>

      </div>
      <TypographyStylesProvider>
        <div className={ cx(classes.messageBodyContainer, classes.messageText) } >
          { smsContent.body }
        </div>
      </TypographyStylesProvider>
    </div>
  );
};

export default SmsSummaryComponent;
