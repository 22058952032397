import { FileWithPath } from "@mantine/dropzone";
import { createModel } from "@rematch/core";
import { Feature, Polygon } from "@turf/turf";

import { geoTimeZones } from "@/common/config/geoTimeZones";
import { GridParams } from "@/common/types/ag-grid";
import { PaginatedResult } from "@/common/types/reference-data";
import { handleError, handleSuccess } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { LocationTypes } from "@/tenant-context/control-connected-sites-dashboard/types/connectedSitesDashboard";
import { GeoPolygon } from "@/tenant-context/control-draw/types/draw";
import { ProfileGroup } from "@/tenant-context/control-profile/types/profile";
import { formatSortingParameters } from "@/tenant-context/control-tenant-admin/util/formatSortingParameters";

import {
  addGeoJsonToLocation, addLocationToSite,
  createBuilding,
  createFloor,
  createLocationCategory,
  deleteBuilding,
  deleteFloor,
  deleteLocationCategory,
  deleteLocationSitePlan,
  deleteLocationZone,
  editLocationCategory, editLocationToSite,
  getAllLocationCategories,
  getBuildingListData,
  getBulkLocationTemplate,
  getBulkLocationUploadStatus, getBulkLocationUploadStatusCSV,
  getBulkLocationUploadStatusGrid,
  getFloor,
  getFloorsListData,
  getLocationDetails,
  getLocationsListData,
  getLocationZoneReferenceData,
  getLocationZonesData,
  getProfileGroupsForLocationZone,
  getSitePlansListData,
  getSubscribedLocationTypes,
  saveLocationZone,
  updateBuilding,
  updateFloor,
  updateLocationZone,
  uploadBulkLocationsFile
} from "../api/manage-locations";
import {
  ActiveMarkerLocation,
  AdditionalInfoObj,
  AddLocationRequest,
  Building,
  BuildingListItem,
  BulkLocationsFileUploadStatus,
  CategoryDTO,
  CreateFloorRequest, CriticalStatuses,
  EnteredLocationDetails,
  FloorListItem,
  GeoTimeZone,
  ILocation,
  LocationZone,
  LocationZoneReferenceState,
  LocationZoneReferenceType,
  LocationZoneResponse,
  SitePlanList,
  UploadedLocationStatusGrid,
  UploadGeoJsonRequest,
  ZoneTypes
} from "../types/ManageLocations.types";

export type ManageLocationsState = {
  subCategories: Array<CategoryDTO>;
  isSubCategoriesLoading: boolean;
  locationTypes: Array<{ value: string; label: string }>;
  sitePicture: File | null;
  enteredLocationDetails: EnteredLocationDetails;
  geoJsonFile?: FileWithPath;
  locationsListData?: PaginatedResult<ILocation>;
  currentSitePlan: Array<GeoPolygon>;
  locationZonesData?: PaginatedResult<LocationZoneResponse>;
  selectedLocationZone?: LocationZoneResponse;
  isMapOpen: boolean;
  currentSitePlanGeoJson?: Feature<Polygon>;
  downloadedSitePlanGeoJson?: Feature<Polygon>;
  sitePlanList: Array<SitePlanList>;
  isSitePlanLoading: boolean;
  activeBuilding?: Building;
  isBuildingLoading: boolean;
  locationZoneReferenceData: LocationZoneReferenceState;
  isFooterVisible: boolean;
  profileGroupsData?: ProfileGroup[];
  locationBuildingData?: PaginatedResult<BuildingListItem>;
  activeMarkerLocation?: ActiveMarkerLocation;
  activeFloor?: FloorListItem;
  floorListData?: PaginatedResult<FloorListItem>;
  bulkLocationsUploadPercentage?: number;
  bulkLocationsFileUploadingStatus?: "in-progress" | "successful" | "failed";
  bulkLocationsUploadFailedMessage?: string;
  bulkLocationsUploadSuccessMessage?: string;
  bulkLocationsFileUploadStatus?: BulkLocationsFileUploadStatus;
  uploadedLocationsStatusGrid?: UploadedLocationStatusGrid
  isLoading: boolean
  floorSort: 'asc' | 'desc' | ''
  overlayGeoJson?: Feature<Polygon>;
};

export const manageLocationsDefaultState: ManageLocationsState = {
  subCategories: [],
  locationTypes: [],
  sitePicture: null,
  enteredLocationDetails: {
    id: "",
    code: "",
    parentId: "",
    lat: "",
    lon: "",
    description: "",
    isoCountryCode: "",
    timezones: undefined,
    address01: "",
    address02: "",
    alias: [],
    city: "",
    countyRegion: "",
    locationCategory: "Default Location",
    postalCode: "",
    locationType: LocationTypes.LocationPin,
    isCriticalLocation: false,
    poiAdditionalInfoRequest: {
      contactList: [{ profileId: "", name: "" }],
      externalLinks: [{ linkName: "", url: "" }]
    }
  },
  geoJsonFile: undefined,
  locationsListData: undefined,
  currentSitePlan: [],
  isSubCategoriesLoading: false,
  locationZonesData: undefined,
  selectedLocationZone: undefined,
  isMapOpen: true,
  sitePlanList: [],
  isSitePlanLoading: false,
  activeBuilding: undefined,
  isBuildingLoading: false,
  locationZoneReferenceData: {
    accessControlMode: [],
    musterZonePosition: [],
    releaseBehaviour: [],
    zoneType: []
  },
  isFooterVisible: true,
  profileGroupsData: undefined,
  locationBuildingData: undefined,
  activeMarkerLocation: undefined,
  floorListData: undefined,
  isLoading: false,
  floorSort: '',
  overlayGeoJson: undefined
};

const manageLocationsModel = {
  name: "manageLocations",
  state: manageLocationsDefaultState,
  reducers: {
    SET_ENTERED_LOCATION_DETAILS(
      state: ManageLocationsState,
      payload: EnteredLocationDetails
    ) {
      return {
        ...state,
        enteredLocationDetails: payload
      };
    },
    SET_LOCATION_SUB_CATEGORIES(
      state: ManageLocationsState,
      payload: Array<CategoryDTO>
    ) {
      return {
        ...state,
        subCategories: payload
      };
    },
    SET_LOCATION_TYPES(
      state: ManageLocationsState,
      payload: Array<{ value: string; label: string }>
    ) {
      return {
        ...state,
        locationTypes: payload
      };
    },
    SET_LOCATION_SITE_PIC(state: ManageLocationsState, payload: File | null) {
      return {
        ...state,
        sitePicture: payload
      };
    },
    CLEAR_ADD_LOCATION_DATA(state: ManageLocationsState) {
      return {
        ...state,
        sitePicture: null,
        enteredLocationDetails: {
          id: "",
          tid: "",
          code: "",
          parentId: "",
          lat: "",
          lon: "",
          description: "",
          isoCountryCode: "",
          timezones: undefined,
          address01: "",
          address02: "",
          address03: "",
          alias: [],
          city: "",
          countyRegion: "",
          locationCategory: "Default Location",
          postalCode: "",
          locationType: LocationTypes.LocationPin,
          isCriticalLocation: false,
          poiAdditionalInfoRequest: {
            contactList: [{ profileId: "", name: "" }],
            externalLinks: [{ linkName: "", url: "" }]
          }
        },
        geoJsonFile: undefined
      };
    },
    SET_GEO_JSON_FILE(state: ManageLocationsState, payload?: FileWithPath) {
      return {
        ...state,
        geoJsonFile: payload
      };
    },
    SET_LOCATIONS_LIST_DATA: (
      state: ManageLocationsState,
      locationsListData: ManageLocationsState["locationsListData"]
    ) => ({
      ...state,
      locationsListData
    }),
    SET_CURRENT_SITE_PLAN: (
      state: ManageLocationsState,
      currentSitePlan: ManageLocationsState["currentSitePlan"]
    ) => ({
      ...state,
      currentSitePlan
    }),
    SET_OVERLAY_GEO_JSON: (
      state: ManageLocationsState,
      overlayGeoJson: ManageLocationsState["overlayGeoJson"]
    ) => ({
      ...state,
      overlayGeoJson
    }),
    SET_CURRENT_SITE_PLAN_GEO_JSON: (
      state: ManageLocationsState,
      currentSitePlanGeoJson: ManageLocationsState["currentSitePlanGeoJson"]
    ) => ({
      ...state,
      currentSitePlanGeoJson
    }),
    SET_DOWNLOADED_SITE_PLAN_GEO_JSON: (
      state: ManageLocationsState,
      downloadedSitePlanGeoJson: ManageLocationsState["downloadedSitePlanGeoJson"]
    ) => ({
      ...state,
      downloadedSitePlanGeoJson
    }),
    SET_SITE_PLAN_LIST: (
      state: ManageLocationsState,
      sitePlanList: ManageLocationsState["sitePlanList"]
    ) => ({
      ...state,
      sitePlanList
    }),
    SET_IS_SITE_PLAN_LOADING: (
      state: ManageLocationsState,
      isSitePlanLoading: ManageLocationsState["isSitePlanLoading"]
    ) => ({
      ...state,
      isSitePlanLoading
    }),
    TOGGLE_CATEGORIES_LOAD: (state: ManageLocationsState) => ({
      ...state,
      isSubCategoriesLoading: !state.isSubCategoriesLoading
    }),
    SET_LOCATION_ZONES_DATA: (
      state: ManageLocationsState,
      locationZonesData: ManageLocationsState["locationZonesData"]
    ) => ({
      ...state,
      locationZonesData
    }),
    TOGGLE_MAP: (state: ManageLocationsState, open?: boolean) => ({
      ...state,
      isMapOpen: open || !state.isMapOpen
    }),
    SET_ACTIVE_BUILDING: (
      state: ManageLocationsState,
      activeBuilding: ManageLocationsState["activeBuilding"]
    ) => ({
      ...state,
      activeBuilding
    }),
    SET_IS_BUILDING_LOADING: (
      state: ManageLocationsState,
      isBuildingLoading: ManageLocationsState["isBuildingLoading"]
    ) => ({
      ...state,
      isBuildingLoading
    }),
    SET_ACTIVE_FLOOR: (
      state: ManageLocationsState,
      activeFloor: ManageLocationsState["activeFloor"]
    ) => ({
      ...state,
      activeFloor
    }),
    TOGGLE_FOOTER: (state: ManageLocationsState, open?: boolean) => ({
      ...state,
      isFooterVisible: open || !state.isFooterVisible
    }),
    SET_LOCATION_ZONE_REFERENCE_DATA: (
      state: ManageLocationsState,
      locationZoneReferenceData: ManageLocationsState["locationZoneReferenceData"]
    ) => ({
      ...state,
      locationZoneReferenceData
    }),
    SET_SELECTED_LOCATION_ZONE: (
      state: ManageLocationsState,
      selectedLocationZone: ManageLocationsState["selectedLocationZone"]
    ) => ({
      ...state,
      selectedLocationZone
    }),
    CLEAR_SELECTED_LOCATION_ZONE: (state: ManageLocationsState) => ({
      ...state,
      selectedLocationZone: undefined
    }),
    SET_PROFILE_GROUPS_DATA: (
      state: ManageLocationsState,
      profileGroupsData: ManageLocationsState["profileGroupsData"]
    ) => ({
      ...state,
      profileGroupsData
    }),
    SET_LOCATION_BUILDING_DATA: (
      state: ManageLocationsState,
      locationBuildingData: ManageLocationsState["locationBuildingData"]
    ) => ({
      ...state,
      locationBuildingData
    }),
    SET_ACTIVE_MARKER_LOCATION: (
      state: ManageLocationsState,
      activeMarkerLocation: ManageLocationsState["activeMarkerLocation"]
    ) => ({
      ...state,
      activeMarkerLocation
    }),
    SET_FLOOR_LIST_DATA: (
      state: ManageLocationsState,
      floorListData: ManageLocationsState["floorListData"]
    ) => ({
      ...state,
      floorListData
    }),
    SET_BULK_LOCATIONS_UPLOAD_PERCENTAGE: (
      state: ManageLocationsState,
      bulkLocationsUploadPercentage: ManageLocationsState["bulkLocationsUploadPercentage"]
    ) => ({
      ...state,
      bulkLocationsUploadPercentage
    }),
    SET_BULK_LOCATIONS_FILE_UPLOADING_STATUS: (
      state: ManageLocationsState,
      bulkLocationsFileUploadingStatus: ManageLocationsState["bulkLocationsFileUploadingStatus"]
    ) => ({
      ...state,
      bulkLocationsFileUploadingStatus
    }),
    SET_BULK_LOCATIONS_UPLOAD_FAILED_MESSAGE: (
      state: ManageLocationsState,
      bulkLocationsUploadFailedMessage: ManageLocationsState["bulkLocationsUploadFailedMessage"]
    ) => ({
      ...state,
      bulkLocationsUploadFailedMessage
    }),
    SET_BULK_LOCATIONS_UPLOAD_SUCCESS_MESSAGE: (
      state: ManageLocationsState,
      bulkLocationsUploadSuccessMessage: ManageLocationsState["bulkLocationsUploadSuccessMessage"]
    ) => ({
      ...state,
      bulkLocationsUploadSuccessMessage
    }),
    SET_IS_LOADING: (
      state: ManageLocationsState,
      isLoading: ManageLocationsState["isLoading"]
    ) => ({
      ...state,
      isLoading
    }),
    SET_UPLOADED_LOCATIONS_STATUS_GRID: (
      state: ManageLocationsState,
      uploadedLocationsStatusGrid: ManageLocationsState["uploadedLocationsStatusGrid"]
    ) => ({
      ...state,
      uploadedLocationsStatusGrid
    }),

    SET_FLOOR_SORT: (
      state: ManageLocationsState,
      floorSort: ManageLocationsState["floorSort"]
    ) => ({
      ...state,
      floorSort
    }),

    SET_BULK_LOCATIONS_FILE_UPLOAD_STATUS: (
      state: ManageLocationsState,
      bulkLocationsFileUploadStatus: ManageLocationsState["bulkLocationsFileUploadStatus"]
    ) => ({
      ...state,
      bulkLocationsFileUploadStatus
    }),
    RESET_LOCATIONS_STATE: (
      _state: ManageLocationsState
    ) => ({
      ...manageLocationsDefaultState
    })
  },
  effects: (dispatch: Dispatch) => ({
    async getLocationsListData(
      payload: {
        gridParams: GridParams;
      },
      _state: RootState
    ): Promise<PaginatedResult<ILocation>> {
      const { searchQuery, additionalParams } = payload.gridParams;
      if (searchQuery) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `name LIKE %27%25${searchQuery}%25%27 OR code LIKE %27%25${searchQuery}%25%27 OR alias LIKE %27%25${searchQuery}%25%27`;
      }

      // eslint-disable-next-line fp/no-let
      let additionalFilterQuery = "";

      if (typeof additionalParams === "object") {
        Object.keys(additionalParams).forEach(function (key) {
          if (additionalParams[key]) {
            if (key !== "locationCategory") {
              additionalFilterQuery += `${key}=${additionalParams[key]}&`;
            }
          }
        });
      }

      const locationsListData = await getLocationsListData(
        payload.gridParams.page,
        payload.gridParams.size,
        additionalFilterQuery,
        additionalParams.locationCategory,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.manageLocations.SET_LOCATIONS_LIST_DATA(locationsListData);
      return locationsListData;
    },
    async getSitePlanList(
      _: void,
      state: RootState
    ): Promise<Array<SitePlanList> | void> {
      const {
        manageLocations: { enteredLocationDetails }
      } = state;

      const id = enteredLocationDetails?.tid;
      const locationCategory = enteredLocationDetails?.subCategoryName;

      if (!id || !locationCategory) {
        return;
      }
      await getSitePlansListData(id, locationCategory)
        .then((res) => {
          dispatch.manageLocations.SET_SITE_PLAN_LIST(res);
          if (res[0]?.geoJson) {
            dispatch.manageLocations.SET_DOWNLOADED_SITE_PLAN_GEO_JSON(
              JSON.parse(res[0]?.geoJson)
            );
          }
        })
        .catch(handleError);
    },

    async getLocationCategories(query?: string): Promise<void> {
      dispatch.manageLocations.TOGGLE_CATEGORIES_LOAD();
      // eslint-disable-next-line fp/no-let
      let searchQuery = "";

      if (query) {
        searchQuery = `name LIKE '%25${query}%25'`;
      }

      const subCategories = await getAllLocationCategories(searchQuery);
      const dropDownSubCat: CategoryDTO[] = subCategories?.map((subCat) => {
        return {
          value: subCat?.name,
          label: subCat?.name,
          tid: subCat.tid,
          type: subCat.type,
          icon: subCat.icon
        } as CategoryDTO;
      });

      dispatch.manageLocations.SET_LOCATION_SUB_CATEGORIES(dropDownSubCat);
      dispatch.manageLocations.TOGGLE_CATEGORIES_LOAD();
    },

    async initializeAddLocations(_: void, _state: RootState): Promise<void> {
      const locationTypes = await getSubscribedLocationTypes();
      const dropDownTypes = locationTypes?.map((locType) => {
        return { value: locType.displayName, label: locType.displayName };
      });

      dispatch.manageLocations.SET_LOCATION_TYPES(dropDownTypes);
    },

    async addLocation(
      _: void,
      state: RootState
    ): Promise<{ tid: string; subCategoryName: string }> {
      // eslint-disable-next-line fp/no-let
      let results = undefined;

      const {
        manageLocations: { enteredLocationDetails, sitePicture }
      } = state;
      const enteredLocation = { ...enteredLocationDetails };
      const geoTimeZone = (enteredLocation.timezones as GeoTimeZone) || undefined;
      const addRequestObj: AddLocationRequest = {
        id: enteredLocation.id.trim(),
        criticalLocation: enteredLocation.isCriticalLocation as boolean,
        description: enteredLocation.description,
        lat: enteredLocation.lat,
        lon: enteredLocation.lon,
        locationType: enteredLocation.locationType,
        alias: enteredLocation.alias,
        code: enteredLocation.code,
        imageUrl: enteredLocation.imageUrl,
        timeZone: {
          label: geoTimeZone?.label,
          offset: geoTimeZone?.offset,
          timeZone: geoTimeZone?.timeZone,
          value: geoTimeZone?.value,
          country: geoTimeZone?.country
        },
        address: {
          city: enteredLocation.city,
          country: enteredLocation.isoCountryCode,
          firstLine: enteredLocation.address01,
          secondLine: enteredLocation.address02,
          postalCode: enteredLocation.postalCode,
          region: enteredLocation.countyRegion
        },
        poiAdditionalInfoRequest: {
          contactList: [
            ...(
              enteredLocation.poiAdditionalInfoRequest as AdditionalInfoObj
            ).contactList?.filter(
              (contact) => contact.name !== "" && contact.profileId !== ""
            )
          ],
          externalLinks: [
            ...(
              enteredLocation.poiAdditionalInfoRequest as AdditionalInfoObj
            ).externalLinks?.filter(
              (link) => link.linkName !== "" || link.url !== ""
            )
          ]
        }
      };
      const formData = new FormData();
      formData.append("location", JSON.stringify(addRequestObj));
      if (sitePicture) {
        formData.append("image", sitePicture as FileWithPath);
      }

      if (enteredLocationDetails.tid) {
        //edit
        results = await editLocationToSite(
          enteredLocationDetails.tid,
          enteredLocation.locationCategory,
          formData
        ).catch(handleError);

        if (results && results.tid && results.subCategoryName) {
          dispatch.manageLocations.getLocationDetails({
            tid: results.tid,
            category: results.subCategoryName
          });
        }
      } else {
        //add
        results = await addLocationToSite(
          enteredLocation.locationCategory,
          formData
        ).catch(handleError);
      }

      // eslint-disable-next-line fp/no-let
      let tid = "",
        subCategoryName = "";

      if (results && results.tid && results.subCategoryName) {
        // eslint-disable-next-line prefer-destructuring
        tid = results.tid;
        // eslint-disable-next-line prefer-destructuring
        subCategoryName = results.subCategoryName;
      }

      return { tid, subCategoryName };
    },

    async setLatitudeLongitudeInLocationDetails(
      payload: { lat: number; lng: number, isReverseGeocodingEnabled: boolean},
      state: RootState
    ): Promise<void> {
      const { enteredLocationDetails } = state.manageLocations;
      // eslint-disable-next-line fp/no-let
      let currentEnteredLocationDetails: EnteredLocationDetails = enteredLocationDetails;

      if (payload.isReverseGeocodingEnabled) {
        const reverseGeocodedLocationAddress = await dispatch.reverseGeocoding.getReverseGeoLocationAddress({
          lon: payload.lng,
          lat: payload.lat
        });

        if (reverseGeocodedLocationAddress && reverseGeocodedLocationAddress.status === 'SUCCESS' && reverseGeocodedLocationAddress.address) {
          const { address } = reverseGeocodedLocationAddress;
          currentEnteredLocationDetails = {
            ...currentEnteredLocationDetails,
            address01: address.addressLine1 ?? "",
            address02: address.addressLine2 ?? "",
            city: address.city ?? "",
            isoCountryCode: address.countryCode ?? "",
            postalCode: address.postalCode ?? "",
            type: 'NEW_LOCATION'
          };
        }

        const timeZone = await dispatch.reverseGeocoding.getLocationTimezone({
          lon: payload.lng,
          lat: payload.lat
        });

        if (timeZone && timeZone.timeZone) {
          const timezoneObj = geoTimeZones.find((tz) => tz.timeZone === timeZone.timeZone);
          const mappedTimeZone: GeoTimeZone = {
            ...timeZone,
            value: timeZone.value ?? timezoneObj?.value
          };

          currentEnteredLocationDetails = {
            ...currentEnteredLocationDetails,
            timezones: mappedTimeZone
          };
        }
      }

      dispatch.manageLocations.SET_ENTERED_LOCATION_DETAILS({
        ...currentEnteredLocationDetails,
        lat: payload.lat.toFixed(6),
        lon: payload.lng.toFixed(6)
      } as EnteredLocationDetails);
    },

    setLatitudeLongitudeInActiveBuilding(
      location: { lat: number; lng: number },
      state: RootState
    ): void {
      const { activeBuilding } = state.manageLocations;

      const currentActiveBuilding: Building | undefined = activeBuilding;

      dispatch.manageLocations.SET_ACTIVE_BUILDING({
        ...currentActiveBuilding,
        geoPoint: {
          ...currentActiveBuilding?.geoPoint,
          lat: location.lat.toString(),
          lon: location.lng.toString()
        }
      } as Building);
    },

    async deleteLocationCategory(
      categoryId: string,
      _state: RootState
    ): Promise<boolean> {
      const result = await deleteLocationCategory(categoryId).catch(
        handleError
      );

      return !!result;
    },

    async saveLocationCategory(
      payload: {
        id: string;
        tid: string;
      },
      _state: RootState
    ): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let result;
      if (payload.tid) {
        result = await editLocationCategory(payload.id, payload.tid).catch(
          handleError
        );
      } else {
        result = await createLocationCategory(payload.id).catch(handleError);
      }

      return !!result;
    },

    async getLocationDetails(
      payload: { tid: string; category: string },
      _state: RootState
    ): Promise<void> {
      const locationDetails = await getLocationDetails(
        payload.tid,
        payload.category
      );
      // eslint-disable-next-line fp/no-let
      let locationAdditionalDetails;
      if (!locationDetails.locationAdditionalInfo) {
        locationAdditionalDetails =
          manageLocationsDefaultState.enteredLocationDetails.poiAdditionalInfoRequest;
      } else {
        locationAdditionalDetails = locationDetails.locationAdditionalInfo;
      }
      if (
        (locationAdditionalDetails as AdditionalInfoObj).contactList === null ||
        (locationAdditionalDetails as AdditionalInfoObj)?.contactList
          ?.length === 0
      ) {
        (locationAdditionalDetails as AdditionalInfoObj).contactList = [];
        (locationAdditionalDetails as AdditionalInfoObj).contactList.push({
          name: "",
          profileId: ""
        });
      }
      if (
        (locationAdditionalDetails as AdditionalInfoObj)?.externalLinks ===
          null ||
        (locationAdditionalDetails as AdditionalInfoObj)?.externalLinks
          ?.length === 0
      ) {
        (locationAdditionalDetails as AdditionalInfoObj).externalLinks = [];
        (locationAdditionalDetails as AdditionalInfoObj).externalLinks?.push({
          linkName: "",
          url: ""
        });
      }

      const formattedEnteredLocationDetails: EnteredLocationDetails = {
        id: locationDetails.id,
        code: locationDetails.code,
        parentId: "",
        lat: Number(locationDetails.geoPoint?.lat).toFixed(6) || "",
        lon: Number(locationDetails.geoPoint?.lon).toFixed(6) || "",
        description: locationDetails.description,
        isoCountryCode: locationDetails.address.country,
        address01: locationDetails.address.firstLine,
        address02: locationDetails.address.secondLine,
        alias: locationDetails.alias,
        city: locationDetails.address.city,
        countyRegion: locationDetails.address.region,
        locationCategory: locationDetails.subCategoryName || "",
        postalCode: locationDetails.address.postalCode,
        locationType: locationDetails.locationType,
        isCriticalLocation: locationDetails.criticalLocation,
        timezones: locationDetails.timeZone,
        subCategoryName: locationDetails.subCategoryName,
        tid: locationDetails.tid,
        imageUrl: locationDetails.imageUrl,
        poiAdditionalInfoRequest: locationAdditionalDetails,
        type: "EXISTING_LOCATION"
      };

      if (locationDetails) {
        dispatch.manageLocations.SET_ENTERED_LOCATION_DETAILS(
          formattedEnteredLocationDetails
        );
      }
    },

    async getLocationZonesData(
      payload: {
        gridParams: GridParams;
        locationId?: string
      },
      state: RootState
    ): Promise<PaginatedResult<LocationZoneResponse>> {

      const locId = state.manageLocations.enteredLocationDetails.tid || payload.locationId;

      if (!locId) {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 0,
          items: []
        } as PaginatedResult<LocationZoneResponse>;
      }

      const { searchQuery } = payload.gridParams;
      if (searchQuery) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `name LIKE %27%25${searchQuery}%25%27 OR code LIKE %27%25${searchQuery}%25%27`;
      }

      const locationZonesData = await getLocationZonesData(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.additionalParams.locationCategory,
        locId,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.manageLocations.SET_LOCATION_ZONES_DATA(locationZonesData);
      return locationZonesData;
    },

    async deleteLocationZone(
      zoneId: string,
      state: RootState
    ): Promise<boolean> {
      const result = await deleteLocationZone(
        state.manageLocations.enteredLocationDetails?.subCategoryName || "",
        state.manageLocations.enteredLocationDetails?.tid || "",
        zoneId
      ).catch(handleError);

      return !!result;
    },

    async saveLocationZone(
      params: LocationZone,
      state: RootState
    ): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let success = true;
      // eslint-disable-next-line fp/no-let
      let result;
      if (state.manageLocations.selectedLocationZone) {
        //edit situation category
        result = await updateLocationZone(params).catch(handleError);
        success = !!result;
      } else {
        //new situation category
        result = await saveLocationZone(params).catch(handleError);
        success = !!result;
      }

      if (result) {
        dispatch.manageLocations.SET_SELECTED_LOCATION_ZONE(result);
      }

      return success;
    },
    async uploadGEOJSONFileToLocation(
      file: UploadGeoJsonRequest,
      state: RootState
    ): Promise<void> {
      const {
        manageLocations: { enteredLocationDetails }
      } = state;

      const id = enteredLocationDetails?.tid;
      const locationCategory = enteredLocationDetails?.subCategoryName;
      if (!id || !file || !locationCategory) {
        return;
      }
      dispatch.manageLocations.SET_IS_SITE_PLAN_LOADING(true);
      await addGeoJsonToLocation(id, locationCategory.replaceAll(" ", ""), file)
        .then(() =>
          dispatch.manageLocations.SET_CURRENT_SITE_PLAN_GEO_JSON(undefined))
        .then(() => dispatch.manageLocations.getSitePlanList())
        .catch(handleError)
        .finally(() =>
          dispatch.manageLocations.SET_IS_SITE_PLAN_LOADING(false));
    },
    async deleteLocationSitePlan(
      geoJsonId: string,
      state: RootState
    ): Promise<void> {
      const {
        manageLocations: { enteredLocationDetails }
      } = state;

      const id = enteredLocationDetails?.tid;
      const locationCategory = enteredLocationDetails?.subCategoryName;
      if (!id || !geoJsonId || !locationCategory) {
        return;
      }

      await deleteLocationSitePlan(
        id,
        locationCategory.replaceAll(" ", ""),
        geoJsonId
      )
        .then(() => dispatch.manageLocations.getSitePlanList())
        .then(() =>
          dispatch.manageLocations.SET_CURRENT_SITE_PLAN_GEO_JSON(undefined))
        .catch(handleError);
    },
    async postCreateBuilding(
      building: Building,
      state: RootState
    ): Promise<Building | void | boolean> {
      const {
        manageLocations: { enteredLocationDetails, activeBuilding }
      } = state;

      const id = enteredLocationDetails?.tid;
      if (!id || !building) {
        return;
      }
      dispatch.manageLocations.SET_IS_BUILDING_LOADING(true);
      if (activeBuilding?.buildingTid) {
        return await updateBuilding(id, building, activeBuilding?.buildingTid)
          .catch(handleError)
          .finally(() =>
            dispatch.manageLocations.SET_IS_BUILDING_LOADING(false));
      } else {
        return await createBuilding(id, building)
          .catch(handleError)
          .finally(() =>
            dispatch.manageLocations.SET_IS_BUILDING_LOADING(false));
      }
    },

    async getBuildingList(
      payload: {
        gridParams: GridParams;
        locationId?: string
      },
      state: RootState
    ): Promise<PaginatedResult<BuildingListItem>> {
      const {
        manageLocations: { enteredLocationDetails }
      } = state;

      const id = enteredLocationDetails?.tid || payload.locationId;
      if (!id) {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 0,
          items: []
        } as PaginatedResult<BuildingListItem>;
      }

      const { searchQuery } = payload.gridParams;
      if (searchQuery) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `name LIKE %27%25${searchQuery}%25%27 OR code LIKE %27%25${searchQuery}%25%27 OR floors LIKE %27%25${searchQuery}%25%27`;
      }

      const locationBuildingData = await getBuildingListData(
        id,
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.manageLocations.SET_LOCATION_BUILDING_DATA(locationBuildingData);

      return locationBuildingData;
    },

    async deleteBuilding(buildingId: string, state: RootState): Promise<void> {
      const {
        manageLocations: { enteredLocationDetails }
      } = state;

      const id = enteredLocationDetails?.tid;
      if (id && buildingId) {
        await deleteBuilding(id, buildingId).catch(handleError);
      } else {
        return;
      }
    },

    async getFloor(floorId: string, state: RootState): Promise<void> {
      const {
        manageLocations: { enteredLocationDetails, activeBuilding }
      } = state;

      const locationId = enteredLocationDetails?.tid;
      const buildingId = activeBuilding?.buildingTid;

      if (!locationId || !buildingId || !floorId) {
        return;
      }
      dispatch.manageLocations.SET_IS_BUILDING_LOADING(true);
      await getFloor(locationId, buildingId, floorId)
        .then((res) => dispatch.manageLocations.SET_ACTIVE_FLOOR(res))
        .then(() => dispatch.manageLocations.SET_IS_BUILDING_LOADING(false))
        .catch(handleError);
    },

    async postCreateFloor(
      params: {
        floor: CreateFloorRequest;
        floorId?: string;
      },
      state: RootState
    ): Promise<Building | void> {
      const {
        manageLocations: {
          enteredLocationDetails,
          activeBuilding,
          overlayGeoJson,
          activeFloor
        }
      } = state;

      const locationId = enteredLocationDetails?.tid;
      const buildingId = activeBuilding?.buildingTid;

      if (!locationId || !buildingId) {
        return;
      }
      const floorGeoJson = overlayGeoJson && {
        geoJson: JSON.stringify(overlayGeoJson)
      };

      dispatch.manageLocations.SET_IS_BUILDING_LOADING(true);

      if (params?.floorId) {
        await updateFloor(locationId, buildingId, params?.floorId, {
          ...params.floor,
          ...floorGeoJson
        })
          .then(() => dispatch.manageLocations.SET_OVERLAY_GEO_JSON(undefined))
          .then(() => handleSuccess())
          .then(() => dispatch.manageLocations.getFloorsList())
          .catch(handleError)
          .finally(() =>
            dispatch.manageLocations.SET_IS_BUILDING_LOADING(false));

        await getFloor(locationId, buildingId, activeFloor?.floorTid || "")
          .then((res) => dispatch.manageLocations.SET_ACTIVE_FLOOR(res))
          .catch(handleError)
          .finally(() =>
            dispatch.manageLocations.SET_IS_BUILDING_LOADING(false));
      } else {
        await createFloor(locationId, buildingId, { ...params?.floor, ...floorGeoJson } )
          .then(() => dispatch.manageLocations.SET_ACTIVE_FLOOR(undefined))
          .then(() => dispatch.manageLocations.getFloorsList())
          .catch(handleError)
          .finally(() =>
            dispatch.manageLocations.SET_IS_BUILDING_LOADING(false));
      }
    },

    async getFloorsList(
      _: void,
      state: RootState
    ): Promise<void> {
      const {
        manageLocations: { enteredLocationDetails, activeBuilding, floorSort }
      } = state;

      const locationId = enteredLocationDetails?.tid;
      const buildingId = activeBuilding?.buildingTid;
      if (!locationId || !buildingId) {
        return;
      }

      const floorsListData = await getFloorsListData(
        locationId,
        buildingId,
        {  colId: 'name',
          sort: floorSort }
      );

      dispatch.manageLocations.SET_FLOOR_LIST_DATA(floorsListData);
    },

    async deleteFloor(floorId: string, state: RootState): Promise<void> {
      const {
        manageLocations: { enteredLocationDetails, activeBuilding }
      } = state;

      const locationId = enteredLocationDetails?.tid;
      const buildingId = activeBuilding?.buildingTid;

      if (locationId && buildingId && floorId) {
        dispatch.manageLocations.SET_ACTIVE_FLOOR(undefined);
        await deleteFloor(locationId, buildingId, floorId)
          .then(() => dispatch.manageLocations.getFloorsList())
          .then(() => handleSuccess())
          .catch(handleError);
      } else {
        return;
      }
    },

    async initializeAddLocationZone(_: void, state: RootState): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];
      promises.push(
        getLocationZoneReferenceData(LocationZoneReferenceType.zoneType)
      );
      promises.push(
        getLocationZoneReferenceData(
          LocationZoneReferenceType.musterZonePosition
        )
      );
      promises.push(
        getLocationZoneReferenceData(LocationZoneReferenceType.releaseBehaviour)
      );
      promises.push(
        getLocationZoneReferenceData(
          LocationZoneReferenceType.accessControlMode
        )
      );

      promises.push(
        getLocationZonesData(
          0,
          999,
          state.manageLocations.enteredLocationDetails.subCategoryName || "",
          state.manageLocations.enteredLocationDetails?.tid || "",
          undefined,
          undefined,
          `zoneType=${ZoneTypes.NormalZone}`
        )
      );
      promises.push(getProfileGroupsForLocationZone());
      promises.push(
        this.getBuildingList(
          {
            gridParams: {
              page: 0,
              size: 999
            }
          },
          state
        )
      );
      promises.push(dispatch.manageLocations.getSitePlanList());

      const results = await Promise.all(promises);

      const [
        zoneType,
        musterZonePosition,
        releaseBehaviour,
        accessControlMode,
        locationZonesData,
        profileGroups,
        buildingsListData
      ] = results;

      dispatch.manageLocations.SET_LOCATION_ZONE_REFERENCE_DATA({
        zoneType,
        musterZonePosition,
        releaseBehaviour,
        accessControlMode
      });

      dispatch.manageLocations.SET_LOCATION_ZONES_DATA(locationZonesData);
      dispatch.manageLocations.SET_PROFILE_GROUPS_DATA(profileGroups.items);
      dispatch.manageLocations.SET_LOCATION_BUILDING_DATA(
        buildingsListData
      );
    },

    resetBulkLocationsUploadModal(): void {
      dispatch.manageLocations.SET_BULK_LOCATIONS_UPLOAD_PERCENTAGE(undefined);
      dispatch.manageLocations.SET_BULK_LOCATIONS_FILE_UPLOADING_STATUS(
        undefined
      );
      dispatch.manageLocations.SET_BULK_LOCATIONS_UPLOAD_FAILED_MESSAGE(
        undefined
      );
      dispatch.manageLocations.SET_BULK_LOCATIONS_UPLOAD_SUCCESS_MESSAGE(
        undefined
      );
    },

    async uploadBulkLocationsFile(
      file: FileWithPath,
      _state: RootState
    ): Promise<void> {
      const formData = new FormData();
      formData.append("files", file, file.name);
      formData.append("body", '{dataType:"LOCATION"}');

      dispatch.manageLocations.SET_BULK_LOCATIONS_UPLOAD_PERCENTAGE(50);

      try {
        const upload = await uploadBulkLocationsFile(formData);

        if (upload) {
          if (upload.summary.total === 0) {
            dispatch.manageLocations.SET_BULK_LOCATIONS_FILE_UPLOADING_STATUS(
              "failed"
            );
            dispatch.manageLocations.SET_BULK_LOCATIONS_UPLOAD_FAILED_MESSAGE(
              `An error occurred while uploading the file. No records were inserted.`
            );
          } else {
            await localStorage.setItem(
              "bulkLocationsUploadId",
              upload.bulkDataId
            );
            dispatch.manageLocations.getBulkLocationUploadStatus();
            dispatch.manageLocations.SET_BULK_LOCATIONS_FILE_UPLOADING_STATUS(
              "successful"
            );
            dispatch.manageLocations.SET_BULK_LOCATIONS_UPLOAD_SUCCESS_MESSAGE(
              "Please note that it will take approximately 10 to 15 minutes to process the data and add locations for each record."
            );
          }
        }
      } catch (e) {
        dispatch.manageLocations.SET_BULK_LOCATIONS_FILE_UPLOADING_STATUS(
          "failed"
        );
        dispatch.manageLocations.SET_BULK_LOCATIONS_UPLOAD_FAILED_MESSAGE(
          "An error occurred while uploading the file."
        );
        if (e instanceof Error) {
          dispatch.manageLocations.SET_BULK_LOCATIONS_UPLOAD_FAILED_MESSAGE(
            e?.message ?? "An error occurred while uploading the file."
          );
        }
      }

      dispatch.manageLocations.SET_BULK_LOCATIONS_UPLOAD_PERCENTAGE(100);
    },

    async downloadBulkUploadLocationTemplate(): Promise<void> {
      getBulkLocationTemplate()
        .then((res) => window.open(res?.exportUrl))
        .catch(handleError);
    },

    async getBulkLocationUploadStatus(
      _: void,
      _state: RootState
    ): Promise<void> {
      try {
        const bulkDataId = localStorage.getItem("bulkLocationsUploadId");
        if (!bulkDataId) {
          return;
        }
        const status = await getBulkLocationUploadStatus(bulkDataId);

        dispatch.manageLocations.SET_BULK_LOCATIONS_FILE_UPLOAD_STATUS(status);

        if (status.status === "SUCCESS") {
          localStorage.removeItem("bulkLocationsUploadId");
          dispatch.manageLocations.SET_BULK_LOCATIONS_FILE_UPLOAD_STATUS(
            undefined
          );
          dispatch.manageLocations.getLocationsListData({ gridParams: { page: 0, size: 10, additionalParams: {
            locationType: "All",
            locationCategory: "All",
            country:"",
            criticalStatusFilter: CriticalStatuses.ALL
          } } });
        }
        if (status.status === 'ERROR') {
          dispatch.manageLocations.getLocationsListData({ gridParams: { page: 0, size: 10, additionalParams: {
            locationType: "All",
            locationCategory: "All",
            country:"",
            criticalStatusFilter: CriticalStatuses.ALL
          } } });
        } else {
          setTimeout(
            () => dispatch.manageLocations.getBulkLocationUploadStatus(),
            5000
          );
        }
      } catch {
        return undefined;
      }
    },

    async downloadBulkLocationUploadStatusCSV(): Promise<void> {
      try {
        const bulkDataId = localStorage.getItem("bulkLocationsUploadId");

        if (!bulkDataId) {
          return;
        }
        dispatch.manageLocations.SET_IS_LOADING(true);
        const errorReportData = await getBulkLocationUploadStatusCSV(bulkDataId).catch(handleError);
        if (errorReportData) {
          const errorReportBlob = new Blob([errorReportData], { type: 'text/csv' });
          const url = URL.createObjectURL(errorReportBlob);
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.setAttribute('download', 'errorReport.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          URL.revokeObjectURL(url);
        }
      } catch {
        return undefined;
      } finally {
        dispatch.manageLocations.SET_IS_LOADING(false);
      }
    },

    async getBulkLocationUploadStatusGrid(
      payload: {
        gridParams: GridParams;
      },
      _state: RootState
    ): Promise<UploadedLocationStatusGrid> {
      const { searchQuery, sort } = payload.gridParams;
      if(sort) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.sort = { ...sort, colId: 'data.' + sort.colId };
      }
      if (searchQuery) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = encodeURI(`data.locationName LIKE '%${searchQuery}%' OR data.description LIKE '%${searchQuery}%' OR data.category
 LIKE '%${searchQuery}%' OR data.addressLine1 LIKE '%${searchQuery}%' OR data.addressLine2 LIKE '%${searchQuery}%' OR errorMsg LIKE '%${searchQuery}%'`);
      }

      const bulkDataId = payload.gridParams?.additionalParams?.id;
      const emptyData = {
        currentPage: 0,
        items: [],
        totalPages: 0,
        totalItems: 0,
        filename: "",
        tenantId: "",
        bulkDataId: "",
        status: "",
        createdTime: 0
      };

      if (!bulkDataId) {
        return emptyData;
      }

      dispatch.manageLocations.SET_IS_LOADING(true);

      return await getBulkLocationUploadStatusGrid(
        bulkDataId,
        payload.gridParams.page,
        payload.gridParams.size,
        formatSortingParameters(payload.gridParams.sort),
        payload.gridParams.searchQuery
      )
        .then((res) => {
          dispatch.manageLocations.SET_UPLOADED_LOCATIONS_STATUS_GRID(res);
          dispatch.manageLocations.SET_IS_LOADING(false);
          return res;
        }).catch((err) => {
          handleError(err);
          return emptyData;
        })
        .finally(() => dispatch.manageLocations.SET_IS_LOADING(false));
    }
  })
};

export const manageLocations = createModel<RootModel>()(manageLocationsModel);
