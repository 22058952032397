import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import { situationsApi } from "../api/situationTypes";
import { SituationType, SituationTypeItem } from "../types/situationTypes";

type SituationTypesState = {
    situationTypesData?: PaginatedResult<SituationType>
    selectedSituationTypeItem?: SituationTypeItem,
    situationsList: SituationType[],
    selectedSituationItem?: SituationType,
};

const situationTypesDataModel = {
  name: 'situationTypes',
  state: {
    situationTypesData: undefined,
    selectedSituationTypeItem: undefined,
    selectedSituationItem: undefined,
    situationsList: []
  } as SituationTypesState,
  reducers: {
    SET_SITUATION_TYPES: (state: SituationTypesState, situationTypes: SituationTypesState['situationTypesData']) => ({
      ...state,
      situationTypes
    }),
    SET_SELECTED_SITUATION_TYPE_ITEM: (state: SituationTypesState, selectedSituationTypeItem: SituationTypesState['selectedSituationTypeItem']) => ({
      ...state,
      selectedSituationTypeItem
    }),
    SET_SELECTED_SITUATION_ITEM: (state: SituationTypesState, selectedSituationItem: SituationTypesState['selectedSituationItem']) => ({
      ...state,
      selectedSituationItem
    }),
    RESET_SELECTED_SITUATION_TYPE_ITEM: (state: SituationTypesState) => ({
      ...state,
      selectedSituationTypeItem: undefined,
      situationsList: []
    }),
    RESET_SELECTED_SITUATION_ITEM: (state: SituationTypesState) => ({
      ...state,
      selectedSituationItem: undefined
    }),
    SET_SITUATION_LIST: (state: SituationTypesState, situationsList: SituationTypesState['situationsList']) => ({
      ...state,
      situationsList
    })
  },
  effects: (dispatch: Dispatch) => ({
    async getSituationTypesData(payload: {
      gridParams: GridParams,
  }, _state: RootState): Promise<PaginatedResult<SituationType>> {

      const query = payload.gridParams.searchQuery;
      if(query){
      // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `categoryName LIKE %27%25${query}%25%27 OR description LIKE %27%25${query}%25%27`;
      }

      const situationTypesData = await situationsApi.getSituationTypesData(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.situationTypes.SET_SITUATION_TYPES(situationTypesData);
      return situationTypesData;
    },
    async getSituationTypeById(id: string, _state: RootState): Promise<SituationTypeItem> {
      const situationType = await situationsApi.getSituationTypeById(id);
      dispatch.situationTypes.SET_SELECTED_SITUATION_TYPE_ITEM(situationType);
      return situationType;
    },
    async saveSituationType(params: SituationType, state: RootState): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let success = true;
      // eslint-disable-next-line fp/no-let
      let result;
      if(state.situationTypes.selectedSituationTypeItem){
        //edit situation category
        result = await situationsApi.updateSituationType(params).catch(handleError);
        success = !!result;
      } else{
        //new situation category
        result = await situationsApi.saveSituationType(params).catch(handleError);
        success = !!result;

        if(success){
          dispatch.situationTypes.SET_SELECTED_SITUATION_TYPE_ITEM(result as SituationTypeItem);
          await Promise.all(state.situationTypes.situationsList.map(async (situation: SituationType) => {
            await this.saveSituation(situation, state);
          }));
        }
      }

      return success;
    },
    async saveSituation(params: SituationType, state: RootState): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let success = true;
      // eslint-disable-next-line fp/no-let
      let result;

      //edit situation category
      if(state.situationTypes.selectedSituationTypeItem){
        const situationTypeId = state.situationTypes.selectedSituationTypeItem?._id;

        if(situationTypeId){
          if(state.situationTypes.selectedSituationItem){
            //new situation item
            result = await situationsApi.updateSituation(situationTypeId, params).catch(handleError);
          } else{
            //edit situation item
            result = await situationsApi.saveSituation(situationTypeId, params).catch(handleError);
          }
          success = !!result;
        }
      } else{
        //new situation category
        const updatedSituationsList = [...state.situationTypes.situationsList, params];
        dispatch.situationTypes.SET_SITUATION_LIST(updatedSituationsList);
      }

      return success;
    },
    async deleteSituation(situationId: string, state: RootState): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let result = false;

      //edit situation category
      if(state.situationTypes.selectedSituationTypeItem){
        const situationTypeId = state.situationTypes.selectedSituationTypeItem?._id;
        if(situationTypeId){
          result = !!await situationsApi.deleteSituation(situationTypeId, situationId).catch(handleError);
        }
      } else{
        //new situation category
        const updatedSituationsList = [...state.situationTypes.situationsList];
        updatedSituationsList.splice(+situationId, 1);
        dispatch.situationTypes.SET_SITUATION_LIST(updatedSituationsList);
      }

      return result;
     
    },
    async deleteSituationType(situationTypeId: string, _state: RootState): Promise<void> {
      await situationsApi.deleteSituationType(situationTypeId).catch(handleError);
    },
    async setSituationsList(payload: {
      gridParams: GridParams,
  }, state: RootState): Promise<PaginatedResult<SituationType>> {

      // eslint-disable-next-line fp/no-let
      let searchQuery = "";
      if(payload.gridParams.searchQuery){
        searchQuery = `situationName LIKE %27%25${payload.gridParams.searchQuery}%25%27`;
      }

      const situationsList =  await situationsApi.getSituationsData(
        payload.gridParams.page,
        payload.gridParams.size,
        state.situationTypes.selectedSituationTypeItem?._id || "",
        searchQuery
      );
  
      dispatch.situationTypes.SET_SITUATION_LIST(situationsList.items);
      return situationsList;
    
    }
  })
};

export const situationTypes = createModel<RootModel>()(situationTypesDataModel);
