import { Textarea } from "@mantine/core";
import { ChangeEvent, FC } from "react";

import { useCreateCommsPageStyles } from "@/tenant-context/control-mass-comms/components/CreateCommsPage/CreateCommsPage.styles";

type Props = {
  audioContent: string,
  onTextChanged: (event: ChangeEvent<HTMLTextAreaElement> | string | null) => void
}

const TextToSpeechComponent: FC<Props> = ({
  audioContent,
  onTextChanged
}) => {

  const { classes } = useCreateCommsPageStyles({ isCommsSequenceNoAvailable: true });

  return (
    <div>
      <div>Text</div>
      <Textarea
        value={ audioContent }
        onChange={ onTextChanged }
        className={ classes.commsTextArea }
      />
    </div>
  );
};

export default TextToSpeechComponent;
