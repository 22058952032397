export const SiteConfigurationPolicies = {
  SITE_CONFIG_ALL_ACCESS: {
    policyNames: [
      'SystemAdmin_System_ConnectedSite_ReadOnly',
      'SystemAdmin_System_ConnectedSite_FullAccess'
    ]
  },
  SITE_CONFIG_FULL_ACCESS: {
    policyNames: [
      'SystemAdmin_System_ConnectedSite_FullAccess'
    ]
  }
};
