import { ReactComponent as BSOC } from "@/common/icons/bsoc.svg";
import { ReactComponent as DataMinr } from "@/common/icons/dataminr.svg";
import { ReactComponent as Healix } from "@/common/icons/healix.svg";
import { ReactComponent as LexisNexis } from "@/common/icons/lexisnexis.svg";
import { ReactComponent as RepRisk } from "@/common/icons/reprisk.svg";
import { ReactComponent as RiskLine } from "@/common/icons/riskline.svg";
import { ReactComponent as MaxSecurity } from "@/common/icons/RiskProviders/max-security.svg";
import { capitalizeWord } from "@/common/util/format/string";
import type { RiskProvider } from "@/tenant-context/control-risk-config/types/risk";

//TODO: Icon urls should come from backend
const riskProviders: RiskProvider[] = [
  {
    id: "riskline",
    Icon: RiskLine
  },
  {
    id: "bsoc",
    Icon: BSOC
  },
  {
    id: "lexisNexis",
    Icon: LexisNexis
  },
  {
    id: "repRisk",
    Icon: RepRisk
  },
  {
    id: "healix",
    Icon: Healix
  },
  {
    id: "dataminr",
    Icon: DataMinr
  },
  {
    id: "max-security",
    Icon: MaxSecurity
  }
];

export const riskProvidersMeta: Map<string, RiskProvider> = new Map(
  riskProviders.map((provider) => [provider.id, provider])
);

/** Helper component to render an icon */
export const ProviderIcon = ({ providerId }: { providerId: string }) => {
  const Icon = riskProvidersMeta.get(providerId)?.Icon;
  return Icon ? <Icon /> : null;
};

const isUpperCase = (str:string) => str === str.toUpperCase();

export const getCapitalizedProviderName = (providerName: string) => {
  // This only applicable for a race condition - shouldn't be in usual flow
  if (!providerName) {
    return '';
  }

  if (isUpperCase(providerName)) {
    return providerName;
  }

  if (providerName === 'max-security') {
    return capitalizeWord(providerName).replace('-', ' ');
  }

  return capitalizeWord(providerName);
};