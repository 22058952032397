import { callApi, TokenType } from "@/common/util/api";
import browserDatabase from "@/common/util/browser-database";
import { CONTEXT_KEY, ContextTypes } from "@/core/store/shared-data/sharedData.types";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import { PlaybookForm, PlaybookItem, PlaybooksList, TaskForm, TasksList } from "../types/playbooks";

export const playbooksApi = {
  tokenType: browserDatabase.getItem(CONTEXT_KEY) as ContextTypes === "platform" ? "access": "tenant",
  getPlaybooksData: function(
    page = 0,
    size = 10,
    sort?: SortData,
    query?: string
  ) {
    return callApi<PaginatedResult<PlaybooksList>>(
      `managed/icm-service/v1/play-books?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${query ? '&search=(' + query + ')&' : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListPlayBook'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getPlaybookById: function(id: string) {
    return callApi<PlaybookItem>(
      `managed/icm-service/v1/play-books/${id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetPlayBook'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  savePlaybook: function(params: PlaybookForm) {
    return callApi(
      `managed/icm-service/v1/play-books`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreatePlayBook'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updatePlaybook: function (params: PlaybookForm) {
    return callApi(
      `managed/icm-service/v1/play-books/${params._id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdatePlayBook'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  deletePlaybook: function(playbookId: string){
    return callApi(
      `managed/icm-service/v1/play-books/${playbookId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeletePlayBook'
        },
        method: 'delete',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getTasksData: function(
    page = 0,
    size = 10,
    playbookId: string,
    sort?: SortData,
    searchQuery?: string
  ) {
    return callApi<PaginatedResult<TasksList>>(
      `/managed/icm-service/v1/play-books/${playbookId}?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}&orderBy=name:ASC${searchQuery ? '&search=(' + searchQuery + ')' : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListTask'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },

  deleteTask: function(playbookId: string, taskId: string){
    return callApi(
      `/managed/icm-service/v1/play-books/${playbookId}/tasks/${taskId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeleteTask'
        },
        method: 'delete',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  saveTask:  function(playbookId: string, params: TaskForm) {
    return callApi(
      `/managed/icm-service/v1/play-books/${playbookId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateTask'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updateTask: function(playbookId: string, params: TaskForm) {
    return callApi(
      `/managed/icm-service/v1/play-books/${playbookId}/tasks/${params._id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateTask'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  }
};