import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useMassCommsSummaryGraphStyles = createStyles((theme: MantineTheme) => ({
  count: {
    fontSize: 28,
    fill: theme.white,
    fontWeight: theme.other.fw.bold
  },
  peopleText: {
    fontSize: theme.fontSizes.lg,
    fill: theme.colors.neutral[6]
  },
  tooltipCount: {
    display: 'flex',
    alignItems: 'start',
    fontSize: theme.fontSizes.xl,
    lineHeight: theme.other.lh.l,

    '& > span': {
      marginLeft: 5,
      color: theme.colors.neutral[6],
      fontSize: theme.fontSizes.sm,
      lineHeight: theme.other.lh.l
    }
  },
  tooltipWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    fontSize: theme.fontSizes.sm,

    '& > span': {
      color: theme.colors.neutral[6],
      fontSize: 10,
      lineHeight: 1
    }
  },

  tooltipChannels: {
    display: 'flex',
    alignItems: 'center',
    height: 24,
    fontSize: theme.fontSizes.xl,
    color: theme.colors.neutral[6],

    '& > *:not(:first-child)': {
      marginLeft: 6
    }
  },

  innerCircle: {
    fill: theme.colors.neutral[9]
  },
  innerLabel:{
    fill: theme.colors.neutral[6],
    fontSize: theme.fontSizes.sm

  },
  labelIcon:{
    '& svg': {
      fill:theme.white,
      '& path':{
        fill:theme.white
      }
    }
  }
}));
