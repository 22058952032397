import { createStyles } from "@mantine/core";

export const useBottomDrawerStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.neutral[8],
    padding: '8px 16px'
  },
  headerContainer: {
    borderBottom: `0.5px solid ${theme.colors.neutral[6]} !important`,
    margin: '-12px -12px 16px -12px'
  },
  title: {
    flex: '1',
    marginRight: 0
  }
}));
