/* eslint-disable react/boolean-prop-naming */
import { MantineTheme } from "@mantine/core";
import { CellClickedEvent, ColDef, GridOptions, RowClassParams } from "ag-grid-community";
import { IsExternalFilterPresentParams, RowHeightParams } from "ag-grid-community/dist/lib/entities/iCallbackParams";
import { RowNode } from "ag-grid-community/dist/lib/entities/rowNode";
import { RowClickedEvent } from "ag-grid-community/dist/lib/events";
import { AgGridReact } from "ag-grid-react";
import React, { forwardRef, ForwardRefRenderFunction, ReactNode, useCallback, useMemo } from "react";

import { agGridDefaultColDef } from "@/common/components/AgGrid/ag-grid.config";
import { useDataGridStyles } from "@/common/components/AgGrid/DataGrid.styles";
import { ReactComponent as ExpandIcon } from "@/common/icons/Arrows/down-arrow-20-20.svg";
import { ReactComponent as CollapseIcon } from "@/common/icons/Arrows/up-arrow.svg";
import { getFontSize } from "@/core/styles/mantine/components/Input.style";
import { semanticColorPalette } from "@/core/styles/mantine/palettes";
import appTheme from "@/core/styles/mantine/theme";

type Props<T> = {
  id: string,
  data: Array<T>,
  columnDefs: Array<ColDef>,
  defaultColDef?: ColDef,
  rowClass?: string,
  isInInvertedTheme?: boolean,
  pagination: boolean,
  paginationPageSize: number,
  animateRows: boolean,
  suppressHorizontalScroll: boolean,
  suppressRowClickSelection: boolean,
  suppressDragLeaveHidesColumns: boolean,
  suppressCellFocus: boolean,
  domLayout: 'normal' | 'autoHeight' | 'print',
  gridOptions: GridOptions<T>,
  isWithExpandableRows: boolean,
  expandedRows: Map<number, boolean>,
  onRowExpandChanged: (rowIndex: number) => void,
  getExpandedRowHeight?: (params: RowHeightParams<T>) => number,
  isExternalFilterPresent?: (params: IsExternalFilterPresentParams<T>) => boolean,
  doesExternalFilterPass?: (node: RowNode<T>) => boolean,
  onCellClicked?: (event: CellClickedEvent<T>) => void,
  onRowClicked?: (event: RowClickedEvent<T>) => void,
  variant?: 'default' | 'zebra' | 'noZebra',
  size?: 'xxLarge' | 'large' | 'medium' | 'small'
  unSortIcon?: boolean
}

const DataGridComponent: ForwardRefRenderFunction<AgGridReact, Props<Record<string, unknown>>> = ({
  id,
  data,
  columnDefs,
  rowClass,
  isInInvertedTheme,
  defaultColDef,
  pagination,
  paginationPageSize,
  animateRows,
  suppressHorizontalScroll,
  suppressRowClickSelection,
  suppressDragLeaveHidesColumns,
  suppressCellFocus,
  domLayout,
  gridOptions,
  isWithExpandableRows,
  expandedRows,
  onRowExpandChanged,
  getExpandedRowHeight,
  isExternalFilterPresent,
  doesExternalFilterPass,
  onCellClicked,
  onRowClicked,
  variant = 'default',
  size = 'medium',
  unSortIcon = true
}, ref) => {

  const { classes } = useDataGridStyles(
    { hasData: data?.length > 0, searchInputPosition: undefined }
  );

  const calculateRowHeight = useCallback(() => {
    const isDefaultRowStyle = variant === 'default';
    switch (size) {
    case 'xxLarge':
      return 97;
    case 'large':
      return isDefaultRowStyle ? 74 : 58;
    case 'medium':
      return isDefaultRowStyle ? 72 : 50;
    case 'small':
      return isDefaultRowStyle ? 56 : 41;
    default:
      return 66;
    }
  }, [size, variant]);

  const calculateMaxRowHeight = useCallback(() => {
    const isDefaultRowStyle = variant === 'default';
    switch (size) {
    case 'xxLarge':
      return 98;
    case 'large':
      return 57;
    case 'medium':
      return isDefaultRowStyle ? 56 : 49;
    case 'small':
      return 40;
    default:
      return 72;
    }
  }, [size, variant]);

  const getRowHeight = useCallback((params: RowHeightParams) => {
    return expandedRows.get(params.node.rowIndex as number)
      ? (getExpandedRowHeight?.(params) || 72) + 16
      :
      calculateRowHeight();
  }, [calculateRowHeight, expandedRows, getExpandedRowHeight]);

  const getRowStyles = useCallback((params: RowClassParams) => {
    const noTopBorder = ((variant !== 'default') && params.rowIndex !== 0);
    const isDefaultXXLRow = ((variant === 'default') && size === 'xxLarge');

    const getBackgroundColor = (expandedRowsMap: Map<number, boolean>, rowIndex: number, variantProp: 'default' | 'zebra' | 'noZebra') => {
      if (expandedRowsMap.size > 0) {
        return expandedRowsMap.get(rowIndex) ?
          semanticColorPalette.surfaceBackground.tertiary : semanticColorPalette.surfaceBackground.secondary;
      }
      if(variantProp === 'zebra') {
        return rowIndex % 2 === 0
          ?
          semanticColorPalette.surfaceBackground.secondary
          :
          semanticColorPalette.surfaceBackground.tertiary;
      } else {
        return semanticColorPalette.surfaceBackground.secondary;
      }
    };

    return {
      maxHeight: expandedRows.get(params.rowIndex as number)
        ? (getExpandedRowHeight?.(params) || 72)
        : gridOptions?.rowStyle?.maxHeight ?? calculateMaxRowHeight(),
      backgroundColor: getBackgroundColor(expandedRows, params.rowIndex as number, variant ),
      boxSizing: 'border-box',
      fontSize: size === 'xxLarge' ? getFontSize(appTheme as MantineTheme, 'lg') : getFontSize(appTheme as MantineTheme, 'md'),
      border: isDefaultXXLRow ? 'none' : `0.5px solid ${semanticColorPalette.border.general.lighter}`,
      borderTop: noTopBorder || isDefaultXXLRow ? 'none' : `0.5px solid ${semanticColorPalette.border.general.lighter}`,
      borderBottom: `0.5px solid ${semanticColorPalette.border.general.lighter}`,
      overflow: 'hidden'
    };
  }, [
    calculateMaxRowHeight,
    expandedRows,
    getExpandedRowHeight,
    gridOptions?.rowStyle?.maxHeight,
    size,
    variant
  ]);

  const expandCellRenderer = useCallback((params: Record<string, string | number>): ReactNode => {
    const isRowExpanded = expandedRows?.get(params.rowIndex as number) || false;
    return (
      // eslint-disable-next-line react/jsx-no-bind
      <button className={ classes.expandButton } onClick={ () => onRowExpandChanged?.(params.rowIndex as number) }>
        { isRowExpanded ? <CollapseIcon/> : <ExpandIcon/> }
      </button>
    );
  }, [ expandedRows, classes.expandButton, onRowExpandChanged ]);

  const mappedColDefs: Array<ColDef> = useMemo(() => {
    if (!isWithExpandableRows) {
      return columnDefs;
    }

    return [
      ...columnDefs,
      {
        field: 'expand',
        headerName: '',
        width: 55,
        flex: 0,
        cellRenderer: expandCellRenderer,
        sortable: false,
        resizable: false,
        lockPosition: 'right'
      }
    ];
  }, [ columnDefs, expandCellRenderer, isWithExpandableRows ]);

  return (
    <div id={ id }
      // className={ `${size === 'small' ? 'ag-icons-small' : 'ag-icons-large'} ag-theme-alpine-dark ag-theme-rt ${isInInvertedTheme ? 'ag-inverted-theme-rt' : ''} ag-theme-rt-row-style-${variant} ` }
    >
      <AgGridReact
        ref={ ref }
        defaultColDef={ {
          ...agGridDefaultColDef,
          ...defaultColDef
        } }
        rowData={ data }
        rowClass={ rowClass }
        columnDefs={ mappedColDefs }
        pagination={ pagination }
        paginationPageSize={ paginationPageSize }
        animateRows={ animateRows }
        suppressRowClickSelection={ suppressRowClickSelection }
        suppressHorizontalScroll={ suppressHorizontalScroll }
        suppressDragLeaveHidesColumns={ suppressDragLeaveHidesColumns }
        suppressCellFocus={ suppressCellFocus }
        domLayout={ domLayout }
        gridOptions={ {
          ...gridOptions
        } }
        isExternalFilterPresent={ isExternalFilterPresent }
        doesExternalFilterPass={ doesExternalFilterPass }
        getRowHeight={ getRowHeight }
        getRowStyle={ getRowStyles }
        onCellClicked={ onCellClicked }
        onRowClicked={ onRowClicked }
        unSortIcon={ unSortIcon }
      />
    </div>
  );
};

export default forwardRef(DataGridComponent) as <T>(
  props: Props<T> & React.RefAttributes<AgGridReact>
) => JSX.Element;
