import { closeModal } from '@mantine/modals';
import { FC, useCallback } from "react";

import EvidenceVideoModalComponent
  from '@/tenant-context/employee-app-action-response-center/components/ui/EvidenceVideoModal/EvidenceVideoModal.component';
import { EA_ARC_EMERGENCY_VIDEO_MODAL } from '@/tenant-context/employee-app-action-response-center/config/eaArc';
import { EmergencyVideo } from "@/tenant-context/employee-app-action-response-center/types/emergency-video";

type Props = {
  emergencyVideo: EmergencyVideo
};

const EvidenceVideoModalContainer: FC<Props> = ({ emergencyVideo }) => {

  const handleClose = useCallback(() => {
    closeModal(EA_ARC_EMERGENCY_VIDEO_MODAL);
  }, []);

  return (
    <EvidenceVideoModalComponent
      emergencyVideo={ emergencyVideo }
      onClose={ handleClose }
    />
  );
};

export default EvidenceVideoModalContainer;
