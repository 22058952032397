import { createStyles, MantineTheme } from "@mantine/core";

export const  useEmergencyContactModalContentStyles = createStyles((theme: MantineTheme) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '0 16px'
  },

  toggleContainer: {
    display: 'flex',
    justifyContent: 'end',
    padding: '25px 0',
    alignItems: 'end'
  },

  toggle: {
    justifyContent: 'end',
    fontSize: 16
  },

  singular: {
    // 8px because gap is 16
    width: 'calc(50% - 8px)'
  },

  formItemFullWidth: {
    margin: '24px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
    width: '100%'
  },

  formItemHeader: {
    color: theme.other.semantic.text.primary,
    fontSize: theme.fontSizes.md,
    lineHeight: theme.other.lh.l
  },

  formDivider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center'
  }
}));