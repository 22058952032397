import { closeAllModals } from "@mantine/modals";
import { FeatureCollection, Point } from "geojson";
import { createContext, FC, useCallback } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import { capitalizeWord } from "@/common/util/format/string";
import { Dispatch, RootState } from "@/core/store";
import { ILocation } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";
import ProfileAddressesModal, { ProfileAddressesModalFormData } from "@/tenant-context/control-profile/components/modals/ProfileAddressesModal/ProfileAddressesModal.component";
import {
  CreatePersonAddress,
  ProfileAddress,
  UpdatePersonAddress
} from "@/tenant-context/control-profile/types/profile";

type PersonalDetailsModalContextType = {
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>,
  formControls: UseFormReturn,
  locationsListData: Array<ILocation>
  currentSearchLocation: FeatureCollection<Point> | undefined,
};

export const ProfileAddressesModalContext = createContext<PersonalDetailsModalContextType>(
  {} as PersonalDetailsModalContextType
);

type Props = {
  mode?: 'add' | 'edit',
  editableAddress?: ProfileAddress
  type?: 'Work' | 'Home'
}

export const ProfileAddressesModalProvider: FC<Props> = ({
  mode = 'add',
  editableAddress,
  type
}) => {
  const formControls = useForm({
    mode: "onChange"
  });

  const {
    profile: {
      modifyAddress,
      deleteAddress
    }
  } = useDispatch<Dispatch>();

  const locationsListData = useSelector((state: RootState) => state.profile?.locationsList?.items) || [];
  const currentSearchLocation = useSelector((state: RootState) => state.mapSearch?.activeLocation);

  const handleSubmit = async (untypedData: unknown) => {
    const data = untypedData as ProfileAddressesModalFormData;

    if (mode === 'edit' && !editableAddress) {
      return;
    }
    const generatePayload = (): (UpdatePersonAddress | CreatePersonAddress) & { mode: 'add' | 'edit' } => {
      if (mode === 'edit') {
        return {
          ...data,
          isPrimary: data.isPrimary,
          type: capitalizeWord(data.type),
          mode,
          identifier: data?.identifier === editableAddress?.identifier
            ? editableAddress?.identifier
            :
            editableAddress?._id
        };
      }

      return {
        ...data,
        isPrimary: data.isPrimary,
        type: capitalizeWord(data.type),
        mode
      };
    };


    const payload = generatePayload();
    const success = await modifyAddress(payload);
    if (success) {
      formControls.reset();
      closeAllModals();
    }
  };

  const onSubmit = formControls.handleSubmit(handleSubmit);

  const handleRemove = useCallback(async () => {
    if (!editableAddress) {
      return;
    }

    const addressId = editableAddress.type === 'Work' ? editableAddress.identifier : editableAddress._id;
    const success = await deleteAddress(addressId);

    if (success) {
      formControls.reset();
      closeAllModals();
    }
  }, [deleteAddress, editableAddress, formControls]);

  return (
    <ProfileAddressesModalContext.Provider value={ useContextValue({
      onSubmit,
      formControls,
      handleRemove,
      locationsListData,
      currentSearchLocation
    }) }>
      <ProfileAddressesModal
        handleRemove={ handleRemove }
        mode={ mode }
        editableAddress={ editableAddress }
        type={ type }
      />
    </ProfileAddressesModalContext.Provider>
  );
};
