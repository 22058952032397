import { Button, Drawer } from "@mantine/core";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Dispatch, FC, ReactNode, SetStateAction, useCallback, useMemo, useRef } from "react";

import DataGrid from "@/common/components/AgGrid";
import TruncatedTextWithTooltip from "@/common/components/TruncatedTextWithTooltip/TruncatedTextWithTooltip.component";
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import ArcSection from "@/tenant-context/control-action-response-center/components/ArcSection";
import ImpactSummary from "@/tenant-context/control-action-response-center/components/ImpactSummary";
import ImpactTabGridFiltersComponent
  from "@/tenant-context/control-action-response-center/components/ImpactTab/components/ImpactTabGridFilters.component";
import { useImpactTabStyles } from "@/tenant-context/control-action-response-center/components/ImpactTab/ImpactTab.styles";
import {
  ImpactPeopleGridData,
  ImpactPeopleGridDataList
} from "@/tenant-context/control-action-response-center/components/ImpactTab/types/ImpactTab.types";
import { ArcImpactStatus } from "@/tenant-context/control-action-response-center/types/ARC.types";
import MiniProfile from "@/tenant-context/control-profile/components/ui/MiniProfile";

type Props = {
  rowData: ImpactPeopleGridDataList;
  expandedRows: Map<number, boolean>;
  selectedImpactStatus: ArcImpactStatus | undefined;
  onSearchStringChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStatusFilterChange: (status: ArcImpactStatus | 'ALL') => void;
  onRowExpandChanged: (rowIndex: Map<number, boolean>) => void;
  onManualStatusChangeRequest: (personId: string, personName: string, personStatus: ArcImpactStatus) => void;
  onNavigateToPersonProfileRequested: (personId: string) => void;
  handleRowExpand: (personId: string) => void;
  isProfileOpened: boolean;
  setIsProfileOpened: Dispatch<SetStateAction<boolean>>;
  onPersonTypeFilterChange: (personType: string) => void;
  onLocationTypeFilterChange: (locationType: string) => void;
  onResponseFilterChange: (responseFilter: string) => void;
}

const ImpactTabComponent: FC<Props> = ({
  rowData,
  expandedRows,
  selectedImpactStatus,
  onSearchStringChange,
  onStatusFilterChange,
  onRowExpandChanged,
  onManualStatusChangeRequest,
  onNavigateToPersonProfileRequested,
  isProfileOpened,
  setIsProfileOpened,
  onPersonTypeFilterChange,
  onLocationTypeFilterChange,
  onResponseFilterChange
}) => {
  const { classes } = useImpactTabStyles();
  const gridRef = useRef<AgGridReact>(null);

  const nameCellRenderer = useCallback((params: ICellRendererParams): ReactNode => {
    const isRowExpanded = expandedRows.get(params.rowIndex as number) || false;

    if (!isRowExpanded) {
      return (
        <button
          className={ classes.nameCellWrapper }
          /* eslint-disable-next-line react/jsx-no-bind */
          onClick={ () => onNavigateToPersonProfileRequested(params.data.personId) }
        >
          <TruncatedTextWithTooltip fullText={ params.value } maxLength={ 15 }/>
        </button>
      );
    }

    return (
      <div>
        <button
          className={ classes.nameCellWrapper }
          /* eslint-disable-next-line react/jsx-no-bind */
          onClick={ () => onNavigateToPersonProfileRequested(params.data.personId) }
        >
          { <TruncatedTextWithTooltip fullText={ params.value } maxLength={ 15 }/>  }
        </button>

        <div className={ classes.expandedRow }>
          { params.data[`${params?.colDef?.field}Expanded`] }
        </div>
      </div>
    );
  }, [classes.nameCellWrapper,classes.expandedRow, expandedRows, onNavigateToPersonProfileRequested]);

  const locationTypeCellRenderer = useCallback((params: ICellRendererParams): ReactNode => {
    const isRowExpanded = expandedRows.get(params.rowIndex as number) || false;

    if (!isRowExpanded) {
      return (<TruncatedTextWithTooltip fullText={ params.value } maxLength={ 20 }/>
      );
    }

    return (
      <div>
        <div>
          <TruncatedTextWithTooltip fullText={ params.value } maxLength={ 20 }/>
        </div>
        <div className={ classes.expandedRow }>
          { params.data[`${params?.colDef?.field}Expanded`] }
        </div>
      </div>
    );
  }, [classes.expandedRow, expandedRows]);

  const safetyStatusCellRenderer = useCallback(({ data }: ICellRendererParams<ImpactPeopleGridData>): ReactNode => {
    if (!data) {
      return null;
    }

    return (
      <div style={ { display: 'flex', alignItems: 'center', height: 48 } }>
        <Button
          className={ `${classes.statusChangeButton}` }
          variant='subtle'
          size='xs'
          /* eslint-disable-next-line react/jsx-no-bind */
          onClick={ () => {
            onManualStatusChangeRequest(data.personId, data.name, data.safetyStatus as ArcImpactStatus);
          } }
        >
          <span className={ `${classes.statusIndicator} ${classes.statusIndicatorBg}_${data.safetyStatus}` }></span>
          { data.safetyStatus }
        </Button>
      </div>
    );
  }, [ classes.statusChangeButton,classes.statusIndicator, classes.statusIndicatorBg, onManualStatusChangeRequest ]);

  const defaultCellRenderer = useCallback((params: ICellRendererParams): ReactNode => {
    const isRowExpanded = expandedRows.get(params.rowIndex as number) || false;

    if (!isRowExpanded) {
      return (
        <div>
          { params.value }
        </div>
      );
    }

    return (
      <div>
        <div>
          { params.value }
        </div>
        <div className={ classes.expandedRow }>
          { params.data[`${params?.colDef?.field}Expanded`] }
        </div>
      </div>
    );
  }, [ classes, expandedRows ]);

  const columnDefs  = useMemo<ColDef[]>(() => {
    return [
      {
        field: 'name',
        flex: .75,
        cellRenderer: nameCellRenderer
      },
      { field: 'personType', flex: .5 },
      {
        field: 'locationType',
        flex: .75,
        cellRenderer: locationTypeCellRenderer
      },
      { field: 'location', flex: 1 },
      // { field: 'lastContacted', flex: 1 },
      // { field: 'response', flex: 1 },
      {
        field: 'safetyStatus',
        flex: .5,
        cellClass: classes.cellWrapText + " " + classes.alignRight,
        cellRenderer: safetyStatusCellRenderer
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ classes.alignRight, classes.cellWrapText, expandedRows, locationTypeCellRenderer,
    nameCellRenderer, safetyStatusCellRenderer ]);

  const defaultColDef = useMemo( ()=> ({
    sortable: true,
    autoHeight: true,
    resizable: true,
    cellRenderer: defaultCellRenderer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [ classes.cellWrapText, classes.rowWrapper, defaultCellRenderer, expandedRows ]);

  return (
    <div className={ classes.container }>
      <ImpactSummary
        isOpenInImpactTab={ true }
      />

      <div style={ { marginTop: 10 } }>
        <ArcSection>
          <LocalErrorBoundary>
            <ImpactTabGridFiltersComponent
              selectedImpactStatus={ selectedImpactStatus }
              onSearchStringChange={ onSearchStringChange }
              onStatusFilterChange={ onStatusFilterChange }
              onPersonTypeFilterChange={ onPersonTypeFilterChange }
              onLocationTypeFilterChange={ onLocationTypeFilterChange }
              onResponseFilterChange={ onResponseFilterChange }
            />
            <div className="ag-theme-alpine-dark ag-theme-rt">
              <div className={ classes.impactTable }>
                <DataGrid<ImpactPeopleGridData>
                  id='arc-impact-people-grid'
                  ref={ gridRef }
                  data={ rowData }
                  defaultColDef={ defaultColDef }
                  columnDefs={ columnDefs }
                  pagination={ true }
                  paginationPageSize={ 10 }
                  suppressCellFocus={ true }
                  suppressRowClickSelection={ true }
                  domLayout='autoHeight'
                  isWithExpandableRows={ true }
                  rowExpandMode='single'
                  rowClass={ classes.rowWrapper }
                  isInInvertedTheme={ true }
                  /* eslint-disable-next-line react/jsx-no-bind */
                  getExpandedRowHeight={ useCallback(() => 250, []) }
                  onExpandedRowsChange={ onRowExpandChanged }
                  gridOptions={ {
                    rowHeight: 60,
                    rowStyle: {
                      cursor: "pointer",
                      boxSizing: 'border-box',
                      overflow: 'hidden',
                      maxHeight: 64
                    }
                  } }
                />
              </div>
            </div>
          </LocalErrorBoundary>
        </ArcSection>
      </div>
      <Drawer
        opened={ isProfileOpened }
        // eslint-disable-next-line react/jsx-no-bind
        onClose={ () => setIsProfileOpened(false) }
        withOverlay={ false }
        withCloseButton={ true }
        position="right"
      >
        <MiniProfile isStandAlone/>

      </Drawer>

    </div>
  );
};

export default ImpactTabComponent;
