import { createStyles } from "@mantine/core";

export const useNavigationSidebarStyles = createStyles((theme, isExpanded: boolean) => ({
  root: {
    height: "100%",
    width: `var(--navigation-sidebar-${isExpanded ? 'open' : 'closed'}-width)`,
    backgroundColor: theme.colors.neutral[8],

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    position: 'relative',
    '&,*': {
      transition: 'all 0.2s ease-in-out !important'
    },

    ...(isExpanded && {
    })
  },

  topBlock: {
    display: "flex",
    flexDirection: "column"
  },

  menuFixedBlock: {
    flex: '0 0 auto'
  },

  scrollableContent: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    '::-webkit-scrollbar:vertical': {
      width: '7px',
      padding: '7px 2px'
    },
    ul: {
      height: '100%'
    }
  },

  bottomBlock: {
    height: 32,
    marginBottom: 16
  },

  toggleButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 24,
    backgroundColor: theme.colors.white[0],
    borderRadius: `${theme.radius.xl}px 0 0 ${theme.radius.xl}px`,
    '&:hover': {
      backgroundColor: theme.colors.neutral[1]
    },
    svg: {
      width: 4,
      height: 8,
      transform: `rotate(${isExpanded ? 180 : 0}deg)`
    }
  },

  bottomEmphasized: {
    backgroundColor: theme.colors.primary[4],
    borderTop: `1px solid ${theme.colors.neutral[6]}`
  },

  userDetailsItemContainer: {
    marginBottom: isExpanded ? 10 : 12
  },

  navItemContainer: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "space-between",
    height: '100%'
  },

  navigationListItem: {
    '&, *': {
      transition: 'all 0s ease-in-out !important'
    }
  }
}));
