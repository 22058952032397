import { createStyles } from "@mantine/core";

import { CSS_KEY } from '@/core/styles/mantine/constants';

export const useRiskProviderBadgeStyles = createStyles((theme) =>({
  badgeButton: {
    borderRadius: theme.radius.xl,
    padding: '4px 20px !important',
    lineHeight: 1,
    [`.${CSS_KEY}-Button-label`]: {
      fontSize: 12,
      fontWeight: 700
    },
    '&, & *': {
      transition: '0.3s ease-in-out'
    }
  },
  badgeButtonFilled: {
    backgroundColor: `${theme.colors.neutral[0]} !important`,
    [`.${CSS_KEY}-Button-label`]: {
      color: `${theme.colors.neutral[9]} !important`
    },
    '&:hover': {
      backgroundColor: `${theme.colors.neutral[3]} !important`
    }
  },
  badgeButtonLight: {
    backgroundColor: `${theme.colors.neutral[7]} !important`,
    [`.${CSS_KEY}-Button-label`]: {
      color: `${theme.colors.neutral[0]} !important`
    },
    '&:hover': {
      backgroundColor: `${theme.colors.neutral[8]} !important`
    }
  }
}));
