import { createStyles } from "@mantine/core";

import { NotificationContainerLayoutParams } from '@/tenant-context/control-live-feed/types/live-feed';

type Params = {
  layoutParams?: NotificationContainerLayoutParams;
}

export const useToastNotificationContainerStyles = createStyles((theme, params: Params) => ({
  root: {
    position: 'fixed',
    top: theme.spacing.xl,
    paddingLeft: theme.spacing.xl,
    right: params?.layoutParams?.rightBorderToWindowGap || 100,
    width: 'auto',
    height: `calc(100vh - ${ theme.spacing.xl * 2 }px)`,
    zIndex: 9999,
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none'
  },
  notificationHeader: {
    transition: '0.3s ease-in-out',
    overflow: 'hidden',
    pointerEvents: 'auto'
  },
  showLessButton: {
    background: 'transparent !important',
    height: 48
  },
  notificationContainer: {
    flexGrow: 1,
    overflow: 'scroll',
    scrollbarWidth: 'none',
    paddingLeft: theme.spacing.xl,
    paddingRight: theme.spacing.xl,
    marginRight: -theme.spacing.xl,
    paddingTop: 32,
    marginTop: -32,
    maxHeight: `calc(100vh - ${ theme.spacing.xl * 2 }px)`,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'auto'
    }
  }
}));
