import { LoadingOverlay } from '@mantine/core';
import { FC } from "react";

type Props = {
  isLoading: boolean | undefined
};

const ProfileLoadingOverlayComponent: FC<Props> = ({
  isLoading
}) => {

  return (
    <LoadingOverlay visible={ isLoading || false } />
  );
};

export default ProfileLoadingOverlayComponent;