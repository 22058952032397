import { createModel } from "@rematch/core";

import { RootModel } from "@/core/store";

import { autocompleteReducers } from './autocomplete.reducers';
import { autocompleteState } from './autocomplete.state';

export const autocomplete = createModel<RootModel>()({
  state: autocompleteState,
  reducers: autocompleteReducers
});