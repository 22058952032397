import { closeAllModals } from '@mantine/modals';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/core/store';
import RelatedEventPopupComponent
  from '@/tenant-context/control-risk-config/components/RelatedEventPopup/RelatedEventPopup.component';

const RelatedEventPopup: FC = () => {
  const selectedRelatedEvent = useSelector((state: RootState) => state.eventTimeLine?.selectedRelatedEvent);

  const handleCloseButtonClick = useCallback(() => {
    closeAllModals();
  }, []);
  
  if (!selectedRelatedEvent) {
    return null;
  }

  return (
    <RelatedEventPopupComponent
      riskLevelColor={ selectedRelatedEvent.riskLevelColor }
      onCloseButtonClick={ handleCloseButtonClick }
      source={ selectedRelatedEvent.mediaSource }
      timeElapsed={ selectedRelatedEvent.timeElapsed }
      riskEventAlertType={ selectedRelatedEvent.riskEventAlertType }
      eventLocationName={ selectedRelatedEvent.eventLocationName }
      eventDetails={ selectedRelatedEvent.eventDetails }
      providerName={ selectedRelatedEvent.providerName }
      providerIcon={ selectedRelatedEvent.providerIcon }
    />
  );
};

export default RelatedEventPopup;
