export const ThreatReportPolicies = {
  COUNTRY_RISK_REPORTS_READONLY: {
    policyNames: [
      'Tenant_RiskReport_CountryRisks_ReadOnly'
    ]
  },
  CITY_RISK_REPORTS_READONLY: {
    policyNames: [
      'Tenant_RiskReport_CityRisks_ReadOnly'
    ]
  }
};
