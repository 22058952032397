import { FC } from "react";
import { useSelector } from 'react-redux';

import LoaderScreen from '@/core/components/SSOIntegration/LoaderScreen/LoaderScreen.component';
import { RootState } from '@/core/store';

type Props = {
  isLoading?: boolean,
  error?: string
  isGlobal?: boolean
}

const LoaderScreenContainer: FC<Props> = ({ isLoading, error, isGlobal = false }) => {
  const isLoadingState = useSelector((state: RootState) => state.commonData?.showLoaderScreen);

  return (
    <LoaderScreen isLoading={ isGlobal ? isLoadingState : isLoading || false } error={ error }/>
  );
};

export default LoaderScreenContainer;
