import { createStyles } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useVoiceRecordingStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed ${theme.colors.neutral[5]}`,
    height: 250,
    backgroundColor: genericColorPalette.neutral[9],
    borderRadius: 4,
    padding: 24
  },

  volumeBoxWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -94,

    '& > div': {
      marginLeft: 1,
      marginRight: 1
    }
  },

  volumeBoxDivider: {
    height: 25,
    width: 2,
    marginLeft: 2,
    marginRight: 2
  },

  volumeBox: {
    height: 25,
    width: 8,
    backgroundColor: theme.colors.neutral[6]
  },

  volumeBoxActive: {
    backgroundColor: theme.colors.neutral[4]
  },

  currentTime: {
    marginRight: 24
  },

  currentSize: {
    marginLeft: 24
  },

  recordButton: {
    marginTop: 46
  }
}));
