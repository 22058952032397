import { FC } from "react";
import { useSelector } from 'react-redux';

import { RootStateWithLoading } from '@/core/store';
import GeofenceLoadingOverlayComponent
  from '@/tenant-context/control-draw/components/GeofenceLoadingOverlay/GeofenceLoadingOverlay.component';


const GeofenceLoadingOverlay: FC = () => {
  const isGeofencesLoading = useSelector(
    (rootState: RootStateWithLoading) => rootState.loading.models.geofence
  );

  return (
    <GeofenceLoadingOverlayComponent isLoading={ isGeofencesLoading }/>
  );
};

export default GeofenceLoadingOverlay;
