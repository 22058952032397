import { FeedNotification } from '@/tenant-context/control-live-feed/types/live-feed';

export function upcomingNotificationsFilter(notification: FeedNotification): boolean {
  if (notification.status === 'CLOSE') {
    return false;
  }

  const timeStampLowerBound = Date.now(); // Current time
  const notificationTime = getNotificationTime(notification);

  return notificationTime >= timeStampLowerBound;
}

export function recentNotificationsFilter(notification: FeedNotification): boolean {
  if (notification.status === 'CLOSE') {
    return false;
  }

  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
  const timeStampUpperBound = Date.now() + 1000 * 60 * 60; // Current time + error margin 1 hour
  const timeStampLowerBound = Date.now() - MILLISECONDS_PER_DAY; // A day before the current time
  const notificationTime = getNotificationTime(notification);

  return notificationTime <= timeStampUpperBound && notificationTime >= timeStampLowerBound;
}

export function olderNotificationsFilter(notification: FeedNotification): boolean {
  if (notification.status === 'CLOSE') {
    return false;
  }

  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
  const timeStampUpperBound = Date.now() - MILLISECONDS_PER_DAY; // A day before the current time
  const notificationTime = getNotificationTime(notification);

  return notificationTime <= timeStampUpperBound;
}

export function closedNotificationsFilter(notification: FeedNotification): boolean {
  return notification.status === 'CLOSE';
}

export function getNotificationTime(notification: FeedNotification): number {
  return notification.eventStartedDateTime;
}
