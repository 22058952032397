/* eslint-disable @typescript-eslint/no-explicit-any */
import { Feature, Point } from "geojson";

import { store } from "@/core/store";
import { AssetRankingLabel } from "@/tenant-context/common/types/asset-ranking";
import { GeoPoint } from "@/tenant-context/common/types/location";
import { IndoorPersonAssetRankingSummary } from "@/tenant-context/plugin-indoors/types/indoor-map.types";

// This function takes out a list of GeoJSON Features and groups them by the property `smallestLocation`. Which denotes
// the smalled label/location of a given person/asset is in currently (Floor, zone, room, etc).
export const groupBySmallestLocation = (
  inputArray: Feature<Point, IndoorPersonAssetRankingSummary>[],
  outputType = {}
) => {
  return inputArray.reduce(
    (acc: Record<string, Feature<Point, IndoorPersonAssetRankingSummary>[]>, cur) => {
      // Get the cur value that matches the input key and remove the key value for it.
      const {
        id: key
      } = (cur.properties.smallestLocation as AssetRankingLabel);
      // If there is already an array for the user provided key use it else default to an empty array.
      const {
        [key]: currentArr = []
      } = acc;

      // Create a new object and return that merges the acc with the cur object
      return Object.assign(acc, {
        [key]: currentArr.concat(cur)
      });
    },
    // Replace the object here to an array to change output object to an array
    outputType
  );
};

// Returns the geometry of the given location, finding from the global locations hierarchy loaded earlier
export const getGeometryOfLocation = (locationName: string): GeoPoint | undefined => {
  const state = store.getState();

  // filter out the location that matches the location name
  const givenLocation = state.commonData.allLocations.find((location) => location.name === locationName);

  if (!givenLocation) {
    return undefined;
  }

  return givenLocation.geoPoint;
};
