import { Feature } from "geojson";

import { GeoPolygon, ViewportBbox } from "@/tenant-context/control-draw/types/draw";

export function getUpdatedGeofencePolygons(
  actionType: 'CREATE' | 'UPDATE',
  currentPolygons: Array<GeoPolygon>,
  newPolygon: Feature & {
    geometry: {
      coordinates: Array<ViewportBbox>
    }
  } | Feature,
  newBoundingBox: ViewportBbox
): Array<GeoPolygon> {
  const polygonId = newPolygon.id as string;
  const polygonType = newPolygon.properties?.isCircle ? 'CIRCLE' :
    newPolygon.geometry.type === 'LineString' ? 'LINE' : 'POLYGON';

  if (actionType === 'CREATE') {
    const polygon: GeoPolygon = {
      id: polygonId,
      type: polygonType,
      geometry: newBoundingBox
    };

    return [
      ...currentPolygons,
      polygon
    ];
  } else { // On Update
    return currentPolygons
      .map((geoFence) => {
        if (geoFence.id !== polygonId) {
          return geoFence;
        }

        return {
          ...geoFence,
          geometry: newBoundingBox
        };
      });
  }
}
