import { Embla } from "@mantine/carousel";
import { createContext, FC, useCallback, useContext, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import {
  TRAVELLERS_SEARCH_DRAWERS,
  TRAVELLERS_SEARCH_FIELDS,
  TRAVELLERS_SEARCH_SUB_SLIDES
} from "@/tenant-context/control-travellers-search/config/travellers-search.config";
import {
  DestinationRiskLevel,
  TravellerData,
  TravellersSearchFormData,
  TravellersType
} from "@/tenant-context/control-travellers-search/types/travelSearch.types";

type TravellersSearchContextType = {
  currentlyOpenedDrawer: TRAVELLERS_SEARCH_DRAWERS | undefined;
  setCurrentlyOpenedDrawer: (drawerId: TRAVELLERS_SEARCH_DRAWERS | undefined) => void;
  highlightedCountryIso: string | undefined;
  setHighlightedCountryIso: (countryIso: string | undefined) => void;
  highlightedTravellerId: string | undefined;
  setHighlightedTravellerId: (travellerId: string | undefined) => void;
  travellersSearchForm: UseFormReturn<TravellersSearchFormData>;
  searchTravellers: () => void;
  getTravellersForCountry: () => Promise<void>;
  selectedTraveller: TravellerData | undefined;
  setSelectedTraveller: (traveller: TravellerData | undefined) => void;
  subDrawerCarousel: Embla | null;
  setSubDrawerCarousel: (embla: Embla | null) => void;
  currentlyOpenedSlide: TRAVELLERS_SEARCH_SUB_SLIDES | undefined;
  setCurrentlyOpenedSlide: (slide: TRAVELLERS_SEARCH_SUB_SLIDES | undefined) => void;
  resetContext: () => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export const TravellersSearchContext = createContext<TravellersSearchContextType>(
    {} as TravellersSearchContextType
);

export const useTravellersSearchContext = () => useContext(TravellersSearchContext);

export const TravellersSearchContextProvider: FC = ({ children }) => {
  const [currentlyOpenedDrawer, setCurrentlyOpenedDrawer] = useState<TRAVELLERS_SEARCH_DRAWERS | undefined>(undefined);
  const [currentlyOpenedSlide, setCurrentlyOpenedSlide] = useState<TRAVELLERS_SEARCH_SUB_SLIDES>();
  const [highlightedCountryIso, setHighlightedCountryIso] = useState<string | undefined>(undefined);
  const [highlightedTravellerId, setHighlightedTravellerId] = useState<string | undefined>(undefined);
  const [selectedTraveller, setSelectedTraveller] = useState<TravellerData | undefined>(undefined);
  const [subDrawerCarousel, setSubDrawerCarousel] = useState<Embla | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isTimeTravelActive, timeTravelTargetEpoch } = useSelector(
    (state: RootState) => state.rankingSettings
  );

  const {
    travellersSearch: {
      fetchCountryWiseTravellers,
      fetchCountryDetailData
    },
    userProfile: {
      RESET_ITINERARY_DATA
    }
  } = useDispatch<Dispatch>();

  const travellersSearchForm = useForm<TravellersSearchFormData>({
    mode: 'onChange',
    defaultValues: {
      // [TRAVELLERS_SEARCH_FIELDS.TIME_RANGE]: TimeRange.NOW,
      [TRAVELLERS_SEARCH_FIELDS.TRAVELLERS_TYPE]: [ TravellersType.DOMESTIC, TravellersType.INTERNATIONAL ],
      [TRAVELLERS_SEARCH_FIELDS.DESTINATION_RISK_LEVEL]: [ DestinationRiskLevel.EXTREME, DestinationRiskLevel.HIGH,
        DestinationRiskLevel.LOW, DestinationRiskLevel.MEDIUM, DestinationRiskLevel.MODERATE ],
      [TRAVELLERS_SEARCH_FIELDS.RANGE_CALENDAR]: [null, null]
    } });

  const searchTravellers = useCallback(async () => {
    setIsLoading(true);
    const travellersSearchFormValues = travellersSearchForm.getValues();

    // Hardcoding the fromTime and toTime for now as per temporal filters
    // TODO: When Temporal filters are there - need to plug this to those values
    const fromTime = isTimeTravelActive ? timeTravelTargetEpoch : new Date().getTime();
    const toTime = isTimeTravelActive ? timeTravelTargetEpoch : new Date().getTime();

    const travellersResponse = await fetchCountryWiseTravellers({
      pageNum: 0,
      pageSize: 999,
      fromTime,
      toTime,
      mergeNonTenant: false,
      destinationRiskLevel: new Set(travellersSearchFormValues[TRAVELLERS_SEARCH_FIELDS.DESTINATION_RISK_LEVEL])
    });

    if (travellersResponse && travellersResponse.length > 0) {
      setCurrentlyOpenedDrawer(TRAVELLERS_SEARCH_DRAWERS.TRAVELLERS_SEARCH_SUB_DRAWER);
      setCurrentlyOpenedSlide(TRAVELLERS_SEARCH_SUB_SLIDES.TRAVELLERS_BY_COUNTRY_SLIDE);
      subDrawerCarousel?.scrollTo(0);
    } else {
      setCurrentlyOpenedDrawer(undefined);
    }

    setIsLoading(false);
  }, [fetchCountryWiseTravellers, isTimeTravelActive, subDrawerCarousel, timeTravelTargetEpoch, travellersSearchForm]);

  const getTravellersForCountry = useCallback((): Promise<void> => {
    setIsLoading(true);
    const travellersSearchFormValues = travellersSearchForm.getValues();

    // Hardcoding the fromTime and toTime for now as per temporal filters
    // TODO: When Temporal filters are there - need to plug this to those values
    const fromTime = new Date().getTime();
    const toTime = new Date().getTime();

    // const [ fromTime, toTime ] = calculateFromAndToTime(
    //   travellersSearchFormValues[TRAVELLERS_SEARCH_FIELDS.TIME_RANGE],
    //   travellersSearchFormValues[TRAVELLERS_SEARCH_FIELDS.RANGE_CALENDAR]
    // );

    return fetchCountryDetailData({
      pageNum: 0,
      pageSize: 999,
      fromTime,
      toTime,
      mergeNonTenant: false,
      travellersType: new Set(travellersSearchFormValues[TRAVELLERS_SEARCH_FIELDS.TRAVELLERS_TYPE]),
      destinationRiskLevel: new Set(travellersSearchFormValues[TRAVELLERS_SEARCH_FIELDS.DESTINATION_RISK_LEVEL])
    }).then(() => setIsLoading(false));
  }, [ travellersSearchForm, fetchCountryDetailData ]);

  const resetContext = useCallback(() => {
    setHighlightedCountryIso(undefined);
    setHighlightedTravellerId(undefined);
    setSelectedTraveller(undefined);
    setCurrentlyOpenedDrawer(undefined);
    setCurrentlyOpenedSlide(undefined);
    setSubDrawerCarousel(null);

    // Resetting the itinerary data in the user profile
    RESET_ITINERARY_DATA();
  }, [ RESET_ITINERARY_DATA ]);

  useEffect(() => {
    if (!currentlyOpenedSlide || !subDrawerCarousel) {
      return;
    }

    if (currentlyOpenedSlide === TRAVELLERS_SEARCH_SUB_SLIDES.TRAVELLERS_BY_COUNTRY_SLIDE) {
      subDrawerCarousel.scrollTo(0);
      return;
    }

    if (currentlyOpenedSlide === TRAVELLERS_SEARCH_SUB_SLIDES.COUNTRY_DETAIL_VIEW_SLIDE) {
      subDrawerCarousel.scrollTo(1);
      return;
    }

    if (currentlyOpenedSlide === TRAVELLERS_SEARCH_SUB_SLIDES.TRAVELLERS_MINI_PROFILE_SLIDE) {
      subDrawerCarousel.scrollTo(2);
      return;
    }
  }, [ currentlyOpenedSlide, subDrawerCarousel ]);

  return (
    <TravellersSearchContext.Provider
      value={ {
        currentlyOpenedDrawer,
        setCurrentlyOpenedDrawer,
        highlightedCountryIso,
        setHighlightedCountryIso,
        highlightedTravellerId,
        setHighlightedTravellerId,
        travellersSearchForm,
        searchTravellers,
        getTravellersForCountry,
        selectedTraveller,
        setSelectedTraveller,
        subDrawerCarousel,
        setSubDrawerCarousel,
        currentlyOpenedSlide,
        setCurrentlyOpenedSlide,
        resetContext,
        isLoading,
        setIsLoading
      } }
    >
      { children }
    </TravellersSearchContext.Provider>
  );
};
