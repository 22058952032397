
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ToggleableContextLayers
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import usePermission from "@/common/hooks/usePermission";
import { CorePolicies } from "@/core/config/CorePolicies.config";
import { Dispatch, RootState } from "@/core/store";
import {
  ContextualLayerSwitchType
} from "@/core/store/data-options/data-options.state";

import ContextualLayers from "./ContextualLayers.component";

const weatherLayers = [
  ToggleableContextLayers.Wind,
  ToggleableContextLayers.Radar,
  ToggleableContextLayers.Cyclones
];

const ContextualLayersContainer = () => {
  const isTimeTravelActive = useSelector((state: RootState) => state.rankingSettings.isTimeTravelActive);
  const contextualLayersOptions = useSelector((state: RootState) => state.dataOptions.contextLayerOptions);

  const {
    dataOptions: { ENABLE_CONTEXTUAL_LAYER_TYPE, DISABLE_CONTEXTUAL_LAYER_TYPE }
  } = useDispatch<Dispatch>();

  const isRiskConnectorsPermissionAvailable = usePermission(
    CorePolicies.RISK_CONNECTOR_POLICY
  );
  const isContextualLayersSubscribed = useSelector((state: RootState) => state.commonData.tenantSubscribedProducts)
    ?.findIndex((product) => product.name === "Contextual Map Layers") !== -1;

  // Handlers for switches
  const handleSwitchToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name as ContextualLayerSwitchType;
      const { checked } = event.target;

      const layerType = {
        [ContextualLayerSwitchType.CountryRiskLevel]: ToggleableContextLayers.CountryRiskLevel,
        [ContextualLayerSwitchType.Traffic]: ToggleableContextLayers.Traffic,
        [ContextualLayerSwitchType.Weather]: ToggleableContextLayers.Weather,
        [ContextualLayerSwitchType.Wind]: ToggleableContextLayers.Wind,
        [ContextualLayerSwitchType.Radar]: ToggleableContextLayers.Radar,
        [ContextualLayerSwitchType.Cyclones]: ToggleableContextLayers.Cyclones
      }[name];

      if (checked) {
        ENABLE_CONTEXTUAL_LAYER_TYPE([layerType]);
      } else {
        DISABLE_CONTEXTUAL_LAYER_TYPE([layerType]);
      }

      // If the layerType is Weather, enable/disable all Wind, Radar or Cyclones layers as well respect to is checked or not
      if (layerType === ToggleableContextLayers.Weather) {
        if (checked) {
          weatherLayers.forEach((layer) => ENABLE_CONTEXTUAL_LAYER_TYPE([layer]));
        } else {
          weatherLayers.forEach((layer) => DISABLE_CONTEXTUAL_LAYER_TYPE([layer]));
        }
      }
    },
    [DISABLE_CONTEXTUAL_LAYER_TYPE, ENABLE_CONTEXTUAL_LAYER_TYPE]
  );

  useEffect(() => {
    if (isTimeTravelActive) {
      DISABLE_CONTEXTUAL_LAYER_TYPE(Object.values(ToggleableContextLayers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeTravelActive]);

  return (
    <ContextualLayers
      contextualLayersOptions={ contextualLayersOptions }
      onChangeContextualLayerSwitch={ handleSwitchToggle }
      isRiskConnectorsPermissionAvailable={ isRiskConnectorsPermissionAvailable }
      isContextualLayersSubscribed={ isContextualLayersSubscribed }
      isTimeTravelActive={ isTimeTravelActive }
    />
  );
};

export default ContextualLayersContainer;
