import React, { useCallback, useEffect } from "react";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { Dispatch, RootState } from "@/core/store";
import useZoomIntoFeature from "@/tenant-context/common/hooks/useZoomIntoFeature";

import GlobalSearch from './GlobalSearchBar.component';

export const SEARCH_DEBOUNCE_INTERVAL = 1000;

const GlobalSearchBarContainer: FC = () => {
  const {
    searchCriteria: previousSearchCriteria,
    geoData,
    areResultsLoading
  } = useSelector((state: RootState) => state.search);

  const {
    autocompleteEntries
  } = useSelector((state: RootState) => state.autocomplete);

  const [searchCriteria, setSearchCriteria] = useState(previousSearchCriteria || '');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const zoomIntoFeature = useZoomIntoFeature();

  const {
    search: {
      updateSearchCriteria
    },
    autocomplete: {
      ADD_AUTOCOMPLETE_ENTRY
    }
  } = useDispatch<Dispatch>();

  /**
   * Use debounced updates of global store to improve performance
   */
  const debouncedUpdateSearchCriteria = useDebouncedCallback(
    updateSearchCriteria,
    SEARCH_DEBOUNCE_INTERVAL
  );

  useEffect(() => {
    debouncedUpdateSearchCriteria(searchCriteria);
  }, [searchCriteria, debouncedUpdateSearchCriteria]);

  /**
   * Handle Enter press
   * Wait until the results are loaded
   * Then if only 1 results -> zoom into it
   */
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
    if (event.key !== 'Enter') {
      return;
    }

    setIsSubmitted(true);
    ADD_AUTOCOMPLETE_ENTRY(searchCriteria);
  }, [ADD_AUTOCOMPLETE_ENTRY, searchCriteria]);

  useEffect(() => {
    if (areResultsLoading || !geoData.features.length) {
      return;
    }

    if(geoData.features.length === 1) {
      zoomIntoFeature(geoData.features[0]);
    }
    setIsSubmitted(false);
  }, [isSubmitted, areResultsLoading, geoData, zoomIntoFeature]);

  /**
   * Clear criterias in all stores on clear click
   */
  const handleClearClick = useCallback(() => {
    setSearchCriteria('');
    updateSearchCriteria('');
  }, [updateSearchCriteria]);

  return (
    <GlobalSearch
      onSearchInputChange={ setSearchCriteria }
      searchCriteria={ searchCriteria }
      autocompleteData={ autocompleteEntries }
      searchResultList = { geoData }
      onClearClick={ handleClearClick }
      onKeyDown={ handleKeyDown }
    />
  );
};

export default GlobalSearchBarContainer;
