import { ActionIcon, Box, Flex } from '@mantine/core';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as CloseIcon } from '@/common/icons/circle-xmark-neutral-dark.svg';
import LiveFeedNotification from '@/tenant-context/control-live-feed/components/LiveFeedNotification';
import {
  useToastNotificationStyles
} from '@/tenant-context/control-live-feed/components/ToastNotificationContainer/ToastNotification.styles';
import { FeedNotification } from '@/tenant-context/control-live-feed/types/live-feed';

interface Props {
  notification: FeedNotification;
  indexByNotificationId: (notificationId: string) => number;
  isCollapsed: boolean;
  showMore: () => void;
  totalVisibleNotifications: number;
  onClose: (notificationId: string) => void;
  onCloseAll?: () => void;
}

const ToastNotificationComponent: FC<Props> = ({
  notification,
  indexByNotificationId,
  isCollapsed,
  showMore,
  totalVisibleNotifications,
  onClose,
  onCloseAll
}) => {
  const [ isClosing, setIsClosing ] = useState(notification?.isClosedInDisplayStack || false);
  const [ measuredHeight, setMeasuredHeight ] = useState<number | undefined>(undefined);
  const rootRef = useRef<HTMLDivElement>(null);

  const index = useMemo(
    () => indexByNotificationId(notification.notificationId),
    [ indexByNotificationId, notification.notificationId ]
  );

  const { classes, cx } = useToastNotificationStyles({
    index, isCollapsed, measuredHeight, isClosing: notification.isClosedInDisplayStack || false
  });

  const handleShowMore = useCallback(() => {
    // Won't execute if it's not the first notification or if it's not collapsed or if there's only one notification
    if ((index > 0) || !isCollapsed || (totalVisibleNotifications === 1)) {
      return;
    }

    showMore();
  }, [index, isCollapsed, showMore, totalVisibleNotifications]);

  const handleClose = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (index === 0 && isCollapsed && onCloseAll) {
      onCloseAll();
      return;
    }

    setIsClosing(true);
    onClose(notification.notificationId);
  }, [index, isCollapsed, notification.notificationId, onClose, onCloseAll]);

  useEffect(() => {
    setIsClosing(false);
    if (!rootRef.current) {
      return;
    }
    setMeasuredHeight(rootRef.current.scrollHeight);
  }, [notification, isCollapsed]);

  const refreshMeasuredHeight = useCallback(() => {
    if (!rootRef.current) {
      return;
    }
    setMeasuredHeight(rootRef.current.scrollHeight);
  }, []);

  return (
    <Box
      className={ cx( {
        [classes.notificationRoot]: true,
        [classes.notificationHidden]: isCollapsed && (index > 0),
        [classes.notificationClosing]: isClosing
      }) }
      onClick={ handleShowMore }
      onMouseEnter={ refreshMeasuredHeight }
    >
      <ActionIcon
        variant="subtle"
        className={ classes.closeButton }
        onClick={ handleClose }
      >
        <CloseIcon/>
      </ActionIcon>
      <Box ref={ rootRef } onClick={ refreshMeasuredHeight } className={ classes.notificationContainer }>
        <LiveFeedNotification
          notification={ notification }
        />
      </Box>
      { index === 0 && <Flex direction="column" align="center" className={ classes.dummyNotificationContainer }>
        { totalVisibleNotifications > 1 &&
          <Box className={ cx(classes.dummyNotification, classes.dummyNotificationOne) }/> }
        { totalVisibleNotifications > 2 &&
          <Box className={ cx(classes.dummyNotification, classes.dummyNotificationTwo) }/> }
      </Flex> }
    </Box>
  );
};

export default ToastNotificationComponent;
