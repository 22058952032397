/* eslint-disable react/jsx-no-bind */
import { AxisBottom, AxisLeft, AxisScale } from '@visx/axis';
import { curveStepAfter } from '@visx/curve';
import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { AreaClosed } from '@visx/shape';
import React from 'react';

import { AssetRankingEvent } from "@/tenant-context/common/types/asset-ranking";
import { AssetRankingEventGroup } from '@/tenant-context/control-location-graph/util/groupBy';

// Initialize some variables
const axisColor = '#fff';
const axisBottomTickLabelProps = {
  textAnchor: 'middle' as const,
  fontFamily: 'Arial',
  fontSize: 10,
  fill: axisColor
};
const axisLeftTickLabelProps = {
  dx: '-0.25em',
  dy: '0.25em',
  fontFamily: 'Arial',
  fontSize: 10,
  textAnchor: 'end' as const,
  fill: axisColor
};

// accessors
const getDate = (d: AssetRankingEvent) => new Date(d.rankTime ?? 0);
const getValue = (d: AssetRankingEvent) => d.currentRank;

const MIN_CHART_WIDTH = 10;
const MORE_TICKS_THRESHOLD = 520;
const MORE_TICKS = 10;
const LESS_TICKS = 5;

export default function AreaChart({
  data,
  gradientColor,
  width,
  yMax,
  margin,
  xScale,
  yScale,
  hideBottomAxis = false,
  hideLeftAxis = false,
  top,
  left,
  children
}: {
  data: AssetRankingEventGroup[];
  gradientColor: string;
  xScale: AxisScale<number>;
  yScale: AxisScale<number>;
  width: number;
  yMax: number;
  margin: { top: number; right: number; bottom: number; left: number };
  hideBottomAxis?: boolean;
  hideLeftAxis?: boolean;
  top?: number;
  left?: number;
  children?: React.ReactNode;
}) {
  if (width < MIN_CHART_WIDTH) {
    return null;
  }

  return (
    <Group left={ left || margin.left } top={ top || margin.top }>
      <LinearGradient
        id="gradient"
        from={ gradientColor }
        fromOpacity={ 1 }
        to={ gradientColor }
        toOpacity={ 0.2 }
      />

      { data.map((group) => (
        <AreaClosed<AssetRankingEvent>
          key={ group.serializedKey }
          data={ group.events }
          x={ (d) => xScale(getDate(d)) || 0 }
          y={ (d) => yScale(getValue(d)) || 0 }
          yScale={ yScale }
          strokeWidth={ 1 }
          stroke="url(#gradient)"
          fill="url(#gradient)"
          curve={ curveStepAfter }
        />
      )) }

      { !hideBottomAxis && (
        <AxisBottom
          top={ yMax }
          scale={ xScale }
          numTicks={ width > MORE_TICKS_THRESHOLD ? MORE_TICKS : LESS_TICKS }
          stroke={ axisColor }
          tickStroke={ axisColor }
          tickLabelProps={ () => axisBottomTickLabelProps }
        />
      ) }
      { !hideLeftAxis && (
        <AxisLeft
          scale={ yScale }
          numTicks={ 5 }
          stroke={ axisColor }
          tickStroke={ axisColor }
          tickLabelProps={ () => axisLeftTickLabelProps }
        />
      ) }
      { children }
    </Group>
  );
}