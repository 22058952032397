import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import {
  ToggleableContextLayers
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { Dispatch, RootState } from "@/core/store";

import RankingSettings from "./RankingSettings.component";

const TIME_TRAVEL_DEBOUNCE_INTERVAL = 500;

const RankingSettingsContainer: FC = () => {
  const { timeTravelTargetEpoch } = useSelector(
    (state: RootState) => state.rankingSettings
  );

  const {
    rankingSettings: { timeTravelTo },
    dataOptions: {
      DISABLE_CONTEXTUAL_LAYER_TYPE
    }
  } = useDispatch<Dispatch>();

  const debouncedTimeTravelTo = useDebouncedCallback(
    timeTravelTo,
    TIME_TRAVEL_DEBOUNCE_INTERVAL
  );

  const handleTimeChange = useCallback(
    (timestamp: number | [number, number]) => {
      debouncedTimeTravelTo(timestamp);

      DISABLE_CONTEXTUAL_LAYER_TYPE([ToggleableContextLayers.CountryRiskLevel, ToggleableContextLayers.Traffic]);
    },
    [ DISABLE_CONTEXTUAL_LAYER_TYPE, debouncedTimeTravelTo ]
  );

  return (
    <RankingSettings
      timestampEpoch={ timeTravelTargetEpoch }
      onTimeChange={ handleTimeChange }
    />
  );
};

export default RankingSettingsContainer;
