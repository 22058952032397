import { Text } from "@mantine/core";
import { FC, Fragment } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import AlarmBadge from "@/tenant-context/employee-app-action-response-center/components/ui/AlarmBadge";
import { useMiniProfileEmergencyBlockStyles } from "@/tenant-context/employee-app-action-response-center/components/ui/MiniProfileEmergencyBlock/MiniProfileEmergencyBlock.style";
import { isSomeKindOfEmergency } from "@/tenant-context/employee-app-action-response-center/util/emergency";
import { LWPersonStatus } from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config";

export const lwStatusMap: Partial<Record<LWPersonStatus, {
  title: string,
  riskLevelIndex: number,
  description: string
}>> = {
  [LWPersonStatus.ManualActivatedEmergency]: {
    title: "Manual Alarm Activation!",
    riskLevelIndex: 4,
    description: 'User has manually activated their alarm via their app.'
  },
  [LWPersonStatus.DuressCancellationEmergency]: {
    title: "Duress Alarm Cancellation!",
    riskLevelIndex: 4,
    description: 'Lone Worker has escalated their alarm using their Duress Cancellation Pin.'
  },
  [LWPersonStatus.MissedEndTaskEmergency]: {
    title: "Missed End Of Task",
    riskLevelIndex: 2,
    description: 'User has failed to end or extend their task on time.'
  },
  [LWPersonStatus.MissedCheckInEmergency]: {
    title: "Missed Check In",
    riskLevelIndex: 2,
    description: 'User has failed to check in on time.'
  }
};

type Props = {
  externalStatus?: {status: LWPersonStatus | undefined, isExternal: boolean},
  sectionHeader?: string
}

const MiniProfileEmergencyBlock: FC<Props> = ({
  externalStatus = { status: undefined, isExternal: false },
  sectionHeader
}) => {

  const lwStatus = useSelector((state: RootState) => state.userProfile.lwStatus);

  const isEaArcOpen = useSelector((state: RootState) => state.eaArc.isOpen);
  const isBigProfileOpen = useSelector((state: RootState) => state.profile.isOpen);

  // TODO unify
  const { title, riskLevelIndex, description } =
    lwStatusMap[externalStatus.status ? externalStatus.status : lwStatus as LWPersonStatus] || {
      title: "Emergency",
      riskLevelIndex: 4
    };

  const { classes } = useMiniProfileEmergencyBlockStyles({
    isInBigOverlay: isEaArcOpen || isBigProfileOpen,
    riskLevelIndex
  });

  if (isEaArcOpen ||
    !isSomeKindOfEmergency(externalStatus.status ? externalStatus.status : lwStatus as LWPersonStatus)) {
    return null;
  }

  return (
    <Fragment>
      { sectionHeader && <Text size={ 'sm' } fw={ 'bold' } className={ classes.secondaryText } mb={ 8 }>{ sectionHeader }</Text> }
      <section className={ classes.root }>
        <div className={ classes.alarmBlock }>
          <div className={ classes.alarmLine } />

          <div className={ classes.badge }>
            <AlarmBadge riskLevelIndex={ riskLevelIndex } />
          </div>

          <div>
            <h6 className={ classes.activationType }>{ title }</h6>

            <p className={ classes.description }>
              { description }
            </p>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default MiniProfileEmergencyBlock;
