import { createStyles } from "@mantine/core";

export const useTaskSummaryTimeStyles = createStyles((theme, riskLevelIndex: number) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16
  },

  overdue: {
    color: theme.colors.risk[riskLevelIndex],
    fontWeight: theme.other.fw.bold
  }
}));