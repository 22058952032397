/* eslint-disable react/jsx-props-no-spreading */
import { Radio } from "@mantine/core";
import { Dispatch, FC, SetStateAction, useContext } from "react";

import ModalControlGroup from "@/common/components/ModalControlGroup";
import { TestableComponent } from "@/common/types/testable-component";
import { ProfilePeopleGroupsModalContext } from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/context/ProfilePeopleGroupsModal.context";
import { useProfilePeopleGroupsModalStyles } from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/ProfileGroupsModal.style";
import ProfileGroupsModalTabMapper from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/tabs/ProfileGroupsModalTabMapper.component";
import ProfileLoadingOverlay from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay';

export enum GroupType {
  Existing = 'Existing',
  New = 'New'
}

type Props = {
  groupType: string,
  setGroupType: Dispatch<SetStateAction<string>>
} & TestableComponent

const ProfileGroupsModal: FC<Props> = ({
  groupType,
  setGroupType,
  dataTestId
}) => {
  const { classes } = useProfilePeopleGroupsModalStyles();
  const {
    formControls: {
      register,
      formState:{ isDirty }
    },
    onSubmit
  } = useContext(ProfilePeopleGroupsModalContext);

  return (
    <form onSubmit={ onSubmit }>

      <ProfileLoadingOverlay />

      <Radio.Group
        value={ groupType }
        onChange={ setGroupType }
        className={ classes.radioGroup }
        data-testid={ `${dataTestId}-add-to-radio-group` }
      >
        <Radio
          value={ GroupType.Existing }
          label="Add to an existing group"
          { ...register('group_action') }
        />

        <Radio
          value={ GroupType.New }
          label="Add to a new group"
          { ...register('group_action') }
        />
      </Radio.Group>

      <div className={ classes.tabContent }>
        <ProfileGroupsModalTabMapper
          activeTab={ groupType as GroupType }
          dataTestId={ dataTestId }
        />
      </div>

      <ModalControlGroup dataTestId={ dataTestId }
        primaryButtonDisabled={ !isDirty }
      />
    </form>
  );
};

export default ProfileGroupsModal;