import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { genericColorPalette } from '@/core/styles/mantine/palettes';

export const useOnMapNotificationBoxStyles = createStyles((theme: MantineTheme) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    pointerEvents: 'none'
  },
  content: {
    position: 'relative',
    marginBottom: 24,
    backgroundColor: genericColorPalette.neutral[9],
    border: `1px solid ${theme.other.semantic.border.general.lighter}`,
    padding: 8,
    fontSize: 16,
    transitionDuration: '300ms',
    pointerEvents: 'all',
    '&[hidden]': {
      opacity: 0
    }
  },
  closeButton: {
    position: 'absolute',
    right: -14,
    top: -14,
    backgroundColor: genericColorPalette.neutral[9],
    border: `1px solid ${theme.other.semantic.icon.general.secondary}`,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: genericColorPalette.neutral[8]
    }
  }
}));
