import { createStyles, MantineTheme } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";


export const useMiniProfileStyles = createStyles((theme: MantineTheme, isInBig: boolean) => ({
  emptyText:{
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fw.regular,
    color: theme.colors.neutral[3],
    textAlign: "center",
    padding: 8,
    marginTop: "2rem"
  },
  statusRow:{
    display: "flex",
    flexWrap: "wrap",
    gap: 8
  },
  tooltipWrap:{
    textAlign: "left",
    display: "grid",
    gridGap: 4,
    "& p":{
      display: "flex",
      alignItems: "center",
      color: theme.white,
      "& svg":{
        width: 20,
        height: 20,
        fill: theme.white,
        marginRight: 8
      }
    }
  },
  tooltipBtn:{
    padding: 0,
    fontSize: theme.fontSizes.sm,
    boxShadow: "none !important",
    background: "transparent !important",
    '&:active':{
      boxShadow: "none !important",
      border: "0 !important",
      transform: "translate(0, 0) !important"
    },
    height: "auto"
  },
  dateIcon:{
    width: 24,
    height: 24
  },
  root: {
    width: isInBig ? '385px' : '100%',
    padding: isInBig ? 24 : '0',
    height: isInBig ? '100vh' : '100%',
    backgroundColor: theme.colors.neutral[8]
  },

  actionIcon: {
    marginLeft: 'auto'
  },

  fixedBottomBlock: {
    marginTop: 16
  },

  useAvatarContainer:{
    display:'flex',
    justifyContent:'flex-start',
    gap:'10px',
    fontFamily:theme.fontFamily
  },
  userNameData:{
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1
  },
  profileName:{
    fontFamily:theme.fontFamily,
    fontWeight:theme.other.fw.bold,
    fontSize:theme.headings.sizes.h4.fontSize,
    lineHeight:theme.other.lh.s,
    color:theme.colors.neutral[0],
    wordWrap: "break-word"
  },
  position:{
    marginTop:'8px',
    fontSize:theme.fontSizes.sm,
    lineHeight:theme.lineHeight,
    color:theme.white
  },
  companyName:{
    fontSize:theme.fontSizes.sm,
    color:theme.colors.neutral[5]
  },
  divider:{
    borderTopColor: theme.colors.neutral[6]
  },
  userData:{
    display:'flex',
    justifyContent:'space-between'
  },

  userDataHeader:{
    marginTop:'16px',
    fontSize:theme.fontSizes.xs,
    color:theme.colors.neutral[5],
    lineHeight:theme.lineHeight
  },
  userDataValue:{
    marginTop:'8px',
    marginBottom:'16px',
    fontSize:theme.fontSizes.sm,
    fontWeight:theme.other.fw.regular,
    flexGrow:0
  },
  userDataColumn:{
    display:'flex',
    flexDirection:'column'
  },
  // !
  collapseBtn:{
    marginBottom: '15px',
    paddingLeft: '0px',
    paddingRight: '0px',
    '&:focus-visible': {
      border: `unset !important`
    },
    '&:active': {
      border: `unset !important`
    },

    '&:hover': {
      backgroundColor: 'unset'
    },
    [`.${CSS_KEY}-Button-inner`]: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    [`.${CSS_KEY}-Button-label`]: {
      fontFamily: theme.fontFamily,
      fontWeight: theme.other.fw.bold,
      fontSize: theme.fontSizes.md,
      color: theme.colors.neutral[1],
      lineHeight: theme.other.lh.s
    }
  },
  contactsContainer: {},
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginBlock: '16px'
  },
  contactsRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  locationRow: {
    display: 'flex',
    width: '100%',
    gap: '8px'
  },
  contactAttribute: {
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.lineHeight,
    color: theme.colors.neutral[5],
    display: 'flex',
    gap: '15px',
    alignItems: 'center'

  },
  locationAttribute: {
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.lineHeight,
    color: theme.colors.neutral[5],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '35%'
  },
  contactValue: {
    display: 'flex',
    wordWrap: 'break-word',
    fontFamily: theme.fontFamily,
    fontWeight: theme.other.fw.regular,
    lineHeight: theme.lineHeight,
    color: theme.colors.neutral[0],
    fontSize: theme.fontSizes.md,
    width: '60%',
    alignItems: "center"
  },
  locationIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '5%x',
    marginRight: '16px'
  },
  deviceCollapseContainer: {
    marginTop: '42px'
  },
  contactCollapseContainer: {
    marginTop: '70px'
  },
  viewFullProfileBtn: {
    marginTop: '30px'
  },
  lastKnownloc: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.sm
  },
  sourceInfo: {
    fontSize: theme.fontSizes.sm
  },
  personLocationInfo: {
    display: 'flex',
    marginTop: '16px',
    alignItems: 'flex-start'
  },
  locationIconContainer: {
    width: '40px'
  },
  locationInfoBody: {
    color: theme.other.semantic.accessibility.outer,
    fontSize: theme.fontSizes.sm,
    display: 'flex',
    flexDirection: 'column'
  },
  locationtimer: {
    color: theme.white
  },
  locationPanel: {
    backgroundColor: theme.colors.neutral[9]
  },
  locationContainer: {
    margin: '24px 0px'
  },
  standardMarginTop: {
    marginTop: '24px'
  },
  sinceLocation: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs,
    marginTop: '24px'
  },
  personInfoData: {
    fontSize: theme.fontSizes.sm
  },
  iteneraryView: {
    display: 'flex',
    fontSize: theme.fontSizes.sm,
    justifyContent: 'space-between',
    margin: '16px 0px'
  },
  iteneraryContainer: {
    display: 'flex',
    gap: '12px'
  },
  locationsWrapper: {
    flex: '1'
  },
  itineraryStepperContainer: {
    display: 'flex',
    [`& .${CSS_KEY}-Step-stepWrapper`]: {
      marginRight: '24px'
    }
  },
  singleStep: {
    [`.${CSS_KEY}-Divider-root`]: {
      margin: '0 auto'
    }
  },
  stepper: {
    marginTop: '8px',
    [`& .${CSS_KEY}-Stepper-steps`]:{
      height: '100%',
      [`& .${CSS_KEY}-Stepper-separator`]: {
        '&::after': {
          top: 0,
          left: '50%',
          width: '1px',
          height: '100%',
          backgroundColor: theme.colors.neutral[7]
        }
      },
      [`& .${CSS_KEY}-Stepper-separatorActive`]: {
        '&::after': {
          backgroundColor: theme.other.semantic.accessibility.outer
        }
      }
    }
  },
  stepperStep: {
    marginBottom: 0,
    '& > div':{
      marginBottom: 0
    },
    [`& .${CSS_KEY}-Stepper-stepIcon`]: {
      width: '24px',
      height: '24px',
      minWidth: '24px',
      backgroundColor: theme.colors.neutral[7],
      borderColor: 'transparent',
      '&[data-completed]': {
        borderColor: theme.other.semantic.accessibility.outer,
        backgroundColor: theme.other.semantic.accessibility.outer
      },
      '& svg': {
        fill: theme.colors.neutral[8]
      }
    }
  },
  personInfoHeader: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs
  },
  travelTimeUtc: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.neutral[5],
    marginTop: '6px'
  },
  miniProfileTabs: {
    position: 'relative',
    [`& .${CSS_KEY}-Tabs-tabsList`]: {
      gap: 0
    },
    [`& .${CSS_KEY}-Tabs-tabs`]: {
      width: '50%',
      padding: '16px 11px',
      '&[data-active]': {
        padding: '16px 16px',
        backgroundColor: theme.colors.neutral[8],
        color: theme.colors.neutral[1],
        border: 0,
        borderBottom: `2px solid ${theme.colors.primary[8]}`,
        '&::before': {
          backgroundColor: theme.colors.neutral[6]
        },
        '&:hover:not([data-active])': {
          backgroundColor: theme.colors.neutral[8]
        }
      }
    }
  },
  visitorBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '28px'
  },
  visitorTitle: {
    marginBottom: '8px',
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fw.bold,
    color: theme.colors.neutral[5]
  },
  visitorInfo: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    justifyContent: 'center',
    alignItems: "flex-start",
    gap: '10px',
    backgroundColor: theme.colors.neutral[9],
    minHeight: '158px'
  },
  visitorInfoRequestId: {
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fw.bold,
    color: theme.colors.neutral[2]
  },
  visitorsInfoBox: {
    display: 'flex',
    flex: '1 1',
    gap: '26px'
  },
  visitorInfoSubTitle: {
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.regular,
    color: theme.colors.neutral[6]
  },
  visitorInfoText: {
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fw.regular,
    color: theme.colors.neutral[3]
  }
}));
