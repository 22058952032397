/* eslint-disable import/extensions */
import { FC } from "react";

import TenantRefContext from "../TenantRefContextProvider";


const PlatformContext: FC = ({ children }) => {
  return <TenantRefContext>{ children }</TenantRefContext>;
};

export default PlatformContext;
