/* eslint-disable react/jsx-props-no-spreading */
import { ActionIcon, Text } from "@mantine/core";
import { useId } from "@mantine/hooks";
import { CSSProperties,FC, ReactNode, useCallback, useEffect, useRef } from "react";
import { Anchor, Popup as MapGLPopup } from "react-map-gl";
import { PopupProps } from "react-map-gl/dist/esm/components/popup";

import { usePopupStyles } from "@/common/components/Popup/Popup.styles";
import { ReactComponent as CloseIcon } from "@/common/icons/close.svg";
import { PopupPositionInfo } from "@/tenant-context/visualisation-people/types/people.types";

type Props = {
  backgroundColor?: string,
  popUpClassName?: string,
  popUpStyles?: CSSProperties,
  children: ReactNode,
  title?: string | ReactNode,
  isWithConnectingLine?: boolean,
  padding?: 'sm' | 'md' | string,
  popupPosition?: PopupPositionInfo
} & Omit<PopupProps, "className" | "style" | "children">;

export const Popup: FC<Props> = (props) => {

  const id = useId();
  const mapGLPopupRootClassName = `popup-${id}`;
  const mapGLCloseBtnRef = useRef<HTMLButtonElement | null>(null);

  const { classes, cx } = usePopupStyles({
    backgroundColor: props.backgroundColor,
    padding: props.padding
  });

  /**
  * Gets the position of the popup, finds out if there is screen size available to accomodate the popup, if not position where space is available
  *
  * @remarks
  * popupPosition object needs to be defined to get this adjustment or else the popup with fallback to its default positioning
  *
  * @param props.popupPosition - The object that holds the popup's numeric position, maximum width and height
  * @returns The anchor of the popup in which it needs to appear, returns undefined if no position adjustment is needed
  *
  */

  const calculatePopupPosition = useCallback((): Anchor | undefined => {
    if (props.popupPosition) {
      const remainingWidth = window.innerWidth - props.popupPosition.clientX;
      const remainingHeight = window.innerHeight - props.popupPosition.clientY;
      if (props.popupPosition.popupMaxHeight >= remainingHeight ||
        props.popupPosition.popupMaxHeight >= props.popupPosition.clientY) {
        if (remainingWidth >= props.popupPosition.popupMaxWidth &&
          (remainingHeight >= (props.popupPosition.popupMaxHeight / 2)) &&
          (props.popupPosition.clientY >= (props.popupPosition.popupMaxHeight / 2))) {
          return 'left';
        } else if (props.popupPosition.clientX > props.popupPosition.popupMaxWidth &&
          remainingHeight >= (props.popupPosition.popupMaxHeight / 2) &&
          (props.popupPosition.clientY >= (props.popupPosition.popupMaxHeight / 2))) {
          return 'right';
        } else {
          return undefined;
        }
      }  else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [props.popupPosition]);

  useEffect(() => {
    if (props.closeButton) {
      mapGLCloseBtnRef.current = document.querySelector(`.${mapGLPopupRootClassName} .mapboxgl-popup-close-button`);
    }
  } , [props.closeButton, mapGLPopupRootClassName]);

  const handleClickOnCustomCloseButton = useCallback(() => {
    if (mapGLCloseBtnRef.current) {
      mapGLCloseBtnRef.current.click();
    }
  }, []);

  return (
    <MapGLPopup
      { ...props.popupPosition && calculatePopupPosition() && { anchor:calculatePopupPosition() } }
      closeButton={ false }
      className={ cx(classes.popup, props.popUpClassName, mapGLPopupRootClassName) }
      { ...props }
      style={ {
        ...props.popUpStyles
      } }
    >
      { props.isWithConnectingLine && (<div className={ classes.connectingLine }></div>) }

      { props.title && (
        <Text className={ classes.title } >{ props.title }</Text>
      ) }

      { props.closeButton && (
        <ActionIcon data-custom-close="true" className={ classes.drawerCloseButton } size={ 20 } onClick={ handleClickOnCustomCloseButton }>
          <CloseIcon/>
        </ActionIcon>
      ) }

      { props.children }
    </MapGLPopup>
  );

};
