import { createModel } from "@rematch/core";
import { ViewState } from 'react-map-gl';

import { RootModel } from "@/core/store";
import { GeoPolygon, ViewportBbox } from "@/tenant-context/control-draw/types/draw";

export type BigMapState = {
  currentGeoFences: Array<GeoPolygon>,
  currentViewPort: ViewportBbox
  currentViewState: ViewState | undefined;
  currentBigMapPopup: PopupType | undefined;
  mapStyleUrl: string;
}

export type PopupType = "None" | "MapLocation" |  "Risk" | "PersonLocation";

export const PopupPrecedence: Record<PopupType, number> = {
  "None": 0,
  "MapLocation": 1,
  "Risk": 2,
  "PersonLocation": 3
};

export type MapStyle = 'default' | 'dark' | 'light' | 'streets' | 'satellite';

export const MAP_STYLES_URLS: Record<MapStyle, string> = {
  'default': 'mapbox://styles/smehmood/cl4feh10w000114pyksvp6mu9',
  'dark': 'mapbox://styles/mapbox/dark-v11',
  'light': 'mapbox://styles/mapbox/light-v11',
  'streets': 'mapbox://styles/mapbox/streets-v12',
  'satellite': 'mapbox://styles/mapbox/satellite-streets-v12'
};
const DEFAULT_MAP_STYLE: MapStyle = 'default';

export const bigMap = createModel<RootModel>()({
  state: {
    currentGeoFences: [],
    currentViewPort: [],
    currentViewState: undefined,
    currentBigMapPopup: undefined,
    mapStyleUrl: MAP_STYLES_URLS[DEFAULT_MAP_STYLE]
  } as BigMapState,
  reducers: {
    setCurrentGeoFences(state: BigMapState, payload: Array<GeoPolygon>) {
      return {
        ...state,
        currentGeoFences: payload
      };
    },

    setCurrentViewPort(state: BigMapState, payload: ViewportBbox) {
      return {
        ...state,
        currentViewPort: payload
      };
    },

    setCurrentViewState(state: BigMapState, payload: ViewState) {
      return {
        ...state,
        currentViewState: payload
      };
    },

    setCurrentBigMapPopup(state: BigMapState, payload: PopupType) {
      const precededPopup = payload === "None" ? payload :
        PopupPrecedence[payload] > PopupPrecedence[state.currentBigMapPopup || "None"]
          ? payload : state.currentBigMapPopup;

      return {
        ...state,
        currentBigMapPopup: precededPopup
      };
    },

    setMapStyle(state: BigMapState, payload: MapStyle) {
      return {
        ...state,
        mapStyleUrl: MAP_STYLES_URLS[payload]
      };
    },

    // custom map style, for dev only mode
    setMapUrl(state: BigMapState, mapUrl: string) {
      if (!mapUrl) {
        return state;
      }

      return {
        ...state,
        mapStyleUrl: mapUrl
      };
    }
  }
});
