import { Group } from "@visx/group";
import { FC, useCallback } from "react";

import {
  useRiskTimelineGraphStyles
} from "@/tenant-context/control-risk-timeline/components/RiskTimelineGraph/RiskTimelineGraph.styles";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type Props = {
  id: string;
  left: number;
  groupLeftOffset: number,
  top: number;
  riskItem: RiskAlertEvent;
  riskEventColor: string;
  isFirstRiskItem: boolean;
  onTooltipMouseLeave: () => void;
  onTooltipMouseMove: (event: React.MouseEvent<SVGGElement>, riskItem: RiskAlertEvent,
    left: number, groupLeftOffset: number) => void;
  onRiskAlertClicked: (riskAlert: RiskAlertEvent) => void;
}

export const RiskChristmasTree: FC<Props> = ({
  id,
  left,
  groupLeftOffset,
  top,
  riskItem,
  riskEventColor,
  isFirstRiskItem,
  onTooltipMouseLeave,
  onTooltipMouseMove,
  onRiskAlertClicked
}) => {

  const { classes } = useRiskTimelineGraphStyles();

  const handleTooltipMouseMove = useCallback(
    (event: React.MouseEvent<SVGGElement>) =>
      onTooltipMouseMove(event, riskItem, top, groupLeftOffset),
    [ onTooltipMouseMove, riskItem, top, groupLeftOffset ]
  );

  const handleRiskAlertClicked = useCallback(() => {
    onRiskAlertClicked(riskItem);
  }, [ onRiskAlertClicked, riskItem ]);

  return (
    <svg
      className={ classes.riskChristmasTree }
      key={ id }
      x={ left }
      y={ top }
    >
      { /* Risk event triangle */ }
      <g
        filter="url(#filter0_d_5505_42348)"
        onMouseUp={ handleRiskAlertClicked }
        onMouseLeave={ onTooltipMouseLeave }
        onMouseOver={ handleTooltipMouseMove }
      >
        <path
          d="M13.1242 3.46384C13.5033 2.76922 14.5008 2.76921 14.8798 3.46384L23.1949 18.7007C23.5585 19.3671 23.0762 20.1797 22.3171 20.1797H5.68696C4.92781 20.1797 4.44551 19.3671 4.80916 18.7007L13.1242 3.46384Z"
          fill={ riskEventColor }
        />
      </g>

      { /* Is impacted exclamation mark */ }
      { riskItem.isImpacted ? (
        <Group className="risk-alert-exclamation" left={ 12 } top={ 8 }>
          <path
            d="M1.02838 5.29032C1.02838 5.76575 1.40025 6.14825 1.86248 6.14825C2.32471 6.14825 2.69658 5.76575 2.69658 5.29032V1.28664C2.69658 0.811205 2.32471 0.428711 1.86248 0.428711C1.40025 0.428711 1.02838 0.811205 1.02838 1.28664V5.29032ZM2.97461 8.43606C2.97461 7.80334 2.47763 7.29216 1.86248 7.29216C1.24733 7.29216 0.750345 7.80334 0.750345 8.43606C0.750345 9.06879 1.24733 9.57997 1.86248 9.57997C2.47763 9.57997 2.97461 9.06879 2.97461 8.43606Z"
            fill={ riskItem.json.alert.risk_level.id === '3' ? '#061927' : '#FFFFFF' }
          />
        </Group>
      ) : null }

      { /* Line from XAxis to triangle */ }
      { isFirstRiskItem ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 42L13 20L15 20L15 42L13 42Z"
          fill="#CDD4DB"
        />
      ) : null }

      { /* Shadows */ }
      <defs>
        <filter
          id="filter0_d_5505_42348"
          x="0"
          y="0"
          width="28"
          height="26.5142"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5505_42348"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5505_42348"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
