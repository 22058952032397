import { Flex } from '@mantine/core';
import { FlexProps } from '@mantine/core/lib/Flex/Flex';
import { FC } from 'react';

import { usePageStyles } from '@/tenant-context/common/components/Page/Page.styles';

type Props = {
  gap?: number;
} & FlexProps

const Page: FC<Props> = ({ children, gap, ...others }) => {

  const { classes } = usePageStyles();

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Flex direction="column" className={ classes.bodyRoot } gap={ gap } { ...others }>
      { children }
    </Flex>
  );
};

export default Page;
