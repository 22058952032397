import { InputStylesParams } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

import { ReactComponent as Chevron } from "@/common/icons/Arrows/select-chevron.svg";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import { ThemeComponent } from "@/core/styles/mantine/types";

import { CSS_KEY, primitiveNumbers } from "../constants";
import { getFontSize } from "./Input.style";

const getIconSize = (size: string): number => {
  const iconSizeMap: Record<string, number> = {
    xs: 12,
    sm: 12,
    md: primitiveNumbers['2x'],
    lg: primitiveNumbers['3x'],
    xl: primitiveNumbers['3x']
  };

  return iconSizeMap[size];
};

const getRightIconSize = (size: string): number => {
  const iconSizeMap: Record<string, number> = {
    xs: primitiveNumbers['2x'],
    sm: primitiveNumbers['2x'],
    md: primitiveNumbers['2x'],
    lg: primitiveNumbers['3x'],
    xl: primitiveNumbers['3x']
  };

  return iconSizeMap[size];
};

export const SelectComponentStyle: ThemeComponent = {
  defaultProps: {
    rightSection: <Chevron />,
    size: 'lg'
  },
  styles: (theme: MantineTheme, params: InputStylesParams) => ({
    rightSection: {
      pointerEvents: "none",
      transition: 'all ease-in 200ms',
      right: theme.other.spacing.md,
      '[aria-expanded=true] &': {
        transform: 'rotate(180deg)'
      },
      '& svg': {
        width: getRightIconSize(params.size),
        height: getRightIconSize(params.size)
      }
    },
    root: {
      marginBottom: 24
    },
    wrapper: {
      marginBottom: 0,
      [`& .${CSS_KEY}-Input-icon.${CSS_KEY}-Select-icon`]: {
        '& svg': {
          width: getIconSize(params.size)
        }
      }
    },
    dropdown: {
      backgroundColor: theme.colors.neutral[8],
      border: `1px solid ${theme.colors.neutral[6]}`,
      padding: 0,
      top: 'unset',
      left: 'unset'
    },
    item: {
      borderBottom: `1px solid ${theme.colors.neutral[7]}`,
      borderRadius: 0,
      margin: 0,
      lineHeight: 1.2,
      fontSize: getFontSize(theme, params.size),
      '&:hover, &[data-hovered]': {
        backgroundColor: genericColorPalette.neutral[9] + ' !important'
      },

      '&[data-disabled]':{
        cursor: "not-allowed",
        backgroundColor: theme.colors.neutral[7]
      },

      '&:hover:not([data-disabled]), &[data-hovered]:not([data-disabled])': {
        backgroundColor: genericColorPalette.neutral[9] + ' !important'
      }
    }
  })
};
