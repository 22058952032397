/* eslint-disable react/boolean-prop-naming */
import { Divider, Stepper } from "@mantine/core";
import { FC } from "react";

import { formatMonth } from "@/common/util/format/date";
import ProfileItineraryItem from "@/tenant-context/control-profile/components/ui/ProfileItineraryItem";
import {
  useTravelSegmentWrapperStyles
} from "@/tenant-context/control-profile/components/ui/TravelSegmentWrapper/TravelSegmentWrapper.styles";
import { TravelMethods } from "@/tenant-context/control-profile/types/mini-profile";
import {
  PersonAirTravelDetail,
  PersonHotelTravelDetail
} from "@/tenant-context/visualisation-people/types/people.types";
import { TravelIcon } from "@/tenant-context/visualisation-people/util/TravelIcons";

type Props = {
  location: PersonHotelTravelDetail | PersonAirTravelDetail,
  isCurrentSegment: boolean,
  lastSegment: boolean,
  completedSegment: boolean,
  onCalculateTripProgress: { progress: number; status: "past" | "present" | "future" },
  fromDate: Date,
  toDate: Date
};


const TravelSegmentWrapper: FC<Props> = ({
  location,
  isCurrentSegment,
  lastSegment,
  completedSegment,
  onCalculateTripProgress,
  fromDate,
  toDate
}) => {
  const { classes, cx } = useTravelSegmentWrapperStyles();
  return <div className={ classes.itineraryStepperContainer }>
    <Stepper.Step
      className={ cx(classes.stepperStep, completedSegment ? classes.completedStep : '', isCurrentSegment ? classes.currentStep : '') }
      icon={ <TravelIcon iconName={ location.type }/> }
      completedIcon={ <TravelIcon iconName={ location.type }/> }
    />

    { !lastSegment && <Divider
      orientation="vertical"
      className={ cx(classes.itineraryLine, completedSegment ? classes.itineraryLineCompleted : '', isCurrentSegment ? classes.itineraryLineCurrent : '') }
      size="xs"
      variant={ completedSegment ? "solid" : "dotted" }
    /> }

    <ProfileItineraryItem
      address={ location?.origin_address }
      travelType={ location.type as TravelMethods }
      timeframe={ onCalculateTripProgress.status }
      progressConfigs={ {
        percentCompleted: onCalculateTripProgress.progress,
        indicatorIcon: <TravelIcon iconName={ location.type }/>
      } }
      from={ {
        flagCode: location.origin_country,
        name: location.origin_name,
        date: `${fromDate.getUTCDate()} ${formatMonth(fromDate?.getUTCMonth())} ${fromDate?.getUTCFullYear()}`,
        time: `${fromDate.getUTCHours()}:${fromDate?.getUTCMinutes().toString().padStart(2, "0")} UTC`
      } }
      to={ {
        flagCode: location.destination_country,
        name: location?.destination_name,
        date: `${toDate.getUTCDate()} ${formatMonth(toDate?.getUTCMonth())} ${toDate?.getUTCFullYear()}`,
        time: `${toDate.getUTCHours()}:${toDate?.getUTCMinutes().toString().padStart(2, "0")} UTC`
      } }
    />
  </div>;
};

export default TravelSegmentWrapper;
