import { FC } from "react";

import {
  useVideoProgressBarStyles
} from "@/tenant-context/employee-app-action-response-center/components/ui/VideoProgressBarComponent/VideoProgressBar.styles";

type Props = {
  numberOfVideos: number,
  currentVideoIndex: number,
  currentVideoPercentage: number
}

const VideoProgressBarComponent: FC<Props> = ({
  numberOfVideos,
  currentVideoIndex,
  currentVideoPercentage
}) => {

  const { classes, cx } = useVideoProgressBarStyles({ 
    totalVideos: numberOfVideos
  });

  return (<div className={ classes.wrapper }>
    { Array(numberOfVideos).fill(undefined)
      .map((_: undefined, index: number) => {
        return <div
          key={ index }
          className={ cx({
            [classes.videoItem]: true,
            [classes.currentVideoItem]: index === currentVideoIndex,
            [classes.playedVideoItem]: index < currentVideoIndex
          } ) }>
          <div style={ { width: index === currentVideoIndex ? `${ currentVideoPercentage }%` : '100%' } }></div>
        </div>;
      }) }
  </div>);
};

export default VideoProgressBarComponent;
