import { MAX_RANK } from "@/tenant-context/core/config/ranking";

const MAX_TICK = 99;

export interface NumberValue {
  valueOf(): number;
}

export function formatProbability(probability: NumberValue): string {
  const value = Math.ceil((probability.valueOf() / MAX_RANK) * 100);

  if (value >= MAX_TICK) {
    return '100%';
  }

  return value.toString();
}
