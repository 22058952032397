/* eslint-disable no-magic-numbers, react/jsx-props-no-spreading */
import { useMantineTheme } from "@mantine/core";
import { Group } from "@visx/group";
import { Pie } from "@visx/shape";
import { FC, useCallback, useMemo } from "react";

import AnimatedPie
  from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactedSummaryPieChart/AnimatedPie.component";
import {
  useImpactedSummaryPieChartStyles
} from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactedSummaryPieChart/ImpactedSummaryPieChart.styles";
import {
  defaultPieMargin
} from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactedSummaryPieChart/ImpactedSummaryPieChart.utils";
import {
  ArcImpactStatus,
  ArcPeopleImpactItem,
  ArcPeopleImpactSummary } from "@/tenant-context/control-action-response-center/types/ARC.types";

type Props = {
  summaryData: ArcPeopleImpactSummary;
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  isAnimated?: boolean;
}

const ImpactedSummaryPieChart: FC<Props> = ({
  summaryData,
  width,
  height,
  margin = defaultPieMargin,
  isAnimated = true
}) => {
  const { classes } = useImpactedSummaryPieChartStyles();
  const theme = useMantineTheme();

  const totalCount = useMemo(
    () => summaryData.reduce((acc, { count }) => acc + count, 0),
    [ summaryData ]
  );

  // color scales
  const getStatusColor = useCallback((status: ArcImpactStatus) => {
    const statusColorMap: Record<ArcImpactStatus, string> = {
      SAFE: theme.colors.risk[1],
      NO_RESPONSE: theme.colors.risk[2],
      NOT_SAFE: theme.colors.risk[5],
      OTHER: theme.colors.neutral[4]
    };
    
    return statusColorMap[status];
  }, [ theme.colors.neutral, theme.colors.risk ]);

  // accessor functions
  const getImpactCount = useCallback((d: ArcPeopleImpactItem) => d.count, []);
  const getImpactStatus = useCallback((arc) => arc.data.impactStatus, []);
  const getColor = useCallback((arc) => getStatusColor(arc.data.impactStatus), [ getStatusColor ]);

  if (width < 10) {
    return null;
  }

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 22;

  return (
    <svg width={ width } height={ height }>
      <Group top={ centerY + margin.top } left={ centerX + margin.left }>
        <Pie
          data={ summaryData }
          pieValue={ getImpactCount }
          outerRadius={ radius }
          innerRadius={ radius - donutThickness }
          cornerRadius={ 0 }
          padAngle={ 0.005 }
        >
          { (pie) => (
            <>
              { /*Pie Arcs*/ }
              <AnimatedPie
                { ...pie }
                animate={ isAnimated }
                getKey={ getImpactStatus }
                getColor={ getColor }
              />

              { /*Count text*/ }
              <text
                y="5"
                textAnchor={ "middle" }
                className={ classes.count }
              >
                { totalCount }
              </text>

              { /*People text*/ }
              <text
                x="-25"
                y="30"
                className={ classes.peopleText }
              >
                People
              </text>
            </>
          ) }
        </Pie>
      </Group>
    </svg>
  );

};

export default ImpactedSummaryPieChart;
