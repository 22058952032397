import { createStyles } from "@mantine/core";

export const usePlaybookStyles = createStyles((theme) => ({
  li: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 16,
    maxWidth: '100%',

    '&:not(:last-of-type)': {
      marginBottom: 10
    },

    '&:last-of-type': {
      marginBottom: 18
    }
  },
  newActionLi: {
    position: 'relative'
  },
  input: {
    marginBottom: 0,
    width: '100%'
  },
  ord: {
    width: 16,
    textAlign: 'end'
  },
  title: {
    paddingInline: 16,
    paddingBlock: 12,
    border: `1px solid ${theme.colors.neutral[6]}`,
    display: 'block',
    flexGrow: 1,
    maxWidth: '100%',
    overflow: 'clip',
    overflowWrap: 'break-word'
  },
  saveBtn: {
    position: 'absolute',
    bottom: -(24 + 6),
    right: 0
  }
}));
