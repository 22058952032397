import { createStyles } from "@mantine/core";

export const useVideoThumbnailStyles = createStyles((theme) => ({
  container: {
    all: 'unset',
    height: 'auto',
    width: 'auto',
    backgroundColor: theme.colors.neutral[8],
    borderBottom: `3px solid red`,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    cursor: 'pointer'
  },

  metaData: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: 10,
    maxWidth: 300
  },

  metaDataLabel: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.colors.neutral[5],
    textTransform: 'uppercase'
  },

  metaDataValue: {
    fontWeight: 400,
    fontSize: 16
  },

  poster: {
    width: 300,
    height: 148,
    borderRadius: 10,
    objectFit: 'cover'
  }
}));
