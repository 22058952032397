import { AssetRankingSummary } from "@/tenant-context/common/types/asset-ranking";

export function prepareEvent(
  locationSummary: AssetRankingSummary
) {
  const {
    assetId,
    personId,
    personFirstName,
    personLastName
  } = locationSummary;

  const id = personId ?? assetId;
  const personFullName = `${personFirstName} ${personLastName}`;

  return {
    ...locationSummary,
    personFullName,
    topRank: {
      ...locationSummary.topRank,
      assetId: id,
      personFullName,
      personFirstName,
      personLastName,
      isTopRank: true
    },
    otherRanks: locationSummary.otherRanks.map((rank) => ({
      ...rank,
      assetId: id,
      personFullName,
      personFirstName,
      personLastName,
      isTopRank: false
    }))
  };
}
