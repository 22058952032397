import { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";

const MAX_DEGREES = 360;

export default function useSpinningGlobe() {
  const isGlobe = useSelector((state: RootState) => state.rankingSettings.isGlobe);

  const { AssetLocationSnapshotMap: map } = useMap();

  const [userInteracting, setUserInteracting] = useState(false);

  // At low zooms, complete a revolution every two minutes.
  const secondsPerRevolution = 120;
  // Above zoom level 5, do not rotate.
  const maxSpinZoom = 5;
  // Rotate at intermediate speeds between zoom levels 3 and 5.
  const slowSpinZoom = 3;

  const spinEnabled = true;

  useEffect(() => {
    if (!map || !isGlobe) {
      return;
    }

    function spinGlobe() {
      if (!map) {
        return;
      }

      const zoom = map.getZoom();

      if (userInteracting || zoom >= maxSpinZoom || !spinEnabled) {
        return;
      }

      // eslint-disable-next-line fp/no-let
      let distancePerSecond = MAX_DEGREES / secondsPerRevolution;

      if (zoom > slowSpinZoom) {
        // Slow spinning at higher zooms
        const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom);

        distancePerSecond *= zoomDif;
      }

      const center = map.getCenter();
      center.lng += distancePerSecond;

      // Smoothly animate the map over one second.
      // When this animation is complete, it calls a 'moveend' event.
      map.easeTo({
        center,
        duration: 1000,
        easing: (n) => n
      });
    }

    // Pause spinning on interaction
    const handleInteractionStart = () => {
      setUserInteracting(true);
    };

    const handleInteractionEnd = () => {
      setUserInteracting(false);
      spinGlobe();
    };

    map.on('mousedown', handleInteractionStart);

    // Restart spinning the globe when interaction is complete
    map.on('mouseup', handleInteractionEnd);

    // These events account for cases where the mouse has moved
    // off the map, so 'mouseup' will not be fired.
    map.on('dragend', handleInteractionEnd);
    map.on('pitchend', handleInteractionEnd);
    map.on('rotateend', handleInteractionEnd);

    // When animation is complete, start spinning if there is no ongoing interaction
    map.on('moveend', spinGlobe);

    spinGlobe();

    return () => {
      map.off('mousedown', handleInteractionStart);
      map.off('mouseup', handleInteractionEnd);
      map.off('dragend', handleInteractionEnd);
      map.off('pitchend', handleInteractionEnd);
      map.off('rotateend', handleInteractionEnd);
      map.off('moveend', spinGlobe);
    };
  }, [isGlobe, map, spinEnabled, userInteracting]);
}
