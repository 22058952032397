import { EventData, MapLayerEventType } from 'mapbox-gl';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/core/store';
import GeofenceMapComponent from '@/tenant-context/control-draw/components/GeofenceMap/GeofenceMap.component';

type Props = {
  onDrawingUpdate: (evt: MapLayerEventType & EventData) => void;
  onDrawingDelete: (evt: MapLayerEventType & EventData) => void;
  onDrawingSelected: (evt: MapLayerEventType & EventData) => void;
  onDrawControlCreated?: (mapboxDraw: MapboxDraw) => void;
};

const GeofenceMapContainer: FC<Props> = ({
  onDrawingUpdate,
  onDrawingDelete,
  onDrawingSelected,
  onDrawControlCreated
}) => {
  const currentBigMapViewState = useSelector((state: RootState) => state.bigMap?.currentViewState);

  return (
    <GeofenceMapComponent
      onDrawingUpdate = { onDrawingUpdate }
      onDrawingDelete = { onDrawingDelete }
      onDrawingSelected = { onDrawingSelected }
      onDrawControlCreated = { onDrawControlCreated }
      viewState={ currentBigMapViewState }
    />
  );
};

export default GeofenceMapContainer;
