import { FC, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';

import TagsContainerComponent from '@/common/components/TagsContainer/TagsContainer.component';
import { Dispatch, RootState } from '@/core/store';

type Props = {
  onSelectTag: (index: number) => void
}

const TagsContainerContainer: FC<Props> = ({ onSelectTag }) => {
  const selectedTagList = useSelector((state: RootState) => state.profile.addedProfileTagsListOnPopover);
  const profileTagsListForProfile = useSelector((state: RootState) => state.profile.profileTagsListForProfile);
  const mode = useSelector((state: RootState) => state.profile?.profileTagsPopoverMode);

  const {
    profile: {
      removeFromProfileTagsListForProfileOnPopover
    }
  } = useDispatch<Dispatch>();

  const handleRemove = useCallback((index: number) => {
    removeFromProfileTagsListForProfileOnPopover(index);
  }, [removeFromProfileTagsListForProfileOnPopover]);

  return (
    <TagsContainerComponent
      onSelectTag={ onSelectTag }
      onRemove={ handleRemove }
      selectedTagList={ selectedTagList || [] }
      profileTagsListForProfile={ profileTagsListForProfile || [] }
      mode={ mode }
    />
  );
};

export default TagsContainerContainer;
