import { useContext, useEffect } from "react";
import { MapboxMap, MapRef } from "react-map-gl";

import { BigMapContext } from "@/tenant-context/core/context/BigMap.context";


export default function useMapIcon(
  name: string,
  image: string,
  map: MapboxMap | MapRef | undefined,
  isOverrideIcons:boolean
) {
  const { customIcons } = useContext(BigMapContext);

  useEffect(() => {
    if (!map) {
      return;
    }

    const ensureImage = () => {
      if (customIcons.current.find((icon) => icon.name === name) && !isOverrideIcons) {
        return;
      }

      const customIcon = new Image();
      customIcon.src = image;

      customIcon.onload = () => {
        if (!map.hasImage(name)) {
          map.addImage(name, customIcon);

          customIcons.current.push({
            name: name,
            icon: customIcon
          });
        }
      };
    };

    map.on('styleimageneeded', ensureImage);
    map.on('styleimagemissing', ensureImage);

    ensureImage();
  }, [customIcons, image, isOverrideIcons, map, name]);
}
