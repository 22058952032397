

import { createModel } from "@rematch/core";

import { RootModel } from "@/core/store";
import { ArcImpactStatus } from "@/tenant-context/control-action-response-center/types/ARC.types";

type ArcModelState = {
  isArcOpen: boolean;
  currentCaseId: string | undefined;
  selectedImpactStatus: ArcImpactStatus | undefined;
}

const arcModel = {
  name: 'arc',
  state: {
    isArcOpen: false,
    currentCaseId: undefined,
    selectedImpactStatus: undefined
  } as ArcModelState,
  reducers: {
    SET_IS_ARC_OPEN(
      state: ArcModelState,
      payload: boolean
    ) {
      return {
        ...state,
        isArcOpen: payload
      };
    },

    SET_CURRENT_CASE_ID(
      state: ArcModelState,
      payload: string | undefined
    ) {
      return {
        ...state,
        currentCaseId: payload
      };
    },

    SET_SELECTED_IMPACT_STATUS(
      state: ArcModelState,
      payload: ArcImpactStatus | undefined
    ) {
      return {
        ...state,
        selectedImpactStatus: payload
      };
    }
  }
};

export const arc = createModel<RootModel>()(
  arcModel
);
