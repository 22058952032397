// eslint-disable-next-line no-restricted-imports
import { showNotification as mantineShowNotification } from "@mantine/notifications";
import type { NotificationProps } from "@mantine/notifications/lib/types";
import { ReactNode } from "react";

import { ReactComponent as NotificationCautionIcon } from "@/common/icons/Notification/notification-caution-icon.svg";
import { ReactComponent as NotificationErrorIcon } from "@/common/icons/Notification/notification-error-icon.svg";
import { ReactComponent as NotificationInfoIcon } from "@/common/icons/Notification/notification-info-icon.svg";
import { ReactComponent as NotificationSuccessIcon } from "@/common/icons/Notification/notification-success-icon.svg";

export const showNotification = (props: NotificationProps) => {

  const getIcon = (notificationType: string | undefined, icon: ReactNode | undefined) => {
    switch (notificationType) {
    case 'red':
    case 'error':
      return <NotificationErrorIcon />;
    case 'green':
    case 'success':
      return <NotificationSuccessIcon />;
    case 'yellow':
    case 'warning':
      return <NotificationCautionIcon />;
    case 'blue':
    case 'info':
      return <NotificationInfoIcon />;
    default:
      return icon || null;
    }
  };

  const mappedProps = {
    ...props,
    icon: getIcon(props.color, props.icon)
  };

  mantineShowNotification(mappedProps);
};
