import { createStyles } from "@mantine/core";


type Params = {
  highlightedTraveller?: boolean;
}

export const useTravellerInfoCardStyles = createStyles((theme, params: Params) => ({
  card: {
    all: 'unset',
    padding: '16px 8px 16px 16px',
    marginBottom: 16,
    backgroundColor: params.highlightedTraveller? theme.colors.neutral[7] :  theme.colors.neutral[9],
    border: params.highlightedTraveller? `1px solid ${theme.colors.neutral[6]}` : `1px solid ${theme.colors.neutral[7]}`,
    cursor: 'pointer',
    boxSizing: 'border-box',
    width: '100%'
  },

  personDetails: {
    display: 'flex',
    alignItems: 'center'
  },

  personSummaryWrapper: {
    marginLeft: 8
  },

  personName: {
    fontWeight: 700
  },

  countryOfResidence: {
    fontSize: 12,
    color: theme.colors.neutral[6],
    lineHeight: theme.other.lh.l,

    '& > b': {
      color: theme.colors.neutral[0],
      fontWeight: 700,
      letterSpacing: .2
    }
  },

  divider: {
    borderTopColor: theme.colors.neutral[7],
    marginTop: 8,
    marginBottom: 8
  },

  infoRow: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: 8
  },

  infoLabel: {
    fontSize: 12,
    color: theme.colors.neutral[5],
    lineHeight: theme.other.lh.l,
    textTransform: 'uppercase'
  },

  infoValue: {
    fontSize: 12,
    lineHeight: theme.other.lh.l
  }
}));
