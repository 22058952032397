import { useMantineTheme } from "@mantine/core";
import { FeatureCollection } from "geojson";
import { FC, RefObject } from "react";
import { Layer, Source } from "react-map-gl";
import { useSelector } from "react-redux";

import { Popup } from "@/common/components/Popup/Popup.components";
import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { RootState } from "@/core/store";
import DynamicallyVisibleLayer from "@/tenant-context/common/components/DynamicallyVisibleLayer";
import { PopupCoordinates } from "@/tenant-context/common/types/popup.types";
import { SITE_INFO_CONTROL_DRAWER_ID } from "@/tenant-context/control-site/controls/SiteInfoControl/SiteInfoControl.component";
import SiteLocationPopup from "@/tenant-context/visualisation-site/components/SiteLocationPopup/SiteLocationPopup.component";
import { SiteLocationSnapshotProperties } from "@/tenant-context/visualisation-site/types/site.types";

type Props = {
  id: string,
  geoData: FeatureCollection,
  isSiteNamePopupShown: boolean,
  isSiteDetailPopupShown: boolean,
  popupCoordinates?: PopupCoordinates,
  popupContent: SiteLocationSnapshotProperties | undefined,
  siteNamePopupRef?: RefObject<HTMLDivElement>,
  siteDetailsPopupRef?: RefObject<HTMLDivElement>,
  siteGeoJson?: FeatureCollection
  musterSiteData?: FeatureCollection
}

const popupOffsetY = 14;

const SitesLocationLayerComponent: FC<Props> = ({
  id,
  geoData,
  isSiteNamePopupShown,
  popupCoordinates,
  popupContent,
  siteGeoJson
}) => {
  const theme = useMantineTheme();
  const openDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenRightDrawerId);


  const sitePeopleCountTextOffset = 0.1;
  const clusterSymbolTextOffset = -0.9;

  return (
    <>
      { siteGeoJson && <Source id="sites-location-outline" type="geojson" data={ siteGeoJson }>
        <Layer
          id="site-outline"
          type="line"
          paint={ {
            "line-color": "#DE5702",
            "line-opacity": 1,
            "line-width": 2
          } }
        />
        <Layer
          id="site-outline-layer"
          type="fill"
          paint={ {
            "fill-color": "#FFD337",
            "fill-opacity": 0
          } }
        />
      </Source> }


      <Source
        id="r__sitesLocationSource"
        type="geojson"
        tolerance={ 0 }
        data={ geoData }
        // cluster={ true }
        // clusterRadius={ 20 }
        // clusterMaxZoom={ 6 }
      >
        { /* TODO clarify this with Product */ }


        { /* Last Mile site clusters */ }
        { /*
      <DynamicallyVisibleLayer
        toggleableLayerType={ ToggleableLayerType.Sites }
        id={ `${id}-cluster` }
        type={ 'symbol' }
        filter={ ['has', 'point_count'] }
        paint={ {
          "text-color": '#FFFFFF'
        } }
        layout={ {
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-image': 'last-mile-site-cluster',
          'icon-size': 0.25,
          'text-field': '{point_count_abbreviated}',
          'text-size': 12,
          // eslint-disable-next-line no-magic-numbers
          'text-offset': [0, 0.3],
          'text-anchor': 'center'
        } }
        source="r__sitesLocationSource"
      />
*/ }
        { /* Non Last Mile site clusters */ }
        { /*
      <DynamicallyVisibleLayer
        toggleableLayerType={ ToggleableLayerType.Sites }
        id={ `${id}-none-last-mile-cluster` }
        type={ 'symbol' }
        filter={ ['all',
          ['!', ['has', 'point_count']],
          ['!=', ['get', 'category'], "LastMile"]
        ] }
        paint={ {
          "text-color": '#FFFFFF'
        } }
        layout={ {
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-image': 'none-last-mile-cluster',
          'icon-size': 0.5,
          // 'text-field': '{point_count_abbreviated}',

          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
          'text-anchor': 'top',
          'text-offset': [0, clusterSymbolTextOffset]
        } }
        source="r__sitesLocationSource"
      />
*/ }

        { /*Last Mile Site Marker*/ }
        { /*{ openDrawerId !== SITE_INFO_CONTROL_DRAWER_ID && <DynamicallyVisibleLayer*/ }
        { /*  toggleableLayerType={ ToggleableLayerType.Sites }*/ }
        { /*  id={ id }*/ }
        { /*  type="symbol"*/ }
        { /*  paint={ {*/ }
        { /*    "text-color": 'white'*/ }
        { /*  } }*/ }
        { /*  layout={ {*/ }
        { /*    'text-allow-overlap': true,*/ }
        { /*    'icon-allow-overlap': true,*/ }
        { /*    'icon-image': 'last-mile-site-marker',*/ }
        { /*    'icon-size': 0.2,*/ }
        { /*    // 'text-field': '{peopleCount}',*/ }
        { /*    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],*/ }
        { /*    'text-size': 9,*/ }
        { /*    'text-anchor': 'top',*/ }
        { /*    'text-offset': [sitePeopleCountTextOffset, clusterSymbolTextOffset + 1]*/ }
        { /*  } }*/ }

        { /*  filter={ ['all',*/ }
        { /*    // ['!', ['has', 'point_count']],*/ }
        { /*    ['==', ['get', 'category'], "LastMile"]*/ }
        { /*  ] }*/ }
        { /*  interactive*/ }
        { /*  source="r__sitesLocationSource"*/ }
        { /*/> }*/ }

        { /*None Last Mile Sites Marker*/ }
        <DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.Locations }
          id={ `${id}-none-last-mile` }
          type={ 'symbol' }
          paint={ {
            "text-color": 'white'
          } }
          layout={ {
            'text-allow-overlap': true,
            'icon-allow-overlap': true,
            'icon-image': 'none-last-mile-sites-marker',
            'icon-size': 0.2,
            // 'text-field': '{peopleCount}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 9,
            'text-anchor': 'top',
            'text-offset': [sitePeopleCountTextOffset, clusterSymbolTextOffset + 1]
          } }
          filter={ ['all',
            // ['!', ['has', 'point_count']],
            ['!=', ['get', 'category'], "LastMile"]
          ] }
          interactive
          source="r__sitesLocationSource"
        />

        { isSiteNamePopupShown && popupCoordinates
          && popupContent && (openDrawerId !== SITE_INFO_CONTROL_DRAWER_ID) && (
          <Popup
            longitude={ popupCoordinates.longitude }
            latitude={ popupCoordinates.latitude }
            /* eslint-disable-next-line no-magic-numbers */
            backgroundColor={ theme.colors.neutral[8] }
            padding={ '0' }
            anchor="top-right"
            offset={ [0, popupOffsetY] }
          >
            <SiteLocationPopup
              popupContent={ popupContent }
            />
          </Popup>
        ) }

      </Source>
    </>
  );

};

export default SitesLocationLayerComponent;
