import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

type Props = {
  isOpenInImpactTab: boolean
}

export const useImpactSummaryStyles = createStyles((theme: MantineTheme, { isOpenInImpactTab }: Props) => ({
  sectionWrapper: {
    display: isOpenInImpactTab ? "flex" : "block",

    '& > div:not(:last-child)': {
      marginRight: isOpenInImpactTab ? 36 : 'auto'
    }
  },
  sectionHeading: {
    fontWeight: theme.other.fw.semibold,
    color: theme.white
  },
  sectionSubHeading: {
    fontSize: theme.fontSizes.sm,
    color: theme.colors.neutral[5],
    marginBottom: isOpenInImpactTab ? 20 : 'auto',

    '& > .emphasis': {
      color: theme.white
    }
  },
  subSectionHeading: {
    fontSize:theme.fontSizes.md,
    color:theme.colors.neutral[5],
    fontFamily:theme.fontFamily,
    marginTop: 40,
    marginBottom: 24,
    textTransform: 'uppercase'
  },

  subSectionHeadingAssets: {
    fontSize:theme.fontSizes.md,
    color:theme.colors.neutral[5],
    fontFamily:theme.fontFamily,
    marginTop: 40,
    marginBottom: 20
  },

  impactedPeopleWrapper: {
    display: 'flex',
    alignItems: 'flex-end',

    '& > *:not(:last-child)': {
      marginRight: 24
    }
  },
  pieChart: {
    width: 176,
    height: 176,
    background: theme.colors.primary[4]
  },
  siteWrapper: {
    display: 'flex',
    flexWrap: 'wrap',

    '& > *:not(:last-child)': {
      marginRight: 10
    }
  }
}));
