/* eslint-disable max-lines */
export const geoTimeZones = 
    [
      {
        "label": "Europe/London UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Europe/London",
        "value": "Europe/London",
        "country": null
      },
      {
        "label": "Europe/Jersey UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Europe/Jersey",
        "value": "Europe/Jersey",
        "country": null
      },
      {
        "label": "Europe/Guernsey UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Europe/Guernsey",
        "value": "Europe/Guernsey",
        "country": null
      },
      {
        "label": "Europe/Isle_of_Man UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Europe/Isle_of_Man",
        "value": "Europe/Isle_of_Man",
        "country": null
      },
      {
        "label": "Africa/Tunis UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Tunis",
        "value": "Africa/Tunis",
        "country": null
      },
      {
        "label": "Africa/Malabo UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Malabo",
        "value": "Africa/Malabo",
        "country": null
      },
      {
        "label": "Africa/Lagos UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Lagos",
        "value": "Africa/Lagos",
        "country": null
      },
      {
        "label": "Africa/Algiers UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Algiers",
        "value": "Africa/Algiers",
        "country": null
      },
      {
        "label": "Africa/Ndjamena UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Ndjamena",
        "value": "Africa/Ndjamena",
        "country": null
      },
      {
        "label": "Atlantic/Faroe UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Atlantic/Faroe",
        "value": "Atlantic/Faroe",
        "country": null
      },
      {
        "label": "Europe/Dublin UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Europe/Dublin",
        "value": "Europe/Dublin",
        "country": null
      },
      {
        "label": "Africa/Libreville UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Libreville",
        "value": "Africa/Libreville",
        "country": null
      },
      {
        "label": "Africa/El_Aaiun UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/El_Aaiun",
        "value": "Africa/El_Aaiun",
        "country": null
      },
      {
        "label": "Africa/Douala UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Douala",
        "value": "Africa/Douala",
        "country": null
      },
      {
        "label": "Africa/Brazzaville UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Brazzaville",
        "value": "Africa/Brazzaville",
        "country": null
      },
      {
        "label": "Africa/Porto-Novo UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Porto-Novo",
        "value": "Africa/Porto-Novo",
        "country": null
      },
      {
        "label": "Atlantic/Madeira UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Atlantic/Madeira",
        "value": "Atlantic/Madeira",
        "country": null
      },
      {
        "label": "Europe/Lisbon UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Europe/Lisbon",
        "value": "Europe/Lisbon",
        "country": null
      },
      {
        "label": "Atlantic/Canary UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Atlantic/Canary",
        "value": "Atlantic/Canary",
        "country": null
      },
      {
        "label": "Africa/Casablanca UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Casablanca",
        "value": "Africa/Casablanca",
        "country": null
      },
      {
        "label": "Africa/Luanda UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Luanda",
        "value": "Africa/Luanda",
        "country": null
      },
      {
        "label": "Africa/Kinshasa UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Kinshasa",
        "value": "Africa/Kinshasa",
        "country": null
      },
      {
        "label": "Africa/Bangui UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Bangui",
        "value": "Africa/Bangui",
        "country": null
      },
      {
        "label": "Africa/Niamey UTC+01:00",
        "offset": "UTC+01:00",
        "timeZone": "Africa/Niamey",
        "value": "Africa/Niamey",
        "country": null
      },
      {
        "label": "Africa/Mbabane UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Mbabane",
        "value": "Africa/Mbabane",
        "country": null
      },
      {
        "label": "Europe/Brussels UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Brussels",
        "value": "Europe/Brussels",
        "country": null
      },
      {
        "label": "Europe/Warsaw UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Warsaw",
        "value": "Europe/Warsaw",
        "country": null
      },
      {
        "label": "Europe/Luxembourg UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Luxembourg",
        "value": "Europe/Luxembourg",
        "country": null
      },
      {
        "label": "Africa/Kigali UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Kigali",
        "value": "Africa/Kigali",
        "country": null
      },
      {
        "label": "Africa/Tripoli UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Tripoli",
        "value": "Africa/Tripoli",
        "country": null
      },
      {
        "label": "Europe/Kaliningrad UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Kaliningrad",
        "value": "Europe/Kaliningrad",
        "country": null
      },
      {
        "label": "Africa/Windhoek UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Windhoek",
        "value": "Africa/Windhoek",
        "country": null
      },
      {
        "label": "Europe/Malta UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Malta",
        "value": "Europe/Malta",
        "country": null
      },
      {
        "label": "Europe/Busingen UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Busingen",
        "value": "Europe/Busingen",
        "country": null
      },
      {
        "label": "Europe/Skopje UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Skopje",
        "value": "Europe/Skopje",
        "country": null
      },
      {
        "label": "Europe/Sarajevo UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Sarajevo",
        "value": "Europe/Sarajevo",
        "country": null
      },
      {
        "label": "Europe/Rome UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Rome",
        "value": "Europe/Rome",
        "country": null
      },
      {
        "label": "Europe/Zurich UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Zurich",
        "value": "Europe/Zurich",
        "country": null
      },
      {
        "label": "Europe/Gibraltar UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Gibraltar",
        "value": "Europe/Gibraltar",
        "country": null
      },
      {
        "label": "Africa/Lubumbashi UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Lubumbashi",
        "value": "Africa/Lubumbashi",
        "country": null
      },
      {
        "label": "Europe/Vaduz UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Vaduz",
        "value": "Europe/Vaduz",
        "country": null
      },
      {
        "label": "Europe/Ljubljana UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Ljubljana",
        "value": "Europe/Ljubljana",
        "country": null
      },
      {
        "label": "Europe/Berlin UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Berlin",
        "value": "Europe/Berlin",
        "country": null
      },
      {
        "label": "Europe/Stockholm UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Stockholm",
        "value": "Europe/Stockholm",
        "country": null
      },
      {
        "label": "Europe/Budapest UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Budapest",
        "value": "Europe/Budapest",
        "country": null
      },
      {
        "label": "Europe/Zagreb UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Zagreb",
        "value": "Europe/Zagreb",
        "country": null
      },
      {
        "label": "Europe/Paris UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Paris",
        "value": "Europe/Paris",
        "country": null
      },
      {
        "label": "Africa/Ceuta UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Ceuta",
        "value": "Africa/Ceuta",
        "country": null
      },
      {
        "label": "Europe/Prague UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Prague",
        "value": "Europe/Prague",
        "country": null
      },
      {
        "label": "Antarctica/Troll UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Antarctica/Troll",
        "value": "Antarctica/Troll",
        "country": null
      },
      {
        "label": "Africa/Gaborone UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Gaborone",
        "value": "Africa/Gaborone",
        "country": null
      },
      {
        "label": "Europe/Copenhagen UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Copenhagen",
        "value": "Europe/Copenhagen",
        "country": null
      },
      {
        "label": "Europe/Vienna UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Vienna",
        "value": "Europe/Vienna",
        "country": null
      },
      {
        "label": "Europe/Tirane UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Tirane",
        "value": "Europe/Tirane",
        "country": null
      },
      {
        "label": "Europe/Amsterdam UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Amsterdam",
        "value": "Europe/Amsterdam",
        "country": null
      },
      {
        "label": "Africa/Maputo UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Maputo",
        "value": "Africa/Maputo",
        "country": null
      },
      {
        "label": "Europe/San_Marino UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/San_Marino",
        "value": "Europe/San_Marino",
        "country": null
      },
      {
        "label": "Europe/Andorra UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Andorra",
        "value": "Europe/Andorra",
        "country": null
      },
      {
        "label": "Europe/Oslo UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Oslo",
        "value": "Europe/Oslo",
        "country": null
      },
      {
        "label": "Europe/Podgorica UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Podgorica",
        "value": "Europe/Podgorica",
        "country": null
      },
      {
        "label": "Africa/Bujumbura UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Bujumbura",
        "value": "Africa/Bujumbura",
        "country": null
      },
      {
        "label": "Africa/Maseru UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Maseru",
        "value": "Africa/Maseru",
        "country": null
      },
      {
        "label": "Europe/Madrid UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Madrid",
        "value": "Europe/Madrid",
        "country": null
      },
      {
        "label": "Africa/Blantyre UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Blantyre",
        "value": "Africa/Blantyre",
        "country": null
      },
      {
        "label": "Africa/Lusaka UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Lusaka",
        "value": "Africa/Lusaka",
        "country": null
      },
      {
        "label": "Africa/Harare UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Harare",
        "value": "Africa/Harare",
        "country": null
      },
      {
        "label": "Africa/Khartoum UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Khartoum",
        "value": "Africa/Khartoum",
        "country": null
      },
      {
        "label": "Africa/Johannesburg UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Johannesburg",
        "value": "Africa/Johannesburg",
        "country": null
      },
      {
        "label": "Europe/Belgrade UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Belgrade",
        "value": "Europe/Belgrade",
        "country": null
      },
      {
        "label": "Africa/Juba UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Africa/Juba",
        "value": "Africa/Juba",
        "country": null
      },
      {
        "label": "Europe/Bratislava UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Bratislava",
        "value": "Europe/Bratislava",
        "country": null
      },
      {
        "label": "Arctic/Longyearbyen UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Arctic/Longyearbyen",
        "value": "Arctic/Longyearbyen",
        "country": null
      },
      {
        "label": "Europe/Vatican UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Vatican",
        "value": "Europe/Vatican",
        "country": null
      },
      {
        "label": "Europe/Monaco UTC+02:00",
        "offset": "UTC+02:00",
        "timeZone": "Europe/Monaco",
        "value": "Europe/Monaco",
        "country": null
      },
      {
        "label": "Asia/Aden UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Aden",
        "value": "Asia/Aden",
        "country": null
      },
      {
        "label": "Africa/Nairobi UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Africa/Nairobi",
        "value": "Africa/Nairobi",
        "country": null
      },
      {
        "label": "Africa/Cairo UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Africa/Cairo",
        "value": "Africa/Cairo",
        "country": null
      },
      {
        "label": "Europe/Istanbul UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Istanbul",
        "value": "Europe/Istanbul",
        "country": null
      },
      {
        "label": "Indian/Comoro UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Indian/Comoro",
        "value": "Indian/Comoro",
        "country": null
      },
      {
        "label": "Antarctica/Syowa UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Antarctica/Syowa",
        "value": "Antarctica/Syowa",
        "country": null
      },
      {
        "label": "Africa/Mogadishu UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Africa/Mogadishu",
        "value": "Africa/Mogadishu",
        "country": null
      },
      {
        "label": "Europe/Bucharest UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Bucharest",
        "value": "Europe/Bucharest",
        "country": null
      },
      {
        "label": "Europe/Mariehamn UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Mariehamn",
        "value": "Europe/Mariehamn",
        "country": null
      },
      {
        "label": "Europe/Moscow UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Moscow",
        "value": "Europe/Moscow",
        "country": null
      },
      {
        "label": "Europe/Chisinau UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Chisinau",
        "value": "Europe/Chisinau",
        "country": null
      },
      {
        "label": "Europe/Helsinki UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Helsinki",
        "value": "Europe/Helsinki",
        "country": null
      },
      {
        "label": "Asia/Beirut UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Beirut",
        "value": "Asia/Beirut",
        "country": null
      },
      {
        "label": "Africa/Djibouti UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Africa/Djibouti",
        "value": "Africa/Djibouti",
        "country": null
      },
      {
        "label": "Europe/Simferopol UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Simferopol",
        "value": "Europe/Simferopol",
        "country": null
      },
      {
        "label": "Europe/Sofia UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Sofia",
        "value": "Europe/Sofia",
        "country": null
      },
      {
        "label": "Asia/Gaza UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Gaza",
        "value": "Asia/Gaza",
        "country": null
      },
      {
        "label": "Africa/Asmara UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Africa/Asmara",
        "value": "Africa/Asmara",
        "country": null
      },
      {
        "label": "Europe/Riga UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Riga",
        "value": "Europe/Riga",
        "country": null
      },
      {
        "label": "Asia/Baghdad UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Baghdad",
        "value": "Asia/Baghdad",
        "country": null
      },
      {
        "label": "Asia/Damascus UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Damascus",
        "value": "Asia/Damascus",
        "country": null
      },
      {
        "label": "Africa/Dar_es_Salaam UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Africa/Dar_es_Salaam",
        "value": "Africa/Dar_es_Salaam",
        "country": null
      },
      {
        "label": "Africa/Addis_Ababa UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Africa/Addis_Ababa",
        "value": "Africa/Addis_Ababa",
        "country": null
      },
      {
        "label": "Asia/Jerusalem UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Jerusalem",
        "value": "Asia/Jerusalem",
        "country": null
      },
      {
        "label": "Asia/Riyadh UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Riyadh",
        "value": "Asia/Riyadh",
        "country": null
      },
      {
        "label": "Asia/Kuwait UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Kuwait",
        "value": "Asia/Kuwait",
        "country": null
      },
      {
        "label": "Europe/Kirov UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Kirov",
        "value": "Europe/Kirov",
        "country": null
      },
      {
        "label": "Africa/Kampala UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Africa/Kampala",
        "value": "Africa/Kampala",
        "country": null
      },
      {
        "label": "Europe/Minsk UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Minsk",
        "value": "Europe/Minsk",
        "country": null
      },
      {
        "label": "Asia/Qatar UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Qatar",
        "value": "Asia/Qatar",
        "country": null
      },
      {
        "label": "Asia/Bahrain UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Bahrain",
        "value": "Asia/Bahrain",
        "country": null
      },
      {
        "label": "Europe/Vilnius UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Vilnius",
        "value": "Europe/Vilnius",
        "country": null
      },
      {
        "label": "Indian/Antananarivo UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Indian/Antananarivo",
        "value": "Indian/Antananarivo",
        "country": null
      },
      {
        "label": "Indian/Mayotte UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Indian/Mayotte",
        "value": "Indian/Mayotte",
        "country": null
      },
      {
        "label": "Europe/Volgograd UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Volgograd",
        "value": "Europe/Volgograd",
        "country": null
      },
      {
        "label": "Europe/Tallinn UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Tallinn",
        "value": "Europe/Tallinn",
        "country": null
      },
      {
        "label": "Europe/Kyiv UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Kyiv",
        "value": "Europe/Kyiv",
        "country": null
      },
      {
        "label": "Asia/Nicosia UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Nicosia",
        "value": "Asia/Nicosia",
        "country": null
      },
      {
        "label": "Asia/Famagusta UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Famagusta",
        "value": "Asia/Famagusta",
        "country": null
      },
      {
        "label": "Asia/Hebron UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Hebron",
        "value": "Asia/Hebron",
        "country": null
      },
      {
        "label": "Asia/Amman UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Asia/Amman",
        "value": "Asia/Amman",
        "country": null
      },
      {
        "label": "Europe/Athens UTC+03:00",
        "offset": "UTC+03:00",
        "timeZone": "Europe/Athens",
        "value": "Europe/Athens",
        "country": null
      },
      {
        "label": "Asia/Tehran UTC+03:30",
        "offset": "UTC+03:30",
        "timeZone": "Asia/Tehran",
        "value": "Asia/Tehran",
        "country": null
      },
      {
        "label": "Asia/Yerevan UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Asia/Yerevan",
        "value": "Asia/Yerevan",
        "country": null
      },
      {
        "label": "Asia/Dubai UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Asia/Dubai",
        "value": "Asia/Dubai",
        "country": null
      },
      {
        "label": "Indian/Reunion UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Indian/Reunion",
        "value": "Indian/Reunion",
        "country": null
      },
      {
        "label": "Indian/Mauritius UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Indian/Mauritius",
        "value": "Indian/Mauritius",
        "country": null
      },
      {
        "label": "Europe/Saratov UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Europe/Saratov",
        "value": "Europe/Saratov",
        "country": null
      },
      {
        "label": "Europe/Samara UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Europe/Samara",
        "value": "Europe/Samara",
        "country": null
      },
      {
        "label": "Indian/Mahe UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Indian/Mahe",
        "value": "Indian/Mahe",
        "country": null
      },
      {
        "label": "Asia/Baku UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Asia/Baku",
        "value": "Asia/Baku",
        "country": null
      },
      {
        "label": "Asia/Muscat UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Asia/Muscat",
        "value": "Asia/Muscat",
        "country": null
      },
      {
        "label": "Europe/Astrakhan UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Europe/Astrakhan",
        "value": "Europe/Astrakhan",
        "country": null
      },
      {
        "label": "Asia/Tbilisi UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Asia/Tbilisi",
        "value": "Asia/Tbilisi",
        "country": null
      },
      {
        "label": "Europe/Ulyanovsk UTC+04:00",
        "offset": "UTC+04:00",
        "timeZone": "Europe/Ulyanovsk",
        "value": "Europe/Ulyanovsk",
        "country": null
      },
      {
        "label": "Asia/Kabul UTC+04:30",
        "offset": "UTC+04:30",
        "timeZone": "Asia/Kabul",
        "value": "Asia/Kabul",
        "country": null
      },
      {
        "label": "Asia/Aqtau UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Asia/Aqtau",
        "value": "Asia/Aqtau",
        "country": null
      },
      {
        "label": "Asia/Samarkand UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Asia/Samarkand",
        "value": "Asia/Samarkand",
        "country": null
      },
      {
        "label": "Asia/Karachi UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Asia/Karachi",
        "value": "Asia/Karachi",
        "country": null
      },
      {
        "label": "Asia/Yekaterinburg UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Asia/Yekaterinburg",
        "value": "Asia/Yekaterinburg",
        "country": null
      },
      {
        "label": "Asia/Dushanbe UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Asia/Dushanbe",
        "value": "Asia/Dushanbe",
        "country": null
      },
      {
        "label": "Indian/Maldives UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Indian/Maldives",
        "value": "Indian/Maldives",
        "country": null
      },
      {
        "label": "Asia/Oral UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Asia/Oral",
        "value": "Asia/Oral",
        "country": null
      },
      {
        "label": "Asia/Tashkent UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Asia/Tashkent",
        "value": "Asia/Tashkent",
        "country": null
      },
      {
        "label": "Antarctica/Mawson UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Antarctica/Mawson",
        "value": "Antarctica/Mawson",
        "country": null
      },
      {
        "label": "Asia/Qyzylorda UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Asia/Qyzylorda",
        "value": "Asia/Qyzylorda",
        "country": null
      },
      {
        "label": "Asia/Aqtobe UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Asia/Aqtobe",
        "value": "Asia/Aqtobe",
        "country": null
      },
      {
        "label": "Asia/Ashgabat UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Asia/Ashgabat",
        "value": "Asia/Ashgabat",
        "country": null
      },
      {
        "label": "Asia/Atyrau UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Asia/Atyrau",
        "value": "Asia/Atyrau",
        "country": null
      },
      {
        "label": "Indian/Kerguelen UTC+05:00",
        "offset": "UTC+05:00",
        "timeZone": "Indian/Kerguelen",
        "value": "Indian/Kerguelen",
        "country": null
      },
      {
        "label": "Asia/Kolkata UTC+05:30",
        "offset": "UTC+05:30",
        "timeZone": "Asia/Kolkata",
        "value": "Asia/Kolkata",
        "country": null
      },
      {
        "label": "Asia/Colombo UTC+05:30",
        "offset": "UTC+05:30",
        "timeZone": "Asia/Colombo",
        "value": "Asia/Colombo",
        "country": null
      },
      {
        "label": "Asia/Kathmandu UTC+05:45",
        "offset": "UTC+05:45",
        "timeZone": "Asia/Kathmandu",
        "value": "Asia/Kathmandu",
        "country": null
      },
      {
        "label": "Asia/Almaty UTC+06:00",
        "offset": "UTC+06:00",
        "timeZone": "Asia/Almaty",
        "value": "Asia/Almaty",
        "country": null
      },
      {
        "label": "Asia/Omsk UTC+06:00",
        "offset": "UTC+06:00",
        "timeZone": "Asia/Omsk",
        "value": "Asia/Omsk",
        "country": null
      },
      {
        "label": "Asia/Dhaka UTC+06:00",
        "offset": "UTC+06:00",
        "timeZone": "Asia/Dhaka",
        "value": "Asia/Dhaka",
        "country": null
      },
      {
        "label": "Indian/Chagos UTC+06:00",
        "offset": "UTC+06:00",
        "timeZone": "Indian/Chagos",
        "value": "Indian/Chagos",
        "country": null
      },
      {
        "label": "Asia/Qostanay UTC+06:00",
        "offset": "UTC+06:00",
        "timeZone": "Asia/Qostanay",
        "value": "Asia/Qostanay",
        "country": null
      },
      {
        "label": "Asia/Bishkek UTC+06:00",
        "offset": "UTC+06:00",
        "timeZone": "Asia/Bishkek",
        "value": "Asia/Bishkek",
        "country": null
      },
      {
        "label": "Antarctica/Vostok UTC+06:00",
        "offset": "UTC+06:00",
        "timeZone": "Antarctica/Vostok",
        "value": "Antarctica/Vostok",
        "country": null
      },
      {
        "label": "Asia/Urumqi UTC+06:00",
        "offset": "UTC+06:00",
        "timeZone": "Asia/Urumqi",
        "value": "Asia/Urumqi",
        "country": null
      },
      {
        "label": "Asia/Thimphu UTC+06:00",
        "offset": "UTC+06:00",
        "timeZone": "Asia/Thimphu",
        "value": "Asia/Thimphu",
        "country": null
      },
      {
        "label": "Asia/Yangon UTC+06:30",
        "offset": "UTC+06:30",
        "timeZone": "Asia/Yangon",
        "value": "Asia/Yangon",
        "country": null
      },
      {
        "label": "Indian/Cocos UTC+06:30",
        "offset": "UTC+06:30",
        "timeZone": "Indian/Cocos",
        "value": "Indian/Cocos",
        "country": null
      },
      {
        "label": "Asia/Pontianak UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Pontianak",
        "value": "Asia/Pontianak",
        "country": null
      },
      {
        "label": "Asia/Phnom_Penh UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Phnom_Penh",
        "value": "Asia/Phnom_Penh",
        "country": null
      },
      {
        "label": "Asia/Novosibirsk UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Novosibirsk",
        "value": "Asia/Novosibirsk",
        "country": null
      },
      {
        "label": "Antarctica/Davis UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Antarctica/Davis",
        "value": "Antarctica/Davis",
        "country": null
      },
      {
        "label": "Asia/Tomsk UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Tomsk",
        "value": "Asia/Tomsk",
        "country": null
      },
      {
        "label": "Asia/Jakarta UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Jakarta",
        "value": "Asia/Jakarta",
        "country": null
      },
      {
        "label": "Asia/Barnaul UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Barnaul",
        "value": "Asia/Barnaul",
        "country": null
      },
      {
        "label": "Indian/Christmas UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Indian/Christmas",
        "value": "Indian/Christmas",
        "country": null
      },
      {
        "label": "Asia/Ho_Chi_Minh UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Ho_Chi_Minh",
        "value": "Asia/Ho_Chi_Minh",
        "country": null
      },
      {
        "label": "Asia/Hovd UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Hovd",
        "value": "Asia/Hovd",
        "country": null
      },
      {
        "label": "Asia/Bangkok UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Bangkok",
        "value": "Asia/Bangkok",
        "country": null
      },
      {
        "label": "Asia/Vientiane UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Vientiane",
        "value": "Asia/Vientiane",
        "country": null
      },
      {
        "label": "Asia/Novokuznetsk UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Novokuznetsk",
        "value": "Asia/Novokuznetsk",
        "country": null
      },
      {
        "label": "Asia/Krasnoyarsk UTC+07:00",
        "offset": "UTC+07:00",
        "timeZone": "Asia/Krasnoyarsk",
        "value": "Asia/Krasnoyarsk",
        "country": null
      },
      {
        "label": "Asia/Kuching UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Kuching",
        "value": "Asia/Kuching",
        "country": null
      },
      {
        "label": "Australia/Perth UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Australia/Perth",
        "value": "Australia/Perth",
        "country": null
      },
      {
        "label": "Asia/Macau UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Macau",
        "value": "Asia/Macau",
        "country": null
      },
      {
        "label": "Asia/Choibalsan UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Choibalsan",
        "value": "Asia/Choibalsan",
        "country": null
      },
      {
        "label": "Asia/Shanghai UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Shanghai",
        "value": "Asia/Shanghai",
        "country": null
      },
      {
        "label": "Asia/Ulaanbaatar UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Ulaanbaatar",
        "value": "Asia/Ulaanbaatar",
        "country": null
      },
      {
        "label": "Asia/Taipei UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Taipei",
        "value": "Asia/Taipei",
        "country": null
      },
      {
        "label": "Asia/Manila UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Manila",
        "value": "Asia/Manila",
        "country": null
      },
      {
        "label": "Asia/Brunei UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Brunei",
        "value": "Asia/Brunei",
        "country": null
      },
      {
        "label": "Asia/Hong_Kong UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Hong_Kong",
        "value": "Asia/Hong_Kong",
        "country": null
      },
      {
        "label": "Asia/Makassar UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Makassar",
        "value": "Asia/Makassar",
        "country": null
      },
      {
        "label": "Asia/Kuala_Lumpur UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Kuala_Lumpur",
        "value": "Asia/Kuala_Lumpur",
        "country": null
      },
      {
        "label": "Asia/Irkutsk UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Irkutsk",
        "value": "Asia/Irkutsk",
        "country": null
      },
      {
        "label": "Asia/Singapore UTC+08:00",
        "offset": "UTC+08:00",
        "timeZone": "Asia/Singapore",
        "value": "Asia/Singapore",
        "country": null
      },
      {
        "label": "Australia/Eucla UTC+08:45",
        "offset": "UTC+08:45",
        "timeZone": "Australia/Eucla",
        "value": "Australia/Eucla",
        "country": null
      },
      {
        "label": "Pacific/Palau UTC+09:00",
        "offset": "UTC+09:00",
        "timeZone": "Pacific/Palau",
        "value": "Pacific/Palau",
        "country": null
      },
      {
        "label": "Asia/Chita UTC+09:00",
        "offset": "UTC+09:00",
        "timeZone": "Asia/Chita",
        "value": "Asia/Chita",
        "country": null
      },
      {
        "label": "Asia/Dili UTC+09:00",
        "offset": "UTC+09:00",
        "timeZone": "Asia/Dili",
        "value": "Asia/Dili",
        "country": null
      },
      {
        "label": "Asia/Jayapura UTC+09:00",
        "offset": "UTC+09:00",
        "timeZone": "Asia/Jayapura",
        "value": "Asia/Jayapura",
        "country": null
      },
      {
        "label": "Asia/Yakutsk UTC+09:00",
        "offset": "UTC+09:00",
        "timeZone": "Asia/Yakutsk",
        "value": "Asia/Yakutsk",
        "country": null
      },
      {
        "label": "Asia/Pyongyang UTC+09:00",
        "offset": "UTC+09:00",
        "timeZone": "Asia/Pyongyang",
        "value": "Asia/Pyongyang",
        "country": null
      },
      {
        "label": "Asia/Seoul UTC+09:00",
        "offset": "UTC+09:00",
        "timeZone": "Asia/Seoul",
        "value": "Asia/Seoul",
        "country": null
      },
      {
        "label": "Asia/Khandyga UTC+09:00",
        "offset": "UTC+09:00",
        "timeZone": "Asia/Khandyga",
        "value": "Asia/Khandyga",
        "country": null
      },
      {
        "label": "Asia/Tokyo UTC+09:00",
        "offset": "UTC+09:00",
        "timeZone": "Asia/Tokyo",
        "value": "Asia/Tokyo",
        "country": null
      },
      {
        "label": "Australia/Adelaide UTC+09:30",
        "offset": "UTC+09:30",
        "timeZone": "Australia/Adelaide",
        "value": "Australia/Adelaide",
        "country": null
      },
      {
        "label": "Australia/Broken_Hill UTC+09:30",
        "offset": "UTC+09:30",
        "timeZone": "Australia/Broken_Hill",
        "value": "Australia/Broken_Hill",
        "country": null
      },
      {
        "label": "Australia/Darwin UTC+09:30",
        "offset": "UTC+09:30",
        "timeZone": "Australia/Darwin",
        "value": "Australia/Darwin",
        "country": null
      },
      {
        "label": "Australia/Hobart UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Australia/Hobart",
        "value": "Australia/Hobart",
        "country": null
      },
      {
        "label": "Pacific/Port_Moresby UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Pacific/Port_Moresby",
        "value": "Pacific/Port_Moresby",
        "country": null
      },
      {
        "label": "Antarctica/Macquarie UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Antarctica/Macquarie",
        "value": "Antarctica/Macquarie",
        "country": null
      },
      {
        "label": "Pacific/Chuuk UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Pacific/Chuuk",
        "value": "Pacific/Chuuk",
        "country": null
      },
      {
        "label": "Pacific/Guam UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Pacific/Guam",
        "value": "Pacific/Guam",
        "country": null
      },
      {
        "label": "Asia/Vladivostok UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Asia/Vladivostok",
        "value": "Asia/Vladivostok",
        "country": null
      },
      {
        "label": "Pacific/Saipan UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Pacific/Saipan",
        "value": "Pacific/Saipan",
        "country": null
      },
      {
        "label": "Antarctica/DumontDUrville UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Antarctica/DumontDUrville",
        "value": "Antarctica/DumontDUrville",
        "country": null
      },
      {
        "label": "Australia/Sydney UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Australia/Sydney",
        "value": "Australia/Sydney",
        "country": null
      },
      {
        "label": "Australia/Brisbane UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Australia/Brisbane",
        "value": "Australia/Brisbane",
        "country": null
      },
      {
        "label": "Asia/Ust-Nera UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Asia/Ust-Nera",
        "value": "Asia/Ust-Nera",
        "country": null
      },
      {
        "label": "Australia/Melbourne UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Australia/Melbourne",
        "value": "Australia/Melbourne",
        "country": null
      },
      {
        "label": "Australia/Lindeman UTC+10:00",
        "offset": "UTC+10:00",
        "timeZone": "Australia/Lindeman",
        "value": "Australia/Lindeman",
        "country": null
      },
      {
        "label": "Australia/Lord_Howe UTC+10:30",
        "offset": "UTC+10:30",
        "timeZone": "Australia/Lord_Howe",
        "value": "Australia/Lord_Howe",
        "country": null
      },
      {
        "label": "Pacific/Bougainville UTC+11:00",
        "offset": "UTC+11:00",
        "timeZone": "Pacific/Bougainville",
        "value": "Pacific/Bougainville",
        "country": null
      },
      {
        "label": "Antarctica/Casey UTC+11:00",
        "offset": "UTC+11:00",
        "timeZone": "Antarctica/Casey",
        "value": "Antarctica/Casey",
        "country": null
      },
      {
        "label": "Pacific/Pohnpei UTC+11:00",
        "offset": "UTC+11:00",
        "timeZone": "Pacific/Pohnpei",
        "value": "Pacific/Pohnpei",
        "country": null
      },
      {
        "label": "Pacific/Efate UTC+11:00",
        "offset": "UTC+11:00",
        "timeZone": "Pacific/Efate",
        "value": "Pacific/Efate",
        "country": null
      },
      {
        "label": "Pacific/Norfolk UTC+11:00",
        "offset": "UTC+11:00",
        "timeZone": "Pacific/Norfolk",
        "value": "Pacific/Norfolk",
        "country": null
      },
      {
        "label": "Asia/Magadan UTC+11:00",
        "offset": "UTC+11:00",
        "timeZone": "Asia/Magadan",
        "value": "Asia/Magadan",
        "country": null
      },
      {
        "label": "Pacific/Kosrae UTC+11:00",
        "offset": "UTC+11:00",
        "timeZone": "Pacific/Kosrae",
        "value": "Pacific/Kosrae",
        "country": null
      },
      {
        "label": "Asia/Sakhalin UTC+11:00",
        "offset": "UTC+11:00",
        "timeZone": "Asia/Sakhalin",
        "value": "Asia/Sakhalin",
        "country": null
      },
      {
        "label": "Pacific/Noumea UTC+11:00",
        "offset": "UTC+11:00",
        "timeZone": "Pacific/Noumea",
        "value": "Pacific/Noumea",
        "country": null
      },
      {
        "label": "Asia/Srednekolymsk UTC+11:00",
        "offset": "UTC+11:00",
        "timeZone": "Asia/Srednekolymsk",
        "value": "Asia/Srednekolymsk",
        "country": null
      },
      {
        "label": "Pacific/Guadalcanal UTC+11:00",
        "offset": "UTC+11:00",
        "timeZone": "Pacific/Guadalcanal",
        "value": "Pacific/Guadalcanal",
        "country": null
      },
      {
        "label": "Pacific/Kwajalein UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Pacific/Kwajalein",
        "value": "Pacific/Kwajalein",
        "country": null
      },
      {
        "label": "Antarctica/McMurdo UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Antarctica/McMurdo",
        "value": "Antarctica/McMurdo",
        "country": null
      },
      {
        "label": "Pacific/Wallis UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Pacific/Wallis",
        "value": "Pacific/Wallis",
        "country": null
      },
      {
        "label": "Pacific/Fiji UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Pacific/Fiji",
        "value": "Pacific/Fiji",
        "country": null
      },
      {
        "label": "Pacific/Funafuti UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Pacific/Funafuti",
        "value": "Pacific/Funafuti",
        "country": null
      },
      {
        "label": "Pacific/Nauru UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Pacific/Nauru",
        "value": "Pacific/Nauru",
        "country": null
      },
      {
        "label": "Pacific/Wake UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Pacific/Wake",
        "value": "Pacific/Wake",
        "country": null
      },
      {
        "label": "Pacific/Tarawa UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Pacific/Tarawa",
        "value": "Pacific/Tarawa",
        "country": null
      },
      {
        "label": "Pacific/Auckland UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Pacific/Auckland",
        "value": "Pacific/Auckland",
        "country": null
      },
      {
        "label": "Asia/Kamchatka UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Asia/Kamchatka",
        "value": "Asia/Kamchatka",
        "country": null
      },
      {
        "label": "Asia/Anadyr UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Asia/Anadyr",
        "value": "Asia/Anadyr",
        "country": null
      },
      {
        "label": "Pacific/Majuro UTC+12:00",
        "offset": "UTC+12:00",
        "timeZone": "Pacific/Majuro",
        "value": "Pacific/Majuro",
        "country": null
      },
      {
        "label": "Pacific/Chatham UTC+12:45",
        "offset": "UTC+12:45",
        "timeZone": "Pacific/Chatham",
        "value": "Pacific/Chatham",
        "country": null
      },
      {
        "label": "Pacific/Fakaofo UTC+13:00",
        "offset": "UTC+13:00",
        "timeZone": "Pacific/Fakaofo",
        "value": "Pacific/Fakaofo",
        "country": null
      },
      {
        "label": "Pacific/Apia UTC+13:00",
        "offset": "UTC+13:00",
        "timeZone": "Pacific/Apia",
        "value": "Pacific/Apia",
        "country": null
      },
      {
        "label": "Pacific/Kanton UTC+13:00",
        "offset": "UTC+13:00",
        "timeZone": "Pacific/Kanton",
        "value": "Pacific/Kanton",
        "country": null
      },
      {
        "label": "Pacific/Tongatapu UTC+13:00",
        "offset": "UTC+13:00",
        "timeZone": "Pacific/Tongatapu",
        "value": "Pacific/Tongatapu",
        "country": null
      },
      {
        "label": "Pacific/Kiritimati UTC+14:00",
        "offset": "UTC+14:00",
        "timeZone": "Pacific/Kiritimati",
        "value": "Pacific/Kiritimati",
        "country": null
      },
      {
        "label": "Atlantic/Cape_Verde UTC-01:00",
        "offset": "UTC-01:00",
        "timeZone": "Atlantic/Cape_Verde",
        "value": "Atlantic/Cape_Verde",
        "country": null
      },
      {
        "label": "America/Nuuk UTC-01:00",
        "offset": "UTC-01:00",
        "timeZone": "America/Nuuk",
        "value": "America/Nuuk",
        "country": null
      },
      {
        "label": "America/Miquelon UTC-02:00",
        "offset": "UTC-02:00",
        "timeZone": "America/Miquelon",
        "value": "America/Miquelon",
        "country": null
      },
      {
        "label": "America/Noronha UTC-02:00",
        "offset": "UTC-02:00",
        "timeZone": "America/Noronha",
        "value": "America/Noronha",
        "country": null
      },
      {
        "label": "Atlantic/South_Georgia UTC-02:00",
        "offset": "UTC-02:00",
        "timeZone": "Atlantic/South_Georgia",
        "value": "Atlantic/South_Georgia",
        "country": null
      },
      {
        "label": "America/St_Johns UTC-02:30",
        "offset": "UTC-02:30",
        "timeZone": "America/St_Johns",
        "value": "America/St_Johns",
        "country": null
      },
      {
        "label": "America/Argentina/Catamarca UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/Catamarca",
        "value": "America/Argentina/Catamarca",
        "country": null
      },
      {
        "label": "America/Argentina/Cordoba UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/Cordoba",
        "value": "America/Argentina/Cordoba",
        "country": null
      },
      {
        "label": "America/Araguaina UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Araguaina",
        "value": "America/Araguaina",
        "country": null
      },
      {
        "label": "America/Argentina/Salta UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/Salta",
        "value": "America/Argentina/Salta",
        "country": null
      },
      {
        "label": "America/Montevideo UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Montevideo",
        "value": "America/Montevideo",
        "country": null
      },
      {
        "label": "America/Argentina/Mendoza UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/Mendoza",
        "value": "America/Argentina/Mendoza",
        "country": null
      },
      {
        "label": "America/Argentina/Rio_Gallegos UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/Rio_Gallegos",
        "value": "America/Argentina/Rio_Gallegos",
        "country": null
      },
      {
        "label": "America/Sao_Paulo UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Sao_Paulo",
        "value": "America/Sao_Paulo",
        "country": null
      },
      {
        "label": "America/Argentina/Jujuy UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/Jujuy",
        "value": "America/Argentina/Jujuy",
        "country": null
      },
      {
        "label": "America/Cayenne UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Cayenne",
        "value": "America/Cayenne",
        "country": null
      },
      {
        "label": "America/Recife UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Recife",
        "value": "America/Recife",
        "country": null
      },
      {
        "label": "America/Paramaribo UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Paramaribo",
        "value": "America/Paramaribo",
        "country": null
      },
      {
        "label": "America/Moncton UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Moncton",
        "value": "America/Moncton",
        "country": null
      },
      {
        "label": "America/Santarem UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Santarem",
        "value": "America/Santarem",
        "country": null
      },
      {
        "label": "Atlantic/Bermuda UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "Atlantic/Bermuda",
        "value": "Atlantic/Bermuda",
        "country": null
      },
      {
        "label": "America/Maceio UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Maceio",
        "value": "America/Maceio",
        "country": null
      },
      {
        "label": "Atlantic/Stanley UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "Atlantic/Stanley",
        "value": "Atlantic/Stanley",
        "country": null
      },
      {
        "label": "America/Halifax UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Halifax",
        "value": "America/Halifax",
        "country": null
      },
      {
        "label": "Antarctica/Rothera UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "Antarctica/Rothera",
        "value": "Antarctica/Rothera",
        "country": null
      },
      {
        "label": "America/Argentina/San_Luis UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/San_Luis",
        "value": "America/Argentina/San_Luis",
        "country": null
      },
      {
        "label": "America/Argentina/Ushuaia UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/Ushuaia",
        "value": "America/Argentina/Ushuaia",
        "country": null
      },
      {
        "label": "Antarctica/Palmer UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "Antarctica/Palmer",
        "value": "Antarctica/Palmer",
        "country": null
      },
      {
        "label": "America/Punta_Arenas UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Punta_Arenas",
        "value": "America/Punta_Arenas",
        "country": null
      },
      {
        "label": "America/Glace_Bay UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Glace_Bay",
        "value": "America/Glace_Bay",
        "country": null
      },
      {
        "label": "America/Fortaleza UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Fortaleza",
        "value": "America/Fortaleza",
        "country": null
      },
      {
        "label": "America/Thule UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Thule",
        "value": "America/Thule",
        "country": null
      },
      {
        "label": "America/Argentina/La_Rioja UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/La_Rioja",
        "value": "America/Argentina/La_Rioja",
        "country": null
      },
      {
        "label": "America/Belem UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Belem",
        "value": "America/Belem",
        "country": null
      },
      {
        "label": "America/Bahia UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Bahia",
        "value": "America/Bahia",
        "country": null
      },
      {
        "label": "America/Goose_Bay UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Goose_Bay",
        "value": "America/Goose_Bay",
        "country": null
      },
      {
        "label": "America/Argentina/San_Juan UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/San_Juan",
        "value": "America/Argentina/San_Juan",
        "country": null
      },
      {
        "label": "America/Argentina/Tucuman UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/Tucuman",
        "value": "America/Argentina/Tucuman",
        "country": null
      },
      {
        "label": "America/Argentina/Buenos_Aires UTC-03:00",
        "offset": "UTC-03:00",
        "timeZone": "America/Argentina/Buenos_Aires",
        "value": "America/Argentina/Buenos_Aires",
        "country": null
      },
      {
        "label": "America/Cuiaba UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Cuiaba",
        "value": "America/Cuiaba",
        "country": null
      },
      {
        "label": "America/Marigot UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Marigot",
        "value": "America/Marigot",
        "country": null
      },
      {
        "label": "America/Indiana/Petersburg UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Indiana/Petersburg",
        "value": "America/Indiana/Petersburg",
        "country": null
      },
      {
        "label": "America/Grand_Turk UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Grand_Turk",
        "value": "America/Grand_Turk",
        "country": null
      },
      {
        "label": "America/Manaus UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Manaus",
        "value": "America/Manaus",
        "country": null
      },
      {
        "label": "America/St_Thomas UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/St_Thomas",
        "value": "America/St_Thomas",
        "country": null
      },
      {
        "label": "America/Anguilla UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Anguilla",
        "value": "America/Anguilla",
        "country": null
      },
      {
        "label": "America/Havana UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Havana",
        "value": "America/Havana",
        "country": null
      },
      {
        "label": "America/Barbados UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Barbados",
        "value": "America/Barbados",
        "country": null
      },
      {
        "label": "America/Curacao UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Curacao",
        "value": "America/Curacao",
        "country": null
      },
      {
        "label": "America/Guyana UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Guyana",
        "value": "America/Guyana",
        "country": null
      },
      {
        "label": "America/Martinique UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Martinique",
        "value": "America/Martinique",
        "country": null
      },
      {
        "label": "America/Puerto_Rico UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Puerto_Rico",
        "value": "America/Puerto_Rico",
        "country": null
      },
      {
        "label": "America/Port_of_Spain UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Port_of_Spain",
        "value": "America/Port_of_Spain",
        "country": null
      },
      {
        "label": "America/Indiana/Vevay UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Indiana/Vevay",
        "value": "America/Indiana/Vevay",
        "country": null
      },
      {
        "label": "America/Indiana/Vincennes UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Indiana/Vincennes",
        "value": "America/Indiana/Vincennes",
        "country": null
      },
      {
        "label": "America/Kralendijk UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Kralendijk",
        "value": "America/Kralendijk",
        "country": null
      },
      {
        "label": "America/Antigua UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Antigua",
        "value": "America/Antigua",
        "country": null
      },
      {
        "label": "America/Iqaluit UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Iqaluit",
        "value": "America/Iqaluit",
        "country": null
      },
      {
        "label": "America/St_Vincent UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/St_Vincent",
        "value": "America/St_Vincent",
        "country": null
      },
      {
        "label": "America/Kentucky/Louisville UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Kentucky/Louisville",
        "value": "America/Kentucky/Louisville",
        "country": null
      },
      {
        "label": "America/Dominica UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Dominica",
        "value": "America/Dominica",
        "country": null
      },
      {
        "label": "America/Asuncion UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Asuncion",
        "value": "America/Asuncion",
        "country": null
      },
      {
        "label": "America/Nassau UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Nassau",
        "value": "America/Nassau",
        "country": null
      },
      {
        "label": "America/Kentucky/Monticello UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Kentucky/Monticello",
        "value": "America/Kentucky/Monticello",
        "country": null
      },
      {
        "label": "America/Aruba UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Aruba",
        "value": "America/Aruba",
        "country": null
      },
      {
        "label": "America/Indiana/Indianapolis UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Indiana/Indianapolis",
        "value": "America/Indiana/Indianapolis",
        "country": null
      },
      {
        "label": "America/Santiago UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Santiago",
        "value": "America/Santiago",
        "country": null
      },
      {
        "label": "America/La_Paz UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/La_Paz",
        "value": "America/La_Paz",
        "country": null
      },
      {
        "label": "America/Indiana/Marengo UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Indiana/Marengo",
        "value": "America/Indiana/Marengo",
        "country": null
      },
      {
        "label": "America/Blanc-Sablon UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Blanc-Sablon",
        "value": "America/Blanc-Sablon",
        "country": null
      },
      {
        "label": "America/Santo_Domingo UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Santo_Domingo",
        "value": "America/Santo_Domingo",
        "country": null
      },
      {
        "label": "America/Port-au-Prince UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Port-au-Prince",
        "value": "America/Port-au-Prince",
        "country": null
      },
      {
        "label": "America/St_Barthelemy UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/St_Barthelemy",
        "value": "America/St_Barthelemy",
        "country": null
      },
      {
        "label": "America/St_Lucia UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/St_Lucia",
        "value": "America/St_Lucia",
        "country": null
      },
      {
        "label": "America/Montserrat UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Montserrat",
        "value": "America/Montserrat",
        "country": null
      },
      {
        "label": "America/Lower_Princes UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Lower_Princes",
        "value": "America/Lower_Princes",
        "country": null
      },
      {
        "label": "America/Detroit UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Detroit",
        "value": "America/Detroit",
        "country": null
      },
      {
        "label": "America/Tortola UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Tortola",
        "value": "America/Tortola",
        "country": null
      },
      {
        "label": "America/Porto_Velho UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Porto_Velho",
        "value": "America/Porto_Velho",
        "country": null
      },
      {
        "label": "America/Campo_Grande UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Campo_Grande",
        "value": "America/Campo_Grande",
        "country": null
      },
      {
        "label": "America/Indiana/Winamac UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Indiana/Winamac",
        "value": "America/Indiana/Winamac",
        "country": null
      },
      {
        "label": "America/Boa_Vista UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Boa_Vista",
        "value": "America/Boa_Vista",
        "country": null
      },
      {
        "label": "America/Grenada UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Grenada",
        "value": "America/Grenada",
        "country": null
      },
      {
        "label": "America/New_York UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/New_York",
        "value": "America/New_York",
        "country": null
      },
      {
        "label": "America/St_Kitts UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/St_Kitts",
        "value": "America/St_Kitts",
        "country": null
      },
      {
        "label": "America/Caracas UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Caracas",
        "value": "America/Caracas",
        "country": null
      },
      {
        "label": "America/Guadeloupe UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Guadeloupe",
        "value": "America/Guadeloupe",
        "country": null
      },
      {
        "label": "America/Toronto UTC-04:00",
        "offset": "UTC-04:00",
        "timeZone": "America/Toronto",
        "value": "America/Toronto",
        "country": null
      },
      {
        "label": "America/Panama UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Panama",
        "value": "America/Panama",
        "country": null
      },
      {
        "label": "America/Chicago UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Chicago",
        "value": "America/Chicago",
        "country": null
      },
      {
        "label": "America/Eirunepe UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Eirunepe",
        "value": "America/Eirunepe",
        "country": null
      },
      {
        "label": "America/Guayaquil UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Guayaquil",
        "value": "America/Guayaquil",
        "country": null
      },
      {
        "label": "America/Rankin_Inlet UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Rankin_Inlet",
        "value": "America/Rankin_Inlet",
        "country": null
      },
      {
        "label": "America/Indiana/Knox UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Indiana/Knox",
        "value": "America/Indiana/Knox",
        "country": null
      },
      {
        "label": "America/North_Dakota/Beulah UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/North_Dakota/Beulah",
        "value": "America/North_Dakota/Beulah",
        "country": null
      },
      {
        "label": "America/Jamaica UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Jamaica",
        "value": "America/Jamaica",
        "country": null
      },
      {
        "label": "America/Atikokan UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Atikokan",
        "value": "America/Atikokan",
        "country": null
      },
      {
        "label": "America/North_Dakota/Center UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/North_Dakota/Center",
        "value": "America/North_Dakota/Center",
        "country": null
      },
      {
        "label": "America/Cayman UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Cayman",
        "value": "America/Cayman",
        "country": null
      },
      {
        "label": "America/Indiana/Tell_City UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Indiana/Tell_City",
        "value": "America/Indiana/Tell_City",
        "country": null
      },
      {
        "label": "America/Ojinaga UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Ojinaga",
        "value": "America/Ojinaga",
        "country": null
      },
      {
        "label": "America/Matamoros UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Matamoros",
        "value": "America/Matamoros",
        "country": null
      },
      {
        "label": "America/Bogota UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Bogota",
        "value": "America/Bogota",
        "country": null
      },
      {
        "label": "America/Menominee UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Menominee",
        "value": "America/Menominee",
        "country": null
      },
      {
        "label": "America/Resolute UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Resolute",
        "value": "America/Resolute",
        "country": null
      },
      {
        "label": "America/Cancun UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Cancun",
        "value": "America/Cancun",
        "country": null
      },
      {
        "label": "America/Lima UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Lima",
        "value": "America/Lima",
        "country": null
      },
      {
        "label": "America/Rio_Branco UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Rio_Branco",
        "value": "America/Rio_Branco",
        "country": null
      },
      {
        "label": "America/North_Dakota/New_Salem UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/North_Dakota/New_Salem",
        "value": "America/North_Dakota/New_Salem",
        "country": null
      },
      {
        "label": "America/Winnipeg UTC-05:00",
        "offset": "UTC-05:00",
        "timeZone": "America/Winnipeg",
        "value": "America/Winnipeg",
        "country": null
      },
      {
        "label": "America/El_Salvador UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/El_Salvador",
        "value": "America/El_Salvador",
        "country": null
      },
      {
        "label": "America/Guatemala UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Guatemala",
        "value": "America/Guatemala",
        "country": null
      },
      {
        "label": "America/Belize UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Belize",
        "value": "America/Belize",
        "country": null
      },
      {
        "label": "America/Managua UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Managua",
        "value": "America/Managua",
        "country": null
      },
      {
        "label": "America/Tegucigalpa UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Tegucigalpa",
        "value": "America/Tegucigalpa",
        "country": null
      },
      {
        "label": "Pacific/Easter UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "Pacific/Easter",
        "value": "Pacific/Easter",
        "country": null
      },
      {
        "label": "America/Regina UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Regina",
        "value": "America/Regina",
        "country": null
      },
      {
        "label": "America/Denver UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Denver",
        "value": "America/Denver",
        "country": null
      },
      {
        "label": "Pacific/Galapagos UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "Pacific/Galapagos",
        "value": "Pacific/Galapagos",
        "country": null
      },
      {
        "label": "America/Swift_Current UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Swift_Current",
        "value": "America/Swift_Current",
        "country": null
      },
      {
        "label": "America/Inuvik UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Inuvik",
        "value": "America/Inuvik",
        "country": null
      },
      {
        "label": "America/Ciudad_Juarez UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Ciudad_Juarez",
        "value": "America/Ciudad_Juarez",
        "country": null
      },
      {
        "label": "America/Boise UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Boise",
        "value": "America/Boise",
        "country": null
      },
      {
        "label": "America/Costa_Rica UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Costa_Rica",
        "value": "America/Costa_Rica",
        "country": null
      },
      {
        "label": "America/Monterrey UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Monterrey",
        "value": "America/Monterrey",
        "country": null
      },
      {
        "label": "America/Chihuahua UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Chihuahua",
        "value": "America/Chihuahua",
        "country": null
      },
      {
        "label": "America/Mexico_City UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Mexico_City",
        "value": "America/Mexico_City",
        "country": null
      },
      {
        "label": "America/Edmonton UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Edmonton",
        "value": "America/Edmonton",
        "country": null
      },
      {
        "label": "America/Bahia_Banderas UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Bahia_Banderas",
        "value": "America/Bahia_Banderas",
        "country": null
      },
      {
        "label": "America/Cambridge_Bay UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Cambridge_Bay",
        "value": "America/Cambridge_Bay",
        "country": null
      },
      {
        "label": "America/Merida UTC-06:00",
        "offset": "UTC-06:00",
        "timeZone": "America/Merida",
        "value": "America/Merida",
        "country": null
      },
      {
        "label": "America/Dawson_Creek UTC-07:00",
        "offset": "UTC-07:00",
        "timeZone": "America/Dawson_Creek",
        "value": "America/Dawson_Creek",
        "country": null
      },
      {
        "label": "America/Mazatlan UTC-07:00",
        "offset": "UTC-07:00",
        "timeZone": "America/Mazatlan",
        "value": "America/Mazatlan",
        "country": null
      },
      {
        "label": "America/Dawson UTC-07:00",
        "offset": "UTC-07:00",
        "timeZone": "America/Dawson",
        "value": "America/Dawson",
        "country": null
      },
      {
        "label": "America/Tijuana UTC-07:00",
        "offset": "UTC-07:00",
        "timeZone": "America/Tijuana",
        "value": "America/Tijuana",
        "country": null
      },
      {
        "label": "America/Creston UTC-07:00",
        "offset": "UTC-07:00",
        "timeZone": "America/Creston",
        "value": "America/Creston",
        "country": null
      },
      {
        "label": "America/Hermosillo UTC-07:00",
        "offset": "UTC-07:00",
        "timeZone": "America/Hermosillo",
        "value": "America/Hermosillo",
        "country": null
      },
      {
        "label": "America/Vancouver UTC-07:00",
        "offset": "UTC-07:00",
        "timeZone": "America/Vancouver",
        "value": "America/Vancouver",
        "country": null
      },
      {
        "label": "America/Phoenix UTC-07:00",
        "offset": "UTC-07:00",
        "timeZone": "America/Phoenix",
        "value": "America/Phoenix",
        "country": null
      },
      {
        "label": "America/Whitehorse UTC-07:00",
        "offset": "UTC-07:00",
        "timeZone": "America/Whitehorse",
        "value": "America/Whitehorse",
        "country": null
      },
      {
        "label": "America/Fort_Nelson UTC-07:00",
        "offset": "UTC-07:00",
        "timeZone": "America/Fort_Nelson",
        "value": "America/Fort_Nelson",
        "country": null
      },
      {
        "label": "America/Los_Angeles UTC-07:00",
        "offset": "UTC-07:00",
        "timeZone": "America/Los_Angeles",
        "value": "America/Los_Angeles",
        "country": null
      },
      {
        "label": "America/Juneau UTC-08:00",
        "offset": "UTC-08:00",
        "timeZone": "America/Juneau",
        "value": "America/Juneau",
        "country": null
      },
      {
        "label": "America/Metlakatla UTC-08:00",
        "offset": "UTC-08:00",
        "timeZone": "America/Metlakatla",
        "value": "America/Metlakatla",
        "country": null
      },
      {
        "label": "America/Yakutat UTC-08:00",
        "offset": "UTC-08:00",
        "timeZone": "America/Yakutat",
        "value": "America/Yakutat",
        "country": null
      },
      {
        "label": "Pacific/Pitcairn UTC-08:00",
        "offset": "UTC-08:00",
        "timeZone": "Pacific/Pitcairn",
        "value": "Pacific/Pitcairn",
        "country": null
      },
      {
        "label": "America/Sitka UTC-08:00",
        "offset": "UTC-08:00",
        "timeZone": "America/Sitka",
        "value": "America/Sitka",
        "country": null
      },
      {
        "label": "America/Anchorage UTC-08:00",
        "offset": "UTC-08:00",
        "timeZone": "America/Anchorage",
        "value": "America/Anchorage",
        "country": null
      },
      {
        "label": "America/Nome UTC-08:00",
        "offset": "UTC-08:00",
        "timeZone": "America/Nome",
        "value": "America/Nome",
        "country": null
      },
      {
        "label": "Pacific/Gambier UTC-09:00",
        "offset": "UTC-09:00",
        "timeZone": "Pacific/Gambier",
        "value": "Pacific/Gambier",
        "country": null
      },
      {
        "label": "America/Adak UTC-09:00",
        "offset": "UTC-09:00",
        "timeZone": "America/Adak",
        "value": "America/Adak",
        "country": null
      },
      {
        "label": "Pacific/Marquesas UTC-09:30",
        "offset": "UTC-09:30",
        "timeZone": "Pacific/Marquesas",
        "value": "Pacific/Marquesas",
        "country": null
      },
      {
        "label": "Pacific/Honolulu UTC-10:00",
        "offset": "UTC-10:00",
        "timeZone": "Pacific/Honolulu",
        "value": "Pacific/Honolulu",
        "country": null
      },
      {
        "label": "Pacific/Rarotonga UTC-10:00",
        "offset": "UTC-10:00",
        "timeZone": "Pacific/Rarotonga",
        "value": "Pacific/Rarotonga",
        "country": null
      },
      {
        "label": "Pacific/Tahiti UTC-10:00",
        "offset": "UTC-10:00",
        "timeZone": "Pacific/Tahiti",
        "value": "Pacific/Tahiti",
        "country": null
      },
      {
        "label": "Pacific/Pago_Pago UTC-11:00",
        "offset": "UTC-11:00",
        "timeZone": "Pacific/Pago_Pago",
        "value": "Pacific/Pago_Pago",
        "country": null
      },
      {
        "label": "Pacific/Niue UTC-11:00",
        "offset": "UTC-11:00",
        "timeZone": "Pacific/Niue",
        "value": "Pacific/Niue",
        "country": null
      },
      {
        "label": "Pacific/Midway UTC-11:00",
        "offset": "UTC-11:00",
        "timeZone": "Pacific/Midway",
        "value": "Pacific/Midway",
        "country": null
      },
      {
        "label": "Atlantic/St_Helena UTCZ",
        "offset": "UTCZ",
        "timeZone": "Atlantic/St_Helena",
        "value": "Atlantic/St_Helena",
        "country": null
      },
      {
        "label": "Africa/Banjul UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Banjul",
        "value": "Africa/Banjul",
        "country": null
      },
      {
        "label": "Africa/Freetown UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Freetown",
        "value": "Africa/Freetown",
        "country": null
      },
      {
        "label": "Africa/Bamako UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Bamako",
        "value": "Africa/Bamako",
        "country": null
      },
      {
        "label": "Africa/Conakry UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Conakry",
        "value": "Africa/Conakry",
        "country": null
      },
      {
        "label": "Africa/Sao_Tome UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Sao_Tome",
        "value": "Africa/Sao_Tome",
        "country": null
      },
      {
        "label": "Africa/Nouakchott UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Nouakchott",
        "value": "Africa/Nouakchott",
        "country": null
      },
      {
        "label": "Atlantic/Azores UTCZ",
        "offset": "UTCZ",
        "timeZone": "Atlantic/Azores",
        "value": "Atlantic/Azores",
        "country": null
      },
      {
        "label": "Africa/Abidjan UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Abidjan",
        "value": "Africa/Abidjan",
        "country": null
      },
      {
        "label": "Africa/Accra UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Accra",
        "value": "Africa/Accra",
        "country": null
      },
      {
        "label": "Africa/Ouagadougou UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Ouagadougou",
        "value": "Africa/Ouagadougou",
        "country": null
      },
      {
        "label": "Atlantic/Reykjavik UTCZ",
        "offset": "UTCZ",
        "timeZone": "Atlantic/Reykjavik",
        "value": "Atlantic/Reykjavik",
        "country": null
      },
      {
        "label": "Africa/Lome UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Lome",
        "value": "Africa/Lome",
        "country": null
      },
      {
        "label": "America/Danmarkshavn UTCZ",
        "offset": "UTCZ",
        "timeZone": "America/Danmarkshavn",
        "value": "America/Danmarkshavn",
        "country": null
      },
      {
        "label": "Africa/Dakar UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Dakar",
        "value": "Africa/Dakar",
        "country": null
      },
      {
        "label": "America/Scoresbysund UTCZ",
        "offset": "UTCZ",
        "timeZone": "America/Scoresbysund",
        "value": "America/Scoresbysund",
        "country": null
      },
      {
        "label": "Africa/Bissau UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Bissau",
        "value": "Africa/Bissau",
        "country": null
      },
      {
        "label": "Africa/Monrovia UTCZ",
        "offset": "UTCZ",
        "timeZone": "Africa/Monrovia",
        "value": "Africa/Monrovia",
        "country": null
      }
    ];