import { ActionIcon, Divider } from "@mantine/core";
import { FC, MouseEventHandler, useCallback } from "react";

import TextTruncate from "@/common/components/TextTruncate";
import { ReactComponent as RightArrowIcon } from "@/common/icons/Arrows/right-arrow.svg";
import { ReactComponent as ChevronLeft } from "@/common/icons/chevron-left.svg";
import { ReactComponent as CircleCheck } from "@/common/icons/circle-check.svg";
import { ReactComponent as CircleX } from "@/common/icons/circle-xmark.svg";
import CountryFlag from "@/tenant-context/common/components/CountryFlag/CountryFlag.component";
import RiskALertStatus from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertStatus/RiskAlertStatus.component";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

import { RiskAlertDetailsStyles } from "./RiskAlertDetails.styles";

type Props = {
  riskAlert?: RiskAlertEvent,
  onRequestToToggleArc: MouseEventHandler<HTMLButtonElement>,
  isArcOpen: boolean;
  isAnotherOverlayModuleOpen: boolean;
  onRequestToCloseComms: MouseEventHandler<HTMLButtonElement>;
}

const BSOCRiskAlertDetails: FC<Props> = ({
  isArcOpen,
  riskAlert,
  isAnotherOverlayModuleOpen,
  onRequestToToggleArc,
  onRequestToCloseComms
}) => {
  const { classes } = RiskAlertDetailsStyles({ isInArc: isArcOpen, isOverlayOpen: isAnotherOverlayModuleOpen });

  const getVicinity = useCallback((ivoInfo: boolean) => {
    return ivoInfo ? (
      <div className={ classes.detailsInfo }>
        <CircleCheck /> &nbsp; Yes
      </div>
    ) : (
      <div className={ classes.detailsInfo }>
        <CircleX /> &nbsp; No
      </div>);
  }, [classes.detailsInfo]);

  return (
    <div className={ classes.container }>
      { isArcOpen && (
        <div className={ classes.toggleArcButton }>
          <ActionIcon size={ 20 } onClick={ onRequestToToggleArc }>
            <RightArrowIcon />
          </ActionIcon>
        </div>
      ) }

      { isAnotherOverlayModuleOpen && (
        <div className={ classes.innerArrow }>
          <ActionIcon size={ 20 } onClick={ onRequestToCloseComms }>
            <ChevronLeft />
          </ActionIcon>
        </div>
      ) }

      <div>
        <div className={ classes.popupAlternativeHeader }>
          { riskAlert?.json.alert.countries[0].iso_code && (
            <CountryFlag
              width={ 54 }
              height={ 36 }
              countryCode={ riskAlert?.json.alert.countries[0].iso_code }
            />
          ) }
          { riskAlert?.json.externalData?.region },
          { riskAlert?.json.externalData?.province }
          <div className={ classes.riskAlertTitle }> {  riskAlert?.json.alert.title }</div>
        </div>

        <RiskALertStatus riskAlertData={ riskAlert } />

        <div className={ classes.incidentTimeInfo }>
          Time of incident &nbsp;
          <div className={ classes.boldIncidentTimeInfo }>
            { riskAlert?.json.externalData?.incident_date }
          </div>
        </div>

        <div className={ classes.sectionHeader }>
          Narrative Report
        </div>

        <Divider className={ classes.sectionHeaderDivider } />

        <div className={ classes.riskDescription }>
          <TextTruncate
            text={ riskAlert?.json.alert.text }
            maxTextLength={ 100 }
          />
        </div>

        <div className={ classes.sectionHeader }>
          Details
        </div>

        <Divider
          className={ classes.sectionHeaderDivider }
        />
        <div className={ classes.detailsContainer }>

          <div className={ classes.detailsSection }>

            <div className={ classes.detailsHeader }>
              SOURCE CODE
            </div>
            <div className={ classes.detailsInfo }>
              { riskAlert?.json.externalData?.source_code }
            </div>
          </div>
          <div className={ classes.detailsSection }>

            <div className={ classes.detailsHeader }>
              IN VICINITY OF
            </div>
            { getVicinity(riskAlert?.json.externalData?.ivo_crit_inf_log === 'YES') }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BSOCRiskAlertDetails;
