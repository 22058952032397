import { createStyles } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";


export const useSummaryTabStyles = createStyles(() => ({
  root: {
    position: 'relative',
    height: '100%'
  },
  taskSummary: {
    zIndex: 2,
    margin: '24px'
  },
  summaryMap: {
    zIndex: 1,
    position: 'absolute',
    width: '100%',
    //
    top: 85,
    height: 'calc(100% - 85px)',
    minHeight: '450px'
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    margin: 12,
    gap: 12,
    backgroundColor: genericColorPalette.neutral[9]
  },
  gridColumn: {
    // Required to make sure the grid items are not stretched
    // To see it in action, remove this style and enter a very long word in playbook
    minWidth: 0
  },
  emergencyVideoWrapper: {
    position: 'absolute',
    top: 100,
    right: 25,
    zIndex: 999
  }
}));
