import { useMantineTheme } from "@mantine/core";
import { FeatureCollection, LineString } from "geojson";
import { FC } from "react";
import { Source } from "react-map-gl";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import DynamicallyVisibleLayer from "@/tenant-context/common/components/DynamicallyVisibleLayer";
import { MAX_RANK } from "@/tenant-context/core/config/ranking";
import { BreadcrumbCollection } from "@/tenant-context/visualisation-people/types/people.types";

type Props = {
  locations: BreadcrumbCollection,
  isEnabled: boolean,
  linesBetweenCrumbs: FeatureCollection<LineString>,
  disabledAdapters: string[],
  minRankThreshold: number,
  maxRankThreshold:number,
  isMinRankThresholdApplied: boolean
};

const PeopleBreadcrumbLayer: FC<Props> = ({
  locations,
  isEnabled,
  linesBetweenCrumbs,
  disabledAdapters,
  minRankThreshold,
  maxRankThreshold,
  isMinRankThresholdApplied
}) => {

  const theme = useMantineTheme();

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <Source
        id="r__possible-locations-lines"
        type="geojson"
        data={ linesBetweenCrumbs }
      >
        <DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.People }
          source='r__possible-locations-lines'
          type="line"
          id="r__possible-locations-lines"
          // TODO dedupe with PeopleLocationLayer (DRY)
          filter={ [
            'all',
            isMinRankThresholdApplied
              ? ['all', 
                [
                  '<=',
                  ['get', 'currentRank', ['get', 'otherRank']],
                  ['literal', maxRankThreshold]
                ],
                [
                  '>=',
                  ['get', 'currentRank', ['get', 'otherRank']],
                  ['literal', minRankThreshold]
                ]
              ]
              : ['literal', true],
            [
              '!', [
                'in',
                ['get', 'adapterSource', ['get', 'otherRank']],
                ['literal', disabledAdapters]
              ]
            ],
            [
              '!', [
                'in',
                ['get', 'adapterSource', ['get', 'topRank']],
                ['literal', disabledAdapters]
              ]
            ]
          ] }
          layout={ {
            visibility: isEnabled ? 'visible' : 'none'
          } }
          paint={ {
            'line-color': theme.white,
            'line-width': 1,
            // eslint-disable-next-line no-magic-numbers
            'line-dasharray': [1, 2]
          } }
        />
      </Source>

      <Source
        id="r__possible-locations"
        type="geojson"
        data={ locations }
      >
        <DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.People }
          source='r__possible-locations'
          id="r__possible-locations-secondary"
          type="symbol"
          paint={ {
            // eslint-disable-next-line no-magic-numbers
            'text-color': theme.colors.neutral[8]
          } }
          layout={ {
            visibility: isEnabled ? 'visible' : 'none',
            'icon-image': 'person-breadcrumb-percentage-marker',
            'icon-size': 0.25,
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': [
              'concat',
              [
                'to-string',
                [
                  'ceil',
                  [
                    '*',
                    [
                      '/',
                      ['get', 'currentRank'],
                      ['literal', MAX_RANK]
                    ],
                    ['literal', 100]
                  ]
                ]
              ]
            ],
            'text-size': 12,
            'text-font': ['Arial Unicode MS Bold'],
            'text-line-height': 1.2,
            // eslint-disable-next-line no-magic-numbers
            'text-offset': [0, -0.6],
            'text-anchor': 'center'
          } }
          filter={ [
            'all',
            isMinRankThresholdApplied
              ? [
                '>=',
                ['get', 'currentRank'],
                ['literal', minRankThreshold]
              ]
              : ['literal', true],
            [
              '!', [
                'in',
                ['get', 'adapterSource'],
                ['literal', disabledAdapters]
              ]
            ],
            [
              '==', ['get', 'isTopRank'], ['literal', false]
            ]
          ] }
        />
      </Source>
    </>
  );
};

export default PeopleBreadcrumbLayer;
