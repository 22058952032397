import { ReactComponent as ActiveShooter } from  '@/common/icons/RiskProviders/MaxSecurityCategories/active_shooter.svg';
import { ReactComponent as AirStrike } from  '@/common/icons/RiskProviders/MaxSecurityCategories/air_strike.svg';
import { ReactComponent as ArbitraryDetention } from  '@/common/icons/RiskProviders/MaxSecurityCategories/arbitrary_detention.svg';
import { ReactComponent as ArmedConflict } from  '@/common/icons/RiskProviders/MaxSecurityCategories/armed_conflict.svg';
import { ReactComponent as CivilUnrest } from  '@/common/icons/RiskProviders/MaxSecurityCategories/civil_unrest.svg';
import { ReactComponent as Crime } from  '@/common/icons/RiskProviders/MaxSecurityCategories/crime.svg';
import { ReactComponent as Cyber } from  '@/common/icons/RiskProviders/MaxSecurityCategories/cyber.svg';
import { ReactComponent as Developing } from  '@/common/icons/RiskProviders/MaxSecurityCategories/developing.svg';
import { ReactComponent as Earthquake } from  '@/common/icons/RiskProviders/MaxSecurityCategories/earthquake.svg';
import { ReactComponent as Economics } from  '@/common/icons/RiskProviders/MaxSecurityCategories/economics.svg';
import { ReactComponent as Fire } from  '@/common/icons/RiskProviders/MaxSecurityCategories/fire.svg';
import { ReactComponent as Health } from  '@/common/icons/RiskProviders/MaxSecurityCategories/health.svg';
import { ReactComponent as Holiday } from  '@/common/icons/RiskProviders/MaxSecurityCategories/holiday.svg';
import { ReactComponent as KidnapAndRansom } from  '@/common/icons/RiskProviders/MaxSecurityCategories/kidnap_and_ransom.svg';
import { ReactComponent as LaborAction } from  '@/common/icons/RiskProviders/MaxSecurityCategories/labor_action.svg';
import { ReactComponent as LargeScaleEvents } from  '@/common/icons/RiskProviders/MaxSecurityCategories/large_scale_events.svg';
import { ReactComponent as MaritimeIncidents } from  '@/common/icons/RiskProviders/MaxSecurityCategories/maritime_incidents.svg';
import { ReactComponent as MilitancyTerrorism } from  '@/common/icons/RiskProviders/MaxSecurityCategories/militancy_terrorism.svg';
import { ReactComponent as OrganisedCrime } from  '@/common/icons/RiskProviders/MaxSecurityCategories/organised_crime.svg';
import { ReactComponent as Piracy } from  '@/common/icons/RiskProviders/MaxSecurityCategories/piracy.svg';
import { ReactComponent as Politics } from  '@/common/icons/RiskProviders/MaxSecurityCategories/politics.svg';
import { ReactComponent as Protest } from  '@/common/icons/RiskProviders/MaxSecurityCategories/protest.svg';
import { ReactComponent as Rocket } from  '@/common/icons/RiskProviders/MaxSecurityCategories/rocket.svg';
import { ReactComponent as SecurityOperations } from  '@/common/icons/RiskProviders/MaxSecurityCategories/security_operations.svg';
import { ReactComponent as SupplyChainDisruptions } from  '@/common/icons/RiskProviders/MaxSecurityCategories/supply_chain_disruptions.svg';
import { ReactComponent as Travel } from  '@/common/icons/RiskProviders/MaxSecurityCategories/travel.svg';
import { ReactComponent as Volcano } from  '@/common/icons/RiskProviders/MaxSecurityCategories/volcano.svg';
import { ReactComponent as Weather } from  '@/common/icons/RiskProviders/MaxSecurityCategories/weather.svg';

export const MAX_SECURITY_RISK_CATEGORIES = [
  {
    id: 'ActiveShooter',
    name: 'Active Shooter',
    Icon: ActiveShooter
  },{
    id: 'AirStrike',
    name: 'Air Strike',
    Icon: AirStrike
  },{
    id: 'ArbitraryDetention',
    name: 'Arbitrary Detention',
    Icon: ArbitraryDetention
  },{
    id: 'ArmedConflict',
    name: 'Armed Conflict',
    Icon: ArmedConflict
  },{
    id: 'CivilUnrest',
    name: 'Civil Unrest',
    Icon: CivilUnrest
  },{
    id: 'Crime',
    name: 'Crime',
    Icon: Crime
  },{
    id: 'Cyber',
    name: 'Cyber',
    Icon: Cyber
  },{
    id: 'Developing',
    name: 'Developing',
    Icon: Developing
  },{
    id: 'Earthquake',
    name: 'Earthquake',
    Icon: Earthquake
  },{
    id: 'Economics',
    name: 'Economics',
    Icon: Economics
  },{
    id: 'Fire',
    name: 'Fire',
    Icon: Fire
  },{
    id: 'Health',
    name: 'Health',
    Icon: Health
  },{
    id: 'Holiday',
    name: 'Holiday',
    Icon: Holiday
  },{
    id: 'KidnapAndRansom',
    name: 'Kidnap And Ransom',
    Icon: KidnapAndRansom
  },{
    id: 'LaborAction',
    name: 'Labor Action',
    Icon: LaborAction
  },{
    id: 'LargeScaleEvents',
    name: 'Large Scale Events',
    Icon: LargeScaleEvents
  },{
    id: 'MaritimeIncidents',
    name: 'Maritime Incidents',
    Icon: MaritimeIncidents
  },{
    id: 'MilitancyTerrorism',
    name: 'Militancy Terrorism',
    Icon: MilitancyTerrorism
  },{
    id: 'OrganisedCrime',
    name: 'Organised Crime',
    Icon: OrganisedCrime
  },{
    id: 'Piracy',
    name: 'Piracy',
    Icon: Piracy
  },{
    id: 'Politics',
    name: 'Politics',
    Icon: Politics
  },{
    id: 'Protest',
    name: 'Protest',
    Icon: Protest
  },{
    id: 'Rocket',
    name: 'Rocket',
    Icon: Rocket
  },{
    id: 'SecurityOperations',
    name: 'Security Operations',
    Icon: SecurityOperations
  },{
    id: 'SupplyChainDisruptions',
    name: 'Supply Chain Disruptions',
    Icon: SupplyChainDisruptions
  },{
    id: 'Travel',
    name: 'Travel',
    Icon: Travel
  },{
    id: 'Volcano',
    name: 'Volcano',
    Icon: Volcano
  },{
    id: 'Weather',
    name: 'Weather',
    Icon: Weather
  }];
