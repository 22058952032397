import { Tabs, TabsValue } from "@mantine/core";
import { FC } from "react";

import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import AppConfiguration
  from '@/tenant-context/control-mass-comms/components/CreateCommsPage/Channel-Configuration/AppConfiguration';
import EmailConfiguration
  from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Channel-Configuration/EmailConfiguration";
import VoiceConfiguration
  from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Channel-Configuration/VoiceConfiguration";
import CreateCommsFormComponent from "@/tenant-context/control-mass-comms/components/CreateCommsPage/CreateCommsForm.component";
import { useCreateCommsPageStyles } from "@/tenant-context/control-mass-comms/components/CreateCommsPage/CreateCommsPage.styles";
import { ReactComponent as WorkAppIcon } from "@/tenant-context/control-mass-comms/icons/app-gray.svg";
import { ReactComponent as EmailIcon } from "@/tenant-context/control-mass-comms/icons/email-gray.svg";
import { ReactComponent as LeftArrow } from "@/tenant-context/control-mass-comms/icons/leftArrow.svg";
import { ReactComponent as PhoneIcon } from "@/tenant-context/control-mass-comms/icons/phone-gray.svg";
import { ReactComponent as MessageSmsIcon } from "@/tenant-context/control-mass-comms/icons/sms-gray.svg";
import { DeliveryChannel, DeliveryItems, TemplateList } from "@/tenant-context/control-mass-comms/types/MassComms.types";

import MassCommsSection from "../MassCommsSection/MassCommsSegment.component";
import SmsConfiguration from "./Channel-Configuration/SmsConfiguration";


type Props = {
  deliveryChannels: DeliveryChannel,
  templateItems: TemplateList | undefined,
  communicationName: string | undefined,
  defaultResponseMethod: string | undefined,
  onDeliveryChannelChanged: (event: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>) => void,
  onCommunicationNameChanged: (data: React.ChangeEvent<HTMLInputElement>) => void,
  selectedTab: DeliveryItems | undefined,
  onSelectedTabChange: (data: TabsValue) => void,
  commSequenceNo: number | null,
  alertSequenceNo: number | null,
  isInTemplateCreationFlow: boolean
}

const CreateCommsPageComponent: FC<Props> = ({
  deliveryChannels,
  onDeliveryChannelChanged,
  onCommunicationNameChanged,
  templateItems,
  communicationName,
  defaultResponseMethod,
  selectedTab,
  onSelectedTabChange,
  commSequenceNo,
  alertSequenceNo,
  isInTemplateCreationFlow
}) => {

  const { classes } = useCreateCommsPageStyles({ isCommsSequenceNoAvailable: true });

  return (
    <div className={ classes.layoutContainer }>
      <LocalErrorBoundary>
        <CreateCommsFormComponent
          deliveryChannels={ deliveryChannels }
          templateItems={ templateItems }
          communicationName={ communicationName }
          defaultResponseMethod={ defaultResponseMethod }
          onCommunicationNameChanged={ onCommunicationNameChanged }
          onDeliveryChannelChanged={ onDeliveryChannelChanged }
          commSequenceNo={ commSequenceNo }
          alertSequenceNo={ alertSequenceNo }
          isInTemplateCreationFlow={ isInTemplateCreationFlow }
        />

        <MassCommsSection
          className={ classes.configsWrapper }
        >
          { !deliveryChannels.sms && !deliveryChannels.email && !deliveryChannels.voice && !deliveryChannels.app ? (
            <div className={ classes.noResultBody }>
              <LeftArrow className={ classes.leftArrow } />
              Select Delivery Channels and Message Response to start creating a notification
            </div>
          ) : (
            <>
              <Tabs value={ selectedTab } onTabChange={ onSelectedTabChange } className={ classes.deliveryTabs }>
                <div>
                  <Tabs.List>
                    { deliveryChannels.app ? (<Tabs.Tab value="app" icon={ <WorkAppIcon /> }>App Configuration</Tabs.Tab>) : null }
                    { deliveryChannels.email ? (<Tabs.Tab value="email" icon={ <EmailIcon /> }>Email Configuration</Tabs.Tab>) : null }
                    { deliveryChannels.sms ? (<Tabs.Tab value="sms" icon={ <MessageSmsIcon /> }>SMS Configuration</Tabs.Tab>) : null }
                    { deliveryChannels.voice ? (<Tabs.Tab value="voice" icon={ <PhoneIcon /> }>Call Configuration</Tabs.Tab>) : null }
                  </Tabs.List>
                </div>

                { deliveryChannels.app ? <Tabs.Panel value="app">
                  <AppConfiguration/>
                </Tabs.Panel> : null }

                { deliveryChannels.email ? <Tabs.Panel value="email">
                  <EmailConfiguration
                    isInTemplateCreationFlow={ isInTemplateCreationFlow }
                  />
                </Tabs.Panel> : null }

                { deliveryChannels.sms ? <Tabs.Panel value="sms">
                  <SmsConfiguration />
                </Tabs.Panel> : null }

                { deliveryChannels.voice ? <Tabs.Panel value="voice">
                  <VoiceConfiguration />
                </Tabs.Panel> : null }
              </Tabs>
              <div className={ classes.infoText }>
                Once you are happy with the content and configurations you have made across all selected Communication
                Channels, please move to the Next Step
              </div>
            </>
          ) }
        </MassCommsSection>
      </LocalErrorBoundary>
    </div>
  );
};

export default CreateCommsPageComponent;
