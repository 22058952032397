import { Feature, FeatureCollection, Point } from "geojson";
import mapboxgl, {
  GeoJSONSource,
  LngLatLike,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
  MapMouseEvent
} from "mapbox-gl";
import { MapboxMap, MapRef } from "react-map-gl";

import { defaultClusterRadius } from "@/tenant-context/visualisation-site/config/site-location-layer.config";

// TODO rename this (for other event types)
export const getClickedOnFeatures = (
  evt: MapLayerMouseEvent | MapMouseEvent,
  layerOrLayers?: string | string[]
): {
  features: MapboxGeoJSONFeature[],
  layerFeatures: typeof layerOrLayers extends undefined ? null : MapboxGeoJSONFeature[]
} => {
  const {
    target: map,
    point
  } = evt;

  const features = map.queryRenderedFeatures(point);

  const filterCriteria = Array.isArray(layerOrLayers)
    ? (feature: MapboxGeoJSONFeature) => layerOrLayers.includes(feature.layer.id)
    : (feature: MapboxGeoJSONFeature) => layerOrLayers === feature.layer.id;

  const layerFeatures = features.filter(filterCriteria);

  return {
    features,
    layerFeatures
  };
};

export const getClusteredFeatures = (
  map: MapRef | MapboxMap,
  event: mapboxgl.MapLayerEventType['click'] & mapboxgl.EventData,
  features: FeatureCollection<Point>,
  layers: Array<string>
): Feature<Point>[] => {
  const cluster = map.queryRenderedFeatures(event.point, { layers });

  if (cluster?.length === 0) {
    return [];
  }

  // features: from the added source that are clustered
  return features.features.filter(f => {
    const pointPixels = map.project(f.geometry.coordinates as LngLatLike);
    const pixelDistance = Math.sqrt(
      Math.pow(event.point.x - pointPixels.x, 2) +
      Math.pow(event.point.y - pointPixels.y, 2)
    );

    return Math.abs(pixelDistance) <= defaultClusterRadius;
  });
};

// Only GeoJSON sources are supported since they have a getClusterExpansionZoom method
export const zoomIntoCluster = (
  map: MapRef | MapboxMap,
  event: mapboxgl.MapLayerEventType['click'] & mapboxgl.EventData,
  layerIds: Array<string>,
  sourceId: string
) => {
  const features = map.queryRenderedFeatures(event.point, {
    layers: layerIds
  });

  const clusterId = features[0].properties !== null ? features[0].properties.cluster_id : null;

  if (!clusterId) {
    return;
  }

  (map.getSource(sourceId) as GeoJSONSource).getClusterExpansionZoom(
    clusterId,
    (err, zoom) => {
      if (err) {
        return;
      }

      map.easeTo({
        center: (features[0].geometry as Point).coordinates as [number, number],
        zoom: zoom
      });
    }
  );
};
