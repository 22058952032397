import dayjs from "dayjs";
import { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { timeScaleDomainDeltaMap } from "@/tenant-context/control-location-graph/store/location-graph/location-graph.model";
import RiskDateFilterTriggerControlComponent
  from "@/tenant-context/control-risk-timeline/controls/RiskDateFilterTriggerControl/RiskDateFilterTriggerControl.component";

type Props = {
  isMapZoomedIn?: boolean
}

const RiskDateFilterTriggerControl: FC<Props> = ({
  isMapZoomedIn = false
}) => {

  const [ isPopupOpen, setIsPopupOpen ] = useState(false);
  const [ minDate, setMinDate ] = useState<Date | undefined>(undefined);
  const [ maxDate, setMaxDate ] = useState<Date | undefined>(undefined);

  const filterDataRange = useSelector((state: RootState) => state.riskTimeline.filterDataRange);

  const {
    riskTimeline: {
      SET_FILTER_DATE_RANGE,
      SET_TIME_DOMAIN
    },
    riskAlerts: {
      clearCurrentRiskAlerts,
      subscribeToRiskAlerts
    },
    drawer: {
      openBottomDrawer,
      closeBottomDrawer
    }
  } = useDispatch<Dispatch>();

  const handlePopupChange = useCallback((open: boolean) => {
    setIsPopupOpen(open);
  }, [ setIsPopupOpen ]);

  const handleDateRangeChange = useCallback((value: [Date | null, Date | null]) => {
    // Checks whether both the start date and end date selected
    const startDate = value[0];
    const endDate = value[1];

    // Setting the range restricted to 3months
    if (startDate) {
      // eslint-disable-next-line no-magic-numbers
      const maxValidDate = dayjs(startDate).add(3, 'months').toDate();
      // eslint-disable-next-line no-magic-numbers
      const minValidDate = dayjs(startDate).subtract(3, 'months').toDate();

      setMinDate(minValidDate);
      setMaxDate(maxValidDate);
    }

    // Resetting range restriction
    if (!startDate) {
      setMinDate(undefined);
      setMaxDate(undefined);
    }

    if (startDate && endDate) {
      SET_FILTER_DATE_RANGE(value);
      clearCurrentRiskAlerts();
      subscribeToRiskAlerts();

      SET_TIME_DOMAIN({
        startTime: endDate.getTime() - (timeScaleDomainDeltaMap.Days * 2),
        endTime: endDate.getTime()
      });

      // Resetting range restriction
      setMinDate(undefined);
      setMaxDate(undefined);

      openBottomDrawer('risk-timeline-graph-control');
      setIsPopupOpen(false);
    }
  }, [ SET_FILTER_DATE_RANGE, SET_TIME_DOMAIN, clearCurrentRiskAlerts, openBottomDrawer, subscribeToRiskAlerts ]);

  const handlePopupOpenRequest = useCallback(() => {
    setIsPopupOpen(true);
  }, [ setIsPopupOpen ]);

  const handleDateFilterClearRequest = useCallback(($event) => {
    $event.stopPropagation();
    SET_FILTER_DATE_RANGE([ null, null ]);
    clearCurrentRiskAlerts();
    subscribeToRiskAlerts();
    closeBottomDrawer();

    SET_TIME_DOMAIN({
      // eslint-disable-next-line no-magic-numbers
      startTime: new Date().getTime() - (timeScaleDomainDeltaMap.Days * 2),
      endTime: new Date().getTime()
    });
  }, [ SET_FILTER_DATE_RANGE, SET_TIME_DOMAIN, clearCurrentRiskAlerts, closeBottomDrawer, subscribeToRiskAlerts ]);

  return (
    <RiskDateFilterTriggerControlComponent
      isMapZoomedIn={ isMapZoomedIn }
      isPopupOpen={ isPopupOpen }
      dateRange={ filterDataRange }
      minDate={ minDate }
      maxDate={ maxDate }
      onPopupChange={ handlePopupChange }
      onDateRangeChange={ handleDateRangeChange }
      onPopupOpenRequest={ handlePopupOpenRequest }
      onDateFilterClearRequest={ handleDateFilterClearRequest }
    />
  );
};

export default RiskDateFilterTriggerControl;
