export const PlaybookRequiredPoliciesConfig = {
  platform: {
    PLAYBOOK_ROUTE_ACCESS: {
      policyNames: [
        'SystemAdmin_ICM-Global_Playbook_ReadOnly',
        'SystemAdmin_ICM-Global_Playbook_FullAccess'
      ]
    },
    EDIT_PLAYBOOK: {
      policyNames: [
        'SystemAdmin_ICM-Global_Playbook_FullAccess'
      ]
    }
  },
  tenant: {
    PLAYBOOK_ROUTE_ACCESS: {
      policyNames: [
        'Tenant_ICM_Playbooks_ReadOnly',
        'Tenant_ICM_Playbooks_FullAccess'
      ]
    },
    EDIT_PLAYBOOK: {
      policyNames: [
        'Tenant_ICM_Playbooks_FullAccess'
      ]
    }
  }
};