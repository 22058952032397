// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
export const TRACE_PROPAGATION_TARGETS = [/^https:\/\/global-dev\.restrata\.net\//, /^https:\/\/global-qa\.restrata\.net\//];

// Performance Monitoring
// Capture 100% of the transactions, reduce in production!
export const TRACES_SAMPLE_RATE = 1.0;

// Session Replay
// This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
export const REPLAYS_SESSION_SAMPLE_RATE = 0.1;
// If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
export const REPLAYS_ON_ERROR_SAMPLE_RATE = 1.0;
