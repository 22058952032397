import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { Dispatch, RootState } from "@/core/store";
import ProfilePersonalContactTab
  from "@/tenant-context/control-profile/components/tabs/ProfilePersonalContactTab/ProfilePersonalContactTab.component";
import { ReferenceType } from "@/tenant-context/control-profile/types/profile";

const ProfilePersonalContactTabContainer: FC = () => {
  const {
    profile: {
      loadPersonalContacts,
      getProfileReferenceData,
      isLoggedInProfileId
    }
  } = useDispatch<Dispatch>();

  const params = useParams();

  const personalContacts = useSelector(
    ({ profile: { contacts } }: RootState) => contacts
  );

  useEffect(() => {
    loadPersonalContacts();
    getProfileReferenceData(ReferenceType.CONTACT_TYPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPersonalContacts]);

  const handleOwnProfileCheck = useCallback((): boolean => {
    return isLoggedInProfileId(params.userId);
  }, [isLoggedInProfileId, params.userId]);

  return (
    <ProfilePersonalContactTab
      personalContacts={ personalContacts }
      isOwnProfile={ handleOwnProfileCheck }
    />
  );
};

export default ProfilePersonalContactTabContainer;
