import { createModel } from "@rematch/core";

import { Dispatch, RootModel, RootState } from "@/core/store";
import geoJsonMapper from "@/tenant-context/common/util/geo-json-mapper";
import { Visualizable, withVisualisableTraits } from "@/tenant-context/common/util/with-visualisable-traits";
import { searchAssetsAndPeople } from "@/tenant-context/control-search/api/search";
import { SearchResultFeatureCollection } from "@/tenant-context/control-search/types/search";


export type SearchState = Visualizable<{
  searchCriteria: string,
  areResultsVisible: boolean,
  areResultsLoading: boolean
}, SearchResultFeatureCollection>

const searchModel = withVisualisableTraits<
  SearchResultFeatureCollection
>()({
  name: 'search',
  state: {
    searchCriteria: '',
    areResultsVisible: false,
    areResultsLoading: false
  },
  reducers: {
    SET_SEARCH_LOADING(
      state: SearchState,
      payload: boolean
    ): SearchState {
      return {
        ...state,
        areResultsLoading: payload
      };
    },

    SET_SEARCH_CRITERIA(
      state: SearchState,
      payload: string
    ): SearchState {
      return {
        ...state,
        searchCriteria: payload
      };
    },

    SET_SEARCH_RESULTS(
      state: SearchState,
      payload: SearchResultFeatureCollection
    ): SearchState {
      return {
        ...state,
        geoData: payload
      };
    },

    SET_SEARCH_RESULTS_VISIBILITY(
      state: SearchState,
      payload: boolean
    ): SearchState {
      return {
        ...state,
        areResultsVisible: payload
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async updateSearchCriteria(searchCriteria: string): Promise<void> {
      dispatch.search.SET_SEARCH_CRITERIA(searchCriteria);

      dispatch.search.SET_SEARCH_LOADING(true);

      await dispatch.search.loadSearchResults(searchCriteria);

      dispatch.search.SET_SEARCH_LOADING(false);
    },

    async loadSearchResults(searchCriteria: string, state: RootState): Promise<void> {
      // Handle empty search criteria by setting the search result set to empty and set search visibility to false
      if (searchCriteria === '') {
        dispatch.search.SET_SEARCH_RESULTS({
          type: 'FeatureCollection',
          features: []
        } as SearchResultFeatureCollection);

        dispatch.search.SET_SEARCH_RESULTS_VISIBILITY(false);

        return;
      }

      const {
        rankingSettings: {
          timeTravelTargetEpoch
        }
      } = state;

      const assetLocations = await searchAssetsAndPeople(searchCriteria, timeTravelTargetEpoch);

      const result = geoJsonMapper.parse(assetLocations, {
        Point: [
          'geoPoint.lat',
          'geoPoint.lon'
        ]
      }) as SearchResultFeatureCollection;

      dispatch.search.SET_SEARCH_RESULTS(result);

      if (result.features.length > 0) {
        dispatch.search.SET_SEARCH_RESULTS_VISIBILITY(true);
      } else if (!searchCriteria) {
        dispatch.search.SET_SEARCH_RESULTS_VISIBILITY(false);
      }
    }
  })
});

export const search = createModel<RootModel>()(
  searchModel
);
