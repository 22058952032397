import { openModal } from '@mantine/modals';
import { useCallback } from 'react';

import UnauthorizedWarningModalComponent from '@/common/components/UnauthorizedWarningModal';

export default function useUnauthorizedModal(
  customHeading?: string,
  options?: {  customAlert?: string, customMessage?: string }
) {
  return useCallback(() => {
    openModal({
      title: customHeading || 'This feature is unavailable',
      size: 648,
      children: (
        <UnauthorizedWarningModalComponent messageOptions={ options }/>
      )
    });
  }, [customHeading, options]);
}
