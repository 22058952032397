import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/core/store';
import CreateGeofenceToolBarComponent
  from '@/tenant-context/control-draw/components/CreateGeofenceToolBar/CreateGeofenceToolBar.component';
import { DrawingTypes } from '@/tenant-context/control-draw/types/draw';

type Props = {
  handleDrawingToolSelect: (type: DrawingTypes) => void,
  isMinified?: boolean,
  selectedTools?: DrawingTypes[]
  disabledTools?: DrawingTypes[],
  isWithLiveFeedButton?: boolean
};

const CreateGeofenceToolBarContainer: FC<Props> = ({
  handleDrawingToolSelect,
  isMinified,
  selectedTools,
  disabledTools,
  isWithLiveFeedButton = false
}) => {

  const profile = useSelector((state: RootState) => state.profile?.general);
  const [selectedTool, setSelectedTool] = useState<DrawingTypes>(DrawingTypes.NONE);

  const selectGeofenceTool = useCallback((type: DrawingTypes) => {
    handleDrawingToolSelect(type);
    setSelectedTool(type);
  }, [handleDrawingToolSelect]);

  return (
    <CreateGeofenceToolBarComponent
      selectGeofenceTool={ selectGeofenceTool }
      selectedTool={ selectedTool }
      userName={ `${profile?.firstName} ${profile?.lastName}` }
      isMinified={ isMinified }
      selectedTools={ selectedTools }
      disabledTools={ disabledTools }
      isWithLiveFeedButton={ isWithLiveFeedButton }
    />
  );
};

export default CreateGeofenceToolBarContainer;
