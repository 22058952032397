import { FC, useCallback } from "react";

import { useColorSwatchPaletteStyles } from '@/common/components/ColorSwatch/ColorSwatch.styles';

type Props = {
  color: string
  borderColor: string
  onClick: (color: string) => void
}

const ColorSwatchesPalette: FC<Props> = ({
  color,
  borderColor,
  onClick
}) => {

  const { classes } = useColorSwatchPaletteStyles({ color, borderColor });

  const handleClick = useCallback(() => {
    onClick(color);
  }, [color, onClick]);

  return (
    <button className={ classes.circle } onClick={ handleClick }/>
  );
};

export default ColorSwatchesPalette;
