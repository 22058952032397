import { ActionIcon, Badge, Box, Flex, Progress, SimpleGrid, Skeleton, Stack, Text } from '@mantine/core';
import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from "@/common/icons/Actions/close.svg";
import { ReactComponent as Contract } from "@/common/icons/Actions/contract.svg";
import { ReactComponent as ExpandIcon } from "@/common/icons/Actions/expand.svg";
import { ReactComponent as MaxSecurity } from "@/common/icons/RiskProviders/max-security.svg";
import { RootStateWithLoading } from '@/core/store';
import CountryFlag from '@/tenant-context/common/components/CountryFlag';
import {
  useDetailedCityReportStyles
} from '@/tenant-context/control-reports/components/DetailedCityReport/DetailedCityReport.styles';
import ReportSectionComponent
  from '@/tenant-context/control-reports/components/DetailedCountryReport/ReportSection.component';
import { CityRiskData } from '@/tenant-context/control-reports/types/city-risk-reports';
import {
  getRiskColorByRiskLevel, getRiskLevelValueByRiskLevel, kebabCaseToTitleCase,
  snakeCaseToTitleCase
} from '@/tenant-context/control-reports/util/country-risk-reports';
import {
  getIndicatorIcon,
  getSafetyCategoryIcon, getSubSectionIcon
} from '@/tenant-context/control-reports/util/icons-maps';

type Props = {
  cityRiskData: CityRiskData;
  onClose: () => void;
  onExpand: () => void;
  isExpanded: boolean;
};

const DetailedCityReportComponent: FC<Props> = ({
  cityRiskData,
  onClose: handleClose,
  onExpand: handleExpand,
  isExpanded
}) => {
  const getColorByLevel = (level?: string) => {
    if (!level) {
      return getRiskColorByRiskLevel('low');
    }

    return getRiskColorByRiskLevel(level);
  };
  const { classes, cx } = useDetailedCityReportStyles( {
    riskLevelColor: getColorByLevel(cityRiskData?.riskLevel?.toLowerCase() || '')
  } );

  const isCountryDataLoading = useSelector(
    (state: RootStateWithLoading) => state.loading.effects.cityRiskReports.loadSelectedCityReport
  );

  const indicatorIcon = useCallback((key: string) => {
    return getIndicatorIcon(key);
  }, []);

  const safetyCategoryIcon = useCallback((key: string) => {
    return getSafetyCategoryIcon(key);
  }, []);

  const subSectionIcon = useCallback((key: string) => {
    return getSubSectionIcon(key);
  }, []);

  const riskLevelValue = useMemo(() => {
    return getRiskLevelValueByRiskLevel(cityRiskData.riskLevel);
  }, [cityRiskData.riskLevel]);

  const providerName = useMemo(() => {
    return kebabCaseToTitleCase(cityRiskData.providerName);
  }, [cityRiskData.providerName]);

  const providerIcon = useMemo(() => {
    switch (cityRiskData.providerName?.toLowerCase()) {
    case 'max-security':
      return <MaxSecurity/>;
    default:
      return null;
    }
  }, [cityRiskData.providerName]);

  return (
    <Box className={ classes.root }>
      <Flex direction="column" className={ classes.header }>
        <Flex justify="space-between" align="center">
          <Flex gap={ 12 } direction="column">
            <Flex gap={ 12 } align="center">
              <Box className={ classes.flag }>
                <CountryFlag countryCode={ cityRiskData.countryISOCode || '' } />
              </Box>
              <Text size={ 32 } weight={ 700 }>{ cityRiskData.cityName }</Text>
            </Flex>
            <Flex gap={ 4 }>
              <Text>Issued by:</Text>
              { providerIcon }
              <Text>{ providerName }</Text>
            </Flex>
          </Flex>

          <Flex>
            <ActionIcon className={ classes.actionIcon } onClick={ handleExpand }>
              { isExpanded ? <Contract/> : <ExpandIcon/> }
            </ActionIcon>
            <ActionIcon className={ classes.actionIcon } onClick={ handleClose }><CloseIcon/></ActionIcon>
          </Flex>
        </Flex>
      </Flex>

      { !isCountryDataLoading ? (
        <Flex direction="column" gap={ 32 } className={ cx({ [classes.body]: true, [classes.bodyExpanded]: isExpanded }) }>

          <Stack spacing="sm" className={ classes.riskLevelContainer }>
            <Flex justify="space-between">
              <Text weight={ 400 }>Risk Level</Text>
              <Text weight={ 400 } transform="capitalize">{ cityRiskData.riskLevel }</Text>
            </Flex>
            <Progress value={ riskLevelValue } color="red" size="lg" className={ classes.riskLevel }/>
          </Stack>

          { cityRiskData.risk_indicators && <Stack>
            <Text className={ classes.sectionHeading }>Indicators</Text>
            <SimpleGrid cols={ 4 } spacing={ 8 }>
              { Object.keys(cityRiskData.risk_indicators).map((indicatorKey, index) => (
                <Badge
                  key={ index }
                  style={ { backgroundColor: getColorByLevel(cityRiskData.risk_indicators ? cityRiskData.risk_indicators[indicatorKey].level : '') } }
                  className={ classes.indicatorPill }
                >
                  <Flex align="center" gap={ 4 }>
                    { indicatorIcon(indicatorKey) }
                  </Flex>
                  { snakeCaseToTitleCase(indicatorKey) }
                </Badge>
              )) }
            </SimpleGrid>
          </Stack> }

          { cityRiskData.overview && <Stack>
            <Text className={ classes.sectionHeading }>Overview</Text>
            <div className={ classes.text } dangerouslySetInnerHTML={ { __html: cityRiskData.overview.text } } />
          </Stack> }

          <ReportSectionComponent
            title={ 'Safety and security' }
            reportData={ cityRiskData.safety_security }
            getSubsectionIcon={ safetyCategoryIcon }
          />

          <ReportSectionComponent
            title={ 'Infrastructure' }
            reportData={ cityRiskData.infrastructure }
            getSubsectionIcon={ subSectionIcon }
            isLevelShownAsRating
          />

          <ReportSectionComponent
            title={ 'Local considerations' }
            reportData={ cityRiskData.local_considerations }
            getSubsectionIcon={ subSectionIcon }
          />

          { cityRiskData.dos_end_donts && <Stack spacing="md">
            <Text className={ classes.reportSectionHeading }>{ cityRiskData.dos_end_donts?.title }</Text>
            <Flex gap="lg" wrap="wrap">
              <Stack spacing="md">
                <div
                  className={ classes.content }
                  dangerouslySetInnerHTML={ { __html: cityRiskData.dos_end_donts.text } }
                />
              </Stack>
            </Flex>
          </Stack> }

          <ReportSectionComponent
            title={ 'Contact numbers' }
            reportData={ cityRiskData.contact_numbers }
            getSubsectionIcon={ subSectionIcon }
          />
        </Flex>
      ) : (
        <Stack spacing="md" className={ classes.loadingContainer }>
          { Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={ index } height={ 100 } radius="sm" opacity={ 0.1 }/>
          )) }
        </Stack>
      ) }
    </Box>
  );
};

export default DetailedCityReportComponent;
