import { FC } from "react";
import { Popup } from "react-map-gl";

import { useMiniPopupStyles } from './MiniPopup.style';

type Props = {
  longitude: number,
  latitude: number,
};

const MiniPopup: FC<Props> = ({
  longitude,
  latitude,
  children
}) => {
  const { classes } = useMiniPopupStyles();

  return (
    <Popup
      className={ classes.root }
      longitude={ longitude }
      latitude={ latitude }
      anchor="top"
      closeButton={ false }
    >
      { children }
    </Popup>
  );
};

export default MiniPopup;