import { ActionIcon, CloseButton } from "@mantine/core";
import { Input } from "@mantine/core";
import { FC, RefObject, useCallback, useEffect, useState } from "react";

import { ReactComponent as SearchIcon } from '@/common/icons/search.svg';

import { useIconSearchStyles } from "./IconSearchSlider.styles";

type Props = {
    isSearchBoxExpanded: boolean,
    searchBoxRef: RefObject<HTMLInputElement>,
    onSearchBoxExpand: () => void,
    onSearchBoxShrink: () => void,
    onChangeSearch: (val: string) => void,
    customClass: string
}
const IconSearchSliderComponent: FC<Props> = (props) => {
  const { classes, cx } = useIconSearchStyles();

  const [searchVal, setSearchVal] = useState("");

  useEffect(()=>{
    props.onChangeSearch(searchVal);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  const handleSearch = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) =>
      setSearchVal(evt.currentTarget.value)
    , [setSearchVal]
  );

  const handleClose = useCallback(() => {
    setSearchVal('');
    props.onSearchBoxShrink();
  }, [props]);

  return (
    <div className={ cx({
      [classes.searchBoxWrapperExpanded]: props.isSearchBoxExpanded,
      [classes.searchBoxWrapperShrink]: !props.isSearchBoxExpanded,
      [props.customClass]: true
    }) }>
      <ActionIcon size={ 'xl' } onClick={ props.onSearchBoxExpand }><SearchIcon /></ActionIcon>
      <Input
        ref={ props.searchBoxRef }
        onFocus={ props.onSearchBoxExpand }
        placeholder="Type here..."
        rightSection={ props.isSearchBoxExpanded ? <CloseButton onClick={ handleClose } /> : null }
        onChange={ handleSearch }
        value={ searchVal }
      />
    </div>
  );
};

export default IconSearchSliderComponent;
