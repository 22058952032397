import { createContext, FC, useCallback, useContext, useState } from "react";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import useContextValue from "@/common/hooks/useContextValue";

export type DrawerPage = "map-layers" | "riskline" | 'bsoc' | ToggleableLayerType.People | 'dataminr';
const rootDrawerPage: DrawerPage = "map-layers";

export const enum Sections {
  BusinessData = "businessData",
  ThreatData = "threatData",
  ContextualData = "contextualData"
}

const defaultOpenedTreeItems = [Sections.BusinessData, Sections.ThreatData];

export type MapLayersDrawerContextType = {
  drawerPage: DrawerPage;
  changeDrawerPage(page: DrawerPage): void;
  setRootDrawerPage(): void;
  openedTreeItems: Set<string>;
  handleToggleTreeItem(isExpanded: boolean, itemValue: string): void;
  resetToggledTreeItems(): void;
};

export const MapLayersDrawerContext =
  createContext<MapLayersDrawerContextType | null>(null);

export const useMapLayersDrawerContext = () => {
  const context = useContext(MapLayersDrawerContext);
  if (!context) {
    throw new Error(
      "useMapLayersDrawerContext must be used within MapLayersDrawerProvider"
    );
  }

  return context;
};

export const MapLayersDrawerProvider: FC = ({ children }) => {
  const [drawerPage, setDrawerPage] = useState<DrawerPage>(rootDrawerPage);
  const changeDrawerPage = useCallback((page: DrawerPage) => {
    setDrawerPage(page);
  }, []);
  const setRootDrawerPage = useCallback(() => {
    setDrawerPage(rootDrawerPage);
  }, []);
  const [openedTreeItems, setOpenedTreeItems] = useState<Set<string>>(
    new Set(defaultOpenedTreeItems)
  );
  const handleToggleTreeItem = useCallback(
    (isExpanded: boolean, itemValue: string) => {
      setOpenedTreeItems((prev) => {
        const newSet = new Set(prev);
        if (isExpanded) {
          newSet.add(itemValue);
        } else {
          newSet.delete(itemValue);
        }

        return newSet;
      });
    },
    []
  );
  const resetToggledTreeItems = useCallback(() => {
    setOpenedTreeItems(new Set(defaultOpenedTreeItems));
  }, []);

  return (
    <MapLayersDrawerContext.Provider
      value={ useContextValue({
        openedTreeItems,
        handleToggleTreeItem,
        resetToggledTreeItems,
        drawerPage,
        changeDrawerPage,
        setRootDrawerPage
      }) }
    >
      { children }
    </MapLayersDrawerContext.Provider>
  );
};
