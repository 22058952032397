import { FC, useCallback } from "react";

import VideoThumbnail from '@/tenant-context/employee-app-action-response-center/components/ui/VideoThumbnail/VideoThumbnail.component';
import { EmergencyVideo } from "@/tenant-context/employee-app-action-response-center/types/emergency-video";

type Props = {
  emergencyVideo: EmergencyVideo
  onClickThumbnail: (emergencyVideo: EmergencyVideo) => void
};

const VideoThumbnailContainer: FC<Props> = ({
  emergencyVideo,
  onClickThumbnail
}) => {
  const handleClick = useCallback(() => {
    onClickThumbnail(emergencyVideo);
  }, [emergencyVideo, onClickThumbnail]);

  return (
    <VideoThumbnail
      emergencyVideo={ emergencyVideo }
      onClickThumbnail={ handleClick }
    />
  );
};

export default VideoThumbnailContainer;
