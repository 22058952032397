import { ColDef, ICellRendererParams } from "ag-grid-community";
import { ReactNode } from "react";

export const columnDefs = (
  _actionCellRenderer: (params: ICellRendererParams) => ReactNode
): ColDef[] => [
  {
    field: 'name',
    headerName: 'Zone Name',
    sortable: true,
    comparator: () => 0
  },
  {
    field: 'code',
    headerName: 'Zone Code',
    sortable: true,
    comparator: () => 0
  },
  {
    field: 'zoneType',
    headerName: 'Zone Type',
    sortable: true,
    comparator: () => 0
  },
  
  {
    field: 'building',
    headerName: 'Building (Name)',
    sortable: true,
    comparator: () => 0
  },
  {
    field: 'floor',
    headerName: 'Floor (Name)',
    sortable: true,
    comparator: () => 0
  },
  {
    field: 'accessControlMode',
    headerName: 'Access Control Mode',
    sortable: true,
    comparator: () => 0
  },
  {
    field: 'musterPosition',
    headerName: 'Muster Zone Position',
    sortable: true,
    comparator: () => 0
  },
  {
    field: 'releaseBehavior',
    headerName: 'Release Behaviour',
    sortable: true,
    comparator: () => 0
  }
  //temporary
  // {
  //   headerName: '',
  //   colId: 'action',
  //   type: 'rightAligned',
  //   flex: 0,
  //   cellRenderer: actionCellRenderer
  // }
];