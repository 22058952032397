export const getValues = (logString: string) => {
  const entries = logString
    .slice(1, logString.length - 1) // remove curly braces
    .split(',') // split by comma
    .map((pair: string) => pair.split('=')); // split by equal sign

  return entries.reduce((
    acc: Record<string, string>,
    [key, value]
  ) => {
    if (!key || !value) {
      return acc;
    }

    acc[key.trim()] = value.trim();
    return acc;
  }, {} as Record<string, string>); // compile into an object
};

export const getDelta = (
  oldValues: Record<string, string>,
  newValues: Record<string, string>
) => {
  const delta: Record<string, string> = {};

  Object.keys(newValues).forEach((key) => {
    if (oldValues[key] !== newValues[key]) {
      delta[key] = newValues[key];
    }
  });

  return delta;
};

const splitRegex = /,(?![^{]*})/g;
const isObject = (s: string): boolean => s.startsWith('{') && s.endsWith('}');
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseLogData = (log: string): any => {
  if (!isObject(log)) {
    return log?.trim();
  }

  // split str into array of key value pairs, respect nested objects
  const arr = log.slice(1, log.length - 1).split(splitRegex);

  const entries = arr.map((item) => {
    // split in place of first '='
    const [key, ...rest] = item.split('=');
    // join the rest back together
    const val = rest.join('=');
    // return key value pair
    return [
      key.trim(),
      parseLogData(val)
    ];
  });

  return Object.fromEntries(entries);
};
