export const ProfileRequiredPoliciesConfig = {
  VIEW_FULL_PROFILE: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess'
    ]
  },
  EDIT_FULL_PROFILE: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess'
    ]
  },
  VIEW_CONTACT_DETAILS: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-ContactDetails_Readonly',
      'Tenant_System_People-ContactDetails_FullAccess'
    ]
  },
  EDIT_CONTACT_DETAILS: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-ContactDetails_FullAccess'
    ]
  },
  VIEW_PEOPLE_GROUPS: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-Groups_Readonly',
      'Tenant_System_People-Groups_FullAccess'
    ]
  },
  EDIT_PEOPLE_GROUPS: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-Groups_FullAccess'
    ]
  },
  GROUPS_FULL_ACCESS: {
    policyNames: [
      'Tenant_System_People-Groups_FullAccess'
    ]
  },
  VIEW_IDENTIFICATION: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-Identification_Readonly',
      'Tenant_System_People-Identification_FullAccess'
    ]
  },
  IDENTIFICATION_READ_ONLY: {
    policyNames: [
      'Tenant_System_People-Identification_Readonly'
    ]
  },
  IDENTIFICATION_FULL_ACCESS: {
    policyNames: [
      'Tenant_System_People-Identification_FullAccess'
    ]
  },
  EDIT_IDENTIFICATION: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-Identification_FullAccess'
    ]
  },
  VIEW_ADDRESS: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-Addresses_Readonly',
      'Tenant_System_People-Addresses_FullAccess'
    ]
  },
  EDIT_ADDRESS: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-Addresses_FullAccess'
    ]
  },
  VIEW_EMERGENCY_CONTACTS_TAB: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-EmergencyContact_Readonly',
      'Tenant_System_People-EmergencyContact_FullAccess'
    ]
  },
  EDIT_EMERGENCY_CONTACT: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-EmergencyContact_FullAccess'
    ]
  },
  VIEW_DOCUMENTS: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-Documents_Readonly',
      'Tenant_System_People-Documents_FullAccess'
    ]
  },
  EDIT_DOCUMENTS: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-Documents_FullAccess'
    ]
  },
  VIEW_MEDICAL_SECTION: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-MedicalDetails_Readonly',
      'Tenant_System_People-MedicalDetails_FullAccess'
    ]
  },
  EDIT_MEDICAL_SECTION: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-MedicalDetails_FullAccess'
    ]
  },
  VIEW_ATTRIBUTES_SECTION: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-PersonalAttributes_Readonly',
      'Tenant_System_People-PersonalAttributes_FullAccess'
    ]
  },
  EDIT_ATTRIBUTES_SECTION: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-PersonalAttributes_FullAccess'
    ]
  },
  VIEW_DISTINGUISHING_FEATURES_SECTION: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-Features_Readonly',
      'Tenant_System_People-Features_FullAccess'
    ]
  },
  EDIT_DISTINGUISHING_FEATURES_SECTION: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-Features_FullAccess'
    ]
  },
  VIEW_COMPANY_DETAILS_SECTION: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-CompanyDetails_Readonly',
      'Tenant_System_People-CompanyDetails_FullAccess'
    ]
  },
  EDIT_COMPANY_DETAILS_SECTION: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-CompanyDetails_FullAccess'
    ]
  },
  VIEW_PROFILE_PERSONAL_DETAILS: {
    policyNames: [
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-PersonalDetails_Readonly',
      'Tenant_System_People-PersonalDetails_FullAccess',
      'Tenant_System_People-CompanyDetails_Readonly',
      'Tenant_System_People-PersonalAttributes_Readonly',
      'Tenant_System_People-Features_Readonly'
    ]
  },
  EDIT_PROFILE_PERSONAL_DETAILS: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-PersonalDetails_FullAccess'
    ]
  },
  READ_ONLY_SENSITIVE_DATA: {
    policyNames: [
      'Tenant_System_People-SensitiveData_Readonly',
      'Tenant_System_People-SensitiveData_FullAccess'
    ]
  },
  FULL_ACCESS_SENSITIVE_DATA: {
    policyNames: [
      'Tenant_System_People-SensitiveData_FullAccess'
    ]
  },
  PEOPLE_PROFILE_BULK_UPLOAD: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess'
    ]
  },
  PEOPLE_PROFILE_BULK_DEACTIVATE: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess'
    ]
  },
  VIEW_COMPANIES: {
    policyNames: [
      'Tenant_System_Company_FullAccess',
      'Tenant_System_Company_ReadOnly '
    ]
  },
  EDIT_COMPANIES: {
    policyNames: [
      'Tenant_System_Company_FullAccess'
    ]
  },
  ADD_TO_PEOPLE_GROUP: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-Groups_FullAccess'
    ]
  },
  VIEW_PROFILE_TAGS: {
    policyNames: [
      'Tenant_System_People-Tag_ReadOnly',
      'Tenant_System_People-Tag_FullAccess'
    ]
  },
  EDIT_PROFILE_TAGS : {
    policyNames: [
      'Tenant_System_People-Tag_FullAccess'
    ]
  }
};

export const ProfileRoutePoliciesConfig = {
  PROFILE_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-PersonalDetails_FullAccess',
      'Tenant_System_People-PersonalDetails_Readonly',
      'Tenant_System_People-CompanyDetails_FullAccess',
      'Tenant_System_People-CompanyDetails_Readonly',
      'Tenant_System_People-MedicalDetails_FullAccess',
      'Tenant_System_People-MedicalDetails_Readonly',
      'Tenant_System_People-PersonalAttributes_FullAccess',
      'Tenant_System_People-PersonalAttributes_Readonly',
      'Tenant_System_People-Features_FullAccess',
      'Tenant_System_People-Features_Readonly',
      'Tenant_System_People-Addresses_FullAccess',
      'Tenant_System_People-Addresses_Readonly',
      'Tenant_System_People-Documents_FullAccess',
      'Tenant_System_People-Documents_Readonly',
      'Tenant_System_People-EmergencyContact_FullAccess',
      'Tenant_System_People-EmergencyContact_Readonly',
      'Tenant_System_People-MiniProfile_Readonly',
      'Tenant_System_People-SensitveData_FullAccess',
      'Tenant_System_People-SensitveData_Readonly'
    ]
  },
  PROFILE_LIST_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_System_People-FullProfile_FullAccess',
      'Tenant_System_People-FullProfile_Readonly',
      'Tenant_System_People-PersonalDetails_FullAccess',
      'Tenant_System_People-PersonalDetails_Readonly',
      'Tenant_System_People-CompanyDetails_FullAccess',
      'Tenant_System_People-CompanyDetails_Readonly'
    ]
  },
  COMPANY_LIST_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_System_Company_FullAccess',
      'Tenant_System_Company_ReadOnly '
    ]
  }
};
