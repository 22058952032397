export const SituationTypePoliciesConfig = {
  platform: {
    SITUATION_TYPE_ROUTE_ACCESS: {
      policyNames: [
        'SystemAdmin_ICM-Global_ThreatType_ReadOnly',
        'SystemAdmin_ICM-Global_ThreatType_FullAccess'
      ]
    },
    EDIT_SITUATION_TYPE: {
      policyNames: [
        'SystemAdmin_ICM-Global_ThreatType_FullAccess'
      ]
    }
  },
  tenant: {
    SITUATION_TYPE_ROUTE_ACCESS: {
      policyNames: [
        'Tenant_ICM_ThreatType_ReadOnly',
        'Tenant_ICM_ThreatType_FullAccess'
      ]
    },
    EDIT_SITUATION_TYPE: {
      policyNames: [
        'Tenant_ICM_ThreatType_FullAccess'
      ]
    }
  }
};