export type CountrySummaryDataType = {
    countryName: string;
    countryISOCode: string;
    travellerCount : number;
    risk: {
        level : string;
        category : string;
    } | null;
    travellers?: TravellerData[];
}

export type CountryDetailData = {
    countryName: string;
    countryISOCode: string;
    risk: {
        level: string;
        category: string;
        description: string;
    } | null,
    travellers: TravellerData[];
}

export type TravellerData = {
    profileId: string;
    personEmail: string;
    personLocation: {
        latitude: number,
        longitude: number
    },
    personName: string,
    personRole: string | null,
    personProfilePictureUrl?: string,
    countryOfResident?: string,
    tripLocation: string,
    tripDates: {
        from: number,
        to: number
    },
    tripStatus: string,
    correlationId: string;
    countryISOCode: string;
    countryName: string;
}

export enum CountriesSortType {
    countryName = 'countryName',
    travellerCount = 'travellerCount',
    riskLevel = 'riskLevel'
}

export enum TravellersInCountrySortType {
    TRIP_DATE = 'TRIP_DATE',
    NAME = 'NAME',
    RISK = 'RISK'
}

export type TravellersSearchFormData = {
    // timeRange: TimeRange;
    tripStatus: Array<TripStatus>;
    travellersType: Array<TravellersType>;
    destinationRiskLevel: Array<DestinationRiskLevel>;
    rangeCalendar: [Date | null, Date | null];
}

export enum TripStatus {
    ARRIVING = 'ARRIVING',
    DEPARTING = 'DEPARTING',
    IN_COUNTRY = 'IN_COUNTRY'
}

export enum TravellersType {
    INTERNATIONAL = 'INTERNATIONAL',
    DOMESTIC = 'DOMESTIC'
}

export enum DestinationRiskLevel {
    MODERATE = 'MODERATE',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    EXTREME = 'EXTREME'
}

export enum TimeRange {
    'NOW' = 'now',
    '24_HOURS' = '24hours',
    '7_DAYS' = '7days',
    '31_DAYS' = '31days',
    'DATE_RANGE' = 'dateRange'
}

export type QueryTravellersRequestData = {
    pageNum: number,
    pageSize: number,
    orderBy?: string,
    fromTime: number,
    toTime: number,
    destinationRiskLevel: Set<DestinationRiskLevel>,
    mergeNonTenant: boolean,
}

export type QueryTravellersByCountryRequestData = {
    pageNum: number,
    pageSize: number,
    orderBy?: string,
    countryISOCode: string,
    fromTime: number,
    toTime: number,
    travellersType: Set<TravellersType>,
    destinationRiskLevel: Set<DestinationRiskLevel>,
    mergeNonTenant: boolean,
}
