import { FC, useContext } from "react";

import { BigMapPopupIds, useMapPopupList } from "@/tenant-context/common/hooks/useMapPopupList";
import { RiskContext } from "@/tenant-context/visualisation-risk-alerts/context/Risk.context";

import RiskAlertPopup from "./RiskAlertPopup.component";

const RiskAlertPopupContainer: FC = () => {
  const { handleRiskLocationClose, showRiskDetails, handleZoomTo } = useContext(RiskContext);
  const { popups } = useMapPopupList("bigMapPopups");

  return (
    <>
      { popups
        .filter(({ id }) => id.startsWith(BigMapPopupIds.RiskAlert))
        .map(({ id }) => (
          <RiskAlertPopup
            key={ id }
            popupId={ id }
            onClosePopup={ handleRiskLocationClose }
            onClickRiskDetails={ showRiskDetails }
            onClickZoomTo={ handleZoomTo }
          />
        )) }
    </>
  );
};

export default RiskAlertPopupContainer;
