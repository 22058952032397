import { useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { removeRef } from "@/common/util/common-functions";
import { GenericStringKeyObject } from "@/common/util/interface";
import { Dispatch, RootState } from "@/core/store";
import {
  CaseTypeDetailsContext
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/CaseTypeDetails.context";
import AccessPermissionsComponent
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/AccessPermissions/AccessPermissions.component";
import { CaseFeature } from "@/platform-context/control-icm-management/types/CaseTypes/caseFeatures";
import {
  CreateRoleParams
} from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

const AccessPermissions = () => {

  const {
    caseTypes: {
      getRole,
      updateRole
    }
  } = useDispatch<Dispatch>();

  const { id, roleId } = useParams();

  const { activeRole } = useSelector((state: RootState) => state.caseTypes);

  const { isAuthorizedToCreateOrEdit } = useContext(CaseTypeDetailsContext);

  const handleFeatureChange = useCallback(async (feat: Record<string, unknown>)=> {

    if (id && roleId && activeRole) {

      const { featuresWithPermissionList, ...rest } = activeRole;

      const initialFeatures: Record<string, unknown> = {};

      featuresWithPermissionList.forEach((feature) => {
        if (feature.enable) {
          initialFeatures[feature.identifier] = feature.permission;
        }
      });

      const data = {
        ...rest,
        teamRef: removeRef(activeRole.teamRef),
        featurePermissionMap: { ...initialFeatures, ...feat }
      };

      const params = {
        data: data as CreateRoleParams,
        caseTypeId: id,
        roleId
      };

      const result = await updateRole(params);

      if(result){
        getRole({
          caseTypeId: id,
          roleId
        });
      }

      return result;
    }
  }, [id, roleId, activeRole, updateRole, getRole]);

  const formattedCaseFeatures = useMemo(
    () => {
      const featuresList = (activeRole?.featuresWithPermissionList || []).reduce((acc, feat) => {
        if (!acc[feat.featureCategory]) {
          acc[feat.featureCategory] = [];
        }

        acc[feat.featureCategory].push(feat);

        return acc;
      }, {} as GenericStringKeyObject<CaseFeature[]>);

      // eslint-disable-next-line fp/no-loops
      for(const key in featuresList){
        featuresList[key] = featuresList[key].sort((a, b) => a.sortOrder - b.sortOrder);
      }

      return featuresList;
    }

    , [activeRole]
  );

  return (
    <AccessPermissionsComponent
      formattedCaseFeatures={ formattedCaseFeatures }
      isAuthorizedToCreateOrEdit={ isAuthorizedToCreateOrEdit }
      onFeatureChange={ handleFeatureChange }
    />
  );
};

export default AccessPermissions;