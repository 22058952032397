/* this is disabled to programmatically pass deps to useMemo */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';

const useContextValue = <T extends object>(value: T): T => useMemo(
  () => value,
  Object.values(value)
);

export default useContextValue;
