import { ArcCaseEvent, ArcPeopleImpactSummary } from "@/tenant-context/control-action-response-center/types/ARC.types";

export const generateArcCaseImpactSummary = (arcCase: ArcCaseEvent | undefined): ArcPeopleImpactSummary => {
  if (!arcCase) {
  // Setting the default state if arc case is not available yet
    return [
      { count: 0, impactStatus: 'NOT_SAFE' },
      { count: 0, impactStatus: 'NO_RESPONSE' },
      { count: 0, impactStatus: 'SAFE' },
      { count: 0, impactStatus: 'OTHER' }
    ];
  }

  const {
    totalNotSafePeopleCount,
    totalNoResponsePeopleCount,
    totalSafePeopleCount,
    totalOtherPeopleCount
  } = arcCase;

  return [
    { count: totalNotSafePeopleCount, impactStatus: 'NOT_SAFE' },
    { count: totalNoResponsePeopleCount, impactStatus: 'NO_RESPONSE' },
    { count: totalSafePeopleCount, impactStatus: 'SAFE' },
    { count: totalOtherPeopleCount, impactStatus: 'OTHER' }
  ];
};
