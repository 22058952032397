/* eslint-disable import/extensions */
import { FC } from "react";

import { ReactComponent as ChevronUp } from '@/common/icons/chevron-up-solid.svg';
import { useMapTopInfoBoxStyles } from "@/tenant-context/common/components/MapTopInfoBox/MapTopInfoBox.styles";


type Props = {
  title: string,
  value: string | number,
  width?: string,
  bgColor?: string,
  fontColor?: string,
  onClick?: () => void,
  isDropdownBox?: boolean
  isDropdownExpanded?: boolean
  musterCount?: number
}

const MapTopInfoBox: FC<Props> = ({
  title,
  value,
  bgColor = '#FFFFFF',
  fontColor = '#343A40',
  onClick,
  isDropdownBox = false,
  isDropdownExpanded = false,
  musterCount
}) => {
  const { classes } = useMapTopInfoBoxStyles({
    backgroundColor: bgColor,
    color: fontColor,
    isDropdownBox,
    isDropdownExpanded
  });

  return (
    <button className={ classes.infoBox }
      onClick={ onClick }>
      <div className={ classes.infoDiv }>
        <div className={ classes.title }>{ title }</div>
        <div className={ classes.value }>{ value }</div>
      </div>

      { isDropdownBox && (
        <div className={ classes.dropdownIconWrapper }>
          { musterCount && musterCount > 0
            ?
            (<div className={ classes.musterCount }>{ musterCount }</div>)
            :
            null }

          <ChevronUp className={ classes.dropdownArrowIcon }/>
        </div>
      ) }
    </button>
  );
};

export default MapTopInfoBox;
