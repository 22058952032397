/**
 * This is an implementation for reactive filters in Sources
 * https://github.com/mapbox/mapbox-gl-js/issues/10722
 */

import mapboxgl, { AnySourceImpl, GeoJSONSourceRaw } from "mapbox-gl";
import { FC } from "react";
import { Source } from 'react-map-gl';
import { useMap } from "react-map-gl";

import usePrevious from "@/common/hooks/usePrevious";

type FilterableSourceProps = {
  id: string
};

type GeoJSONSourceProps = GeoJSONSourceRaw & FilterableSourceProps;

type FilterableGeoJSONSource = mapboxgl.GeoJSONSource & {
  setFilter?: (filter: object) => void,
  _updateWorkerData: () => void,

  // This type is same as in mapbox source code
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workerOptions: any
};

const polyfillSourceFilterUpdate = (source: AnySourceImpl): FilterableGeoJSONSource => {
  const polyfillableSource = source as FilterableGeoJSONSource;

  if (polyfillableSource.setFilter) {
    return polyfillableSource;
  }

  polyfillableSource.setFilter = function(filter: object) {
    this.workerOptions.filter = filter;
    // This causes a massive memory leak (up to 3G allocation confirmed)
    // Also breaks the filters
    // I have removed this, but being conscious of probable side effects I keep it here
    // Not sure what this did initially
    // this._updateWorkerData();
  };

  return polyfillableSource;
};

const updateFilter = (
  props: GeoJSONSourceProps,
  prevProps: GeoJSONSourceProps,
  source: mapboxgl.AnySourceImpl
) => {
  const { filter } = props;
  const { filter: prevFilter } = prevProps || {};

  if (source.type !== 'geojson') {
    return;
  }

  const polyfilledSource = polyfillSourceFilterUpdate(source);

  if (filter === prevFilter) {
    return;
  }

  polyfilledSource.setFilter?.(filter);
};

const FilterableSource: FC<GeoJSONSourceProps> = (props) => {
  const map = useMap()?.current?.getMap();
  const { id } = props;

  const prevProps = usePrevious(props);
  const source = map && map.getSource(id);

  if (source) {
    updateFilter(props, prevProps, source);
  }

  return (
    <Source
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...props }
    />
  );
};

export default FilterableSource;