import { createModel } from "@rematch/core";
import { GeoJSON } from 'geojson';

import { Dispatch, RootModel, RootState } from "@/core/store";
import {
  createGeofence,
  deleteGeofenceById,
  downloadGeoFencesCsv,
  getGeofence,
  getGeofences,
  getShortGeofence,
  updateGeofence,
  updateGeofenceStatusById
} from "@/tenant-context/control-draw/api/geofence";
import { geofenceSearchQuery, metaDataQuery, shortGeofenceSearchQuery } from "@/tenant-context/control-draw/store/search-string";
import {
  DrawingFilterTypes,
  Geofence,
  GeofenceFilterType,
  GeofenceListTabsTypes,
  GeofencePermissionType,
  GeofenceStatusTypes,
  GeofenceType,
  GeoPolygon,
  MetaData,
  ShortGeofence,
  ViewportBbox
} from "@/tenant-context/control-draw/types/draw";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

type GeofenceState = {
  geofenceList?: PaginatedResult<Geofence>
  allShortGeofenceList?: PaginatedResult<ShortGeofence>
  privateShortGeofenceList?: PaginatedResult<ShortGeofence>
  geofenceListFilterValue: GeofenceFilterType
  shortGeofenceListFilterValue: GeofenceFilterType
  activeTab: GeofenceListTabsTypes
  geofenceTypeFilter: DrawingFilterTypes,
  currentGeoFences: Array<GeoPolygon>,
  currentGeoJson?: GeoJSON,
  currentViewPort: ViewportBbox,
  geofenceType: GeofenceType,
  geofencePermissionType?: GeofencePermissionType,
  editableGeofence?: Geofence,
  visibleGeofenceOnBigMap?: Geofence
}

const geofenceModel = {
  name: 'geofence',
  state: {
    geofenceList: undefined,
    privateGeofenceList: undefined,
    geofenceListFilterValue: '',
    activeTab: GeofenceListTabsTypes.ALL_GEOFENCE,
    geofenceTypeFilter: DrawingFilterTypes.ALL,
    shortGeofenceListFilterValue: {},
    currentGeoFences: [],
    currentGeoJson: undefined,
    currentViewPort: [],
    geofenceType: GeofenceType.GENERAL,
    geofencePermissionType: GeofencePermissionType.PRIVATE,
    editableGeofence: undefined,
    visibleGeofenceOnBigMap: undefined
  } as GeofenceState,
  reducers: {
    SET_GEOFENCE_LIST: (state: GeofenceState, geofenceList: GeofenceState['geofenceList']) => ({
      ...state,
      geofenceList
    }),
    SET_ALL_SHORT_GEOFENCE_LIST: (state: GeofenceState, allShortGeofenceList: GeofenceState['allShortGeofenceList']) => ({
      ...state,
      allShortGeofenceList
    }),
    SET_PRIVATE_SHORT_GEOFENCE_LIST: (state: GeofenceState, privateShortGeofenceList: GeofenceState['privateShortGeofenceList']) => ({
      ...state,
      privateShortGeofenceList
    }),
    SET_GEOFENCE_MANAGE_FILTER_VALUE: (state: GeofenceState, geofenceListFilterValue: GeofenceState['geofenceListFilterValue']) => ({
      ...state,
      geofenceListFilterValue
    }),
    SET_SHORT_GEOFENCE_LIST_FILTER_VALUE: (state: GeofenceState, shortGeofenceListFilterValue: GeofenceState['shortGeofenceListFilterValue']) => ({
      ...state,
      shortGeofenceListFilterValue
    }),
    SET_ACTIVE_TAB: (state: GeofenceState, activeTab: GeofenceState['activeTab']) => ({
      ...state,
      activeTab
    }),
    SET_GEOFENCE_TYPE_FILTER: (state: GeofenceState, geofenceTypeFilter: GeofenceState['geofenceTypeFilter']) => ({
      ...state,
      geofenceTypeFilter
    }),
    SET_CURRENT_GEO_FENCES: (state: GeofenceState, currentGeoFences: GeofenceState['currentGeoFences']) => ({
      ...state,
      currentGeoFences
    }),
    SET_CURRENT_VIEW_PORT: (state: GeofenceState, currentViewPort: GeofenceState['currentViewPort']) => ({
      ...state,
      currentViewPort
    }),
    SET_GEOFENCE_TYPE: (state: GeofenceState, geofenceType: GeofenceState['geofenceType']) => ({
      ...state,
      geofenceType
    }),
    SET_GEOFENCE_PERMISSION_TYPE: (state: GeofenceState, geofencePermissionType: GeofenceState['geofencePermissionType']) => ({
      ...state,
      geofencePermissionType
    }),
    SET_EDITABLE_GEOFENCE: (state: GeofenceState, editableGeofence: GeofenceState['editableGeofence']) => ({
      ...state,
      editableGeofence
    }),
    SET_CURRENT_GEO_JSON: (state: GeofenceState, currentGeoJson: GeofenceState['currentGeoJson']) => ({
      ...state,
      currentGeoJson
    }),
    SET_VISIBLE_GEOFENCE_ON_BIG_MAP: (state: GeofenceState, visibleGeofenceOnBigMap: GeofenceState['visibleGeofenceOnBigMap']) => ({
      ...state,
      visibleGeofenceOnBigMap
    })
  },
  effects: (dispatch: Dispatch) => ({

    async loadGeofenceList(meta: MetaData, state: RootState): Promise<void> {

      const {
        geofence: {
          geofenceListFilterValue
        }
      } = state;

      const geofenceList = await getGeofences(
        meta?.page,
        meta?.size,
        metaDataQuery(meta),
        geofenceSearchQuery(geofenceListFilterValue)
      );

      dispatch.geofence.SET_GEOFENCE_LIST(geofenceList);
    },

    async loadShortGeofenceList(meta: MetaData, state: RootState): Promise<void> {

      const {
        geofence: {
          privateShortGeofenceList,
          allShortGeofenceList,
          shortGeofenceListFilterValue
        }
      } = state;
      const shortGeofenceList = await getShortGeofence(
        meta?.page,
        meta?.size,
        metaDataQuery(meta),
        shortGeofenceSearchQuery(shortGeofenceListFilterValue)
      );


      if (meta.myGeoFences) {
        dispatch.geofence.SET_PRIVATE_SHORT_GEOFENCE_LIST({
          ...shortGeofenceList,
          items: [...privateShortGeofenceList?.items
            ?
            privateShortGeofenceList?.items
            :
            []
          , ...shortGeofenceList?.items]
        });
      } else {
        dispatch.geofence.SET_ALL_SHORT_GEOFENCE_LIST({
          ...shortGeofenceList,
          items: [...allShortGeofenceList?.items ? allShortGeofenceList?.items : [], ...shortGeofenceList?.items]
        });
      }

    },

    async deleteGeofence(tid: string, state: RootState): Promise<void> {

      await deleteGeofenceById(tid);

      dispatch.geofence.SET_SHORT_GEOFENCE_LIST_FILTER_VALUE(
        { ...state.geofence?.shortGeofenceListFilterValue } as GeofenceFilterType
      );
      dispatch.geofence.SET_GEOFENCE_MANAGE_FILTER_VALUE(
        { ...state.geofence?.geofenceListFilterValue } as GeofenceFilterType
      );
    },

    async updateGeofenceStatus(meta: { tid: string, status: GeofenceStatusTypes }, state: RootState): Promise<void> {

      await updateGeofenceStatusById(meta.tid, meta.status);

      dispatch.geofence.SET_SHORT_GEOFENCE_LIST_FILTER_VALUE(
        { ...state.geofence?.shortGeofenceListFilterValue } as GeofenceFilterType
      );
      dispatch.geofence.SET_GEOFENCE_MANAGE_FILTER_VALUE(
        { ...state.geofence?.geofenceListFilterValue } as GeofenceFilterType
      );
    },

    async updateEditableGeofenceStatus(meta: { tid: string, status: GeofenceStatusTypes }): Promise<Geofence> {
      const updatedGeofence = await updateGeofenceStatusById(meta.tid, meta.status);
      dispatch.geofence.SET_EDITABLE_GEOFENCE(updatedGeofence);
      return updatedGeofence;
    },

    async createGeofence(geofence: Geofence, state: RootState): Promise<void> {
      const createdGeofence = await createGeofence(geofence);
      dispatch.geofence.SET_SHORT_GEOFENCE_LIST_FILTER_VALUE(
        { ...state.geofence?.shortGeofenceListFilterValue } as GeofenceFilterType
      );
      dispatch.geofence.SET_GEOFENCE_MANAGE_FILTER_VALUE(
        { ...state.geofence?.geofenceListFilterValue } as GeofenceFilterType
      );

      return createdGeofence;
    },

    async updateGeofence(geofence: Geofence): Promise<Geofence> {
      return await updateGeofence(geofence.tid as string, geofence);
    },

    async visualiseGeofence(tid: string): Promise<void> {
      const geofence = await getGeofence(tid);
      dispatch.geofence.SET_VISIBLE_GEOFENCE_ON_BIG_MAP(geofence);
    },

    closeCreateGeofenceDrawer(): void {
      dispatch.drawer.CLOSE_ALL_DRAWERS();
      return;
    },

    async openAllGeofencesDrawer(): Promise<void> {
      dispatch.drawer.closeRightDrawer();
      dispatch.drawer.openRightDrawer('draw-control');
    },

    async openEditGeofenceDrawer(geofenceId: string): Promise<void> {
      dispatch.drawer.openLeftDrawer('create-geofence');
      const geofenceResponse = await getGeofence(geofenceId);
      dispatch.geofence.SET_EDITABLE_GEOFENCE(geofenceResponse);
      return;
    },

    async openCreateGeofenceDrawer(): Promise<void> {
      dispatch.geofence.SET_EDITABLE_GEOFENCE(undefined);
      dispatch.drawer.openLeftDrawer('create-geofence');
      return;
    },
    async getGeoFencesCsv(): Promise<void> {
      const csVData = await downloadGeoFencesCsv();
      const csVblob = new Blob([csVData], { type: 'text/csv' });
      const url = URL.createObjectURL(csVblob);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.setAttribute('download', 'geoFences.csv');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      URL.revokeObjectURL(url);
    },

    hideGeofencesOnBigMap(): void {
      dispatch.geofence.SET_VISIBLE_GEOFENCE_ON_BIG_MAP(undefined);
    },

    isGeofenceNameExist(name: string, state: RootState): boolean {
      const {
        geofence: {
          allShortGeofenceList,
          editableGeofence
        }
      } = state;

      const match = allShortGeofenceList?.items.find((geofence) => geofence.name === name);

      if (!match) {
        return false;
      }

      return !(editableGeofence && match.tid === editableGeofence.tid);
    }
  })
};


export const geofence = createModel<RootModel>()(geofenceModel);
