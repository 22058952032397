import { createStyles } from "@mantine/core";

import { semanticColorPalette } from "@/core/styles/mantine/palettes";

type Props = {
  isDropdownExpanded: boolean
}

export const useButtonWithOptionsStyles = createStyles((theme, { isDropdownExpanded }: Props) => ({
  container: {
    position: 'relative'
  },
  dropdown: {
    display: isDropdownExpanded ? 'block' : 'none',
    position: 'absolute',
    zIndex: 1000,
    width: '100%',
    boxShadow: theme.other.sdw.right
  },
  dropdownItem: {
    padding: '11px 8px',
    fontSize: theme.fontSizes.xs,
    backgroundColor: theme.colors.neutral[7],
    border: `1px solid ${semanticColorPalette.border.general.lighter}`,
    marginTop: '2px',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.neutral[9],
      color: theme.colors.neutral[0]
    }
  },
  itemOption: {
    all: 'unset'
  }
}));
