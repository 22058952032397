import { DECODED_TENANT_TOKEN_KEY, decodeToken, REGULAR_TOKEN_KEY, SITE_TOKEN_KEY, TENANT_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { showNotification } from "@/common/util/notification";
import { ENV, Environment, environment } from "@/core/config/env";
import { store } from "@/core/store";
import { CONTEXT_KEY, ContextTypes } from "@/core/store/shared-data/sharedData.types";

import { logger } from "./ConsoleLogger";

const isInDevMode = (ENV === Environment.Dev ||  ENV === Environment.Qa);

export const UNSUCCESSFUL_REQUEST_STATUS_LOWER_BOUNDARY = 400;
export const SERVER_ERROR_STATUS_UPPER_BOUNDARY = 500;
export const SERVER_CONNECTION_UNAVAILABLE_STATUS = 503;
export const SERVER_CONNECTION_TIMEOUT_STATUS = 504;
export const TOKEN_EXPIRED_STATUS = 401;
export const ACCESS_DENIED_STATUS = 403;
export const NOT_FOUND_STATUS = 404;
export const UNPROCESSABLE_ENTITY_STATUS = 422;

export const createUrl = (path: string) => new URL(
  path,
  environment.restEndpoint
);

export enum TokenType {
  TENANT = "tenant",
  ACCESS = "access",
  SITE = "site"
}

export type CustomRequestOptions = {
  queryParams?: Record<string, string | number | boolean | undefined>,
  withContentType?: boolean,
  withTenantId?: boolean,
  withAuthorization?: boolean,
  tokenType?: TokenType,
  showApiError?: boolean,
  responseType?: 'json' | 'blob' | 'void'
};

export const getTenantIdFromToken = () => {
  const preDecodedTokenData = browserDatabase.getItem<{ tenantId: string }>(
    DECODED_TENANT_TOKEN_KEY
  );

  const token = browserDatabase.getItem<string>(TENANT_TOKEN_KEY);

  if (!token) {
    return;
  }

  const tokenData = preDecodedTokenData || decodeToken(token);

  if (!tokenData) {
    return null;
  }

  return tokenData.tenantId;
};

export const callApi = async <T>(
  path: string,
  options?: RequestInit & CustomRequestOptions
): Promise<T> => {
  const url = createUrl(path);

  const tenantId = store.getState().commonData?.tenantId;

  const currentContext: ContextTypes | null = browserDatabase.getItem(CONTEXT_KEY);

  const {
    queryParams = {},
    withContentType = true,
    withTenantId = true,
    withAuthorization = true,
    tokenType = currentContext === "platform" ? TokenType.ACCESS : TokenType.TENANT,
    responseType = 'json',
    ...fetchOptions
  } = options || {};

  const authToken = {
    [TokenType.TENANT]: browserDatabase.getItem(TENANT_TOKEN_KEY),
    [TokenType.ACCESS]: browserDatabase.getItem(REGULAR_TOKEN_KEY),
    [TokenType.SITE]: browserDatabase.getItem(SITE_TOKEN_KEY)
  };

  const defaultOptions: RequestInit = {
    method: 'get',
    headers: Object.fromEntries([
      withContentType ? ['Content-Type', 'application/json'] : [],
      withTenantId ? ['X-TenantId', tenantId]: [],
      withAuthorization ? ['Authorization', `Bearer ${authToken[tokenType]}`] : []
    ])
  };

  // Setting default values
  const requestOptions: RequestInit = {
    ...defaultOptions,
    ...fetchOptions,
    headers: {
      ...defaultOptions.headers,
      ...fetchOptions?.headers
    }
  };

  // TODO refactor
  // Will need to change this in the future
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const xTenantId = requestOptions.headers['X-TenantId'];

  if (xTenantId === undefined || xTenantId === null || xTenantId === '') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line fp/no-delete
    delete requestOptions.headers['X-TenantId'];
  }

  // Cast all the values toString
  const castedQueryParams: Record<string, string> = Object.fromEntries(
    Object.entries(queryParams).map(
      ([key, value]) => [
        key,
        typeof value === 'undefined' ? '' : value.toString()
      ],
      {}
    )
  );

  const paramString = Object.keys(castedQueryParams).length > 0
    ? '?' + new URLSearchParams(castedQueryParams)
    : '';

  const response = await fetch(
    url.toString() + paramString,
    requestOptions
  );

  const isFailed = !response.ok || response.status >= UNSUCCESSFUL_REQUEST_STATUS_LOWER_BOUNDARY;
  const isTokenExpired = response.status === TOKEN_EXPIRED_STATUS;
  const isAccessDenied = response.status === ACCESS_DENIED_STATUS;

  // eslint-disable-next-line fp/no-let
  let error: Error | undefined;
  try {

    if (responseType === 'void') {
      return {} as T;
    }

    const responseContents = await response[responseType]();

    if (!isFailed) {
      return responseContents as T;
    } else {
      // HTTP Error handling
      if (responseType === 'blob'){
        showNotification({
          message: 'Server error occurred when processing request.',
          color: 'error'
        });
      } if (response.status === SERVER_CONNECTION_UNAVAILABLE_STATUS ||
        response.status === SERVER_CONNECTION_TIMEOUT_STATUS) {
        showNotification({
          message: 'The system is experiencing connectivity issues. Please try again later. If this issue recurs, please contact the system administrator.',
          color: 'error'
        });
      } else if (response.status >= SERVER_ERROR_STATUS_UPPER_BOUNDARY) {
        if (isInDevMode) {
          const errorMessage = `${responseContents.error}: ${responseContents.message}`;
          logger.error('Server error:', errorMessage);
        }

        showNotification({
          message: options?.showApiError ? responseContents.message : 'Server error occurred. Please try again later or contact system administrator.',
          color: 'error'
        });
      } else if (response.status === TOKEN_EXPIRED_STATUS) {
        showNotification({
          message: 'Your session has expired. Please log in again.',
          color: 'warning'
        });
      } else if (response.status === ACCESS_DENIED_STATUS) {
        showNotification({
          message: 'You do not have access to this resource.',
          color: 'warning'
        });
      } else if (response.status === UNPROCESSABLE_ENTITY_STATUS) {
        const errorMessage = responseContents.message ? responseContents.message : `Couldn't complete the task. Either the data is invalid or some server error.`;
        showNotification({
          message: errorMessage,
          color: 'error'
        });
      } else {
        if (isInDevMode) {
          const errorMessage = responseContents.message ?
            `${responseContents.error}: ${responseContents.message}` :
            JSON.stringify(responseContents, null, 2);

          error = new Error(errorMessage, {
            cause: responseContents.status
          });

          logger.error('Failed response:', responseContents);
        }
      }

      if (!error) {
        const errorMessage = responseContents.message ?
          `${responseContents.error}: ${responseContents.message}` :
          JSON.stringify(responseContents, null, 2);

        error = new Error(errorMessage, {
          cause: responseContents.status
        });
      }

    }
  } catch (err) {

    if (isTokenExpired) {
      showNotification({
        // Commented the below until BE fixes sending 401 for 403s
        // message: 'Your session has expired. Please log in again.',
        message: 'You do not have access to this resource. Please check your permissions or login again.',
        color: 'warning'
      });
      throw new Error( `Your session has expired. Please log in again. err: ${err}`);
    }

    // Handle access denied errors
    if (isAccessDenied) {
      showNotification({
        message: 'You do not have access to this resource.',
        color: 'warning'
      });
      throw new Error(`You do not have access to this resource. err: ${err}`);
    }

    // Handle server connection errors
    if (response.status === SERVER_CONNECTION_UNAVAILABLE_STATUS ||
      response.status === SERVER_CONNECTION_TIMEOUT_STATUS) {
      showNotification({
        message: 'The system is experiencing connectivity issues. Please try again later. If this issue recurs, please contact the system administrator.',
        color: 'error'
      });
      throw new Error( `Server is currently unavailable. Please try again later. err: ${err}`);
    }

    // Handle other >500 server errors
    if (response.status >= SERVER_ERROR_STATUS_UPPER_BOUNDARY) {
      showNotification({
        message: 'Server error occurred. Please try again later or contact system administrator.',
        color: 'error'
      });
      throw new Error( `Server error occurred. Please try again later or contact system administrator. err: ${err}`);
    }

    if (isInDevMode) {
      logger.error('Failed to deserialize JSON:', err);
      showNotification({
        message: `Unable to deserialize JSON: ${err}`,
        color: 'error'
      });
      throw new Error(`Unable to deserialize JSON: ${err}`);
    } else {
      throw new Error(`Error happened while making the request!`);
    }
  }

  throw error;
};
