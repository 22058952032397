import { FC, ReactNode } from "react";

import { useMassCommsSectionStyles } from "@/tenant-context/control-mass-comms/components/MassCommsSection/MassCommsSection.styles";

type Props = {
  children: ReactNode,
  className?: string,
  isFillSpace?: boolean
};

const MassCommsSection: FC<Props> = ({
  className,
  isFillSpace,
  children
}) => {

  const { classes, cx } = useMassCommsSectionStyles({ fillSpace: isFillSpace });

  return (
    <section className={ cx(classes.container, className) }>
      { children }
    </section>
  );
};

export default MassCommsSection;
