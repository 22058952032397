import { createStyles, MantineTheme } from "@mantine/core";

export const useGeofenceMapStatsCardStyles = createStyles((theme: MantineTheme) => ({
  heading: {
    fontSize: 16,
    fontWeight: theme.other.fw.bold
  },
  geofenceStats: {
    position: 'absolute',
    bottom: 36,
    left: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    backgroundColor: theme.colors.primary[4],
    padding: 16,
    transitionDuration: '300ms',
    pointerEvents: 'none',

    '&:hover': {
      opacity: 0.5
    },

    '&[hidden]': {
      display: 'none !important'
    }
  },
  geofenceStatsCardContainer: {
    display: 'flex',
    gap: 8,
    backgroundColor: theme.colors.primary[4]
  },
  statsCard: {
    all: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 120,
    height: 120,
    boxShadow: theme.other.sdw.left,
    borderBottom: `3px solid ${ theme.white }`,
    backgroundColor: theme.colors.neutral[9],
    cursor: 'pointer'
  },
  count: {
    fontSize: 32,
    fontWeight: theme.other.fw.bold,
    lineHeight: theme.other.lh.l,
    color: theme.white
  },
  text: {
    fontSize: 12,
    color: theme.white,
    lineHeight: theme.other.lh.l
  }
}));
