import { FC } from "react";
import { ErrorBoundary } from 'react-error-boundary';

import LocalErrorBoundaryFallbackComponent from './LocalErrorBoundaryFallback.component';

const LocalErrorBoundaryComponent: FC = ({
  children
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={ LocalErrorBoundaryFallbackComponent }
    >
      { children }
    </ErrorBoundary>
  );
};

export default LocalErrorBoundaryComponent;
