import { ActionIcon } from '@mantine/core';
import { FC } from "react";

import ColorSwatch from "@/common/components/ColorSwatch";
import { useColorSwatchesPaletteStyles } from '@/common/components/ColorSwatchesPalette/ColorSwatchesPalette.styles';
import { ReactComponent as CloseIcon } from '@/common/icons/close.svg';

type Props = {
  colors: { color: string, borderColor: string }[]
  onSelect: (color: string) => void
  onCloseColorPalate: () => void
}

const ColorSwatchesPalette: FC<Props> = ({
  colors,
  onSelect,
  onCloseColorPalate
}) => {

  const { classes } = useColorSwatchesPaletteStyles();

  return (
    <div className={ classes.root }>
      <div className={ classes.header }>
        <ActionIcon onClick={ onCloseColorPalate } >
          <CloseIcon />
        </ActionIcon>
      </div>
      <div className={ classes.swatchesContainer }>
        { colors.map((color, index) => (
          <ColorSwatch borderColor={ color.borderColor } color={ color.color } key={ index } onClick={ onSelect }/>
        )) }
      </div>
    </div>
  );
};

export default ColorSwatchesPalette;
