import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';

import { ReactComponent as Bell } from "@/common/icons/bell.svg";
import NotificationSound from '@/common/sounds/alarm.mp3';
import { TestableComponent } from "@/common/types/testable-component";
import { NotificationContainerLayoutParams } from '@/tenant-context/control-live-feed/types/live-feed';

import { useLiveFeedButtonStyles } from "./LiveFeedButton.styles";

type Props = {
    liveFeedCount: number,
    handleOpenFeedDrawer: () => void
    onLayoutChange?: (layoutParams: NotificationContainerLayoutParams) => void
} & TestableComponent;

const LiveFeedButtonComponent: FC<Props> = ({ 
  liveFeedCount,
  handleOpenFeedDrawer,
  onLayoutChange,
  dataTestId = "top-menu-travel-event-feed"
}) => {
  const location = useLocation();

  const buttonRef = useRef<HTMLButtonElement>(null);

  const hasNotifications = liveFeedCount > 0;

  const { classes } = useLiveFeedButtonStyles({ hasNotifications });

  const [previousCounter, setPreviousCounter] = useState(liveFeedCount);

  const audioPlayer = useRef<HTMLAudioElement>(null);

  const isInGlobalMap = useMemo(() => {
    return location.pathname === '/';
  }, [ location ]);
  
  // Uncaught (in promise) DOMException: play() failed because the user didn't interact with the document first.
  // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
  // Hard to reproduce with normal use but need more investigation on how to avoid this error
  
  useEffect(() => {
    if (liveFeedCount > previousCounter) {
      audioPlayer.current?.play();
    }
    setPreviousCounter(liveFeedCount);

    if (buttonRef.current?.getBoundingClientRect().left) {
      onLayoutChange?.({
        rightBorderToWindowGap: isInGlobalMap ?
          (window.innerWidth - buttonRef.current?.getBoundingClientRect().left || 0) - 36 :
          (window.innerWidth - buttonRef.current?.getBoundingClientRect().left || 0) + 24
      });
    }
  }, [ isInGlobalMap, liveFeedCount, onLayoutChange, previousCounter ]);

  const notificationSound = (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio ref={ audioPlayer } src={ NotificationSound } />
  );

  return (
    <>
      { notificationSound }
      <button className={ classes.container } onClick={ handleOpenFeedDrawer }
        data-testid={ dataTestId } ref={ buttonRef }
      >
        { hasNotifications && <div className={ classes.liveFeedCircle }/> }
        { hasNotifications && <div className={ classes.liveFeedCount }>
          { liveFeedCount }
        </div> }
        <Bell />
      </button>
    </>
  );
};

export default LiveFeedButtonComponent;
