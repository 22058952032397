import { createModel } from "@rematch/core";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { MAX_RANK, MIN_RANK } from "@/tenant-context/core/config/ranking";

export type RankingSettingsState = {
  isShowOnlyHighestRanksForPeople: boolean,
  disabledAdapters: string[],
  probabilityThresholdMax: number,
  probabilityThresholdMin: number,
  probabilityThresholdPercentageUpperEnd: number,
  probabilityThresholdPercentageLowerEnd: number,
  isApplyProbabilityThreshold: boolean,
  timeTravelTargetEpoch: number,
  isTimeTravelActive: boolean,
  isGlobe: boolean,
}

const getDefaultState = (): RankingSettingsState => ({
  isShowOnlyHighestRanksForPeople: true,
  isApplyProbabilityThreshold: false,
  probabilityThresholdPercentageUpperEnd: 100,
  probabilityThresholdPercentageLowerEnd: 0,
  probabilityThresholdMax: MAX_RANK,
  probabilityThresholdMin: MIN_RANK,
  disabledAdapters: [] as string[],
  timeTravelTargetEpoch: 0,
  isTimeTravelActive: false,
  isGlobe: false
});

const rankingSettingsModel = ({
  name: 'rankingSettings',
  state: getDefaultState(),
  reducers: {
    SET_IS_GLOBE(
      state: RankingSettingsState,
      isGlobe: boolean
    ) {
      return {
        ...state,
        isGlobe
      };
    },

    TOGGLE_APPLY_PROBABILITY_THRESHOLD(
      state: RankingSettingsState,
      isApply: boolean
    ) {
      return {
        ...state,
        isApplyProbabilityThreshold: isApply
      };
    },

    SET_TIME_TRAVEL(
      state: RankingSettingsState,
      targetEpoch: number | [number, number]
    ) {
      // TODO: add `tab` state to this model (point | range)
      const isRange = Array.isArray(targetEpoch);
      if (!isRange) {
        return {
          ...state,
          isTimeTravelActive: targetEpoch > 0,
          timeTravelTargetEpoch: targetEpoch
        };
      }
    },

    SET_ONLY_SHOW_HIGHEST_RANKS_FOR_PEOPLE(
      state: RankingSettingsState,
      show: boolean
    ) {
      return {
        ...state,
        isShowOnlyHighestRanksForPeople: show
      };
    },

    DISABLE_DATA_SOURCE(
      state: RankingSettingsState,
      payload: string
    ) {
      const {
        disabledAdapters
      } = state;

      const updatedDisabledDataSources = Array.from(new Set([
        ...disabledAdapters,
        payload
      ]));

      return {
        ...state,
        disabledAdapters: updatedDisabledDataSources
      };
    },

    ENABLE_DATA_SOURCE(
      state: RankingSettingsState,
      payload: string
    ) {
      const {
        disabledAdapters: disabledDataSources
      } = state;

      const updatedDisabledDataSources = disabledDataSources.filter(
        (source) => source !== payload
      );

      return {
        ...state,
        disabledAdapters: updatedDisabledDataSources
      };
    },

    RECALCULATE_MINIMUM_PROBABILITY_THRESHOLD(
      state: RankingSettingsState,
      percentage: number[]
    ) {
      const thresholdMin = (MAX_RANK * (percentage[0] / 100));
      const thresholdMax =  (MAX_RANK * (percentage[1] / 100));

      return {
        ...state,
        isApplyProbabilityThreshold:true,
        probabilityThresholdMin: thresholdMin,
        probabilityThresholdMax:thresholdMax,
        probabilityThresholdPercentageUpperEnd: percentage[1],
        probabilityThresholdPercentageLowerEnd: percentage[0]
      };
    },

    RESET_RANKING_SETTING_STATE() {
      return getDefaultState();
    }
  },

  effects: (dispatch: Dispatch) => ({
    toggleShowOnlyHighestRanks(
      show: boolean
    ): void {
      dispatch.peopleLocations.unsubscribeFromPeopleLocationData();

      dispatch.rankingSettings.SET_ONLY_SHOW_HIGHEST_RANKS_FOR_PEOPLE(show);

      dispatch.peopleLocations.subscribeToPeopleLocationData();

      dispatch.peopleBreadcrumbs.UNSAFE_SET_IS_BREADCRUMB_LAYER_ENABLED(!show);
    },

    /** Pass `0` for deactivation, or use the `reset...` effect */
    timeTravelTo(
      targetEpoch: number | [number, number]
    ): void {
      if(Array.isArray(targetEpoch)){
        return;
        // TODO: add support for range time travel
      }
      dispatch.rankingSettings.SET_TIME_TRAVEL(targetEpoch);

      dispatch.locationGraph.setTimeDomain(targetEpoch);

      dispatch.peopleLocations.unsubscribeFromPeopleLocationData();
      dispatch.peopleLocations.subscribeToPeopleLocationData();
    },

    resetRankingSettingsFilters(_: void, state: RootState):void {
      const { isShowOnlyHighestRanksForPeople } = state.rankingSettings;

      if(!isShowOnlyHighestRanksForPeople){
        dispatch.rankingSettings.toggleShowOnlyHighestRanks(true);
      }

      dispatch.rankingSettings.RESET_RANKING_SETTING_STATE();
      dispatch.rankingSettings.RECALCULATE_MINIMUM_PROBABILITY_THRESHOLD([0,100]);

      dispatch.rankingSettings.timeTravelTo(0);
    }
  })
});

export const rankingSettings = createModel<RootModel>()(
  rankingSettingsModel
);