import { List, ThemeIcon } from "@mantine/core";
import { FC } from "react";

import {
  useGlobalSearchResultItemStyles
} from "@/tenant-context/control-search/components/GlobalSearchResultItem/GlobalSearchResultItem.style";
import { SearchResultFeature } from "@/tenant-context/control-search/types/search";
import { getPersonInitials } from "@/tenant-context/visualisation-people/util/getPersonInitials";

type Props = {
  feature: SearchResultFeature,
  isActive:boolean,
  onClickItem: () => void
};

const GlobalSearchResultItem: FC<Props> = ({
  feature,
  isActive,
  onClickItem
}) => {
  const { classes } = useGlobalSearchResultItemStyles(
    { isActive }
  );

  const id = feature.properties.__subject === 'asset'
    ? feature.properties.personId
    : feature.properties.name;

  return (
    <List.Item
      icon={ (
        <ThemeIcon
          size={ 24 }
          radius="xl"
          className={ classes.icon }
        >
          { getPersonInitials(id) }
        </ThemeIcon>
      ) }
      key={ id }
      onClick={ onClickItem }
      className={ classes.item }
    >
      { id }
    </List.Item>
  );
};

export default GlobalSearchResultItem;
