export enum LWPersonStatus {
  GpsTracking = 'GPS_TRACKING',
  CheckIn = 'CHECK_IN',
  TaskStarted = 'TASK_STARTED',
  TaskExtended = 'TASK_EXTENDED',
  TaskOngoing = 'TASK_ONGOING',
  TaskEnded = 'TASK_ENDED',
  MissedCheckInEmergency = 'MISSED_CHECK_IN_EMERGENCY',
  MissedEndTaskEmergency = 'MISSED_END_TASK_EMERGENCY',
  ManualActivatedEmergency = 'MANUAL_ACTIVATED_EMERGENCY',
  DuressCancellationEmergency = 'DURESS_CANCELLED_EMERGENCY'
}

export const PERSON_ON_TASK_STATUSES = [
  LWPersonStatus.TaskStarted,
  LWPersonStatus.TaskOngoing,
  LWPersonStatus.TaskExtended
];

export const PERSON_CHECKED_IN_STATUSES = [
  LWPersonStatus.CheckIn
];

export const PERSON_RED_ALERT_STATUSES = [
  LWPersonStatus.ManualActivatedEmergency,
  LWPersonStatus.DuressCancellationEmergency
];

export const PERSON_YELLOW_ALERT_STATUSES = [
  LWPersonStatus.MissedCheckInEmergency,
  LWPersonStatus.MissedEndTaskEmergency
];

export const PERSON_ALL_ALERT_STATUSES = [
  ...PERSON_RED_ALERT_STATUSES,
  ...PERSON_YELLOW_ALERT_STATUSES
];

export const PERSON_ON_TASK_FILTER = [
  'in',
  ['get', 'status', ['get', 'loneWorker', ['get', 'topRank']]],
  ['literal', PERSON_ON_TASK_STATUSES]
];

export const PERSON_CHECKED_IN_FILTER = [
  'in',
  ['get', 'status', ['get', 'loneWorker', ['get', 'topRank']]],
  ['literal', PERSON_CHECKED_IN_STATUSES]
];

export const PERSON_RED_ALERT_FILTER = [
  'in',
  ['get', 'status', ['get', 'loneWorker', ['get', 'topRank']]],
  ['literal', PERSON_RED_ALERT_STATUSES]
];

export const PERSON_YELLOW_ALERT_FILTER = [
  'in',
  ['get', 'status', ['get', 'loneWorker', ['get', 'topRank']]],
  ['literal', PERSON_YELLOW_ALERT_STATUSES]
];

/* eslint-disable no-magic-numbers */
export const peopleMarkerModifierLayers = [
  {
    id: 'r__person-modified-on-task',
    type: 'symbol',
    filter: PERSON_ON_TASK_FILTER,
    layout: {
      'icon-image': 'person-modified-on-task',
      'icon-size': 0.8,
      'icon-offset': [17, -30]
    }
  },
  {
    id: 'r__person-modified-checked-in',
    type: 'symbol',
    filter: PERSON_CHECKED_IN_FILTER,
    layout: {
      'icon-image': 'person-modified-checked-in',
      'icon-size': 0.8,
      'icon-offset': [17, -30]
    }
  }
];

export const peopleAlarmMarkerModifierLayers = [
  {
    id: 'r__person-modified-red-alert',
    type: 'symbol',
    beforeId: 'r__countryTravelersCountSource-cluster',
    filter: PERSON_RED_ALERT_FILTER,
    layout: {
      'icon-image': 'person-modified-red-alert',
      'icon-size': 0.8,
      'icon-offset': [17, -30]
    }
  },
  {
    id: 'r__person-modified-yellow-alert',
    type: 'symbol',
    filter: PERSON_YELLOW_ALERT_FILTER,
    beforeId: 'r__person-modified-red-alert',
    layout: {
      'icon-image': 'person-modified-yellow-alert',
      'icon-size': 0.8,
      'icon-offset': [17, -30]
    }
  }
];
