
import { ThemeComponent } from "@/core/styles/mantine/types";

export const ScrollAreaStyle: ThemeComponent = {
  // TODO dedupe with TextInput and other Inputs
  styles: () => ({
    viewport: {
      // Select / MultiSelect component item container padding removal
      '& > * > *': {
        padding: 0 + ' !important'
      }
    }
  })
};
