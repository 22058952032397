import { FC, useMemo } from "react";

import { useVideoThumbnailStyles } from '@/tenant-context/employee-app-action-response-center/components/ui/VideoThumbnail/VideoThumbnail.style';
import { EmergencyVideo } from "@/tenant-context/employee-app-action-response-center/types/emergency-video";

type Props = {
  emergencyVideo: EmergencyVideo
  onClickThumbnail: () => void
};

const VideoThumbnailComponent: FC<Props> = ({
  emergencyVideo,
  onClickThumbnail
}) => {
  const { classes } = useVideoThumbnailStyles();

  const dateTime = useMemo(() => {
    const timestamp_ = emergencyVideo.timestamp;

    if (!timestamp_) {
      return '';
    }

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date(timestamp_);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${day} ${month} ${year} - ${hours}:${minutes}:${seconds}`;
  }, [emergencyVideo]);

  return (
    <button className={ classes.container } onClick={ onClickThumbnail }>
      <div className={ classes.metaData }>
        <div className={ classes.metaDataLabel }>ID</div>
        <div className={ classes.metaDataLabel }>Date & time</div>
        <div className={ classes.metaDataValue }> { emergencyVideo.videoId } </div>
        <div className={ classes.metaDataValue }> { dateTime } </div>
      </div>
      { /* eslint-disable-next-line jsx-a11y/media-has-caption */ }
      <video className={ classes.poster } src={ emergencyVideo.url }>
        <track/>
      </video>
    </button>
  );
};

export default VideoThumbnailComponent;
