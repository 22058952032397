import { createStyles } from "@mantine/core";

import { CSS_KEY } from '@/core/styles/mantine/constants';

type Params = {
  riskLevelColor?: string;
}

export const useDetailedCountryReportStyles = createStyles((theme, { riskLevelColor }: Params) =>({
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100%'
  },
  header: {
    padding: `${theme.spacing.xl}px 32px`,
    borderBottom: `1px solid ${theme.colors.neutral[7]}`
  },
  actionIcon: {
    height: 24,
    width: 24
  },
  body: {
    flexGrow: 1,
    padding: 32,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent'
    }
  },
  bodyExpanded: {
    padding: '32px calc((100vw - 856px) / 2)'
  },
  flag: {
    borderRadius: 5,
    overflow: 'hidden',
    height: '40px !important',
    width: '55px !important',
    'svg': {
      margin: 0,
      height: '40px !important',
      width: '55px !important'
    }
  },
  riskProviderIcon: {
    svg: {
      height: 22
    }
  },
  riskLevelContainer: {
    padding: theme.spacing.xl,
    gap: theme.spacing.sm,
    borderRadius: theme.radius.sm,
    border: `1px solid ${'#122B3E'}`,
    backgroundColor: `${theme.colors.white[0]}05`
  },
  countryRiskLevel: {
    backgroundColor: `${theme.colors.white[0]}05`,
    [`.${CSS_KEY}-Progress-bar`]: {
      backgroundColor: riskLevelColor
    }
  },
  sectionHeading: {
    fontSize: 18,
    fontWeight: 700
  },
  reportSectionHeading: {
    fontSize: 24,
    fontWeight: 700
  },
  subSectionHeading: {
    fontSize: 16,
    fontWeight: 700
  },
  indicatorPill: {
    borderRadius: theme.radius.xs,
    padding: '4px 12px',
    fontSize: 12,
    fontWeight: 400,
    textTransform: 'none',
    height: 40,
    justifyContent: 'flex-start',
    span: {
      display: 'flex',
      alignItems: 'center',
      gap: 4
    },
    svg: {
      margin: 1,
      height: 16,
      width: 16
    }
  },
  infoPill: {
    borderRadius: theme.radius.xs,
    padding: '4px 12px',
    fontSize: 12,
    minHeight: 32,
    justifyContent: 'flex-start',
    backgroundColor: `${theme.colors.neutral[6]}33`,
    svg: {
      margin: 1,
      height: 19,
      width: 19
    }
  },
  eventPill: {
    borderRadius: theme.radius.xs,
    padding: '4px 12px',
    fontSize: 12,
    height: 40,
    justifyContent: 'flex-start',
    backgroundColor: `${theme.colors.neutral[6]}33`
  },
  text: {
    color: theme.colors.neutral[5]
  },
  content: {
    '&, *': {
      color: `${theme.colors.neutral[5]} !important`
    },
    p: {
      marginBottom: theme.spacing.xs
    },
    li: {
      marginBottom: theme.spacing.xs
    }
  },
  titleIcon: {
    svg: {
      height: 32,
      width: 32
    }
  },
  loadingContainer: {
    padding: 32
  }
}));
