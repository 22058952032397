import {
  ActionIcon,
  Flex,
  NumberInput,
  NumberInputHandlers,
  Stack,
  useMantineTheme
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import {
  IconChevronDown,
  IconChevronLeft,
  IconChevronRight,
  IconChevronUp
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { FC, useCallback, useMemo, useRef } from "react";

import { ReactComponent as CalendarIcon } from "@/common/icons/calendar-days.svg";
import { useRankingSettingsStyles } from "@/tenant-context/control-ranking-settings/components/RankingSettings/RankingSettings.styles";

const MAX_HOUR_INPUT_VALUE = 23;
const MAX_MINUTE_INPUT_VALUE = 59;
const UTC_OFFSET = dayjs().utcOffset();

type Timestamp = number;
type TimestampRange = [Timestamp, Timestamp];

type Props = {
  timestampEpoch: Timestamp | TimestampRange;
  onTimeChange: (
    timestampEpoch: Timestamp | TimestampRange,
    rangeMode: boolean
  ) => void;
};

const formatter = (value = "0") => {
  if (value.length < 2) {
    return `0${value}`;
  }

  return value.slice(-2);
};

const parser = (value = "0") => value.replace(/\$\s?|(,*)/g, "");

const prohibitLetters = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const isLetterChar = e.key.length === 1 && /[^0-9]/.test(e.key);
  if (isLetterChar) {
    e.preventDefault();
  }
};

const RankingSettings: FC<Props> = ({ timestampEpoch, onTimeChange }) => {
  const theme = useMantineTheme();
  // range for simplicity, `end` is optional
  const [start, end] = useMemo(() => {
    const now = dayjs().utc();
    if (typeof timestampEpoch === "number") {
      return [dayjs.utc(timestampEpoch || now).second(0), null];
    }

    return [
      dayjs(timestampEpoch[0] || now),
      timestampEpoch[1]
        ? dayjs(timestampEpoch[1]).second(0)
        : dayjs(timestampEpoch[0] || now)
          .second(0)
          .add(1, "day")
    ];
  }, [timestampEpoch]);

  const handleTimeChange = useCallback(
    (newStart: dayjs.Dayjs, newEnd: dayjs.Dayjs | null) => {
      if (newEnd) {
        onTimeChange([newStart.valueOf(), newEnd.valueOf()], true);
      } else {
        onTimeChange(newStart.valueOf(), false);
      }
    },
    [onTimeChange]
  );

  const isRangeMode = !!end;

  const handleChangeDatePicker = useCallback(
    (value: Date) => {
      const date = dayjs(value).date();
      const month = dayjs(value).month();
      const year = dayjs(value).year();
      const newValue = start.utc().year(year).month(month).date(date);
      handleTimeChange(newValue, null);
    },
    [handleTimeChange, start]
  );

  const handleChangeHoursStart = useCallback(
    (value: number) => {
      if (value > MAX_HOUR_INPUT_VALUE) {
        return;
      }
      const newValue = start.hour(value);
      handleTimeChange(newValue, null);
    },
    [handleTimeChange, start]
  );

  const handleChangeMinutesStart = useCallback(
    (value: number) => {
      if (value > MAX_MINUTE_INPUT_VALUE) {
        return;
      }
      const newValue = start.minute(value);
      handleTimeChange(newValue, null);
    },
    [handleTimeChange, start]
  );

  const handleClickNextDay = useCallback(() => {
    const newValue = start.add(1, "day");
    handleTimeChange(newValue, null);
  }, [handleTimeChange, start]);

  const handleClickPrevDay = useCallback(() => {
    const newValue = start.subtract(1, "day");
    handleTimeChange(newValue, null);
  }, [handleTimeChange, start]);

  // reuse handlers for both points in time and range
  const handlersHourInputStart = useRef<NumberInputHandlers>();
  const handlersMinuteInputStart = useRef<NumberInputHandlers>();

  const handleIncreaseHourStart = useCallback(() => {
    handlersHourInputStart.current?.increment();
  }, []);
  const handleDecreaseHourStart = useCallback(() => {
    handlersHourInputStart.current?.decrement();
  }, []);
  const handleIncreaseMinuteStart = useCallback(() => {
    handlersMinuteInputStart.current?.increment();
  }, []);
  const handleDecreaseMinuteStart = useCallback(() => {
    handlersMinuteInputStart.current?.decrement();
  }, []);
  // TODO: add handlers for "End";

  const { classes } = useRankingSettingsStyles();

  const pointInTimeJSX = !isRangeMode && (
    <Stack spacing={ 16 }>
      <Flex gap={ 8 }>
        <ActionIcon onClick={ handleClickPrevDay } sx={ { flexShrink: 0 } } className={ classes.actionIcon }>
          <IconChevronLeft color={ theme.colors.blue[4] } />
        </ActionIcon>
        <DatePicker
          sx={ { width: "100%" } }
          icon={ <CalendarIcon /> }
          inputFormat="D MMM YYYY"
          placeholder="Pick date"
          clearable={ false }
          value={ start.subtract(UTC_OFFSET, "minute").toDate() }
          onChange={ handleChangeDatePicker }
        />

        <ActionIcon onClick={ handleClickNextDay } sx={ { flexShrink: 0 } } className={ classes.actionIcon }>
          <IconChevronRight color={ theme.colors.blue[4] } />
        </ActionIcon>
      </Flex>

      <Flex gap={ 8 } justify={ "center" }>
        <Stack spacing={ 2 }>
          <ActionIcon onClick={ handleIncreaseHourStart } className={ classes.actionIcon }>
            <IconChevronUp color={ theme.colors.blue[4] } />
          </ActionIcon>
          <NumberInput
            size="lg"
            className={ classes.numberInput }
            hideControls
            value={ start.hour() }
            onChange={ handleChangeHoursStart }
            min={ 0 }
            max={ MAX_HOUR_INPUT_VALUE }
            parser={ parser }
            handlersRef={ handlersHourInputStart }
            onKeyDown={ prohibitLetters }
            formatter={ formatter }
          />
          <ActionIcon onClick={ handleDecreaseHourStart } className={ classes.actionIcon }>
            <IconChevronDown color={ theme.colors.blue[4] } />
          </ActionIcon>
        </Stack>
        <Stack spacing={ 2 }>
          <ActionIcon onClick={ handleIncreaseMinuteStart } className={ classes.actionIcon }>
            <IconChevronUp color={ theme.colors.blue[4] } />
          </ActionIcon>
          <NumberInput
            size="lg"
            className={ classes.numberInput }
            hideControls
            value={ start.minute() }
            onChange={ handleChangeMinutesStart }
            min={ 0 }
            max={ MAX_MINUTE_INPUT_VALUE }
            parser={ parser }
            handlersRef={ handlersMinuteInputStart }
            onKeyDown={ prohibitLetters }
            formatter={ formatter }
          />
          <ActionIcon onClick={ handleDecreaseMinuteStart } className={ classes.actionIcon }>
            <IconChevronDown color={ theme.colors.blue[4] } />
          </ActionIcon>
        </Stack>
      </Flex>
    </Stack>
  );

  return (
    <div className={ classes.root }>{ !isRangeMode ? pointInTimeJSX : null }</div>
  );
};

export default RankingSettings;
