import { createStyles } from "@mantine/core";

import { CSS_KEY } from '@/core/styles/mantine/constants';

type Args = {
  riskLevelIconColor?: string
  riskLevelTextColor?: string
  criticallyColor?: string
  criticallyTextColor?: string
  isFullHeader?: boolean
  isRiskLevelOne?: boolean
  isFromRight?: boolean
  position?: number
  isClosed?: boolean
  source?: string
};

export const useLiveFeedNotificationStyles = createStyles((
  theme,
  {
    riskLevelIconColor,
    riskLevelTextColor,
    criticallyColor,
    criticallyTextColor,
    isFullHeader,
    isRiskLevelOne,
    isFromRight,
    position,
    isClosed,
    source
  }: Args
) => ({
  container: {
    backgroundColor: theme.colors.neutral[9],
    borderBottom: isFromRight ? `0.5px solid ${theme.colors.neutral[6]}` : 'none',
    boxShadow: !isFromRight ? '4px 4px 8px 0px #00000040' : 'none',
    marginTop: position ? `${position * 42}px` : '0px',
    transition: 'transform 0.5s ease-in-out, opacity 0.3s ease-in-out 0.2s, marginTop 0.3s ease-in-out'
  },
  hiddenContainer: {
    transform: 'translateX(100%)',
    opacity: 0
  },
  remove: {
    position: 'absolute',
    left: '-11px',
    top: '-11px'
  },
  content: {
    width: '337px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    color: theme.colors.neutral[5]
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    width: '100%',
    color: riskLevelTextColor ? riskLevelTextColor : criticallyTextColor,
    backgroundColor: riskLevelIconColor ? isFullHeader ? riskLevelIconColor : theme.colors.neutral[9] : criticallyColor,
    borderTop: !isFullHeader ? `4px solid ${riskLevelIconColor}` : 'none',
    fontSize: theme.fontSizes.xs,
    cursor: 'pointer',
    path: {
      fill:  criticallyTextColor ?  criticallyTextColor : isFullHeader ? theme.white : riskLevelIconColor
    }
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '18px',
    gap: '8px',
    color: criticallyTextColor,
    '> svg': {
      opacity: 0.65
    }
  },
  critically: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    maxHeight: '18px'
  },
  contentBody:{
    width: '100%',
    padding: '24px 16px 8px'
  },
  contentBodyHeader: {
    display: 'flex',
    gap: '8px'
  },
  profileName: {
    color: theme.white,
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fw.bold,
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    maxHeight: '3em',
    lineHeight: '1.5em',
    marginBottom: '8px'
  },
  location: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs
  },
  source: {
    color: theme.colors.neutral[6],
    fontSize: '10px',
    fontStyle: 'italic'
  },
  riskLevelContainer: {
    maxHeight: '18px',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    gap: '4px'
  },
  riskLevelIdText:{
    position: 'absolute',
    color: isRiskLevelOne ? theme.white : theme.colors.neutral[9],
    fontSize:theme.fontSizes.xs,
    fontWeight:theme.other.fw.bold,
    marginLeft: '6px',
    marginTop: '4px'
  },
  riskLevelIcon:{
    position: 'relative',
    color: riskLevelTextColor,
    '> *': {
      fill: riskLevelIconColor
    }
  },
  riskNameContainer: {
    maxHeight: '18px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    marginLeft: 'auto',
    '> svg': {
      opacity: 0.5,
      width: source === 'max-security' ? 24 : undefined, // Max Security icons are bigger
      '> path': {
        fill: theme.white
      }
    }
  },
  contentBodyDescription: {
    color: theme.white,
    fontSize: theme.fontSizes.xs,
    marginBottom: '8px'
  },
  impactedContainer:{
    marginTop: '16px',
    width: '100%',
    display: 'flex',
    gap: '16px',
    maxHeight: '34px'
  },
  impactedInfo:{
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    borderTop: `1px solid ${theme.colors.neutral[6]}`,
    borderBottom: `1px solid ${theme.colors.neutral[6]}`
  },
  impactedTitle:{
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs
  },
  impactedCount:{
    marginLeft: 'auto',
    color: theme.white,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold,
    lineHeight: '18px',
    padding: '7px 0px'
  },
  footer: {
    width: '100%',
    padding: '16px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs
  },
  footerTime: {
    display: 'flex',
    color: theme.colors.neutral[5],
    alignItems: 'center',
    gap: '8px'
  },
  zoomIcon: {
    marginLeft: -12,
    marginTop: -16,
    marginBottom: -16
  },
  crosshair: {
    fill: theme.colors.blue[4]
  },
  closed: {
    padding: '0 8px',
    backgroundColor: theme.colors.neutral[6],
    borderRadius: '60px',
    color: theme.white
  },
  button: {
    height: '24px',
    width: '84px',
    padding: '0 8px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [`.${CSS_KEY}-Button-inner`]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '24px',
      width: '84px'
    }
  },
  caseOwnerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '8px'
  },
  caseOwnerTitle: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs
  },
  caseOwnerName: {
    color: isClosed ? theme.colors.neutral[4] : theme.colors.dodgerBlue[0],
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold
  },
  caseOwnerJobTitle: {
    color: theme.white
  },
  avatar: {
    filter: isClosed ? 'grayscale(100%)' : 'none'
  }
}));
