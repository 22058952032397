import { createStyles } from "@mantine/core";

export const useProfileDocumentsTabsStyles = createStyles((theme) => ({
  block: {
    marginBottom: 64
  },
  tab: {
    '&[data-active]': {
      backgroundColor: theme.colors.neutral[8]
    }
  }
}));