import { Button, Tabs } from "@mantine/core";
import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ChevronRightIcon } from '@/common/icons/chevron.svg';
import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState } from "@/core/store";
import ProfileEmergencyContactTabSections from "@/tenant-context/control-profile/components/tabs/ProfileEmergencyContactTab/ProfileEmergencyContactTabSections.component";
import ProfileAttributes from "@/tenant-context/control-profile/components/tabs/ProfileProfileTab/sections/ProfileAttributes.component";
import ProfileCompanyDetails from "@/tenant-context/control-profile/components/tabs/ProfileProfileTab/sections/ProfileCompanyDetails.component";
import ProfileDistinguishingFeatures from "@/tenant-context/control-profile/components/tabs/ProfileProfileTab/sections/ProfileDistinguishingFeatures.component";
import ProfileMedicalDetails from "@/tenant-context/control-profile/components/tabs/ProfileProfileTab/sections/ProfileMedicalDetails.component";
import ProfilePersonalDetails from "@/tenant-context/control-profile/components/tabs/ProfileProfileTab/sections/ProfilePersonalDetails.component";
import { useProfileTabStyles } from "@/tenant-context/employee-app-action-response-center/components/tabs/ProfileTab/ProfileTab.style";

enum ProfileGeneralSection {
  Personal = 'Personal',
  Company = 'Company',
  Medical = 'Medical',
  EmergencyContacts = 'EmergencyContacts',
  Attributes = 'Attributes',
  DistinguishingFeatures = 'DistinguishingFeatures'
}

const ProfileTab: FC = () => {
  const currentUserId = useSelector((state: RootState) => state.userProfile.id);

  const { classes } = useProfileTabStyles();

  const {
    profile: {
      SET_PROFILE_ID
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    if (!currentUserId) {
      return;
    }

    SET_PROFILE_ID(currentUserId);
  }, [SET_PROFILE_ID, currentUserId]);

  const handleFullProfileClick = useCallback(() => {
    window.open(RouterConfig.routes.bigProfile(currentUserId), '_blank');
  }, [currentUserId]);

  if (!currentUserId) {
    return null;
  }

  return (
    <Tabs defaultValue={ ProfileGeneralSection.Personal } variant="default">
      <div className={ classes.buttonContainer }>
        <Button
          onClick={ handleFullProfileClick }
          size={ 'md' }
          rightIcon={ (
            <ChevronRightIcon style={ { rotate: '180deg' } } />
          ) }
        >
          Full Profile
        </Button>
      </div>

      <Tabs.List className={ classes.tabList }>
        <Tabs.Tab value={ ProfileGeneralSection.Personal }>
          Personal
        </Tabs.Tab>

        <Tabs.Tab value={ ProfileGeneralSection.Company }>
          Company
        </Tabs.Tab>

        <Tabs.Tab value={ ProfileGeneralSection.Medical }>
          Medical
        </Tabs.Tab>

        <Tabs.Tab value={ ProfileGeneralSection.EmergencyContacts }>
          Emergency Contacts
        </Tabs.Tab>

        <Tabs.Tab value={ ProfileGeneralSection.Attributes }>
          Attributes
        </Tabs.Tab>

        <Tabs.Tab value={ ProfileGeneralSection.DistinguishingFeatures }>
          Distinguishing Features
        </Tabs.Tab>
      </Tabs.List>

      <div className={ classes.root }>
        <Tabs.Panel value={ ProfileGeneralSection.Personal }>
          <ProfilePersonalDetails />
        </Tabs.Panel>

        <Tabs.Panel value={ ProfileGeneralSection.Company }>
          <ProfileCompanyDetails />
        </Tabs.Panel>

        <Tabs.Panel value={ ProfileGeneralSection.Medical }>
          <ProfileMedicalDetails />
        </Tabs.Panel>

        <Tabs.Panel value={ ProfileGeneralSection.EmergencyContacts }>
          <ProfileEmergencyContactTabSections
            isEditable={ false }
          />
        </Tabs.Panel>

        <Tabs.Panel value={ ProfileGeneralSection.Attributes }>
          <ProfileAttributes />
        </Tabs.Panel>

        <Tabs.Panel value={ ProfileGeneralSection.DistinguishingFeatures }>
          <ProfileDistinguishingFeatures />
        </Tabs.Panel>
      </div>
    </Tabs>
  );
};

export default ProfileTab;
