import { useDisclosure } from "@mantine/hooks";
import { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import usePermission from "@/common/hooks/usePermission";
import { showNotification } from "@/common/util/notification";
import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState } from "@/core/store";
import MassCommsFooterComponent from "@/tenant-context/control-mass-comms/components/MassCommsFooter/MassCommsFooter.component";
import { MassCommsPoliciesConfig } from "@/tenant-context/control-mass-comms/config/mass-comms.policies";
import { MASS_COMMS_STEPS } from "@/tenant-context/control-mass-comms/types/MassComms.types";
import { getPlainTextFromHtml } from "@/tenant-context/control-mass-comms/utils";

type Props = {
  isInTemplateFlow?: boolean
}

const MassCommsFooter: FC<Props> = ({
  isInTemplateFlow = false
}) => {
  const { routes } = RouterConfig;
  const isMassCommsReportsPermissionAvailable = usePermission(MassCommsPoliciesConfig.MC_REPORTS_PAGE);

  const navigate = useNavigate();
  const {
    currentStep,
    communicationName,
    deliveryChannels,
    emailContent,
    selectedRecipients,
    massCommsNavigation,
    smsContent,
    currentCommunicatTid,
    appCommunicationContent
  } = useSelector((state: RootState) => state.massComms);

  const {
    massComms: {
      SET_CURRENT_STEP,
      saveCommunicationsConfig,
      saveSelectedRecipients,
      sendCommunication,
      navigateToMassComms,
      resetMassCommsState,
      setTemplateManagementView,
      saveTemplate,
      calculateChannelCount
    },
    arc: {
      SET_IS_ARC_OPEN
    },
    massCommsReport: {
      SET_IS_MASS_COMMS_FROM_LOOKUP
    }
  } = useDispatch<Dispatch>();

  const [opened, { open: openNotificationSendModal, close: closeNotificationSendModal }] = useDisclosure(false);

  const handleNextStep = useCallback(
    async () => {

      if (currentStep === MASS_COMMS_STEPS.CREATE_COMMS) {
        saveCommunicationsConfig();
      }

      if (currentStep === MASS_COMMS_STEPS.RECIPIENT_SELECTION) {
        saveSelectedRecipients();
        calculateChannelCount();
      }

      if (currentStep === MASS_COMMS_STEPS.SUMMARY) {
        openNotificationSendModal();
      }

      // eslint-disable-next-line no-magic-numbers
      SET_CURRENT_STEP(currentStep < MASS_COMMS_STEPS.SUMMARY ? currentStep + 1 : currentStep);
    },
    [ SET_CURRENT_STEP, calculateChannelCount, currentStep, openNotificationSendModal, saveCommunicationsConfig,
      saveSelectedRecipients]
  );

  const handlePreviousStep = useCallback(
    () => SET_CURRENT_STEP(currentStep > 0 ? currentStep - 1 : currentStep),
    [SET_CURRENT_STEP, currentStep]
  );

  const handleCancel = useCallback(
    () => {
      resetMassCommsState();
      if (isInTemplateFlow) {
        setTemplateManagementView(true);
        return;
      }

      if (massCommsNavigation.fromArc) {
        navigateToMassComms({ ...massCommsNavigation, isOpen: false });
        SET_IS_ARC_OPEN(true);
      } else if (massCommsNavigation.from === 'look-up') {
        navigate(routes.lookupTool);
      } else {
        navigate(routes.massComms);
      }
    },
    [
      resetMassCommsState,
      isInTemplateFlow,
      massCommsNavigation,
      setTemplateManagementView,
      navigateToMassComms,
      SET_IS_ARC_OPEN,
      navigate,
      routes.lookupTool,
      routes.massComms
    ]
  );

  /**
   * This checks whether we need to disable the next button, based on the validation logics
   */
  const isNextItemDisabled = useMemo(() => {
    if (currentStep === MASS_COMMS_STEPS.CREATE_COMMS) {
      const isNoDeliveryChannelsSelected = !deliveryChannels.app && !deliveryChannels.sms
        && !deliveryChannels.email && !deliveryChannels.voice;
      const isEmptyEmailContent = deliveryChannels.email && (emailContent.subject.trim() === '' || getPlainTextFromHtml(emailContent.body) === '');
      const isEmptySmsContent = deliveryChannels.sms && (smsContent.body === '');
      const isEmptyAppContent = deliveryChannels.app && (appCommunicationContent.header.trim() === '' || appCommunicationContent.content.trim() === '');

      if (!communicationName || communicationName.length === 0 ||
        isNoDeliveryChannelsSelected || isEmptyEmailContent || isEmptySmsContent || isEmptyAppContent) {
        return true;
      }
    }

    if (currentStep === MASS_COMMS_STEPS.RECIPIENT_SELECTION) {
      return selectedRecipients.length === 0;
    }

    return false;
  }, [currentStep, deliveryChannels.app, deliveryChannels.sms,
    deliveryChannels.email, deliveryChannels.voice, emailContent.subject,
    emailContent.body, smsContent.body, communicationName, selectedRecipients.length,
    appCommunicationContent.header, appCommunicationContent.content]);

  const handleTemplateSaveRequest = useCallback(() => {
    showNotification({
      title: 'Template Save',
      message: 'Current communication has been saved as a template.',
      color: 'success'
    });
    saveTemplate();

    resetMassCommsState();
    setTemplateManagementView(true);
  }, [ resetMassCommsState, saveTemplate, setTemplateManagementView ]);

  const handleSendNotificationRequest = useCallback(() => {
    sendCommunication();
    closeNotificationSendModal();

    showNotification({
      title: 'Communication Sending',
      message: 'Your communication is being sent to your selected recipients.',
      color: 'success'
    });

    if (massCommsNavigation.fromArc) {
      resetMassCommsState();
      navigateToMassComms({ ...massCommsNavigation, isOpen: false });
      SET_IS_ARC_OPEN(true);
    } else {
      resetMassCommsState();
      if (isMassCommsReportsPermissionAvailable) {
        if (massCommsNavigation?.from === 'look-up') {
          SET_IS_MASS_COMMS_FROM_LOOKUP(true);
        }
        navigate(routes.massCommsReports(currentCommunicatTid));
      } else {
        navigate(routes.massComms);
      }
    }
  }, [
    SET_IS_ARC_OPEN,
    SET_IS_MASS_COMMS_FROM_LOOKUP,
    closeNotificationSendModal,
    currentCommunicatTid,
    isMassCommsReportsPermissionAvailable,
    massCommsNavigation,
    navigate,
    navigateToMassComms,
    resetMassCommsState,
    routes,
    sendCommunication
  ]);

  return (
    <MassCommsFooterComponent
      currentStep={ currentStep }
      isNextItemDisabled={ isNextItemDisabled }
      onNextStepRequested={ handleNextStep }
      onPreviousStepRequested={ handlePreviousStep }
      isConfirmationModalOpen={ opened }
      isInTemplateFlow={ isInTemplateFlow }
      onConfirmationModalClose={ closeNotificationSendModal }
      onNavigationCancel={ handleCancel }
      onTemplateSaveRequested={ handleTemplateSaveRequest }
      onSendNotificationRequest={ handleSendNotificationRequest }
    />
  );
};

export default MassCommsFooter;
