import { FeatureCollection, GeoJsonProperties, LineString } from "geojson";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import useLayerListener from "@/common/hooks/useLayerListener";
import { RootState } from "@/core/store";
import { PeopleContext } from "@/tenant-context/visualisation-people/context/People.context";
import PeopleLocationLayerComponent
  from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.component";
import {
  LWPersonStatus, PERSON_ALL_ALERT_STATUSES
} from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config";

const PeopleLocationLayer: FC = () => {
  const { current: map } = useMap();
  const {
    handlePersonLocationHover,
    handlePersonLocationLeave,
    handlePersonLocationClick,
    handlePersonClusterLocationClick,
    calculatePopupOffset,
    lineEndCoordinates
  } = useContext(PeopleContext);

  const disabledAdapters = useSelector((state: RootState) => state.rankingSettings.disabledAdapters);
  const currentBigMapPopup = useSelector((state: RootState) => state.bigMap.currentBigMapPopup);
  const isPopupLineEnabled = currentBigMapPopup === "PersonLocation";

  const minRankThreshold = useSelector(
    (state: RootState) => state.rankingSettings.probabilityThresholdMin
  );

  const maxRankThreshold = useSelector(
    (state: RootState) => state.rankingSettings.probabilityThresholdMax
  );

  const isMinRankThresholdApplied = useSelector(
    (state: RootState) => state.rankingSettings.isApplyProbabilityThreshold
  );

  const geoData = useSelector((state: RootState) => state.peopleLocations.geoData);

  const generalGeoData = useMemo(() => {
    const filteredFeatures = geoData?.features.filter((feature) => {
      if (feature.properties?.loneWorkerStatus) {
        return !PERSON_ALL_ALERT_STATUSES.includes(
          feature.properties?.loneWorkerStatus as LWPersonStatus
        );
      } else {
        return true;
      }
    });

    return { ...geoData, features: filteredFeatures };

  }, [geoData]);

  const alarmGeoData = useMemo(() => {
    const filteredFeatures = geoData?.features.filter((feature) => {
      if (feature.properties?.loneWorkerStatus) {
        return PERSON_ALL_ALERT_STATUSES.includes(
          feature.properties?.loneWorkerStatus as LWPersonStatus
        );
      } else {
        return false;
      }
    });

    return { ...geoData, features: filteredFeatures };

  }, [geoData]);
  const [popupLine, setPopupLine] = useState<FeatureCollection<LineString, GeoJsonProperties>>();

  // People layer hover handler
  useLayerListener(
    'mouseenter',
    'r__peopleLocationSnapshotLayer',
    handlePersonLocationHover
  );

  useLayerListener(
    'mouseenter',
    'r__peopleLocationAlarmSnapshotLayer',
    handlePersonLocationHover
  );

  useLayerListener(
    'mouseleave',
    'r__peopleLocationSnapshotLayer',
    handlePersonLocationLeave
  );

  useLayerListener(
    'mouseleave',
    'r__peopleLocationAlarmSnapshotLayer',
    handlePersonLocationLeave
  );

  // People layer click handler
  useLayerListener(
    'click',
    'r__peopleLocationSnapshotLayer',
    handlePersonLocationClick
  );

  useLayerListener(
    'click',
    'r__peopleLocationAlarmSnapshotLayer',
    handlePersonLocationClick
  );

  // Cluster layer click handler
  useLayerListener(
    'click',
    'r__peopleLocationSnapshotLayer-cluster',
    handlePersonClusterLocationClick
  );

  useLayerListener(
    'click',
    'r__peopleLocationAlarmSnapshotLayer-cluster',
    handlePersonClusterLocationClick
  );

  useLayerListener(
    'mouseenter',
    'r__peopleLocationSnapshotLayer-cluster',
    handlePersonLocationHover
  );

  useLayerListener(
    'mouseenter',
    'r__peopleLocationAlarmSnapshotLayer-cluster',
    handlePersonLocationHover
  );

  useLayerListener(
    'mouseleave',
    'r__peopleLocationSnapshotLayer-cluster',
    handlePersonLocationLeave
  );

  useLayerListener(
    'mouseleave',
    'r__peopleLocationAlarmSnapshotLayer-cluster',
    handlePersonLocationLeave
  );

  useEffect(() => {
    if (!isPopupLineEnabled) {
      return;
    }

    if (!lineEndCoordinates) {
      setPopupLine(undefined);
      return;
    }

    // const personLocation = hoveredOnPerson?.location.point;

    // if(!personLocation){
    //   return;
    // }
    // const lines = createLinesBetweenPopupAndRiskPoint(
    //   [personLocation.lon, personLocation.lat ]as Position,
    //   [lineEndCoordinates?.longitude, lineEndCoordinates?.latitude] as Position
    // );

    // setPopupLine(lines);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lineEndCoordinates,
    lineEndCoordinates?.latitude,
    lineEndCoordinates?.longitude
  ] );


  useEffect(() => {
    if (!lineEndCoordinates) {
      return;
    }
    if(map){
      map.on(
        'move',
        calculatePopupOffset
      );
    }

    return () => {
      map?.off(
        'move',
        calculatePopupOffset
      );
    };
  }, [calculatePopupOffset, lineEndCoordinates, map]);

  return (
    <PeopleLocationLayerComponent
      isArcModeOn={ false }
      geoData={ generalGeoData }
      alarmGeoData={ alarmGeoData }
      disabledAdapters={ disabledAdapters }
      minRankThreshold={ minRankThreshold }
      maxRankThreshold={ maxRankThreshold }
      isMinRankThresholdApplied={ isMinRankThresholdApplied }
      lineToPopup={ popupLine }
      mapRef={ map }
    />
  );
};

export default PeopleLocationLayer;
