import { FC, useCallback, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import FloorListTableComponent
  from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/components/FloorListTable/FloorListTable.component";
import {
  LocationInfoContext
} from "@/tenant-context/control-location-configuration/context/LocationInfo.context";


type Props = {
  setBuildingView: (val: boolean) => void
}
const FloorListTable: FC<Props> = ({ setBuildingView }) => {
  const floors = useSelector((state: RootState) => state.manageLocations?.floorListData);
  const activeBuilding = useSelector((state: RootState) => state.manageLocations?.activeBuilding);
  const activeFloorSort = useSelector((state: RootState) => state.manageLocations?.floorSort);

  const { isAddFloorFormOpened } = useContext(LocationInfoContext);
  const scrollableRef = useRef<HTMLDivElement>(null);

  const {
    manageLocations:{
      getFloorsList,
      SET_ACTIVE_BUILDING,
      SET_FLOOR_SORT
    }
  } = useDispatch<Dispatch>();

  const handleSetFloorSort = useCallback(() => {
    if(activeFloorSort === 'asc') {
      SET_FLOOR_SORT('desc');
    }
    if (activeFloorSort === 'desc') {
      SET_FLOOR_SORT('');
    }
    if (activeFloorSort === '') {
      SET_FLOOR_SORT('asc');
    }
  },[SET_FLOOR_SORT, activeFloorSort]);

  const handleGoBackButtonClick = useCallback(() => {
    SET_ACTIVE_BUILDING(undefined);
    setBuildingView(true);
  }, [SET_ACTIVE_BUILDING, setBuildingView]);

  const handleScrollToEditForm = useCallback(() => {
    if (scrollableRef?.current) {
      scrollableRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  useEffect(() => {
    getFloorsList();
    return () => {
      SET_ACTIVE_BUILDING(undefined);
      setBuildingView(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFloorsList, activeFloorSort]);

  return (
    <FloorListTableComponent
      handleGoBackButtonClick={ handleGoBackButtonClick }
      floors={ floors?.items || [] }
      isAddFloorFormOpened={ isAddFloorFormOpened }
      activeBuilding={ activeBuilding }
      handleSetFloorSort={ handleSetFloorSort }
      activeFloorSort={ activeFloorSort }
      scrollableRef={ scrollableRef }
      onScrollToEditForm={ handleScrollToEditForm }
    />
  );
};

export default FloorListTable;
