import { createStyles } from "@mantine/core";

export const useCountryDetailViewDrawerStyles = createStyles((theme) => ({
  backIcon: {
    all: 'unset',
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: 48,

    '& > svg': {
      marginRight: 8
    }
  },

  riskText: {
    fontSize: 12,
    marginLeft: 8
  },

  riskIconWrapper: {
    display: 'flex',
    alignItems: 'center'
  },

  countryWrapper: {
    marginBottom: 48
  },

  countryLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16
  },

  countryName: {
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 22,
    lineHeight: theme.other.lh.l
  },

  countryRiskSummary: {
    fontSize: 14,
    lineHeight: theme.other.lh.l
  },

  divider: {
    margin: '8px 0',
    borderTopColor: theme.colors.neutral[7]
  },

  filtersContainer: {
    display: 'flex',
    gap: '16px',
    marginBottom: 24
  },

  dropdown: {
    padding: '16px 16px 5px',
    width: `230px !important`,
    border: `1px solid ${theme.colors.neutral[7]}`,
    backgroundColor: theme.colors.neutral[9]
  },

  dropdownLabel: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[7],
    padding: 0
  },

  dropdownItem: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[0],
    padding: '12px 0'
  },

  dropdownItemDescription: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[6],
    marginLeft: 4
  },

  searchInput: {
    width: '85%',
    marginBottom: 0,
    input: {
      minHeight: 32,
      paddingTop: 0,
      paddingBottom: 0
    }
  },

  searchIcon: {
    width: 16,
    height: 16
  },

  sortButton: {
    width: 32,
    height: 32,
    border: `1px solid ${theme.colors.neutral[7]}`
  },

  countryDetailViewFilter: {
    marginBottom: 32
  },

  travellersList: {

  }
}));
