import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { genericColorPalette } from '@/core/styles/mantine/palettes';


export const useLookupInitToolStyles = createStyles((theme: MantineTheme) => ({

  root: {
    display: 'flex',
    width: '66px',
    height: '66px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    flexShrink: 0,
    backgroundColor: theme.colors.neutral[8],
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: genericColorPalette.neutral[9]
    }
  },
  rootDisabled: {
    display: 'flex',
    width: '66px',
    height: '66px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    flexShrink: 0,
    backgroundColor: theme.colors.neutral[7],
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    cursor: 'not-allowed',
    '& svg > path': {
      fill: `${theme.colors.neutral[4]} !important`
    }
  }
}));
