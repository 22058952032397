export const LookupPoliciesConfig = {
  LOOKUP_ASSERT_RANKING_READONLY: {
    policyNames: [
      'Tenant_Lookup_Asset-Ranking_ReadOnly'
    ]
  },
  MC_CREATE_PAGE: {
    policyNames: [
      'Tenant_MassComms_Manual_FullAccess',
      'Tenant_MassComms_ImpactEvent_FullAccess'
    ]
  }
};
