import { Dialog, Text } from '@mantine/core';
import { useCallback,useMemo,useState } from 'react';

import { useConfirmationDialogStyle } from '@/common/components/Modal/ConfirmationDialog.style';
import ModalControlGroup from '@/common/components/ModalControlGroup/ModalControlGroup.component';


type ConfirmationDialogProps = {
  onConfirm: ()=>void,
  text: string,
  onClose?: ()=> void,
  primaryActionLabel?: string,
  secondaryActionLabel?: string
}

export const useConfirmationDialog = ({ onConfirm,  text, onClose, primaryActionLabel = "Yes",secondaryActionLabel = "No" }: ConfirmationDialogProps) => {
  const [opened, setOpened] = useState(false);
  const { classes } = useConfirmationDialogStyle();

  const openDialog = useCallback(() => {
    setOpened(true);
  }, []);
    
  const closeDialog = useCallback(() => {
    setOpened(false);
  }, []);

  const handleConfirm = useCallback(() => {
    setOpened(false);
    onConfirm();
  }, [onConfirm]);

  const handleClose = useCallback(() => {
    setOpened(false);
    onClose?.();
  }, [onClose]);
  
  const DialogComponent = useMemo(() => {
    const DialogFC = () => {
      return (
        <Dialog transitionTimingFunction="ease" transitionDuration={ 200 } transition={ "pop" } className={ classes.confirmDialog } opened={ opened } withCloseButton onClose={ handleClose } size="lg" >
          <div>
            <Text size="sm" mb="xs" weight={ 500 }>
              { text }
            </Text>
            <ModalControlGroup primaryActionLabel={ primaryActionLabel } secondaryLabel={ secondaryActionLabel }
              isCancelAdditional onPrimary={ handleConfirm } onSecondary={ handleClose }/>
          </div>
        
        </Dialog>
      );
    };

    return DialogFC;
  }, [classes.confirmDialog, handleClose, handleConfirm, opened, primaryActionLabel, secondaryActionLabel, text]);

  return {
    DialogComponent,
    openDialog,
    closeDialog
  };
};
