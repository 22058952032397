import { parsePhoneNumber } from "awesome-phonenumber";
import { FC, useMemo } from "react";

import PhoneWithFlag from "@/tenant-context/control-profile/components/ui/PhoneWithFlag/PhoneWithFlag.component";

type Props = {
  phone: string
}

const PhoneWithFlagContainer: FC<Props> = ({
  phone
}) => {
  const parsedData = useMemo(
    () => parsePhoneNumber(phone),
    [phone]
  );

  return (
    <PhoneWithFlag
      phone={ phone }
      regionCode={ parsedData.regionCode }
    />
  );
};

export default PhoneWithFlagContainer;