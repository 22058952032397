import { AvatarStylesParams, MantineTheme } from "@mantine/core";

import { ThemeComponent } from "@/core/styles/mantine/types";

import { primitiveNumbers } from "../constants";

type Params = AvatarStylesParams & {
  size: string;
}

const getSize = (size: string): number => {
  const sizeValueMap: Record<string, number> = {
    xs: primitiveNumbers['3x'],
    sm: primitiveNumbers['3x'],
    md: primitiveNumbers['4x'],
    lg: primitiveNumbers['6x'],
    xl: primitiveNumbers['8x']
  };

  return sizeValueMap[size];
};

const getBorderWidth = (size: string): string => {
  const sizeValueMap: Record<string, string> = {
    xs: '1px',
    sm: '1px',
    md: '1.4px',
    lg: '1.4px',
    xl: '1.8px'
  };

  return sizeValueMap[size] || '1px';
};

const getInitialsFontSize = (size: string): string => {
  const sizeValueMap: Record<string, string> = {
    xs: '8px',
    sm: '8px',
    md: '12px',
    lg: primitiveNumbers['2x'] + 'px',
    xl: primitiveNumbers['2x'] + 'px'
  };

  return sizeValueMap[size] || '1px';
};


export const AvatarComponentStyle: ThemeComponent = {
  defaultProps: {
    radius: 500,
    size: "xl"
  },
  styles: (theme: MantineTheme, params: Params) => ({
    root: {
      border: `${getBorderWidth(params.size)} solid ${theme.colors.neutral[6]}`,
      width: getSize(params.size),
      minWidth: getSize(params.size),
      height: getSize(params.size)
    },
    placeholder: {
      backgroundColor: theme.colors.neutral[9],
      color: theme.colors.white,
      fontSize: getInitialsFontSize(params.size)
    },
    placeholderIcon: {
      color: theme.colors.neutral[6]
    }
  })
};
