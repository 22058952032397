import { MantineTheme } from "@mantine/core";

import { ThemeComponent } from "@/core/styles/mantine/types";

export const BreadcrumbsComponentStyle: ThemeComponent = {
  styles: (theme: MantineTheme) => ({
    root: {
      fontSize: theme.fontSizes.md
    },
    breadcrumb: {
      color: theme.colors.blue[4],
      "&:last-child": {
        color: theme.colors.blue[0]
      }
    },
    separator: {
      marginLeft: "8px",
      marginRight: "13px",
      color: theme.colors.neutral[6]
    }
  })
};
