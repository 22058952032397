import { useEffect, useRef } from "react";

export default function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  },[value]);

  return ref.current as T; //in the end, return the current ref value.
}