import { callApi } from "@/common/util/api";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import {
  AccessCardLayoutParams,
  IVisitorPrintJobsList, PrintJobAddEmployees,
  PrintJobDetailsParams,
  PrintJobsList, PrintJobStatusParams,
  TenantAccessCard,
  TenantAccessCardHistory,
  TenantAccessCardLayoutListItem
} from "../types/tenantAccessCard";

export const getTenantAccessCardData = (
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<TenantAccessCard>>(
  `/managed/Integration-service/v1/access-cards?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'X-Domain-Model': 'ListAccessCard'
    },
    method: 'get'
  }
);
export const getTenantAccessCardHistoryData = (
  page = 0,
  size = 10,
  tenantId: string,
  accessCardId: string,
  sort?: SortData,
  query?: string
) => {
  const orderBy = sort?.colId === 'assignedDate' ? '_meta.createdDate' : sort?.colId;
  return callApi<PaginatedResult<TenantAccessCardHistory>>(
    `/managed/Integration-service/v1/access-cards/${accessCardId}/events?${sort?.colId ? 'orderBy=' + orderBy + ':' + sort.sort + '&' : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}&profileTid=${tenantId}&orderBy=name:DESC`,
    {
      headers: {
        'X-Domain-Model': 'ListAccessCardHistoryDetails'
      },
      method: 'get'
    }
  );
};

export const uploadAccessCardsFile = (formData: FormData) => callApi<
{ status?: string, generalMessage? : string, uploadedCount: number, failedCount: number }
>(
  `/managed/Integration-service/v1/access-cards/files`,
  {
    method: 'post',
    body: formData,
    headers: {
      'x-domain-model': 'UploadAccessCard'
    },
    withContentType: false
  }
);

export const deleteTenantAccessCard = (accessCardId: string)=> callApi(
  `/managed/Integration-service/v1/access-cards/${accessCardId}`,
  {
    headers: {
      'X-Domain-Model': 'DeleteAccessCard'
    },
    method: 'delete'
  }
);

export const getTenantAccessCardItem = (
  accessCardId: string
) => callApi<TenantAccessCard>(
  `/managed/Integration-service/v1/access-cards/${accessCardId}`,
  {
    headers: {
      'X-Domain-Model': 'GetAccessCard'
    },
    method: 'get'
  }
);

export const updateTenantAccessCard =
 (params: TenantAccessCard) => callApi<TenantAccessCard>(
   `/managed/Integration-service/v1/access-cards/${params._id}`,
   {
     headers: {
       'X-Domain-Model': 'UpdateAccessCard'
     },
     method: 'post',
     body: JSON.stringify(params)
   }
 );

export const toggleTenantAccessCardActivation =
 (id: string, activate = true) => callApi<TenantAccessCard>(
   `/managed/Integration-service/v1/access-cards/${id}/`,
   {
     headers: {
       'X-Domain-Model': 'DeactivateAccessCard'
     },
     method: 'post',
     body: JSON.stringify({ deActive: !activate })
   }
 );

export const reassignTenantAccessCard =
 (id: string) => callApi<TenantAccessCard>(
   `/managed/Integration-service/v1/access-cards/${id}/`,
   {
     headers: {
       'X-Domain-Model': 'ReassignAccessCard'
     },
     body: "{}",
     method: 'post'
   }
 );

export const getTenantAccessCardLayoutList = (
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<TenantAccessCardLayoutListItem>>(
  `/managed/Integration-service/v1/access-card-layouts?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    method: 'get'
  }
);

export const deleteTenantAccessCardLayout = (accessCardLayoutId: string) => callApi(
  `/managed/Integration-service/v1/access-card-layouts/${accessCardLayoutId}`,
  {
    headers: {
      'X-Domain-Model': 'DeleteCardLayout'
    },
    method: 'delete'
  }
);

export const saveAccessCardLayout = (params: AccessCardLayoutParams) => callApi<AccessCardLayoutParams>(
  `managed/Integration-service/v1/access-card-layouts`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreateCardLayout'
    },
    method: 'post',
    body: JSON.stringify(params)
  }
);

export const updateAccessCardLayout = (params: AccessCardLayoutParams, tid: string) => callApi<AccessCardLayoutParams>(
  `managed/Integration-service/v1/access-card-layouts/${tid}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=UpdateCardLayout'
    },
    method: 'post',
    body: JSON.stringify(params)
  }
);

export const getAccessCardLayout = (
  id: string
) => callApi<AccessCardLayoutParams>(
  `/managed/Integration-service/v1/access-card-layouts/${id}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=GetCardLayout'
    },
    method: 'get'
  }
);

export const getVisitorPrintJobsData = (
  printJobId: string,
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<IVisitorPrintJobsList>>(
  `managed/Integration-service/v1/print-jobs/${printJobId}/visitor-print-job-line-items?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=ListVisitorPrintJobLineItem'
    },
    method: 'get'
  }
);

export const getPrintJobsListData = (
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<PrintJobsList>>(
  `/managed/Integration-service/v1/print-jobs?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    method: 'get'
  }
);

export const savePrintJobDetails = (params: PrintJobDetailsParams) => callApi<PrintJobDetailsParams>(
  `managed/Integration-service/v1/print-jobs`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreatePrintJob'
    },
    method: 'post',
    body: JSON.stringify(params)
  }
);

export const updatePrintJobDetails = (params: PrintJobDetailsParams, tid: string) => callApi<PrintJobDetailsParams>(
  `managed/Integration-service/v1/print-jobs/${tid}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=UpdatePrintJob'
    },
    method: 'post',
    body: JSON.stringify(params)
  }
);

export const getUsersforPrintJob = (
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi(
  `managed/platform-service/v1/users?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;x-domain-model=ListTenantPrintJobUser'
    }
  }
);

export const getPrintJobDetails = (
  id: string
) => callApi<PrintJobDetailsParams>(
  `managed/Integration-service/v1/print-jobs/${id}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=GetPrintJob'
    },
    method: 'get'
  }
);

export const deletePrintJob = (printJobId: string)=> callApi(
  `/managed/Integration-service/v1/print-jobs/${printJobId}`,
  {
    headers: {
      'X-Domain-Model': 'DeletePrintJob'
    },
    method: 'delete'
  }
);

export const setVisitorPrintJobCount = (noOfCards: number, printJobId: string) => callApi<PrintJobDetailsParams>(
  `managed/Integration-service/v1/print-jobs/${printJobId}/visitor-print-job-line-items`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreateVisitorPrintJobLineItem'
    },
    method: 'post',
    body: JSON.stringify({ noOfCards })
  }
);

export const updatePrintJobStatus = 
(printJobId: string, status: PrintJobStatusParams) => callApi<PrintJobDetailsParams>(
  `managed/Integration-service/v1/print-jobs/${printJobId}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=UpdatePrintJobStatus'
    },
    method: 'post',
    body: JSON.stringify({ status })
  }
);

export const getPrintJobToAddProfiles = (
  page = 0,
  size = 10,
  providerTid = '',
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<PrintJobAddEmployees>>(
  `/managed/Integration-service/v1/print-jobs?providerTid=${providerTid}&${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=ListPersonProfileForPrintJob'
    },
    method: 'get'
  }
);

export const addProfilesToPrintJob = (data: string[], tid: string) => callApi<PrintJobDetailsParams>(
  `managed/Integration-service/v1/print-jobs/${tid}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreateEmployeePrintJobLineItem'
    },
    method: 'post',
    body: JSON.stringify({ profileITidList: data })
  }
);

export const getPrintJobAddedProfiles = (
  page = 0,
  size = 10,
  printJobId = '',
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<PrintJobAddEmployees>>(
  `/managed/Integration-service/v1/print-jobs/${printJobId}?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=ListEmployeePrintJobLineItem'
    },
    method: 'get'
  }
);

export const removeProfileFromPrintJob = (profileTid: string, tid: string) => callApi<PrintJobDetailsParams>(
  `managed/Integration-service/v1/print-jobs/${tid}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=DeleteEmployeePrintJobLineItem'
    },
    method: 'post',
    body: JSON.stringify({ lineId: profileTid })
  }
);
