import type { CSSObject } from '@emotion/react';
import { MantineTheme } from "@mantine/core";

import { genericColorPalette } from '@/core/styles/mantine/palettes';

import { CSS_KEY, primitiveNumbers } from '../mantine/constants';

export const globalStyles = (theme: MantineTheme): CSSObject | CSSObject[] => ({
  ':root': {
    '--navigation-sidebar-closed-width': '88px',
    '--navigation-sidebar-open-width': '248px',
    '--tabs-absolute-height': '51px'
  },

  // without this inputs turn white on autofill
  'input:-webkit-autofill': {
    transition: 'background-color 600000s 0s, color 600000s 0s'
  },
  'input:-webkit-autofill:focus': {
    transition: 'background-color 600000s 0s, color 600000s 0s'
  },

  html: {
    // overscroll disable
    overflow: 'hidden',
    height: '100%',
    //

    fontSize: theme.fontSizes.md,
    lineHeight: theme.other.lh.l,
    letterSpacing: '0.25px',
    fontFamily: theme.fontFamily
  },
  body: {
    // overscroll disable
    height: '100%',
    overflow: 'auto'
    //
  },
  h1: {
    fontSize: theme.headings.sizes.h1.fontSize,
    fontWeight: theme.headings.fontWeight,
    lineHeight: theme.headings.sizes.h1.lineHeight
  },
  h2: {
    fontSize: theme.headings.sizes.h2.fontSize,
    fontWeight: theme.headings.fontWeight,
    lineHeight: theme.headings.sizes.h2.lineHeight
  },
  h3: {
    fontSize: theme.headings.sizes.h3.fontSize,
    fontWeight: theme.headings.fontWeight,
    lineHeight: theme.headings.sizes.h3.lineHeight
  },
  h4: {
    fontSize: theme.headings.sizes.h4.fontSize,
    fontWeight: theme.headings.fontWeight,
    lineHeight: theme.headings.sizes.h4.lineHeight
  },
  h5: {
    fontSize: theme.headings.sizes.h5.fontSize,
    fontWeight: theme.headings.fontWeight,
    lineHeight: theme.headings.sizes.h5.lineHeight
  },
  h6: {
    fontSize: theme.headings.sizes.h6.fontSize,
    fontWeight: theme.headings.fontWeight,
    lineHeight: theme.headings.sizes.h6.lineHeight
  },

  '.mapboxgl-map': {
    fontFamily: `${theme.fontFamily} !important`,
    font: "unset !important",
    fontSize: 'unset !important'
  },

  '.mapboxgl': {
    '&-popup': {
      '&-anchor-bottom &-tip': {
        borderTopColor: theme.other.semantic.navigation.secondary.lvl[1]
      },

      '&-content': {
        // backgroundColor: theme.other.semantic.navigation.secondary.lvl[1]
      }
    }
  },

  [`.${CSS_KEY}-Drawer-title`]: {
    width: '100%',
    marginRight: '0 !important'
  },

  [`.${CSS_KEY}-Drawer-drawer`]: {
    backgroundColor: `${theme.colors.neutral[8]} !important`
  },

  '.mapboxgl-popup-anchor-top .mapboxgl-popup-tip': {
    borderBottomColor: theme.colors.blue[6]
  },

  '.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip': {
    borderTopColor: theme.colors.blue[6]
  },

  '.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip': {
    borderBottomColor: theme.colors.blue[6]
  },

  '.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip': {
    borderBottomColor: theme.colors.blue[6]
  },

  '.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip': {
    borderTopColor: theme.colors.blue[6]
  },

  '.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip': {
    borderTopColor: theme.colors.blue[6]
  },
  '.ag-body-horizontal-scroll-viewport': {
    overflowX: 'auto'
  },

  //toDo - remove from global styles
  'mapboxgl-ctrl-logo': {
    display: 'none !important'
  },

  // * Scrollbar
  '::-webkit-scrollbar': {
    background: theme.colors.neutral[9]
  },
  '::-webkit-scrollbar:vertical': {
    width: '9px',
    padding: '7px 2px'
  },
  '::-webkit-scrollbar:horizontal': {
    height: '9px',
    padding: '2px 7px'
  },
  '::webkit-scrollbar-track': {
    background: theme.colors.neutral[4]
  },
  '::-webkit-scrollbar-thumb': {
    background: genericColorPalette.neutral[6]
  },
  '.ag-paging-button': {
    backgroundColor: `${theme.other.semantic.surfaceBackground.primary} !important`,
    border: `1px solid ${theme.other.semantic.border.general.lighter} !important`
  },
  '.ag-paging-panel.ag-unselectable': {
    flexDirection: "row-reverse",
    justifyContent: "flex-end"
  },
  '.ag-paging-description': {
    fontSize: '14px !important'
  },
  '.ag-paging-row-summary-panel': {
    margin: `0 0 0 ${primitiveNumbers['4x']} !important`,
    fontSize: '14px !important',
    '&::before': {
      content: '"Showing"'
    },
    '&::after': {
      content: '"records"'
    }
  },
  [`.${CSS_KEY}-Tabs-tabsList button[data-active]`]: {
    marginBottom: '-1px',
    position: 'inherit'
  },
  '.mantine-RangeCalendar-calendarBase': {
    gap: '66px',
    maxWidth: 'none',
    '& td': {
      padding: 4
    }
  },
  '.mantine-RangeCalendar-calendarHeaderLevel': {
    color: theme.colors.royalBlue[1],
    '&:hover': {
      backgroundColor: theme.colors.neutral[7]
    }
  },
  '.mantine-RangeCalendar-weekday': {
    color: theme.colors.neutral[6]
  },
  '.mantine-RangeCalendar-day': {
    backgroundColor: theme.colors.neutral[6],
    color: theme.colors.neutral[9],
    width: '32px',
    height: '32px',
    '&[data-weekend]': {
      color: theme.colors.neutral[9]
    },
    '&:hover': {
      backgroundColor: theme.colors.neutral[5]
    },
    '&[data-in-range]': {
      backgroundColor: theme.colors.neutral[7],
      color: theme.colors.neutral[0]
    },
    '&[data-selected]': {
      backgroundColor: theme.colors.royalBlue[0],
      color: theme.colors.neutral[0]
    },
    '&:disabled': {
      color: theme.colors.neutral[9],
      cursor: 'not-allowed',
      pointerEvents: 'unset',
      '&:hover': {
        backgroundColor: theme.colors.neutral[6]
      }
    }
  },

  // * Drawer
  [`.${CSS_KEY}-Drawer-root`]: {
    zIndex: '190!important'
  },

  // Quill editor styles
  '.ql-toolbar.ql-snow': {
    border: 'none'
  },

  '.ql-container.ql-snow': {
    border: 'none'
  },

  '.tox:not(.tox-tinymce-inline) .tox-editor-header, .tox .tox-toolbar-overlord, .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary, .tox .tox-menubar':{
    background: "transparent",
    backgroundColor: "transparent !important"
  },
  '.mce-content-body':{
    backgroundColor: theme.colors.primary[5]
  },
  '.tox .tox-statusbar':{
    backgroundColor: "transparent !important"
  }

});
