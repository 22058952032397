import { callApi } from "@/common/util/api";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import { AccessCardProvider, AccessCardSharing, AccessCardSites, ServiceConfiguration } from "../types/serviceConfiguration";

export const getServiceConfigurationData = (
  tenantId: string,
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<ServiceConfiguration>>(
  `/integration-service/v1/integration-configurations?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'X-TenantId': tenantId,
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'Access Control'
    },
    withTenantId: false,
    method: 'get'
  }
);

export const saveServiceConfiguration = (params: ServiceConfiguration, tenantId: string) => callApi(
  `/integration-service/v1/integration-configurations`,
  {
    headers: {
      'X-TenantId': tenantId,
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'Access Control'
    },
    method: 'post',
    body: JSON.stringify(params),
    withTenantId: false
  }
);

export const updateServiceConfiguration =
 (params: ServiceConfiguration, tenantId: string) => callApi<ServiceConfiguration>(
   `/integration-service/v1/integration-configurations/${params._id}`,
   {
     headers: {
       'X-TenantId': tenantId,
       'Content-Type': 'application/json;',
       'X-Domain-Model': 'Access Control'
     },
     method: 'post',
     withTenantId: false,
     body: JSON.stringify(params)
   }
 );

export const getServiceConfigurationItem = (
  serviceConfigurationId: string,
  tenantId: string
) => callApi<ServiceConfiguration>(
  `/integration-service/v1/integration-configurations/${serviceConfigurationId}`,
  {
    headers: {
      'X-TenantId': tenantId,
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'Access Control'
    },
    withTenantId: false,
    method: 'get'
  }
);

export const deleteServiceConfiguration = (serviceConfigurationId: string, tenantId: string)=> callApi(
  `/integration-service/v1/integration-configurations/${serviceConfigurationId}`,
  {
    headers: {
      'X-TenantId': tenantId,
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'Access Control'
    },
    withTenantId: false,
    method: 'delete'
  }
);

export const getAccessCardData = (
  tenantId: string,
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<ServiceConfiguration>>(
  `/managed/Integration-service/v1/access-cards?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'X-TenantId': tenantId,
      'X-Domain-Model': 'ListAccessCard'
    },
    withTenantId: false,
    method: 'get'
  }
);

export const deleteAccessCard = (accessCardId: string, tenantId: string)=> callApi(
  `/managed/Integration-service/v1/access-cards/${accessCardId}`,
  {
    headers: {
      'X-Domain-Model': 'DeleteAccessCard',
      'X-TenantId': tenantId
    },
    method: 'delete',
    withTenantId: false
  }
);

export const uploadAccessCardsFile = (formData: FormData, tenantId: string) => callApi<
  { status?: string, generalMessage? : string, uploadedCount: number, failedCount: number }
>(
  `/managed/Integration-service/v1/access-cards/files`,
  {
    method: 'post',
    body: formData,
    headers: {
      'X-TenantId': tenantId,
      'x-domain-model': 'UploadAccessCard'
    },
    withTenantId: false,
    withContentType: false
  }
);

export const getSharingData = (
  tenantRef: string,
  serviceConfigId: string
) => callApi<AccessCardSharing>(
  `/managed/Integration-service/v1/access-provider-groups?size=9999&page=0&tenantRef=${tenantRef}&orderBy=name:DESC&providerId=${serviceConfigId}`,
  {
    headers: {
      'X-TenantId': tenantRef,
      'X-Domain-Model': 'ListAccessProviderGroup'
    },
    withTenantId: false,
    method: 'get'
  }
);

export const submitSharedData = 
(sharedProviderTidList: string[], accessProviderTid: string, tenantId: string) => callApi<{status: string}>(
  `/managed/Integration-service/v1/access-provider-groups`,
  {
    method: 'post',
    body: JSON.stringify({
      sharedProviderTidList,
      accessProviderTid
    }),
    withTenantId: false,
    headers: {
      'X-TenantId': tenantId
    }
  }
);

export const getAccessCardSitesData = (
  tenantRef: string,
  serviceConfigId: string
) => callApi<AccessCardSites>(
  `/managed/Integration-service/v1/access-control-integrations?size=10&page=0&tenantRef=${tenantRef}&orderBy=name:DESC&providerId=${serviceConfigId}`,
  {
    headers: {
      'X-Domain-Model': 'ListAccessProviderSite',
      'X-TenantId': tenantRef
    },
    withTenantId: false,
    method: 'get'
  }
);

export const submitAccessCardSitesData = 
(serviceConfigId: string, siteRefDtoList: AccessCardProvider[], tenantId: string) => callApi<{status: string}>(
  `/managed/Integration-service/v1/access-control-integrations/${serviceConfigId}`,
  {
    method: 'post',
    body: JSON.stringify({
      siteRefDtoList
    }),
    withTenantId: false,
    headers: {
      'X-Domain-Model': 'CreateAccessProviderSiteReference',
      'X-TenantId': tenantId
    }
  }
);
