import { createStyles } from "@mantine/core";

export const useManageSiteControlStyles = createStyles({
  controlButton: {
    backgroundColor: 'black',
    left: '-25px',
    top: '15px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.6) !important'
    }
  }
});
