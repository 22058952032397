import { FC } from "react";

import { ReactComponent as LinkIcon } from "@/common/icons/arrow-up-right-from-square.svg";
import { ReactComponent as MusterIconOrange } from "@/common/icons/muster-icon-orange.svg";
import { ReactComponent as MusterIcon } from "@/common/icons/start-muster.svg";
import { useMusterStyles } from "@/tenant-context/visualisation-site/components/MusterTab/styles";

type Props = {
  musterCount: number
  navigateToMuster: () => void
}

const MasterTab: FC<Props> = ({ musterCount = 3, navigateToMuster }) => {

  const { classes } = useMusterStyles();
  return (
    <div className={ classes.wrapper }>
      <div className={ classes.progressBar }>
        <MusterIcon className={ classes.icon }/>
      </div>
      <div className={ classes.musterIconOrangeContainer }>
        <MusterIconOrange/>
      </div>
      <div className={ classes.info }>
        <div className={ classes.title }>Muster in progress</div>
        <div className={ classes.count }>{ musterCount }</div>
      </div>
      <LinkIcon className={ classes.link } onClick={ navigateToMuster }/>
    </div>
  );
};

export default MasterTab;
