export const headerPlaceHolders = {
  logoLink: '{{HEADER_LOGO_LINK}}',
  logoURL: '{{HEADER_LOGO_URL}}',
  alignment: '{{HEADER_ALIGN}}',
  altText: '{{HEADER_LOGO_ALT}}',
  bgColor: '{{HEADER_CONTENT_BG_COLOR}}',
  padding: {
    top: '{{HEADER_PADDING_TOP}}',
    right: '{{HEADER_PADDING_RIGHT}}',
    bottom: '{{HEADER_PADDING_BOTTOM}}',
    left: '{{HEADER_PADDING_LEFT}}'
  }
};

export const footerPlaceHolders = {
  logoLink: '{{FOOTER_LOGO_LINK}}',
  logoURL: '{{FOOTER_LOGO_URL}}',
  alignment: '{{FOOTER_ALIGN}}',
  altText: '{{FOOTER_LOGO_ALT}}',
  bgColor: '{{FOOTER_CONTENT_BG_COLOR}}',
  padding: {
    top: '{{FOOTER_PADDING_TOP}}',
    right: '{{FOOTER_PADDING_RIGHT}}',
    bottom: '{{FOOTER_PADDING_BOTTOM}}',
    left: '{{FOOTER_PADDING_LEFT}}'
  }
};