import { createStyles } from "@mantine/core";


export const useArcSectionStyles = createStyles((t) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: t.colors.neutral[8],
    padding: 24,
    margin: 0
  },

  title: {
    fontSize: t.fontSizes.md,
    fontWeight: t.other.fw.bold,
    paddingBottom: 16,
    marginBottom: 16,
    borderBottom: `1px solid ${t.colors.neutral[7]}`
  }
}));
