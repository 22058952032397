import { createContext, FC, useContext, useEffect, useState } from "react";

import { UserPolicy } from '@/common/types/permission-control';
import { getCurrentUsersPermissionsPolicies } from '@/core/api/policies';


export type PoliciesContextType = {
  userPolicies: UserPolicy[] | undefined;
  sitePolicies: UserPolicy[] | undefined;
  isPoliciesLoading: boolean;
  setSitePolicies: React.Dispatch<React.SetStateAction<UserPolicy[] | undefined>>
};

export const PoliciesContext = createContext<PoliciesContextType>({} as PoliciesContextType);

export const usePoliciesContext = () => useContext(PoliciesContext);

export const PoliciesContextProvider: FC = ({
  children
}) => {
  const [userPolicies, setUserPolicies] = useState<UserPolicy[]>();
  const [isPoliciesLoading, setIsPoliciesLoading] = useState<boolean>(false);
  const [sitePolicies, setSitePolicies] = useState<UserPolicy[]>();

  useEffect(() => {
    setIsPoliciesLoading(true);
    getCurrentUsersPermissionsPolicies().then(
      (response) => {
        setIsPoliciesLoading(false);
        return response && setUserPolicies(response.items);
      }
    );
  }, []);

  return (
    <PoliciesContext.Provider value={ {
      userPolicies,
      isPoliciesLoading,
      sitePolicies,
      setSitePolicies
    } }>
      { children }
    </PoliciesContext.Provider>
  );
};
