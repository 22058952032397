/* eslint-disable react/jsx-props-no-spreading */
import { Button } from "@mantine/core";
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import { ICellRendererParams, RowClickedEvent } from "ag-grid-community";
import { forwardRef, ForwardRefRenderFunction, ReactNode } from "react";

import ServerDataGrid from "@/common/components/AgGrid/ServerSide";
import { ReactComponent as BackIcon } from "@/common/icons/chevron-left-solid.svg";
import { DataGrid, GridParams } from "@/common/types/ag-grid";
import { columnDefs } from "@/tenant-context/control-location-configuration/config/location-zone.config";
import { LocationZoneResponse } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import LocationZoneForm from "./form/LocationZoneForm.container";
import { useLocationZonesStyles } from "./ZoneTab.styles";

type Props = {
    onLocationZoneEditClick: (data: LocationZoneResponse) => void,
    getData: (payload: {
      gridParams: GridParams,
    }) => Promise<PaginatedResult<LocationZoneResponse>>,
    actionCellRenderer: (params: ICellRendererParams) => ReactNode,
    toggleLocationZoneListView: () => void,
    isLocationZoneListView: boolean,
    locationCategory: string | null,
    onRowClicked: (event: RowClickedEvent<LocationZoneResponse>) => void,
    isAuthorizedToEdit: boolean
  }

const ZoneTabComponent: ForwardRefRenderFunction<DataGrid, Props>  = ({
  getData,
  actionCellRenderer,
  toggleLocationZoneListView,
  isLocationZoneListView,
  locationCategory,
  onRowClicked,
  isAuthorizedToEdit
}, ref) => {

  const { classes } = useLocationZonesStyles();

  if(!isLocationZoneListView){
    return (
      <div>
        <button
          onClick={ toggleLocationZoneListView }
          className={ classes.backLink }
        >
          <BackIcon />
          Zones
        </button>
        <LocationZoneForm toggleLocationZoneListView={ toggleLocationZoneListView }/>
      </div>

    );
  }

  return (
    <ParentSize>
      { ({ height }) =>   <div style={ { minHeight: height } } className={ classes.container }>
        <div className={ classes.headerWrapper }>
          <div className={ classes.headerTextWrapper }>
            <h1 className={ classes.headerText }>Zones</h1>
            <Button
              disabled={ !isAuthorizedToEdit }
              onClick={ isAuthorizedToEdit ? toggleLocationZoneListView : undefined }
            >
              + Add Zone
            </Button>
          </div>
        </div>
        <div className="ag-theme-alpine-dark ag-theme-rt ">
          <ServerDataGrid
            id ={ "locationZones-grid" }
            columnDefs={ columnDefs(actionCellRenderer) }
            ref = { ref }
            getData = { getData }
            additionalGridParams={ { locationCategory } }
            onRowClicked={ onRowClicked }
            search
          />
        </div>

      </div> }
    </ParentSize>
  );
};

export default forwardRef(ZoneTabComponent) as (
  props: Props & React.RefAttributes<DataGrid>
) => JSX.Element;

