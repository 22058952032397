import lastMileSitesMarker from '@/common/icons/markers/LastMileSite/Primary/Marker.png';
import locationClusterMarker from '@/common/icons/markers/Locations/LocationCluster.png';
import airportIcon from "@/common/icons/markers/Locations/Primary/Airport.png";
import busStationIcon from "@/common/icons/markers/Locations/Primary/Bus Station.png";
import businessAccommodationIcon from "@/common/icons/markers/Locations/Primary/BusinessAccommodation.png";
import campIcon from "@/common/icons/markers/Locations/Primary/Camp.png";
import carHiresIcon from "@/common/icons/markers/Locations/Primary/Car Hire.png";
import customLocationIcon from "@/common/icons/markers/Locations/Primary/Custom.png";
import embarkationIcon from "@/common/icons/markers/Locations/Primary/Embark-Disembark.png";
import embassyIcon from "@/common/icons/markers/Locations/Primary/Embassy.png";
import evacuationPointIcon from "@/common/icons/markers/Locations/Primary/Evacuation Centre.png";
import heliportIcon from "@/common/icons/markers/Locations/Primary/Heliport.png";
import hospitalIcon from "@/common/icons/markers/Locations/Primary/Hospital.png";
import hotelIcon from "@/common/icons/markers/Locations/Primary/Hotel.png";
import officeIcon from "@/common/icons/markers/Locations/Primary/OfficeSite.png";
import policeIcon from "@/common/icons/markers/Locations/Primary/Police.png";
import harbourIcon from "@/common/icons/markers/Locations/Primary/Port.png";
import railStationIcon from "@/common/icons/markers/Locations/Primary/Rail Station.png";
import infrastructureIcon from "@/common/icons/markers/Locations/Primary/Supplier.png";
import transportationIcon from "@/common/icons/markers/Locations/Primary/Transport-Hub.png";
import { MapIcon } from "@/common/types/icon";


export const staticLocationMapIcons: Readonly<MapIcon[]> = [
  {
    name: 'location-cluster-marker',
    image: locationClusterMarker
  },
  {
    name: 'static-location-office-marker',
    image: officeIcon
  },
  {
    name: 'static-location-business-accommodation-marker',
    image: businessAccommodationIcon
  },
  {
    name: 'static-location-hotel-marker',
    image: hotelIcon
  },
  {
    name: 'static-location-airport-marker',
    image: airportIcon
  },
  {
    name: 'static-location-heliport-marker',
    image: heliportIcon
  },
  {
    name: 'static-location-harbour-marker',
    image: harbourIcon
  },
  {
    name: 'static-location-rail-station-marker',
    image: railStationIcon
  },
  {
    name: 'static-location-bus-station-marker',
    image: busStationIcon
  },
  {
    name: 'static-location-car-hires-marker',
    image: carHiresIcon
  },
  {
    name: 'static-location-embarkation-marker',
    image: embarkationIcon
  },
  {
    name: 'static-location-camp-marker',
    image: campIcon
  },
  {
    name: 'static-location-police-marker',
    image: policeIcon
  },
  {
    name: 'static-location-hospital-marker',
    image: hospitalIcon
  },
  {
    name: 'static-location-embassy-marker',
    image: embassyIcon
  },
  {
    name: 'static-location-infrastructure-marker',
    image: infrastructureIcon
  },
  {
    name: 'static-location-evacuation-point-marker',
    image: evacuationPointIcon
  },
  {
    name: 'customer-location-generic-marker',
    image: customLocationIcon
  },
  {
    name: 'last-mile-site-marker',
    image: lastMileSitesMarker
  },
  {
    name: 'static-location-transportation-marker',
    image: transportationIcon
  }
];
