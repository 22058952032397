import { createStyles } from "@mantine/core";

type Props = {
  isLegendHeader?: boolean,
  firstItem: "dimmed" | "emphasis" | "regular" | "label",
  isUpperCase: boolean
}

export const useProfileKeyValueStyle = createStyles((theme, args?: Props) => ({
  dt: {
    color: args?.firstItem === 'dimmed' || args?.firstItem === 'label'
      ? theme.colors.neutral[5]
      : 'inherit',

    textTransform: args?.isUpperCase ? 'uppercase' : 'unset',

    maxWidth: '100%',
    overflow: 'hidden',

    fontSize: args?.firstItem === 'emphasis' || args?.firstItem === 'label'
      ? theme.fontSizes.sm
      : 'inherit',

    fontWeight: args?.firstItem === 'emphasis'
      ? theme.other.fw.bold
      : theme.other.fw.regular
  },
  dd: {
    maxWidth: '100%',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis'
  }
}));
