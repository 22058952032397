import { ButtonStylesParams, ButtonVariant } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

import { primitiveNumbers } from "../constants";

const getSize = (size: string): string => {
  const heightMap: Record<string, string> = {
    xs: `${primitiveNumbers['3x']}px`,
    sm: `${primitiveNumbers['3x']}px`,
    md: `${primitiveNumbers['4x']}px`,
    lg: `${primitiveNumbers['6x']}px`,
    xl: `${primitiveNumbers['6x']}px`
  };

  return heightMap[size];
};

const getIconSize = (size: string): number => {
  const iconSizeMap: Record<string, number> = {
    xs: 14,
    sm: 14,
    md: primitiveNumbers['2x'],
    lg: primitiveNumbers['2x'],
    xl: primitiveNumbers['3x']
  };

  return iconSizeMap[size];
};

const getIconColor = (variant: ButtonVariant, theme: MantineTheme): string => {
  const variantMap: Record<string, string> = {
    default: theme.other.semantic.icon.button.primary,
    outline: theme.other.semantic.button.outline.default,
    gradient: theme.other.semantic.text.button.primary,
    subtle: theme.other.semantic.button.subtle.default,
    light: theme.other.semantic.button.light.default
  };

  return variantMap[variant] || theme.white;
};

const getBackgroundColor = (variant: ButtonVariant, theme: MantineTheme): string => {
  const variantMap: Record<string, string> = {
    default: `transparent`,
    outline: 'transparent',
    subtle: 'transparent',
    gradient: `${theme.other.semantic.button.white.default}`,
    light: `${theme.other.semantic.button.white.default}`
  };

  return variantMap[variant] || `${theme.colors.blue[6]}`;
};

const getBorder = (variant: ButtonVariant, color: string, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: 'none',
    outline: `0.5px solid ${theme.other.semantic.button.outline.default}`,
    gradient: 'none',
    subtle: 'none',
    light: 'none'
  };

  return variantMap[variant] || "";
};

const getActiveBackgroundColor = (variant: ButtonVariant, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: 'transparent',
    outline: 'transparent',
    gradient: theme.other.semantic.button.white.pressed,
    subtle: 'transparent',
    light: `${theme.colors.neutralDarkShades[3]}`
  };

  return variantMap[variant] || theme.fn.darken(getBackgroundColor(variant, theme), 0.1);
};

const getFocusedBackgroundColor = (variant: ButtonVariant, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: 'transparent',
    outline: 'transparent',
    gradient: `${theme.other.semantic.button.white.pressed}`,
    subtle: 'transparent',
    light: `${theme.colors.neutralDarkShades[3]}`
  };

  return variantMap[variant] || "";
};

const getFocusedBorder = (variant: ButtonVariant, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: `2px solid ${theme.other.semantic.button.default.pressed}`,
    outline: `2px solid ${theme.other.semantic.button.outline.focused}`,
    gradient: `2px solid ${theme.other.semantic.button.white.focused}`,
    subtle: `1px solid ${theme.other.semantic.button.outline.focused}`,
    light: `2px solid ${theme.other.semantic.button.light.focused}`
  };

  return variantMap[variant] || "";
};

const getHoveredBackgroundColor = (variant: ButtonVariant, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: `${theme.colors.neutralDarkShades[3]}`,
    outline: `${theme.colors.neutralDarkShades[3]}`,
    gradient: `${theme.other.semantic.button.white.hover}`,
    subtle: 'transparent',
    light: `${theme.colors.neutralDarkShades[3]}`
  };

  return variantMap[variant] || theme.fn.darken(getBackgroundColor(variant, theme), 0.05);
};

const getHoveredBorder = (variant: ButtonVariant, theme: MantineTheme): string =>{
  const variantMap: Record<string, string> = {
    default: 'none',
    outline: `1px solid ${theme.other.semantic.button.outline.hover}`,
    gradient: 'none',
    subtle: 'none'
  };

  return variantMap[variant] || "";
};

export const ActionIconComponentStyle = {
  defaultProps: {
    size: 'lg',
    variant: 'default'
  },
  styles: (theme: MantineTheme, params: ButtonStylesParams) => ({
    root: {
      width: getSize(params.size),
      height: getSize(params.size),
      backgroundColor: getBackgroundColor(params.variant, theme),
      border: getBorder(params.variant, params.color, theme),
      backgroundImage: 'none',

      '& svg': {
        color: getIconColor(params.variant, theme),
        width: getIconSize(params.size),
        height: getIconSize(params.size)
      },

      '&:focus-visible':{
        border: getFocusedBorder(params.variant, theme),
        backgroundColor: `${getFocusedBackgroundColor(params.variant, theme)}`,
        outline: 'none'
      },

      '&:hover': {
        backgroundColor: getHoveredBackgroundColor(params.variant, theme),
        border: getHoveredBorder(params.variant, theme)
      },

      '&:active':{
        backgroundColor: `${getActiveBackgroundColor(params.variant, theme)}`
      },

      '&:disabled': {
        backgroundColor: params.variant === 'light' ? 'transparent' : theme.other.semantic.button.default.disabled,
        cursor: 'not-allowed',

        '& svg': {
          color: theme.other.semantic.icon.general.disabled
        }
      }
    }
  })
};
