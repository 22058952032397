import { ReferenceData } from "@/common/types/reference-data";
import { callApi } from "@/common/util/api";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { BulkLocationsFileUploadStatus } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";
import { ReferenceType } from "@/tenant-context/control-profile/types/profile";
import {
  PaginatedResult,
  ProfileListItem
} from "@/tenant-context/control-profile/types/profile-list";


export const getProfileList = (
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string,
  profileTagsFilter?: string
) => callApi<PaginatedResult<ProfileListItem>>(
  `managed/platform-service/v1/person-profiles?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${query}`
    + `${profileTagsFilter ? '&profileTagTidsToFilter=' + profileTagsFilter : ''}`,
  {
    headers: {
      'Content-Type': 'application/json'
    },
    method: 'get',
    withTenantId: false
  }
);

export const getSearchedProfilesWithName = (
  firstName?: string,
  lastName?: string,
  page = 0,
  size = 99999
) => callApi<PaginatedResult<ProfileListItem>>(
  `managed/platform-service/v1/person-profiles?size=${size}&page=${page}${firstName || lastName ? `&search=%28firstName LIKE "${firstName || ''}%25" AND lastName LIKE "${lastName || ''}%25"%29` : ''}`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=ListPersonProfileNames'
    },
    method: 'get',
    withTenantId: false
  }
);

export const getReferenceData = (
  referenceDataType: ReferenceType,
  data?: string
) => callApi<PaginatedResult<ReferenceData>>(
  `public/platform-service/v1/reference-data?type=${referenceDataType}${data ? '&data=' + data : ''}&size=1000`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=ListReferenceData'
    },
    method: 'get',
    withTenantId: false,
    withAuthorization: false
  }
);

export const getBulkProfilesUploadStatus = (bulkDataId: string) =>
  callApi<BulkLocationsFileUploadStatus>(
    `managed/platform-service/v1/bulk-data/${bulkDataId}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json;domain-model=CheckBulkDataStatus"
      }
    }
  );

export const getDownloadBulkProfileUploadTemplate = () => callApi<{ expirationInMillis: number; exportUrl: string }>(
  `managed/platform-service/v1/bulk-data?templateType=profile`,
  {
    method: "get",
    headers: {
      "Content-Type":
                "application/json;domain-model=DownloadBulkDataTemplate"
    }
  }
);


