/* eslint-disable import/extensions */
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { Location } from "@/tenant-context/common/types/location";
import { INITIAL_MAP_BOUNDS, INITIAL_MAP_VIEW } from "@/tenant-context/common/util/constants";
import { getMusterCountries } from "@/tenant-context/widget-overview/util/locations";

import CountryInfoBoxComponent from "./CountryInfoBox.component";

export const ALL_COUNTRIES = 'all-countries';
export const ALL_COUNTRIES_ISO_CODE = 'ALL';


const CountryInfoBox: FC = () => {

  const allCountriesOptionData = useMemo(() => ({
    id: ALL_COUNTRIES,
    name: 'All',
    geoPoint: {
      lat: 0,
      lon: 0
    },
    isoCountryCode: 'ALL',
    countryName: 'ALL',
    category: 'Regions',
    subCategory: 'Country'
  }), []);

  const [selectedCountry, setSelectedCountry] = useState<Location>(allCountriesOptionData);
  const [isExpanded, setIsExpanded] = useState(false);
  const [countrySearchValue, setCountrySearchValue] = useState('');

  const selectedCountryState = useSelector((state: RootState) => state.commonData.selectedCountry);
  const locationsPeopleCount = useSelector((state: RootState) => state.sitePopup.locationsPeopleCount);

  const {
    commonData: {
      SET_SELECTED_COUNTRY,
      SET_SHOULD_REFRESH_SITES_FLAG
    }
  } = useDispatch<Dispatch>();

  const allCountries = useSelector((state: RootState) => state.commonData.allCountries);

  const map = useMap().current;

  const mappedCountries: Location[] = useMemo<Location[]>(() => {
    return [
      allCountriesOptionData,
      ...allCountries
    ];
  }, [allCountries, allCountriesOptionData]);

  const filteredCountries = useMemo(
    () => mappedCountries.filter((country) => country.name.toLowerCase().includes(countrySearchValue.toLowerCase()))
    , [mappedCountries, countrySearchValue]
  );

  const toggleIsInfoBoxExpanded = useCallback(
    (shouldBe?: boolean) => {
      setIsExpanded(
        typeof shouldBe !== 'undefined'
          ? shouldBe
          : (is) => !is
      );
    },
    []
  );

  const handleCountryChange = useCallback((country: Location) => {
    if (country.id === ALL_COUNTRIES) {
      SET_SELECTED_COUNTRY(
        allCountriesOptionData
      );

      map?.fitBounds([
        INITIAL_MAP_BOUNDS.ne.lng,
        INITIAL_MAP_BOUNDS.ne.lat,
        INITIAL_MAP_BOUNDS.sw.lng,
        INITIAL_MAP_BOUNDS.sw.lat
      ], {
        zoom: INITIAL_MAP_VIEW.zoom
      });
      SET_SHOULD_REFRESH_SITES_FLAG(true);
    } else {
      SET_SELECTED_COUNTRY(country);

      const { geoPoint: { lon, lat } } = country;

      map?.flyTo({
        center: {
          lng: lon,
          lat
        },
        zoom: 6
      });
    }

    setIsExpanded(false);
    setSelectedCountry(country);
  }, [SET_SELECTED_COUNTRY, SET_SHOULD_REFRESH_SITES_FLAG, allCountriesOptionData, map]);

  const countryMustering = useMemo(() => {
    return getMusterCountries(locationsPeopleCount);
  }, [locationsPeopleCount]);

  const musterCount = useCallback(() => {
    if (selectedCountry && selectedCountry.isoCountryCode === ALL_COUNTRIES_ISO_CODE) {
      return locationsPeopleCount?.musters;
    }
    if (selectedCountry && selectedCountry.isoCountryCode !== ALL_COUNTRIES_ISO_CODE) {
      return selectedCountry.isoCountryCode ? countryMustering[selectedCountry.isoCountryCode] : 0;

    } else {
      return 0;
    }
  }, [selectedCountry, countryMustering, locationsPeopleCount]);

  useEffect(() => {
    SET_SELECTED_COUNTRY(
      allCountriesOptionData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedCountryState) {
      return;
    }

    setSelectedCountry(selectedCountryState);
  }, [selectedCountryState]);

  return (
    <CountryInfoBoxComponent
      selectedCountry={ selectedCountry }
      countryList={ filteredCountries }
      isExpanded={ isExpanded }
      onCountrySelected={ handleCountryChange }
      toggleIsInfoBoxExpanded={ toggleIsInfoBoxExpanded }
      countryMustering={ countryMustering }
      musterCount={ musterCount() }
      setCountrySearchValue={ setCountrySearchValue }
    />
  );

};

export default CountryInfoBox;
