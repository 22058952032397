export const downloadAsFile = (data: string, fileName: string, type = 'text/csv') => {
  const csvBlob = new Blob([data], { type: type });
  const url = URL.createObjectURL(csvBlob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.setAttribute('download', fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  URL.revokeObjectURL(url);
};
