export function capitalizeWord(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function removeHTMLTags(s: string): string {
  return s.replace(/(<([^>]+)>)/ig, '');
}

export function checkIfStringNotEmpty(str: string): boolean {
  return !!str && str.trim() !== '';
}

export const trimAndRemoveMultipleSpaces = (str = ''): string => {
  return str.replace(/ +/g, ' ').trim();
};
