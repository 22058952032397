import { Button, Group, Menu } from "@mantine/core";
import { FC, useCallback, useMemo } from "react";

import usePermission from '@/common/hooks/usePermission';
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { ReactComponent as IconChevronDown } from '@/common/icons/chevron-down-solid-white.svg';
import { ReactComponent as IconChevronUp } from '@/common/icons/chevron-up-solid-white.svg';
import { ReactComponent as PlusIcon } from '@/common/icons/plus.svg';
import { RequiredPolicies } from '@/common/types/permission-control';
import {
  useProfileTabActionButtonStyles
} from "@/tenant-context/control-profile/components/ui/ProfileTabActionButton/ProfileTabActionButton.styles";

type Props = {
  isWithPlus?: boolean,
  onClick: (ev: React.MouseEvent<HTMLButtonElement>) => void,
  requiredPolicies?: RequiredPolicies | RequiredPolicies[]
  isDropDownButton?: boolean
  setIsExpanded?: (val: boolean) => void
  isExpanded?: boolean
};

const ProfileTabActionButton: FC<Props> = ({
  children,
  isWithPlus = true,
  onClick,
  requiredPolicies,
  isDropDownButton = false,
  setIsExpanded,
  isExpanded = false
}) => {

  const isAuthorized = usePermission(requiredPolicies);

  const { classes } = useProfileTabActionButtonStyles({ disabled: !isAuthorized });

  const handleUnauthorizedAction = useUnauthorizedModal();

  const handleExpandMenuChange = useCallback(() => {
    if (setIsExpanded) {
      setIsExpanded(!isExpanded);
    }
  }, [isExpanded, setIsExpanded]);

  const renderIcon = useMemo(() => {
    if (isWithPlus) {
      return <PlusIcon/>;
    } else {
      return undefined;
    }
  }, [isWithPlus]);

  return (
    <>
      { isDropDownButton ? (
        <div className={ classes.wrapper }>
          <Group noWrap spacing={ 0 }>
            <Menu opened={ isExpanded } onChange={ handleExpandMenuChange } position="bottom-end"
              withinPortal>
              <Menu.Target>
                <Button
                  disabled={ !isAuthorized }
                  className={ classes.menuTitle }
                  size="lg"
                  onClick={ isAuthorized ? onClick : handleUnauthorizedAction }
                  rightIcon={ isExpanded ? <IconChevronUp/> : <IconChevronDown/> }
                >
                  { children }
       
                </Button>
              </Menu.Target>
            </Menu>
          </Group>
        </div>
      )
        :
        (
          <Button
            size="lg"
            disabled={ !isAuthorized }
            /* eslint-disable-next-line react/jsx-no-bind */
            rightIcon={ renderIcon }
            onClick={ isAuthorized ? onClick : handleUnauthorizedAction }
          >
            { children }
          </Button>
        ) }
    </>
  );
};

export default ProfileTabActionButton;

