import { FC } from "react";

import { ReactComponent as TrashIcon } from "@/common/icons/delete-bin.svg";
import { ReactComponent as EditIcon } from '@/common/icons/edit.svg';
import {
  useBuildingsAndFloorsStyles
} from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.styles";
import { FloorListItem } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

type Props = {
  floor: FloorListItem
  handleDeleteFloor: (e: React.MouseEvent, floor: FloorListItem) => void
  handleFloorClick: () => void
}

const FloorCellComponent: FC<Props> = ({
  floor,
  handleDeleteFloor,
  handleFloorClick
}) => {

  const { classes } = useBuildingsAndFloorsStyles();

  return (
    <div className={ classes.floorCellContainer }>
      <div className={ classes.floorCellHeader }>
        <button onClick={ handleFloorClick } className={ classes.floorCellHeaderButton }>
          <div className={ classes.floorCellInnerContainer }>
            <div className={ classes.floorName }>{ floor.name }</div>
            <div className={ classes.floorName }>{ floor.code }</div>
            <div className={ classes.floorName }>{ floor.zones }</div>
            <div className={ classes.actionButtonContainer }>
              { <EditIcon /> }
              { floor.floorTid &&
                // eslint-disable-next-line react/jsx-no-bind
                <TrashIcon onClick={ (e) => handleDeleteFloor(e, floor) } className={ classes.trash }/> }
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default FloorCellComponent;
