import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";

import {
  useResponseMethodsStyles
} from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Response-Methods/ResponseMethods.styles";

const ResponseMethodItem = forwardRef<HTMLDivElement, {
  value: string,
  label: string,
  icon?: IconProp,
  iconColor?: string
}>(
  ({ icon, iconColor, label, ...others }: {value: string, label: string, icon?: IconProp, iconColor?: string}, ref) => {

    const { classes } = useResponseMethodsStyles();

    return (
      <div ref={ ref } className={ classes.customResponseItem } { ...others }>
        { icon && <FontAwesomeIcon icon={ icon } className={ classes.responseItemIcon } color={ iconColor }/> }
        { label }
      </div>
    );
  }
);

ResponseMethodItem.displayName = 'ResponseMethodItem';

export default ResponseMethodItem;
