export enum TRAVELLERS_SEARCH_DRAWERS {
  TRAVELLERS_SEARCH_CONTROL = 'travellers-search-control',
  TRAVELLERS_SEARCH_SUB_DRAWER = 'travellers-search-sub-drawer'
}

export enum TRAVELLERS_SEARCH_SUB_SLIDES {
  TRAVELLERS_BY_COUNTRY_SLIDE = 'travellers-by-country-slide',
  COUNTRY_DETAIL_VIEW_SLIDE = 'country-detail-view-slide',
  TRAVELLERS_MINI_PROFILE_SLIDE = 'travellers-mini-profile-slide'
}

export const countryWiseMapPadding = {
  top: 150,
  bottom: 75,
  left: 75,
  right: 385 // Width of both drawers
};

export enum TRAVELLERS_SEARCH_FIELDS {
  TIME_RANGE = 'timeRange',
  TRAVELLERS_TYPE = 'travellersType',
  DESTINATION_RISK_LEVEL = 'destinationRiskLevel',
  RANGE_CALENDAR = 'rangeCalendar',
}
