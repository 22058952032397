import { FC, useContext } from "react";
import { Link } from "react-router-dom";

import { RouterConfig } from "@/core/components/Router/Router.config";
import { ReactComponent as BackIcon } from "@/tenant-context/control-mass-comms/icons/arrow-half.svg";

import { LocationInfoContext } from "../../../context/LocationInfo.context";
import { useAddLocationsStyles } from "../AddLocations.styles";


const AddLocationsHeader: FC = () => {

  const { classes } = useAddLocationsStyles();

  const { isNewLocation } = useContext(LocationInfoContext);

  return (
    <div className={ classes.headerWrapper }>
      <Link
        className={ classes.backLink }
        to={ RouterConfig.routes.locationConfiguration.locationsList }
      >
        <BackIcon />
        Back to Location Configuration
      </Link>
      <div className={ classes.headerTextWrapper }>
        <div className={ classes.headerText }>
          <div>{ !isNewLocation ? "Edit Location" : "New Location" }</div>
        </div>
      </div>
    </div>
  );
};

export default AddLocationsHeader;
