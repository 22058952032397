/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import { ENV, Environment } from "@/core/config/env";

import browserDatabase from './browser-database';

interface LogFn {
    (message?: any, ...optionalParams: any[]): void;
  }
  
interface Logger {
    log: LogFn;
    info: LogFn;
    error: LogFn;
    warn: LogFn;
    logWithTime: LogFn;
  }

const NO_OP = () => {};

const IS_LOGGING_ENABLED = 'isLoggingEnabled';

export class ConsoleLogger implements Logger {
    readonly log: LogFn;

    readonly info: LogFn;

    readonly error: LogFn;

    readonly warn: LogFn;

    readonly logWithTime: LogFn;

    constructor() {

      const isLogEnabledInStorage = browserDatabase.getItem(IS_LOGGING_ENABLED);

      (window as any).setLogging = (enable = true) => {
        browserDatabase.setItem(IS_LOGGING_ENABLED, enable);
      };

      if(isLogEnabledInStorage === null){
        browserDatabase.setItem(IS_LOGGING_ENABLED, false);
      }
        
      this.error =  function(){
        const isLoggingEnabled = (ENV === Environment.Dev ||  ENV === Environment.Qa)
            || isLogEnabledInStorage;

        if(isLoggingEnabled){
          return console.error.bind(console);
        }

        return NO_OP;

      }();

      this.info =  function(){
        const isLoggingEnabled = (ENV === Environment.Dev ||  ENV === Environment.Qa)
            || isLogEnabledInStorage;

        if(isLoggingEnabled){
          return console.info.bind(console);
        }

        return NO_OP;

      }();
      
      this.log = function(){
        const isLoggingEnabled = (ENV === Environment.Dev ||  ENV === Environment.Qa)
            || isLogEnabledInStorage;

        if(isLoggingEnabled){
          return console.log.bind(console);
        }

        return NO_OP;

      }();
      this.warn = function(){
        const isLoggingEnabled = (ENV === Environment.Dev ||  ENV === Environment.Qa)
            || isLogEnabledInStorage;

        if(isLoggingEnabled){
          return console.warn.bind(console);
        }

        return NO_OP;

      }();

      this.logWithTime = function(){
        const isLoggingEnabled = (ENV === Environment.Dev ||  ENV === Environment.Qa)
          || isLogEnabledInStorage;

        if(isLoggingEnabled){
          return function(...args) {
            const timestamp = new Date().toLocaleString('en-US', {
              weekday: 'short',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit'
            });

            console.log(`[${timestamp}]`, ...args);
          };
        }

        return NO_OP;
      }();
    }
}

export const logger = new ConsoleLogger();
