import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { SitePeopleCount } from "@/tenant-context/visualisation-site/types/site.types";
import { ALL_COUNTRIES } from "@/tenant-context/widget-overview/components/CountryInfoBox/CountryInfoBox.container";
import PeopleInfoBox from "@/tenant-context/widget-overview/components/PeopleInfoBox/PeopleInfoBox.component";
import { ALL_SITES_CODE } from "@/tenant-context/widget-overview/components/SitesInfoBox/SiteInfoBox.container";

type Props = {
  isSitesAvailable: boolean
}

const PeopleInfoBoxContainer: FC<Props> = ({ isSitesAvailable }) => {

  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);
  const locationsPeopleCount = useSelector((state: RootState) => state.sitePopup.locationsPeopleCount);
  const selectedCountry = useSelector((state: RootState) => state.commonData?.selectedCountry);
  const selectedSite = useSelector((state: RootState) => state.commonData?.selectedSite);
  const onClickHandler = useCallback(() => {
    setIsDropdownExpanded((prev) => !prev);
  }, [setIsDropdownExpanded]);

  const activeSite = useMemo(() => {
    // eslint-disable-next-line fp/no-let
    let activeSiteData: SitePeopleCount = {
      code: '',
      isMusterActive: false,
      musterCount: 0,
      total: 0,
      visitorsNext24Hrs: 0,
      visitorsOnSite: 0,
      countryName: '',
      address: {
        firstLine: '',
        secondLine: '',
        thirdLine: '',
        city: '',
        region: '',
        country: '',
        postalCode: ''
      },
      description: ''
    };

    locationsPeopleCount.countries.forEach(
      (country) => {
        return country.sites.forEach((site) => {
          if (site.code === selectedSite?.code) {
            activeSiteData = site;
          } else {
            return true;
          }
        });
      }
    );

    return activeSiteData;
  }, [locationsPeopleCount, selectedSite]);


  const viewLevel = useCallback(() => {
    if (selectedCountry?.id === ALL_COUNTRIES && selectedSite?.code === ALL_SITES_CODE) {
      return 'world';
    }
    if (selectedCountry?.id !== ALL_COUNTRIES && selectedSite?.code === ALL_SITES_CODE) {
      return 'country';
    }
    if (selectedSite?.code !== ALL_SITES_CODE) {
      return 'site';
    } else {
      return;
    }
  }, [selectedCountry, selectedSite]);


  // world
  const worldCount = locationsPeopleCount.total?.toLocaleString('en-US');
  const worldSitesCount = locationsPeopleCount.siteTotal?.toLocaleString('en-US');
  const totalVisitingWorldCount = locationsPeopleCount.visitors?.toLocaleString('en-US');
  const totalVisitingWorldNext24HrsCount = locationsPeopleCount.visitorsNext24Hrs?.toLocaleString('en-US');


  // country
  const totalCountryCount = useMemo(
    () => locationsPeopleCount.countries.find(
      (country) => country.code === selectedCountry?.isoCountryCode
    )?.total
    , [locationsPeopleCount, selectedCountry]
  )?.toLocaleString('en-US');
  const totalSitesCount = useMemo(
    () => locationsPeopleCount.countries.find(
      (country) => country.code === selectedCountry?.isoCountryCode
    )?.siteTotal
    , [locationsPeopleCount, selectedCountry]
  )?.toLocaleString('en-US');
  const totalVisitingCountryCount = useMemo(
    () => locationsPeopleCount.countries.find(
      (country) => country.code === selectedCountry?.isoCountryCode
    )?.visitors
    , [locationsPeopleCount, selectedCountry]
  )?.toLocaleString('en-US');
  const totalVisitingCountryNext24HrsCount = useMemo(() => locationsPeopleCount.countries.find(
    (country) => country.code === selectedCountry?.isoCountryCode
  )?.visitorsNext24Hrs, [locationsPeopleCount, selectedCountry])?.toLocaleString('en-US');

  // site
  const totalOnsiteCount = activeSite?.total?.toLocaleString('en-US');
  const visitorsOnSite = activeSite?.visitorsOnSite?.toLocaleString('en-US');
  const visitorsNext24HrsCount = activeSite?.visitorsNext24Hrs?.toLocaleString('en-US');
  // const musterCount = activeSite?.[0]?.musterCount ?? 0;
  const isMusterActive = activeSite?.isMusterActive;


  return (
    <PeopleInfoBox
      title="People"
      worldTotalCount={ worldCount }
      worldSitesCount={ worldSitesCount }
      totalSitesCount={ totalSitesCount }
      countryTotalCount={ totalCountryCount }
      siteTotalCount={ totalOnsiteCount }
      totalVisitingWorldCount={ totalVisitingWorldCount }
      totalVisitingWorldNext24HrsCount={ totalVisitingWorldNext24HrsCount }
      totalVisitingCountryCount={ totalVisitingCountryCount }
      totalVisitingCountryNext24HrsCount={ totalVisitingCountryNext24HrsCount }
      visitorsOnSiteCount={ visitorsOnSite }
      isDropdownExpanded={ isDropdownExpanded }
      onClick={ onClickHandler }
      visitorsNext24HrsCount={ visitorsNext24HrsCount }
      viewLevel={ viewLevel() }
      type={ isMusterActive && viewLevel() === 'site' ? 'muster' : 'people' }
      isSitesAvailable={ isSitesAvailable }
    />
  );
};

export default PeopleInfoBoxContainer;
