import { Feature, LineString, Position } from "geojson";

import { AssetRankingSummary } from "@/tenant-context/common/types/asset-ranking";

export const createLinesBetweenBreadcrumbs = (
  assetRankingSummary: AssetRankingSummary
): Feature<LineString>[] => {
  const {
    topRank,
    otherRanks,
    personId
  } = assetRankingSummary;

  const { location: { point: { lat: srcLat, lon: srcLon } } } = topRank;

  const lines: Feature<LineString>[] = otherRanks.map(
    (rankingEvent): Feature<LineString> => {
      const { location: { point: { lat: tgtLat, lon: tgtLon } } } = rankingEvent;

      return {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [srcLon, srcLat],
            [tgtLon, tgtLat]
          ]
        },
        properties: {
          personId,
          topRank,
          otherRank: rankingEvent,

          topRankLocationType: topRank.location.type,
          topRankLocationId: topRank.location.id,

          otherRankLocationType: rankingEvent.location.type,
          otherRankLocationId: rankingEvent.location.id

          // TODO deprecate
          // topRankSource: topRank.adapterSource,
          // otherRankSource: rankingEvent.adapterSource
        }
      };
    },
    {
      type: 'LineString',
      coordinates: [] as Position[]
    }
  );

  return lines;
};
