import { FC } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import VoiceSummaryComponent
  from "@/tenant-context/control-mass-comms/components/SummaryPage/Summary-Channels/VoiceSummary/VoiceSummary.component";

const VoiceSummary: FC = () => {

  const voiceContent = useSelector((state: RootState) => state.massComms.voiceContent);

  return (
    <VoiceSummaryComponent
      audioResult={ voiceContent.audioContent }
      voiceMessageType={ voiceContent.voiceMessageType }
    />
  );
};

export default VoiceSummary;
