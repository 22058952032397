import { FC } from "react";
import { Popup } from "react-map-gl";

import { ReactComponent as RiskImpactAssetIcon } from "@/common/icons/riskImpactAssetIcon.svg";
import { ReactComponent as RiskImpactDistanceIcon } from "@/common/icons/riskImpactDistanceIcon.svg";
import { ReactComponent as RiskImpactPeopleIcon } from "@/common/icons/riskImpactPeopleIcon.svg";
import { ImpactPosition } from "@/tenant-context/visualisation-risk-details/types/risk-impact";

import { useRiskImpactPopupStyles } from "./RiskImpactPopup.styles";

type Props = {
  isShown: boolean
  impactPositionData?:ImpactPosition,
  impactRadius?:number,
  assetCount?:number,
  peopleCount?:number,
  popUpOffset?:number
}

export const RiskImpactPopup: FC<Props> = ({
  isShown,
  impactPositionData,
  impactRadius,
  assetCount,
  peopleCount,
  popUpOffset
}) => {

  const { classes } = useRiskImpactPopupStyles();
  if (!isShown || !impactPositionData || !popUpOffset) {
    return null;
  }

  return (
    <Popup
      longitude={ impactPositionData.lon }
      latitude={ impactPositionData.lat }
      offset={ popUpOffset }
      closeButton={ false }
      closeOnClick={ false }
      className = { classes.popUp }>
      <div className={ classes.popupContainer }>
        <div className={ classes.riskImpactDistance }>
          <RiskImpactDistanceIcon />
          { impactRadius } km
        </div>
        <div  className={ classes.riskImpactCount }>
          <RiskImpactPeopleIcon />
          { peopleCount }
        </div>
        <div className={ classes.riskImpacted }>
          <RiskImpactAssetIcon/>
          { assetCount }
        </div>
      </div>
    </Popup>
  );
};

export default RiskImpactPopup;
