import { Textarea } from "@mantine/core";
import { ChangeEvent, FC } from "react";

import { useCreateCommsPageStyles } from "@/tenant-context/control-mass-comms/components/CreateCommsPage/CreateCommsPage.styles";
import { SmsContent } from "@/tenant-context/control-mass-comms/types/MassComms.types";

type Props = {
  smsContent: SmsContent,
  onSmsContentChanged: (event: ChangeEvent<HTMLTextAreaElement> | null) => void
}

const SmsConfigurationComponent: FC<Props> = ({
  smsContent,
  onSmsContentChanged
}) => {

  const { classes, cx } = useCreateCommsPageStyles({ isCommsSequenceNoAvailable: true });

  return (
    <div className={ classes.tabContent }>

      <div className={ classes.extendedMargin }>
        <div className={ cx(classes.smsSpecSection, classes.extendedMargin) }>
          <div className={ classes.smsContentTitle }>Content</div>
          <div className={ classes.smsSpecs }>
            <div className={ classes.specItem }>
              <div className={ classes.specHeader }>Message Length</div>
              <div className={ classes.specDesc }>{ `${smsContent.length} Characters` }</div>
            </div>
            <div className={ classes.specItem }>
              <div className={ classes.specHeader }>SMS Units</div>
              <div className={ classes.specDesc }>{ smsContent.units }</div>
            </div>
          </div>

        </div>
        <Textarea
          size={ 'lg' }
          value={ smsContent.body }
          onChange={ onSmsContentChanged }
          className={ classes.commsTextArea }
        />
      </div>
    </div>
  );
};

export default SmsConfigurationComponent;
