import { LocationCategoryTypes, StringifiedLocation } from "../types/IntegrationMapping.types";

export function formatAdapterNames (name: string) : string {
  return name?.replace(/-/g, ' ').replace(/^./, name[0].toUpperCase()) || '-';
}

const locationHierarchyOrder = ["SITE", "BUILDING", "FLOOR", "ZONE"];

export function generateLocationPath(location: StringifiedLocation): string {
  const index = locationHierarchyOrder.indexOf(location.locationSubCategoryType);
  const locationPath = locationHierarchyOrder.slice(0, index)
    .map((level) => {
      if (location?.locationHierarchy[level as LocationCategoryTypes]){
        return location?.locationHierarchy[level as LocationCategoryTypes]?.name;
      }
    }).filter((name) => name !== undefined && name !== null);

  return locationPath.join(' > ');
}