import { CloseButton } from "@mantine/core";
import { FC } from "react";

import { useOnMapNotificationBoxStyles } from '@/tenant-context/common/components/OnMapNotificationBox/OnMapNotificationBox.styles';


type Props = {
  localNotification: string | undefined;
  onClose: () => void;
}

const OnMapNotificationBoxComponent: FC<Props> = ({
  localNotification,
  onClose
}) => {
  const { classes } = useOnMapNotificationBoxStyles();

  return (
    <div className={ classes.root }>
      <div className={ classes.content } hidden={ localNotification === undefined }>
        { localNotification }
        <CloseButton size="sm" onClick={ onClose } className={ classes.closeButton }/>
      </div>
    </div>
  );
};

export default OnMapNotificationBoxComponent;
