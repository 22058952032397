import { useMantineTheme } from "@mantine/core";
import { FC, memo } from "react";
import { Layer, Source } from "react-map-gl";

import ToggleableLayer from "@/common/components/ToggleableLayer";
import {
  ToggleableContextLayers
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { PopupCoordinates } from "@/tenant-context/common/types/popup.types";
import CountryRiskLevelPopupComponent from "@/tenant-context/visualize-country-risk/components/CountryRiskLevelPopup";
import { CountryRiskLevel } from "@/tenant-context/visualize-country-risk/types/country-risk.types";

const FILL_OPACITY = 0.7;

const getLayerFilter = (countryISOs: string[]) => [
  "all",
  [
    "any",
    [
      "==",
      "all",
      ["get", "worldview"]
    ],
    [
      "in",
      "US",
      ["get", "worldview"]
    ]
  ],
  [
    "in",
    ["get", "iso_3166_1"],
    ["literal", [...countryISOs]]
  ]
];

type Props = {
  riskLevelWiseCountries: { [key: string]: string[] },
  isPopupShown: boolean,
  popupCoordinates?: PopupCoordinates,
  popupData?: CountryRiskLevel
}

const CountryRiskLevelLayerComponent: FC<Props> = ({
  riskLevelWiseCountries,
  isPopupShown,
  popupCoordinates,
  popupData
}) => {
  const theme = useMantineTheme();

  return (
    <ToggleableLayer
      type={ ToggleableContextLayers.CountryRiskLevel }
      layerType="contextual-layer"
    >
      <Source
        id="r__countryRiskLevelSource"
        type="vector"
        url="mapbox://mapbox.country-boundaries-v1"
      >
        { /*Low Risk Countries*/ }
        <Layer
          id="countryRiskLevelLayer_low"
          type="fill"
          source-layer="country_boundaries"
          source="r__countryRiskLevelSource"
          paint={ {
            'fill-color': theme.other.semantic.risk.low,
            'fill-opacity': FILL_OPACITY
          } }
          beforeId="waterway-label"
          filter={ getLayerFilter(riskLevelWiseCountries["1"]) }
        />

        { /*Moderate Risk Countries*/ }
        <Layer
          id="countryRiskLevelLayer_moderate"
          type="fill"
          source-layer="country_boundaries"
          source="r__countryRiskLevelSource"
          paint={ {
            'fill-color': theme.other.semantic.risk.moderate,
            'fill-opacity': FILL_OPACITY
          } }
          beforeId="waterway-label"
          filter={ getLayerFilter(riskLevelWiseCountries["2"]) }
        />

        { /*Medium Risk Countries*/ }
        <Layer
          id="countryRiskLevelLayer_medium"
          type="fill"
          source-layer="country_boundaries"
          source="r__countryRiskLevelSource"
          paint={ {
            'fill-color': theme.other.semantic.risk.medium,
            'fill-opacity': FILL_OPACITY
          } }
          beforeId="waterway-label"
          filter={ getLayerFilter(riskLevelWiseCountries["3"]) }
        />

        { /*High Risk Countries*/ }
        <Layer
          id="countryRiskLevelLayer_high"
          type="fill"
          source-layer="country_boundaries"
          source="r__countryRiskLevelSource"
          paint={ {
            'fill-color': theme.other.semantic.risk.high,
            'fill-opacity': FILL_OPACITY
          } }
          beforeId="waterway-label"
          filter={ getLayerFilter(riskLevelWiseCountries["4"]) }
        />

        { /*Extreme Risk Countries*/ }
        <Layer
          id="countryRiskLevelLayer_extreme"
          type="fill"
          source-layer="country_boundaries"
          source="r__countryRiskLevelSource"
          paint={ {
            'fill-color': theme.other.semantic.risk.extreme,
            'fill-opacity': FILL_OPACITY
          } }
          beforeId="waterway-label"
          filter={ getLayerFilter(riskLevelWiseCountries["5"]) }
        />
      </Source>

      { isPopupShown && popupCoordinates && (
        <CountryRiskLevelPopupComponent
          longitude={ popupCoordinates.longitude }
          latitude={ popupCoordinates.latitude }
          data={ popupData as CountryRiskLevel }
        />
      ) }

    </ToggleableLayer>
  );
};

export default memo(CountryRiskLevelLayerComponent);
