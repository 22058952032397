import { environment } from '@/core/config/env';
import { Location } from "@/tenant-context/common/types/location";
import { formatAdapterNames } from '@/tenant-context/control-integrations-mapping/utils/IntegrationMapping';
import { getOutsystemsToken } from '@/tenant-context/navigation/api/navigation';

export const locationsCount = (subCategory = '', locationName = '', locations: Location[] = []) => locations.filter(
  (location) => (location.subCategory === subCategory && location?.parentList && location.parentList.find(
    (parentListItem) => parentListItem.code === locationName
  ))
).length;

export const navigateToSitePage = async (siteCode: string, application: 'Musters' | 'Site') => {
  const { access_token: osToken } = await getOutsystemsToken();

  const url = `${environment.outSystemsGlobalDashboardUrl}?` +
    `Application=${application}|${siteCode}&` +
    `Token=${osToken}&` +
    'OriginApp=GLOBAL-MAP&' +
    `OriginDomain=${environment.unifiedExperienceUrl}/`;

  window.open(url, "_blank")?.focus();
};

export const handleShortAdapterSourceDisplay = (name: string): string => {
  switch (name) {
  case 'gps-adapter-service':
    return 'GPS - Approximate';
  case 'travel-book-adapter-service':
    return 'Travel Itinerary';
  case 'site-zone-access-adapter-service':
    return 'Access Control';
  case 'person-profile-service':
    return 'Work Location';
  case '-':
    return 'GPS - App Location'; // LW App
  default:
    return formatAdapterNames(name);
  }
};
