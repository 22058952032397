import { ActionIcon, Autocomplete, AutocompleteItem, Box, Drawer, Flex, Skeleton, Text } from '@mantine/core';
import React, { FC, useCallback, useMemo } from 'react';

import { ReactComponent as More } from '@/common/icons/Actions/more-three-dots.svg';
import { ReactComponent as Notifications } from '@/common/icons/Actions/notification-bell.svg';
import Page, { Body, Header } from '@/tenant-context/common/components/Page';
import {
  useCityRiskReportsPageStyles
} from '@/tenant-context/control-reports/components/CityRiskReportsPage/CityRiskReportsPage.styles';
import CitySelectItem from '@/tenant-context/control-reports/components/CityRiskReportsPage/CitySelectItem.component';
import CityRiskStatusCard from '@/tenant-context/control-reports/components/CityRiskStatusCard';
import DetailedCityReport from '@/tenant-context/control-reports/components/DetailedCityReport';
import RiskProviderBadge from '@/tenant-context/control-reports/components/RiskProviderBadge';
import { CityRiskData, CityRiskProvider } from '@/tenant-context/control-reports/types/city-risk-reports';
import { uppercaseKebabToCapitalizedWords } from '@/tenant-context/control-reports/util/country-risk-reports';

type Props = {
  selectedRiskProvider?: CityRiskProvider;
  onRiskProviderChange: (value: CityRiskProvider) => void;
  onDrawerClose: () => void;
  cityRiskDataList: CityRiskData[];
  selectedCityRiskReport?: CityRiskData;
  setSelectedCityReport?: (cityRiskData: CityRiskData) => void;
  isDrawerExpanded: boolean;
  toggleDrawerExpand: () => void;
  onSearchStringChange: (value: string) => void;
  allCities: { city: string, country: string, countryIsoCode: string }[];
  currentTimestamp?: number;
  isCitySummaryLoading?: boolean;
  cityRiskProvidersList?: CityRiskProvider[];
  handleScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
}

const CityRiskReportsPageComponent: FC<Props> = ({
  selectedRiskProvider,
  onRiskProviderChange,
  onDrawerClose,
  cityRiskDataList,
  selectedCityRiskReport,
  setSelectedCityReport,
  isDrawerExpanded,
  toggleDrawerExpand,
  onSearchStringChange,
  allCities,
  currentTimestamp,
  isCitySummaryLoading,
  cityRiskProvidersList,
  handleScroll
}) => {
  const { classes, cx } = useCityRiskReportsPageStyles();
  const riskProviders = useMemo(() => {
    return cityRiskProvidersList?.map((provider) => ({
      label: uppercaseKebabToCapitalizedWords(provider.providerName),
      value: provider
    })) || [];
  }, [cityRiskProvidersList]);

  const allCitiesItems = useMemo(() => {
    const distinctCountriesMap = new Map<
      string,
      { city: string, country: string, countryIsoCode: string }
    >();

    allCities.forEach((city) => {
      distinctCountriesMap.set(city.country, {
        city: '_',
        country: city.country,
        countryIsoCode: city.countryIsoCode
      });
    });

    const distinctCountries = Array.from(distinctCountriesMap.values());

    const allItems = [...distinctCountries, ...allCities];

    return allItems.map((city) => ({
      value: (city.city !== '_') ? city.city : city.country,
      label: `${city.city}, ${city.country}`,
      ...city
    }));
  }, [allCities]);

  const citySearchFilter = useCallback((value: string, item: AutocompleteItem) => {
    return item.city.toLowerCase().includes(value.toLowerCase()) ||
      item.country.toLowerCase().includes(value.toLowerCase());
  }, []);

  return <Page>
    <Header heading="City Risk Reports" isBeta>
      <Flex gap="sm" hidden>
        <ActionIcon variant="outline" radius="xs" size="xl" className={ classes.headerIcons }><Notifications/></ActionIcon>
        <ActionIcon variant="outline" radius="xs" size="xl" className={ classes.headerIcons }><More/></ActionIcon>
      </Flex>
    </Header>
    <Body gap={ 40 } onScroll={ handleScroll }>
      <Flex gap={ 12 }>
        <Flex gap={ 12 }>
          { riskProviders.map((provider, index) => <RiskProviderBadge
            isSelected={ selectedRiskProvider?.providerName === provider.value.providerName }
            label={ provider.label }
            value={ provider.value }
            onClick={ onRiskProviderChange }
            key={ index }
          /> ) }
        </Flex>
        <Autocomplete
          data={ allCitiesItems }
          placeholder="Search for city"
          className={ classes.searchBox }
          onChange={ onSearchStringChange }
          itemComponent={ CitySelectItem }
          filter={ citySearchFilter }
        />
      </Flex>
      <Text typeof="h4" className={ classes.subHeading }>All Cities</Text>

      { !isCitySummaryLoading && (cityRiskDataList.length === 0) ? (
        <Flex align="center" justify="center" className={ classes.noRiskDataTextContainer }>
          <Text className={ classes.noRiskDataText }>No City Risk Reports Found</Text>
        </Flex>
      ): (
        <Box className={ classes.statusCardGrid }>
          { isCitySummaryLoading ? (
            Array.from({ length: 8 }).map((_, index) => (
              <Skeleton key={ index } height={ 360 } radius={ 24 } opacity={ 0.05 }/>
            ))
          ): cityRiskDataList.map(
            (cityRiskData, index) => (
              <CityRiskStatusCard
                cityRiskData={ cityRiskData }
                key={ index }
                onClick={ setSelectedCityReport }
                currentTimestamp={ currentTimestamp }
              />
            )
          ) }
        </Box> ) }
    </Body>

    <Drawer
      opened={ !!selectedCityRiskReport }
      onClose={ onDrawerClose }
      position="right"
      className={ cx({
        [classes.riskStatusDrawer]: true,
        [classes.riskStatusDrawerSide]: !isDrawerExpanded,
        [classes.riskStatusDrawerExpanded]: isDrawerExpanded
      }) }
      title={ 'Risk Status' }
      size={ isDrawerExpanded ? 'full' : undefined }
    >
      { selectedCityRiskReport && <DetailedCityReport
        cityRiskData={ selectedCityRiskReport }
        onClose={ onDrawerClose }
        onExpand={ toggleDrawerExpand }
        isExpanded={ isDrawerExpanded }
      /> }
    </Drawer>
  </Page>;
};

export default CityRiskReportsPageComponent;
