import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

export const useRiskTimelineDrawerStyles = createStyles((theme: MantineTheme) => ({
  drawerHeadingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    marginRight: 40
  },
  leftSpan: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  drawerHeading: {
    fontWeight: theme.other.fw.semibold,
    lineHeight: theme.other.lh.s,
    color: theme.colors.neutral[5],
    marginRight: 24
  },
  switch: {
    display: 'flex',
    alignItems: 'center',

    '& > *:last-child': {
      marginLeft: 8
    }
  },
  drawerBodyWrapper:{
    padding: "16px 16px 0 16px"
  }
}));
