import { Button, Divider } from "@mantine/core";
import { FC } from "react";

import DrawerControl from "@/common/components/DrawerControl";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import { CustomColors } from "@/core/styles/mantine/palettes/types";
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import CountBox from "@/tenant-context/control-site/components/CountBox";
import SiteContact from "@/tenant-context/control-site/components/SiteContact";
import { useSiteInfoControlStyles } from "@/tenant-context/control-site/controls/SiteInfoControl/styles";
import MasterTab from "@/tenant-context/visualisation-site/components/MusterTab";
import img from "@/tenant-context/visualisation-site/components/SiteLocationPopup/mocImg.png";

export const SITE_INFO_CONTROL_DRAWER_ID = 'site-info-control';

type Props = {
  zonesCount: number
  buildingCount: number
  musterCount: number
  isMuster: boolean
  navigateToSite: () => void
  isSitesAuthorized: boolean
  siteName?: string
  siteAddress?: string
  siteCountryName?: string
  isLegacyModeOn?: boolean,
  connectionProxyEnabled: boolean
}

const SiteInfoControl: FC<Props> = ({
  zonesCount = 0,
  buildingCount = 0,
  musterCount = 0,
  isMuster,
  siteName,
  siteAddress,
  siteCountryName,
  navigateToSite,
  isSitesAuthorized,
  connectionProxyEnabled
}) => {
  const { classes } = useSiteInfoControlStyles();
  return (
    <DrawerControl
      id={ SITE_INFO_CONTROL_DRAWER_ID }
      size={ 'lg' }
      drawerPosition="right"
    >
      <LocalErrorBoundary>
        <div className={ classes.content }>
          <div className={ classes.mainContent }>
            <div className={ classes.siteDescriptionContainer }>
              <img className={ classes.siteLogo } src={ img } alt="site"/>
              <div className={ classes.siteDescription }>
                <div className={ classes.siteName }>
                  { siteName }
                </div>
                <Divider
                  className={ classes.nameDivider }
                  color={ genericColorPalette.blue[6] as CustomColors }
                  size={ 2 }
                />
                <div className={ classes.siteLocation }>
                  { siteAddress }
                </div>
                <div className={ classes.countryName }>
                  { siteCountryName }
                </div>
              </div>
            </div>
            <div className={ classes.blockTitle }>SITE CONTACT</div>
            <Divider/>
            <SiteContact/>
            <CountBox isDarkTheme={ true }/>
            <div className={ classes.blockTitle }>SITE DETAILS</div>
            <CountBox
              zonesCount={ zonesCount }
              buildingCount={ buildingCount }
            />
            { isMuster && <MasterTab musterCount={ musterCount }/> }
          </div>
          { connectionProxyEnabled && (
            <Button
              className={ isSitesAuthorized ? classes.manageButton : classes.unauthorizedButton }
              fullWidth
              onClick={ navigateToSite }
              disabled={ !isSitesAuthorized }
            >
              Manage Site
            </Button>
          ) }

        </div>
      </LocalErrorBoundary>
    </DrawerControl>
  );
};

export default SiteInfoControl;
