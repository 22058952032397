import { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";

import AppCommsSummaryComponent from "./AppCommsSummary.component";

const AppCommsSummary: FC = () => {
  const {
    appCommunicationContent,
    selectedResponseMethod,
    selectedRecipients,
    receiveCountByCommunicationChannel,
    appCommsResponseMethodConfigurations
  } = useSelector((state: RootState) => state.massComms);
  const {
    massComms: {
      SET_CURRENT_STEP,
      SET_SELECTED_CONFIG_TAB
    }
  } = useDispatch<Dispatch>();

  const handleStepperNavigate = useCallback((step: number) => () => {
    SET_CURRENT_STEP(step);
    SET_SELECTED_CONFIG_TAB({ defaultTab: 'app', fromSummary: true });
  }, [SET_CURRENT_STEP, SET_SELECTED_CONFIG_TAB]);

  const isCriticalAlertMode = useMemo(
    () => appCommunicationContent.criticalAlertIOS,
    [appCommunicationContent.criticalAlertIOS]
  );


  const expectedTotalRecipientCount = selectedRecipients.length;
  const receivingCount = selectedRecipients.length - receiveCountByCommunicationChannel.none;
  return (
    <AppCommsSummaryComponent
      appCommunicationContent={ appCommunicationContent }
      responseMethod={ selectedResponseMethod }
      onStepperNavigate={ handleStepperNavigate }
      expectedTotalRecipientCount={ expectedTotalRecipientCount }
      receivingCount={ receivingCount }
      appCommunicationResponseMethodConfigurations={ appCommsResponseMethodConfigurations }
      isCriticalAlert={ isCriticalAlertMode }
    />
  );
};

export default AppCommsSummary;
