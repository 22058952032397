import { Button, Flex, Modal, Text } from '@mantine/core';
import { FC } from "react";

import {
  useSessionTimeoutWarningStyles
} from '@/tenant-context/common/components/SessionTimeoutWarning/SessionTimeoutWarning.styles';
import { useAuthContext } from '@/tenant-context/core/context/Auth.context';

const SessionTimeoutWarning: FC = () => {
  const { timeToSessionTimeout, setSessionActive, logout } = useAuthContext();
  const { classes } = useSessionTimeoutWarningStyles();

  return (
    <Modal
      className={ classes.modal }
      opened={ !!timeToSessionTimeout }
      onClose={ setSessionActive }
      title="Are you still there?"
      size={ 500 }
      withCloseButton={ false }
    >
      <Flex gap="sm">
        { timeToSessionTimeout !== '0 second' ?
          <>If not we will close this session in <Text fw="bold">{ timeToSessionTimeout }</Text></> :
          <>Login out... </> }
      </Flex>
      <Flex justify="end" gap="md" mt="xl">
        <Button onClick={ logout } variant="outline">Log out</Button>
        <Button onClick={ setSessionActive } disabled={ timeToSessionTimeout === '0 second' }>I&apos;m here</Button>
      </Flex>
    </Modal>
  );
};

export default SessionTimeoutWarning;
