import { TimeRange } from "@/tenant-context/control-travellers-search/types/travelSearch.types";

export const calculateFromAndToTime = (
  timeRange: TimeRange,
  formTimeRange: [Date | null, Date | null]
): [number, number] => {
  const now = new Date().getTime();
  const oneDay = 24 * 60 * 60 * 1000;
  const oneWeek = 7 * oneDay;
  const oneMonth = 30 * oneDay;

  switch (timeRange) {
  case TimeRange["24_HOURS"]:
    return [now, now + oneDay];
  case TimeRange["7_DAYS"]:
    return [now, now + oneWeek];
  case TimeRange["31_DAYS"]:
    return [now, now + oneMonth];
  case TimeRange.DATE_RANGE:
    return [formTimeRange[0]?.getTime() || now, formTimeRange[1]?.getTime() || now];
  default:
    return [now, now];
  }
};

export const calculateTripStatus = (fromDate: number, toDate: number) => {
  const now = new Date().getTime();
  if (fromDate > now) {
    return "Upcoming Trip";
  } else if (toDate < now) {
    return "Completed Trip";
  } else {
    return "Trip In Progress";
  }
};
