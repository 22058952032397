import { FC, useMemo } from "react";

import { RisklineToggleableFilterType } from "@/common/components/ToggleableFilter/ToggleableFilter.config";
import { RiskProviders } from "@/tenant-context/common/types/risk";

import { ProviderCategory } from "../../types/risk";
import { useRiskLineEventCategories } from "../RiskLineEventsSettings/RiskLineEventsSettings.hooks";
import { useDisabledRiskFilterTypes, useToggleCategoryFilter } from "./hooks";
import RiskLineEventsComponent from "./RiskLineEvents.component";

const RiskLineEventsContainer: FC = () => {
  const riskLineCaregories = useRiskLineEventCategories();
  const items = useMemo<Array<ProviderCategory>>(
    () =>
      riskLineCaregories.map((category) => ({
        ...category,
        categoryId: category.categoryId.toString()
      })),
    [riskLineCaregories]
  );

  const disabledRiskFilterTypes = useDisabledRiskFilterTypes();

  const handleSwitchToggle = useToggleCategoryFilter(RiskProviders.riskline, {
    categoryIdToFilterIdMap: RisklineToggleableFilterType
  });

  return (
    <RiskLineEventsComponent
      items={ items }
      onCategoryToggle={ handleSwitchToggle }
      disabledRiskFilterTypes={ disabledRiskFilterTypes }
    />
  );
};

export default RiskLineEventsContainer;
