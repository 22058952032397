import { createModel } from '@rematch/core';

import { logger } from '@/common/util/ConsoleLogger';
import { Dispatch, RootModel, RootState } from '@/core/store';
import {
  getCityRiskProvidersList, getCityRiskReport,
  getCityRiskReportsSummery
} from '@/tenant-context/control-reports/api/city-risk-reports';
import { CityRiskData, CityRiskProvider } from '@/tenant-context/control-reports/types/city-risk-reports';


export type CityRiskReports = {
  cityRiskDataReports: CityRiskData[];
  citiesList: { city: string, country: string, countryIsoCode: string }[];
  selectedRiskProvider?: CityRiskProvider;
  selectedDetailedCityReport?: CityRiskData;
  cityRiskProvidersList: CityRiskProvider[];
}

const cityRiskReportsDefaultState = {
  cityRiskDataReports: [],
  citiesList: [],
  selectedRiskProvider: undefined,
  selectedDetailedCityReport: undefined,
  cityRiskProvidersList: []
} as CityRiskReports;

const cityRiskReportsState = {
  name: 'cityRiskReports',
  state: cityRiskReportsDefaultState,
  reducers: {
    SET_CITY_RISK_DATA_REPORTS(state: CityRiskReports, cityRiskDataReports: CityRiskData[]) {
      return {
        ...state,
        cityRiskDataReports
      };
    },
    SET_CITIES_LIST(state: CityRiskReports, citiesList: { city: string, country: string, countryIsoCode: string }[]) {
      return {
        ...state,
        citiesList: citiesList
      };
    },
    SET_SELECTED_RISK_PROVIDER(state: CityRiskReports, selectedRiskProvider?: CityRiskProvider) {
      return {
        ...state,
        selectedRiskProvider
      };
    },
    SET_SELECTED_DETAILED_CITY_REPORT(state: CityRiskReports, selectedDetailedCityReport?: CityRiskData) {
      return {
        ...state,
        selectedDetailedCityReport
      };
    },
    SET_CITY_RISK_PROVIDERS_LIST(state: CityRiskReports, cityRiskProvidersList: CityRiskProvider[]) {
      return {
        ...state,
        cityRiskProvidersList
      };
    },
    RESET_CITY_RISK_REPORTS(_: CityRiskReports) {
      return cityRiskReportsDefaultState;
    }
  },
  effects: (dispatch: Dispatch) => ({
    async fetchCityRiskProvidersList(_: void, _state: RootState): Promise<void> {
      try {
        const cityRiskProvidersList = await getCityRiskProvidersList();
        dispatch.cityRiskReports.SET_CITY_RISK_PROVIDERS_LIST(cityRiskProvidersList);
      } catch (error) {
        logger.error('Error fetching city risk providers list', error);
        dispatch.cityRiskReports.SET_CITY_RISK_PROVIDERS_LIST([]);
      }
    },
    async fetchCityRiskDataReports(riskProvider: CityRiskProvider, state: RootState): Promise<void> {
      const { selectedRiskProvider } = state.cityRiskReports;

      if (!(selectedRiskProvider || riskProvider)) {
        return;
      }

      try {
        const riskProviderForQuery = riskProvider || selectedRiskProvider;
        const cityRiskDataReports = await getCityRiskReportsSummery(riskProviderForQuery.providerName);
        const extractedCityList = cityRiskDataReports.map(
          (cityRiskData) => {
            return {
              city: cityRiskData.cityName,
              country: cityRiskData.countryName || '',
              countryIsoCode: cityRiskData.countryISOCode || ''
            };
          }
        );

        dispatch.cityRiskReports.SET_CITY_RISK_DATA_REPORTS(cityRiskDataReports);
        dispatch.cityRiskReports.SET_CITIES_LIST(extractedCityList);
      } catch (error) {
        logger.error('Error fetching city risk data reports', error);
        dispatch.cityRiskReports.SET_CITY_RISK_DATA_REPORTS([]);
      }
    },
    async loadSelectedCityReport(citySummery: CityRiskData, state: RootState): Promise<void> {
      const { selectedRiskProvider } = state.cityRiskReports;
      dispatch.cityRiskReports.SET_SELECTED_DETAILED_CITY_REPORT(citySummery);

      if (!selectedRiskProvider) {
        return;
      }

      try {
        const cityRiskDataReport = await getCityRiskReport(
          selectedRiskProvider.providerName,
          citySummery.tid
        );

        dispatch.cityRiskReports.SET_SELECTED_DETAILED_CITY_REPORT({
          ...citySummery,
          ...cityRiskDataReport,
          providerName: selectedRiskProvider.providerName
        });
      } catch (error) {
        logger.error('Error fetching city risk data reports', error);
        dispatch.cityRiskReports.SET_SELECTED_DETAILED_CITY_REPORT(undefined);
      }
    }
  })
};

export const cityRiskReports = createModel<RootModel>()(
  cityRiskReportsState
);
