import { ActionIcon } from "@mantine/core";
import { FC, useRef } from "react";
import ReactPlayer from 'react-player';
import { OnProgressProps } from "react-player/base";

import { ReactComponent as CloseIcon } from "@/common/icons/close.svg";
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import {
  useEmergencyVideoPopupStyles
} from "@/tenant-context/employee-app-action-response-center/components/ui/EmergencyVideoPopup/EmergencyVideoPopup.styles";
import VideoProgressBarComponent from "@/tenant-context/employee-app-action-response-center/components/ui/VideoProgressBarComponent";
import { VideoMode } from "@/tenant-context/employee-app-action-response-center/types/emergency-video";

type Props = {
  url: string;
  mode: VideoMode;
  isPlaying: boolean;
  totalVideos: number;
  currentVideoIndex: number;
  videoProgress: OnProgressProps;
  onEnded: () => void;
  onCloseRequested: () => void;
  onProgress: (progress: OnProgressProps) => void;
}

const EmergencyVideoPopupComponent: FC<Props> = ({
  url,
  mode,
  isPlaying,
  totalVideos,
  currentVideoIndex,
  videoProgress,
  onEnded,
  onCloseRequested,
  onProgress
}) => {

  const videoPlayerRef = useRef<ReactPlayer>(null);
  const { classes } = useEmergencyVideoPopupStyles();

  return (
    <div style={ { maxWidth: '500px', maxHeight: '500px' } }>
      <LocalErrorBoundary>
        <VideoProgressBarComponent
          numberOfVideos={ totalVideos }
          currentVideoIndex={ currentVideoIndex }
          currentVideoPercentage={ videoProgress.played * 100 }
        />

        <ReactPlayer
          ref={ videoPlayerRef }
          url={ url }
          playing={ isPlaying }
          progressInterval={ 500 }
          onEnded={ onEnded }
          onProgress={ onProgress }
          width={ mode === VideoMode.LANDSCAPE ? '400px' : '' }
          height={ mode === VideoMode.PORTRAIT ? '500px' : '' }
          controls
          muted
        />

        <ActionIcon
          className={ classes.closeButton }
          onClick={ onCloseRequested }
        >
          <CloseIcon />
        </ActionIcon>
      </LocalErrorBoundary>
    </div>);
};

export default EmergencyVideoPopupComponent;
