import { Button } from '@mantine/core';
import { FC, useContext, useMemo } from "react";
import { useDispatch } from 'react-redux';

import DrawerControl from "@/common/components/DrawerControl";
import usePermission from '@/common/hooks/usePermission';
import { Dispatch } from '@/core/store';
import BigProfile from "@/tenant-context/control-profile/components/ui/BigProfile";
import MiniProfile from "@/tenant-context/control-profile/components/ui/MiniProfile";
import { useMiniProfileControlStyles } from "@/tenant-context/control-profile/controls/MiniProfileControl/MiniProfileControl.styles";
import EAARC from "@/tenant-context/employee-app-action-response-center/components/ui/EaArc";
import TaskSummaryTime from "@/tenant-context/employee-app-action-response-center/components/ui/TaskSummaryTime/TaskSummaryTime.component";
import { EaArcPoliciesConfig } from '@/tenant-context/employee-app-action-response-center/config/permissions';
import { EaArcContext } from "@/tenant-context/employee-app-action-response-center/context/EaArc.context";


type Props = {
  isBigProfileOpen: boolean,
  isEaArcOpen: boolean,
  isInFullscreenOverlay: boolean,
  isEmergency: boolean,
  onOpenBigProfile: () => void,
  onDrawerClose: () => void
};

export const MINI_PROFILE_CONTROL_DRAWER_ID = 'mini-profile-control';

const MiniProfileControl: FC<Props> = ({
  isBigProfileOpen,
  isEaArcOpen,
  isInFullscreenOverlay,
  isEmergency,
  onOpenBigProfile: openBigProfile,
  onDrawerClose
}) => {

  const lwARCPermissions = usePermission(EaArcPoliciesConfig.OPEN_CASE);

  const { classes } = useMiniProfileControlStyles({ isInMiniProfile: true });

  const {
    arcCase: {
      eventStartedDataTime
    } = {
      eventStartedDataTime: undefined
    }
  } = useContext(EaArcContext);

  const {
    eaArc: {
      openEaArc
    }
  } = useDispatch<Dispatch>();


  const footer = useMemo(() => {
    if (isBigProfileOpen || isEaArcOpen) {
      return null;
    }

    if (!isInFullscreenOverlay && !isEmergency) {
      return (
        <Button
          fullWidth
          onClick={ openBigProfile }
        >
          View Full Profile
        </Button>
      );
    }

    return (
      <Button
        fullWidth
        disabled={ !lwARCPermissions }
        title={ !lwARCPermissions ? 'You are not authorized to open a Lone Worker ARC case' : '' }
        onClick={ openEaArc }
      >
        Open Case
      </Button>
    );
  }, [
    isBigProfileOpen,
    isEaArcOpen,
    isEmergency,
    isInFullscreenOverlay,
    lwARCPermissions,
    openBigProfile,
    openEaArc
  ]);

  return (
    <DrawerControl
      id={ MINI_PROFILE_CONTROL_DRAWER_ID }
      header={ eventStartedDataTime && (
        <TaskSummaryTime
          direction="row"
          time={ eventStartedDataTime }
          timeStyle='relative'
          isAgo
          isWithDate={ false }
        />
      ) }
      footer={ footer }
      size={ isBigProfileOpen || isEaArcOpen ? 'full' : 'lg' }
      drawerPosition="right"
      onCloseClick={ onDrawerClose }
    >
      <div className={ classes.container }>
        <MiniProfile />

        { isBigProfileOpen && (
          <BigProfile />
        ) }

        { isEaArcOpen && (
          <EAARC />
        ) }
      </div>
    </DrawerControl>
  );
};

export default MiniProfileControl;
