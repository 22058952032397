import { callApi } from "@/common/util/api";
import createServerSentEventStream, { SubscriptionEventHandler } from "@/common/util/sse";
import { store } from "@/core/store";
import { CloseCasePayload,EaArcCase, EaArcCasePersonLocationEvent, EaArcEmergencyVideoStreamEvent, EaTask } from "@/tenant-context/employee-app-action-response-center/types/ea-arc";

export const getEaArcCaseByProfileId = (
  profileId: string
) => callApi<
  EaArcCase
>(
  `/arc-service/v1/lone-worker/case/profile/${profileId}`,
  {
    method: 'get',
    withTenantId: true
  }
);

export const getTaskById = (
  taskId: string
) => callApi<
  EaTask
>(
  `/managed/lone-worker-service/v1/tasks/${taskId}`,
  {
    method: 'get',
    withTenantId: true
  }
);

export const closeCase = (payload: CloseCasePayload) => callApi<
  EaTask
>(
  `/arc-service/v1/lone-worker/case/close`,
  {
    method: 'post',
    withTenantId: true,
    body: JSON.stringify(payload)
  }
);

export const createEaArcCaseEventsStream = (
  profileId: string,
  handleEvent: SubscriptionEventHandler<EaArcCasePersonLocationEvent>
) => {
  const tenantId = store.getState().commonData?.tenantId;

  return createServerSentEventStream(
    `/arc-service/v1/lone-worker/case/stream/case/${ profileId }?tenantId=${tenantId}`,
    handleEvent
  );
};

export const createEmergencyVideoStream = (
  profileId: string,
  caseId: string,
  handleEvent: SubscriptionEventHandler<EaArcEmergencyVideoStreamEvent>
) => {
  const tenantId = store.getState().commonData?.tenantId;

  return createServerSentEventStream(
    `/arc-service/v1/lone-worker/case/stream/${ caseId }/profile/${ profileId }/case-video?tenantId=${ tenantId }`,
    handleEvent
  );
};
