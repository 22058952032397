import { FileWithPath } from "@mantine/dropzone";
import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { ReferenceData } from "@/common/types/reference-data";
import { handleError, handleSuccess } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import {
  createICMEvent,
  deleteICMEvent,
  exportICMEvents, getEventTypeReference, getEventUseCaseReference,
  getICMEventsData,
  updateICMEvent,
  uploadEventsFile
} from "../api/icmEvents";
import { ICMEvent, ICMEventCreate, ICMEventsParams } from "../types/icmEvents";

type EventsState = {
  eventsData?: PaginatedResult<ICMEvent>,
  uploadEventsPercentage?: number,
  uploadEventsFileUploadingStatus?: 'in-progress' | 'successful' | 'failed',
  uploadEventsFailedMessage?: string
  activeEvent?: ICMEvent,
  isActiveEventLoading?: boolean
  eventTypeReference?: PaginatedResult<ReferenceData>
  eventUseCaseReference?: PaginatedResult<ReferenceData>
};

const icmEventsDataModel = {
  name: 'icmEvents',
  state: {
    eventsData: undefined,
    uploadEventsFailedMessage: undefined,
    uploadEventsFileUploadingStatus: undefined,
    uploadEventsPercentage: undefined,
    activeEvent: undefined,
    isActiveEventLoading: false,
    eventTypeReference: undefined,
    eventUseCaseReference: undefined
  } as EventsState,
  reducers: {
    SET_EVENTS: (state: EventsState, icmEvents: EventsState['eventsData']) => ({
      ...state,
      icmEvents
    }),
    SET_UPLOAD_EVENTS_PERCENTAGE: (state: EventsState, uploadEventsPercentage: EventsState['uploadEventsPercentage']) => ({
      ...state,
      uploadEventsPercentage
    }),
    SET_UPLOAD_EVENTS_STATUS: (state: EventsState, uploadEventsFileUploadingStatus: EventsState['uploadEventsFileUploadingStatus']) => ({
      ...state,
      uploadEventsFileUploadingStatus
    }),
    SET_UPLOAD_EVENTS_FAILED_MESSAGE: (state: EventsState, uploadEventsFailedMessage: EventsState['uploadEventsFailedMessage']) => ({
      ...state,
      uploadEventsFailedMessage
    }),
    SET_ACTIVE_EVENT: (state: EventsState, activeEvent: EventsState['activeEvent']) => ({
      ...state,
      activeEvent
    }),
    SET_IS_ACTIVE_EVENT_LOADING: (state: EventsState, isActiveEventLoading: EventsState['isActiveEventLoading']) => ({
      ...state,
      isActiveEventLoading
    }),
    SET_EVENT_TYPE_REFERENCE: (state: EventsState, eventTypeReference: EventsState['eventTypeReference']) => ({
      ...state,
      eventTypeReference
    }),
    SET_EVENT_USE_CASE_REFERENCE: (state: EventsState, eventUseCaseReference: EventsState['eventUseCaseReference']) => ({
      ...state,
      eventUseCaseReference
    })
  },
  effects: (dispatch: Dispatch) => ({
    resetUploadEventsModal(): void {
      dispatch.icmEvents.SET_UPLOAD_EVENTS_PERCENTAGE(undefined);
      dispatch.icmEvents.SET_UPLOAD_EVENTS_STATUS(undefined);
      dispatch.icmEvents.SET_UPLOAD_EVENTS_FAILED_MESSAGE(undefined);
    },
    async getICMEventsData(payload: {
        gridParams: GridParams,
        additionalParams?: ICMEventsParams
    }, _state: RootState): Promise<PaginatedResult<ICMEvent>> {
      const query = payload.gridParams.searchQuery;
      if(query){
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `description LIKE %27%25${query}%25%27 OR eventType LIKE %27%25${query}%25%27 OR summary LIKE %27%25${query}%25%27 OR useCase LIKE %27%25${query}%25%27`;
      }
      const eventsData = await getICMEventsData(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams?.searchQuery
      );

      dispatch.icmEvents.SET_EVENTS(eventsData);
      return eventsData;
    },

    async exportEvents(gridParams: GridParams, _state: RootState): Promise<unknown> {
      const link = await exportICMEvents(
        gridParams.page,
        gridParams.size
      ).catch(handleError);

      return link;
    },
    
    async uploadEventsFile(file: FileWithPath, _state: RootState): Promise<void> {
      const formData = new FormData();
      formData.append('files', file);

      dispatch.icmEvents.SET_UPLOAD_EVENTS_PERCENTAGE(50);
      try {
        const upload = await uploadEventsFile(formData);

        if (upload.status === '200') {
          dispatch.icmEvents.SET_UPLOAD_EVENTS_STATUS('successful');
        } else {
          dispatch.icmEvents.SET_UPLOAD_EVENTS_STATUS('failed');
          dispatch.icmEvents.SET_UPLOAD_EVENTS_FAILED_MESSAGE('An error occurred while uploading the file.');
        }
      } catch (e) {
        dispatch.icmEvents.SET_UPLOAD_EVENTS_STATUS('failed');
        dispatch.icmEvents.SET_UPLOAD_EVENTS_FAILED_MESSAGE('An error occurred while uploading the file.');
        if (e instanceof Error) {
          dispatch.icmEvents.SET_UPLOAD_EVENTS_FAILED_MESSAGE(e?.message ?? 'An error occurred while uploading the file.');
        }
      }
      dispatch.icmEvents.SET_UPLOAD_EVENTS_PERCENTAGE(100);
    },

    async createICMEvent(event: ICMEventCreate, _state: RootState): Promise<void> {
      dispatch.icmEvents.SET_IS_ACTIVE_EVENT_LOADING(true);
      const res = await createICMEvent(event).catch(handleError);
      if(res){
        handleSuccess('Record created successfully!');
        dispatch.icmEvents.SET_IS_ACTIVE_EVENT_LOADING(false);
      }
    },

    async updateActiveICMEvent(event: ICMEventCreate, state: RootState): Promise<void> {
      const { activeEvent } = state.icmEvents;
      if (!activeEvent?._id) {
        return;
      }
      dispatch.icmEvents.SET_IS_ACTIVE_EVENT_LOADING(true);
      const res = await updateICMEvent(event, activeEvent?._id).catch(handleError);
      if(res){
        handleSuccess('Record updated successfully!');
        dispatch.icmEvents.SET_IS_ACTIVE_EVENT_LOADING(false);
      }
    },

    async deleteICMEvent(eventId: string, _state: RootState): Promise<void> {
      dispatch.icmEvents.SET_IS_ACTIVE_EVENT_LOADING(true);
      await deleteICMEvent(eventId).then(() => handleSuccess('Record deleted successfully!')).catch(handleError).finally(() => {
        dispatch.icmEvents.SET_IS_ACTIVE_EVENT_LOADING(false);
      });
    },

    async getEventTypeReference(): Promise<void> {
      await getEventTypeReference()
        .then((res) => dispatch.icmEvents.SET_EVENT_TYPE_REFERENCE(res))
        .catch(handleError);
    },

    async getEventUseCaseReference(): Promise<void> {
      await getEventUseCaseReference()
        .then((res) => dispatch.icmEvents.SET_EVENT_USE_CASE_REFERENCE(res))
        .catch(handleError);
    }
  })
};

export const icmEvents = createModel<RootModel>()(icmEventsDataModel);
