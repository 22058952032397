import { FC } from "react";

import { useArcSectionStyles } from "@/tenant-context/control-action-response-center/components/ArcSection/ArcSection.style";

type Props = {
  className?: string,
  title?: string
}

const ArcSection: FC<Props> = ({
  children,
  className,
  title
}) => {
  const { classes, cx } = useArcSectionStyles();

  return (
    <section className={ cx(classes.section, className) }>
      { title && (
        <h3 className={ classes.title }>{ title }</h3>
      ) }

      { children }
    </section>
  );
};

export default ArcSection;