import { Radio } from '@mantine/core';
import { FC, useCallback } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { useDrawingFilterRadioStyles } from "@/tenant-context/control-draw/components/DrawingFilterRadio/DrawingFilterRadio.styles";
import { DrawingFilterTypes } from "@/tenant-context/control-draw/types/draw";

type Props = {
  defaultDrawingFilterValue?: string,
  onDrawingFilterSelected: (item: DrawingFilterTypes) => void
}

const DrawingFilterRadio: FC<Props> = ({
  onDrawingFilterSelected
}) => {
  const { classes } = useDrawingFilterRadioStyles();

  const typeValue = useSelector((state: RootState) => state.geofence?.geofenceListFilterValue?.type);
  const type = useSelector((state: RootState) => state.geofence?.geofenceTypeFilter);

  const onSelectChanged = useCallback((value: DrawingFilterTypes) => {

    onDrawingFilterSelected(value);
  }, [onDrawingFilterSelected]);

  return (
    <div>
      <Radio.Group
        className={ classes.radioGroup }
        onChange={ onSelectChanged }
        label="Filter by"
        orientation="vertical"
        value={ typeValue || type }
      >
        <Radio className={ classes.radioItem } value={ DrawingFilterTypes.ALL } label="All Geofence"/>
        <Radio className={ classes.radioItem } value={ DrawingFilterTypes.GENERAL } label="General Geofence"/>
        <Radio className={ classes.radioItem } value={ DrawingFilterTypes.PROHIBITED } label="Prohibitive Geofence"/>
      </Radio.Group>
    </div>
  );
};


export default DrawingFilterRadio;
