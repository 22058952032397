export enum Environment {
  Dev = 'dev',
  Qa = 'qa',
  UAT = 'uat',
  PROD = 'prod',
  Demo = 'demo'
}

type Env = {
  unifiedExperienceUrl: string,
  outSystemsGlobalDashboardUrl: string,
  outSystemsSitesUrl: string,
  restEndpoint: string
}

// Not to hit process.env in runtime (for performance reasons)
// https://stackoverflow.com/a/48453906/6686446
const {
  REACT_APP_REST_ENDPOINT,
  REACT_APP_UNIFIED_EXPERIENCE_URL,
  REACT_APP_OUTSYSTEMS_GLOBAL_DASHBOARD_URL,
  REACT_APP_OUTSYSTEMS_SITES_URL
} = process.env;

export const ENV: string = (function() {

  if (!REACT_APP_REST_ENDPOINT) {
    throw new Error('No REST endpoint!');
  }

  const map: Record<string, string> = {
    'https://global-f1dev.demo.copy.restrata.net': Environment.Dev,
    'https://global-dev.restrata.net/': Environment.Dev,
    'https://global-qa.restrata.net/': Environment.Qa,
    'https://global-uat.restrata.net/': Environment.UAT,
    'https://global.restrata.net/': Environment.PROD,
    'https://demo.global-demo.restrata.net': Environment.Demo
  };

  // Considering any other envs as DEV (For isInDevMode checks)
  return map[REACT_APP_REST_ENDPOINT] || Environment.Dev;
})();

export const environment = (function() {
  if (!REACT_APP_REST_ENDPOINT || !REACT_APP_UNIFIED_EXPERIENCE_URL
    || !REACT_APP_OUTSYSTEMS_GLOBAL_DASHBOARD_URL || !REACT_APP_OUTSYSTEMS_SITES_URL) {
    throw new Error('Endpoints are not configured correctly!');
  }

  return {
    unifiedExperienceUrl: REACT_APP_UNIFIED_EXPERIENCE_URL,
    outSystemsGlobalDashboardUrl: REACT_APP_OUTSYSTEMS_GLOBAL_DASHBOARD_URL,
    outSystemsSitesUrl: REACT_APP_OUTSYSTEMS_SITES_URL,
    restEndpoint: REACT_APP_REST_ENDPOINT
  } as Env;
})();

export const isInDevMode = (ENV === Environment.Dev || ENV === Environment.Qa || ENV === Environment.Demo);
export const isInPRODMode = (ENV === Environment.UAT || ENV === Environment.PROD);
