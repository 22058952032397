/* typescript-eslint/no-explicit-any is disabled because the parent object (DragCircleMode) is written in JS */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { DragCircleMode } from 'mapbox-gl-draw-circle';

import { cursors } from '@/tenant-context/control-draw/controls/MapboxDrawControl/config/const.config';

const ExtendedDragCircleMode = { ...DragCircleMode };

ExtendedDragCircleMode.onMouseMove = function (state: any, e: any) {
  DragCircleMode.onMouseMove.call(this, state, e);
  this.updateUIClasses({ mouse: cursors.ADD });
};

export default ExtendedDragCircleMode;
