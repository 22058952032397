import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { getRiskLevelColor } from "@/tenant-context/common/util/risk";


type Params = {
  level: string
}

export const useRiskLabelStyles = createStyles((theme: MantineTheme, params: Params) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 82,
    minHeight: 22,
    borderRadius: 1,
    backgroundColor: getRiskLevelColor(params.level, theme),
    color: params.level === "3" ? theme.colors.neutral[7] : 'auto',
    fontWeight: theme.other.fw.regular
  }
}));
