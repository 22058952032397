import { FeatureCollection, Point } from "geojson";
import { FC } from "react";
import { Layer, Source, useMap } from "react-map-gl";

import { useRiskAlertIcons } from "@/tenant-context/visualisation-risk-alerts/hooks/useRiskAlertIcons";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type Props = {
  riskAlertsGeoData?: FeatureCollection<Point, RiskAlertEvent>,
}

const ImpactZoneLayer: FC<Props> = ({
  riskAlertsGeoData
}) => {
  const { current: map } = useMap();
  useRiskAlertIcons(map, true);
  
  if(!riskAlertsGeoData){
    return null;
  }

  return (
    <Source
      id="r__risk-impact-source-arc"
      type="geojson"
      tolerance={ 0 }
      cluster={ false }
      data={ riskAlertsGeoData }
    >
      <Layer
        id="_risk-impact-layer-arc"
        key="_risk-impact-layer-arc"
        type="symbol" 
        layout={ {
          'icon-size': [
            'match',
            ['get', 'source'],
            'max-security', 0.35,
            'dataminr', 0.8,
            0.35
          ],
          'icon-image': [
            'match',
            ['get', 'source'],
            'max-security', ['concat',
              'RiskAlertIcon_',
              ['get', 'name', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]],
              'TriangleBase'
            ],
            ['concat',
              'RiskAlertIcon_',
              ['get', 'name', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]],
              [
                'match',
                ['get', 'id', ['get', 'category', ['get', 'alert', ['get', 'json']]]],
                '7', 'TravelSafety',
                '6', 'Medical',
                '5', 'Natural',
                '4', 'Crime',
                '3', 'Unrest',
                '2', 'Conflict',
                '1', 'Political',
                'Base'
              ]]
          ],
          'icon-allow-overlap': true
        } }
      />
      <Layer
        id="_risk-category-layer-arc"
        key="_risk-category-layer-arc"
        type="symbol"
        layout={ {
          'icon-size': [
            'match',
            ['get', 'source'],
            'max-security', 0.35,
            0.35
          ],
          'icon-image': [
            'match',
            ['get', 'source'],
            'max-security', ['concat',
              'RiskAlertIcon_MaxSecurity_',
              ['get', 'id', ['get', 'category', ['get', 'alert', ['get', 'json']]]],
              ['case',
                ['==', ['get', 'name', ['get', 'risk_level', ['get', 'alert', ['get', 'json']]]], 'Extreme'],
                '_White',
                ''
              ]
            ],
            ''
          ],
          'icon-allow-overlap': true
        } }
      />

      <Layer
        id='r__risk-impacthovericons-impacted-warning-arc'
        type='symbol'
        layout={ {
          'icon-image': 'RiskAlertIcon_RiskAlertImpacted',
          'icon-size': 0.35,
          'icon-allow-overlap': true,
          // eslint-disable-next-line no-magic-numbers
          'icon-offset':[25, -45]
        } }
      />
    </Source>
  );
};

export default ImpactZoneLayer;
