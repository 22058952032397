import { ColDef } from "ag-grid-community";
import { forwardRef, ForwardRefRenderFunction } from "react";

import ServerDataGrid from "@/common/components/AgGrid/ServerSide/ServerDataGrid.container";
import { DataGrid, GridParams } from "@/common/types/ag-grid";
import {
  useProfileIdentificationTabStyles
} from "@/tenant-context/control-profile/components/tabs/ProfileIdentificationTab/ProfileIdentificationTab.style";
import ProfileAccessCardSearch
  from "@/tenant-context/control-profile/components/ui/ProfileAccessCardSearch";
import { AccessCard, PaginatedResult } from "@/tenant-context/control-profile/types/profile";


type Props = {
  columnDefs: ColDef<AccessCard>[]
  getData: (payload: {
    gridParams: GridParams
  }) => Promise<PaginatedResult<AccessCard>>,
}

const ProfileIdentificationTab: ForwardRefRenderFunction<DataGrid, Props> = ({
  getData,
  columnDefs
}, ref) => {

  const { classes } = useProfileIdentificationTabStyles();
  return (
    <div className={ classes.wrapper }>
      <div className={ classes.titleContainer }>
        <div className={ classes.title }>Assign Access Control Card</div>
      </div>
      <div className={ classes.searchableSelectContainer }>
        <ProfileAccessCardSearch/>
      </div>
      <div className={ classes.tableContainer }>
        <div className={ classes.titleContainer }>
          <div className={ classes.title }>Assigned Access Control Cards</div>
        </div>
        <div className="ag-theme-alpine-dark ag-theme-rt">
          <ServerDataGrid
            id={ "accessCards-grid" }
            columnDefs={ columnDefs }
            ref={ ref }
            getData={ getData }
            animateRows={ true }
            domLayout={ 'autoHeight' }
            suppressCellFocus={ true }
            overlayNoRowsTemplate={ 'No cards assigned' }
          />
        </div>
      </div>
    </div>
  );
};


export default forwardRef(ProfileIdentificationTab) as (
  props: Props & React.RefAttributes<DataGrid>
) => JSX.Element;
