import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { RootState, RootStateWithLoading } from '@/core/store';
import CountryRiskReportsPageComponent
  from '@/tenant-context/control-reports/components/CountryRiskReportsPage/CountryRiskReportsPage.component';
import { CountryRiskData, CountryRiskProvider } from '@/tenant-context/control-reports/types/country-risk-reports';

const CountryRiskReportsPageContainer: FC = () => {
  const {
    countryRiskDataReports,
    selectedRiskProvider,
    countriesList,
    selectedDetailedCountryReport,
    countryRiskProvidersList
  } = useSelector((state: RootState) => state.countryRiskReports);

  const isCountrySummaryLoading = useSelector(
    (state: RootStateWithLoading) => state.loading.effects.countryRiskReports.fetchCountryRiskDataReports
  );

  const [isDrawerExpanded, setIsDrawerExpanded] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  const {
    countryRiskReports: {
      fetchCountryRiskDataReports,
      loadSelectedCountyReport,
      fetchCountryRiskProvidersList,
      SET_SELECTED_RISK_PROVIDER,
      SET_SELECTED_DETAILED_COUNTRY_REPORT
    }
  } = useDispatch();

  const handleDrawerClose = useCallback(() => {
    setIsDrawerExpanded(false);
    SET_SELECTED_DETAILED_COUNTRY_REPORT(undefined);
  }, [SET_SELECTED_DETAILED_COUNTRY_REPORT]);

  const toggleDrawerExpand = useCallback(() => {
    setIsDrawerExpanded(!isDrawerExpanded);
  }, [isDrawerExpanded]);

  const filteredCountryRiskDataReports = useMemo(() => {
    return countryRiskDataReports.filter((countryRiskData) => {
      return countryRiskData.countryName.toLowerCase().includes(searchString.toLowerCase());
    });
  }, [countryRiskDataReports, searchString]);

  const setSelectedRiskProvider = useCallback((value: CountryRiskProvider) => {
    SET_SELECTED_RISK_PROVIDER(value);
    fetchCountryRiskDataReports(value);
  }, [SET_SELECTED_RISK_PROVIDER, fetchCountryRiskDataReports]);

  const handleOpenDetailedReportDrawer = useCallback((countryRiskData: CountryRiskData) => {
    SET_SELECTED_DETAILED_COUNTRY_REPORT(countryRiskData);
    loadSelectedCountyReport(countryRiskData);
  }, [SET_SELECTED_DETAILED_COUNTRY_REPORT, loadSelectedCountyReport]);

  useEffect(() => {
    fetchCountryRiskProvidersList();
  }, [fetchCountryRiskProvidersList]);

  useEffect(() => {
    if (!countryRiskProvidersList || countryRiskProvidersList.length === 0) {
      return;
    }
    setSelectedRiskProvider(countryRiskProvidersList[0]);
    SET_SELECTED_RISK_PROVIDER(countryRiskProvidersList[0]);
  }, [setSelectedRiskProvider, countryRiskProvidersList, SET_SELECTED_RISK_PROVIDER]);

  return <CountryRiskReportsPageComponent
    selectedRiskProvider={ selectedRiskProvider }
    onRiskProviderChange={ setSelectedRiskProvider }
    onDrawerClose={ handleDrawerClose }
    countryRiskDataList={ filteredCountryRiskDataReports }
    selectedCountryRiskReport={ selectedDetailedCountryReport }
    setSelectedCountryReport={ handleOpenDetailedReportDrawer }
    isDrawerExpanded={ isDrawerExpanded }
    toggleDrawerExpand={ toggleDrawerExpand }
    onSearchStringChange={ setSearchString }
    allCountries={ countriesList }
    isCountrySummaryLoading={ isCountrySummaryLoading }
    countryRiskProvidersList={ countryRiskProvidersList }
  />;
};

export default CountryRiskReportsPageContainer;
