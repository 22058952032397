import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';

import { RouterConfig } from '@/core/components/Router/Router.config';
import { Dispatch, RootState } from "@/core/store";
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import EaArcContextProvider from "@/tenant-context/employee-app-action-response-center/context/EaArc.context";
import { isSomeKindOfEmergency } from '@/tenant-context/employee-app-action-response-center/util/emergency';
import {
  LWPersonStatus
} from '@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config';

import MiniProfileControl, { MINI_PROFILE_CONTROL_DRAWER_ID } from "./MiniProfileControl.component";


const MiniProfileControlContainer: FC = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const isBigProfileOpen = useSelector((state: RootState) => state.profile.isOpen);
  const isEaArcOpen = useSelector((state: RootState) => state.eaArc.isOpen);
  const openDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenRightDrawerId);
  const lwStatus = useSelector((state: RootState) => state.userProfile.lwStatus);
  const userId = useSelector((state: RootState) => state.userProfile.id);

  const {
    locationGraph: {
      disableGraph
    },
    profile: {
      openBigProfile
    },
    drawer: {
      closeRightDrawer
    } } = useDispatch<Dispatch>();

  const handleOpenBigProfile = useCallback(() => {
    disableGraph();

    if (!userId) {
      return;
    }

    const isInProfileList = location.pathname === RouterConfig.routes.profileList;

    //open profile unique page only if inside profile list
    if(!isInProfileList){
      openBigProfile(userId);
    } else{
      closeRightDrawer();
      navigate(RouterConfig.routes.bigProfile(userId));
    }

  }, [disableGraph, userId, location.pathname, openBigProfile, closeRightDrawer, navigate]);

  const handleProfileDrawerClose = useCallback(() => {
    disableGraph();
  }, [disableGraph]);

  if (openDrawerId !== MINI_PROFILE_CONTROL_DRAWER_ID) {
    return null;
  }

  return (
    <LocalErrorBoundary>
      <EaArcContextProvider>
        <MiniProfileControl
          isBigProfileOpen={ isBigProfileOpen }
          isEaArcOpen={ isEaArcOpen }
          isEmergency={ isSomeKindOfEmergency(lwStatus as LWPersonStatus) }
          isInFullscreenOverlay={ isBigProfileOpen || isEaArcOpen }
          onOpenBigProfile={ handleOpenBigProfile }
          onDrawerClose={ handleProfileDrawerClose }
        />
      </EaArcContextProvider>
    </LocalErrorBoundary>
  );
};

export default MiniProfileControlContainer;
