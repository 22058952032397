import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useImpactStatusChangeModalStyles = createStyles((theme: MantineTheme) => ({
  personName: {
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[5],
    marginBottom: 24
  },

  inputGroup: {
    marginBottom: 24
  },

  statusItem: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    borderWidth: '0px 1px 1px 1px',
    borderColor: theme.colors.neutral[6],
    backgroundColor: theme.colors.neutral[8],
    paddingLeft: 16,
    paddingRight: 16,
    color: 'white'
  },

  statusIndicator: {
    '&_NOT_SAFE': {
      backgroundColor: `${ theme.colors.risk[5] } !important`,
      marginRight: 12
    },
    '&_NO_RESPONSE': {
      backgroundColor: `${ theme.colors.risk[2] } !important`,
      color: `${ theme.colors.neutral[8] } !important`,
      marginRight: 12
    },
    '&_SAFE': {
      backgroundColor: `${ theme.colors.risk[1] } !important`,
      marginRight: 12
    },
    '&_OTHER': {
      backgroundColor: `${ 'blue' } !important`,
      marginRight: 12
    }
  }
}));
