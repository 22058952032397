import { animated, to, useTransition } from "@react-spring/web";
import { PieArcDatum, ProvidedProps } from "@visx/shape/lib/shapes/Pie";
import { FC } from "react";

import {
  AnimatedStyles, enterUpdateTransition, fromLeaveTransition
} from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactedSummaryPieChart/ImpactedSummaryPieChart.types";
import { ArcPeopleImpactItem } from "@/tenant-context/control-action-response-center/types/ARC.types";

type Props<ArcPeopleImpactItem> = ProvidedProps<ArcPeopleImpactItem> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<ArcPeopleImpactItem>) => string;
  getColor: (d: PieArcDatum<ArcPeopleImpactItem>) => string;
  delay?: number;
}

const AnimatedPie: FC<Props<ArcPeopleImpactItem>> = ({
  animate,
  arcs,
  path,
  getKey,
  getColor
}) => {

  const transitions = useTransition<PieArcDatum<ArcPeopleImpactItem>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey
  });

  return transitions((props, arc, { key }) => {
    return (
      <g key={ key }>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={ to(
            [props.startAngle, props.endAngle],
            (startAngle, endAngle) =>
              path({
                ...arc,
                startAngle,
                endAngle
              })
          ) }
          fill={ getColor(arc) }
        />
      </g>
    );
  });
};

export default AnimatedPie;
