import { Text } from "@mantine/core";
import { FC, ReactNode, useCallback } from "react";

import { ReactComponent as ChevronRight } from "@/common/icons/chevron-right-no-line.svg";
import { ReactComponent as ExtremeRiskIcon } from "@/common/icons/markers/RiskEvents/Extreme/Base.svg";
import { ReactComponent as HighRiskIcon } from "@/common/icons/markers/RiskEvents/High/Base.svg";
import { ReactComponent as LowRiskIcon } from "@/common/icons/markers/RiskEvents/Low/Base.svg";
import { ReactComponent as MediumRiskIcon } from "@/common/icons/markers/RiskEvents/Medium/Base.svg";
import { ReactComponent as ModerateRiskIcon } from "@/common/icons/markers/RiskEvents/Moderate/Base.svg";
import { capitalizeWord } from "@/common/util/format/string";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import { CustomColors } from "@/core/styles/mantine/palettes/types";
import { useTravellersSearchContext } from "@/tenant-context/control-travellers-search/context/TravellersSearchContext/TravellersSearch.context";
import { CountrySummaryDataType } from "@/tenant-context/control-travellers-search/types/travelSearch.types";

import { useTravellersByCountryDrawerStyles } from "../TravellersByCountryDrawer.styles";

type Props = {
  countryData: CountrySummaryDataType,
  onClick: (country: CountrySummaryDataType) => void
}

export const Country: FC<Props> = ({
  countryData,
  onClick
}) => {


  const {
    highlightedCountryIso,
    setHighlightedCountryIso
  } = useTravellersSearchContext();

  const { classes } = useTravellersByCountryDrawerStyles({
    highlightedCountry: highlightedCountryIso === countryData.countryISOCode
  });

  const getRiskLevelIcon = useCallback((riskLevel: string, riskText: string) => {
    const riskLevelIconMap: Record<string, ReactNode> = {
      5: <ExtremeRiskIcon width={ 24 } height={ 24 }/>,
      4: <HighRiskIcon width={ 24 } height={ 24 }/>,
      3: <MediumRiskIcon width={ 24 } height={ 24 }/>,
      2: <ModerateRiskIcon width={ 24 } height={ 24 }/>,
      1: <LowRiskIcon width={ 24 } height={ 24 }/>
    };

    const riskLevelColorMap: Record<string, CustomColors> = {
      5: genericColorPalette.risk[4] as CustomColors,
      4: genericColorPalette.risk[3] as CustomColors,
      3: genericColorPalette.risk[2] as CustomColors,
      2: genericColorPalette.risk[1] as CustomColors,
      1: genericColorPalette.risk[0] as CustomColors
    };

    return <div className={ classes.riskIconWrapper }>
      { riskLevelIconMap[riskLevel] }
      <Text className={ classes.riskText } color={ riskLevelColorMap[riskLevel] }>{ capitalizeWord(riskText) }</Text>
    </div>;
  }, [classes.riskIconWrapper, classes.riskText]);

  return (
    <button
      className={ classes.country }
      // eslint-disable-next-line react/jsx-no-bind
      onClick={ () => onClick(countryData) }
      // eslint-disable-next-line react/jsx-no-bind
      onMouseEnter={ () => setHighlightedCountryIso(countryData.countryISOCode ) }
      // eslint-disable-next-line react/jsx-no-bind
      onMouseLeave={ () => setHighlightedCountryIso(undefined) }
    >
      <p>
        { countryData.countryName }
      </p>
      <div>
        { countryData.risk && getRiskLevelIcon(countryData.risk.level, countryData.risk.category) }
      </div>
      <div>
        { countryData.travellerCount } <ChevronRight/>
      </div>
    </button>
  );
};
