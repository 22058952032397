import { FC } from "react";

import { formatMonth, getFormattedTimeDifference } from "@/common/util/format/date";
import { useTaskSummaryStyles } from "@/tenant-context/employee-app-action-response-center/components/ui/TaskSummary/TaskSummary.style";
import { useTaskSummaryTimeStyles } from "@/tenant-context/employee-app-action-response-center/components/ui/TaskSummaryTime/TaskSummaryTime.style";

type Props = {
  time: number,
  direction?: 'row' | 'column',
  timeStyle?: 'absolute' | 'relative' | 'overdue',
  isWithDate?: boolean,
  isAgo?: boolean,
  riskLevelIndex?: number
}

const TaskSummaryTime: FC<Props> = ({
  time,
  direction = 'column',
  timeStyle = 'absolute',
  isWithDate = true,
  isAgo = false,
  riskLevelIndex = 0
}) => {
  const { classes, cx } = useTaskSummaryStyles({
    direction,
    riskLevelIndex: 0
  });

  const {
    classes: timeClasses
  } = useTaskSummaryTimeStyles(riskLevelIndex);

  const date = new Date(time);

  const getTime = () => {
    const timeArray = Object.entries(getFormattedTimeDifference(
      time,
      new Date().getTime()
    )).filter(([ _, val ]) => val.value > 0);

    if (timeArray.length === 0) {
      return '0 MIN';
    }

    return timeArray.map(([ key, val ]) => (
      <span key={ key }>
        <span className={ classes.emphasized }>
          { val.value }
        </span>
        { ' ' }
        { val.label }
        { ' ' }
      </span>
    )); 
  };

  return (
    <span className={ cx({
      [classes.value]: true,
      [timeClasses.row]: direction === 'row'
    }) }>
      { isWithDate && (
        <span>
          <span className={ classes.emphasized }>{ date.getDate() }</span>
          { ' ' }
          { formatMonth(date.getMonth()) }
          { ' ' }
          { date.getFullYear() }
        </span>
      ) }

      { direction !== 'row' && isWithDate && (<br />) }

      { timeStyle === 'absolute' && (
        <span>
          <span className={ classes.emphasized }>
            { date.getHours() }:{ date.getMinutes().toString().padStart(2, '0') }
          </span>
          { ' ' }
          { date.getHours() >= 12 ? 'PM' : 'AM' }
        </span>
      ) }

      { (timeStyle === 'relative' || timeStyle === 'overdue') && (
        <span className={ classes.value }>
          { getTime() }

          { isAgo && ' AGO' }
          { timeStyle === 'overdue' && (
            <span className={ timeClasses.overdue }>
              OVERDUE
            </span>
          ) }
        </span>
      ) }
    </span>
  );
};

export default TaskSummaryTime;