import { MantineTheme } from "@mantine/core";

export const DividerStyle = {
  defaultProps: {
    className: 'mantine-divider-override'
  },
  styles: (theme: MantineTheme) => ({
    root: {
      borderTopWidth: 0.5 + '!important',
      borderTopColor: theme.colors.neutral[6] + '!important'
    }
  })
};