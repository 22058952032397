import { createModel } from "@rematch/core";

import { RootModel } from "@/core/store";
import { indoorEffects } from "@/tenant-context/plugin-indoors/store/indoor/indoor.effects";

import { indoorReducers } from "./indoor.reducers";
import { indoorState } from "./indoor.state";

export const indoor = createModel<RootModel>()({
  state: indoorState,
  reducers: indoorReducers,
  effects: indoorEffects
});
