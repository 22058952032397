import { ActionIcon, Switch, TextInput } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import React, { FC } from "react";

import ResponseMethodsPreviewComponent
  from "@/tenant-context/control-mass-comms/components/SummaryPage/Summary-Channels/ResponseMethodsPreview.component";
import { ReactComponent as EditIcon } from "@/tenant-context/control-mass-comms/icons/editIcon.svg";
import { ReactComponent as FileIcon } from "@/tenant-context/control-mass-comms/icons/file-icon.svg";
import { ReactComponent as MassPeople } from "@/tenant-context/control-mass-comms/icons/massPeople.svg";
import {
  AppCommunicationContent,
  MASS_COMMS_STEPS, MassCommsAppCommunicationsResponseItem,
  ResponseMethod
} from "@/tenant-context/control-mass-comms/types/MassComms.types";

import { useSummaryPageStyles } from "../../SummaryPage.styles";


type Props = {
  appCommunicationContent: AppCommunicationContent,
  responseMethod: ResponseMethod,
  expectedTotalRecipientCount: number,
  receivingCount: number,
  onStepperNavigate: (step: number) => (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void,
  appCommunicationResponseMethodConfigurations: MassCommsAppCommunicationsResponseItem[],
  isCriticalAlert: boolean
}

const AppCommsSummaryComponent: FC<Props> = ({
  appCommunicationContent,
  responseMethod,
  onStepperNavigate,
  expectedTotalRecipientCount,
  receivingCount,
  appCommunicationResponseMethodConfigurations,
  isCriticalAlert
}) => {
  const { classes, cx } = useSummaryPageStyles({});

  return (
    <div>
      <div className={ classes.countContainerAppPreview }>
        <div className={ classes.appCommsSettings }>
          <Switch
            label={ 'Critical Alert (iOS)' }
            size={ 'md' }
            checked={ isCriticalAlert }
            readOnly
          />
          <Switch
            label={ 'Confirm response with Face ID / Touch ID' }
            size={ 'md' }
            checked={ appCommunicationContent.isBiometricConfirmationTurnedOn }
            readOnly
          />
        </div>
        <div className={ classes.countTextContainerAppPreview }>
          <MassPeople />
          <div className={ classes.countText }>
            <div>{ receivingCount }</div>
            &nbsp;/&nbsp;{ expectedTotalRecipientCount }
          </div>
          <ActionIcon><EditIcon onClick={ onStepperNavigate(MASS_COMMS_STEPS.CREATE_COMMS) }/></ActionIcon>
        </div>
      </div>
      <div className={ classes.configItemsContainer }>
        <div className={ classes.configItem }>
          <div>Header</div>
          <div><TextInput disabled defaultValue={ appCommunicationContent.header }/></div>
        </div>
      </div>

      <ResponseMethodsPreviewComponent
        type="app"
        selectedResponseMethod={ responseMethod }
        responseConfigs={ appCommunicationResponseMethodConfigurations }
      />

      <div className={ classes.contentText }>
        Content
      </div>
      <div className={ cx({
        [classes.messageBodyContainer]: true
      }) }>
        <div className={ classes.messageText } >
          { appCommunicationContent.content }
        </div>
        <div className={ classes.attachmentContainerForAppComms }>
          { appCommunicationContent?.encodedAttachments?.map(file => {
            if (file.extension === MIME_TYPES.jpeg || file.extension === MIME_TYPES.png
              || file.extension === 'jpeg' || file.extension === 'png') {
              return (<img className={ classes.attachmentItemForAppComms }
                title={ file.name } key={ file.name } src={ file.base64 } alt={ file.name } />);
            }

            return (<div key={ file.name } title={ file.name }
              className={ classes.attachmentFileForAppComms }>
              <div className={ classes.otherAttachmentItem }>
                <FileIcon title={ file.name } /><div className={ classes.fileIconFileName }>{ file.name }</div>
              </div>
            </div>);
          }) }
        </div>
      </div>
    </div>
  );
};

export default AppCommsSummaryComponent;
