import { createStyles, MantineTheme } from "@mantine/core";

export const useShowcaseStyles = createStyles((theme: MantineTheme) => ({
  container: {
    backgroundColor: theme.colors.neutral[8],
    minHeight: '100%',
    padding: theme.other.spacing.xl
  },

  variantWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.other.spacing.xl,
    marginBottom: theme.other.spacing.lg
  },

  bold: {
    fontSize: theme.fontSizes.sm
  },
  light: {
    color: theme.other.semantic.text.secondary,
    fontSize: theme.fontSizes.xs
  },

  variantTitle: {
    minWidth: 200
  }
}));
