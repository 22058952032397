import { FC } from "react";

import ControlContainer from "@/common/controls/ControlContainer";

import LiveFeedButton from "../../components/LiveFeedButton";
import { useLiveFeedControlStyles } from "./LiveFeedControl.styles";


const LiveFeedControl: FC = () => {

  const { classes } = useLiveFeedControlStyles();

  return (
    <ControlContainer
      containerId="top-right-control-container"
      position="top-right"
      layout="vertical"
      styles={ { 'flex-direction': 'row-reverse', 'width': '40px', 'height': '40px' } }
    >
      <div className={ classes.controlContainer }>
        <LiveFeedButton/>
      </div>
    </ControlContainer>
  );
};

export default LiveFeedControl;
