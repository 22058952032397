import { FC, useContext } from "react";

import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import { useARCStyles } from "@/tenant-context/control-action-response-center/components/ARC/ARC.style";
import ArcColumn from "@/tenant-context/control-action-response-center/components/ArcColumn";
import ArcHeader from "@/tenant-context/control-action-response-center/components/ArcHeader";
import ImpactSummary from "@/tenant-context/control-action-response-center/components/ImpactSummary";
import ImpactTab from "@/tenant-context/control-action-response-center/components/ImpactTab/ImpactTab.container";
import ImpactZone from "@/tenant-context/control-action-response-center/components/ImpactZone";
import NotificationSummary
  from "@/tenant-context/control-action-response-center/components/NotificationSummary/NotificationSummary.container";
import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";
import Playbook from "@/tenant-context/employee-app-action-response-center/components/ui/Playbook";

const ARC: FC = () => {
  const { classes } = useARCStyles();
  const { activeTab } = useContext(ArcContext);

  return (
    <div className={ classes.arc }>
      <LocalErrorBoundary>

        <ArcHeader />

        { activeTab === "summary" && (
          <div className={ classes.grid }>
            <ArcColumn>
              <ImpactSummary />
              <ImpactZone />
            </ArcColumn>

            <ArcColumn>
              <NotificationSummary />
              <Playbook source="ARC" />
            </ArcColumn>
          </div>
        ) }

        { activeTab === "impact" && (
          <ImpactTab />
        ) }
      </LocalErrorBoundary>
    </div>
  );
};

export default ARC;
