import { createStyles, MantineTheme } from "@mantine/core";

export const useGeofenceDataPopupStyles = createStyles((theme: MantineTheme) => ({


  popUp:{
    color: theme.white,

    '& > .mapboxgl-popup-content': {
      background: theme.colors.blue[6],
      padding: '5px 10px'

    },
    '& > .mapboxgl-popup-tip': {
      border: 'none'
    }
  },

  popupContainer:{
    fontFamily:theme.fontFamily,
    backgroundColor: theme.colors.blue[6],
    font:theme.fontFamily,
    display:'flex',
    gap:'10px',
    width: 'auto',
    top: '50px',
    justifyContent:'space-between',
    alignItems:'center'
  },

  propertyContainer:{
    display:'flex',
    gap:'3px',
    fontSize:theme.fontSizes.xs
  },

  propertyName:{
    display:'flex',
    fontWeight:theme.other.fw.bold,
    fontSize:theme.fontSizes.xs
  },

  propertyValue:{
    display:'flex',
    fontSize:theme.fontSizes.xs
  }
}));
