import { createModel } from "@rematch/core";
import { FeatureCollection, Point } from "geojson";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { withVisualisableTraits } from "@/tenant-context/common/util/with-visualisable-traits";
import { parseGeoJSON } from "@/tenant-context/common/workers/geo-json-mapper-worker/geo-json-mapper-worker.external";
import { getCommonLocations } from "@/tenant-context/visualisation-common-locations/api/common-locations";
import { constructCommonLocationQuery } from "@/tenant-context/visualisation-common-locations/utils/common-location.utils";

const commonLocationLayerModel = withVisualisableTraits<FeatureCollection<Point, Location>>()({
  name: 'commonLocations',
  state: {},
  reducers: {},
  effects: (dispatch: Dispatch) => ({
    async loadAllCommonLocations(_: void, state: RootState): Promise<void> {
      const allCommonLocations = await getCommonLocations(
        constructCommonLocationQuery(state.dataOptions?.disabledLayerTypes)
      );

      const { data } = await parseGeoJSON({
        data: allCommonLocations,
        params: {
          Point: ['geoPoint.lat', 'geoPoint.lon'],
          Polygon: 'geoBoundary'
        }
      });

      dispatch.commonLocations.SET_GEO_DATA(data as FeatureCollection<Point, Location>);
    }
  })
});

export const commonLocations = createModel<RootModel>()(
  commonLocationLayerModel
);
