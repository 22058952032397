import { SortData } from "@/tenant-context/control-groups/types/group";

export const formatSortingParameters = (sortData?: SortData): SortData | undefined => {
  if (sortData?.colId === 'createdUser') {
    return {
      colId: '_meta.userName',
      sort: sortData.sort
    };
  }
  if (sortData?.colId === 'createdTime') {
    return {
      colId: '_meta.createdDate',
      sort: sortData.sort
    };
  } else {
    return sortData;
  }
};
