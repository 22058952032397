import { callApi } from "@/common/util/api";
import { createCustomEventSource } from "@/common/util/custom-event-source";
import {
  ConnectedSite,
  SiteWithPolicies
} from "@/tenant-context/control-connected-sites-dashboard/types/connectedSitesDashboard";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

export const getListUserSiteData = (
  page = 0,
  size = 10,
  userId: string,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<ConnectedSite>>(
  `/managed/platform-service/v1/users/${userId}?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'X-Domain-Model': 'ListUserSite'
    },
    method: 'get'
  }
);

export const streamUserSiteData = (
  userId: string
) => createCustomEventSource<ConnectedSite>(
  `/managed/streams/platform-service/v1/users/${userId}`,
  {
    headers: {
      'X-Domain-Model': 'StreamUserSites'
    },
    method: 'get'
  }
);

export const getListUserSiteAndPolicies = (
  page = 0,
  size = 10,
  userId: string,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<SiteWithPolicies>>(
  `/managed/platform-service/v1/users/${userId}?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'X-Domain-Model': 'ListUserSiteAndPolicies'
    },
    method: 'get'
  }
);

export const getActivityListData = (
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<ConnectedSite>>(
  `/managed/visitor-service/v1/visitor-requests?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'X-Domain-Model': 'ListPendingAndAccessAvailableVisitorRequest'
    },
    method: 'get'
  }
);
