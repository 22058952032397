import { callApi } from '@/common/util/api';
import { TableauAuthResponse, TableauTokenResponse } from '@/tenant-context/control-tableau/types/tableau-dashboard';
import { extractTableauSignInPayloadParams } from '@/tenant-context/control-tableau/util/bsoc-dashboard';

export const getTableauDashboardToken = () => callApi<TableauTokenResponse>(
  '/bsoc-risk-connector-service/v1/generate-token',
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    withTenantId: true
  }
);

export const signRequest = async function (dashboardUrl: string, token: string): Promise<TableauAuthResponse> {
  const { site, url } = extractTableauSignInPayloadParams(dashboardUrl);

  if (!site || !url || !token) {
    return Promise.reject('Invalid parameters');
  }

  const data = {
    siteName: site,
    jwt: token
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const responseContents = (await response['json']()) as TableauAuthResponse;
    return Promise.reject(responseContents);
  } catch (error) {
    return Promise.reject(error);
  }
};
