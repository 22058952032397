import { DrawerStylesNames, Styles } from "@mantine/core";
import { DrawerPosition, DrawerProps } from "@mantine/core";
import { MantineNumberSize } from "@mantine/styles/lib/theme/types/MantineSize";
import { FC, ReactNode, useCallback, useState } from "react";

import BottomDrawerComponent from "./BottomDrawer.component";
import { BottomDrawerVariant } from "./BottomDrawer.types";

type Props = {
  title?: string,
  position?: DrawerPosition,
  drawerVariant?: 'lg' | 'full' | 'window',
  padding?: MantineNumberSize
  children: ReactNode,
  onClose?: () => void,
  onDrawerVariantChanged?: (variant: BottomDrawerVariant) => void,
  styles: Styles<DrawerStylesNames, { isFull: boolean }>
  // Disabling this for compatibility purposes (with the Base Drawer)
  // eslint-disable-next-line react/boolean-prop-naming
  opened: boolean,
} & Partial<Omit<DrawerProps, 'styles'>>;

const BottomDrawer: FC<Props> = ({
  title,
  position = 'bottom',
  opened: isOpen,
  drawerVariant = 'lg',
  padding = 'md',
  children,
  onClose = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  onDrawerVariantChanged,
  styles,
  ...simpleDrawerProps
}) => {
  const [currentDrawerVariant, setCurrentDrawerVariant] = useState(drawerVariant);

  const onDrawerVariantChangeRequested = useCallback((requestedVariant: BottomDrawerVariant) => {
    setCurrentDrawerVariant(requestedVariant);

    if (onDrawerVariantChanged) {
      onDrawerVariantChanged(requestedVariant);
    }
  }, [onDrawerVariantChanged]);

  const overriddenStyles = useCallback((theme) => {
    if (typeof styles === 'function') {
      return styles(theme, { isFull: currentDrawerVariant === 'full' });
    }

    return styles;
  }, [currentDrawerVariant, styles]);

  const handleClose = useCallback(() => {
    onClose();
    if (currentDrawerVariant !== drawerVariant) {
      setCurrentDrawerVariant(drawerVariant);
    }
  }, [currentDrawerVariant, drawerVariant, onClose]);

  return (
    <BottomDrawerComponent
      title={ title || '' }
      position={ position }
      isOpen={ isOpen }
      drawerVariant={ currentDrawerVariant }
      padding={ padding }
      onClose={ handleClose }
      onDrawerVariantChanged={ onDrawerVariantChangeRequested }
      styles={ overriddenStyles }
      simpleDrawerProps={ simpleDrawerProps }
    >
      { children }
    </BottomDrawerComponent>
  );
};

export default BottomDrawer;
