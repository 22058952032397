/* eslint-disable react/jsx-no-bind */
import { ActionIcon, Badge, Box, Divider, Flex, Tabs, Text } from "@mantine/core";
import { FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import TextTruncate from "@/common/components/TextTruncate";
import { ReactComponent as RightArrowIcon } from "@/common/icons/Arrows/right-arrow.svg";
import { ReactComponent as ChevronLeft } from "@/common/icons/chevron-left.svg";
import { ReactComponent as DataMinr } from "@/common/icons/dataminr.svg";
import { getTimeDurationFromCurrentDate } from '@/common/util/format/date';
import { Dispatch, RootState } from '@/core/store';
import CountryFlag from "@/tenant-context/common/components/CountryFlag/CountryFlag.component";
import NumberHighlightedTextComponent from '@/tenant-context/common/components/NumberHighlightedText/NumberHighlightedText.component';
import RelatedEventList
  from '@/tenant-context/control-risk-config/components/RiskAlertDetails/RelatedEventList.component';
import { RiskAlertDetailsStyles } from "@/tenant-context/control-risk-config/components/RiskAlertDetails/RiskAlertDetails.styles";
import RiskAlertBasicData from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertBasicData";
import { DataMinrExternalData } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";
import { BSOCExternalData } from "@/tenant-context/visualisation-risk-alerts/types/risk-alert-response";
import { RiskAlertEvent, RiskAlertExternalData } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskImpactEvent } from "@/tenant-context/visualisation-risk-details/types/risk-impact";

type Props = {
  riskAlert: RiskAlertEvent,
  riskImpactData?: RiskImpactEvent,
  onRequestToToggleArc: MouseEventHandler<HTMLButtonElement>,
  onRequestToCloseComms: MouseEventHandler<HTMLButtonElement>,
  isArcOpen: boolean;
  isOpenedInArc?: boolean;
  isAnotherOverlayModuleOpen: boolean;
}

const DataMinrRiskAlertDetails: FC<Props> = ({
  riskAlert,
  onRequestToToggleArc,
  onRequestToCloseComms,
  isArcOpen,
  isOpenedInArc = false,
  isAnotherOverlayModuleOpen
}) => {
  const { classes  } = RiskAlertDetailsStyles({ isInArc: isOpenedInArc, isOverlayOpen: isAnotherOverlayModuleOpen });

  const {
    relatedEventsLazyLoadedList ,
    relatedEventsCount,
    selectedEventAlertId
  } = useSelector((state: RootState) => state.eventTimeLine);

  const { eventTimeLine: {
    loadRelatedEventsLazyLoadedList,
    RESET_EVENT_TIMELINE
  } } = useDispatch<Dispatch>();

  const [currentTab, setCurrentTab] = useState<'details' | 'events'>('details');

  const divRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleTabChange = useCallback((tab: 'details' | 'events') => setCurrentTab(tab), []);

  useEffect(() => {
    if (!riskAlert.tid) {
      return;
    }

    loadRelatedEventsLazyLoadedList({ parentEventId: riskAlert.tid, provider: 'dataminr' });

    return () => {
      loadRelatedEventsLazyLoadedList(undefined);
      RESET_EVENT_TIMELINE();
    };
  }, [RESET_EVENT_TIMELINE, loadRelatedEventsLazyLoadedList, riskAlert.tid]);

  useEffect(() => {
    if (isOpenedInArc) {
      return;
    }

    const rect = containerRef?.current?.getBoundingClientRect();

    if (rect) {
      const calculatedHeight = rect.height - 24;
      if (calculatedHeight > 0) {
        divRef.current?.setAttribute('style', `height: ${calculatedHeight}px`);
      } else {
        divRef.current?.setAttribute('style', 'height: 200px');
      }
    }
  }, [isOpenedInArc, riskAlert]);

  const mappedRiskAlert: RiskAlertEvent<RiskAlertExternalData | BSOCExternalData | DataMinrExternalData> = {
    ...riskAlert,
    json: {
      ...riskAlert.json,
      alert: {
        ...riskAlert.json.alert,
        start_date: new Date(riskAlert?.json.alert.start_date).toLocaleDateString(),
        end_date: new Date(riskAlert?.json.alert.end_date).toLocaleDateString()
      }
    }
  };

  return (
    <div className={ classes.container }>
      { (isArcOpen || isAnotherOverlayModuleOpen) && <Flex justify={ 'space-between' } align={ 'center' } mb={ 'md' }>
        { (isArcOpen || isAnotherOverlayModuleOpen) && (
          <div>
            <Flex gap={ 2 } align={ 'flex-end' }>
              <NumberHighlightedTextComponent
                numberFw={ 'bold' }
                numberFs={ 'sm' }
                alphaFs={ 12 }
                text={ riskAlert?.json.alert.start_date !== undefined
                  ? `${getTimeDurationFromCurrentDate(new Date(riskAlert?.json.alert.start_date))} AGO`
                  : '' }
              />
            </Flex>
          </div>
        ) }

        { isArcOpen && (
          <div>
            <ActionIcon size={ 20 } onClick={ onRequestToToggleArc } variant={ 'subtle' }>
              <RightArrowIcon />
            </ActionIcon>
          </div>
        ) }

        { isAnotherOverlayModuleOpen && (
          <div className={ classes.innerArrow }>
            <ActionIcon size={ 20 } onClick={ onRequestToCloseComms }>
              <ChevronLeft />
            </ActionIcon>
          </div>
        ) }
      </Flex> }

      <Flex direction={ 'column' } className={ classes.innerContainer }>
        <Flex direction={ 'column' } gap={ 32 } mb={ 48 }>
          <Flex direction={ 'column' } gap={ 8 }>
            { riskAlert?.json.alert.countries[0].iso_code && (
              <div className={ classes.popupHeader }>
                <Flex justify={ 'center' } align={ 'center' } h={ 48 } w={ 48 } className={ classes.flag }>
                  <Box>
                    <CountryFlag
                      width={ 80 }
                      height={ 80 }
                      countryCode={ riskAlert?.json.alert.countries[0].iso_code }
                    />
                  </Box>
                </Flex>
              </div>
            ) }

            <div className={ classes.countryInfo }>
              { riskAlert?.json.alert.location_explanation }
            </div>

            <Text className={ classes.riskAlertTitle } lineClamp={ 2 }>
              { riskAlert?.json.alert.title }
            </Text>
          </Flex>

          <Box>
            <RiskAlertBasicData riskAlertData={ mappedRiskAlert } isOnSideDrawer/>
          </Box>
        </Flex>

        <Tabs onTabChange={ handleTabChange } value={ currentTab } className={ classes.detailsTabsRoot }>
          <Tabs.List grow>
            <Tabs.Tab value="details">
              Details
            </Tabs.Tab>
            <Tabs.Tab value="events">
              Related Events
              <Badge size="sm" variant={ 'filled' } className={ classes.badge } radius={ 'md' }>{ relatedEventsCount }</Badge>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={ 'details' }>
            <div className={ classes.riskDescription }>
              <TextTruncate
                text={ riskAlert?.json.alert.text }
                maxTextLength={ window.innerHeight <= 800 ? 100 : 300 }
              />
            </div>

            <Divider
              className={ classes.divider }
            />

            <Flex gap={ 'xs' } justify={ 'flex-start' } align={ 'center' } mt={ 4 }>
              <div className={ classes.issuedBy }>Issued by</div>

              <DataMinr className={ classes.riskAlerProviderIcon }/>

              <div className={ classes.riskAlertProviderName }> Dataminr</div>
            </Flex>
          </Tabs.Panel>
          <Tabs.Panel value={ 'events' } className={ classes.relatedEventsTabsPanel }>
            <RelatedEventList
              riskAlertTid={ riskAlert.tid }
              relatedEventsList={ relatedEventsLazyLoadedList }
              relatedEventsCount={ relatedEventsCount }
              selectedEventAlertId={ selectedEventAlertId }
              isInArc={ isOpenedInArc }
              provider={ 'dataminr' }
              isRelatedEventsTabSelected={ currentTab === 'events' }
            />
          </Tabs.Panel>
        </Tabs>
      </Flex>
    </div>
  );
};

export default DataMinrRiskAlertDetails;
