import { FC } from "react";

import { RiskProviders } from "@/tenant-context/common/types/risk";

import BSOCEventsContainer from "./BSOCEvents.container";
import DataMinrEventsContainer from "./DataMinrEvents.container";
import MaxSecurityEventsContainer from "./MaxSecurityEvents.container";
import RiskLineEventsContainer from "./RiskLineEvents.container";

type Props = {
  providerId: string;
};

const RiskProviderTreeContentComponent: FC<Props> = ({ providerId }) => {
  const components: Record<string, JSX.Element | null> = {
    [RiskProviders.riskline]: <RiskLineEventsContainer />,
    [RiskProviders.maxSecurity]: <MaxSecurityEventsContainer />,
    [RiskProviders.dataminr]: <DataMinrEventsContainer />,
    [RiskProviders.bsoc]: <BSOCEventsContainer />
  };

  return components[providerId] || null;
};

export default RiskProviderTreeContentComponent;
