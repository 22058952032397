import { addIndoorTo, IndoorControl, MapGLWithIndoor } from '@f/map-gl-indoor';
import { FC, useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";

import ManageSiteControl from '@/tenant-context/plugin-indoors/controls/ManageSiteControl';

const IndoorsPlugin: FC = () => {
  const map = useMap().current;
  const [isAdded, setIsAdded] = useState(false);
  const [isIndoorsMapShown, setIsIndoorsMapShown] = useState(false);

  const floorControl = useMemo(() => new IndoorControl(), []);

  useEffect(() => {
    if (!map || isAdded) {
      return;
    }

    const mapboxMap = map.getMap();

    // Add indoor layer
    addIndoorTo(mapboxMap);

    setIsAdded(true);
  }, [map, isAdded]);

  useEffect(() => {
    const mapWithIndoor = map?.getMap() as MapGLWithIndoor;

    const handleMapLoaded = () => {
      floorControl.onAdd(mapWithIndoor);
      // Below comment is kept for future references
      // mapWithIndoor.addControl(floorControl, 'bottom-left');
      setIsIndoorsMapShown(true);
    };

    const handleMapUnloaded = () => {
      floorControl.onRemove();
      // Below comment is kept for future references
      // mapWithIndoor.removeControl(floorControl);
      setIsIndoorsMapShown(false);
    };

    mapWithIndoor.on('indoor.map.loaded', handleMapLoaded);
    mapWithIndoor.on('indoor.map.unloaded', handleMapUnloaded);

    return () => {
      mapWithIndoor.off('indoor.map.loaded', handleMapLoaded);
      mapWithIndoor.off('indoor.map.unloaded', handleMapUnloaded);
    };
  }, [floorControl, map]);

  if (!isIndoorsMapShown) {
    return null;
  }

  return (
    <ManageSiteControl />
  );
};

export default IndoorsPlugin;
