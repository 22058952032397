import { ActionIcon, Col, Grid, Group, Stack, Tabs } from "@mantine/core";
import ParentSize from "@visx/responsive/lib/components/ParentSizeModern";
import { FC, useContext } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { ReactComponent as BackArrow } from '@/common/icons/Arrows/back-arrow.svg';
import LoaderScreen from "@/core/components/SSOIntegration/LoaderScreen/LoaderScreen.component";
import { RootState } from "@/core/store";
import {
  CaseTypeDetailsContext
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/CaseTypeDetails.context";
import {
  RoleDetailsContext
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/RoleDetails/RoleDetails.context";
import {
  useRoleDetailsStyles
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/RoleDetails/RoleDetails.styles";
import RowDetailsTabContent
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/RoleDetails/RowDetailTabContent";

const RoleDetailsComponent: FC = () => {
  const { classes } = useRoleDetailsStyles();
  const { activeTab, handleTabChange, renderTab, roleTabs } = useContext(RoleDetailsContext);
  const { navigateToCaseTypeDetails } = useContext(CaseTypeDetailsContext);
  const { id } = useParams();

  const activeRole = useSelector((state: RootState) => state.caseTypes?.activeRole);

  if(!activeRole){
    return <LoaderScreen isLoading />;
  }

  return (<ParentSize>
    { ({ height }) => <div style={ { minHeight: height } } className={ classes.roleDetailsContainer }>
      <Grid gutter={ 24 }>
        <Col span={ 12 }>
          <Group>
            { /* eslint-disable-next-line react/jsx-no-bind */ }
            <ActionIcon onClick={ () => navigateToCaseTypeDetails(id || '') }>
              <BackArrow />
            </ActionIcon>
            <Stack spacing={ 4 }>
              <h1>{ activeRole?.roleName }</h1>
            </Stack>
          </Group>
        </Col>
        <Col span={ 12 }>
          <Tabs
            className={ classes.roleTabs }
            value={ activeTab }
            onTabChange={ handleTabChange }
          >
            <Tabs.List>
              { roleTabs.map((tab, index) => (
                renderTab(tab, index)
              )) }
            </Tabs.List>
          </Tabs>
        </Col>
        <Col className={ classes.relative } span={ 12 }>
          <RowDetailsTabContent activeTab={ activeTab } />
        </Col>
      </Grid>
    </div> }
  </ParentSize>);
};

export default RoleDetailsComponent;