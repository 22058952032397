import { createModel } from "@rematch/core";
import { Feature, FeatureCollection, LineString } from "geojson";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { AssetRankingSummary } from "@/tenant-context/common/types/asset-ranking";
import { mergeFeatureCollections } from "@/tenant-context/common/util/location-event/merge-feature-collections";
import { prepareEvent } from "@/tenant-context/common/util/location-event/prepare";
import { Visualizable, withVisualisableTraits } from "@/tenant-context/common/util/with-visualisable-traits";
import { parseGeoJSON } from "@/tenant-context/common/workers/geo-json-mapper-worker/geo-json-mapper-worker.external";
import { getPersonBreadcrumbs } from "@/tenant-context/visualisation-people/api/breadcrumbs";
import { BreadcrumbCollection } from "@/tenant-context/visualisation-people/types/people.types";
import { createLinesBetweenBreadcrumbs } from "@/tenant-context/visualisation-people/util/breadcrumbs";

export type PeopleBreadcrumbsState = {
  isEnabled: boolean,
  currentPersonAssetId?: string,
  linesBetweenCrumbs: FeatureCollection<LineString>
}

// TODO type this, impossible to read
const peopleBreadcrumbLayerModel = withVisualisableTraits()({
  name: 'peopleBreadcrumbs',
  state: {
    isEnabled: false,
    currentPersonAssetId: undefined,
    linesBetweenCrumbs: {
      type: 'FeatureCollection',
      features: []
    }
  } as PeopleBreadcrumbsState,
  reducers: {
    UNSAFE_SET_IS_BREADCRUMB_LAYER_ENABLED: (
      state: Visualizable<PeopleBreadcrumbsState>,
      enable: boolean
    ) => ({
      ...state,
      isEnabled: enable
    }),

    SET_CURRENT_PERSON: (
      state: Visualizable<PeopleBreadcrumbsState>,
      payload: string
    ) => ({
      ...state,
      currentPersonAssetId: payload
    }),

    SET_LINES_BETWEEN_CRUMBS: (
      state: Visualizable<PeopleBreadcrumbsState>,
      lines: FeatureCollection<LineString>
    ) => ({
      ...state,
      linesBetweenCrumbs: lines
    }),

    CLEAR_LINES_BETWEEN_CRUMBS: (
      state: Visualizable<PeopleBreadcrumbsState>
    ) => ({
      ...state,
      linesBetweenCrumbs: {
        type: 'FeatureCollection',
        features: []
      } as Visualizable<PeopleBreadcrumbsState>['linesBetweenCrumbs']
    })
  },
  effects: (dispatch: Dispatch) => ({
    safeToggleLayerVisibility(
      show: boolean,
      state: RootState
    ): void {
      const {
        rankingSettings: {
          isShowOnlyHighestRanksForPeople
        }
      } = state;

      if (!isShowOnlyHighestRanksForPeople) {
        return;
      }

      dispatch.peopleBreadcrumbs.UNSAFE_SET_IS_BREADCRUMB_LAYER_ENABLED(show);
    },

    async loadPersonBreadcrumbs(
      assetId: string,
      state: RootState
    ): Promise<void> {
      const {
        rankingSettings: {
          timeTravelTargetEpoch
        }
      } = state;

      const crumbs = await getPersonBreadcrumbs(
        assetId,
        timeTravelTargetEpoch
      );

      // Add asset IDs to ranking events
      const crumbsWithAssetIds: AssetRankingSummary = prepareEvent(
        crumbs
      );

      const { data: crumbsGeoJSON } = await parseGeoJSON<BreadcrumbCollection>({
        data: [
          crumbsWithAssetIds.topRank,
          ...crumbsWithAssetIds.otherRanks
        ],
        params: {
          Point: ['location.point.lat', 'location.point.lon']
        }
      });

      const linesBetweenCrumbs = createLinesBetweenBreadcrumbs(
        crumbsWithAssetIds
      );

      dispatch.peopleBreadcrumbs.SET_LINES_BETWEEN_CRUMBS({
        type: 'FeatureCollection',
        features: linesBetweenCrumbs
      });

      dispatch.peopleBreadcrumbs.SET_GEO_DATA(crumbsGeoJSON);
    },

    async processEnqueuedBreadcrumbEvents(
      events: AssetRankingSummary[],
      state: RootState
    ): Promise<void> {
      const {
        peopleBreadcrumbs: {
          geoData: oldGeoData,
          linesBetweenCrumbs: oldLinesBetweenCrumbs
        }
      } = state;

      // Get all events, add IDs to each event
      const crumbs = events.flatMap(
        ({ topRank, otherRanks, personId }) => [topRank, ...otherRanks].map((rank) => ({
          ...rank,
          assetId: personId
        }))
      );

      // TODO improve multithreading
      const { data: crumbsGeoJSON } = await parseGeoJSON<BreadcrumbCollection>({
        data: crumbs,
        params: {
          Point: [
            'location.point.lat',
            'location.point.lon'
          ]
        }
      });

      const updatedGeoData = mergeFeatureCollections(
        oldGeoData,
        crumbsGeoJSON,
        (d) => [{
          id: 'id',
          value: d?.properties?.location.id
        }, {
          id: 'type',
          value: d?.properties?.location.type
        }]
      );

      const linesBetweenCrumbs: Feature<LineString>[] = events.flatMap(
        (event) => createLinesBetweenBreadcrumbs(event)
      );

      dispatch.peopleBreadcrumbs.SET_GEO_DATA(updatedGeoData);

      const updatedLinesBetweenCrumbs = mergeFeatureCollections(
        oldLinesBetweenCrumbs,
        {
          type: 'FeatureCollection',
          features: linesBetweenCrumbs
        },
        [
          'personId',
          'topRankLocationType',
          'topRankLocationId',
          'otherRankLocationType',
          'otherRankLocationId'
        ]
      );

      dispatch.peopleBreadcrumbs.SET_LINES_BETWEEN_CRUMBS(updatedLinesBetweenCrumbs);
    }
  })
});

export const peopleBreadcrumbs = createModel<RootModel>()(
  peopleBreadcrumbLayerModel
);
