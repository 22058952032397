/* eslint-disable no-param-reassign */
import { Point } from "geojson";
import mapboxgl from "mapbox-gl";
import { createContext, FC, useCallback,useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import usePermission from "@/common/hooks/usePermission";
import { logger } from "@/common/util/ConsoleLogger";
import ensureDeserialized from "@/common/util/ensure-deserialized";
import { Dispatch, RootState } from "@/core/store";
import { BigMapPopupIds, MapKey, useMapPopupList } from "@/tenant-context/common/hooks/useMapPopupList";
import { AssetRankingEvent, AssetRankingSummary } from "@/tenant-context/common/types/asset-ranking";
import { PopupCoordinates } from "@/tenant-context/common/types/popup.types";
import { getClickedOnFeatures } from "@/tenant-context/common/util/map-click";
import { MiniProfileDatails } from "@/tenant-context/control-profile/types/mini-profile";
import { IndoorPersonAssetSummary, IndoorPersonLocationProperties } from "@/tenant-context/plugin-indoors/types/indoor-map.types";
import { PeoplePolicies } from "@/tenant-context/visualisation-people/configs/People.policies";
import { PersonCurrentTravelInfo, PersonPopupAssetRankingEvent } from "@/tenant-context/visualisation-people/types/people.types";

import { getCurrentTravelItenerary } from "../api/people";

export type MapboxHoverEvent = mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined; }
& mapboxgl.EventData;

export type PeopleContextType = {
  mapKey: MapKey;
  handlePersonLocationHover: (evt: MapboxHoverEvent) => void,
  handlePersonLocationLeave: (evt: MapboxHoverEvent) => void,
  enablePopupForRankingEvent: (event: AssetRankingEvent) => void,
  handleCurrentTravelInfo: (segmentId: string) => void,
  handlePersonLocationClick:(evt: MapboxHoverEvent) => void,
  handlePersonClusterLocationClick: (evt: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
  } & mapboxgl.EventData) => void,
  handleClusterItemClick: (feature: mapboxgl.MapboxGeoJSONFeature) => void
  handleClickZoomToPerson:(feature?: mapboxgl.MapboxGeoJSONFeature) => void,
  handleClickMoreDetails:(feature?: mapboxgl.MapboxGeoJSONFeature) => void,
  handleClickClosePopup:(popupId: string) => void,
  clickedFeatures?: mapboxgl.MapboxGeoJSONFeature[],
  calculatePopupOffset:() => void,
  lineEndCoordinates?: PopupCoordinates | undefined,
  clickedPersonEvent?: MapboxHoverEvent,
  personCurrentTravelInfo: PersonCurrentTravelInfo | undefined,
  tempProfiles?: Array<MiniProfileDatails>,
};

const INDOORS_VISIBILITY_MIN_ZOOM = 15;

export const PeopleContext = createContext<PeopleContextType>({} as PeopleContextType);

type PeopleContextProviderProps = {
  /** Because we use this context in deferent maps */
  mapKey: MapKey;
};

export const PeopleContextProvider: FC<PeopleContextProviderProps> = ({
  children,
  mapKey
}) => {
  const allCountries = useSelector((state: RootState) => state.commonData.allCountries);
  const [lineEndCoordinates, setLineEndCoordinates] = useState<PopupCoordinates | undefined>();
  const [personCurrentTravelInfo, setPersonCurrentTravelInfo]
    = useState<PersonCurrentTravelInfo | undefined>(undefined);
  const [clickedPersonEvent, setClickedPersonEvent] = useState<MapboxHoverEvent | undefined>();

  const loggedUser = useSelector((state: RootState) => state.profile.loggedUser);

  const {
    peopleLocations: {
      subscribeToPeopleLocationData
    },
    globalPeople: {
      getMiniProfileById
    },
    reverseGeocoding: {
      getReverseGeoLocation
    },
    peopleBreadcrumbs: {
      SET_CURRENT_PERSON: SET_CURRENT_BREADCRUMBS_PERSON,
      safeToggleLayerVisibility
    },
    locationGraph: {
      enableGraphForPerson,disableGraph
    },
    userProfile:{
      SET_CURRENT_USER_ID,
      RESET_ITINERARY_DATA,
      SET_TRAVEL_CORRELATION_ID
    },
    drawer: {
      openRightDrawer
    },
    connectedSitesDashboard:{
      loadListUserSiteAndPolicies
    }
  } = useDispatch<Dispatch>();

  const { current: map } = useMap();

  const isPeopleRankingPermissionAvailable = usePermission(PeoplePolicies.PEOPLE_RANKING_POLICY);

  useEffect(() => {
    if (!allCountries || !allCountries.length || !isPeopleRankingPermissionAvailable) {
      return;
    }

    subscribeToPeopleLocationData();
  }, [ subscribeToPeopleLocationData, allCountries, isPeopleRankingPermissionAvailable ]);
  
  useEffect(()=>{
    loadListUserSiteAndPolicies({ gridParams: { page: 0, size: 9999 } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser?.assignedTenantUserTid]);

  /*
  const {
    setIsPopupShown: setIsIndividualPersonPopupShown,
    popupCoordinates: individualPersonPopupCoordinates,
    setPopupCoordinates: setIndividualPersonPopupCoordinates,
    popupRef: individualPersonPopupRef
  } = useMapPopup("PersonLocation");
   */

  const { openPopup, closePopup, updatePopup } = useMapPopupList(mapKey);

  const enablePopupForRankingEvent = useCallback(
    async (event: AssetRankingEvent, originalFeature?: mapboxgl.MapboxGeoJSONFeature) => {
      const { location: { point: { lat, lon } } } = event;
      const popupId = `${BigMapPopupIds.PeopleLocation}/${event.assetId}`;
      openPopup({
        id: popupId,
        position: [lon, lat],
        data: null
      });

      // use address if found
      try {
        if (event.adapterSource !== 'travel-book-adapter-service' && event.assetId) {

          const { place_name } = await getReverseGeoLocation({
            lon: event.location.point.lon,
            lat: event.location.point.lat
          });
          
          (event as PersonPopupAssetRankingEvent).location.address = place_name ?? 'We currently have no location data for this individual';
        } else {
          (event as PersonPopupAssetRankingEvent).location.address = 'We currently have no location data for this individual';
        }
        if (event.assetId) {
          const {
            nationality,
            personType,
            countryOfResidenceRef,
            jobTitle,
            company,
            profilePictureThumbnailUrl,
            firstName,
            lastName
          }
            = await getMiniProfileById(event.assetId);

          (event as PersonPopupAssetRankingEvent).jobTitle = jobTitle ?? 'N/A';
          (event as PersonPopupAssetRankingEvent).nationality = nationality ?? 'N/A';
          (event as PersonPopupAssetRankingEvent).personType = personType ?? 'N/A';
          (event as PersonPopupAssetRankingEvent).countryOfResidenceRef = countryOfResidenceRef ?? 'N/A';
          (event as PersonPopupAssetRankingEvent).company = company ?? 'N/A';
          (event as PersonPopupAssetRankingEvent).firstName = firstName ?? undefined;
          (event as PersonPopupAssetRankingEvent).lastName = lastName ?? undefined;
          (event as PersonPopupAssetRankingEvent).profilePictureThumbnailUrl = profilePictureThumbnailUrl ?? '';
        }

      // use N/A if not
      } catch (err) {
        (event as PersonPopupAssetRankingEvent).location.address = 'We currently have no location data for this individual';
        (event as PersonPopupAssetRankingEvent).jobTitle = 'N/A';
        (event as PersonPopupAssetRankingEvent).nationality = 'N/A';
        (event as PersonPopupAssetRankingEvent).personType = 'N/A';
        (event as PersonPopupAssetRankingEvent).countryOfResidenceRef = 'N/A';
        (event as PersonPopupAssetRankingEvent).company = 'N/A';
        (event as PersonPopupAssetRankingEvent).firstName = undefined;
        (event as PersonPopupAssetRankingEvent).lastName = undefined;
      }

      updatePopup(popupId, {
        position: [lon, lat],
        data: [event as PersonPopupAssetRankingEvent, originalFeature]
      });
    },
    [getMiniProfileById, getReverseGeoLocation, openPopup, updatePopup]
  );

  const calculatePopupOffset = useCallback(() => {
    // Todo - Temorarily disabled due to the requirement of disabling lines in people markers
    return;

    // if(hoveredOnPerson) {
    //   const mappedLngLat = map?.unproject(new mapboxgl.Point(
    //     window.screen.width - RIGHT_PANEL_OFFSET_IN_PIXELS,
    //     window.screen.height/2
    //   ));
    //   const popupPosition = getPopupOrDrawerPosition(
    //     map?.getBounds(),
    //     {
    //       type: 'Point',
    //       coordinates: [hoveredOnPerson.location.point.lon, hoveredOnPerson.location.point.lat]
    //     },
    //     mappedLngLat,
    //     true
    //   );
    //
    //   setLineEndCoordinates({
    //     latitude: popupPosition[1],
    //     longitude:popupPosition[0]
    //   });
    // }
  },[]);

  const handlePersonLocationLeave = useCallback(() => {
    if(map){
      map.getCanvas().style.cursor = 'default';
    }
  },[map]);

  const handleClickClosePopup = useCallback((popupId: string) => {
    safeToggleLayerVisibility(false);
    disableGraph();
    setLineEndCoordinates(undefined);
    closePopup(popupId);
  },[closePopup, disableGraph, safeToggleLayerVisibility]);

  const handlePersonLocationHover = useCallback(
    () => {
      if(map){
        map.getCanvas().style.cursor = 'pointer';
      }
    },
    [map]
  );

  const handlePersonLocationClick = useCallback(
    (evt: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData) => {
      const { layerFeatures } = getClickedOnFeatures(
        evt,
        ['r__peopleLocationSnapshotLayer', 'r__peopleLocationAlarmSnapshotLayer','r__possible-locations-secondary', 'r__indoorPeopleLocationSnapshotLayer']
      );

      if (!layerFeatures.length) {
        return;
      }
      const feature = layerFeatures[0];
      if (feature.layer.id === 'r__peopleLocationSnapshotLayer' || feature.layer.id === 'r__peopleLocationAlarmSnapshotLayer') {
        const { topRank } = ensureDeserialized(
          feature.properties as AssetRankingSummary,
          ['topRank']
        );

        enablePopupForRankingEvent(topRank, feature);
      } else if (feature.layer.id === 'r__indoorPeopleLocationSnapshotLayer') {
        const { people, calculatedGeoLocation } = ensureDeserialized(
          feature.properties as IndoorPersonLocationProperties,
          ['people', 'calculatedGeoLocation']
        );
        const { topRank } = people as IndoorPersonAssetSummary;
        // Setting the calculated location point as the popup coordinates since the location in the topRank and the
        // location in the indoor map due to zonal placement are not the same.
        const topRankWithCalculatedLocation: AssetRankingEvent = {
          ...topRank,
          location: {
            ...topRank.location,
            point: {
              lat: calculatedGeoLocation.lat,
              lon: calculatedGeoLocation.lon
            }
          }
        };

        enablePopupForRankingEvent(topRankWithCalculatedLocation, feature);
      } else {
        const rankingEvent = ensureDeserialized(
          feature.properties as AssetRankingEvent,
          ['location']
        );

        enablePopupForRankingEvent(rankingEvent, feature);
      }
    },
    [enablePopupForRankingEvent]
  );

  const handlePersonClusterLocationClick = useCallback((evt: mapboxgl.MapMouseEvent & {
        features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData) => {
    if (!map) {
      return;
    }
    const { layerFeatures } = getClickedOnFeatures(
      evt,
      ['r__peopleLocationSnapshotLayer-cluster', 'r__peopleLocationAlarmSnapshotLayer-cluster']
    );
    
    const sourceId = layerFeatures[0]?.source;
    const clusterId = layerFeatures[0]?.properties?.cluster_id;

    if (!sourceId || !clusterId) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (map.getSource(sourceId) as any).getClusterLeaves(
      clusterId,
      10000, // maximum number of leaves to return
      0,   // starting index
      (err: Error , features: mapboxgl.MapboxGeoJSONFeature[]) => {
        if (err) {
          logger.error('Click on people cluster', err);
          return;
        }
        openPopup({
          id: BigMapPopupIds.PeopleCluster,
          position: [evt.lngLat.lng, evt.lngLat.lat],
          data: features
        });
      }
    );

  }, [map, openPopup]);

  const handleClusterItemClick = useCallback((feature: mapboxgl.MapboxGeoJSONFeature) => {
    const { topRank } = ensureDeserialized(
      feature.properties as AssetRankingSummary,
      ['topRank']
    );

    enablePopupForRankingEvent(topRank, feature);
    closePopup(BigMapPopupIds.PeopleCluster);
  }, [closePopup, enablePopupForRankingEvent]);

  const handleClickZoomToPerson = useCallback((feature?: mapboxgl.MapboxGeoJSONFeature) => {
    if(!feature || !map){
      return;
    }

    const { coordinates } = (feature.geometry as Point);

    if (map.getZoom() < INDOORS_VISIBILITY_MIN_ZOOM) {
      map.flyTo({
        center: [coordinates[0], coordinates[1]],
        zoom: INDOORS_VISIBILITY_MIN_ZOOM
      });
    }
  }, [map]);

  // console.log({ clickedPersonEvent }); // something is wrong here

  const handleClickMoreDetails = useCallback((feature?: mapboxgl.MapboxGeoJSONFeature) => {
    if(!feature || !map){
      return;
    }

    const currentFeatureProps = feature?.properties;
    if (!currentFeatureProps) {
      return;
    }
    RESET_ITINERARY_DATA();
    
    const { personId } = currentFeatureProps as AssetRankingSummary;
    SET_CURRENT_BREADCRUMBS_PERSON(personId);
    SET_CURRENT_USER_ID(personId);
    const rankProps = typeof currentFeatureProps?.topRank === 'string' ? JSON.parse(currentFeatureProps?.topRank) : currentFeatureProps?.topRank;
    if (rankProps.adapterSource === 'travel-book-adapter-service') {
      SET_TRAVEL_CORRELATION_ID(rankProps?.correlationId);
    } else {
      SET_TRAVEL_CORRELATION_ID(undefined);
    }

    enableGraphForPerson(personId);
    openRightDrawer('mini-profile-control');
    safeToggleLayerVisibility(true);
    setClickedPersonEvent(rankProps);
  },     [
    map,
    RESET_ITINERARY_DATA,
    SET_CURRENT_BREADCRUMBS_PERSON,
    SET_CURRENT_USER_ID,
    enableGraphForPerson,
    openRightDrawer,
    safeToggleLayerVisibility,
    SET_TRAVEL_CORRELATION_ID
  ]);

  const handleCurrentTravelInfo = useCallback(async (segmentId: string) => { 
    const res = await getCurrentTravelItenerary(segmentId);
    setPersonCurrentTravelInfo(res);
  }, []);

  return (
    <PeopleContext.Provider value={ useContextValue({
      mapKey,
      handlePersonLocationHover,
      handlePersonLocationLeave,
      enablePopupForRankingEvent,
      handlePersonLocationClick,
      calculatePopupOffset,
      lineEndCoordinates,
      clickedPersonEvent,
      handleCurrentTravelInfo,
      personCurrentTravelInfo,
      handleClickZoomToPerson,
      handleClickMoreDetails,
      handleClickClosePopup,
      handlePersonClusterLocationClick,
      handleClusterItemClick
    }) }>
      { children }
    </PeopleContext.Provider>
  );
};
