import { ColDef, ICellRendererParams } from "ag-grid-community";

import { handleShortAdapterSourceDisplay } from "../util/util";


export const peopleListColumnDefs = (): ColDef[] => [
  {
    field: 'profileId',
    headerName: 'Person ID',
    sortable: true,
    cellRenderer: (params: ICellRendererParams) => params.data.personProfile.profileId,
    comparator: () => 0
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    cellRenderer: (params: ICellRendererParams) => params.data.personProfile.name,
    comparator: () => 0
  },
  {
    field: 'personType',
    headerName: 'Person Type',
    sortable: true,
    cellRenderer: (params: ICellRendererParams) => params.data.personProfile.personType || '-',
    comparator: () => 0
  },
  {
    field: 'companyName',
    headerName: 'Company',
    sortable: true,
    cellRenderer: (params: ICellRendererParams) => params.data.personProfile.companyName || '-',
    comparator: () => 0
  },
  {
    field: 'jobTitle',
    headerName: 'Job Title',
    sortable: true,
    cellRenderer: (params: ICellRendererParams) => params.data.personProfile.jobTitle || '-',
    comparator: () => 0
  },
  {
    field: 'adapterSource',
    headerName: 'Source',
    sortable: true,
    comparator: () => 0,
    cellRenderer: (params: ICellRendererParams) => handleShortAdapterSourceDisplay(params.data.adapterSource)
  },
  {
    field: 'lastKnownLocation',
    headerName: 'Based On',
    sortable: false,
    cellRenderer: (params: ICellRendererParams) => params.data.lastKnownLocation ? 'Last Known Location' : 'Current Location'
  }
];