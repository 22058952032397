import { callApi } from '@/common/util/api';
import { PaginatedResult } from '@/tenant-context/common/types/common';
import { DataminrRelatedEvent, MaxSecurityRelatedEvent } from '@/tenant-context/control-risk-config/types/risk';

export const getRelatedEventsPage = (
  tenantId: string,
  parentEventId: string,
  pageNumber: number,
  pageSize: number
) => callApi<PaginatedResult<DataminrRelatedEvent>>(
  `/data-minr-connector-service/v1/${ tenantId }/related-alerts/${ parentEventId }?pageNum=${ pageNumber }&pageSize=${ pageSize }`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    withTenantId: true
  }
);

export const getRelatedEventsMaxSecurityPage = (
  tenantId: string,
  parentEventId: string,
  pageNumber: number,
  pageSize: number
) => callApi<PaginatedResult<MaxSecurityRelatedEvent>>(
  `/max-security-connector-service/v1/${ tenantId }/related-reports/${ parentEventId }?pageNum=${ pageNumber }&pageSize=${ pageSize }`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    withTenantId: true
  }
);
