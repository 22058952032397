import { createStyles } from "@mantine/core";

export const useCreateAndUpdatePeopleGroupFormStyles = createStyles(() => ({
  formGroup: {
    marginBottom: 24,
    display: "flex",
    flexDirection: 'column',
    gap: 8
  },
  applicableServicesDropdown: {
    marginBottom: 24
  }
}));
