export type EmergencyVideo = {
  url: string,
  mode: VideoMode,
  timestamp: number,
  videoId: string
};

export enum VideoMode {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait'
}
