import { openModal } from '@mantine/modals';
import { FC, useCallback } from "react";

import ProfileEmergencyContactsModal from '@/tenant-context/control-profile/components/modals/ProfileEmergencyContactsModal';
import ProfileEmergencyContactTabSections from '@/tenant-context/control-profile/components/tabs/ProfileEmergencyContactTab/ProfileEmergencyContactTabSections.component';
import GenericProfileTab from "@/tenant-context/control-profile/components/ui/GenericProfileTab";
import ProfileTabActionButton from '@/tenant-context/control-profile/components/ui/ProfileTabActionButton';
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';
import { PaginatedResult, ProfileEmergencyContact } from "@/tenant-context/control-profile/types/profile";

type Props = {
  emergencyContacts?: PaginatedResult<ProfileEmergencyContact> | null,
  isOwnProfile: () => boolean
}

const ProfilePersonalContactTab: FC<Props> = ({
  emergencyContacts, isOwnProfile
}) => {
  const openAddAddressModal = useCallback(() => {
    openModal({
      title: 'Add Emergency Contact',
      children: (
        <ProfileEmergencyContactsModal />
      ),
      closeOnClickOutside: false
    });
  }, []);

  const renderAddAction = useCallback(() => (
    <ProfileTabActionButton
      onClick={ openAddAddressModal }
      requiredPolicies={ isOwnProfile() ? [] : ProfileRequiredPoliciesConfig.EDIT_EMERGENCY_CONTACT }
    >
      Add Emergency Contact
    </ProfileTabActionButton>
  ), [isOwnProfile, openAddAddressModal]);

  if (!emergencyContacts) {
    return <span>Loading...</span>;
  }

  return (
    <GenericProfileTab
      heading="Emergency Contact"
      renderAction={ renderAddAction }
    >
      <ProfileEmergencyContactTabSections />
    </GenericProfileTab>
  );
};

export default ProfilePersonalContactTab;
