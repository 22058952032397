// This is false-positive for tabMap
/* eslint-disable react/no-multi-comp */
import { FC, ReactElement } from "react";

import ProfileAddressesTab from "@/tenant-context/control-profile/components/tabs/ProfileAddressesTab";
import ProfileDocumentsTab from "@/tenant-context/control-profile/components/tabs/ProfileDocumentsTab";
import ProfileEmergencyContactTab from "@/tenant-context/control-profile/components/tabs/ProfileEmergencyContactTab";
import ProfileGroupsTab from "@/tenant-context/control-profile/components/tabs/ProfileGroupsTab";
import ProfileIdentificationTab
  from "@/tenant-context/control-profile/components/tabs/ProfileIdentificationTab";
import ProfilePersonalContactTab from "@/tenant-context/control-profile/components/tabs/ProfilePersonalContactTab";
import ProfileProfileTab from "@/tenant-context/control-profile/components/tabs/ProfileProfileTab";
import { ProfileTab } from "@/tenant-context/control-profile/components/ui/ProfileTabs/ProfileTabs.component";
type Props = {
  activeTab: ProfileTab
}

const SelectedProfileTab: FC<Props> = ({
  activeTab
}) => {
  // Mapping profile tabs to render functions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tabMap: Record<ProfileTab, () => ReactElement<any, any> | null> = {
    [ProfileTab.Profile]: () => (<ProfileProfileTab/>),
    [ProfileTab.EmergencyContact]: () => (<ProfileEmergencyContactTab/>),
    [ProfileTab.PersonalContact]: () => (<ProfilePersonalContactTab/>),
    [ProfileTab.Addresses]: () => (<ProfileAddressesTab/>),
    [ProfileTab.Documents]: () => (<ProfileDocumentsTab/>),
    [ProfileTab.PeopleGroups]: () => (<ProfileGroupsTab/>),
    [ProfileTab.Identification]: () => (<ProfileIdentificationTab/>)
    // [ProfileTab.ChangeLog]: () => null
  };

  return tabMap[activeTab]();
};

export default SelectedProfileTab;
