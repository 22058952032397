/* eslint-disable no-param-reassign */
import { AssetRankingEvent } from "@/tenant-context/common/types/asset-ranking";

type AssetRankingEventSeriesKey = {
  locationType: string,
  locationId: string
};

const sep = '@_@_@';

export const getEventSeriesKey = (
  datum: AssetRankingEvent
): AssetRankingEventSeriesKey => ({
  locationId: datum.location.id,
  locationType: datum.location.type
});

export const serializeEventSeriesKey = (
  key: AssetRankingEventSeriesKey
): string => [key.locationType, key.locationId].join(sep);

// export type GroupedAssetRankingEvents = Record<string, AssetRankingEvent[]>

export type AssetRankingEventGroup = {
  serializedKey: string,
  deserializedKey: AssetRankingEventSeriesKey,
  events: AssetRankingEvent[]
};

export default function groupAssetRankingEvents(
  events: AssetRankingEvent[]
) {
  return events.reduce(
    (groups, ev): AssetRankingEventGroup[] => {
      const deserializedKey = getEventSeriesKey(ev);
      const serializedKey = serializeEventSeriesKey(deserializedKey);

      const eventGroupIndex = groups.findIndex(
        ({
          deserializedKey: {
            locationId,
            locationType
          }
        }) => (
          locationId === deserializedKey.locationId
          && locationType === deserializedKey.locationType
        )
      );

      const eventGroup = eventGroupIndex !== -1
        ? groups[eventGroupIndex]
        : {
          deserializedKey,
          serializedKey,
          events: []
        };

      eventGroup.events.push(ev);

      if (eventGroupIndex !== -1) {
        groups[eventGroupIndex] = eventGroup;
      } else {
        groups.push(eventGroup);
      }

      return groups;
    },
    [] as AssetRankingEventGroup[]
  );
}