import { Autocomplete, CloseButton } from "@mantine/core";
import React, { FC } from "react";

import { ReactComponent as SearchIcon } from '@/common/icons/search-light.svg';
import { SearchResultFeatureCollection } from "@/tenant-context/control-search/types/search";

import { useGlobalSearchBarStyles } from "./GlobalSearchBar.styles";

type Props = {
  onSearchInputChange: (value: string) => void,
  autocompleteData?: string[],
  onClearClick?: () => void,
  searchCriteria: string,
  searchResultList: SearchResultFeatureCollection,
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement>
};

const GlobalSearchBar: FC<Props> = ({
  onSearchInputChange,
  onClearClick,
  autocompleteData = [],
  searchCriteria,
  onKeyDown,
  searchResultList
}) => {
  const { classes } = useGlobalSearchBarStyles();
  return (
    <Autocomplete
      className={ classes.searchBar }
      data={ searchCriteria && searchResultList && searchResultList.features.length > 0 ? [] : autocompleteData }
      icon={ <SearchIcon /> }
      value={ searchCriteria }
      type="text"
      onChange={ onSearchInputChange }
      placeholder="Search for a person or a location"
      rightSection={ searchCriteria && (
        <CloseButton
          size="md"
          onClick={ onClearClick }
          variant={ 'subtle' }
        />
      ) }
      onKeyDown={ onKeyDown }
    />
  );
};

export default GlobalSearchBar;
