import { createStyles } from "@mantine/core";

export const useGeofenceListStyles = createStyles((theme) => ({
  container: {
    marginTop: '48px',
    button: {
      '&[data-active]': {
        backgroundColor: theme.colors.neutral[8] + '!important'
      }
    }
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px',
    width: '100%',
    height: 'calc(100vh - 705px)',
    overflow: 'auto',
    minHeight: '250px'
  }
}));
