import { FC, ReactNode } from "react";
import { useSelector } from "react-redux";

import {
  ToggleableContextLayers,
  ToggleableLayerType
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { RootState } from "@/core/store";

type Props = {
  type: ToggleableLayerType | ToggleableContextLayers,
  children: ReactNode
  layerType?: 'map-layer' | 'contextual-layer'
};

/**
 * ! When using this to wrap Layers that are children of Source component
 * ! You MUST use `source` prop of the Layers anyways
 * ! Because this intermediate component breaks direct parent-child structure of Source -> Layer
 * ! And it becomes Source -> ToggleableLayer -> Layer
 */
const ToggleableLayer: FC<Props> = ({
  children,
  type,
  layerType = 'map-layer'
}) => {
  const disabledLayerTypes = useSelector(
    (state: RootState) => state.dataOptions.disabledLayerTypes
  );
  const contextualLayersOptions = useSelector(
    (state: RootState) => state.dataOptions.contextLayerOptions
  );

  // TODO enhance performance
  // base enhanced performance on visibility property
  if (layerType === 'map-layer' && disabledLayerTypes.includes(type as ToggleableLayerType)) {
    return null;
  }

  if (layerType === 'contextual-layer' && !contextualLayersOptions[type as ToggleableContextLayers]) {
    return null;
  }

  return (
    <>{ children }</>
  );
};

export default ToggleableLayer;
