import { createStyles } from "@mantine/core";


export const useGeofenceListItemStyles = createStyles((_theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: "space-between",
    alignItems: "center",
    padding: '12px 6px 12px 0',
    height: '100%'
  },
  iconContainer: {
    marginRight: '8px'
  },
  titleContainer: {
    all: "unset",
    display: "flex",
    maxWidth: '300px',
    cursor: 'pointer'
  },
  title: {
    textOverflow: "ellipsis",
    whiteSpace: 'nowrap',
    overflow: "hidden",
    fontSize: '14px',
    
    '&:hover': {
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'break-all'
    }
  },
  ellipsis: {
    display: "flex",
    height: '100%',
    alignItems: "center",
    cursor: 'pointer'
  },
  itemButton: {
    all: "unset"
  },
  uaMenuItem: {
    color: _theme.colors.neutral[6],
    cursor: 'not-allowed',
    '&:hover': {
      color: _theme.colors.neutral[5]
    }
  }

}));
