import { Button, DefaultProps, Flex, Text } from "@mantine/core";
import { FC, useMemo } from "react";

import { ReactComponent as ClockEightIcon } from "@/common/icons/clock-eight.svg";
import { TestableComponent } from "@/common/types/testable-component";
import { formatDate } from "@/common/util/format/date";

import { useTemporalFilterIndicatorStyles } from "./TemporalFilterIndicator.styles";

type Props = {
  isLive: boolean;
  date: Date;
  onClickReset: () => void;
} & TestableComponent &
  Pick<DefaultProps, "sx">;

const TemporalFilterIndicatorComponent: FC<Props> = ({
  dataTestId = "bottom-items-date-and-time",
  sx,
  isLive,
  date,
  onClickReset
}) => {
  const { classes } = useTemporalFilterIndicatorStyles();
  const dateText = useMemo(() => {
    const text = formatDate(date, true);
    if (!isLive) {
      return text.replace("-", "on");
    }

    return text;
  }, [date, isLive]);

  return (
    <Flex className={ classes.indicatorRoot } sx={ sx } data-testid={ dataTestId }>
      <ClockEightIcon className={ classes.clockIcon } />

      { isLive && (
        <Flex gap={ 4 } p={ 8 } align="center">
          <svg
            className={ classes.circle }
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            fill="none"
            viewBox="0 0 8 8"
          >
            <circle cx="4" cy="4" r="4" fill="#E36363"></circle>
          </svg>
          <Text weight={ 700 } lh={ "16px" }>
            LIVE
          </Text>
        </Flex>
      ) }

      <Text p={ 8 } pr={ 16 } lh={ "16px" }>
        { dateText }
      </Text>

      { !isLive &&
      (
        <Button size="md" onClick={ onClickReset }>
          Reset to Live
        </Button>
      ) }
    </Flex>
  );
};

export default TemporalFilterIndicatorComponent;
