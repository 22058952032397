/* eslint-disable import/extensions */
import { ActionIcon, Drawer, DrawerPosition, DrawerProps, DrawerStylesNames } from "@mantine/core";
import { Styles } from "@mantine/core";
import { MantineNumberSize } from "@mantine/styles/lib/theme/types/MantineSize";
import { FC, ReactNode, useCallback, useMemo } from "react";

import { ReactComponent as CloseIcon } from "@/common/icons/close.svg";
import { ReactComponent as MaximizeIcon } from "@/common/icons/maximize.svg";
import { ReactComponent as MinimizeIcon } from "@/common/icons/minimize.svg";

import { useBottomDrawerStyles } from "./BottomDrawer.styles";
import { BottomDrawerVariant } from "./BottomDrawer.types";

type Props = {
  title: string,
  position: DrawerPosition,
  isOpen: boolean,
  drawerVariant: BottomDrawerVariant,
  padding: MantineNumberSize,
  children: ReactNode,
  onClose: () => void,
  onDrawerVariantChanged?: (variant: BottomDrawerVariant) => void,
  simpleDrawerProps: Partial<DrawerProps>,
  styles?: Styles<DrawerStylesNames>
}

const BottomDrawerComponent: FC<Props> = ({
  title,
  position,
  isOpen,
  drawerVariant,
  padding,
  children,
  onClose,
  onDrawerVariantChanged,
  simpleDrawerProps,
  styles
}) => {

  const { classes } = useBottomDrawerStyles();

  const createDrawerVariantChangeRequestHandler = useCallback((requestedVariant: BottomDrawerVariant) =>
    (_: React.MouseEvent) => {
      if (onDrawerVariantChanged) {
        onDrawerVariantChanged(requestedVariant);
      }
    }, [onDrawerVariantChanged]);

  const onLargeDrawerVariantChangeRequested = useMemo(
    () => createDrawerVariantChangeRequestHandler('lg'),
    [createDrawerVariantChangeRequestHandler]
  );

  const onFullDrawerVariantChangeRequested = useMemo(
    () => createDrawerVariantChangeRequestHandler('full'),
    [createDrawerVariantChangeRequestHandler]
  );

  const drawerHeader: ReactNode = <div className={ classes.header }>
    { title }
    <div className="d-flex">
      { drawerVariant === 'lg' && (
        <ActionIcon mr={ 12 } onClick={ onFullDrawerVariantChangeRequested }>
          <MaximizeIcon />
        </ActionIcon>) }

      { drawerVariant === 'full' && (
        <ActionIcon mr={ 12 } onClick={ onLargeDrawerVariantChangeRequested }>
          <MinimizeIcon />
        </ActionIcon>) }

      <ActionIcon onClick={ onClose } >
        <CloseIcon />
      </ActionIcon>

    </div>
  </div>;

  return (
    <Drawer
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...simpleDrawerProps }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      styles={ styles }
      classNames={ { title: classes.title } }
      position={ position }
      opened={ isOpen }
      size={ drawerVariant }
      title={ drawerHeader }
      withCloseButton={ false }
      padding={ padding }
      onClose={ onClose }
    >
      { drawerVariant === 'full' && (
        <div className={ classes.headerContainer }>
          { drawerHeader }
        </div> ) }
      { children }
    </Drawer>
  );
};

export default BottomDrawerComponent;
