import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

export const useMassCommsFooterStyles = createStyles((theme: MantineTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    backgroundColor: theme.colors.neutral[7],
    boxShadow: theme.other.sdw.top,
    padding: 24
  },

  segment: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: 24
    }
  },

  confirmationModalHeader: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',

    '& > svg': {
      marginRight: 16
    }
  },

  confirmationModalContent: {
    marginTop: 16,
    color: theme.colors.neutral[4]
  },

  confirmationModalFooter: {
    marginTop: 48,
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *:not(:first-child)': {
      marginLeft: 24
    }
  }
}));
