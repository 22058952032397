import { Divider } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as Traveller } from '@/common/icons/travel.svg';
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import { CustomColors } from "@/core/styles/mantine/palettes/types";

import { useTravelSearchInfoBoxStyles } from "./TravelSearchInfoBox.styles";

type Props = {
  title: string,
  travellerCount: string
}


const TravelSearchInfoBox: FC<Props> = ({
  title,
  travellerCount = 0
}) => {

  const { classes, cx } = useTravelSearchInfoBoxStyles();

  return (
    <div className={ classes.wrapper }>
      <div className={ classes.infoBox }>
        <div className={ cx(classes.infoDiv, classes.mainTitle) }>
          <div className={ classes.title }>{ title }</div>
          <Traveller className={ classes.travelIcon }/>
        </div>
        <div className={ classes.dividerContainer }>
          <Divider color={ genericColorPalette.neutral[7] as CustomColors }
            orientation="vertical"/>
        </div>
        <div className={ classes.dayDiv }>
          <div className={ classes.dayCount }>{ travellerCount }</div>
        </div>
      </div>
    </div>
  );
};

export default TravelSearchInfoBox;
