import { IndoorMapGeoJSON } from "@f/map-gl-indoor";
import { Feature, Polygon } from '@turf/turf';
import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';

type Props = {
  currentGeofence?: Feature<Polygon> | IndoorMapGeoJSON
  isNoFill?: boolean;
  id?: string
}

const GeofenceLayerComponent: FC<Props> = ({
  currentGeofence,
  isNoFill,
  id
}) => {

  if (!currentGeofence) {
    return null;
  }

  return (
    <Source id={ id ? id : `current-geo-fence` } type="geojson" data={ currentGeofence }>
      <Layer
        id={ id ? `${id}-outline` : `polygon-outline` }
        type="line"
        paint={ {
          "line-color": "#FFD337",
          "line-opacity": 1,
          "line-width": 2,
          "line-dasharray": [2, 2]
        } }
      />
      <Layer
        id={ id ? `${id}-layer` : `current-geo-fence-layer` }
        type="fill"
        paint={ {
          "fill-color": "#FFD337",
          "fill-opacity": isNoFill ? 0 : 0.2
        } }
      />
    </Source>
  );
};

export default GeofenceLayerComponent;
