import { MIME_TYPES } from "@mantine/dropzone";

export const MIME_TYPE_JSON = 'application/json';
export const MIME_TYPE_GEO_JSON = 'application/geojson';

export const MAX_SITE_PLAN_UPLOAD_SIZE = 2 * 1024 * 1024; // 2 MB

export const UploadLocationImageConfig = {
  MAX_NUMBER_OF_FILES: 1,
  MAX_DOCUMENT_FILE_SIZE: 1 * 1024 * 1024,
  ALLOWED_FILE_FORMATS: [ MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.svg ],
  HUMAN_READABLE_ALLOWED_FILE_FORMATS: 'png, jpeg, svg'
};
