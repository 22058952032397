import { createModel } from "@rematch/core";
import EventSource from "eventsource";
import { FeatureCollection, Point } from "geojson";

import { logger } from "@/common/util/ConsoleLogger";
import { ENV, Environment } from "@/core/config/env";
import { store } from '@/core/store';
import { Dispatch, RootModel, RootState } from "@/core/store";
import { AssetRankingEvent, AssetRankingSummary } from "@/tenant-context/common/types/asset-ranking";
import { Location } from "@/tenant-context/common/types/location";
import geoJsonMapper from "@/tenant-context/common/util/geo-json-mapper";
import { mergeFeatureCollections } from "@/tenant-context/common/util/location-event/merge-feature-collections";
import { prepareEvent } from "@/tenant-context/common/util/location-event/prepare";
import { Visualizable } from "@/tenant-context/common/util/with-visualisable-traits";
import { withVisualisableTraits } from "@/tenant-context/common/util/with-visualisable-traits";
import { createPeopleLocationStream } from "@/tenant-context/visualisation-people/api/people";
import { CountryPersonCount, TravelPeopleMap, TravelResponse } from "@/tenant-context/visualisation-people/types/people.types";
import { getPersonInitials } from "@/tenant-context/visualisation-people/util/getPersonInitials";
import { LocationsPeopleCount } from "@/tenant-context/visualisation-site/types/site.types";

type PeopleLocationState = Visualizable<{
  countryWisePersonCount: CountryPersonCount[],
  eventQueue: AssetRankingSummary[],
  usedAdapters: string[],
  travelPeopleCount?: TravelPeopleMap;
}, FeatureCollection<Point, AssetRankingSummary>>;

export enum RankingOption {
  topRank = 'TOP_RANK',
  allRanks = 'ALL_RANK'
}

type NonSerializablePeopleLocationState = {
  locationStream?: EventSource
};

export const LOCATION_UPDATE_INTERVAL = 500;
const isInDevMode = (ENV === Environment.Dev ||  ENV === Environment.Qa);

const nonSerializableState: NonSerializablePeopleLocationState = {};

const peopleLocationLayerModel = withVisualisableTraits<
  FeatureCollection<Point, AssetRankingSummary>
>()({
  name: 'peopleLocations',
  state: {
    countryWisePersonCount: [] as CountryPersonCount[],
    eventQueue: [] as AssetRankingSummary[],
    usedAdapters: [] as string[],
    travelPeopleCount: {} as TravelPeopleMap
  } as unknown as PeopleLocationState,
  reducers: {
    SET_COUNTRY_WISE_PERSON_COUNT(state: PeopleLocationState, count: CountryPersonCount[]) {
      return {
        ...state,
        countryWisePersonCount: count
      };
    },

    ENQUEUE_PEOPLE_LOCATION_EVENT({ eventQueue, ...state }: PeopleLocationState, event: AssetRankingSummary) {
      return {
        ...state,
        eventQueue: [
          ...eventQueue,
          {
            ...event,
            __subject: 'asset'
          } as const
        ]
      };
    },

    SET_TRAVEL_PEOPLE_COUNT(state: PeopleLocationState, response: TravelPeopleMap) {
      return {
        ...state,
        travelPeopleCount: response
      };
    },

    DEQUEUE_EVENTS(
      state: PeopleLocationState,
      dequeuedEvents: AssetRankingSummary[]
    ) {
      const {
        eventQueue
      } = state;

      const dequeuedEventIds = new Set(
        dequeuedEvents.map(({
          topRank: {
            adapterEventId
          }
        }) => adapterEventId)
      );

      const updatedEventQueue = eventQueue.filter(
        ({ topRank: { adapterEventId } }) => !dequeuedEventIds.has(
          adapterEventId
        )
      );

      return {
        ...state,
        eventQueue: updatedEventQueue
      };
    },

    UPDATE_PEOPLE_LOCATIONS(
      state: PeopleLocationState,
      eventFeatureCollection: FeatureCollection<Point, AssetRankingSummary>
    ) {
      const {
        geoData
      } = state;

      const updatedGeoData = mergeFeatureCollections(
        geoData,
        eventFeatureCollection,
        'personId'
      );

      return {
        ...state,
        geoData: updatedGeoData
      };
    },

    RECALCULATE_COUNTRY_WISE_PEOPLE_COUNT(state: PeopleLocationState, countries: Location[]) {
      const { geoData: { features } } = state;

      const countryWisePersonCountMap: {
        [key: string]: CountryPersonCount
      } = {};

      // eslint-disable-next-line fp/no-loops
      for (const { properties: { topRank: { location: { isoCountryCode } } } } of features) {
        if (!countryWisePersonCountMap[isoCountryCode]) {
          const country = countries.find(
            ({ isoCountryCode: iso }) => iso === isoCountryCode
          );

          if (!country) {
            continue;
          }

          countryWisePersonCountMap[isoCountryCode] = {
            count: 0,
            latitude: country.geoPoint.lat,
            longitude: country.geoPoint.lon,
            countryName: isoCountryCode
          };
        }

        countryWisePersonCountMap[isoCountryCode].count++;
      }

      const countryWisePersonCount = Object.values(countryWisePersonCountMap);

      return {
        ...state,
        countryWisePersonCount
      };
    },

    RECALCULATE_USED_ADAPTERS(state: PeopleLocationState) {
      const {
        geoData: {
          features
        }
      } = state;

      const usedAdapters = Array.from(new Set(
        features.flatMap(
          ({
            properties: {
              topRank,
              otherRanks
            }
          }) => [
            topRank.adapterSource,
            ...otherRanks.map(({ adapterSource }) => adapterSource)
          ]
        )
      ));

      return {
        ...state,
        usedAdapters
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async subscribeToPeopleLocationData(
      _: void,
      state: RootState
    ): Promise<void> {
      const {
        rankingSettings: {
          isShowOnlyHighestRanksForPeople,
          timeTravelTargetEpoch
        },
        commonData: {
          tenantId
        }
      } = state;

      const stream = createPeopleLocationStream(
        (event) => {

          if ((event as LocationsPeopleCount)._type === 'POBDataResponse') {
            dispatch.sitePopup.SET_LOCATIONS_PEOPLE_COUNT(event as LocationsPeopleCount);
          }

          if (event._type === 'TravelResponse') {
            const { responseMap } = event as unknown as TravelResponse;

            if (!responseMap.all) {
              const all = {
                countryCode: 'ALL',
                todayTravelling: {
                  arrivingCount:
                  Object.values(responseMap)
                    .reduce((acc, cur) => acc + cur.todayTravelling.arrivingCount, 0),
                  leavingCount:
                  Object.values(responseMap)
                    .reduce((acc, cur) => acc + cur.todayTravelling.leavingCount, 0)
                },
                tomorrowTravelling: {
                  arrivingCount:
                  Object.values(responseMap)
                    .reduce((acc, cur) => acc + cur.tomorrowTravelling.arrivingCount, 0),
                  leavingCount:
                  Object.values(responseMap)
                    .reduce((acc, cur) => acc + cur.tomorrowTravelling.leavingCount, 0)
                }
              };

              dispatch.peopleLocations.SET_TRAVEL_PEOPLE_COUNT({ ...responseMap, all });
              return;
            }

            dispatch.peopleLocations.SET_TRAVEL_PEOPLE_COUNT(responseMap);
            return;
          }
          // eslint-disable-next-line no-param-reassign
          event.personInitials = getPersonInitials(`${(event as AssetRankingSummary).personFirstName} ${(event as AssetRankingSummary).personLastName}`);
          const assetRankingEvent = event as unknown as AssetRankingSummary;
          if (assetRankingEvent.loneWorkerStatus && assetRankingEvent.topRank.loneWorker?.status) {
            assetRankingEvent.topRank.loneWorker.status = assetRankingEvent.loneWorkerStatus;
          }

          if ((event as LocationsPeopleCount)._type === 'POBDataResponse') {
            dispatch.sitePopup.SET_LOCATIONS_PEOPLE_COUNT(event as LocationsPeopleCount);
          }
          if (event._type !== 'TrackingResponse') {
            // Not to process TravelResponse here
            return;
          } else {
            const personLocationEvent: AssetRankingSummary = event as AssetRankingSummary;
            personLocationEvent.topRank.loneWorkerStatus = personLocationEvent.loneWorkerStatus;
            dispatch.peopleLocations.ENQUEUE_PEOPLE_LOCATION_EVENT(personLocationEvent);
          }
        },
        {
          rankRequestTime: timeTravelTargetEpoch.toString(),
          rankingOption: isShowOnlyHighestRanksForPeople
            ? RankingOption.topRank
            : RankingOption.allRanks,
          tenantId
        }
      );

      setInterval(() => {
        if (!store.getState().peopleLocations.eventQueue.length) {
          return;
        }

        dispatch.peopleLocations.processEnqueuedLocationEvents();
      }, LOCATION_UPDATE_INTERVAL);

      nonSerializableState.locationStream = stream;
    },

    unsubscribeFromPeopleLocationData(): void {
      if (!nonSerializableState.locationStream) {
        return;
      }

      nonSerializableState.locationStream.close();
      dispatch.peopleLocations.CLEAR_GEO_DATA();
      dispatch.peopleBreadcrumbs.CLEAR_GEO_DATA();
      dispatch.peopleBreadcrumbs.CLEAR_LINES_BETWEEN_CRUMBS();
    },

    getPersonById(personId: string): AssetRankingEvent | undefined {
      const {
        peopleLocations: {
          geoData: {
            features
          }
        }
      } = store.getState();

      const person = features
        .find(
          ({ properties: { topRank } }) => topRank.assetId === personId
        )
        ?.properties
        .topRank;

      return person;
    },

    async processEnqueuedLocationEvents(_: void, state: RootState): Promise<void> {
      // Before going into this function, I check whether there are any events in the queue
      // If there are not any - do not call this function
      const {
        peopleLocations: {
          eventQueue
        },

        commonData: {
          allCountries
        },

        rankingSettings: {
          isShowOnlyHighestRanksForPeople
        }
      } = state;

      const eventsToProcess = [...eventQueue.map(prepareEvent)];

      const filteredEvents = eventsToProcess.filter((event) => {
        const include = event.topRank.location?.point?.lat && event.topRank.location?.point?.lon;

        if (!include) {
          if (isInDevMode) {
            logger.warn('Incompatible event found for people locations:', event);
          }

          return false;
        }

        return include;
      });

      const topRankIncomingFeatureCollection = await geoJsonMapper.parse(
        filteredEvents,
        {
          Point: [
            'topRank.location.point.lat',
            'topRank.location.point.lon'
          ]
          // exclude: ['otherRanks']
        }
      ) as FeatureCollection<Point, AssetRankingSummary>;

      // Update people location feature collection
      dispatch.peopleLocations.UPDATE_PEOPLE_LOCATIONS(
        topRankIncomingFeatureCollection
      );

      // Update breadcrumbs if "SHOW ALL CRUMBS" selected
      if (!isShowOnlyHighestRanksForPeople) {
        dispatch.peopleBreadcrumbs.processEnqueuedBreadcrumbEvents(
          eventsToProcess
        );
      }

      // Throw away processed events
      dispatch.peopleLocations.DEQUEUE_EVENTS(eventsToProcess);

      // Side effects: people per countries
      dispatch.peopleLocations.RECALCULATE_COUNTRY_WISE_PEOPLE_COUNT(
        allCountries
      );

      // Side effects: people per sites
      dispatch.siteLocations.recalculatePeopleCountForSites();

      // Side effects: used adapters
      dispatch.peopleLocations.RECALCULATE_USED_ADAPTERS();
    }
  })
});

export const peopleLocations = createModel<RootModel>()(
  peopleLocationLayerModel
);
