import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { CommonLocationLayerType } from "@/tenant-context/visualisation-common-locations/types/common-location.type";

export const CommonLocationLayerConfig: Array<CommonLocationLayerType> = [
  {
    id: `r__commonLocationsLayer-hotels`,
    type: 'symbol',
    filter: [
      ['==', 'subCategory', 'Hotel']
    ],
    layout: {
      'icon-image': 'static-location-hotel-marker',
      'icon-size': 0.2
    },
    toggleableLayerType: ToggleableLayerType.Hotels,
    fieldName: 'Hotel'
  },
  {
    id: `r__commonLocationsLayer-airports`,
    type: 'symbol',
    filter: [
      ['==', 'subCategory', 'Airport']
    ],
    layout: {
      'icon-image': 'static-location-airport-marker',
      'icon-size': 0.2
    },
    toggleableLayerType: ToggleableLayerType.Airports,
    fieldName: 'Airport'
  },
  {
    id: `r__commonLocationsLayer-countries`,
    type: 'region',
    filter: [
      ['==', 'subCategory', 'Country'],
      ['==', '$type', 'Polygon']
    ],
    layout: {},
    toggleableLayerType: ToggleableLayerType.CommonRegions,
    fieldName: 'Country'
  },
  {
    id: `r__commonLocationsLayer-cities`,
    type: 'region',
    filter: [
      ['==', 'subCategory', 'City'],
      ['==', '$type', 'Polygon']
    ],
    layout: {},
    toggleableLayerType: ToggleableLayerType.CommonRegions,
    fieldName: 'City'
  },
  {
    id: `r__commonLocationsLayer-regions`,
    type: 'region',
    filter: [
      ['==', 'subCategory', 'Region'],
      ['==', '$type', 'Polygon']
    ],
    layout: {},
    toggleableLayerType: ToggleableLayerType.CommonRegions,
    fieldName: 'Region'
  }
];
