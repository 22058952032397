/* eslint-disable react/no-multi-comp */
import { FC, ReactElement } from "react";

import BuildingsAndFloorsTab
  from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab";

import { LocationTab } from "../../context/LocationInfo.context";
import AdditionalDetailsTab from "./AdditionalDetailsTab/AdditionalDetailsTabContent/AdditionalDetailsTab.container";
import LocationDetailsTab from "./LocationDetailsTab/LocationDetailsTabContent/LocationDetailsTab.container";
import SitePlanTab from "./SitePlanTab";
import ZoneTab from "./ZoneTab";

type Props = {
  activeTab: LocationTab
}

const SelectedLocationTab: FC<Props> = ({
  activeTab
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tabMap: Record<LocationTab, () => ReactElement<any, any> | null> = {
    [LocationTab.LocationDetails]: () => ( <LocationDetailsTab/> ),
    [LocationTab.AdditionalDetails]: () => ( <AdditionalDetailsTab /> ),
    [LocationTab.SitePlan]: () => (  <SitePlanTab /> ),
    [LocationTab.Buildings_Floors]: () => (<BuildingsAndFloorsTab/>),
    [LocationTab.Zones]: () => ( <ZoneTab /> ),
    [LocationTab.Logs]: () => ( <div>Logs</div> )
  };

  return tabMap[activeTab]();
};

export default SelectedLocationTab;
