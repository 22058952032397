import { createStyles } from "@mantine/core";

export const useDrawControlStyles = createStyles((theme) => ({
  pagination: {
    all: 'unset',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    fontSize: '12px',
    cursor: "pointer",
    color: theme.colors.info[0]
  },
  unauthorizedButton: {
    backgroundColor: `${theme.colors.neutral[3]} !important`,
    cursor: 'not-allowed',
    'svg path': {
      fill: theme.colors.neutral[5]
    },
    '*': {
      color: `${theme.colors.neutral[5]} !important`
    }
  }
}));
