import { FeatureCollection, Point } from "geojson";

import {
  IndoorPersonAssetRankingSummary,
  IndoorPersonLocationProperties
} from "@/tenant-context/plugin-indoors/types/indoor-map.types";

import { IndoorState } from "./indoor.state";

export const indoorReducers = {
  SET_LEVEL(state: IndoorState, payload: number | undefined) {
    return {
      ...state,
      level: payload
    };
  },
  SET_IS_SITE_LOADED(state: IndoorState, payload: boolean) {
    return {
      ...state,
      isSiteLoaded: payload
    };
  },
  SET_CURRENT_SITE_ID(state: IndoorState, payload: string | undefined) {
    return {
      ...state,
      currentSiteId: payload
    };
  },
  SET_INDOOR_PEOPLE(state: IndoorState, payload: FeatureCollection<Point, IndoorPersonAssetRankingSummary>) {
    return {
      ...state,
      indoorPeople: payload
    };
  },
  SET_INDOOR_PEOPLE_LOCATIONS(state: IndoorState, payload: FeatureCollection<Point, IndoorPersonLocationProperties>) {
    return {
      ...state,
      indoorPeopleLocation: payload
    };
  }
};
