import { createStyles } from "@mantine/core";

export const useLocationGraphControlStyles = createStyles((t) => ({
  heading: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    width: '100%',
    marginRight: '48px',
    fontSize: t.fontSizes.sm
  },

  titleString: {
    display: 'flex',
    alignItems: 'center'
  },

  title: {
    textTransform: 'uppercase',
    lineHeight: '21px'
  },

  personName: {
    fontSize: t.other.fs.md,
    fontWeight: t.other.fw.bold,
    lineHeight: '31px'
  }
}));
