import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";

import LocationGraph from './LocationGraphControl.component';

const LocationGraphControlContainer: FC = () => {
  const currentPersonAssetId = useSelector((state: RootState) => state.locationGraph.currentPersonFullName);
  const {
    locationGraph: {
      disableGraph
    },
    peopleBreadcrumbs: {
      safeToggleLayerVisibility
    }
  } = useDispatch<Dispatch>();

  const onCloseClick = useCallback(() => {
    disableGraph();
    safeToggleLayerVisibility(false);
  }, [disableGraph, safeToggleLayerVisibility]);

  return (
    <LocationGraph
      currentPersonAssetId={ currentPersonAssetId }
      onCloseClick={ onCloseClick }
    />
  );
};

export default LocationGraphControlContainer;
