import { FC, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock/ProfileBlock.component";
import ProfileKeyValue from "@/tenant-context/control-profile/components/ui/ProfileKeyValue/ProfileKeyValue.component";
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock/ProfileKeyValueBlock.component";
import { ProfileGeneralSection } from "@/tenant-context/control-profile/types/profile";

type Props = {
  createAction?: (section: ProfileGeneralSection) => () => ReactNode;
};

const ProfileDistinguishingFeatures: FC<Props> = ({
  createAction
}) => {
  const {
    profile: {
      loadDistinguishingFeatures
    }
  } = useDispatch<Dispatch>();

  const distinguishingFeatures = useSelector(
    ({ profile: { distinguishingFeatures: df } }: RootState) => df
  );

  const profileId = useSelector(
    ({ profile: { id } }: RootState) => id
  );

  useEffect(() => {
    loadDistinguishingFeatures();
  }, [loadDistinguishingFeatures, profileId]);

  return (
    <ProfileBlock
      heading="Distinguishing Features"
      renderAction={ createAction?.(ProfileGeneralSection.DistinguishingFeatures) }
    >
      <ProfileKeyValueBlock
        gtc={ '1fr 2fr' }
        maxColumns={ 2 }
      >
        <ProfileKeyValue firstItem="label" name={ 'Tattoos' } value={ distinguishingFeatures?.tattoosList } />
        <ProfileKeyValue firstItem="label" name={ 'Scars' } value={ distinguishingFeatures?.scarsList } />
        <ProfileKeyValue firstItem="label" name={ 'Birth Marks' } value={ distinguishingFeatures?.birthMarksList } />
      </ProfileKeyValueBlock>
    </ProfileBlock>
  );
};

export default ProfileDistinguishingFeatures;