import { createStyles } from "@mantine/core";

export const useDropzoneStyles = createStyles((theme) => ({
  dropZone: {
    border: `1px dashed ${theme.colors.neutral[5]}`,
    borderRadius: '4px',
    textAlign: 'center',
    padding: '0 120px',
    marginBottom: 24,
    backgroundColor: theme.colors.neutral[9]
  },

  dropIcon: {
    width: 'fit-content',
    position: 'relative',
    // This makes the whole icon look right, idk why
    '& > *:nth-of-type(2)': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)'
    }
  },

  accentedAction: {
    fontWeight: theme.other.fw.semibold,
    color: '#6BA5FC'
  },

  dimmedText: {
    color: theme.colors.neutral[5]
  }
}));
