import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import useDrawer from "@/common/hooks/useDrawer";
import { Dispatch, RootState } from "@/core/store";
import { useMapLayersDrawerContext } from "@/tenant-context/control-risk-config/context/MapLayersDrawer.context";

import MapLayersComponent from "./MapLayers.component";

const MapLayersContainer: React.FC = () => {
  const { openedTreeItems, handleToggleTreeItem } = useMapLayersDrawerContext();
  const {
    riskAlerts: { showOnlyImpactedRiskEvents }
  } = useSelector((state: RootState) => state);

  const {
    riskAlerts: { SET_SHOW_ONLY_IMPACTED_RISK_EVENTS }
  } = useDispatch<Dispatch>();

  const handleImpactRiskEventsToggle = useCallback((checked: boolean) => {
    SET_SHOW_ONLY_IMPACTED_RISK_EVENTS(checked);
  }, [SET_SHOW_ONLY_IMPACTED_RISK_EVENTS]);

  const {
    drawer: {
      openBottomDrawer
    }
  } = useDispatch<Dispatch>();


  const {
    isOpen,
    close
  } = useDrawer('risk-timeline-graph-control', 'bottom');

  const toggleRiskTimelineDrawer = useCallback(() => {
    if(!isOpen) {
      openBottomDrawer('risk-timeline-graph-control');
    } else {
      close();
    }
  }, [close, isOpen, openBottomDrawer]);

  return (
    <MapLayersComponent
      openedTreeItems={ openedTreeItems }
      onToggleTreeItem={ handleToggleTreeItem }
      isShowOnlyImpactedAlerts={ showOnlyImpactedRiskEvents }
      onImpactRiskEventsToggleChange={ handleImpactRiskEventsToggle }
      onToggleRiskTimelineClick={ toggleRiskTimelineDrawer }
      isRiskTimelineOpened={ isOpen }
    />
  );
};

export default MapLayersContainer;
