import { FeatureCollection, Point } from "geojson";
import { FC } from "react";
import { useMap } from "react-map-gl";

import { ArcPersonImpactDetail } from "@/tenant-context/control-action-response-center/types/ARC.types";
import usePeopleIcons from "@/tenant-context/visualisation-people/hooks/usePeopleIcons";
import PeopleLocationLayerComponent from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.component";


type Props = {
    peopleGeodata: FeatureCollection<Point, ArcPersonImpactDetail>
  }

const ImpactPeopleContainer: FC<Props> =  ({
  peopleGeodata
}) => {

  const { current: map } = useMap();
  usePeopleIcons(map, true);

  return (
    <PeopleLocationLayerComponent
      isArcModeOn = { true }
      geoData={ peopleGeodata }
      disabledAdapters={ [] }
      minRankThreshold={ 0 }
      maxRankThreshold={ 3300 }
      isMinRankThresholdApplied={ false } />
  );
};

export default ImpactPeopleContainer;