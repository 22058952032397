import { callApi } from '@/common/util/api';
import {
  LocationTimezoneResponse,
  ReverseGeoLocationAddressResponse,
  ReverseGeoLocationPlaceNameResponse,
  ServiceType
} from '@/tenant-context/core/types/reverse-geocoding';

export const getReverseGeoLocationPlaceName = (lon: string, lat: string, service?: ServiceType) =>
  callApi<ReverseGeoLocationPlaceNameResponse>(
    `${service || 'map-service'}/v1/get-reverse-geocoding?latitude=${lat}&longitude=${lon}`,
    {
      method: 'get'
    }
  );

export const getGeoLocationAddress = (lon: string, lat: string, tenantId: string) => {
  return callApi<ReverseGeoLocationAddressResponse>(
    `location-management-service/v1/get-reverse-geocoding`,
    {
      method: 'post',
      headers: {
        tenantId
      },
      body: JSON.stringify({
        processType: "REVERSE_GEOCODING",
        spatialType: "PLACE",
        point: {
          lat,
          lon
        }
      })
    }
  );
};

export const getLocationTimezone = (lon: string, lat: string) =>
  callApi<LocationTimezoneResponse>(
    `location-management-service/v1/timeZones?lat=${lat}&lon=${lon}`,
    {
      method: 'get'
    }
  );
