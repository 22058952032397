import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";

import EmailSummaryComponent from "./EmailSummary.component";

const EmailSummary: FC = () => {
  const {
    emailContent,
    selectedResponseMethod,
    selectedRecipients,
    receiveCountByCommunicationChannel,
    emailResponseMethodConfigurations
  } = useSelector((state: RootState) => state.massComms);
  const {
    massComms: {
      SET_CURRENT_STEP,
      SET_SELECTED_CONFIG_TAB,
      getEmailBodyContent
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    getEmailBodyContent();
  }, [getEmailBodyContent]);

  const handleStepperNavigate = useCallback((step: number) => () => {
    SET_CURRENT_STEP(step);
    SET_SELECTED_CONFIG_TAB({ defaultTab: 'email', fromSummary: true });
  }, [SET_CURRENT_STEP, SET_SELECTED_CONFIG_TAB]);


  const expectedTotalRecipientCount = selectedRecipients.length;
  const receivingCount = selectedRecipients.length - receiveCountByCommunicationChannel.none;
  return (
    <EmailSummaryComponent
      emailContent={ emailContent }
      responseMethod={ selectedResponseMethod }
      onStepperNavigate={ handleStepperNavigate }
      expectedTotalRecipientCount={ expectedTotalRecipientCount }
      receivingCount={ receivingCount }
      emailResponseConfigs={ emailResponseMethodConfigurations }
    />
  );
};

export default EmailSummary;
