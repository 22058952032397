import { ActionIcon, Button } from "@mantine/core";
import dayjs from "dayjs";
import { FC, useCallback, useMemo } from "react";

import PersonAvatar from "@/common/components/PersonAvatar";
import { ReactComponent as Conflict } from "@/common/icons/conflict.svg";
import { ReactComponent as Crime } from "@/common/icons/crime.svg";
import { ReactComponent as Crosshair } from "@/common/icons/crosshair.svg";
import { ReactComponent as Demonstrations } from "@/common/icons/demonstrations.svg";
import { ReactComponent as Health } from "@/common/icons/health.svg";
import { ReactComponent as Natural } from "@/common/icons/natural.svg";
import { ReactComponent as Political } from "@/common/icons/political.svg";
import { ReactComponent as RiskAlertLevelIcon } from "@/common/icons/riskAlertLevelIcon.svg";
import { ReactComponent as Siren } from '@/common/icons/siren.svg';
import { ReactComponent as Travel } from "@/common/icons/travel.svg";
import { getTimeDurationFromCurrentDate } from "@/common/util/format/date";
import theme from "@/core/styles/mantine/theme";
import { formatDateTime } from '@/tenant-context/common/util/data-standardize';
import {
  MaxSecurityRiskCategories
} from '@/tenant-context/control-live-feed/components/LiveFeedNotification/LiveFeedNotification.config';
import { NotificationCritically } from "@/tenant-context/control-live-feed/types/live-feed";

import { useLiveFeedNotificationStyles } from "./LiveFeedNotification.styles";

interface Props {
    critically?: string;
    profilePicture?: string;
    profileName?: string;
    details?: string;
    title: string;
    time: number;
    riskLevelId?: string
    riskLevelName?: string;
    riskCategoryId?: string;
    riskCategoryName?: string;
    source?: string;
    location?: string;
    peopleCount?: number;
    assetsCount?: number;
    isFromRight?: boolean;
    handleButtonClick?: () => void;
    handleRemoveButtonClick?: () => void;
    position?: number;
    caseOwnerName?: string;
    caseOwnerPicture?: string;
    caseOwnerJobTitle?: string;
    closedDataTime?: number;
    showZoomButton?: boolean;
    onZoomClick?: () => void;
    isClosed: boolean;
}

const riskCategories = [
  {
    Icon: <Political />,
    categoryId:'1'
  },
  {
    Icon: <Conflict />,
    categoryId:'2'
  },
  {
    Icon: <Demonstrations />,
    categoryId:'3'
  },
  {
    Icon: <Crime />,
    categoryId:'4'
  },
  {
    Icon: <Natural />,
    categoryId:'5'
  },
  {
    Icon: <Health />,
    categoryId:'6'
  },
  {
    Icon: <Travel />,
    categoryId:'7'
  }
];

const LiveFeedNotificationComponent: FC<Props> = ({
  critically,
  profilePicture,
  profileName,
  details,
  title,
  time,
  riskLevelId,
  riskLevelName,
  riskCategoryId,
  riskCategoryName,
  source,
  location,
  peopleCount,
  assetsCount,
  isFromRight,
  handleButtonClick,
  position,
  caseOwnerPicture,
  caseOwnerName,
  caseOwnerJobTitle,
  closedDataTime,
  showZoomButton,
  onZoomClick,
  isClosed
}) => {
  const getCriticality = (crit?: string) => {
    if (crit === NotificationCritically.CRITICAL) {
      return (
        <>
          Critical
        </>
      );
    }
  };

  const getRiskLevelColor = useCallback(
    (value?: string) => {
      if (closedDataTime) {
        return theme.colors?.neutral?.[7];
      }

      return  !value ? "" : theme.colors?.risk?.[parseInt(value)-1];
    },
    [closedDataTime]
  );

  const getRiskLevelTextColor = useCallback(
    (value?: string) => {
      return  (value === '1' || value === '2' || value === '3') ? theme.colors?.navyBlue?.[1] : theme.colors?.neutral?.[0];
    },
    []
  );

  const gerCriticallyColor =  useCallback(
    (value?: string) => {
      if (!value) {
        return undefined;
      }

      if (closedDataTime) {
        return theme.colors?.neutral?.[7];
      }

      return  value === 'CRITICAL' ? theme.colors?.risk?.[4] : theme.colors?.risk?.[3];
    },
    [closedDataTime]
  );

  const getRiskCriticallyTextColor = useCallback(
    (value?: string) => {
      if (!value) {
        return theme.colors?.neutral?.[0];
      }

      if (isClosed) {
        return theme.colors?.neutral?.[0];
      }

      return  value === 'CRITICAL' ? theme.colors?.neutral?.[0] : theme.colors?.neutral?.[9];
    }
    , []
  );

  const riskCategoryIcon = useMemo(() => {
    if (source === 'dataminr') {
      return null;
    } else if (source === 'max-security') {
      if (!riskCategoryId) {
        return '';
      }

      const maxSecurityRiskCategory = riskCategoryName?.replace(' ', '');
      return MaxSecurityRiskCategories.find(
        (category) => category.categoryId === maxSecurityRiskCategory
      )?.Icon;
    }

    return !riskCategoryId ? "" : riskCategories.find((one) => one.categoryId === riskCategoryId)?.Icon;
  },[riskCategoryId, riskCategoryName, source]);

  const getTime = useCallback(() => {

    if (closedDataTime) {
      return dayjs(closedDataTime).format('DD MMM YYYY');
    }

    const date = new Date(time);

    const inFuture = date.valueOf() > new Date().valueOf();

    if (inFuture) {
      return `In ${getTimeDurationFromCurrentDate(date).toLowerCase()}`;
    } else {
      return  `${getTimeDurationFromCurrentDate(date).toLowerCase()} ago`;
    }
  }, [time, closedDataTime]);

  const { classes } = useLiveFeedNotificationStyles({
    isFullHeader: Number(riskLevelId) > 3 || !!critically,
    isRiskLevelOne: riskLevelId === '1',
    riskLevelTextColor: getRiskLevelTextColor(riskLevelId),
    riskLevelIconColor: getRiskLevelColor(riskLevelId),
    criticallyColor: gerCriticallyColor(critically),
    criticallyTextColor: getRiskCriticallyTextColor(critically),
    isFromRight,
    position: position,
    isClosed,
    source
  });

  const handleClick = useCallback(
    () => {
      handleButtonClick?.();
    }
    , [handleButtonClick]
  );

  return (
    <div className={ classes.container }>
      <div className={ classes.content }>
        <div className={ classes.contentHeader }>
          { riskLevelId ?
            <>
              <div className={ classes.riskLevelContainer }>
                <RiskAlertLevelIcon className={ classes.riskLevelIcon }/>
                <p className={ classes.riskLevelIdText }>{ riskLevelId } </p>
                <p>
                  { riskLevelName }
                </p>
              </div>
              <div className={ classes.riskNameContainer }>
                { riskCategoryIcon }
                <p>
                  { riskCategoryName }
                </p>
              </div>
            </>
            :
            (
              <p className={ classes.headerTitle }>
                <Siren/> { title }
              </p>
            ) }
          <div className={ classes.critically }>
            { critically && getCriticality(critically) }
          </div>
        </div>
        <div className={ classes.contentBody }>
          <div className={ classes.contentBodyHeader }>
            { profileName &&
        (
          <PersonAvatar
            width="24px"
            height="24px"
            personName={ profileName }
            personAvatarUrl={ profilePicture }
            className={ classes.avatar }
          />
        ) }
            <div>
              <p className={ classes.profileName }>
                { profileName || details }
              </p>
              <p className={ classes.location }>
                { location || 'Unknown' }
              </p>
              <p className={ classes.source }>
                Source: { source || 'Unknown' }
              </p>
              { caseOwnerName && <div className={ classes.caseOwnerContainer }>
                <PersonAvatar
                  width="24px"
                  height="24px"
                  personName={ caseOwnerName }
                  personAvatarUrl={ caseOwnerPicture }
                  className={ classes.avatar }
                />
                <div>
                  <p className={ classes.caseOwnerTitle }>Case Owner</p>
                  <p className={ classes.caseOwnerName }>
                    { caseOwnerName }
                    <span className={ classes.caseOwnerJobTitle }>
                      { caseOwnerJobTitle }
                    </span>
                  </p>
                </div>
              </div> }
            </div>

          </div>
          { riskLevelId && <div className={ classes.impactedContainer }>
            <div className={ classes.impactedInfo }>
              <p className={ classes.impactedTitle }>People Impacted</p>
              <p className={ classes.impactedCount }>{ peopleCount }</p>
            </div>
            <div className={ classes.impactedInfo }>
              <p className={ classes.impactedTitle }>Assets Impacted</p>
              <p className={ classes.impactedCount }>{ assetsCount }</p>
            </div>
          </div> }
          { riskLevelId && time && <div className={ classes.impactedContainer }>
            <div className={ classes.impactedInfo }>
              <p className={ classes.impactedTitle }>Alert Time</p>
              <p className={ classes.impactedCount }>{ formatDateTime(time, 'YYYY-MM-DD, HH:mm') }</p>
            </div>
          </div> }
        </div>
      </div>
      <div className={ classes.footer }>
        <div className={ classes.footerTime }>
          { showZoomButton &&
          <ActionIcon
            onClick={ onZoomClick }
            className={ classes.zoomIcon }
          >
            <Crosshair className={ classes.crosshair } />
          </ActionIcon> }
          { isClosed && <div className={ classes.closed }>
            Closed
          </div> }
          { getTime() }
        </div>

        <Button
          size={ 'sm' }
          variant={ 'default' }
          className={ classes.button }
          onClick={ handleClick }
          disabled={ isClosed }
        >
          { closedDataTime || caseOwnerName ? 'View case' : 'Respond' }
        </Button>

      </div>
    </div>
  );
};

export default LiveFeedNotificationComponent;
