import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { geoTimeZones } from "@/common/config/geoTimeZones";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import { LocationTypes } from "@/tenant-context/control-connected-sites-dashboard/types/connectedSitesDashboard";
import {
  UploadLocationImageConfig
} from "@/tenant-context/control-location-configuration/config/location-configuration.config";
import {
  EnteredLocationDetails,
  GeoTimeZone
} from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

import { LocationInfoContext } from "../../../../context/LocationInfo.context";
import { LocationManagementContext } from '../../../../context/LocationManagement.context';
import LocationDetailsFormComponent from './LocationDetailsForm.component';

const LocationDetailsForm: FC = () => {

  const [currentAlias, setCurrentAlias] = useState<string>("");
  const [uploadedImagePreview, setUploadedImagePreview] = useState<string | undefined>();

  const imageUploadInputRef: React.MutableRefObject<HTMLButtonElement | null> = useRef(null);

  const {
    enteredLocationDetails,
    locationTypes
  } = useSelector((state: RootState) => state.manageLocations);

  const { allCountries } = useSelector((state: RootState) => state.commonData);
  const currentSearchLocation = useSelector((state: RootState) => state.mapSearch?.activeLocation);

  const { sitePicture } = useSelector((state: RootState) => state.manageLocations);

  const {
    manageLocations: {
      SET_ENTERED_LOCATION_DETAILS,
      SET_LOCATION_SITE_PIC
    }
  } = useDispatch<Dispatch>();

  const {
    setMarkerLocationExternally,
    locationDetailsForm
  } = useContext(LocationManagementContext);

  const { isAuthorizedToEdit } = useContext(LocationInfoContext);

  const countries = useMemo(() => {
    return allCountries.map(x => ({
      label: x.countryName,
      value: x.isoCountryCode
    }));
  }, [allCountries]);

  const handleChangeFormInputs = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const locationDetails = { ...enteredLocationDetails };
      locationDetails[event.target.name as keyof Omit<EnteredLocationDetails, 'alias' | 'timezones' | 'type'>] = event.target.value;
      SET_ENTERED_LOCATION_DETAILS(locationDetails);
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  const handleCriticalLocationChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const locationDetails = { ...enteredLocationDetails };
      locationDetails['isCriticalLocation'] = event.currentTarget.checked;
      SET_ENTERED_LOCATION_DETAILS(locationDetails);
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  const handleChangeCountries = useCallback(
    (countryString: string | null) => {
      if (countryString) {
        const locationDetails = { ...enteredLocationDetails };
        const countryId = allCountries.find(x => x.isoCountryCode === countryString)?.id;

        if (countryId) {
          locationDetails.isoCountryCode = countryString;
          locationDetails.parentId = countryId;
          SET_ENTERED_LOCATION_DETAILS(locationDetails);
        }
      }
    },
    [SET_ENTERED_LOCATION_DETAILS, allCountries, enteredLocationDetails]
  );

  const handleChangeTimeZone = useCallback(
    (timeZone: string | null) => {
      if (timeZone) {
        const locationDetails = { ...enteredLocationDetails };
        const timeZoneObj = geoTimeZones.find(tZone => tZone.timeZone === timeZone);
        locationDetails.timezones = timeZoneObj as GeoTimeZone;
        SET_ENTERED_LOCATION_DETAILS(locationDetails);
      }
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  const handleChangeCategories = useCallback(
    (category: string | null) => {
      const locationDetails = { ...enteredLocationDetails };
      locationDetails.locationCategory = category || "";
      SET_ENTERED_LOCATION_DETAILS(locationDetails);
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  const handleChangeLocationTypes = useCallback(
    (locationType: string | null) => {
      if (locationType) {
        const locationDetails = { ...enteredLocationDetails };
        locationDetails.locationType = locationType;
        SET_ENTERED_LOCATION_DETAILS(locationDetails);
      }
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  useEffect(()=>{
    if(locationTypes.length > 0){
      handleChangeLocationTypes(enteredLocationDetails.locationType || LocationTypes.LocationPin);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationTypes]);

  const handleChangeSitePicUpload = useCallback(
    (file: File | null) => {
      if ((file?.size || 0) > UploadLocationImageConfig.MAX_DOCUMENT_FILE_SIZE) {
        showNotification({
          message: "Please upload a file under 1MB",
          color: "error"
        });

        return;
      }
      if (file) {
        SET_LOCATION_SITE_PIC(file);
      }
    },
    [SET_LOCATION_SITE_PIC]
  );

  const handleLatitudeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const locationDetails = { ...enteredLocationDetails };
      locationDetails.lat = event.currentTarget.value;
      SET_ENTERED_LOCATION_DETAILS(locationDetails);
      if (!isNaN(parseFloat(event.currentTarget.value))) {
        setMarkerLocationExternally(Number(event.currentTarget.value), Number(locationDetails.lon));
      }
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails, setMarkerLocationExternally]
  );

  const handleLongitudeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const locationDetails = { ...enteredLocationDetails };
      locationDetails.lon = event.currentTarget.value;
      SET_ENTERED_LOCATION_DETAILS(locationDetails);
      if (!isNaN(parseFloat(event.currentTarget.value))) {
        setMarkerLocationExternally(Number(locationDetails.lat), Number(event.currentTarget.value));
      }
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails, setMarkerLocationExternally]
  );

  const handleCurrentAliasChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentAlias(event.currentTarget.value);
    },
    []
  );

  const handleAddLocationAliasChange = useCallback(
    () => {
      if (currentAlias.trim()) {
        const locationDetails = { ...enteredLocationDetails };
        locationDetails.alias.push(currentAlias);
        SET_ENTERED_LOCATION_DETAILS(locationDetails);

        setCurrentAlias("");
      } else {
        showNotification({
          color: "error",
          message: "Please input an alias to add"
        });
      }
    },
    [SET_ENTERED_LOCATION_DETAILS, currentAlias, enteredLocationDetails]
  );

  const handleRemoveLocationAlias = useCallback(
    (index: number) => {
      const locationDetails = { ...enteredLocationDetails };
      locationDetails.alias.splice(index, 1);
      SET_ENTERED_LOCATION_DETAILS(locationDetails);

      setCurrentAlias("");
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  const handleRemoveLocationImage = useCallback(() => {

    //for temp uploaded image
    if(sitePicture){
      SET_LOCATION_SITE_PIC(null);
      if(imageUploadInputRef.current){
        imageUploadInputRef.current.value = '';
      }

      return;
    }

    //for uploaded image removal
    const locationDetails = { ...enteredLocationDetails };
    locationDetails.imageUrl = "";
    SET_ENTERED_LOCATION_DETAILS(locationDetails);
  }, [SET_ENTERED_LOCATION_DETAILS, SET_LOCATION_SITE_PIC, enteredLocationDetails, sitePicture]);

  useEffect(() => {
    if (enteredLocationDetails.tid) {
      setMarkerLocationExternally(Number(enteredLocationDetails.lat), Number(enteredLocationDetails.lon));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredLocationDetails.tid]);

  useEffect(()=>{
    if (!sitePicture) {
      setUploadedImagePreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(sitePicture);
    setUploadedImagePreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [sitePicture]);

  return (
    <LocationDetailsFormComponent
      enteredLocationDetails={ enteredLocationDetails }
      allCountries={ countries }
      locationTypes={ locationTypes }
      timeZones={ geoTimeZones }
      currentAlias={ currentAlias }
      currentSearchLocation={ currentSearchLocation }
      isAuthorizedToEdit={ isAuthorizedToEdit }
      locationDetailsForm={ locationDetailsForm }
      onChangeFormInputs={ handleChangeFormInputs }
      onChangeCountrySelect={ handleChangeCountries }
      onChangeTimeZone={ handleChangeTimeZone }
      onRemoveLocationImage={ handleRemoveLocationImage }
      onLatitudeChange={ handleLatitudeChange }
      onLongitudeChange={ handleLongitudeChange }
      onCriticalLocationChange={ handleCriticalLocationChange }
      onChangeCategories={ handleChangeCategories }
      onChangeLocationTypes={ handleChangeLocationTypes }
      onChangeSitePicUpload={ handleChangeSitePicUpload }
      onAddLocationAliasChange={ handleAddLocationAliasChange }
      onCurrentAliasChange={ handleCurrentAliasChange }
      onRemoveLocationAlias={ handleRemoveLocationAlias }
      uploadedImagePreview = { uploadedImagePreview }
      imageUploadInputRef={ imageUploadInputRef }
      sitePicture= { sitePicture }
    />
  );
};

export default LocationDetailsForm;
