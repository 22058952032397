import { FC } from "react";

import { useRiskLevelLegendStyles } from "@/tenant-context/core/controls/RiskLevelLegend/RiskLevelLegend.styles";

const RiskLevelLegendControl: FC = () => {
  const { classes, cx } = useRiskLevelLegendStyles();

  return (
    <div className={ classes.wrapper }>
      <div className={ classes.riskLevelItem }>
        <span className={ cx( classes.riskItemIndicator, classes.riskItemIndicatorExtreme ) }>5</span>
        <span className={ classes.riskItemLabel }>Extreme</span>
      </div>
      <div className={ classes.riskLevelItem }>
        <span className={ cx( classes.riskItemIndicator, classes.riskItemIndicatorHigh ) }>4</span>
        <span className={ classes.riskItemLabel }>High</span>
      </div>
      <div className={ classes.riskLevelItem }>
        <span className={ cx( classes.riskItemIndicator, classes.riskItemIndicatorMedium ) }>3</span>
        <span className={ classes.riskItemLabel }>Medium</span>
      </div>
      <div className={ classes.riskLevelItem }>
        <span className={ cx( classes.riskItemIndicator, classes.riskItemIndicatorModerate ) }>2</span>
        <span className={ classes.riskItemLabel }>Moderate</span>
      </div>
      <div className={ classes.riskLevelItem }>
        <span className={ cx( classes.riskItemIndicator, classes.riskItemIndicatorLow ) }>1</span>
        <span className={ classes.riskItemLabel }>Low</span>
      </div>
    </div>
  );
};

export default RiskLevelLegendControl;
