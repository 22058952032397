import { Box, Flex, Text } from '@mantine/core';
import { FC, Fragment, useCallback, useMemo } from "react";

import { ReactComponent as RiskAlertLevelIcon } from "@/common/icons/riskAlertLevelIcon.svg";
import { ReactComponent as RiskAlertLevelTriangle } from "@/common/icons/RiskProviders/risk-level-triangle.svg";
import theme from "@/core/styles/mantine/theme";
import { RiskCategories } from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertBasicData/RiskAlertBasicData.config";
import { DataMinrExternalData } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";
import { MaxSecurityExternalData } from '@/tenant-context/visualisation-risk-alerts/types/max-security';
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskConnectorExternalData } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts-generic';

import { useRiskAlertBasicDataStyles } from "./RiskAlertBasicData.styles";

type Props = {
    riskAlertData?: RiskAlertEvent<RiskConnectorExternalData>
    isOnSideDrawer?: boolean
}

export const RiskAlertBasicData: FC<Props> = ({
  riskAlertData,
  isOnSideDrawer
}) => {
  const riskSource = riskAlertData?.source || 'riskline';
  const getRiskLevelColor = useCallback(
    (value?: string) => {
      return  !value ? "" : theme.colors?.risk?.[parseInt(value)-1];
    },
    []
  );

  const getRiskLevelTextColor = useCallback(
    (value?: string) => {
      return  (value === '2' || value === '3') ? theme.colors?.black?.[0] : theme.colors?.white?.[0];
    },
    []
  );

  const getRiskCategoryIcon = useCallback((categoryId?:string) => {
    return !categoryId ? "" : RiskCategories.find((one) => one.categoryId === categoryId)?.Icon;
  },[]);

  const { classes } = useRiskAlertBasicDataStyles(
    {
      riskLevelTextColor: getRiskLevelTextColor(riskAlertData?.json.alert.risk_level.id),
      riskLevelIconColor: getRiskLevelColor(riskAlertData?.json.alert.risk_level.id),
      isOnTheRight: isOnSideDrawer
    }
  );

  const riskAlertLevelIcon = useMemo(() => {
    if (riskSource === 'max-security') {
      const riskCategoryIcon = getRiskCategoryIcon(riskAlertData?.json.alert.category.id);

      return <div className={ classes.riskLevelIconRoot }>
        <RiskAlertLevelTriangle className={ classes.riskLevelTriangle }/>
        <div className={ classes.riskCategoryIcon }>{ riskCategoryIcon }</div>
      </div>;
    }

    return <Fragment>
      <RiskAlertLevelIcon className={ classes.riskLevelIcon }/>
      { riskSource !== 'dataminr' && <div className={ classes.riskLevelIdText }>{ riskAlertData?.json.alert.risk_level.id } </div> }
    </Fragment>;
  }, [
    riskSource,
    classes.riskLevelIcon,
    classes.riskLevelIdText,
    classes.riskLevelIconRoot,
    classes.riskLevelTriangle,
    classes.riskCategoryIcon,
    riskAlertData?.json.alert.risk_level.id,
    riskAlertData?.json.alert.category.id,
    getRiskCategoryIcon
  ]);

  return(
    <div>
      <Flex p={ 'md' } className={ classes.riskAlertStatusContainer } pos={ 'relative' }>
        <Box className={ classes.riskAlertStatusBar }/>
        <Flex direction={ 'column' } className={ classes.riscAlertStatusInnerGrid }>
          <div className={ classes.riskCriticalityRow }>
            { riskAlertLevelIcon }
            <div className={ classes.riskLevelName }>
              { riskSource === 'dataminr'
                ? (riskAlertData?.json.externalData as DataMinrExternalData).alertType.name
                : riskAlertData?.json.alert.risk_level.name }
            </div>
          </div>


          { riskSource === 'dataminr' ? (
            <Flex direction={ 'column' } className={ classes.riskInfoRowDataminr }>
              { (riskAlertData?.json.externalData as DataMinrExternalData).categories.map((category, index) => (
                <span key={ index }>{ category.name }</span>
              )) }
            </Flex>
          ) : riskSource === 'max-security' ? (
            <div className={ classes.riskInfoRow }>
              { (riskAlertData?.json.externalData as MaxSecurityExternalData).incident_type }
            </div>
          ) :
            <div className={ classes.riskInfoRow }>
              <span>{ getRiskCategoryIcon(riskAlertData?.json.alert.category.id) }</span>
              { riskAlertData?.json?.alert?.category.name }
            </div> }
        </Flex>

      </Flex>
      <Flex mt={ 8 } align={ 'center' } justify={ isOnSideDrawer ? 'flex-start' : 'center' } className={ classes.risInfoEffectFrom }>
        <Text>Effective from  &nbsp;</Text>
        <Text fw={ 700 }>  { riskAlertData?.json.alert.start_date } </Text>
        &nbsp;
        until   &nbsp;
        <Text fw={ 700 }>{ riskAlertData?.json.alert.end_date } </Text>
      </Flex>
      { riskSource === 'dataminr' && <Flex mt={ 8 } align={ 'center' } justify={ isOnSideDrawer ? 'flex-start' : 'center' } className={ classes.risInfoEffectFrom }>
        <Text>From Watchlist</Text>
        <Text>
          { (riskAlertData?.json.externalData as DataMinrExternalData).watchlistsMatchedByType.map((item) => (
            <span key={ item.id } className={ classes.watchListTypeText }>{ item.name }</span>
          )) }
        </Text>
      </Flex> }
    </div>
  );
};

export default RiskAlertBasicData;


