export const CaseTypeRequiredPoliciesConfig = {
  platform: {
    CASE_TYPE_ROUTE_ACCESS: {
      policyNames: [
        'SystemAdmin_ICM-Global_CaseType_ReadOnly',
        'SystemAdmin_ICM-Global_CaseType_FullAccess'
      ]
    },
    EDIT_CASE_TYPE: {
      policyNames: [
        'SystemAdmin_ICM-Global_CaseType_FullAccess'
      ]
    }
  },
  tenant: {
    CASE_TYPE_ROUTE_ACCESS: {
      policyNames: [
        'Tenant_ICM_CaseType_ReadOnly',
        'Tenant_ICM_CaseType_FullAccess'
      ]
    },
    EDIT_CASE_TYPE: {
      policyNames: [
        'Tenant_ICM_CaseType_FullAccess'
      ]
    }
  }
};