import { EventData } from "@/tenant-context/common/types/event";
import { DataMinrExternalData } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";
import { MaxSecurityExternalData } from '@/tenant-context/visualisation-risk-alerts/types/max-security';
import { RiskAlertFeature } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskConnectorExternalData } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts-generic';

export interface GenericRiskAlertResponse<
  RiskProviderRequestType = RisklineV3ExternalData | BSOCExternalData | DataMinrExternalData | MaxSecurityExternalData
> {
  id: string;
  tenantId: string;
  calculation: Calculation;
  peopleCount: number;
  assetCount: number;
  impactStatus: 'OPEN' | 'CLOSED';
  notificationActions: NotificationAction[];
  updatedOn: number;
  request: GenericRiskAlertRequest<RiskProviderRequestType>;
  tid: string;
  meta: Meta;
}

export interface Country {
  iso_code: string;
  name: string;
}

export interface Geometry {
  lat: number;
  lon: number;
}

export interface Meta {
  tenantId: string;
  createdTime: number;
  updatedTime: number;
}

export interface RiskEventAssetLocation {
  code: string;
  name: string;
}

export interface Category {
  id: string;
  name: string;
}

export interface Alert {
  id: string;
  text: string;
  title: string;
  advice: string;
  regions: RiskEventAssetLocation[];
  airports: RiskEventAssetLocation[];
  category: Category;
  end_date: number;
  countries: Country[];
  is_notice: boolean;
  parent_id: string;
  risk_level: {
    id: string,
    name: string
  };
  start_date: number;
  is_breaking: boolean;
  location_explanation: string;
}

export interface RisklineMeta {
  geojson: RiskAlertFeature;
}

export interface RisklineV3ExternalData {
  meta: Meta;
  alert: Alert;
  risklineMeta: RisklineMeta;
}

export interface GenericRiskAlertRequest<RiskProviderType> {
  ext_id: string;
  tenantId: string;
  title: string;
  text: string;
  start_date: number;
  end_date: number;
  countries: Country[];
  source: 'bsoc' | 'riskline' | 'dataminr';
  eventType: string;
  severityType: string;
  severityLevel: string;
  geometry: Geometry;
  connectorSource: string;
  adapterSource: string;
  connectorEventId: string;
  externalData: RiskProviderType;
}

export interface BSOCExternalData extends RiskConnectorExternalData {
  critical_incident: boolean;
  data_entry_date: string;
  disruption_access_blocked: string;
  id: string;
  incident_count: string;
  incident_date: string;
  ivo_crit_inf_log: string;
  latitude: string;
  longitude: string;
  meta: Meta;
  narrative_report: string;
  position: string;
  protest_size: string;
  protest_themes: string;
  province: string;
  region: string;
  sigact: string;
  source_code: string;
  target_code: string;
  trend_code: string;
}

export interface Calculation {
  name: string;
  runImpactTime: string;
  calculationType: string;
  appliedTo: number;
}

export interface Point {
  lat: number;
  lon: number;
}

export interface Label {
  id: string;
  name: string;
  subCategory: string;
}

export interface Location {
  id: string;
  type: string;
  point: Point;
  isoCountryCode: string;
  labels: Label[];
}

export interface TopRank {
  currentRank: number;
  connectorEventId: string;
  connectorSource: string;
  adapterEventId: string;
  adapterSource: string;
  startTime: number;
  endTime: number;
  receivedTime: number;
  location: Location;
  isAffected: boolean;
}

export interface TrackingResponse {
  personId: string;
  personFirstName: string;
  personLastName: string;
  email: string;
  topRank: EventData;
  rankTime: number;
  otherRanks: EventData[];
}

export interface NotificationAction {
  messageType: string;
  commsType: string;
}

export enum Sigact {
  Crime = 'Crime',
  SAF = 'SAF',
  IED = 'IED',
  HighImpactExplosiveActivity = 'HighImpactExplosiveActivity',
  IDFUAVActivity = 'IDFUAVActivity',
  SecurityOperations = 'SecurityOperations',
  ProtestsAndGatherings = 'ProtestsAndGatherings',
  Other = 'Other'
}

export enum Crime {
  THEFT = 'THEFT',
  ARMEDROBBERY = 'ARMEDROBBERY',
  CARJACKING = 'CARJACKING',
  ASSAULT = 'ASSAULT',
  ARMEDASSAULT = 'ARMEDASSAULT',
  KIDNAP = 'KIDNAP',
  MURDER = 'MURDER',
  BODYFIND = 'BODYFIND',
  THREAT = 'THREAT',
  CRIMEOTHER = 'CRIMEOTHER',
  ARREST = 'ARREST',
  NONVIOLENTCRIME = 'NONVIOLENTCRIME',
  UPDATE = 'UPDATE'

}

export enum SAF {
  SAF = 'SAF',
  SAFTRIBAL = 'SAFTRIBAL',
  SAFPERSONALDISPUTE = 'SAFPERSONALDISPUTE',
  SAFINTIMIDATION = 'SAFINTIMIDATION',
  SAFRANDOM = 'SAFRANDOM',
  SAFOTHER = 'SAFOTHER',
}

export enum IED {
  IED = 'IED',
  IEDINTIMIDATION = 'IEDINTIMIDATION',
  IEDFIND = 'IEDFIND',
  GRENADE = 'GRENADE',
  GRENADEINTIMIDATION = 'GRENADEINTIMIDATION',
  GRENADEFIND = 'GRENADEFIND',
  RPG = 'RPG',
  EFP = 'EFP',
  EFPFIND = 'EFPFIND',
  UVIED = 'UVIED',
  UVIEDFIND = 'UVIEDFIND',
  UXO = 'UXO',
  UXOFIND = 'UXOFIND',
  IEDLEGACY = 'IEDLEGACY',
  IEDFINDFEGACY = 'IEDFINDLEGACY',
  MOLOTOV = 'MOLOTOV',
  ARSON = 'ARSON'

}

export enum HighImpactExplosiveActivity {
  SVEST = 'SVEST',
  SVESTFIND = 'SVESTFIND',
  VBIED = 'VBIED',
  VBIEDFIND = 'VBIEDFIND',
  SVBIED = 'SVBIED',
  SVBIEDFIND = 'SVBIEDFIND',
  COMPLEXATTACK = 'COMPLEXATTACK'

}

export enum IDFUAVActivity {
  ROCKETPOI = 'ROCKETPOI',
  ROCKETPOO = 'ROCKETPOO',
  MORTARPOI = 'MORTARPOI',
  MORTARPOO = 'MORTARPOO',
  IDFUNIDENTIFIED = 'IDFUNIDENTIFIED',
  DRONEFIND = 'DRONEFIND',
  IRLFIND = 'IRLFIND',
  MLRSFIND = 'MLRSFIND',
  WEAPONISEDDRONE = 'WEAPONISEDDRONE',
  IDFUAVACTIVITY = 'IDFUAVACTIVITY',
  SURVEILLANCEDRONE = 'SURVEILLANCEDRONE',
  MISSILE = 'MISSILE'

}

export enum SecurityOperations {
  SECURITYOPERATIONJOINT = 'SECURITYOPERATIONJOINT',
  SECURITYOPERATIONISF = 'SECURITYOPERATIONISF',
  SECURITYOPERATIONPMU = 'SECURITYOPERATIONPMU',
  SECURITYOPERATIONINTCOL = 'SECURITYOPERATIONINTCOL',
  SECURITYOPERATIONTURKISH = 'SECURITYOPERATIONTURKISH',
  SECURITYOPERATIONKURD = 'SECURITYOPERATIONKURD',
  CACHE = 'CACHE',
  AIRSTRIKE = 'AIRSTRIKE',
  COMBAT = 'COMBATANDBEDDOWN'

}

export enum ProtestsAndGatherings {
  DEMO = 'DEMO',
  PUBLICGATHERING = 'PUBLICGATHERING',
  RELIGIOUSGATHERING = 'RELIGIOUSGATHERING'
}

export enum BsocOther {
  OTHER = 'OTHER',
  IDFPOI = 'IDFPOI'
}
