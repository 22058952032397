import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const useEaArcNavigationStyles = createStyles((_theme) => ({
  tabsAndButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch'
  },

  tabs: {
    display: 'flex',
    alignItems: 'end'
  },

  buttons: {
    display: 'flex',
    gap: 24,
    marginRight: 24,
    alignItems: 'center'
  },

  divider: {
    height: 27,
    margin: 'auto'
  },
  
  item: {
    [`& .${CSS_KEY}-Tabs-tabLabel`]: {
      display: 'flex',
      alignItems: 'center',
      gap: 8
    }
  }
}));
