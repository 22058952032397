import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

import { genericColorPalette } from '@/core/styles/mantine/palettes';

export const useVisualizedGeofenceDrawerStyles = createStyles((theme: MantineTheme) => ({
  visualizedGeofenceDrawerContent: {
    display: 'grid',
    gap: 24,
    gridTemplateRows: '1fr auto',
    gridTemplateColumns: '100%',
    height: '100%',
    width: '100%'
  },

  data: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    width: '100%'
  },

  geofenceType: {
    textTransform: 'uppercase'
  },

  type: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.colors.neutral[5]
  },

  privacy: {
    fontWeight: 400,
    fontSize: 12
  },

  description: {
    padding: 8,
    background: genericColorPalette.neutral[9],
    border: `0.5px solid ${theme.colors.neutral[6]}`
  },

  heading: {
    marginBottom: 16
  },

  geofenceStats: {
    display: 'flex',
    flexDirection: 'column'
  },

  geofenceStatsCardContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  statsCard: {
    all: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    boxShadow: theme.other.sdw.left,
    borderBottom: `3px solid ${ theme.white }`,
    backgroundColor: theme.colors.neutral[9],
    cursor: 'pointer'
  },

  count: {
    fontSize: 22,
    fontWeight: theme.other.fw.bold,
    lineHeight: theme.other.lh.l,
    color: theme.white
  },

  text: {
    fontSize: 12,
    color: theme.colors.neutral[5],
    lineHeight: theme.other.lh.l
  },

  metaDataSection: {
    display: 'flex',
    flexDirection: 'column'
  },

  metadataContainer: {
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: '120px 1fr auto',
    width: '100%',
    padding: 16,
    gap: 24,
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    'div': {
      wordWrap: 'break-word'
    }
  },

  dataLabel: {
    color: genericColorPalette.neutral[6]
  },

  unauthorizedButton: {
    backgroundColor: `${theme.colors.neutral[3]} !important`,
    '*': {
      color: `${theme.colors.neutral[5]} !important`
    }
  }
}));
