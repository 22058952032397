import { createStyles } from "@mantine/core";

export const useProfileDocumentsTabStyles = createStyles(() => ({
  btn: {
    all: 'unset',
    backgroundColor: 'unset !important',
    padding: 0,
    width: 'fit-content',
    cursor: 'pointer'
  },
  unauthorizedDeleteBtn: {
    all: 'unset',
    backgroundColor: 'unset !important',
    opacity: 0.5,
    cursor: 'not-allowed',
    border: 'none !important'
  },
  trashIcon: {
    marginLeft: '8px'
  }
}));