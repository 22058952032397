import { IndoorMapGeoJSON } from "@f/map-gl-indoor";
import { Feature, Polygon } from '@turf/turf';
import { FC } from 'react';
import { Layer, Source } from 'react-map-gl';

type Props = {
  currentGeofence?: Feature<Polygon> | IndoorMapGeoJSON
  id?: string,
  layerColor: string
}

const LocationGeoFenceLayerComponent: FC<Props> = ({
  currentGeofence,
  id,
  layerColor
}) => {

  if (!currentGeofence) {
    return null;
  }

  return (
    <Source id={ id } type="geojson" data={ currentGeofence }>
      <Layer
        id={ `${id}-outline` }
        type="line"
        paint={ {
          "line-color": layerColor,
          "line-opacity": 1,
          "line-width": 2,
          "line-dasharray": [2, 2]
        } }
      />
      <Layer
        id={ `${id}-layer` }
        type="fill"
        paint={ {
          "fill-color": layerColor,
          "fill-opacity": 0.2
        } }
      />
    </Source>
  );
};

export default LocationGeoFenceLayerComponent;
