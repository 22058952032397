import { FC, useCallback } from "react";

import { GenericStringKeyObject } from "@/common/util/interface";
import Feature
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/Feature";
import {
  useRoleDetailsStyles
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/RoleDetails/RoleDetails.styles";
import { CaseFeature } from "@/platform-context/control-icm-management/types/CaseTypes/caseFeatures";

type Props = {
  formattedCaseFeatures: GenericStringKeyObject<CaseFeature[]>
  onFeatureChange: (feat: Record<string, unknown>) => void
  isAuthorizedToCreateOrEdit: boolean
}

const AccessPermissionsComponent: FC<Props> = ({
  formattedCaseFeatures,
  onFeatureChange,
  isAuthorizedToCreateOrEdit
}) => {

  const { classes } = useRoleDetailsStyles();

  const featuresRender = useCallback(()=> Object.entries(formattedCaseFeatures).map(([key, value]) =>
    (
      <div className={ classes.accessPermission } key={ key }>
        <h3>{ key }</h3>
        <div>
          { value.map((feature, index) => {
            return(
              <div key={ `${key}-feature-${index}` }>
                <Feature
                  disabled={ !isAuthorizedToCreateOrEdit }
                  onChange={ onFeatureChange }
                  feature={ feature }
                />
              </div>
            );
          }) }
        </div>
      </div>
    )), [classes.accessPermission, formattedCaseFeatures, isAuthorizedToCreateOrEdit, onFeatureChange]);
  
  return (
    <div className={ classes.accessPermissionsContainer }>
      { featuresRender() }
    </div>
  );
};

export default AccessPermissionsComponent;