import { TabsStylesParams } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

import { primitiveNumbers } from "../constants";


export const TabComponentStyle = {
  defaultProps: {
    variant: 'outline'
  },
  styles: (theme: MantineTheme, params: TabsStylesParams) => ({
    root: {
      width: '100%'
    },
    tabsList: {
      borderBottom: `0.5px solid ${theme.other.semantic.border.general.lighter}`
    },

    tab: {
      padding: params.variant === 'default'
        ? `0 ${theme.other.spacing.md}px`
        : `${theme.other.spacing.xs}px ${theme.other.spacing.md}px 0`,

      color: theme.other.semantic.text.primary,
      fontSize: theme.other.fs.sm,
      lineHeight: theme.other.lh.l,
      minHeight: primitiveNumbers['6x'],

      '&:not([data-active])': {
        color: theme.other.semantic.text.secondary,

        '&:not(:disabled)': {
          '&:hover': {
            backgroundColor: genericColorPalette.neutral[7]
          }
        }
      },

      '&[data-active]': {
        borderLeft: params.variant === 'default' ? 'none' : `1px solid ${theme.other.semantic.border.general.lighter}`,
        borderRight: params.variant === 'default' ? 'none' : `1px solid ${theme.other.semantic.border.general.lighter}`,
        boxShadow: params.variant === 'default' ? 'none' : `inset 0 2px 0 0 ${theme.other.semantic.accent[1]}`,
        borderTop: 'none',
        // borderTop: params.variant === 'default' ? 'none' : `2px solid ${theme.other.semantic.accent[1]}`,
        borderBottom: params.variant === 'default' ? `2px solid ${theme.other.semantic.accent[1]}` :'none',
        backgroundColor: params.variant === 'default' ? theme.other.semantic.surfaceBackground.none : theme.other.semantic.surfaceBackground.primary,
        marginBottom: -1, // This is to make the border overlap the tab below
        position: "relative !important" as React.CSSProperties["position"],
        '&::before': {
          display: "none"
        },
        "&::after":{
          content: '""',
          width: "100%",
          height: 1,
          background: theme.colors.neutral[9],
          bottom: 0,
          position: "absolute" as React.CSSProperties["position"]
        }
      },
      '&:disabled': {
        color: theme.other.semantic.text.disabled,
        backgroundColor: theme.other.semantic.surfaceBackground.none
      }
    },

    tabIcon: {
      marginRight: theme.other.spacing.md
    }
  })
};
