/* eslint-disable */
// @ts-nocheck
// Source - https://github.com/ludovicwyffels/GeoJSONParser

import type { Feature, FeatureCollection, GeoJSON, Geometry } from "geojson";

export class GeoJSONMapper {
  // version = "0.5.0";

  // Allow user to specify default parameters
  default: object = {
    doThrows: {
      invalidGeometry: false
    }
  };

  errors: object = {
    InvalidGeometryError: this.invalidGeometryError
  };

  private geomAttrs: Array<any> = [];

  private geoms = [
    "Point",
    "MultiPoint",
    "LineString",
    "MultiLineString",
    "Polygon",
    "MultiPolygon",
    "GeoJSON"
  ];

  // the user did not specify
  private static applyDefaults(params: GeoJSONMapperParams, defaults: Record<string, any>): object {
    const settings = params || {};

    Object.keys(settings).forEach((setting) => {
      if (defaults.hasOwnProperty(setting) && !settings[setting]) {
        settings[setting] = defaults[setting];
      }
    });

    return settings;
  }

  // if they have been specified
  private static addOptionals(geojson: Feature | FeatureCollection, settings: Record<string, any>) {
    // if (settings.crs && GeoJSONMapper.checkCRS(settings.crs)) {
    //   if (settings.isPostgres) {
    //     geojson.geometry.crs = settings.crs;
    //   } else {
    //     geojson.crs = settings.crs;
    //   }
    // }
    if (settings.bbox) {
      geojson.bbox = settings.bbox;
    }
    if (settings.extraGlobal) {
      (geojson as Feature).properties = {};
      Object.keys(settings.extraGlobal).forEach((key) => {
        if ((geojson as Feature).properties) {
          (geojson as any).properties[key] = settings.extraGlobal[key];
        }
      });
    }
  }

  // Verify that the structure of CRS object is valid
  private static checkCRS(crs: any): boolean {
    if (crs.type === "name") {
      if (crs.properties && crs.properties.name) {
        return true;
      } else {
        throw new Error('Invalid CRS. Properties must contain "name" key');
      }
    } else if (crs.type === "link") {
      if (crs.properties && crs.properties.href && crs.properties.type) {
        return true;
      } else {
        throw new Error(
          'Invalid CRS. Properties must contain "href" and "type" key'
        );
      }
    } else {
      throw new Error('Invalid CRS. Type attribute must be "name" or "link"');
    }
  }

  // Adds default settings to user-specified params
  // Does not overwrite any settings--only adds defaults

  private static isNested(val: any) {
    return /^.+\..+$/.test(val);
  }

  // Adds the optional GeoJSON properties crs and bbox

  invalidGeometryError(...arguments_arr: any[]): Error {
    const args = 1 <= arguments_arr.length ? [].slice.call(arguments_arr, 0) : [];
    const item = args.shift();
    const params = args.shift();

    throw Error(
      "Invalid Geometry: " +
      "item: " +
      JSON.stringify(item) +
      ", params: " +
      JSON.stringify(params)
    );
  }

  public isGeometryValid = (geometry: Geometry): boolean => {
    if (!geometry || !Object.keys(geometry).length) {
      return false;
    }

    return true;
  };

  // Moves the user-specified geometry parameters

  public parse(
    objects: [] | object,
    params: GeoJSONMapperParams,
    callback?: (geojson: GeoJSON) => void
  ): Feature | FeatureCollection {
    let geojson: GeoJSON;
    const settings = GeoJSONMapper.applyDefaults(
      params,
      this.default
    );
    const propFunc = this.getPropFunction(settings);

    this.geomAttrs.length = 0; // Reset the list of geometry fields
    this.setGeom(settings);

    if (Array.isArray(objects)) {
      geojson = {
        type: "FeatureCollection",
        features: []
      };

      // Custom cleaning & mapping
      objects = objects.map((item) => {
        // Cleaning out empty geoBoundaries
        const { geoBoundary } = item;
        if (geoBoundary && Array.isArray(geoBoundary) && geoBoundary.length === 0) {
          delete (item.geoBoundary);
        }

        return item;
      })

      objects.forEach((item) => {
        // For the locations that have both geoBoundary and geoPoint, parse the item twice by giving only one type
        if (item.geoBoundary && item.geoPoint) {
          (geojson as FeatureCollection).features.push(
            this.getFeature({
              item: item,
              params: {
                ...settings,
                geom: { Point: settings.geom['Point'] }
              },
              propFunc
            }),
            this.getFeature({
              item: item,
              params: {
                ...settings,
                geom: { Polygon: settings.geom['Polygon'] }
              },
              propFunc
            })
          );
        } else {
          (geojson as FeatureCollection).features.push(
            this.getFeature({
              item: item,
              params: settings,
              propFunc
            })
          );
        }
      });

      GeoJSONMapper.addOptionals(geojson, settings);
    } else {
      geojson = this.getFeature({
        item: objects,
        params: settings,
        propFunc: propFunc
      });
      GeoJSONMapper.addOptionals(geojson, settings);
    }

    if (callback && typeof callback === "function") {
      callback(geojson);
    } else {
      return geojson;
    }
  }

  // Add fields which contain geometry data
  // to geomAttrs. This list is used when adding
  // properties to the features so that no geometry

  // under the `geom` key in param for easier access
  private setGeom(params: GeoJSONMapperParams): void {
    params.geom = {};

    Object.keys(params).forEach((param) => {
      if (params.hasOwnProperty(param) && this.geoms.indexOf(param) !== -1) {
        params.geom[param] = params[param];
        delete params[param];
      }
    });

    this.setGeomAttrList(params.geom);
  }

  // Creates a feature object to be added

  // fields are added the properties key
  private setGeomAttrList(params: GeoJSONMapperParams): void {
    Object.keys(params).forEach((param) => {
      if (params.hasOwnProperty(param)) {
        if (typeof params[param] === "string") {
          this.geomAttrs.push(params[param]);
        } else if (typeof params[param] === "object") {
          // Array of coordinates for Point
          this.geomAttrs.push(params[param][0]);
          this.geomAttrs.push(params[param][1]);
        }
      }
    });

    if (this.geomAttrs.length === 0) {
      throw new Error("No geometry attributes specified");
    }
  }

  // to the GeoJSON features array
  private getFeature(
    args: {
      item: any,
      params: GeoJSONMapperParams,
      propFunc: (that: any) => Record<string, unknown>
    }
  ): Feature {
    const { item } = args,
      { params } = args,
      { propFunc } = args;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    const geometry = this.buildGeom(item, params);
    const properties = propFunc.call(item, that);

    return { type: 'Feature', geometry, properties };
  }

  // Assembles the `geometry` property

  // for the feature output
  private buildGeom(item: any, params: GeoJSONMapperParams): any {
    let itemClone;
    let paths;
    let geom: Record<string, any> = {};
    //   attr;

    // eslint-disable-next-line fp/no-loops
    for (const gtype in params.geom) {
      const val = params.geom[gtype];

      // Geometry parameter specified as: {Point: 'coords'}
      // eslint-disable-next-line no-prototype-builtins
      if (typeof val === "string" && item.hasOwnProperty(val)) {
        if (gtype === "GeoJSON") {
          geom = item[val];
        } else {
          geom["type"] = gtype;
          geom["coordinates"] = item[val];
        }
      } else if (typeof val === "object" && !Array.isArray(val)) {
        /* Handle things like:
          Polygon: {
            northeast: ['lat', 'lng'],
            southwest: ['lat', 'lng']
          }
          */
        /*jshint loopfunc: true */
        const points = Object.keys(val).map((key) => {
          const order = val[key];
          const newItem = item[key];
          return this.buildGeom(newItem, { geom: { Point: order } });
        });

        geom.type = gtype;
        /*jshint loopfunc: true */
        geom.coordinates = points.map((p) => {
          return p.coordinates;
        });
      } else if (
        // Geometry parameter specified as: {Point: ['lat', 'lng', 'alt']}
        Array.isArray(val) &&
        item.hasOwnProperty(val[0]) &&
        item.hasOwnProperty(val[1]) &&
        item.hasOwnProperty(val[2])
      ) {
        geom["type"] = gtype;
        geom["coordinates"] = [
          Number(item[val[1]]),
          Number(item[val[0]]),
          Number(item[val[2]])
        ];
      } else if (
        // Geometry parameter specified as: {Point: ['lat', 'lng']}
        Array.isArray(val) &&
        item.hasOwnProperty(val[0]) &&
        item.hasOwnProperty(val[1])
      ) {
        geom["type"] = gtype;
        geom["coordinates"] = [Number(item[val[1]]), Number(item[val[0]])];
      } else if (
        // Geometry parameter specified as: {Point: ['container.lat', 'container.lng', 'container.alt']}
        Array.isArray(val) &&
        GeoJSONMapper.isNested(val[0]) &&
        GeoJSONMapper.isNested(val[1]) &&
        GeoJSONMapper.isNested(val[2])
      ) {
        const coordinates = [];
        // eslint-disable-next-line fp/no-loops,fp/no-let
        for (let i = 0; i < val.length; i++) {
          paths = val[i].split(".");
          itemClone = item;
          // eslint-disable-next-line fp/no-loops
          for (let j = 0; j < paths.length; j++) {
            if (!itemClone.hasOwnProperty(paths[j])) {
              return false;
            }
            itemClone = itemClone[paths[j]]; // Iterate deeper into the object
          }
          coordinates[i] = itemClone;
        }
        geom["type"] = gtype;
        geom["coordinates"] = [
          Number(coordinates[1]),
          Number(coordinates[0]),
          Number(coordinates[2])
        ];
      } else if ( // Geometry parameter specified as: {Point: ['container.lat', 'container.lng']}
        Array.isArray(val) &&
        GeoJSONMapper.isNested(val[0]) &&
        GeoJSONMapper.isNested(val[1])
      ) {
        const coordinates = [];
        // eslint-disable-next-line fp/no-loops
        for (let i = 0; i < val.length; i++) {
          // i.e. 0 and 1
          paths = val[i].split(".");
          itemClone = item;
          // eslint-disable-next-line fp/no-loops
          for (let j = 0; j < paths.length; j++) {
            if (!itemClone.hasOwnProperty(paths[j])) {
              return false;
            }
            itemClone = itemClone[paths[j]]; // Iterate deeper into the object
          }
          coordinates[i] = itemClone;
        }
        geom["type"] = gtype;
        geom["coordinates"] = [Number(coordinates[1]), Number(coordinates[0])];
      } else if (
        // Geometry parameter specified as: {Point: [{coordinates: [lat, lng]}]}
        Array.isArray(val) &&
        val[0].constructor.name === "Object" &&
        Object.keys(val[0])[0] === "coordinates"
      ) {
        geom["type"] = gtype;
        geom["coordinates"] = [
          Number(item.coordinates[val[0].coordinates.indexOf("lng")]),
          Number(item.coordinates[val[0].coordinates.indexOf("lat")])
        ];
      }
    }

    if (
      params.doThrows &&
      params.doThrows.invalidGeometry &&
      !this.isGeometryValid(geom as Geometry)
    ) {
      throw this.invalidGeometryError(item, params);
    }

    return geom;
  }

  // Returns the function to be used to
  // build the properties object for each feature
  private getPropFunction(params: GeoJSONMapperParams) {
    let func: (properties: any, that: any) => void;

    if (!params.exclude && !params.include) {
      func = function (properties: any, that: any) {
        // eslint-disable-next-line fp/no-loops
        for (const attr in this) {
          if (
            this.hasOwnProperty(attr) &&
            that.geomAttrs.indexOf(attr) === -1
          ) {
            properties[attr] = this[attr];
          }
        }
      };
    } else if (params.include) {
      func = function (properties) {
        params.include.forEach(function (attr) {
          properties[attr] = this[attr];
        }, this);
      };
    } else if (params.exclude) {
      func = function (properties, that) {
        // eslint-disable-next-line fp/no-loops
        for (const attr in this) {
          if (
            this.hasOwnProperty(attr) &&
            that.geomAttrs.indexOf(attr) === -1 &&
            params.exclude.indexOf(attr) === -1
          ) {
            properties[attr] = this[attr];
          }
        }
      };
    }

    return function (that: any) {
      const properties = {};

      func.call(this, properties, that);

      if (params.extra) {
        that.addExtra(properties, params.extra);
      }

      return properties;
    };
  }

  // Adds data contained in the `extra`
  // parameter if it has been specified
  private addExtra(properties: Record<string, any>, extra: Record<string, any>) {
    Object.keys(extra).forEach((key) => {
      if (extra.hasOwnProperty(key)) {
        properties[key] = extra[key];
      }
    });

    return properties;
  }
}

export type GeoJSONMapperParams = {
  include?: Array<string>,
  exclude?: Array<string>,
  Point?: Array<string> | string,
  MultiPoint?: string,
  GeoJSON?: string,
  LineString?: string,
  MultiLineString?: string,
  Polygon?: string,
  MultiPolygon?: string,
  extra?: Record<string, any>
}

// TODO remove this and move everything to a worker
export default new GeoJSONMapper;
