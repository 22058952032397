import { FC, useCallback } from "react";
import { useMap } from "react-map-gl";
import { useDispatch } from "react-redux";

import ButtonControl from "@/common/components/ButtonControl";
import { ReactComponent as GlobeIcon } from "@/common/icons/globe.svg";
import { Dispatch } from "@/core/store";
import { INITIAL_MAP_VIEW } from "@/tenant-context/common/util/constants";
import {
  useNavigateToGlobalControlStyles
} from "@/tenant-context/core/controls/NavigateToGlobalControl/NavigateToGlobalControl.styles";
import { ALL_COUNTRIES } from "@/tenant-context/widget-overview/components/CountryInfoBox/CountryInfoBox.container";

const NavigateToGlobalControl: FC = () => {

  const { current: map } = useMap();
  const { classes } = useNavigateToGlobalControlStyles();

  const {
    commonData: {
      SET_SELECTED_COUNTRY
    }
  } = useDispatch<Dispatch>();

  const zoomBackToGlobal = useCallback(() => {
    map?.flyTo({
      center: {
        lng: INITIAL_MAP_VIEW.longitude,
        lat: INITIAL_MAP_VIEW.latitude
      },
      zoom: INITIAL_MAP_VIEW.zoom
    });

    SET_SELECTED_COUNTRY({
      id: ALL_COUNTRIES,
      name: 'All',
      geoPoint: {
        lat: 0,
        lon: 0
      },
      isoCountryCode: 'ALL',
      countryName: 'ALL',
      category: 'Regions',
      subCategory: 'Country'
    });
  }, [ SET_SELECTED_COUNTRY, map ]);

  return (
    <ButtonControl
      onClick={ zoomBackToGlobal }
      className={ classes.button }
      label='Global View'
      labelPosition="left"
    >
      <GlobeIcon />
    </ButtonControl>
  );
};

export default NavigateToGlobalControl;
