import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

export const useResponseMethodsStyles = createStyles((theme: MantineTheme) => ({
  radioGroup: {
    gap: '16px',
    marginTop: '7px',
    label: {
      fontSize: theme.fontSizes.md,
      color: theme.colors.neutral[4]
    },
    'input:checked': {
      backgroundColor: theme.colors.neutral[9],
      borderColor: theme.colors.neutral[9]
    },
    input: {
      backgroundColor: theme.colors.neutral[8],
      borderColor: theme.colors.neutral[5]
    },
    svg: {
      color: theme.other.semantic.accessibility.outer
    }
  },

  responseMethodItem: {
    display: 'flex',
    alignItems: 'center',

    '& > hr': {
      height: '1px',
      backgroundColor: theme.colors.neutral[6],
      border: 'none',
      flex: '.95'
    }
  },

  responseItem: {
    backgroundColor: theme.colors.neutral[8],
    borderRadius: 4,
    padding: '24px 0',
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',

    '& > *:not(:first-child)': {
      marginLeft: 32
    }
  },

  addNewResponseItem: {
    all: 'unset',
    height: 76,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 36,
    border: `1px dashed ${theme.colors.neutral[4]}`,
    borderRadius: 4,
    cursor: 'pointer',

    '& > button': {
      marginRight: 8
    }
  },

  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 78,

    '& > *:not(:first-child)': {
      marginLeft: 24
    }
  },

  customResponseItem: {
    '& > svg': {
      marginRight: 8
    }
  },

  responseItemIcon: {
    marginRight: 8
  },

  responseMethodModalSubtitle: {
    padding: '16px 0'
  }
}));
