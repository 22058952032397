import { useMantineTheme } from "@mantine/core";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import { FC, useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import geoJsonMapper from "@/tenant-context/common/util/geo-json-mapper";
import useCommonLocationIcons from "@/tenant-context/visualisation-common-locations/hooks/useCommonLocationIcons";

import { ITINERARY_TYPE_MAPPER } from "./constants";
import PeopleTravelLocationLayerComponent
  from "./PeopleTravelLocationLayer.component";

const PeopleTravelLocationLayer: FC = () => {

  const [travelGeoData, setTravelGeoData] = useState<FeatureCollection<Geometry, GeoJsonProperties>[]>();

  const userTravelItenerary = useSelector((state: RootState) => state.userProfile.userTravelItenerary);

  const isItineararyOnMap = useSelector((state: RootState) => state.userProfile.isTravelItineraryShownOnMap);

  const theme = useMantineTheme();

  useEffect(() => {
    (async () => {
      const geoArray = userTravelItenerary?.map((itenerary) => {
        const isActive = itenerary.start_date < Date.now() && itenerary.end_date > Date.now();


        const {
          end_date,
          origin_city_name,
          origin_iata_code,
          origin_lat,
          origin_lon,
          start_date,
          type,
          destination_city_name,
          destination_iata_code,
          destination_lat,
          destination_lon
        } = itenerary;

        const origin = {
          end_date,
          origin_city_name,
          origin_iata_code,
          lat: origin_lat,
          lon: origin_lon,
          start_date,
          icon: ITINERARY_TYPE_MAPPER[type],
          isActive,
          type
        };

        const desitination = {
          end_date,
          start_date,
          icon: ITINERARY_TYPE_MAPPER[type],
          destination_city_name,
          destination_iata_code,
          lat: destination_lat,
          lon: destination_lon,
          isActive
        };

        if (type === 'HOTEL') {
          return [origin];
        }

        return [origin, desitination];
      }) || [];

      const travelGeoData1 = await Promise.all(
        geoArray.map(async(geoData) => await geoJsonMapper.parse(
          geoData,
          {
            Point: [
              'lat',
              'lon'
            ]
          }
        ) as FeatureCollection<Geometry, GeoJsonProperties>)
      );

      setTravelGeoData(travelGeoData1);
    })();
  }, [userTravelItenerary, theme.colors.neutral]);

  const { current: map } = useMap();
  useCommonLocationIcons(map);

  return (
    <PeopleTravelLocationLayerComponent
      geoData={ travelGeoData }
      isItineararyOnMap={ isItineararyOnMap || false }
    />
  );
};

export default PeopleTravelLocationLayer;
