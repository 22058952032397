import { FC } from "react";

import { ReactComponent as PersonMarkerTooltip } from "@/common/icons/person-marker-tooltip.svg";
import { getPersonInitials } from "@/tenant-context/visualisation-people/util/getPersonInitials";

import { useIconTextMarkerStyles } from "./IconTextMarker.style";


type Props = {
    iconText: string | undefined
}

const IconTextMarker: FC<Props> = ({
  iconText
}) => {
  const { classes } = useIconTextMarkerStyles();

  return (
    <div className={ classes.iconContainer }>
      <PersonMarkerTooltip />
      { iconText ?
        <div className={ classes.iconText }>{ iconText?.split(" ").length >= 2 ? getPersonInitials(iconText) : iconText?.charAt(0) }</div> :
        null }

    </div>
  );
};

export default IconTextMarker;
