import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import usePermission from "@/common/hooks/usePermission";
import { Dispatch, RootState } from "@/core/store";
import { TravelContext } from "@/tenant-context/control-travel/context/Travel.context";
import { PeoplePolicies } from "@/tenant-context/visualisation-people/configs/People.policies";
import TravelSearchInfoBox from "@/tenant-context/widget-overview/components/TravelSearchInfoBox/TravelSearchInfoBox.component";

const TravelSearchInfoBoxContainer = () => {
  const {
    travel: {
      fetchTravelData
    }
  } = useDispatch<Dispatch>();

  const countryWiseTravellers = useSelector((state: RootState) => state.travellersSearch.countryWiseTravellers);
  const selectedCountry = useSelector((state: RootState) => state.travellersSearch.selectedCountry);

  const { activeDay, activeTravelEndPoint } = useContext(TravelContext);

  const isItineraryPermissionsAvailable = usePermission(PeoplePolicies.PEOPLE_RANKING_POLICY);

  useEffect(
    () => {
      if (selectedCountry && activeDay && activeTravelEndPoint && isItineraryPermissionsAvailable) {
        fetchTravelData({
          countryCode: selectedCountry.countryISOCode,
          travelEndpointType: activeTravelEndPoint,
          travelDay: activeDay
        });
      }
    }
    , [fetchTravelData, selectedCountry, activeDay, activeTravelEndPoint, isItineraryPermissionsAvailable]
  );

  const totalTravelPeopleCount = useMemo(
    () => {
      const duplicatedTravellers = countryWiseTravellers?.flatMap(traveller => traveller?.travellers );
      const uniqueTravellers = new Set();
      duplicatedTravellers?.forEach(traveller => uniqueTravellers.add(traveller?.profileId));
      return uniqueTravellers.size;
    }, 
    [countryWiseTravellers]
  );

  const [selectedCountryCount, setSelectedCountryCount] = useState<number>(totalTravelPeopleCount);

  useEffect(() => {
    if (selectedCountry && !(selectedCountry.countryISOCode === 'ALL')) {
      const countryData = countryWiseTravellers?.find((country) => country.countryISOCode
        === selectedCountry.countryISOCode);

      if (countryData) {
        setSelectedCountryCount(countryData.travellerCount);
      } else {
        setSelectedCountryCount(0);
      }
    } else {
      setSelectedCountryCount(totalTravelPeopleCount);
    }
  }, [ countryWiseTravellers, selectedCountry, totalTravelPeopleCount ]);

  const travellerCount = selectedCountryCount.toLocaleString('en-US');

  return (
    <TravelSearchInfoBox
      title="TRAVELLERS"
      travellerCount={ travellerCount }
    />
  );
};

export default TravelSearchInfoBoxContainer;
