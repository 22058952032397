import { createStyles } from "@mantine/core";

export const useProfileTabStyles = createStyles((_theme) => ({
  root: {
    padding: '32px 0'
  },

  tabList: {
    overflowX: 'auto'
  },

  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '32px',
    paddingBottom: '24px'
  }
}));
