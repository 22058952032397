import createServerSentEventStream, { SubscriptionEventHandler } from "@/common/util/sse";

import { FeedNotification } from "../types/live-feed";

export const createLiveFeedEventStream = (
  tenantId: string,
  handleEvent: SubscriptionEventHandler<FeedNotification>
) => createServerSentEventStream(
  `/feed-service/v1/stream/feed/live/notification/${ tenantId }`,
  handleEvent
);

export const createAllAvailableFeedNotificationsStream = (
  tenantId: string,
  handleEvent: SubscriptionEventHandler<FeedNotification>
) => createServerSentEventStream(
  `/feed-service/v1/stream/feed/all/notification/${ tenantId }`,
  handleEvent
);
