import { callApi } from "@/common/util/api";
import { Location } from "@/tenant-context/common/types/location";

export const getCustomerLocationsForBounds = (query: string) => callApi<Location[]>(
  'location-management-service/v1/categories/All/sub-categories/All/locations',
  {
    method: 'get',
    queryParams: {
      orderBy: 'name:ASC',
      query,
      mergeNonTenant: false
    }
  }
);
