import { Divider, Tabs, UnstyledButton } from "@mantine/core";
import { openModal } from "@mantine/modals";
import { FC, useCallback } from "react";

import PersonAvatar from "@/common/components/PersonAvatar";
import { ReactComponent as CloseCaseIcon } from '@/common/icons/close-case.svg';
import { ReactComponent as EvidenceIcon } from '@/common/icons/evidence.svg';
import { ReactComponent as ProfileIcon } from '@/common/icons/profile.svg';
import { ReactComponent as SummaryIcon } from '@/common/icons/summary.svg';
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import { MiniProfileDatails } from "@/tenant-context/control-profile/types/mini-profile";
import CloseCaseModal from "@/tenant-context/employee-app-action-response-center/components/modals/CloseCaseModal";
import { useEaArcNavigationStyles } from "@/tenant-context/employee-app-action-response-center/components/ui/EaArcNavigation/EaArcNavigation.style";
import { EaArcTab } from "@/tenant-context/employee-app-action-response-center/types/ea-arc";

interface EaArcNavigationProps {
  caseOwner?: MiniProfileDatails;
}

const EaArcNavigation: FC<EaArcNavigationProps> = ({ caseOwner }) => {
  const { classes } = useEaArcNavigationStyles();

  const handleCloseCaseClick = useCallback(() => {
    openModal({
      title: 'Close Case',
      children: <CloseCaseModal />,
      size: 'xl',
      closeOnClickOutside: false
    });
  }, []);

  const personName = caseOwner ? caseOwner.firstName + " " + caseOwner.lastName : "";

  return (
    <LocalErrorBoundary>
      <Tabs.List className={ classes.tabsAndButtons }>
        <div className={ classes.tabs }>
          <Tabs.Tab className={ classes.item } value={ EaArcTab.Summary }>
            <SummaryIcon /> Summary
          </Tabs.Tab>

          <Tabs.Tab className={ classes.item } value={ EaArcTab.Profile }>
            <ProfileIcon /> Profile
          </Tabs.Tab>

          <Tabs.Tab className={ classes.item } value={ EaArcTab.AlertInsight }>
            <EvidenceIcon /> Alert Insights
          </Tabs.Tab>
        </div>

        <div className={ classes.buttons }>
          <UnstyledButton onClick={ handleCloseCaseClick }>
            <CloseCaseIcon />
          </UnstyledButton>
          <Divider className={ classes.divider } orientation="vertical" />
          <PersonAvatar
            personName={ personName }
            borderRadius="100%"
            width="35px"
            height="35px"
            personAvatarUrl={ caseOwner?.profilePictureThumbnailUrl }
          />
        </div>
      </Tabs.List>
    </LocalErrorBoundary>
  );
};

export default EaArcNavigation;

