import { Button } from '@mantine/core';
import { FC } from "react";

import DrawerControl from "@/common/components/DrawerControl";
import usePermission from '@/common/hooks/usePermission';
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import CreateGeofenceDrawer from '@/tenant-context/control-draw/components/CreateGeofenceDrawer';
import { GeofenceRequiredPoliciesConfig } from '@/tenant-context/control-draw/config/GeofenceRequiredPolicies.config';
import { Geofence } from '@/tenant-context/control-draw/types/draw';
import { useVisualizedGeofenceDrawerStyles } from '@/tenant-context/visualisation-geofences/components/VisualizedGeofenceDrawer/VisualizedGeofenceDrawer.styles';

type Props = {
  selectedGeofence?: Geofence;
  onEditGeofenceClick: () => void;
  metaData?: {
    lastUpdatedDate: string;
    lastUpdatedTime: string;
    createdDate: string;
    createdTime: string;
  };
  inSideGeofenceData?: {
    locationCount: number;
    peopleLocationCount: number;
    siteLocationsCount: number;
  };
  onDrawerClose: () => void;
  goBackToGeofences: () => void;
};

const VisualizedGeofenceDrawerComponent: FC<Props> = ({
  selectedGeofence,
  onEditGeofenceClick,
  metaData,
  inSideGeofenceData,
  onDrawerClose,
  goBackToGeofences
}) => {
  const { classes } = useVisualizedGeofenceDrawerStyles();

  const isPermittedToEditGeofence = usePermission(
    GeofenceRequiredPoliciesConfig.LONE_WORKER_MANAGE_GEO_FENCE_FULL_ACCESS
  );
  const handleUnauthorizedAction = useUnauthorizedModal(
    'You do not have permission to access this feature',
    { customMessage: 'you do not have access to this feature, please contact your admin if you require this feature' }
  );

  return (
    <DrawerControl
      id={ 'visualized-geofence' }
      title={ selectedGeofence?.name }
      size={ 400 }
      onCloseClick={ onDrawerClose }
      isSubDrawer
      isCloseButtonHidden
      onBackButtonClick={ goBackToGeofences }
      footer={ <Button
        className={ isPermittedToEditGeofence ? undefined : classes.unauthorizedButton }
        onClick={ isPermittedToEditGeofence ? onEditGeofenceClick : handleUnauthorizedAction }
        fullWidth
      >
        Edit Geofence
      </Button> }
    >
      <div className={ classes.visualizedGeofenceDrawerContent }>
        <div className={ classes.data }>
          <div>
            <div className={ classes.geofenceType }>LONE WORKER - { selectedGeofence?.type }</div>
            <div className={ classes.privacy }>{ selectedGeofence?.enablePrivate ? 'Private' : 'Public' } Geofence</div>
          </div>

          <div className={ classes.description } hidden={ !selectedGeofence?.description }>
            { selectedGeofence?.description }
          </div>

          <div className={ classes.geofenceStats }>
            <h5 className={ classes.heading }>Inside geofence now</h5>
            <div className={ classes.geofenceStatsCardContainer }>
              <div className={ classes.statsCard }>
                <div className={ classes.count }>{ inSideGeofenceData?.peopleLocationCount }</div>
                <div className={ classes.text }>PEOPLE</div>
              </div>
              <div className={ classes.statsCard }>
                <div className={ classes.count }>{ inSideGeofenceData?.locationCount }</div>
                <div className={ classes.text }>LOCATIONS</div>
              </div>
              <div className={ classes.statsCard }>
                <div className={ classes.count }>{ inSideGeofenceData?.siteLocationsCount }</div>
                <div className={ classes.text }>SITES</div>
              </div>
            </div>
          </div>

          <div className={ classes.metaDataSection }>
            <h5 className={ classes.heading }>Created</h5>
            <div className={ classes.metadataContainer }>
              <div className={ classes.dataLabel }>BY</div>
              <div className={ classes.dataLabel }>DATE</div>
              <div className={ classes.dataLabel }>TIME</div>
              <div>{ selectedGeofence?.createdBy }</div>
              <div>{ metaData?.createdDate }</div>
              <div>{ metaData?.createdTime }</div>
            </div>
          </div>

          <div className={ classes.metaDataSection }>
            <h5 className={ classes.heading }>Last Modified</h5>
            <div className={ classes.metadataContainer }>
              <div className={ classes.dataLabel }>BY</div>
              <div className={ classes.dataLabel }>DATE</div>
              <div className={ classes.dataLabel }>TIME</div>
              <div>{ selectedGeofence?.lastUpdatedBy }</div>
              <div>{ metaData?.lastUpdatedDate }</div>
              <div>{ metaData?.lastUpdatedTime }</div>
            </div>
          </div>

        </div>

        <CreateGeofenceDrawer/>
      </div>
    </DrawerControl>
  );
};

export default VisualizedGeofenceDrawerComponent;
