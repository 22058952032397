/* eslint-disable import/extensions */
import { FC } from "react";
import { MapProvider as MapboxMapProvider } from "react-map-gl";

import TenantRefContext from "@/platform-context/core/components/TenantRefContextProvider";
import { AppInitContextProvider } from "@/tenant-context/core/context/AppInit.context";
import { BigMapProvider } from "@/tenant-context/core/context/BigMap.context";


const TenantContext: FC = ({ children }) => {
  return (
    <MapboxMapProvider>
      <AppInitContextProvider>
        <BigMapProvider>
          <TenantRefContext>
            { children }
          </TenantRefContext>
        </BigMapProvider>
      </AppInitContextProvider>
    </MapboxMapProvider>
  );
};

export default TenantContext;
