/* eslint-disable react/jsx-props-no-spreading */
import { Select, Textarea, TextInput } from "@mantine/core";
import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { RegisterOptions } from "react-hook-form";
import { useSelector } from "react-redux";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import ModalControlGroup from "@/common/components/ModalControlGroup";
import { RootState } from "@/core/store";
import { unshiftDefaultItem } from '@/tenant-context/common/util/profile-reference-data';
import {
  CompanyDetailsModalContext
} from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/CompanyDetailsModal/context/CompanyDetailsModal.context";
import {
  useEmergencyContactModalContentStyles
} from "@/tenant-context/control-profile/components/ui/EmergencyContactModalContent/EmergencyContactModalContent.style";
import ProfileLoadingOverlay from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay';
import { ProfileCompany } from "@/tenant-context/control-profile/types/profile";

export type CompanyDetailsModalFormData = Pick<ProfileCompany,
  | 'companyRef'
  | 'employeeId'
  | 'role'
  | 'department'
  | 'departmentHead'
  | 'lineManagerId'
  | 'personType'> & {
  dateOfBirth: Date
};

const CompanyDetailsModal: FC = () => {
  const { classes } = useEmergencyContactModalContentStyles();
  const {
    onSubmit,
    formControls: { register, setValue, formState: { errors, isDirty,isValid } }
  } = useContext(CompanyDetailsModalContext);

  const companyDetails = useSelector((state: RootState) => state.profile.companyDetails);
  const referenceData = useSelector((state: RootState) => state.profile.referenceData);
  const companiesList = useSelector((state: RootState) => state.profile.companies?.items);

  const personType = useMemo(() => {
    return unshiftDefaultItem(referenceData?.personType?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    }));
  }, [referenceData]);

  const employeeType = useMemo(() => {
    return unshiftDefaultItem(referenceData?.employeeType?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    }));
  }, [referenceData]);

  const companies = useMemo(() => {
    return unshiftDefaultItem(companiesList?.map((company) => {
      return { value: company?._id, label: company.name };
    }));
  }, [companiesList]);

  const handlePersonTypeChange = useCallback((val) => {
    setValue('personType', val, { shouldDirty: true });
  }, [setValue]);

  const handleEmployeeTypeChange = useCallback((val) => {
    setValue('employeeType', val, { shouldDirty: true });
  }, [setValue]);

  const handleCompanyChange = useCallback((val) => {
    setValue('companyRef', val, { shouldDirty: true });
  }, [setValue]);

  useEffect(() => {
    if (!companyDetails?.companyRef) {
      return;
    }

    setValue('companyRef', companyDetails?.companyRef);
  }, [companyDetails?.companyRef, register, setValue]);

  if (typeof companyDetails === 'undefined') {
    return (
      <p>Loading...</p>
    );
  }

  const renderTextInput = (
    label: string,
    id: keyof ProfileCompany,
    options: RegisterOptions
  ) => (
    <TextInput
      label={ label }
      defaultValue={ companyDetails?.[id]?.toString() }
      error={ errors?.[id]?.message }
      { ...register(id, addErrorMessages(label, options)) }
    />
  );

  return (
    <form onSubmit={ onSubmit }>

      <ProfileLoadingOverlay/>
      <div className={ classes.grid }>

        <Select
          { ...register('companyRef', addErrorMessages('Company', {
            required: false
          })) }
          searchable
          onChange={ handleCompanyChange }
          data={ companies ? companies : [] }
          label="Company"
          defaultValue={ companyDetails?.companyRef }
          error={ errors?.companyRef?.message }
        />

        { renderTextInput('Employee ID', 'employeeId', {
          required: false,
          maxLength: 128
        }) }

        { renderTextInput('Job Title', 'jobTitle', {
          required: false,
          maxLength: 128
        }) }

        { renderTextInput('Department', 'department', {
          required: false,
          maxLength: 128
        }) }

        { renderTextInput('Department Head', 'departmentHead', {
          maxLength: 128
        }) }

        { renderTextInput('Manager', 'lineManagerName', {
          required: false,
          maxLength: 128
        }) }

        <Select
          { ...register('personType') }
          searchable
          onChange={ handlePersonTypeChange }
          data={ personType ? personType : [] }
          label="Person Type"
          defaultValue={ companyDetails?.personType }
          error={ errors?.personType?.message }
        />

        <Select
          { ...register('employeeType') }
          searchable
          onChange={ handleEmployeeTypeChange }
          data={ employeeType ? employeeType : [] }
          label="Employee Type"
          defaultValue={ companyDetails?.employeeType }
          error={ errors?.employeeType?.message }
        />

        <Textarea
          label={ 'Comment' }
          defaultValue={ companyDetails?.comment }
          error={ errors?.comment?.message }
          minRows={ 4 }
          { ...register(
            'comment',
            addErrorMessages('Comment', { maxLength: 500 })
          ) }
        />
      </div>

      <ModalControlGroup
        primaryButtonDisabled={ !isValid || !isDirty }
      />
    </form>
  );
};

export default CompanyDetailsModal;
