import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import usePermission from "@/common/hooks/usePermission";
import { Dispatch, RootState } from "@/core/store";
import ProfileAccessCardSearch
  from "@/tenant-context/control-profile/components/ui/ProfileAccessCardSearch/ProfileAccessCardSearch.component";
import { ProfileRequiredPoliciesConfig } from "@/tenant-context/control-profile/config/ProfileRequiredPolicies.config";
import { AccessCard } from "@/tenant-context/control-profile/types/profile";
import {
  TenantAccessCardRequiredPoliciesConfig
} from "@/tenant-context/control-tenant-admin/config/TenantAccessCardRequiredPolicies.config";


const ProfileAccessCardSearchContainer = () => {

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const accessCardListSearchResults = useSelector((state: RootState) => state.profile.accessCardListSearchResults);
  const accessCardListSearchResultsLoading = useSelector(
    (state: RootState) => state.profile.accessCardListSearchResultsLoading
  );
  const accessCardListActiveResults = useSelector((state: RootState) => state.profile.accessCardListActiveResults);
  const {
    profile: {
      assignAccessCardToProfile,
      getAccessCardListWithProfileAssigment,
      SET_ACCESS_CARD_LIST_SEARCH_RESULT,
      unAssignAccessCardToProfile
    }
  } = useDispatch<Dispatch>();

  const searchFullAccessPermissions = usePermission(ProfileRequiredPoliciesConfig.IDENTIFICATION_FULL_ACCESS);

  const hasAccessCardsSubscription = usePermission(TenantAccessCardRequiredPoliciesConfig.EDIT_TENANT_ACCESS_CARD);

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
  }, [setSearchValue]);

  const handleSearchResetButton = useCallback(() => {
    setSearchValue(undefined);
    SET_ACCESS_CARD_LIST_SEARCH_RESULT(undefined);
  }, [SET_ACCESS_CARD_LIST_SEARCH_RESULT]);

  const handleSearchSubmitButton = useCallback(() => {
    if (searchValue === undefined) {
      return;
    }
    getAccessCardListWithProfileAssigment({
      gridParams: {
        page: 0,
        size: 1000,
        searchQuery: searchValue
      }
    }, true);
  }, [getAccessCardListWithProfileAssigment, searchValue]);

  const handleAssignCard = useCallback(async (accessCard: AccessCard) => {
    await assignAccessCardToProfile({
      tid: accessCard?._id
    });

    handleSearchResetButton();
  }, [assignAccessCardToProfile, handleSearchResetButton]);

  const handleReAssignCard = useCallback(async (accessCard: AccessCard) => {
    await unAssignAccessCardToProfile({
      tid: accessCard?._id
    }, accessCard?.assignedProfileTid);
    await assignAccessCardToProfile({
      tid: accessCard?._id
    });

    handleSearchResetButton();
  }, [assignAccessCardToProfile, handleSearchResetButton, unAssignAccessCardToProfile]);

  const handleAssignAccessCard = useCallback((accessCard: AccessCard) => {

    const assignedToTeSameProvider = accessCardListActiveResults?.find(
      (item) => item.provider === accessCard?.provider && item._id !== accessCard?._id
    );

    if (assignedToTeSameProvider) {
      openConfirmationModal({
        text: 'This user is already assigned an access card from the same Provider or its associates',
        onConfirm: () => handleAssignCard(accessCard ?? {}),
        title: 'Confirm Assignment'
      });

      return;
    }
    if (accessCard?.assignedToProfile) {
      openConfirmationModal({
        text: `This Access Card is already assigned to ${accessCard?.assignedProfileName ?? 'another person'}. Do you want to re-assign to this person?`,
        onConfirm: () => handleReAssignCard(accessCard ?? {}),
        title: "Confirm Assignment"
      });
    } else {
      openConfirmationModal({
        text: "Are you sure you want to assign this Access Card?",
        onConfirm: () => handleAssignCard(accessCard ?? {}),
        title: "Confirm Assignment"
      });
    }
  }, [accessCardListActiveResults, handleAssignCard, handleReAssignCard]);

  useEffect(() => {
    handleSearchSubmitButton();
    return () => {
      handleSearchResetButton();
    };
  }, [handleSearchResetButton, handleSearchSubmitButton, searchValue]);

  return (
    <ProfileAccessCardSearch
      onSelect={ handleAssignAccessCard }
      searchResults={ accessCardListSearchResults }
      searchValue={ searchValue }
      onSearchChange={ handleSearchChange }
      handleSearchResetButton={ handleSearchResetButton }
      isAccessCardListSearchResultsLoading={ accessCardListSearchResultsLoading }
      isDisabled={ !searchFullAccessPermissions || !hasAccessCardsSubscription }
    />
  );
};

export default ProfileAccessCardSearchContainer;
