import { Box, Button, Flex, Menu, Text, UnstyledButton } from '@mantine/core';
import React, { FC, ReactNode, useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";

import { ReactComponent as ExpandIcon } from '@/common/icons/add.svg';
import { ReactComponent as ShrinkIcon } from '@/common/icons/sub.svg';
import { TestableComponent } from "@/common/types/testable-component";
import { convertToNavFormat } from '@/common/util/get-testid';
import { useSideNavItemStyles } from '@/core/navigation/components/SideNavItem/SideNavItem.style';
import { NavigationSidebarContext } from '@/core/navigation/context/NavigationSidebar.context';
import { NavigationSidebarItem } from '@/core/navigation/types/navigation';
import { NavIconProps, NavigationItemId, ProductArea } from '@/core/navigation/types/navigation';
import { redirectWithinRestrataProductEcosystem } from "@/core/navigation/util/navigation";
import { Dispatch } from '@/core/store';

type Props = {
  isRedirectOnClick?: boolean;
  onClick?: () => void;
  openedMenuItem?: string;

  item?: NavigationSidebarItem;
  Icon?: React.ComponentType<NavIconProps>;
  title?: ReactNode;

  isDisabled?: boolean;
} & TestableComponent;

const SideNavItem: FC<Props> = ({
  title: manualTitle,
  isRedirectOnClick = true,
  item,
  isDisabled: forcedDisabled,
  dataTestId = 'navigation-item'
}) => {
  const {
    area,
    title: itemTitle,
    isNotReady,
    policies: itemPolicies
  } = item || {};

  const navigate = useNavigate();

  const title = manualTitle || itemTitle;

  const {
    isExpanded,
    setIsExpanded,
    usersPolicies,
    selectedNavigationItem,
    setSelectedNavigationItem,
    selectedSubNavigationItem,
    setSelectedSubNavigationItem,
    openedMenu,
    setOpenedMenu
  } = useContext(NavigationSidebarContext);

  const {
    commonData: {
      startLoading,
      loadingComplete
    }
  } = useDispatch<Dispatch>();

  const isDisabled = useMemo(() => {
    if (forcedDisabled || isNotReady) {
      return true;
    }

    const isPolicyExist = usersPolicies?.items?.some((policy) => itemPolicies?.includes(policy.policyName));

    return !!(itemPolicies && !isPolicyExist);
  }, [forcedDisabled, isNotReady, itemPolicies, usersPolicies]);

  const isAlwaysSubmenusOnContextMenu = useMemo(() => {
    return item?.itemId === NavigationItemId.Settings;
  }, [item]);

  const isSubMenuItemRestricted = useCallback((subMenuItem: NavigationSidebarItem): boolean => {
    if (!subMenuItem.policies || !subMenuItem.policies.length || !usersPolicies) {
      return false;
    }
    const isAllowedByPolicy = subMenuItem.policies.some(
      (policy) => usersPolicies.items.some(
        (userPolicy) => userPolicy.policyName === policy
      )
    );

    return !isAllowedByPolicy;
  }, [usersPolicies]);

  const handleRedirectClick = useCallback(() => {
    if (!area) {
      return;
    }

    if (isRedirectOnClick && item) {
      startLoading();
      redirectWithinRestrataProductEcosystem(item, navigate)
        .then(() => {
          if (area === ProductArea.Global) {
            if (setSelectedNavigationItem) {
              setSelectedNavigationItem(item.itemId);
            }
            if (setOpenedMenu) {
              setOpenedMenu(item.itemId);
            }
            if (setSelectedSubNavigationItem) {
              setSelectedSubNavigationItem(undefined);
            }
          }
        })
        .finally(() => loadingComplete());
      setIsExpanded(false);

    }
  }, [area, isRedirectOnClick, item, loadingComplete, navigate, setIsExpanded,
    setOpenedMenu, setSelectedNavigationItem, setSelectedSubNavigationItem, startLoading]);

  const handleSubMenuRedirect = useCallback((subItem: NavigationSidebarItem) => {
    return () => {
      startLoading();
      redirectWithinRestrataProductEcosystem(subItem, navigate)
        .then(() => {
          if (item && subItem.area === ProductArea.Global) {
            if (setSelectedNavigationItem) {
              setSelectedNavigationItem(item.itemId);
            }
            if (setOpenedMenu) {
              setOpenedMenu(item.itemId);
            }
            if (setSelectedSubNavigationItem) {
              setSelectedSubNavigationItem(subItem.itemId);
            }
          }
        })
        .finally(() => loadingComplete());
      setIsExpanded(false);
    };
  }, [item, loadingComplete, navigate, setIsExpanded, setOpenedMenu, setSelectedNavigationItem,
    setSelectedSubNavigationItem, startLoading]);

  const handleMenuSelect = useCallback(() => {
    if (isAlwaysSubmenusOnContextMenu) {
      return;
    }

    if (item?.type === 'menu') {
      if (!isExpanded) {
        setIsExpanded(true);
      }
      if (setOpenedMenu) {
        setOpenedMenu((openedMenu === item.itemId && isExpanded) ? NavigationItemId.None : item.itemId);
      }

      return;
    }

    if (isRedirectOnClick) {
      handleRedirectClick();
    }

    handleRedirectClick();
  }, [
    handleRedirectClick,
    isAlwaysSubmenusOnContextMenu,
    isExpanded,
    isRedirectOnClick,
    item?.itemId,
    item?.type,
    openedMenu,
    setIsExpanded,
    setOpenedMenu
  ]);

  const countOfAllSubMenuItems = useMemo(() => {
    return item?.subMenuItems?.reduce((acc, subItem) => acc + subItem.subItems.length, 0) || 0;
  }, [item]);

  const { classes, cx } = useSideNavItemStyles({
    isExpanded,
    subMenuCount: countOfAllSubMenuItems,
    sectionCount: item?.subMenuItems?.length || 0
  });

  if (item) {
    return (
      <Menu
        shadow="md"
        position="right-start"
        trigger="hover"
        transition={ 'fade' }
      >
        <Menu.Target>
          <Flex direction="column" className={ classes.menuItemContainer }>
            { (item?.type !== 'menu') && !isExpanded && <Box className="tooltip">
              <Box className={ classes.tooltipArrow }/>
              <Text>{ title }</Text>
            </Box> }
            <UnstyledButton
              className={ cx({
                [classes.menuItem]: true,
                [classes.menuItemActive]: (selectedNavigationItem === item?.itemId) ||
                  (openedMenu === item?.itemId && isExpanded)
              }) }
              onClick={ handleMenuSelect }
              disabled={ isDisabled }
            >
              <Flex
                align="center"
                className={ classes.menuItemContent }
              >
                { item?.icon }
                <Flex align="center" justify="space-between" className={ classes.menuNameSection }>
                  <Text className={ classes.menuTitle }>{ title }</Text>
                  { (item?.type === 'menu' && !isAlwaysSubmenusOnContextMenu) &&
                    ( openedMenu === item?.itemId ?
                      <ShrinkIcon width={ 12 } height={ 12 } className={ classes.menuExpandIcon }/> :
                      <ExpandIcon width={ 12 } height={ 12 } className={ classes.menuExpandIcon }/> ) }
                </Flex>
              </Flex>
            </UnstyledButton>
            { (item?.type === 'menu') && !isAlwaysSubmenusOnContextMenu && <Box className={ cx({
              [classes.subMenuPanel]: true,
              [classes.subMenuPanelOpened]: isExpanded && openedMenu === item.itemId
            }) }>
              { (!isAlwaysSubmenusOnContextMenu && item?.type === 'menu') && item?.subMenuItems?.map((mainItem, index) => {
                return (
                  <Flex direction="column" key={ index } className={ classes.subMenuSection }>
                    { item.subMenuItems?.length && (item.subMenuItems?.length > 1) && (
                      <Text className={ classes.subMenuSectionHeader }>{ mainItem.sectionHeader }</Text>
                    ) }

                    <Flex direction="column" className={ classes.subMenuItems }>
                      { mainItem.subItems.map((subItem, nestedIndex) => {
                        const isItemDisabled = isSubMenuItemRestricted(subItem);

                        return (
                          <Button
                            variant="subtle"
                            key={ nestedIndex.toString() + '_' + item.itemId } className={ cx({
                              [classes.subMenuItemDisabled]: isItemDisabled,
                              [classes.subMenuItemSelected]: selectedSubNavigationItem === subItem.itemId,
                              [classes.subMenuItem]: true
                            }) }
                            onClick={ handleSubMenuRedirect(subItem) }
                          >
                            { subItem.title }
                          </Button>
                        );
                      }) }
                    </Flex>
                  </Flex>
                );
              }) }
            </Box> }
          </Flex>
        </Menu.Target>
        { ((item?.type === 'menu' && !isExpanded) || isAlwaysSubmenusOnContextMenu) && <Menu.Dropdown className={ classes.menuDropdown }>
          <Flex direction="column" gap={ 12 }>
            { item?.type === 'menu' && item?.subMenuItems?.map((mainItem, index) => {
              return (
                <Flex direction="column" gap={ 4 } key={ index }>
                  <Menu.Label>{ mainItem.sectionHeader }</Menu.Label>
                  <Flex direction="column" gap={ 6 }>
                    { mainItem.subItems.map((subItem) => {
                      const isItemDisabled = isSubMenuItemRestricted(subItem);
                      const isSubItemActive = selectedSubNavigationItem === subItem.itemId;

                      return (
                        <Box key={ subItem.itemId } className={ cx({
                          [classes.subMenuDisabled]: isItemDisabled
                        }) }>
                          <Menu.Item
                            className={ cx({
                              [classes.subMenuActive]: isSubItemActive
                            }) }
                            data-testid={ convertToNavFormat(dataTestId, item.title, subItem.title) }
                            disabled={ isItemDisabled }
                            onClick={ handleSubMenuRedirect(subItem) }>
                            { subItem.title }
                          </Menu.Item>
                        </Box>
                      );
                    }) }
                  </Flex>
                </Flex>
              );
            }) }
          </Flex>
        </Menu.Dropdown> }
      </Menu>
    );
  }

  return null;
};

export default SideNavItem;
