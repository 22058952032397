import { callApi, TokenType } from "@/common/util/api";
import { CONTEXT_TYPES } from "@/core/store/shared-data/sharedData.types";
import {
  ActiveSeverity,
  CreateSeverityType,
  PaginatedResult,
  Severity,
  SortData
} from "@/platform-context/control-icm-management/types/severityTypes";

export const createSeverity = (severity: CreateSeverityType) => callApi<ActiveSeverity>(
  `/managed/icm-service/v1/severities`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;domain-model=CreateSeverity'
    },
    body: JSON.stringify(severity),
    withTenantId: false
  }
);

export const updateSeverity = (severity: CreateSeverityType, id: string) => callApi<ActiveSeverity>(
  `/managed/icm-service/v1/severities/${id}`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;domain-model=UpdateSeverity'
    },
    body: JSON.stringify(severity),
    withTenantId: false
  }
);

export const getSeverity = (id: string, type?: string) => callApi<ActiveSeverity>(
  `/managed/icm-service/v1/severities/${id}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;domain-model=GetSeverity'
    },
    tokenType:type === CONTEXT_TYPES.tenant ? TokenType.TENANT : TokenType.ACCESS ,
    withTenantId: false
  }
);

export const deleteSeverity = (id: string) => callApi(
  `/managed/icm-service/v1/severities/${id}`,
  {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json;domain-model=DeleteSeverity'
    },
    withTenantId: false
  }
);

export const getSeveritiesList = (
  page = 0,
  size = 10,
  sort?: SortData,
  searchQuery = ''
) => callApi<PaginatedResult<Severity>>(
  `/managed/icm-service/v1/severities?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}page=${page}&size=${size}${searchQuery ? `&search=%28name%20LIKE%20%27%25${searchQuery}%25%27%20OR%20description%20LIKE%20%27%25${searchQuery}%25%27%29` : ''}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;domain-model=ListSeverity'
    },
    withTenantId: false
  }
);
