import { Button } from "@mantine/core";
import { openModal } from "@mantine/modals";
import { FC, ReactNode, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import usePermission from "@/common/hooks/usePermission";
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { ReactComponent as TrashIcon } from '@/common/icons/trash.svg';
import { DataGrid } from "@/common/types/ag-grid";
import { Dispatch, RootState } from "@/core/store";
import ProfilePeopleGroupsModal from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal";
import ProfileGroupsTab from "@/tenant-context/control-profile/components/tabs/ProfileGroupsTab/ProfileGroupsTab.component";
import { columnDefs } from "@/tenant-context/control-profile/components/tabs/ProfileGroupsTab/ProfileGroupsTab.config";
import {
  useProfileGroupsTabStyles
} from "@/tenant-context/control-profile/components/tabs/ProfileGroupsTab/ProfileGroupsTab.style";
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';
const ProfileGroupsTabContainer: FC = () => {
  const {
    profile: {
      loadGroupsGrid
    },
    groups: {
      removeProfileFromGroup,
      SET_IS_PEOPLE_GROUP_UPDATED
    }
  } = useDispatch<Dispatch>();

  const ref = useRef<DataGrid>(null);
  const isAuthorizedToEdit = usePermission( ProfileRequiredPoliciesConfig.GROUPS_FULL_ACCESS );
  const { classes } = useProfileGroupsTabStyles();

  const isPeopleGroupUpdated = useSelector((state: RootState) => state.groups?.isPeopleGroupUpdated);

  const handleUnauthorizedAction = useUnauthorizedModal();

  const handleRemoveFromGroupClick = useCallback((documentId: string) => {
    removeProfileFromGroup(documentId).then(() => ref.current?.refreshGrid());
  }, [removeProfileFromGroup]);

  const confirmDelete = useCallback((documentId: string) => {
    openConfirmationModal({
      text: "Are you sure you want to remove the profile from this group?",
      onConfirm: () => handleRemoveFromGroupClick(documentId),
      title: "Confirm Removal"
    });
  }, [handleRemoveFromGroupClick]);
  
  const handleAddClick = useCallback(() => {
    openModal({
      title: 'Add to Group',
      children: (
        <ProfilePeopleGroupsModal />
      ),
      closeOnClickOutside: false
    });
  }, []);

  const actionCellRenderer = useCallback((groupId): ReactNode => {
    return (
      <Button
        /* eslint-disable-next-line react/jsx-no-bind */
        onClick={ isAuthorizedToEdit ? () => confirmDelete(groupId) : handleUnauthorizedAction }
        className={ isAuthorizedToEdit ? classes.btn : classes.unauthorizedDeleteBtn }
        styles={ {
          label: {
            float: 'right'
          }
        } }
      >
        Remove Person
        <TrashIcon className={ classes.trashIcon }/>
      </Button>
    );
  },[classes, confirmDelete, handleUnauthorizedAction, isAuthorizedToEdit]);

  useEffect(() => {
    if(isPeopleGroupUpdated) {
      ref.current?.refreshGrid();
      SET_IS_PEOPLE_GROUP_UPDATED(false);
    }
  }, [SET_IS_PEOPLE_GROUP_UPDATED, isPeopleGroupUpdated]);

  return (
    <ProfileGroupsTab
      onAddClick={ handleAddClick }
      onUnauthorizedAction={ handleUnauthorizedAction }
      requiredPolicies={ ProfileRequiredPoliciesConfig.GROUPS_FULL_ACCESS }
      ref={ ref }
      getData={ loadGroupsGrid }
      columnDefs={ columnDefs(actionCellRenderer) }
    />
  );
};

export default ProfileGroupsTabContainer;
