import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { ReactComponent as SiteMarker } from "@/common/icons/DataOptionControlIcons/lm-site.svg";
import { ReactComponent as PersonMarker } from "@/common/icons/DataOptionControlIcons/person-layer.svg";
import { LayerType } from "@/tenant-context/core/controls/DataOptionsControl/DataOptionsControl.component";

export const mainLayers: LayerType[] = [
  {
    label: "People",
    type: ToggleableLayerType.People,
    Icon: PersonMarker
  },
  {
    label: "Locations",
    type: ToggleableLayerType.Locations,
    Icon: SiteMarker
  }
  // {
  //   label: 'Business Locations',
  //   type: ToggleableLayerType.AssetLocations,
  //   Icon: AssetLocationMarker,
  //   isNavigable: true
  // },
  // {
  //   label: 'Airports',
  //   type: ToggleableLayerType.Airports,
  //   Icon: AirportMarker
  // },
  // {
  //   label: 'Hotels',
  //   type: ToggleableLayerType.Hotels,
  //   Icon: HotelMarker
  // },
  // {
  //   label: 'Country Risk Level',
  //   type: ToggleableLayerType.CountryRiskLevel,
  //   Icon: ICMRiskIcon
  // }
];
