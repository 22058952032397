import { Button, Checkbox, Flex, Stack, Text } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as ChevronRightIcon } from "@/common/icons/chevron-right.svg";

import { riskLevels } from "../BSOCEventsSettings/BSOCEventsSettings.config";

type Props = {
  riskLevelFilters: {
    nonCritical: boolean;
    critical: boolean;
  };
  onRiskLevelToggle: React.ChangeEventHandler<HTMLInputElement>;
  onOpenAnalytics: () => void;
  isBsocAnalyticsEnabled?: boolean;
};

const BSOCEventsSettingsComponent: FC<Props> = ({
  riskLevelFilters,
  onRiskLevelToggle,
  onOpenAnalytics,
  isBsocAnalyticsEnabled
}) => {

  return (
    <Stack>
      <Text size={ 18 } fw={ 700 } mb={ 24 }>
        Filter by Risk Category
      </Text>
      <Stack>
        { riskLevels.map(({ Icon, id, name }) => (
          <Checkbox
            key={ id }
            value={ id }
            checked={ (id === "critical" && riskLevelFilters.critical) ||
                (id === "nonCritical" && riskLevelFilters.nonCritical) }
            onChange={ onRiskLevelToggle }
            label={ <Flex gap={ 8 } display="inline-flex" align="center">
              <Icon />
              <span>{ name }</span>
            </Flex> }
          />
        )) }
      </Stack>

      { isBsocAnalyticsEnabled && (
        <Button
          mt={ 24 }
          onClick={ onOpenAnalytics }
          rightIcon={ <ChevronRightIcon /> }
        >
          Launch BSOC Analytics
        </Button>
      ) }
    </Stack>
  );
};

export default BSOCEventsSettingsComponent;
