import { Tabs } from "@mantine/core";
import { FC, useContext } from "react";

import { ProfileDocumentsModalContext } from "@/tenant-context/control-profile/components/modals/ProfileDocumentsModal/ProfileDocumentsModal.context";
import { useProfileDocumentsTabsStyles } from "@/tenant-context/control-profile/components/ui/ProfileDocumentsTabs/ProfileDocumentsTabs.style";

export enum ProfileDocumentsTab {
  Files = 'Files',
  BasicInfo = 'BasicInfo'
}

const ProfileDocumentsTabs: FC = () => {
  const { classes } = useProfileDocumentsTabsStyles();
  const {
    activeTab,
    setActiveTab,
    files,
    filesFromServer
  } = useContext(ProfileDocumentsModalContext);

  return (
    <Tabs
      value={ activeTab }
      onTabChange={ setActiveTab }
      className={ classes.block }
    >
      <Tabs.List>
        <Tabs.Tab value={ ProfileDocumentsTab.Files } className={ classes.tab }>
          Files ({ files.length + filesFromServer.length })
        </Tabs.Tab>

        <Tabs.Tab value={ ProfileDocumentsTab.BasicInfo } className={ classes.tab }>
          Basic Info
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};

export default ProfileDocumentsTabs;