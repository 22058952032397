import { FC, useCallback, useContext, useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { getAllGeoJsonBounds } from "@/tenant-context/common/util/map";
import AddFloorFormComponent
  from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/components/AddFloorForm/AddFloorForm.component";
import {
  LocationInfoContext
} from "@/tenant-context/control-location-configuration/context/LocationInfo.context";
import {
  CreateFloorRequest
} from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

const AddFloorForm: FC = () => {
  const { manageLocations: {
    postCreateFloor,
    SET_ACTIVE_FLOOR,
    SET_OVERLAY_GEO_JSON
  } } = useDispatch<Dispatch>();

  const { setIsAddFloorFormOpened } = useContext(LocationInfoContext);

  const activeFloor = useSelector((state: RootState) => state.manageLocations?.activeFloor);
  const { AddLocationMap: map } = useMap();

  const formControls = useForm({ mode: 'onChange' });

  const handleFormClear = useCallback(() => {
    SET_ACTIVE_FLOOR(undefined);
    formControls.reset();
    setIsAddFloorFormOpened(false);
  }, [SET_ACTIVE_FLOOR, formControls, setIsAddFloorFormOpened]);

  const handleFormSubmit = useCallback((data: FieldValues) => {
    postCreateFloor({
      floor: {
        ...data,
        name: data.name.trim().toUpperCase(),
        code: data.code.trim().toUpperCase()
      } as CreateFloorRequest,
      floorId: activeFloor?.floorTid || ''
    }).then(() => handleFormClear());
  }, [activeFloor?.floorTid, handleFormClear, postCreateFloor]);

  useEffect(() => {
    return () => {
      handleFormClear();
    };
  }, [handleFormClear]);

  useEffect(() => {
    if (activeFloor){
      formControls.setValue('name', activeFloor.name);
      formControls.setValue('code', activeFloor.code);
      formControls.setValue('note', activeFloor.note);
      if (activeFloor?.geoJson?.geoJson){
        const parsedGeoJson = JSON.parse(activeFloor?.geoJson?.geoJson);
        SET_OVERLAY_GEO_JSON(parsedGeoJson);
        const bounds = getAllGeoJsonBounds(parsedGeoJson);
        if (bounds && map) {
          map.fitBounds(bounds as [number, number, number, number], {
            padding: { top: 50, bottom: 50, left: 50, right: 50 }
          });
        }
      }
    } else {
      formControls.setValue('name', undefined);
      formControls.setValue('code', undefined);
      formControls.setValue('note', undefined);
      SET_OVERLAY_GEO_JSON(undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFloor]);

  return (
    <AddFloorFormComponent
      onSubmit={ handleFormSubmit }
      formControls={ formControls }
      onFormClear={ handleFormClear }
      activeFloor={ activeFloor }
    />
  );
};

export default AddFloorForm;
