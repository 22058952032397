import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { PaginatedResult } from "@/common/types/reference-data";
import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";

import { assetsApi } from "../api/assets";
import { AssetItem, AssetsList } from "../types/assets";

type AssetsState = {
    assetsListData?: PaginatedResult<AssetsList>
    selectedAsset?: AssetItem,
};

const assetsListDataModel = {
  name: 'assetsManagement',
  state: {
    assetsListData: undefined,
    selectedAsset: undefined
  } as AssetsState,
  reducers: {
    SET_ASSETS_LIST: (state: AssetsState, assetsListData: AssetsState['assetsListData']) => ({
      ...state,
      assetsListData
    }),
    SET_SELECTED_ASSET: (state: AssetsState, selectedAsset: AssetsState['selectedAsset']) => ({
      ...state,
      selectedAsset
    })
  },
  effects: (dispatch: Dispatch) => ({
    async getAssetsListData(payload: {
      gridParams: GridParams,
  }, _state: RootState): Promise<PaginatedResult<AssetsList>> {

      const query = payload.gridParams.searchQuery;
      if(query){
      // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `name LIKE %27%25${query}%25%27`;
      }

      const assetsListData = await assetsApi.getAssetsListData(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.assetsManagement.SET_ASSETS_LIST(assetsListData);
      return assetsListData;
    },
    async getAssetById(id: string, _state: RootState): Promise<AssetItem> {
      const asset = await assetsApi.getAssetById(id);
      dispatch.assetsManagement.SET_SELECTED_ASSET(asset);
      return asset;
    },
    async saveAsset(params: AssetItem, state: RootState): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let success = true;
      // eslint-disable-next-line fp/no-let
      let result;
      if(state.assetsManagement.selectedAsset){
        //edit asset
        result = await assetsApi.updateAsset(params).catch(handleError);
        success = !!result;
      } else{
        //new asset
        result = await assetsApi.saveAsset(params).catch(handleError);
        success = !!result;

        if(success){
          dispatch.assetsManagement.SET_SELECTED_ASSET(result as AssetItem);
        }
      }

      return success;
    },

    async deleteAsset(assetId: string, _state: RootState): Promise<boolean> {
      return !!await assetsApi.deleteAsset(assetId).catch(handleError);
    }
  })
};

export const assetsManagement = createModel<RootModel>()(assetsListDataModel);
