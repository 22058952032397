import { FC, useContext } from 'react';

import SelectedGeofenceDataPopupComponent
  from '@/tenant-context/visualisation-geofences/components/SelectedGeofenceDataPopup/SelectedGeofenceDataPopup.component';
import { GeofenceVisualizeContext } from '@/tenant-context/visualisation-geofences/context/GeofenceVisualize.context';

export const SelectedGeofenceDataPopupContainer: FC = () => {
  const {
    currentPopupCoordinates,
    isShownGeofencePopup,
    popupProperties
  } = useContext(GeofenceVisualizeContext);

  return (
    <SelectedGeofenceDataPopupComponent
      isShown={ isShownGeofencePopup }
      latitude={ currentPopupCoordinates[1] }
      longitude={ currentPopupCoordinates[0] }
      properties={ popupProperties }
    />
  );
};

export default SelectedGeofenceDataPopupContainer;
