import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { Dispatch, RootState } from "@/core/store";
import {
  TRAVELLERS_SEARCH_SUB_SLIDES
} from "@/tenant-context/control-travellers-search/config/travellers-search.config";
import {
  useTravellersSearchContext
} from "@/tenant-context/control-travellers-search/context/TravellersSearchContext/TravellersSearch.context";
import {
  CountryDetailViewDrawerComponent
} from "@/tenant-context/control-travellers-search/drawers/CountryDetailViewDrawer/CountryDetailViewDrawer.component";
import {
  CountryDetailData,
  TravellerData,
  TravellersInCountrySortType
} from "@/tenant-context/control-travellers-search/types/travelSearch.types";

const CountryDetailViewDrawer: FC = () => {

  const selectedCountryDetails = useSelector((state: RootState) => state.travellersSearch.countryDetailData);
  const [countryDetailData, setCountryDetailData] = useState<CountryDetailData | undefined>();
  const [travellers, setTravellers] = useState<Array<TravellerData>>([]);

  const {
    dataOptions: {
      ENABLE_LAYER_TYPE,
      DISABLE_LAYER_TYPE
    },
    travellersSearch: {
      SET_SELECTED_COUNTRY
    }
  } = useDispatch<Dispatch>();

  const {
    setCurrentlyOpenedSlide,
    setSelectedTraveller
  } = useTravellersSearchContext();

  const handleDrawerClose = useCallback(() => {
    setCurrentlyOpenedSlide(TRAVELLERS_SEARCH_SUB_SLIDES.TRAVELLERS_BY_COUNTRY_SLIDE);
    SET_SELECTED_COUNTRY(undefined);
    DISABLE_LAYER_TYPE([ToggleableLayerType.TravelCountryDetailsView]);
    ENABLE_LAYER_TYPE([ToggleableLayerType.TravelCountriesView]);
  }, [DISABLE_LAYER_TYPE, ENABLE_LAYER_TYPE, SET_SELECTED_COUNTRY, setCurrentlyOpenedSlide]);

  const handleSort = useCallback((sortType: TravellersInCountrySortType) => () => {
    if (!countryDetailData) {
      return;
    }

    if (sortType === TravellersInCountrySortType.TRIP_DATE) {
      const sortedData = [...countryDetailData.travellers]
        .sort((a, b) => b.tripDates.from - a.tripDates.from);

      setTravellers(sortedData);
    } else if (sortType === TravellersInCountrySortType.NAME) {
      const sortedData = [...countryDetailData.travellers]
        .sort((a, b) => a.personName.localeCompare(b.personName));

      setTravellers(sortedData);
    }
  }, [countryDetailData]);

  const handleSearchChange = useCallback((value: string) => {
    const filteredData = countryDetailData?.travellers.filter((traveller) =>
      traveller.personName.toLowerCase().includes(value.toLowerCase()));

    setTravellers(filteredData || []);
  }, [countryDetailData?.travellers]);

  const handleTravellerClick = useCallback((traveller: TravellerData) => {
    setSelectedTraveller(traveller);
    setCurrentlyOpenedSlide(TRAVELLERS_SEARCH_SUB_SLIDES.TRAVELLERS_MINI_PROFILE_SLIDE);
  }, [setCurrentlyOpenedSlide, setSelectedTraveller]);

  useEffect(() => {
    if (!selectedCountryDetails) {
      return;
    }

    setCountryDetailData(selectedCountryDetails);
    setTravellers(selectedCountryDetails.travellers);
  }, [selectedCountryDetails]);

  return (
    <CountryDetailViewDrawerComponent
      countryDetailData={ countryDetailData }
      travellers={ travellers }
      handleSort={ handleSort }
      handleSearchChange={ handleSearchChange }
      onBackClick={ handleDrawerClose }
      handleTravellerClick={ handleTravellerClick }
    />
  );
};

export default CountryDetailViewDrawer;
