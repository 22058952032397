import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

import { primitiveNumbers } from "../constants";

export const RadioComponentStyle = {
  defaultProps: {
    size: 'md'
  },
  styles: (theme: MantineTheme) => ({
    inner: {
      width: primitiveNumbers['3x'],
      height: primitiveNumbers['3x'],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg':{
        color: theme.other.semantic.icon.general.main
      },
      '& label': {
        color: `${theme.other.semantic.text.primary} !important`
      }
    },
    radio: {
      label: {
        fontSize: theme.fontSizes.md
      },
      width: primitiveNumbers['3x'],
      height: primitiveNumbers['3x'],
      backgroundColor: `${theme.other.semantic.icon.general.transparent} !important`,
      border: `1px solid ${theme.other.semantic.icon.general.secondary} !important`,
      '&:hover': {
        border: `1px solid ${theme.other.semantic.icon.general.hover} !important`
      },
      '&:checked':{
        backgroundColor: `${theme.other.semantic.accent[1]} !important`,
        border: 'none !important'
      },
      '&:disabled':{
        border: `1px solid ${theme.other.semantic.icon.general.disabled} !important`,
        backgroundColor: `${theme.other.semantic.icon.general.transparent} !important`,
        cursor: 'not-allowed',
        '&:checked':{
          backgroundColor: `${theme.other.semantic.icon.general.disabled} !important`,
          border: `none !important`
        }
      }
    },
    description: {
      color: theme.other.semantic.text.secondary
    }
  })
};
