import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const useArcHeaderStyles = createStyles((t) => ({
  // Some styles are temporarily added until the header is implemented
  header: {
    backgroundColor: t.colors.neutral[9],
    padding: '0px 12px',
    height: 80,
    position: 'sticky',
    top: 0,
    zIndex: 1
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',

    '& > button:not(:first-child)': {
      marginLeft: 20
    }
  },

  createNotificationBtn:{
    fontSize:t.fontSizes.sm
  },

  arcTabs:{
    [`& .${CSS_KEY}-Tabs-tab`]:{
      '&[data-active]': {
        borderBottom: 0,
        backgroundColor: t.colors.neutral[9] + '!important',
        '&::before': {
          backgroundColor: t.colors.neutral[9],
          borderBottom: 0
        }
      }
    }
  }
}));
