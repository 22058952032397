import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ToggleableContextLayers,
  ToggleableLayerType
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { Dispatch, RootState } from "@/core/store";
import { TRAVELLERS_SEARCH_DRAWERS } from "@/tenant-context/control-travellers-search/config/travellers-search.config";
import { useTravellersSearchContext } from "@/tenant-context/control-travellers-search/context/TravellersSearchContext/TravellersSearch.context";

import PeopleSettingsComponent from "./PeopleSettings.component";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const allToggleableLayers = Object.keys(ToggleableLayerType).map((key) => ToggleableLayerType[key]);

type Props = {
  resetSearchState: () => void;
};

const PeopleSettingsContainer: FC<Props> = ({ resetSearchState }) => {
  const { travellersSearchForm, isLoading, searchTravellers } =
    useTravellersSearchContext();

  const isMainDrawerOpen = useSelector(
    (state: RootState) =>
      state.drawer?.currentlyOpenRightDrawerId ===
      TRAVELLERS_SEARCH_DRAWERS.TRAVELLERS_SEARCH_CONTROL
  );

  const {
    dataOptions: {
      ENABLE_LAYER_TYPE,
      DISABLE_LAYER_TYPE,
      DISABLE_CONTEXTUAL_LAYER_TYPE
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    if (!isMainDrawerOpen) {
      return;
    }

    DISABLE_LAYER_TYPE([ToggleableLayerType.People]);

    return () => {
      ENABLE_LAYER_TYPE([...allToggleableLayers]);

      DISABLE_LAYER_TYPE([
        ToggleableLayerType.TravelCountriesView,
        ToggleableLayerType.TravelCountryDetailsView
      ]);

      DISABLE_CONTEXTUAL_LAYER_TYPE([ToggleableContextLayers.CountryRiskLevel]);
    };
  }, [
    ENABLE_LAYER_TYPE,
    DISABLE_LAYER_TYPE,
    isMainDrawerOpen,
    DISABLE_CONTEXTUAL_LAYER_TYPE
  ]);

  useEffect(() => {
    return () => {
      resetSearchState();
    };
  }, [resetSearchState]);
  

  const handleSearchTravellers = useCallback(() => {
    DISABLE_LAYER_TYPE([
      ToggleableLayerType.TravelCountriesView,
      ToggleableLayerType.TravelCountryDetailsView
    ]);
    DISABLE_CONTEXTUAL_LAYER_TYPE([ToggleableContextLayers.CountryRiskLevel]);
    searchTravellers();
  }, [DISABLE_CONTEXTUAL_LAYER_TYPE, DISABLE_LAYER_TYPE, searchTravellers]);

  return (
    <PeopleSettingsComponent
      travellersSearchForm={ travellersSearchForm }
      isLoading={ isLoading }
      onClickSearchButton={ handleSearchTravellers }
    />
  );
};

export default PeopleSettingsContainer;
