import { Box, Button, Switch, Textarea, TextInput } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import { closeAllModals, openModal } from '@mantine/modals';
import React, { ChangeEvent, FC, FormEvent, useCallback } from "react";
import { FileRejection } from "react-dropzone";

import Dropzone from '@/common/components/Dropzone';
import DropzoneFiles from "@/common/components/DropzoneFiles";
import { ReactComponent as ExclamationIcon } from '@/common/icons/MassCommsIcons/Response/large-exclamation-mark-red-circle.svg';
import { useCreateCommsPageStyles } from "@/tenant-context/control-mass-comms/components/CreateCommsPage/CreateCommsPage.styles";
import {
  APP_COMMS_ATTACHMENT_MAX_FILE_COUNT,
  APP_COMMS_ATTACHMENT_MAX_FILE_SIZE, APP_COMMS_ATTACHMENT_TYPES, APP_COMMS_ATTACHMENT_TYPES_HUMAN_READABLE
} from '@/tenant-context/control-mass-comms/config/app-comms-config';
import { CRITICAL_ALERT_INSTRUCTIONS } from '@/tenant-context/control-mass-comms/config/critical-alert-instructions';
import {
  AppCommunicationContent,
  Base64EmailAttachment
} from "@/tenant-context/control-mass-comms/types/MassComms.types";
import { ProfileDocumentFile } from "@/tenant-context/control-profile/types/profile";


type Props = {
  communicationContent: AppCommunicationContent,
  onHeaderChanged: (subject: FormEvent<HTMLInputElement>) => void,
  onContentChanged: (event: ChangeEvent<HTMLTextAreaElement>) => void,
  onNewAttachmentSelected: (files: FileWithPath[]) => void,
  uploadRef: React.RefObject<() => void>,
  onRemoveAttachment: (file: FileWithPath | ProfileDocumentFile) => void,
  onRemoveBase64Attachment: (file: Base64EmailAttachment) => void,
  onDownloadAttachment: (file: ProfileDocumentFile) => void,
  onRejectedFiles: (files: FileRejection[]) => void,
  onCriticalAlertModeChange: (isOn: boolean) => void,
  isCriticalAlertMode: boolean,
  onBiometricConfirmationStateChange: (isOn: boolean) => void,
  isBiometricConfirmationTurnedOn: boolean
}

const AppConfigurationComponent: FC<Props> = ({
  communicationContent,
  onHeaderChanged,
  onContentChanged,
  uploadRef,
  onNewAttachmentSelected,
  onRemoveAttachment,
  onRemoveBase64Attachment,
  onDownloadAttachment,
  onRejectedFiles,
  onCriticalAlertModeChange,
  isCriticalAlertMode,
  onBiometricConfirmationStateChange,
  isBiometricConfirmationTurnedOn
}) => {

  const { classes, cx } = useCreateCommsPageStyles({ isCommsSequenceNoAvailable: true });

  const handleCriticalAlertModalOK = useCallback(() => {
    onCriticalAlertModeChange(true);
    closeAllModals();
  }, [onCriticalAlertModeChange]);

  const handleToggleBiometricConfirmationChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onBiometricConfirmationStateChange(event.target.checked);
  }, [onBiometricConfirmationStateChange]);

  const handleToggleCriticalAlert = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const isOn = event.target.checked;

    if (isOn) {
      openModal({
        title: 'Important Information!',
        className: classes.caModalRoot,
        withCloseButton: false,
        size: 666,
        children: ( <div>
          <div className={ classes.caModalRootGrid }>

            <ExclamationIcon/>

            <div className={ classes.caModalContent }>

              <div className={ classes.caModalSubHeading }>
                Reading and understanding the sending of comms with Critical Alert (iOS) is imperative.
              </div>

              <div className={ classes.caModalContentGrid }>

                { CRITICAL_ALERT_INSTRUCTIONS.map((item, index) => (
                  <div key={ index }>
                    <div className={ classes.caModalContentTitle }>{ item.heading }</div>
                    <div className={ classes.caModalContentText }>{ item.text }</div>
                  </div> )) }

              </div>

            </div>

          </div>
          <div className={ classes.caModalFooter }>
            <div className={ classes.caOkButton }>
              <Button onClick={ handleCriticalAlertModalOK } size={ 'md' } fullWidth>OK</Button>
            </div>
          </div>
        </div> )
      });
    } else {
      onCriticalAlertModeChange(isOn);
    }
  }, [
    classes,
    handleCriticalAlertModalOK,
    onCriticalAlertModeChange
  ]);

  return (
    <div className={ classes.tabContent }>
      <div className={ classes.switches }>
        <Switch
          onChange={ handleToggleCriticalAlert }
          checked={ isCriticalAlertMode }
          size={ 'md' }
          label={ 'Critical Alert (iOS)' }
        />
        <Switch
          onChange={ handleToggleBiometricConfirmationChange }
          checked={ isBiometricConfirmationTurnedOn }
          size={ 'md' }
          label={ 'Confirm response with Face ID / Touch ID' }
        />
      </div>
      <div className={ classes.section }>
        <Box mt={ 'xl' } >
          <TextInput
            onChange={ onHeaderChanged }
            value={ communicationContent.header }
            label={ 'Header' }
          />
        </Box>
      </div>

      <div className={ classes.section }>
        <div className={ classes.contentTitle }>Content</div>

        <Textarea
          size={ 'lg' }
          value={ communicationContent.content }
          onChange={ onContentChanged }
          autosize
          minRows={ 10 }
        />

        <div className={ cx(classes.contentTitle, classes.standardMargin) }>Attachments</div>

        <Dropzone
          accept={ APP_COMMS_ATTACHMENT_TYPES }
          humanReadableAccept={ APP_COMMS_ATTACHMENT_TYPES_HUMAN_READABLE }
          openRef={ uploadRef }
          onAcceptedFiles={ onNewAttachmentSelected }
          maxSize={ APP_COMMS_ATTACHMENT_MAX_FILE_SIZE }
          maxFiles={ APP_COMMS_ATTACHMENT_MAX_FILE_COUNT }
          allFilesMaxSize={ APP_COMMS_ATTACHMENT_MAX_FILE_SIZE * 2 }
          onRejectedFiles={ onRejectedFiles }
        />

        { communicationContent.attachments && (communicationContent.attachments?.length > 0) && <DropzoneFiles
          removeFile={ onRemoveAttachment }
          removeBase64File={ onRemoveBase64Attachment }
          downloadFile={ onDownloadAttachment }
          files={ communicationContent.attachments || [] }
        /> }
      </div>
    </div>
  );
};

export default AppConfigurationComponent;
