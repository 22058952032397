import { callApi } from "@/common/util/api";

import { SummaryItineraryItem } from "../types/travel.types";

export const getItineraryForSummary = (
  countryCode: string, 
  travelEndpointType: string, 
  travelDay: string
) => callApi<SummaryItineraryItem[]>(
  'asset-ranking-service/v1/location-engine/itinerary-list/',
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;',
      'User-Agent': 'Apidog/1.0.0 (https://www.apidog.com)'
    },
    queryParams: {
      countryCode,
      travelEndpointType,
      travelDay
    },
    withTenantId: true
  }
);

