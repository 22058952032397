import { createStyles, keyframes } from '@mantine/core';

const spinner = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' }
});

export const useSpinnerStyles = createStyles(() => ({
  spinner: {
    animation: spinner,
    animationDuration: '1.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    width: 48,
    height: 48
  }
}));
