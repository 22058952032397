import { createStyles, MantineTheme } from "@mantine/core";

export const useTextTruncateStyles = createStyles((theme: MantineTheme) => ({
  readMoreTxt:{
    color:"#66B1FF",
    cursor:"pointer",
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.semibold
  }
}));
