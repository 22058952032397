import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { Dispatch, RootState } from "@/core/store";
import SelectRecipientsPageComponent
  from "@/tenant-context/control-mass-comms/components/SelectRecipientsPage/SelectRecipientsPage.component";
import { GroupEntity, ImpactResponseName, PeopleEntity, RecipientMainSelection } from "@/tenant-context/control-mass-comms/types/MassComms.types";


const SelectRecipientsPage: FC = () => {
  const SEARCH_DEBOUNCE_INTERVAL = 300;

  const selectedRecipientType = useSelector((state: RootState) => state.massComms.selectedRecipientType);
  const impactResponseItems  = useSelector((state: RootState) => state.massComms.impactResponseItems);
  const isDeliverySearchLoading  = useSelector((state: RootState) => state.massComms?.isDeliveryEntitySearchLoading);
  const searchedDeliveryEntities  = useSelector((state: RootState) => state.massComms.searchedDeliveryEntities);
  const selectedGroups = useSelector((state: RootState) => state.massComms.selectedGroups);
  const massCommsNavigation = useSelector((state: RootState) => state.massComms.massCommsNavigation);

  const {
    massComms: {
      loadPeopleSearch,
      loadGroupSearch,
      selectRecipients,
      SET_SELECTED_RECIPIENT_TYPE,
      SET_IMPACT_RESPONSE_ITEMS,
      addGroup,
      removeGroup,
      populateImpactedPeopleToSelectedRecipients
    }
  } = useDispatch<Dispatch>();

  const debouncedPeopleSearch = useDebouncedCallback(
    loadPeopleSearch,
    SEARCH_DEBOUNCE_INTERVAL
  );

  const debouncedGroupSearch = useDebouncedCallback(
    loadGroupSearch,
    SEARCH_DEBOUNCE_INTERVAL
  );

  const handleRecipientGroup = useCallback(
    (value: string) => {
      SET_SELECTED_RECIPIENT_TYPE(value as RecipientMainSelection);
    },
    [ SET_SELECTED_RECIPIENT_TYPE ]
  );

  const handleDeliveryEntites = useCallback((event) => {
    if (event.currentTarget.name === 'people') {
      debouncedPeopleSearch(event.currentTarget.value);
    } else if (event.currentTarget.name === 'selectedGroups') {
      debouncedGroupSearch(event.currentTarget.value);
    }
  }, [debouncedGroupSearch, debouncedPeopleSearch]);

  const handlePeopleSelect = useCallback((selectedPerson: PeopleEntity) =>
    (_: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      selectRecipients(selectedPerson);

    }, [selectRecipients]);

  const handleImpactItemSelect = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const impactItems = { ...impactResponseItems };
    if (event.target.name === 'allImpacted') {
      Object.keys(impactItems).forEach(itemKey => {
        if (impactItems[itemKey as ImpactResponseName].isChecked !== event.target.checked) {
          impactItems[itemKey as ImpactResponseName].isChecked = event.target.checked;
        }
      });
    } else {
      impactItems[event.target.name as ImpactResponseName].isChecked = event.target.checked;
      const isAllOtherSelected = Object.keys(impactItems).filter(item => item !== 'allImpacted').every(impactItem => impactItems[impactItem as ImpactResponseName].isChecked === true);
      impactItems['allImpacted'].isChecked = isAllOtherSelected;
    }
    SET_IMPACT_RESPONSE_ITEMS(impactItems);

    populateImpactedPeopleToSelectedRecipients();
  }, [ SET_IMPACT_RESPONSE_ITEMS, impactResponseItems, populateImpactedPeopleToSelectedRecipients ]);

  const handleAddGroup = useCallback((selectedGroup: GroupEntity) =>
    (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      addGroup(selectedGroup);

    }, [addGroup]);

  const handleRemoveGroup = useCallback((selectedGroup: GroupEntity) =>
    (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      removeGroup(selectedGroup);

    }, [removeGroup]);

  return (
    <SelectRecipientsPageComponent
      onRecipientGroupSelected={ handleRecipientGroup }
      selectedRecipient={ selectedRecipientType }
      onDeliveryEntitiesSelected={ handleDeliveryEntites }
      isDeliverySearchLoading={ isDeliverySearchLoading }
      searchedDeliveryEntities={ searchedDeliveryEntities }
      onPeopleSelect={ handlePeopleSelect }
      onImpactItemSelect={ handleImpactItemSelect }
      impactResponseItems={ impactResponseItems }
      onAddGroup={ handleAddGroup }
      onRemoveGroup={ handleRemoveGroup }
      selectedGroups={ selectedGroups }
      isNavigatedFromArc={ massCommsNavigation.fromArc }
    />
  );
};

export default SelectRecipientsPage;
