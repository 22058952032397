import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

type Args = {
  riskLevelColor: string;
}

export const useRelatedEventPopupStyles = createStyles((theme: MantineTheme, { riskLevelColor }: Args) => ({
  caption: {
    maxHeight: 350,
    minHeight: 200,
    overflow: 'auto',
    marginRight: -16,
    paddingRight: 16
  },
  issuedBy: {
    fontSize: theme.fontSizes.xs,
    fontFamily: theme.fontFamily,
    fontStyle: 'italic'
  },
  riskAlertProviderName: {
    fontSize: theme.fontSizes.xs,
    fontFamily: theme.fontFamily,
    color: theme.white
  },
  riskLevelBadge: {
    padding: '2px 4px 2px 4px',
    borderRadius: '1px !important',
    backgroundColor: riskLevelColor,
    textTransform: 'capitalize',
    marginBottom: 8
  },
  riskAlertProvider: {
    svg: {
      width: '16px',
      height: '16px'
    }
  },
  dimmed: {
    color: theme.colors.neutral[5]
  },
  sectionContent: {
    'ul, ol': {
      paddingLeft: 40
    },
    ol: {
      listStyleType: 'decimal !important'
    },
    ul: {
      listStyleType: 'disc !important'
    },
    img: {
      width: '100%',
      objectFit: 'contain'
    }
  }
}));
