import { logger } from "../ConsoleLogger";

export default class WorkerExternalFacade<MessageType, ResponseType> {
  protected messageId = 0;

  protected watcherMap = new Map<number, {
    resolve: (result: ResponseType) => void,
    reject: (error: Error) => void
  }>();

  static create<MessageType, ResponseType>(worker: Worker): WorkerExternalFacade<MessageType, ResponseType> {
    return new this<MessageType, ResponseType>(worker);
  }

  protected constructor(
    protected worker: Worker
  ) {
    this.watcherMap = new Map<number, {
      resolve: (result: ResponseType) => void,
      reject: (error: Error) => void
    }>();

    this.worker.addEventListener('message', this.handleMessage.bind(this));
  }

  /**
   * Using this is not recommended
   */
  getWorker() {
    return this.worker;
  }

  handleMessage(
    { data }: MessageEvent<ResponseType & {
      __INTERNAL__id: number,
      error?: Error
    }>
  ) {
    const { error } = data;
    if (error) {
      return this.handleError(error, data.__INTERNAL__id);
    }

    const watcher = this.watcherMap.get(data.__INTERNAL__id);

    if (!watcher) {
      return;
    }

    const {
      __INTERNAL__id,
      ...sanitisedData
    } = data;

    // todo figure out
    watcher.resolve(sanitisedData as unknown as ResponseType);

    this.watcherMap.delete(__INTERNAL__id);
  }

  handleError(
    error: Error, // Actually error-like rather than Error itself
    messageId: number
  ) {
    const revivedError = new Error(
      'in Web Worker'
    );

    revivedError.stack = error.stack;
    revivedError.name = error.name;

    const reinstantiatedError = new Error(error.message, {
      cause: revivedError
    });

    const watcher = this.watcherMap.get(messageId);
    if (!watcher) {
      logger.warn(
        'The error below has occurred in a worker, but has no listener\n' +
        'This happens when the listener has already been dropped'
      );

      logger.error(error);

      return;
    }

    watcher.reject(reinstantiatedError);

    this.watcherMap.delete(messageId);
  }

  async execute(message: MessageType): Promise<ResponseType> {
    this.messageId++;

    return new Promise((resolve, reject) => {
      this.watcherMap.set(this.messageId, {
        resolve,
        reject
      });

      // Post the message
      this.worker.postMessage({
        ...message,
        __INTERNAL__id: this.messageId
      });
    });
  }
}