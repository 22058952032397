import { DefaultProps, SimpleGrid } from "@mantine/core";
import { FC } from "react";

import mapStylePreviewDark from "@/common/icons/MapStylePreview/map-style-preview-dark.png";
import mapStylePreviewDefault from "@/common/icons/MapStylePreview/map-style-preview-default.png";
import mapStylePreviewLight from "@/common/icons/MapStylePreview/map-style-preview-light.png";
import mapStylePreviewSatellite from "@/common/icons/MapStylePreview/map-style-preview-satellite.png";
import mapStylePreviewStreets from "@/common/icons/MapStylePreview/map-style-preview-streets.png";

import MapStyleToggle from "./MapStyleToggle.component";

type Props = {
  onChangeMapStyle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  sx?: DefaultProps['sx'];
};

const MapStyleToggleList: FC<Props> = ({ onChangeMapStyle, value, sx }) => {
  return (
    <SimpleGrid cols={ 2 } spacing={ 24 }>
      <MapStyleToggle
        label="Default"
        name="mapStyle"
        value="default"
        checked={ value === "default" }
        imgUrl={ mapStylePreviewDefault }
        onChange={ onChangeMapStyle }
      />
      <MapStyleToggle
        label="Dark"
        name="mapStyle"
        value="dark"
        checked={ value === "dark" }
        imgUrl={ mapStylePreviewDark }
        onChange={ onChangeMapStyle }
      />
      <MapStyleToggle
        label="Light"
        name="mapStyle"
        value="light"
        checked={ value === "light" }
        imgUrl={ mapStylePreviewLight }
        onChange={ onChangeMapStyle }
      />
      <MapStyleToggle
        label="Streets"
        name="mapStyle"
        value="streets"
        checked={ value === "streets" }
        imgUrl={ mapStylePreviewStreets }
        onChange={ onChangeMapStyle }
      />
      <MapStyleToggle
        label="Satellite"
        name="mapStyle"
        value="satellite"
        checked={ value === "satellite" }
        imgUrl={ mapStylePreviewSatellite }
        onChange={ onChangeMapStyle }
      />
    </SimpleGrid>
  );
};

export default MapStyleToggleList;
