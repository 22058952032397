import { Button } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { FC, Fragment, ReactNode, useCallback } from 'react';

import { useDialogBoxModalStyles } from '@/tenant-context/control-draw/components/DeleteGeofenceWarningModal/DeleteGeofenceWarningModal.style';

type Props = {
  highlightedText?: string;
  messageText: string;
  controls?: ReactNode;
}

const DialogBoxModalComponent: FC<Props> = ({ highlightedText, messageText, controls }) => {
  const { classes } = useDialogBoxModalStyles();

  const close = useCallback(() => {
    closeAllModals();
  }, []);

  return (
    <Fragment>
      <div className={ classes.messageArea }>
        <span className={ classes.dangerText }>{ highlightedText }</span>
        { messageText }
      </div>
      <div className={ classes.footer }>
        <Button variant="outline" onClick={ close } size={ 'md' }>Cancel</Button>
        { controls }
      </div>
    </Fragment>
  );
};

export default DialogBoxModalComponent;
