import { createStyles } from "@mantine/core";

export const useConfirmationDialogStyle = createStyles((_theme) => ({
  confirmDialog:{
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%) !important"
  }
}));
