import { Box } from "@mantine/core";
import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import { useDebouncedCallback } from "use-debounce";

import { RowClickedEvent, ServerDataListComponent } from "@/common/components/ServerDataList/ServerDataList.component";
import { GridParams } from "@/common/types/ag-grid";
import { handleError } from "@/common/util/common-functions";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

export const defaultRowTemplate = (row: Record<string, unknown>, _index: number): React.ReactNode => (
  <Box>
    { Object.keys(row).map((key, i) => (
      <Box key={ i }>{ row[key] as ReactNode }</Box>
    )) }
  </Box>
);

export type ServerDataListRef = {
  refreshList: () => void;
}

export type SortingConfig = {
  field: string,
  label: string
}

export type SortedColumn = {
  colId: string,
  sort: 'asc' | 'desc' | null
}

type Props<T, P = Record<string, string | number | boolean>> = {
  /** Identifier for the grid */
  id: string,
  /** Whether to have Search input or not **/
  search?: boolean,
  /** Placement of the search input */
  searchInputPosition?: 'left' | 'right',
  /** Additional data to be passed to the getData function */
  additionalGridParams?: Record<string, string | number | boolean>,
  /** Whether to show the pagination in a responsive way */
  isResponsivePagination?: boolean,
  /** Text to be displayed in the search tooltip */
  searchTooltipText?: string
  /** Number of rows to be displayed in the list */
  rowCount?: string
  /** Alignment of the filters */
  alignFilters?: 'flex-start' | 'flex-end'
  /** Whether to wrap the filters */
  wrapFilters?: boolean,
  /** Function to get the data. This will be calling with GridParams and given additionalGridParams.
   * Should return a PaginatedResult of <T> */
  getData: (payload: {
    gridParams: GridParams,
    additionalParams?: P
  }) => Promise<PaginatedResult<T>>;
  /** Callback function on row click. Includes rowData, index and clickedEvent */
  onRowClicked?: (event: RowClickedEvent<T>) => void,
  /** Content for the list header. Can include extra filtering */
  headerContent?: React.ReactNode | (() => React.ReactNode),
  /** Function to get the row template.
   * Compulsory. This will be used as the render template for the list item.
   * Can use the `defaultRowTemplate` is nothing available */
  getRowTemplate: (row: T, index: number) => React.ReactNode,
  sortingConfigs?: SortingConfig[],
  searchPlaceholder?: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServerDataList: ForwardRefRenderFunction<ServerDataListRef, any>
  = <T, P = Record<string, string | number | boolean>>({
    id,
    onRowClicked,
    getData,
    search = false,
    searchInputPosition = 'right',
    additionalGridParams,
    isResponsivePagination,
    rowCount,
    searchTooltipText,
    alignFilters = 'flex-end',
    wrapFilters,
    headerContent,
    getRowTemplate,
    sortingConfigs,
    searchPlaceholder = "Search"
  }: Props<T, P>, ref: ForwardedRef<ServerDataListRef>) => {
    const initialLoad = useRef(true);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const [activePage, setActivePage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(rowCount || '10');
    const [searchQuery, setSearchQuery] = useState("");
    const [sortedColumn, setSortedColumn] = useState<SortedColumn | undefined>(undefined);
    const [data, setData] = useState<PaginatedResult<T> | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const toggleLoading = useCallback(() => {
      setLoading(prevState => !prevState);
    }, []);

    const showingFrom = useMemo(() => {
      if (data?.totalPages) {
        const firstRow = (activePage + 1) * +itemsPerPage - +itemsPerPage + 1;
        return data?.totalPages < 1 ? 0 : firstRow;
      } else {
        return 0;
      }
    }, [activePage, itemsPerPage, data?.totalPages]);

    const showingTo = useMemo(() => {
      if (data?.totalPages) {
        const lastRow = (activePage + 1) * +itemsPerPage;
        return lastRow > data?.totalItems ? data?.totalItems : lastRow;
      } else {
        return 0;
      }
    }, [data?.totalPages, data?.totalItems, activePage, itemsPerPage]);

    const handleSearchPhraseChange = useDebouncedCallback((value: string): void => {
      setSearchQuery(value);
    }, 1000);

    useImperativeHandle(ref, () => ({
      refreshList() {
        toggleLoading();
        if (searchInputRef && searchInputRef.current) {
          searchInputRef.current.value = "";
        }
        getData({
          gridParams: {
            page: 0, size: +itemsPerPage,
            additionalParams: additionalGridParams
          }
        })
          .then(response => {
            setData(response);
            setActivePage(0);
          }).catch(handleError).finally(toggleLoading);
      }
    }));

    const onSortChanged = useCallback((field: string, dir: "asc" | "desc" | null): void => {
      if (field === 'NONE') {
        setSortedColumn(undefined);
        return;
      }

      const sorted: SortedColumn = {
        colId: field,
        sort: dir
      };

      setSortedColumn(sorted);
    }, []);

    useEffect(() => {
      const gridParams: GridParams = { page: activePage, size: +itemsPerPage };
      if (searchQuery) {
        gridParams.searchQuery = searchQuery;
      }

      if (sortedColumn) {
        gridParams.sort = {
          sort: sortedColumn.sort || "",
          colId: sortedColumn.colId
        };
      }

      if (additionalGridParams) {
        gridParams.additionalParams = additionalGridParams;
      }
      toggleLoading();
      getData({ gridParams }).then(response =>
        setData(response)).catch(handleError).finally(() => {
        initialLoad.current = false;
        toggleLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePage, sortedColumn]);

    useEffect(() => {
      //prevent api from triggering twice in the initial load
      if (!initialLoad.current) {
        if (activePage > 0) {
          setActivePage(0);
        } else {
          const gridParams: GridParams = { page: activePage, size: +itemsPerPage };
          if (searchQuery) {
            gridParams.searchQuery = searchQuery;
          }
          if (additionalGridParams) {
            gridParams.additionalParams = additionalGridParams;
          }
          toggleLoading();
          getData({ gridParams }).then(response => setData(response)).catch(handleError).finally(toggleLoading);
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsPerPage, searchQuery]);

    return (
      <ServerDataListComponent
        id={ id }
        data={ data?.items || [] }
        onRowClicked={ onRowClicked }
        showingFrom={ showingFrom }
        showingTo={ showingTo }
        totalRecords={ data?.totalItems || 0 }
        activePage={ activePage }
        setActivePage={ setActivePage }
        totalPages={ data?.totalPages || 0 }
        boundaries={ 2 }
        itemsPerPage={ itemsPerPage }
        setItemsPerPage={ setItemsPerPage }
        isResponsivePagination={ isResponsivePagination }
        search={ search }
        headerContent={ headerContent }
        handleSearchPhraseChange={ handleSearchPhraseChange }
        loading={ loading }
        searchInputRef={ searchInputRef }
        searchInputPosition={ searchInputPosition }
        searchTooltipText={ searchTooltipText }
        sortingConfigs={ sortingConfigs }
        alignFilters={ alignFilters }
        wrapFilters={ wrapFilters }
        getRowTemplate={ getRowTemplate }
        onSortChanged={ onSortChanged }
        searchPlaceholder={ searchPlaceholder }
      />
    );
  };

export default forwardRef(ServerDataList) as <T, P = Record<string, string | number | boolean>>(
  props: Props<T, P> & React.RefAttributes<ServerDataListRef>
) => JSX.Element;
