import { FC, useCallback } from "react";

import TagChipComponent from '@/common/components/TagsContainer/ui/TagChip/TagChip.component';

type Props = {
  color: string
  index: number
  onRemove?: (index: number) => void
  onClick?: (index: number) => void
  label: string
}

const TagChipContainer: FC<Props> = ({ color, index, onRemove, onClick, label }) => {

  const handleRemove = useCallback(($event) => {
    $event.stopPropagation();
    if (!onRemove) {
      return;
    }

    onRemove(index);
  }, [index, onRemove]);

  const handleClick = useCallback(() => {
    if (!onClick) {
      return;
    }

    onClick(index);
  }, [index, onClick]);

  return (
    <TagChipComponent
      color={ color }
      onRemove={ handleRemove }
      onClick={ handleClick }
      label={ label }
    />
  );
};

export default TagChipContainer;
