
import {
  Accordion,
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Select,
  Skeleton,
  Tabs,
  Text,
  Textarea,
  TextInput,
  Title
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconSettings } from '@tabler/icons-react';
import { IconPhoto } from '@tabler/icons-react';
import { FC, useCallback, useState } from "react";

import DrawerControl from '@/common/components/DrawerControl';
import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import PhoneInput from "@/common/components/PhoneInput";
import { ReactComponent as CalendarIcon } from '@/common/icons/calendar-days.svg';
import { ReactComponent as Icon } from "@/common/icons/search-light.svg";
import { logger } from "@/common/util/ConsoleLogger";
import { useShowcaseStyles } from "@/tenant-context/showcase/Showcase.styles";


export const Showcase: FC = () => {
  const { classes } = useShowcaseStyles();

  const [phoneValue, setPhoneValue] = useState<string>('');

  const handleModal = useCallback(() => {
    openConfirmationModal({
      text: "Are you sure you want to remove this event?",
      title: "Confirm Removal",
      onConfirm: () => logger.log('Confirmed')
    });
  },[]);


  const onPhoneChange = useCallback((value: string) => {
    setPhoneValue(value);
  }, []);


  return (
    <div className={ classes.container }>
      <Title order={ 2 } mb={ 'md' }>Buttons</Title>

      { /* Buttons */ }
      <Box>
        { /* Primary Buttons */ }
        <Box mt="xl">
          <Title order={ 3 } mb="lg">Primary buttons</Title>
          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size="lg">Primary Large</Text>
            <Button>
              Button
            </Button>
            <Box style={ { width: '150px' } }>
              <Button fullWidth rightIcon={ <Icon/> }>
                Full width
              </Button>
            </Box>
            <Button rightIcon={ <Icon/> }>
              Button
            </Button>
            <Button>
              <Icon/>
            </Button>
            <Button disabled>
              Button
            </Button>
          </Box>
          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size="lg">Primary Medium</Text>
            <Button size="md" rightIcon={ <Icon/> }>
              Button
            </Button>
            <Button size='md' disabled>
              Button
            </Button>
          </Box>
          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size="lg">Primary Small</Text>
            <Button size="sm">
              Button
            </Button>
            <Button size='sm' disabled>
              Button
            </Button>
          </Box>
        </Box>
        { /* Secondary/Outline Buttons */ }
        <Box mt='xl'>
          <Title order={ 3 } mb='lg'>Secondary/Outline buttons</Title>
          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size='lg'>Secondary Large</Text>
            <Button variant='outline' rightIcon={ <Icon/> }>
              Button
            </Button>
            <Button variant='outline' disabled>
              Button
            </Button>
          </Box>
          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size='lg'>Secondary Medium</Text>
            <Button variant='outline' size='md'>
              Button
            </Button>
            <Button variant='outline' size='md' disabled>
              Button
            </Button>
          </Box>
          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size='lg'>Secondary Small</Text>
            <Button variant='outline' size='sm'>
              Button
            </Button>
            <Button variant='outline' size='sm' disabled>
              Button
            </Button>
          </Box>
        </Box>
        { /* Secondary/Outline Buttons */ }
        <Box mt='xl'>
          <Title order={ 3 } mb='lg'>Tertiary/Light buttons</Title>
          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size='lg'>Tertiary Large</Text>
            <Button variant='light' rightIcon={ <Icon/> }>
              Button
            </Button>
            <Button variant='light' disabled>
              Button
            </Button>
          </Box>
          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size='lg'>Tertiary Medium</Text>
            <Button variant='light' size='md'>
              Button
            </Button>
            <Button variant='light' size='md' disabled>
              Button
            </Button>
          </Box>
          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size='lg'>Tertiary Small</Text>
            <Button variant='light' size='sm'>
              Button
            </Button>
            <Button variant='light' size='sm' disabled>
              Button
            </Button>
          </Box>
        </Box>
        { /* Link/Subtle Buttons */ }
        <Box mt='xl'>
          <Title order={ 3 } mb='lg'>Link/Subtle buttons</Title>
          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size='lg'>Link Large</Text>
            <Button variant='subtle' rightIcon={ <Icon/> }>
              Button
            </Button>

            <Button variant='subtle' disabled>
              Button
            </Button>
          </Box>

          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size='lg'>Link Medium</Text>
            <Button variant='subtle' size='md'>
              Button
            </Button>

            <Button variant='subtle' size='md' disabled>
              Button
            </Button>
          </Box>

          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size='lg'>Link Small</Text>
            <Button variant='subtle' size='sm'>
              Button
            </Button>

            <Button variant='subtle' size='sm' disabled>
              Button
            </Button>
          </Box>
        </Box>

        { /* Destructive/Gradient Buttons */ }
        <Box mt="xl">
          <Title order={ 3 }>Destructive/Gradient</Title>
          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size='lg'>Destructive Large</Text>
            <Button variant='gradient' rightIcon={ <Icon/> }>
              Button
            </Button>

            <Button variant="gradient" disabled>
              Button
            </Button>
          </Box>

          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size="lg">Destructive Medium</Text>
            <Button variant="gradient" size="md">
              Button
            </Button>

            <Button variant="gradient" size="md" disabled>
              Button
            </Button>
          </Box>

          <Box className={ classes.variantWrapper }>
            <Text className={ classes.variantTitle } size="lg">Destructive Small</Text>
            <Button variant="gradient" size="sm">
              Button
            </Button>

            <Button variant="gradient" size="sm" disabled>
              Button
            </Button>
          </Box>
        </Box>
      </Box>

      <Divider/>

      { /* Action Icons */ }

      <Box>
        { /* Primary Action Icons */ }
        <Box mt="xl">
          <Title order={ 3 } mb="lg">Primary Action Icons</Title>
          <Box className={ classes.variantWrapper }>
            <ActionIcon><IconPhoto/></ActionIcon>
            <ActionIcon size="md"><IconPhoto/></ActionIcon>
            <ActionIcon size="sm"><IconPhoto/></ActionIcon>
            <ActionIcon disabled><IconPhoto/></ActionIcon>
          </Box>
          <Title order={ 3 } mb="lg">Secondary/outline Action Icons</Title>
          <Box className={ classes.variantWrapper }>
            <ActionIcon variant="outline"><IconPhoto/></ActionIcon>
            <ActionIcon variant="outline" size="md"><IconPhoto/></ActionIcon>
            <ActionIcon variant="outline" size="sm"><IconPhoto/></ActionIcon>
            <ActionIcon variant="outline" disabled><IconPhoto/></ActionIcon>
          </Box>
          <Title order={ 3 } mb="lg">Tertiary/light Action Icons</Title>
          <Box className={ classes.variantWrapper }>
            <ActionIcon variant="light"><IconPhoto/></ActionIcon>
            <ActionIcon variant="light" size="md"><IconPhoto/></ActionIcon>
            <ActionIcon variant="light" size="sm"><IconPhoto/></ActionIcon>
            <ActionIcon variant="light" disabled><IconPhoto/></ActionIcon>
          </Box>
          <Title order={ 3 } mb="lg">Destractive/gradient Action Icons</Title>
          <Box className={ classes.variantWrapper }>
            <ActionIcon variant="gradient"><IconPhoto/></ActionIcon>
            <ActionIcon variant="gradient" size="md"><IconPhoto/></ActionIcon>
            <ActionIcon variant="gradient" size="sm"><IconPhoto/></ActionIcon>
            <ActionIcon variant="gradient" disabled><IconPhoto/></ActionIcon>
          </Box>
        </Box>
      </Box>

      <Divider/>
      { /* Input */ }
      <Box>
        <Box mt="xl">
          <Title order={ 3 } mb="lg">Single line Input</Title>
          <Box className={ classes.variantWrapper }>
            <TextInput size="lg" label="Label"/>
            <Input.Wrapper label="Label">
              <Input size="lg" placeholder="Placeholder text" rightSection={ <CalendarIcon/> }/>
            </Input.Wrapper>
            <Input.Wrapper label="Label">
              <Input size="md" placeholder="Placeholder text" disabled/>
            </Input.Wrapper>
            <Input.Wrapper label="Label" error={ <span>Error</span> }>
              <Input size="sm" placeholder="Placeholder text" required/>
            </Input.Wrapper>
          </Box>

          <Title order={ 3 } mb="lg">Multi line Input</Title>
          <Box className={ classes.variantWrapper }>
            <Textarea size="lg" label="Label" placeholder="Placeholder text" required/>
            <Textarea size="md" label="Label" placeholder="Placeholder text" required/>
            <Textarea size="sm" label="Label" placeholder="Placeholder text" required/>
          </Box>

          <Title order={ 3 } mb="lg">Date Input</Title>
          <Box className={ classes.variantWrapper }>
            <DatePicker icon={ <CalendarIcon/> } size="lg" label="Label" placeholder="DD / MM / YY"/>
            <DatePicker icon={ <CalendarIcon/> } size="md" label="Label" placeholder="DD / MM / YY"/>
            <DatePicker icon={ <CalendarIcon/> } size="sm" label="Label" placeholder="DD / MM / YY"/>
          </Box>
        </Box>

      </Box>

      <Divider/>

      { /* Phone Input */ }
      <Box mt="xl">
        <Title order={ 3 } mb="lg">Phone Input</Title>
        <Box className={ classes.variantWrapper }>
          <PhoneInput size="lg" label="Label" placeholder="SASA" onChange={ onPhoneChange } value={ phoneValue }/>
          <PhoneInput error="Error message" size="md" label="Label" placeholder="dede" onChange={ onPhoneChange }
            value={ phoneValue }/>
          <PhoneInput isDisabled size="sm" label="Label" placeholder="dede" onChange={ onPhoneChange }
            value={ phoneValue }/>
        </Box>
      </Box>


      { /* Dropdown input */ }
      <Box mt="xl">
        <Title order={ 3 } mb="lg">Dropdown input</Title>
        <Box className={ classes.variantWrapper }>
          <Select
            size="lg"
            label="Your favorite framework/library"
            placeholder="Pick one"
            icon={ <Icon/> }
            data={ [
              { value: 'react', label: 'React' },
              { value: 'ng', label: 'Angular' },
              { value: 'svelte', label: 'Svelte' },
              { value: 'vue', label: 'Vue' }
            ] }/>
          <Select
            size="md"
            label="Your favorite framework/library"
            placeholder="Pick one"
            icon={ <Icon/> }
            data={ [
              { value: 'react', label: 'React' },
              { value: 'ng', label: 'Angular' },
              { value: 'svelte', label: 'Svelte' },
              { value: 'vue', label: 'Vue' }
            ] }/>
          <Select
            size="sm"
            label="Your favorite framework/library"
            icon={ <Icon/> }
            placeholder="Pick one"
            data={ [
              { value: 'react', label: 'React' },
              { value: 'ng', label: 'Angular' },
              { value: 'svelte', label: 'Svelte' },
              { value: 'vue', label: 'Vue' }
            ] }/>
        </Box>
      </Box>

      <Divider/>

      { /* Phone Input */ }
      <Box mt="xl">
        <Title order={ 3 } mb="lg">Phone Input</Title>
        <Box className={ classes.variantWrapper }>
          <PhoneInput size="lg" label="Label" placeholder="SASA" onChange={ onPhoneChange } value={ phoneValue }/>
          <PhoneInput error="Error message" size="md" label="Label" placeholder="dede" onChange={ onPhoneChange }
            value={ phoneValue }/>
          <PhoneInput isDisabled size="sm" label="Label" placeholder="dede" onChange={ onPhoneChange }
            value={ phoneValue }/>
        </Box>
      </Box>

      { /* Tabs */ }

      <Box mt="xl">
        <Title order={ 3 } mb="lg">Tabs outline Main</Title>
        <Box className={ classes.variantWrapper }>
          <Tabs variant="outline" defaultValue="basicInfo">
            <Tabs.List>
              <Tabs.Tab icon={ <IconSettings size={ 14 }/> } value="basicInfo">Basic info</Tabs.Tab>
              <Tabs.Tab icon={ <IconSettings size={ 14 }/> } value="severityLevels">Severity Levels</Tabs.Tab>
              <Tabs.Tab icon={ <IconSettings size={ 14 }/> } disabled value="Disabled">Disabled</Tabs.Tab>
            </Tabs.List>

          </Tabs>
        </Box>
      </Box>

      <Box mt="xl">
        <Title order={ 3 } mb="lg">Tabs default Sub</Title>
        <Box className={ classes.variantWrapper }>
          <Tabs variant="default" defaultValue="basicInfo">
            <Tabs.List>
              <Tabs.Tab value="basicInfo">Basic info</Tabs.Tab>
              <Tabs.Tab value="severityLevels">Severity Levels</Tabs.Tab>
              <Tabs.Tab value="Disabled" disabled>Disabled</Tabs.Tab>
            </Tabs.List>

          </Tabs>
        </Box>
      </Box>

      { /* Accordion */ }
      <Box mt="xl">
        <Accordion defaultValue="customization" variant="contained">
          <Accordion.Item value="customization">
            <Accordion.Control icon={ <IconPhoto /> }>Customization</Accordion.Control>
            <Accordion.Panel>Colors, fonts,
              shadows and many other parts are customizable to fit your design needs</Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="flexibility">
            <Accordion.Control>Flexibility</Accordion.Control>
            <Accordion.Panel>Configure components appearance and
              behavior with vast amount of settings or overwrite any part of component styles</Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>

      <Box mt="xl">
        <Accordion defaultValue="customization">
          <Accordion.Item value="customization">
            <Accordion.Control icon={ <IconPhoto /> }>Customization</Accordion.Control>
            <Accordion.Panel>Colors, fonts,
              shadows and many other parts are customizable to fit your design needs</Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="flexibility">
            <Accordion.Control>Flexibility</Accordion.Control>
            <Accordion.Panel>Configure components appearance and
              behavior with vast amount of settings or overwrite any part of component styles</Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>



      { /* Modals */ }

      <Box mt="xl">

        <Button onClick={ handleModal }>Show modal</Button>
      </Box>

      <Divider />

      { /* Drawers */ }
      <Box mt='xl'>
        <Title order={ 3 } mb='lg'>Drawers</Title>
        <Box className={ classes.variantWrapper }>
          <DrawerControl
            id={ 'showcase-drawer' }
            title={ 'Showcase' }
            drawerPosition={ 'right' }
            isSubDrawer={ true }
            header={ <Flex gap={ 4 } align={ 'flex-end' }>
              <Text fw={ 700 } className={ classes.bold }>12</Text>
              <Text className={ classes.light }>mins ago</Text>
            </Flex> }
            backButtonText={ 'This is back button' }
            // eslint-disable-next-line react/jsx-no-bind
            renderButton={ ({ onClick }) => <Button onClick={ onClick }>Open Drawer</Button> }
            footer={ <Button fullWidth>Footer button</Button> }
          >
            <Flex direction={ 'column' } gap={ 16 }>
              <Text>Child components</Text>
              <Skeleton height={ 100 } radius={ 8 }></Skeleton>
              <Skeleton height={ 100 } radius={ 8 }></Skeleton>
              <Skeleton height={ 100 } radius={ 8 }></Skeleton>
              <Skeleton height={ 100 } radius={ 8 }></Skeleton>
              <Skeleton height={ 100 } radius={ 8 }></Skeleton>
              <Skeleton height={ 100 } radius={ 8 }></Skeleton>
              <Skeleton height={ 100 } radius={ 8 }></Skeleton>
              <Skeleton height={ 100 } radius={ 8 }></Skeleton>
              <Skeleton height={ 100 } radius={ 8 }></Skeleton>
              <Skeleton height={ 100 } radius={ 8 }></Skeleton>
            </Flex>

          </DrawerControl>
        </Box>
      </Box>
    </div>
  );
};
