export const TAG_COLORS = [
  { color: '#0B60FE', borderColor: '#3D7FFD' },
  { color: '#2FB2DA', borderColor: '#6BD7F1' },
  { color: '#FFDD33', borderColor: '#FFE75F' },
  { color: '#FD7521', borderColor: '#FF913F' },
  { color: '#74BEAF', borderColor: '#99D7C1' },
  { color: '#9666F4', borderColor: '#AF8CFF' },
  { color: '#BA3B72', borderColor: '#E1568D' },
  { color: '#733B7D', borderColor: '#9C5094' },
  { color: '#4888FE', borderColor: '#77A6FF' },
  { color: '#85D3EA', borderColor: '#A7E2F4' },
  { color: '#FFEE99', borderColor: '#FFF8B6' },
  { color: '#FEB485', borderColor: '#FFCC9D' },
  { color: '#BADED7', borderColor: '#D6F2E2' },
  { color: '#D9C6FA', borderColor: '#EFD7FF' },
  { color: '#D883A8', borderColor: '#FBA5C1' },
  { color: '#C090CB', borderColor: '#D8ABD8' }
];

export const DEFAULT_INIT_COLOR = TAG_COLORS[0].color;
export const REMOTE_SEARCH_ENABLED = false;
