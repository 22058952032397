import { createModel } from "@rematch/core";

import { Dispatch, RootModel } from "@/core/store";
import { getAllCountriesRiskLevels } from "@/tenant-context/visualize-country-risk/api/country-risk";
import { CountryRiskLevel } from "@/tenant-context/visualize-country-risk/types/country-risk.types";

type CountryRiskLevelState = {
  countryRiskLevels: Array<CountryRiskLevel>;
}

const countryRiskLevelsModel = ({
  name: 'countryRiskLevels',
  state: {
    countryRiskLevels: []
  } as CountryRiskLevelState,

  reducers: {
    SET_COUNTRY_RISK_LEVELS(
      state: CountryRiskLevelState,
      payload: Array<CountryRiskLevel>
    ) {
      return {
        ...state,
        countryRiskLevels: payload
      };
    }
  },

  effects: (dispatch: Dispatch) => ({
    async fetchCountryRiskLevels(): Promise<void> {
      const riskLevelResponse = await getAllCountriesRiskLevels();
      const countryRiskLevels: Array<CountryRiskLevel> = riskLevelResponse.items.map(item => ({
        id: item.json.id,
        tid: item.tid,
        country: item.json.data.country.name,
        countryISO: item.json.data.country.iso_code,
        summary: item.json.data.summary,
        risk_level: item.json.data.risk_level,
        covid_risk_level: item.json.data.covid_risk_level,
        security_risk_level: item.json.data.security_risk_level,
        meta: item.meta
      }));

      dispatch.countryRiskLevels.SET_COUNTRY_RISK_LEVELS(countryRiskLevels);
    }
  })
});

export const countryRiskLevels = createModel<RootModel>()(
  countryRiskLevelsModel
);
