import { ActionIcon, Tooltip } from "@mantine/core";
import { openModal } from "@mantine/modals";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import UnauthorizedWarningModalComponent from "@/common/components/UnauthorizedWarningModal";
import usePermission from "@/common/hooks/usePermission";
import { ReactComponent as DeleteIconDisabled } from "@/common/icons/trash-outlined.svg";
import { DataGrid } from "@/common/types/ag-grid";
import { Dispatch, RootState } from "@/core/store";
import ProfileIdentificationTab
  from "@/tenant-context/control-profile/components/tabs/ProfileIdentificationTab/ProfileIdentificationTab.component";
import {
  useProfileIdentificationTabStyles
} from "@/tenant-context/control-profile/components/tabs/ProfileIdentificationTab/ProfileIdentificationTab.style";
import { ProfileRequiredPoliciesConfig } from "@/tenant-context/control-profile/config/ProfileRequiredPolicies.config";
import { AccessCard } from "@/tenant-context/control-profile/types/profile";

import { columnDefs as tableColumns } from './ProfileIdentificationTab.config';

const ProfileIdentificationTabContainer = () => {
  const hasSubscribedToAccessCardsManagement = useSelector((state: RootState) => state.profile?.isAccessCardPrivilege);
  const isNewAccessCardAssigned = useSelector((state: RootState) => state.profile?.isNewAccessCardAssigned);
  const {
    profile: {
      getAccessCardListWithProfileAssigment,
      unAssignAccessCardToProfile,
      SET_IS_NEW_ACCESS_CARD_ASSIGNED
    }
  } = useDispatch<Dispatch>();

  const ref = useRef<DataGrid>(null);
  const { classes } = useProfileIdentificationTabStyles();
  const deleteFullAccessPermissions = usePermission(ProfileRequiredPoliciesConfig.IDENTIFICATION_FULL_ACCESS);

  const handleUnAssignCard = useCallback(async (id: string) => {
    await unAssignAccessCardToProfile({
      tid: id
    });
    ref.current?.refreshGrid();
  }, [unAssignAccessCardToProfile]);

  const handleDeleteIconClicked = useCallback((accessCard: AccessCard) => {

    if (accessCard?.accessCardType === 'VISITOR') {
      openConfirmationModal({
        text: "Are you sure you want to un-assign this Access Card?",
        onConfirm: () => handleUnAssignCard(accessCard?._id ?? ''),
        title: "Confirm Un-Assignment"
      });
    } else {
      openModal({
        title: 'Confirm Assignment',
        children: (
          <UnauthorizedWarningModalComponent
            messageOptions={ { customMessage: 'Only VISITOR Access Card can be unassigned' } }
          />
        )
      });
    }
  }, [handleUnAssignCard]);

  const actionIconsCellRenderer = useCallback((accessCard: AccessCard) => {

    const toolTipLabel = () => {
      if (!hasSubscribedToAccessCardsManagement) {
        return 'This license does not have access to this feature, please get in touch with your administrator.';
      } else {
        return accessCard?.accessCardType !== 'VISITOR' ? 'You can\'t un-assign this Access Card' : 'Un-assign Access Card';
      }
    };

    const disableDeleteButton = () => {

      if (!deleteFullAccessPermissions) {
        return true;
      } else {
        if (accessCard?.accessCardType !== 'VISITOR') {
          return true;
        }
      }
    };

    return (
      <div className={ classes.iconContainer }>
        <Tooltip
          label={ toolTipLabel() }
          withinPortal>
          <div>
            <ActionIcon className={ classes.actionButton } disabled={ disableDeleteButton() }
              /* eslint-disable-next-line react/jsx-no-bind */
              onClick={ () => handleDeleteIconClicked(accessCard) }>
              <DeleteIconDisabled/>
            </ActionIcon>
          </div>
        </Tooltip>
      </div>
    );
  }, [classes.actionButton,
    classes.iconContainer, deleteFullAccessPermissions, handleDeleteIconClicked, hasSubscribedToAccessCardsManagement]);

  const columnDefs = tableColumns(actionIconsCellRenderer);

  useEffect(() => {
    if (isNewAccessCardAssigned) {
      ref.current?.refreshGrid();
      SET_IS_NEW_ACCESS_CARD_ASSIGNED(false);
    }
  }, [isNewAccessCardAssigned, SET_IS_NEW_ACCESS_CARD_ASSIGNED]);

  return (
    <ProfileIdentificationTab
      getData={ getAccessCardListWithProfileAssigment }
      ref={ ref }
      columnDefs={ columnDefs }
    />
  );
};

export default ProfileIdentificationTabContainer;
