import { callApi } from "@/common/util/api";
import {
  CountryDetailData,
  CountrySummaryDataType,
  QueryTravellersByCountryRequestData,
  QueryTravellersRequestData
} from "@/tenant-context/control-travellers-search/types/travelSearch.types";

export const queryTravellers = (
  queryTravellersRequest: QueryTravellersRequestData
): Promise<Array<CountrySummaryDataType>> => {

  const {
    pageNum,
    pageSize,
    orderBy,
    fromTime,
    toTime,
    destinationRiskLevel,
    mergeNonTenant
  } = queryTravellersRequest;

  const destinationRiskLevelQuery = Array.from(destinationRiskLevel).map((riskLevel) => `destinationRiskLevels=${riskLevel}`).join('&');

  const query = `pageNum=${pageNum}&pageSize=${pageSize}&orderBy=${orderBy}&fromTime=${fromTime}&toTime=${toTime}&${destinationRiskLevelQuery}&mergeNonTenant=${mergeNonTenant}`;

  return callApi(
    `travel-service/v1/travel-books/travellers?${ query }`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json;'
      },
      withTenantId: true
    }
  );
};

export const queryTravellersByCountry = (
  queryTravellersByCountryRequest: QueryTravellersByCountryRequestData
): Promise<CountryDetailData> => {

  const {
    pageNum,
    pageSize,
    orderBy,
    countryISOCode,
    fromTime,
    toTime,
    travellersType,
    destinationRiskLevel,
    mergeNonTenant
  } = queryTravellersByCountryRequest;

  const travellersTypeQuery = Array.from(travellersType).map((type) => `travellerTypes=${type}`).join('&');
  const destinationRiskLevelQuery = Array.from(destinationRiskLevel).map((riskLevel) => `destinationRiskLevels=${riskLevel}`).join('&');

  const query = `pageNum=${pageNum}&pageSize=${pageSize}${orderBy ? `&orderBy=${orderBy}` : ''}&fromTime=${fromTime}&toTime=${toTime}&${travellersTypeQuery}&${destinationRiskLevelQuery}&mergeNonTenant=${mergeNonTenant}`;

  return callApi(
    `travel-service/v1/travel-books/travellers/${ countryISOCode }?${ query }`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json;'
      },
      withTenantId: true
    }
  );
};
