import { Select } from "@mantine/core";
import { FC } from "react";

import { genericColorPalette } from "@/core/styles/mantine/palettes";
import { constructTestId } from "@/core/util/test-id";
import {
  roleFeaturesOptions
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/CreateRoleModal/CreateRoleModal.config";
import {
  useRoleDetailsStyles
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/RoleDetails/RoleDetails.styles";
import { CaseFeature } from "@/platform-context/control-icm-management/types/CaseTypes/caseFeatures";
import { RoleFeatures } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

const FORM_NAME = 'feature';

type Props = {
    onChange: (feature: Record<string, unknown>) => void
    disabled: boolean
    feature: CaseFeature
}

const Feature: FC<Props> = (
  {
    disabled,
    onChange,
    feature
  }
) => {
  const { description, customName, enable, permission } = feature;

  const { classes } = useRoleDetailsStyles();
  return (
    <div className={ classes.featureBlock }>
      <div>
        <h6 className={ classes.featureHeader }>{ customName } </h6>
        <p className={ classes.subText }>{ description }</p>
      </div>
      <div className={ classes.featureSection }>
        <Select
          data-testid={ constructTestId("SELECT", FORM_NAME, "roleFeature") }
          wrapperProps={ {
            style:{
              marginBottom: 0,
              backgroundColor: genericColorPalette.neutral[9],
              borderBottom: `1px solid ${genericColorPalette.primary[5]}`,
              borderTop: `1px solid ${genericColorPalette.neutral[9]}`,
              borderRight: `1px solid ${genericColorPalette.neutral[7]}`,
              borderLeft: `1px solid ${genericColorPalette.neutral[7]}`

            }
          } }
          disabled={ disabled || !enable }
          /* eslint-disable-next-line react/jsx-no-bind */
          onChange={ (val) => onChange({ [feature.identifier]: val })  }
          data={ roleFeaturesOptions }
          value={ permission || RoleFeatures.RESTRICTED }
        />
      </div>
    </div>
  );
};

export default Feature;