import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

export const useDrawingFilterRadioStyles = createStyles((theme: MantineTheme) => ({
  radioGroup: {
    gap: '16px'
  },
  radioItem: {
    display: 'flex',
    alignItems: 'center',

    '& > hr': {
      height: '1px',
      backgroundColor: theme.colors.neutral[6],
      border: 'none',
      flex: '.95'
    }
  }


}));
