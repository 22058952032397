export const PeopleGroupsPoliciesConfig = {
  PEOPLE_GROUPS_LANDING: {
    policyNames: [
      'Tenant_System_People-Groups_FullAccess',
      'Tenant_System_People-Groups_Readonly'
    ]
  },
  PEOPLE_GROUPS_VIEW_GROUP: {
    policyNames: [
      'Tenant_System_People-Groups_FullAccess',
      'Tenant_System_People-Groups_Readonly'
    ]
  },
  PEOPLE_GROUPS_FULL_ACCESS: {
    policyNames: [
      'Tenant_System_People-Groups_FullAccess'
    ]
  }
};
