import { createStyles, MantineTheme } from "@mantine/core";

type Params = {
  riskLevelTextColor?: string,
  riskLevelIconColor?:string
  isOnTheRight?: boolean
}
export const useRiskAlertBasicDataStyles = createStyles((theme: MantineTheme, params?: Params) => ({

  risInfoEffectFrom:{
    color:theme.colors.neutral[4],
    fontSize:theme.fontSizes.xs,
    '*': {
      fontSize:theme.fontSizes.xs
    }
  },

  riskInfoEffectFromCell:{
    verticalAlign: 'middle',
    backgroundColor:theme.white
  },

  boldText:{
    fontWeight:theme.other.fw.bold,
    color:theme.white
  },

  riskLevelIdText:{
    position: 'absolute',
    color: theme.white,
    fontSize:theme.fontSizes.xs,
    fontWeight:theme.other.fw.bold,
    marginLeft: '6px',
    marginTop:'4px'
  },

  riskLevelIcon:{
    position: 'relative',
    color: params?.riskLevelTextColor,
    '> *': {
      fill: params?.riskLevelIconColor
    }
  },

  riskLevelIconRoot: {
    height: 24,
    width: 24,
    display: 'flex',
    alignItems: 'flex-end'
  },

  riskLevelTriangle: {
    '> svg': {
      marginTop: 3
    },
    '> *': {
      fill: params?.riskLevelIconColor
    }
  },

  riskCategoryIcon: {
    position: 'absolute',
    height: 24,
    width: 24,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    '> svg': {
      height: 14,
      width: 14,
      '> *': {
        fill: params?.riskLevelTextColor
      }
    }
  },

  riskLevelName:{
    width:'60px',
    color: theme.colors.white[0],
    textTransform: 'uppercase',
    marginLeft: '6px'
  },

  riskAlertStatusContainer:{
    backgroundColor: theme.colors.neutral[9],
    margin: params?.isOnTheRight ? '0' : '36px 16px 0'
  },
  riscAlertStatusInnerGrid:{
    gap: 8
  },
  riskAlertStatusBar: {
    position: 'absolute',
    height: '100%',
    left: 0,
    top: 0,
    width: 4,
    flex: '0 0 4px',
    backgroundColor: params?.riskLevelIconColor
  },
  riskCriticalityRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px'
  },
  riskInfoRowDataminr: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs
  },
  riskInfoRow: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.neutral[5],

    '& > span:not(:empty)': {
      marginRight: '4px'
    }
  },
  trendCode: {
    marginLeft: '30px',
    fontWeight:theme.other.fw.bold
  },
  watchListTypeText: {
    color: theme.colors.white,
    marginLeft: '4px'
  }
}));
