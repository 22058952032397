import { FC } from "react";

import ControlContainer from "@/common/controls/ControlContainer/ControlContainer.component";
import usePermission from '@/common/hooks/usePermission';
import { LookupPoliciesConfig } from '@/tenant-context/control-lookup-tool/configs/Lookup.policies';
import TravelContextProvider from "@/tenant-context/control-travel/context/Travel.context";
import TravelItineraryControl from "@/tenant-context/control-travel/controls/TravelItenararyControl";
import CountryInfoBox from "@/tenant-context/widget-overview/components/CountryInfoBox";
import LookupInitTool from '@/tenant-context/widget-overview/components/LookupInitTool';
import PeopleInfoBox from '@/tenant-context/widget-overview/components/PeopleInfoBox';
import SiteInfoBox from "@/tenant-context/widget-overview/components/SitesInfoBox";
import { useOverviewWidgetStyles } from "@/tenant-context/widget-overview/controls/OverviewWidget/OverviewWidget.styles";

import TravelSearchInfoBox from "../../components/TravelSearchInfoBox";

type Props = {
  isSubscribedToTravel: boolean,
  isSitesAvailable: boolean,
  isTravelSearchDrawerOpen: boolean,
  isCountryWiseTravellersDataAvailable: boolean
}

const OverviewWidgetComponent: FC<Props> = ( {
  isSubscribedToTravel,
  isSitesAvailable,
  isTravelSearchDrawerOpen,
  isCountryWiseTravellersDataAvailable
}) => {
  const { classes } = useOverviewWidgetStyles();

  const isTravellerSearchActive = isTravelSearchDrawerOpen && isCountryWiseTravellersDataAvailable;
  const isAuthorizedLookup = usePermission(LookupPoliciesConfig.LOOKUP_ASSERT_RANKING_READONLY);

  return (
    <ControlContainer
      containerId="top-left-control-container"
      position="top-left"
      layout="horizontal"
    >
      <TravelContextProvider>
        <div className={ classes.topLeftContainer }>
          { !isTravellerSearchActive && <CountryInfoBox/> }
          { !isTravellerSearchActive && <SiteInfoBox /> }
          { !isTravellerSearchActive && <PeopleInfoBox isSitesAvailable={ isSitesAvailable }/> }
          { /*Temporary removed as product requirement*/ }
          { /*{ isSubscribedToTravel && !isTravellerSearchActive && <TravelInfoBox/> }*/ }
          { isSubscribedToTravel && isTravellerSearchActive &&
            <TravelSearchInfoBox/> }
          { !isTravellerSearchActive && isAuthorizedLookup && <LookupInitTool/> }
        </div>
        <TravelItineraryControl />
      </TravelContextProvider>
    </ControlContainer>
  );
};

export default OverviewWidgetComponent;
