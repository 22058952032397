import { Drawer } from "@mantine/core";
import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import CreateCommsPage from "@/tenant-context/control-mass-comms/components/CreateCommsPage";
import { useMassCommsStyles } from "@/tenant-context/control-mass-comms/components/MassComms/MassComms.styles";
import MassCommsFooter from "@/tenant-context/control-mass-comms/components/MassCommsFooter";
import MassCommsHeader from "@/tenant-context/control-mass-comms/components/MassCommsHeader";
import SelectRecipientsPage from "@/tenant-context/control-mass-comms/components/SelectRecipientsPage";
import { massCommsDefaultState } from "@/tenant-context/control-mass-comms/store/MassComms.model";
import { MASS_COMMS_STEPS } from "@/tenant-context/control-mass-comms/types/MassComms.types";
import RiskAlertDetails from "@/tenant-context/control-risk-config/components/RiskAlertDetails";

import SummaryPage from "../SummaryPage";

const MassCommsComponent: FC = () => {
  const currentStep = useSelector((state: RootState) => state.massComms.currentStep);
  const massCommsNavigation = useSelector((state: RootState) => state.massComms.massCommsNavigation);

  const {
    massComms: {
      navigateToMassComms,
      getAllTemplates,
      getAlertSequenceNumber,
      getImpactedPeople,
      populateImpactedPeopleToSelectedRecipients,
      populatePeoplesToRecipientListByLookupKey,
      SET_IMPACT_RESPONSE_ITEMS,
      SET_SELECTED_RECIPIENT_TYPE,
      RESET_MASS_COMMS_TO_DEFAULT_STATE
    }
  } = useDispatch<Dispatch>();
  const { classes } = useMassCommsStyles(massCommsNavigation.isSliderOpen);

  useEffect(() => {
    getAllTemplates();
  }, [ getAllTemplates ]);

  useEffect(() => {
    return () => {
      RESET_MASS_COMMS_TO_DEFAULT_STATE(massCommsDefaultState);
    };
  }, [RESET_MASS_COMMS_TO_DEFAULT_STATE]);

  useEffect(() => {
    (async () => {
      if (massCommsNavigation.fromArc) {
        getAlertSequenceNumber();
        const impactedPeople = await getImpactedPeople();
        // Setting the impact response items to true for all the impacted people and calling the
        // populateImpactedPeopleToSelectedRecipients to pre-populate all the impacted people to the
        // selected recipients list.
        SET_SELECTED_RECIPIENT_TYPE('impactSelect');
        SET_IMPACT_RESPONSE_ITEMS({
          allImpacted: { isChecked: true, people: impactedPeople.allImpacted.people },
          notSafe: { isChecked: true, people: impactedPeople.notSafe.people },
          noResponse: { isChecked: true, people: impactedPeople.noResponse.people },
          okPeople: { isChecked: true, people: impactedPeople.okPeople.people },
          otherPeople: { isChecked: true, people: impactedPeople.otherPeople.people }
        });
        populateImpactedPeopleToSelectedRecipients();
      } else if (massCommsNavigation.from === 'look-up' && massCommsNavigation.lookupKey) {
        populatePeoplesToRecipientListByLookupKey(massCommsNavigation.lookupKey);
      }
    })();
  }, [
    SET_IMPACT_RESPONSE_ITEMS,
    SET_SELECTED_RECIPIENT_TYPE,
    getAlertSequenceNumber,
    getImpactedPeople,
    massCommsNavigation.fromArc,
    massCommsNavigation.from,
    populateImpactedPeopleToSelectedRecipients,
    massCommsNavigation.lookupKey,
    populatePeoplesToRecipientListByLookupKey
  ]);

  const handleSliderClose = useCallback(
    () => navigateToMassComms({ ...massCommsNavigation, isSliderOpen: false }),
    [navigateToMassComms, massCommsNavigation]
  );

  return (
    <div>
      <Drawer size={ '335px' }
        className={ classes.riskAlertDrawer }
        opened={ massCommsNavigation.isSliderOpen }
        onClose={ handleSliderClose }
        withOverlay={ false }
        withCloseButton={ false }
      >
        <RiskAlertDetails
        />
      </Drawer>
      <div className={ classes.container }>
        <LocalErrorBoundary>
          <MassCommsHeader/>

          <div className={ classes.page }>

            { currentStep === MASS_COMMS_STEPS.CREATE_COMMS && (
              <CreateCommsPage/>
            ) }

            { currentStep === MASS_COMMS_STEPS.RECIPIENT_SELECTION && (
              <SelectRecipientsPage/>
            ) }

            { currentStep === MASS_COMMS_STEPS.SUMMARY && (
              <SummaryPage/>
            ) }

          </div>

          <MassCommsFooter/>
        </LocalErrorBoundary>
      </div>
    </div>
  );
};

export default MassCommsComponent;
