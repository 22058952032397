import { MapboxMap, MapRef } from "react-map-gl";

import { staticLocationMapIcons } from "@/common/icons/MapIconConfigs/static-location-icons";
import useMapIcons from "@/tenant-context/common/hooks/useMapIcons";

const useCommonLocationIcons = (
  map: MapboxMap | MapRef | undefined,
  isOverrideIcons = false
) =>  useMapIcons(staticLocationMapIcons, map, isOverrideIcons);

export default useCommonLocationIcons;
