import { createStyles } from "@mantine/core";

export const useIconTextMarkerStyles = createStyles((theme) => ({
  iconContainer: {
    position: 'relative',
    height: '30px',
    width: '36px'
  },
  iconText: {
    position: 'absolute',
    top: '1px',
    color: theme.colors.blue[6],
    fontSize: '11px',
    height: '30px',
    width: '30px',
    fontWeight: theme.other.fw.bold,
    textAlign: 'center'
  }
}));
