// This is false-positive for tabMap
/* eslint-disable react/no-multi-comp */
import { FC, ReactElement } from "react";

import { TestableComponent } from "@/common/types/testable-component";
import { GroupType } from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/ProfileGroupsModal.component";
import AddToExistingGroup from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/tabs/AddToExistingGroup";
import AddToNewGroup from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/tabs/AddToNewGroup";

type Props = {
  activeTab: GroupType
} & TestableComponent;

const ProfileGroupsModalTabMapper: FC<Props> = ({
  activeTab,
  dataTestId
}) => {
  // Mapping profile tabs to render functions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tabMap: Record<GroupType, () => ReactElement<any, any> | null> = {
    [GroupType.Existing]: () => ( <AddToExistingGroup dataTestId={ `${dataTestId}-existing` } /> ),
    [GroupType.New]: () => ( <AddToNewGroup dataTestId={ `${dataTestId}-new` }  /> )
  };

  return tabMap[activeTab]();
};

export default ProfileGroupsModalTabMapper;