import { FC } from "react";

import {
  useSearchInputRightSectionStyles
} from "@/tenant-context/control-profile/components/ui/ProfileAccessCardSearch/components/SearchInputRightSection/SearchInputRightSection.styles";

type Props = {
  handleSearchResetButton: () => void
}

const SearchInputRightSection: FC<Props> = ({ handleSearchResetButton }) => {

  const { classes } = useSearchInputRightSectionStyles();
  return (
    <button className={ classes.button } onClick={ handleSearchResetButton }>x</button>
  );
};

export default SearchInputRightSection;
