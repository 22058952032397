import { Box, Button, Flex } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as DownArrow } from "@/common/icons/Arrows/down-arrow-pointed.svg";
import { Dispatch, RootStateWithLoading } from '@/core/store';
import RelatedEventPopup from '@/tenant-context/control-risk-config/components/RelatedEventPopup';
import RelatedEventListItem
  from '@/tenant-context/control-risk-config/components/RiskAlertDetails/RelatedEventListItem.component';
import {
  RiskAlertDetailsStyles
} from '@/tenant-context/control-risk-config/components/RiskAlertDetails/RiskAlertDetails.styles';
import { GeneralizedRelatedEvent } from '@/tenant-context/control-risk-config/types/risk';

type Props = {
  riskAlertTid?: string
  relatedEventsList: GeneralizedRelatedEvent[]
  relatedEventsCount: number
  selectedEventAlertId?: string
  isInArc: boolean
  provider: 'max-security' | 'dataminr'
  isRelatedEventsTabSelected: boolean
}

const RelatedEventList: FC<Props> = ({
  riskAlertTid,
  relatedEventsList,
  relatedEventsCount,
  selectedEventAlertId,
  isInArc,
  provider,
  isRelatedEventsTabSelected
}) => {
  const isRelatedEventsLoading = useSelector(
    (state: RootStateWithLoading) => state.loading.effects.eventTimeLine.loadRelatedEventsLazyLoadedList
  );

  const divRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { classes } = RiskAlertDetailsStyles({ isInArc: isInArc, isOverlayOpen: false });

  const { eventTimeLine: {
    loadRelatedEventsLazyLoadedList,
    SET_SELECTED_EVENT_ALERT_ID,
    SET_SELECTED_RELATED_EVENT
  } } = useDispatch<Dispatch>();

  const handleEventClick = useCallback((relatedEvent: GeneralizedRelatedEvent) => {
    closeAllModals();
    openModal({
      children: <RelatedEventPopup/>,
      withCloseButton: false,
      className: classes.relatedEventModal,
      overlayOpacity: isInArc ? undefined : 0,
      onClose: () => {
        SET_SELECTED_EVENT_ALERT_ID(undefined);
        SET_SELECTED_RELATED_EVENT(undefined);
      }
    });
    SET_SELECTED_EVENT_ALERT_ID(relatedEvent.id);
    SET_SELECTED_RELATED_EVENT(relatedEvent);
  }, [classes.relatedEventModal, isInArc, SET_SELECTED_EVENT_ALERT_ID, SET_SELECTED_RELATED_EVENT]);

  const loadNextPage = useCallback(() => {
    if (!riskAlertTid) {
      return;
    }

    loadRelatedEventsLazyLoadedList({ parentEventId: riskAlertTid, provider: provider });
  }, [loadRelatedEventsLazyLoadedList, provider, riskAlertTid]);

  const handleScroll = useCallback(() => {
    if (isRelatedEventsLoading) {
      return;
    }

    if (!divRef.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = divRef.current;
    if (scrollTop + clientHeight + 2 >= scrollHeight) {
      loadNextPage();
    }
  }, [isRelatedEventsLoading, loadNextPage]);

  const syncContainerHeightAndPosition = useCallback(() => {
    if (isInArc) {
      return;
    }

    const rect = containerRef?.current?.getBoundingClientRect();

    if (rect) {
      // 80 in below line is the height of the load more button and the padding of the related events list container
      // 80 = 48 (height of the load more button) + 24 (padding top) + 8 (gap)
      const calculatedHeight = rect.height - ((relatedEventsList.length !== relatedEventsCount) ? 80 : 24);
      const distanceFromTop = rect.top + 24;
      if (calculatedHeight > 0) {
        divRef.current?.setAttribute('style', `height: ${calculatedHeight}px; top: ${distanceFromTop}px`);
      } else {
        divRef.current?.setAttribute('style', `height: 122px; top: ${distanceFromTop}px`);
      }
    }
  }, [isInArc, relatedEventsList, relatedEventsCount]);

  useEffect(() => {
    syncContainerHeightAndPosition();
  }, [
    isInArc,
    riskAlertTid,
    relatedEventsList,
    relatedEventsCount,
    isRelatedEventsTabSelected,
    syncContainerHeightAndPosition
  ]);

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    window.addEventListener('resize', syncContainerHeightAndPosition);
    document.addEventListener('scroll', syncContainerHeightAndPosition, true);

    return () => {
      window.removeEventListener('resize', syncContainerHeightAndPosition);
      document.removeEventListener('scroll', syncContainerHeightAndPosition, true);
    };
  }, [syncContainerHeightAndPosition]);

  return (
    <Flex gap={ 8 } direction={ 'column' } className={ classes.flexGrowFull } ref={ containerRef } mih={ 192 }>
      <Box className={ classes.flexGrowFull } mt={ 'xl' }>
        <Box className={ classes.relatedEventsListContainer } ref={ divRef }>
          { relatedEventsList.map((relatedEvent, index) => {
            return <RelatedEventListItem
              relatedEvent={ relatedEvent }
              key={ index }
              index={ index }
              relatedEventsCount={ relatedEventsList.length }
              onClick={ handleEventClick }
              selectedEventAlertId={ selectedEventAlertId }
              isInArc={ isInArc }
            />;
          }) }
        </Box>
      </Box>
      { !(relatedEventsList.length === relatedEventsCount) && <Flex
        align={ 'center' }
        justify={ 'center' }
      >
        <Button
          rightIcon={ !isRelatedEventsLoading ? <DownArrow /> : undefined }
          variant={ 'subtle' }
          onClick={ loadNextPage }
          disabled={ isRelatedEventsLoading }
        >
          { isRelatedEventsLoading ? 'Loading...' : 'Load more' }
        </Button>
      </Flex> }
    </Flex>
  );
};

export default RelatedEventList;
