import { callApi } from "@/common/util/api";
import { getRegularToken } from '@/tenant-context/control-profile/api/profile';
import { Config } from '@/tenant-context/core/types/config';

export const getSessionTimeout = () => callApi<Config>(
  'managed/platform-service/v1/config-group-entities/tenantConfig/config-entities/idleTimeout',
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${ getRegularToken() }`
    },
    withTenantId: true,
    withAuthorization: false
  }
);
