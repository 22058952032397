import { FC, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock/ProfileBlock.component";
import ProfileKeyValue from "@/tenant-context/control-profile/components/ui/ProfileKeyValue/ProfileKeyValue.component";
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock/ProfileKeyValueBlock.component";
import { ProfileGeneralSection } from "@/tenant-context/control-profile/types/profile";

type Props = {
  createAction?: (section: ProfileGeneralSection) => () => ReactNode;
};

const ProfileAttributes: FC<Props> = ({
  createAction
}) => {
  const {
    profile: {
      loadPersonalAttributes
    }
  } = useDispatch<Dispatch>();

  const personalAttributes = useSelector(
    ({ profile: { personalAttributes: pa } }: RootState) => pa
  );

  const profileId = useSelector(
    ({ profile: { id } }: RootState) => id
  );

  useEffect(() => {
    loadPersonalAttributes();
  }, [loadPersonalAttributes, profileId]);

  return (
    <ProfileBlock
      heading="Attributes"
      renderAction={ createAction?.(ProfileGeneralSection.Attributes) }
    >
      <ProfileKeyValueBlock
        gtc={ '1fr 2fr' }
        maxColumns={ 2 }
      >
        <ProfileKeyValue firstItem="label" name={ 'Eye Colour' } value={ personalAttributes?.eyeColour } />
        <ProfileKeyValue firstItem="label" name={ 'Hair Colour' } value={ personalAttributes?.hairColour } />
        <ProfileKeyValue firstItem="label" name={ 'Ethnicity' } value={ personalAttributes?.ethnicity } />
        <ProfileKeyValue firstItem="label" name={ 'Build' } value={ personalAttributes?.build } />
      </ProfileKeyValueBlock>
    </ProfileBlock>
  );
};

export default ProfileAttributes;