import { Brush } from '@visx/brush';
import BaseBrush from '@visx/brush/lib/BaseBrush';
import { Bounds } from '@visx/brush/lib/types';
import { PatternLines } from '@visx/pattern';
import { scaleLinear,scaleTime } from '@visx/scale';
import { useMemo,useRef } from 'react';

import { semanticColorPalette } from '@/core/styles/mantine/palettes';
import { AssetRankingEventGroup } from '@/tenant-context/control-location-graph/util/groupBy';
import { MAX_RANK } from '@/tenant-context/core/config/ranking';

import AreaChart from './AreaChart';

const PATTERN_ID = 'brush_pattern';
export const background2 = semanticColorPalette.accent[1];
const selectedBrushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: semanticColorPalette.label[1]
};

const brushMargin = {
  top: 0,
  bottom: 15,
  left: 50,
  right: 20
};

export type BrushProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  data: AssetRankingEventGroup[],
  onChange: (domaing: Bounds | null) => void,
  startTime: number,
  endTime: number,
};

function BrushChart({
  width,
  height,
  data,
  onChange,
  startTime,
  endTime
}: BrushProps) {
  const brushRef = useRef<BaseBrush | null>(null);

  // bounds
  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0);
  const yBrushMax = height;

  // Brush scales
  const brushXScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: [startTime, endTime]
      }),
    [endTime, startTime, xBrushMax]
  );
  const brushYScale = useMemo(
    () =>
      scaleLinear({
        range: [height, 0],
        domain: [0, MAX_RANK]
      }),
    [height]
  );

  return (
    <svg width={ width } height={ height }>
      { /* Data */ }
      <AreaChart
        hideBottomAxis
        hideLeftAxis
        data={ data }
        width={ width }
        yMax={ yBrushMax }
        xScale={ brushXScale }
        yScale={ brushYScale }
        margin={ brushMargin }
        top={ 0 }
        gradientColor={ background2 }
      >
        <PatternLines
          id={ PATTERN_ID }
          height={ 8 }
          width={ 8 }
          stroke={ 'white' }
          strokeWidth={ 1 }
          orientation={ ['diagonal'] }
        />

        { /* Brush */ }
        <Brush
          xScale={ brushXScale }
          yScale={ brushYScale }
          width={ xBrushMax }
          height={ height }
          margin={ brushMargin }
          handleSize={ 8 }
          innerRef={ brushRef }
          resizeTriggerAreas={ ['left', 'right'] }
          brushDirection="horizontal"
          onChange={ onChange }
          selectedBoxStyle={ selectedBrushStyle }
          useWindowMoveEvents
        />
      </AreaChart>
    </svg>
  );
}

export default BrushChart;