import { Select, Textarea } from "@mantine/core";
import { FC, useCallback, useRef, useState } from "react";

import ModalControlGroup from "@/common/components/ModalControlGroup";
import {
  useImpactStatusChangeModalStyles
} from "@/tenant-context/control-action-response-center/components/ImpactTab/components/ImpactStatusChangeModal/ImpactStatusChangeModal.styles";
import { ArcImpactStatus } from "@/tenant-context/control-action-response-center/types/ARC.types";

type Props = {
  personName: string | undefined,
  status: ArcImpactStatus | undefined,
  onSubmit: (personStatus: ArcImpactStatus, notes: string) => void,
  onCancel: () => void
}

export const ImpactStatusChangeModalComponent: FC<Props> = ({
  personName,
  status,
  onSubmit,
  onCancel
}) => {
  const { classes } = useImpactStatusChangeModalStyles();
  const statusSelect = useRef<HTMLInputElement>(null);
  const notesInput = useRef<HTMLTextAreaElement>(null);

  const [ selectedStatus, setSelectedStatus ] = useState(status);

  const onStatusChange = useCallback((personStatus: ArcImpactStatus) => setSelectedStatus(personStatus), []);

  const handleSubmit = useCallback(() => {
    if (!selectedStatus) {
      return;
    }

    onSubmit(
      selectedStatus,
      notesInput.current?.value || ''
    );
  }, [ onSubmit, selectedStatus ]);

  return (
    <div>
      <div className={ classes.personName }>{ personName }</div>

      <div className={ classes.inputGroup }>
        <Select
          ref={ statusSelect }
          placeholder="Pick one"
          label="Safety Status"
          value={ selectedStatus }
          onChange={ onStatusChange }
          data={ [
            { value: 'NOT_SAFE', label: 'Not Safe' },
            { value: 'NO_RESPONSE', label: 'No Response' },
            { value: 'SAFE', label: 'Safe' },
            { value: 'OTHER', label: 'Other' }
          ] }
        />
      </div>

      <div className={ classes.inputGroup }>
        <Textarea
          ref={ notesInput }
          label="Note"
          placeholder="Note here"
        />
      </div>

      <ModalControlGroup
        onPrimary={ handleSubmit }
        primaryActionLabel={ 'Change Status' }

        onSecondary={ onCancel }
        secondaryLabel={ 'Cancel' }
      />
    </div>
  );
};
