import { ControlGroupFilterType, ControlGroupProfilesFilterType } from "@/tenant-context/control-groups/types/group";

export const isEmptyQueryString = (initialString: string, searchString: string) => initialString.length === 0 ? searchString : 'AND' + searchString;

export const peopleGroupsSearchQuery = (
  filterData?: ControlGroupFilterType
) => {
  // eslint-disable-next-line fp/no-let
  let str = '';
  if (filterData?.searchQuery) {
    str = str + isEmptyQueryString(str, `name LIKE %22%25${filterData?.searchQuery}%25%22`);
  }
  if (filterData?.allServicesFilter) {
    str = str + isEmptyQueryString(str, `isApplicableToAllService = ${filterData?.allServicesFilter}`);
  }
  if (filterData?.statusFilter) {
    str = str + isEmptyQueryString(str, `isActive = ${filterData?.statusFilter}`);
  }
  if (filterData?.applicableServicesFilter && filterData?.applicableServicesFilter.length > 0) {
    str = str + isEmptyQueryString(str, `%28applicableServicesList%3Aarray IN %5B${filterData?.applicableServicesFilter.map((item) => `"${item}"`).join('%2C')}%5D%29`);
  }

  return str.length > 0 ? `&search=${str}` : '';
};

export const profileInPeopleGroupSearchQuery = (filterData?: ControlGroupProfilesFilterType) => {
  return filterData?.name ? `&search=(name LIKE %22%25${filterData?.name}%25%22 OR firstName LIKE %22%25${filterData?.name}%25%22 OR  lastName LIKE %22%25${filterData?.name}%25%22 OR profileId LIKE %22%25${filterData?.name}%25%22)` : '';
};



