
import { ReactComponent as CriticalRiskIcon } from "@/common/icons/bsocRiskLevelCriticalIcon.svg";
import { ReactComponent as NonCriticalRiskIcon } from "@/common/icons/bsocRiskLevelNonCriticalIcon.svg";
import { ReactComponent as Crime } from "@/common/icons/crime.svg";
import { ReactComponent as HighImpactExplosiveActivity } from "@/common/icons/HighImpactExplosiveActivity.svg";
import { ReactComponent as IDFUAVActivity } from "@/common/icons/IDFUAVActivity.svg";
import { ReactComponent as IED } from "@/common/icons/IED.svg";
import { ReactComponent as SAF } from "@/common/icons/SAF.svg";
import { ReactComponent as SecurityOperations } from "@/common/icons/SecurityOperations.svg";
import { ReactComponent as ProtestsAndGatherings } from "@/common/icons/unrest.svg";
import { BSOC_CATEGORY_PREFIX } from "@/tenant-context/common/util/risk";
import { Sigact } from "@/tenant-context/visualisation-risk-alerts/types/risk-alert-response";

export const riskCategories = [
  {
    id: Sigact.SAF,
    name: 'Small Arms Fire',
    Icon: SAF,
    categoryId: Sigact.SAF
  },
  {
    id: Sigact.Crime,
    name: 'Crime',
    Icon: Crime,
    categoryId: Sigact.Crime
  },
  {
    id: Sigact.ProtestsAndGatherings,
    name: 'Protest & Gatherings',
    Icon: ProtestsAndGatherings,
    categoryId: Sigact.ProtestsAndGatherings
  },
  {
    id: Sigact.IED,
    name: 'IED & Incendiary Devices',
    Icon: IED,
    categoryId: Sigact.IED
  },
  {
    id: Sigact.HighImpactExplosiveActivity,
    name: 'High Impact IED Activity',
    Icon: HighImpactExplosiveActivity,
    categoryId: Sigact.HighImpactExplosiveActivity
  },
  {
    id: Sigact.IDFUAVActivity,
    name: 'IDF & UAV Activity',
    Icon: IDFUAVActivity,
    categoryId: Sigact.IDFUAVActivity
  },
  {
    id: Sigact.SecurityOperations,
    name: 'Security Operations',
    Icon: SecurityOperations,
    categoryId: Sigact.SecurityOperations
  },
  {
    id: Sigact.Other,
    name: 'Other',
    Icon: SecurityOperations,
    categoryId: Sigact.Other
  }
].map(category => ({
  ...category,
  id: BSOC_CATEGORY_PREFIX + category.id,
  categoryId: BSOC_CATEGORY_PREFIX + category.id
}));


export const riskLevels = [
  {
    id: 'critical',
    name: 'Critical event',
    Icon: CriticalRiskIcon
  },
  {
    id: 'nonCritical',
    name: 'Non-Critical event',
    Icon: NonCriticalRiskIcon
  }
];