import { Button } from '@mantine/core';
import { FC } from "react";

import ControlContainer from '@/common/controls/ControlContainer';
import { useManageSiteControlStyles } from "@/tenant-context/plugin-indoors/controls/ManageSiteControl/ManageSiteControl.styles";

const ManageSiteControl: FC = () => {
  const { classes } = useManageSiteControlStyles();

  // No-op button temporarily removed
  return null;

  return (
    <ControlContainer
      containerId={ 'TopRightContainer' }
      position="top-left"
    >
      <Button className={ classes.controlButton }>
        Manage this site
      </Button>
    </ControlContainer>
  );
};

export default ManageSiteControl;
