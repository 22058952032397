import { formatMonth } from "@/common/util/format/date";

import { MassCommsEmailResponseItem } from "../types/MassComms.types";


export const constructProfileSearchQueryByProfileId = (profileIds: Array<string>) => {
  if (!profileIds || profileIds.length === 0) {
    return '';
  }

  const profileIdQuery = profileIds.map((profileId) => `profileId='${profileId}'`).join(' OR ');
  return `&search=(${encodeURIComponent(profileIdQuery)})`;
};

export const generateResponseMethodsMarkup =
 (responseMethods: MassCommsEmailResponseItem[], tenantId: string): string => {
   if (responseMethods.length === 0) {
     return '';
   }
   // Inserting response methods markup into email template body
   const resMethods = responseMethods.reduce((prevItem, currentItem, _currentIndex) => prevItem + `
            <td align="center">
            <table cellpadding="0" cellspacing="0" border="0" style="display: inline-block;">
              <tr>
                <td align="center" bgcolor=${currentItem.buttonColor} style="padding: 10px;min-width:110px;width:110px">
                  <a href="{{ GLOBAL_HOST }}/mass-comms/response?reference={{ REFERENCE }}&response=${ currentItem.responseLabel }&tenant=${ tenantId }" style="color: #ffffff; text-decoration: none;">${currentItem.buttonLabel}</a>
                </td>
              </tr>
            </table>
          </td>
          <td width="10"></td>
`, '');

   return `<table cellpadding="0" cellspacing="0" border="0" align="center" width="100%">
          <tr>
          <td>&nbsp;</td>
          </tr>
          <tr>
          <td align="center">
          <table cellpadding="0" cellspacing="0" border="0" align="center">
          <tr>
          ${resMethods}
          </tr>
          </table>                          
          </tr>
          </table>`.replace(/"/g, "'");
 };

export const generateSearchForRecipientsQuery = (query = '') => {
  return query ? `&search=(name LIKE %22%25${query}%25%22 OR firstName LIKE %22%25${query}%25%22 OR  lastName LIKE %22%25${query}%25%22 OR email LIKE %22%25${query}%25%22 OR profileId LIKE %22%25${query}%25%22)` : '';
};

export const formatToNormalDate = (date: Date): string => {

  const DOUBLE_DIGIT_NUMBER = 10;

  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();

  const h = date.getUTCHours();
  const min = date.getUTCMinutes();
  const m = min < DOUBLE_DIGIT_NUMBER ? `0${min}` : min;

  return (
    `${day} ${formatMonth(month)} ${year} - ${h}:${m}`
  );
};

export const mcReportsStreamRetryValidation = (event: MessageEvent | null, isDataReceived: boolean): boolean => {

  if (event === null || !isDataReceived) {
    return true;
  }

  if (isDataReceived) {
    return false;
  }

  const eventMessage = JSON.parse(event.data);

  if (eventMessage._type === undefined) {
    return false;
  }

  return eventMessage._type === 'HB';
};

export const getPlainTextFromHtml = (html: string): string => {
  return html.replace(/<[^>]*>/g, '').trim();
};
