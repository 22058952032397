import { FC } from "react";

import { ReactComponent as SiteIcon } from "@/common/icons/site.svg";
import {
  useImpactedAssetCardStyles
} from "@/tenant-context/control-action-response-center/components/ImpactSummary/ImpactedAssetCard/ImpactedAssetCard.styles";

type Props = {
  siteName: string;
  siteAddress: string;
}

const ImpactedAssetCard: FC<Props> = ({
  siteName,
  siteAddress
}) => {

  const { classes } = useImpactedAssetCardStyles();

  return (
    <div className={ classes.card }>
      <div className={ classes.siteInfoWrapper }>
        <div className={ classes.siteIcon }>
          <SiteIcon />
        </div>
        <div className={ classes.siteInfo }>
          <h6 className={ classes.siteName }>{ siteName }</h6>
          <div
            className={ classes.siteAddress }
            title={ siteAddress }
          >
            { /* { siteAddress } */ }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactedAssetCard;
