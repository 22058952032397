import { FileWithPath } from "@mantine/dropzone";
import { FC, useCallback, useEffect, useRef } from "react";
import { FileRejection } from 'react-dropzone';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import usePermission from "@/common/hooks/usePermission";
import { toHumanReadableFileSize } from "@/common/util/file-size";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import ProfileProfileTab from "@/tenant-context/control-profile/components/tabs/ProfileProfileTab/ProfileProfileTab.component";
import { ProfileRequiredPoliciesConfig } from "@/tenant-context/control-profile/config/ProfileRequiredPolicies.config";
import { ReferenceType } from "@/tenant-context/control-profile/types/profile";

const ProfileProfileTabContainer: FC = () => {
  const {
    profile: {
      SET_PROFILE_ID,
      loadGeneral,
      loadGeneralForBigProfile,
      setProfilePicture,
      getProfileReferenceData,
      getProfileCompanies
    },
    userProfile: {
      SET_CURRENT_USER_PROFILE_ID
    }
  } = useDispatch<Dispatch>();

  const openRef = useRef<() => void>(null);

  const pictureFileTypes = useSelector((state: RootState) => state.profile.generalConfig?.pictureFileTypes);
  const maxPictureFileSizeMb = useSelector((state: RootState) => state.profile.generalConfig?.maxPictureFileSize);
  const isAddNewProfile = useSelector((state: RootState) => state.profile?.isAddNewProfile);
  // eslint-disable-next-line no-magic-numbers
  const maxPictureFileSizeB = (maxPictureFileSizeMb || 0) * 1024 * 1024;

  const loggedUser = useSelector((state: RootState) => state.profile?.loggedUser);

  const general = useSelector(({ profile: { general: g } }: RootState) => g);

  const { userId } = useParams();

  const isCanEditProfileTags = usePermission(ProfileRequiredPoliciesConfig.EDIT_PROFILE_TAGS);
  const isCanViewProfileTags = usePermission(ProfileRequiredPoliciesConfig.VIEW_PROFILE_TAGS);

  useEffect(() => {
    if(userId && userId.length !== 0){
      loadGeneralForBigProfile(userId).then(data => SET_CURRENT_USER_PROFILE_ID(data._id));
      
    } else{
      loadGeneral();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadGeneral, SET_PROFILE_ID, getProfileReferenceData, userId]);

  useEffect(() => {
    getProfileCompanies();
    getProfileReferenceData(ReferenceType.TITLES);
    getProfileReferenceData(ReferenceType.GENDER);
    getProfileReferenceData(ReferenceType.BLOOD_TYPE);
    getProfileReferenceData(ReferenceType.WEIGHT_UNIT);
    getProfileReferenceData(ReferenceType.LENGTH_UNIT);
    getProfileReferenceData(ReferenceType.ETHNICITY);
    getProfileReferenceData(ReferenceType.BUILD);
    getProfileReferenceData(ReferenceType.EYE_COLOUR);
    getProfileReferenceData(ReferenceType.HAIR_COLOR);
    getProfileReferenceData(ReferenceType.NATIONALITY);
    getProfileReferenceData(ReferenceType.COUNTRY);
    getProfileReferenceData(ReferenceType.PERSON_TYPE);
    getProfileReferenceData(ReferenceType.EMPLOYEE_TYPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNewProfilePictureSelected = useCallback((files: FileWithPath[]) => {
    const [newProfilePicture] = files;

    setProfilePicture(newProfilePicture);
  }, [setProfilePicture]);

  const onNewProfilePictureRejected = useCallback((fileRejections: FileRejection[]) => {
    const [{ errors: [{ message, code }] }] = fileRejections;

    const errorMessage = code === 'file-too-large'
      ? `File is larger than the maximum allowed size of ${toHumanReadableFileSize(maxPictureFileSizeB)}`
      : message;

    showNotification({
      title: 'Error',
      message: errorMessage,
      color: 'error'
    });
  }, [maxPictureFileSizeB]);

  const handleOwnProfileCheck = useCallback((): boolean => {
    if(!userId){
      return false;
    }

    return userId === loggedUser?._id;
  }, [loggedUser?._id, userId]);

  return (
    <ProfileProfileTab
      openRef={ openRef }
      general={ general }
      onNewProfilePictureSelected={ onNewProfilePictureSelected }
      onNewProfilePictureRejected={ onNewProfilePictureRejected }
      pictureFileTypes={ pictureFileTypes || [] }
      maxPictureFileSize={ maxPictureFileSizeB }
      isAddNewProfile={ isAddNewProfile }
      isOwnProfile={ handleOwnProfileCheck }
      isCanViewProfileTags={ isCanViewProfileTags }
      isCanEditProfileTags={ isCanEditProfileTags }
    />
  );
};

export default ProfileProfileTabContainer;
