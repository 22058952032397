import { Button, Modal } from "@mantine/core";
import { FC } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as LeftIcon } from "@/common/icons/chevron-left.svg";
import { ReactComponent as RightIcon } from "@/common/icons/chevron-right.svg";
import { ReactComponent as SendIcon } from "@/common/icons/send.svg";
import { RootState } from "@/core/store";
import { useMassCommsFooterStyles } from "@/tenant-context/control-mass-comms/components/MassCommsFooter/MassCommsFooter.styles";
import { ReactComponent as ConfirmationIcon } from "@/tenant-context/control-mass-comms/icons/confirmation-icon.svg";
import { MASS_COMMS_STEPS } from "@/tenant-context/control-mass-comms/types/MassComms.types";

type Props = {
  currentStep: number;
  isNextItemDisabled: boolean;
  onNextStepRequested: () => void
  onPreviousStepRequested: () => void
  isConfirmationModalOpen: boolean,
  isInTemplateFlow: boolean,
  onConfirmationModalClose: () => void,
  onNavigationCancel: () => void,
  onTemplateSaveRequested: () => void,
  onSendNotificationRequest: () => void
}

const MassCommsFooterComponent: FC<Props> = ({
  currentStep,
  isNextItemDisabled,
  isConfirmationModalOpen,
  isInTemplateFlow,
  onNextStepRequested,
  onPreviousStepRequested,
  onConfirmationModalClose,
  onNavigationCancel,
  onTemplateSaveRequested,
  onSendNotificationRequest
}) => {

  const { classes } = useMassCommsFooterStyles();

  const communicationName = useSelector((state: RootState) => state.massComms.communicationName);

  return (
    <>
      <div className={ classes.container }>
        <div className={ classes.segment }>
          { (currentStep === MASS_COMMS_STEPS.RECIPIENT_SELECTION || currentStep === MASS_COMMS_STEPS.SUMMARY) && (
            <Button
              size="md"
              variant="outline"
              leftIcon={ <LeftIcon /> }
              onClick={ onPreviousStepRequested }
            >
              { currentStep === MASS_COMMS_STEPS.RECIPIENT_SELECTION && 'Back to Communication Content' }
              { currentStep === MASS_COMMS_STEPS.SUMMARY && 'Back to Recipients' }
            </Button>
          ) }
        </div>
        <div className={ classes.segment }>
          <Button size='md' variant='outline' onClick={ onNavigationCancel }>Cancel</Button>
          <Button disabled={ !communicationName } size='md' variant='outline' onClick={ onTemplateSaveRequested }>
            { isInTemplateFlow ? 'Save Template' : 'Save For Later' }
          </Button>
          { !isInTemplateFlow && <Button
            size="md"
            rightIcon={ currentStep !== MASS_COMMS_STEPS.SUMMARY ? <RightIcon/> : <SendIcon/> }
            onClick={ onNextStepRequested }
            disabled={ isNextItemDisabled }
          >
            { currentStep !== MASS_COMMS_STEPS.SUMMARY ? 'Next Step' : 'Send Notification' }
          </Button> }
        </div>
      </div>

      <Modal
        opened={ isConfirmationModalOpen }
        onClose={ onConfirmationModalClose }
        withCloseButton={ false }
        centered>
        <div className={ classes.confirmationModalHeader }>
          <ConfirmationIcon />
          <h6>Confirmation Required</h6>
        </div>

        <div className={ classes.confirmationModalContent }>
          Are you sure you want to send this notification now?
        </div>

        <div className={ classes.confirmationModalFooter }>
          <Button
            size='md'
            variant='outline'
            onClick={ onConfirmationModalClose }
          >
            Cancel
          </Button>
          <Button
            size='md'
            rightIcon={ <SendIcon /> }
            onClick={ onSendNotificationRequest }
          >
            Send Notification
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MassCommsFooterComponent;
