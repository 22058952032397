import { ColDef, ICellRendererParams } from "ag-grid-community";
import { ReactNode } from "react";

export const columnDefs = (
  actionCellRenderer: (params: ICellRendererParams) => ReactNode
): ColDef[] => [
  {
    headerName: 'Building Name',
    field: 'name',
    sortable: true,
    comparator: () => 0
  },
  {
    headerName: 'Building Code',
    field: 'code',
    sortable: true,
    comparator: () => 0
  },
  {
    headerName: 'No of Floors',
    field: 'floors',
    sortable: true,
    comparator: () => 0
  },
  {
    headerName: 'Action',
    colId: 'action',
    type: 'rightAligned',
    sortable: false,
    cellRenderer: actionCellRenderer
  }
];
