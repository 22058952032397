import { MantineTheme, ModalProps } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

import { primitiveNumbers } from "../constants";

export const ModalComponentStyle = {
  defaultProps: {
    centered: true
  } as ModalProps,
  styles: (theme: MantineTheme) => ({
    overlay: {
      backgroundColor: `${genericColorPalette.neutral[9]} !important`
    },

    modal: {
      backgroundColor: `${theme.other.semantic.surfaceBackground.secondary} !important`,
      padding: `${primitiveNumbers['3x']}px !important`,
      minWidth: 645,
      maxWidth: 'calc(100% - 24px)',
      position: 'relative' as const
    },

    header: {
      paddingBottom: 0,
      marginBottom: primitiveNumbers['4x']
    },

    title: {
      fontSize: 22,
      fontWeight: theme.other.fw.bold
    },

    body: {
      display: 'flex',
      flexDirection: 'column' as const,
      gap: primitiveNumbers['8x'],
      '&:first-child': {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: primitiveNumbers['3x']
      },
      '&:last-child': {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: primitiveNumbers['3x'],
        '& button': {
          // width: 200,
          '& div': {
            justifyContent: 'flex-start'
          }
        }
      }
    },

    close:{
      position: 'absolute' as const,
      padding: theme.other.spacing.md,
      right: 0,
      top: 0,
      minWidth: 0,
      minHeight: 0,
      width: primitiveNumbers['6x'],
      height: primitiveNumbers['6x'],
      backgroundColor: 'transparent',
      border: 0,
      '& svg': {
        width: primitiveNumbers['2x'],
        height: primitiveNumbers['2x'],
        color: theme.other.semantic.button.light.default
      },
      '&:hover': {
        backgroundColor: theme.colors.neutralDarkShades[3],
        cursor: 'pointer'
      },
      '&:focus-visible': {
        backgroundColor: theme.colors.neutralDarkShades[3],
        border: `2px solid ${theme.other.semantic.button.light.focused}`,
        outline: 'none'
      },
      '&:active': {
        backgroundColor: theme.colors.neutralDarkShades[3]
      }
    }
  })
};
