import { Text } from "@mantine/core";
import { FC } from "react";

import { useTextTruncateStyles } from "./TextTruncate.styles";

type Props = {
  isFullTextShown: boolean,
  isTruncated:boolean,
  text:string,
  onClickReadMoreText:() => void
}

export const TextTruncate: FC<Props> = ({
  isFullTextShown,
  isTruncated,
  text,
  onClickReadMoreText
}) => {
  const { classes } = useTextTruncateStyles();

  return (
    <Text>
      { text }
      { !isFullTextShown && (
        <div role="button"
          tabIndex={ 0 }
          className={ classes.readMoreTxt }
          onKeyPress={ onClickReadMoreText }
          onClick={ onClickReadMoreText }>
          { isTruncated ?  'Read More' : 'Read Less' }
        </div>
      ) }
    </Text>
  );
};

export default TextTruncate;
