import { Tooltip } from "@mantine/core";
import { FC } from "react";

type Props = {
  fullText?: string,
  maxLength?: number,
  className?: string,
  width?: number | 'auto',
  isMultiline?: boolean,
  isWithArrow?: boolean
}

export const TruncatedTextWithTooltip: FC<Props> = ({
  fullText = '',
  maxLength = 18,
  className = '',
  width = 300,
  isMultiline = false,
  isWithArrow = false
}) => {

  return (
    fullText.length > maxLength ? <Tooltip
      multiline={ isMultiline }
      width={ width }
      withinPortal
      label={ fullText }
      withArrow={ isWithArrow }
      arrowSize={ 10 }
    >
      <div className={ className ?? "" }>{ fullText.trim().slice(0, maxLength) + '...' }</div>
    </Tooltip> : <div className={ className ?? "" }>{ fullText }</div>
  );
};

export default TruncatedTextWithTooltip;
