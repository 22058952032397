import { useContext } from 'react';

import { PermissionCategory, RequiredPolicies } from '@/common/types/permission-control';
import { PoliciesContext } from '@/core/context/Policies.context';

export default function usePermission(
  requiredPolicies?: RequiredPolicies | RequiredPolicies[] | null,
  type: PermissionCategory = PermissionCategory.global
): boolean {

  const {
    userPolicies,
    sitePolicies
  } = useContext(PoliciesContext);

  const permissionPolicies = (type === PermissionCategory.site ? sitePolicies : userPolicies);

  if (!requiredPolicies || (Array.isArray(requiredPolicies) && requiredPolicies?.length === 0)) {
    return true;
  }

  if (!permissionPolicies || !permissionPolicies.length) {
    return false;
  }

  if (!Array.isArray(requiredPolicies)) {

    if (!requiredPolicies || !requiredPolicies?.policyNames?.length || !permissionPolicies) {
      return true;
    } else {
      return requiredPolicies?.policyNames.some(
        (policy) => permissionPolicies.some(
          (userPolicy) => userPolicy.policyName === policy
        )
      );
    }

  } else {

    return requiredPolicies?.every(
      (requiredPolicy) => requiredPolicy?.policyNames.some(
        (policy) => permissionPolicies.some(
          (userPolicy) => userPolicy.policyName === policy
        )
      )
    );

  }
}
