export const getPersonInitials = (name: string, maxCount = 2): string => {
  const initials = (name || '')
    .split(' ')
    .map(word => word[0])
    .join('');

  if (initials.length === 1) {
    return name.slice(0, 2).toUpperCase();
  }

  return initials.slice(0, maxCount).toUpperCase();
};
