import { Modal } from "@mantine/core";
import { ColDef } from "ag-grid-community";
import { FC, Ref } from "react";

import ServerDataGrid from "@/common/components/AgGrid/ServerSide";
import { DataGrid, GridParams } from "@/common/types/ag-grid";
import { PaginatedResult } from "@/common/types/reference-data";

import { POBListFilters, POBPeopleListResponse } from "../../types/site";
import { useCountBoxStyles } from "../CountBox/styles";


type Props = {
  isPeopleListOpened: boolean;
  onPeopleModalClose: () => void;
  columnDefs: ColDef[],
  tableRef: Ref<DataGrid>,
  getData: (payload: {
    gridParams: GridParams,
  }) => Promise<PaginatedResult<POBPeopleListResponse>>,
  additionalGridParams: POBListFilters,
  renderHeaderFilters: () => JSX.Element,
}
const POBPeopleListComponent: FC<Props> = ({ 
  isPeopleListOpened, 
  onPeopleModalClose, 
  columnDefs, 
  tableRef, 
  getData,
  additionalGridParams,
  renderHeaderFilters }) => {

  const { classes } = useCountBoxStyles({ isDarkTheme: true });


  return (
    <Modal onClose={ onPeopleModalClose } opened={ isPeopleListOpened } title={ 'People On Site' } size={ '65%' }>
      <div className={ 'ag-theme-alpine-dark ' + classes.modalPagination }>
        <ServerDataGrid
          id={ "pobList-grid" }
          columnDefs={ columnDefs }
          ref={ tableRef }
          getData={ getData }
          search={ true }
          additionalGridParams={ additionalGridParams }
          headerContent={ renderHeaderFilters }
          rowCount="5"
        />
      </div>
    </Modal>
  );
};

export default POBPeopleListComponent;
