import { IndoorMapGeoJSON } from '@f/map-gl-indoor/Types';
import { Feature, Polygon } from 'geojson';
import { FC } from 'react';

import { genericColorPalette, semanticColorPalette } from '@/core/styles/mantine/palettes';

import LocationGeoFenceLayerComponent from './LocationGeoFenceLayer.component';

type Props = {
  currentGeofence?: Feature<Polygon> | IndoorMapGeoJSON,
  geoFenceEnabledTab: 'SitePlan' | 'Buildings_Floors' | 'Zones',
  id: string
}

const LocationTabColors = {
  'SitePlan' : semanticColorPalette.accessibility.outer,
  'Buildings_Floors': genericColorPalette.warning[1],
  'Zones': genericColorPalette.red[4]
};

const LocationGeoFenceLayer: FC<Props> = ({ currentGeofence, id, geoFenceEnabledTab }) => {

  return (
    <LocationGeoFenceLayerComponent
      currentGeofence={ currentGeofence }
      id={ id }
      layerColor={ LocationTabColors[geoFenceEnabledTab] || semanticColorPalette.accessibility.outer }
    />
  );
};

export default LocationGeoFenceLayer;
