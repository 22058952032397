export const TenantAccessCardRequiredPoliciesConfig = {
  TENANT_ACCESS_CARD_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_AccessCards_Management_ReadOnly',
      'Tenant_AccessCards_Management_FullAccess'
    ]
  },
  EDIT_TENANT_ACCESS_CARD: {
    policyNames: [
      'Tenant_AccessCards_Management_FullAccess'
    ]
  },
  TENANT_ACCESS_CARD_LAYOUTS_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_AccessCards_Layouts_ReadOnly',
      'Tenant_AccessCards_Layouts_FullAccess'
    ]
  },
  EDIT_TENANT_ACCESS_CARD_LAYOUTS: {
    policyNames: [
      'Tenant_AccessCards_Layouts_FullAccess'
    ]
  },
  PRINT_JOBS_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_AccessCards_PrintJobs_ReadOnly',
      'Tenant_AccessCards_PrintJobs_FullAccess'
    ]
  },
  EDIT_PRINT_JOBS: {
    policyNames: [
      'Tenant_AccessCards_PrintJobs_FullAccess'
    ]
  }
};
