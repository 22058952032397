import { FC } from "react";

import { useIconProgressBarStyles } from "./IconProgressBar.style";

type Props = {
    indicatorIcon: React.ReactNode,
    progress: number,
    containerStyles?: React.CSSProperties
}

const IconProgressBar: FC<Props> = ({ indicatorIcon, progress = 0, containerStyles }) => {

  const { classes } = useIconProgressBarStyles();

  if (progress === 0) {
    return null;
  }

  return (
    <div className={ classes.progressBarContainer } style={ containerStyles }>
      <div className={ classes.progressedBar } style={ { width: `calc(${progress}% - 10px)` } } />
      <div className={ classes.progressIndicator }>{ indicatorIcon }</div>
      <div className={ classes.pendingBar } style={ { width: `calc(100% - ${progress}% - ${progress === 100 ? 0 : 10}px)` } } />
    </div>
  );
};

export default IconProgressBar;