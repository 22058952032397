import { ActionIcon, Divider, Stack, Text, TextInput, UnstyledButton, useMantineTheme } from "@mantine/core";
import { Feature, GeoJsonProperties, Point } from "geojson";
import {
  forwardRef,
  ForwardRefRenderFunction,
  RefObject,
  useCallback,
  useMemo,
  useState
} from "react";

import { Popup } from "@/common/components/Popup/Popup.components";
import { ReactComponent as CloseIcon } from "@/common/icons/close.svg";
import { ReactComponent as SearchIcon } from '@/common/icons/search.svg';
import { CustomColors } from "@/core/styles/mantine/palettes/types";
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import { useCurrentPopup } from "@/tenant-context/common/hooks/useMapPopupList";

import { useSiteLocationPopupStyles } from "../SiteLocationPopup/SiteLocationPopup.styles";

type Props = {
  popupId: string;
  longitude: number;
  latitude: number;
  ref?: RefObject<HTMLDivElement>;
  onClickLocationItem?: (feature: Feature<Point, GeoJsonProperties>) => void;
  onClickClose: () => void;
};

const CriticalLocationClusterPopup: ForwardRefRenderFunction<
  HTMLDivElement,
  Props
> = ({ popupId, longitude, latitude, onClickLocationItem, onClickClose }, ref) => {
  const theme = useMantineTheme();
  const { popup } = useCurrentPopup<Array<Feature<Point, GeoJsonProperties>>>('bigMapPopups', popupId);
  const locations = popup?.data;

  const { classes: popupClasses } = useSiteLocationPopupStyles();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const handleClickClusterItem = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const id = ev.currentTarget.value;
      const item = locations?.find(
        ({ properties }) => properties?.id === id
      );

      if (!item) {
        return;
      }
      onClickLocationItem?.({ ...item });
    },
    [locations, onClickLocationItem]
  );

  const handleSearchChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(ev.target.value);
  }, []);

  const filteredLocationsFeatures = useMemo(
    () => 
      locations?.filter(({ properties }) => {
        const name = properties?.name?.toLowerCase();
        const search = searchTerm.toLowerCase();
        return name?.includes(search);
      })
    , [locations, searchTerm]
  );

  return (
    <Popup
      longitude={ longitude }
      latitude={ latitude }
      /* eslint-disable-next-line no-magic-numbers */
      backgroundColor={ theme.colors.neutral[8] }
      padding={ "0" }
      offset={ 22 }
      closeOnClick={ false }
    >
      <LocalErrorBoundary>
        <div ref={ ref } className={ popupClasses.popupContainer }>
          <div className={ popupClasses.clusterHeader }>
            <Text size='lg' fw={ 700 }>Locations:</Text>
            <ActionIcon
              onClick={ onClickClose }
              size="md"
              className={ popupClasses.closeIcon }
            >
              <CloseIcon />
            </ActionIcon>
          </div>

          <TextInput
            size='md'
            placeholder="Search"
            icon={ <SearchIcon /> }
            onChange={ handleSearchChange }
            defaultValue={ '' }
          />

          <Divider mb={ 16 }  orientation="horizontal" />
          
          <Stack spacing="md" className={ popupClasses.clusterList }>
            { filteredLocationsFeatures?.map(({ properties }) => (
              <UnstyledButton
                key={ properties?.id }
                value={ properties?.id }
                onClick={ handleClickClusterItem }
                className={ popupClasses.clusterItem }
              >
                <Text className="CriticalLocationClusterPopup__personName">
                  { properties?.name || "Name N/A" }
                </Text>
                <Text size="sm" color={ theme.colors.neutral[5] as CustomColors }>
                  { properties?.locationType || "N/A" }
                </Text>
              </UnstyledButton>
            )) }
          </Stack>
        </div>
      </LocalErrorBoundary>
    </Popup>
  );
};

export default forwardRef(CriticalLocationClusterPopup);
