import { Stepper } from "@mantine/core";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { useMassCommsStepperStyles } from "@/tenant-context/control-mass-comms/components/MassCommsStepper/MassCommsStepper.styles";

const MassCommsStepperComponent: FC = () => {

  const { classes } = useMassCommsStepperStyles();

  const { currentStep } = useSelector((state: RootState) => state.massComms);
  const {
    massComms: {
      SET_CURRENT_STEP
    }
  } = useDispatch<Dispatch>();

  return (
    <Stepper classNames={ classes } active={ currentStep } onStepClick={ SET_CURRENT_STEP } breakpoint="sm">
      <Stepper.Step label="Create Communication" completedIcon="1"></Stepper.Step>
      <Stepper.Step label="Select Recipients" completedIcon="2"></Stepper.Step>
      <Stepper.Step label="Preview & Send" completedIcon="3"></Stepper.Step>
    </Stepper>
  );
};

export default MassCommsStepperComponent;
