/* eslint-disable react/jsx-props-no-spreading */
import { AutocompleteItem, Divider, Select, Switch, TextInput } from "@mantine/core";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RegisterOptions } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import ModalControlGroup from "@/common/components/ModalControlGroup";
import { Dispatch, RootState } from "@/core/store";
import { MapboxGeocodingProperties } from "@/tenant-context/common/types/mapbox-geocoding";
import {
  ProfileAddressesModalContext
} from "@/tenant-context/control-profile/components/modals/ProfileAddressesModal/ProfileAddressesModal.context";
import {
  useEmergencyContactModalContentStyles
} from "@/tenant-context/control-profile/components/ui/EmergencyContactModalContent/EmergencyContactModalContent.style";
import ProfileLoadingOverlay from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay';
import { ProfileAddress } from "@/tenant-context/control-profile/types/profile";
import MapSearch from "@/tenant-context/widget-overview/components/MapSearch";

export type ProfileAddressesModalFormData = Pick<ProfileAddress,
  | 'line1'
  | 'line2'
  | 'line3'
  | 'postCode'
  | 'region'
  | 'city'
  | 'country'
  | 'isPrimary'
  | 'title'
  | 'type'
  | 'number'
  | 'latitude'
  | 'longitude'
    | 'identifier'
>;

type Props = {
  mode?: 'add' | 'edit',
  editableAddress?: ProfileAddress,
  handleRemove: () => void,
  type?: 'Work' | 'Home'
}

const ProfileAddressesModal: FC<Props> = ({
  mode = 'add',
  editableAddress,
  handleRemove,
  type
}) => {

  const { classes, cx } = useEmergencyContactModalContentStyles();
  const {
    currentSearchLocation,
    onSubmit,
    formControls: {
      register,
      unregister,
      formState: { errors, isDirty },
      reset: resetAddressForm,
      resetField,
      setValue,
      trigger
    },
    locationsListData
  } = useContext(ProfileAddressesModalContext);

  const {
    profile: {
      getLocationsList
    },
    mapSearch: {
      SET_ACTIVE_LOCATIONS
    }
  } = useDispatch<Dispatch>();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [locationSearchValue, setLocationSearchValue] = useState<string>("");
  const [locationValue, setLocationValue] = useState(editableAddress?.locationId);
  const [countryValue, setCountryValue] = useState(editableAddress?.country);
  const locationsList: AutocompleteItem[] = useMemo(() => {
    return locationsListData.map((location) => {
      return {
        value: location?.tid,
        label: location?.name,
        latitude: location?.geoPoint?.lat,
        longitude: location?.geoPoint?.lon
      };
    });
  }, [locationsListData]);

  const handleSearchQueryChange = useDebouncedCallback((value: string): void => {
    setSearchQuery(value);
  }, 500);

  const { allCountries } = useSelector((state: RootState) => state.commonData);

  const countries = useMemo(() => {
    return allCountries.map(x => ({
      label: x.countryName,
      value: x.isoCountryCode
    }));
  }, [allCountries]);

  const isEdit = mode === 'edit';
  const isWorkAddressType = (type === 'Work') || (editableAddress?.type === 'Work');
  const handleCountryChange = useCallback((val) => {
    setValue('country', val, { shouldDirty: true });
    setCountryValue(val);
  }, [setValue]);

  const handleLocationIdChange = useCallback((val) => {
    const selectedLocation = locationsList.find((location) => location.value === val);
    setLocationValue(selectedLocation?.value);
    setValue('latitude', selectedLocation?.latitude);
    setValue('longitude', selectedLocation?.longitude, { shouldDirty: true });
    setValue('name', selectedLocation?.label, { shouldDirty: true });
    setValue('locationId', val, { shouldDirty: true });
    setValue('identifier', selectedLocation?.identifier, { shouldDirty: true });
    trigger();
  }, [locationsList, setValue, trigger]);

  const handleTypeChange = useCallback((event:React.ChangeEvent<HTMLInputElement>) => {
    const newType: string = event.target.value;
    setValue('type', newType, { shouldDirty: true });
  }, [setValue]);

  const handleLocationListQueryChange = useCallback((val) => {
    setLocationSearchValue(val);
  }, []);

  const renderTextInput = (
    label: string,
    id: keyof ProfileAddress,
    options: RegisterOptions
  ) => (
    <TextInput
      label={ label + `${options?.required ? '*' : ''}` }
      defaultValue={ editableAddress ? editableAddress[id]?.toString() : undefined }
      error={ errors?.[id]?.message }
      { ...register(id, addErrorMessages(label, options)) }
    />
  );

  const resetAddressFields = useCallback(() => {
    resetField('line1');
    resetField('line2');
    resetField('postCode');
    resetField('region');
    resetField('city');
    resetField('country');
    resetField('latitude');
    resetField('longitude');
  }, [resetField]);


  useEffect(() => {
    getLocationsList({ searchQuery });
  }, [getLocationsList, searchQuery]);

  useEffect(() => {
    handleSearchQueryChange(locationSearchValue);
  }, [handleSearchQueryChange, locationSearchValue]);

  useEffect(() => {
    if (isWorkAddressType) {
      register('name');
      if (editableAddress?.name) {
        setValue('name', editableAddress?.name);
      }
      unregister(['line1', 'line2', 'postCode', 'region', 'city', 'country']);
    }
  }, [editableAddress?.name, isWorkAddressType, register, setValue, type, unregister]);

  useEffect(() => {
    if (isWorkAddressType) {
      register('locationId', {
        required: 'Location is required'
      });
      register('identifier');
      setValue('identifier', editableAddress?.identifier);
      setValue('locationId', editableAddress?.locationId);
      setValue('latitude', editableAddress?.latitude);
      setValue('longitude', editableAddress?.longitude);
      // Makes the value available without manual change
      if (editableAddress?.locationId) {
        trigger();
      }
    }
  }, [
    editableAddress, isWorkAddressType, register, setValue, trigger, type]);

  useEffect(() => {
    register('type', {
      required: 'Type is required'
    });
    if (mode === 'add') {
      setValue('type', type);
    } else {
      setValue('type', editableAddress?.type);
    }
  }, [editableAddress, mode, register, setValue, type]);

  // Filling location details for new location came through location search
  useEffect(() => {
    if (currentSearchLocation) {
      resetAddressFields();
      const { features } = currentSearchLocation;

      if (features.length) {
        const [feature] = features;
        const { geometry, properties } = feature;

        const { coordinates } = geometry;

        const { name,  address, context } =
            properties as MapboxGeocodingProperties;
        const { country, region , place, postcode } = context;
        const resolvedAddress = address ? address : name;

        setValue("latitude", coordinates[1]?.toFixed(6));
        setValue("longitude", coordinates[0]?.toFixed(6));

        if (country?.country_code) {
          handleCountryChange(country.country_code);
        }

        if (place?.name) {
          setValue("city", place.name);
        }

        if (region?.name) {
          setValue("region", region.name);
        }

        if (resolvedAddress) {
          setValue("line1", resolvedAddress);
        }

        if (postcode?.name) {
          setValue("postCode", postcode.name);
        }
      }
    }
  }, [currentSearchLocation, handleCountryChange, resetAddressFields, resetAddressForm, setValue]);

  // Resetting location form on destroy
  useEffect(() => {
    return () => {
      resetAddressForm();
      SET_ACTIVE_LOCATIONS(undefined);
    };
  }, [SET_ACTIVE_LOCATIONS, resetAddressForm]);

  if (mode === 'edit' && !editableAddress) {
    return (
      <p>Loading...</p>
    );
  }

  return (
    <form onSubmit={ onSubmit }>
      <ProfileLoadingOverlay />

      <TextInput
        label="Type"
        defaultValue={ type || editableAddress?.type }
        disabled={ true }
        onChange={ handleTypeChange }
        error={ errors?.type?.message }
      />

      <div className={ classes.grid }>
        { isWorkAddressType ?
          renderTextInput('Title', 'title', {
            required: false,
            maxLength: 128
          }) :
          renderTextInput('Title', 'title', {
            required: true,
            maxLength: 128
          }) }

        <div
          className={ classes.toggleContainer }
        >
          <Switch
            className={ classes.toggle }
            label="Set As Primary"
            defaultChecked={ editableAddress?.primary }
            { ...register('isPrimary') }
          />
        </div>
      </div>

      { isWorkAddressType && (<Select
        searchable
        label={ 'Location' }
        required={ true }
        nothingFound={ 'No location found' }
        searchValue={ locationSearchValue }
        onChange={ handleLocationIdChange }
        onSearchChange={ handleLocationListQueryChange }
        data={ locationsList }
        value={ locationValue }
        error={ errors?.locationId?.message }
      />) }

      { !isWorkAddressType && (

        <>
          <div className={ classes.formItemFullWidth }>
            <div className={ classes.formItemHeader }>Search for a place name or type an address</div>
            <MapSearch isFullWidth/>
          </div>

          <div className={ cx(classes.formItemFullWidth, classes.formDivider) }>
            <div style={ { width: '35%' } }>
              <Divider orientation="horizontal"/>
            </div>
            <div>or add manually</div>
            <div style={ { width: '35%' } }>
              <Divider orientation="horizontal"/>
            </div>
          </div>
        </>
      ) }

      { !isWorkAddressType && renderTextInput('Address Line 1', 'line1', {
        required: true,
        maxLength: 256
      }) }

      { !isWorkAddressType && renderTextInput('Address Line 2', 'line2', {
        required: false,
        maxLength: 256
      }) }

      { !isWorkAddressType && <div className={ classes.grid }>

        { renderTextInput('Post Code / ZIP', 'postCode', {
          required: false,
          maxLength: 50
        }) }

        { renderTextInput('County / Region', 'region', {
          required: false,
          maxLength: 128
        }) }

        { renderTextInput('City', 'city', {
          required: false,
          maxLength: 128
        }) }

        <Select
          { ...register('country', addErrorMessages('Country', {
            required: false
          })) }
          value={ countryValue }
          searchable
          onChange={ handleCountryChange }
          data={ countries ? countries : [] }
          label="Country"
          error={ errors?.country?.message }
        />

        { renderTextInput('Latitude', 'latitude', {
          required: false,
          pattern:{
            value: /^([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)$/,
            message: "This input is number only."
          },
          maxLength: 20
        }) }

        { renderTextInput('Longitude', 'longitude', {
          required: false,
          pattern:{
            value: /^([0-9]*[0-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[0-9][0-9]*)$/,
            message: "This input is number only."
          },
          maxLength: 20
        }) }

      </div> }

      <ModalControlGroup
        additionalLabel={ isEdit ? "Remove Address" : undefined }
        onAdditional={ isEdit ? handleRemove : undefined }
        primaryButtonDisabled={ !isDirty }
      />
    </form>
  );
};

export default ProfileAddressesModal;
