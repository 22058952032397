import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

type Props = {
  isSliderOpen: boolean,
  isFromArc: boolean
}

export const useMassCommsHeaderStyles = createStyles((theme: MantineTheme, params: Props) => ({
  headerWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 66
  },
  sliderText: {
    color: theme.colors.neutral[6],
    fontSize: theme.fontSizes.md,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.neutral[9],
    ...((params.isSliderOpen || !params.isFromArc) && { visibility: 'hidden' }),
    path: {
      stroke: theme.colors.risk[5]
    },
    svg: {
      marginRight: '5px'
    }
  },
  headerTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    flex: '0 0 35%',
    marginTop: 32,
    maxWidth: 250
  },
  headerText: {
    fontSize: theme.fontSizes.xl,
    fontWeight: theme.other.fw.bold
  },
  wizard: {
    display: 'flex',
    marginTop: 96
  },
  headerCommName: {
    display: 'flex'
  },
  headerPrefix: {
    color: theme.colors.neutral[5]
  },
  notificationContainer: {
    width: 250,
    display: 'flex',
    justifyContent: 'flex-end',
    height: '120px',
    padding: '24px 0 64px 0'
  }
}));
