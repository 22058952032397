import { FC, useMemo } from "react";
import { useSelector } from 'react-redux';

import { Products } from '@/common/types/products';
import { RootState } from '@/core/store';
import { TRAVELLERS_SEARCH_DRAWERS } from "@/tenant-context/control-travellers-search/config/travellers-search.config";
import OverviewWidgetComponent from '@/tenant-context/widget-overview/controls/OverviewWidget/OverviewWidget.component';

export const OverviewWidget: FC = () => {
  const siteFeatureCollection = useSelector((state: RootState) => state.siteLocations.geoData);
  const subscribedProducts = useSelector((state: RootState) => state.commonData.tenantSubscribedProducts);

  const isTravelSearchDrawerOpen = useSelector((state: RootState) =>
    state.drawer?.currentlyOpenRightDrawerId === TRAVELLERS_SEARCH_DRAWERS.TRAVELLERS_SEARCH_CONTROL);
  const isCountryWiseTravellersDataAvailable = useSelector((state: RootState) =>
    state.travellersSearch.countryWiseTravellers.length > 0);

  const isSitesAvailable = useMemo(() => {
    return siteFeatureCollection.features.length > 0;
  }, [siteFeatureCollection.features]);

  const isSubscribedToTravel = useMemo(() => {
    return subscribedProducts?.some(product => product.name === Products.TRAVEL);
  }, [subscribedProducts]);

  return (
    <OverviewWidgetComponent
      isSitesAvailable={ isSitesAvailable }
      isSubscribedToTravel={ isSubscribedToTravel }
      isTravelSearchDrawerOpen={ isTravelSearchDrawerOpen }
      isCountryWiseTravellersDataAvailable={ isCountryWiseTravellersDataAvailable }
    />
  );
};
