import { createStyles } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useSiteInfoControlStyles = createStyles((theme) => ({

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  siteDescriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  siteLogo: {
    width: 'auto',
    height: '100px'
  },

  siteDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-between",
    marginTop: '24px'

  },

  siteName: {
    fontWeight: theme.other.fw.bold,
    fontSize: theme.fontSizes.xl,
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[4]
  },
  nameDivider: {
    width: '55px',
    borderTop: `2px solid ${theme.colors.blue[6]}  !important`
  },

  siteLocation: {
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.other.lh.l,
    color: theme.white,
    marginTop: '16px'
  },

  countryName: {
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[5],
    marginTop: '8px'
  },

  blockTitle: {
    marginTop: '35px',
    marginBottom: '10px',
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[1],
    fontFamily: theme.fontFamily
  },

  manageButton: {
    marginTop: '24px'
  },

  unauthorizedButton: {
    marginTop: '24px',
    backgroundColor: `${theme.colors.neutral[3]} !important`,
    '*': {
      color: `${theme.colors.neutral[5]} !important`
    }
  },
  drawerImgContainer: {
    maxHeight: '100px',
    img: {
      maxHeight: '100px',
      width: 'auto'
    }
  },
  locationTypeBadge: {
    width: 'fit-content',
    backgroundColor: theme.colors.neutral[7],
    color: theme.white,
    marginBottom: '9px'
  },
  imgPlaceHolderContainer: {
    backgroundColor: genericColorPalette.neutral[9],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '85px',
    height: '85px',
    svg : {
      width: '40px',
      fill: theme.colors.neutral[6]
    }
  }
}));
