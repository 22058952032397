import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { VOICE_MESSAGE_TYPES } from "@/tenant-context/control-mass-comms/types/MassComms.types";

import VoiceConfigurationComponent from "./VoiceConfiguration.component";

const VoiceConfiguration: FC = () => {

  const { voiceMessageType } = useSelector((state: RootState) => state.massComms.voiceContent);

  const {
    massComms: {
      SET_VOICE_MESSAGE_TYPE
    }
  } = useDispatch<Dispatch>();

  const handleVoiceMessageTypeChange = useCallback((type: VOICE_MESSAGE_TYPES) => {
    SET_VOICE_MESSAGE_TYPE(type);
  }, [ SET_VOICE_MESSAGE_TYPE ]);

  return (
    <VoiceConfigurationComponent
      selectedVoiceMessageType={ voiceMessageType }
      onVoiceMessageTypeChanged={ handleVoiceMessageTypeChange }
    />
  );
};

export default VoiceConfiguration;
