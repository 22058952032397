/* eslint-disable react/jsx-no-bind */
import { Button, Select, SelectItem } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as Search } from '@/common/icons/search.svg';
import { useTenantAccessCardsStyles } from "@/tenant-context/control-tenant-admin/components/TenantAccessCards/TenantAccessCards.styles";

import { POBListFilters } from "../../types/site";



interface Props {
    handlePOBFilterChange: (type: string, value: string) => void,
    handlePOBFilterSubmit: () => void,
    handlePOBFilterReset: () => void,
    currentFilters: POBListFilters,
    filterData: Record<string, SelectItem[] | []>
}

const POBPeopleListFilter: FC<Props> = ({
  handlePOBFilterChange,
  handlePOBFilterSubmit,
  handlePOBFilterReset,
  currentFilters, filterData }) => {

  const { classes } = useTenantAccessCardsStyles();

  return (
    <div className={ classes.filterWrap }>
      <div className={ classes.filterContainer }>
        <Select
          label={ 'Source' }
          onChange={ (value: string) => handlePOBFilterChange("adapterSource", value) }
          placeholder={ 'Select Type' }
          value={ currentFilters?.adapterSource ?? '' }
          data={ filterData.adapterIntegrations }
        />
      </div>
      <div className={ classes.controlsContainer }>
        <Button
          className={ classes.searchBtn }
          onClick={ handlePOBFilterSubmit }
        >
          Search
          <Search />
        </Button>
        <Button
          className={ classes.resetFilter }
          variant="subtle"
          onClick={ handlePOBFilterReset }
        >
          Reset Filter
        </Button>
      </div>
    </div>
  );
};

export default POBPeopleListFilter;
