/* eslint-disable max-lines */
import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { CSS_KEY } from '@/core/styles/mantine/constants';
import { genericColorPalette } from "@/core/styles/mantine/palettes";

type Params = {
  isInArc: boolean,
  isOverlayOpen: boolean,
  relatedItemIndex?: number
}

export const RiskAlertDetailsStyles = createStyles((theme: MantineTheme, params: Params) => ({
  container: {
    flex: 1,
    width: 'auto',
    ...(params.isInArc && {
      width: '415px',
      padding: 20,
      flex: '',
      maxHeight: '100vh',
      overflow: 'auto'
    }),
    ...(!params.isInArc && {
      height: '100%',
      display: 'flex'
    }),
    ...(params.isOverlayOpen && {
      width: '100%',
      marginLeft: '20px'
    }),

    display: 'flex',
    flexDirection: 'column'
    // justifyContent: 'space-between', // TODO: Removed due to no use of this property. Check if it is needed.
  },

  innerContainer: {
    flexGrow: 1
  },

  riskBasicData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px'
  },
  innerArrow: {
    width: '32px',
    height: '32px',
    background: theme.colors.neutral[8],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      height: '16px',
      width: '16px'
    },
    path: {
      stroke: theme.colors.risk[5]
    }
  },
  popupHeader: {
    fontFamily: theme.fontFamily,
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px'
  },
  flag: {
    borderRadius: '50px',
    overflow: 'hidden'
  },
  popupAlternativeHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '8px',
    marginBottom: '32px',
    color: theme.colors.neutral[4]

  },
  detailsTabsRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  relatedEventsTabsPanel: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  flexGrowFull: {
    flexGrow: 1
  },
  tabs: {
    marginTop: '24px',
    alignItems: 'çenter',
    display: 'flex',
    fontFamily: theme.fontFamily
  },
  relatedEventsTab: {
    cursor: 'pointer',
    padding: '10px',
    fontSize: theme.fontSizes.xs
  },
  tabBorder: {
    borderStyle: 'solid solid none solid',
    borderWidth: '1px'
  },
  detailsTab: {
    cursor: 'pointer',
    borderColor: theme.white,
    display: 'flex',
    gap: '5px',
    padding: '9px'
  },
  tabName: {
    display: 'flex',
    gap: '5px',
    fontWeight: theme.other.fw.semibold,
    fontSize: theme.fontSizes.xs
  },
  tabInactive: {
    color: theme.other.semantic.label[3],
    path: {
      fill: theme.other.semantic.label[3]
    }
  },
  countryFlagImg:{
    borderRadius: '32px'
  },
  riskDescription: {
    marginTop: '24px',
    fontFamily: theme.fontFamily,
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.lineHeight,

    flexGrow: 1,
    minHeight: 0,
    overflow: 'auto'
  },
  riskDescriptionText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xs
  },
  seeMoreButton: {
    fontSize: theme.fontSizes.sm
  },
  sectionTitle: {
    marginBottom: theme.spacing.md,
    fontSize: theme.fontSizes.xl
  },
  sectionContent: {
    fontSize: theme.fontSizes.sm,
    'ul, ol': {
      paddingLeft: 40
    },
    ol: {
      listStyleType: 'decimal !important'
    },
    ul: {
      listStyleType: 'disc !important'
    },
    img: {
      width: '100%',
      objectFit: 'contain'
    }
  },
  countryInfo: {
    color: theme.white,
    fontFamily: theme.fontFamily,
    fontWeight: theme.other.fw.regular,
    fontSize: theme.headings.sizes.h5.fontSize,
    lineHeight: theme.lineHeight,
    marginTop: '8px'
  },
  riskAlertTitle: {
    color: theme.white,
    fontWeight: theme.other.fw.bold,
    fontSize: theme.headings.sizes.h6.fontSize,
    lineHeight: theme.lineHeight
  },
  riskAlerProviderIcon: {
    width: '16px',
    height: '16px'
  },
  riskAlertProvider: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '20px',
    marginTop: '8px',
    gap: '3px'
  },
  issuedBy: {
    fontSize: theme.fontSizes.xs,
    fontFamily: theme.fontFamily,
    fontStyle: 'italic'
  },
  relatedEventsListContainer: {
    position: 'fixed',
    flexGrow: 1,
    marginLeft: -theme.other.spacing.lg,
    minHeight: !params.isInArc ? '136px': undefined,
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    ...( params.isInArc && {
      position: 'relative',
      height: 'auto !important',
      marginLeft: -20,
      marginRight: -20
    })
  },
  riskAlertProviderName: {
    fontSize: theme.fontSizes.xs,
    fontFamily: theme.fontFamily,
    color: theme.white
  },
  riskInfoPublishedOn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.neutral[3],
    fontSize: theme.fontSizes.xs,
    fontFamily: theme.fontFamily
  },
  divider: {
    marginTop: '24px',
    borderTopColor: theme.colors.neutral[8]
  },
  responseStatusContainer: {
    fontFamily: theme.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  responseBox: {
    backgroundColor: genericColorPalette.neutral[9],
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  responseStatusText: {
    display: 'flex',
    gap: '10px',
    color: theme.colors.neutral[5],
    letterSpacing: '0.5px',
    marginTop: '20px',
    textAlign: 'left',
    paddingLeft: '20px',
    fontSize: theme.fontSizes.sm,
    fontFamily: theme.fontFamily
  },
  responseMeta: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px'
  },
  responseProgress: {
    color: theme.white,
    letterSpacing: '0.5px',
    marginTop: '8px',
    textAlign: 'left',
    paddingLeft: '20px',
    fontWeight: theme.other.fw.bold,
    fontSize: theme.headings.sizes.h6.fontSize,
    fontFamily: theme.fontFamily,
    paddingBottom: '20px',
    lineHeight: '1.2'
  },
  responseMetaTxt: {
    paddingLeft: '20px',
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold
  },
  responseMetaValue: {
    paddingLeft: '20px',
    color: theme.white,
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.regular,
    paddingBottom: '20px'
  },
  openCaseBtn: {
    marginTop: '10px',
    padding: '8px',
    span: {
      color: theme.white,
      fontSize: theme.fontSizes.sm,
      fontWeight: theme.other.fw.regular
    }
  },
  caseOwner: {
    display: 'flex',
    paddingLeft: '20px',
    gap: '10px',
    marginBottom: '20px',
    alignItems: 'center'
  },
  caseOwnerTxt: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold
  },
  ownerName: {
    color: theme.colors.dodgerBlue[0],
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fw.bold
  },
  detailsTabDivider: {
    marginLeft: '82.3px',
    borderTopColor: theme.white
  },
  eventsTabDividerLeft: {
    width: '80px',
    marginLeft: '2px',
    borderTopColor: theme.white
  },
  eventsTabDividerRight: {
    marginLeft: '213px',
    width: '66px',
    borderTopColor: theme.white,
    marginTop: '-1px'
  },
  incidentTimeInfo: {
    fontFamily: theme.fontFamily,
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.colors.neutral[4],
    fontSize: theme.fontSizes.xs
  },
  boldIncidentTimeInfo: {
    fontWeight: theme.other.fw.bold,
    color: theme.white
  },
  sectionHeader: {
    fontWeight: theme.other.fw.semibold,
    fontFamily: theme.fontFamily,
    fontSize: theme.headings.sizes.h6.fontSize,
    lineHeight: theme.headings.sizes.h6.lineHeight,
    color: theme.colors.neutral[0],
    marginTop: '40px'
  },
  sectionDetailsHeader: {
    color: theme.colors.neutral[5],
    marginTop: '40px'
  },
  sectionHeaderDivider: {
    borderTopWidth: '0.5px',
    marginTop: '8px',
    borderTopColor: theme.colors.neutral[8]
  },
  detailsContainer: {
    marginTop: '9px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  detailsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  detailsHeader: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[6]
  },
  detailsInfo: {
    color: theme.white,
    display: 'flex',
    alignItems: 'center'
  },
  toggleArcButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  listItem: {
    cursor: 'pointer',
    position: 'relative',
    overflow: 'hidden',
    borderTop: '1px solid transparent',
    borderBottom: '1px solid transparent',
    paddingLeft: theme.other.spacing.lg,
    paddingRight: theme.other.spacing.lg,
    ...(!params.isInArc && {
      [theme.fn.smallerThan('md')]: {
        marginLeft: -theme.other.spacing.md,
        // marginRight: -theme.other.spacing.md,
        paddingLeft: theme.other.spacing.md,
        paddingRight: theme.other.spacing.md
      }
    }),
    [`.${ CSS_KEY }-Divider-root`]: {
      borderTopColor: `${theme.colors.neutral[8]} !important`
    },
    ...(params.isInArc && {
      width: 328,
      padding: '0px 20px'
    })
  },
  selectedItem: {
    backgroundColor: theme.colors.neutral[9],
    borderTop: `1px solid ${theme.colors.neutral[6]}`,
    borderBottom: `1px solid ${theme.colors.neutral[6]}`,
    '&:not(:first-child)': {
      marginTop: -2,
      paddingTop: 2
    }
  },
  textNeutral: {
    color: theme.colors.neutral[5]
  },
  caption: {
    wordBreak: 'break-word'
  },
  source: {
    color: theme.colors.skyBlue[0]
  },
  badge: {
    backgroundColor: theme.colors.blue[6],
    marginLeft: theme.spacing.xs
  },
  relatedEventModal: {
    pointerEvents: 'none',
    [`.${ CSS_KEY }-Modal-modal`]: {
      pointerEvents: 'auto',
      padding: '0 !important',
      backgroundColor: `${theme.colors.neutral[9]} !important`,
      boxShadow: `-4px 0px 10px 0px ${theme.colors.black[0]}40`
    },
    [`.${ CSS_KEY }-Modal-inner"`]: {
      pointerEvents: 'none'
    }
  },
  relatedEventsDivider: {
    borderTop: `1px solid ${theme.colors.neutral[6]} !important`,
    borderBottom: 'none !important'
  }
}));
