import { FC } from "react";

import VoiceRecordingComponent
  from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Channel-Configuration/VoiceConfiguration/VoiceMessageTypes/VoiceRecording/VoiceRecording.component";

const VoiceRecording: FC = () => {

  return (
    <VoiceRecordingComponent />
  );
};

export default VoiceRecording;
