export type RiskLevel = "Low" | "Moderate" | "Medium" | "High" | "Extreme";

export enum RiskProviders {
    riskline = 'riskline',
    lexisnexis = 'lexisnexis',
    reprisk = 'reprisk',
    healix = 'healix',
    bsoc = 'bsoc',
    dataminr = 'dataminr',
    maxSecurity = 'max-security'
}
