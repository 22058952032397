import { FC } from "react";

import { useCountBoxStyles } from "@/tenant-context/control-site/components/CountBox/styles";

import { ReactComponent as BuildingIcon } from '../../icons/building.svg';
import { ReactComponent as ZonesIcon } from '../../icons/zones.svg';

type Props = {
  isDarkTheme?: boolean;
  zonesCount?: number
  buildingCount?: number
}
const CountBox: FC<Props> = ({ isDarkTheme, zonesCount = 0, buildingCount = 0 }) => {

  const { classes } = useCountBoxStyles({ isDarkTheme });

  return (
    <>
      { !isDarkTheme &&

        <>
          <div className={ classes.container }>
            <div className={ classes.item }>
              <div className={ classes.title }>
                <ZonesIcon className={ classes.icon } />
                Number of Zones
              </div>
            </div>
            <div className={ classes.item }>
              <div className={ classes.count }>
                { zonesCount }
              </div>
            </div>
          </div>

          <div className={ classes.container }>
            <div className={ classes.item }>
              <div className={ classes.title }>
                <BuildingIcon className={ classes.icon } />
                Number of Buildings
              </div>
            </div>
            <div className={ classes.item }>
              <div className={ classes.count }>
                { buildingCount }
              </div>
            </div>
          </div>
        </> }
    </>
  );
};

export default CountBox;
