import { closeAllModals } from "@mantine/modals";
import { createContext, FC } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import { Dispatch } from "@/core/store";
import { MedicalDetailsModalFormData } from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/MedicalDetailsModal/MedicalDetailsModal.component";

type MedicalDetailsModalContextType = {
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>,
  formControls: UseFormReturn
};

export const MedicalDetailsModalContext = createContext<MedicalDetailsModalContextType>(
  {} as MedicalDetailsModalContextType
);

export const MedicalDetailsModalContextProvider: FC = ({
  children
}) => {
  const formControls = useForm({ mode: 'onChange' });

  const {
    profile: {
      updateMedicalDetails
    }
  } = useDispatch<Dispatch>();

  const handleSubmit = async (untypedData: unknown) => {
    const data = untypedData as MedicalDetailsModalFormData;
    const modifiedData: Record<string, unknown> = {};

    Object.keys(data).map((item) => {
      if ((item === 'height' && !data[item]) || (item === 'weight' && !data[item])) {
        modifiedData[item] = null;
      } else {
        modifiedData[item] = data[item as keyof typeof data];
      }

      return modifiedData;
    });
    const success = await updateMedicalDetails(modifiedData as MedicalDetailsModalFormData);
    if (success) {
      closeAllModals();
    }
  };

  const onSubmit = formControls.handleSubmit(handleSubmit);

  return (
    <MedicalDetailsModalContext.Provider value={ useContextValue({
      onSubmit,
      formControls
    }) }>
      { children }
    </MedicalDetailsModalContext.Provider>
  );
};
