import { ActionIcon, TextInput } from "@mantine/core";
import { MIME_TYPES } from "@mantine/dropzone";
import DOMPurify from "dompurify";
import { FC } from "react";

import ResponseMethodsPreviewComponent
  from "@/tenant-context/control-mass-comms/components/SummaryPage/Summary-Channels/ResponseMethodsPreview.component";
import { ReactComponent as EditIcon } from "@/tenant-context/control-mass-comms/icons/editIcon.svg";
import { ReactComponent as FileIcon } from "@/tenant-context/control-mass-comms/icons/file-icon.svg";
import { ReactComponent as MassPeople } from "@/tenant-context/control-mass-comms/icons/massPeople.svg";
import { EmailContent, MASS_COMMS_STEPS, MassCommsEmailResponseItem, ResponseMethod } from "@/tenant-context/control-mass-comms/types/MassComms.types";

import { useSummaryPageStyles } from "../../SummaryPage.styles";


type Props = {
  emailContent: EmailContent,
  responseMethod: ResponseMethod,
  expectedTotalRecipientCount: number,
  receivingCount: number,
  onStepperNavigate: (step: number) => (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void,
  emailResponseConfigs: MassCommsEmailResponseItem[]
}

const EmailSummaryComponent: FC<Props> = ({
  emailContent,
  responseMethod,
  onStepperNavigate,
  expectedTotalRecipientCount,
  receivingCount,
  emailResponseConfigs
}) => {
  const { classes, cx } = useSummaryPageStyles({ fontColor: emailContent?.fontColor });

  return (
    <div>
      <div className={ classes.countContainer }>
        <div className={ classes.countTextContainer }>
          <MassPeople />
          <div className={ classes.countText }>
            <div>{ receivingCount }</div>
            &nbsp;/&nbsp;{ expectedTotalRecipientCount }
          </div>
        </div>
        <ActionIcon><EditIcon onClick={ onStepperNavigate(MASS_COMMS_STEPS.CREATE_COMMS) }/></ActionIcon>
      </div>
      <div className={ classes.configItemsContainer }>
        <div className={ classes.configItem }>
          <div>Email Subject</div>
          <div><TextInput disabled defaultValue={ emailContent.subject }/></div>
        </div>
      </div>

      <ResponseMethodsPreviewComponent
        type="email"
        selectedResponseMethod={ responseMethod }
        responseConfigs={ emailResponseConfigs }
      />

      <div className={ classes.contentText }>
        Content
      </div>
      <div className={ cx({
        [classes.messageBodyContainer]: true,
        [classes.bodyFont]: true
      }) }>
        <div style={ { pointerEvents: "none" } } dangerouslySetInnerHTML={ {
          __html: DOMPurify.sanitize(emailContent.bodyWithStyling || '') } } />
        <div className={ classes.attachmentContainer }>
          { emailContent?.encodedAttachments?.map(file => {
            if (file.extension === MIME_TYPES.jpeg || file.extension === MIME_TYPES.png
              || file.extension === 'jpeg' || file.extension === 'png') {
              return (<img className={ classes.attachmentItem }
                title={ file.name } key={ file.name } src={ file.base64 } alt={ file.name } />);
            }

            return (<div key={ file.name } title={ file.name }
              className={ classes.attachmentItem }>
              <div className={ classes.otherAttachmentItem }>
                <FileIcon title={ file.name } /><div className={ classes.fileIconFileName }>{ file.name }</div>
              </div>
            </div>);
          }) }
        </div>
      </div>
    </div>
  );
};

export default EmailSummaryComponent;
