import { FC, useMemo } from "react";
import { Layer } from "react-map-gl";

import { LayerWithLayoutProps } from "@/tenant-context/common/types/mapbox";

type Props = {
  layerProps: LayerWithLayoutProps,
  isVisible: boolean
}

const DynamicallyVisibleLayer: FC<Props> = ({
  isVisible,
  layerProps
}) => {
  const { layout = {} } = layerProps ?? {};

  const mutatedLayout = useMemo(() => ({
    ...layout,
    visibility: isVisible
      ? 'visible' as const
      : 'none' as const
  }), [isVisible, layout]);

  return (
    <Layer
      // This is a decorator component, prop spreading is fine here
      // eslint-disable-next-line react/jsx-props-no-spreading
      { ...layerProps }

      layout={ mutatedLayout }
    />
  );
};

export default DynamicallyVisibleLayer;