
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ToggleableLayerType } from '@/common/components/ToggleableLayer/ToggleableLayer.config';
import { Dispatch, RootState } from "@/core/store";

import BusinessDataLayersComponent from "./BusinessDataLayers.component";

const BusinessDataLayersContainer = () => {
  const disabledLayers = useSelector((state: RootState) => state.dataOptions.disabledLayerTypes);
  const isTimeTravelActive = useSelector(
    (state: RootState) => state.rankingSettings.isTimeTravelActive
  );

  const {
    dataOptions: {
      ENABLE_LAYER_TYPE,
      DISABLE_LAYER_TYPE
    }
  } = useDispatch<Dispatch>();

  // Handlers for switches
  const handleSwitchToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        checked,
        value: layerType
      } = event.currentTarget;

      if (!Object.keys(ToggleableLayerType).includes(layerType)) {
        // Omit non-registered layer type interactions
        return;
      }

      if (checked) {
        if(layerType === ToggleableLayerType.AllCommonLocations){
          ENABLE_LAYER_TYPE([
            ToggleableLayerType.Hotels,
            ToggleableLayerType.Airports,
            ToggleableLayerType.CommonRegions,
            ToggleableLayerType.AllCommonLocations
          ]);
        } else{
          ENABLE_LAYER_TYPE([layerType as ToggleableLayerType]);
        }
     
      } else {
        if(layerType === ToggleableLayerType.AllCommonLocations){
          DISABLE_LAYER_TYPE([
            ToggleableLayerType.Hotels,
            ToggleableLayerType.Airports,
            ToggleableLayerType.CommonRegions,
            ToggleableLayerType.AllCommonLocations  ]);
        } else{
          DISABLE_LAYER_TYPE([layerType as ToggleableLayerType]);
        }
       
      }
    },
    [DISABLE_LAYER_TYPE, ENABLE_LAYER_TYPE]
  );

  const peopleFeatures = useSelector((state: RootState) => state.peopleLocations.geoData);
  const locationFeatures = useSelector((state: RootState) => state.siteLocations.geoData);
  const counters = useMemo(() => (
    {
      [ToggleableLayerType.People]: peopleFeatures?.features?.length || 0,
      [ToggleableLayerType.Locations]: locationFeatures?.features?.length || 0
    }
  ), [isTimeTravelActive, locationFeatures?.features?.length, peopleFeatures?.features?.length]);

  return (
    <BusinessDataLayersComponent
      disabledLayers={ disabledLayers }
      onLayerToggle={ handleSwitchToggle }
      counters={ counters }
    />
  );
};

export default BusinessDataLayersContainer;
