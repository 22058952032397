import { createStyles } from "@mantine/core";

export const useProfileAccessCardSearchStyles = createStyles((theme) => ({
  dropdown: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "300px",
    backgroundColor: theme.colors.neutral[8],
    overflow: "auto",
    marginTop: '16px',
    marginRight: '16px'
  },
  menuItem: {
    display: "flex",
    backgroundColor: theme.colors.neutral[8],
    alignItems: "center",
    padding: '8px 0'
  },
  menuItemRow: {
    fontSize:theme.fontSizes.sm,
    lineHeight:theme.lineHeight,
    color:theme.white
  },
  searchInput: {
    margin: '0 16px 0 0',
    width: '100%'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px 0'
  },
  searchContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  resetFilter: {
    cursor: "pointer",
    marginLeft: '16px'
  }
}));

