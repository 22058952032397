import { createStyles } from "@mantine/core";

export const useCountrySearchDropdownStyles = createStyles((t) => ({
  wrapper: {
    maxHeight: '50vh',
    overflow: 'auto'
  },
  countryContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: '100%'
  },
  countryName: {
    fontSize: t.fontSizes.sm,
    lineHeight: t.other.lh.l
  }
}));
