import { Tabs } from "@mantine/core";
import { createContext, FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { Dispatch } from "@/core/store";

export type TabProps = {
  value: string;
  label: string;
}

export enum RoleDetailsTabs {
  Overview = 'Overview',
  AccessPermissions = 'AccessPermissions',
  Checklist = 'Checklist',
}

const roleTabs: TabProps[] = [
  {
    value: RoleDetailsTabs.Overview,
    label: 'Overview'
  },
  {
    value: RoleDetailsTabs.AccessPermissions,
    label: 'Access Permissions'
  },
  {
    value: RoleDetailsTabs.Checklist,
    label: 'Checklist'
  }
];

export type RoleDetailsContextType = {
  roleTabs: TabProps[],
  activeTab: RoleDetailsTabs,
  renderTab: (tab: TabProps, key: number) => JSX.Element,
  handleTabChange: (tab: RoleDetailsTabs) => void
}

export const RoleDetailsContext = createContext<RoleDetailsContextType>({} as RoleDetailsContextType);

export const RoleDetailsContextTypeProvider: FC = ({ children }) => {

  const {
    caseTypes: {
      getRole
    }
  } = useDispatch<Dispatch>();

  const { id, roleId } = useParams();

  const [activeTab, setActiveTab] = useState(RoleDetailsTabs.Overview);

  const handleTabChange = useCallback((tab: RoleDetailsTabs) => {
    if (!tab) {
      return;
    }

    setActiveTab(tab);
  }, []);

  const renderTab = useCallback((tab: TabProps, key: number) => {
    return (
      <Tabs.Tab
        value={ tab.value }
        key={ key }
      >
        { tab.label }
      </Tabs.Tab>
    );
  }, []);

  useEffect(() => {
    if(id && roleId) {
      const params = {
        caseTypeId: id,
        roleId: roleId
      };

      getRole(params);
    }
  }, [getRole, id, roleId]);

  return (
    <RoleDetailsContext.Provider value={ {
      roleTabs,
      activeTab,
      renderTab,
      handleTabChange
    } }>
      { children }
    </RoleDetailsContext.Provider>
  );
};
