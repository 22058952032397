/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { FC } from "react";

import { useCountBoxStyles } from "@/tenant-context/control-site/components/CountBox/styles";
import { ReactComponent as GroupIcon } from '@/tenant-context/navigation/icons/settings/user-groups-disabled.svg';

import { POBDisplayDetails } from "../../types/site";
import POBPeopleList from "../POBPeopleList";

type Props = {
  isDarkTheme?: boolean;
  sitePOBDetails?: POBDisplayDetails,
  elementRef: React.MutableRefObject<HTMLDivElement | null>,
  isPeopleListOpen: boolean,
  onPeopleModalClose: () => void,
  onPeopleModalOpen: () => void
}
const POBDisplayComponent: FC<Props> = ({ 
  isDarkTheme, 
  sitePOBDetails, 
  elementRef, 
  isPeopleListOpen, 
  onPeopleModalClose,
  onPeopleModalOpen }) => {

  const { classes, cx } = useCountBoxStyles({ isDarkTheme });

  return (
    <>
      <POBPeopleList isPeopleListOpened={ isPeopleListOpen } onPeopleModalClose={ onPeopleModalClose }/>
      { !isDarkTheme &&

        <div ref={ elementRef }>
          <div className={ classes.container }>
            <div className={ classes.item }>
              <div className={ classes.title }>
                <GroupIcon className={ classes.icon } />
                Total People On Site
              </div>
            </div>
            <div className={ classes.item }>
              <div className={ cx({
                [classes.totalCount]: true,
                [classes.clickableCount]: sitePOBDetails && sitePOBDetails?.totalPob > 0
              }) } onClick={ onPeopleModalOpen } onKeyDown={ onPeopleModalOpen } role="button" tabIndex={ 0 }>
                { sitePOBDetails?.totalPob || 0 }
              </div>
            </div>
          </div>
          { sitePOBDetails?.countByAdapterMap && sitePOBDetails?.countByAdapterMap?.length > 0 &&
            <div className={ `${classes.pobEmptyContainer} ${classes.pobDetailsWrapper}` }>
              <div>
              </div>
              <div className={ classes.pobMergedItem }>
                Based On:
              </div>
            </div> }

          { sitePOBDetails?.countByAdapterMap && sitePOBDetails?.countByAdapterMap?.length > 0 && 
          <><div className={ classes.pobDisplayContainer }>
            <div className={ classes.pobItem }>
              <div>Source</div>
            </div>
            <div className={ classes.pobItem }>
              <div>Current</div>
            </div>
            <div className={ classes.pobItem }>
              <div>Last Known</div>
            </div>
          </div> 
          { sitePOBDetails?.countByAdapterMap?.map((adapter) => (
              <div className={ classes.pobDisplayContainer } key={ adapter.adapterName }>
              <div className={ classes.pobItemTitle }>
                  <div>
                  { adapter.adapterName }
                </div>
                </div>
              <div className={ classes.pobItemDetail }>
                  <div>
                  { (adapter.total - adapter.lastKnownCount) }
                </div>
                </div>
              <div className={ classes.pobItemDetail }>
                  <div>
                  { adapter.lastKnownCount }
                </div>
                </div>
            </div>
          ) ) }</> }

        </div> }
    </>
  );
};

export default POBDisplayComponent;
