import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

import { genericColorPalette } from "../palettes";

export const AutocompleteComponentStyle = {
  defaultProps: {
    size: 'lg'
  },
  styles: (theme: MantineTheme) => ({
    input: {
      border:`1px solid ${theme.colors.neutral[6]} !important`,
      '&:focus':{
        border: `1px solid ${theme.other.semantic.accessibility.outer} !important`
       
      },
      '&:active':{
        border:`1px solid ${theme.colors.neutral[6]} !important`
      },
      '&:disabled':{
        cursor:'unset',
        backgroundColor:theme.colors.neutral[6],
        border:`1px solid ${theme.colors.neutral[6]} !important`
      }

    },
    label: {
      color: theme.colors.neutral[5],
      fontSize: theme.fontSizes.md,
      marginBottom: 8
    },
    item:{
      borderBottom: `1px solid ${theme.colors.neutral[7]}`,
      borderRadius: 0,
      margin: 0,
      fontSize: theme.fontSizes.sm,
      '&:hover, &[data-hovered]': {
        backgroundColor: genericColorPalette.neutral[9] + ' !important'
      }
    },
    dropdown:{
      backgroundColor: theme.colors.neutral[8],
      border: `1px solid ${theme.colors.neutral[6]}`,
      padding: 0,
      top: 'unset',
      left: 'unset',
      maxHeight: 250,
      overflow: "auto"
    }
  })
};
