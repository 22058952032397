import { createModel } from "@rematch/core";

import { Dispatch, RootModel } from "@/core/store";

import { getItineraryForSummary } from "../api/summary-itinerary";
import { SummaryItineraryItemWithProfile } from "../types/travel.types";

export type TravelState = {
    travelData: SummaryItineraryItemWithProfile[],
    travelDataLoading: boolean
}

const travelModel = ({
  name: 'travel',
  state: {
    travelData: [] as SummaryItineraryItemWithProfile[],
    travelDataLoading: false
  },
  reducers: {
    SET_TRAVEL_DATA_LOADING(state: TravelState, payload: boolean): TravelState {
      return {
        ...state,
        travelDataLoading: payload
      };
    },
    SET_TRAVEL_DATA(state: TravelState, payload: SummaryItineraryItemWithProfile[]): TravelState {
      return {
        ...state,
        travelData: payload
      };
    },
    CLEAR_TRAVEL_DATA(state: TravelState): TravelState {
      return {
        ...state,
        travelData: []
      };
    }
        
  },
  effects: (dispatch: Dispatch) => ({
    async fetchTravelData(
      meta: { countryCode: string, travelEndpointType: string, travelDay: string }
    ): Promise<void> {
      dispatch.travel.SET_TRAVEL_DATA_LOADING(true);
      const data = await getItineraryForSummary(
        meta.countryCode.toUpperCase(),
        meta.travelEndpointType.toUpperCase(),
        meta.travelDay.toUpperCase()
      );

      const dataWithProfile = await Promise.all(
        data.map(async (item) => {
          const {
            profilePictureThumbnailUrl,
            firstName,
            lastName,
            jobTitle,
            company,
            profileId
          } = await dispatch.globalPeople.getMiniProfileById(item.profileId);

          return {
            ...item,
            profileData: { 
              profilePictureThumbnailUrl,
              firstName,
              lastName,
              jobTitle,
              company, 
              profileId
            }
          };

        })
      );


      dispatch.travel.SET_TRAVEL_DATA(dataWithProfile);

      dispatch.travel.SET_TRAVEL_DATA_LOADING(false);
    }
  })
});

export const travel = createModel<RootModel>()(
  travelModel
);
