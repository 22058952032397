import { FC, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import Dropzone from "@/common/components/Dropzone";
import DropzoneFiles from "@/common/components/DropzoneFiles";
import { Dispatch, RootState } from "@/core/store";
import { ProfileDocumentsModalContext } from "@/tenant-context/control-profile/components/modals/ProfileDocumentsModal/ProfileDocumentsModal.context";
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';

const ProfileDocumentsFilesTab: FC = () => {
  const {
    files,
    handleAcceptedFiles,
    handleRejectedFiles,
    removeFile,
    isWithoutFilesError,
    downloadFile,
    filesFromServer,
    maxDocumentFileSizeB,
    currentUserId
  } = useContext(ProfileDocumentsModalContext);

  const documentFileTypes = useSelector((state: RootState) => state.profile.generalConfig?.documentFileTypes);
  const maxNumberOfFilesPerUpload = useSelector(
    (state: RootState) => state.profile.generalConfig?.maxNumberOfFilesPerUpload
  );

  const {
    profile: {
      isLoggedInProfileId
    }
  } = useDispatch<Dispatch>();

  return (
    <div>
      <section>
        <Dropzone
          onAcceptedFiles={ handleAcceptedFiles }
          onRejectedFiles={ handleRejectedFiles }
          maxFiles={ maxNumberOfFilesPerUpload }
          accept={ Object.values(documentFileTypes || {}).flatMap(x => x) }
          humanReadableAccept={ Object.keys(documentFileTypes || {}).sort().join(", ") }
          // eslint-disable-next-line no-magic-numbers
          maxSize={ maxDocumentFileSizeB }
          requiredPolicies={ isLoggedInProfileId(currentUserId) ? [] : 
            ProfileRequiredPoliciesConfig.EDIT_DOCUMENTS }
        />

        <DropzoneFiles
          downloadFile={ downloadFile }
          removeFile={ removeFile }
          files={ files }
          filesFromServer={ filesFromServer }
          isWithoutFilesError={ isWithoutFilesError }
          requiredPoliciesForDelete={ isLoggedInProfileId(currentUserId) ? [] :
            ProfileRequiredPoliciesConfig.EDIT_DOCUMENTS }
        />
      </section>
    </div>
  );
};

export default ProfileDocumentsFilesTab;