import { FC, useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { ReactComponent as Arrow } from '@/common/icons/Arrows/right-arrow.svg';
import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState } from "@/core/store";
import SelectedProfileTab from "@/tenant-context/control-profile/components/tabs/SelectedProfileTab";
import { BigProfileContext } from "@/tenant-context/control-profile/components/ui/BigProfile/BigProfile.context";
import { useBigProfileStyles } from "@/tenant-context/control-profile/components/ui/BigProfile/BigProfile.style";
import ProfileTabs from "@/tenant-context/control-profile/components/ui/ProfileTabs";

const BigProfile: FC = () => {
  const isAddNewProfile = useSelector((state: RootState) => state.profile?.isAddNewProfile);
  const { activeTab } = useContext(BigProfileContext);
  const { classes } = useBigProfileStyles();
  const {
    profile: {
      loadGeneralConfig,
      SET_IS_ADD_NEW_PROFILE,
      closeBigProfile,
      getIsAccessCardPrivilege
    }
  } = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const handleGoBackToProfileList = useCallback(() => {
    navigate(RouterConfig.routes.profileList);
    SET_IS_ADD_NEW_PROFILE(false);
  }, [SET_IS_ADD_NEW_PROFILE, navigate]);

  useEffect(() => {
    loadGeneralConfig();
    getIsAccessCardPrivilege();
  }, [loadGeneralConfig, getIsAccessCardPrivilege]);

  useEffect(()=>{
    return () => closeBigProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoaded = useSelector((state: RootState) => state.profile.generalConfig);

  return (
    <div className={ classes.block }>
      <ProfileTabs/>
      { isAddNewProfile && <div className={ classes.goToPeopleListContainer }>
        { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */ }
        <span role="link" tabIndex={ 0 } onClick={ handleGoBackToProfileList }
          className={ classes.goToPeopleList }><Arrow /> Go to People</span>
      </div> }
      { isLoaded && <SelectedProfileTab activeTab={ activeTab }/> }
      { !isLoaded && <div>Loading...</div> }
    </div>
  );
};

export default BigProfile;
