import {
  ToggleableContextLayers,
  ToggleableLayerType
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { DataOptionsState } from "@/core/store/data-options/data-options.state";

export const drawerReducers = {
  ENABLE_LAYER_TYPE(state: DataOptionsState, layerTypeToEnable: ToggleableLayerType[]) {
    const { disabledLayerTypes } = state;

    const updatedDisabledLayerTypes = disabledLayerTypes.filter(
      (layerType) =>  !layerTypeToEnable.includes(layerType)
    );

    return {
      ...state,
      disabledLayerTypes: updatedDisabledLayerTypes
    };
  },

  DISABLE_LAYER_TYPE(state: DataOptionsState, layerTypeToDisable: ToggleableLayerType[]) {
    const { disabledLayerTypes } = state;

    const updatedDisabledLayerTypes = Array.from(
      new Set([...disabledLayerTypes, ...layerTypeToDisable])
    );

    return {
      ...state,
      disabledLayerTypes: updatedDisabledLayerTypes
    };
  },

  ENABLE_CONTEXTUAL_LAYER_TYPE(state: DataOptionsState, layerTypeToEnable: ToggleableContextLayers[]) {
    const { contextLayerOptions } = state;

    const updatedContextLayerOptions = { ...contextLayerOptions };

    layerTypeToEnable.forEach((layerType) => {
      updatedContextLayerOptions[layerType] = true;
    });

    return {
      ...state,
      contextLayerOptions: updatedContextLayerOptions
    };
  },

  DISABLE_CONTEXTUAL_LAYER_TYPE(state: DataOptionsState, layerTypeToDisable: ToggleableContextLayers[]) {
    const { contextLayerOptions } = state;

    const updatedContextLayerOptions = { ...contextLayerOptions };

    layerTypeToDisable.forEach((layerType) => {
      updatedContextLayerOptions[layerType] = false;
    });

    return {
      ...state,
      contextLayerOptions: updatedContextLayerOptions
    };
  }
};
