import { Button, MultiSelect } from "@mantine/core";
import { FC } from "react";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";

import { constructTestId } from "@/core/util/test-id";
import {
  usePlaybookSelectModalStyles
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/PlaybookSelectModal/PlaybookSelectModal.styles";
import { ItemProps } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

const FORM_NAME = 'playbook-select';
type Props = {
    cancelButtonHandler: () => void
    onSubmit: (data: FieldValues) => void
    formControls: UseFormReturn<FieldValues, unknown>
    selectOptions: ItemProps[]
}

const PlaybookSelectModalComponent: FC<Props> = ({
  cancelButtonHandler,
  onSubmit,
  formControls,
  selectOptions
}) => {

  const { classes } = usePlaybookSelectModalStyles();

  const { control } = formControls;

  return (
    <div className={ classes.wrapper }>
      <form onSubmit={ formControls.handleSubmit(onSubmit) }>
        <div className={ classes.section }>
          <Controller
            control={ control }
            name={ 'playbookItemsList' }
            /* eslint-disable-next-line react/jsx-no-bind */
            render={ ({
              field: { onChange, value }
            }) => (
              <MultiSelect
                data-testid={ constructTestId("SELECT", FORM_NAME, "playbookItemsList") }
                required
                searchable
                wrapperProps={ {
                  style:{
                    marginBottom: 24
                  }
                } }
                label="Select Playbook"
                placeholder="Select"
                onChange={ onChange }
                data={ selectOptions }
                value={ value }
              />
            ) }
          />
        </div>
        <div className={ classes.buttonContainer }>
          <div className={ classes.submitButtonContainer }>
            <Button
              data-testid={ constructTestId("BUTTON", FORM_NAME, "cancel") }
              variant="light"
              onClick={ cancelButtonHandler }
            >
              Cancel
            </Button>
            <Button
              data-testid={ constructTestId("BUTTON", FORM_NAME, "submit") }
              type={ "submit" }
            >
              Copy Checklist
            </Button>
          </div>
        </div>
      </form>
    </div>

  );
};

export default PlaybookSelectModalComponent;