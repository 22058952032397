import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { Dispatch, RootState } from "@/core/store";
import { AdditionalInfoObj } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";
import { getSearchedProfilesWithName } from "@/tenant-context/control-profile/api/profile-list";

import AdditionalDetailsTabComponent from "./AdditionalDetailsTab.component";


const AdditionalDetailsTab: FC = () => {

  const [searchedContacts, setSearchedContacts] = useState<{[index: number] :Array<{label: string, value: string}>}>();
  const [isSearching, setIsSearching] = useState<{[index: number] :{searching: boolean}}>();
  const [searchText, setSearchText] = useState<{ [index: number]: { searchString: string } }>({ 0: { searchString: '' } });
  const [focusedProfile, setFocusedProfile] = useState<number>();

  const {
    enteredLocationDetails
  } = useSelector((state: RootState) => state.manageLocations);
  

  const [_contactsLength, setContactsLength] = 
  useState((enteredLocationDetails.poiAdditionalInfoRequest as AdditionalInfoObj)?.contactList?.length);

  const contactInputRefs: React.MutableRefObject<React.RefObject<HTMLInputElement>[]> = useRef(Array.from(
    { length: 
    (enteredLocationDetails.poiAdditionalInfoRequest as AdditionalInfoObj)?.contactList?.length }, 
    () => React.createRef()
  ));

  const {
    manageLocations: {
      SET_ENTERED_LOCATION_DETAILS
    }
  } = useDispatch<Dispatch>();

  const handleContactSearch = useDebouncedCallback(async (
    event: React.ChangeEvent<HTMLInputElement>,
    itemIndex: number
  ) => {
    const searchedContact: typeof searchedContacts = {};
    const trimmedText = event.target.value.trim();
    const nameBreakdownIndex = trimmedText.indexOf(' ');
    if (trimmedText.length >= 2) {
      searchedContact[itemIndex] = [];
      setSearchedContacts(searchedContact);
      if (nameBreakdownIndex === -1) {
        setIsSearching({ [itemIndex]: { searching: true } });
        const searchByFirstName = await getSearchedProfilesWithName(trimmedText, '');
        const selectFirstNames = searchByFirstName?.items
          ?.filter(contact => !(enteredLocationDetails.poiAdditionalInfoRequest as AdditionalInfoObj).contactList
            .some(enteredContact => enteredContact.profileId === contact.profileId))
          .map(item => ({ value: JSON.stringify({ profileId: item.profileId, name: item.name }), label: `${item.name} (${item.profileId})` }));

        searchedContact[itemIndex] = selectFirstNames;
        setIsSearching({ [itemIndex]: { searching: false } });
        setSearchedContacts(searchedContact);
      } else {
        const firstName = trimmedText.slice(0, nameBreakdownIndex);
        const lastName = trimmedText.slice(nameBreakdownIndex + 1);
        setIsSearching({ [itemIndex]: { searching: true } });
        const searchByFullName = await getSearchedProfilesWithName(firstName, lastName);
        const selectFullNames = searchByFullName?.items
          ?.filter(contact => !(enteredLocationDetails.poiAdditionalInfoRequest as AdditionalInfoObj).contactList
            .some(enteredContact => enteredContact.profileId === contact.profileId))
          .map(item => ({ value: JSON.stringify({ profileId: item.profileId, name: item.name }), label: `${item.name} (${item.profileId})` }));

        searchedContact[itemIndex] = selectFullNames;
        setIsSearching({ [itemIndex]: { searching: false } });
        setSearchedContacts(searchedContact);
      }
    } else {
      searchedContact[itemIndex] = [];
      setSearchedContacts(searchedContact);
    }
  }, 1000);

  const handleContactChange = useCallback(async (
    event: React.ChangeEvent<HTMLInputElement>, 
    itemIndex: number
  ) => {
    const searchInput = { ...searchText };
    searchInput[itemIndex] = { searchString: event.target.value };
    setSearchText(searchInput);
    await handleContactSearch(event, itemIndex);
  }, [handleContactSearch, searchText]);

  const handleFocusProfileSearch = useCallback(
    (itemIndex: number)  => {
      setFocusedProfile(itemIndex);
      document.addEventListener('mousedown', () => setFocusedProfile(undefined), { once: true });
      setTimeout(() => {
        if (contactInputRefs.current[itemIndex].current) {
          (contactInputRefs.current[itemIndex].current as HTMLInputElement).focus();
        }
      }, 50);
    },
    []
  );


  const handleAddContact = useCallback(
    () => {
      const locDetails = { ...enteredLocationDetails };
      (locDetails.poiAdditionalInfoRequest as AdditionalInfoObj)?.contactList.push({ profileId: '', name: '' });
      SET_ENTERED_LOCATION_DETAILS(locDetails);
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  const handleDeleteContact = useCallback(
    (linkIndex: number) => {
      const locDetails = { ...enteredLocationDetails };
      if ((locDetails.poiAdditionalInfoRequest as AdditionalInfoObj)?.contactList?.length > 1) {
        (locDetails.poiAdditionalInfoRequest as AdditionalInfoObj).contactList.splice(linkIndex, 1);
        SET_ENTERED_LOCATION_DETAILS(locDetails);
      } else {
        (locDetails.poiAdditionalInfoRequest as AdditionalInfoObj).contactList[0] = { name: '', profileId: '' };
        SET_ENTERED_LOCATION_DETAILS(locDetails);
        if (contactInputRefs.current[linkIndex]?.current) {
          (contactInputRefs.current[linkIndex]?.current as HTMLInputElement).value = '';
        }
      }
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  const handleLinkChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, linkIndex: number) => {
      const locDetails = { ...enteredLocationDetails };
      (locDetails.poiAdditionalInfoRequest as AdditionalInfoObj).externalLinks[linkIndex][event.target.name as 'linkName' | 'url'] = event.target.value;
      SET_ENTERED_LOCATION_DETAILS(locDetails);
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  const handleAddLink = useCallback(
    () => {
      const locDetails = { ...enteredLocationDetails };
      (locDetails.poiAdditionalInfoRequest as AdditionalInfoObj)?.externalLinks.push({ linkName: '', url: '' });
      SET_ENTERED_LOCATION_DETAILS(locDetails);
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  const handleContactSelect = useCallback(
    (contactItem: string, itemIndex: number) => {
      const locDetails = { ...enteredLocationDetails };
      const parsedContact: { name: string, profileId: string } = JSON.parse(contactItem);
      (locDetails.poiAdditionalInfoRequest as AdditionalInfoObj).contactList[itemIndex] = 
      { name: parsedContact.name, profileId: parsedContact.profileId };
      setFocusedProfile(undefined);
      SET_ENTERED_LOCATION_DETAILS(locDetails);
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  const handleDeleteLink= useCallback(
    (linkIndex: number) => {
      const locDetails = { ...enteredLocationDetails };
      if ((locDetails.poiAdditionalInfoRequest as AdditionalInfoObj)?.externalLinks?.length > 1){
        (locDetails.poiAdditionalInfoRequest as AdditionalInfoObj).externalLinks.splice(linkIndex, 1);
        SET_ENTERED_LOCATION_DETAILS(locDetails);
      } else {
        (locDetails.poiAdditionalInfoRequest as AdditionalInfoObj).externalLinks[0] = { linkName: '', url: '' };
        SET_ENTERED_LOCATION_DETAILS(locDetails);
      }
    },
    [SET_ENTERED_LOCATION_DETAILS, enteredLocationDetails]
  );

  useEffect(() => {
    contactInputRefs.current = 
    Array.from(
      { length: (enteredLocationDetails.poiAdditionalInfoRequest as AdditionalInfoObj)?.contactList?.length }, 
      () => React.createRef()
    );
    setContactsLength((enteredLocationDetails.poiAdditionalInfoRequest as AdditionalInfoObj)?.contactList?.length);
    const searchedInputs = { ...searchText };
    (enteredLocationDetails.poiAdditionalInfoRequest as AdditionalInfoObj)?.contactList?.forEach((contact, index) => {
      searchedInputs[index] = { searchString: contact.name && contact.profileId ? `${contact.name} (${contact.profileId})` : '' };
    });
    setSearchText(searchedInputs);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredLocationDetails]);

  return (
    <AdditionalDetailsTabComponent 
      onContactChange={ handleContactChange }
      contacts={ searchedContacts }
      onAddContact={ handleAddContact }
      onDeleteContact={ handleDeleteContact }
      enteredDetails = { enteredLocationDetails }
      onAddExternalLink={ handleAddLink }
      onDeleteExternalLink={ handleDeleteLink }
      onLinkChange={ handleLinkChange }
      onFocusProfileSearch={ handleFocusProfileSearch }
      focusedProfile ={ focusedProfile }
      onContactSelect={ handleContactSelect }
      contactInputRefs={ contactInputRefs }
      isSearching= { isSearching }
      searchText= { searchText }
    />
  );
};

export default AdditionalDetailsTab;
