/* eslint-disable react/jsx-props-no-spreading */
import { Select } from "@mantine/core";
import { FC, useCallback, useContext } from "react";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import { TestableComponent } from "@/common/types/testable-component";
import { ProfilePeopleGroupsModalContext } from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/context/ProfilePeopleGroupsModal.context";
import { PaginatedResult, ProfileGroup } from "@/tenant-context/control-profile/types/profile";

export type AddProfileToExistingGroupFormData = {
  group_id: string,
  is_new_group: number
};

type Props = {
  groups?: PaginatedResult<ProfileGroup>
} & TestableComponent;

const AddToExistingGroup: FC<Props> = ({
  groups,
  dataTestId
}) => {
  const {
    formControls: {
      register,
      setValue,
      watch,
      formState: {
        errors
      }
    }
  } = useContext(ProfilePeopleGroupsModalContext);


  const handleSelectChange = useCallback((value: string) => {
    setValue('group_id', value, { shouldDirty: true });
  }, [setValue]);

  const groupId = watch('group_id');

  if (!groups) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Select
        searchable
        label="Select Group"
        error={ errors?.group_id?.message }
        { ...register('group_id', addErrorMessages('Group', {
          required: true
        })) }
        data={ groups.items.map((group) => ({
          value: group._id,
          label: group.name
        })) }

        value={ groupId }
        onChange={ handleSelectChange }
        size="md"
        data-testId={ `${dataTestId}-select-group` }
      />
    </div>
  );
};

export default AddToExistingGroup;