import { MapboxMap, MapRef } from "react-map-gl";

import lastMileSitesCautionMarker from '@/common/icons/markers/LastMileSite/Caution/Marker.png';
import lastMileSitesCluster from '@/common/icons/markers/LastMileSite/Primary/Cluster.png';
import lastMileSitesMarker from '@/common/icons/markers/LastMileSite/Primary/Marker.png';
import locationClusterMarker from '@/common/icons/markers/Locations/LocationCluster.png';
import nonLastMileSitesCluster from '@/common/icons/markers/NonLastMileSite/Cluster.png';
import nonLastMileSitesMarker from '@/common/icons/markers/NonLastMileSite/Site.png';
import { MapIcon } from "@/common/types/icon";
import useMapIcons from "@/tenant-context/common/hooks/useMapIcons";

const siteIcons: Readonly<MapIcon[]> = [
  {
    name: 'location-cluster-marker',
    image: locationClusterMarker
  },
  {
    name: 'last-mile-site-marker',
    image: lastMileSitesMarker
  },
  {
    name: 'last-mile-site-cluster',
    image: lastMileSitesCluster
  },
  {
    name: 'last-mile-site-caution-marker',
    image: lastMileSitesCautionMarker
  },
  {
    name: 'none-last-mile-sites-marker',
    image: nonLastMileSitesMarker
  },
  {
    name: 'none-last-mile-cluster',
    image: nonLastMileSitesCluster
  }
];

const useSiteIcons =
(map: MapboxMap | MapRef | undefined, isOverrideIcons=false	) => useMapIcons(siteIcons, map, isOverrideIcons);

export default useSiteIcons;
