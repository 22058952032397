export const AssetsManagementPoliciesConfig = {
  ASSETS_MANAGEMENT_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_ICM_Asset_ReadOnly',
      'Tenant_ICM_Asset_FullAccess'
    ]
  },
  EDIT_ASSETS_MANAGEMENT: {
    policyNames: [
      'Tenant_ICM_Asset_FullAccess'
    ]
  }
};