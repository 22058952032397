import { createStyles } from "@mantine/core";

export const useEmergencyVideoPopupStyles = createStyles((theme) => ({
  playerWrapper: {
    '& > div > div > video': {
      border: `3px solid ${theme.colors.neutral[8]}`,
      borderRadius: 20
    }
  },

  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  }
}));
