export const extractTableauSignInPayloadParams = (url: string): { site?: string, url?: string } => {
  try {
    const pathParts = url.split('/');
    const siteIndex = pathParts.findIndex(part => part === 'site');
    const siteName = ((siteIndex !== -1) && pathParts[siteIndex + 1]) ?  pathParts[siteIndex + 1] : undefined;

    const urlObj = new URL(url);
    const hostParts = urlObj.hostname.split('.');
    const environment = hostParts[0];
    const signInUrl = `https://${environment}.online.tableau.com/vizportal/api/web/v1/auth/embed/signin`;

    return {
      site: siteName,
      url: signInUrl
    };
  } catch (error) {
    return {};
  }
};
