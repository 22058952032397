import { createStyles } from "@mantine/core";

export const useTagsContainerStyles = createStyles((theme) => ({
  tagsContainerRoot: {
    display: 'flex',
    padding: 16,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: 8,
    alignSelf: 'stretch',
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    minHeight: 69,
    maxHeight: 160,
    overflowY: 'auto'
  }
}));
