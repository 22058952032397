import { createStyles } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useActivityLogStyles = createStyles((theme) => ({
  root: {
    fontSize: theme.fontSizes.xs
  },
  timeline: {
    // marginLeft: 32
  },
  time: {},
  date: {
    padding: '10px 20px',
    backgroundColor: genericColorPalette.neutral[9],
    width: 'fit-content',
    marginBottom: 12
  },
  dim: {
    color: theme.colors.neutral[5]
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));
