import { SortData } from "@/tenant-context/control-groups/types/group";

export enum GeofenceListTabsTypes {
  ALL_GEOFENCE = 'allGeofence',
  MY_GEOFENCE = 'myGeofence'
}

export enum DrawingFilterTypes {
  ALL = '',
  GENERAL = 'General Geofence',
  PROHIBITED = 'Prohibitive Geofence',
}

export type Geofence = {
  tid?: string,
  id?: string
  name: string,
  description: string
  type: string | GeofenceType,
  status?: 'DISABLED' | 'ACTIVE',
  enablePrivate: boolean
  sharing?: string
  pushNotificationText: string
  enableLocationTracking: boolean
  geoJson?: string
  createdBy?: string
  createdDateTime?: number
  lastUpdatedBy?: string
  lastUpdatedDateTime?: number
  updatedDateTime?: number
}

export type ShortGeofence = {
  tenantId: string,
  tid: string,
  name: string,
  enablePrivate: boolean,
}

export type GeofenceFilterData = {
  nameFilter?: string,
  typeFilter?: string,
}

export enum GeofenceStatusTypes {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}

export type GeofenceFilterType = {
  name?: string
  type?: DrawingFilterTypes
  status?: GeofenceStatusTypes
}


export type MetaData = {
  short?: boolean,
  myGeoFences?: string,
  page?: number,
  size?: number,
  isPrivate?: boolean,
  type?: DrawingFilterTypes
  sort?: SortData
}

export type ViewportBbox = [number, number][];

export type GeoPolygon = {
  id: string;
  type: 'POLYGON' | 'CIRCLE' | 'LINE';
  geometry: [number, number][];
};

export enum DrawingTypes {
  NONE = 'NONE',
  POLYGON = 'POLYGON',
  CIRCLE = 'CIRCLE',
  DRAG_CIRCLE = 'DRAG_CIRCLE',
  SQUARE = 'SQUARE',
  DELETE = 'REMOVE',
  LINE = 'LINE',
}

export enum GeofenceType {
  'GENERAL' = 'GENERAL',
  'PROHIBITIVE' = 'PROHIBITIVE'
}

export enum GeofencePermissionType {
  'PRIVATE' = 'PRIVATE',
  'SHARED' = 'SHARING'
}

export type GeofenceProperty = {
  name: string;
  value: number;
  unit: string;
}

export type WithinGeofenceData = {
  locationCount: number;
  peopleLocationCount: number;
  siteLocationsCount: number;
}
