import { FC, useCallback, useMemo, useState } from "react";
import { useDispatch } from 'react-redux';

import { Dispatch } from '@/core/store';
import {
  FeedNotification,
  NotificationContainerLayoutParams
} from '@/tenant-context/control-live-feed/types/live-feed';

import ToastNotificationContainerComponent from "./ToastNotificationContainer.component";

interface Props {
  notifications: FeedNotification[];
  layoutParams?: NotificationContainerLayoutParams;
}

const ToastNotificationContainer: FC<Props> = ({
  notifications,
  layoutParams
}) => {

  const {
    liveFeed: {
      CLOSE_NOTIFICATION_IN_DISPLAY_STACK
    }
  } = useDispatch<Dispatch>();

  const [ isCollapsed, setIsCollapsed ] = useState<boolean>(true);
  const [ isContainerScrolledToTop, setIsContainerScrolledToTop ] = useState<boolean>(true);

  const totalVisibleNotifications = useMemo(() => {
    return notifications.filter(({ isClosedInDisplayStack }) => !isClosedInDisplayStack).length;
  }, [notifications]);

  const onNotificationClose = useCallback((notificationId: string) => {
    CLOSE_NOTIFICATION_IN_DISPLAY_STACK(notificationId);
  }, [ CLOSE_NOTIFICATION_IN_DISPLAY_STACK ]);

  const handleContainerScroll = useCallback((event) => {
    setIsContainerScrolledToTop(event.target.scrollTop === 0);
  }, []);

  const closeAllNotifications = useCallback(() => {
    notifications.forEach(({ notificationId }) => {
      CLOSE_NOTIFICATION_IN_DISPLAY_STACK(notificationId);
    });
  }, [ CLOSE_NOTIFICATION_IN_DISPLAY_STACK, notifications ]);

  return (
    <ToastNotificationContainerComponent
      notifications={ notifications }
      isCollapsed={ isCollapsed }
      setIsCollapsed={ setIsCollapsed }
      onNotificationClose={ onNotificationClose }
      totalVisibleNotifications={ totalVisibleNotifications }
      handleContainerScroll={ handleContainerScroll }
      isContainerScrolledToTop={ isContainerScrolledToTop }
      layoutParams={ layoutParams }
      closeAllNotifications={ closeAllNotifications }
    />
  );
};

export default ToastNotificationContainer;
