import { Button } from '@mantine/core';
import { FC, useCallback } from "react";
import { useSelector } from "react-redux";

import usePermission from '@/common/hooks/usePermission';
import { ReactComponent as OpenCaseIcon } from "@/common/icons/openCaseIcon.svg";
import { TestableComponent } from "@/common/types/testable-component";
import { RootState } from "@/core/store";
import { ArcCasePoliciesConfig } from '@/tenant-context/control-action-response-center/config/arc-case.policies';
import { RiskAlertEvent } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts';

type Props = {
  isImpactCircleEnabled:boolean,
  isArcOpen: boolean,
  isMassCommsOpen: boolean,
  riskAlert?: RiskAlertEvent,
  onRequestToToggleArc: () => void,
  isAnotherOverlayModuleOpen: boolean,
  selectedRiskProvider?: string
} & TestableComponent

const RiskEventsControlFooter: FC<Props> = ({
  isImpactCircleEnabled,
  isArcOpen,
  isMassCommsOpen,
  riskAlert,
  onRequestToToggleArc,
  isAnotherOverlayModuleOpen,
  selectedRiskProvider
}) => {

  const riskAlertsGeoData = useSelector((state: RootState) => state.riskAlerts.currentRiskAlert);
  const isAuthorizedForArc = usePermission(ArcCasePoliciesConfig.ARC_CASE_BY_ID);

  const footer = useCallback(() => {
    // if ( !selectedRiskProvider && !isImpactCircleEnabled && !riskAlertsGeoData) {
    //   RiskEventsProviderSelection footer
    //   <<<< NO FOOTER >>>>
    // }

    if ( selectedRiskProvider && !isImpactCircleEnabled ) {
      if (selectedRiskProvider === 'bsoc') {
        return <Button fullWidth>
          Show Live Feed
        </Button>;
      }

      // if (selectedRiskProvider === 'dataminr') {
      //   return DataMinrEventsSettings Footer;
      // }
      //
      // return <RiskLineEventsSettingsContainer Footer;
    }


    if (((isImpactCircleEnabled || riskAlertsGeoData) && !isArcOpen && !isMassCommsOpen)) {
      if (!isArcOpen && !isAnotherOverlayModuleOpen && riskAlert?.isImpacted) {
        return (
          <Button
            fullWidth
            onClick={ onRequestToToggleArc }
            disabled={ !isAuthorizedForArc }
            title={ !isAuthorizedForArc ? 'You are not authorized to open a ARC case' : '' }
            rightIcon={ <OpenCaseIcon/> }
          >
            { 'Open Case' }
          </Button>
        );
      }
    }

    // if ( isImpactCircleEnabled && isArcOpen ) {
    //   ARC footer
    // }

    // if ( isImpactCircleEnabled && isMassCommsOpen ) {
    //   MassComms footer
    // }

    return null;
  }, [
    isImpactCircleEnabled,
    riskAlertsGeoData,
    isArcOpen,
    isMassCommsOpen,
    isAnotherOverlayModuleOpen,
    onRequestToToggleArc,
    isAuthorizedForArc,
    riskAlert,
    selectedRiskProvider
  ]);

  return footer();
};

export default RiskEventsControlFooter;
