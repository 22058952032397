import { AutocompleteState } from './autocomplete.state';

const MAX_RESULTS = 5;

export const autocompleteReducers = {
  ADD_AUTOCOMPLETE_ENTRY(state: AutocompleteState, displayName: string) {
    const updatedEntries = Array.from(
      new Set([ displayName, ...state.autocompleteEntries])
    );

    if (updatedEntries.length > MAX_RESULTS) {
      updatedEntries.pop();
    }

    return {
      ...state,
      autocompleteEntries: updatedEntries
    };
  }
};
