import { FC } from "react";

import ControlContainer from "@/common/controls/ControlContainer/ControlContainer.component";
import { useGeofenceWidgetStyles } from "@/tenant-context/control-draw/controls/GeofenceWidget/GeofenceWidget.styles";
import MapSearch from "@/tenant-context/widget-overview/components/MapSearch";


const GeofenceWidget: FC = () => {
  const { classes } = useGeofenceWidgetStyles();

  return (
    <ControlContainer
      containerId="top-right-control-container"
      position="top-right"
      layout="horizontal"
    >
      <div className={ classes.topRightContainer }>
        <MapSearch />
      </div>
    </ControlContainer>
  );
};

export default GeofenceWidget;
