import { createContext, FC, useCallback, useState } from "react";

import useContextValue from "@/common/hooks/useContextValue";
import BigProfile from "@/tenant-context/control-profile/components/ui/BigProfile/BigProfile.component";
import { ProfileTab } from "@/tenant-context/control-profile/components/ui/ProfileTabs/ProfileTabs.component";

export type BigProfileContextType = {
  handleTabChange: (tab: string | null) => void,
  activeTab: ProfileTab
}

export const BigProfileContext = createContext<BigProfileContextType>({} as BigProfileContextType);

export const BigProfileContextProvider: FC = () => {
  const [activeTab, setActiveTab] = useState(ProfileTab.Profile);

  const handleTabChange = useCallback((tab: string | null) => {
    if (!tab) {
      return;
    }

    setActiveTab(tab as ProfileTab);
  }, []);

  return (
    <BigProfileContext.Provider value={ useContextValue({
      activeTab,
      handleTabChange
    }) }>
      <BigProfile />
    </BigProfileContext.Provider>
  );
};
