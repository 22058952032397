import { ActionIcon, Button, CopyButton, Flex } from "@mantine/core";
import { FC, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import usePermission from "@/common/hooks/usePermission";
import { ReactComponent as CloseIcon } from "@/common/icons/close.svg";
import { ReactComponent as DuplicateIcon } from "@/common/icons/duplicateIcon.svg";
import { ReactComponent as SendIcon } from "@/common/icons/send.svg";
import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState, store } from "@/core/store";
import { useArcHeaderStyles } from "@/tenant-context/control-action-response-center/components/ArcHeader/ArcHeader.style";
import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";
import LiveFeedButton from '@/tenant-context/control-live-feed/components/LiveFeedButton';
import { MassCommsPoliciesConfig } from "@/tenant-context/control-mass-comms/config/mass-comms.policies";
import { massCommsDefaultState } from "@/tenant-context/control-mass-comms/store/MassComms.model";

import { ArcCasePoliciesConfig } from "../../config/arc-case.policies";
import ArcHeaderTabs from "./ArcHeaderTabs/ArcHeaderTabs.component";

const ArcHeader: FC = () => {
  const { classes } = useArcHeaderStyles();
  const { routes } = RouterConfig;
  const { arcCase, isArcOpenInDetachedView, handleCloseArcCase } = useContext(ArcContext);

  const isMcArcInvocationAvailable = usePermission(MassCommsPoliciesConfig.MC_ARC_INVOKE);
  const isAuthorizedForArc = usePermission(ArcCasePoliciesConfig.ARC_CASE_BY_ID);


  const tenantId = store.getState().commonData?.tenantId;
  const url = `${window.location.protocol}//${window.location.host}${routes.arc(arcCase?.caseId)}?tenantId=${tenantId}`;
  const {
    massCommsNavigation
  } = useSelector((state: RootState) => state.massComms);

  const openArcInDetachedMode = useCallback(() => {
    window.open(url, '_blank');
  }, [url]);

  const {
    massComms: {
      navigateToMassComms,
      RESET_MASS_COMMS_TO_DEFAULT_STATE
    },
    arc: {
      SET_IS_ARC_OPEN
    }
  } = useDispatch<Dispatch>();

  const openMassComms = useCallback(() => {
    RESET_MASS_COMMS_TO_DEFAULT_STATE(massCommsDefaultState);
    SET_IS_ARC_OPEN(false);
    navigateToMassComms({ ...massCommsNavigation, isOpen: true, fromArc: true });
  }, [ RESET_MASS_COMMS_TO_DEFAULT_STATE, SET_IS_ARC_OPEN, navigateToMassComms, massCommsNavigation ]);

  const openCloseArcPrompt = useCallback(() => {
    openConfirmationModal({
      text: "Are you sure you want to close this case? Closing the case will change the case status to closed and remove the risk alert from the global map.",
      title: "Confirm Case Close",
      onConfirm: handleCloseArcCase,
      closeOnClickOutside: false
    });
  }, [handleCloseArcCase]);

  return (
    <Flex direction={ 'row' } align={ 'center' } justify={ 'space-between' } className={ classes.header }>
      <Flex align={ 'flex-end' }>
        <ArcHeaderTabs />
      </Flex>
      <Flex gap={ 20 } direction={ 'row' } align={ 'center' }>
        <div className={ classes.buttons }>
          { !isArcOpenInDetachedView && (
            <ActionIcon onClick={ openArcInDetachedMode }>
              <DuplicateIcon />
            </ActionIcon>
          ) }
          <CopyButton value={ url }>
            { ({ copy }) => (
              <Button onClick={ copy }>Share</Button>
            ) }
          </CopyButton>
          <Button
            leftIcon={ <SendIcon /> }
            className={ classes.createNotificationBtn }
            onClick={ openMassComms }
            disabled={ !isMcArcInvocationAvailable }
          >
            Send Communication
          </Button>
          <Button
            leftIcon={ <CloseIcon /> }
            variant="gradient"
            disabled={ !isAuthorizedForArc || arcCase?.status === 'CLOSED' }
            onClick={ openCloseArcPrompt }
          >
            Close Case
          </Button>
        </div>
        <LiveFeedButton/>
      </Flex>
    </Flex>
  );
};

export default ArcHeader;
