import { Button, Group } from "@mantine/core";
import { closeAllModals } from "@mantine/modals";
import { FC, useCallback } from "react";

import { useModalControlGroupStyles } from "@/common/components/ModalControlGroup/ModalControlGroup.style";
import usePermission from '@/common/hooks/usePermission';
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { RequiredPolicies } from '@/common/types/permission-control';
import { TestableComponent } from "@/common/types/testable-component";

type Props = {
  onPrimary?: () => void,
  primaryActionLabel?: string,
  primaryButtonDisabled?: boolean | undefined,

  onSecondary?: () => void,
  secondaryLabel?: string,

  onAdditional?: () => void,
  additionalLabel?: string,

  isCancelSecondary?: boolean,
  isCancelAdditional?: boolean,
  isPrimarySubmit?: boolean
  requiredPoliciesToSubmit?: RequiredPolicies | RequiredPolicies[]
} & TestableComponent;


const ModalControlGroup: FC<Props> = ({
  onPrimary,
  primaryActionLabel = 'Submit',
  primaryButtonDisabled,

  onSecondary,
  secondaryLabel = 'Cancel',

  additionalLabel,
  onAdditional,

  isCancelSecondary = true,
  isCancelAdditional = false,
  isPrimarySubmit = true,
  requiredPoliciesToSubmit,

  dataTestId

}) => {
  const { classes } = useModalControlGroupStyles();
  const isAuthorizedToSubmit = usePermission(requiredPoliciesToSubmit);
  const handleUnauthorizedAction = useUnauthorizedModal();

  const overriddenCancelSecondary = isCancelSecondary && !isCancelAdditional;

  const handleAdditionalClick = useCallback(
    () => isCancelAdditional ? closeAllModals() : onAdditional?.(),
    [isCancelAdditional, onAdditional]
  );

  const handleSecondaryClick = useCallback(
    () => overriddenCancelSecondary ? closeAllModals() : onSecondary?.(),
    [onSecondary, overriddenCancelSecondary]
  );

  const secondaryActionExists = onSecondary || overriddenCancelSecondary;
  const additionalActionExists = onAdditional || isCancelAdditional;

  return (
    <Group
      className={ classes.group }
      position="apart"
    >
      { /* Additional */ }
      { additionalActionExists ? (
        <Button
          size="md"
          variant="subtle"
          color="warning"
          onClick={ handleAdditionalClick }
        >
          { additionalLabel }
        </Button>
      ) : <div /> }

      <Group>
        { /* Secondary */ }
        { secondaryActionExists ? (
          <Button
            size="md"
            variant="outline"
            onClick={ handleSecondaryClick }
            color="warning"
            data-testid={ `${dataTestId}-cancel-button` }
          >
            { secondaryLabel }
          </Button>
        ) : <div /> }

        { /* Primary */ }
        <Button
          type={ isPrimarySubmit ? 'submit' : 'button' }
          size="md"
          variant="default"
          color="warning"
          onClick={ isAuthorizedToSubmit ? onPrimary : handleUnauthorizedAction }
          disabled={ primaryButtonDisabled }
          className={ isAuthorizedToSubmit ? undefined : classes.unauthorizedButton }
          data-testid={ `${dataTestId}-submit-button` }
        >
          { primaryActionLabel }
        </Button>
      </Group>
    </Group>
  );
};

export default ModalControlGroup;
