import { MAX_LAT_BOUND, MAX_LONG_BOUND, MIN_LAT_BOUND, MIN_LONG_BOUND } from "@/tenant-context/common/util/constants";

export const isValidLatitude = (value: number | string): boolean => {
  if (typeof value === 'string') {
    return Number(value) >= MIN_LAT_BOUND && Number(value) <= MAX_LAT_BOUND;
  }

  return value >= MIN_LAT_BOUND && value <= MAX_LAT_BOUND;
};

export const isValidLongitude = (value: number | string): boolean => {
  if (typeof value === 'string') {
    return Number(value) >= MIN_LONG_BOUND && Number(value) <= MAX_LONG_BOUND;
  }

  return value >= MIN_LONG_BOUND && value <= MAX_LONG_BOUND;
};
