import { ProfileListFilterData } from "@/tenant-context/control-profile/types/profile-list";

// Check if initial search string is empty, if not adds 'AND" after it
// export const isEmptyQueryString = (initialString: string, searchString: string) => initialString.length === 0 ? searchString : '&' + searchString;
const isEmptyQueryString = (initialString: string, searchString: string) => initialString.length === 0 ? searchString : 'AND' + searchString;


export const profileListQueryString = ({
  name,
  personType,
  companyRef,
  userAccountStatus,
  employeeAppStatus,
  source,
  activeStatus
}: ProfileListFilterData = {}) => {
  // eslint-disable-next-line fp/no-let
  let str = '';

  if (name) {
    str = str + isEmptyQueryString(str, `name LIKE %22%25${name}%25%22`);
  }
  if (personType
  ) {
    str = str + isEmptyQueryString(str, `personType = %22${personType}%22`);
  }
  if (companyRef) {
    str = str + isEmptyQueryString(str, `companyRef LIKE %22%25${companyRef}%25%22`);
  }
  if (userAccountStatus
  ) {
    str = str + isEmptyQueryString(str, `userAccountStatus LIKE %22%25${userAccountStatus}%25%22`);
  }
  if (employeeAppStatus) {
    str = str + isEmptyQueryString(str, `employeeAppStatus LIKE %22%25${employeeAppStatus}%25%22`);
  }
  if (source
  ) {
    str = str + isEmptyQueryString(str, `source LIKE %22%25${source}%25%22`);
  }
  if (activeStatus) {
    str = str + isEmptyQueryString(str, `activeStatus LIKE %22${activeStatus}%22`);
  }


  return str.length > 0 ? `&search=${str}` : '';

};
