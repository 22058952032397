import { createStyles,MantineTheme } from "@mantine/core";

import { CSS_KEY, primitiveNumbers } from "@/core/styles/mantine/constants";

type Params = {
  size: string
}

const getIconPadding = (size: string, theme: MantineTheme): string => {
  const paddingMap: Record<string, string> = {
    xs: `${theme.other.spacing.sm}px`,
    sm: `${theme.other.spacing.sm}px`,
    md: `${theme.other.spacing.sm}px`,
    lg: `${theme.other.spacing.md}px`,
    xl: `${theme.other.spacing.md}px`
  };
  
  return paddingMap[size];
};

const getInputPadding = (size: string, theme: MantineTheme): string => {
  const paddingMap: Record<string, string> = {
    xs: `0 ${theme.other.spacing.sm}px`,
    sm: `0 ${theme.other.spacing.sm}px`,
    md: `0 ${theme.other.spacing.sm}px`,
    lg: `0 ${theme.other.spacing.md}px`,
    xl: `0 ${theme.other.spacing.md}px`
  };
  
  return paddingMap[size];
};

const getIconSize = (size: string): number => {
  const iconSizeMap: Record<string, number> = {
    xs: 12,
    sm: 12,
    md: primitiveNumbers['2x'],
    lg: primitiveNumbers['3x'],
    xl: primitiveNumbers['3x']
  };

  return iconSizeMap[size];
};

const getMinHeight = (size: string): number => {
  const minHeightMap: Record<string, number> = {
    xs: primitiveNumbers['3x'],
    sm: primitiveNumbers['3x'],
    md: primitiveNumbers['4x'],
    lg: primitiveNumbers['6x'],
    xl: primitiveNumbers['6x']
  };
  
  return minHeightMap[size];
};

const getFontSize = (theme: MantineTheme, size: string): number => {
  const fontSizeMap: Record<string, number> = {
    xs: theme.fontSizes.xs,
    sm: theme.fontSizes.xs,
    md: theme.fontSizes.sm,
    lg: theme.fontSizes.md,
    xl: theme.fontSizes.md
  };
  
  return fontSizeMap[size];
};

export const usePhoneInputStyles = createStyles((theme, params: Params) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.other.spacing.sm,
    '.PhoneInputCountrySelectArrow': {
      marginLeft: 12,
      marginTop: -4,
      width: 7,
      height: 7,
      borderColor: theme.white,
      borderWidth: '0 1.5px 1.5px 0',
      opacity: 1
    },

    '.PhoneInputCountryIcon--border': {
      boxShadow: 'none'
    }
  },

  inputBlock: {
    margin: '0 0 18px 0'
  },

  pseudoInput: {
    [`& .${CSS_KEY}-InputWrapper-label, & .${CSS_KEY}-Input-wrapper`]: {
      display: 'none'
    },
    [`& .${CSS_KEY}-InputWrapper-error`]: {
      marginTop: 5
    }
  },

  label: {
    display: 'inline-block',
    wordBreak: 'break-word',
    cursor: 'default',
    color:  theme.other.semantic.text.secondary,
    fontSize: theme.fontSizes.sm,
    fontWeight: 400
  },

  phoneInput: {
    '& .PhoneInputCountry': {
      backgroundColor:'transparent',
      paddingLeft: getIconPadding(params.size, theme),
      margin: 0,
      border: `1px solid ${theme.other.semantic.border.general.lighter}`,
      borderRight: 'none'
    },

    '&:focus-within .PhoneInputCountry': {
      borderColor: theme.other.semantic.border.general.accent[1]
    },

    '&:hover .PhoneInputCountry': {
      borderColor: theme.other.semantic.border.general.light
    },

    '& .PhoneInputInput': {
      fontFamily: "'Inter',sans-serif",
      minHeight: getMinHeight(params.size),
      lineHeight: 1,
      appearance: 'none',
      resize: 'none',
      boxSizing: 'border-box',
      fontSize: getFontSize(theme, params.size),
      width: '100%',
      color: theme.other.semantic.text.secondary,
      display: 'block',
      textAlign: 'left',
      backgroundColor: theme.other.semantic.surfaceBackground.none,
      border: `1px solid ${theme.other.semantic.border.general.lighter}`,
      borderLeft: 'none',
      padding: getInputPadding(params.size, theme),

      '&:focus': {
        outline: 'none',
        borderColor: theme.other.semantic.border.general.accent[1],
        color: theme.other.semantic.text.primary
      },

      '&:hover': {
        borderColor: theme.other.semantic.border.general.light,
        color: theme.other.semantic.text.primary
      }
    },
    '& .PhoneInputCountryIcon--border':{
      width: getIconSize(params.size),
      height: getIconSize(params.size),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      overflow: 'hidden'
    },
    '& .PhoneInputCountryIconImg': {
      objectFit: 'cover'
    },
    '&.PhoneInput--disabled': {
      border: 'none',
      backgroundColor: theme.other.semantic.surfaceBackground.disabled,
      color: theme.other.semantic.text.disabled,
      '& .PhoneInputCountry': {
        border: 'none',
        cursor: 'not-allowed'
      },
      '& .PhoneInputInput': {
        border: 'none',
        cursor: 'not-allowed'
      }
    }
  },

  phoneInputFilled: {
    backgroundColor: theme.other.semantic.surfaceBackground.primary,
    '& .PhoneInputCountry': {
      border: 'none',
      borderBottom: `1px solid ${theme.other.semantic.border.general.lighter}`
    },
    '& .PhoneInputInput': {
      border: 'none',
      borderBottom: `1px solid ${theme.other.semantic.border.general.lighter}`,
      color: theme.other.semantic.text.primary,
      '&::placeholder': {
        color: theme.other.semantic.text.primary
      }
    }
  },

  phoneInputError: {
    backgroundColor: theme.other.semantic.surfaceBackground.primary,
    '& .PhoneInputCountry': {
      border: 'none',
      borderBottom: `1px solid ${theme.other.semantic.border.alert.error}`
    },
    '& .PhoneInputInput': {
      border: 'none',
      borderBottom: `1px solid ${theme.other.semantic.border.alert.error}`,
      '&::placeholder': {
        color: theme.other.semantic.text.primary
      }
    }
  }
}));
