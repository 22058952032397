

import { createModel } from "@rematch/core";

import { RootModel } from "@/core/store";
import { LocationsPeopleCount, SiteLocationSnapshotProperties } from "@/tenant-context/visualisation-site/types/site.types";

export type SitePopupState = {
  popupContent?: SiteLocationSnapshotProperties
  locationsPeopleCount: LocationsPeopleCount

}

const sitePopupModel = ({
  name: 'sitePopup',
  state: {
    popupContent: undefined,
    locationsPeopleCount: {
      countries: [],
      personInitials: "",
      total: 0,
      musters: 0,
      visitors: 0,
      siteTotal: 0,
      countryTotal: 0,
      visitorsNext24Hrs: 0,
      _type: "POBDataResponse"
    }
  } as SitePopupState,

  reducers: {
    SET_LOCATIONS_PEOPLE_COUNT(state: SitePopupState, payload: LocationsPeopleCount) {
      return {
        ...state,
        locationsPeopleCount: payload
      };
    },

    SET_SITE_POPUP_CONTENT(
      state: SitePopupState,
      popupContent: SiteLocationSnapshotProperties
    ): SitePopupState {
      return {
        ...state,
        popupContent
      };
    }
  }

});

export const sitePopup = createModel<RootModel>()(
  sitePopupModel
);
