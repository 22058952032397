/* eslint-disable no-magic-numbers */
import { Feature, GeoJsonProperties, Polygon } from 'geojson';
import mapboxgl from 'mapbox-gl';
import {  FC, useContext, useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { fitToViewportBounds } from '@/tenant-context/common/util/map';
import { RiskContext } from '@/tenant-context/visualisation-risk-alerts/context/Risk.context';
import { ImpactPosition } from '@/tenant-context/visualisation-risk-details/types/risk-impact';
import { drawRiskImpactCircle } from '@/tenant-context/visualisation-risk-details/util/drawRiskImpactCircle';

import RiskImpactLayer from "./RiskImpactLayer.component";

type Props = {
  impactPositionData?:ImpactPosition
  isImpactZoneModeEnabled?:boolean
  impactRadius?:number
};

const _getTimout = (zoomLevel:number) => {
  if(zoomLevel >= 17){
    return 4000;
  } else if(zoomLevel > 14 ){
    return 3000;
  } else if(zoomLevel  > 11){
    return 500;
  } else if(zoomLevel > 8){
    return 1000;
  } else if(zoomLevel > 6){
    return 2000;
  } else if(zoomLevel > 5){
    return 3000;
  } else {
    return 4000;
  }
};

const RiskImpactLayerContainer: FC<Props> = ({
  impactPositionData,
  isImpactZoneModeEnabled,
  impactRadius }) => {
  const { current: map } = useMap();
  const riskImpactData = useSelector((state: RootState) => state.riskDetails.currentRiskAlertImpactEvent);
  const radius = isImpactZoneModeEnabled ? impactRadius : riskImpactData?.calculation.appliedTo;
  const isRiskImpactCircleEnabled = useSelector((state: RootState) => state.riskDetails.isRiskImpactCircleEnabled);
  const impactPosition = isImpactZoneModeEnabled
    ? impactPositionData
    : riskImpactData?.request?.geometry;

  const [riskImpactCircle, setRiskImpactCircle] = useState<Feature<Polygon, GeoJsonProperties>>();
  const {
    riskDetails: {
      resetCurrentImpact,
      TOGGLE_POPUP_LINE
    }
  } = useDispatch<Dispatch>();

  const {
    calculatePopupOffset
  } = useContext(RiskContext);

  useEffect(() => {
    if (!map || !impactPosition) {
      return;
    }
    map.on('click', () => {
      if(!isImpactZoneModeEnabled) {
        TOGGLE_POPUP_LINE(false);
        resetCurrentImpact();
      }
    });

    // Drawing the risk impact circle
    const circleCenter = [impactPosition.lon, impactPosition.lat];
    const impactCircle = drawRiskImpactCircle(circleCenter, radius ?? 0, 100);
    setRiskImpactCircle(impactCircle);

    // zooming the map to close proximity of the circle
    const coords = impactCircle.geometry.coordinates[0].map((feature) =>feature  as [number, number]);
    const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);
    coords.forEach((coordinates) => bounds.extend(coordinates));
    fitToViewportBounds(map, bounds, { right: !isImpactZoneModeEnabled });


    // Commented out due to https://restrataplatform.atlassian.net/browse/RP-8867
    // // Popup line should draw just after the zooming in is completed
    // // timeout various based on the users current zoom level and getTimout() returns
    // // necessary time for the timeout function to wait
    // if(!isImpactZoneModeEnabled){
    //   setTimeout(() => {
    //     calculatePopupOffset();
    //     TOGGLE_POPUP_LINE(true); 
    //   }, 200); // Changed this from getTimout(bigMapZoomLevel) to 200. Need to check if this is correct
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TOGGLE_POPUP_LINE, resetCurrentImpact, calculatePopupOffset,map,
    impactPosition, radius]);

  return (
    <RiskImpactLayer
      impactCircleData = { riskImpactCircle }
      isRiskImpactCircleEnabled = { isRiskImpactCircleEnabled ||
        (isImpactZoneModeEnabled !== undefined && isImpactZoneModeEnabled) }
    />
  );
};

export default RiskImpactLayerContainer;
