export const CorePolicies = {
  CONNECTOR_PROVIDER_POLICY: {
    policyNames: [
      'Tenant_ICE_ImpactCalculation_ReadOnly',
      'Tenant_ICE_ImpactCalculation_FullAccess'
    ]
  },

  RISK_CONNECTOR_POLICY: {
    policyNames: [
      'Tenant_Risk_Connector_ReadOnly',
      'Tenant_Risk_Connector_FullAccess'
    ]
  },

  RISK_LOCATION_POLICY: {
    policyNames: [
      'Tenant_PALE_LocationEvent-Connector_ReadOnly',
      'Tenant_Location_Connector_FullAccess'
    ]
  },

  GLOBAL_LOCATION_PERMISSIONS: {
    policyNames: [
      'Tenant_System_GlobalMap-Location_ReadOnly',
      'Tenant_System_GlobalMap-Location_FullAccess'
    ]
  }
};
