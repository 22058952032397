/* eslint-disable import/extensions */
import { TextInput } from "@mantine/core";
import { FC } from "react";

import { TestableComponent } from "@/common/types/testable-component";
import DroppableMapTopInfoBox from "@/tenant-context/common/components/DroppableMapTopInfoBox";

import { WidgetSite } from "../../types/site.types";
import { useCountryInfoBoxStyles } from "../CountryInfoBox/CountryInfoBox.styles";
import SiteSearchDropdown
  from "../SiteSearchDropdown/SiteSearchDropdown.component";


type Props = {
  selectedSite: WidgetSite | undefined,
  siteList: Array<WidgetSite>,
  isExpanded: boolean,
  oSiteSelected: (selectedSite: WidgetSite) => void,
  onInfoBoxClicked: () => void,
  musterCount?: number,
  setSiteSearchValue: (value: string) => void
} & TestableComponent;

const SiteInfoBoxComponent: FC<Props> = ({
  selectedSite,
  siteList,
  isExpanded,
  oSiteSelected,
  onInfoBoxClicked,
  musterCount,
  setSiteSearchValue,
  dataTestId = "top-menu-critical-locations"
}) => {
  const { classes } = useCountryInfoBoxStyles();
  
  return(
    <DroppableMapTopInfoBox
      title={ 'Critical Locations' }
      value={ selectedSite?.name ?? '' }
      isExpanded={ isExpanded }
      onToggle={ onInfoBoxClicked }
      musterCount={ musterCount }
      data-testid={ dataTestId }
    >
      { /* eslint-disable-next-line react/jsx-no-bind */ }
      <TextInput onChange={ (e) => setSiteSearchValue(e.target.value) }
        placeholder="Search for sites.." 
        className={ classes.searchInput }
        data-testid="top-menu-critical-locations-search-input"
      />
      <SiteSearchDropdown
        siteList={ siteList }
        onSiteSelected={ oSiteSelected }
        musterCount={ musterCount }
      />
    </DroppableMapTopInfoBox>
  );
};

export default SiteInfoBoxComponent;
