import { ChangeEvent, FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { TimeScale } from "@/tenant-context/control-location-graph/store/location-graph/location-graph.model";

import TimeScaleDropdown from './TimeScaleDropdown.component';

const TimeScaleDropdownContainer: FC = () => {
  const timeScale = useSelector((state: RootState) => state.locationGraph.timeScale);

  const {
    locationGraph: {
      setTimeScale
    }
  } = useDispatch<Dispatch>();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { target: { value } } = event;

      if (!value) {
        return;
      }

      setTimeScale(value as TimeScale);
    },
    [setTimeScale]
  );

  return (
    <TimeScaleDropdown
      timeScale={ timeScale }
      onChange={ handleChange }
    />
  );
};

export default TimeScaleDropdownContainer;