/* eslint-disable no-magic-numbers,react/jsx-props-no-spreading */
import { useMantineTheme } from "@mantine/core";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { Pie } from "@visx/shape";
import { PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { FC, useCallback } from "react";

import {
  useMassCommsSummaryGraphStyles
} from "@/tenant-context/control-mass-comms/components/SummaryPage/MassCommsSummaryGraph/MassCommsSummaryGraph.styles";
import { ReactComponent as AppGrayIcon } from "@/tenant-context/control-mass-comms/icons/app-gray.svg";
import { ReactComponent as EmailGrayIcon } from "@/tenant-context/control-mass-comms/icons/email-gray.svg";
import { ReactComponent as PhoneGrayIcon } from "@/tenant-context/control-mass-comms/icons/phone-gray.svg";
import { ReactComponent as SMSGrayIcon } from "@/tenant-context/control-mass-comms/icons/sms-gray.svg";
import { MassCommsSummaryChartDataItem } from "@/tenant-context/control-mass-comms/types/MassComms.types";

import AnimatedPie from "./AnimatedPie.component";
import { defaultPieMargin } from "./MassCommsSummaryGraph.utils";

type Props = {
  summaryData: Array<MassCommsSummaryChartDataItem>;
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  isAnimated?: boolean;
  activeChannel: { title: string, count: number }
  onMouseEnterPie: (event: PieArcDatum<MassCommsSummaryChartDataItem>) => void;
  onMouseLeavePie: (event: PieArcDatum<MassCommsSummaryChartDataItem>) => void;
}

const MassCommsSummaryGraphComponent: FC<Props> = ({
  summaryData,
  width,
  height,
  margin = defaultPieMargin,
  isAnimated = true,
  activeChannel,
  onMouseEnterPie,
  onMouseLeavePie
}) => {
  const { classes } = useMassCommsSummaryGraphStyles();
  const theme = useMantineTheme();

  const getChannelColor = useCallback((channel: string) => {
    const channelColorMap: Record<string, string> = {
      'app': theme.colors.blue[4],
      'sms': theme.colors.blue[4],
      'voice': theme.colors.royalBlue[0],
      'email': theme.colors.skyBlue[0],
      'appAndEmail': theme.colors.dodgerBlue[0],
      'appAndSms': theme.colors.turquoiseBlue[0],
      'appAndVoice': theme.colors.blue[4],
      'appAndEmailAndSms': theme.colors.navyBlue[0],
      'appAndEmailAndVoice': theme.colors.royalBlue[1],
      'appAndSmsAndVoice': theme.colors.skyBlue[0],
      'emailAndSmsAndVoice': theme.colors.dodgerBlue[0],
      'emailAndSms': theme.colors.turquoiseBlue[0],
      'emailAndVoice': theme.colors.blue[4],
      'smsAndVoice': theme.colors.navyBlue[0],
      'all': theme.colors.royalBlue[1],
      'none': theme.colors.risk[4]
    };

    return channelColorMap[channel];
  },  [ theme.colors ]);

  // accessor functions
  const getChannelCount = useCallback((d: MassCommsSummaryChartDataItem) => d.count, []);

  const getChannel = useCallback((d: PieArcDatum<MassCommsSummaryChartDataItem>) => d.data.field, []);
  const getColor = useCallback(
    (channel: PieArcDatum<MassCommsSummaryChartDataItem>) => getChannelColor(channel.data.field),
    [getChannelColor]
  );

  const getTooltipContent = useCallback((data: { title: string, count: number }) => {
    const { count, title } = data;
    return (<>
      <div className={ classes.tooltipCount }>
        { count }
        <span>person{ count > 0 ? 's' : '' }</span>
      </div>
      <hr style={ { marginTop: 1, marginLeft: -1, borderColor: `${theme.colors.neutral[6]}` } }/>
      <div className={ classes.tooltipChannels }>
        { ['app', 'appAndEmail', 'appAndSms', 'appAndVoice', 'appAndEmailAndSms', 'appAndEmailAndVoice', 'appAndSmsAndVoice', 'all'].includes(title) && <AppGrayIcon/> }
        { ['email', 'appAndEmail', 'appAndEmailAndSms', 'appAndEmailAndVoice', 'emailAndSmsAndVoice', 'emailAndSms', 'emailAndVoice', 'all'].includes(title) && <EmailGrayIcon/> }
        { ['voice', 'appAndVoice', 'appAndEmailAndVoice', 'appAndSmsAndVoice', 'emailAndSmsAndVoice', 'emailAndVoice', 'smsAndVoice', 'all'].includes(title) && <PhoneGrayIcon/> }
        { ['sms', 'appAndSms', 'appAndEmailAndSms', 'appAndSmsAndVoice', 'emailAndSmsAndVoice', 'emailAndSms', 'smsAndVoice', 'all'].includes(title) && <SMSGrayIcon/> }
        { ['none'].includes(title) && <span>None</span> }
      </div>
    </>);
  }, [ classes.tooltipChannels, classes.tooltipCount, theme.colors.neutral ]);

  if (width < 10) {
    return null;
  }

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const donutThickness = 22;

  return (
    <ParentSize debounceTime={ 999999 }>
      { ({ width: ParentWidth, height: ParentHeight }) =>
        <svg width={ ParentWidth } height={ ParentHeight }>
          <Group top={ (ParentHeight / 2) } left={ (ParentWidth / 2) }>
            <Pie
              data={ summaryData }
              pieValue={ getChannelCount }
              outerRadius={ radius }
              innerRadius={ radius - donutThickness }
              cornerRadius={ 0 }
              padAngle={ 0.005 }
            >
              { (pie) => {
                return (
                  <>
                    { /* Pie Arcs */ }
                    <AnimatedPie
                      { ...pie }
                      animate={ isAnimated }
                      getKey={ getChannel }
                      getColor={ getColor }
                      onMouseEnter={ onMouseEnterPie }
                      onMouseLeave={ onMouseLeavePie }
                      /* eslint-disable-next-line react/jsx-no-bind */
                      tooltip={ getTooltipContent }
                    />

                    { /*Count text*/ }
                    <text
                      y="30"
                      textAnchor={ "middle" }
                      className={ classes.count }
                    >
                      { activeChannel.count }
                    </text>

                    { /* Title text */ }
                    <text
                      y="-20"
                      className={ classes.peopleText }
                      textAnchor="middle"
                    >
                      { activeChannel.title }
                    </text>
                  </>
                );
              } }
            </Pie>
          </Group>
        </svg> }
    </ParentSize>
  );
};

export default MassCommsSummaryGraphComponent;
