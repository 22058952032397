import { pointsWithinPolygon } from '@turf/turf';
import { useCallback } from 'react';

export default function useFeaturesInSideGeofence() {

  const numberOfPointsWithinGeofence = useCallback((featureCollection, polygon) => {
    try {
      const ptsWithin = pointsWithinPolygon(featureCollection, polygon);
      return ptsWithin.features.length;
    } catch (e) {
      return 0;
    }
  }, []);

  return {
    numberOfPointsWithinGeofence
  };
}
