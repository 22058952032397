import { throttle } from "@github/mini-throttle";
import { createModel } from "@rematch/core";
import EventSource from "eventsource";

import { Dispatch, RootModel, RootState, store } from "@/core/store";
import { mergeFeatureCollections } from "@/tenant-context/common/util/location-event/merge-feature-collections";
import { mapImpactEngineRiskAlertToRiskAlertEvent } from "@/tenant-context/common/util/risk";
import { Visualizable, withVisualisableTraits } from "@/tenant-context/common/util/with-visualisable-traits";
import { parseGeoJSON } from "@/tenant-context/common/workers/geo-json-mapper-worker/geo-json-mapper-worker.external";
import { createImpactEngineRiskAlertStream } from "@/tenant-context/visualisation-risk-alerts/api/risk-alerts";
import {
  RiskAlertEvent,
  RiskAlertFeatureCollection
} from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskConnectorExternalData } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts-generic';

export type RiskAlertState = Visualizable<{
  eventQueue: RiskAlertEvent<RiskConnectorExternalData>[],
  currentRiskAlert?:RiskAlertEvent,
  showOnlyImpactedRiskEvents: boolean
}, RiskAlertFeatureCollection>;

export type NonSerializableRiskAlertState = {
  riskAlertStream?: EventSource
};

const initialState: RiskAlertState = {
  eventQueue: [],
  geoData: {
    features: [],
    type: 'FeatureCollection'
  },
  currentRiskAlert : undefined,
  showOnlyImpactedRiskEvents: true
};

const nonSerializableState: NonSerializableRiskAlertState = {};

export const RISK_ALERT_UPDATE_INTERVAL = 500;
export const DISPATCH_RISK_ALERTS_INTERVAL = 333;

// TODO type this, impossible to read
const riskAlertModel = withVisualisableTraits()({
  name: 'riskAlerts',
  state: initialState,
  reducers: {
    ENQUEUE_RISK_ALERT_EVENT(
      { eventQueue, ...state }: RiskAlertState,
      event: RiskAlertEvent<RiskConnectorExternalData>
    ): RiskAlertState {
      return {
        ...state,
        eventQueue: [
          ...eventQueue,
          event
        ]
      };
    },
    ENQUEUE_RISK_ALERT_EVENT_BATCH(
      { eventQueue, ...state }: RiskAlertState,
      events: Array<RiskAlertEvent<RiskConnectorExternalData>>
    ): RiskAlertState {
      return {
        ...state,
        eventQueue: [
          ...eventQueue,
          ...events
        ]
      };
    },

    DEQUEUE_EVENTS(
      state: RiskAlertState,
      dequeuedEvents: RiskAlertEvent<RiskConnectorExternalData>[]
    ): RiskAlertState {
      const {
        eventQueue
      } = state;

      const dequeuedEventIds = new Set(
        dequeuedEvents.map(({
          tid
        }) => tid)
      );

      const updatedEventQueue = eventQueue.filter(
        ({ tid }) => !dequeuedEventIds.has(tid)
      );

      return {
        ...state,
        eventQueue: updatedEventQueue
      };
    },

    SET_SHOW_ONLY_IMPACTED_RISK_EVENTS(state: RiskAlertState, showOnlyImpactedRiskEvents: boolean): RiskAlertState {
      return {
        ...state,
        showOnlyImpactedRiskEvents
      };
    },

    SET_CURRENT_RISK_ALERT(state:RiskAlertState, clickedAlert:RiskAlertEvent){
      return {
        ...state,
        currentRiskAlert:clickedAlert
      };
    },

    CLEAR_CURRENT_RISK_ALERT(state:RiskAlertState){
      return {
        ...state,
        currentRiskAlert:undefined
      };
    }

  },
  effects: (dispatch: Dispatch) => ({
    async subscribeToRiskAlerts(_: void, state: RootState): Promise<void> {
      const {
        commonData:{
          riskProviders
        },
        riskTimeline: {
          filterDataRange: [ startDate, endDate ]
        }
      } = state;

      // TODO this slows the waterfall down
      // Until riskProviders (that the tenant is subscribed to) are fetched from the server
      // This risk alert subscription will not fire, it will wait for that to be fetched
      if (!riskProviders || riskProviders.length === 0) {
        return;
      }

      const riskProvidersList = state.commonData?.riskProviders
        .map((provider) => provider.providerName)
        .join(',');

      const query = generateRiskQuery(riskProvidersList, startDate, endDate);
      const eventList: Array<RiskAlertEvent<RiskConnectorExternalData>> = [];
      const throttledEnqueueRiskAlertEventBatch = throttle(() => {
        dispatch.riskAlerts.ENQUEUE_RISK_ALERT_EVENT_BATCH(eventList);
        eventList.length = 0;
      }, DISPATCH_RISK_ALERTS_INTERVAL);


      const stream = await createImpactEngineRiskAlertStream(
        query,
        state.commonData.tenantId,
        (ev) => {

          const riskAlertEvent = mapImpactEngineRiskAlertToRiskAlertEvent(ev);

          if (!riskAlertEvent) {
            return;
          }

          eventList.push(riskAlertEvent);
          throttledEnqueueRiskAlertEventBatch();
        }
      );

      setInterval(() => {
        if (!store.getState().riskAlerts.eventQueue.length) {
          return;
        }

        dispatch.riskAlerts.processEnqueuedRiskAlertEvents();
      }, RISK_ALERT_UPDATE_INTERVAL);

      nonSerializableState.riskAlertStream = stream;
    },

    async processEnqueuedRiskAlertEvents(_: void, state: RootState): Promise<void> {
      const {
        riskAlerts: {
          eventQueue,
          geoData
        }
      } = state;

      if (!eventQueue.length) {
        return;
      }

      const eventsToProcess = [...eventQueue];

      const { data: geoJSONData } = await parseGeoJSON({
        data: eventsToProcess,
        params: {
          Point: [
            'json.meta.geojson.geometry.coordinates.1',
            'json.meta.geojson.geometry.coordinates.0'
          ]
        }
      });

      const incomingRiskAlertFeatureCollection = geoJSONData as RiskAlertFeatureCollection;

      const updatedRiskAlertFeatureCollection: RiskAlertFeatureCollection = await mergeFeatureCollections(
        geoData,
        incomingRiskAlertFeatureCollection,
        'tid'
      );

      // Update risk alert feature collection
      dispatch.riskAlerts.SET_GEO_DATA(updatedRiskAlertFeatureCollection);

      // Throw away processed events
      dispatch.riskAlerts.DEQUEUE_EVENTS(eventsToProcess);
    },

    async enableRiskAlertDrawer(clickedEvent:RiskAlertEvent):Promise<void>{
      dispatch.riskAlerts.SET_CURRENT_RISK_ALERT(clickedEvent);

      dispatch.drawer.openRightDrawer('risk-events');
    },

    async disableRiskAlertDrawer() {
      dispatch.drawer.closeDrawer();
    },

    clearCurrentRiskAlerts(_: void, _state: RootState): void {
      dispatch.riskAlerts.SET_GEO_DATA({
        features: [],
        type: 'FeatureCollection'
      });
    }
  })
});

export const riskAlerts = createModel<RootModel>()(
  riskAlertModel
);

const generateRiskQuery = (riskProvider: string, startDate: Date | null, endDate: Date | null) => {
  if (!startDate || !endDate) {
    return `request.source='${riskProvider}'+AND+impactStatus='OPEN'`;
  }

  return `request.source='${riskProvider}' AND request.start_date>${startDate.getTime()} AND request.end_date<${endDate.getTime()}`;
};
