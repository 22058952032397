import { Accordion, Text } from '@mantine/core';
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { useMiniProfileStyles } from "@/tenant-context/control-profile/components/ui/MiniProfile/MiniProfile.styles";
import { ReactComponent as AlarmIcon } from '@/tenant-context/control-profile/icons/alarmIcon.svg';
import { ReactComponent as AndroidIcon } from '@/tenant-context/control-profile/icons/android.svg';
import { ReactComponent as BatteryStatusIcon } from '@/tenant-context/control-profile/icons/batteryStatusIcon.svg';
import { ReactComponent as ConnectionIcon } from '@/tenant-context/control-profile/icons/connectionIcon.svg';
import { ReactComponent as DeviceIcon } from '@/tenant-context/control-profile/icons/deviceIcon.svg';
import { ReactComponent as IOSIcon } from '@/tenant-context/control-profile/icons/ios.svg';
import { ReactComponent as LastUpdatedIcon } from '@/tenant-context/control-profile/icons/lastUpdatedIcon.svg';
import { ReactComponent as LocationFrequencyIcon } from '@/tenant-context/control-profile/icons/locationFrequencyIcon.svg';
import { ReactComponent as OSIcon } from '@/tenant-context/control-profile/icons/osIcon.svg';
import { ReactComponent as SignalIcon } from '@/tenant-context/control-profile/icons/signalIcon.svg';
import { ReactComponent as VersionIcon } from '@/tenant-context/control-profile/icons/versionIcon.svg';
import { DeviceOsType } from "@/tenant-context/control-profile/types/mini-profile";
import { getTime } from "@/tenant-context/control-profile/util/time-convector";

type Props = {
  isInFullscreenOverlay: boolean,
}

const MiniProfileAppData: FC<Props> = ({
  isInFullscreenOverlay
}) => {
  const { classes } = useMiniProfileStyles(isInFullscreenOverlay);
  const { classes: { rowContainer }, cx } = useMiniProfileStyles(isInFullscreenOverlay);
  const currentUserProfile = useSelector((state: RootState) => state.userProfile?.currentUserProfile);


  const deviceOSIcon = useMemo(() => {
    if (currentUserProfile?.operatingSystem?.toLowerCase() === DeviceOsType.IOS) {
      return <IOSIcon/>;
    }
    if (currentUserProfile?.operatingSystem?.toLowerCase() === DeviceOsType.ANDROID) {
      return <AndroidIcon/>;
    } else {
      return <OSIcon/>;
    }
  }, [currentUserProfile]);

  return (
    <Accordion variant={ 'contained' }>
      <Accordion.Item value="appData">
        <Accordion.Control>
          <Text>App Device</Text>
        </Accordion.Control>
        <Accordion.Panel>
          <div className={ cx(classes.contactsContainer, rowContainer) }>
            { (currentUserProfile?.lastUpdatedOn || currentUserProfile?.lastUpdatedOn !== 0) && (
              <div className={ classes.locationRow }>
                <div className={ classes.locationAttribute }>
                  LAST UPDATED
                </div>
                <div className={ classes.locationIcon }>
                  <LastUpdatedIcon/>
                </div>
                <div className={ classes.contactValue }>
                  { getTime(currentUserProfile?.lastUpdatedOn) }
                </div>
              </div>) }


            { currentUserProfile?.batteryLevel && (
              <div className={ classes.locationRow }>
                <div className={ classes.locationAttribute }>
                  BATTERY STATUS
                </div>
                <div className={ classes.locationIcon }>
                  <BatteryStatusIcon/>
                </div>
                <div className={ classes.contactValue }>
                  { currentUserProfile?.batteryLevel }
                </div>
              </div>
            ) }
            <div className={ classes.locationRow }>
              <div className={ classes.locationAttribute }>
                ALARM DEVICE
              </div>
              <div className={ classes.locationIcon }>
                <AlarmIcon/>
              </div>
              <div className={ classes.contactValue }>
                { currentUserProfile?.bluetoothOn ? 'Paired' : 'Disconnected' }
              </div>
            </div>
            { currentUserProfile?.connectivity && <div className={ classes.locationRow }>
              <div className={ classes.locationAttribute }>
                CONNECTION
              </div>
              <div className={ classes.locationIcon }>
                <ConnectionIcon/>
              </div>
              <div className={ classes.contactValue }>
                { currentUserProfile?.connectivity }
              </div>
            </div> }
            { currentUserProfile?.signalStrength && <div className={ classes.locationRow }>
              <div className={ classes.locationAttribute }>
                SIGNAL
              </div>
              <div className={ classes.locationIcon }>
                <SignalIcon/>
              </div>
              <div className={ classes.contactValue }>
                { currentUserProfile?.signalStrength }
              </div>
            </div> }
            { currentUserProfile?.locationFrequency && <div className={ classes.locationRow }>
              <div className={ classes.locationAttribute }>
                LOCATION
              </div>
              <div className={ classes.locationIcon }>
                <LocationFrequencyIcon/>
              </div>
              <div className={ classes.contactValue }>
                { currentUserProfile?.locationFrequency }
              </div>
            </div> }
            { currentUserProfile?.deviceBeingUsed && <div className={ classes.locationRow }>
              <div className={ classes.locationAttribute }>
                DEVICE
              </div>
              <div className={ classes.locationIcon }>
                <DeviceIcon/>
              </div>
              <div className={ classes.contactValue }>
                { currentUserProfile?.deviceBeingUsed }
              </div>
            </div> }
            { currentUserProfile?.operatingSystem && <div className={ classes.locationRow }>
              <div className={ classes.locationAttribute }>
                OPERATING SYSTEM
              </div>
              <div className={ classes.locationIcon }>
                { deviceOSIcon }
              </div>
              <div className={ classes.contactValue }>
                { currentUserProfile?.operatingSystem }
              </div>
            </div> }
            { currentUserProfile?.version && <div className={ classes.locationRow }>
              <div className={ classes.locationAttribute }>
                VERSION
              </div>
              <div className={ classes.locationIcon }>
                <VersionIcon/>
              </div>
              <div className={ classes.contactValue }>
                { currentUserProfile?.version }
              </div>
            </div> }
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default MiniProfileAppData;
