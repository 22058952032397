import { ColDef } from "ag-grid-community";
import { forwardRef, ForwardRefRenderFunction, useCallback } from "react";
import { useSelector } from "react-redux";

import ServerDataGrid from "@/common/components/AgGrid/ServerSide/ServerDataGrid.container";
import { DataGrid, GridParams } from "@/common/types/ag-grid";
import { RootStateWithLoading } from "@/core/store";
import { useProfilePersonalContactTabStyles } from '@/tenant-context/control-profile/components/tabs/ProfilePersonalContactTab/ProfilePersonalContactTab.style';
import GenericProfileTab from "@/tenant-context/control-profile/components/ui/GenericProfileTab";
import ProfileLoadingOverlayComponent from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay/ProfileLoadingOverlay.component';
import ProfileTabActionButton from "@/tenant-context/control-profile/components/ui/ProfileTabActionButton";
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';
import { ProfileDocumentDetails } from "@/tenant-context/control-profile/types/profile";

type Props = {
  onAddClick: () => void,
  isOwnProfile: () => boolean
  columnDefs: ColDef[],
  getData: (payload: {
    gridParams: GridParams,
  }) => Promise<{items: Array<ProfileDocumentDetails>}>,
}

const ProfileDocumentsTab: ForwardRefRenderFunction<DataGrid, Props>  = ({
  onAddClick,
  isOwnProfile,
  columnDefs,
  getData
},ref) => {

  const { classes: {  relative } } = useProfilePersonalContactTabStyles();

  const isDocumentLoading = useSelector((state: RootStateWithLoading) => state.loading.effects.profile.deleteDocument);

  const renderAddDocumentButton = useCallback(() => (
    <ProfileTabActionButton
      onClick={ onAddClick }
      requiredPolicies={ isOwnProfile() ? [] : ProfileRequiredPoliciesConfig.EDIT_DOCUMENTS }
    >
      Add Document
    </ProfileTabActionButton>
  ), [isOwnProfile, onAddClick]);

  return (
    <GenericProfileTab
      heading="Documents"
      renderAction={ renderAddDocumentButton }
      className={ relative }
    >
      <div className="ag-theme-alpine-dark ag-theme-rt">
        <ServerDataGrid
          id={ "profile-documents-grid" }
          columnDefs={ columnDefs }
          ref={ ref }
          getData={ getData }
        />
      </div>



      <ProfileLoadingOverlayComponent
        isLoading={ isDocumentLoading }
      />
    </GenericProfileTab>
  );
};

export default forwardRef(ProfileDocumentsTab) as (
    props: Props & React.RefAttributes<DataGrid>
) => JSX.Element;