import { createStyles, MantineTheme } from "@mantine/core";

export const useIconProgressBarStyles = createStyles((theme: MantineTheme) => ({
  progressBarContainer: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  progressedBar: {
    backgroundColor: theme.other.semantic.button.default.default,
    height: '2px'
  },
  progressIndicator: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    border: `0.5px solid ${theme.other.semantic.button.default.default}`,
    display: 'flex',
    backgroundColor: theme.other.semantic.button.default.default,
    alignItems: 'center',
    justifyContent: 'center',
    path: {
      fill: theme.colors.white
    },
    svg: {
      width: '12px',
      height: '12px'
    }
  },
  pendingBar: {
    backgroundColor: theme.colors.neutral[7],
    height: '2px'
  }
}));
