import { FC, useContext } from "react";

import { BigMapPopupIds, useMapPopupList } from "@/tenant-context/common/hooks/useMapPopupList";
import { PeopleContext } from "@/tenant-context/visualisation-people/context/People.context";

import PeopleClusterPopup from "./PeopleClusterPopup.component";
import PeopleLocationPopup from "./PeopleLocationPopup.component";

const PeopleLocationPopupContainer: FC = () => {
  const {
    mapKey,
    handleClickZoomToPerson,
    handleClickMoreDetails,
    handleClickClosePopup,
    handleClusterItemClick
  } = useContext(PeopleContext);

  const { popups } = useMapPopupList(mapKey);

  return (
    <>
      { /* Cluster popup */ }
      { popups
        .filter(({ id }) => id.startsWith(BigMapPopupIds.PeopleCluster))
        .map(({ id, position }) => (
          <PeopleClusterPopup
            key={ id + position.join(",") }
            onClickClose={ handleClickClosePopup }
            onClickPersonItem={ handleClusterItemClick }
          />
        )) }

      { /* Individual person popup */ }
      { popups
        .filter(({ id }) => id.startsWith(BigMapPopupIds.PeopleLocation))
        .map(({ id }) => (
          <PeopleLocationPopup
            key={ id }
            popupId={ id }
            onClickZoomTo={ handleClickZoomToPerson }
            onClickMoreDetails={ handleClickMoreDetails }
            onClickClose={ handleClickClosePopup }
          />
        )) }
    </>
  );
};

export default PeopleLocationPopupContainer;
