import { Accordion } from '@mantine/core';
import { FC } from "react";

import TruncatedTextWithTooltip from "@/common/components/TruncatedTextWithTooltip";
import { useMiniProfileStyles } from "@/tenant-context/control-profile/components/ui/MiniProfile/MiniProfile.styles";
import { ReactComponent as MobileIcon } from '@/tenant-context/control-profile/icons/mobilePhoneIcon.svg';
import { ReactComponent as WorkEmailIcon } from '@/tenant-context/control-profile/icons/workEmailIcon.svg';
import { ReactComponent as WorkPhoneIcon } from '@/tenant-context/control-profile/icons/workPhoneIcon.svg';
import { MiniProfileDatails } from "@/tenant-context/control-profile/types/mini-profile";

type Props = {
  profileData?: MiniProfileDatails,
  isInFullscreenOverlay: boolean
}

const findByType = (contacts: MiniProfileDatails["contacts"], type: string) => {
  return contacts.find(
    o => o.type === type
  )?.value;
};

const MiniProfileContacts: FC<Props> = ({
  profileData,
  isInFullscreenOverlay
}) => {
  const { classes, cx } = useMiniProfileStyles(isInFullscreenOverlay);

  const personalMobile = findByType(profileData?.contacts ?? [], "MOBILE");
  const workPhone = findByType(profileData?.contacts ?? [], "PHONE");
  const workEmail = findByType(profileData?.contacts ?? [], "EMAIL");

  if (!(personalMobile || workPhone || workEmail)) {
    return null;
  }

  return (
    <Accordion variant={ 'contained' }>
      <Accordion.Item value={ "contacts" }>
        <Accordion.Control>
          Contact
        </Accordion.Control>
        <Accordion.Panel>
          <div className={ cx(classes.contactsContainer, classes.rowContainer) }>
            { personalMobile && (
              <div className={ classes.contactsRow }>
                <div className={ classes.contactAttribute }>
                  <MobileIcon />
                  <div>PERSONAL MOBILE</div>
                </div>
                <div className={ classes.contactValue }>
                  { personalMobile }
                </div>
              </div>
            ) }

            { workPhone && (
              <div className={ classes.contactsRow }>
                <div className={ classes.contactAttribute }>
                  <WorkPhoneIcon />
                  <div>WORK PHONE</div>
                </div>
                <div className={ classes.contactValue }>
                  { workPhone }
                </div>
              </div>
            ) }

            { workEmail && (
              <div className={ classes.contactsRow }>
                <div className={ classes.contactAttribute }>
                  <WorkEmailIcon />
                  <div>WORK EMAIL</div >
                </div>
                <div className={ classes.contactValue }>
                  <TruncatedTextWithTooltip fullText={ workEmail } maxLength={ 22 }/>
                </div>
              </div>
            ) }
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default MiniProfileContacts;
