import { CSSObject } from "@mantine/core";
import type { IControl } from "mapbox-gl";

import { toStyleString } from "@/common/util/css";

export class Container implements IControl {
  private map?: mapboxgl.Map;

  private container?: HTMLDivElement;

  constructor(
    private containerId: string,
    private width: string,
    private height: string,
    private layout: 'horizontal' | 'vertical',
    private containerClassNames?: string,
    private containerStyles?: CSSObject,
    private applyDefaultControlStyles: boolean = true
  ) {
  }

  onAdd(map: mapboxgl.Map): HTMLElement {
    this.map = map;
    const container = document.createElement('div');
    container.id = this.containerId;
    container.className = `mapbox-ctrl-${this.containerId} ${this.applyDefaultControlStyles ? 'mapboxgl-ctrl' : ''}`
      .concat(this.containerClassNames ?? '');

    if (this.containerStyles) {
      // TODO this does not work
      container.setAttribute('style', toStyleString({
        display: 'flex',
        'flex-direction': this.layout === 'horizontal' ? 'row' : 'column',
        ...this.containerStyles
      }));
    } else {
      const containerStyle = `
        display: flex;
        flex-direction: ${this.layout === 'horizontal' ? 'row' : 'column'};
        width: ${this.width};
        height: ${this.height};
        ${!this.applyDefaultControlStyles ? 'pointer-events: auto;' : ''}
      `;

      container.setAttribute('style', containerStyle);
    }

    this.container = container;
    return this.container;
  }

  onRemove(_: mapboxgl.Map) {
    return;
  }
}
