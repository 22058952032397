import { createStyles } from "@mantine/core";

export const useTimeTravelControlsStyles = createStyles(() => ({
  control: {
    cursor: 'pointer'
  },
  timeTravelBtn:{
    '&:hover':{
      filter: "brightness(0.85)"
    }
  }
}));