import MapboxDraw, { DrawCreateEvent, DrawDeleteEvent, DrawModes, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw';
import { EventData, MapLayerEventType } from "mapbox-gl";
import { CircleMode } from 'mapbox-gl-draw-circle';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import type { ControlPosition, MapRef } from 'react-map-gl';
import { useControl } from 'react-map-gl';

import ExtendedDirectSelectMode
  from '@/tenant-context/control-draw/controls/MapboxDrawControl/ExtendedDirectSelectMode';
import ExtendedDragCircleMode from '@/tenant-context/control-draw/controls/MapboxDrawControl/ExtendedDragCircletMode';
import ExtendedSimpleSelectMode
  from '@/tenant-context/control-draw/controls/MapboxDrawControl/ExtendedSimpleSelectMode';

type ControlTypes =
  | 'point'
  | 'line_string'
  | 'polygon'
  | 'trash'
  | 'draw_circle'
  | 'drag_circle'
  | 'combine_features'
  | 'uncombine_features';

type DrawControlProps = {
  keybindings?: boolean;
  touchEnabled?: boolean;
  boxSelect?: boolean;
  clickBuffer?: number;
  touchBuffer?: number;
  disableDragging?: boolean;
  controls?: Partial<{ [name in ControlTypes]: boolean }>;
  displayControlsDefault?: boolean;
  modes?: DrawModes;
  userProperties?: boolean;
  position?: ControlPosition;
  styles?: object[];
  onCreate: (evt: MapLayerEventType & EventData & DrawCreateEvent) => void;
  onUpdate: (evt: MapLayerEventType & EventData & DrawUpdateEvent) => void;
  onDelete: (evt: MapLayerEventType & EventData & DrawDeleteEvent) => void;
  onDrawControlCreated?: (mapboxDraw: MapboxDraw) => void,
  onSelectionChanged: (evt: MapLayerEventType & EventData) => void;
};

function DrawControl({
  keybindings,
  touchEnabled,
  boxSelect,
  clickBuffer,
  touchBuffer,
  disableDragging = false,
  controls,
  displayControlsDefault,
  modes,
  userProperties,
  position,
  styles,
  onCreate,
  onUpdate,
  onDelete,
  onSelectionChanged,
  onDrawControlCreated
}: DrawControlProps) {

  const allModes = {
    ...MapboxDraw.modes,
    ...modes,
    draw_point: DrawRectangle,
    draw_circle: CircleMode,
    drag_circle: ExtendedDragCircleMode,
    direct_select: ExtendedDirectSelectMode,
    simple_select: ExtendedSimpleSelectMode,

    ...disableDragging && {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      simple_select: { ...ExtendedSimpleSelectMode, dragMove() {} },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      direct_select: { ...ExtendedDirectSelectMode, dragFeature() {} }
    }
  };

  const mapboxDraw = new MapboxDraw({
    keybindings,
    touchEnabled,
    boxSelect,
    clickBuffer,
    touchBuffer,
    controls,
    userProperties: userProperties ?? true,
    modes: allModes,
    displayControlsDefault: displayControlsDefault ?? false,
    ...styles && { styles }
  });



  useControl(
    ({ map }: { map: MapRef }) => {
      map.on('draw.create', onCreate);
      map.on('draw.update', onUpdate);
      map.on('draw.delete', onDelete);
      map.on('draw.selectionchange', onSelectionChanged);

      if (onDrawControlCreated) {
        onDrawControlCreated(mapboxDraw);
      }

      return mapboxDraw;
    },
    ({ map }: { map: MapRef }) => {
      map.off('draw.create', onCreate);
      map.off('draw.update', onUpdate);
      map.off('draw.delete', onDelete);
    },
    {
      position: position
    }
  );

  return null;
}

export default DrawControl;
