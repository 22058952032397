import { Feature, FeatureCollection } from "geojson";

import WorkerExternalFacade from "@/common/util/worker/external-facade";

import {
  GeoJSONMapperWorkerMessageType,
  GeoJSONMapperWorkerResponseType } from "./geo-json-mapper-worker.types";

const geoJSONMapperWorker = WorkerExternalFacade.create<
  GeoJSONMapperWorkerMessageType,
  GeoJSONMapperWorkerResponseType
>(
  new Worker(new URL(
    './geo-json-mapper-worker.isolated.ts',
    import.meta.url
  ))
);

const parseGeoJSON = async <T extends Feature | FeatureCollection>(
  params: GeoJSONMapperWorkerMessageType
): Promise<GeoJSONMapperWorkerResponseType<T>> => {
  const result = await geoJSONMapperWorker.execute(params);

  return result as GeoJSONMapperWorkerResponseType<T>;
};

export { parseGeoJSON };



