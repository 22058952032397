/* eslint-disable react/jsx-no-bind,react/jsx-props-no-spreading */
import { Button, Checkbox, Loader, Stack, Text } from "@mantine/core";
import { FC, ReactNode, useCallback } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { ReactComponent as RightIcon } from "@/common/icons/Arrows/right-arrow.svg";
import { ReactComponent as ExtremeRiskIcon } from "@/common/icons/markers/RiskEvents/Extreme/Base.svg";
import { ReactComponent as HighRiskIcon } from "@/common/icons/markers/RiskEvents/High/Base.svg";
import { ReactComponent as LowRiskIcon } from "@/common/icons/markers/RiskEvents/Low/Base.svg";
import { ReactComponent as MediumRiskIcon } from "@/common/icons/markers/RiskEvents/Medium/Base.svg";
import { ReactComponent as ModerateRiskIcon } from "@/common/icons/markers/RiskEvents/Moderate/Base.svg";
import { capitalizeWord } from "@/common/util/format/string";
import { TRAVELLERS_SEARCH_FIELDS } from "@/tenant-context/control-travellers-search/config/travellers-search.config";
import { useTravelSearchControlDrawerStyles } from "@/tenant-context/control-travellers-search/drawers/TravellersSearchControlsDrawer/TravellersSearchControlDrawer.styles";
import {
  DestinationRiskLevel,
  TravellersSearchFormData
} from "@/tenant-context/control-travellers-search/types/travelSearch.types";

type Props = {
  travellersSearchForm: UseFormReturn<TravellersSearchFormData>;
  isLoading: boolean;
  onClickSearchButton: () => void;
};

const PeopleSettingsComponent: FC<Props> = ({ travellersSearchForm, isLoading, onClickSearchButton }) => {
  const { classes } = useTravelSearchControlDrawerStyles();
  const { control } = travellersSearchForm;

  const getRiskLevelIcon = useCallback(
    (riskLevel: string) => {
      const riskLevelIconMap: Record<string, ReactNode> = {
        extreme: <ExtremeRiskIcon width={ 24 } height={ 24 } />,
        high: <HighRiskIcon width={ 24 } height={ 24 } />,
        low: <LowRiskIcon width={ 24 } height={ 24 } />,
        medium: <MediumRiskIcon width={ 24 } height={ 24 } />,
        moderate: <ModerateRiskIcon width={ 24 } height={ 24 } />
      };

      return (
        <div className={ classes.riskIconWrapper }>
          { riskLevelIconMap[riskLevel.toLowerCase()] }
          <div className={ classes.riskText }>{ capitalizeWord(riskLevel) }</div>
        </div>
      );
    },
    [classes.riskIconWrapper, classes.riskText]
  );

  return (
    <Stack h={ `calc(100% - 4px)` }>
      <Text size={ 18 } fw={ 700 } mt={ 16 } mb={ 8 }>
        Travellers trip risk level
      </Text>

      <Controller
        name={ TRAVELLERS_SEARCH_FIELDS.DESTINATION_RISK_LEVEL }
        control={ control }
        // eslint-disable-next-line react/jsx-no-bind
        render={ ({ field }) => (
          <Checkbox.Group
            size="md"
            spacing={ 16 }
            orientation="vertical"
            // eslint-disable-next-line react/jsx-props-no-spreading
            { ...field }
          >
            <Checkbox
              className={ classes.riskCheckbox }
              value={ DestinationRiskLevel.EXTREME }
              label={ getRiskLevelIcon("extreme") }
            />
            <Checkbox
              className={ classes.riskCheckbox }
              value={ DestinationRiskLevel.HIGH }
              label={ getRiskLevelIcon("high") }
            />
            <Checkbox
              className={ classes.riskCheckbox }
              value={ DestinationRiskLevel.MEDIUM }
              label={ getRiskLevelIcon("medium") }
            />
            <Checkbox
              className={ classes.riskCheckbox }
              value={ DestinationRiskLevel.MODERATE }
              label={ getRiskLevelIcon("moderate") }
            />
            <Checkbox
              className={ classes.riskCheckbox }
              value={ DestinationRiskLevel.LOW }
              label={ getRiskLevelIcon("low") }
            />
          </Checkbox.Group>
        ) }
      />

      <Button
        mt={ 'auto' }
        fullWidth
        rightIcon={ isLoading ? <Loader size="xl" variant="oval" /> : <RightIcon /> }
        onClick={ onClickSearchButton }
      >
        Search
      </Button>
    </Stack>
  );
};

export default PeopleSettingsComponent;
