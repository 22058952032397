import { ActionIcon } from "@mantine/core";
import { FC } from "react";

import PersonAvatar from "@/common/components/PersonAvatar";
import { ReactComponent as RightArrowIcon } from "@/common/icons/Arrows/right-arrow.svg";
import { useMiniProfileStyles } from "@/tenant-context/control-profile/components/ui/MiniProfile/MiniProfile.styles";
import { MiniProfileDatails } from "@/tenant-context/control-profile/types/mini-profile";

type Props = {
  profileData?: MiniProfileDatails,
  isInFullscreenOverlay: boolean,
  onCloseClick: () => void,
}

const MiniProfileHeader: FC<Props> = ({
  profileData,
  onCloseClick,
  isInFullscreenOverlay
}) => {
  const { classes } = useMiniProfileStyles(isInFullscreenOverlay);

  const profileName = [
    profileData?.title,
    profileData?.firstName,
    profileData?.lastName
  ].filter(Boolean).join(" ");

  const personName = profileData ? profileData.firstName + " " + profileData.lastName : "";

  return (
    <section className={ classes.useAvatarContainer }>
      <PersonAvatar
        width="67px"
        height="67px"
        borderRadius="100%"
        personName={ personName }
        personAvatarUrl={ profileData?.profilePictureThumbnailUrl }
      />

      <div className={ classes.userNameData }>
        <div className={ classes.profileName }> { profileName } </div>
        <div className={ classes.position }>  { profileData?.jobTitle }</div>
        <div className={ classes.companyName }> { profileData?.company } </div>
      </div>

      { isInFullscreenOverlay &&  (
        <ActionIcon
          size={ 20 }
          onClick={ onCloseClick }
          className={ classes.actionIcon }
        >
          <RightArrowIcon />
        </ActionIcon>
      ) }
    </section>
  );
};

export default MiniProfileHeader;
