import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useTravelSearchInfoBoxStyles = createStyles((theme: MantineTheme) => ({
  wrapper: {
    display: 'flex'
  },

  infoBox: {
    display: "flex",
    boxSizing: 'border-box',
    boxShadow: "0 1px 8px rgba(0, 0, 0, 0.05)",
    minHeight: '66px',
    maxHeight: "66px",
    height: '100%',
    backgroundColor: theme.colors.neutral[9],
    border: `1px solid ${theme.colors.neutral[7]}`
  },

  infoDiv: {
    display: 'flex',
    flex: 1,
    gap: 8,
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    width: '92px',
    padding: '8px'
  },

  countDiv: {
    backgroundColor: theme.colors.neutral[9]
  },

  dayDiv: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    position: 'relative',
    alignItems: 'center',
    minWidth: '39px',
    padding: '8px'
  },

  title: {
    color: theme.other.semantic.label[3],
    letterSpacing: '0.5px',
    fontFamily:theme.fontFamily,
    fontSize: theme.fontSizes.xs,
    lineHeight: "18px",
    textTransform: "uppercase"
  },

  mainTitle: {
    margin: '0 8px'
  },

  dayCount: {
    color: theme.colors.royalBlue[0],
    fontSize: theme.fontSizes.md,
    fontFamily:theme.fontFamily
  },

  dividerContainer: {
    margin: '8px 0'
  },

  travelIcon: {
    color: theme.colors.royalBlue[0]
  }

}));
