import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { CSS_KEY } from "@/core/styles/mantine/constants";
import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useVisitRequestStyles = createStyles((theme: MantineTheme) => ({
  justifyCenter:{
    '& div':{
      justifyContent: "center"
    }
  },
  mb3:{
    marginBottom: 24
  },
  timeDisplayHeader:{
    display: "flex",
    alignItems: "center",
    color: theme.colors.neutral[5],
    textTransform: "uppercase",
    maxWidth: "100%",
    overflow: "hidden",
    fontSize: "14px",
    fontWeight: 400,
    marginBottom: 4
  },
  timeDisplay:{
    "& p":{
      lineHeight: 1.8
    },
    "& label":{
      color: theme.colors.neutral[5],
      minWidth: "100px",
      display: "inline-block"
    },
    "& svg":{
      width: 20,
      height: 20,
      fill: theme.white,
      marginRight: 8
    }
  },
  halfWidth: {
    gridColumn: "1/span 2"
  },
  searchInput: {
    '& svg > path': {
      fill: theme.colors.neutral[4]
    }
  },
  searchRight:{
    all: "unset",
    cursor: "pointer",
    color: theme.colors.neutral[5]
  },
  relative:{
    position: "relative"
  },
  dropdown:{
    maxHeight: "50vh",
    overflowY: "auto",
    backgroundColor: theme.colors.neutral[8],
    border: `1px solid ${theme.colors.neutral[6]}`

  },
  menuItem:{
    '&:hover, &[data-hovered]': {
      backgroundColor: genericColorPalette.neutral[9] + ' !important'
    }
  },
  docWrap:{
    marginTop: "-1rem"
  },
  paraLine:{
    marginBottom: 24
  },
  m_0:{
    margin: "0 !important"
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *:not(:first-child)': {
      marginLeft: 24
    }
  },
  vehicleBlock:{
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    '& > div':{
      flex: 1
    },
    padding: "1rem 0",
    '&:not(.document)':{
      background: genericColorPalette.dark[10],
      padding: "1rem"
    }
  },
  vehicleBlockWrap:{
    '&>div:nth-child(odd)':{
      background: theme.colors.neutral[8]
    }
  },
  floatBtn:{
    width: 50,
    height: 50,
    padding: 8
  },
  w50:{
    width: '50%'
  },
  tabContainer:{
    padding: '32px 0'
  },
  noteBlock:{
    display: 'flex',
    gap: 24
  },
  noteContent:{
    flex: 1
  },
  noteHeader:{
    '& h6':{
      color: theme.colors.neutral[5],
      marginBottom: 8,
      display: 'flex',
      justifyContent: 'space-between',
      '& label':{
        fontWeight: 400
      }
    },
    '& p':{
      marginBottom: 16
    }
  },
  noteActions:{
    display: 'flex',
    gap: 16
  },
  radioGroup: {
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
    label: {
      fontSize: theme.fontSizes.md,
      color: theme.colors.neutral[4]
    },
    [`& .${CSS_KEY}-Group-root`]:{
      paddingTop: 0,
      marginLeft: 16
    },
    [`& .${CSS_KEY}-InputWrapper-label`]:{
      marginBottom: 0
    },
    'input:checked': {
      backgroundColor: theme.colors.neutral[9],
      borderColor: theme.colors.neutral[5]
    },
    input: {
      backgroundColor: theme.colors.neutral[8],
      borderColor: theme.colors.neutral[5]
    },
    svg: {
      color: theme.other.semantic.accessibility.outer
    }
  },
  inputIcon:{
    display: "flex",
    alignItems: "center",
    marginRight: "16px"
  },
  subHeader:{
    fontSize: "18px",
    fontWeight: 600
  },
  asterisk:{
    '&:after':{
      color:genericColorPalette.error[0],
      content: '"*"',
      display: "inline-block",
      marginLeft: 4
    },
    position: "relative"
  },
  container:{
    background: theme.colors.neutral[9],
    padding: 24
  },
  breadcrumbs: {
    marginBottom: '32px'
  },
  anchor: {
    color: theme.colors.info[0],
    cursor: 'pointer'
  },
  mainHeader:{
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "capitalize",
    lineHeight: '150%'
  },
  tag:{
    height: 24,
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    borderRadius: 32,
    padding: '0 8px',
    textTransform: 'capitalize',
    letterSpacing: "0.5px",
    fontWeight: 500,
    '&.Arrived, &.ARRIVED':{
      background: theme.colors.green[5],
      color: theme.colors.neutral[9]
    },
    '&.Departed, &.DEPARTED':{
      background: theme.colors.blue[6],
      color: theme.colors.neutral[0]
    },
    '&.Escorting, &.ESCORTING':{
      background: theme.colors.skyBlue[0],
      color: theme.colors.neutral[9]
    },
    '&.With.Host, &.WITH_HOST':{
      background: theme.colors.aqua[5],
      color: theme.colors.neutral[9]
    },
    '&.DRAFT, &.Draft':{
      background: theme.colors.neutral[6]
    },
    '&.AWAITING_APPROVAL, &.Awaiting, &.PRINTING_IN_PROGRESS, &.PRINTING.IN.PROGRESS':{
      background: theme.colors.risk[3],
      color: theme.colors.neutral[9]
    },
    '&.APPROVED, &.Expected, &.DONE, &.READY_TO_PRINT, &.READY.TO.PRINT':{
      background: theme.colors.success[0]
    },
    '&.REJECTED, &.Rejected, &.ERROR':{
      background: theme.colors.risk[4]
    },
    '&.CANCELLED, &.Cancelled':{
      background: theme.colors.red[4],
      color: theme.colors.neutral[0]
    },
    '&.VIP':{
      background: theme.colors.purple[9],
      color: theme.colors.neutral[4]
    }
  },
  grid:{
    display: 'grid',
    margin: '32px 0'
  },
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: '0 24px',
    '&._5': {
      gridTemplateColumns: 'repeat(5, 1fr)'
    },
    '&._2':{
      gridTemplateColumns: 'repeat(2, 1fr)'
    }
  },
  visitRequestForm:{
    '& > div':{
      marginBottom: 24
    }
  },
  wrapper: {
    marginBottom: 40
  }
}));
