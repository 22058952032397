/* eslint-disable no-magic-numbers */
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { FC } from "react";

import { getRiskLevelColor } from "@/tenant-context/common/util/risk";
import { RiskChristmasTree } from "@/tenant-context/control-risk-timeline/components/RiskTimelineGraph/RiskChristmasTree";
import { maxRiskTimelineGraphAlerts } from "@/tenant-context/control-risk-timeline/config/risk-timeline.config";
import {
  GroupedRiskTimelineEvents,
  RiskLevelGroupedEvents
} from "@/tenant-context/control-risk-timeline/types/risk-timeline.types";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type Props = {
  groupLeftOffset: number,
  risksGroupedByDate: GroupedRiskTimelineEvents,
  risksGroupByLevel: RiskLevelGroupedEvents,
  index: number,
  oneTickWidth: number,
  itemsPerDate: number,
  chartHeight: number,
  theme: MantineTheme,
  onTooltipMouseMove: (event: React.MouseEvent<SVGElement, MouseEvent>, riskAlert: RiskAlertEvent, top: number,
    groupLeftOffset: number) => void,
  onTooltipMouseLeave: () => void,
  onRiskAlertClicked: (riskAlert: RiskAlertEvent) => void;
}

export const RiskEventGroup: FC<Props> = ({
  groupLeftOffset,
  risksGroupedByDate,
  risksGroupByLevel,
  index,
  oneTickWidth,
  itemsPerDate,
  chartHeight,
  theme,
  onTooltipMouseMove,
  onTooltipMouseLeave,
  onRiskAlertClicked
}) => (
  <Group
    left={ groupLeftOffset }
  >
    { risksGroupByLevel.riskEvents.map((riskItem, i) => {
      if (i > maxRiskTimelineGraphAlerts) {
        return null;
      }

      const left = (oneTickWidth / (itemsPerDate + 1)) * (index + 1) - 5;
      const defaultTop = chartHeight - 46; // 46 to comply with triangle's size
      const top = defaultTop - i * 15; // 15 to offset and overlap triangles other than the first one
      const isFirstItem = i === 0;
      const riskEventColor = getRiskLevelColor(risksGroupByLevel.riskLevel, theme);
      const christmasTreeId = `risk-event-${ risksGroupedByDate.date }-${ risksGroupByLevel.riskLevel }-${ i }`;

      if (i === maxRiskTimelineGraphAlerts) {
        const extraItemsText = `+${(risksGroupByLevel.riskEvents.length - maxRiskTimelineGraphAlerts).toString()}`;
        return (
          <Text
            key={ `${christmasTreeId}-extra-items-text` }
            dx={ left + (extraItemsText.length === 2 ? 5 : 3) }
            dy={ top + 12 }
            fill={ 'white' }
          >
            { extraItemsText }
          </Text>
        );
      }

      return (
        // One Christmas tree
        <RiskChristmasTree
          key={ christmasTreeId }
          id={ christmasTreeId }
          left={ left }
          groupLeftOffset={ groupLeftOffset }
          top={ top }
          riskItem={ riskItem }
          riskEventColor={ riskEventColor }
          isFirstRiskItem={ isFirstItem }
          onTooltipMouseMove={ onTooltipMouseMove }
          onTooltipMouseLeave={ onTooltipMouseLeave }
          onRiskAlertClicked={ onRiskAlertClicked }
        />
      );
    }) }
  </Group>
);
