import { Button } from '@mantine/core';
import { FC, Fragment, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useUnauthorizedPageStyles } from '@/core/components/UnauthorizedPage/UnauthorizedPage.styles';
import { Dispatch } from '@/core/store';

type Props = {
  isLoadingMode: boolean
}

const UnauthorizedPageComponent: FC<Props> = ({ isLoadingMode }) => {
  const { classes } = useUnauthorizedPageStyles();

  const {
    commonData: {
      SET_SHOW_LOADER_SCREEN
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    SET_SHOW_LOADER_SCREEN(isLoadingMode);
  }, [SET_SHOW_LOADER_SCREEN, isLoadingMode]);

  const home = useCallback(() => {
    return null;
  }, []);

  return (
    <div className={ classes.unauthorizedPageContainer }>
      { isLoadingMode ? null :
        (
          <Fragment>
            <h1>401: UNAUTHORIZED</h1>
            <div className={ classes.messageArea }>
              <span className={ classes.dangerText }>Please Note</span>
              You don’t have access to this feature, please get in touch with your administrator.
            </div>
            <div className={ classes.footer }>
              <Button size={ 'sm' } onClick={ home }>Okay</Button>
            </div>
          </Fragment>
        ) }
    </div>
  );
};

export default UnauthorizedPageComponent;
