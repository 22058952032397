import { closeAllModals } from "@mantine/modals";
import { createContext, FC } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import { Dispatch } from "@/core/store";
import { AttributesModalFormData } from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/AttributesModal/AttributesModal.component";

type AttributesModalContextType = {
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>,
  formControls: UseFormReturn
};

export const AttributesModalContext = createContext<AttributesModalContextType>(
  {} as AttributesModalContextType
);

export const AttributesModalContextProvider: FC = ({
  children
}) => {
  const formControls = useForm({ mode: 'onChange' });

  const {
    profile: {
      updatePersonalAttributes
    }
  } = useDispatch<Dispatch>();

  const handleSubmit = async (untypedData: unknown) => {
    const data = untypedData as AttributesModalFormData;

    const success = await updatePersonalAttributes(data);
    if(success){
      closeAllModals();
    }
  };

  const onSubmit = formControls.handleSubmit(handleSubmit);

  return (
    <AttributesModalContext.Provider value={ useContextValue({
      onSubmit,
      formControls
    }) }>
      { children }
    </AttributesModalContext.Provider>
  );
};