import { ColDef, ICellRendererParams } from "ag-grid-community";
import { ReactNode } from "react";

import { Visitor } from "@/tenant-context/control-visitor-management/types/visitorManagement";

export const columnDefs = (actionCellRenderer: (params: ICellRendererParams) => ReactNode): ColDef[]  => [
  {
    headerName: 'File Name',
    sortable: false,
    field: 'fileName',
    flex: 3,
    comparator: () => 0
  },
  {
    headerName: 'File Size',
    sortable: false,
    field: 'fileSize',
    flex: 1,
    comparator: () => 0
  },
  {
    headerName: 'File Type',
    sortable: false,
    flex: 1,
    field: 'fileType',
    comparator: () => 0
  },
  {
    headerName: '',
    colId: 'action',
    type: 'rightAligned',
    flex: 0,
    width: 50,
    cellRenderer: (data: ICellRendererParams<Visitor>) => actionCellRenderer(data)
  }
];
