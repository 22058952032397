import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";

import { Dispatch } from "@/core/store";
import GeofenceListItem
  from "@/tenant-context/control-draw/components/GeofenceList/components/GeofenceListItem/GeofenceListItem.component";
import { GeofenceStatusTypes,ShortGeofence } from "@/tenant-context/control-draw/types/draw";


const GeofenceListItemContainer: FC<ShortGeofence> = ({ tid, name, enablePrivate }) => {

  const {
    geofence: {
      deleteGeofence,
      updateGeofenceStatus,
      visualiseGeofence,
      openEditGeofenceDrawer
    }
  } = useDispatch<Dispatch>();

  const onGeofenceListItemClickHandler = useCallback(() => {
    visualiseGeofence(tid);
  }, [tid, visualiseGeofence]);

  const onArchiveIconClickHandler = useCallback(() => updateGeofenceStatus({
    tid: tid,
    status: GeofenceStatusTypes.DISABLED
  }), [tid, updateGeofenceStatus]);

  const onDeleteClickHandler = useCallback(() => deleteGeofence(tid), [tid, deleteGeofence]);

  const onEditIconClickHandler = useCallback(() => {
    openEditGeofenceDrawer(tid);
  }, [openEditGeofenceDrawer, tid]);

  return (
    <div>
      <GeofenceListItem
        isEnablePrivate={ enablePrivate }
        name={ name }
        onGeofenceListItemClickHandler={ onGeofenceListItemClickHandler }
        onEditIconClickHandler={ onEditIconClickHandler }
        onDeleteClickHandler={ onDeleteClickHandler }
        onArchiveIconClickHandler={ onArchiveIconClickHandler }
      />
    </div>
  );
};

export default GeofenceListItemContainer;
