import { FC, useCallback, useEffect, useState } from "react";

import OnMapNotificationBoxComponent
  from '@/tenant-context/common/components/OnMapNotificationBox/OnMapNotificationBox.component';


type Props = {
  show?: boolean;
  notification: string;
  onClose?: () => void;
}

const OnMapNotificationBox: FC<Props> = ({
  show = true,
  notification,
  onClose
}) => {
  const [ localNotification, setLocalNotification ] = useState<string | undefined>(notification);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }

    setLocalNotification(undefined);
  }, [onClose]);

  useEffect(() => {
    setLocalNotification(notification);
  }, [notification]);

  if (!show) {
    return null;
  }

  return (
    <OnMapNotificationBoxComponent
      localNotification={ localNotification }
      onClose={ handleClose }
    />
  );
};

export default OnMapNotificationBox;
