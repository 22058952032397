import { hasFlag } from "country-flag-icons";
import Flags from 'country-flag-icons/react/3x2';
import { FC, useMemo } from "react";

import { useCountryFlagStyles } from "@/tenant-context/common/components/CountryFlag/CountryFlag.styles";

import countriesMapping from './countries-mapping.json';
import countryCodeByName from './country-name-to-code.json';

type Props = {
  className?: string,
  countryCode: string,
  width?: number,
  height?: number
}


const DEFAULT_FLAG_SIZE = 16;
const ISO_ALPHA_2_CONTRY_CODE_LENGTH = 2;
const ISO_ALPHA_3_CONTRY_CODE_LENGTH = 3;

const CountryFlag: FC<Props> = ({
  className,
  countryCode,
  width = DEFAULT_FLAG_SIZE,
  height = DEFAULT_FLAG_SIZE
}) => {

  const { classes } = useCountryFlagStyles({
    width,
    height
  });


  const isoAlpha2CountryCode = useMemo(() => {
    if (countryCode.length === ISO_ALPHA_2_CONTRY_CODE_LENGTH) {
      return countryCode;
    }

    if (countryCode.length === ISO_ALPHA_3_CONTRY_CODE_LENGTH) {
      return (countriesMapping as { [key: string]: string })[countryCode];
    }

    // Search for country name in countryCodeByName and get the code
    const country = countryCodeByName.find((countryItem: {name: string, code: string}) =>
      countryItem.name.toUpperCase() === countryCode.toUpperCase());

    return country?.code || null;
  }, [countryCode]);

  if (!isoAlpha2CountryCode) {
    return null;
  }

  if (!hasFlag(isoAlpha2CountryCode)) {
    return <label className={ classes.imgPlaceholder }></label>;
  }

  const Flag = Flags[isoAlpha2CountryCode as keyof typeof Flags];

  return (
    <Flag
      className={ className || classes.flag }
      title={ Flag.name }
      style={ {
        display: "inline-block",
        width: `${width}px`,
        height: `${height}px`
      } }
    />
  );
};

export default CountryFlag;
