import { Button, MultiSelect, Popover } from "@mantine/core";
import { SelectItem } from '@mantine/core/lib/Select/types';
import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";

import ColorSwatchesPalette from '@/common/components/ColorSwatchesPalette';
import TagsContainer from '@/common/components/TagsContainer';
import TagChip from '@/common/components/TagsContainer/ui/TagChip';
import { ReactComponent as CirclePlusIcon } from '@/common/icons/circle-plus.svg';
import { DEFAULT_INIT_COLOR, TAG_COLORS } from '@/tenant-context/control-profile/components/ui/AddTagsPopover/AddTagsPopover.config';
import { ProfileTag } from '@/tenant-context/control-profile/types/profile';

import { useAddTagsPopoverStyles } from "./AddTagsPopover.styles";

type Props = {
  isOpened: boolean
  isColorPalateOpened: boolean
  selectedTags: ProfileTag[]
  profileTagsList: ProfileTag[]
  multiselectTagsStringValueState: [string[], (value: string[]) => void],
  handleTrigger: () => void
  onColorSelect: (color: string) => void
  onCloseColorPalate: () => void
  add: () => void
  handleCancel: () => void
  handleDone: () => void
  onTagSelect: (index: number) => void
  setTagsMultiselectValue: (tags: ProfileTag[]) => void
  mode?: 'ADD' | 'CREATE' | 'EDIT'
  initTagColor?: string
  onSearchChange: (phrase: string) => void
  onEnter: ($event: React.KeyboardEvent) => void
  onCreate: (query: string) => SelectItem
  profileTagsListForProfile: ProfileTag[]
};

const AddTagsPopoverComponent: FC<Props> = ({
  isOpened,
  isColorPalateOpened,
  selectedTags,
  profileTagsList,
  multiselectTagsStringValueState,
  handleTrigger,
  onColorSelect,
  onCloseColorPalate,
  add,
  handleCancel,
  handleDone,
  onTagSelect,
  setTagsMultiselectValue,
  mode,
  initTagColor,
  onSearchChange,
  onCreate,
  profileTagsListForProfile
}) => {
  const multiselectRef = useRef<HTMLInputElement>(null);

  const { classes } = useAddTagsPopoverStyles({ initColor: initTagColor || DEFAULT_INIT_COLOR });
  const [multiselectTagsStringValue, setMultiselectTagsStringValue] = multiselectTagsStringValueState;

  const valueComponent = useCallback(({ label, value, onRemove }) => {
    const tag: ProfileTag | undefined = profileTagsList.find((tag_) => tag_.id === value);

    if (!tag) {
      return null;
    }

    return (
      <TagChip
        color={ tag.color }
        index={ 0 }
        label={ label }
        onRemove={ onRemove }
      />
    );
  }, [profileTagsList]);


  const handleChange = useCallback((value: string[]) => {
    const filteredValue = value.filter((item) => item);
    setMultiselectTagsStringValue(filteredValue);
    const tagObjectValues = profileTagsList.filter((tag) => {
      return value.includes(tag.id as string);
    });

    setTagsMultiselectValue(tagObjectValues);
  },[profileTagsList, setMultiselectTagsStringValue, setTagsMultiselectValue]);

  const createLabel = useCallback((query: string) => {
    return query;
  },[]);

  const multiselectTagList = useMemo(() => {
    const filteredProfileTagsList = profileTagsList
      .filter((tag) => !selectedTags.some(selectedTag => selectedTag.id === tag.id))
      .filter((tag) => !profileTagsListForProfile.some(existingTag => existingTag.name === tag.name));

    return filteredProfileTagsList.map((tag: ProfileTag) => ({
      value: tag.id || '',
      label: tag.name
    }));
  }, [profileTagsList, profileTagsListForProfile, selectedTags]);

  useEffect(() => {
    if (!multiselectRef.current) {
      return;
    }
    multiselectRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest'
    });
  }, [multiselectTagsStringValue]);

  return (
    <div className={ classes.addTagsContainer }>
      <Popover position="bottom" opened={ isOpened } width={ 528 } zIndex={ 999 }>
        <Popover.Dropdown className={ classes.popover }>

          <h4 className={ classes.heading }>Tags</h4>

          { mode === 'ADD' && <MultiSelect
            ref={ multiselectRef }
            data={ multiselectTagList }
            placeholder="Tag name"
            searchable
            onSearchChange={ onSearchChange }
            nothingFound="Nothing found"
            className={ classes.select }
            onChange={ handleChange }
            value={ multiselectTagsStringValue }
            valueComponent={ valueComponent }
            clearable
            creatable
            getCreateLabel={ createLabel }
            onCreate={ onCreate }
          /> }

          { mode !== 'EDIT' && <div className={ classes.controllers }>
            <Button size={ 'md' } onMouseDown={ add }>Add</Button>
          </div> }

          { isColorPalateOpened && <ColorSwatchesPalette
            colors={ TAG_COLORS }
            onSelect={ onColorSelect }
            onCloseColorPalate={ onCloseColorPalate }
          /> }

          <div className={ classes.label }>
            <label>Added tags</label>
            <TagsContainer onSelectTag={ onTagSelect }/>
          </div>

          <div className={ classes.controllers }>
            <Button size={ 'md' } onClick={ handleDone }>Done</Button>
            <Button size={ 'md' } variant={ 'outline' } onClick={ handleCancel }>Cancel</Button>
          </div>

        </Popover.Dropdown>
      </Popover>
      <Button
        leftIcon={ <CirclePlusIcon/> }
        variant={ 'subtle' }
        size={ 'lg' }
        onClick={ handleTrigger }
        className={ classes.triggerButton }
      >
        Add Tags
      </Button>
    </div>
  );
};

export default AddTagsPopoverComponent;
