import { PaginatedResult } from "@/common/types/reference-data";
import { callApi, TokenType } from "@/common/util/api";
import { SortData } from "@/tenant-context/control-groups/types/group";
import {
  CreateNewVisitor,
  CreateNewVisitorCompany, CreateNewVisitorContact, CreateNewVisitorEmergencyContact, CreateNewVisitorGroup,
  CreateNewVisitorProfile,
  ExistingVisitor,
  ExistingVisitorProfile,
  IVisitNote,
  IVisitorDetails,
  IVisitRequest,
  IVisitVehicle,
  NewVisitorGroupList,
  UserVisitsInfo,
  VisitorDetailsParams,
  VisitorSite,
  VisitRequestParams
} from "@/tenant-context/control-visitor-management/types/visitorManagement";

import { Visitor, VisitorDetails, VisitorRequest } from "../types/visitorManagement";

export const getVisitorRequestData = (
  page = 0,
  size = 10,
  sort?: SortData,
  searchQuery?: string,
  additionalFilterQuery?: string
) => callApi<PaginatedResult<VisitorRequest>>(
  `/managed/visitor-service/v1/visitor-requests?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}${searchQuery ? '&search=(' + searchQuery + ')&' : ''}${additionalFilterQuery ? additionalFilterQuery + '&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'X-Domain-Model': 'ListVisitorRequest'
    },
    method: 'get',
    tokenType: TokenType.SITE
  }
);
export const getVisitorVisitsData = (
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<VisitorDetails>>(
  `/ADD_URL_HERE?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'X-Domain-Model': 'DOMAIN_MODEL'
    },
    method: 'get'
  }
);
export const getVisitorsData = (
  page = 0,
  size = 10,
  sort?: SortData,
  searchQuery?: string,
  additionalFilterQuery?: string
) => callApi<PaginatedResult<Visitor>>(
  `/managed/visitor-service/v1/visitor-requests?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}${searchQuery ? '&search=(' + searchQuery + ')&' : ''}${additionalFilterQuery ? additionalFilterQuery + '&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'X-Domain-Model': 'TenantListVisitor'
    },
    method: 'get',
    tokenType: TokenType.SITE
  }
);

export const getSites = (
  userId: string
) => callApi<PaginatedResult<VisitorSite>>(
  `managed/platform-service/v1/users/${userId}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=ListUserSiteAndPolicies'
    },
    method: 'get'
  }
);

export const saveVisitRequest = (params: VisitRequestParams) => callApi<IVisitRequest>(
  `/managed/visitor-service/v1/visitor-requests`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreateVisitorRequest'
    },
    method: 'post',
    body: JSON.stringify(params),
    tokenType: TokenType.SITE
  }
);

export const getVisitRequest = (id: string) => callApi<IVisitRequest>(
  `/managed/visitor-service/v1/visitor-requests/${id}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=GetVisitorRequest'
    },
    method: 'get',
    tokenType: TokenType.SITE
  }
);

export const updateVisitRequest = (params: VisitRequestParams) => callApi<IVisitRequest>(
  `/managed/visitor-service/v1/visitor-requests/${params._id}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=UpdateVisitorRequest'
    },
    method: 'post',
    body: JSON.stringify(params),
    tokenType: TokenType.SITE
  }
);

export const getVisitNotesData = (visitRequestId: string) => callApi<PaginatedResult<IVisitNote>>(
  `/managed/visitor-service/v1/visitor-requests/${visitRequestId}/visitor-notes`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=ListVisitorNote'
    },
    method: 'get',
    tokenType: TokenType.SITE
  }

);

export const saveVisitNote = (params: {visitRequestId: string,  note: string}) => callApi<IVisitNote>(
  `/managed/visitor-service/v1/visitor-requests/${params.visitRequestId}/visitor-notes`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreateVisitorNote'
    },
    method: 'post',
    body: JSON.stringify(params),
    tokenType: TokenType.SITE
  }
);
export const updateVisitNote = (params: {visitRequestId: string, visitNoteId: string,  note: string, 
 }) => callApi<IVisitNote>(
   `/managed/visitor-service/v1/visitor-requests/${params.visitRequestId}/visitor-notes/${params.visitNoteId}`,
   {
     headers: {
       'Content-Type': 'application/json;x-domain-model=UpdateVisitorNote'
     },
     method: 'post',
     body: JSON.stringify({ note: params.note }),
     tokenType: TokenType.SITE
   }
 );

export const deleteVisitNote = (visitRequestId: string, visitNoteId: string)=> callApi(
  `/managed/visitor-service/v1/visitor-requests/${visitRequestId}/visitor-notes/${visitNoteId}`,
  {
    headers: {
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'DeleteVisitorNote'
    },
    method: 'delete',
    tokenType: TokenType.SITE
  }
);

export const getVisitVehiclesData = (
  visitRequestId: string,
  page = 0,
  size = 10,
  sort?: SortData
) => callApi<PaginatedResult<IVisitVehicle>>(
  `/managed/visitor-service/v1/visitor-requests/${visitRequestId}/visitor-vehicles?${sort?.colId ? 'sort=' + sort.colId + ',' + sort.sort.toUpperCase() + '&' : ''}size=${size}&page=${page}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=ListVisitorVehicle'
    },
    method: 'get',
    tokenType: TokenType.SITE
  }
);

export const getListOfVehiclesData = (searchQuery?: string) => callApi<PaginatedResult<IVisitVehicle>>(
  // eslint-disable-next-line no-useless-concat
  `/managed/visitor-service/v1/visitor-requests?${searchQuery ? `search=` + "(" + searchQuery + ")" : ''}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=ListVehicle'
    },
    method: 'get',
    tokenType: TokenType.SITE
  }
);

export const saveVisitVehicle = (params: IVisitVehicle) => callApi<IVisitVehicle>(
  `/managed/visitor-service/v1/visitor-requests/${params.visitRequestId}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreateVisitorVehicle'
    },
    method: 'post',
    body: JSON.stringify(params),
    showApiError: true,
    tokenType: TokenType.SITE
  }
);
export const updateVisitVehicle = (params: IVisitVehicle) => callApi<IVisitVehicle>(
  `/managed/visitor-service/v1/visitor-requests/${params.visitRequestId}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=UpdateVisitorVehicle'
    },
    method: 'post',
    body: JSON.stringify(params),
    showApiError: true,
    tokenType: TokenType.SITE
  }
);

export const deleteVisitVehicle = (visitRequestId: string, visitVehicleId: string) => callApi(
  `/managed/visitor-service/v1/visitor-requests/${visitRequestId}/visitor-vehicles/${visitVehicleId}`,
  {
    headers: {
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'DeleteVisitorVehicle'
    },
    method: 'delete',
    tokenType: TokenType.SITE
  }
);

export const getListOfExistingProfiles = (
  visitorRequestTid = '',
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<ExistingVisitorProfile>>(
  `managed/visitor-service/v1/visitor-requests?${visitorRequestTid ? 'visitorRequestTid=' + visitorRequestTid + '&' : '' }${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'Content-Type': 'application/json',
      'X-Domain-Model': 'ListExistingProfile'
    },
    method: 'get',
    tokenType: TokenType.SITE
  }
);

export const postAddVisitorRequest = (id: string, visitor: ExistingVisitor) => callApi<CreateNewVisitor>(
  `managed/visitor-service/v1/visitor-requests/${id}`,
  {
    headers: {
      'Content-Type': 'application/json',
      'X-Domain-Model': 'CreateVisitor'
    },
    method: 'post',
    body: JSON.stringify(visitor),
    tokenType: TokenType.SITE
  }
);

export const getListOfVisitors = (
  id = '',
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) => callApi<PaginatedResult<ExistingVisitorProfile>>(
  `managed/visitor-service/v1/visitor-requests/${id}/visitors?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}${query ? '&search=(' + query + ')&' : ''}&size=${size}&page=${page}`,
  {
    headers: {
      'Content-Type': 'application/json',
      'X-Domain-Model': 'ListVisitor'
    },
    method: 'get',
    tokenType: TokenType.SITE
  }
);

export const deleteVisitor = (visitRequestId: string, visitorData: Record<string, string>) => callApi(
  `/managed/visitor-service/v1/visitor-requests/${visitRequestId}`,
  {
    headers: {
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'DeleteVisitor'
    },
    method: 'post',
    body: JSON.stringify(visitorData),
    tokenType: TokenType.SITE
  }
);

export const updateVisitorDetails = (params: VisitorDetailsParams) => callApi<IVisitorDetails>(
  `/managed/visitor-service/v1/visitor-requests/${params.visitRequestId}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=UpdateVisitorDetail'
    },
    method: 'post',
    body: JSON.stringify(params),
    tokenType: TokenType.SITE
  }
);

export const getVisitorDetails = (visitRequestId: string, visitorId: string) => callApi<IVisitorDetails>(
  `/managed/visitor-service/v1/visitor-requests/${visitRequestId}?visitorTid=${visitorId}`,
  {
    headers: {
      'Content-Type': 'application/json',
      'X-Domain-Model': 'GetVisitor'
    },
    method: 'get',
    tokenType: TokenType.SITE
  }
);

export const postCreateNewVisitorProfile = (
  visitRequestId: string,
  data: CreateNewVisitorProfile
) => callApi<CreateNewVisitorProfile>(
  `/managed/visitor-service/v1/visitor-requests/${visitRequestId}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreateNewProfile'
    },
    method: 'post',
    body: JSON.stringify(data),
    tokenType: TokenType.SITE
  }
);

export const postCreateNewVisitorCompany = (
  visitRequestId: string,
  data: CreateNewVisitorCompany
) => callApi<CreateNewVisitorCompany>(
  `/managed/visitor-service/v1/visitor-requests/${visitRequestId}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreateCompanyDetails'
    },
    method: 'post',
    body: JSON.stringify(data),
    tokenType: TokenType.SITE
  }
);

export const postCreateNewVisitorContact = (
  visitRequestId: string,
  data: CreateNewVisitorContact
) => callApi<CreateNewVisitorContact>(
  `/managed/visitor-service/v1/visitor-requests/${visitRequestId}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreateBulkContactDetails'
    },
    method: 'post',
    body: JSON.stringify(data),
    tokenType: TokenType.SITE
  }
);

export const postCreateNewVisitorEmergencyContact = (
  visitRequestId: string,
  data: CreateNewVisitorEmergencyContact
) => callApi<CreateNewVisitorEmergencyContact>(
  `/managed/visitor-service/v1/visitor-requests/${visitRequestId}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreateEmergencyContactDetails'
    },
    method: 'post',
    body: JSON.stringify(data),
    tokenType: TokenType.SITE
  }
);

export const postCreateOrDeleteNewVisitorGroup = (
  visitRequestId: string,
  data: CreateNewVisitorGroup
) => callApi<CreateNewVisitorGroup>(
  `/managed/visitor-service/v1/visitor-requests/${visitRequestId}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=CreatePeopleGroup'
    },
    method: 'post',
    body: JSON.stringify(data),
    tokenType: TokenType.SITE
  }
);

export const getNewVisitorGroupList = (
  profileId: string
) => callApi<PaginatedResult<NewVisitorGroupList>>(
  `managed/platform-service/v1/person-profiles/${profileId}`,
  {
    headers: {
      'Content-Type': 'application/json;x-domain-model=ListPersonProfilePeopleGroup'
    },
    method: 'get'
  }
);

export const deleteVisitorRequest = (requestId: string)=> callApi(
  `/managed/visitor-service/v1/visitor-requests/${requestId}`,
  {
    headers: {
      'Content-Type': 'application/json;',
      'X-Domain-Model': 'DeleteVisitorRequest'
    },
    method: 'delete',
    tokenType: TokenType.SITE
  }
);

export const changeVisitRequestStatus = (requestId: string, domainModel: string)=> callApi<string>(
  `/managed/visitor-service/v1/visitor-requests/${requestId}`,
  {
    headers: {
      'Content-Type': 'application/json;',
      'X-Domain-Model': domainModel
    },
    method: 'post',
    body: JSON.stringify({}),
    tokenType: TokenType.SITE
  }
);

export const changeVisitStatus = (requestId: string, visitId: string, domainModel: string)=> callApi<string>(
  `/managed/visitor-service/v1/visitor-requests/${requestId}`,
  {
    headers: {
      'Content-Type': 'application/json;',
      'X-Domain-Model': domainModel
    },
    method: 'post',
    body: JSON.stringify({
      tid: visitId
    }),
    tokenType: TokenType.SITE
  }
);

export const fetchVisitsForUser = (userId: string)=> callApi<UserVisitsInfo>(
  `/managed/visitor-service/v1/visitor-requests?profileTid=${userId}`,
  {
    headers: {
      'Content-Type': 'application/json;',
      'X-Domain-Model': "ListProfileVisitorRequest"
    },
    method: 'get',
    tokenType: TokenType.SITE
  }
);
