import { useId } from "@mantine/hooks";
import React, { FC } from "react";

import { TestableComponent } from "@/common/types/testable-component";

import { useMapStyleToggleStyles } from "./MapStyleToggle.styles";

type MapStyleToggleProps = {
  label: string;
  value: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  imgUrl?: string;
} & TestableComponent;

const MapStyleToggle: FC<MapStyleToggleProps> = ({
  label,
  value,
  onChange,
  name,
  imgUrl,
  defaultChecked,
  checked
}) => {
  const id = useId();
  const { classes } = useMapStyleToggleStyles({ imgUrl });

  return (
    <label className={ classes.label } htmlFor={ id }>
      <input
        className={ classes.hiddenInput }
        type="radio"
        id={ id }
        name={ name }
        value={ value }
        onChange={ onChange }
        checked={ checked }
        defaultChecked={ defaultChecked }
      />
      <span className={ classes.mapPreview }></span>

      { label }
    </label>
  );
};

export default MapStyleToggle;
