export type MiniProfileDatails = {
  profileId: string,
  profilePictureThumbnailUrl: string,
  title: string,
  firstName: string,
  lastName: string,
  nationality: string,
  countryOfResidenceRef: string,
  jobTitle: string,
  company: string,
  contacts: ContactItem[]
  lastUpdatedOn?: number
  batteryLevel?: string
  connectivity?: string
  locationFrequency?: string
  operatingSystem?: string
  bluetoothOn?: boolean,
  deviceBeingUsed?: string
  version?: string
  signalStrength?: string
  personType?: string
}

export type ContactItem = {
  type: string,
  value: string
}

export type TravelMethods = 'HOTEL' | 'AIR' | 'CAR' | 'FLIGHT' | 'ACCOMMODATION' | 'JOURNEY' | 'TAXI'

export enum DeviceOsType {
  IOS = 'ios',
  ANDROID = 'android',
}

export type ReverseGeoData = {
  lon: number,
  lat: number,
  place_name: string
}

export type ReverseGeoLocation = {
  place_name: string
}
