import { FC } from "react";

import CountryFlag from "@/tenant-context/common/components/CountryFlag/CountryFlag.component";

type Props = {
  phone: string,
  regionCode?: string
}

const PhoneWithFlag: FC<Props> = ({
  phone,
  regionCode
}) => {
  return (
    <span>
      { regionCode && (
        <CountryFlag
          countryCode={ regionCode }
        />
      ) }
      { phone }
    </span>
  );
};

export default PhoneWithFlag;