import { Feature, FeatureCollection, Point } from "geojson";

import { Dispatch, RootState } from "@/core/store";
import { getGeometryOfLocation, groupBySmallestLocation } from "@/tenant-context/plugin-indoors/helpers/indoor-map.helpers";
import {
  IndoorPersonAssetRankingSummary,
  IndoorPersonLocationProperties
} from "@/tenant-context/plugin-indoors/types/indoor-map.types";

export const indoorEffects = (dispatch: Dispatch) => ({
  async fetchIndoorPeopleFromGlobalPeople(_: void, state: RootState): Promise<void> {

    const { currentSiteId } = state.indoor;

    if (!currentSiteId) {
      return;
    }

    // Getting all the people in the map
    const globalPeople = state.peopleLocations?.geoData;

    if (!globalPeople) {
      return;
    }

    // Filter out people only in the current site
    const indoorPeopleFeatures = globalPeople.features
      .filter(feature => {
        const { properties: { topRank: { location: { labels } } } } = feature;
        if (!Array.isArray(labels)) {
          return false;
        }

        const site = labels
          .find((label) => label.subCategory === "SITE" && label.id === currentSiteId);

        return !!site;
      });

    if (indoorPeopleFeatures.length === 0) {
      return;
    }

    // Setting the indoor location traits to the indoor people
    indoorPeopleFeatures.forEach((indoorPerson) => {
      const { labels } = indoorPerson.properties.topRank.location;
      // smallestLocation denotes the smalled label/location of a given person/asset is in currently (Floor, zone, room, etc)
      const smallestLocation = labels[0]; // Label array is sorted by smallest location to largest

      const site = labels.find((label) => label.subCategory === 'SITE');
      const zone = labels.find((label) => label.subCategory === 'ZONE');
      const floor = labels.find((label) => label.subCategory === 'FLOOR');

      const indoorAssetRanking = indoorPerson.properties as IndoorPersonAssetRankingSummary;
      indoorAssetRanking.site = site;
      indoorAssetRanking.zone = zone;
      indoorAssetRanking.floor = floor || { id: '0', name: 'Floor 0', subCategory: 'FLOOR' };
      indoorAssetRanking.smallestLocation = smallestLocation;

      // Find the smallest location label's geo point
      const smalledLocationGeoPoint = getGeometryOfLocation(smallestLocation.name);

      // Set calculated geolocations as smallest label's geo point if there's any, or else use the person's current
      // geo point.
      indoorAssetRanking.calculatedGeoLocation = smalledLocationGeoPoint || {
        lon: indoorPerson.geometry.coordinates[0],
        lat: indoorPerson.geometry.coordinates[1]
      };
    });

    dispatch.indoor.SET_INDOOR_PEOPLE({
      type: "FeatureCollection",
      features: indoorPeopleFeatures
    } as FeatureCollection<Point, IndoorPersonAssetRankingSummary>);

    dispatch.indoor.setIndoorPeopleCalculatedLocations();
  },
  async setIndoorPeopleCalculatedLocations(_: void, state: RootState): Promise<void> {

    const { indoorPeople } = state.indoor;

    if (!indoorPeople) {
      return;
    }

    // Group people by smallest location label
    const grouped: Record<string, Feature<Point, IndoorPersonAssetRankingSummary>[]> = groupBySmallestLocation(
      indoorPeople.features
    );

    const features = Object.entries(grouped).map(([_key, groupedPeople]) => {
      // This map function creates a IndoorPersonLocation feature property which includes whether this feature is
      // includes multiple people (isClustered), the floor it belongs to, calculated geolocation (geoLocation of the
      // smallest location label), and the set of people in that cluster/location.

      // Getting a count of people in the location label
      const peopleCount = groupedPeople.length;
      // Getting the floor of the location label. Floor id can be like "0", "1" or even "floor-01". We need to use
      // parseInt to remove any leading 0s from the floor id.
      const floor = parseInt(groupedPeople[0].properties.floor?.id.split('-').reverse()[0] || '0').toString();
      const featureProperties: IndoorPersonLocationProperties = {
        isClustered: groupedPeople.length > 1,
        floor: floor,
        calculatedGeoLocation: groupedPeople[0].properties.calculatedGeoLocation as { lat: number, lon: number },
        // People property will have an array if multiple people are there in the given location label. If not it's just
        // a single value. We add a new property `location` for the calculations in the later stage easier
        people: peopleCount === 1
          ? ({
            location: groupedPeople[0].properties.topRank.location,
            ...groupedPeople[0].properties
          })
          : groupedPeople.map((feature) => ({
            location: feature.properties.topRank.location,
            ...feature.properties
          }))
      };

      return featureProperties;
    }).map((featureProperties) => {
      // Converting the mapped indoor person records to geojson features
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          // Since the geo point is already calculated, we just use it here
          coordinates: [featureProperties.calculatedGeoLocation.lon, featureProperties.calculatedGeoLocation.lat]
        },
        properties: featureProperties
      } as Feature<Point, IndoorPersonLocationProperties>;
    });

    const featureCollection: FeatureCollection<Point, IndoorPersonLocationProperties> = {
      type: "FeatureCollection",
      features
    };

    dispatch.indoor.SET_INDOOR_PEOPLE_LOCATIONS(featureCollection);
  }
});
