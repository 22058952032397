import { createModel } from "@rematch/core";

import browserDatabase from "@/common/util/browser-database";
import { Dispatch, RootModel, RootState } from "@/core/store";

import { CONTEXT_KEY, ContextTypes } from "./sharedData.types";

type SharedData = {
    currentContext: ContextTypes
};

const sharedDataModel = {
  name: 'sharedData',
  state: {
    currentContext: browserDatabase.getItem(CONTEXT_KEY) || "tenant"
  } as SharedData,
  reducers: {
    SET_CONTEXT: (state: SharedData, context: ContextTypes) => ({
      ...state,
      currentContext: context
    })
  },
  effects: (dispatch: Dispatch) => ({
    setContext(context: ContextTypes, _state: RootState): void {
      browserDatabase.setItem(CONTEXT_KEY, context);
      dispatch.sharedData.SET_CONTEXT(context);
    }
  })
};

export const sharedData = createModel<RootModel>()(sharedDataModel);
