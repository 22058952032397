import { ReactComponent as Conflict } from "@/common/icons/conflict.svg";
import { ReactComponent as Crime } from "@/common/icons/crime.svg";
import { ReactComponent as Demonstrations } from "@/common/icons/demonstrations.svg";
import { ReactComponent as Health } from "@/common/icons/health.svg";
import { ReactComponent as Natural } from "@/common/icons/natural.svg";
import { ReactComponent as Political } from "@/common/icons/political.svg";
import { ReactComponent as ActiveShooterMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/active_shooter.svg';
import { ReactComponent as AirStrikeMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/air_strike.svg';
import { ReactComponent as ArbitraryDetentionMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/arbitrary_detention.svg';
import { ReactComponent as ArmedConflictMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/armed_conflict.svg';
import { ReactComponent as CivilUnrestMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/civil_unrest.svg';
import { ReactComponent as CrimeMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/crime.svg';
import { ReactComponent as CyberMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/cyber.svg';
import { ReactComponent as DevelopingMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/developing.svg';
import { ReactComponent as EarthquakeMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/earthquake.svg';
import { ReactComponent as EconomicsMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/economics.svg';
import { ReactComponent as FireMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/fire.svg';
import { ReactComponent as HealthMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/health.svg';
import { ReactComponent as HolidayMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/holiday.svg';
import { ReactComponent as KidnapAndRansomMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/kidnap_and_ransom.svg';
import { ReactComponent as LaborActionMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/labor_action.svg';
import { ReactComponent as LargeScaleEventsMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/large_scale_events.svg';
import { ReactComponent as MaritimeIncidentsMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/maritime_incidents.svg';
import { ReactComponent as MilitancyTerrorismMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/militancy_terrorism.svg';
import { ReactComponent as OrganisedCrimeMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/organised_crime.svg';
import { ReactComponent as PiracyMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/piracy.svg';
import { ReactComponent as PoliticsMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/politics.svg';
import { ReactComponent as ProtestMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/protest.svg';
import { ReactComponent as RocketMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/rocket.svg';
import { ReactComponent as SecurityOperationsMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/security_operations.svg';
import { ReactComponent as SupplyChainDisruptionsMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/supply_chain_disruptions.svg';
import { ReactComponent as TravelMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/travel.svg';
import { ReactComponent as VolcanoMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/volcano.svg';
import { ReactComponent as WeatherMaxSec } from  '@/common/icons/RiskProviders/MaxSecurityCategories/weather.svg';
import { ReactComponent as Travel } from "@/common/icons/travel.svg";

export const riskCategories = [
  {
    Icon: <Political />,
    categoryId:'1'
  },
  {
    Icon: <Conflict />,
    categoryId:'2'
  },
  {
    Icon: <Demonstrations />,
    categoryId:'3'
  },
  {
    Icon: <Crime />,
    categoryId:'4'
  },
  {
    Icon: <Natural />,
    categoryId:'5'
  },
  {
    Icon: <Health />,
    categoryId:'6'
  },
  {
    Icon: <Travel />,
    categoryId:'7'
  }
];

export const MaxSecurityRiskCategories = [
  {
    Icon: <ActiveShooterMaxSec/>,
    categoryId: 'ActiveShooter'
  },
  {
    Icon: <AirStrikeMaxSec/>,
    categoryId: 'AirStrike'
  },
  {
    Icon: <ArbitraryDetentionMaxSec/>,
    categoryId: 'ArbitraryDetention'
  },
  {
    Icon: <ArmedConflictMaxSec/>,
    categoryId: 'ArmedConflict'
  },
  {
    Icon: <CivilUnrestMaxSec/>,
    categoryId: 'CivilUnrest'
  },
  {
    Icon: <CrimeMaxSec/>,
    categoryId: 'Crime'
  },
  {
    Icon: <CyberMaxSec/>,
    categoryId: 'Cyber'
  },
  {
    Icon: <DevelopingMaxSec/>,
    categoryId: 'Developing'
  },
  {
    Icon: <EarthquakeMaxSec/>,
    categoryId: 'Earthquake'
  },
  {
    Icon: <EconomicsMaxSec/>,
    categoryId: 'Economics'
  },
  {
    Icon: <FireMaxSec/>,
    categoryId: 'Fire'
  },
  {
    Icon: <HealthMaxSec/>,
    categoryId: 'Health'
  },
  {
    Icon: <HolidayMaxSec/>,
    categoryId: 'Holiday'
  },
  {
    Icon: <KidnapAndRansomMaxSec/>,
    categoryId: 'KidnapAndRansom'
  },
  {
    Icon: <LaborActionMaxSec/>,
    categoryId: 'LaborAction'
  },
  {
    Icon: <LargeScaleEventsMaxSec/>,
    categoryId: 'LargeScaleEvents'
  },
  {
    Icon: <MaritimeIncidentsMaxSec/>,
    categoryId: 'MaritimeIncidents'
  },
  {
    Icon: <MilitancyTerrorismMaxSec/>,
    categoryId: 'MilitancyTerrorism'
  },
  {
    Icon: <OrganisedCrimeMaxSec/>,
    categoryId: 'OrganisedCrime'
  },
  {
    Icon: <PiracyMaxSec/>,
    categoryId: 'Piracy'
  },
  {
    Icon: <PoliticsMaxSec/>,
    categoryId: 'Politics'
  },
  {
    Icon: <ProtestMaxSec/>,
    categoryId: 'Protest'
  },
  {
    Icon: <RocketMaxSec/>,
    categoryId: 'Rocket'
  },
  {
    Icon: <SecurityOperationsMaxSec/>,
    categoryId: 'SecurityOperations'
  },
  {
    Icon: <SupplyChainDisruptionsMaxSec/>,
    categoryId: 'SupplyChainDisruptions'
  },
  {
    Icon: <TravelMaxSec/>,
    categoryId: 'Travel'
  },
  {
    Icon: <VolcanoMaxSec/>,
    categoryId: 'Volcano'
  },
  {
    Icon: <WeatherMaxSec/>,
    categoryId: 'Weather'
  }
];
