import { createStyles } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useEaArcStyles = createStyles(() => ({
  root: {
    backgroundColor: genericColorPalette.neutral[9],
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex'
  },

  panelRoot: {
    marginTop: 32,
    padding: '0 8px',
    flexGrow: 1,
    maxWidth: '100%'
  },

  panel: {
    maxWidth: '100%',
    height: 'calc(100% - var(--tabs-absolute-height))'
  }
}));
