import { createStyles, MantineTheme } from "@mantine/core";

type Args = {
  isInMiniProfile: boolean
}
export const useMiniProfileControlStyles = createStyles((_theme: MantineTheme, { isInMiniProfile }: Args ) => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    width: '100%',
    position: 'relative',
    height: isInMiniProfile ? '100%' : 'auto',

    '& *': {
      boxSizing: 'border-box'
    }
  }
}));
