import { callApi } from '@/common/util/api';
import {
  LookupMetaData,
  LookupSearchRequestEntity,
  SearchResultResponse
} from '@/tenant-context/control-lookup-tool/types/Lookup.types';
import { ReverseGeoLocationPlaceNameResponse } from '@/tenant-context/core/types/reverse-geocoding';

export const lookupSearch = (
  requestParams: LookupSearchRequestEntity,
  pageNum = 0,
  pageSize = 10,
  orderBy: string | null = null
) => callApi<SearchResultResponse>(
  `lookup-service/v1/look-up?pageNum=${ pageNum }&pageSize=${ pageSize }&orderBy=${ orderBy }`,
  {
    method: 'POST',
    body: JSON.stringify(requestParams)
  }
);

export const getMetaDataForCharts = (
  lookupId: string
) => callApi<LookupMetaData>(
  `lookup-service/v1/look-up/meta-data?lookUpId=${lookupId}`,
  {
    method: 'GET'
  }
);

export const getReverseGeoLocation = (lon: string, lat: string) => callApi<ReverseGeoLocationPlaceNameResponse>(
  `lookup-service/v1/get-reverse-geocoding?latitude=${lat}&longitude=${lon}`,
  {
    method: 'get'
  }
);

export const getLookupSearchCSVData = (lookupId: string) => callApi<string>(
  `lookup-service/v1/look-up/export-to-csv?lookUpId=${lookupId}`,
  {
    method: 'GET',
    responseType: 'blob'
  }
);
