import { Textarea, TextInput } from "@mantine/core";
import { FC } from "react";

import {
  useBuildingsAndFloorsStyles
} from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.styles";
import { Building } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

type Props = {
    activeBuilding?:Building
    isReadOnly?: boolean
}

const BuildingInfo: FC<Props> = ({ activeBuilding, isReadOnly }) => {

  const { classes } = useBuildingsAndFloorsStyles();

  return (
    <div className={ classes.buildingInfoContainer }>
      <div className={ classes.formRow }>
        <TextInput
          value={ activeBuilding?.name || '' }
          className={ classes.formItem }
          label={ 'Name' }
          readOnly={ isReadOnly }
          disabled={ isReadOnly }
        />
        <TextInput
          value={ activeBuilding?.code || '' }
          className={ classes.formItem }
          label={ 'Code' }
          readOnly={ isReadOnly }
          disabled={ isReadOnly }
        />
      </div>
      <div className={ classes.formRow }>
        <Textarea
          value={ activeBuilding?.note || '' }
          className={ classes.formItem }
          label={ 'Notes' }
          minRows={ 4 }
          readOnly={ isReadOnly }
          disabled={ isReadOnly }
        />
      </div>
    </div>
  );
};

export default BuildingInfo;
