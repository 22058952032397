import { RegisterOptions } from "react-hook-form";

import { formErrorConfig } from "@/common/components/FormError/FormError.config";

export function addErrorMessages(fieldDisplayName: string, options: RegisterOptions): RegisterOptions {
  const entriesWithMessages = Object.entries(options).map(([key, value]) => {
    const messageGenerator = formErrorConfig[key];

    if (typeof value === 'object' || !messageGenerator) {
      return [key, value];
    }

    return [key, {
      value,
      message: messageGenerator(fieldDisplayName, value)
    }];
  });

  return Object.fromEntries(entriesWithMessages);
}
