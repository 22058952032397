import { FC } from "react";

import BasemapLayerToggleControl
  from "@/tenant-context/core/controls/BasemapLayerToggleControl/BasemapLayerToggleControl.component";

const BasemapLayerToggleControlContainer: FC = () => {

  return (
    <BasemapLayerToggleControl />
  );
};

export default BasemapLayerToggleControlContainer;

