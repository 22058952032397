import { Pagination, Select } from "@mantine/core";
import { FC, useCallback } from "react";

import { usePaginationBlockStyles } from "@/common/components/PaginationBlock/PaginationBlock.style";

type Props = {
  showingFrom: number
  showingTo: number
  totalRecords: number
  activePage: number
  setActivePage: (value: number) => void
  totalPages: number
  boundaries?: number
  itemsPerPage: string
  setItemsPerPage: (value: string) => void
  isNarrowTable?: boolean
  isResponsive?: boolean
  className?: string,
}

const PaginationBlock:FC<Props> = ({
  showingFrom,
  showingTo,
  totalRecords,
  activePage,
  setActivePage,
  totalPages,
  boundaries,
  itemsPerPage,
  setItemsPerPage,
  isNarrowTable = false,
  className,
  isResponsive = false
}) => {


  const onChangeHandler = useCallback((page: number) => {
    setActivePage(page - 1);
  }, [setActivePage]);


  const { classes, cx } = usePaginationBlockStyles({
    isNarrowTable: isNarrowTable,
    isResponsive: isResponsive
  });

  return (
    <div className={ cx(classes.container, className) }>
      <Pagination
        size={ 'md' }
        page={ activePage + 1 }
        boundaries={ boundaries }
        onChange={ onChangeHandler }
        total={ totalPages }
      />
      <div className={ classes.countContainer }>
        <span className={ classes.perPageLabel }>Records per page</span>
        <Select onChange={ setItemsPerPage } size='md'
          className={ classes.itemsPerPageSelect } value={ itemsPerPage } data={ ['5', '10', '25', '50'] }/>
        <span className={ classes.records }>Showing { showingFrom } - { showingTo } of { totalRecords } records</span>
      </div>
    </div>
  );

};


export default PaginationBlock;
