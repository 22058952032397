import { List } from "@mantine/core";
import { FC } from "react";

import GlobalSearchResultItem from "@/tenant-context/control-search/components/GlobalSearchResultItem";
import { useGlobalSearchResultsStyles } from "@/tenant-context/control-search/components/GlobalSearchResults/GlobalSearchResults.style";
import { SearchResultFeatureCollection } from "@/tenant-context/control-search/types/search";

type Props = {
  searchResults: SearchResultFeatureCollection,
  searchCriteria: string,
  areResultsLoading: boolean,
  selectedSearchResultId:string,
  onHandleItemClick:(id?:string) => void
};

const GlobalSearchResults: FC<Props> = ({
  searchResults,
  searchCriteria,
  areResultsLoading,
  selectedSearchResultId,
  onHandleItemClick
}) => {
  const { classes } = useGlobalSearchResultsStyles();

  return (
    <div>
      <List
        size="sm"
        type="unordered"
        className={ classes.list }
      >
        { searchResults.features.map((feature) => (
          <GlobalSearchResultItem
            isSelected = { feature.properties.__subject === 'asset'
              ? feature.properties.personId ===  selectedSearchResultId
              : feature.properties.name ===selectedSearchResultId }
            // eslint-disable-next-line react/jsx-no-bind
            onHandleActiveItemClick={ () => onHandleItemClick(feature.properties.__subject === 'asset'
              ? feature.properties.personId
              : feature.properties.name) }
            isTheOnlyOne={ searchResults.features.length === 1 }
            key={ feature.properties.__subject === 'asset'
              ? feature.properties.personId
              : feature.properties.name }
            feature={ feature }
          />
        )) }
      </List>

      { searchCriteria && (<>
        { (!areResultsLoading && !searchResults.features.length) && (
          <p className={ classes.resultsSubDescription }>
            <span>We cannot find <strong>{ searchCriteria }</strong></span>
            <span>
              Make sure your search is spelled correctly.
            </span>
          </p>
        ) }

        { areResultsLoading && (
          <p className={ classes.resultsSubDescription }>
            Searching for assets ...
          </p>
        ) }

        <p className={ classes.resultsFound }>
          { `${searchResults.features.length} ${searchResults.features.length === 1 ? 'result' : 'results'} found` }
        </p>
      </>) }
    </div>
  );
};

export default GlobalSearchResults;
