import { callApi } from "@/common/util/api";
import { AssetRankingSummary } from "@/tenant-context/common/types/asset-ranking";
import { RankingOption } from "@/tenant-context/visualisation-people/store/people-locations/people-locations.model";

const DEFAULT_PRECISION = 3600;

export const getLocationsForPersonInTimeframe = (
  personId: string,
  startTime: number,
  endTime: number,
  rankingOption: RankingOption = RankingOption.allRanks,
  precisionIntervalInSeconds = DEFAULT_PRECISION
) => callApi<AssetRankingSummary[]>(
  `/asset-ranking-service/v1/location-engine/people/${personId}/series`,
  {
    method: 'get',
    queryParams: {
      personId,
      startTime,
      endTime,
      intervalInSeconds: precisionIntervalInSeconds,
      rankingOption
    }
  }
);
