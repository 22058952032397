import { FC } from "react";

import { TimerProps } from "./TimeUpCounter.container";

type Props = {
    timeCounter: TimerProps,
    timeInMillis: number | string
}

export const TimeUpCounterComponent: FC<Props> = ({
  timeCounter,
  timeInMillis
}) => {
  if (!timeInMillis || Number(timeInMillis) <= 0) {
    return null;
  }

  return (
    <div>{ `${timeCounter.days} ${timeCounter.days === 1 ? 'day' : 'days'}, ${timeCounter.hours} ${timeCounter.hours === 1 ? 'hour' : 'hours'}, ${timeCounter.minutes} min ago` }</div>
  );
};

export default TimeUpCounterComponent;
