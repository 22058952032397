import { FC } from "react";

import usePermission from '@/common/hooks/usePermission';
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { ReactComponent as EditIcon } from '@/common/icons/edit.svg';
import { RequiredPolicies } from '@/common/types/permission-control';

import { useEditButtonStyles } from './EditButton.style';

type Props = {
  onClick?: () => void,
  className?: string,
  requiredPolicies?: RequiredPolicies | RequiredPolicies[]
};

const EditButton: FC<Props> = ({
  onClick,
  className,
  requiredPolicies
}) => {
  const { classes } = useEditButtonStyles();
  const isAuthorized = usePermission(requiredPolicies);

  const handleUnauthorizedAction = useUnauthorizedModal();

  return (
    <button
      onClick={ isAuthorized ? onClick : handleUnauthorizedAction }
      className={ [className, isAuthorized ? classes.button : classes.unauthorizedButton ].filter(Boolean).join(' ') }
    >
      <EditIcon />
    </button>
  );
};

export default EditButton;
