import { createModel } from "@rematch/core";
import EventSource from "eventsource";
import { Feature, FeatureCollection, Point } from "geojson";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { AssetRankingSummary } from "@/tenant-context/common/types/asset-ranking";
import { GeoPoint } from "@/tenant-context/common/types/location";
import { Visualizable } from "@/tenant-context/common/util/with-visualisable-traits";
import { withVisualisableTraits } from "@/tenant-context/common/util/with-visualisable-traits";
import { parseGeoJSON } from "@/tenant-context/common/workers/geo-json-mapper-worker/geo-json-mapper-worker.external";
import { createConnectedSiteStream, getAllLocationSites } from '@/tenant-context/visualisation-site/api/site';
import { ConnectedSiteStatus,CountrySiteCount, SiteConnectivityStatus, SiteLocationSnapshot } from "@/tenant-context/visualisation-site/types/site.types";

import { getSiteStatusDuration } from "../../utils/site-utils";

type SiteState = Visualizable<{
  countryWiseSiteCount: CountrySiteCount[],
  connectedSiteStatuses: Record<string, number>
}, FeatureCollection<Point, SiteLocationSnapshot>>;

type NonSerializableConnectedSiteState = {
  connectedSiteStream?: EventSource
};

const nonSerializableState: NonSerializableConnectedSiteState = {};

const sitesLocationLayerModel = withVisualisableTraits<
  FeatureCollection<Point, SiteLocationSnapshot>
>()({
  name: 'siteLocations',
  state: {
    countryWiseSiteCount: [] as CountrySiteCount[],
    connectedSiteStatuses: {} as Record<string, number>
  },
  reducers: {
    SET_SITE_COUNT_FOR_COUNTRIES(state: SiteState, payload: CountrySiteCount[]) {
      return {
        ...state,
        countryWiseSiteCount: payload
      };
    },

    RECALCULATE_PEOPLE_COUNT_FOR_SITES(
      state: SiteState,
      people: FeatureCollection<Point, AssetRankingSummary>
    ) {
      const { geoData: sites } = state;

      const sitesFeaturesWithPeopleCounts = sites.features.map(
        (site: Feature<Point, SiteLocationSnapshot>) => ({
          ...site,
          properties: {
            ...site.properties,
            peopleCount: people.features.filter(
              ({
                properties: {
                  topRank: {
                    location: {
                      labels
                    }
                  }
                }
              }) => {
                const siteLabel = labels.find((label) => label.subCategory === 'SITE');
                return siteLabel?.name === site.properties.name;
              }
            ).length
          }
        })
      );

      return {
        ...state,
        geoData: {
          ...state.geoData,
          features: sitesFeaturesWithPeopleCounts
        }
      };
    },
    SET_CONNECTED_SITE_STATUS(state: SiteState, event: ConnectedSiteStatus) {
      const { connectedSiteStatuses: siteStatuses } = state;
      const statuses = { ...siteStatuses };
      statuses[event.locationId] = event.lastHeartbeatTimestamp;
      return {
        ...state,
        connectedSiteStatuses: statuses
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async loadAllSites(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          allCountries
        }
      } = state;

      // Load sites
      const allLocationSites = await getAllLocationSites(false, true);

      const allSites = [
        ...allLocationSites
      ].filter((location) => location.subCategory !== 'Zone'); // Filter out zones

      const { data } = await parseGeoJSON({
        data: allSites,
        params: {
          Point: ['geoPoint.lat', 'geoPoint.lon']
        }
      });

      dispatch.siteLocations.SET_GEO_DATA(data as FeatureCollection<Point, SiteLocationSnapshot>);

      // Recalculate site count per countries
      const countriesWithSites = Array.from(
        new Set(
          allSites.map(({ isoCountryCode }) => isoCountryCode)
        )
      );

      const countryMap: Record<string, GeoPoint> = allCountries.reduce(
        (acc, country) => ({
          ...acc,
          [country.isoCountryCode]: country.geoPoint
        }),
        {}
      );

      const countriesSiteCount: CountrySiteCount[] = countriesWithSites
        .filter((iso) => typeof countryMap[iso] !== 'undefined')
        .map(
          (country) => ({
            count: allSites.filter(({ isoCountryCode }) => isoCountryCode === country).length,
            isoCountryCode: country,
            latitude: countryMap[country].lat,
            longitude: countryMap[country].lon
          })
        );

      dispatch.siteLocations.SET_SITE_COUNT_FOR_COUNTRIES(
        countriesSiteCount
      );
    },

    recalculatePeopleCountForSites(_: void, state: RootState): void {
      const {
        peopleLocations: {
          geoData: people
        }
      } = state;

      dispatch.siteLocations.RECALCULATE_PEOPLE_COUNT_FOR_SITES(people);
    },
    subscribeToConnectedSiteData(_: void, state: RootState): void {
      const {
        commonData: {
          tenantId
        }
      } = state;

      if (!nonSerializableState.connectedSiteStream){
        const stream = createConnectedSiteStream((event) => {
          dispatch.siteLocations.SET_CONNECTED_SITE_STATUS(event);

        }, tenantId);

        nonSerializableState.connectedSiteStream = stream;
      }
    },
    processSiteConnectivity(locationId: string, state: RootState): {status: SiteConnectivityStatus, duration: string} {
      const {
        siteLocations: {
          connectedSiteStatuses
        }
      } = state;

      if (connectedSiteStatuses[locationId]){
        if ((Date.now() - connectedSiteStatuses[locationId]) < 60000){
          return { status: SiteConnectivityStatus.Online, duration: '' };
        } else {
          const statusDuraton = getSiteStatusDuration(connectedSiteStatuses[locationId]);
          return { status: SiteConnectivityStatus.Offline, duration: statusDuraton };
        }
      } else {
        return { status: SiteConnectivityStatus.Offline, duration: '' };
      }
    }
  })
});

export const siteLocations = createModel<RootModel>()(
  sitesLocationLayerModel
);
