export type DrawerState = {
  currentlyOpenRightDrawerId: string | undefined,
  currentlyOpenBottomDrawerId: string | undefined,
  currentlyOpenLeftDrawerId: string | undefined
}

export const drawerState: DrawerState = {
  currentlyOpenRightDrawerId: undefined,
  currentlyOpenBottomDrawerId:undefined,
  currentlyOpenLeftDrawerId: undefined
};
