import { FC } from "react";
import { useSelector } from "react-redux";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { RootState } from "@/core/store";
import { LayerWithLayoutProps } from "@/tenant-context/common/types/mapbox";

import DynamicallyVisibleLayer from './DynamicallyVisibleLayer.component';

type Props = LayerWithLayoutProps & {
  toggleableLayerType: ToggleableLayerType
};

const DynamicallyVisibleLayerContainer: FC<Props> = (props) => {
  const { toggleableLayerType, ...layerProps } = props;

  if (!toggleableLayerType) {
    throw new Error('Dynamically visible layers should have toggleable types!');
  }

  const disabledLayerTypes = useSelector(
    (state: RootState) => state.dataOptions.disabledLayerTypes
  );

  const isVisible = !disabledLayerTypes.includes(toggleableLayerType);

  return (
    <DynamicallyVisibleLayer
      layerProps={ layerProps }
      isVisible={ isVisible }
    />
  );
};

export default DynamicallyVisibleLayerContainer;
