import { FC, useContext, useEffect, useMemo } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import useLayerListener from "@/common/hooks/useLayerListener";
import { RootState } from "@/core/store";
import {
  BSOC_LAYER_IDS, DATAMINR_LAYER_IDS, MAX_SECURITY_LAYER_IDS,
  RISKLINE_LAYER_IDS
} from "@/tenant-context/visualisation-risk-alerts/configs/risk-alert-layer.config";
import { RiskContext } from "@/tenant-context/visualisation-risk-alerts/context/Risk.context";
import { useRiskAlertIcons } from "@/tenant-context/visualisation-risk-alerts/hooks/useRiskAlertIcons";

import RiskAlertLayer from "./RiskAlertLayer.component";

const layerIds = [
  ...RISKLINE_LAYER_IDS,
  ...BSOC_LAYER_IDS,
  ...DATAMINR_LAYER_IDS,
  ...MAX_SECURITY_LAYER_IDS,

  // Impact alerts
  'r__risk-impact-alert-hovered',
  'r__risk-impacthovericons-impacted-warning',
  'r__risk-impacthovericons',

  // Selected alert
  'r__risk-current-selected-risk-event',
  'r__risk-current-selected-risk-event-max-security-category',
  'r__risk-current-selected-risk-event-impacted-icon'
];

const RiskAlertLayerContainer: FC = () => {
  const riskAlertsGeoData = useSelector((state: RootState) => state.riskAlerts.geoData);
  const currentRiskAlert = useSelector((state: RootState) => state.riskAlerts.currentRiskAlert);
  const isRiskImpactCircleEnabled = useSelector((state: RootState) => state.riskDetails.isRiskImpactCircleEnabled);
  const currentBigMapPopup = useSelector((state: RootState) => state.bigMap.currentBigMapPopup);
  const isPopupLineEnabled = useSelector((state: RootState) => state.riskDetails.popupLineEnabled) && currentBigMapPopup === "Risk";

  const disabledRiskFilterTypes = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.disabledRiskFilterTypes
  );
  const riskLevelFilters = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.riskLevelFilters
  );
  const dataMinrWatchlist = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.dataMinrWatchlist
  ).map((watchlist) => watchlist.id);

  const showOnlyImpactedRiskEvents = useSelector(
    (state: RootState) => state.riskAlerts.showOnlyImpactedRiskEvents
  );
  const riskLevelBSOCFilters = useSelector((state: RootState) => state.riskEventFilterDataOptions.riskLevelBSOCFilters);

  const {
    showRiskAlertPopup,
    calculatePopupOffset
  } = useContext(RiskContext);

  useLayerListener(
    'click',
    layerIds,
    (evt) => {
      showRiskAlertPopup(evt, layerIds);
    }
  );

  const { current: map } = useMap();
  useRiskAlertIcons(map);

  useLayerListener(
    'mouseenter',
    layerIds,
    () => {
      if(map){
        map.getCanvas().style.cursor = 'pointer';
      }
    }
  );
  useLayerListener(
    'mouseleave',
    layerIds,
    () => {
      if(map){
        map.getCanvas().style.cursor = 'default';
      }
    }
  );

  // useEffect(() => {
  //   if(!isPopupLineEnabled){
  //     return;
  //   }

  //   const riskLocationCordinates = isRiskImpactCircleEnabled
  //     ? currentRiskAlert?.json.meta.geojson.geometry.coordinates
  //     : hoveredOnRiskLocation?.json.meta.geojson.geometry.coordinates;

  //   if(!riskLocationCordinates){
  //     return;
  //   }
  //   const lines = createLinesBetweenPopupAndRiskPoint(
  //       [riskLocationPopupCoordinates?.longitude,
  //         riskLocationPopupCoordinates?.latitude] as Position,
  //       riskLocationCordinates as Position
  //   );

  //   setPopupLine(lines);
  // }, [currentRiskAlert?.json.meta.geojson.geometry.coordinates,
  //   hoveredOnRiskLocation?.json.meta.geojson.geometry.coordinates,
  //   isPopupLineEnabled, isRiskImpactCircleEnabled,
  //   riskLocationPopupCoordinates?.latitude,
  //   riskLocationPopupCoordinates?.longitude] );

  useEffect(() => {
    if (!isPopupLineEnabled) {
      return;
    }
    if(map){
      map.on(
        'move',
        calculatePopupOffset
      );
    }

    return () => {
      map?.off(
        'move',
        calculatePopupOffset
      );
    };
  }, [isPopupLineEnabled, calculatePopupOffset, map]);

  const BSOCRiskLevelFilter = useMemo(() => {
    const { nonCritical, critical } = riskLevelBSOCFilters;

    if (critical && nonCritical) {  // TODO: Refactor
      return 'true false';
      // I know - pretty dumb. But this is temporary until it implements true and false values
      // for the risk event critical data property :P
    } else if (critical) {
      return 'true';
    } else if (nonCritical) {
      return 'false';
    } else {
      return '';
    }
  }, [riskLevelBSOCFilters]);

  return (
    <RiskAlertLayer
      riskAlertsGeoData={ riskAlertsGeoData }
      riskLevelDataminrFilters={ riskLevelFilters.dataminr }
      riskLevelRisklineFilters={ riskLevelFilters.riskline }
      disabledRiskFilters = {  disabledRiskFilterTypes }
      selectedRiskEventLocation = { currentRiskAlert }
      isShowOnlyImpactedRiskEvents = { showOnlyImpactedRiskEvents }
      isRiskImpactCircleEnabled = { isRiskImpactCircleEnabled }
      BSOCRiskLevelFilter = { BSOCRiskLevelFilter }
      dataMinrWatchlist={ dataMinrWatchlist }
    />
  );
};

export default RiskAlertLayerContainer;


