import { FeatureCollection, Point } from "geojson";
import { FC } from "react";

import { ArcImpactDetail } from "@/tenant-context/control-action-response-center/types/ARC.types";
import SitesLocationLayerComponent from "@/tenant-context/visualisation-site/layers/SitesLocationLayer/SitesLocationLayer.component";

type Props = {
    assetsGeodata?: FeatureCollection<Point, ArcImpactDetail>,
}

const ImpactAssets: FC<Props> = ({
  assetsGeodata
}) => {
  if(!assetsGeodata){
    return null;
  }

  return(
    <SitesLocationLayerComponent
      isSiteNamePopupShown={ false }
      isSiteDetailPopupShown={ false }
      popupContent={ undefined }
      id={ 'siteLocationSnapshotLayer-arc' }
      geoData={ assetsGeodata }
    />
  );
};

export default ImpactAssets;
