import { createStyles } from "@mantine/core";

export const usePersonDidNotShareLocationStyles = createStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "rgba(6, 25, 39, 0.76)",
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
    gap: '8px',
    backgroundColor: '#002338',
    boxShadow: theme.other.sdw.halo
  }
}));