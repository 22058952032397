import { createStyles } from "@mantine/core";

export const useProfilePeopleGroupsModalStyles = createStyles((theme) => ({
  tabContent: {
    margin: '24px 0'
  },

  radioGroup: {
    paddingBottom: 24,
    borderBottom: `1px solid ${theme.colors.neutral[6]}`
  }
}));