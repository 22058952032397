import { FC } from "react";

import CreateGeofenceToolBarContainer from "@/tenant-context/control-draw/components/CreateGeofenceToolBar";
import { DrawingTypes } from "@/tenant-context/control-draw/types/draw";


type Props = {
  handleDrawingToolSelect: (type: DrawingTypes) => void;
}
const SitePlanMapToolbarComponent: FC<Props> = ({ handleDrawingToolSelect }) => {

  return (
    <CreateGeofenceToolBarContainer isMinified={ true } handleDrawingToolSelect={ handleDrawingToolSelect }/>
  );
};

export default SitePlanMapToolbarComponent;
