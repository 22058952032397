import { Tabs } from "@mantine/core";
import { FC, useCallback } from "react";

import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { useProfileTabsStyles } from '@/tenant-context/control-profile/components/ui/ProfileTabs/ProfileTabs.style';

export enum ProfileTab {
  Profile = 'Profile',
  EmergencyContact = 'EmergencyContact',
  PersonalContact = 'PersonalContact',
  Addresses = 'Addresses',
  Documents = 'Documents',
  PeopleGroups = 'PeopleGroups',
  // ChangeLog = 'ChangeLog',
  Identification = 'IdentificationIdentification'
}

type Props = {
  activeTab: ProfileTab,
  handleTabChange: (tab: string | null) => void,
  tabs: TabProps[]
}

export type TabProps = {
  value: string;
  label: string;
  isAuthorized?: boolean;
}

const ProfileTabs: FC<Props> = ({
  activeTab,
  handleTabChange,
  tabs
}) => {
  
  const handleUnauthorizedAction = useUnauthorizedModal();
  const { classes } = useProfileTabsStyles();

  const interceptTabChange = useCallback((event) => {
    const selectedTab = tabs.find((tab) => tab.value === event);
    if (selectedTab?.isAuthorized) {
      handleTabChange(event);
    } else {
      handleUnauthorizedAction();
    }
  }, [tabs, handleTabChange, handleUnauthorizedAction]);

  const renderTab = (tab: TabProps, key: number) => {
    return (
      <Tabs.Tab
        value={ tab.value }
        key={ key }
        onClick={ !tab.isAuthorized ? handleUnauthorizedAction : undefined }
        className={ !tab.isAuthorized ? classes.unauthorizedTab : undefined }
      >
        { tab.label }
      </Tabs.Tab>
    );
  };

  return (
    <Tabs
      value={ activeTab }
      onTabChange={ interceptTabChange }
    >
      <Tabs.List>
        { tabs.map((tab, index) => (
          renderTab(tab, index)
        )) }

        { /* <Tabs.Tab value={ ProfileTab.ChangeLog }>
          Change Log
        </Tabs.Tab> */ }
      </Tabs.List>
    </Tabs>
  );
};

export default ProfileTabs;
