import { PaginatedResult } from "@/common/types/reference-data";
import { callApi, TokenType } from "@/common/util/api";
import browserDatabase from "@/common/util/browser-database";
import { CONTEXT_KEY, ContextTypes } from "@/core/store/shared-data/sharedData.types";
import {
  AccessibleCustomer,
  AllowedCustomerSet, ICMSubscribedTenants
} from "@/platform-context/control-icm-management/types/CaseTypes/caseTypesCustomerAccess";

export const caseTypesCustomerAccessApi = {
  tokenType: browserDatabase.getItem(CONTEXT_KEY) as ContextTypes === "platform" ? "access" : "tenant",
  createAccessibleCustomerList: function (data: AllowedCustomerSet, caseTypeId: string) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateCustomerAccess'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updateAccessibleCustomerList: function (data: AllowedCustomerSet, caseTypeId: string) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateCustomerAccess'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getICMSubscribedTenantsExceptSelected: function(caseTypeId: string) {
    return callApi<PaginatedResult<ICMSubscribedTenants>> (
      `/managed/icm-service/v1/case-types/${caseTypeId}?productsList=Crisis%20Management%20-%20Global%2C%20Incident%20%26%20Crisis%20Manager%20-%20Global`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetIcmSubscribedTenants'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getICMSubscribedTenants: function(caseTypeId: string) {
    return callApi<PaginatedResult<ICMSubscribedTenants>> (
      `/managed/icm-service/v1/case-types/${caseTypeId}?productsList=Crisis%20Management%20-%20Global%2C%20Incident%20%26%20Crisis%20Manager%20-%20Global`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetIcmSubscribedTenants'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getAccessibleCustomerList: function(caseTypeId: string) {
    return callApi<PaginatedResult<AccessibleCustomer>>(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListCustomerAccess'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  }
};