import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { handleError, handleSuccess } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { CONTEXT_TYPES } from "@/core/store/shared-data/sharedData.types";
import {
  createSeverity,
  deleteSeverity,
  getSeveritiesList, getSeverity,
  updateSeverity
} from "@/platform-context/control-icm-management/api/severity";
import {
  createSeverityLevel, deleteSeverityLevel,
  getSeverityLevel,
  getSeverityLevelList, postSeverityLevelOrder, updateSeverityLevel
} from "@/platform-context/control-icm-management/api/severityLevels";
import {
  ActiveSeverity,
  CreateSeverityType,
  PaginatedResult, Severity, SeverityLevel, SeverityLevelDeleteItem, SeverityLevelOrder
} from "@/platform-context/control-icm-management/types/severityTypes";

type SeverityState = {
  severities?: PaginatedResult<Severity>
  activeSeverity?: ActiveSeverity
  severityLevels?: PaginatedResult<SeverityLevel>
  activeSeverityLevel?: SeverityLevel
}

const severityModel = {
  name: 'severities',
  state: {
    severities: undefined,
    activeSeverity: undefined,
    severityLevels: undefined,
    activeSeverityLevel: undefined
  } as SeverityState,
  reducers: {
    SET_SEVERITIES: (state: SeverityState, severities: SeverityState['severities']) => ({
      ...state,
      severities
    }),
    SET_ACTIVE_SEVERITY: (state: SeverityState, activeSeverity: SeverityState['activeSeverity']) => ({
      ...state,
      activeSeverity
    }),
    SET_SEVERITY_LEVELS: (state: SeverityState, severityLevels: SeverityState['severityLevels']) => ({
      ...state,
      severityLevels
    }),
    SET_ACTIVE_SEVERITY_LEVEL: (state: SeverityState, activeSeverityLevel: SeverityState['activeSeverityLevel']) => ({
      ...state,
      activeSeverityLevel
    })
  },
  effects: (dispatch: Dispatch) => ({
    async addNewSeverity(severity: CreateSeverityType): Promise<boolean> {
      const res = await createSeverity(severity).catch(handleError);
      if (res) {
        handleSuccess("Record created successfully");
        dispatch.severities.SET_ACTIVE_SEVERITY(res);
      }

      return !!res;
    },
    async getSeverity(params: {id?: string, type?: string}): Promise<ActiveSeverity | undefined> {
      if (!params?.id) {
        return;
      }
      await getSeverity(params?.id, params?.type)
        .then((res) => dispatch.severities.SET_ACTIVE_SEVERITY(res))
        .catch(handleError);
    },
    async getAllSeverities(payload: {
      gridParams: GridParams
    }, _state: RootState): Promise<PaginatedResult<Severity>> {

      const res = await getSeveritiesList(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      if (res) {
        dispatch.severities.SET_SEVERITIES(res);
      }

      return res;
    },

    async deleteSeverity(id: string): Promise<void> {
      if (!id) {
        return;
      }
      const res = await deleteSeverity(id).catch(handleError);

      if(res){
        handleSuccess("Record deleted successfully");
        dispatch.severities.SET_ACTIVE_SEVERITY(undefined);
      }
    },
    async updateSeverity(data: { severity: CreateSeverityType, id: string }): Promise<void> {
      if (!data.id) {
        return;
      }
      const res = await updateSeverity(data.severity, data.id).catch(handleError);
      if (res) {
        handleSuccess("Record updated successfully");
        dispatch.severities.SET_ACTIVE_SEVERITY(res);
      }
    },
    async getAllSeverityLevels(params:{type?: string}, state: RootState): Promise<void> {
      const {
        severities: {
          activeSeverity
        }
      } = state;

      if (!activeSeverity?._id) {
        return;
      }

      const res = await getSeverityLevelList(activeSeverity?._id, params?.type).catch(handleError);
      if (res) {
        dispatch.severities.SET_SEVERITY_LEVELS(res);
      }
    },
    async createSeverityLevel(severityLevel: SeverityLevel, state: RootState): Promise<boolean> {
      const {
        severities: {
          activeSeverity
        }
      } = state;

      if (!activeSeverity?._id) {
        return false;
      }
      const res = await createSeverityLevel(activeSeverity?._id, severityLevel)
        .catch(handleError);

      if (res) {
        handleSuccess("Record created successfully");
        dispatch.severities.SET_ACTIVE_SEVERITY_LEVEL(undefined);
        dispatch.severities.getAllSeverityLevels(
          { type: activeSeverity?.tenantId ? CONTEXT_TYPES.tenant : CONTEXT_TYPES.platform }
        );
      }

      return !!res;
    },
    async getSeverityLevel(id: string, state: RootState): Promise<void> {
      const {
        severities: {
          activeSeverity
        }
      } = state;

      if (!activeSeverity?._id || !id) {
        return;
      }
      const res = await getSeverityLevel(
        activeSeverity?._id,
        id,
        activeSeverity?.tenantId ? CONTEXT_TYPES.tenant : CONTEXT_TYPES.platform
      ).catch(handleError);

      if (res) {
        dispatch.severities.SET_ACTIVE_SEVERITY_LEVEL(res);
      }
    },

    async updateSeverityLevel(severityLevel: SeverityLevel, state: RootState): Promise<void> {
      const {
        severities: {
          activeSeverity,
          activeSeverityLevel
        }
      } = state;

      if (!activeSeverity?._id || !activeSeverityLevel?._id) {
        return;
      }
      const res = await updateSeverityLevel(activeSeverity?._id, activeSeverityLevel?._id, severityLevel)
        .catch(handleError);

      if (res) {
        handleSuccess("Record updated successfully");
        dispatch.severities.SET_ACTIVE_SEVERITY_LEVEL(res);
        dispatch.severities.getAllSeverityLevels(
          { type: activeSeverity?.tenantId ? CONTEXT_TYPES.tenant : CONTEXT_TYPES.platform }
        );
      }
    },

    async updateSeverityLevelOrder(severityLevelOrder: SeverityLevelOrder, state: RootState): Promise<void> {
      const {
        severities: {
          activeSeverity
        }
      } = state;

      if (!activeSeverity?._id) {
        return;
      }
      await postSeverityLevelOrder(
        activeSeverity?._id,
        severityLevelOrder,
        activeSeverity?.tenantId ? CONTEXT_TYPES.tenant : CONTEXT_TYPES.platform
      ).catch(handleError);
      dispatch.severities.getAllSeverityLevels(
        { type: activeSeverity?.tenantId ? CONTEXT_TYPES.tenant : CONTEXT_TYPES.platform }
      );
    },

    async deleteSeverityLevel(SeverityLevelItem: SeverityLevelDeleteItem, state: RootState): Promise<void> {
      const {
        severities: {
          activeSeverity
        }
      } = state;

      if (!activeSeverity?._id) {
        return;
      }
      await deleteSeverityLevel(activeSeverity?._id, SeverityLevelItem)
        .then(() => dispatch.severities.SET_ACTIVE_SEVERITY_LEVEL(undefined))
        .then(() => handleSuccess("Record removed successfully"))
        .catch(handleError);
      dispatch.severities.getAllSeverityLevels(
        { type: activeSeverity?.tenantId ? CONTEXT_TYPES.tenant : CONTEXT_TYPES.platform }
      );
    }
  })
};

export const severities = createModel<RootModel>()(severityModel);
