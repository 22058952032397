import { Button, Loader,Menu, TextInput } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as DeleteIcon } from "@/common/icons/delete-bin.svg";
import { ReactComponent as PlusCircle } from "@/common/icons/plus-circle.svg";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import { AdditionalInfoObj, EnteredLocationDetails } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

import { useAddLocationsStyles } from "../../AddLocations.styles";

type Props = {
  onContactChange: (event: React.ChangeEvent<HTMLInputElement>, itemIndex: number) => void
  contacts: { [index: number]: Array<{ label: string, value: string }> } | undefined,
  onAddContact: () => void,
  onDeleteContact: (linkIndex: number) => void,
  onAddExternalLink: () => void,
  onDeleteExternalLink: (linkIndex: number) => void,
  enteredDetails: EnteredLocationDetails,
  onLinkChange: (val: React.ChangeEvent<HTMLInputElement>, linkIndex: number) => void,
  onFocusProfileSearch: (currentIndex: number) => void,
  focusedProfile: number | undefined,
  onContactSelect: (contactItem: string, index: number) => void,
  contactInputRefs: React.MutableRefObject<React.RefObject<HTMLInputElement>[]>,
  isSearching: { [index: number]: { searching: boolean } } | undefined,
  searchText: { [index: number]: { searchString: string } }
}

const AdditionalDetailsTabComponent: FC<Props> = (
  {  contacts, onAddContact, onDeleteContact,
    enteredDetails, onAddExternalLink, onDeleteExternalLink, onLinkChange,
    onFocusProfileSearch, focusedProfile, onContactChange, onContactSelect,
    contactInputRefs, isSearching, searchText }
) => {


  const { classes } = useAddLocationsStyles();

  return (
    <div className={ classes.formMainContainer }>
      <div className={ classes.formItemContainer }>
        <div className={ classes.formBody }>
          <div className={ classes.formItemHeader }>Location Contact</div>
          { (enteredDetails?.poiAdditionalInfoRequest as AdditionalInfoObj)?.contactList?.map((_contact, index) => (
            <div className={ classes.locationContact } key={ index }>
              <div className={ classes.menuContainer }>
                <TextInput ref={ contactInputRefs.current[index] ? contactInputRefs.current[index] : undefined }
                  value={ searchText[index]?.searchString || '' } key={ index }
                  // eslint-disable-next-line react/jsx-no-bind
                  onFocus={ () => onFocusProfileSearch(index) }
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (evt) => onContactChange(evt, index) }
                />
                <div className={ classes.relative }>
                  <Menu opened={ index === focusedProfile } position="bottom">
                    <Menu.Dropdown className={ classes.menuDropdown }>
                      { contacts ? contacts[index]?.map(contactItem =>
                        (<Menu.Item key={ contactItem.value }
                        // eslint-disable-next-line react/jsx-no-bind
                          onClick={ () => onContactSelect(contactItem.value, index) }>
                          { contactItem.label }</Menu.Item>)) : [] }
                      { (isSearching && isSearching[index]?.searching) ?
                        <Menu.Item><div className={ classes.loaderContainer }><div><Loader size={ "sm" } /></div>
                          <div>Searching</div></div>
                        </Menu.Item> : (contacts && contacts[index]?.length === 0) &&
                      ((contactInputRefs.current[index]?.current as HTMLInputElement)?.value.length > 2)
                          ? <Menu.Item>
                            No results found
                          </Menu.Item> : null }
                      { (contactInputRefs.current[index]?.current as HTMLInputElement)?.value.length < 2 ?
                        <Menu.Item>Type two or more characters to search</Menu.Item> : null }
                    </Menu.Dropdown>
                  </Menu>
                </div>
               
              </div>
              <Button
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => onDeleteContact(index) }
                className={ classes.deleteButton }><DeleteIcon /></Button>
            </div>
          )) }
          <div tabIndex={ 0 }
            className={ classes.addContainer } onClick={ onAddContact } onKeyDown={ onAddContact } role="button">
            <PlusCircle fill={ genericColorPalette.neutral[0] }/>
            <div className={ classes.formItemText }>Select a person</div>
          </div>
        </div>
      </div>
      <div className={ classes.formItemContainer }>
        <div className={ classes.formBody }>
          { (enteredDetails?.poiAdditionalInfoRequest as AdditionalInfoObj)
            ?.externalLinks?.map((item, index) => (<div key={ index } className={ classes.itemSpanThree }>
              <div className={ classes.externalLinkItem }>
                <div className={ classes.formItemHeader }>External Link Name</div>
                { /* eslint-disable-next-line react/jsx-no-bind */ }
                <TextInput value={ item.linkName } maxLength={ 128 } onChange = { (event) => onLinkChange(event, index) } name="linkName"/>
              </div>
              <div className={ classes.externalLinkItem }>
                <div className={ classes.formItemHeader }>URL</div>
                { /* eslint-disable-next-line react/jsx-no-bind */ }
                <TextInput value={ item.url } maxLength={ 128 } className={ classes.textExtendedWidth } onChange={ (event) => onLinkChange(event, index) } name="url" />
              </div>
              <div className={ classes.externalLinkItem }>
                <Button
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => onDeleteExternalLink(index) } className={ classes.deleteButton }>
                  <DeleteIcon /></Button>
              </div>
            </div>)) }

          <div className={ classes.addContainer } role="button" tabIndex={ 0 }
            onClick={ onAddExternalLink } onKeyDown={ onAddExternalLink }>
            <PlusCircle fill={ genericColorPalette.neutral[0] }/>
            <div className={ classes.formItemText }>Add another link</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalDetailsTabComponent;
