import { useMantineTheme } from "@mantine/core";
import { FeatureCollection } from "geojson";
import { FC, RefObject } from "react";
import { Source } from "react-map-gl";

import { Popup } from "@/common/components/Popup/Popup.components";
import DynamicallyVisibleLayer from "@/tenant-context/common/components/DynamicallyVisibleLayer";
import { PopupCoordinates } from "@/tenant-context/common/types/popup.types";
import {
  useCustomerLocationLayerStyles
} from "@/tenant-context/visualisation-customer-locations/layers/CustomerLocationLayer/CustomerLocationLayer.styles";
import {
  CustomerLocationLayerType,
  CustomerLocationPopupData
} from "@/tenant-context/visualisation-customer-locations/types/customer-location.types";

type Props = {
  geoData: FeatureCollection,
  layers: Array<CustomerLocationLayerType>,
  isPopupShown: boolean,
  popupCoordinates?: PopupCoordinates,
  popupContent: CustomerLocationPopupData | undefined,
  popupRef: RefObject<HTMLDivElement>
}

const CustomerLocationLayerComponent: FC<Props> = ({
  geoData,
  layers,
  isPopupShown,
  popupCoordinates,
  popupContent,
  popupRef
}: Props) => {

  const theme = useMantineTheme();
  const popupOffsetY = -32;

  const { classes } = useCustomerLocationLayerStyles();

  return (
    <Source
      id="r__customerLocationSource"
      type="geojson"
      tolerance={ 0 }
      data={ geoData }
    >
      { layers.map((layer) => (
        <DynamicallyVisibleLayer
          key={ layer.id }
          toggleableLayerType={ layer.toggleableLayerType }
          id={ layer.id }
          source="r__customerLocationSource"
          filter={ layer.filter }
          type={ 'symbol' }
          paint={ {
            ...layer.paint
          } }
          layout={ layer.layout }
        />

      )) }

      { isPopupShown && popupCoordinates && (
        <Popup
          longitude={ popupCoordinates.longitude }
          latitude={ popupCoordinates.latitude }
          anchor="bottom-left"
          /* eslint-disable-next-line no-magic-numbers */
          backgroundColor={ theme.colors.primary[3] }
          offset={ [0, popupOffsetY] }
          padding="sm"
          isWithConnectingLine
        >
          <div ref={ popupRef }
            className={ classes.popupContainer }>
            <div><b>{ popupContent?.locationName }</b></div>
            <div>{ popupContent?.locationCategory }</div>
          </div>
        </Popup>
      ) }
    </Source>
  );
};

export default CustomerLocationLayerComponent;
