import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useRiskLevelLegendStyles = createStyles((theme: MantineTheme) => ({
  wrapper: {
    width: 106,
    backgroundColor: theme.colors.navyBlue,
    order: 1,
    marginBottom: 8
  },

  riskLevelItem: {
    display: "flex",
    alignItems: "center",
    height: 32
  },

  riskItemIndicator: {
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.fontSizes.xs,
    color: theme.white,
    marginRight: 8
  },

  riskItemIndicatorLow: {
    backgroundColor: theme.colors.risk[0]
  },

  riskItemIndicatorModerate: {
    backgroundColor: theme.colors.risk[1]
  },

  riskItemIndicatorMedium: {
    backgroundColor: theme.colors.risk[2],
    color: theme.black
  },

  riskItemIndicatorHigh: {
    backgroundColor: theme.colors.risk[3]
  },

  riskItemIndicatorExtreme: {
    backgroundColor: theme.colors.risk[4]
  },

  riskItemLabel: {
    color: theme.white,
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.other.lh.l
  }
}));
