import { createStyles } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useSiteLocationPopupStyles = createStyles((theme) => ({
  popupContainer: {
    width: '385px',
    fontFamily: theme.fontFamily,
    color: theme.colors.neutral[8],
    boxSizing: 'border-box',
    backgroundColor: theme.colors.neutral[8],
    padding: '24px'
  },

  siteDescriptionContainer: {
    display: 'flex',
    width: '100%',
    overflowWrap: 'anywhere'
  },

  siteDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "space-between",
    marginLeft: '16px'
  },

  siteName: {
    fontWeight: theme.other.fw.bold,
    fontSize: theme.fontSizes.xl,
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[4]
  },

  siteLocation: {
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[6]
  },

  siteCategory: {
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[5],
    marginTop: '8px'
  },

  sitePeopleCountWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 4
  },
  sitePeopleCountValue: {
    fontWeight: theme.other.fw.bold,
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.other.lh.l,
    marginLeft: 16
  },
  popUpImgContainer: {
    maxWidth: '100px',
    img: {
      maxWidth: '100%',
      display: 'block',
      margin: '0 auto'
    },
    svg: {
      width: '40px',
      fill: theme.colors.neutral[6]
    }
  },
  siteStatusContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: theme.colors.neutral[5],
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.sm,
    gap: '6px'
  },
  imgPlaceHolderContainer: {
    backgroundColor: genericColorPalette.neutral[9],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '65px',
    height: '65px'
  },
  clusterItem: {
    '&:hover .CriticalLocationClusterPopup__personName': {
      textDecoration: "underline"
    }
  },
  clusterList: {
    overflowY: 'auto',
    maxHeight: '350px'
  },
  clusterHeader: {
    position: 'relative',
    display: 'flex',
    marginBottom: '16px',
    color: theme.colors.white
  },
  closeIcon: {
    position: 'absolute',
    right: '0',
    top: '0'
  }
}));
