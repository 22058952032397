import {
  ActionIcon,
  Divider,
  Stack,
  Text,
  TextInput,
  UnstyledButton,
  useMantineTheme
} from "@mantine/core";
import mapboxgl from "mapbox-gl";
import { FC, useCallback, useContext, useMemo, useRef, useState } from "react";

import { Popup } from "@/common/components/Popup/Popup.components";
import { ReactComponent as CloseIcon } from "@/common/icons/close.svg";
import { ReactComponent as SearchIcon } from "@/common/icons/search.svg";
import { CustomColors } from "@/core/styles/mantine/palettes/types";
import { BigMapPopupIds, useCurrentPopup } from "@/tenant-context/common/hooks/useMapPopupList";
import { LocationContext } from "@/tenant-context/control-lookup-tool/types/Lookup.types";
import { handleLocationTypeDisplay } from "@/tenant-context/control-lookup-tool/utils/LocationType";

import { PeopleContext } from "../../context/People.context";
import { usePeopleLocationPopupStyles } from "./PeopleLocationPopup.styles";

type Props = {
  onClickClose(popupId: string): void;
  onClickPersonItem?: (feature: mapboxgl.MapboxGeoJSONFeature) => void;
};

export const PeopleClusterPopup: FC<Props> = ({
  onClickClose,
  onClickPersonItem
}) => {
  const { mapKey } = useContext(PeopleContext);
  const { popup } = useCurrentPopup<mapboxgl.MapboxGeoJSONFeature[]>(
    mapKey,
    BigMapPopupIds.PeopleCluster
  );
  const { classes: popupClasses } = usePeopleLocationPopupStyles();
  const clusterFeatures = popup?.data;
  const theme = useMantineTheme();
  const popupRef = useRef<HTMLDivElement>(null);
  const { classes } = usePeopleLocationPopupStyles();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const handleClickPersonItem = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const personId = ev.currentTarget.value;
      const clickedPerson = clusterFeatures?.find(
        ({ properties }) => properties?.personId === personId
      );

      if (!clickedPerson) {
        return;
      }
      onClickPersonItem?.({ ...clickedPerson });
    },
    [clusterFeatures, onClickPersonItem]
  );

  const handleSearchChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(ev.target.value);
    },
    []
  );

  const filteredClusterFeatures = useMemo(
    () =>
      clusterFeatures?.filter(({ properties }) => {
        const fullName = properties?.topRank.personFullName?.toLowerCase();
        const email = properties?.email?.toLowerCase();
        const search = searchTerm.toLowerCase();
        return fullName?.includes(search) || email?.includes(search);
      }),
    [clusterFeatures, searchTerm]
  );

  return (
    <Popup
      longitude={ popup?.position[0] ?? 0 }
      latitude={ popup?.position[1] ?? 0 }
      padding="0"
      closeButton={ false }
      offset={ 20 }
      closeOnClick={ false }
    >
      <div ref={ popupRef } className={ popupClasses.popupContainer }>
        <div className={ classes.travellerPopupcontainer }>
          <div className={ classes.peopleClusterHeader }>
            <Text size="lg" fw={ 700 }>
              People:
            </Text>
            <ActionIcon
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => onClickClose(BigMapPopupIds.PeopleCluster) }
              size="md"
              className={ classes.closeIcon }
            >
              <CloseIcon />
            </ActionIcon>
          </div>

          <TextInput
            size="md"
            placeholder="Search"
            icon={ <SearchIcon /> }
            onChange={ handleSearchChange }
            defaultValue={ "" }
          />

          <Divider mb={ 16 } orientation="horizontal" />

          <div>
            <Stack spacing="md" className={ classes.peopleList }>
              { filteredClusterFeatures?.map(({ properties }) => (
                <UnstyledButton
                  key={ properties?.personId }
                  value={ properties?.personId }
                  onClick={ handleClickPersonItem }
                  className={ classes.clusterPersonItem }
                >
                  <Text className="PeopleClusterPopup__personName">
                    { properties?.topRank.personFullName || "Full Name N/A" }
                  </Text>
                  <Text
                    size="sm"
                    color={ theme.colors.neutral[5] as CustomColors }
                  >
                    Via{ " " }
                    { properties?.topRank?.adapterSource
                      ? handleLocationTypeDisplay(
                          properties?.topRank
                            ?.locationContextEnum as LocationContext
                      )
                      : "N/A" }
                  </Text>
                </UnstyledButton>
              )) }
            </Stack>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default PeopleClusterPopup;
