/* eslint-disable import/extensions */
import { FC, useCallback } from "react";

import { useDroppedInfoBoxStyles } from "@/tenant-context/common/components/DroppableMapTopInfoBox/DroppableMapTopInfoBox.styles";
import { ALL_SITES_CODE } from "@/tenant-context/widget-overview/components/SitesInfoBox/SiteInfoBox.container";

import { WidgetSite } from "../../types/site.types";
import {
  useSiteSearchDropdownStyles
} from "./SiteSearchDropdown.styles";


type Props = {
  siteList: Array<WidgetSite>,
  onSiteSelected: (selectedCountry: WidgetSite) => void
  musterCount?: number
}

const SiteSearchDropdown: FC<Props> = ({
  siteList,
  onSiteSelected,
  musterCount
}) => {

  const { classes, cx } = useSiteSearchDropdownStyles();
  const { classes: { listItemButton, musterSite } } = useDroppedInfoBoxStyles(undefined);

  const onSiteClicked = useCallback((site: WidgetSite) => (_: React.MouseEvent<HTMLButtonElement>) => {
    onSiteSelected(site);
  }, [onSiteSelected]);

  return (
    <div className={ classes.wrapper }>
      <ul>
        { siteList.length > 0 && siteList.map((site, index) => (
          <li
            key={ index }
          >
            <button
              className={ cx(listItemButton, { [musterSite]: (site.musterCount && site.musterCount > 0) }) }
              onClick={ onSiteClicked(site) }
            >
              <div className={ classes.siteContainer }>
                <div>
                  <div className={ classes.siteName }>{ site.name }</div>
                  <div className={ classes.siteLocation }>{ site.location }</div>
                </div>
                { site.musterCount && site.musterCount > 0
                  ?
                  (
                    <div className={ classes.musterContainer }>
                      <div className={ classes.musterCount }>
                        { site.code === ALL_SITES_CODE ? musterCount : site.musterCount }
                      </div>
                    </div>
                  ) : null }

              </div>
            </button>
          </li>
        )) }

        { siteList.length === 0 && (
          <div
            className={ listItemButton }
            role="listitem"
          >
            No sites available!
          </div>
        ) }
      </ul>
    </div>
  
  );
};

export default SiteSearchDropdown;
