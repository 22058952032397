import { Button } from "@mantine/core";
import { FC } from "react";
import { FallbackProps } from 'react-error-boundary';

const GlobalErrorBoundary: FC<FallbackProps> = ({
  error,
  resetErrorBoundary
}) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{ error.message }</pre>
      <Button onClick={ resetErrorBoundary }>Try again</Button>
    </div>
  );
};

export default GlobalErrorBoundary;
