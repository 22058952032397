export const DashboardRequiredPoliciesConfig = {
  DASHBOARD_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_Visitor_RequestManagement_FullAccess',
      'Tenant_Visitor_RequestManagement_ReadOnly',
      'Tenant_Visitor_RequestManagement_Approval',
      'Tenant_ConnectedSite_ManageSite_Access'
    ]
  },
  MANAGE_SITE_ACCESS: {
    policyNames: [
      'Tenant_ConnectedSite_ManageSite_Access'
    ]
  }
};
