export enum ReferenceType { // HERE
  TITLES = 'titles',
  GENDER = 'gender',
  PERSON_TYPE = 'personType',
  BLOOD_TYPE = 'bloodType',
  LENGTH_UNIT = 'lengthUnit',
  WEIGHT_UNIT = 'weightUnit',
  ETHNICITY = 'ethnicity',
  CONTACT_TYPE = 'contactType',
  ADDRESS_TYPE = 'addressType',
  EMERGENCY_CONTACT_TYPE = 'emergencyContacts',
  BUSINESS_CONTACT_RELATIONSHIP = 'BECRelationship',
  RELATIONSHIP = 'relationship',
  DOCUMENT_TYPE = 'documentType',
  BUILD = 'build',
  EYE_COLOUR = 'eyeColor',
  HAIR_COLOR = 'hairColor',
  NATIONALITY = 'nationality',
  COUNTRY = 'country',
  INTEGRATION_PROVIDER = 'IntegrationProvider',
  INTEGRATION_TYPES = 'IntegrationTypes',
  INTEGRATION_STATUS = 'IntegrationStatus',
  ACCESS_CARD_TYPE = 'AccessCardType',
  WHAT_HAPPENED = 'whatHappened',
  ACCESS_CARD_LAYOUT_KEYWORDS = 'card-layout-keyword',
  PRINT_JOB_STATUS = 'print-job-status',
  EMPLOYEE_TYPE = 'employeeType',
  ASSET_CATEGORIES = 'icm-asset-categories'
}


export type ReferenceData = {
    _id: string,
    code: string
    name: string
    active: true
    displayOrder: number
    type: string
    data: string
  imageUrl?: string
    meta: {
      createdDate: number
      updatedDate: number
    },
    defaultValue?: string
  }

export type CurrentLocationSubscription = {
    _id: string,
    identifier: string
    levelName: string
    isActive: true
    brn: string;
    trn: string;
    root: boolean;
    tenantRequirement: string;
    meta: {
      createdDate: number
      updatedDate: number
    }
  }

export type PaginatedResult<T> = {
    totalItems: number,
    totalPages: number,
    currentPage: number,
    items: T[]
  }
