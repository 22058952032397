import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useCaseTypeDetailsStyles = createStyles((theme: MantineTheme) => ({
  unauthorizedTab: {
    cursor: 'not-allowed'
  },
  caseTypeDetailsContainer: {
    padding: 32,
    width: '100%',
    backgroundColor: theme.colors.neutral[9]
  },
  caseTabs:{
    height: 50
  },
  subText:{
    fontSize: theme.fontSizes.sm,
    color: theme.colors.neutral[5]
  },
  featureHeader:{
    fontSize: theme.fontSizes.md
  },
  featureBlock:{
    backgroundColor: theme.colors.neutral[8],
    padding: "4px 16px 16px 16px",
    borderRadius: 8,
    height: "100%"
  },
  link:{
    color: theme.colors.teal[5]
  },
  relative:{
    position: "relative"
  },
  btnAlignRight:{
    display: "flex",
    marginLeft: "auto"
  },
  section: {
    marginBottom: 40
  },
  loaderWrap:{
    minHeight: 'calc(100vh - 12rem)'
  }
}));
