import { openModal } from '@mantine/modals';
import { FC, useCallback, useContext } from "react";

import AlertInsightTabComponent
  from '@/tenant-context/employee-app-action-response-center/components/tabs/AlertInsightTab/AlertInsightTab.component';
import { useAlertInsightTabStyles } from '@/tenant-context/employee-app-action-response-center/components/tabs/AlertInsightTab/AlertInsightTab.style';
import EvidenceVideoModal from '@/tenant-context/employee-app-action-response-center/components/ui/EvidenceVideoModal';
import { EA_ARC_EMERGENCY_VIDEO_MODAL } from '@/tenant-context/employee-app-action-response-center/config/eaArc';
import { EaArcContext } from "@/tenant-context/employee-app-action-response-center/context/EaArc.context";
import { EmergencyVideo } from "@/tenant-context/employee-app-action-response-center/types/emergency-video";

const EvidenceTab: FC = () => {

  const {
    emergencyVideos
  } = useContext(EaArcContext);

  const { classes } = useAlertInsightTabStyles();

  const openVideoInModal = useCallback((emergencyVideo: EmergencyVideo) => {
    openModal({
      modalId: EA_ARC_EMERGENCY_VIDEO_MODAL,
      title: 'Emergency video',
      withCloseButton: false,
      className: classes.modal,
      size: 'auto',
      children: <EvidenceVideoModal emergencyVideo={ emergencyVideo }/>
    });
  }, [classes.modal]);

  return (
    <AlertInsightTabComponent
      evidenceVideos={ emergencyVideos }
      onClickThumbnail={ openVideoInModal }
    />
  );
};

export default EvidenceTab;
