import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

type Params = {
  isMapZoomedIn: boolean,
  isDatesSelected: boolean
}

export const useRiskDateFilterTriggerControlStyles = createStyles((theme: MantineTheme, params: Params) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  controlButton: {
    width: params.isDatesSelected ? 260 : 'auto',
    fontSize: theme.fontSizes.xs
  },
  triggerContent: {
    display: "flex",
    alignItems: "center",

    '& > button': {
      color: theme.colors.error[0]
    }
  }
}));
