/* typescript-eslint/no-explicit-any is disabled because the parent object (SimpleSelectMode) is written in JS */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */

import { default as circle } from '@turf/circle';
import { default as distance } from '@turf/distance';
import { point } from '@turf/helpers';
import { SimpleSelectMode } from 'mapbox-gl-draw-circle';

import {
  cursors,
  geojsonTypes,
  meta,
  modes
} from '@/tenant-context/control-draw/controls/MapboxDrawControl/config/const.config';
import constrainFeatureMovement
  from '@/tenant-context/control-draw/controls/MapboxDrawControl/util/constrain_feature_movement';
import dragPan from '@/tenant-context/control-draw/controls/MapboxDrawControl/util/drag_pan';

export function isOfMetaType(type: any) {
  return function(e: any) {
    const { featureTarget } = e;
    if (!featureTarget) {
      return false;
    }
    if (!featureTarget.properties) {
      return false;
    }

    return featureTarget.properties.meta === type;
  };
}

const ExtendedSimpleSelectMode = { ...SimpleSelectMode };

ExtendedSimpleSelectMode.onSetup = function(opts: any) {
  const onSetup = SimpleSelectMode.onSetup.call(this, opts);
  this.updateUIClasses({ mouse: cursors.CROSS_HAIR });
  return onSetup;
};

ExtendedSimpleSelectMode.onMouseMove = function(state: any, e: any) {
  if (isOfMetaType(meta.VERTEX)(e)) {
    this.updateUIClasses({ mouse: cursors.MOVE });
  }

  return SimpleSelectMode.onMouseMove.call(this, state);
};

ExtendedSimpleSelectMode.clickOnVertex = function(state: any, e: any) {
  const featureId = e.featureTarget?.properties?.parent;
  const feature = featureId ? this.getFeature(featureId) : undefined;

  const shouldSwitchToDirectSelect = !feature?.properties?.isCircle;

  if (shouldSwitchToDirectSelect) {
    return this.changeMode(modes.direct_select, {
      featureId: e.featureTarget.properties.parent,
      coordPath: e.featureTarget.properties.coord_path,
      startPos: e.lngLat
    });
  }
  this.updateUIClasses({ mouse: cursors.MOVE });
};

ExtendedSimpleSelectMode.onMouseDown = function(state: any, e: any) {
  if (isOfMetaType(meta.VERTEX)(e)) {
    state.isScalling = true;
    state.canDragMove = true;
    state.dragMoveLocation = e.lngLat;
    const about = e.featureTarget?.properties;
    state.selectedCoordPaths = about ? [about.coord_path] : [];
    dragPan.disable(this);
  }

  return SimpleSelectMode.onMouseDown.call(this, state, e);
};

ExtendedSimpleSelectMode.onDrag = function(state: any, e: any) {
  if (state.isScalling) {
    if (state.canDragMove !== true) {
      return;
    }

    state.dragMoving = true;
    e.originalEvent.stopPropagation();

    const delta = {
      lng: e.lngLat.lng - state.dragMoveLocation.lng,
      lat: e.lngLat.lat - state.dragMoveLocation.lat
    };

    if (state.selectedCoordPaths.length > 0) {
      this.dragVertex(state, e, delta);
    } else {
      this.dragFeature(state, e, delta);
    }

    state.dragMoveLocation = e.lngLat;
  } else {
    return SimpleSelectMode.onDrag.call(this, state, e);
  }
};

ExtendedSimpleSelectMode.dragVertex = function(state: any, e: any, delta: any) {
  const targetFeatureId = state.initiallySelectedFeatureIds[0];
  const feature = this.getFeature(targetFeatureId);

  if(!feature){
    return;
  }
  
  if (feature.properties.isCircle) {
    const { center } = feature.properties;
    const movedVertex = [e.lngLat.lng, e.lngLat.lat];
    const radius = distance(
      point(center),
      point(movedVertex),
      { units: 'kilometers' }
    );
    const circleFeature = circle(center, radius);
    feature.incomingCoords(circleFeature.geometry.coordinates);
    feature.properties.radiusInKm = radius;
  } else {
    const selectedCoords = state.selectedCoordPaths.map((coord_path: any) => feature.getCoordinate(coord_path));
    const selectedCoordPoints = selectedCoords.map((coords: any) => ({
      type: geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: geojsonTypes.POINT,
        coordinates: coords
      }
    }));

    const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta);
    // eslint-disable-next-line fp/no-loops,fp/no-let
    for (let i = 0; i < selectedCoords.length; i++) {
      const coord = selectedCoords[i];
      state.feature.updateCoordinate(
        state.selectedCoordPaths[i],
        coord[0] + constrainedDelta.lng,
        coord[1] + constrainedDelta.lat
      );
    }
  }
};

ExtendedSimpleSelectMode.onMouseUp = function(state: any, e: any) {
  if (state.isScalling) {
    state.isScalling = false;
    state.canDragMove = false;
    dragPan.enable(this);
  }

  return SimpleSelectMode.onMouseUp.call(this, state, e);
};

export default ExtendedSimpleSelectMode;
