/* eslint-disable react/jsx-no-bind,react/jsx-props-no-spreading */
import { Checkbox } from "@mantine/core";
import { FC, ReactNode, useCallback } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { ReactComponent as ExtremeRiskIcon } from "@/common/icons/markers/RiskEvents/Extreme/Base.svg";
import { ReactComponent as HighRiskIcon } from "@/common/icons/markers/RiskEvents/High/Base.svg";
import { ReactComponent as LowRiskIcon } from "@/common/icons/markers/RiskEvents/Low/Base.svg";
import { ReactComponent as MediumRiskIcon } from "@/common/icons/markers/RiskEvents/Medium/Base.svg";
import { ReactComponent as ModerateRiskIcon } from "@/common/icons/markers/RiskEvents/Moderate/Base.svg";
import { capitalizeWord } from "@/common/util/format/string";
import { TRAVELLERS_SEARCH_FIELDS } from "@/tenant-context/control-travellers-search/config/travellers-search.config";
import {
  useTravelSearchControlDrawerStyles
} from "@/tenant-context/control-travellers-search/drawers/TravellersSearchControlsDrawer/TravellersSearchControlDrawer.styles";
import {
  DestinationRiskLevel,
  TravellersSearchFormData
} from "@/tenant-context/control-travellers-search/types/travelSearch.types";

type Props = {
  travellersSearchForm: UseFormReturn<TravellersSearchFormData>;
}

export const TravellersSearchControlsDrawerComponent: FC<Props> = ({
  travellersSearchForm
}) => {
  const { classes } = useTravelSearchControlDrawerStyles();
  const { control } = travellersSearchForm;

  const getRiskLevelIcon = useCallback((riskLevel: string) => {
    const riskLevelIconMap: Record<string, ReactNode> = {
      extreme: <ExtremeRiskIcon width={ 24 } height={ 24 }/>,
      high: <HighRiskIcon width={ 24 } height={ 24 }/>,
      low: <LowRiskIcon width={ 24 } height={ 24 }/>,
      medium: <MediumRiskIcon width={ 24 } height={ 24 }/>,
      moderate: <ModerateRiskIcon width={ 24 } height={ 24 }/>
    };

    return <div className={ classes.riskIconWrapper }>
      { riskLevelIconMap[riskLevel.toLowerCase()] }
      <div className={ classes.riskText }>{ capitalizeWord(riskLevel) }</div>
    </div>;
  }, [classes.riskIconWrapper, classes.riskText]);

  return (
    <div>
      { /*Removing Time filters for now until the temporal filters are on place*/ }
      { /* Time filters */ }
      { /*<div className={ classes.segment }>*/ }
      { /*  <div className={ classes.segmentLabel }>*/ }
      { /*    TIME*/ }
      { /*  </div>*/ }

      { /*  <div>*/ }
      { /*    <Controller*/ }
      { /*      name={ TRAVELLERS_SEARCH_FIELDS.TIME_RANGE }*/ }
      { /*      rules={ { required: true } }*/ }
      { /*      control={ control }*/ }
      { /*      render={ ({ field }) => (*/ }
      { /*        <Radio.Group*/ }
      { /*          size="sm"*/ }
      { /*          spacing={ 16 }*/ }
      { /*          orientation="vertical"*/ }
      { /*          { ...field }*/ }
      { /*        >*/ }
      { /*          <Radio value={ TimeRange["NOW"] } label="NOW"/>*/ }
      { /*          <Radio value={ TimeRange["24_HOURS"] } label="In 24 hours"/>*/ }
      { /*          <Radio value={ TimeRange["7_DAYS"] } label="In next 7 days"/>*/ }
      { /*          <Radio value={ TimeRange["31_DAYS"] } label="Next 31 days"/>*/ }
      { /*          <Menu*/ }
      { /*            withinPortal*/ }
      { /*            shadow="md"*/ }
      { /*            closeOnEscape={ false }*/ }
      { /*            closeOnClickOutside={ false }*/ }
      { /*            zIndex={ 9999 }*/ }
      { /*            opened={ opened }*/ }
      { /*            onChange={ handlers.toggle }*/ }
      { /*            position="bottom-start"*/ }
      { /*            styles={ {*/ }
      { /*              dropdown: {*/ }
      { /*                left: '530px !important'*/ }
      { /*              }*/ }
      { /*            } }*/ }
      { /*          >*/ }
      { /*            <Menu.Target>*/ }
      { /*              <Radio value={ TimeRange["DATE_RANGE"] } label="Select Date Range"/>*/ }
      { /*            </Menu.Target>*/ }

      { /*            <Menu.Dropdown className={ classes.calendarMenu }>*/ }
      { /*              <div className="header">*/ }
      { /*                <ActionIcon onClick={ handlers.toggle } variant={ 'subtle' }>*/ }
      { /*                  <CloseIcon/>*/ }
      { /*                </ActionIcon>*/ }
      { /*              </div>*/ }
      { /*              <div>*/ }
      { /*                <Controller*/ }
      { /*                  name={ TRAVELLERS_SEARCH_FIELDS.RANGE_CALENDAR }*/ }
      { /*                  control={ control }*/ }
      { /*                  render={ ({ field: calendarField }) => (*/ }
      { /*                    <RangeCalendar*/ }
      { /*                      amountOfMonths={ 2 }*/ }
      { /*                      minDate={ new Date(new Date().getTime() - (24 * 60 * 60 * 1000 * 6)) }*/ }
      { /*                      maxDate={ new Date(new Date().getTime() + (24 * 60 * 60 * 1000 * 30)) }*/ }
      { /*                      { ...calendarField }*/ }
      { /*                    />*/ }
      { /*                  ) }/>*/ }
      { /*              </div>*/ }

      { /*              <div className="footer">*/ }
      { /*                <Button*/ }
      { /*                  size="sm"*/ }
      { /*                  mt={ 64 }*/ }
      { /*                  rightIcon={ <RightIcon/> }*/ }
      { /*                  onClick={ handlers.toggle }*/ }
      { /*                  disabled={ !fromTime || !toTime }*/ }
      { /*                >Set Date Range*/ }
      { /*                </Button>*/ }
      { /*              </div>*/ }
      { /*            </Menu.Dropdown>*/ }
      { /*          </Menu>*/ }
      { /*        </Radio.Group>*/ }
      { /*      ) }*/ }
      { /*    />*/ }
      { /*  </div>*/ }
      { /*</div>*/ }

      { /* Traveller Type filters */ }
      { /*<div className={ classes.segment }>*/ }
      { /*  <div className={ classes.segmentLabel }>*/ }
      { /*    TRAVELLER TYPE*/ }
      { /*  </div>*/ }

      { /*  <div>*/ }
      { /*    <Controller*/ }
      { /*      name={ TRAVELLERS_SEARCH_FIELDS.TRAVELLERS_TYPE }*/ }
      { /*      control={ control }*/ }
      { /*      render={ ({ field }) => (*/ }
      { /*        <Checkbox.Group*/ }
      { /*          size="md"*/ }
      { /*          spacing={ 16 }*/ }
      { /*          orientation="vertical"*/ }
      { /*          { ...field }*/ }
      { /*        >*/ }
      { /*          <Checkbox value={ TravellersType.INTERNATIONAL } label="International"/>*/ }
      { /*          <Checkbox value={ TravellersType.DOMESTIC } label="Domestic"/>*/ }
      { /*        </Checkbox.Group>*/ }
      { /*      ) }/>*/ }
      { /*  </div>*/ }
      { /*</div>*/ }

      { /* Destination Risk Level filters */ }
      <div className={ classes.segment }>
        <div className={ classes.segmentLabel }>
          Trip Risk Level
        </div>

        <div>
          <Controller
            name={ TRAVELLERS_SEARCH_FIELDS.DESTINATION_RISK_LEVEL }
            control={ control }
            render={ ({ field }) => (
              <Checkbox.Group
                size="md"
                spacing={ 16 }
                orientation="vertical"
                { ...field }
              >
                <Checkbox className={ classes.riskCheckbox } value={ DestinationRiskLevel.EXTREME }
                  label={ getRiskLevelIcon('extreme') }/>
                <Checkbox className={ classes.riskCheckbox } value={ DestinationRiskLevel.HIGH }
                  label={ getRiskLevelIcon('high') }/>
                <Checkbox className={ classes.riskCheckbox } value={ DestinationRiskLevel.MEDIUM }
                  label={ getRiskLevelIcon('medium') }/>
                <Checkbox className={ classes.riskCheckbox } value={ DestinationRiskLevel.MODERATE }
                  label={ getRiskLevelIcon('moderate') }/>
                <Checkbox className={ classes.riskCheckbox } value={ DestinationRiskLevel.LOW }
                  label={ getRiskLevelIcon('low') }/>
              </Checkbox.Group>
            ) }/>
        </div>
      </div>
    </div>
  );
};
