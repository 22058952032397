import { ActionIcon, Badge, Divider, Flex, Text } from '@mantine/core';
import { FC, ReactNode } from 'react';

import { ReactComponent as CloseIcon } from "@/common/icons/close-x.svg";
import { ReactComponent as DataMinr } from "@/common/icons/dataminr.svg";
import { ReactComponent as MaxSecurity } from "@/common/icons/RiskProviders/max-security.svg";
import LocalErrorBoundary from '@/tenant-context/common/components/LocalErrorBoundary';
import NumberHighlightedTextComponent from '@/tenant-context/common/components/NumberHighlightedText/NumberHighlightedText.component';
import {
  useRelatedEventPopupStyles
} from '@/tenant-context/control-risk-config/components/RelatedEventPopup/RelatedEventPopup.styles';

type Props = {
  riskLevelColor: string,
  onCloseButtonClick: () => void,
  source?: string,
  timeElapsed?: string,
  riskEventAlertType?: string,
  eventLocationName?: string,
  eventDetails: ReactNode,
  providerName?: string,
  providerIcon?: ReactNode
}
const RelatedEventPopupComponent: FC<Props> = ({
  riskLevelColor,
  onCloseButtonClick: handleCloseButtonClick,
  source,
  timeElapsed,
  riskEventAlertType,
  eventLocationName,
  eventDetails,
  providerName,
  providerIcon
}) => {

  const { classes, cx } = useRelatedEventPopupStyles({
    riskLevelColor: riskLevelColor
  });

  return (
    <Flex direction={ 'column' } gap={ 8 } px={ 'md' } pb={ 'md' }>
      <LocalErrorBoundary>
        <Flex h={ 48 } align={ 'center' } justify={ 'space-between' }>
          { timeElapsed ? <NumberHighlightedTextComponent
            alphaFs={ 'xs' }
            text={ timeElapsed }
          /> : <div/> }
          <ActionIcon onClick={ handleCloseButtonClick } variant={ 'subtle' } size={ 14 }>
            <CloseIcon/>
          </ActionIcon>
        </Flex>
        <Divider/>
        <Flex direction={ 'column' } gap={ 8 } py={ 'xs' }>
          <Text><h4>{ eventLocationName }</h4></Text>
          <Flex><Badge radius={ 'xs' } className={ classes.riskLevelBadge }>{ riskEventAlertType }</Badge></Flex>
          <Text className={ cx(classes.caption, classes.sectionContent) }>
            { eventDetails }
          </Text>
        </Flex>
        { source && <Text size={ 'xs' }><span className={ classes.dimmed }>Source</span> { source }</Text> }
        <Divider/>
        <Flex gap={ 'xs' } justify={ 'flex-start' } align={ 'center' } mt={ 4 } className={ classes.riskAlertProvider }>
          { (providerName || providerIcon) && <div className={ classes.issuedBy }>Issued by</div> }
          { providerName === 'Dataminr' ? <DataMinr/> :
            providerName === 'Max Security' ? <MaxSecurity/> : <div/> }
          <div className={ classes.riskAlertProviderName }>{ providerName }</div>
        </Flex>
      </LocalErrorBoundary>
    </Flex>
  );
};

export default RelatedEventPopupComponent;
