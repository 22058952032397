import { FC } from "react";

import { RiskBSOCToggleableFilterType } from "@/common/components/ToggleableFilter/ToggleableFilter.config";
import { RiskProviders } from "@/tenant-context/common/types/risk";
import {
  BSOCEventsComponent
} from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/BSOCEvents.component";

import { useDisabledRiskFilterTypes, useToggleCategoryFilter } from "./hooks";

const BSOCEventsContainer: FC = () => {
  const disabledRiskFilterTypes = useDisabledRiskFilterTypes();

  const handleSwitchToggle = useToggleCategoryFilter(RiskProviders.bsoc, {
    categoryIdToFilterIdMap: RiskBSOCToggleableFilterType
  });

  return (
    <BSOCEventsComponent
      onCategoryToggle={ handleSwitchToggle }
      disabledRiskFilterTypes={ disabledRiskFilterTypes }
    />
  );
};

export default BSOCEventsContainer;
