import { FileWithPath } from "@mantine/dropzone";
import { ChangeEvent, FC, FormEvent, useCallback, useMemo, useRef } from "react";
import { FileRejection } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import { ProfileDocumentFile } from "@/tenant-context/control-profile/types/profile";

import AppConfigurationComponent from "./AppConfiguration.component";

const AppConfiguration: FC = () => {

  const appCommunicationContent = useSelector((state: RootState) => state.massComms?.appCommunicationContent);

  const {
    massComms: {
      SET_APP_COMMUNICATION_CONTENT,
      uploadAppCommunicationAttachment,
      removeAppCommunicationAttachment,
      removeBase64AppCommunicationAttachment
    }
  } = useDispatch<Dispatch>();

  const uploadRef = useRef<() => void>(null);

  const handleHeaderChange = useCallback((subject: FormEvent<HTMLInputElement>) => {
    SET_APP_COMMUNICATION_CONTENT({
      ...appCommunicationContent,
      header: (subject.target as HTMLInputElement).value
    });
  }, [ SET_APP_COMMUNICATION_CONTENT, appCommunicationContent ]);

  const handleContentChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    const contentBody = event.target.value;
    SET_APP_COMMUNICATION_CONTENT({
      ...appCommunicationContent,
      content: contentBody
    });
  }, [SET_APP_COMMUNICATION_CONTENT, appCommunicationContent]);
  
  const onNewAttachmentSelected = useCallback((files: FileWithPath[]) => {
    files.forEach(e => uploadAppCommunicationAttachment(e));
  }, [uploadAppCommunicationAttachment]);

  const handleRemoveAttachment = useCallback((file: FileWithPath | ProfileDocumentFile) => {
    removeAppCommunicationAttachment(file as FileWithPath);
  }, [removeAppCommunicationAttachment]);
  
  const handleDownloadAttachment = useCallback((file: ProfileDocumentFile) => {
    window.open(file.downloadURL, '_blank');
  }, []);

  const handleRejectedFiles = useCallback((rejectedFiles: FileRejection[]) => {
    rejectedFiles.forEach((rejection) => {
      const [error] = rejection.errors;
      const errorMessage = error.code === 'file-too-large'
        ? `File is larger than the maximum allowed size`
        : error.message;


      showNotification({
        title: `Error in ${rejection.file.name}`,
        message: errorMessage,
        color: 'error'
      });
    });
  }, []);

  const setCriticalAlertMode = useCallback((isOn: boolean) => {
    SET_APP_COMMUNICATION_CONTENT({
      ...appCommunicationContent,
      criticalAlertIOS: isOn
    });
  }, [ SET_APP_COMMUNICATION_CONTENT, appCommunicationContent ]);

  const setBiometricConfirmationState = useCallback((isOn: boolean) => {
    SET_APP_COMMUNICATION_CONTENT({
      ...appCommunicationContent,
      isBiometricConfirmationTurnedOn: isOn
    });
  }, [ SET_APP_COMMUNICATION_CONTENT, appCommunicationContent ]);

  const isCriticalAlert = useMemo(
    () => appCommunicationContent?.criticalAlertIOS || false,
    [appCommunicationContent?.criticalAlertIOS]
  );

  const isBiometricConfirmationTurnedOn = useMemo(
    () => appCommunicationContent?.isBiometricConfirmationTurnedOn || false,
    [appCommunicationContent?.isBiometricConfirmationTurnedOn]
  );

  return (
    <AppConfigurationComponent
      communicationContent={ appCommunicationContent }
      onHeaderChanged={ handleHeaderChange }
      onContentChanged={ handleContentChange }
      onNewAttachmentSelected={ onNewAttachmentSelected }
      uploadRef={ uploadRef }
      onRemoveAttachment={ handleRemoveAttachment }
      onRemoveBase64Attachment = { removeBase64AppCommunicationAttachment }
      onDownloadAttachment={ handleDownloadAttachment }
      onRejectedFiles={ handleRejectedFiles }
      onCriticalAlertModeChange={ setCriticalAlertMode }
      isCriticalAlertMode={ isCriticalAlert }
      onBiometricConfirmationStateChange={ setBiometricConfirmationState }
      isBiometricConfirmationTurnedOn={ isBiometricConfirmationTurnedOn }
    />
  );
};

export default AppConfiguration;
