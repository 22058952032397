/* eslint-disable react/jsx-props-no-spreading */
import { Button, Switch, TextInput } from "@mantine/core";
import { FC } from "react";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";

import { constructTestId } from "@/core/util/test-id";
import {
  useCreateRoleModalStyles
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/CreateRoleModal/CreateRoleModal.styles";
import { Role } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

const FORM_NAME = 'overview';

type Props = {
    updateHandler:  (data: FieldValues) => void
    formControls: UseFormReturn<FieldValues, unknown>
    activeRole?: Role,
    isAllowedToUpdate: boolean
    roleFeaturesFirstStep: { value: string, label: string }[]
}

const OverviewComponent: FC<Props> = ({
  updateHandler,
  formControls,
  activeRole,
  isAllowedToUpdate,
  roleFeaturesFirstStep
}) => {

  const { classes } = useCreateRoleModalStyles();
  const { handleSubmit, register, control } = formControls;
  
  return (
    <div className={ classes.overviewContainer }>
      <form onSubmit={ handleSubmit(updateHandler) }>
        <div className={ classes.section }>
          <TextInput
            data-testid={ constructTestId("INPUT", FORM_NAME, "roleName") }
            label="Role Name"
            required={ true }
            readOnly={ !isAllowedToUpdate }
            defaultValue={ activeRole?.roleName }
            maxLength={ 32 }
            wrapperProps={ {
              style: {
                marginBottom: 0
              }
            } }
            { ...register('roleName') }
          />
          <label className={ classes.descriptionLabel }>No longer than 32 characters</label>
        </div>
        <div className={ classes.section }>
          <div className={ classes.sectionTitle }>Data Restrictions</div>
          { roleFeaturesFirstStep.map((feature) => (
            <Controller
              rules={ {
                required: false
              } }
              key={ feature.value }
              control={ control }
              defaultValue={ activeRole?.[feature.value as keyof typeof activeRole] as boolean || false }
              name={ feature.value }
              // eslint-disable-next-line react/jsx-no-bind
              render={ ({
                field: { onChange, value }
              }) => (
                <Switch
                  data-testid={ constructTestId("SWITCH", FORM_NAME, feature.value ) }
                  disabled={ !isAllowedToUpdate }
                  labelPosition="left"
                  label={ feature.label }
                  size="lg"
                  checked={ value }
                  onChange={ onChange }
                  className={ classes.switch }
                  wrapperProps={ {
                    style: {
                      marginBottom: 12
                    }
                  } }
                />
              ) }
            />
          )) }
        </div>
        <div className={ classes.submitButtonContainer }>
          <Button
            data-testid={ constructTestId("BUTTON", FORM_NAME, "Save") }
            disabled={ !isAllowedToUpdate }
            type={ "submit" }
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OverviewComponent;