import { MantineTheme } from "@mantine/core";

// import { genericColorPalette } from "@/tenant-context/core/styles/mantine/palettes";

export const TooltipComponentStyle = {
  defaultProps: {},
  styles: (theme: MantineTheme) => ({
    tooltip: {
      backgroundColor: '#172E40',
      color: theme.colors.neutral[5],
      fontSize: theme.fontSizes.xs,
      border: `0.5px solid ${theme.colors.neutral[6]}`,

      '& > :nth-of-type(1)': {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
    arrow: {
      backgroundColor: '#172E40',
      border: `0.5px solid ${theme.colors.neutral[6]}`
    }
  })
};
