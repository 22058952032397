import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { RiskProviders } from "@/tenant-context/common/types/risk";
import {
  DataMinrEventsComponent
} from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/DataMinrEvents.component";

import { ProviderCategory } from "../../types/risk";
import { useDisabledRiskFilterTypes, useToggleCategoryFilter } from "./hooks";

const DataMinrEventsContainer: FC = () => {
  const dataMinrWatchlist = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.dataMinrWatchlist
  );
  const items = useMemo<Array<ProviderCategory>>(
    () =>
      dataMinrWatchlist.map((category) => ({
        ...category,
        categoryId: category.id.toString()
      })),
    [dataMinrWatchlist]
  );

  const disabledRiskFilterTypes = useDisabledRiskFilterTypes();

  const handleSwitchToggle = useToggleCategoryFilter(RiskProviders.dataminr);

  return (
    <DataMinrEventsComponent items={ items } onCategoryToggle={ handleSwitchToggle }
      disabledRiskFilterTypes={ disabledRiskFilterTypes } />
  );
};

export default DataMinrEventsContainer;
