import { callApi } from "@/common/util/api";
import createServerSentEventStream, { SubscriptionEventHandler } from "@/common/util/sse";
import {
  GenericRiskAlertResponse
} from "@/tenant-context/visualisation-risk-alerts/types/risk-alert-response";
import { RiskAlert, RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

export const getRiskAlerts = () => callApi<RiskAlert[]>(
  '/common-risk-alert-adapter-service/v1/risk-alerts',
  {
    method: 'GET'
  }
);

export const createRiskAlertStream = (
  handleEvent: SubscriptionEventHandler<RiskAlertEvent>
) => createServerSentEventStream(
  '/riskline-alert-connector-service/v1/stream/events',
  handleEvent
);

export const createImpactEngineRiskAlertStream = (
  query: string,
  tenantId: string,
  handleEvent: SubscriptionEventHandler<GenericRiskAlertResponse>
) => createServerSentEventStream(
  `/asset-impact-service/v1/stream/asset-impact-events?query=${query}&tenantId=${tenantId}`,
  handleEvent
);
