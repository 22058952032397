import { ActionIcon } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import { FC, useCallback } from "react";

import { useDropzoneFilesStyles } from "@/common/components/DropzoneFiles/DropzoneFiles.style";
import usePermission from '@/common/hooks/usePermission';
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { ReactComponent as DownloadIcon } from '@/common/icons/download.svg';
import { ReactComponent as TrashIcon } from '@/common/icons/trash.svg';
import { RequiredPolicies } from '@/common/types/permission-control';
import { toHumanReadableFileSize } from "@/common/util/file-size";
import { Base64EmailAttachment } from "@/tenant-context/control-mass-comms/types/MassComms.types";
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock";
import ProfileKeyValue from "@/tenant-context/control-profile/components/ui/ProfileKeyValue";
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock";
import { ProfileDocumentFile } from "@/tenant-context/control-profile/types/profile";

type Props = {
  files: FileWithPath[],
  filesFromServer?: ProfileDocumentFile[],
  base64Files?: Array<Base64EmailAttachment>,
  removeFile?: (file: FileWithPath | ProfileDocumentFile) => void,
  removeBase64File?: (file: Base64EmailAttachment) => void,
  isWithoutFilesError?: boolean,
  downloadFile?: (file: ProfileDocumentFile) => void,
  requiredPoliciesForDelete?: RequiredPolicies | RequiredPolicies[],
  // eslint-disable-next-line react/boolean-prop-naming
  downloadBase64File?: boolean
}

const DropzoneFiles: FC<Props> = ({
  files,
  filesFromServer,
  base64Files,
  removeBase64File,
  removeFile,
  isWithoutFilesError = false,
  downloadFile,
  requiredPoliciesForDelete,
  downloadBase64File = false
}) => {
  const { classes } = useDropzoneFilesStyles();
  const isAuthorizedToDelete = usePermission(requiredPoliciesForDelete);
  const handleUnauthorizedAction = useUnauthorizedModal();

  const gtc = useCallback(
    () => '3fr 1fr 1fr 0.3fr 0.3fr',
    []
  );

  return (
    <div>
      <ProfileBlock
        mode='legend-header'
      >
        <ProfileKeyValueBlock
          maxColumns={ 3 }
          gtc={ gtc }
        >
          <ProfileKeyValue
            mode='legend-header'
            name={ 'NAME' }
            value={ [
              'FILE TYPE',
              'SIZE'
            ] }
          />
        </ProfileKeyValueBlock>
      </ProfileBlock>
      { !(files.length + (filesFromServer?.length || 0) + (base64Files?.length || 0)) && (
        <ProfileBlock
          element="li"
          padding="m"
        >
          <span className={ isWithoutFilesError ? classes.err : "" }>
            { isWithoutFilesError ? "Please select files!" : "No items to show" }
          </span>
        </ProfileBlock>
      ) }

      { filesFromServer?.map((file, i) => {
        const splitName = file.nameWithExtension.split('.');
        const ext = splitName.pop();
        const name = splitName.join('.');

        return (
          <ProfileBlock key={ i } element='li'>
            <ProfileKeyValueBlock
              maxColumns={ 3 }
              gtc={ gtc }
            >
              <ProfileKeyValue
                firstItem="regular"
                name={ name }
                value={ [
                  ext,
                  toHumanReadableFileSize(file.fileSize * 1024 * 1024)
                ] }
              />
              { downloadFile && <ActionIcon
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => downloadFile(file) }
                className={ `${classes.btn} download` }
                size={ 'lg' }
              >
                <DownloadIcon />
              </ActionIcon> }
              { removeFile &&  <ActionIcon
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ isAuthorizedToDelete ? () => removeFile(file) : handleUnauthorizedAction }
                className={ isAuthorizedToDelete ? `${classes.btn} delete` : classes.unauthorizedButton }
                size={ 'lg' }
              >
                <TrashIcon />
              </ActionIcon> }
            </ProfileKeyValueBlock>
          </ProfileBlock>
        );
      }) }

      { files.map((file, i) => {
        const splitName = file.name.split('.');
        const ext = splitName.pop();
        const name = splitName.join('.');

        return (
          <ProfileBlock key={ i } element='li'>
            <ProfileKeyValueBlock
              maxColumns={ 3 }
              gtc={ gtc }
            >
              <ProfileKeyValue
                firstItem="regular"
                name={ name }
                value={ [
                  ext,
                  toHumanReadableFileSize(file.size)
                ] }
              />
              <div />
              { removeFile &&  <ActionIcon
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => removeFile(file) }
                className={ `${classes.btn} delete` }
                size={ 'lg' }
              >
                <TrashIcon />
              </ActionIcon> }
            </ProfileKeyValueBlock>
          </ProfileBlock>
        );
      }) }

      { base64Files?.map((file, i) => {
        const ext = file.extension;
        const name = file.name || `Attachment - ${ i + 1 }`; // Because Base64 doesn't store file name
        const size = file.size || Math.ceil(file.base64.length / 4) * 3;

        return (
          <ProfileBlock key={ i } element='li'>
            <ProfileKeyValueBlock
              maxColumns={ 3 }
              gtc={ gtc }
            >
              <ProfileKeyValue
                firstItem="regular"
                name={ name }
                value={ [
                  ext,
                  toHumanReadableFileSize(size)
                ] }
              />
              <div />
              { downloadBase64File && <ActionIcon
                className={ `${classes.btn} download` }
                size={ 'lg' }
              >
                <a download={ `Attachment - ${ i + 1 }` } href={ file.base64 }> <DownloadIcon /></a>
              </ActionIcon> }
              { removeBase64File && <ActionIcon
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => removeBase64File(file) }
                className={ `${classes.btn} delete` }
                size={ 'lg' }
              >
                <TrashIcon />
              </ActionIcon> }
            </ProfileKeyValueBlock>
          </ProfileBlock>
        );
      }) }
    </div>
  );
};

export default DropzoneFiles;
