import { RoleFeatures } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

export const roleFeaturesOptions = [
  { value: RoleFeatures.RESTRICTED, label: 'Restricted' },
  { value: RoleFeatures.READ_ONLY, label: 'Read only' },
  { value: RoleFeatures.FULL_ACCESS, label: 'Full access' }
];

export const roleFeaturesFirstStep = [
  { value: 'allowImportOrExport', label: 'Allow import/export data' },
  { value: 'showSensitiveData', label: 'Show sensitive data' },
  { value: 'showAllEvents', label: 'Show all events' },
  { value: 'showKeyEvents', label: 'Show key events' },
  { value: 'showEscalatedEvents', label: 'Show escalated events' },
  { value: 'crossTeamManagement', label: 'Cross-team management' },
  { value: 'shareWithOtherTeams', label: 'Share with other teams' }
];

export const roleFeaturesSecondStep = [
  { value: 'briefings', label: 'Briefings' },
  { value: 'checklists', label: 'Checklists' },
  { value: 'contactLogs', label: 'Contact Logs' },
  { value: 'events', label: 'Events' },
  { value: 'incidentReports', label: 'Incident Reports' },
  { value: 'logistics', label: 'Logistics' },
  { value: 'media', label: 'Media' }
];