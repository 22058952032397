import { FC, useCallback } from "react";

import ButtonControl from "@/common/components/ButtonControl";
import DrawerControl from "@/common/components/DrawerControl";
import { DrawerControlButtonRenderer } from "@/common/components/DrawerControl/DrawerControl.component";
import { ReactComponent as LayerToggleIcon } from '@/common/icons/layer-toggle.svg';
import { TestableComponent } from '@/common/types/testable-component';

import { MapStyleToggleList } from "./components";

const BasemapLayerToggleControl: FC<TestableComponent> = ({
  dataTestId = "right-menu-layers" }) => {
  // const mantineTheme = useMantineTheme();
  const renderTestDrawerButton: DrawerControlButtonRenderer = useCallback(
    ({ onClick }) => (
      <ButtonControl label="Basemaps" onClick={ onClick } data-testid={ dataTestId }>
        <LayerToggleIcon/>
      </ButtonControl>
    ),
    [dataTestId]
  );

  return (
    <DrawerControl
      id={ "basemap-layer-control" }
      title={ "Basemaps" }
      renderButton={ renderTestDrawerButton }
    >
      <MapStyleToggleList/>
    </DrawerControl>
  );
};

export default BasemapLayerToggleControl;
