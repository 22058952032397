import { Box, Button, Flex } from '@mantine/core';
import React, { FC, useCallback, useEffect, useRef } from "react";

import ToastNotificationComponent
  from '@/tenant-context/control-live-feed/components/ToastNotificationContainer/ToastNotification.component';
import {
  FeedNotification,
  NotificationContainerLayoutParams
} from '@/tenant-context/control-live-feed/types/live-feed';

import { useToastNotificationContainerStyles } from "./ToastNotificationContainer.styles";

interface Props {
  notifications: FeedNotification[];
  isCollapsed: boolean;
  setIsCollapsed: (state: boolean) => void;
  onNotificationClose: (notificationId: string) => void;
  totalVisibleNotifications: number;
  handleContainerScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
  isContainerScrolledToTop?: boolean;
  layoutParams?: NotificationContainerLayoutParams;
  closeAllNotifications?: () => void;
}

const ToastNotificationContainerComponent: FC<Props> = ({
  notifications,
  isCollapsed,
  setIsCollapsed,
  onNotificationClose,
  totalVisibleNotifications,
  handleContainerScroll,
  isContainerScrolledToTop,
  layoutParams,
  closeAllNotifications
}) => {
  const { classes } = useToastNotificationContainerStyles({ layoutParams: layoutParams });

  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToTop = useCallback(() => {
    if (containerRef.current?.scrollTop) {
      containerRef.current.scrollTop = 0;
    }
  }, [ containerRef ]);

  const showLess = useCallback(() => {
    setIsCollapsed(true);
    scrollToTop();
  }, [ scrollToTop, setIsCollapsed ]);

  const showMore = useCallback(() => {
    setIsCollapsed(false);
  }, [ setIsCollapsed ]);

  const calculateVisibleIndex = useCallback((notificationId: string) => {
    const allVisibleNotifications = notifications.filter(({ isClosedInDisplayStack }) => !isClosedInDisplayStack);
    return allVisibleNotifications.findIndex(({ notificationId: id }) => id === notificationId);
  }, [ notifications ]);

  useEffect(() => {
    if (totalVisibleNotifications <= 1) {
      showLess();
    }
  }, [ showLess, totalVisibleNotifications ]);

  return (
    <Flex direction="column" className={ classes.root }>
      { totalVisibleNotifications > 0 && <Box
        className={ classes.notificationHeader }
        h={ isCollapsed || !isContainerScrolledToTop ? 0 : 32 }>
        <Flex direction="row" justify="flex-end">
          <Button variant="outline" size="xs" onClick={ showLess } className={ classes.showLessButton }>Show less</Button>
        </Flex>
      </Box> }
      <Flex direction="column" className={ classes.notificationContainer } ref={ containerRef } onScroll={ handleContainerScroll }>
        { notifications.map((notification, index) => (
          <ToastNotificationComponent
            notification={ notification }
            key={ index }
            indexByNotificationId={ calculateVisibleIndex }
            isCollapsed={ isCollapsed }
            showMore={ showMore }
            totalVisibleNotifications={ totalVisibleNotifications }
            onClose={ onNotificationClose }
            onCloseAll={ closeAllNotifications }
          />
        )) }
      </Flex>
    </Flex>
  );
};

export default ToastNotificationContainerComponent;
