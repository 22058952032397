import { Switch } from "@mantine/core";
import React, { FC } from "react";

import { ReactComponent as DataMinr } from "@/common/icons/RiskProviders/max-security.svg";
import {
  MAX_SECURITY_RISK_CATEGORIES
} from '@/tenant-context/control-risk-config/components/MaxSecurityEventsSettings/MaxSecurityEventsSettings.config';
import {
  useMaxSecurityEventsSettingsStyles
} from '@/tenant-context/control-risk-config/components/MaxSecurityEventsSettings/MaxSecurityEventsSettings.styles';

type Props = {
  onCategoryToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeGlobalCategoryToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isGlobalCategoryToggleOn: boolean;
  disabledRiskFilterTypes: string[];
}

const MaxSecurityEventsSettingsComponent: FC<Props> = ({
  isGlobalCategoryToggleOn,
  disabledRiskFilterTypes,
  onCategoryToggle,
  onChangeGlobalCategoryToggle
}) => {

  const { classes } = useMaxSecurityEventsSettingsStyles();

  return (
    <div className={ classes.riskEventSettingsContainer }>
      <div className={ classes.riskLineRiskAlertSwitchContent }>
        <div className={ classes.riskLineRiskAlertIconNDescription }>
          <DataMinr className={ classes.risklineIcon }/>
          <p className={ classes.riskLineRiskAlertDescription }>Max security</p>
        </div>

        <Switch
          onChange = { onChangeGlobalCategoryToggle }
          checked={ isGlobalCategoryToggleOn }
        />
      </div>

      <h1 className={ classes.sectionHeading }>Filter by Risk Category</h1>

      <ul className={ classes.orderList }>
        { MAX_SECURITY_RISK_CATEGORIES.map(({
          id, name, Icon
        }) => (
          <li
            key={ id }
            className={ classes.listContent }
          >
            <div className={ classes.riskLineRiskAlertIconNDescription }>
              <Icon/>
              <p className={ classes.riskCategoryType }>{ name }</p>
            </div>

            <Switch
              id={ `risk-category-switch-${id}` }
              checked = { !disabledRiskFilterTypes.includes(id.toString()) }
              onChange={ onCategoryToggle } value={ id }/>
          </li>
        )) }
      </ul>
    </div>
  );
};

export default MaxSecurityEventsSettingsComponent;
