/* eslint-disable import/extensions */
import { FC } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import PlatformContext from "@/platform-context/core/components/PlatformContext/PlatformContext.component";
import TenantContext from "@/tenant-context/core/components/TenantContext/TenantContext.component";

import { CONTEXT_TYPES } from '../../store/shared-data/sharedData.types';

const ContextProviders: FC = ({ children }) => {
  const context = useSelector((state: RootState) => state.sharedData.currentContext);

  if(context === CONTEXT_TYPES.tenant){
    return <TenantContext>
      { children }
    </TenantContext>;
  }

  return <PlatformContext>
    { children }
  </PlatformContext> ;
  
};

export default ContextProviders;
