
import { showNotification } from "@/common/util/notification";

import { ACCESS_DENIED_STATUS, SERVER_ERROR_STATUS_UPPER_BOUNDARY, TOKEN_EXPIRED_STATUS, UNPROCESSABLE_ENTITY_STATUS } from "./api";

export function handleError(err: Error): false {

  const handledNotifications = [SERVER_ERROR_STATUS_UPPER_BOUNDARY, TOKEN_EXPIRED_STATUS,
    ACCESS_DENIED_STATUS, UNPROCESSABLE_ENTITY_STATUS];

  if(err.cause){
    if(!handledNotifications.includes(Number(err.cause))){
      showNotification({
        message: err.message,
        color: 'error'
      });
    }
  }
 
  return false;
}

export function handleSuccess(message = 'Success'): false {
  showNotification({
    message: message,
    color: 'success'
  });

  return false;
}

export const removeRef = (refValue: string): string => {
  return refValue?.split('ref:').pop() as string;
};


export const ensureHTTP = (url: string): string => {
  // eslint-disable-next-line fp/no-let
  let ensuredURL = "";
  if (!/^https?:\/\//i.test(url)) {
    ensuredURL = 'http://' + url;
  }

  return ensuredURL;
};
