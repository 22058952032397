import { createStyles, MantineTheme } from "@mantine/core";

type Params = {
  width?: string,
  height?: string,
  borderRadius?:string
}

export const usePersonAvatarStyles = createStyles((theme: MantineTheme,  params?: Params) => ({
  avatarContainer:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',

    width:params?.width ? params.width : '30px',
    height:params?.height ? params.height:'30px',

    borderRadius: params?.borderRadius ? params.borderRadius : '15px',
    backgroundColor:theme.white,
    color:theme.colors.blue[6],
    position: 'relative'
  },

  border: {
    width: '100%',
    height: '100%',
    borderRadius: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    border: `1px solid ${theme.colors.blue[6]}`
  },

  nameText:{
    fontWeight: theme.other.fw.bold,
    fontSize: params?.height ? `calc(${params.height} / 2 - 3px)` : undefined,
    lineHeight: 1
  },

  personImage:{
    objectFit: 'cover',
    width: params?.width ? params.width : '30px',
    height: params?.height ? params.height:'30px',
    borderRadius:'40px'
  }
}));
