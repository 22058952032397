import { TabsValue } from "@mantine/core";
import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import CreateCommsPageComponent from "@/tenant-context/control-mass-comms/components/CreateCommsPage/CreateCommsPage.component";
import { DeliveryItems } from "@/tenant-context/control-mass-comms/types/MassComms.types";

type Props = {
  isInTemplateCreationFlow?: boolean
}

const CreateCommsPage: FC<Props> = ({
  isInTemplateCreationFlow = false
}) => {

  const {
    massComms: {
      SET_COMMS_NAME,
      SET_TEMPLATE_ITEMS,
      getCommunicationSequence,
      handleTabSelection,
      SET_SELECTED_CONFIG_TAB,
      handlePrePopulationOnDeliveryChannelChange
    }
  } = useDispatch<Dispatch>();

  const {
    deliveryChannels,
    templatesList,
    communicationName,
    selectedResponseMethod,
    communicationSequenceNo,
    selectedConfigTab
  } = useSelector((state: RootState) => state.massComms);

  const alertSequenceNumber = useSelector((state: RootState) => state.massComms?.alertSequenceNumber);

  // This useEffect only runs when the user navigates to the create comms page from the summary page to set the default
  // tab to the one that was selected in the summary page. In here we don't need selectedConfigTab to be a dependency.
  // Adding that will cause an infinite loop.
  useEffect(() => {
    handleTabSelection(selectedConfigTab.fromSummary);
    if (selectedConfigTab.fromSummary) {
      SET_SELECTED_CONFIG_TAB({
        ...selectedConfigTab,
        fromSummary: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SET_SELECTED_CONFIG_TAB, deliveryChannels, handleTabSelection]);

  const handleDeliveryChannelChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | React.ChangeEvent<HTMLInputElement>) => {
      const channel: DeliveryItems = event.currentTarget.name as DeliveryItems;
      const deliveryItems = {
        ...deliveryChannels,
        [channel]: !deliveryChannels[channel]
      };

      if (!deliveryChannels[channel]) {
        SET_SELECTED_CONFIG_TAB({ ...selectedConfigTab, defaultTab: channel });
      }

      handlePrePopulationOnDeliveryChannelChange(deliveryItems);
    },
    [SET_SELECTED_CONFIG_TAB, deliveryChannels, handlePrePopulationOnDeliveryChannelChange, selectedConfigTab]
  );

  const handleCommunicationNameChange = useCallback(
    (data: React.ChangeEvent<HTMLInputElement>) => {
      if (isInTemplateCreationFlow) {
        SET_COMMS_NAME(data.currentTarget.value);
        return;
      }
      if (data.currentTarget.value === '') {
        SET_COMMS_NAME('');
        return;
      }
      SET_COMMS_NAME(`${communicationSequenceNo}-${data.currentTarget.value}`);
    },
    [ SET_COMMS_NAME, communicationSequenceNo, isInTemplateCreationFlow ]
  );
  const handleSelectedTabChange = useCallback(
    (value: TabsValue) => {
      SET_SELECTED_CONFIG_TAB({ ...selectedConfigTab, defaultTab: value as DeliveryItems });
    },
    [ SET_SELECTED_CONFIG_TAB, selectedConfigTab ]
  );

  useEffect(() => {
    if (communicationSequenceNo === null && !isInTemplateCreationFlow) {
      getCommunicationSequence();
    }
  }, [ SET_TEMPLATE_ITEMS, communicationSequenceNo, getCommunicationSequence, isInTemplateCreationFlow ]);

  return (
    <CreateCommsPageComponent
      deliveryChannels={ deliveryChannels }
      onDeliveryChannelChanged={ handleDeliveryChannelChange }
      templateItems={ templatesList }
      communicationName={ communicationName }
      onCommunicationNameChanged={ handleCommunicationNameChange }
      defaultResponseMethod={ selectedResponseMethod }
      selectedTab={ selectedConfigTab.defaultTab }
      onSelectedTabChange={ handleSelectedTabChange }
      commSequenceNo={ communicationSequenceNo }
      alertSequenceNo={ alertSequenceNumber }
      isInTemplateCreationFlow={ isInTemplateCreationFlow }
    />
  );
};

export default CreateCommsPage;
