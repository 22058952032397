import { Button, TextInput } from "@mantine/core";
import { FC, useContext } from "react";
import { useSelector } from "react-redux";

import ProtectedFragment from "@/common/components/ProtectedFragment/ProtectedFragment.component";
import { RootState, RootStateWithLoading } from "@/core/store";
import ArcSection from "@/tenant-context/control-action-response-center/components/ArcSection/ArcSection.component";
import ProfileLoadingOverlayComponent from "@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay/ProfileLoadingOverlay.component";
import { EaArcPoliciesConfig } from "@/tenant-context/employee-app-action-response-center/config/permissions";

import { PlaybookContext } from "./Playbook.context";
import { usePlaybookStyles } from "./Playbook.style";

const Playbook: FC = () => {
  const { classes, cx } = usePlaybookStyles();
  const actions = useSelector((state: RootState) => state.playbook.actions);
  const isLoading = useSelector((state: RootStateWithLoading) => state.loading.models.playbook);

  const { handleNewActionInput, handleSaveClick, newAction } = useContext(PlaybookContext);

  return (
    <ArcSection title='Playbook'>
      <ul>
        { actions.map((action, i) => (
          <li className={ classes.li } key={ action.id }>
            <span className={ classes.ord }>{ i + 1 }</span>

            <span className={ classes.title }>{ action.action }</span>
          </li>
        )) }

        <ProtectedFragment requiredPolicies={ EaArcPoliciesConfig.EDIT_PLAYBOOK }>
          <li className={ cx(classes.li, classes.newActionLi) }>
            <span className={ classes.ord }>{ actions.length + 1 }</span>

            <TextInput
              onChange={ handleNewActionInput }
              className={ classes.input }
              value={ newAction }
            />

            <Button
              size="sm"
              onClick={ handleSaveClick }
              className={ classes.saveBtn }
            >
              Save Action
            </Button>
          </li>
        </ProtectedFragment>
      </ul>

      <ProfileLoadingOverlayComponent isLoading={ isLoading } />
    </ArcSection>
  );
};

export default Playbook;
