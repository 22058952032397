import { Tabs } from "@mantine/core";
import { FC, useContext } from "react";

import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";
import { ReactComponent as ImpactIcon } from '@/tenant-context/control-action-response-center/icons/arc-header/impactIcon.svg';
import { ReactComponent as SummaryIcon } from '@/tenant-context/control-action-response-center/icons/arc-header/summaryIcon.svg';

import { useArcHeaderStyles } from "../ArcHeader.style";

const ArcHeaderTabs: FC = () => {
  const { activeTab, setActiveTab } = useContext(ArcContext);
  const { classes } = useArcHeaderStyles();

  return (
    <Tabs className={ classes.arcTabs } defaultValue="summary"  onTabChange={ setActiveTab } value={ activeTab }>
      <Tabs.List>
        <Tabs.Tab value="summary" icon={ <SummaryIcon /> } >Summary</Tabs.Tab>
        <Tabs.Tab value="impact"  icon={ <ImpactIcon /> }>Impact</Tabs.Tab>
        { /* <Tabs.Tab value="actions" >Actions</Tabs.Tab>
        <Tabs.Tab value="notifications" >Notifications</Tabs.Tab>
        <Tabs.Tab value="audit" >Audit</Tabs.Tab> */ }
      </Tabs.List>
    </Tabs>
  );
};

export default ArcHeaderTabs;
