import { callApi } from "@/common/util/api";
import createServerSentEventStream, { SubscriptionEventHandler } from "@/common/util/sse";
import { generateSearchForRecipientsQuery, mcReportsStreamRetryValidation } from "@/tenant-context/control-mass-comms/utils";

import {
  AddOrUpdateRecipientsRequest, AlertSequenceNumber,
  CommSequenceNoTypes,
  CommunicationConfigRequest,
  CommunicationTemplate,
  DeliverySuccessStream,
  EmailStylingContent,
  EmailStylingTemplate,
  GroupProfilesResponseType,
  MassCommsReportsRecipientsResponse,
  OverallDeliverySuccessStream,
  PollResponsesStream,
  SaveOrUpdateEmailStyling,
  SearchPeopleResponseTypes,
  SentCommsList,
  SentCommunications, SmsNavResponsesData
} from '../types/MassComms.types';

export const postCommunicationsConfig = (
  reqObj: CommunicationConfigRequest
) => callApi<{ tid: string }>(
  `notification-service/v1/${reqObj.tenantId}/communications`,
  {
    method: 'post',
    body: JSON.stringify(reqObj)
  }
);

export const getPeopleSearchResults = (
  searchCriteria: string,
  shouldGetAppInstallationStatus?: boolean
) => callApi<SearchPeopleResponseTypes>(
  `/managed/platform-service/v1/person-profiles?page=0&size=20&${ generateSearchForRecipientsQuery(searchCriteria) }` +
    `&appInstallationStatus=${ shouldGetAppInstallationStatus || false }&active=true`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=ListCompositePersonProfileV2'
    },
    method: 'get'
  }
);

export const addOrUpdateRecipients = (
  tenantId: string,
  requestObj: AddOrUpdateRecipientsRequest
) => callApi<void>(
  `notification-service/v1/${ tenantId }/communications/${ encodeURIComponent(requestObj.communicationName) }/recipients`,
  {
    method: 'post',
    body: JSON.stringify(requestObj)
  }
);

export const sendCommunication = (
  tenantId: string,
  communicationName: string
) => callApi<void>(
  `/notification-service/v1/${ tenantId }/send`,
  {
    method: 'post',
    body: JSON.stringify({
      communicationName
    }),
    responseType: 'void'
  }
);

export const getCommunicationSequence = (
  tenantId: string
) => callApi<CommSequenceNoTypes>(
  `/notification-service/v1/${ tenantId }/next-communication-number`,
  {
    method: 'get'
  }
);

export const getEmailStylingTemplate = (
  tenantId: string
) => callApi<EmailStylingTemplate>(
  `/notification-service/v1/${ tenantId }/content/common-content`,
  {
    method: 'get'
  }
);

export const getEmailStylingContent = (
  tenantId: string
) => callApi<EmailStylingContent>(
  `/notification-service/v1/${ tenantId }/style-content/common-style-content`,
  {
    method: 'get'
  }
);

export const saveEmailStylingContent = (
  tenantId: string,
  requestObj: SaveOrUpdateEmailStyling
) => callApi<EmailStylingContent>(
  `/notification-service/v1/${ tenantId }/style-content/common-style-content`,
  {
    method: 'post',
    body: JSON.stringify(requestObj)
  }
);

export const getGroupsSearchResults = (searchCriteria: string) => callApi<GroupProfilesResponseType>(
  `/managed/platform-service/v1/people-groups`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=ListPeopleGroup'
    },
    method: 'get',
    queryParams: { search: `(name LIKE '%${searchCriteria}%')` }
  }
);

export const getProfilesPerGroup = (groupId: string) => callApi<SearchPeopleResponseTypes>(
  `/managed/platform-service/v1/person-profiles?peopleGroupTidToFilter=${groupId}:true&size=1000`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=ListCompositePersonProfile'
    },
    method: 'get'
  }
);

export const getTemplateByTid = (
  tenantId: string,
  templateTid: string
) => callApi<CommunicationTemplate>(
  `/notification-service/v1/${ tenantId }/templates/${ templateTid }`,
  {
    method: 'get'
  }
);

export const createNewTemplate = (
  tenantId: string,
  template: CommunicationTemplate
) => callApi<{ tid: string }>(
  `/notification-service/v1/${ tenantId }/templates`,
  {
    method: 'post',
    body: JSON.stringify(template)
  }
);

export const getAllTemplates = (
  tenantId: string
) => callApi<Array<CommunicationTemplate>>(
  `/notification-service/v1/${ tenantId }/templates?pageNum=0&pageSize=999&orderBy=DESC&mergeNonTenant=false`,
  {
    method: 'get'
  }
);

export const deleteTemplate = (
  tenantId: string,
  templateId: string
) => callApi(
  `/notification-service/v1/${ tenantId }/templates/${ templateId }`,
  {
    method: 'delete'
  }
);

export const getAlertSequenceNumber = (
  alertId: string,
  tenantId: string
) => callApi<AlertSequenceNumber>(
  `/notification-service/v1/${ tenantId }/${ alertId }/next-alert-sequence-number`,
  {
    method: 'get'
  }
);

export const getCommunicationsByName = (
  tid: string,
  tenantId: string
) => callApi <CommunicationConfigRequest>(
  `/notification-service/v1/${tenantId}/communications/${tid}`,
  {
    method: 'get'
  }
);

export const getCommunicationsList = (
  tenantId: string
) => callApi<Array<SentCommsList>>(
  `notification-service/v1/${tenantId}/communications/?pageNum=0&pageSize=50&orderBy=_updatedTime:DESC`,
  {
    method: 'get'
  }
);

export const getSentCommunicationList = (
  tenantId: string,
  pageConfig: { pageNum: number, pageSize: number },
  query: string
) => callApi<Array<SentCommunications>>(
  `notification-service/v1/${tenantId}/communications/communication-summery?pageNum=${pageConfig.pageNum}&pageSize=${pageConfig.pageSize}&orderBy=_createdTime:DESC&query=${query}`,
  {
    method: 'get'
  }
);

export const subscribeToMassCommsReportsRecipientUpdates = (
  tenantId: string,
  communicationId: string,
  handleEvent: SubscriptionEventHandler<MassCommsReportsRecipientsResponse>
) => createServerSentEventStream(
  `notification-service/v1/${ tenantId }/stream/communications/${ communicationId }/delivery-and-response-status`,
  handleEvent
);

export const createDeliverySuccessStream = (
  tenantId: string,
  tid: string,
  handleEvent: SubscriptionEventHandler<DeliverySuccessStream>
) => createServerSentEventStream <DeliverySuccessStream>(
  `notification-service/v1/${tenantId}/stream/communications/${tid}/delivery-break-down`,
  handleEvent,
  {
    retryValidation: mcReportsStreamRetryValidation,
    retryInterval: 5000
  }
);

export const createOverallDeliveryStream = (
  tenantId: string,
  tid: string,
  handleEvent: SubscriptionEventHandler<OverallDeliverySuccessStream>
) => createServerSentEventStream<OverallDeliverySuccessStream>(
  `notification-service/v1/${tenantId}/stream/communications/${tid}/overall-delivery`,
  handleEvent,
  {
    retryValidation: mcReportsStreamRetryValidation,
    retryInterval: 5000
  }
);

export const createPollResponsesStream = (
  tenantId: string,
  tid: string,
  handleEvent: SubscriptionEventHandler<PollResponsesStream>
) => createServerSentEventStream<PollResponsesStream>(
  `notification-service/v1/${tenantId}/stream/communications/${tid}/poll-response-break-down`,
  handleEvent,
  {
    retryValidation: mcReportsStreamRetryValidation,
    retryInterval: 5000
  }
);

export const respondToMassCommsEmail = (tenantId: string, refId: string, response: string) => callApi<void>(
  `notification-service/v1/${tenantId}/user-response?reference=${refId}&response=${response}`,
  {
    method: 'get'
  }
);

export const getSmsCommunicationData = (referenceId: string, tenantId: string) => callApi<SmsNavResponsesData>(
  `notification-service/v1/${referenceId}/sms-rd/${tenantId}`,
  {
    method: 'get'
  }
);

export const respondToMassCommsSms = (tenantId: string, refId: string, response: string) => callApi<void>(
  `notification-service/v1/${tenantId}/user-response?reference=${refId}&response=${response}`,
  {
    method: 'get'
  }
);
