import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TestableComponent } from "@/common/types/testable-component";
import { Dispatch, RootState } from "@/core/store";

import AddToExistingGroup from './AddToExistingGroup.component';

const AddToExistingGroupContainer: FC<TestableComponent> = ({ dataTestId }) => {
  const groups = useSelector((state: RootState) => state.groups.groups);

  const {
    groups: {
      loadAllGroups
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    loadAllGroups({ gridParams: { page: 0, size: 10 } });
  }, [loadAllGroups]);

  return (
    <AddToExistingGroup
      dataTestId={ dataTestId }
      groups={ groups }
    />
  );
};

export default AddToExistingGroupContainer;