import { CellClickedEvent } from "ag-grid-community";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { PickedPeopleEntity } from "@/tenant-context/control-mass-comms/types/MassComms.types";

import RecipientsListViewComponent from "./RecipientsListView.component";

const RecipientsListView: FC = () => {

  const {
    massComms: {
      selectedRecipients,
      deliveryChannels,
      receiveCountByCommunicationChannel,
      selectedRecipientFilterPhrase
    }
  } = useSelector((state: RootState) => state);

  const {
    massComms: {
      removeSelectedRecipient,
      SET_SELECTED_RECIPIENTS_FILTER_PHRASE
    }
  } = useDispatch<Dispatch>();

  const handlePersonRemoveRequest = useCallback((event: CellClickedEvent<PickedPeopleEntity>) => {
    if (!event.data?.profileId) {
      return;
    }

    removeSelectedRecipient(event.data.profileId);
  }, [ removeSelectedRecipient ]);

  const handleFilterPhraseChange = useCallback((value: string) => {
    SET_SELECTED_RECIPIENTS_FILTER_PHRASE(value);
  }, [SET_SELECTED_RECIPIENTS_FILTER_PHRASE]);

  const isExternalFilterPresent = useCallback(() => {
    return selectedRecipientFilterPhrase != null;
  }, [selectedRecipientFilterPhrase]);

  const doesExternalFilterPass = useCallback(
    (node) => {
      if (node.data) {
        const name = `${node.data?.firstName} ${node.data?.lastName}`;
        return name.toLocaleLowerCase().includes(selectedRecipientFilterPhrase.toLocaleLowerCase());
      }

      return true;
    },
    [selectedRecipientFilterPhrase]
  );

  return (
    <RecipientsListViewComponent
      selectedPeople={ selectedRecipients }
      deliveryChannels={ deliveryChannels }
      onPersonRemoveRequested={ handlePersonRemoveRequest }
      receiveCountByCommunicationChannel={ receiveCountByCommunicationChannel }
      isExternalFilterPresent={ isExternalFilterPresent }
      doesExternalFilterPass={ doesExternalFilterPass }
      onFilterPhraseChange={ handleFilterPhraseChange }
      selectedRecipientFilterPhrase = { selectedRecipientFilterPhrase }
    />
  );
};

export default RecipientsListView;
