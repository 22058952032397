import { createStyles } from "@mantine/core";

type Props = {
  hasData: boolean
  searchInputPosition?: 'left' | 'right',
  alignFilters?: 'flex-end' | 'flex-start'
  wrapFilters?: boolean
}

export const useServerDataListStyles = createStyles((
  theme,
  { searchInputPosition, hasData, alignFilters, wrapFilters }: Props
) => ({
  gridHeader:{
    display: 'flex',
    alignItems: alignFilters,
    justifyContent: 'space-between',
    marginBottom: 24,
    flexWrap: wrapFilters ? 'wrap' : 'unset'
  },

  searchWrap: {
    '& > div':{
      marginBottom: 0,
      marginRight: 24
    }
  },

  searchInput: {
    width: 450,
    marginLeft: searchInputPosition === 'right' ? 'auto' : 'unset',
    '& svg > path': {
      fill: theme.colors.neutral[4]
    }
  },

  sortingDropdown: {
    marginBottom: 0
  },

  clearSearch:{
    cursor: 'pointer'
  },

  headerWrap:{
    flexWrap: wrapFilters ? 'wrap' : 'unset',
    width: wrapFilters ? '100%' : 'inherit'
  },

  headerEnd: {
    display: 'flex',
    gap: 16
  },

  flex: {
    flex: 1
  },

  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 35
  },

  row: {
    backgroundColor: theme.colors.neutral[8],
    padding: 16,
    borderRadius: 8
  }
}));
