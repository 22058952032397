import { FC, useContext } from 'react';

import useDrawer from '@/common/hooks/useDrawer';
import GeofenceMapStatsCardsComponent
  from '@/tenant-context/control-draw/components/GeofenceMapStatsCards/GeofenceMapStatsCards.component';
import { GeofenceContext } from '@/tenant-context/control-draw/context/Geofence.context';

export const GeofenceMapStatsCardsContainer: FC = () => {
  const {
    insideGeofencePeopleCount,
    insideGeofenceLocationsCount,
    insideGeofenceSitesCount
  } = useContext(GeofenceContext);

  const { isOpen } = useDrawer('create-geofence', 'left');

  return (
    <GeofenceMapStatsCardsComponent
      peopleCount={ insideGeofencePeopleCount }
      locationsCount={ insideGeofenceLocationsCount }
      sitesCount={ insideGeofenceSitesCount }
      isHidden={ !isOpen }
    />
  );
};

export default GeofenceMapStatsCardsContainer;
