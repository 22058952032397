import { FC, useCallback } from "react";
import { useNavigate } from 'react-router';

import usePermission from '@/common/hooks/usePermission';
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { ReactComponent as LookupToolIcon } from '@/common/icons/lookup/init-icon.svg';
import { TestableComponent } from "@/common/types/testable-component";
import { RouterConfig } from '@/core/components/Router/Router.config';
import { LookupPoliciesConfig } from '@/tenant-context/control-lookup-tool/configs/Lookup.policies';
import { useLookupInitToolStyles } from '@/tenant-context/widget-overview/components/LookupInitTool/LookupInitTool.styles';

const LookupInitTool: FC<TestableComponent> = ({ dataTestId = "top-menu-lookup-button" }) => {

  const { classes } = useLookupInitToolStyles();
  const navigate = useNavigate();
  const isAuthorized = usePermission(LookupPoliciesConfig.LOOKUP_ASSERT_RANKING_READONLY);
  const handleUnauthorizedClick = useUnauthorizedModal();

  const navigateToLookupTool = useCallback(() => {
    navigate(RouterConfig.routes.lookupTool);
  }, [navigate]);

  return (
    <button
      className={ isAuthorized ? classes.root : classes.rootDisabled }
      onClick={ isAuthorized ? navigateToLookupTool : handleUnauthorizedClick }
      data-testid={ dataTestId }
    >
      <LookupToolIcon/>
    </button>
  );
};

export default LookupInitTool;
