import { closeAllModals } from "@mantine/modals";
import { createContext, FC } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import { formatDateToString } from "@/common/util/format/date";
import { Dispatch } from "@/core/store";
import {
  PersonalDetailsModalFormData
} from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/PersonalDetailsModal/PersonalDetailsModal.component";

type PersonalDetailsModalContextType = {
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>,
  formControls: UseFormReturn
};

export const PersonalDetailsModalContext = createContext<PersonalDetailsModalContextType>(
  {} as PersonalDetailsModalContextType
);

export const PersonalDetailsModalContextProvider: FC = ({
  children
}) => {
  const formControls = useForm({
    mode: "onChange"
  });

  const {
    profile: {
      updateGeneral
    }
  } = useDispatch<Dispatch>();

  const handleSubmit = async (untypedData: unknown) => {
    const data = untypedData as PersonalDetailsModalFormData;

    const {
      dateOfBirth
    } = data;

    // eslint-disable-next-line fp/no-let
    let formattedDateOfBirth = "";
    if(dateOfBirth && typeof dateOfBirth === 'object'){
      formattedDateOfBirth = formatDateToString(dateOfBirth);
    }

    const success = await updateGeneral({
      ...data,
      dateOfBirth: formattedDateOfBirth
    });

    if(success){
      closeAllModals();
    }    
  };

  const onSubmit = formControls.handleSubmit(handleSubmit);

  return (
    <PersonalDetailsModalContext.Provider value={ useContextValue({
      onSubmit,
      formControls
    }) }>
      { children }
    </PersonalDetailsModalContext.Provider>
  );
};
