import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useImpactedSummaryPieChartStyles = createStyles((theme: MantineTheme) => ({
  count: {
    fontSize: 36,
    fill: theme.white,
    fontWeight: theme.other.fw.bold
  },
  peopleText: {
    fontSize: theme.fontSizes.md,
    fill: theme.colors.neutral[5]
  }
}));
