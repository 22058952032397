import { default as turfCircle } from '@turf/circle';
import { Feature, GeoJsonProperties, Polygon } from 'geojson';

export const drawRiskImpactCircle = (
  circleCenter: number [],
  radius:number,
  noOfSteps:number
):  Feature<Polygon, GeoJsonProperties> => {
  const _options = {    
    steps:  noOfSteps 
  };
  // drawing the circle for given center point and radius
  const _circle = turfCircle(circleCenter, radius, _options);
  return _circle;
};
