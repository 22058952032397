import { ActionIcon, Flex, Tabs } from "@mantine/core";
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DrawerControl from '@/common/components/DrawerControl';
import { DrawerControlButtonRenderer } from '@/common/components/DrawerControl/DrawerControl.component';
import usePermission from "@/common/hooks/usePermission";
import { ReactComponent as ChevronDown } from "@/common/icons/chevron-down.svg";
import { Dispatch, RootState } from "@/core/store";
import LiveFeedNotification from "@/tenant-context/control-live-feed/components/LiveFeedNotification";
import ToastNotificationContainer from '@/tenant-context/control-live-feed/components/ToastNotificationContainer';
import { LiveFeedPolicies } from "@/tenant-context/control-live-feed/configs/LiveFeed.policies";
import {
  closedNotificationsFilter,
  olderNotificationsFilter,
  recentNotificationsFilter,
  upcomingNotificationsFilter
} from '@/tenant-context/control-live-feed/util/live-feed';

import { LiveFeedCasesTab, NotificationContainerLayoutParams } from "../../types/live-feed";
import LiveFeedButtonComponent from "./LiveFeedButton.component";
import { useLiveFeedButtonStyles } from "./LiveFeedButton.styles";

const LiveFeedButton: FC = () => {

  const [displayCount, setDisplayCount] = useState<number>(10);
  const [showChevron, setShowChevron] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<LiveFeedCasesTab>(LiveFeedCasesTab.Recent);
  const [ notificationContainerLayout, setNotificationContainerLayout ] =
    useState<NotificationContainerLayoutParams>({ rightBorderToWindowGap: 0 });

  const {
    liveNotificationList: liveNotifications,
    allNotificationList: allNotifications,
    isLoadNotificationsListCalled
  } = useSelector((state: RootState) => state.liveFeed);

  const {
    liveFeed: {
      loadNotificationsList,
      subscribeToLiveFeedNotifications,
      SET_ALL_ALERTS_FEED_OPEN,
      SET_IS_LOAD_NOTIFICATIONS_LIST_CALLED
    }
  } = useDispatch<Dispatch>();

  const upcomingNotifications = useMemo(() => {
    return allNotifications.filter(upcomingNotificationsFilter);
  }, [ allNotifications ]);

  const recentNotifications = useMemo(() => {
    return allNotifications.filter(recentNotificationsFilter);
  }, [ allNotifications ]);

  const olderNotifications = useMemo(() => {
    return allNotifications.filter(olderNotificationsFilter);
  }, [ allNotifications ]);

  const closedNotifications = useMemo(() => {
    return allNotifications.filter(closedNotificationsFilter);
  }, [ allNotifications ]);

  const totalVisibleNotifications = useMemo(() => {
    return liveNotifications.filter(({ isClosedInDisplayStack }) => !isClosedInDisplayStack).length;
  }, [ liveNotifications ]);

  const isLiveFeedPermissionAvailable = usePermission(LiveFeedPolicies.LIVE_FEED_POLICY);

  const allFeedContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>): void => {
    const target = e.target as HTMLDivElement;
    const bottom =
      target.scrollHeight - (Math.floor(target.scrollTop) + 1) === target.clientHeight;

    if (displayCount >= allNotifications.length) {
      return; 
    }

    if (bottom) {
      setDisplayCount((prevCount) => prevCount + 10);
    }
  }, [displayCount, allNotifications.length]);

  const handleOpenFeedDrawer = useCallback(
    () => {
      if (!isLoadNotificationsListCalled) {
        SET_IS_LOAD_NOTIFICATIONS_LIST_CALLED(true);
        loadNotificationsList();
      }
      
      SET_ALL_ALERTS_FEED_OPEN(true);
    },
    [
      loadNotificationsList,
      SET_ALL_ALERTS_FEED_OPEN, 
      isLoadNotificationsListCalled,
      SET_IS_LOAD_NOTIFICATIONS_LIST_CALLED
    ]
  );

  const handleClosefeedDrawer = useCallback(
    () => {
      SET_ALL_ALERTS_FEED_OPEN(false);
    },
    [SET_ALL_ALERTS_FEED_OPEN]
  );

  const handleScrollToBottom = useCallback(() => {
    setDisplayCount((prevCount) => prevCount + 10);
    const element = allFeedContainerRef.current;
    if (element) {
      element.lastElementChild?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleTabChange = useCallback((tab: LiveFeedCasesTab | null) => {
    if (!tab) {
      return;
    }

    setDisplayCount(10);

    setActiveTab(tab as LiveFeedCasesTab);
  }, []);

  useEffect(() => {
    if (activeTab === LiveFeedCasesTab.Upcoming) {
      if (upcomingNotifications.length > displayCount) {
        setShowChevron(true);
      } else {
        setShowChevron(false);
      }
    }

    if (activeTab === LiveFeedCasesTab.Recent) {
      if (recentNotifications.length > displayCount) {
        setShowChevron(true);
      } else {
        setShowChevron(false);
      }
    }

    if (activeTab === LiveFeedCasesTab.Older) {
      if (olderNotifications.length > displayCount) {
        setShowChevron(true);
      } else {
        setShowChevron(false);
      }
    }

    if (activeTab === LiveFeedCasesTab.Closed) {
      if (closedNotifications.length > displayCount) {
        setShowChevron(true);
      } else {
        setShowChevron(false);
      }
    }
  }, [
    displayCount,
    activeTab,
    closedNotifications.length,
    upcomingNotifications.length,
    recentNotifications.length,
    olderNotifications.length
  ]);

  const { classes } = useLiveFeedButtonStyles({});

  useEffect(() => {
    if (!isLiveFeedPermissionAvailable) {
      return;
    }

    subscribeToLiveFeedNotifications();
  }, [subscribeToLiveFeedNotifications, isLiveFeedPermissionAvailable]);


  const notifications = useMemo<ReactNode>(() => {
    if (activeTab === LiveFeedCasesTab.Upcoming) {
      return upcomingNotifications.slice(0, displayCount).map((notification) => (
        <LiveFeedNotification key={ notification.notificationId } notification={ notification } isFromRight />
      ));
    }

    if (activeTab === LiveFeedCasesTab.Recent) {
      return recentNotifications.slice(0, displayCount).map((notification) => (
        <LiveFeedNotification key={ notification.notificationId } notification={ notification } isFromRight />
      ));
    }

    if (activeTab === LiveFeedCasesTab.Older) {
      return olderNotifications.slice(0, displayCount).map((notification) => (
        <LiveFeedNotification key={ notification.notificationId } notification={ notification } isFromRight />
      ));
    }

    if (activeTab === LiveFeedCasesTab.Closed) {
      return closedNotifications.slice(0, displayCount).map((notification) => (
        <LiveFeedNotification key={ notification.notificationId } notification={ notification } isFromRight />
      ));
    }

  }, [activeTab, upcomingNotifications, displayCount, recentNotifications, olderNotifications, closedNotifications]);

  const renderDrawerButton: DrawerControlButtonRenderer = useCallback(({ onClick: openDrawer }) => {
    const onClick = () => {
      handleOpenFeedDrawer();
      openDrawer();
    };

    return <LiveFeedButtonComponent
      liveFeedCount={ totalVisibleNotifications }
      // jsx-no-bind disabled for next line, because a callback can not be used in this scope
      // eslint-disable-next-line react/jsx-no-bind
      handleOpenFeedDrawer={ onClick }
      onLayoutChange={ setNotificationContainerLayout }
    />;
  }, [handleOpenFeedDrawer, totalVisibleNotifications]);

  return (
    <div className={ classes.alertMonitorContainer }>

      <DrawerControl
        id={ 'event-feed' }
        renderButton={ renderDrawerButton }
        title={ 'Risk Events and Alarms' }
        explainer={ 'Emergency Alarms and threats impacting people and assets.' }
        onCloseClick={ handleClosefeedDrawer }
      >
        <Flex direction={ 'column' } className={ classes.allFeedContainer }>
          <Tabs onTabChange={ handleTabChange } value={ activeTab }>
            <Tabs.List>
              <Tabs.Tab value="Upcoming">Coming</Tabs.Tab>
              <Tabs.Tab value="Recent">Last 24h</Tabs.Tab>
              <Tabs.Tab value="Older">Older</Tabs.Tab>
              <Tabs.Tab value="Closed">Closed</Tabs.Tab>
            </Tabs.List>
          </Tabs>
          <Flex direction="column" className={ classes.notificationsContainer } onScroll={ handleScroll } ref={ allFeedContainerRef }>
            { notifications }
            { showChevron && <div className={ classes.chevronContainer }>
              <ActionIcon onClick={ handleScrollToBottom } variant={ 'subtle' }>
                <ChevronDown className={ classes.chevron } />
              </ActionIcon>
            </div> }
          </Flex>
        </Flex>

      </DrawerControl>

      <ToastNotificationContainer notifications={ liveNotifications } layoutParams={ notificationContainerLayout }/>
    </div>
  );
};

export default LiveFeedButton;
