import { FileWithPath } from "@mantine/dropzone";
import { FC, useCallback, useState } from "react";
import { FileRejection } from "react-dropzone";
import { useDispatch } from "react-redux";

import Dropzone from "@/common/components/Dropzone";
import DropzoneFiles from "@/common/components/DropzoneFiles";
import { logger } from "@/common/util/ConsoleLogger";
import { Dispatch } from "@/core/store";
import { ProfileDocumentFile } from "@/tenant-context/control-profile/types/profile";

const UploadAudioMessageComponent: FC = () => {

  const {
    massComms: {
      uploadVoiceAttachment
    }
  } = useDispatch<Dispatch>();

  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);

  const onNewAttachmentSelected = useCallback((files: FileWithPath[]) => {
    logger.log(files);
    setUploadedFiles(files);
    uploadVoiceAttachment(files[0]);
  }, [ uploadVoiceAttachment ]);

  const onRemoveAttachment = useCallback((file: FileWithPath | ProfileDocumentFile) => {
    logger.log(file);
    setUploadedFiles([]);
  }, []);

  const onDownloadAttachment = useCallback((file: ProfileDocumentFile) => {
    logger.log(file);
  }, []);

  const handleRejectedFiles = useCallback((rejectedFiles: FileRejection[]) => {
    logger.log(rejectedFiles);
  }, []);

  return (
    <div>
      <Dropzone
        accept={ [ 'audio/*' ] }
        onAcceptedFiles={ onNewAttachmentSelected }
        onRejectedFiles={ handleRejectedFiles }
        maxSize={ 25000000 }
        maxFiles={ 1 }
      />
      <DropzoneFiles
        removeFile={ onRemoveAttachment }
        downloadFile={ onDownloadAttachment }
        files={ uploadedFiles || [] }
      />
    </div>
  );
};

export default UploadAudioMessageComponent;
