import { Divider } from "@mantine/core";
import { FC } from "react";

import { getTimeDurationFromCurrentDate } from "@/common/util/format/date";
import CountryFlag from "@/tenant-context/common/components/CountryFlag";
import RiskALertStatus
  from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertStatus/RiskAlertStatus.component";
import {
  RiskAlert,
  RiskAlertEvent,
  RiskAlertEventMeta, RiskAlertExternalData,
  RiskAlertJsonMeta
} from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type Props = {
  classes: Record<string, string>,
  meta: RiskAlertEventMeta,
  json: { meta: RiskAlertJsonMeta; alert: RiskAlert; externalData?: RiskAlertExternalData },
  riskAlertData: RiskAlertEvent
}

export const BSOCPopupContent: FC<Props> = ({
  classes,
  meta,
  json,
  riskAlertData
}) => {
  return <div className={ classes.popupAlternativeContainer }>
    <div className={ classes.timesAgoText }>
      { (meta.createdTime > 0)
        ? `${getTimeDurationFromCurrentDate(new Date(json.alert.start_date))} ago`
        : "" }
    </div>
    { (meta.createdTime > 0) &&
      <Divider
        className={ classes.divider }
      /> }
    { /* <Divider
              className={ classes.divider }
            />  */ }
    <div className={ classes.popupAlternativeHeader }>
      { json.alert.countries[0].iso_code && (
        <CountryFlag
          width={ 48 }
          height={ 48 }
          countryCode={ json.alert.countries[0].iso_code }
        />
      ) }
      <div>
        { json.externalData?.region },
        { json.externalData?.province }
      </div>
    </div>
    <div className={ classes.riskAlertTitle }> { json.alert.title }</div>
    <RiskALertStatus riskAlertData={ riskAlertData }/>
    <div className={ classes.incidentTimeInfo }>
      Time of incident &nbsp;
      <div className={ classes.boldIncidentTimeInfo }>
        { json.externalData?.incident_date }
      </div>
    </div>
  </div>;
};
