import { FeatureCollection, Point } from "geojson";
import { FC } from "react";
import { useMap } from "react-map-gl";

import { ArcImpactDetail } from "@/tenant-context/control-action-response-center/types/ARC.types";
import useSiteIcons from "@/tenant-context/visualisation-site/hooks/useSiteIcons";

import ImpactAssets from "./ImpactAssets.component";

type Props = {
    assetsGeodata?: FeatureCollection<Point, ArcImpactDetail>,
  }

const ImpactAssetsContainer: FC<Props> =  ({
  assetsGeodata
}) => {
    
  const { impactZoneMap: map } = useMap();
  useSiteIcons(map, true);

  return (
    <ImpactAssets assetsGeodata={ assetsGeodata }/>
  );
};

export default ImpactAssetsContainer;