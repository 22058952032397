import { PaginatedResult, ReferenceData, ReferenceType } from "../types/reference-data";
import { callApi } from "./api";

export const getReferenceData = (
  referenceDataType: ReferenceType
) => callApi<PaginatedResult<ReferenceData>>(
  `public/platform-service/v1/reference-data?type=${referenceDataType}&size=1000`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=ListReferenceData'
    },
    method: 'get',
    withTenantId: false,
    withAuthorization: false
  }
);