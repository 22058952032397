import { ActionIcon } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as DeleteIcon } from "@/common/icons/delete.svg";
import { ReactComponent as EditIcon } from "@/common/icons/edit.svg";
import { constructTestId } from "@/core/util/test-id";
import {
  useRoleRowTemplateStyles
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/RoleRowTemplate/RowTemplate.styles";
import {
  ChecklistItem
} from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

const GRID_NAME = 'checklist-grid';

type  Props = {
    row: ChecklistItem
    isAuthorizedToCreateOrEdit: boolean
    onDeleteIconClicked: (checklistItemId: string) => void
    onEditIconClicked: (row: ChecklistItem) => void
}

const ChecklistRowTemplateComponent: FC<Props> = ({
  row,
  isAuthorizedToCreateOrEdit,
  onDeleteIconClicked,
  onEditIconClicked
}) => {

  const { classes } = useRoleRowTemplateStyles();

  return (
    <div className={ classes.wrapper }>
      <h6 className={ classes.title }>
        { row.itemName }
      </h6>
      <ActionIcon
        disabled={ !isAuthorizedToCreateOrEdit }
        /* eslint-disable-next-line react/jsx-no-bind */
        onClick={ () => onEditIconClicked(row) }
        data-testid={ constructTestId("BUTTON", `${GRID_NAME}-${row._id}`, "edit") }
      >
        <EditIcon />
      </ActionIcon>
      <ActionIcon
        disabled={ !isAuthorizedToCreateOrEdit }
        /* eslint-disable-next-line react/jsx-no-bind */
        onClick={ () => onDeleteIconClicked(row?._id || '') }
        data-testid={ constructTestId("BUTTON", `${GRID_NAME}-${row._id}`, "delete") }
      >
        <DeleteIcon />
      </ActionIcon>
    </div>
  );
};

export default ChecklistRowTemplateComponent;