import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";

export default function useDrawer(id: string, drawerPosition:string) {
  const dispatch = useDispatch<Dispatch>();

  const openRightDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenRightDrawerId);
  const openBottomDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenBottomDrawerId);
  const openLeftDrawerId = useSelector((state: RootState) => state.drawer.currentlyOpenLeftDrawerId);

  const open = useCallback(() => {
    if(drawerPosition === "right"){
      dispatch.drawer.openRightDrawer(id);
    } else if (drawerPosition === "left"){
      dispatch.drawer.openLeftDrawer(id);
    } else {
      dispatch.drawer.openBottomDrawer(id);
    }
    
  }, [dispatch.drawer, drawerPosition, id]);

  const close = useCallback(() => {
    if(drawerPosition === "right"){
      dispatch.drawer.closeRightDrawer();
    } else if (drawerPosition === "left"){
      dispatch.drawer.closeLeftDrawer();
    } else {
      dispatch.drawer.closeBottomDrawer();
    }
  
  }, [dispatch.drawer, drawerPosition]);

  return useMemo(() => ({
    isOpen: openRightDrawerId === id || openBottomDrawerId === id || openLeftDrawerId === id,
    open,
    close
  }), [close, id, open, openBottomDrawerId, openRightDrawerId, openLeftDrawerId]);
}
