import { ColDef } from "ag-grid-community";

export const agGridDefaultColDef: ColDef =  {
  enableRowGroup: true,
  enablePivot: true,
  enableValue: true,
  sortable: true,
  resizable: true,
  filter: false,
  flex: 1
};
