import { callApi } from "@/common/util/api";
import { store } from "@/core/store";
import { BreadcrumbsResponse } from "@/tenant-context/visualisation-people/types/people.types";

const DEFAULT_RANK_REQUEST_TIME = 0;

export const getPersonBreadcrumbs = (
  assetId: string,
  rankRequestTime = DEFAULT_RANK_REQUEST_TIME
) => callApi<BreadcrumbsResponse>(
  `/asset-ranking-service/v1/location-engine/people/${assetId}?rankRequestTime=${rankRequestTime}&tenantId=${store.getState().commonData.tenantId}&rankingOption=ALL_RANK`
);
