import { Button, Stack } from "@mantine/core";
import React from "react";

import DataminrLegendComponent
  from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/DataminrLegend.component";
import { useLegendInTree } from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/hooks";
import ProviderEvents
  from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/ProviderEvents.component";
import { ProviderCategory } from "@/tenant-context/control-risk-config/types/risk";

export function DataMinrEventsComponent(props: {
  items: Array<ProviderCategory>,
  onCategoryToggle: (event: React.ChangeEvent<HTMLInputElement>) => void,
  disabledRiskFilterTypes:  Set<string>
}) {
  const { buttonAsLinkClasses, risklineLegendVisible, toggleRisklineLegend } = useLegendInTree();

  return <Stack mt={ 16 } ml={ 32 + 36 }>
    { risklineLegendVisible && <DataminrLegendComponent /> }
    <Button
      variant="subtle"
      compact
      classNames={ buttonAsLinkClasses }
      onClick={ toggleRisklineLegend }
    >
      { risklineLegendVisible ? "Hide" : "Show" } legend
    </Button>
    <ProviderEvents
      items={ props.items }
      onCategoryToggle={ props.onCategoryToggle }
      disabledRiskFilterTypes={ props.disabledRiskFilterTypes }
    />
  </Stack>;
}