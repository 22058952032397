import countryCodeByName from '@/tenant-context/common/components/CountryFlag/country-name-to-code.json';

export function getCountryIsoCode(countryName: string): string | undefined {
  const country = countryCodeByName.find((countryItem: {name: string, code: string}) =>
    countryItem.name.toLowerCase() === countryName.trim().toLowerCase());

  return country?.code;
}

export function getCountryNameByCode(countryCode: string): string | undefined {
  const country = countryCodeByName.find((countryItem: {name: string, code: string}) =>
    countryItem.code.toLowerCase() === countryCode.trim().toLowerCase());

  return country?.name;
}

export function isValidISOCountryCode(isoCode: string): boolean {
  return countryCodeByName.some((countryItem: {name: string, code: string}) =>
    countryItem.code === isoCode.toUpperCase());
}

export function isValidCountryName(countryName: string): boolean {
  return countryCodeByName.some((countryItem: {name: string, code: string}) =>
    countryItem.name.toLowerCase() === countryName.trim().toLowerCase());
}

export function extractCountryNameFromAddress(address: string): string | undefined {
  const countryName = address.split(',').reverse()[0].trim();
  return isValidCountryName(countryName) ? countryName : undefined;
}
export function extractCountryNameFromPhrase(phrase: string): string | undefined {
  const country = countryCodeByName.find((countryItem: {name: string, code: string}) =>
    phrase.toLowerCase().includes(countryItem.name.toLowerCase()));

  return country?.name;
}

export function extractCountryCode(phrase: string): string | undefined {
  const countryName = extractCountryNameFromAddress(phrase) || extractCountryNameFromPhrase(phrase);

  if (!countryName) {
    return undefined;
  }

  return getCountryIsoCode(countryName);
}
