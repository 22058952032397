import { createModel } from "@rematch/core";
import { FeatureCollection } from "geojson";

import { Dispatch, RootState } from "@/core/store";
import { RootModel } from "@/core/store";
import {
  getAssetLocationDetails,
  getAssetLocationSnapshotsForBounds
} from "@/tenant-context/asset-location-snapshots/api/asset-location";
import { AssetLocationDetails } from "@/tenant-context/asset-location-snapshots/types/asset-location";
import { withVisualisableTraits } from "@/tenant-context/common/util/with-visualisable-traits";
import { parseGeoJSON } from "@/tenant-context/common/workers/geo-json-mapper-worker/geo-json-mapper-worker.external";

type AssetLocationLayerModel = {
  geoData: FeatureCollection,
  assetLocationDetails: Map<string, AssetLocationDetails>
}

const assetLocationLayerModel = withVisualisableTraits()({
  name: 'assetLocations',
  state: {
    assetLocationDetails: new Map<string, AssetLocationDetails>()
  } as AssetLocationLayerModel,
  reducers: {
    SET_ASSET_LOCATION_INFO(state: AssetLocationLayerModel, payload: AssetLocationDetails) {
      const { name } = payload;
      return {
        ...state,
        assetLocationDetails: new Map(state.assetLocationDetails).set(name, payload)
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async fetchAssetLayerData(
      payload: void,
      state: RootState
    ): Promise<void> {
      const { bigMap: {
        currentGeoFences,
        currentViewPort
      } } = state;

      // Check if there's any GeoFences available
      // if so - iterate over them and get the results.
      if (currentGeoFences.length > 0) {
        const results = await Promise.all(state.bigMap.currentGeoFences.map((geoFence) => {
          return getAssetLocationSnapshotsForBounds(geoFence.geometry);
        }));

        const { data } = await parseGeoJSON({
          data: results.flat(),
          params: {
            Point: ['lat', 'lon']
          }
        });

        dispatch.assetLocations.SET_GEO_DATA(data as FeatureCollection);

        return;
      }

      // If there's no any GeoFences,
      // get the bounding box came as the payload to request the data
      const assetLocationSnapshots = await getAssetLocationSnapshotsForBounds(
        currentViewPort
      );
      const { data } = await parseGeoJSON({
        data: assetLocationSnapshots.flat(),
        params: { Point: ['lat', 'lon'] }
      });

      dispatch.assetLocations.SET_GEO_DATA(data as FeatureCollection);
    },

    async fetchAssetLocationDetails(
      id: string,
      state: RootState
    ): Promise<AssetLocationDetails> {

      if (state.assetLocations?.assetLocationDetails.has(id)) {
        return state.assetLocations.assetLocationDetails.get(id) as AssetLocationDetails;
      }

      const locationInfo = await getAssetLocationDetails(id);

      dispatch.assetLocations.SET_ASSET_LOCATION_INFO(locationInfo);

      return locationInfo;
    }
  })
} as const);

export const assetLocations = createModel<RootModel>()(
  assetLocationLayerModel
);
