import { createStyles, MantineTheme } from "@mantine/core";


type Params = {
  isActive:boolean, 
}
export const useGlobalSearchResultItemStyles = createStyles((theme:MantineTheme,  params?: Params) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor:params?.isActive ? theme.colors.neutral[6] : '',
    cursor: 'pointer',
    fontWeight:theme.other.fw.regular,
    borderStyle:'none solid solid solid',
    borderWidth:'1px',
    borderColor:theme.colors.neutral[6],
    padding: '8px 16px', 
    '&:hover':{
      backgroundColor:theme.colors.neutral[6]
    },
    '&:active':{
      backgroundColor:theme.colors.neutral[6]
    }
  },

  icon: {
    fontSize: theme.fontSizes.xs
  }
}));
