/* eslint-disable react/jsx-props-no-spreading */
import { Select, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { FC, useCallback, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import { RootState } from "@/core/store";
import {
  ProfileDocumentsModalContext
} from "@/tenant-context/control-profile/components/modals/ProfileDocumentsModal/ProfileDocumentsModal.context";
import {
  useEmergencyContactModalContentStyles
} from "@/tenant-context/control-profile/components/ui/EmergencyContactModalContent/EmergencyContactModalContent.style";

export type ProfileDocumentsBasicInfoTabFormData = {
  documentName: string;
  documentNumber: string;
  comments: string;
  documentType: string;
  documentExpiryDate: Date;
};

const ProfileDocumentsBasicInfoTab: FC = () => {
  const { classes } = useEmergencyContactModalContentStyles();

  const {
    formControls: {
      register,
      formState: { errors },
      watch,
      clearErrors,
      setValue
    },
    editableDocument
  } = useContext(ProfileDocumentsModalContext);

  const documentExpiryDate: Date = watch('documentExpiryDate');

  const referenceData = useSelector((state: RootState) => state.profile.referenceData);

  const documentTypeList = useMemo(() => {
    return referenceData?.documentType?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    });
  }, [referenceData]);

  useEffect(() => {

    register(
      'documentExpiryDate',
      addErrorMessages(
        'Expiry Date',
        { required: false }
      )
    );

    setValue('documentExpiryDate', editableDocument?.expiryDate);
  }, [editableDocument?.expiryDate, register, setValue]);

  const handleDocumentTypeChange = useCallback((value: string) => {
    setValue('documentType', value);
  }, [setValue]);

  const handleExpiryDateChange = useCallback((value: Date | null) => {
    setValue('documentExpiryDate', value);

    if (value) {
      clearErrors('documentExpiryDate');
    }
  }, [clearErrors, setValue]);

  return (
    <div>
      <div
        className={ classes.grid }
      >
        <Select
          { ...register('documentType', addErrorMessages('Document Type', {
            required: true
          })) }
          searchable
          required={ true }
          onChange={ handleDocumentTypeChange }
          data={ documentTypeList ? documentTypeList : [] }
          label="Document Type"
          defaultValue={ editableDocument?.documentType }
          error={ errors?.documentType?.message }
        />

        <DatePicker
          label="Expiry Date"
          error={ errors.documentExpiryDate?.message }
          value={ documentExpiryDate }
          onChange={ handleExpiryDateChange }
          minDate={ new Date() }
          defaultValue={ editableDocument?.expiryDate
            ? new Date(editableDocument?.expiryDate)
            : undefined }
        />

        <TextInput
          required={ true }
          error={ errors.documentName?.message }
          label="Document Name"
          { ...register('documentName', addErrorMessages("Document Name", {
            required: true,
            maxLength: 128
          })) }
          defaultValue={ editableDocument?.name }
        />

        <TextInput
          error={ errors.documentNumber?.message }
          label="Document Number"
          { ...register('documentNumber', addErrorMessages("Document Number", {
            required: false,
            maxLength: 128
          })) }
          defaultValue={ editableDocument?.number }
        />
      </div>

      <TextInput
        error={ errors.comments?.message }
        label="Comments"
        { ...register('comments', addErrorMessages("Comments", {
          required: false,
          maxLength: 500
        })) }
        defaultValue={ editableDocument?.comments }
      />
    </div>
  );
};

export default ProfileDocumentsBasicInfoTab;
