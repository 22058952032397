import { FC } from "react";
import { useSelector } from 'react-redux';

import { RootStateWithLoading } from '@/core/store';
import ProfileLoadingOverlayComponent
  from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay/ProfileLoadingOverlay.component';


const ProfileLoadingOverlay: FC = () => {
  const isProfileLoading = useSelector(
    (rootState: RootStateWithLoading) => rootState.loading.models.profile
  );

  return (
    <ProfileLoadingOverlayComponent isLoading={ isProfileLoading }/>
  );
};

export default ProfileLoadingOverlay;