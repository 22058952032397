import { FC, useEffect, useState } from "react";

import TimeUpCounterComponent from "./TimeUpCounter.component";

type Props = {
    timeInMillis: number | string
}
export type TimerProps = {
    days: number,
    hours: number,
    minutes: number
}
const TimeUpCounter: FC<Props> = ({
  timeInMillis
}) => {

  const [timeTracker, setTimeTracker] = useState<TimerProps>({ days: 0, hours: 0, minutes: 0 });

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const timeDifference = currentTime - Number(timeInMillis);
      if (timeDifference > 0) {
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        setTimeTracker({ days, hours, minutes });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeInMillis]);

  return (
    <TimeUpCounterComponent timeCounter={ timeTracker } timeInMillis={ timeInMillis } />
  );
};

export default TimeUpCounter;
