import { createStyles } from "@mantine/core";

export const useAuthenticatedMapStyles = createStyles((theme) => ({
  lockContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  lockIcon: {
    width: '50px', 
    height: '50px'
  },
  lockIconContainer: {
    backgroundColor: theme.white,
    borderRadius: '50%',
    padding: '20px'
  }
}));
