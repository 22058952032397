import { FC } from "react";

import { useTagsContainerStyles } from '@/common/components/TagsContainer/TagsContainer.style';
import TagChip from "@/common/components/TagsContainer/ui/TagChip";
import { ProfileTag } from '@/tenant-context/control-profile/types/profile';

type Props = {
  onSelectTag: (index: number) => void
  onRemove: (index: number) => void
  selectedTagList: ProfileTag[]
  profileTagsListForProfile: ProfileTag[]
  mode?: 'ADD' | 'EDIT' | 'CREATE'
};

const TagsContainerComponent: FC<Props> = ({
  onSelectTag,
  onRemove,
  selectedTagList,
  profileTagsListForProfile,
  mode
}) => {

  const { classes } = useTagsContainerStyles();

  return (
    <div className={ classes.tagsContainerRoot }>
      { mode === 'ADD' && profileTagsListForProfile.map((chip, index) => (
        <TagChip
          color={ chip.color }
          index={ index }
          label={ chip.name }
          key={ index }
        />
      )) }
      { selectedTagList.map((chip, index) => (
        <TagChip
          color={ chip.color }
          index={ index }
          onRemove={ onRemove }
          onClick={ onSelectTag }
          label={ chip.name }
          key={ index }
        />
      )) }
    </div>
  );
};

export default TagsContainerComponent;
