/* eslint-disable react/jsx-props-no-spreading */
import { Button, DrawerPosition, Radio, Switch, Textarea, TextInput } from "@mantine/core";
import { closeAllModals, openModal } from '@mantine/modals';
import { EventData, MapLayerEventType } from 'mapbox-gl';
import { ChangeEvent, FC, useCallback } from "react";
import { FieldValues, UseFormReturn } from 'react-hook-form';

import DrawerControl from "@/common/components/DrawerControl";
import { addErrorMessages } from '@/common/components/FormError/FormError.component';
import { ReactComponent as DeleteIcon } from "@/common/icons/Tools/delete.svg";
import { useCreateGeofenceDrawerStyles } from '@/tenant-context/control-draw/components/CreateGeofenceDrawer/CreateGeofenceDrawer.styles';
import CreateGeofenceToolBar from '@/tenant-context/control-draw/components/CreateGeofenceToolBar';
import GeofenceLoadingOverlay from '@/tenant-context/control-draw/components/GeofenceLoadingOverlay';
import GeofenceMap from '@/tenant-context/control-draw/components/GeofenceMap';
import DialogBoxModal from '@/tenant-context/control-draw/components/modals/DialogBoxModal/DialogBoxModal.component';
import { DrawingTypes, Geofence, GeofencePermissionType, GeofenceType } from '@/tenant-context/control-draw/types/draw';

type Props = {
  onDrawingUpdate: (evt: MapLayerEventType & EventData) => void;
  onDrawingDelete: (evt: MapLayerEventType & EventData) => void;
  onDrawingSelected: (evt: MapLayerEventType & EventData) => void;
  onDrawControlCreated?: (mapboxDraw: MapboxDraw) => void;
  handleDrawingToolSelect: (type: DrawingTypes) => void;
  drawerId: string;
  drawerPosition: DrawerPosition;
  onDrawerClose: () => void;
  isHiddenGeofenceArchiveOption?: boolean;
  isHiddenGeofencePermission?: boolean;
  isHiddenProhibitiveGeofenceConfig?: boolean;
  onGeofenceTypeChanged: (type: GeofenceType) => void;
  onGeofencePermissionTypeChanged: (type: GeofencePermissionType) => void;
  geofenceType?: GeofenceType;
  geofencePermissionType?: GeofencePermissionType;
  formControls: UseFormReturn<FieldValues, unknown>;
  editableGeofence?: Geofence;
  intermediateArchiveState?: string;
  onSubmit: (data: FieldValues) => void;
  onDelete: () => void;
  onArchive: (event: ChangeEvent<HTMLInputElement>) => void;
};

const CreateGeofenceDrawerComponent: FC<Props> = ({
  onDrawingUpdate,
  onDrawingDelete,
  onDrawingSelected,
  onDrawControlCreated,
  handleDrawingToolSelect,
  drawerId,
  drawerPosition,
  onDrawerClose,
  isHiddenGeofenceArchiveOption,
  isHiddenGeofencePermission,
  isHiddenProhibitiveGeofenceConfig,
  onGeofenceTypeChanged,
  onGeofencePermissionTypeChanged,
  geofenceType,
  geofencePermissionType,
  formControls,
  editableGeofence,
  intermediateArchiveState,
  onSubmit,
  onDelete,
  onArchive
}) => {
  const { classes } = useCreateGeofenceDrawerStyles();
  const  { register, formState: { errors } } = formControls;

  const cancelDialogClickHandle = useCallback(() => {
    onDrawerClose();
    closeAllModals();
  }, [onDrawerClose]);

  const onCancel = useCallback(() => {

    openModal({
      title: 'Cancel',
      size: 648,
      children: (
        <DialogBoxModal
          highlightedText={ 'Warning' }
          messageText={ 'Changes will not be saved' }
          controls={ <Button onClick={ cancelDialogClickHandle }  size={ 'md' }>OK</Button> }
        />
      )
    });
  }, [cancelDialogClickHandle]);

  return (
    <DrawerControl
      id={ drawerId }
      drawerPosition={ drawerPosition }
      size={ 'full' }
    >
      <GeofenceLoadingOverlay/>
      <form onSubmit={ formControls.handleSubmit(onSubmit) } className={ classes.geoFencesForm }>
        <div className={ classes.geoFencesMapGrid }>

          <div className={ classes.title }>
            Geofences
          </div>

          <CreateGeofenceToolBar
            handleDrawingToolSelect={ handleDrawingToolSelect }
            isWithLiveFeedButton
          />

          <div className={ classes.formContainer }>

            <div className={ classes.formInputsSection }>

              <TextInput
                label="Geofence Name"
                className={ classes.inputItem }
                { ...register(
                  'name',
                  addErrorMessages('Geofence name', {
                    required: true
                  })
                ) }
                error={ errors?.name?.message }
              />

              <Textarea
                label="Description"
                className={ classes.inputItem }
                { ...register(
                  'description',
                  addErrorMessages('Geofence description', {})
                ) }
                error={ errors?.description?.message }
              />

              <Radio.Group
                label="Geofence Type"
                onChange={ onGeofenceTypeChanged }
                value={ geofenceType }
                className={ classes.radioGroup }
                defaultValue={ editableGeofence ? editableGeofence.type : GeofenceType.GENERAL }
              >
                <Radio value={ GeofenceType.GENERAL } label="General Geofence" />
                <Radio
                  value={ GeofenceType.PROHIBITIVE }
                  label="Prohibited Geofence"
                />
              </Radio.Group>

              <Radio.Group
                label="Select Sharing Permissions"
                onChange={ onGeofencePermissionTypeChanged }
                value={ geofencePermissionType }
                defaultValue={ GeofencePermissionType.PRIVATE }
                hidden={ isHiddenGeofencePermission }
                className={ classes.radioGroup }
              >
                <Radio value={ GeofencePermissionType.PRIVATE } label="Private" />
                <Radio value={ GeofencePermissionType.SHARED } label="Shared" />
              </Radio.Group>

              <div className={ classes.configSection } hidden={ isHiddenProhibitiveGeofenceConfig }>
                <p>
                  Triggers an Alert Notification to  Restrata Mobile app user on entry to the
                  prohibitive geofence. Note, user must have &apos;Background location use&apos; enabled n
                  their device in order to detect geofence breaches when not using the app.
                </p>

                <Textarea
                  label="Notification Message to App User"
                  className={ classes.inputItem }
                  { ...register(
                    'pushNotificationText',
                    addErrorMessages('Geofence notification message', {
                      required: geofenceType === GeofenceType.PROHIBITIVE,
                      maxLength: 178
                    })
                  ) }
                  error={ errors?.pushNotificationText?.message }
                />

                <div className={ classes.formGroup }>
                  <div className={ classes.switchWithDescription }>
                    <Switch
                      { ...register('enableLocationTracking') }
                      defaultChecked={ editableGeofence && editableGeofence.type === GeofenceType.PROHIBITIVE ?
                        editableGeofence.enableLocationTracking : false }
                    />
                    <label className={ classes.switchLabel }>Turn on user tracking</label>
                    <div/>
                    <label>
                      If app users location permission is granted then live location
                      tracking will be activated upon a breach of this geofence
                    </label>
                  </div>
                </div>
              </div>

              <div className={ classes.configSection } hidden={ isHiddenGeofenceArchiveOption }>
                <div className={ classes.formGroup }>
                  <div className={ classes.switchWithDescription }>
                    <Switch
                      checked={ intermediateArchiveState?.toUpperCase() === 'DISABLED' }
                      onChange={ onArchive }
                    />
                    <label className={ classes.switchLabel }>Archive Geofence</label>
                    <div/>
                    <label>
                      Archiving a geofence will remove the geofence from the list of active
                      geofences and turn-off any associated actions. The geofence will
                      not be deleted and can be re-activated via the geofence management area.
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className={ classes.drawerButtons }>
              <div className={ classes.drawerButtonsSet }>
                <Button
                  hidden={ !editableGeofence }
                  leftIcon={ <DeleteIcon/> }
                  className={ classes.deleteButton }
                  onClick={ onDelete }
                >
                  Delete
                </Button>
              </div>
              <div className={ classes.drawerButtonsSet }>
                <Button variant="outline" onClick={ onCancel }>Cancel</Button>
                <Button type="submit">Save</Button>
              </div>
            </div>
          </div>

          <GeofenceMap
            onDrawingUpdate={ onDrawingUpdate }
            onDrawingDelete={ onDrawingDelete }
            onDrawingSelected={ onDrawingSelected }
            onDrawControlCreated={ onDrawControlCreated }
          />

        </div>
      </form>
    </DrawerControl>
  );
};

export default CreateGeofenceDrawerComponent;
