import { useMantineTheme } from "@mantine/core";
import { FC, useContext } from "react";
import { Layer } from "react-map-gl";
import { useSelector } from "react-redux";

import FilterableSource from "@/common/components/FilterableSource";
import useLayerListener from "@/common/hooks/useLayerListener";
import { RootState } from "@/core/store";
import { PeopleContext } from "@/tenant-context/visualisation-people/context/People.context";

const IndoorPeopleLayerComponent: FC = () => {

  const geoData = useSelector((state: RootState) => state.indoor.indoorPeopleLocation);
  const currentFloor = useSelector((state: RootState) => state.indoor?.level);

  const theme = useMantineTheme();
  const {
    handlePersonLocationHover,
    handlePersonLocationLeave
  } = useContext(PeopleContext);

  // People layer hover handler
  useLayerListener(
    'mouseenter',
    'r__indoorPeopleLocationSnapshotLayer',
    handlePersonLocationHover
  );

  useLayerListener(
    'mouseleave',
    'r__indoorPeopleLocationSnapshotLayer',
    handlePersonLocationLeave
  );

  return (
    <FilterableSource
      id="r__indoorPeopleLocationSource"
      type="geojson"
      tolerance={ 0 }
      data={ geoData }
      // Filtering out features that are not on the current floor
      // ToDo - Need to add missing filters
      filter={ ['==', ['get', 'floor'], currentFloor?.toString()] }
    >
      { /* Indoor People Cluster Layer */ }
      <Layer
        id={ `r__indoorPeopleLocationSnapshotLayer-cluster` }
        type={ 'symbol' }
        filter={ ['==', 'isClustered', true] }
        paint={ {
          "text-color": theme.colors.blue[6]
        } }
        layout={ {
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-image': 'people-cluster',
          'icon-size': 0.25,
          // eslint-disable-next-line no-magic-numbers
          'text-offset': [0, -0.3],
          'text-field': [
            'upcase',
            [
              'to-string',
              [
                'length',
                [
                  'get',
                  'people'
                ]
              ]
            ]
          ],
          'text-font': ['Arial Unicode MS Bold'],
          'text-size': 16,
          'text-anchor': 'top'
        } }
        source="r__indoorPeopleLocationSource"
      />

      { /* Indoor Person Marker Layer */ }
      <Layer
        id={ 'r__indoorPeopleLocationSnapshotLayer' }
        type={ 'symbol' }
        paint={ {
          "text-color": theme.colors.blue[6]
        } }
        layout={ {
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'text-size': 12,
          // eslint-disable-next-line no-magic-numbers
          'text-offset': [0, -0.4],
          'text-anchor': 'center',
          'icon-size': 0.3,
          'text-font': ['Arial Unicode MS Bold'],
          'text-field': [
            'upcase',
            [
              'get',
              'personInitials',
              ['get', 'people']
            ]
          ],
          'icon-image': 'person-marker'
        } }
        filter={ ['==', 'isClustered', false] }
        interactive
        source="r__indoorPeopleLocationSource"
      />
    </FilterableSource>
  );

};

export default IndoorPeopleLayerComponent;
