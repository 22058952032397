import { FC } from "react";

import DrawerControl from "@/common/components/DrawerControl";
import LocationGraph from "@/tenant-context/control-location-graph/components/LocationGraph";
import TimeScaleDropdown from "@/tenant-context/control-location-graph/components/TimeScaleDropdown";
import { useLocationGraphControlStyles } from "@/tenant-context/control-location-graph/controls/LocationGraphControl/LocationGraphControl.styles";

type Props = {
  currentPersonAssetId?: string,
  onCloseClick: () => void
};

const LocationGraphControl: FC<Props> = ({
  currentPersonAssetId,
  onCloseClick
}) => {
  const { classes } = useLocationGraphControlStyles();

  return (
    <DrawerControl
      id="location-graph"
      header={ (
        <div className={ classes.heading }>
          <div className={ classes.titleString }>
            <span className={ classes.title }>
              Location probability for&nbsp;
            </span>

            <span className={ classes.personName }>
              { currentPersonAssetId }
            </span>
          </div>

          <TimeScaleDropdown />
        </div>
      ) }
      drawerPosition="bottom"
      onCloseClick={ onCloseClick }
    >
      <LocationGraph />
    </DrawerControl>
  );
};

export default LocationGraphControl;
