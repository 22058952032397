import { Button, Divider, Flex, TextInput } from "@mantine/core";
import React, { FC, useCallback, useMemo } from "react";

import ButtonControl from "@/common/components/ButtonControl";
import DrawerControl from "@/common/components/DrawerControl";
import { DrawerControlButtonRenderer } from "@/common/components/DrawerControl/DrawerControl.component";
import usePermission from '@/common/hooks/usePermission';
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { ReactComponent as PolygonIcon } from "@/common/icons/draw-polygon.svg";
import { ReactComponent as PlusIcon } from "@/common/icons/plus.svg";
import { ReactComponent as SearchIcon } from "@/common/icons/search.svg";
import { TestableComponent } from "@/common/types/testable-component";
import CreateGeofenceDrawer from "@/tenant-context/control-draw/components/CreateGeofenceDrawer";
import DrawingFilterRadio from "@/tenant-context/control-draw/components/DrawingFilterRadio";
import GeofenceList from "@/tenant-context/control-draw/components/GeofenceList";
import { GeofenceRequiredPoliciesConfig } from '@/tenant-context/control-draw/config/GeofenceRequiredPolicies.config';
import { useDrawControlStyles } from "@/tenant-context/control-draw/controls/DrawControl/DrawControl.styles";
import { DrawingFilterTypes, GeofenceListTabsTypes } from "@/tenant-context/control-draw/types/draw";

type Props = {
  selectDrawingFilter: (value: DrawingFilterTypes) => void,
  handleSearchFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  createNewGeofenceButtonClickHandler: () => void;
  geofenceManagementButtonClickHandler: () => void;
  allPaginationClickHandler: () => void;
  privatePaginationClickHandler: () => void;
  activeTab: GeofenceListTabsTypes
  isSeeMoreButtonVisible: boolean
  disabled?: boolean
  tooltip?: string
} & TestableComponent;

const GeofenceControl: FC<Props> = ({
  selectDrawingFilter,
  handleSearchFilterChange,
  createNewGeofenceButtonClickHandler,
  geofenceManagementButtonClickHandler,
  allPaginationClickHandler,
  activeTab,
  privatePaginationClickHandler,
  isSeeMoreButtonVisible,
  dataTestId = "right-menu-draw",
  disabled,
  tooltip
}) => {

  const { classes } = useDrawControlStyles();

  const isPermittedToViewGeofence = usePermission(
    GeofenceRequiredPoliciesConfig.LONE_WORKER_MANAGE_GEO_FENCE_READ_ACCESS
  );

  const isPermittedToEditGeofence = usePermission(
    GeofenceRequiredPoliciesConfig.LONE_WORKER_MANAGE_GEO_FENCE_FULL_ACCESS
  );
  const handleUnauthorizedAction = useUnauthorizedModal(
    'You do not have permission to access this feature.',
    {
      customMessage: 'you do not have access to this feature, please contact your admin if you require this feature'
    }
  );

  const renderDrawerButton: DrawerControlButtonRenderer = useCallback(({ onClick }) => (
    
    <ButtonControl
      label="Geofences"
      onClick={ isPermittedToViewGeofence ? onClick : handleUnauthorizedAction }
      isUnauthorized={ !isPermittedToViewGeofence }
      data-testid={ dataTestId }
      disabled={ disabled }
      tooltip={ tooltip }
    >
      <PolygonIcon style={ { width: '20px' } }/>
    </ButtonControl>
  ), [isPermittedToViewGeofence, handleUnauthorizedAction, dataTestId, disabled, tooltip]);

  const footer = useMemo(() => (
    <Flex align={ 'center' } direction={ 'column' } gap={ 'xl' }>
      <Button
        className={ isPermittedToEditGeofence ? undefined : classes.unauthorizedButton }
        rightIcon={ <PlusIcon/> }
        onClick={ isPermittedToEditGeofence ? createNewGeofenceButtonClickHandler : handleUnauthorizedAction }
        fullWidth
      >
        Create New Geofence
      </Button>
      <Button
        onClick={ geofenceManagementButtonClickHandler }
        variant="outline"
        fullWidth
      >
        Geofence Management
      </Button>
    </Flex>
  ), [
    classes.unauthorizedButton,
    createNewGeofenceButtonClickHandler,
    geofenceManagementButtonClickHandler,
    handleUnauthorizedAction,
    isPermittedToEditGeofence
  ]);

  return (
    <DrawerControl
      id={ 'draw-control' }
      title={ 'Geofences' }
      renderButton={ renderDrawerButton }
      footer={ footer }
    >
      <CreateGeofenceDrawer/>
      <TextInput
        icon={ <SearchIcon/> }
        onChange={ handleSearchFilterChange }
      />
      <DrawingFilterRadio onDrawingFilterSelected={ selectDrawingFilter }/>
      <GeofenceList/>
      <Divider style={ { marginBottom: 6 } } my="sm"/>
      { isSeeMoreButtonVisible && <button
        onClick={ activeTab === GeofenceListTabsTypes.ALL_GEOFENCE
          ?
          allPaginationClickHandler
          :
          privatePaginationClickHandler }
        className={ classes.pagination }>
        See more
      </button> }
    </DrawerControl>
  );
};

export default GeofenceControl;
