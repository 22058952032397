import { createStyles } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useMiniPopupStyles = createStyles((theme) => ({
  root: {
    '& .mapboxgl-popup-tip': {
      display: 'none'
    },
    '& .mapboxgl-popup-content': {
      backgroundColor: genericColorPalette.neutral[9],
      padding: '6px 8px',
      fontSize: theme.fontSizes.sm
    }
  }
}));
