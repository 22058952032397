import { Button } from '@mantine/core';
import { FC, useCallback, useEffect } from 'react';

import {
  useRiskProviderBadgeStyles
} from '@/tenant-context/control-reports/components/RiskProviderBadge/RiskProviderBadge.styles';
import { CountryRiskProvider } from '@/tenant-context/control-reports/types/country-risk-reports';

type Props = {
  isSelected?: boolean;
  label: string;
  value: CountryRiskProvider;
  onClick?: (value: CountryRiskProvider) => void;
}

const RiskProviderBadgeComponent: FC<Props> = ({
  isSelected,
  label,
  value,
  onClick
}) => {
  const { classes, cx } = useRiskProviderBadgeStyles();

  const handleClick = useCallback(() => {
    if (!onClick) {
      return;
    }
    onClick(value);
  }, [onClick, value]);

  useEffect(() => {
    if (!isSelected) {
      return;
    }
  }, [isSelected, label]);

  return (
    <Button
      onClick={ handleClick }
      className={ cx({
        [classes.badgeButton]: true,
        [classes.badgeButtonFilled]: isSelected,
        [classes.badgeButtonLight]: !isSelected
      }) }
    >
      { label }
    </Button>
  );
};

export default RiskProviderBadgeComponent;
