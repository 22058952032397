export default function ensureDeserialized<T>(
  object: T,
  keys: (keyof T)[]
) {
  const result = keys.reduce(
    (obj, key) => {
      const val = obj[key];

      if (typeof val === 'string') {
        // eslint-disable-next-line no-param-reassign
        obj[key] = JSON.parse(val);
      }

      return obj;
    },
    Object.assign({}, object)
  );

  return result;
}