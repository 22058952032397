import { ReactComponent as BackIcon } from '@/core/navigation/icons/back-arrow.svg';
import { NavigationSidebarItem } from '@/core/navigation/types/navigation';
import { NavigationItemId, ProductArea } from '@/core/navigation/types/navigation';

export const contextSwitchItem: NavigationSidebarItem[] = [
  {
    area: ProductArea.UnifiedExperience,
    unifiedExperienceApplication: 'landing-page',
    title: 'Back to CrossTenant',
    path: '',
    itemId: NavigationItemId.CrossTenant,
    icon: <BackIcon/>
  }
];
