import { FC } from 'react';

import { SummaryItineraryItemWithProfile } from '@/tenant-context/control-travel/types/travel.types';

import TravelItineraryItem from './TravelItineraryItem.component';

type Props = {
  travelDataItem: SummaryItineraryItemWithProfile;
  onClick?: (profileId: string, correlationId: string) => void;
};

const TravelItineraryItemContainer: FC<Props> = ({ travelDataItem, onClick }) => {  
  const { travelSegment: { origin, destination }, profileData } = travelDataItem;
  return (
    <TravelItineraryItem correlationId={ travelDataItem?.correlationId }
      origin={ origin } destination={ destination } profileData={ profileData } onClick={ onClick } />
  );
};

export default TravelItineraryItemContainer;