import { createContext, FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Dispatch } from "@/core/store";

export const SiteInitContext = createContext({});

export const SiteInitContextProvider: FC = ({
  children
}) => {
  const {
    siteLocations: {
      loadAllSites,
      subscribeToConnectedSiteData
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {

    loadAllSites();
    subscribeToConnectedSiteData();
  }, [loadAllSites, subscribeToConnectedSiteData]);

  return (
    <SiteInitContext.Provider value={ 1337 }>
      { children }
    </SiteInitContext.Provider>
  );
};