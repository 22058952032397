/* eslint-disable react/jsx-props-no-spreading */
import { Button, Textarea, TextInput, Title } from "@mantine/core";
import { FC } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import {
  useBuildingsAndFloorsStyles
} from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.styles";
import { FloorListItem } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

import LocationGeoBoundary from "../LocationGeoBoundary";

type Props = {
  formControls:   UseFormReturn<FieldValues, unknown>
  onSubmit: (data: FieldValues) => void
  onFormClear: () => void,
  activeFloor?: FloorListItem
}
const AddFloorFormComponent:FC<Props> = ({
  formControls,
  onSubmit,
  onFormClear,
  activeFloor
}) => {
  const  { register, handleSubmit, formState: { errors } } = formControls;
  const { classes } = useBuildingsAndFloorsStyles();
  return (
    <form className={ classes.addFloorForm } onSubmit={ handleSubmit(onSubmit) }>
      <div className={ classes.addNewFloorTitleContainer }>
        <Title size={ 'h4' }>{ activeFloor ? `Edit Floor` : `Add new floor` }</Title>
      </div>
      <div className={ classes.formRow }>
        <TextInput
          className={ classes.formItem }
          required={ true }
          label={ 'Name' }
          error={ errors?.name?.message }
          { ...register('name', addErrorMessages('Name', {
            required: true
          })) }
        />
        <TextInput
          className={ classes.formItem }
          required={ true }
          label={ 'Code' }
          error={ errors?.code?.message }
          { ...register('code', addErrorMessages('Code', {
            required: true
          })) }
        />
      </div>
      <div className={ classes.formRow }>
        <Textarea
          className={ classes.formItem }
          label={ 'Notes' }
          error={ errors?.note?.message }
          minRows={ 4 }
          { ...register('note', addErrorMessages('Notes', {
            required: false,
            maxLength: 1024
          })) }
        />
      </div>
      <div className={ classes.formRow }>
        <LocationGeoBoundary />
      </div>
      <div className={ classes.buttonContainer }>
        <Button variant="outline" onClick={ onFormClear }>Cancel</Button>
        <Button
          type={ 'submit' }
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default AddFloorFormComponent;
