import { ActionIcon, Badge } from "@mantine/core";
import React, { FC } from "react";

import { useTagChipStyles } from '@/common/components/TagsContainer/ui/TagChip/TagChip.style';
import { ReactComponent as CloseIcon } from '@/common/icons/circle-xmark-outline.svg';

type Props = {
  color: string
  onRemove: ($event: React.MouseEvent<HTMLButtonElement>) => void
  onClick: () => void
  label: string
};



const TagChipComponent: FC<Props> = ({ color, onRemove, onClick, label }) => {
  const { classes } = useTagChipStyles({ color });

  const removeButton = (
    <ActionIcon size="xs" radius="xl" variant="transparent" onClick={ onRemove }>
      <CloseIcon/>
    </ActionIcon>
  );

  return (
    <Badge variant="outline" pr={ 3 } rightSection={ removeButton } className={ classes.root } onClick={ onClick }>
      { label }
    </Badge>
  );
};

export default TagChipComponent;
