import { createStyles } from "@mantine/core";

export const useMaxSecurityEventsSettingsStyles = createStyles((theme) => ({
  riskEventSettingsContainer:{
    fontFamily:theme.fontFamily
  },
  riskLineRiskAlertSwitchContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    paddingTop: '16px',
    paddingBottom: '16px',
    'svg': {
      margin: 0
    }
  },
  riskLineRiskAlertIconNDescription: {
    display: 'flex',
    justifyContent: 'space-between',
    gap:'14px',
    alignItems: 'center',
    svg: {
      width: '24px',
      height: '24px'
    }
  },
  risklineIcon:{
    marginLeft:'20px',
    width:'24px',
    height:'24px'
  },
  riskLineRiskAlertDescription: {
    fontWeight: theme.other.fw.bold,
    fontSize: theme.headings.sizes.h4.fontSize,
    lineHeight: '120%',
    color: theme.colors.neutral[1]
  },
  riskLineRiskAlertSubTopic: {
    fontWeight: theme.other.fw.bold,
    fontSize: theme.fontSizes.md,
    lineHeight: '120%',
    marginLeft: '8px',
    marginTop: '48px'
  },
  riskCategoryType: {
    fontFamily:theme.fontFamily,
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.md,
    lineHeight: '150%',
    marginLeft: '3px'
  },
  orderList: {
    marginTop: '32px'
  },
  buttonSeparator: {
    marginTop: '25px',
    marginBottom: '40px',
    left: '0%',
    right: '99.7%',
    top: '100%',
    bottom: '-33600%',
    background: theme.colors.neutral[7],
    opacity: 0.3
  },
  button: {
    background: theme.colors.info[0],
    height: 48
  },
  checkBoxLabel: {
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.sm,
    alignItems:'flex-start',
    lineHeight: '150%',
    display:'flex',
    gap:'6px'

  },
  listContent: {
    display: 'flex',
    justifyContent: 'space-between',
    gap:'16px',
    marginTop: '24px'
  },
  sliderSeparator: {
    marginTop: '25px',
    marginBottom: '40px',
    left: '0%',
    right: '99.7%',
    top: '100%',
    bottom: '-33600%',
    background: theme.colors.neutral[7],
    opacity: 0.3
  },
  sectionHeading: {
    fontWeight: theme.other.fw.semibold,
    fontFamily:theme.fontFamily,
    fontSize: theme.headings.sizes.h6.fontSize,
    lineHeight: theme.headings.sizes.h6.lineHeight,
    color:theme.colors.neutral[1],
    marginTop:'48px'
  },
  divider:{
    marginTop:'32px'
  }
}));
