import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import useDrawer from '@/common/hooks/useDrawer';
import usePermission from "@/common/hooks/usePermission";
import { Dispatch, RootState } from "@/core/store";
import { DrawingFilterTypes, GeofenceListTabsTypes } from "@/tenant-context/control-draw/types/draw";

import { GeofenceRequiredPoliciesConfig } from "../../config/GeofenceRequiredPolicies.config";
import DrawControl from './DrawControl.component';

const DrawControlContainer: FC = () => {

  const [allActivePage, setAllActivePage] = useState(0);
  const [privateActivePage, setPrivateActivePage] = useState(0);

  const geofenceTypeFilter = useSelector((state: RootState) => state.geofence.geofenceTypeFilter);
  const shortGeofenceListFilter = useSelector((state: RootState) => state.geofence.shortGeofenceListFilterValue);
  const currentUserEmail = useSelector((state: RootState) => state.userProfile?.email);
  const activeTab = useSelector((state: RootState) => state.geofence.activeTab);
  const allShortGeofence = useSelector((state: RootState) => state.geofence.allShortGeofenceList);
  const privateShortGeofence = useSelector((state: RootState) => state.geofence.privateShortGeofenceList);

  const { isTimeTravelActive } = useSelector(
    (state: RootState) => state.rankingSettings
  );

  const {
    geofence: {
      loadShortGeofenceList,
      SET_PRIVATE_SHORT_GEOFENCE_LIST,
      SET_ALL_SHORT_GEOFENCE_LIST,
      SET_SHORT_GEOFENCE_LIST_FILTER_VALUE,
      SET_GEOFENCE_TYPE_FILTER
    }
  } = useDispatch<Dispatch>();

  const navigate = useNavigate();
  const isGeoFencesPermissionsAvailable = usePermission(GeofenceRequiredPoliciesConfig.GEO_FENCE_MANAGE);

  const { open } = useDrawer('create-geofence', 'left');

  const selectDrawingFilter = useCallback((value: DrawingFilterTypes) => {
    SET_GEOFENCE_TYPE_FILTER(value);
    SET_SHORT_GEOFENCE_LIST_FILTER_VALUE({ ...shortGeofenceListFilter, type: value });
  }, [SET_SHORT_GEOFENCE_LIST_FILTER_VALUE, SET_GEOFENCE_TYPE_FILTER, shortGeofenceListFilter]);

  const handleSearchFilterChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    SET_SHORT_GEOFENCE_LIST_FILTER_VALUE({ ...shortGeofenceListFilter, name: event.target.value });
  }, 1000);

  const createNewGeofenceButtonClickHandler = useCallback(() => {
    open();
  }, [open]);

  const geofenceManagementButtonClickHandler = useCallback(() => {
    navigate('/geofence/manage');
  }, [navigate]);


  const allPaginationClickHandler = useCallback(() => {
    setAllActivePage((prevState) => prevState + 1);
    // eslint-disable-next-line
  }, [setAllActivePage, allActivePage]);

  const privatePaginationClickHandler = useCallback(() => {
    setPrivateActivePage((prevState) => prevState + 1);
    // eslint-disable-next-line
  }, [setPrivateActivePage, privateActivePage]);

  const isSeeMoreButtonVisible = useMemo(() => {
    if (
      activeTab === GeofenceListTabsTypes.ALL_GEOFENCE
      &&
      ((allShortGeofence && (allShortGeofence?.currentPage + 1 === allShortGeofence?.totalPages))
        ||
        allShortGeofence?.currentPage === undefined)
    ) {
      return false;
    }

    return !(activeTab === GeofenceListTabsTypes.MY_GEOFENCE
      &&
      ((privateShortGeofence && (privateShortGeofence?.currentPage + 1 === privateShortGeofence?.totalPages))
        ||
        privateShortGeofence?.currentPage === undefined));

  }, [activeTab, allShortGeofence, privateShortGeofence]);

  useEffect(() => {
    SET_PRIVATE_SHORT_GEOFENCE_LIST(undefined);
    SET_ALL_SHORT_GEOFENCE_LIST(undefined);

    if (allActivePage === 0) {
      if (isGeoFencesPermissionsAvailable){
        loadShortGeofenceList({
          page: allActivePage,
          type: geofenceTypeFilter,
          isPrivate: false,
          size: 20,
          sort: { colId: 'name', sort: 'asc' }
        });
      }

    } else {
      setAllActivePage(0);

    }
    if (privateActivePage === 0) {
      if (!currentUserEmail) {
        return;
      } else {
        if (isGeoFencesPermissionsAvailable) {
          loadShortGeofenceList({
            myGeoFences: currentUserEmail,
            page: privateActivePage,
            type: geofenceTypeFilter,
            isPrivate: true,
            size: 20,
            sort: { colId: 'name', sort: 'asc' }
          });
        }
      }
    } else {
      setPrivateActivePage(0);
    }
    // eslint-disable-next-line
  }, [shortGeofenceListFilter]);

  useEffect(() => {
    if (!currentUserEmail) {
      return;
    } else {
      if (isGeoFencesPermissionsAvailable) {
        loadShortGeofenceList({
          myGeoFences: currentUserEmail,
          page: privateActivePage,
          type: geofenceTypeFilter,
          isPrivate: true,
          size: 20,
          sort: { colId: 'name', sort: 'asc' }
        });
      }
    }
  }, [loadShortGeofenceList, privateActivePage, currentUserEmail, geofenceTypeFilter, isGeoFencesPermissionsAvailable]);

  useEffect(() => {
    if (isGeoFencesPermissionsAvailable) {
      loadShortGeofenceList({
        page: allActivePage,
        type: geofenceTypeFilter,
        isPrivate: false,
        size: 20,
        sort: { colId: 'name', sort: 'asc' }
      });
    }
  }, [loadShortGeofenceList, allActivePage, geofenceTypeFilter, isGeoFencesPermissionsAvailable]);

  return (
    <DrawControl
      selectDrawingFilter={ selectDrawingFilter }
      handleSearchFilterChange={ handleSearchFilterChange }
      createNewGeofenceButtonClickHandler={ createNewGeofenceButtonClickHandler }
      geofenceManagementButtonClickHandler={ geofenceManagementButtonClickHandler }
      allPaginationClickHandler={ allPaginationClickHandler }
      privatePaginationClickHandler={ privatePaginationClickHandler }
      activeTab={ activeTab }
      isSeeMoreButtonVisible={ isSeeMoreButtonVisible }
      disabled={ isTimeTravelActive }
      tooltip={ "Cannot access when not in live mode" }
    />
  );
};

export default DrawControlContainer;
