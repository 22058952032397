import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { CustomerLocationLayerType } from "@/tenant-context/visualisation-customer-locations/types/customer-location.types";

export const CustomerLocationLayerConfig: Array<CustomerLocationLayerType> = [
  {
    id: `r__customerLocationsLayer-hotels`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Hotel'],
    layout: {
      'icon-image': 'static-location-hotel-marker',
      'icon-size': 0.2
    },
    toggleableLayerType: ToggleableLayerType.Hotels,
    fieldName: 'Hotel'
  },
  {
    id: `r__customerLocationsLayer-airports`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Airport'],
    layout: {
      'icon-image': 'static-location-airport-marker',
      'icon-size': 0.2
    },
    toggleableLayerType: ToggleableLayerType.Airports,
    fieldName: 'Airport'
  },
  {
    id: `r__customerLocationsLayer-general-accommodations`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'GeneralAccommodation'],
    layout: {
      'icon-image': 'static-location-business-accommodation-marker',
      'icon-size': 0.2
    },
    toggleableLayerType: ToggleableLayerType.CustomerGeneralAccommodations,
    fieldName: 'GeneralAccommodation'
  },
  {
    id: `r__customerLocationsLayer-heliports`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Heliport'],
    layout: {
      'icon-image': 'static-location-heliport-marker',
      'icon-size': 0.2
    },
    toggleableLayerType: ToggleableLayerType.CustomerHeliports,
    fieldName: 'Heliport'
  },
  {
    id: `r__customerLocationsLayer-harbours`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Harbour'],
    layout: {
      'icon-image': 'static-location-harbour-marker',
      'icon-size': 0.2
    },
    toggleableLayerType: ToggleableLayerType.CustomerHarbors,
    fieldName: 'Harbour'
  },
  {
    id: `r__customerLocationsLayer-rail-stations`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Rail Station'],
    layout: {
      'icon-image': 'static-location-rail-station-marker',
      'icon-size': 0.2
    },
    toggleableLayerType: ToggleableLayerType.CustomerRailStations,
    fieldName: 'Rail Station'
  },
  {
    id: `r__customerLocationsLayer-bus-stations`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Bus Station'],
    layout: {
      'icon-image': 'static-location-bus-station-marker',
      'icon-size': 0.2
    },
    toggleableLayerType: ToggleableLayerType.CustomerBusStations,
    fieldName: 'Bus Station'
  },
  {
    id: `r__customerLocationsLayer-car-hires`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Car Hire'],
    layout: {
      'icon-image': 'static-location-car-hires-marker',
      'icon-size': 0.2
    },
    toggleableLayerType: ToggleableLayerType.CustomerCarHires,
    fieldName: 'Car Hire'
  },
  {
    id: `r__customerLocationsLayer-embarkation-points`,
    type: 'symbol',
    filter: ['==', 'subCategory', 'Embarkation Disembarkation Point'],
    layout: {
      'icon-image': 'static-location-embarkation-marker',
      'icon-size': 0.2
    },
    toggleableLayerType: ToggleableLayerType.CustomerEmbarkationPoints,
    fieldName: 'Embarkation Disembarkation Point'
  }
];
