import { Overlay } from "@mantine/core";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { RootState } from "@/core/store";
import { genericColorPalette } from "@/core/styles/mantine/palettes";

const RouterOverlay: FC = () => {

  const isNavigationExpanded = useSelector((state: RootState) => state.commonData.isNavigationExpanded);
  const location = useLocation();

  const isInGlobalMap = location.pathname === '/';

  if (!isNavigationExpanded || isInGlobalMap) {
    return null;
  }

  return (
    <Overlay color={ genericColorPalette.neutral[9] } opacity={ 0.5 } style={ { zIndex: 190 } }/>
  );
};

export default RouterOverlay;
