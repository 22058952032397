import { createStyles } from "@mantine/core";

import { genericColorPalette } from '@/core/styles/mantine/palettes';

type Args ={
  color: string
  isSelected?: boolean
}

export const useTagChipStyles = createStyles((theme, { color, isSelected }: Args) => ({
  root: {
    height: 37,
    padding: '8px !important',
    borderRadius: 99,
    border: `2px solid ${color}`,
    color: theme.white,
    fontWeight: 'normal',
    textTransform: 'none',
    fontSize: 14,
    cursor: 'pointer',
    gap: 8,
    backgroundColor: isSelected ? theme.colors.neutral[7] : 'transparent',
    transitionDuration: '300ms',
    'span': {
      margin: 0,
      '&:first-child': {
        paddingLeft: 8
      }
    },
    '&:hover': {
      backgroundColor: genericColorPalette.neutral[9]
    }
  }
}));
