import { FC } from "react";
import { FieldValues, useForm } from "react-hook-form";

import CreateChecklistItemModalComponent
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/CreateChecklistItemModal/CreateChecklistItemModal.component";
import { ChecklistItem } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

type Props = {
    cancelButtonHandler: () => void
    createHandler: (data: FieldValues) => void
    updateHandler:  (data: FieldValues) => void
    activeChecklistItem?: ChecklistItem,
    isAllowedToUpdate: boolean
}

const CreateChecklistItemModal: FC<Props> = ({
  cancelButtonHandler,
  createHandler,
  updateHandler,
  activeChecklistItem,
  isAllowedToUpdate
}) => {

  const formControls = useForm({ mode: 'onChange' });

  return (
    <CreateChecklistItemModalComponent
      createHandler={ createHandler }
      cancelButtonHandler={ cancelButtonHandler }
      updateHandler={ updateHandler }
      formControls={ formControls }
      isAllowedToUpdate={ isAllowedToUpdate }
      activeChecklistItem={ activeChecklistItem }
    />
  );
};

export default CreateChecklistItemModal;