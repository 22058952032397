import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const useCreateRoleModalStyles = createStyles((theme) => ({
  wrapper: {
    maxWidth: '100%',
    marginTop: '-24px'
  },
  overviewContainer: {
    width: '35%'
  },
  step: {
    marginBottom: '40px',
    color: theme.other.semantic.text.secondary
  },
  buttonContainer: {
    marginTop: '24px',
    display: 'flex',
    width: '100%',
    justifyContent: "flex-end"
  },
  submitButtonContainer: {
    display: "flex",
    alignSelf: 'end',
    gap: '16px'
  },
  colorPicker: {
    position: 'absolute',
    top: '-22px',
    right: 0,
    zIndex: 1
  },
  colorPickerContainer: {
    position: 'relative',
    margin: '16px 0'
  },
  colorPickerLabel: {
    fontSize: theme.fontSizes.md,
    color: theme.other.semantic.text.secondary,
    lineHeight: theme.other.lh.s,
    marginBottom: '8px'
  },
  colorPickerLabelAsterisk: {
    color: theme.other.semantic.accent["2"],
    marginLeft: '4px'
  },
  pickedColor: {
    width: '144px',
    height: '48px'
  },
  checkbox: {
    marginTop: '24px'
  },
  descriptionLabel:{
    marginTop: '8px',
    fontSize: 14,
    color: theme.colors.neutral[6],
    fontWeight: 500,
    display: "block"
  },
  section: {
    marginBottom: 40,
    [`& .${CSS_KEY}-Textarea-input`]:{
      minHeight: 200
    }
  },
  sectionTitle: {
    fontWeight: theme.other.fw.bold,
    marginBottom: '16px'
  },
  switch: {
    [`& .${CSS_KEY}-Switch-body`]:{
      width: '100%',
      justifyContent: 'space-between'
    },
    [`& .${CSS_KEY}-Switch-label`]:{
      fontWeight: theme.other.fw.bold,
      fontFamily: theme.fontFamily,
      fontSize: theme.fontSizes.sm,
      lineHeight: "133%",
      display: 'flex',
      alignItems: 'center'
    }
  }
}));