import { FC } from 'react';

import { useGeofenceMapStatsCardStyles } from '@/tenant-context/control-draw/components/GeofenceMapStatsCards/GeofenceMapStatsCards.styles';

type Props = {
  peopleCount: number;
  locationsCount: number;
  sitesCount: number;
  isHidden?: boolean;
}

export const GeofenceMapStatsCardsComponent: FC<Props> = ({
  peopleCount,
  locationsCount,
  sitesCount,
  isHidden
}) => {
  const { classes } = useGeofenceMapStatsCardStyles();

  if (isHidden) {
    return null;
  }

  return (
    <div className={ classes.geofenceStats } hidden={ isHidden }>
      <h3 className={ classes.heading }>Inside geofence now</h3>
      <div className={ classes.geofenceStatsCardContainer }>
        <div className={ classes.statsCard }>
          <div className={ classes.count }>{ peopleCount }</div>
          <div className={ classes.text }>PEOPLE</div>
        </div>
        <div className={ classes.statsCard }>
          <div className={ classes.count }>{ locationsCount }</div>
          <div className={ classes.text }>LOCATIONS</div>
        </div>
        <div className={ classes.statsCard }>
          <div className={ classes.count }>{ sitesCount }</div>
          <div className={ classes.text }>SITES</div>
        </div>
      </div>
    </div>
  );
};

export default GeofenceMapStatsCardsComponent;
