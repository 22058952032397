import area from '@turf/area';
import length from '@turf/length';
import { polygonToLine } from '@turf/polygon-to-line';
import { lineString, polygon } from '@turf/turf';
import { createContext, FC, useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';

import useContextValue from "@/common/hooks/useContextValue";
import { RootState } from '@/core/store';
import useFeaturesInSideGeofence from '@/tenant-context/control-draw/hooks/useFeaturesInSideGeofence';
import { GeofenceProperty, GeoPolygon, WithinGeofenceData } from '@/tenant-context/control-draw/types/draw';

export type GeofenceContextType = {
  currentGeoFences?: GeoPolygon[],
  currentPopupCoordinates: [number, number]
  isShownGeofencePopup: boolean,
  popupProperties: GeofenceProperty[],
  insideGeofencePeopleCount: number,
  insideGeofenceLocationsCount: number,
  insideGeofenceSitesCount: number
};

export const GeofenceContext = createContext<GeofenceContextType>({} as GeofenceContextType);
export const GeofenceContextProvider: FC = ({
  children
}) => {

  const {
    currentGeoFences,
    currentGeoJson
  } = useSelector((state: RootState) => state.geofence);

  const customerLocationsGeoData = useSelector((state: RootState) => state.customerLocations.geoData);
  const peopleLocationGeoData = useSelector((state: RootState) => state.peopleLocations.geoData);
  const siteLocationsGeoData = useSelector((state: RootState) => state.siteLocations.geoData);

  const { numberOfPointsWithinGeofence } = useFeaturesInSideGeofence();

  const roundOff = useCallback((input: number) => {
    return Math.round(input * 100) / 100;
  }, []);

  const [coordinates, setCoordinates] = useState<[number, number]>([0, 0]);
  const [popupProperties, setPopupProperties] = useState<GeofenceProperty[]>([]);
  const [isShownGeofencePopup, setIsShownGeofencePopup] = useState<boolean>(false);
  const [withinGeofenceData, setWithinGeofenceData] = useState<WithinGeofenceData>({
    locationCount: 0,
    peopleLocationCount: 0,
    siteLocationsCount: 0
  });

  const calculatePopupLocation = useCallback((geoFence?: GeoPolygon[]): [number, number] | null => {
    if (geoFence && geoFence.length > 0) {
      return geoFence[0].geometry[0];
    }

    return null;
  }, []);

  useEffect(() => {
    const popupLocation = calculatePopupLocation(currentGeoFences);
    if (popupLocation !== null) {
      setCoordinates(popupLocation);
    }
  }, [calculatePopupLocation, currentGeoFences]);

  useEffect(() => {
    if (currentGeoFences.length > 0) {
      setIsShownGeofencePopup(true);
    } else {
      setIsShownGeofencePopup(false);
    }
  }, [currentGeoFences]);

  useEffect(() => {
    const geofenceProperties: GeofenceProperty[] = [];
    if (currentGeoFences.length > 0) {
      const geofence = currentGeoFences[0];

      if (geofence.type === 'LINE') {
        const line = lineString(geofence?.geometry);
        const lengthOfLine = length(line, { units: 'kilometers' });
        geofenceProperties.push({
          name: 'Length',
          value: roundOff(lengthOfLine),
          unit: 'Km'
        });
      }

      if (geofence.type === 'CIRCLE' || geofence.type === 'POLYGON') {
        const geofenceTurfPolygon = polygon([geofence?.geometry]);
        const areaOfGeofence = roundOff(area(geofenceTurfPolygon)/1000000);
        geofenceProperties.push({
          name: 'Area',
          value: areaOfGeofence,
          unit: 'Km2'
        });

        if (geofence.type === 'CIRCLE') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const radius = currentGeoJson?.properties?.radiusInKm;
          geofenceProperties.push({
            name: 'Diameter',
            value: roundOff(radius * 2),
            unit: 'Km'
          });

        } else if (geofence.type === 'POLYGON') {
          const line = polygonToLine(geofenceTurfPolygon);
          const lengthOfLine = length(line, { units: 'kilometers' });
          geofenceProperties.push({
            name: 'Perimeter',
            value: roundOff(lengthOfLine),
            unit: 'Km'
          });
        }
      }

      setPopupProperties(geofenceProperties);
    }
  }, [currentGeoFences, currentGeoJson, roundOff]);

  useEffect(() => {
    if (currentGeoJson) {
      const inSideGeofenceData = {
        locationCount: 0,
        peopleLocationCount: 0,
        siteLocationsCount: 0
      };

      if (customerLocationsGeoData) {
        inSideGeofenceData.locationCount =
          numberOfPointsWithinGeofence(customerLocationsGeoData, currentGeoJson);
      }
      if (peopleLocationGeoData) {
        inSideGeofenceData.peopleLocationCount =
          numberOfPointsWithinGeofence(peopleLocationGeoData, currentGeoJson);
      }
      if (siteLocationsGeoData) {
        inSideGeofenceData.siteLocationsCount =
          numberOfPointsWithinGeofence(siteLocationsGeoData, currentGeoJson);
      }

      setWithinGeofenceData(inSideGeofenceData);
    }
  }, [
    currentGeoJson,
    customerLocationsGeoData,
    numberOfPointsWithinGeofence,
    peopleLocationGeoData,
    siteLocationsGeoData
  ]);

  return (
    <GeofenceContext.Provider value={ useContextValue({
      currentGeoFences: currentGeoFences,
      currentPopupCoordinates: coordinates,
      isShownGeofencePopup: isShownGeofencePopup,
      popupProperties: popupProperties,
      insideGeofencePeopleCount: withinGeofenceData?.peopleLocationCount,
      insideGeofenceLocationsCount: withinGeofenceData?.locationCount,
      insideGeofenceSitesCount: withinGeofenceData?.siteLocationsCount
    }) }>
      { children }
    </GeofenceContext.Provider>
  );
};

export default GeofenceContextProvider;
