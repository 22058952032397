/* eslint-disable react/jsx-props-no-spreading */
import { FC, useContext } from "react";

import { TestableComponent } from "@/common/types/testable-component";
import AddAndUpdatePeopleGroupForm
  from "@/tenant-context/common/components/CreateAndUpdatePeopleGroupForm/CreateAndUpdatePeopleGroupForm.component";
import {
  ProfilePeopleGroupsModalContext
} from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/context/ProfilePeopleGroupsModal.context";

export type AddProfileToNewGroupFormData = {
  group_name: string,
  is_active: boolean,
  isApplicable_to_all_services: boolean,
  is_new_group: number,
  applicable_services: string[]
  group_description: string
};

const AddToNewGroup: FC<TestableComponent> = ({ dataTestId }) => {
  const { formControls, allServices } = useContext(ProfilePeopleGroupsModalContext);

  return (
    <AddAndUpdatePeopleGroupForm
      formControls={ formControls }
      allServices={ allServices }
      dataTestId={ dataTestId }
    />
  );
};

export default AddToNewGroup;
