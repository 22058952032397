import { useContext } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import BuildingsAndFloorsTabComponent
  from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.component";
import {
  LocationInfoContext
} from "@/tenant-context/control-location-configuration/context/LocationInfo.context";

const BuildingsAndFloorsTab = () => {

  const {
    isAddBuildingFormOpened,
    buildingView,
    setBuildingView
  } = useContext(LocationInfoContext);

  const { isBuildingLoading } = useSelector((state: RootState) => state.manageLocations);

  return (
    <BuildingsAndFloorsTabComponent
      isAddBuildingFormOpened={ isAddBuildingFormOpened }
      isBuildingView={ buildingView }
      setBuildingView={ setBuildingView }
      isLoading={ isBuildingLoading }
    />
  );
};

export default BuildingsAndFloorsTab;
