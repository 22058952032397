import { FC, useCallback, useRef, useState } from "react";

import IconSearchSliderComponent from "./IconSearchSlider.component";

type Props = {
  onChangeSearch: (val: string) => void,
  className?: string
};

const IconSearchSlider: FC<Props> = (props) => {

  const [searchBoxExpanded, setSearchBoxExpanded] = useState<boolean>(false);
  const searchBoxRef = useRef<HTMLInputElement>(null);

  const handleSearchBoxShrink = useCallback(() => {
    if (searchBoxExpanded) {
      setSearchBoxExpanded(false);
    }
  }, [searchBoxExpanded]);
    
  const handleSearchBoxExpanded = useCallback(() => {
    if (!searchBoxExpanded) {
      setSearchBoxExpanded(true);
      searchBoxRef.current?.focus();
    }
  }, [searchBoxExpanded]);

    
  return (
    <IconSearchSliderComponent
      onSearchBoxShrink={ handleSearchBoxShrink }
      onSearchBoxExpand={ handleSearchBoxExpanded }
      isSearchBoxExpanded={ searchBoxExpanded }
      searchBoxRef={ searchBoxRef }
      onChangeSearch={ props.onChangeSearch }
      customClass={ props.className || '' }
    />
  );
};

export default IconSearchSlider;