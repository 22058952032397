import { Menu } from "@mantine/core";
import { FC } from "react";

import usePermission from '@/common/hooks/usePermission';
import useUnauthorizedModal from '@/common/hooks/useUnauthorizedModal';
import { ReactComponent as ArchiveIcon } from '@/common/icons/archive-grey.svg';
import { ReactComponent as DeleteIcon } from '@/common/icons/delete-grey.svg';
import { ReactComponent as EllipsisIcon } from '@/common/icons/ellipsis.svg';
import { ReactComponent as EditIcon } from '@/common/icons/pen2square-grey.svg';
import { ReactComponent as PrivateIcon } from '@/common/icons/private.svg';
import { ReactComponent as ShareIcon } from '@/common/icons/share.svg';
import {
  useGeofenceListItemStyles
} from "@/tenant-context/control-draw/components/GeofenceList/components/GeofenceListItem/GeofenceListItem.styles";
import { GeofenceRequiredPoliciesConfig } from '@/tenant-context/control-draw/config/GeofenceRequiredPolicies.config';


type Props = {
  isEnablePrivate: boolean
  name: string
  onEditIconClickHandler: () => void
  onGeofenceListItemClickHandler: () => void
  onDeleteClickHandler: () => void
  onArchiveIconClickHandler: () => void
}

const GeofenceListItem: FC<Props> = ({
  isEnablePrivate,
  name,
  onEditIconClickHandler,
  onGeofenceListItemClickHandler,
  onDeleteClickHandler,
  onArchiveIconClickHandler

}) => {

  const { classes } = useGeofenceListItemStyles();

  const isPermittedToEditGeofence = usePermission(
    GeofenceRequiredPoliciesConfig.LONE_WORKER_MANAGE_GEO_FENCE_FULL_ACCESS
  );

  const handleUnauthorizedAction = useUnauthorizedModal();

  return (
    <div className={ classes.container }>
      <button className={ classes.titleContainer } onClick={ onGeofenceListItemClickHandler }>
        <div className={ classes.iconContainer }>
          { !isEnablePrivate && <ShareIcon/> }
          { isEnablePrivate && <PrivateIcon/> }
        </div>
        <div className={ classes.title }>
          { name }
        </div>
      </button>
      <Menu shadow="md" width={ 200 }>
        <Menu.Target>
          <div className={ classes.ellipsis }><EllipsisIcon/></div>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            className={ isPermittedToEditGeofence ? undefined : classes.uaMenuItem }
            rightSection={ <EditIcon/> }
            onClick={ isPermittedToEditGeofence ? onEditIconClickHandler : handleUnauthorizedAction }
          >
            Edit
          </Menu.Item>
          <Menu.Item
            className={ isPermittedToEditGeofence ? undefined : classes.uaMenuItem }
            rightSection={ <ArchiveIcon/> }
            onClick={ isPermittedToEditGeofence ? onArchiveIconClickHandler : handleUnauthorizedAction }
          >
            Archive
          </Menu.Item>
          <Menu.Item
            className={ isPermittedToEditGeofence ? undefined : classes.uaMenuItem }
            rightSection={ <DeleteIcon/> }
            onClick={ isPermittedToEditGeofence ? onDeleteClickHandler : handleUnauthorizedAction }
          >
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default GeofenceListItem;
