import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { RootState, RootStateWithLoading } from '@/core/store';
import CityRiskReportsPageComponent
  from '@/tenant-context/control-reports/components/CityRiskReportsPage/CityRiskReportsPage.component';
import { CityRiskData, CityRiskProvider } from '@/tenant-context/control-reports/types/city-risk-reports';

const CityRiskReportsPageContainer: FC = () => {
  const {
    cityRiskDataReports,
    selectedRiskProvider,
    citiesList,
    selectedDetailedCityReport,
    cityRiskProvidersList
  } = useSelector((state: RootState) => state.cityRiskReports);

  const isCitySummaryLoading = useSelector(
    (state: RootStateWithLoading) => state.loading.effects.cityRiskReports.fetchCityRiskDataReports
  );

  const [isDrawerExpanded, setIsDrawerExpanded] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(12);

  const {
    cityRiskReports: {
      fetchCityRiskDataReports,
      loadSelectedCityReport,
      fetchCityRiskProvidersList,
      SET_SELECTED_RISK_PROVIDER,
      SET_SELECTED_DETAILED_CITY_REPORT
    }
  } = useDispatch();

  const handleDrawerClose = useCallback(() => {
    setIsDrawerExpanded(false);
    SET_SELECTED_DETAILED_CITY_REPORT(undefined);
  }, [SET_SELECTED_DETAILED_CITY_REPORT]);

  const toggleDrawerExpand = useCallback(() => {
    setIsDrawerExpanded(!isDrawerExpanded);
  }, [isDrawerExpanded]);

  const handleSearchStringChange = useCallback((value: string) => {
    setSearchString(value);
    setPageSize(12);
  }, []);

  const handleLoadMore = useCallback(() => {
    setPageSize(pageSize + 12);
  }, [pageSize]);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    if (isCitySummaryLoading) {
      return;
    }

    const { currentTarget } = event;
    const isScrollAtBottom = currentTarget.scrollTop + currentTarget.clientHeight >= (currentTarget.scrollHeight * 0.9);
    if (isScrollAtBottom) {
      handleLoadMore();
    }
  }, [handleLoadMore, isCitySummaryLoading]);

  const filteredCityRiskDataReports = useMemo(() => {
    return cityRiskDataReports.filter((cityRiskData) => {
      return cityRiskData.cityName.toLowerCase().includes(searchString.toLowerCase()) ||
        cityRiskData.countryName?.toLowerCase().includes(searchString.toLowerCase());
    }).splice(0, pageSize);
  }, [cityRiskDataReports, pageSize, searchString]);

  const setSelectedRiskProvider = useCallback((value: CityRiskProvider) => {
    SET_SELECTED_RISK_PROVIDER(value);
    fetchCityRiskDataReports(value);
    setPageSize(12);
  }, [SET_SELECTED_RISK_PROVIDER, fetchCityRiskDataReports]);

  const handleOpenDetailedReportDrawer = useCallback((cityRiskData: CityRiskData) => {
    SET_SELECTED_DETAILED_CITY_REPORT(cityRiskData);
    loadSelectedCityReport(cityRiskData);
  }, [SET_SELECTED_DETAILED_CITY_REPORT, loadSelectedCityReport]);

  useEffect(() => {
    fetchCityRiskProvidersList();
  }, [fetchCityRiskProvidersList]);

  useEffect(() => {
    if (!cityRiskProvidersList || cityRiskProvidersList.length === 0) {
      return;
    }
    setSelectedRiskProvider(cityRiskProvidersList[0]);
    SET_SELECTED_RISK_PROVIDER(cityRiskProvidersList[0]);
  }, [setSelectedRiskProvider, cityRiskProvidersList, SET_SELECTED_RISK_PROVIDER]);

  return <CityRiskReportsPageComponent
    selectedRiskProvider={ selectedRiskProvider }
    onRiskProviderChange={ setSelectedRiskProvider }
    onDrawerClose={ handleDrawerClose }
    cityRiskDataList={ filteredCityRiskDataReports }
    selectedCityRiskReport={ selectedDetailedCityReport }
    setSelectedCityReport={ handleOpenDetailedReportDrawer }
    isDrawerExpanded={ isDrawerExpanded }
    toggleDrawerExpand={ toggleDrawerExpand }
    onSearchStringChange={ handleSearchStringChange }
    allCities={ citiesList }
    isCitySummaryLoading={ isCitySummaryLoading }
    cityRiskProvidersList={ cityRiskProvidersList }
    handleScroll={ handleScroll }
  />;
};

export default CityRiskReportsPageContainer;
