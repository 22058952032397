import { Menu, TextInput, Tooltip } from "@mantine/core";
import { Loader } from "@mantine/core";
import React, { FC } from "react";

import { ReactComponent as SearchIcon } from "@/common/icons/search.svg";
import SearchInputRightSection
  from "@/tenant-context/control-profile/components/ui/ProfileAccessCardSearch/components/SearchInputRightSection";
import {
  useProfileAccessCardSearchStyles
} from "@/tenant-context/control-profile/components/ui/ProfileAccessCardSearch/ProfileAccessCardSearch.styles";
import { AccessCard } from "@/tenant-context/control-profile/types/profile";

type Props = {
  onSelect: (accessCard: AccessCard) => void
  searchResults?: AccessCard[]
  searchValue: string | undefined
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSearchResetButton: () => void
  isAccessCardListSearchResultsLoading: boolean
  isDisabled: boolean
}

const ProfileAccessCardSearch: FC<Props> = ({
  onSelect,
  handleSearchResetButton,
  searchResults,
  onSearchChange,
  searchValue,
  isAccessCardListSearchResultsLoading,
  isDisabled
}) => {

  const { classes } = useProfileAccessCardSearchStyles();


  return (
    <>
      <div className={ classes.searchContainer }>
        <Tooltip
          label={ isDisabled && 'This license does not have access to this feature, please get in touch with your administrator.' }
          withinPortal>
          <TextInput
            className={ classes.searchInput }
            value={ searchValue }
            onChange={ onSearchChange }
            icon={ <SearchIcon/> }
            rightSection={ <SearchInputRightSection handleSearchResetButton={ handleSearchResetButton }/> }
            disabled={ isDisabled }
          />
        </Tooltip>
      </div>
      <div className={ classes.dropdown }>
        { isAccessCardListSearchResultsLoading && <div className={ classes.loaderContainer }>
          <Loader/>
        </div> }
        <Menu>
          { searchResults?.length === 0 && !isAccessCardListSearchResultsLoading &&
            <Menu.Item className={ classes.menuItem }>No search results</Menu.Item> }
          { searchResults?.map((item) => {
            return (
              <Menu.Item className={ classes.menuItem } key={ item._id }
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={ () => onSelect(item) }>
                { item?.manufactureId &&
                  <div
                    className={ classes.menuItemRow }>Manufacture
                    ID: { item?.manufactureId } ({ item?.secondaryId + ' ' + item?.provider + ' ' + item?.accessCardType + ' ' + item?.friendlyName } ) </div> }
              </Menu.Item>
            );
          }) }
        </Menu>
      </div>
    </>
  );
};

export default ProfileAccessCardSearch;
