import type { FeatureCollection } from "geojson";

type CommonVisualisableState<
  C extends FeatureCollection
> = {
  geoData: C
};

export type Visualizable<
  S,
  C extends FeatureCollection = FeatureCollection
> = S & CommonVisualisableState<C>;

const createCommonState = <
  C extends FeatureCollection
>(): CommonVisualisableState<C> => ({
    geoData: {
      type: "FeatureCollection",
      features: [] as unknown[]
    } as C
  });

export type CommonVisualisableReducers<
  S,
  C extends FeatureCollection
> = {
  SET_GEO_DATA: (
    state: Visualizable<S, C>,
    payload: C
  ) => Visualizable<S, C>,

  CLEAR_GEO_DATA: (
    state: Visualizable<S, C>,
    payload: void
  ) => Visualizable<S, C>
};

const createCommonReducers = <
  S,
  C extends FeatureCollection
>(): CommonVisualisableReducers<S, C> => ({
    SET_GEO_DATA(
      state: Visualizable<S, C>,
      payload: C
    ): Visualizable<S, C> {
      return {
        ...state,
        geoData: payload
      };
    },

    CLEAR_GEO_DATA(
      state: Visualizable<S, C>,
      _: void
    ): Visualizable<S, C> {
      const updatedGeoData: C = {
        type: 'FeatureCollection',
        features: [] as C['features']
      } as C;

      const updatedState: Visualizable<S, C> = {
        ...state,
        geoData: updatedGeoData
      };

      return updatedState;
    }
  });

export const withVisualisableTraits = <
  C extends FeatureCollection = FeatureCollection
>() => <
  N extends string,
  S,
  R,
  E
>(stateInit: {
  name: N,
  state: S,
  reducers: R,
  effects: E
}): {
  name: N,
  state: Visualizable<S, C>,
  reducers: R & CommonVisualisableReducers<S, C>,
  effects: E
} => {
  return {
    ...stateInit,

    state: {
      ...(createCommonState<C>()),
      ...stateInit.state
    },

    reducers: {
      ...(createCommonReducers<S, C>()),
      ...stateInit.reducers
    }
  } as const;
};