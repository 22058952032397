import { FC } from "react";

import CompanyDetailsModal from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/CompanyDetailsModal/CompanyDetailsModal.component";
import { CompanyDetailsModalContextProvider } from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/CompanyDetailsModal/context/CompanyDetailsModal.context";

const CompanyDetailsModalContainer: FC = () => {
  return (
    <CompanyDetailsModalContextProvider>
      <CompanyDetailsModal />
    </CompanyDetailsModalContextProvider>
  );
};

export default CompanyDetailsModalContainer;
