import { FC } from "react";

import AttributesModal from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/AttributesModal/AttributesModal.component";
import { AttributesModalContextProvider } from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/AttributesModal/context/AttributesModal.context";

const AttributesModalContainer: FC = () => {
  return (
    <AttributesModalContextProvider>
      <AttributesModal />
    </AttributesModalContextProvider>
  );
};

export default AttributesModalContainer;