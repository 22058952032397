import bbox from "@turf/bbox";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { Dispatch, RootState } from "@/core/store";
import {
  countryWiseMapPadding,
  TRAVELLERS_SEARCH_SUB_SLIDES
} from "@/tenant-context/control-travellers-search/config/travellers-search.config";
import {
  useTravellersSearchContext
} from "@/tenant-context/control-travellers-search/context/TravellersSearchContext/TravellersSearch.context";
import {
  TravellersByCountryDrawerComponent
} from "@/tenant-context/control-travellers-search/drawers/TravellersByCountryDrawer/TravellersByCountryDrawer.component";
import {
  CountriesSortType,
  CountrySummaryDataType } from "@/tenant-context/control-travellers-search/types/travelSearch.types";

import { countryBoundingBoxes } from "../../util/country-bounding-boxes";

const TravellersByCountryDrawer = () => {

  const [sortedTravelersData, setSortedTravelersData] = useState<CountrySummaryDataType[]>([]);

  const countryWiseTravellersData = useSelector((state: RootState) =>
    state.travellersSearch.countryWiseTravellers);

  const countryBBox = useMemo(() => {
    if (countryWiseTravellersData.length === 0) {
      return;
    }

    const countries = countryWiseTravellersData.map((countryData) =>
      countryBoundingBoxes[countryData.countryISOCode as keyof typeof countryBoundingBoxes][1]);

    const countriesFeatureCollection = {
      type: 'FeatureCollection',
      features: countries.map((country) => ({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [country[0], country[1]],
              [country[2], country[1]],
              [country[2], country[3]],
              [country[0], country[3]],
              [country[0], country[1]]
            ]
          ]
        }
      }))
    };

    return bbox(countriesFeatureCollection);
  }, [ countryWiseTravellersData ]);

  const {
    setCurrentlyOpenedSlide,
    getTravellersForCountry
  } = useTravellersSearchContext();

  const {
    dataOptions: {
      ENABLE_LAYER_TYPE,
      DISABLE_LAYER_TYPE
    },
    travellersSearch: {
      SET_SELECTED_COUNTRY
    }
  } = useDispatch<Dispatch>();

  const { current: map } = useMap();

  const handleSort = useCallback((sortType: CountriesSortType) => () => {
    if (sortType === CountriesSortType.travellerCount) {
      const sortedData = [...countryWiseTravellersData]
        .sort((a, b) => Number(b.travellerCount) - Number(a.travellerCount));

      setSortedTravelersData(sortedData);
    } else if (sortType === CountriesSortType.riskLevel) {
      const sortedData = [...countryWiseTravellersData]
        .sort((a, b) => Number(a.risk?.level || 0) - Number(b.risk?.level || 0));

      setSortedTravelersData(sortedData);
    } else if (sortType === CountriesSortType.countryName) {
      const sortedData = [...countryWiseTravellersData]
        .sort((a, b) => a.countryName.localeCompare(b.countryName));

      setSortedTravelersData(sortedData);
    }
  }, [ countryWiseTravellersData ]);

  const handleSearchChange = useCallback((value: string) => {
    const filteredData = countryWiseTravellersData.filter((countryData) =>
      countryData.countryName.toLowerCase().includes(value.toLowerCase()));

    setSortedTravelersData(filteredData);
  }, [ countryWiseTravellersData ]);

  const handleCountrySelect = useCallback(async (country: CountrySummaryDataType) => {
    SET_SELECTED_COUNTRY(country);
    await getTravellersForCountry();
    setCurrentlyOpenedSlide(TRAVELLERS_SEARCH_SUB_SLIDES.COUNTRY_DETAIL_VIEW_SLIDE);
    DISABLE_LAYER_TYPE([ToggleableLayerType.TravelCountriesView]);
    ENABLE_LAYER_TYPE([ToggleableLayerType.TravelCountryDetailsView]);

    const selectedCountryBBox = countryBoundingBoxes[country.countryISOCode as keyof typeof countryBoundingBoxes][1];

    map?.fitBounds(selectedCountryBBox as [number, number, number, number], {
      padding: countryWiseMapPadding
    });
  }, [
    SET_SELECTED_COUNTRY,
    setCurrentlyOpenedSlide,
    ENABLE_LAYER_TYPE,
    DISABLE_LAYER_TYPE,
    map,
    getTravellersForCountry
  ]);

  // Effect to fit map to country bounding box
  useEffect(() => {
    if (!map || !countryBBox) {
      return;
    }

    map?.fitBounds(countryBBox as [number, number, number, number], {
      padding: countryWiseMapPadding
    });

    ENABLE_LAYER_TYPE([ToggleableLayerType.TravelCountriesView]);

    return () => {
      DISABLE_LAYER_TYPE([
        ToggleableLayerType.TravelCountriesView
      ]);

    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ countryBBox ]);

  useEffect(() => {
    setSortedTravelersData(countryWiseTravellersData);
  }, [ countryWiseTravellersData ]);

  return (
    <TravellersByCountryDrawerComponent
      countryTravelersData={ sortedTravelersData }
      handleSort={ handleSort }
      handleSearchChange={ handleSearchChange }
      onCountrySelected={ handleCountrySelect }
    />
  );
};

export default TravellersByCountryDrawer;
