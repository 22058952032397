import { FC, Fragment,ReactNode } from 'react';

import usePermission from '@/common/hooks/usePermission';
import { RequiredPolicies } from '@/common/types/permission-control';

type Props = {
  children: ReactNode,
  requiredPolicies: RequiredPolicies | RequiredPolicies[] | null
}

const ProtectedFragment: FC<Props> = ({
  children,
  requiredPolicies
}) => {
  const isAuthorized = usePermission(requiredPolicies);
  if (isAuthorized) {
    return (
      <Fragment>{ children }</Fragment>
    );
  } else {
    return null;
  }
};

export default ProtectedFragment;