import { Flex, Stack, StackProps } from "@mantine/core";
import { FC } from "react";

import { semanticColorPalette } from "@/core/styles/mantine/palettes";

export const legendItems = [
  { label: "Extreme", color: semanticColorPalette.risk.extreme, level: 5 },
  { label: "High", color: semanticColorPalette.risk.high, level: 4 },
  { label: "Medium", color: semanticColorPalette.risk.medium, level: 3 },
  { label: "Moderate", color: semanticColorPalette.risk.moderate, level: 2 },
  { label: "Low", color: semanticColorPalette.risk.low, level: 1 }
];

const RisklineLegendComponent: FC<StackProps> = ({ ...stackProps }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Stack { ...stackProps }>
      { legendItems.map(({ color, label, level }) => (
        <Flex key={ level } gap={ 8 } display="inline-flex" align="center">
          <div
            style={ {
              backgroundColor: color,
              width: 24,
              height: 24,
              borderRadius: "50%",
              display: "inline-block"
            } }
          />
          <span>{ label }</span>
        </Flex>
      )) }
    </Stack>
  );
};

export default RisklineLegendComponent;
