export const LocationConfigurationPoliciesConfig = {
  ADD_LOCATION_CONFIGURATION_FULL_ACCESS: {
    policyNames: [
      'Tenant_System_Tenant-LocationConfig_FullAccess'
    ]
  },
  LOCATION_CONFIGURATION_ROUTE_ACCESS: {
    policyNames: [
      'Tenant_System_Tenant-LocationConfig_FullAccess',
      'Tenant_System_Tenant-LocationConfig_ReadOnly'
    ]
  },
  LOCATION_CONFIGURATION_CATEGORY_FULL_ACCESS: {
    policyNames: ['Tenant_System_Tenant-LocationCategory_FullAccess']
  },
  LOCATION_CONFIGURATION_BULK_UPLOAD: {
    policyNames: ['Tenant_System_Tenant-LocationConfig_FullAccess']
  }
};
