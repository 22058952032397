import { Tooltip } from "@mantine/core";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { RowClickedEvent } from "ag-grid-community/dist/lib/events";
import { AgGridReact } from "ag-grid-react";
import { FC, useCallback, useMemo, useRef, useState } from "react";

import TruncatedTextWithTooltip from "@/common/components/TruncatedTextWithTooltip";
import { formatTime, formatUTCDMY } from "@/common/util/format/date";
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import ArcSection from "@/tenant-context/control-action-response-center/components/ArcSection";
import {
  useNotificationSummaryStyles
} from '@/tenant-context/control-action-response-center/components/NotificationSummary/NotificationSummary.styles';
import { ArcSentCommunication } from "@/tenant-context/control-action-response-center/types/ARC.types";
import { ReactComponent as EmailGrayIcon } from '@/tenant-context/control-mass-comms/icons/email-gray.svg';
import { ReactComponent as PhoneGrayIcon } from '@/tenant-context/control-mass-comms/icons/phone-gray.svg';
import { ReactComponent as SMSGrayIcon } from '@/tenant-context/control-mass-comms/icons/sms-gray.svg';

type Props = {
  arcSentCommunications: Array<ArcSentCommunication>;
  onRowClicked: (event: RowClickedEvent) => void;
}

const NotificationSummaryComponent: FC<Props> = ({
  arcSentCommunications,
  onRowClicked
}) => {

  const { classes } = useNotificationSummaryStyles();
  const gridRef = useRef<AgGridReact>(null);

  const sentOnDateCellRenderer = useCallback((params: ICellRendererParams<ArcSentCommunication>) => {
    if (!params.data?.sentDate) {
      return 'N/A';
    }

    const date = formatUTCDMY(new Date(params.data?.sentDate), {
      conciseMonth: false,
      shortMonth: true
    });

    const time = formatTime(new Date(params.data?.sentDate), {
      withSeconds: false,
      withPad: true
    });

    return <Tooltip label={ `${ date } ${ time }` } withinPortal withArrow><span>{ date }</span></Tooltip>;
  }, []);

  const channelsCellRenderer = useCallback((params: ICellRendererParams<ArcSentCommunication>) => {
    gridRef?.current?.api.sizeColumnsToFit();

    return (
      <div className={ classes.channelCell }>
        { (params.data?.channels && params.data?.channels.includes('EMAIL')) ? <EmailGrayIcon width={ 12 }/> : null }
        { (params.data?.channels && params.data?.channels.includes('SMS')) ? <SMSGrayIcon width={ 12 }/> : null }
        { (params.data?.channels && params.data?.channels.includes('VOICE')) ? <PhoneGrayIcon width={ 12 }/> : null }
      </div>
    );
  }, [classes.channelCell]);

  const responseMethodCellRenderer = useCallback((params: ICellRendererParams<ArcSentCommunication>) => {
    if (!params.data?.responseMethod) {
      return 'N/A';
    }

    return params.data?.responseMethod.replaceAll('_', ' ');
  }, []);

  const defaultColDef: ColDef = useMemo(() => ({
    sortable: true,
    autoHeight: true,
    cellStyle: { fontSize: '12px' }
  }), []);

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'communicationName',
      headerName: 'NAME',
      headerTooltip: 'Name',
      cellRenderer: (params: ICellRendererParams<ArcSentCommunication>) => (
        <TruncatedTextWithTooltip fullText={ params.value } maxLength={ 12 }/>
      ),
      flex: 1
    },
    {
      field: 'sentDate',
      headerName: 'SENT ON',
      headerTooltip: 'Sent On',
      cellRenderer: sentOnDateCellRenderer,
      flex: 1
    },
    {
      field: 'channels',
      headerName: 'CHANNELS',
      headerTooltip: 'Channels',
      cellRenderer: channelsCellRenderer,
      flex: 1
    },
    {
      field: 'responseMethod',
      headerName: 'METHOD',
      headerTooltip: 'Response Method',
      cellRenderer: responseMethodCellRenderer,
      flex: 1
    },
    {
      field: 'recipientCount',
      headerName: 'RECIPIENTS',
      headerTooltip: 'Recipients Count',
      cellStyle: {
        display: 'flex',
        justifyContent: 'center'
      },
      flex: 1
    }
  ]);

  return (
    <ArcSection>
      <LocalErrorBoundary>
        Sent Communications

        <div className="ag-theme-alpine-dark ag-theme-rt">
          <AgGridReact
            ref={ gridRef }
            rowData={ arcSentCommunications }
            defaultColDef={ defaultColDef }
            columnDefs={ columnDefs }
            animateRows={ true }
            pagination={ true }
            paginationPageSize={ 10 }
            suppressCellFocus={ true }
            overlayNoRowsTemplate="No sent communication found!"
            suppressRowClickSelection={ true }
            tooltipShowDelay={ 200 }
            domLayout="autoHeight"
            enableBrowserTooltips={ true }
            onRowClicked={ onRowClicked }
          />
        </div>
      </LocalErrorBoundary>
    </ArcSection>
  );
};

export default NotificationSummaryComponent;
