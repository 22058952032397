import { Divider, Switch } from "@mantine/core";
import React, { FC } from "react";

import { ReactComponent as DataMinr } from "@/common/icons/dataminr.svg";
import {
  useDataMinrEventsSettingsStyles
} from "@/tenant-context/control-risk-config/components/DataMinrEventsSettings/DataMinrEventsSettings.styles";
import { DataMinrWatchList } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";

type Props = {
  dataMinrWatchlist: DataMinrWatchList[];
  onCategoryToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeGlobalCategoryToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isGlobalCategoryToggleOn: boolean;
  disabledRiskFilterTypes: string[];
}

const DataMinrEventsSettingsComponent: FC<Props> = ({
  dataMinrWatchlist,
  isGlobalCategoryToggleOn,
  disabledRiskFilterTypes,
  onCategoryToggle,
  onChangeGlobalCategoryToggle
}) => {

  const { classes } = useDataMinrEventsSettingsStyles();

  return (
    <div className={ classes.riskEventSettingsContainer }>
      <div className={ classes.riskLineRiskAlertSwitchContent }>
        <div className={ classes.riskLineRiskAlertIconNDescription }>
          <DataMinr className={ classes.risklineIcon }/>
          <p className={ classes.riskLineRiskAlertDescription }>Dataminr Watchlist</p>
        </div>

        <Switch
          onChange = { onChangeGlobalCategoryToggle }
          checked={ isGlobalCategoryToggleOn }
        />
      </div>

      <h1 className={ classes.sectionHeading }>Dataminr Watchlists</h1>

      <ul className={ classes.orderList }>
        { dataMinrWatchlist.map(({
          id, name
        }) => (
          <li
            key={ id }
            className={ classes.listContent }
          >
            <div className={ classes.riskLineRiskAlertIconNDescription }>
              <p className={ classes.riskCategoryType }>{ name }</p>
            </div>

            <Switch
              id={ `risk-category-switch-${id}` }
              checked = { !disabledRiskFilterTypes.includes(id.toString()) }
              onChange={ onCategoryToggle } value={ id }/>
          </li>
        )) }
      </ul>

      <Divider
        className={ classes.divider }
        // eslint-disable-next-line react/jsx-no-bind
        sx={ (theme) => ({
          // eslint-disable-next-line no-magic-numbers
          borderTopColor: theme.colors.neutral[7]
        }) }
      />
    </div>
  );
};

export default DataMinrEventsSettingsComponent;
