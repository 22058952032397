import { createContext, FC, useCallback, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import { ReferenceData, ReferenceType } from "@/common/types/reference-data";
import { Dispatch, RootState } from "@/core/store";
import CloseCaseModal, { createSelectItem } from "@/tenant-context/employee-app-action-response-center/components/modals/CloseCaseModal/CloseCaseModal.component";

enum CloseCaseStep {
  FalseAlarm = 0,
  WasAnyoneInjured = 1,
  MoreDetails = 2,
  CloseCase = 3
}

type CloseCaseFormValues = {
  isFalseAlarm: boolean,
  isAnyoneInjured: boolean,
  emergency_reason: string,
  details: string
};

type CloseCaseContextType = {
  handleIsFalseAlarm: () => void,
  handleIsNotFalseAlarm: () => void,
  handleIsAnyoneInjured: () => void,
  handleIsNotAnyoneInjured: () => void,
  nextStepWithValidation: () => void,
  emergecyReason: string | null,
  handleEmergencyReasonChange: (val: string) => void,
  step: CloseCaseStep,
  formControls: UseFormReturn<CloseCaseFormValues, unknown>,
  onSubmit: () => void,
  setStep: (step: CloseCaseStep) => void,
  maxStep: number,
  isFalseAlarm: boolean,
  isAnyoneInjured: boolean,
  whatHappened?: ReferenceData[]
};

export const CloseCaseModalContext = createContext({} as CloseCaseContextType);

const CloseCaseModalContainer: FC = () => {
  const [step, setStep] = useState<CloseCaseStep>(CloseCaseStep.FalseAlarm);
  const [maxStep, setMaxStep] = useState<CloseCaseStep>(0);

  const {
    eaArc: {
      closeCurrentCase,
      getWhatHappenedReferenceData
    }
  } = useDispatch<Dispatch>();
  const formControls = useForm<CloseCaseFormValues>();
  const currentProfileId = useSelector((state: RootState) => state.userProfile.id);
  const caseId = useSelector((state: RootState) => state.eaArc.caseId);
  const whatHappened = useSelector((state: RootState) => state.eaArc.whatHappened);

  useEffect(() => {
    getWhatHappenedReferenceData(ReferenceType.WHAT_HAPPENED);
  }, [getWhatHappenedReferenceData]);
  
  const {
    register,
    setValue,
    watch,
    trigger,
    handleSubmit
  } = formControls;

  const isFalseAlarm = watch('isFalseAlarm');
  const isAnyoneInjured = watch('isAnyoneInjured');
  const emergecyReason = watch('emergency_reason');

  const handleEmergencyReasonChange = useCallback((val: string) => {
    setValue('emergency_reason', val);
  }, [setValue]);

  useEffect(() => {
    register('isFalseAlarm');

    register('isAnyoneInjured');

    register('emergency_reason', addErrorMessages('Emergency Reason', {
      required: isAnyoneInjured
    }));
  }, [register, isAnyoneInjured]);

  const nextStep = useCallback(() => {
    setStep((currentStep) => {
      const nextStepValue = currentStep + 1;
      setMaxStep(nextStepValue);

      return nextStepValue;
    });
  }, []);

  const handleIsFalseAlarm = useCallback(() => {
    setValue('isFalseAlarm', true);
    setValue('emergency_reason', createSelectItem('False Alarm').value);
    nextStep();
    setValue('isAnyoneInjured', false);
    nextStep();
    setValue('details', ' ');
    nextStep();
  }, [nextStep, setValue]);

  const handleIsNotFalseAlarm = useCallback(() => {
    setValue('isFalseAlarm', false);
    nextStep();
  }, [nextStep, setValue]);

  const handleIsAnyoneInjured = useCallback(() => {
    setValue('isAnyoneInjured', true);
    nextStep();
  }, [nextStep, setValue]);

  const handleIsNotAnyoneInjured = useCallback(() => {
    setValue('isAnyoneInjured', false);
    nextStep();
  }, [nextStep, setValue]);

  const nextStepWithValidation = useCallback(async () => {
    const isValid = await trigger();

    if (isValid) {
      nextStep();
    }
  }, [nextStep, trigger]);

  const onSubmit = handleSubmit(({
    emergency_reason,
    details,
    isAnyoneInjured: anyoneInjured,
    isFalseAlarm: falseAlarm
  }) => {
    if (!caseId || !currentProfileId) {
      return;
    }

    closeCurrentCase({
      anyoneInjured,
      closedDetails: details,
      falseAlarm,
      whatHappened: emergency_reason,
      caseId: caseId,
      closedByProfileId: currentProfileId
    });
  });

  return (
    <CloseCaseModalContext.Provider value={ {
      handleIsFalseAlarm,
      handleIsNotFalseAlarm,
      handleIsAnyoneInjured,
      handleIsNotAnyoneInjured,
      nextStepWithValidation,
      emergecyReason,
      handleEmergencyReasonChange,
      step,
      formControls,
      onSubmit,
      setStep,
      maxStep,
      isFalseAlarm,
      isAnyoneInjured,
      whatHappened
    } }>
      <CloseCaseModal />
    </CloseCaseModalContext.Provider>
  );
};

export default CloseCaseModalContainer;