import { Divider } from "@mantine/core";
import { FC } from "react";

import { TestableComponent } from "@/common/types/testable-component";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import { CustomColors } from "@/core/styles/mantine/palettes/types";
import { usePeopleInfoBoxStyles } from "@/tenant-context/widget-overview/components/PeopleInfoBox/PeopleInfoBox.styles";

import { ReactComponent as UsersIcon } from '../../../../common/icons/users.svg';

type Props = {
  title: string,
  worldTotalCount?: string
  countryTotalCount?: string
  siteTotalCount?: string
  worldSitesCount?: string
  totalSitesCount?: string
  totalVisitingWorldCount?: string
  totalVisitingWorldNext24HrsCount?: string
  totalVisitingCountryCount?: string
  totalVisitingCountryNext24HrsCount?: string
  width?: string,
  onClick?: () => void,
  isDropdownExpanded: boolean
  visitorsOnSiteCount?: string,
  visitorsNext24HrsCount?: string
  viewLevel?: 'world' | 'country' | 'site'
  type?: 'muster' | 'people'
  isSitesAvailable: boolean
} & TestableComponent;


const PeopleInfoBox: FC<Props> = ({
  title,
  worldTotalCount = 0,
  // countryTotalCount = 0,
  // siteTotalCount = 0,
  // worldSitesCount = 0,
  // totalSitesCount = 0,
  // totalVisitingWorldCount = 0,
  // totalVisitingWorldNext24HrsCount = 0,
  // totalVisitingCountryCount = 0,
  // totalVisitingCountryNext24HrsCount = 0,
  // visitorsOnSiteCount = 0,
  // visitorsNext24HrsCount = 0,
  // onClick,
  isDropdownExpanded = true,
  // viewLevel,
  // isSitesAvailable
  type = 'people',
  dataTestId = "top-menu-people-infobox"
}) => {

  const { classes } = usePeopleInfoBoxStyles({
    isDropdownExpanded,
    type
  });

  return (
    <div className={ classes.wrapper }
      data-testid={ dataTestId }
    >
      <div className={ classes.infoBox }>
        <div className={ classes.infoDiv }>
          <div className={ classes.title }>{ title }</div>
          <UsersIcon/>
        </div>
        <div className={ classes.dividerContainer }>
          <Divider
            color={ genericColorPalette.neutral[7] as CustomColors }
            orientation="vertical"
          />
        </div>
        <div className={ classes.mainCountDiv }>
          <div className={ classes.title }>{ 'WORLDWIDE' }</div>
          <div className={ classes.regionCount }
            data-testid="top-menu-people-infobox-worldwide-count"
          >{ worldTotalCount }</div>
          { /* { viewLevel === 'world' && (
            <>
              <div className={ classes.title }>{ 'WORLDWIDE' }</div>
              <div className={ classes.regionCount }>{ worldTotalCount }</div>
            </>
          ) }
          { viewLevel === 'country' && (
            <>
              <div className={ classes.title }>{ 'IN COUNTRY' }</div>
              <div className={ classes.regionCount }>{ countryTotalCount }</div>
            </>
          ) }
          { viewLevel === 'site' && (
            <>
              <div className={ classes.title }>{ 'ON SITE' }</div>
              <div className={ classes.regionCount }>{ siteTotalCount }</div>
            </>
          ) } */ }
        </div>

        { /* Temporary hiding
        <div className={ isDropdownExpanded ? classes.onsiteCountExpanded : classes.onsiteCount }>
          { viewLevel === 'world' && (
            <>
              <div className={ classes.countContainer }>
                <ArrorwIcon/>
                <div className={ classes.countDiv }>
                  <div className={ classes.title }>{ 'ON SITES' }</div>
                  <div className={ classes.onsiteCountItem }>
                    { worldSitesCount }
                  </div>
                </div>
              </div>
              <div className={ classes.countDiv }>
                <div className={ classes.title }>{ 'VISITORS ON SITE' }</div>
                <div className={ classes.onsiteCountItem }>
                  { totalVisitingWorldCount }
                </div>
              </div>
              <div className={ classes.countDiv }>
                <div className={ classes.title }>{ 'VISITING NEXT 24H' }</div>
                <div className={ classes.onsiteCountItem }>
                  { totalVisitingWorldNext24HrsCount }
                </div>
              </div>
            </>

          ) }

          { viewLevel === 'country' && (
            <>
              <div className={ classes.countContainer }>
                <ArrorwIcon/>
                <div className={ classes.countDiv }>
                  <div className={ classes.title }>{ 'ON SITES' }</div>
                  <div className={ classes.onsiteCountItem }>
                    <div className={ classes.countBadge }/>
                    { totalSitesCount }
                  </div>
                </div>
              </div>
              <div className={ classes.countDiv }>
                <div className={ classes.title }>{ 'VISITORS ON SITE' }</div>
                <div className={ classes.onsiteCountItem }>
                  <div className={ classes.countBadge }/>
                  { totalVisitingCountryCount }
                </div>
              </div>
              <div className={ classes.countDiv }>
                <div className={ classes.title }>{ 'VISITING NEXT 24H' }</div>
                <div className={ classes.onsiteCountItem }>
                  <div className={ classes.countBadge }/>
                  { totalVisitingCountryNext24HrsCount }
                </div>
              </div>
            </>
          ) }

          { viewLevel === 'site' && (
            <>
              <div className={ classes.countContainer }>
                <ArrorwIcon/>
                <div className={ classes.countDiv }>
                  <div className={ classes.title }>{ 'VISITORS ON SITE' }</div>
                  <div className={ classes.onsiteCountItem }>
                    <div className={ classes.countBadge }/>
                    { visitorsOnSiteCount }
                  </div>
                </div>
              </div>
              <div className={ classes.countDiv }>
                <div className={ classes.title }>{ 'VISITING NEXT 24H' }</div>
                <div className={ classes.onsiteCountItem }>
                  <div className={ classes.countBadge }/>
                  { visitorsNext24HrsCount }
                </div>
              </div>
            </>
          ) }
        </div>
        <button onClick={ onClick } className={ classes.dropdownIconWrapper } hidden={ !isSitesAvailable }>
          <ChevronUp className={ classes.dropdownArrowIcon }/>
        </button>
        */ }
      </div>

    </div>
  );
};

export default PeopleInfoBox;
