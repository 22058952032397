import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import { playbooksApi } from "../api/playbook";
import { PlaybookForm, PlaybookItem, PlaybooksList, TaskForm, TaskItem, TasksList } from "../types/playbooks";

type PlaybooksState = {
    playbooksData?: PaginatedResult<PlaybooksList>
    selectedPlaybookItem?: PlaybookItem,
    tasksList?:  PaginatedResult<TasksList>
    selectedTaskItem?: TaskItem,
};

const playbooksDataModel = {
  name: 'playbooks',
  state: {
    playbooksData: undefined,
    selectedPlaybookItem: undefined,
    selectedTaskItem: undefined,
    tasksList: undefined
  } as PlaybooksState,
  reducers: {
    SET_PLAYBOOK_LIST: (state: PlaybooksState, playbooksData: PlaybooksState['playbooksData']) => ({
      ...state,
      playbooksData
    }),
    SET_SELECTED_PLAYBOOK_ITEM: (state: PlaybooksState, selectedPlaybookItem: PlaybooksState['selectedPlaybookItem']) => ({
      ...state,
      selectedPlaybookItem
    }),
    SET_SELECTED_TASK_ITEM: (state: PlaybooksState, selectedTaskItem: PlaybooksState['selectedTaskItem']) => ({
      ...state,
      selectedTaskItem
    }),
    RESET_SELECTED_PLAYBOOK_ITEM: (state: PlaybooksState) => ({
      ...state,
      selectedPlaybookItem: undefined,
      tasksList: undefined
    }),
    RESET_SELECTED_TASK_ITEM: (state: PlaybooksState) => ({
      ...state,
      selectedTaskItem: undefined
    }),
    SET_TASK_LIST: (state: PlaybooksState, tasksList: PlaybooksState['tasksList']) => ({
      ...state,
      tasksList
    })
  },
  effects: (dispatch: Dispatch) => ({
    async getPlaybooksData(payload: {
      gridParams: GridParams,
  }, _state: RootState): Promise<PaginatedResult<PlaybooksList>> {

      const query = payload.gridParams.searchQuery;
      if(query){
      // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `name LIKE %27%25${query}%25%27`;
      }

      const playbooksData = await playbooksApi.getPlaybooksData(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.playbooks.SET_PLAYBOOK_LIST(playbooksData);
      return playbooksData;
    },
    async getPlaybookById(id: string, _state: RootState): Promise<PlaybookItem> {
      const playbook = await playbooksApi.getPlaybookById(id);
      dispatch.playbooks.SET_SELECTED_PLAYBOOK_ITEM(playbook);
      return playbook;
    },
    async savePlaybook(params: PlaybookForm, state: RootState): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let success = true;
      // eslint-disable-next-line fp/no-let
      let result;
      if(state.playbooks.selectedPlaybookItem){
        //edit playbook
        result = await playbooksApi.updatePlaybook(params).catch(handleError);
        success = !!result;
      } else{
        //new playbook
        result = await playbooksApi.savePlaybook(params).catch(handleError);
        success = !!result;
      }

      return success;
    },
    async saveTask(params: TaskForm, state: RootState): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let success = true;
      // eslint-disable-next-line fp/no-let
      let result;

      const playbookId = state.playbooks.selectedPlaybookItem?._id;

      if(playbookId){
        if(state.playbooks.selectedTaskItem){
          //new task item
          result = await playbooksApi.updateTask(playbookId, params).catch(handleError);
        } else{
          //edit task item
          result = await playbooksApi.saveTask(playbookId, params).catch(handleError);
        }
        success = !!result;
      }


      return success;
    },
    async deleteTask(taskId: string, state: RootState): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let result = false;

      const playbookId = state.playbooks.selectedPlaybookItem?._id;
      if(playbookId){
        result = !!await playbooksApi.deleteTask(playbookId, taskId).catch(handleError);
      }

      return result;
     
    },
    async deletePlaybook(playbookId: string, _state: RootState): Promise<boolean> {
      return !!await playbooksApi.deletePlaybook(playbookId).catch(handleError);
    },
    async getTasksData(payload: {
      gridParams: GridParams,
  }, state: RootState): Promise<PaginatedResult<TasksList>> {

      // eslint-disable-next-line fp/no-let
      let searchQuery = "";
      if(payload.gridParams.searchQuery){
        searchQuery = `taskName LIKE %27%25${payload.gridParams.searchQuery}%25%27`;
      }

      const tasksList =  await playbooksApi.getTasksData(
        payload.gridParams.page,
        payload.gridParams.size,
        state.playbooks.selectedPlaybookItem?._id || "",
        payload.gridParams.sort,
        searchQuery
      );
  
      dispatch.playbooks.SET_TASK_LIST(tasksList);
      return tasksList;
    
    }
  })
};

export const playbooks = createModel<RootModel>()(playbooksDataModel);
