/* eslint-disable import/extensions */
import { MantineProvider } from "@mantine/core";
import { ModalsProvider as MantineModalsProvider } from '@mantine/modals';
import { NotificationsProvider as MantineNotificationsProvider } from '@mantine/notifications';
import { FC } from "react";
import { Provider as ReduxProvider } from "react-redux";

import { PoliciesContextProvider } from '@/core/context/Policies.context';
import { emotionCache } from "@/core/styles/mantine/emotion";
import mantineTheme from "@/core/styles/mantine/theme";
import { AuthContextProvider } from "@/tenant-context/core/context/Auth.context";

import { store } from "../../store";
import ContextRouter from "../ContextRouter";
import ContextProviders from "./ContextProviders.component";

const ProviderHaven: FC = ({ children }) => {
  return (
    <ReduxProvider store={ store }>
      <AuthContextProvider>
        <PoliciesContextProvider>
          <ContextProviders>
            <MantineProvider
              withCSSVariables
              withNormalizeCSS
              withGlobalStyles
              theme={ mantineTheme }
              emotionCache={ emotionCache }
            >
              <MantineNotificationsProvider
                limit={ 8 }
                zIndex={ 999999 }
              >
                <MantineModalsProvider modalProps={ {
                  // styles: modalStylesGenerator,
                  closeOnClickOutside: true,
                  closeOnEscape: true
                } }>
                  { children }
                  <ContextRouter />
                </MantineModalsProvider>
              </MantineNotificationsProvider>
            </MantineProvider>
          </ContextProviders>
        </PoliciesContextProvider>
      </AuthContextProvider>
    </ReduxProvider>
  );
};

export default ProviderHaven;
