export const GeofenceRequiredPoliciesConfig = {
  LONE_WORKER_MANAGE_GEO_FENCE_FULL_ACCESS: {
    policyNames: [
      'Tenant_System_GeoFence_FullAccess'
    ]
  },
  LONE_WORKER_MANAGE_GEO_FENCE_READ_ACCESS: {
    policyNames: [
      'Tenant_System_GeoFence_FullAccess',
      'Tenant_System_GeoFence_ReadOnly'
    ]
  },
  GEO_FENCE_MANAGE: {
    policyNames: [
      'Tenant_System_GeoFence_FullAccess',
      'Tenant_System_GeoFence_ReadOnly'
    ]
  }
};
