import { FC, Fragment, ReactNode } from 'react';

type Props =  {
  conditions?: boolean,
  fallback?: ReactNode,
  children: ReactNode
}

const ConditionalComponent: FC<Props> = ({
  conditions = false,
  fallback,
  children
}) => {
  if (conditions) {
    return (
      <Fragment>{ children }</Fragment>
    );
  } else {
    return (
      <Fragment>{ fallback }</Fragment>
    );
  }
};

export default ConditionalComponent;
