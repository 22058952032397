import { createStyles,MantineTheme } from "@mantine/core";

export const useTravelItineraryItemStyles = createStyles((theme: MantineTheme) =>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `1px solid ${theme.colors.neutral[7]}`,
    backgroundColor: theme.colors.neutral[9],
    minHeight: 172,
    padding: 16
  },
  header: {
    position: 'relative',
    display: 'flex',
    gap: 8
  },
  dropdownArrowIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'rotate(90deg)',
    transition: 'transform var(--default-transition-speed)',
    cursor: 'pointer'
  },
  profileName: {
    fontWeight: theme.other.fw.bold,
    lineHeight: theme.other.lh.s
  },
  position: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.other.lh.s
  },
  companyName: {
    color: theme.colors.neutral[6],
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.other.lh.s
  },
  divider: {
    margin: '16px 0'
  },
  body: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  countryCollapsed: {
    display: 'flex',
    flexDirection: 'column'
  }
}));
