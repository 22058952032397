import { createStyles } from "@mantine/core";

export const useUnauthorizedPageStyles = createStyles((theme) => ({
  unauthorizedPageContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    gridGap: 16,

    '*' : {
      textAlign: 'center'
    }
  },

  messageArea: {
    backgroundColor: theme.colors.neutral[9],
    border: `${theme.colors.neutral[6]} 1px solid`,
    padding: 16
  },

  dangerText: {
    color: theme.colors.error[0],
    fontWeight: 'bold',
    marginRight: '0.5rem'
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: 16
  }
}));
