/* eslint-disable react/jsx-no-bind */
import { ActionIcon, Box, Divider, Flex, Text } from "@mantine/core";
import { FC, MouseEventHandler } from "react";

import TextTruncate from "@/common/components/TextTruncate";
import { ReactComponent as RightArrowIcon } from "@/common/icons/Arrows/right-arrow.svg";
import { ReactComponent as ChevronLeft } from "@/common/icons/chevron-left.svg";
import { ReactComponent as RiskLine } from "@/common/icons/riskline.svg";
import { getTimeDurationFromCurrentDate } from "@/common/util/format/date";
import CountryFlag from "@/tenant-context/common/components/CountryFlag/CountryFlag.component";
import RiskAlertBasicData from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertBasicData";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskImpactEvent } from "@/tenant-context/visualisation-risk-details/types/risk-impact";

import { RiskAlertDetailsStyles } from "./RiskAlertDetails.styles";

type Props = {
  riskAlert?: RiskAlertEvent,
  riskImpactData?: RiskImpactEvent,
  onRequestToToggleArc: MouseEventHandler<HTMLButtonElement>,
  onRequestToCloseComms: MouseEventHandler<HTMLButtonElement>,
  isArcOpen: boolean;
  isOpenedInArc?: boolean;
  isAnotherOverlayModuleOpen: boolean;
}

const RiskAlertDetails: FC<Props> = ({
  riskAlert,
  onRequestToToggleArc,
  onRequestToCloseComms,
  isArcOpen,
  isOpenedInArc = false,
  isAnotherOverlayModuleOpen
}) => {
  const { classes  } = RiskAlertDetailsStyles({ isInArc: isOpenedInArc, isOverlayOpen: isAnotherOverlayModuleOpen });

  return (
    <div className={ classes.container }>
      <div>
        { (isArcOpen || isAnotherOverlayModuleOpen) && <Flex justify={ 'space-between' } mb={ 'md' }>
          { (isArcOpen || isAnotherOverlayModuleOpen) && (
            <div>
              <Flex gap={ 2 } align={ 'flex-end' }>
                { ( riskAlert?.json.alert.start_date !== undefined
                  ? `${getTimeDurationFromCurrentDate(new Date(riskAlert?.json.alert.start_date))} AGO`
                  : "" )?.split(/(\d+)/).map((part, index) => {
                  if (!isNaN(Number(part))) {
                    return <Text fw={ 'bold' } size={ 'sm' } key={ index }>{ part }</Text>;
                  } else {
                    return <Text color={ 'neutral' } size={ 12 } key={ index }>{ part }</Text>;
                  }
                }) }
              </Flex>
            </div>
          ) }

          { isArcOpen && (
            <div>
              <ActionIcon size={ 20 } onClick={ onRequestToToggleArc } variant={ 'subtle' }>
                <RightArrowIcon />
              </ActionIcon>
            </div>
          ) }

          { isAnotherOverlayModuleOpen && (
            <div className={ classes.innerArrow }>
              <ActionIcon size={ 20 } onClick={ onRequestToCloseComms }>
                <ChevronLeft />
              </ActionIcon>
            </div>
          ) }
        </Flex> }

        <Flex direction={ 'column' } gap={ 32 } mb={ 48 }>
          <Flex direction={ 'column' } gap={ 8 }>
            <div className={ classes.popupHeader }>
              { riskAlert?.json.alert.countries[0].iso_code && (
                <Flex justify={ 'center' } align={ 'center' } h={ 48 } w={ 48 } className={ classes.flag }>
                  <Box>
                    <CountryFlag
                      width={ 80 }
                      height={ 80 }
                      countryCode={ riskAlert?.json.alert.countries[0].iso_code }
                    />
                  </Box>
                </Flex>
              ) }
            </div>

            <div className={ classes.countryInfo }>
              { riskAlert?.json.alert.location_explanation.split(",")[0] },
              { riskAlert?.json.alert.countries[0].name }
            </div>

            <div className={ classes.riskAlertTitle }>
              { riskAlert?.json.alert.title }
            </div>
          </Flex>

          <Box>
            <RiskAlertBasicData riskAlertData={ riskAlert } isOnSideDrawer/>
          </Box>
        </Flex>

        <div className={ classes.sectionDetailsHeader }>
          DETAILS
        </div>

        <Divider
          className={ classes.sectionHeaderDivider }
        />

        <div className={ classes.riskDescription }>
          <TextTruncate
            text={ riskAlert?.json.alert.text }
            maxTextLength={ window.innerHeight <= 800 ? 100 : 300 }
          />
        </div>

        <Divider
          className={ classes.divider }
        />

        <Flex gap={ 'xs' } align={ 'center' } justify={ 'flex-start' } mt={ 4 }>
          <div className={ classes.issuedBy }>Issued by</div>

          <RiskLine className={ classes.riskAlerProviderIcon } />

          <div className={ classes.riskAlertProviderName }> Riskline Risk </div>
        </Flex>
      </div>

      <div>
        { /*   HIDDEN BECAUSE AWAITS BE INTEGRATION RO-1069

        { !isAnotherOverlayModuleOpen && riskAlert && riskAlert.isImpacted && (
          <div className={ classes.responseStatusContainer }>
            <div className={ classes.responseBox }>
              <div className={ classes.responseStatusText }>
                <RiskImpactWarningIcon /> IMPACT EVENT
              </div>
              <div className={ classes.responseProgress }> Response progress</div>
              <div className={ classes.responseMeta }>
                <div>
                  <div className={ classes.responseMetaTxt }>Last Modified :</div>
                  <div className={ classes.responseMetaValue }>
                    { riskAlert?.meta.updatedTime !== undefined && riskAlert?.meta.updatedTime > 0
                      ? formatDate(new Date(riskAlert?.meta.updatedTime))
                      : formatDate(new Date()) }
                  </div>
                </div>
                <div>
                  <div className={ classes.responseMetaTxt }>Case ID</div>
                  <div className={ classes.responseMetaValue }>
                    uiJ23bnXC
                  </div>
                </div>
              </div>
              <div className={ classes.caseOwner }>
                <PersonAvatar
                  personName={ "John Jones" }
                />
                <div>
                  <div className={ classes.caseOwnerTxt }>
                    Case Owner:
                  </div>
                  <div className={ classes.ownerName }>
                    John Jones
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) } */ }

      </div>
    </div>
  );
};

export default RiskAlertDetails;
