/* eslint-disable react/jsx-no-bind */
import { ActionIcon, Col, Grid, Menu } from "@mantine/core";
import { FC, useCallback, useState } from "react";

import { ReactComponent as DefaultLocationCategoryIcon } from "@/common/icons/default-location-category.svg";
import { ReactComponent as EditIcon } from "@/common/icons/tableEdit.svg";
import { ReactComponent as DeleteIcon } from "@/common/icons/trash.svg";
import { CategoryDTO, CategoryTypes } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

import { useLocationsListStyles } from "../LocationsList.styles";

interface Props{
    categoryItem: CategoryDTO,
    onLocationCategoryEdit: (categoryItem: CategoryDTO) => void,
    onLocationCategoryDelete: (categoryItem: CategoryDTO) => void,
    onLocationCategorySelect: ( value: string) => void,
    isAuthorized: boolean
}

const LocationCategoryItem: FC<Props> = ({
  categoryItem,
  onLocationCategoryEdit,
  onLocationCategoryDelete,
  onLocationCategorySelect,
  isAuthorized
}) => {
  const { classes } = useLocationsListStyles();
  const isTenantCategory = categoryItem.type === CategoryTypes.Tenant;

  const [isImageError, setIsImageError] = useState(false);

  const handleImageError = useCallback(() => {
    setIsImageError(true);
  }, []);

  return (
    <Menu.Item className={ classes.menuItem } onClick={ () => onLocationCategorySelect(categoryItem.value) }>
      <Grid align="center" justify="space-between">
        <Col span={ isTenantCategory ? 9 : 12 }>
          { categoryItem.icon && !isImageError
            && ( <img onError={ handleImageError } width="20px" className={ classes.catIcon } alt="category-icon" src={ categoryItem.icon } />) }
          { (!categoryItem.icon || isImageError) && <DefaultLocationCategoryIcon className={ classes.catIcon }/> }
          { categoryItem.label }
        </Col>
        { isTenantCategory && (
          <Col span={ 3 }>
            <Grid align="center" gutter={ "sm" }>
              <Col span={ 6 }>
                <ActionIcon disabled={ !isAuthorized } onClick={ isAuthorized ?
                  () => onLocationCategoryEdit(categoryItem) : undefined }>
                  <EditIcon />
                </ActionIcon>
              </Col>
              <Col span={ 6 }>
                <ActionIcon size="xl" disabled={ !isAuthorized } onClick={ isAuthorized ?
                  () => onLocationCategoryDelete(categoryItem) : undefined }>
                  <DeleteIcon />
                </ActionIcon>
              </Col>
            </Grid>
          </Col>
        ) }

      </Grid>
    </Menu.Item>
  );
};

export default LocationCategoryItem;
