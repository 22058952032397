import { Margin } from "@visx/xychart";
import { XYCHART_EVENT_SOURCE } from '@visx/xychart/lib/constants';
import { FC } from "react";

// import useEventEmitters from '@visx/xychart/lib/hooks/useEventEmitters';
import usePointerEventEmitters from "@/tenant-context/control-location-graph/hooks/useEventEmitters";

type Props = {
  chartMargin: Margin,
  width: number,
  height: number
}

const EventInterceptor: FC<Props> = ({
  chartMargin,
  width,
  height
}) => {
  const eventEmitters = usePointerEventEmitters({
    source: XYCHART_EVENT_SOURCE
  });

  return (
    <rect
      x={ chartMargin.left }
      y={ chartMargin.top }
      width={ width - chartMargin.left - chartMargin.right }
      height={ height - chartMargin.top - chartMargin.bottom }
      fill="transparent"
      { ...eventEmitters }
    />
  );
};

export default EventInterceptor;