/* eslint-disable react/jsx-props-no-spreading */
import { TextInput } from "@mantine/core";
import { FC, useContext } from "react";
import { RegisterOptions } from "react-hook-form";
import { useSelector } from "react-redux";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import ModalControlGroup from "@/common/components/ModalControlGroup";
import { RootState } from "@/core/store";
import { DistinguishingFeaturesModalContext } from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/DistinguishingFeaturesModal/context/DistinguishingFeaturesModal.context";
import { useEmergencyContactModalContentStyles } from "@/tenant-context/control-profile/components/ui/EmergencyContactModalContent/EmergencyContactModalContent.style";
import ProfileLoadingOverlay from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay';
import { ProfileDistinguishingFeatures } from "@/tenant-context/control-profile/types/profile";

export type DistinguishingFeaturesModalFormData = Pick<
  ProfileDistinguishingFeatures,
  | 'tattoosList'
  | 'scarsList'
  | 'birthMarksList'
> & {
  dateOfBirth: Date
};

const DistinguishingFeaturesModal: FC = () => {
  const { classes } = useEmergencyContactModalContentStyles();
  const {
    onSubmit,
    formControls: { register, formState: { errors, isDirty,isValid } }
  } = useContext(DistinguishingFeaturesModalContext);

  const distinguishingFeatures = useSelector((state: RootState) => state.profile.distinguishingFeatures);

  if (typeof distinguishingFeatures === 'undefined') {
    return (
      <p>Loading...</p>
    );
  }

  const renderTextInput = (
    label: string,
    id: keyof ProfileDistinguishingFeatures,
    options: RegisterOptions
  ) => (
    <TextInput
      label={ label }
      defaultValue={ distinguishingFeatures?.[id]?.toString() }
      error={ errors?.[id]?.message }
      { ...register(id, addErrorMessages(label, options)) }
    />
  );

  return (
    <form onSubmit={ onSubmit }>

      <ProfileLoadingOverlay />
      <div className={ classes.grid }>
        { renderTextInput('Tattoos', 'tattoosList', {
          maxLength: 256
        }) }

        { renderTextInput('Scars', 'scarsList', {
          maxLength: 256
        }) }

        { renderTextInput('Birth Marks', 'birthMarksList', {
          maxLength: 256
        }) }
      </div>

      <ModalControlGroup
        primaryButtonDisabled={ !isValid || !isDirty }
      />
    </form>
  );
};

export default DistinguishingFeaturesModal;
