import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NavigationSidebar from '@/core/navigation/components/NavigationSidebar';
import { NavigationSidebarContext } from '@/core/navigation/context/NavigationSidebar.context';
import {
  NavigationItemId
} from '@/core/navigation/types/navigation';
import { Dispatch, RootState } from '@/core/store';
import { PaginatedResult, ProfileGeneral } from '@/tenant-context/control-profile/types/profile';
import {
  getCurrentTenantDetails,
  getCurrentUsersPermissionsPolicies,
  getTenantUser
} from '@/tenant-context/navigation/api/navigation';
import {
  navigationSidebarBottomItems,
  navigationSidebarItems
} from '@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.config';
import { TenantDetails, TenantUser, UserPolicy } from '@/tenant-context/navigation/types/user';

export const NavigationSidebarContextProvider: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [usersPolicies, setUsersPolicies] = useState<PaginatedResult<UserPolicy>>();
  const [tenantDetails, setTenantDetails] = useState<TenantDetails>();
  const [userDetails, setUserDetails] = useState<TenantUser>();
  const [profileDetails, setProfileDetails] = useState<ProfileGeneral>();
  const [selectedNavigationItem, setSelectedNavigationItem] = useState<NavigationItemId>(NavigationItemId.Map);
  const [selectedSubNavigationItem, setSelectedSubNavigationItem] = useState<NavigationItemId | undefined>(undefined);
  const [openedMenu, setOpenedMenu] = useState<NavigationItemId | undefined>(undefined);

  const loggedUserInfo = useSelector(({
    profile:{
      loggedUser
    }
  }: RootState) => loggedUser);

  const {
    userProfile: {
      SET_CURRENT_USER_EMAIL
    },
    profile: {
      setLoggedUser
    },
    commonData: {
      SET_IS_NAVIGATION_EXPANDED
    }
  } = useDispatch<Dispatch>();

  const handleNavigationExpand = useCallback((isNavExpanded: boolean) => {
    setIsExpanded(isNavExpanded);
    SET_IS_NAVIGATION_EXPANDED(isNavExpanded);
  }, [ SET_IS_NAVIGATION_EXPANDED ]);

  useEffect(() => {
    getCurrentUsersPermissionsPolicies().then(
      (response) => response && setUsersPolicies(response)
    );
  }, []);

  useEffect(() => {
    getCurrentTenantDetails().then(
      (response) => {
        if(response){
          setTenantDetails(response);
        }
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTenantUser().then((response) => {
      if (!response) {
        return;
      }

      const { email } = response;

      SET_CURRENT_USER_EMAIL(email);

      setUserDetails(response);

      // If no profile linked => no picture
      if (!response.profileRef) {
        return;
      }
      setLoggedUser(response.profileRef);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    setProfileDetails(loggedUserInfo);
  }, [loggedUserInfo]);

  useEffect(() => {
    const path = window.location.pathname;
    navigationSidebarItems.forEach((item) => {
      if (item.subMenuItems && item.subMenuItems.length > 0) {
        item.subMenuItems?.forEach((subSection) => {
          subSection.subItems?.forEach((subItem) => {
            if (subItem.path === path) {
              setSelectedNavigationItem(item.itemId);
              setOpenedMenu(item.itemId);
              setSelectedSubNavigationItem(subItem.itemId);
            }
          });
        });
      } else {
        if (item.path === path) {
          setSelectedNavigationItem(item.itemId);
        }
      }
    });
  }, []);

  return (
    <NavigationSidebarContext.Provider value={ {
      isExpanded,
      setIsExpanded: handleNavigationExpand,
      usersPolicies,
      tenantDetails,
      userDetails,
      profileDetails,
      selectedNavigationItem,
      setSelectedNavigationItem,
      selectedSubNavigationItem,
      setSelectedSubNavigationItem,
      openedMenu,
      setOpenedMenu
    } }>
      <NavigationSidebar
        navigationSidebarItems={ navigationSidebarItems }
        navigationSidebarBottomItems={ navigationSidebarBottomItems }
      />
    </NavigationSidebarContext.Provider>
  );
};

export default NavigationSidebarContextProvider;
