import { Group } from '@visx/group';
import { Margin } from '@visx/xychart';
import { FC } from "react";

import { ReactComponent as BackArrow } from '@/common/icons/Arrows/back-arrow-bg.svg';
import { ReactComponent as DoubleBackArrow } from '@/common/icons/Arrows/double-back-arrow-bg.svg';
import { ReactComponent as DoubleFrontArrow } from '@/common/icons/Arrows/double-forward-arrow-bg.svg';
import { ReactComponent as FrontArrow } from '@/common/icons/Arrows/forward-arrow-bg.svg';
import { useTimeTravelControlsStyles } from '@/tenant-context/control-location-graph/components/TimeTravelControls/TimeTravelControls.styles';

type Props = {
  chartContentHeight: number,
  chartContentWidth: number,
  chartMargin: Margin,
  onForwardClick: () => void,
  onBackwardClick: () => void
  onForwardForDailyClick?: () => void,
  onBackwardForDailyClick?: () => void
}

const CONTROLS_TOP_ADDITIONAL_OFFSET = 4;
const CONTROLS_LEFT_ADDITIONAL_OFFSET = 10;

const TimeTravelControls: FC<Props> = ({
  chartContentHeight,
  chartContentWidth,
  chartMargin,
  onForwardClick,
  onBackwardClick,
  onForwardForDailyClick,
  onBackwardForDailyClick
}) => {
  const { classes } = useTimeTravelControlsStyles();

  const isRiskTimelineGraph = onBackwardForDailyClick && onForwardForDailyClick;

  return (
    <>
      <Group
        top={ chartContentHeight + chartMargin.top - CONTROLS_TOP_ADDITIONAL_OFFSET }
        left={ chartMargin.left - CONTROLS_LEFT_ADDITIONAL_OFFSET }
        className={ classes.control }
        onClick={ onBackwardClick }
      >
        <Group>
          <DoubleBackArrow className={ classes.timeTravelBtn } />
        </Group>
      </Group>
      { isRiskTimelineGraph && <><Group
        top={ chartContentHeight + chartMargin.top - CONTROLS_TOP_ADDITIONAL_OFFSET }
        left={ chartMargin.left - CONTROLS_LEFT_ADDITIONAL_OFFSET + 32 }
        className={ classes.control }
        onClick={ onBackwardForDailyClick }
      >
        <Group>
          <BackArrow className={ classes.timeTravelBtn }/>
        </Group>
      </Group><Group
        top={ chartContentHeight + chartMargin.top - CONTROLS_TOP_ADDITIONAL_OFFSET }
        left={ chartContentWidth + chartMargin.left + CONTROLS_LEFT_ADDITIONAL_OFFSET - 32 }
        className={ classes.control }
        // eslint-disable-next-line react/jsx-no-bind
        onClick={ onForwardForDailyClick }
      >
        <Group>
          <FrontArrow className={ classes.timeTravelBtn }/>
        </Group>
      </Group>
      </> }
      <Group
        top={ chartContentHeight + chartMargin.top - CONTROLS_TOP_ADDITIONAL_OFFSET }
        left={ chartContentWidth + chartMargin.left + CONTROLS_LEFT_ADDITIONAL_OFFSET }
        className={ classes.control }
        onClick={ onForwardClick }
      >
        <Group>
          <DoubleFrontArrow className={ classes.timeTravelBtn }/>
        </Group>
      </Group>
    </>
  );
};

export default TimeTravelControls;
