import { createStyles, MantineTheme } from "@mantine/core";

export const useImpactZoneStyles = createStyles((theme: MantineTheme) => ({
  controlContainer: {
    margin: 0 + '!important'
  },

  impactZoneContainer:{
    height:'541px'
  },

  impactZoneTxt:{
    fontSize:theme.fontSizes.md,
    color:theme.colors.neutral[5],
    fontFamily:theme.fontFamily
  },

  impactZoneHeader:{
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    marginBottom: '24px'
  },

  dateRange:{
    fontSize:theme.fontSizes.sm,
    color:theme.colors.navyBlue,
    fontFamily:theme.fontFamily,
    backgroundColor:theme.white,
    paddingLeft:'24px',
    paddingRight:'24px',
    paddingTop:'3px',
    paddingBottom:'8px',
    marginTop:'0px !important',
    marginLeft:'0px !important'
  }
}));
