import { PaginatedResult } from "@/common/types/reference-data";
import { callApi, TokenType } from "@/common/util/api";
import browserDatabase from "@/common/util/browser-database";
import { CONTEXT_KEY, ContextTypes } from "@/core/store/shared-data/sharedData.types";

import { CaseGuideForm } from "../../types/CaseTypes/caseGuide";

export const caseGuideApi = {
  tokenType: browserDatabase.getItem(CONTEXT_KEY) as ContextTypes === "platform" ? "access": "tenant",
  saveCaseGuide: function(caseTypeId: string,params: CaseGuideForm) {
    return callApi(
      `managed/icm-service/v1/case-types/${caseTypeId}/case-type-guides`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateCaseTypeGuide'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updateCaseGuide: function(caseTypeId: string, params: CaseGuideForm) {
    return callApi(
      `managed/icm-service/v1/case-types/${caseTypeId}/case-type-guides/${params._id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateCaseTypeGuide'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getCaseGuide: function(caseTypeId: string) {
    return callApi<PaginatedResult<CaseGuideForm>>(
      `managed/icm-service/v1/case-types/${caseTypeId}/case-type-guides`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListCaseTypeGuide'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  }
};