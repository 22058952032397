import { FC } from "react";

import { useAlertInsightTabStyles } from '@/tenant-context/employee-app-action-response-center/components/tabs/AlertInsightTab/AlertInsightTab.style';
import VideoThumbnail from '@/tenant-context/employee-app-action-response-center/components/ui/VideoThumbnail';
import { EmergencyVideo } from "@/tenant-context/employee-app-action-response-center/types/emergency-video";

type Props = {
  evidenceVideos: EmergencyVideo[]
  onClickThumbnail: (emergencyVideo: EmergencyVideo) => void
}

const AlertInsightTabComponent: FC<Props> = ({
  evidenceVideos,
  onClickThumbnail
}) => {

  const { classes } = useAlertInsightTabStyles();

  return (
    <div className={ classes.root }>

      { evidenceVideos.length === 0 && (
        <div className={ classes.noAlertFallback }>
          <h3>
            No Alert Insight found...
          </h3>
        </div>
      ) }

      { evidenceVideos.length > 0 && (
        <div className={ classes.videosGrid }>
          { evidenceVideos.map(
            (video, key) =>
              <VideoThumbnail
                emergencyVideo={ video }
                key={ key }
                onClickThumbnail={ onClickThumbnail }
              />
          ) }
        </div>
      ) }
    </div>
  );
};

export default AlertInsightTabComponent;
