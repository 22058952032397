import { closeAllModals } from "@mantine/modals";
import { createContext, FC, useCallback } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import { Dispatch } from "@/core/store";
import PersonalContactModal, { PersonalContactModalFormData } from "@/tenant-context/control-profile/components/modals/PersonalContactModal/PersonalContactModal.component";
import { ProfileContact } from "@/tenant-context/control-profile/types/profile";

export const PersonalContactModalContext = createContext<PersonalContactModalContextType>(
  {} as PersonalContactModalContextType
);

type Props = {
  // Phone / Mobile / Email
  sectionType: string,
  // Home / Work
  type: string,
  mode?: 'add' | 'edit',
  editableContact?: ProfileContact
}

type PersonalContactModalContextType = {
  onSubmit: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>,
  formControls: UseFormReturn,
  handleRemove: () => void
} & Props;

const PersonalContactModalContextProvider: FC<Props> = ({
  sectionType,
  type,
  mode = 'add',
  editableContact
}) => {
  const formControls = useForm();

  const {
    profile: {
      createPersonalContact,
      deletePersonalContact
    }
  } = useDispatch<Dispatch>();

  const handleSubmit = async (untypedData: unknown) => {
    const data = untypedData as PersonalContactModalFormData;

    const success = await createPersonalContact({
      mode,
      identifier: editableContact?._id,
      contactDetailType: sectionType,
      contactType: type,
      value: data.contact,
      isPrimary: data.isPrimary,
      provider: 'Restrata'
    });

    if (success) {
      closeAllModals();
    }
  };

  const handleRemove = useCallback(async () => {
    if (!editableContact) {
      return;
    }

    const success = await deletePersonalContact(editableContact._id);

    if(success){
      closeAllModals();
    }
    

  }, [deletePersonalContact, editableContact]);

  const onSubmit = formControls.handleSubmit(handleSubmit);

  return (
    <PersonalContactModalContext.Provider value={ useContextValue({
      onSubmit,
      formControls,
      sectionType,
      type,
      mode,
      editableContact,
      handleRemove
    }) }>
      <PersonalContactModal />
    </PersonalContactModalContext.Provider>
  );
};

export default PersonalContactModalContextProvider;