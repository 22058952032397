import { PickD3Scale } from "@visx/scale";
import { TooltipData } from "@visx/xychart";
import { FC, Fragment } from "react";

import { formatDate } from "@/common/util/format/date";
import { AssetRankingEvent } from "@/tenant-context/common/types/asset-ranking";

type Props = {
  tooltipData: TooltipData<AssetRankingEvent>
  colorScale: PickD3Scale<'ordinal', string, string>,
  accessors: {
    xAccessor: (d: AssetRankingEvent) => number;
    yAccessor: (d: AssetRankingEvent) => number;
  }
};

const LocationGraphTooltip: FC<Props> = ({
  tooltipData,
  colorScale,
  accessors
}) => {
  const {
    datumByKey = {}
  } = tooltipData || {};

  const datums = Object.values(datumByKey);

  if (!datums.length) {
    return null;
  }

  return (
    <div>
      { datums.map(({ datum, key }, index, { length }) => {
        const epoch = accessors.xAccessor(datum);

        return (<Fragment key={ key }>
          <div style={ {
            color: colorScale(key)
          } }>
            { datum.location.type }: { datum.location.id }
          </div>

          <div>
            <span>
              Date: { formatDate(new Date(epoch)) }
            </span>

            <br />

            <span>Rank: { accessors.yAccessor(datum) }</span>
          </div>

          { index < length - 1 && (
            <br />
          ) }
        </Fragment>);
      }) }
    </div>
  );
};

export default LocationGraphTooltip;