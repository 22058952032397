import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import MaxSecurityEventsSettingsComponent
  from '@/tenant-context/control-risk-config/components/MaxSecurityEventsSettings/MaxSecurityEventsSettings.component';

const MaxSecurityEventsSettings = () => {

  const disabledRiskFilterTypes = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.disabledRiskFilterTypes
  );
  const isGlobalCategoryToggleOn = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions?.isGlobalCategoryToggleOnMaxSecurity
  );

  const {
    riskEventFilterDataOptions: {
      ENABLE_FILTER,
      DISABLE_FILTER,
      ENABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY,
      DISABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY
    }
  } = useDispatch<Dispatch>();

  const handleSwitchToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        checked,
        value: filter
      } = event.currentTarget;

      if (checked) {
        ENABLE_FILTER(filter);
      } else {
        DISABLE_FILTER(filter);
      }
    },
    [ENABLE_FILTER, DISABLE_FILTER]
  );

  const handleGlobalCategoryToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      checked
    } = event.currentTarget;

    if (checked) {
      ENABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY();
    } else {
      DISABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY();
    }
  },[ENABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY, DISABLE_GLOBAL_CATEGORY_FILTER_MAX_SECURITY]);

  return (
    <MaxSecurityEventsSettingsComponent
      onCategoryToggle= { handleSwitchToggle }
      onChangeGlobalCategoryToggle={ handleGlobalCategoryToggle }
      isGlobalCategoryToggleOn = { isGlobalCategoryToggleOn }
      disabledRiskFilterTypes={ disabledRiskFilterTypes }
    />
  );
};

export default MaxSecurityEventsSettings;
