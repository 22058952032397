import { createStyles } from "@mantine/core";

export const useDropzoneFilesStyles = createStyles((theme) => ({
  btn: {
    all: 'unset',
    backgroundColor: 'unset !important',
    cursor: 'pointer',
    padding: '0 !important',
    '&:hover': {
      backgroundColor: 'unset !important'
    },
    '&.delete': {
      '& svg': {
        path: {
          fill: theme.colors.red[6]
        }
      }
    },
    '&.download': {
      '& svg': {
        path: {
          fill: theme.colors.blue[6]
        }
      }
    }

  },
  unauthorizedButton: {
    all: 'unset',
    backgroundColor: 'unset !important',
    border: 'none',
    background: 'transparent',
    opacity: 0.5,
    cursor: 'not-allowed'
  },
  err: {
    color: theme.colors.error[0]
  }
}));
