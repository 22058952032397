import { createModel } from "@rematch/core";

import { Dispatch, RootModel } from "@/core/store";

type CountryTravelState = {
  // TODO: Add state here
}

const countryTravelModel = ({
  name: 'countryTravel',
  state: {
  } as CountryTravelState,

  reducers: {
  },

  effects: (_dispatch: Dispatch) => ({
  })
});

export const countryTravel = createModel<RootModel>()(
  countryTravelModel
);
