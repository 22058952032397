/* eslint-disable no-magic-numbers */
import { createStyles } from "@mantine/core";


type Params = {
  isLabelIncluded: boolean,
  isUnauthorized: boolean
}

export const useButtonControlStyles = createStyles((theme, params: Params) => ({
  wrapper: {
    all: 'unset',
    boxSizing: 'border-box',
    backgroundColor: params.isUnauthorized ?  theme.colors.neutral[4] : theme.colors.neutral[9],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0 !important',
    cursor: 'pointer',
    border: `1px solid ${theme.colors.neutral[6]}`
  },

  btn: {
    '&.rightLabel': {
      '&::after': {
        left: 'unset',
        right: 0
      },

      '&:hover': {
        '&::after': {
          transform: 'translateX(100%)'
        }
      }
    },

    position: 'relative',
    overflowX: 'visible',

    '&:not(:first-of-type)': {
      marginTop: '10px !important'
    },

    '&:hover': {
      path: {
        fill: theme.white
      },

      '&::after': {
        width: 'fit-content',
        paddingInline: '8px',
        overflow: 'visible',
        opacity: 1,
        transform: 'translateX(-100%)'
      }
    },

    '&::after': {
      backgroundColor: params.isLabelIncluded ? theme.colors.blue[6] : "",
      content: 'attr(data-label)',
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: 0,
      width: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',

      opacity: 0,
      height: '100%',

      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center'
    }
  },

  unauthorized: {
    opacity: '0.5 !important'
  }
}));
