import { createStyles } from "@mantine/core";

export const useModalControlGroupStyles = createStyles((theme) => ({
  group: {
    marginTop: 48
  },
  unauthorizedButton: {
    all: 'unset',
    backgroundColor: `${theme.colors.neutral[3]} !important`,
    padding: '6px 12px',
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    fontSize: theme.fontSizes.sm,
    '*' : {
      color: `${theme.colors.neutral[7]} !important`,
      // All SVGs are {colored} in these actions
      '& > svg': {
        '& > path': {
          fill: `${theme.colors.neutral[7]} !important`
        }
      }
    },
    cursor: 'not-allowed',
    border: 'none !important'
  }
}));
