import { Divider } from "@mantine/core";
import { FC, useContext } from "react";

import ArcSection from "@/tenant-context/control-action-response-center/components/ArcSection";
import AlarmBadge from "@/tenant-context/employee-app-action-response-center/components/ui/AlarmBadge";
import { lwStatusMap } from "@/tenant-context/employee-app-action-response-center/components/ui/MiniProfileEmergencyBlock/MiniProfileEmergencyBlock.component";
import { useTaskSummaryStyles } from "@/tenant-context/employee-app-action-response-center/components/ui/TaskSummary/TaskSummary.style";
import TaskSummaryTime from "@/tenant-context/employee-app-action-response-center/components/ui/TaskSummaryTime";
import { EaArcContext } from "@/tenant-context/employee-app-action-response-center/context/EaArc.context";
import { addMinutesToTimestamp } from "@/tenant-context/employee-app-action-response-center/util/task-summary";
import { LWPersonStatus } from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config";

const TaskSummary: FC = () => {
  const { task, arcCase } = useContext(EaArcContext);

  const entry = lwStatusMap[arcCase?.loneWorkerStatus as LWPersonStatus];

  const scheduledEnd = task ? addMinutesToTimestamp(task.startedDateTime, task?.duration || 0) : 0;

  const remainingTime =
    task?.missedEndedDateTime && task?.missedEndedDateTime > 0
      ? task.missedEndedDateTime
      : scheduledEnd;

  const isDuressCancellation = arcCase?.loneWorkerStatus === 'DURESS_CANCELLED_EMERGENCY';

  const { title, riskLevelIndex } = entry || {};
  const { classes, cx } = useTaskSummaryStyles({
    riskLevelIndex: riskLevelIndex || 0
  });


  if (!arcCase || !entry) {
    return null;
  }

  return (
    <ArcSection>
      <div className={ classes.root }>
        <div className={ classes.topValueBlock }>
          <div className={ classes.alarmBadgeContainer }>
            <AlarmBadge
              className={ classes.alarmBadge }
              riskLevelIndex={ riskLevelIndex || 0 }
            />

            <div className={ classes.labeledValueContainer }>
              <span className={ classes.label }>
                Alarm Type
              </span>
              <span className={ cx(classes.value, classes.emphasized) }>
                { title }
              </span>
            </div>
          </div>

          <div className={ classes.topValueBlockRight }>
            <TaskSummaryTime
              direction="row"
              time={ (isDuressCancellation && arcCase.duressUpdatedDataTime)
                ? arcCase.duressUpdatedDataTime : arcCase.eventStartedDataTime }
              timeStyle='relative'
              isAgo
            /> 
          </div>
        </div>

        { /* Render task block only if task exists */ }
        { task && (<>
          <Divider my="xs"/>

          <div className={ classes.bottomValueBlock }>
            <div className={ classes.labeledValueContainer }>
              <span className={ classes.label }>
                Active Task
              </span>
              <span className={ cx(classes.value, classes.emphasized) }>
                { task.taskName }
              </span>
            </div>

            <div className={ classes.labeledValueContainer }>
              <span className={ classes.label }>
                Task Start
              </span>
              <TaskSummaryTime time={ task.startedDateTime } />
            </div>

            <div className={ classes.labeledValueContainer }>
              <span className={ classes.label }>
                Scheduled End
              </span>

              <TaskSummaryTime time={ scheduledEnd } />
            </div>

            <div className={ classes.labeledValueContainer }>
              <span className={ classes.label }>
                Time remaining on task
              </span>

              <TaskSummaryTime
                time={ remainingTime }
                timeStyle={ task?.missedEndedDateTime > 0 ? 'overdue' :'relative' }
                riskLevelIndex={ riskLevelIndex || 0 }
                isWithDate={ false }
              />
            </div>
          </div>
        </>) }
      </div>
    </ArcSection>
  );
};

export default TaskSummary;
