import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useRoleDetailsStyles = createStyles((theme: MantineTheme) => ({
  roleDetailsContainer: {
    padding: 32,
    width: '100%',
    backgroundColor: theme.colors.neutral[9]
  },
  roleTabs:{
    height: 50
  },
  subText:{
    fontSize: theme.fontSizes.sm,
    color: theme.colors.neutral[5]
  },
  featureHeader:{
    fontSize: theme.fontSizes.md
  },
  featureBlock:{
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gap: '24px',
    borderBottom: `1px solid ${theme.other.semantic.border.general.lighter}`,
    padding: '8px 0px',
    height: "100%"
  },
  featureSection: {
    display: "flex",
    alignSelf: 'center'
  },
  relative:{
    position: "relative"
  },
  accessPermissionsContainer: {
    backgroundColor: theme.other.semantic.surfaceBackground.primary,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 24
  },
  accessPermission: {
    backgroundColor: theme.colors.neutral[8],
    display: "flex",
    flexDirection: "column",
    padding: '16px',
    gap: '16px'
  }
}));
