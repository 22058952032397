import { FC, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock/ProfileBlock.component";
import ProfileKeyValue from "@/tenant-context/control-profile/components/ui/ProfileKeyValue/ProfileKeyValue.component";
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock/ProfileKeyValueBlock.component";
import { ProfileGeneralSection } from "@/tenant-context/control-profile/types/profile";

type Props = {
  createAction?: (section: ProfileGeneralSection) => () => ReactNode;
};

const ProfileCompanyDetails: FC<Props> = ({
  createAction
}) => {
  const {
    profile: {
      loadCompanyDetails
    }
  } = useDispatch<Dispatch>();

  const profileId = useSelector(
    ({ profile: { id } }: RootState) => id
  );

  const company = useSelector(
    ({ profile: { companyDetails: cd } }: RootState) => cd
  );

  useEffect(() => {
    loadCompanyDetails();
  }, [loadCompanyDetails, profileId]);

  return (
    <ProfileBlock
      heading="Company Details"
      renderAction={ createAction?.(ProfileGeneralSection.Company) }
    >
      <ProfileKeyValueBlock
        gtc={ '1fr 2fr' }
        maxColumns={ 2 }
      >
        <ProfileKeyValue firstItem="label" name={ 'Company' } value={ company?.companyName }/>
        <ProfileKeyValue firstItem="label" name={ 'Employee ID' } value={ company?.employeeId }/>
        <ProfileKeyValue firstItem="label" name={ 'Job Title' } value={ company?.jobTitle }/>
        <ProfileKeyValue firstItem="label" name={ 'Department' } value={ company?.department }/>
        <ProfileKeyValue firstItem="label" name={ 'Department Head' } value={ company?.departmentHead }/>
        <ProfileKeyValue firstItem="label" name={ 'Manager' } value={ company?.lineManagerName }/>
        <ProfileKeyValue firstItem="label" name={ 'Person Type' } value={ company?.personType }/>
        <ProfileKeyValue firstItem="label" name={ 'Employee Type' } value={ company?.employeeType }/>
        <ProfileKeyValue firstItem="label" name={ 'Comment' } value={ company?.comment }/>
      </ProfileKeyValueBlock>
    </ProfileBlock>
  );
};

export default ProfileCompanyDetails;
