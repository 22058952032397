import { FC, useContext } from "react";

import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import ArcColumn from "@/tenant-context/control-action-response-center/components/ArcColumn";
import { useSummaryTabStyles } from "@/tenant-context/employee-app-action-response-center/components/tabs/SummaryTab/SummaryTab.style";
import ActivityLog from "@/tenant-context/employee-app-action-response-center/components/ui/ActivityLog";
import EmergencyVideoPopup from "@/tenant-context/employee-app-action-response-center/components/ui/EmergencyVideoPopup";
import PersonDidNotShareLocation from "@/tenant-context/employee-app-action-response-center/components/ui/PersonDidNotShareLocation";
import Playbook from "@/tenant-context/employee-app-action-response-center/components/ui/Playbook";
import SummaryMap from "@/tenant-context/employee-app-action-response-center/components/ui/SummaryMap";
import TaskSummary from "@/tenant-context/employee-app-action-response-center/components/ui/TaskSummary";
import { EaArcContext } from "@/tenant-context/employee-app-action-response-center/context/EaArc.context";

const SummaryTab: FC = () => {
  const { classes, cx } = useSummaryTabStyles();

  const { isEmergencyVideoShown } = useContext(EaArcContext);

  return (
    <div
      className={ cx({
        [classes.root]: true,
        [classes.grid]: true
      }) }
    >
      <ArcColumn>
        <div className={ classes.taskSummary }>
          <LocalErrorBoundary>
            <TaskSummary />
          </LocalErrorBoundary>
        </div>

        <div className={ classes.summaryMap }>
          { isEmergencyVideoShown && <div className={ classes.emergencyVideoWrapper }>
            <EmergencyVideoPopup />
          </div> }

          <LocalErrorBoundary>
            <SummaryMap />
          </LocalErrorBoundary>
          <PersonDidNotShareLocation />
        </div>
      </ArcColumn>

      <ArcColumn className={ classes.gridColumn }>
        <Playbook source="EA-ARC" />

        <ActivityLog />
      </ArcColumn>
    </div>
  );
};

export default SummaryTab;
