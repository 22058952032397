import { Button } from "@mantine/core";
import React, { forwardRef, ForwardRefRenderFunction } from "react";

import ServerDataList, { ServerDataListRef } from "@/common/components/ServerDataList/ServerDataList.container";
import { GridParams } from "@/common/types/ag-grid";
import { constructTestId } from "@/core/util/test-id";
import {
  useCreateChecklistItemModalStyles
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/CreateChecklistItemModal/CreateCheckListItemModal.styles";
import {
  ChecklistItem
} from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

const FORM_NAME = 'caseTypeRoleChecklist';

type Props = {
    isAuthorizedToCreateOrEdit: boolean
    getData: (payload: {
        gridParams: GridParams,
    }) => Promise<PaginatedResult<ChecklistItem>>,
    handleCreateCheckListItem: () => void,
    handleAddPlaybookItem: () => void,
    renderRowTemplate: (row: ChecklistItem) => JSX.Element
    caseTypeId: string
}

const ChecklistComponent: ForwardRefRenderFunction<ServerDataListRef, Props>  = (({
  isAuthorizedToCreateOrEdit,
  getData,
  handleCreateCheckListItem,
  handleAddPlaybookItem,
  renderRowTemplate,
  caseTypeId
}, ref) => {

  const { classes } = useCreateChecklistItemModalStyles();

  const headerContent = <div className={ classes.checklistHeader }>
    <Button
      data-testid={ constructTestId("BUTTON", FORM_NAME, "click") }
      onClick={ handleAddPlaybookItem }
      className={ classes.createButton }
      disabled={ !isAuthorizedToCreateOrEdit }
      variant='outline'
    >
      Copy Items from Playbooks
    </Button>
    <Button
      data-testid={ constructTestId("BUTTON", FORM_NAME, "submit") }
      onClick={ handleCreateCheckListItem }
      className={ classes.createButton }
      disabled={ !isAuthorizedToCreateOrEdit }
    >
      Create Item
    </Button>
  </div>;
    
  return (
    <div>
      <ServerDataList<ChecklistItem>
        getData = { getData }
        id ={ "checklist-grid" }
        getRowTemplate={ renderRowTemplate }
        ref={ ref }
        search
        additionalGridParams={ { caseTypeId: caseTypeId } }
        headerContent={ headerContent }
        sortingConfigs={ [{
          field: "itemName",
          label: "Item Name"
        }] }
      />
    </div>
  );
});

export default forwardRef(ChecklistComponent) as (
    props: Props & React.RefAttributes<ServerDataListRef>
) => JSX.Element;