import { FC } from "react";

import CountryFlag from "@/tenant-context/common/components/CountryFlag/CountryFlag.component";
import { useDroppedInfoBoxStyles } from "@/tenant-context/common/components/DroppableMapTopInfoBox/DroppableMapTopInfoBox.styles";
import { Location } from "@/tenant-context/common/types/location";
import {
  useCountrySearchDropdownStyles
} from "@/tenant-context/widget-overview/components/CountrySearchDropdown/CountrySearchDropdown.styles";
import { useSiteSearchDropdownStyles } from "@/tenant-context/widget-overview/components/SiteSearchDropdown/SiteSearchDropdown.styles";


type Props = {
  countryList: Location[],
  onCountrySelected: (selectedCountry: Location) => void
  countryMustering?: Record<string, number>
}

const CountrySearchDropdownComponent: FC<Props> = ({
  countryList,
  onCountrySelected,
  countryMustering
}) => {
  const { classes: { listItemButton, musterSite }, cx } = useDroppedInfoBoxStyles(undefined);
  const { classes } = useCountrySearchDropdownStyles();
  const { classes: { siteContainer, musterContainer, musterCount } } = useSiteSearchDropdownStyles();

  const createHandleCountryClick = (country: Location) => (_: React.MouseEvent<HTMLButtonElement>) => {
    onCountrySelected(country);
  };

  const isCountryMustering = (country: Location) => {
    return country?.isoCountryCode && countryMustering && countryMustering[country?.isoCountryCode] > 0;
  };


  return (
    <div className={ classes.wrapper }>
      <ul>
        { countryList.map((country) => (
          <li
            key={ country.name }
          >
            <button
              className={ cx(listItemButton, classes.countryName, {
                [musterSite]: isCountryMustering(country)
              }) }
              onClick={ createHandleCountryClick(country) }
            >
              <div className={ siteContainer }>
                <CountryFlag countryCode={ country?.isoCountryCode }/>
                <div className={ classes.countryContainer }>
                  <div>
                    { country.name }
                  </div>
                  { countryMustering && isCountryMustering(country)
                    ?
                    (
                      <div className={ musterContainer }>
                        <div className={ musterCount }>
                          { countryMustering[country?.isoCountryCode] }
                        </div>
                      </div>
                    ) : null }
                </div>
              </div>
            </button>
          </li>
        )) }
      </ul>
    </div>
  );
};

export default CountrySearchDropdownComponent;
