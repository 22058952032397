import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { store } from '@/core/store';
import { Dispatch, RootState } from "@/core/store";
import { MapKey, PopupProps } from "@/tenant-context/core/store/map-popup/map-popup.model";

export type { MapKey, PopupProps } from "@/tenant-context/core/store/map-popup/map-popup.model";
export { BigMapPopupIds } from "@/tenant-context/common/types/popup.types";
/** This is a `NEW` hook for map popups (instead `useMapPopup()`), but don't use it. See more specific hook below */
export const useMapPopupList = (mapKey: MapKey) => {
  const { popups } = useSelector((state: RootState) => state.mapPopup[mapKey]);
  const {
    mapPopup: {
      createPopup,
      removePopup,
      updatePopup: _updatePopup,
      clearAllPopups,
      updateWithFilter,
      updateWithMap
    }
  } = useDispatch<Dispatch>();

  /** Create & show a new popup.
   *
   * 99.9% you need just create and render, then close - delete it.
   *
   * But just in case: Pass isVisible=false to create but not display it
   */
  const openPopup = useCallback(
    (popupProps: Omit<PopupProps, "isVisible"> & { isVisible?: boolean }) => {
      const isVisible = popupProps.isVisible ?? true;
      createPopup({ popupProps: { ...popupProps, isVisible }, mapKey });
    },
    [createPopup, mapKey]
  );

  /** Close and remove */
  const closePopup = useCallback(
    (id: string) => {
      removePopup({ id, mapKey });
    },
    [removePopup, mapKey]
  );

  const closeAllPopups = useCallback(() => {
    clearAllPopups({ mapKey });
  }, [clearAllPopups, mapKey]);

  const updatePopup = useCallback(
    (id: string, props: Partial<Omit<PopupProps, "id">>) => {
      // we don't wait then useSelector will update, because `updatePopup` can be invoked right after `openPopup` in the same async function
      const currentPopups = store.getState().mapPopup[mapKey].popups;
      const prevProps = currentPopups.find((popup) => popup.id === id);
      if(!prevProps) {
        return;
      }
      const { isVisible = true, position, data } = props;
      _updatePopup({
        popupProps: {
          ...prevProps,
          id,
          isVisible,
          position: position ?? prevProps.position,
          data: data ?? prevProps.data
        },
        mapKey
      });
    },
    [_updatePopup, mapKey]
  );

  const getPopupData = useCallback(
    <TData = unknown>(id: string): TData | null => {
      const exist = popups.find((popup) => popup.id === id);
      return (exist?.data as TData) || null;
    },
    [popups]
  );

  const filterPopups = useCallback(
    (filterCallback: (popup: PopupProps) => boolean) => {
      updateWithFilter({ mapKey, filterCallback });
    },
    [mapKey, updateWithFilter]
  );

  const mapPopups = useCallback(
    (mapCallback: (popup: PopupProps) => PopupProps) => {
      updateWithMap({ mapKey, mapCallback });
    },
    [mapKey, updateWithMap]
  );

  return {
    openPopup,
    closePopup,
    closeAllPopups,
    filterPopups,
    mapPopups,
    updatePopup,
    getPopupData,
    popups
  };
};


export const useCurrentPopup = <TData = unknown>(mapKey: MapKey, popupId: string) => {
  const popup = useSelector((state: RootState) =>
    state.mapPopup[mapKey].popups.find((p) => p.id === popupId));

  const {
    mapPopup: { removePopup }
  } = useDispatch<Dispatch>();

  /** Close and remove */
  const closePopup = useCallback(() => {
    removePopup({ id: popupId, mapKey });
  }, [removePopup, popupId, mapKey]);

  return {
    popup: popup as PopupProps<TData> | undefined,
    closePopup
  };
};
