import { Button } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { FC, Fragment, useCallback } from 'react';

import { useUnauthorizedWarningModalStyles } from '@/common/components/UnauthorizedWarningModal/UnauthorizedWarningModal.style';

type Props = {
  messageOptions?: { customAlert?: string, customMessage?: string }
}

const UnauthorizedWarningModalComponent: FC<Props> = ({ messageOptions }) => {
  const { classes } = useUnauthorizedWarningModalStyles();

  const close = useCallback(() => {
    closeAllModals();
  }, []);

  return (
    <Fragment>
      <div className={ classes.messageArea }>
        <span className={ classes.dangerText }>{ messageOptions?.customAlert || 'Please Note' }</span>
        { messageOptions?.customMessage || 'This license does not have access to this feature, please get in touch with your administrator.' }
      </div>
      <div className={ classes.footer }>
        <Button size={ 'sm' } onClick={ close }>Okay</Button>
      </div>
    </Fragment>
  );
};

export default UnauthorizedWarningModalComponent;
