import { Box, createStyles, Flex } from '@mantine/core';
import { FC } from "react";

import { ReactComponent as ResilienceOne } from "@/core/navigation/icons/v2-icons/logo-r1.svg";
import { ReactComponent as ResilienceTwo } from "@/core/navigation/icons/v2-icons/logo-r2.svg";
import { ReactComponent as OSLogo } from "@/core/navigation/icons/v2-icons/logo-r3.svg";

type Props = {
  isExpanded: boolean;
  onClick?: () => void;
};

const useLogoStyles = createStyles((_, args: { isExpanded: boolean }) => ({
  container: {
    padding: '34px 20px',
    scale: args.isExpanded ? '1' : '1'
  },
  mid: {
    overflow: 'hidden'
  }
}));

const ResilienceOSLogo: FC<Props> = ({ isExpanded, onClick }) => {
  const { classes } = useLogoStyles({ isExpanded });

  return (
    <Flex align="flex-end" className={ classes.container } onClick={ onClick }>
      <Box><ResilienceOne/></Box>
      <Box w={ isExpanded ? 114 : 0 } className={ classes.mid }><ResilienceTwo/></Box>
      <Box><OSLogo/></Box>
    </Flex>
  );
};

export default ResilienceOSLogo;
