import { PieArcDatum } from "@visx/shape/lib/shapes/Pie";

/**
 * This function is used to get the offset value from subject for visx pie chart's tooltip. This is binded to our design
 *
 * @param arc - PieArcDatum
 * @param pathCentroid - Center value of the arc (Get by path.centroid(arc));
 * @param axis - X axis or Y axis
 */

export const getOffsetFromSubject = (
  arc: PieArcDatum<unknown>,
  pathCentroid: [number, number],
  axis: 'x' | 'y'
) => {
  const currentAngle = (arc.endAngle + arc.startAngle) / 2;
  const arcValue = axis === 'x' ? pathCentroid[0] : pathCentroid[1];
  // eslint-disable-next-line fp/no-let
  let side: 'tr' | 'br' | 'bl' | 'tl';

  if (currentAngle < 1.5625) {
    side = 'tr';
  } else if (currentAngle >= 1.5625 && currentAngle < 3.125) {
    side = 'br';
  } else if (currentAngle >= 3.125 && currentAngle < 4.6875) {
    side = 'bl';
  } else {
    side = 'tl';
  }

  if (side === 'tr') {
    return arcValue + 20;
  } else if (side === 'br') {
    return arcValue + (axis === 'x' ? 20 : +10);
  } else if (side === 'bl') {
    return arcValue;
  } else {
    return arcValue - (axis === 'x' ? 20 : -10);
  }
};
