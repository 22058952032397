import { createModel } from "@rematch/core";
import { Position } from "geojson";

import { RootModel } from "@/core/store";

type MapPopupState = {
  bigMapPopups: PopupsOnMapState;
  geofenceMap: PopupsOnMapState;
  // you can add state for popups on other maps here, see `MapKey` type below
};

export type MapKey = "bigMapPopups" | "geofenceMap";

type PopupsOnMapState = {
  popups: Array<PopupProps>;
};

export type PopupProps<TData = unknown> = {
  id: string;
  /** Array of two elements, they are: [ `longitude`,  `latitude` ] */
  position: Position;
  /** The popup data, e.g. put here Feature or FeatureCollection json for current popup */
  data: TData;
  isVisible: boolean;
};

function getEmptyPopupsState(): PopupsOnMapState {
  return {
    popups: []
  };
}

function getDefaultState(): MapPopupState {
  return {
    bigMapPopups: getEmptyPopupsState(),
    geofenceMap: getEmptyPopupsState()
  };
}

export const mapPopup = createModel<RootModel>()({
  state: getDefaultState(),
  reducers: {
    createPopup(
      state: MapPopupState,
      payload: { popupProps: PopupProps; mapKey: MapKey }
    ) {
      const { popupProps, mapKey } = payload;
      const exist = state[mapKey].popups.find(({ id }) => id === popupProps.id);

      return {
        ...state,
        [mapKey]: {
          popups: [
            ...state[mapKey].popups.filter(({ id }) => id !== exist?.id),
            popupProps
          ]
        }
      };
    },
    removePopup(state: MapPopupState, payload: { id: string; mapKey: MapKey }) {
      const { id, mapKey } = payload;
      return {
        ...state,
        [mapKey]: {
          popups: state[mapKey].popups.filter((popup) => popup.id !== id)
        }
      };
    },
    updatePopup(
      state: MapPopupState,
      payload: { popupProps: PopupProps; mapKey: MapKey }
    ) {
      const { popupProps, mapKey } = payload;
      return {
        ...state,
        [mapKey]: {
          popups: state[mapKey].popups.map((popup) =>
            popup.id === popupProps.id ? popupProps : popup)
        }
      };
    },
    /** Close all popups, clear the popups array */
    clearAllPopups(state: MapPopupState, payload: { mapKey: MapKey }) {
      const { mapKey } = payload;
      return {
        ...state,
        [mapKey]: {
          popups: []
        }
      };
    },
    /** Update popups array with `map` func */
    updateWithMap(
      state: MapPopupState,
      payload: { mapCallback: (p: PopupProps) => PopupProps; mapKey: MapKey }
    ) {
      const { mapCallback, mapKey } = payload;
      return {
        ...state,
        [mapKey]: {
          popups: state[mapKey].popups.map(mapCallback)
        }
      };
    },
    /** Filter popups array with `filter` func */
    updateWithFilter(
      state: MapPopupState,
      payload: { filterCallback: (p: PopupProps) => boolean; mapKey: MapKey }
    ) {
      const { filterCallback, mapKey } = payload;
      return {
        ...state,
        [mapKey]: {
          popups: state[mapKey].popups.filter(filterCallback)
        }
      };
    }
  }
});
