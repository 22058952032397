/* eslint-disable fp/no-loops */
import { store } from "@/core/store";

import { createUrl,CustomRequestOptions } from "./api";
import { TENANT_TOKEN_KEY } from "./auth";
import browserDatabase from "./browser-database";

type EventCallback<T> = (event: { data?: T; error?: Error }) => void;

export enum CustomEventSourceResponseType {
    error = "error",
    message = "message"
}

export const createCustomEventSource = <T>(url: string, options: RequestInit & CustomRequestOptions) => {
  const eventListeners: Record<string, EventCallback<T>[]> = {};

  const addEventListener = (type: string, callback: EventCallback<T>) => {
    if (!eventListeners[type]) {
      eventListeners[type] = [];
    }
    eventListeners[type].push(callback);
  };

  const dispatchEvent = (type: string, event: { data?: T; error?: Error }) => {
    if (eventListeners[type]) {
      // eslint-disable-next-line fp/no-loops
      for (const listener of eventListeners[type]) {
        listener(event);
      }
    }
  };

  const exec = async () => {
    try {
      const tenantId = store.getState().commonData?.tenantId;
      const tenantToken = browserDatabase.getItem(TENANT_TOKEN_KEY);

      const response = await fetch(createUrl(url).toString(), {
        headers: { 
          ...options.headers,
          'X-TenantId': tenantId, 
          'Authorization': `Bearer ${tenantToken}`,
          'Content-Type': 'application/json' }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('Reader not supported');
      }

      // eslint-disable-next-line fp/no-let
      let buffer = '';

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        buffer += new TextDecoder().decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (line.startsWith('data:')) {
            const event = JSON.parse(line.slice(5));

            //ignore heartbeat
            if(event._type === "HB"){
              continue;
            }
            dispatchEvent(CustomEventSourceResponseType.message, { data: event });
          }
        }
      }
    } catch (error) {
      dispatchEvent(CustomEventSourceResponseType.error, { error: error as Error });
    }
  };

  return {
    addEventListener,
    exec
  };
};