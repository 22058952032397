import { FC } from 'react';
import { Popup } from 'react-map-gl';

import { useGeofenceDataPopupStyles } from '@/tenant-context/control-draw/components/GeofenceDataPopup/GeofenceDataPopup.styles';
import { GeofenceProperty } from '@/tenant-context/control-draw/types/draw';

export type Props = {
  isShown: boolean;
  longitude: number;
  latitude: number;
  properties: GeofenceProperty[];
}

export const GeofenceDataPopupComponent: FC<Props> = ({
  isShown,
  longitude,
  latitude,
  properties
}) => {

  const { classes } = useGeofenceDataPopupStyles();

  if (!isShown) {
    return null;
  }

  return (
    <Popup
      longitude={ longitude }
      latitude={ latitude }
      offset={ 50 }
      closeButton={ false }
      closeOnClick={ false }
      className = { classes.popUp }
    >
      <div className={ classes.popupContainer }>
        { properties.map((property, key) => (
          <div key={ key } className={ classes.propertyContainer }>
            <div className={ classes.propertyName }>{ property.name } : </div>
            <div className={ classes.propertyValue }>{ property.value }</div>
            <div className={ classes.propertyValue }>{ property.unit }</div>
          </div>
        )) }
      </div>
    </Popup>
  );
};

export default GeofenceDataPopupComponent;
