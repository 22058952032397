import { InputStylesParams } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

import { ReactComponent as Chevron } from "@/common/icons/Arrows/select-chevron.svg";
import { ThemeComponent } from "@/core/styles/mantine/types";

import { primitiveNumbers } from "../constants";
import { SelectComponentStyle } from './SelectComponentStyle';

const getMinHeight = (size: string): number => {
  const minHeightMap: Record<string, number> = {
    xs: primitiveNumbers['3x'],
    sm: primitiveNumbers['3x'],
    md: primitiveNumbers['4x'],
    lg: primitiveNumbers['6x'],
    xl: primitiveNumbers['6x']
  };

  return minHeightMap[size];
};

const getRightIconSize = (size: string): number => {
  const iconSizeMap: Record<string, number> = {
    xs: primitiveNumbers['2x'],
    sm: primitiveNumbers['2x'],
    md: primitiveNumbers['2x'],
    lg: primitiveNumbers['3x'],
    xl: primitiveNumbers['3x']
  };

  return iconSizeMap[size];
};

const getFontSize = (theme: MantineTheme, size: string): number => {
  const fontSizeMap: Record<string, number> = {
    xs: theme.fontSizes.xs,
    sm: theme.fontSizes.xs,
    md: theme.fontSizes.sm,
    lg: theme.fontSizes.md,
    xl: theme.fontSizes.md
  };

  return fontSizeMap[size];
};

export const MultiSelectComponentStyle: ThemeComponent = {
  defaultProps: {
    rightSection: <Chevron />,
    size: 'lg'
  },
  styles: (theme: MantineTheme, params: InputStylesParams) => ({
    ...(SelectComponentStyle?.styles as CallableFunction)?.(theme, params),
    input:{
      marginBottom: "6px",
      minHeight: getMinHeight(params.size),
      height: `${getMinHeight(params.size)}px !important`,
      fontSize: `${getFontSize(theme, params.size)}px !important`
    },
    values: {
      minHeight: getMinHeight(params.size),
      height: `${getMinHeight(params.size)}px !important`,
      fontSize: `${getFontSize(theme, params.size)}px !important`
    },
    searchInput: {
      minHeight: getMinHeight(params.size),
      height: `${getMinHeight(params.size)}px !important`,
      fontSize: `${getFontSize(theme, params.size)}px !important`
    },
    rightSection: {
      pointerEvents: "none",
      transition: 'all ease-in 200ms',
      '[aria-expanded=true] &': {
        transform: 'rotate(180deg)'
      },

      '& svg': {
        width: getRightIconSize(params.size),
        height: getRightIconSize(params.size)
      }
    }
  })
};
