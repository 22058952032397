import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { CustomerLocationLayerConfig } from "@/tenant-context/visualisation-customer-locations/configs/CustomerLocationLayer.config";

export const constructCustomerLocationQuery = (disabledLayerTypes: ToggleableLayerType[]): string => {

  const query = CustomerLocationLayerConfig
    .filter((layer) => !disabledLayerTypes.includes(layer.toggleableLayerType))
    .map((layer) => `subCategoryName='${layer.fieldName}' or `)
    .join('');

  const letterCountToRemoveLastOr = -4;
  return query.slice(0, letterCountToRemoveLastOr);
};
