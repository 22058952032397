import { callApi } from "@/common/util/api";
import { AuthTokenMsg } from "@/core/types/auth";

export const refreshToken = (token?: string, isPlatformToken = false) => callApi<AuthTokenMsg>(
  `/managed/token?grant_type=refresh_token&access_type=offline`,
  {
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    withTenantId: !isPlatformToken,
    withAuthorization: false
  }
);
