import { Flex, Progress, Text } from '@mantine/core';
import { FC, Fragment, useMemo } from 'react';

import {
  useCountryRiskStatusCardStyles
} from '@/tenant-context/control-reports/components/CountryRiskStatusCard/CountryRiskStatusCard.styles';
import { CountryRiskData } from '@/tenant-context/control-reports/types/country-risk-reports';
import {
  getRiskColorByRiskLevel, getRiskLevelValueByRiskLevel
} from '@/tenant-context/control-reports/util/country-risk-reports';

type Props = {
  countryRiskData: CountryRiskData;
}

const CountryRiskStatusCardMaxSecurity: FC<Props> = ({
  countryRiskData
}) => {
  const getProgressColor = useMemo(() => {
    return getRiskColorByRiskLevel(countryRiskData.riskLevel);
  }, [countryRiskData.riskLevel]);

  const riskLevel = useMemo(() => {
    return getRiskLevelValueByRiskLevel(countryRiskData.riskLevel);
  }, [countryRiskData.riskLevel]);

  const { classes } = useCountryRiskStatusCardStyles({ progressColor: getProgressColor });

  return (
    <Fragment>
      <Flex className={ classes.progressBarContainer } direction="column">
        <Text size={ 12 } transform="capitalize">{ countryRiskData.riskLevel }</Text>
        <Progress value={ riskLevel } className={ classes.progressBar }/>
      </Flex>
      <Flex wrap="wrap" gap={ 4 }>
        <Flex className={ classes.countryStatusBadge } gap="xs">
          <Text className={ classes.dimText }>Total Population:</Text>
          <Text className={ classes.lightText }>{ countryRiskData.totalPopulation }</Text>
        </Flex>
        <Flex className={ classes.countryStatusBadge } gap="xs">
          <Text className={ classes.dimText }>Official Language:</Text>
          <Text className={ classes.lightText }>{ countryRiskData.officialLanguages }</Text>
        </Flex>
        <Flex className={ classes.countryStatusBadge } gap="xs">
          <Text className={ classes.dimText }>Currency:</Text>
          <Text className={ classes.lightText }>{ countryRiskData.currency?.currencyCode }</Text>
        </Flex>
        <Flex className={ classes.countryStatusBadge } gap="xs">
          <Text className={ classes.dimText }>Exchange Rage:</Text>
          <Text className={ classes.lightText }>{ countryRiskData.currency?.currencyRate }</Text>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default CountryRiskStatusCardMaxSecurity;
