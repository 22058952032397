import { PrimitiveNumbers } from "@/core/styles/mantine/types";

export const primitiveNumbers: PrimitiveNumbers = {
  none: 0,
  pointFive: 0.5,
  one: 1,
  quarterX: 2,
  halfX: 4,
  x: 8,
  '2x': 16,
  '3x': 24,
  '4x': 32,
  '5x': 40,
  '6x': 48,
  '7x': 56,
  '8x': 64,
  '9x': 72,
  '10x': 80,
  '11x': 88,
  '12x': 96,
  '13x': 104
};

export const CSS_KEY = 'restrata';