import { FeatureCollection } from "geojson";
import { FC, useCallback } from "react";
import { Source } from "react-map-gl";
import { useSelector } from "react-redux";

// import ToggleableLayer from "@/common/components/ToggleableLayer";
import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { RootState } from "@/core/store";
import theme from "@/core/styles/mantine/theme";
import DynamicallyVisibleLayer from "@/tenant-context/common/components/DynamicallyVisibleLayer";
import { PERSON_RED_ALERT_STATUSES, PERSON_YELLOW_ALERT_STATUSES } from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config";

// So it wouldn't conflict with ids from CountryRiskLevelLayer
const LAYER_ID_PREFIX = 'countryTravelRisk';

const layerIdByRiskLevel = {
  none: `${LAYER_ID_PREFIX}_none`,
  low: `${LAYER_ID_PREFIX}_low`,
  moderate: `${LAYER_ID_PREFIX}_moderate`,
  medium: `${LAYER_ID_PREFIX}_medium`,
  high: `${LAYER_ID_PREFIX}_high`,
  extreme: `${LAYER_ID_PREFIX}_extreme`
};

// convenient way to set listeners in parent component
export const allCountryTravelRiskLayerIds = Object.values(layerIdByRiskLevel);

type Props = {
  riskLevelWiseCountries: { [key: string]: string[] },
  TravelersCountData: FeatureCollection
  travellersData?: FeatureCollection
  highlightedCountryIso?: string
  highlightedTravellerId?: string
}

const CountryTravelLayerComponent: FC<Props> = ({
  riskLevelWiseCountries,
  TravelersCountData,
  travellersData,
  highlightedCountryIso,
  highlightedTravellerId
}) => {


  const selectedCountry = useSelector((state: RootState) => state.travellersSearch.selectedCountry);

  const getCountryRiskColor = useCallback((riskLevel?: string) => {
    switch (riskLevel) {
    case '1':
      return '#47815D';
    case '2':
      return '#13AE87';
    case '3':
      return '#F3CA58';
    case '4':
      return '#D58B58';
    case '5':
      return '#AE3B3B';
    default:
      return '#47815D';
    }
  },[]);

  return (
    <>
      <Source
        id="r__countryTravelRiskLevelSource"
        type="vector"
        url="mapbox://mapbox.country-boundaries-v1"
      >
        { /*No Risk Countries*/ }
        { (<DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.TravelCountriesView }
          id={ layerIdByRiskLevel.none }
          type="fill"
          source-layer="country_boundaries"
          source="r__countryTravelRiskLevelSource"
          paint={ {
            'fill-color': '#FFFFFF',
            'fill-opacity': 0.3
          } }
          beforeId="waterway-label"
          filter={ [
            "in",
            "iso_3166_1",
            ...riskLevelWiseCountries["0"]
          ] }
        />) }

        { /*Low Risk Countries*/ }
        { (<DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.TravelCountriesView }
          id={ layerIdByRiskLevel.low }
          type="fill"
          source-layer="country_boundaries"
          source="r__countryTravelRiskLevelSource"
          paint={ {
            'fill-color': '#47815D',
            'fill-opacity': 0.3
          } }
          beforeId="waterway-label"
          filter={ [
            "in",
            "iso_3166_1",
            ...riskLevelWiseCountries["1"]
          ] }
        />) }

        { /*Moderate Risk Countries*/ }
        { (<DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.TravelCountriesView }
          id={ layerIdByRiskLevel.moderate }
          type="fill"
          source-layer="country_boundaries"
          source="r__countryTravelRiskLevelSource"
          paint={ {
            'fill-color': '#13AE87',
            'fill-opacity': 0.3
          } }
          beforeId="waterway-label"
          filter={ [
            "in",
            "iso_3166_1",
            ...riskLevelWiseCountries["2"]
          ] }
        />) }

        { /*Medium Risk Countries*/ }
        { (<DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.TravelCountriesView }
          id={ layerIdByRiskLevel.medium }
          type="fill"
          source-layer="country_boundaries"
          source="r__countryTravelRiskLevelSource"
          paint={ {
            'fill-color': '#F3CA58',
            'fill-opacity': 0.3
          } }
          beforeId="waterway-label"
          filter={ [
            "in",
            "iso_3166_1",
            ...riskLevelWiseCountries["3"]
          ] }
        />) }

        { /*High Risk Countries*/ }
        { (<DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.TravelCountriesView }
          id={ layerIdByRiskLevel.high }
          type="fill"
          source-layer="country_boundaries"
          source="r__countryTravelRiskLevelSource"
          paint={ {
            'fill-color': '#D58B58',
            'fill-opacity': 0.3
          } }
          beforeId="waterway-label"
          filter={ [
            "in",
            "iso_3166_1",
            ...riskLevelWiseCountries["4"]
          ] }
        />) }

        { /*Extreme Risk Countries*/ }
        { (<DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.TravelCountriesView }
          id={ layerIdByRiskLevel.extreme }
          type="fill"
          source-layer="country_boundaries"
          source="r__countryTravelRiskLevelSource"
          paint={ {
            'fill-color': '#AE3B3B',
            'fill-opacity': 0.3
          } }
          beforeId="waterway-label"
          filter={ [
            "in",
            "iso_3166_1",
            ...riskLevelWiseCountries["5"]
          ] }
        />) }

        { /*Highlighted Country*/ }
        { highlightedCountryIso && (<DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.TravelCountriesView }
          id="countryRiskLevelLayer_highlighted"
          type="line"
          source-layer="country_boundaries"
          source="r__countryTravelRiskLevelSource"
          paint={ {
            'line-color': '#FFFFFF',
            'line-width': 1
          } }
          beforeId="waterway-label"
          filter={ [
            "in",
            "iso_3166_1",
            highlightedCountryIso
          ] }
        />) }
      </Source>
      <Source
        id="r__countryTravelDetailsView"
        type="vector"
        url="mapbox://mapbox.country-boundaries-v1"
      >
        <DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.TravelCountryDetailsView }
          id='outline'
          type="line"
          source-layer="country_boundaries"
          source="r__countryTravelRiskLevelSource"
          paint= { {
            'line-color': selectedCountry?.risk ? getCountryRiskColor(selectedCountry?.risk.level) : '#FFFFFF',
            'line-width': 4,
            'line-opacity': 0.5
          } }
          filter={ [
            "in",
            "iso_3166_1",
            selectedCountry ? selectedCountry?.countryISOCode : ''
          ] }
        />
      </Source>
      <Source
        id="r__countryTravellersSource"
        type="geojson"
        data={ travellersData }
      >
        <DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.TravelCountryDetailsView }
          id={ 'r__countryTravellersLayer' }
          type="symbol"
          paint={ {
            "text-color": '#0085FF'
          } }
          layout={ {
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'icon-image': [
              'match',
              ['get', 'status', [
                // Support not having loneWorker in topRank
                'coalesce',
                ['get', 'loneWorker',
                  // Support not having topRank in entity
                  ['coalesce',
                    ['get', 'topRank'],
                    ['literal', { loneWorker: { status: null } }]
                  ]
                ],
                ['literal', { status: null }]
              ]],
              PERSON_YELLOW_ALERT_STATUSES,
              'person-marker-caution-yellow',
              PERSON_RED_ALERT_STATUSES,
              'person-marker-caution-red',
              ['literal', 'person-marker']
            ],
            'icon-offset': [0, -7],
            'text-field': [
              'upcase',
              [
                'get',
                'personInitials'
              ]
            ],
            'text-size': 12,
            'text-font': ['Arial Unicode MS Bold'],
            'text-offset': [0, -1.2],
            'text-anchor': 'center'
          } }
          interactive
          source="r__countryTravellersSource"
        />
        { highlightedTravellerId && (<DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.TravelCountryDetailsView }
          id={ 'r__countryHighLightedTravellersLayer' }
          filter={ ['==', ['get', 'travellerId'], highlightedTravellerId] }
          type="symbol"
          layout={ {
            'icon-image': 'person-modified-bottom-highlight',
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-anchor': 'bottom' as const,
            'icon-offset': [0, 14]
          } }

          source="r__countryTravellersSource"
        />) }
      </Source>
      <Source
        id="r__countryTravelersCountSource"
        type="geojson"
        data={ TravelersCountData }
      >
        <DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.TravelCountriesView }
          id={ `r__countryTravelersCountSource-cluster` }
          type={ 'symbol' }
          paint={ {
            "text-color": `${theme.colors && theme.colors.royalBlue && theme.colors.royalBlue[0]}`
          } }
          layout={ {
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'icon-image': 'people-travel-cluster',
            'icon-size': 0.5,
            'text-font': ['Arial Unicode MS Bold'],
            'text-size': 12,
            'text-field': [
              'upcase',
              [
                'to-string',
                [
                  'get',
                  'travellerCount'
                ]
              ]
            ],
            'text-offset': [0, 0.5],
            'text-anchor': 'center'
          } }
          source="r__countryTravelersCountSource"
        />
      </Source>
    </>
  );
};

export default CountryTravelLayerComponent;
