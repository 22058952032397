import { FC, useCallback, useContext, useEffect, useState } from "react";

import { formatMonth } from "@/common/util/format/date";
import { PeopleContext } from "@/tenant-context/visualisation-people/context/People.context";
import { PersonPopupAssetRankingEvent } from "@/tenant-context/visualisation-people/types/people.types";

import { PeopleTravelPopupComponent } from "./PeopleTravelPopup.component";

type Props = {
    assetSummary: PersonPopupAssetRankingEvent,
    onClickZoomTo: () => void
    onClickMoreDetails: () => void
    onClickClose: () => void
}

export type UtcTimeObj = {
    from: {date: string, time: string},
    to: {date: string, time: string}
}

const PeopleTravelPopup: FC<Props> = ({ assetSummary, onClickZoomTo, onClickMoreDetails, onClickClose }) => {
  const {
    handleCurrentTravelInfo,
    personCurrentTravelInfo
  } = useContext(PeopleContext);

  const [utcTime, setUtcTime] = useState<UtcTimeObj>({ from: { date: '-', time: '-' }, to: { date: '-', time: '-' } });
  const [profilePictureThumbnailError, setProfilePictureThumbnailErrorr] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [currentLocation, setCurrentLocation] = useState<string>('-');

  useEffect(() => {
    if (assetSummary?.correlationId) {
      handleCurrentTravelInfo(assetSummary?.correlationId);
    }
  }, [assetSummary, handleCurrentTravelInfo]);

  useEffect(() => {
    if (personCurrentTravelInfo?.start_date && personCurrentTravelInfo?.end_date) {
      const fromDate = new Date(personCurrentTravelInfo?.start_date);
      const toDate = new Date(personCurrentTravelInfo?.end_date);
      setUtcTime({
        from: {
          date: `${fromDate.getUTCDate()} ${formatMonth(fromDate.getUTCMonth())} ${fromDate.getUTCFullYear()}`,
          time: `${fromDate.getUTCHours()}:${fromDate.getUTCMinutes().toString().padStart(2, '0')} UTC`
        },
        to: {
          date: `${toDate.getUTCDate()} ${formatMonth(toDate.getUTCMonth())} ${toDate.getUTCFullYear()}`,
          time: `${toDate.getUTCHours()}:${toDate.getUTCMinutes().toString().padStart(2, '0') } UTC`
        }
      });
      const currentUtcInMilis = Date.now();
      if (currentUtcInMilis > personCurrentTravelInfo?.start_date &&
        currentUtcInMilis < personCurrentTravelInfo?.end_date) {
        const progress = currentUtcInMilis - personCurrentTravelInfo?.start_date;
        const totaltrip = personCurrentTravelInfo?.end_date - personCurrentTravelInfo?.start_date;
        setProgressPercent(Math.trunc((progress / totaltrip) * 100));
      } else {
        setProgressPercent(0);
      }
      if (currentUtcInMilis > personCurrentTravelInfo?.end_date) {
        if (personCurrentTravelInfo.type === 'HOTEL') {
          setCurrentLocation(personCurrentTravelInfo?.origin_name || '-');
        } else {
          if (assetSummary.location.id === personCurrentTravelInfo.destination_iata_code) {
            setCurrentLocation(personCurrentTravelInfo?.destination_name || '-');
          } else if (assetSummary.location.id === personCurrentTravelInfo.origin_iata_code) {
            setCurrentLocation(personCurrentTravelInfo?.origin_name || '-');
          } else {
            setCurrentLocation(personCurrentTravelInfo?.destination_name || personCurrentTravelInfo?.origin_name);
          }
        }
      } else {
        if (assetSummary.location.id === personCurrentTravelInfo.destination_iata_code) {
          setCurrentLocation(personCurrentTravelInfo?.destination_name || '-');
        } else if (assetSummary.location.id === personCurrentTravelInfo.origin_iata_code) {
          setCurrentLocation(personCurrentTravelInfo?.origin_name || '-');
        } else {
          setCurrentLocation(personCurrentTravelInfo?.origin_name || personCurrentTravelInfo?.destination_name);
        }      
      }
    }
  }, [assetSummary.location.id, personCurrentTravelInfo]);

  const handleImageError = useCallback(() => {
    setProfilePictureThumbnailErrorr(true);
  }, []);

  return (
    <PeopleTravelPopupComponent
      utcTime={ utcTime }
      travelInfo={ personCurrentTravelInfo }
      assetSummary={ assetSummary } 
      progressPercent={ progressPercent }
      currentLocation={ currentLocation }
      onImageError={ handleImageError }
      isProfilePicturError={ profilePictureThumbnailError }
      onClickZoomTo={ onClickZoomTo }
      onClickMoreDetails={ onClickMoreDetails }
      onClickClose={ onClickClose }
    />
  );
};

export default PeopleTravelPopup;
