/* eslint-disable fp/no-let */
import EventSource from 'eventsource';

import { createUrl } from '@/common/util/api';
import { TENANT_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { showNotification } from "@/common/util/notification";
import { ENV, Environment } from "@/core/config/env";

import { logger } from './ConsoleLogger';

export type SubscriptionEventHandler<E> = (event: E) => void;
export type SSEOptions = {
  // Flag to identify if the stream should be created with auth token. Default is true
  isWithAuth?: boolean;
  // Function to validate if the stream should be retried or not. You can use this to check if the stream is receiving
  // any data or not by checking the event data or the isDataReceived flag
  retryValidation?: (event: MessageEvent | null, isDataReceived: boolean) => boolean;
  // Interval in which the stream should be retried. Default is 3000ms
  retryInterval?: number;
}

const isInDevMode = (ENV === Environment.Dev ||  ENV === Environment.Qa);

export default function createServerSentEventStream<E>(
  url: string,
  onEvent: SubscriptionEventHandler<E>,
  options?: SSEOptions
) {
  const isWithAuth = options?.isWithAuth ?? true;
  const tenantToken = browserDatabase.getItem(TENANT_TOKEN_KEY);

  // Flag to identify if any data was received from the stream other than HB
  let isDataReceived = false;
  let retryTimeout: NodeJS.Timer;
  let isOnRetry = false;

  const authHeader = isWithAuth ? { headers: { 'Authorization': `Bearer ${tenantToken}` } } : undefined;
  const stream = new EventSource(createUrl(url).toString(), authHeader);

  const retryHandler = (event: MessageEvent | null, isDataReceivedByStream: boolean) => {
    if (options?.retryValidation && options.retryValidation(event, isDataReceivedByStream)) {
      isOnRetry = true;
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
      retryTimeout = setInterval(() => {
        stream.close();
        clearInterval(retryTimeout);
        createServerSentEventStream(url, onEvent, options);
      }, options.retryInterval || 3000);
    } else {
      // eslint-disable-next-line unused-imports/no-unused-vars
      isOnRetry = false;
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    }
  };

  // Handle the events received from the stream. This is the main data handler
  // Retry connection if it was closed or no response other than 'HB' was received
  stream.onmessage = (ev) => {
    try {
      const event = JSON.parse(ev.data);
      const isHeartbeatEvent = event._type === 'HB';

      if (!isDataReceived && !isHeartbeatEvent) {
        isDataReceived = true;
      }

      if (!isOnRetry) {
        retryHandler(ev, isDataReceived ? true : isHeartbeatEvent);
      }

      if (isHeartbeatEvent) {
        return;
      }
      onEvent(event);
    } catch(err){
      logger.error('SSE Error: ', err);
    }

  };

  stream.onerror = (err) => {
    logger.error('SSE Error: ', err);
    retryHandler(null, isDataReceived);

    showNotification({
      message: isInDevMode ? 'SSE Stream error. Check console for details' : 'Stream connection issue. Please refresh the page or contact support.',
      color: 'error'
    });
  };

  return stream;
}
