import { CSSObject } from "@mantine/core";
import { ReactNode, ReactPortal, useState } from "react";
import { createPortal } from "react-dom";
import { ControlPosition, useControl } from "react-map-gl";

import { Container } from './Container';

import './ControlContainer.style.scss';

type Props = {
  containerId: string,
  children: ReactNode,
  width?: string,
  height?: string,
  position?: ControlPosition,
  layout?: 'horizontal' | 'vertical',
  className?: string,
  styles?: CSSObject,
  applyDefaultControlStyles?: boolean
}

const ControlContainer = ({
  containerId,
  children,
  width = 'auto',
  height = 'auto',
  position = 'top-left',
  layout = 'horizontal',
  className,
  styles,
  applyDefaultControlStyles = true
}: Props): ReactPortal | null => {
  const [, refresh] = useState<number>(0);
  const controlContainerEl = document.getElementById(containerId);

  useControl(
    (_) => {
      return new Container(
        containerId,
        width,
        height,
        layout,
        ` ${ className }`,
        styles,
        applyDefaultControlStyles
      );
    },
    (_) => {
      document.getElementById(containerId)?.remove();
    },
    {
      position: position
    }
  );

  if (controlContainerEl) {
    // If control container already exists - render
    return createPortal(
      children,
      controlContainerEl
    );
  } else {
    // Otherwise - wait for it to exist and retry
    setTimeout(() => {
      refresh((x) => x + 1);
    }, 0);
  }

  return null;
};


export default ControlContainer;