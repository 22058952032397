import { createStyles } from "@mantine/core";

type Props = {
  isDarkTheme?: boolean
}

export const useCountBoxStyles = createStyles((theme, props: Props) => ({

  container: {
    display: 'grid',
    gridTemplateColumns: '6fr 1fr',
    borderTop: `1px solid ${theme.colors.neutral[6]}`,
    borderLeft: `1px solid ${theme.colors.neutral[6]}`,
    background: props?.isDarkTheme ? theme.colors.neutral[9] : 'inherit',
    height: '43px',
    fontSize: theme.fontSizes.sm
  },

  pobDisplayContainer: {
    display: 'grid',
    gridTemplateColumns: '3fr 1.5fr 1.5fr',
    borderTop: `1px solid ${theme.colors.neutral[6]}`,
    borderLeft: `1px solid ${theme.colors.neutral[6]}`,
    background: props?.isDarkTheme ? theme.colors.neutral[9] : 'inherit',
    height: '43px',
    fontSize: theme.fontSizes.sm
  },

  pobEmptyContainer: {
    display: 'grid',
    gridTemplateColumns: '3fr 3fr',
    background: props?.isDarkTheme ? theme.colors.neutral[9] : 'inherit',
    height: '43px',
    fontSize: theme.fontSizes.sm
  },

  item: {
    borderBottom: `1px solid ${theme.colors.neutral[6]}`,
    borderRight: `1px solid ${theme.colors.neutral[6]}`
  },

  pobItem: {
    borderBottom: `1px solid ${theme.colors.neutral[6]}`,
    borderRight: `1px solid ${theme.colors.neutral[6]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold
  },
  pobMergedItem: {
    border: `1px solid ${theme.colors.neutral[6]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold
  },
  pobItemDetail: {
    borderBottom: `1px solid ${theme.colors.neutral[6]}`,
    borderRight: `1px solid ${theme.colors.neutral[6]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.fontSizes.xs,
    div: {
      textAlign: 'center'
    }
  },
  pobItemTitle: {
    borderBottom: `1px solid ${theme.colors.neutral[6]}`,
    borderRight: `1px solid ${theme.colors.neutral[6]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '15px',
    fontSize: theme.fontSizes.xs
  },

  title:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    marginLeft: '16px'
  },

  icon:{
    marginRight: '8px',
    path: {
      fill: theme.colors.neutral[6]
    }
  },

  count: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    marginRight: '16px',
    lineHeight: theme.other.lh.s,
    color: theme.white,
    fontWeight: theme.other.fw.bold
  },

  totalCount: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    marginRight: '16px',
    lineHeight: theme.other.lh.s
  },

  clickableCount: {
    color: theme.colors.link[0],
    fontWeight: theme.other.fw.bold,
    cursor: 'pointer'
  },

  pobDetailsWrapper: {
    marginTop: '20px'
  },
  modalPagination : {
    '.restrata-Pagination-item': {
      width: '45px'
    }
  }

}));
