import { createStyles } from "@mantine/core";

import { CSS_KEY } from '@/core/styles/mantine/constants';

type Params = {
  index: number;
  isCollapsed: boolean;
  measuredHeight: number | undefined;
  isClosing: boolean;
}

export const useToastNotificationStyles = createStyles((theme, params: Params) => ({
  notificationRoot: {
    position: 'relative',
    zIndex: params.index === 0 ? 99999 : undefined,
    opacity: 1,
    paddingBottom: theme.spacing.xs,
    height: params.measuredHeight ? params.measuredHeight + theme.spacing.xs : 'auto',
    transition: 'transform 0.3s ease-in-out, opacity 0.2s ease-in-out, height 0.3s ease-in-out',
    ...(params.isClosing && {
      overflow: 'hidden',
      height: '0 !important',
      padding: 0
    }),
    // on hover styles for close button
    [`&:hover .${CSS_KEY}-ActionIcon-root`]: {
      opacity: 1
    }
  },
  notificationHidden: {
    opacity: 0,
    height: 0,
    pointerEvents: 'none'
  },
  notificationClosing: {
    opacity: 0,
    height: 0,
    padding: 0,
    overflow: 'hidden'
  },
  closeButton: {
    backgroundColor: 'transparent !important',
    position: 'absolute',
    left: -24,
    top: -24,
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
    '&:hover': {
      opacity: 1
    }
  },
  closeAllButton: {
    zIndex: 99999,
    'span': {
      display: 'none'
    },
    '&:hover': {
      width: 'auto',
      backgroundColor: `${theme.colors.neutral[9]} !important`,
      borderRadius: theme.radius.xl,
      border: `1px solid ${ theme.colors.neutral[1] }`,
      minHeight: '20px !important',
      height: 20,
      top: -10,
      left: -10,
      padding: 2,
      'span': {
        display: 'inline'
      }
    }
  },
  closeAllButtonText: {
    marginLeft: theme.spacing.xs,
    marginRight: theme.spacing.xs
  },
  notificationContainer: {
    boxShadow: '0px 4px 5px 0px #000000BF'
  },
  dummyNotificationContainer: {
    position: 'relative',
    zIndex: -1,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'auto'
    }
  },
  dummyNotification: {
    position: 'absolute',
    backgroundColor: theme.colors.neutral[9],
    boxShadow: '0px 4px 5px 0px #000000BF',
    transition: 'transform 0.3s ease-in-out, opacity 0.2s ease-in-out'
  },
  dummyNotificationOne: {
    zIndex: 99998,
    width: 317,
    height: 15,
    ...(!params.isCollapsed && {
      transform: 'translateY(100%)',
      opacity: 0
    })
  },
  dummyNotificationTwo: {
    zIndex: 99997,
    width: 297,
    height: 30,
    ...(!params.isCollapsed && {
      transform: 'translateY(100%)',
      opacity: 0
    })
  }
}));
