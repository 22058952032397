import { MantineTheme } from "@mantine/core";
import { TickFormatter } from "@visx/axis";
import { Scale } from "@visx/brush/lib/types";
import { ScaleInput } from "@visx/scale";
import { timeFormat } from "d3-time-format";


export const tickLabelProps = (theme: MantineTheme) => ({
  fill: "white",
  fontSize: 12,
  fontFamily: theme.fontFamily,
  textAnchor: "middle",
  y: 20
} as const);

export const tickFormatterDate: TickFormatter<ScaleInput<Scale>> = (d: Date | number) => {
  const date = new Date(d);
  return timeFormat("%d %b")(date);
};

export const maxRiskTimelineGraphAlerts = 7;

export const riskTimelineGraphMargin = {
  top: 50,
  bottom: 100,
  right: 25,
  left: 25
};
