import {
  Button,
  Checkbox,
  Flex,
  HoverCard,
  Stack,
  Text,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { FC, useCallback, useState } from "react";

import { ReactComponent as CircleInfoIcon } from "@/common/icons/circle-info-light-1.svg";
import {
  ContextualLayersOptions,
  ContextualLayerSwitchType
} from "@/core/store/data-options/data-options.state";
import { CustomColors } from "@/core/styles/mantine/palettes/types";
import RisklineLegendComponent from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/RisklineLegend.component";
import { useButtonAsLinkStyles } from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/RiskProviderTreeContent.styles";
import { isWeatherMapEnabled } from "@/tenant-context/visualisation-weather/components/AerisInteractiveMap.component";

type Props = {
  contextualLayersOptions: ContextualLayersOptions;
  onChangeContextualLayerSwitch: React.ChangeEventHandler<HTMLInputElement>;
  isRiskConnectorsPermissionAvailable?: boolean;
  isContextualLayersSubscribed: boolean;
  isTimeTravelActive?: boolean;
};

const ContextualLayers: FC<Props> = ({
  contextualLayersOptions,
  onChangeContextualLayerSwitch,
  isRiskConnectorsPermissionAvailable = false,
  isContextualLayersSubscribed = false,
  isTimeTravelActive = false
}) => {
  const theme = useMantineTheme();
  const { classes: buttonAsLinkClasses } = useButtonAsLinkStyles();
  const [risklineLegendVisible, setRisklineLegendVisible] = useState(true);
  const toggleRisklineLegend = useCallback(() => {
    setRisklineLegendVisible((prev) => !prev);
  }, []);

  const xWeatherInfo = (
    <HoverCard shadow="md">
      <HoverCard.Target>
        <CircleInfoIcon style={ { color: theme.colors.neutral[6] } } />
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text size="sm">
          Provided by{ " " }
          <a href="https://www.xweather.com/" target="_blank" rel="noreferrer">
            Xweather
          </a>
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );

  return (
    <Stack spacing="md" mt={ 24 } ml={ 32 }>
      { isTimeTravelActive && (
        <Text size="md" c={ theme.colors.neutral[5] as CustomColors }>
          Cannot access when not in live mode
        </Text>
      ) }
      <Flex gap={ 8 }>
        <Checkbox
          name={ ContextualLayerSwitchType.CountryRiskLevel }
          onChange={ onChangeContextualLayerSwitch }
          checked={ contextualLayersOptions.CountryRiskLevel }
          label={ <Tooltip
            hidden={ isRiskConnectorsPermissionAvailable }
            withinPortal
            label={ "You don't have permission to view Country Risk Levels" }
          >
            <span>Riskline Country Risk Level</span>
          </Tooltip> }
          disabled={ !isRiskConnectorsPermissionAvailable || isTimeTravelActive }
        />
        <HoverCard shadow="md">
          <HoverCard.Target>
            <CircleInfoIcon style={ { color: theme.colors.neutral[6] } } />
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text size="sm">
              Provided by{ " " }
              <a href="https://riskline.com/" target="_blank" rel="noreferrer">
                Riskline
              </a>
            </Text>
          </HoverCard.Dropdown>
        </HoverCard>
      </Flex>
      { contextualLayersOptions.CountryRiskLevel && (
        <Stack ml={ 36 }>
          { risklineLegendVisible && <RisklineLegendComponent /> }
          <Button
            variant="subtle"
            compact
            classNames={ buttonAsLinkClasses }
            onClick={ toggleRisklineLegend }
          >
            { risklineLegendVisible ? "Hide" : "Show" } legend
          </Button>
        </Stack>
      ) }
      <Flex gap={ 8 }>
        <Checkbox
          checked={ contextualLayersOptions.Traffic }
          name={ ContextualLayerSwitchType.Traffic }
          onChange={ onChangeContextualLayerSwitch }
          label={ <Tooltip
            hidden={ isContextualLayersSubscribed }
            withinPortal
            label={ "Access this data by subscribing to Contextual Map Layers to unlock advanced insights." }
          >
            <span>Traffic</span>
          </Tooltip> }
          disabled={ isTimeTravelActive || !isContextualLayersSubscribed }
        />
        <HoverCard shadow="md">
          <HoverCard.Target>
            <CircleInfoIcon style={ { color: theme.colors.neutral[6] } } />
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text size="sm">
              Provided by{ " " }
              <a
                href="https://www.mapbox.com/"
                target="_blank"
                rel="noreferrer"
              >
                Mapbox
              </a>
            </Text>
          </HoverCard.Dropdown>
        </HoverCard>
      </Flex>
      { isWeatherMapEnabled && (
        <>
          <Flex gap={ 8 }>
            <Checkbox
              label={ <Tooltip
                hidden={ isContextualLayersSubscribed }
                withinPortal
                label={ "Access this data by subscribing to Contextual Map Layers to unlock advanced insights." }
              >
                <span>Radar (Precipitation)</span>
              </Tooltip> }
              name={ ContextualLayerSwitchType.Radar }
              disabled={ isTimeTravelActive || !isContextualLayersSubscribed }
              onChange={ onChangeContextualLayerSwitch }
              checked={ contextualLayersOptions.Radar }
            />
            { xWeatherInfo }
          </Flex>
          <Flex gap={ 8 }>
            <Checkbox
              label={ <Tooltip
                hidden={ isContextualLayersSubscribed }
                withinPortal
                label={ "Access this data by subscribing to Contextual Map Layers to unlock advanced insights." }
              >
                <span>Wind (Direction)</span>
              </Tooltip> }
              name={ ContextualLayerSwitchType.Wind }
              disabled={ isTimeTravelActive || !isContextualLayersSubscribed }
              onChange={ onChangeContextualLayerSwitch }
              checked={ contextualLayersOptions.Wind }
            />
            { xWeatherInfo }
          </Flex>
          <Flex gap={ 8 }>
            <Checkbox
              label={ <Tooltip
                hidden={ isContextualLayersSubscribed }
                withinPortal
                label={ "Access this data by subscribing to Contextual Map Layers to unlock advanced insights." }
              >
                <span>Tropical Cyclones</span>
              </Tooltip> }
              name={ ContextualLayerSwitchType.Cyclones }
              disabled={ isTimeTravelActive || !isContextualLayersSubscribed }
              onChange={ onChangeContextualLayerSwitch }
              checked={ contextualLayersOptions.Cyclones }
            />
            { xWeatherInfo }
          </Flex>
        </>
      ) }
    </Stack>
  );
};

export default ContextualLayers;
