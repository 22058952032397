import { FC } from "react";
import { Marker, Popup, Source } from "react-map-gl";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import {
  useGeofencePOILocationLayerStyles
} from "@/tenant-context/visualisation-site/layers/GeofencePOILocationLayer/GeofencePOILocationLayer.styles";

const GeofencePOILocationLayer: FC = () => {
  const activeLocation = useSelector((state: RootState) => state.mapSearch?.activeLocation);
  const latitude = activeLocation?.features[0].properties?.coordinates.latitude;
  const longitude = activeLocation?.features[0].properties?.coordinates.longitude;
  const siteName = activeLocation?.features[0].properties?.name;
  const location = activeLocation?.features[0].properties?.full_address;
  const offsetX = location ? 30 : 5;
  const { classes } = useGeofencePOILocationLayerStyles();

  return (
    <Source
      id="r__sitesGeofencePOISource_plain"
      type="geojson"
      tolerance={ 0 }
      data={ activeLocation }
    >
      { latitude && longitude &&
        <>
          <Marker
            longitude={ longitude }
            latitude={ latitude }
          />
          <Popup
            className={ classes.popup }
            longitude={ longitude }
            latitude={ latitude }
            anchor="bottom-left"
            closeButton={ false }
            closeOnClick={ false }
            offset={ [15, offsetX] }
          >
            <div className={ classes.popupContentWrapper }>
              <div className={ classes.siteName }>{ siteName }</div>
              <div className={ classes.location }>{ location }</div>
            </div>
          </Popup>
        </> }
    </Source>
  );
};

export default GeofencePOILocationLayer;
