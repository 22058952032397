import { FC, useState } from "react";

import { TestableComponent } from "@/common/types/testable-component";
import { BulkProfileListCreate } from "@/tenant-context/control-groups/types/group";
import { ProfilePeopleGroupsModalContextProvider } from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/context/ProfilePeopleGroupsModal.context";
import ProfileGroupsModal, { GroupType } from "@/tenant-context/control-profile/components/modals/ProfileGroupsModal/ProfileGroupsModal.component";

type Props = {
  isBulk?: boolean,
  bulkData?: BulkProfileListCreate
} & TestableComponent;
const ProfileGroupsModalContainer: FC<Props> = ({ isBulk, bulkData, dataTestId }) => {
  const [groupType, setGroupType] = useState<string>(GroupType.Existing);

  return (
    <ProfilePeopleGroupsModalContextProvider bulkData={ bulkData } isBulk={ isBulk }>
      <ProfileGroupsModal
        groupType={ groupType }
        setGroupType={ setGroupType }
        dataTestId={ dataTestId }
      />
    </ProfilePeopleGroupsModalContextProvider>
  );
};

export default ProfileGroupsModalContainer;
