import { MantineTheme } from '@mantine/styles/lib/theme/types/MantineTheme';

import { ReactComponent as BarsVertical } from '@/common/icons/bars-vertical.svg';

import { CSS_KEY } from '../constants';

export const SliderComponentStyle = {
  defaultProps: {
    thumbSize: 24,
    thumbChildren: <BarsVertical />
  },
  styles: (theme: MantineTheme) => ({
    track: {
      height: 4,
      marginLeft: 0,
      marginRight: 0,
      '&::before': {
        borderRadius: 0,
        left: 0,
        right: 0
      }
    },
    thumb: {
      backgroundColor: theme.colors.blue[6],
      border: 'unset',
      padding: 6
    },
    bar: {
      backgroundColor: theme.colors.blue[6],
      // backgroundColor: 'transparent',
      transform: 'translateX(8px)'
    },
    markWrapper: {
      [`&:first-of-type .${CSS_KEY}-Slider-mark, &:last-of-type .${CSS_KEY}-Slider-mark`]:
        {
          height: '12px',
          width: '2px',
          marginTop: '-4px'
        },
      [`&:first-of-type .${CSS_KEY}-Slider-markLabel`]: {
        transform: 'unset'
      },
      [`&:last-of-type .${CSS_KEY}-Slider-markLabel`]: {
        transform: 'translate(calc(-100% + 2px), 0)'
      }
    },
    mark: {
      border: 'none',
      height: '3px',
      width: '1px',
      backgroundColor: theme.colors.neutral[5],
      marginTop: '4px',
      transform: 'translateX(0px)'
    },
    markLabel: {
      marginTop: '5px',
      fontSize: theme.fontSizes.xs
    },
    label: {
      fontSize: theme.fontSizes.sm,
      color: theme.white,
      backgroundColor: theme.colors.neutral[6]
    }
  })
};
