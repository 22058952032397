import { Dispatch } from "@/core/store";

export const drawerEffects = (dispatch: Dispatch) => ({
  openRightDrawer(drawerId: string): void {
    dispatch.drawer.SET_CURRENTLY_OPEN_RIGHT_DRAWER(drawerId);
  },

  openBottomDrawer(drawerId: string): void {
    dispatch.drawer.SET_CURRENTLY_OPEN_BOTTOM_DRAWER(drawerId);
  },

  openLeftDrawer(drawerId: string): void {
    dispatch.drawer.SET_CURRENTLY_OPEN_LEFT_DRAWER(drawerId);
  },

  closeRightDrawer(): void {
    dispatch.drawer.CLOSE_CURRENTLY_OPEN_RIGHT_DRAWER();
  },

  closeBottomDrawer(): void {
    dispatch.drawer.CLOSE_CURRENTLY_OPEN_BOTTOM_DRAWER();
  },

  closeLeftDrawer(): void {
    dispatch.drawer.CLOSE_CURRENTLY_OPEN_LEFT_DRAWER();
  },
  
  closeDrawer(): void {
    dispatch.drawer.CLOSE_ALL_DRAWERS();
  }
});
