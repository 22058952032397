import { Button } from "@mantine/core";
import { openModal } from "@mantine/modals";
import { FC, ReactNode, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import usePermission from "@/common/hooks/usePermission";
import useUnauthorizedModal from "@/common/hooks/useUnauthorizedModal";
import { ReactComponent as EyeIcon } from '@/common/icons/eye.svg';
import { ReactComponent as TrashIcon } from '@/common/icons/trash.svg';
import { DataGrid } from "@/common/types/ag-grid";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import ProfileDocumentsModal from "@/tenant-context/control-profile/components/modals/ProfileDocumentsModal";
import ProfileDocumentsTab from "@/tenant-context/control-profile/components/tabs/ProfileDocumentsTab/ProfileDocumentsTab.component";
import {
  columnDefs
} from "@/tenant-context/control-profile/components/tabs/ProfileDocumentsTab/ProfileDocumentsTab.config";
import {
  useProfileDocumentsTabStyles
} from "@/tenant-context/control-profile/components/tabs/ProfileDocumentsTab/ProfileDocumentsTab.style";
import { ProfileRequiredPoliciesConfig } from "@/tenant-context/control-profile/config/ProfileRequiredPolicies.config";
import { ReferenceType } from "@/tenant-context/control-profile/types/profile";

const ProfileDocumentsTabContainer: FC = () => {
  const {
    profile: {
      loadDocumentsGrid,
      deleteDocument,
      getProfileReferenceData,
      isLoggedInProfileId,
      SET_IS_PEOPLE_DOCUMENTS_UPDATED
    }
  } = useDispatch<Dispatch>();

  const params = useParams();
  const { classes } = useProfileDocumentsTabStyles();

  const isPeopleDocumentsUpdated = useSelector((state: RootState) => state.profile?.isPeopleDocumentsUpdated);
  const isAuthorizedToDelete = usePermission(ProfileRequiredPoliciesConfig.EDIT_DOCUMENTS);
  const handleUnauthorizedAction = useUnauthorizedModal();
  const ref = useRef<DataGrid>(null);
  const handleDeleteDocumentClick = useCallback((confirm = false) => async (docId: string) =>{
    if(!confirm){
      openConfirmationModal({
        text: "Are you sure you want to remove this document?",
        onConfirm: async () => await handleDeleteDocumentClick(true)(docId),
        title: "Confirm Removal"
      });
    } else{
      const result = await deleteDocument(docId).then(() => ref.current?.refreshGrid());
      if(result){
        showNotification({
          message: 'Record removed successfully',
          color: 'success',
          title: "Success"
        });
      }
    }
  }, [deleteDocument]);

  useEffect(() => {
    getProfileReferenceData(ReferenceType.DOCUMENT_TYPE);
  }, [getProfileReferenceData]);

  const handleAddClick = useCallback(() => {
    openModal({
      title: `Add Document`,
      size: 688,
      children: (
        <ProfileDocumentsModal
          mode="add"
          currentUserId={ params.userId }
        />
      ),
      closeOnClickOutside: false
    });
  }, [params.userId]);

  const handleEditClick = useCallback((documentId: string) => {
    openModal({
      title: `Edit Document`,
      size: 688,
      children: (
        <ProfileDocumentsModal
          mode="edit"
          editableDocumentId={ documentId }
          currentUserId={ params.userId }
        />
      ),
      closeOnClickOutside: false
    });
  }, [params.userId]);

  const handleOwnProfileCheck = useCallback((): boolean => {
    return isLoggedInProfileId(params.userId);
  }, [isLoggedInProfileId, params.userId]);

  const actionCellRenderer = useCallback((documentId): ReactNode => {
    return (
      <div>
        <Button
          // eslint-disable-next-line react/jsx-no-bind
          onClick={ (handleOwnProfileCheck() || isAuthorizedToDelete) ?
            () => handleDeleteDocumentClick(false)(documentId)
            :
            handleUnauthorizedAction }
          className={ (handleOwnProfileCheck() || isAuthorizedToDelete) ? classes.btn : classes.unauthorizedDeleteBtn }
        >
          <TrashIcon className={ classes.trashIcon }/>
        </Button>
        <Button
        // eslint-disable-next-line react/jsx-no-bind
          onClick={ () => handleEditClick(documentId) }
          className={ classes.btn }
        >
          <EyeIcon  />
        </Button>
      </div>
    );
  },[
    classes.btn,
    classes.trashIcon,
    classes.unauthorizedDeleteBtn,
    handleDeleteDocumentClick,
    handleEditClick,
    handleOwnProfileCheck,
    handleUnauthorizedAction,
    isAuthorizedToDelete
  ]);

  useEffect(() => {
    if(isPeopleDocumentsUpdated) {
      ref.current?.refreshGrid();
      SET_IS_PEOPLE_DOCUMENTS_UPDATED(false);
    }
  }, [SET_IS_PEOPLE_DOCUMENTS_UPDATED, isPeopleDocumentsUpdated]);


  return (
    <ProfileDocumentsTab
      onAddClick={ handleAddClick }
      isOwnProfile={ handleOwnProfileCheck }
      getData={ loadDocumentsGrid }
      columnDefs={ columnDefs(actionCellRenderer) }
      ref={ ref }
    />
  );
};

export default ProfileDocumentsTabContainer;
