import { FC, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { formatDMY } from "@/common/util/format/date";
import { Dispatch, RootState, RootStateWithLoading } from "@/core/store";
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock/ProfileBlock.component";
import ProfileKeyValue from "@/tenant-context/control-profile/components/ui/ProfileKeyValue/ProfileKeyValue.component";
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock/ProfileKeyValueBlock.component";
import { ProfileGeneralSection } from "@/tenant-context/control-profile/types/profile";

type Props = {
  createAction?: (section: ProfileGeneralSection) => () => ReactNode;
};

const ProfilePersonalDetails: FC<Props> = ({
  createAction
}) => {
  const {
    profile: {
      loadGeneral
    }
  } = useDispatch<Dispatch>();

  const general = useSelector(
    ({ profile: { general: g } }: RootState) => g
  );

  const isGeneralLoading = useSelector(
    ({ loading: { effects: { profile: { loadGeneral: l } } } }: RootStateWithLoading) => l
  );

  useEffect(() => {
    if (typeof general === 'undefined' && !isGeneralLoading) {
      loadGeneral();
    }
  }, [general, isGeneralLoading, loadGeneral]);

  const formattedDateOfBirth = (() => {
    if (!general?.dateOfBirth) {
      return '';
    }

    try {
      const [year, month, day] = general.dateOfBirth.split('-');
      const date = new Date(+year, +month - 1, +day);

      return formatDMY(date);
    } catch (err) {
      return general?.dateOfBirth;
    }
  })();

  return (
    <ProfileBlock
      heading="Personal Details"
      renderAction={ createAction?.(ProfileGeneralSection.Personal) }
    >
      <ProfileKeyValueBlock
        gtc={ '1fr 2fr' }

        maxColumns={ 2 }
      >
        <ProfileKeyValue firstItem="label" name={ 'Title' } value={ general?.title } />
        <ProfileKeyValue firstItem="label" name={ 'First Name' } value={ general?.firstName } />
        <ProfileKeyValue firstItem="label" name={ 'Middle Names' } value={ general?.middleNames } />
        <ProfileKeyValue firstItem="label" name={ 'Initials' } value={ general?.initials } />
        <ProfileKeyValue firstItem="label" name={ 'Last Name' } value={ general?.lastName } />
        <ProfileKeyValue firstItem="label" name={ 'Alternate Name' } value={ general?.alternateName } />
        <ProfileKeyValue firstItem="label" name={ 'Date of Birth' } value={ formattedDateOfBirth } />
        <ProfileKeyValue firstItem="label" name={ 'Gender' } value={ general?.gender } />
        <ProfileKeyValue firstItem="label" name={ 'Nationality' } value={ general?.nationality } />
        <ProfileKeyValue firstItem="label" name={ 'Country of Residence' } value={ general?.countryOfResidenceRef } />
      </ProfileKeyValueBlock>
    </ProfileBlock>
  );
};

export default ProfilePersonalDetails;