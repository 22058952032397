/* eslint-disable no-magic-numbers */
export function formatWeekday(day: number): string {
  const dayMap = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun'
  ];

  // Day comes as 1 (Monday), 2(Tuesday). Day - 1 converts it to the correct day. Please note that 0 is Sunday.
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getUTCDay
  return day === 0 ? 'Sun' : dayMap[day - 1];
}

export function formatMonth(month: number, concise = true, shortForm = false): string {
  const monthMap = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const shortMonthMap = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const monthWord = shortForm ? shortMonthMap[month] : monthMap[month];

  if (concise) {
    return monthWord.slice(0, 3);
  }

  return monthWord;
}

const DOUBLE_DIGIT_NUMBER = 10;
const NO_OF_MILISECONDS_IN_SECOND = 1000;
const SECONDS_IN_YEAR = 31536000;
const SECONDS_IN_MONTHS = 2592000;
const SECONDS_IN_DAY = 86400;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MIN = 60;

export function formatDate(date: Date, isTimeStampRequired = true): string {
  if (Number(date) === 0) {
    return '';
  }
  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();

  if (!isTimeStampRequired) {
    return `${day} ${formatMonth(month)} ${year}`;
  }

  const h = date.getUTCHours();
  const min = date.getUTCMinutes();
  const m = min < DOUBLE_DIGIT_NUMBER ? `0${min}` : min;
  const weekday = date.getUTCDay();

  return (
    `UTC ${h}:${m} - ${formatWeekday(weekday)}, ${day} ${formatMonth(month)} ${year}`
  );
}

const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) {
    return 'th';
  }

  switch (day % 10) {
  case 1:
    return 'st';
  case 2:
    return 'nd';
  case 3:
    return 'rd';

  default:
    return 'th';
  }
};

export function formatDMY(date: Date, options?: {
  conciseMonth?: boolean,
  shortMonth?: boolean,
  utc?: boolean
}): string {
  const day = options?.utc ? date.getUTCDate() : date.getDate();
  const month = options?.utc ? date.getUTCMonth() : date.getMonth();
  const year = options?.utc ? date.getUTCFullYear() : date.getFullYear();

  return `${day}${getOrdinalSuffix(day)} ${formatMonth(month, options?.conciseMonth, options?.shortMonth)} ${year}${options?.utc ? ' UTC' : ''}`;
}

export function formatUTCDMY(date: Date, options?: {
  conciseMonth: boolean,
  shortMonth: boolean
}): string {
  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();

  return `${day}${getOrdinalSuffix(day)} ${formatMonth(month, options?.conciseMonth, options?.shortMonth)} ${year}`;
}

export function formatTime(date: Date, options?: {
  withSeconds: boolean,
  withPad: boolean
}): string {
  const h = date.getUTCHours().toString().padStart(options?.withPad ? 2 : 0, '0');
  const min = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(options?.withPad ? 2 : 0, '0');
  const m = min < DOUBLE_DIGIT_NUMBER ? `0${min}` : min.toString().padStart(options?.withPad ? 2 : 0, '0');

  return `${h}:${m}${options?.withSeconds ? `:${seconds}` : ''} UTC`;
}

export function getTimeDurationFromCurrentDate(date: Date) {

  const inFuture = date.valueOf() > new Date().valueOf();

  const seconds = inFuture ?
    Math.floor((date.valueOf() - new Date().valueOf()) / NO_OF_MILISECONDS_IN_SECOND) :
    Math.floor((new Date().valueOf() - date.valueOf()) / NO_OF_MILISECONDS_IN_SECOND);

  // This is not written in FP style, it's fine.
  // eslint-disable-next-line fp/no-let
  let interval = seconds / SECONDS_IN_YEAR;
  // eslint-disable-next-line fp/no-let
  let periodTime = 0;
  if (interval > 1) {
    periodTime = Math.floor(interval);
    return periodTime + " YEAR" + (periodTime > 1 ? "S" : "");
  }
  interval = seconds / SECONDS_IN_MONTHS;
  if (interval > 1) {
    periodTime = Math.floor(interval);
    return periodTime + " MONTH" + (periodTime > 1 ? "S" : "");
  }
  interval = seconds / SECONDS_IN_DAY;
  if (interval > 1) {
    periodTime = Math.floor(interval);
    return periodTime + " DAY" + (periodTime > 1 ? "S" : "");
  }
  interval = seconds / SECONDS_IN_HOUR;
  if (interval > 1) {
    periodTime = Math.floor(interval);
    return periodTime + " HOUR" + (periodTime > 1 ? "S" : "");
  }
  interval = seconds / SECONDS_IN_MIN;
  if (interval > 1) {
    periodTime = Math.floor(interval);
    return periodTime + " MIN" + (periodTime > 1 ? "S" : "");
  }

  periodTime = Math.floor(seconds);
  return periodTime + " SECOND" + (periodTime > 1 ? "S" : "");
}

export type TimeDifferenceEntry = {
  label: string;
  value: number;
}

export type TimeDifference = {
  days: TimeDifferenceEntry;
  hours: TimeDifferenceEntry;
  minutes: TimeDifferenceEntry;
};

export function getFormattedTimeDifference(date1: number, date2: number): TimeDifference {
  const difference = Math.abs(date1 - date2);
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / 1000 / 60) % 60);

  return {
    days: {
      label: 'DAY' + (days > 1 ? 'S' : ''),
      value: days
    },
    hours: {
      label: 'HR' + (hours > 1 ? 'S' : ''),
      value: hours
    },
    minutes: {
      label: 'MIN' + (minutes > 1 ? 'S' : ''),
      value: minutes
    }
  };
}

export function calculateAge(timestamp: number) {
  const now = Date.now();
  const timeDifference = now - timestamp;

  // Define the time units in milliseconds
  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;

  if (timeDifference < minute) {
    const seconds = Math.floor(timeDifference / 1000);
    return `${seconds} second${seconds === 1 ? '' : 's'}`;
  } else if (timeDifference < hour) {
    const minutes = Math.floor(timeDifference / minute);
    return `${minutes} minute${minutes === 1 ? '' : 's'}`;
  } else if (timeDifference < day) {
    const hours = Math.floor(timeDifference / hour);
    return `${hours} hour${hours === 1 ? '' : 's'}`;
  } else {
    const days = Math.floor(timeDifference / day);
    return `${days} day${days === 1 ? '' : 's'}`;
  }
}

// format Date to string in YYYY-MM-DD format
export const formatDateToString = (date: Date): string => {
  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

  return yyyy + '-' + mm + '-' + dd;
};
