import { Point } from "geojson";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import useLayerListener from "@/common/hooks/useLayerListener";
import usePermission from "@/common/hooks/usePermission";
import { CorePolicies } from "@/core/config/CorePolicies.config";
import { Dispatch, RootState } from "@/core/store";
import useMapPopup from "@/tenant-context/common/hooks/useMapPopup";
import { getClickedOnFeatures } from "@/tenant-context/common/util/map-click";
import {
  CustomerLocationLayerConfig
} from "@/tenant-context/visualisation-customer-locations/configs/CustomerLocationLayer.config";
import useLocationsMarkerIcons from "@/tenant-context/visualisation-customer-locations/hooks/useLocationsMarkerIcons";
import CustomerLocationLayerComponent
  from "@/tenant-context/visualisation-customer-locations/layers/CustomerLocationLayer/CustomerLocationLayer.component";
import {
  CustomerLocationLayerType,
  CustomerLocationPopupData
} from "@/tenant-context/visualisation-customer-locations/types/customer-location.types";

export const CustomerLocationLayer: FC = () => {
  const [popupContent, setPopupContent] = useState<CustomerLocationPopupData>();
  const geoData = useSelector((state: RootState) => state.customerLocations.geoData);
  const currentBigMapPopup = useSelector((state: RootState) => state.bigMap.currentBigMapPopup);
  const {
    customerLocations: {
      loadAllCustomerLocations
    }
  } = useDispatch<Dispatch>();

  const {
    setIsPopupShown,
    popupCoordinates,
    setPopupCoordinates,
    popupRef
  } = useMapPopup("MapLocation");

  const layers: Array<CustomerLocationLayerType> = useMemo(() => CustomerLocationLayerConfig, []);
  const isLocationPermissionAvailable = usePermission(CorePolicies.GLOBAL_LOCATION_PERMISSIONS);


  const { current: map } = useMap();

  useLocationsMarkerIcons(map);

  useLayerListener(
    'click',
    [
      'r__customerLocationsLayer-hotels',
      'r__customerLocationsLayer-airports',
      'r__customerLocationsLayer-general-accommodations',
      'r__customerLocationsLayer-heliports',
      'r__customerLocationsLayer-harbours',
      'r__customerLocationsLayer-rail-stations',
      'r__customerLocationsLayer-bus-stations',
      'r__customerLocationsLayer-car-hires',
      'r__customerLocationsLayer-embarkation-points'
    ],
    useCallback(
      (evt) => {
        const { layerFeatures } = getClickedOnFeatures(evt, [
          'r__customerLocationsLayer-hotels',
          'r__customerLocationsLayer-airports',
          'r__customerLocationsLayer-general-accommodations',
          'r__customerLocationsLayer-heliports',
          'r__customerLocationsLayer-harbours',
          'r__customerLocationsLayer-rail-stations',
          'r__customerLocationsLayer-bus-stations',
          'r__customerLocationsLayer-car-hires',
          'r__customerLocationsLayer-embarkation-points'
        ]);

        const feature = layerFeatures[0];
        const { properties } = feature;
        const { coordinates } = (feature.geometry as Point);

        setIsPopupShown(false);

        if (!layerFeatures.length) {
          return;
        }

        setPopupContent({
          locationName: properties?.name,
          locationCategory: `${properties?.category}/${properties?.subCategory}`
        } as CustomerLocationPopupData);

        setPopupCoordinates({
          latitude: coordinates[1],
          longitude: coordinates[0]
        });

        setIsPopupShown(true);
      },
      [setIsPopupShown, setPopupCoordinates]
    )
  );

  useEffect(() => {
    if (isLocationPermissionAvailable) {
      loadAllCustomerLocations();
    }
  }, [isLocationPermissionAvailable, loadAllCustomerLocations]);

  return (
    <CustomerLocationLayerComponent
      isPopupShown={ currentBigMapPopup === "MapLocation" }
      popupCoordinates={ popupCoordinates }
      geoData={ geoData }
      popupContent={ popupContent }
      layers={ layers }
      popupRef={ popupRef }
    />
  );
};
