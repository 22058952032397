import { callApi } from "@/common/util/api";
import { Location } from "@/tenant-context/common/types/location";

export const getAllCountries = () => callApi<Location[]>(
  'location-management-service/v1/categories/Areas/sub-categories/Country/locations',
  {
    method: 'get',
    queryParams: {
      pageNum: 0,
      pageSize: 1000,
      orderBy: 'name:ASC',
      mergeNonTenant: true
    }
  }
);
