import * as mapsgl from '@aerisweather/mapsgl';
import { FC, useEffect } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from "react-redux";

import {
  ToggleableContextLayers
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { logger } from "@/common/util/ConsoleLogger";
import { isInDevMode } from "@/core/config/env";
import { RootState } from "@/core/store";
import { useBigMapContext } from "@/tenant-context/core/context/BigMap.context";

type WeatherLayerConfig = {
    contextualLayerType: ToggleableContextLayers,
    name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: Record<string, any>
};

const weatherLayerConfigs: Array<WeatherLayerConfig> = [
  { contextualLayerType: ToggleableContextLayers.Radar, name: 'radar', options: { paint: { opacity: .75 } } },
  { contextualLayerType: ToggleableContextLayers.Cyclones, name: 'tropical-cyclones', options: {} },
  { contextualLayerType: ToggleableContextLayers.Wind, name: 'wind-particles', options: { paint: { opacity: .5, sample: {
    colorscale: {
      normalized: true,
      stops: [
        0, '#FFF',
        1, '#FFF'
      ]
    }
  } } } }
];

const {
  REACT_APP_AERIS_ID,
  REACT_APP_AERIS_KEY
} = process.env;

export const isWeatherMapEnabled = isInDevMode && REACT_APP_AERIS_ID && REACT_APP_AERIS_KEY;

export const AerisInteractiveMap: FC = () => {

  const { weatherController, setWeatherController } = useBigMapContext();

  const contextualLayers = useSelector((state: RootState) => state.dataOptions?.contextLayerOptions);
  const isContextualLayersSubscribed = useSelector((state: RootState) => state.commonData.tenantSubscribedProducts)
    ?.findIndex((product) => product.name === "Contextual Map Layers") !== -1;

  const AERIS_ID = REACT_APP_AERIS_ID;
  const AERIS_KEY = REACT_APP_AERIS_KEY;

  const { current: map } = useMap();

  // Initialize the controller if not already initialized
  useEffect(() => {
    if (!map) {
      return;
    }

    if (!AERIS_ID || !AERIS_KEY) {
      logger.error('Aeris ID and Key are required to use the Aeris Interactive Map');
      return;
    }

    if (!weatherController && isWeatherMapEnabled && isContextualLayersSubscribed) {
      const account = new mapsgl.Account(AERIS_ID, AERIS_KEY);
      const newController = new mapsgl.MapboxMapController(map.getMap(), { account });
      setWeatherController(newController);
    }
  }, [ AERIS_ID, AERIS_KEY, weatherController, map, setWeatherController, isContextualLayersSubscribed ]);

  // Add or remove layers based on the contextual layer configuration
  useEffect(() => {
    if (!weatherController || !contextualLayers) {
      return;
    }

    Object.entries(contextualLayers).forEach(([layerType, isEnabled]) => {
      const layer = weatherLayerConfigs.find((weatherLayer) => weatherLayer.contextualLayerType === layerType);

      if (!layer) {
        return;
      }

      if (isEnabled && !weatherController.hasWeatherLayer(layer.name)) {
        weatherController.addWeatherLayer(layer.name, layer.options);
      } else if (!isEnabled && weatherController.hasWeatherLayer(layer.name)) {
        weatherController.removeWeatherLayer(layer.name);
      }
    });

    return () => {
      weatherLayerConfigs.forEach((layer) => {
        if (weatherController.hasWeatherLayer(layer.name)) {
          weatherController.removeWeatherLayer(layer.name);
        }
      });
    };
  }, [ weatherController, contextualLayers ]);

  return (<div></div>);
};
