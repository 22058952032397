/* eslint-disable no-magic-numbers */
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";
import { Group } from "@visx/group";
import { Line } from "@visx/shape";
import { Text } from "@visx/text";
import { FC } from "react";

import { riskTimelineGraphMargin } from "@/tenant-context/control-risk-timeline/config/risk-timeline.config";

type Props = {
  nowMarkerX: number;
  theme: MantineTheme,
  chartHeight: number,
  classes: Record<string, string>
}

export const TodayLine: FC<Props> = ({
  nowMarkerX,
  theme,
  chartHeight,
  classes
}) => (
  <Group
    left={ nowMarkerX + riskTimelineGraphMargin.left }
  >
    <Line
      stroke={ theme.colors.blue[6] }
      strokeWidth={ 2 }
      from={ {
        x: 0,
        y: chartHeight + 35
      } }
      to={ {
        x: 0,
        y: chartHeight + 55
      } }
    />

    <Text
      className={ classes.todayText }
      dy={ chartHeight + 70 }
      dx={ -20 }
      fontSize={ theme.fontSizes.sm }
      fill={ theme.white }
    >
      Today
    </Text>
  </Group>
);
