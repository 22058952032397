import { FC } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";

import SummaryPageComponent from "./SummaryPage.component";


const SummaryPageContainer: FC = () => {
  const {
    deliveryChannels    
  } = useSelector((state: RootState) => state.massComms);

  return (
    <SummaryPageComponent
      deliveryChannels={ deliveryChannels }
    />
  );
};

export default SummaryPageContainer;
