import { FC, useCallback } from "react";

import useZoomIntoFeature from "@/tenant-context/common/hooks/useZoomIntoFeature";
import { SearchResultFeature } from "@/tenant-context/control-search/types/search";

import GlobalSearchResultItem from './GlobalSearchResultItem.component';

type Props = {
  feature: SearchResultFeature,
  onHandleActiveItemClick: () => void
  isTheOnlyOne: boolean,
  isSelected:boolean
}


const GlobalSearchResultItemContainer: FC<Props> = ({
  feature,isSelected,onHandleActiveItemClick, isTheOnlyOne
}) => {
  const zoomIntoFeature = useZoomIntoFeature();

  const handleItemClick = useCallback(
    () => {
      zoomIntoFeature(feature);
      onHandleActiveItemClick();
    }, 
    [feature, onHandleActiveItemClick, zoomIntoFeature]
  );

  return (
    <GlobalSearchResultItem
      feature={ feature }
      isActive= { isSelected || isTheOnlyOne }
      onClickItem={ handleItemClick }
    />
  );
};

export default GlobalSearchResultItemContainer;
