import { Button } from "@mantine/core";
import { FileWithPath } from "@mantine/dropzone";
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FileRejection } from "react-dropzone";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import { ReactComponent as TrashIcon } from "@/common/icons/trash.svg";
import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootState } from "@/core/store";
import { getAllGeoJsonBounds } from "@/tenant-context/common/util/map";
import {
  columnDefs
} from "@/tenant-context/control-location-configuration/components/AddLocations/SitePlanTab/compoments/UploadedFilesList/UploadedFilesList.config";
import { LocationInfoContext } from "@/tenant-context/control-location-configuration/context/LocationInfo.context";
import { SitePlanList } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

import { useUploadedFilesListStyles } from "../../../SitePlanTab/compoments/UploadedFilesList/styles";
import LocationGeoBoundaryComponent from "./LocationGeoBoundary.component";


const LocationGeoBoundary = () => {
  const [uploadedFile, setUploadedFile] = useState<FileWithPath | null>(null);
  const [uploadedFileError, setUploadedFileError] = useState<FileRejection | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const { AddLocationMap: map } = useMap();
  const { handleDrawingToolSelect, drawControl  } = useContext(LocationInfoContext);
  const { overlayGeoJson, 
    downloadedSitePlanGeoJson, currentSitePlanGeoJson } = useSelector((state: RootState) => state.manageLocations);
  const {
    manageLocations: {
      SET_OVERLAY_GEO_JSON,
      getSitePlanList
    }
  } = useDispatch<Dispatch>();
  const { classes } = useUploadedFilesListStyles();


  const parseUploadedFile = useCallback((file: FileWithPath, callback) => {
    const fr = new FileReader();
    fr.onload = () => callback(null, fr.result);
    fr.onerror = (err) => callback(err);
    fr.readAsText(file);
  }, []);

  const onDrawCancelButtonClick = useCallback(() => {
    setUploadedFile(null);
    setUploadedFileError(null);
    setIsDrawing(false);
    SET_OVERLAY_GEO_JSON(undefined);
    drawControl.current?.deleteAll();
  }, [SET_OVERLAY_GEO_JSON, drawControl]);

  const onUploadCancelButtonClick = useCallback(() => {
    setUploadedFile(null);
    setUploadedFileError(null);
  }, []);

  const onDropFileUpload = useCallback((file: FileWithPath[]) => {
    setUploadedFileError(null);
    setUploadedFile(file[0] as File);
  }, []);

  const onRejectFileUpload = useCallback((file: FileRejection[]) => {
    setUploadedFile(null);
    setUploadedFileError(file[0]);
  }, []);

  const handleDrawingToolSelected = useCallback((isSelected: boolean) => {
    setIsDrawing(isSelected);
  }, []);

  const handleSaveUploadedFile = useCallback(() => {
    if (uploadedFile) {

      parseUploadedFile(uploadedFile, (err: string, res: string) => {
        if (err) {
          handleError({ message: err, name: 'File Reader Error' });
        } else {
          const parsedJson = JSON.parse(res);
          SET_OVERLAY_GEO_JSON(parsedJson);
          const bounds = getAllGeoJsonBounds(parsedJson);
          if (bounds && map){
            map.fitBounds(bounds as [number, number, number, number], {
              padding: { top: 50, bottom: 50, left: 50, right: 50 }
            });       
          }
        }
      });
    } else {
      setIsDrawing(false);
    }

  }, [SET_OVERLAY_GEO_JSON, map, parseUploadedFile, uploadedFile]);

  const onFileDeleteHandler = useCallback(() => {
    if(overlayGeoJson){
      onDrawCancelButtonClick();
    }
  }, [onDrawCancelButtonClick, overlayGeoJson]);


  const deleteFileHandler = useCallback(
    () => {
      openConfirmationModal({
        text: "Are you sure you want to remove this plan?",
        title: "Confirm Removal",
        onConfirm: onFileDeleteHandler
      });
    },
    [onFileDeleteHandler]
  );

  const actionCellRenderer = useCallback((): ReactNode => {
    return (
      <div className={ classes.buttonWrapper }>
        <Button
          onClick={ deleteFileHandler }
          className={ classes.btn }
        >
          <TrashIcon />
        </Button>
      </div>
    );
  }, [classes.btn, classes.buttonWrapper, deleteFileHandler]);

  const uploadedFiles = useMemo(() => {
    if (overlayGeoJson){
      return [{ fileName: 'GeoJson', fileSize: 'no data', fileType: 'json' }];
    } else {
      return [];
    }

  }, [overlayGeoJson]);

  useEffect(() => {
    return () => {
      setUploadedFile(null);
      setIsDrawing(false);
      SET_OVERLAY_GEO_JSON(undefined);
      drawControl.current?.deleteAll();
    };
  }, [SET_OVERLAY_GEO_JSON, drawControl]);

  useEffect(() => {
    if (!currentSitePlanGeoJson && !downloadedSitePlanGeoJson){
      getSitePlanList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocationGeoBoundaryComponent
      handleSaveUploadedFile={ handleSaveUploadedFile }
      uploadedFile={ uploadedFile }
      uploadedFileError={ uploadedFileError }
      handleDrawingToolSelect={ handleDrawingToolSelect }
      isDrawing={ isDrawing }
      setIsDrawing={ handleDrawingToolSelected }
      onDrawCancelButtonClick={ onDrawCancelButtonClick }
      onUploadCancelButtonClick={ onUploadCancelButtonClick }
      onDropFileUpload={ onDropFileUpload }
      onRejectFileUpload={ onRejectFileUpload }
      columnDefs={ columnDefs(actionCellRenderer) }
      fileData={ uploadedFiles as SitePlanList[] }
    />
  );
};

LocationGeoBoundary.displayName = 'LocationGeoBoundary';

export default LocationGeoBoundary;
