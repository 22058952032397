import { Tabs } from "@mantine/core";
import { FC, useMemo } from "react";

import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import MassCommsSummaryGraph from "@/tenant-context/control-mass-comms/components/SummaryPage/MassCommsSummaryGraph";
import AppCommsSummary
  from '@/tenant-context/control-mass-comms/components/SummaryPage/Summary-Channels/AppCommsSummary';
import VoiceSummary
  from "@/tenant-context/control-mass-comms/components/SummaryPage/Summary-Channels/VoiceSummary/VoiceSummary.container";
import { ReactComponent as AppIcon } from "@/tenant-context/control-mass-comms/icons/app-gray.svg";
import { ReactComponent as EmailIcon } from "@/tenant-context/control-mass-comms/icons/emailIcon.svg";
import { ReactComponent as MessageSmsIcon } from "@/tenant-context/control-mass-comms/icons/messageSmsIcon.svg";
import { ReactComponent as PhoneIcon } from "@/tenant-context/control-mass-comms/icons/phoneIcon.svg";
import { ReactComponent as RoundedTick } from "@/tenant-context/control-mass-comms/icons/roundedTick.svg";
import { DeliveryChannel } from "@/tenant-context/control-mass-comms/types/MassComms.types";

import MassCommsSection from "../MassCommsSection";
import EmailSummary from "./Summary-Channels/EmailSummary";
import SmsSummary from "./Summary-Channels/SmsSummary";
import { useSummaryPageStyles } from "./SummaryPage.styles";

type Props = {
  deliveryChannels: DeliveryChannel
}

const SummaryPageComponent: FC<Props> = ({ deliveryChannels }) => {
  const { classes, cx } = useSummaryPageStyles({});

  const defaultTab = useMemo(() => {
    if (deliveryChannels.app) {
      return 'app';
    }
    if (deliveryChannels.email) {
      return 'email';
    }
    if (deliveryChannels.sms) {
      return 'sms';
    }
    if (deliveryChannels.voice) {
      return 'voice';
    }

    return undefined;
  }, [deliveryChannels]);

  return (
    <MassCommsSection className={ cx({
      [classes.parentContainer]: true,
      [classes.hideDisplay]: !deliveryChannels.email &&
        !deliveryChannels.app && !deliveryChannels.sms && !deliveryChannels.voice
    }) }>
      <LocalErrorBoundary>
        <Tabs defaultValue={ defaultTab } >
          <div className={ classes.summaryTabContainer }>
            <Tabs.List>
              { deliveryChannels.app &&
              <Tabs.Tab
                value="app"
                rightSection={ <RoundedTick className={ classes.endIcon } /> }
                icon={ <AppIcon className={ classes.startIcon } /> }>
                App Configuration
              </Tabs.Tab> }
              { deliveryChannels.email &&
                <Tabs.Tab
                  value="email"
                  rightSection={ <RoundedTick className={ classes.endIcon } /> }
                  icon={ <EmailIcon className={ classes.startIcon } /> }>
                  Email Configuration
                </Tabs.Tab> }
              { deliveryChannels.sms &&
                <Tabs.Tab
                  value="sms"
                  rightSection={ <RoundedTick className={ classes.endIcon } /> }
                  icon={ <MessageSmsIcon className={ classes.startIcon } /> }>
                  SMS Configuration
                </Tabs.Tab> }
              { deliveryChannels.voice &&
                <Tabs.Tab
                  value="voice"
                  rightSection={ <RoundedTick className={ classes.endIcon } /> }
                  icon={ <PhoneIcon className={ classes.startIcon } /> }>
                  Call Configuration
                </Tabs.Tab> }
            </Tabs.List>
          </div>
          <div className={ classes.innerContainer }>
            <div className={ classes.tabContainer }>
              { deliveryChannels.app &&
              <Tabs.Panel value="app">
                <LocalErrorBoundary>
                  <AppCommsSummary/>
                </LocalErrorBoundary>
              </Tabs.Panel> }
              { deliveryChannels.email &&
                <Tabs.Panel value="email">
                  <LocalErrorBoundary>
                    <EmailSummary />
                  </LocalErrorBoundary>
                </Tabs.Panel> }
              { deliveryChannels.sms &&
                <Tabs.Panel value="sms">
                  <LocalErrorBoundary>
                    <SmsSummary />
                  </LocalErrorBoundary>
                </Tabs.Panel> }
              { deliveryChannels.voice &&
                <Tabs.Panel value="voice">
                  <LocalErrorBoundary>
                    <VoiceSummary />
                  </LocalErrorBoundary>
                </Tabs.Panel> }
            </div>
            <div className={ classes.graphContainer }>
              <div className={ classes.sectionHeader }>Recipient Breakdown</div>
              <div className={ classes.graph }>
                <MassCommsSummaryGraph />
              </div>
            </div>
          </div>


        </Tabs>
      </LocalErrorBoundary>
    </MassCommsSection>
  );
};

export default SummaryPageComponent;
