import { UserPolicy } from "@/common/types/permission-control";

export type VisitorRequest ={
    requestId: string,
    visitors: string,
    requester: string,
    estimatedArrival: Date,
    estimatedDeparture: Date,
    site: string,
    status: string,
    _id: string
}

export type VisitorDetails ={
    requestId: string,
    requestDateTime: Date,
    numberOfVisitors: string,
    status: Array<string>,
}
export interface VisitorRequestFilters{
    site: string,
    search: string,
    status: string,
    timeZone: string,
    arrivalDateFrom: Date | null,
    arrivalDateTo: Date | null,
    arrivalTimeFrom: string,
    arrivalTimeTo: string
    departureDateFrom: Date | null,
    departureDateTo: Date | null,
    departureTimeFrom: string,
    departureTimeTo: string
  }
export interface VisitorFilters{
    site: string,
    search: string,
    status: string,
    timeZone: string,
    arrivalDateFrom: Date | null,
    arrivalDateTo: Date | null,
    arrivalTimeFrom: string,
    arrivalTimeTo: string
    departureDateFrom: Date | null,
    departureDateTo: Date | null,
    departureTimeFrom: string,
    departureTimeTo: string,
    siteTimeZone: string,
    preperdTimeZone: string,
  }

export enum VisitRequestTag {
    DRAFT = 'DRAFT',
    AWAITING_APPROVAL = 'AWAITING_APPROVAL',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED',
    EXPECTED = 'EXPECTED',
    VIP = "VIP"
  }

export enum VisitStatuses {
    DRAFT = 'DRAFT',
    AWAITING_APPROVAL = 'AWAITING_APPROVAL',
    EXPECTED = 'EXPECTED',
    REJECTED = 'REJECTED',
    CANCELLED = 'CANCELLED',
    ARRIVED = 'ARRIVED',
    ESCORTING = 'ESCORTING',
    WITH_HOST= 'WITH_HOST',
    DEPARTED='DEPARTED'
  }
export enum VisitTag {
    DRAFT = 'Draft',
    AWAITING_APPROVAL = 'Awaiting Approval',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
    CANCELLED = 'Cancelled',
    EXPECTED = 'Expected'
  }
export enum VisitRequestActions {
    SUBMIT_FOR_APPROVAL = 'SUBMIT_FOR_APPROVAL',
    UNDO = 'UNDO',
    CANCEL_REQUEST = "CANCEL_REQUEST",
    APPROVE_REQUEST = "APPROVE_REQUEST",
    REJECT_REQUEST = "REJECT_REQUEST"
  }

export enum VisitRequestType{
    EXPECTED = 'EXPECTED',
    UN_EXPECTED = 'UN_EXPECTED'
  }

export type Visitor = {
    visitorRequestID: string
    arrivalDateTime: number
    arrivalDateInSiteZone: string
    arrivalDateInUtc: string
    arrivalDateInUserZone: string
    departDateTime: number
    departureDateInUtc: string
    departureDateInSiteZone: string
    departureDateInUserZone: string
    visitorName: string
    company: string
    role: string
    site: string
    status: string
    _id?: string
    profileId?: string
    identifier?: string
}

export interface VisitVehicle{
    name: string
}

export interface VisitRequestParams {
    siteId : string | null,
    siteName? : string,
    type : VisitRequestType | null,
    status : VisitRequestTag,
    arrivalDateTime : string,
    departDateTime : string,
    purpose : string,
    vip: boolean,
    _id?: string,
}

export interface IVisitRequest{
    _id: string,
    requestId: string,
    type : VisitRequestType,
    status : VisitRequestTag,
    arrivalDateTime: string,
    departDateTime: string,
    purpose : string,
    vip: boolean,
    siteId: string,
    departureDateInSiteZone: string,
    departureDateInUtc: string,
    arrivalDateInSiteZone: string,
    arrivalDateInUtc: string,
    updatedBy: string,
    updatedDate: number,
    visitorCount: number,
    canCancel: boolean,
    canReject: boolean
}
  
export interface IVisitNote {
    createdBy: string,
    createdDateTime: number,
    note: string,
    _id: string,
    updatedDateTime: number
}

export interface IVisitVehicle {
    licensePlateNumber: string,
    vehicleType: string,
    make: string,
    model: string,
    colour: string,
    _id?: string,
    vehicleTid?: string
    visitRequestId?: string
}

export type ExistingVisitorProfile = {
    activeStatus: string,
    companyName: string,
    companyRef: string,
    email: string,
    employeeId: string,
    firstName: string,
    jobTitle: string,
    lastName: string,
    name: string,
    profileId: string,
    profileTid: string,
    _id?: string
}


export interface VisitorDocumentType {
    documentNumber: string,
    documentType: string
}

export interface VisitorDetailsParams{
    hostProfileId: string,
    documents: VisitorDocumentType[],
    vip: boolean,
    visitorTid: string,
    visitRequestId: string
}

export interface IVisitorDetails{
    _id: string
    identifier: string
    profileId: string
    vip: boolean
    firstName: string
    lastName: string
    name: string
    employeeId: string
    jobTitle: string
    companyRef: string
    companyName: string
    primaryEmail: string
    primaryMobile: string
    hostProfileTid: string
    hostProfileId: string
    documents: VisitorDocumentType[]
    createdBy: string
    createdDateTime: number
    updatedDateTime: number
}

export interface SelectedHost{
    profileId: string,
    name: string
}

export enum AddVisitorModalType {
    EXISTING_PERSON = 'EXISTING_PERSON',
    NEW_PERSON = 'NEW_PERSON'
}

export enum AddNewVisitorTabs {
    PERSONAL_DETAILS = 'PERSONAL_DETAILS',
    COMPANY_DETAILS = 'COMPANY_DETAILS',
    CONTACT_DETAILS = 'CONTACT_DETAILS',
    EMERGENCY_CONTACT = 'EMERGENCY_CONTACT',
    PEOPLE_GROUP = 'PEOPLE_GROUP',
    VISIT_DETAILS = 'VISIT_DETAILS',
}

export type CreateNewVisitorProfile = {
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    gender: string,
    nationality: string
    _id?: string
    profileId?: string,
    mobileNumber?: string,
    email?: string
};

export type CreateNewVisitorCompany = {
    profileId: string,
    companyId: string,
    employeeId: string,
    personType: string,
    _id?: string
}

export type CreateNewVisitorContact = {
    profileId: string,
    contacts: NewVisitorContact[],
}

export type CreateNewVisitorContactFormData = {
    contacts: NewVisitorContact[],
}

export type NewVisitorContact = {
    contactDetailType: string
    contactType: string,
    value: string,
    provider: string
}

export type CreateNewVisitorEmergencyContact = {
    profileId: string,
    type: string,
    relationship: string,
    firstName: string,
    lastName: string,
    phoneList: string[],
    email: string
}

export type CreateNewVisitorGroup = {
    peopleGroupTid: string,
    profileTid: string,
    toRemove: boolean
}

export type NewVisitorGroupList = {
    active: string
    applicableServices: string
    applicableToAllService: string
    isActive: boolean
    isApplicableToAllService: boolean
    name: string
    profileCount: number
    _id: string
}

export type CreateNewVisitor = {
    _id: string,
}

export interface VisitInfo {
  _id: string
  requestId: string
  visitingLocation: string
  arrivalDateTime: number
  departDateTime: number
  arrivalDateInUtc: string
  departureDateInUtc: string
  arrivalDateInSiteZone: string
  departureDateInSiteZone: string
  visitStatus: string
  siteId: string
  vip: boolean
}

export interface UserVisitsInfo {
    future: VisitInfo,
    inProgress: VisitInfo,
    past: VisitInfo
}

export interface ExistingVisitor {
    profileTid: string
    profileId: string
    name: string
    firstName: string
    lastName: string
    activeStatus: string
    jobTitle: string
    companyRef: string
    email: string
    mobileNumber: string,
    primaryEmail?: string,
    primaryMobile?: string
  }

export interface VisitorSite{
    policies: UserPolicy[]
    siteDto: ISiteDto
  }
export interface ISiteDto {
    tenantId?: string
    id?: string
    name: string
    description?: string
    address?: Address
    timeZone?: TimeZone
    tid: string
  }
  
interface Address {
    firstLine: string
    secondLine: string
    thirdLine: string
    postalCode: string
    region: string
    city: string
    country: string
  }
  
interface TimeZone {
    label: string
    offset: string
    timeZone: string
    value: string
  }

export interface IVisitorSitePolicy{
    policies: UserPolicy[],
    siteId: string
  }

export const SITE_SESSION_KEY = "selectedSite";

export interface SessionSite{
    siteId: string,
    siteName: string
}
