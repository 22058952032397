import { createModel } from "@rematch/core";
import { FeatureCollection, Point } from "geojson";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { Location } from "@/tenant-context/common/types/location";
import { withVisualisableTraits } from "@/tenant-context/common/util/with-visualisable-traits";
import { parseGeoJSON } from "@/tenant-context/common/workers/geo-json-mapper-worker/geo-json-mapper-worker.external";
import { getCustomerLocationsForBounds } from "@/tenant-context/visualisation-customer-locations/api/customer-locations";
import { constructCustomerLocationQuery } from "@/tenant-context/visualisation-customer-locations/utils/customer-location.utils";

const customerLocationLayerModel = withVisualisableTraits<FeatureCollection<Point, Location>>()({
  name: 'customerLocations',
  state: {},
  reducers: {},
  effects: (dispatch: Dispatch) => ({
    async loadAllCustomerLocations(_: void, state: RootState): Promise<void> {
      const allCustomerLocations = await getCustomerLocationsForBounds(
        constructCustomerLocationQuery(state.dataOptions?.disabledLayerTypes)
      );

      const { data } = await parseGeoJSON({
        data: allCustomerLocations,
        params: {
          Point: ['geoPoint.lat', 'geoPoint.lon']
        }
      });

      dispatch.customerLocations.SET_GEO_DATA(data as FeatureCollection<Point, Location>);
    }
  })
});

export const customerLocations = createModel<RootModel>()(
  customerLocationLayerModel
);
