export const ServiceConfigurationRequiredPoliciesConfig = {
  SERVICE_CONFIG_ROUTE_ACCESS: {
    policyNames: [
      'SystemAdmin_System_Integration_ReadOnly',
      'SystemAdmin_System_Integration_FullAccess'
    ]
  },
  EDIT_SERVICE_CONFIG: {
    policyNames: [
      'SystemAdmin_System_Integration_FullAccess'
    ]
  }
};