import { useCallback, useContext } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { removeRef } from "@/common/util/common-functions";
import { Dispatch, RootState } from "@/core/store";
import {
  CaseTypeDetailsContext
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/CaseTypeDetails.context";
import {
  roleFeaturesFirstStep
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/modals/CreateRoleModal/CreateRoleModal.config";
import OverviewComponent
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/Overview/Overview.component";
import { CreateRoleParams } from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

const Overview = () => {
  const {
    caseTypes: {
      updateRole,
      getRole
    }
  } = useDispatch<Dispatch>();

  const formControls = useForm({ mode: 'onChange' });

  const { isAuthorizedToCreateOrEdit } = useContext(CaseTypeDetailsContext);

  const activeRole = useSelector((state: RootState) => state.caseTypes?.activeRole);

  const { id, roleId } = useParams();

  const updateRoleHandler = useCallback( async (data: FieldValues) => {
    if(id && roleId && activeRole) {

      const { featuresWithPermissionList, ...rest } = activeRole;

      const initialFeatures: Record<string, unknown> = {};

      featuresWithPermissionList.forEach((feature) => {
        if (feature.enable) {
          initialFeatures[feature.identifier] = feature.permission;
        }
      });

      const updateData = {
        ...rest,
        teamRef: removeRef(activeRole.teamRef),
        featurePermissionMap: initialFeatures,
        ...data,
        roleName: data?.roleName.trim()
      };

      const params = {
        data: updateData as CreateRoleParams,
        caseTypeId: id,
        roleId: roleId
      };

      const result = await updateRole(params);

      if(result){
        getRole({
          caseTypeId: id,
          roleId
        });
      }

      return result;
    }
  },[activeRole, getRole, id, roleId, updateRole]);

  return (
    <div>
      <OverviewComponent
        updateHandler={ updateRoleHandler }
        formControls={ formControls }
        activeRole={ activeRole }
        isAllowedToUpdate={ isAuthorizedToCreateOrEdit }
        roleFeaturesFirstStep={ roleFeaturesFirstStep }
      />
    </div>
  );
};

export default Overview;