import { ActionIcon, Badge, Box, Checkbox, Collapse, Radio, Tabs, TextInput } from "@mantine/core";
import { FC } from "react";

import TruncatedTextWithTooltip from "@/common/components/TruncatedTextWithTooltip/TruncatedTextWithTooltip.component";
import { ReactComponent as CheckedIcon } from "@/common/icons/checked.svg";
import { ReactComponent as CloseIcon } from "@/common/icons/close.svg";
import { ReactComponent as SearchIcon } from '@/common/icons/search.svg';
import LocalErrorBoundary from "@/tenant-context/common/components/LocalErrorBoundary";
import MassCommsSection from "@/tenant-context/control-mass-comms/components/MassCommsSection";
import RecipientsListView from "@/tenant-context/control-mass-comms/components/SelectRecipientsPage/RecipientsListView";
import { ReactComponent as Circle } from '@/tenant-context/control-mass-comms/icons/colored-circle.svg';
import { ReactComponent as PlusIcon } from '@/tenant-context/control-mass-comms/icons/standardPlusIcon.svg';
import { ReactComponent as User } from '@/tenant-context/control-mass-comms/icons/user.svg';
import { ReactComponent as UserGroup } from '@/tenant-context/control-mass-comms/icons/user-group.svg';
import {
  GroupEntity,
  ImpactedPeopleSet,
  PeopleEntity,
  RecipientMainSelection,
  SearchedDeliveryEntities
} from "@/tenant-context/control-mass-comms/types/MassComms.types";

import { useSelectRecipientsPageStyles } from "./SelectRecipientsPage.styles";

type Props = {
  onRecipientGroupSelected: (value: string) => void,
  selectedRecipient: RecipientMainSelection,
  onDeliveryEntitiesSelected: (data: React.FormEvent<HTMLInputElement>) => void,
  onPeopleSelect: (item: PeopleEntity) => (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void,
  isDeliverySearchLoading: boolean | undefined,
  searchedDeliveryEntities: SearchedDeliveryEntities
  onImpactItemSelect: (event: React.ChangeEvent<HTMLInputElement>) => void
  impactResponseItems: ImpactedPeopleSet
  onAddGroup: (item: GroupEntity) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  onRemoveGroup: (item: GroupEntity) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  selectedGroups: Array<GroupEntity>,
  isNavigatedFromArc: boolean
}

const SelectRecipientsPageComponent: FC<Props> = ({
  onRecipientGroupSelected,
  selectedRecipient,
  onDeliveryEntitiesSelected,
  isDeliverySearchLoading,
  searchedDeliveryEntities,
  onPeopleSelect,
  onAddGroup,
  onRemoveGroup,
  onImpactItemSelect,
  impactResponseItems,
  isNavigatedFromArc
}) => {

  const { classes, cx } = useSelectRecipientsPageStyles();
  const maxLengthText = 15;

  return (
    <div className={ classes.recipientContainer }>
      <MassCommsSection>
        <LocalErrorBoundary>
          <div className={ classes.sectionHeader }>Recipients</div>
          <Radio.Group
            orientation="vertical"
            onChange={ onRecipientGroupSelected }
            value={ selectedRecipient }
            className={ classes.radioGroup }
          >
            { isNavigatedFromArc && ( <div className={ selectedRecipient === 'impactSelect' ? classes.radioItemActive : classes.radioItemInactive }>
              <Box m={ 'xl' }>
                <Radio value="impactSelect" label="Select People Impacted"/>
              </Box>
              <Collapse in={ selectedRecipient === 'impactSelect' }>
                <div className={ classes.collapsableImpactDetails }>
                  <div className={ classes.impactPeopleContainer }>
                    <div className={ classes.impactAllRow }>
                      <Checkbox
                        size="sm"
                        name="allImpacted"
                        onChange={ onImpactItemSelect }
                        checked={ impactResponseItems.allImpacted.isChecked }
                      />
                      <div className={ classes.impactColor }></div>
                      <div className={ classes.impactLabel }>
                        All People Impacted
                        ({ impactResponseItems.notSafe.people.length + impactResponseItems.noResponse.people.length
                        + impactResponseItems.okPeople.people.length + impactResponseItems.otherPeople.people.length })
                      </div>
                    </div>
                    <div className={ classes.checkItemRow }>
                      <Checkbox
                        size="sm"
                        name="notSafe"
                        onChange={ onImpactItemSelect }
                        checked={ impactResponseItems.notSafe.isChecked }
                      />
                      <div className={ classes.impactColor }><Circle/></div>
                      <div className={ classes.impactLabel }>
                        Not Safe People ({ impactResponseItems.notSafe.people.length })
                      </div>
                    </div>
                    <div className={ classes.checkItemRow }>
                      <Checkbox
                        size="sm"
                        name="noResponse"
                        onChange={ onImpactItemSelect }
                        checked={ impactResponseItems.noResponse.isChecked }
                      />
                      <div className={ classes.impactNoReponse }><Circle/></div>
                      <div className={ classes.impactLabel }>
                        No Response People ({ impactResponseItems.noResponse.people.length })
                      </div>
                    </div>
                    <div className={ classes.checkItemRow }>
                      <Checkbox
                        size="sm"
                        name="okPeople"
                        onChange={ onImpactItemSelect }
                        checked={ impactResponseItems.okPeople.isChecked }
                      />
                      <div className={ classes.impactOk }><Circle/></div>
                      <div className={ classes.impactLabel }>
                        OK People ({ impactResponseItems.okPeople.people.length })
                      </div>
                    </div>
                    <div className={ classes.checkItemRow }>
                      <Checkbox
                        size="sm"
                        name="otherPeople"
                        onChange={ onImpactItemSelect }
                        checked={ impactResponseItems.otherPeople.isChecked }
                      />
                      <div className={ classes.impactOther }><Circle/></div>
                      <div className={ classes.impactLabel }>
                        Other People ({ impactResponseItems.otherPeople.people.length })
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div> ) }

            <div className={ selectedRecipient === 'singleGroupSelect' ? classes.radioItemActive : classes.radioItemInactive }>
              <Box m={ 'xl' }>
                <Radio value="singleGroupSelect" label="Select Individuals or Groups" />
              </Box>
              <Collapse in={ selectedRecipient === 'singleGroupSelect' }>
                <div className={ classes.collapsableRecipientSelect }>
                  <Tabs variant="outline" defaultValue="people" className={ classes.peopleSelectTab }>
                    <Tabs.List>
                      <Tabs.Tab value="people" icon={ <User /> }>Individual Persons</Tabs.Tab>
                      <Tabs.Tab value="groups" icon={ <UserGroup /> }>People Groups</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="people" className={ classes.peopleTabInner }>
                      <TextInput
                        name="people"
                        placeholder="Start typing a person's first name, last name, email address or employee Id"
                        icon={ <SearchIcon /> }
                        onChange={ onDeliveryEntitiesSelected } />

                      { (isDeliverySearchLoading) && (<div
                        className={ classes.noRecords }>
                        <span>Loading ...</span>
                      </div>
                      ) }

                      { (isDeliverySearchLoading === false && searchedDeliveryEntities.people.length === 0) && <div
                        className={ classes.noRecords }>
                        <span>No recipients found!</span>
                      </div> }

                      { !isDeliverySearchLoading && searchedDeliveryEntities.people.length > 0 && (
                        <div className={ cx({ [classes.areaHidden]: searchedDeliveryEntities.people.length === 0 }) } >
                          <div className={ classes.resultsCount }>{ `${searchedDeliveryEntities.people.length} results found` }</div>
                          <div className={ classes.tableColumns }>
                            <div className={ classes.columnContainer }>
                              <div>FIRST NAME</div>
                              { /* <SortIcon /> Todo */ }
                            </div>
                            <div className={ classes.columnContainer }>
                              <div>LAST NAME</div>
                              { /* <SortIcon /> Todo */ }
                            </div>
                            <div className={ classes.columnContainer }>
                              <div>EMPLOYEE ID</div>
                              { /* <SortIcon /> Todo */ }
                            </div>
                            <div className={ classes.columnContainer }>
                              <div>EMAIL</div>
                              { /* <SortIcon /> Todo */ }
                            </div>
                          </div>
                          <div className={ classes.rowContainer }>
                            <div className={ classes.innerWrapper }>
                              { searchedDeliveryEntities.people.length !== 0 &&
                                searchedDeliveryEntities.people.map((person: PeopleEntity) => (
                                  <div key={ person.profileId } className={ cx({
                                    [classes.tableRow]: true,
                                    [classes.tableRowSelected]: person.isSelected
                                  }) }>
                                    <div className={ classes.tableItem }>
                                      <TruncatedTextWithTooltip
                                        fullText={ person.firstName } maxLength={ maxLengthText } />
                                    </div>
                                    <div className={ classes.tableItem }>
                                      <TruncatedTextWithTooltip
                                        fullText={ person.lastName } maxLength={ maxLengthText } />
                                    </div>
                                    <div className={ classes.tableItem }>
                                      <TruncatedTextWithTooltip
                                        fullText={ person.profileId } maxLength={ maxLengthText } />
                                    </div>
                                    <div className={ classes.tableItem }><TruncatedTextWithTooltip
                                      fullText={ person.email  || '' } maxLength={ maxLengthText } />
                                    </div>
                                    <div className={ classes.actionItem }> { person.isSelected ? (<CheckedIcon />) : (<PlusIcon id={ "people" }
                                      onClick={ onPeopleSelect(person) } />) }
                                    </div>
                                  </div>
                                )) }
                            </div>
                          </div>
                        </div>
                      ) }
                    </Tabs.Panel>

                    <Tabs.Panel value="groups" className={ classes.groupTabInner }>

                      <TextInput
                        name="selectedGroups"
                        placeholder="Start typing a people group name"
                        icon={ <SearchIcon /> }
                        onKeyDown={ onDeliveryEntitiesSelected } />
                      <div className={ cx({ [classes.pillContainer]: searchedDeliveryEntities.groups.length !== 0 }) }>
                        { searchedDeliveryEntities.groups.map(searchedGroup => (
                          <Badge key={ searchedGroup._id } size="xl" variant={ searchedGroup?.isSelected ? "filled" : "outline" }
                            rightSection={ <ActionIcon size={ 'sm' }
                              onClick={ searchedGroup?.isSelected ?
                                onRemoveGroup(searchedGroup) : onAddGroup(searchedGroup) }>
                              { searchedGroup?.isSelected ? <CloseIcon /> : <PlusIcon /> }
                            </ActionIcon> }>
                            { `${searchedGroup.name} (${searchedGroup.profileCount})` }
                          </Badge>
                        )) }
                      </div>
                    </Tabs.Panel>
                  </Tabs>
                </div>
              </Collapse>
            </div>

          </Radio.Group>
        </LocalErrorBoundary>
      </MassCommsSection>
      <MassCommsSection>
        <LocalErrorBoundary>
          <RecipientsListView />
        </LocalErrorBoundary>
      </MassCommsSection>
    </div>
  );
};

export default SelectRecipientsPageComponent;
