import { createStyles } from "@mantine/core";

export const useGeofencePOILocationLayerStyles = createStyles((theme) => ({
  popup: {
    color: 'white',

    '& > .mapboxgl-popup-content': {
      backgroundColor: 'transparent',
      padding: '16px 8px',

      '& > .mapboxgl-popup-close-button': {
        color: 'white'
      }
    },
    '& > .mapboxgl-popup-tip': {
      border: 'none'
    }
  },
  popupContentWrapper: {
    color: theme.white,
    fontFamily:theme.fontFamily,
    maxWidth: '200px'
  },
  siteName: {
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.other.lh.l,
    fontWeight: theme.other.fw.bold
  },
  location: {
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.other.lh.l,
    marginTop: '2px'
  }
}));
