import { createModel } from "@rematch/core";

import { RootModel } from "@/core/store";

import { drawerReducers } from './data-options.reducers';
import { drawerState } from './data-options.state';

export const dataOptions = createModel<RootModel>()({
  state: drawerState,
  reducers: drawerReducers
});