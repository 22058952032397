import mapboxgl from "mapbox-gl";
import { FC, useContext, useEffect } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import useLayerListener from "@/common/hooks/useLayerListener";
import { Dispatch, RootState } from "@/core/store";
import { fitToViewportBounds } from "@/tenant-context/common/util/map";
import { PeopleContext } from "@/tenant-context/visualisation-people/context/People.context";
import { BreadcrumbCollection } from "@/tenant-context/visualisation-people/types/people.types";

import PeopleBreadcrumbLayer from "./PeopleBreadcrumbLayer.component";


const PeopleBreadcrumbLayerContainer: FC = () => {
  const {
    handlePersonLocationHover
  } = useContext(PeopleContext);

  const { current: map } = useMap();

  const bottomDrawer = useSelector((state: RootState) => state.drawer.currentlyOpenBottomDrawerId);

  const breadcrumbs: BreadcrumbCollection = useSelector(
    (state: RootState) => state.peopleBreadcrumbs.geoData
  ) as BreadcrumbCollection;

  const linesBetweenCrumbs = useSelector(
    (state: RootState) => state.peopleBreadcrumbs.linesBetweenCrumbs
  );

  const currentPersonAssetId = useSelector(
    (state: RootState) => state.peopleBreadcrumbs.currentPersonAssetId
  );

  const isLayerEnabled = useSelector(
    (state: RootState) => state.peopleBreadcrumbs.isEnabled
  );

  const minRankThreshold = useSelector(
    (state: RootState) => state.rankingSettings.probabilityThresholdMin
  );

  const maxRankThreshold = useSelector(
    (state: RootState) => state.rankingSettings.probabilityThresholdMax
  );

  const isMinRankThresholdApplied = useSelector(
    (state: RootState) => state.rankingSettings.isApplyProbabilityThreshold
  );

  const disabledAdapters = useSelector((state: RootState) => state.rankingSettings.disabledAdapters);

  const {
    peopleBreadcrumbs: {
      loadPersonBreadcrumbs
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    if (!currentPersonAssetId) {
      // TODO clear data
      return;
    }

    loadPersonBreadcrumbs(currentPersonAssetId);
  }, [
    currentPersonAssetId,
    loadPersonBreadcrumbs
  ]);

  useEffect(() => {
    if (!map || breadcrumbs?.features?.length < 2 || !isLayerEnabled) {
      return;
    }

    const coords = breadcrumbs.features.map((feature) =>
      feature.geometry.coordinates as [number, number]);

    const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);
    coords.forEach((coordinates) => bounds.extend(coordinates));

    fitToViewportBounds(map, bounds, {
      bottom: true,
      right: true
    });
  }, [breadcrumbs, map, isLayerEnabled, bottomDrawer]);


  // People layer hover handler
  useLayerListener(
    'mouseenter',
    'r__possible-locations-secondary',
    handlePersonLocationHover
  );

  return (
    <PeopleBreadcrumbLayer
      locations={ breadcrumbs }
      isEnabled={ false } // isLayerEnabled
      linesBetweenCrumbs={ linesBetweenCrumbs }
      disabledAdapters={ disabledAdapters }
      minRankThreshold={ minRankThreshold }
      maxRankThreshold={ maxRankThreshold }
      isMinRankThresholdApplied={ isMinRankThresholdApplied }
    />
  );
};

export default PeopleBreadcrumbLayerContainer;
