import { FeatureCollection } from "geojson";
import { FC } from "react";
import { Layer, Source } from "react-map-gl";

type Props = {
  geoData: FeatureCollection[] | undefined
  isItineararyOnMap: boolean
}

const PeopleTravelLoactionLayerComponent: FC<Props> = ({
  geoData,
  isItineararyOnMap
}: Props) => {

  if (!isItineararyOnMap) {
    return null;
  }

  return (
    <>
      { geoData?.map((geoDataItem, index) => (
        <Source
          key={ `r__PeopleTravelLocationSource-${index}` }
          id={ `r__PeopleTravelLocationSource-${index}` }
          type="geojson"
          tolerance={ 0 }
          data={ geoDataItem }
        >
          <Layer
            key={ `travel-itenary-layer-${index}` }
            id={ `travel-itenary-layer-${index}` }
            type='symbol'
            source={ `r__PeopleTravelLocationSource-${index}` }
            layout={ {
              'icon-image': ['get', 'icon'],
              'icon-size': 0.2
            } }
          />
        </Source>
      )) }
    </>
  );
};

export default PeopleTravelLoactionLayerComponent;
