import { FC } from "react";

import ChecklistRowTemplateComponent
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/ChecklistRowTemplate/ChecklistRowTemplate.component";
import {
  ChecklistItem
} from "@/platform-context/control-icm-management/types/CaseTypes/caseTypeTeamsAndRoles";

type  Props = {
    row: ChecklistItem
    isAuthorizedToCreateOrEdit: boolean
    onDeleteIconClicked: (checklistItemId: string) => void
    onEditIconClicked: (row: ChecklistItem) => void
}

const ChecklistRowTemplate: FC<Props> = ({
  row,
  isAuthorizedToCreateOrEdit,
  onEditIconClicked,
  onDeleteIconClicked
}) => {

  return (
    <ChecklistRowTemplateComponent
      row={ row }
      isAuthorizedToCreateOrEdit={ isAuthorizedToCreateOrEdit }
      onDeleteIconClicked={ onDeleteIconClicked }
      onEditIconClicked={ onEditIconClicked }
    />
  );
};

export default ChecklistRowTemplate;