import {
  ActionIcon,
  Checkbox,
  Flex,
  Stack,
  useMantineTheme
} from "@mantine/core";
import { FC } from "react";

import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { ReactComponent as SliderSimple } from "@/common/icons/sliders-simple.svg";
import { useMapLayersDrawerContext } from "@/tenant-context/control-risk-config/context/MapLayersDrawer.context";

import { mainLayers } from "./utils";

type Props = {
  disabledLayers: string[];
  onLayerToggle: React.ChangeEventHandler<HTMLInputElement>;
  counters: Record<string, number>;
};

const BusinessDataLayersComponent: FC<Props> = ({
  disabledLayers,
  onLayerToggle,
  counters
}) => {
  const theme = useMantineTheme();
  const { changeDrawerPage } = useMapLayersDrawerContext();
  return (
    <Stack spacing="md" mt={ 24 } ml={ 32 }>
      { mainLayers.map(({ type, label }) => (
        <Flex key={ type } gap={ 8 } justify="space-between">
          <Checkbox
            value={ type }
            label={ label + (counters[type] ? ` (${counters[type]})` : "") }
            onChange={ onLayerToggle }
            checked={ !disabledLayers.includes(type) }
          />
          { type === ToggleableLayerType.People && (
            <ActionIcon
              size="sm"
              w={ 48 }
              ml="auto"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => changeDrawerPage(ToggleableLayerType.People) }
            >
              <SliderSimple style={ { fill: theme.colors.blue[4] } } />
            </ActionIcon>
          ) }
        </Flex>
      )) }
    </Stack>
  );
};

export default BusinessDataLayersComponent;
