import { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { Dispatch, RootState } from "@/core/store";
import ProfileEmergencyContactTab
  from "@/tenant-context/control-profile/components/tabs/ProfileEmergencyContactTab/ProfileEmergencyContactTab.component";
import { ReferenceType } from "@/tenant-context/control-profile/types/profile";

const ProfilePersonalContactTabContainer: FC = () => {
  const {
    profile: {
      loadEmergencyContacts,
      getProfileReferenceData,
      isLoggedInProfileId
    }
  } = useDispatch<Dispatch>();

  const params = useParams();

  const emergencyContacts = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ profile: { emergencyContacts } }: RootState) => emergencyContacts
  );

  const handleOwnProfileCheck = useCallback((): boolean => {
    return isLoggedInProfileId(params.userId);
  }, [isLoggedInProfileId, params.userId]);

  useEffect(() => {
    getProfileReferenceData(ReferenceType.TITLES);
    getProfileReferenceData(ReferenceType.RELATIONSHIP);
    getProfileReferenceData(ReferenceType.EMERGENCY_CONTACT_TYPE);
    getProfileReferenceData(ReferenceType.BUSINESS_CONTACT_RELATIONSHIP);
    loadEmergencyContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadEmergencyContacts]);

  useEffect(() => {
    getProfileReferenceData(ReferenceType.EMERGENCY_CONTACT_TYPE);
    getProfileReferenceData(ReferenceType.RELATIONSHIP);
    getProfileReferenceData(ReferenceType.BUSINESS_CONTACT_RELATIONSHIP);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileEmergencyContactTab
      emergencyContacts={ emergencyContacts }
      isOwnProfile={ handleOwnProfileCheck }
    />
  );
};

export default ProfilePersonalContactTabContainer;
