import { FileWithPath } from "@mantine/dropzone";
import { useDisclosure } from "@mantine/hooks";
import { Editor } from "@tinymce/tinymce-react";
import { FC, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { FileRejection } from "react-dropzone";
import { FieldValues } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";

import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import { EmailSettingsConfig } from "@/tenant-context/control-mass-comms/types/MassComms.types";
import { ProfileDocumentFile } from "@/tenant-context/control-profile/types/profile";

import EmailConfigurationComponent from "./EmailConfiguration.component";

export type Props = {
  isInTemplateCreationFlow: boolean
}

const EmailConfiguration: FC<Props> = ({
  isInTemplateCreationFlow
}) => {

  const emailContent = useSelector((state: RootState) => state.massComms.emailContent);
  const emailSettingsConfig = useSelector((state: RootState) => state.massComms.emailSettingsConfig);
  const currentSelectedTemplate = useSelector((state: RootState) => state.massComms.currentSelectedTemplate);

  const [isContentLoading, setIsContentLoading] = useState<boolean>(true);

  const {
    massComms: {
      SET_EMAIL_CONTENT,
      uploadEmailAttachment,
      removeEmailAttachment,
      removeBase64EmailAttachment,
      SET_EMAIL_SETTINGS_CONFIG
    }
  } = useDispatch<Dispatch>();

  const [opened, { open, close }] = useDisclosure(false);
  const editorRef = useRef<Editor>(null);

  const uploadRef = useRef<() => void>(null);

  useEffect(() => {
    if (!editorRef || !editorRef.current) {
      return;
    }
    
    const currentContent = editorRef.current.editor?.getContent();

    if (currentContent !== emailContent.body) {
      editorRef.current.editor?.setContent(emailContent?.body || "");
    }
  }, [ emailContent.body ]);

  const handleEditorInit = useCallback(()=>{
    setTimeout(()=>{
      if(editorRef.current?.editor){
        editorRef.current.editor?.setContent(emailContent?.body || "");
      }
    }, 0);
    setIsContentLoading(false);
  }, [emailContent?.body]);

  const handleEmailSubjectChange = useCallback((subject: FormEvent<HTMLInputElement>) => {
    SET_EMAIL_CONTENT({
      ...emailContent,
      subject: (subject.target as HTMLInputElement).value
    });
  }, [ SET_EMAIL_CONTENT, emailContent ]);

  const handleEmailContentChange = useDebouncedCallback((contentBody: string): void => {
    SET_EMAIL_CONTENT({
      ...emailContent,
      body: contentBody
    });
  }, 1000);
  
  const onNewAttachmentSelected = useCallback((files: FileWithPath[]) => {
    files.forEach(e => uploadEmailAttachment(e));
  }, [uploadEmailAttachment]);

  const handleRemoveAttachment = useCallback((file: FileWithPath | ProfileDocumentFile) => {
    removeEmailAttachment(file as FileWithPath);
  }, [removeEmailAttachment]);
  
  const handleDownloadAttachment = useCallback((file: ProfileDocumentFile) => {
    window.open(file.downloadURL, '_blank');
  }, []);

  const handleSettingsSave = useCallback((data: FieldValues) => {
    SET_EMAIL_SETTINGS_CONFIG(data as EmailSettingsConfig);
  }, [SET_EMAIL_SETTINGS_CONFIG]);

  const handleRejectedFiles = useCallback((rejectedFiles: FileRejection[]) => {
    rejectedFiles.forEach((rejection) => {
      const [error] = rejection.errors;
      const errorMessage = error.code === 'file-too-large'
        ? `File is larger than the maximum allowed size`
        : error.message;


      showNotification({
        title: `Error in ${rejection.file.name}`,
        message: errorMessage,
        color: 'error'
      });
    });
  }, []);

  return (
    <EmailConfigurationComponent
      editorRef={ editorRef }
      emailContent={ emailContent }
      onEmailSubjectChanged={ handleEmailSubjectChange }
      onEmailContentChanged={ handleEmailContentChange }
      isSettingsModalOpen={ opened }
      onSettingsModalClose={ close }
      onSettingsModalOpen={ open }
      onNewAttachmentSelected={ onNewAttachmentSelected }
      uploadRef={ uploadRef }
      onRemoveAttachment={ handleRemoveAttachment }
      onRemoveBase64Attachment = { removeBase64EmailAttachment }
      onDownloadAttachment={ handleDownloadAttachment }
      onRejectedFiles={ handleRejectedFiles }
      onSettingsSave={ handleSettingsSave }
      emailSettings={ emailSettingsConfig }
      isInTemplateUsageFlow={ isInTemplateCreationFlow || !!currentSelectedTemplate }
      onEditorInit = { handleEditorInit }
      isContentLoading = { isContentLoading }
    />
  );
};

export default EmailConfiguration;
