import { useThrottle } from "@react-hook/throttle";
import type { MapLayerEventType } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { MapRef, useMap } from "react-map-gl";

export default function useLayerListener<T extends keyof MapLayerEventType>(
  type: T,
  layerIdOrIds: string | Array<string>,
  listener: (ev: mapboxgl.MapLayerEventType[T] & mapboxgl.EventData) => void,
  externalMap?: MapRef
) {
  const { current: currentMap } = useMap();

  const map = externalMap ?? currentMap;

  const layerIds = useMemo(
    () => Array.from(new Set(
      Array.isArray(layerIdOrIds)
        ? layerIdOrIds
        : [layerIdOrIds]
    )),
    [layerIdOrIds]
  );

  // Optimization: throttle set state to prevent a lot of renders on `map.render` event
  // without this it would cause ~1000 rerenders
  const [loadedLayers, setLoadedLayers] = useThrottle<string[]>([], 12);

  useEffect(() => {
    if (!map) {
      return;
    }

    function handleMapRender(evt: mapboxgl.MapboxEvent<undefined> & mapboxgl.EventData) {
      // If layer is not loaded yet -> ignore map render
      if (!map || loadedLayers.length === layerIds.length) {
        return;
      }

      const newLoadedLayers = layerIds.filter(
        (id) => evt.target.getLayer(id)
      );

      // If layer is loaded -> note it down and stop waiting for it
      setLoadedLayers((prev) => {
        const newLayers = Array.from(new Set(newLoadedLayers));

        // More optimization under throttle: if arrays not changed, do not trigger a set state
        if (prev.toString() === newLayers.toString()) {
          return prev;
        }

        return newLayers;
      });

      if (newLoadedLayers.length === layerIds.length) {
        map.off('render', handleMapRender);
      }
    }

    map.on('render', handleMapRender);

    return () => {
      map.off('render', handleMapRender);
    };
  }, [layerIds, loadedLayers.length, map, setLoadedLayers]);

  // Add the actual handler
  // But only when the layer is rendered
  useEffect(() => {
    if (!map) {
      return;
    }

    // This wrapper ensures that any event is handled only once
    // The events will not fall through the layers
    // This prevents click events from firing on multiple layers at once
    const mapListener: (ev: MapLayerEventType[T] & mapboxgl.EventData) => void = (e) => {
      if (e.isAlreadyHandled) {
        return;
      }

      /* eslint-disable no-param-reassign */
      // @ts-expect-error adding custom prop
      e.isAlreadyHandled = true;
      /* eslint-enable no-param-reassign */

      listener(e);
    };

    loadedLayers.forEach((layerId) => {
      map.on(type, layerId, mapListener);
    });

    return () => {
      loadedLayers.forEach((layerId) => {
        map.off(type, layerId, mapListener);
      });
    };
  }, [
    map,
    layerIdOrIds,
    listener,
    type,
    loadedLayers
  ]);
}
