import { RiskReportProvider } from '@/tenant-context/control-reports/types/risk-reports-common';

export function getRiskLevelValueByRiskLevel(riskLevel?: string, provider?: RiskReportProvider) {
  if (provider === RiskReportProvider.HEALIX) {
    switch (riskLevel?.toLowerCase()) {
    case 'minimal':
      return 20;
    case 'low':
      return 40;
    case 'moderate':
      return 60;
    case 'high':
      return 80;
    case 'extreme':
      return 100;
    default:
      return 0;
    }
  }

  switch (riskLevel?.toLowerCase()) {
  case 'low':
    return 25;
  case 'medium':
    return 50;
  case 'high':
    return 75;
  case 'extreme':
    return 100;
  default:
    return 0;
  }
}

export function getRiskColorByRiskLevel(riskLevel?: string, provider?: RiskReportProvider) {
  if (provider === RiskReportProvider.HEALIX) {
    switch (riskLevel?.toLowerCase()) {
    case 'minimal':
      return '#4EE6BD' as string;
    case 'low':
      return '#24CA45' as string;
    case 'moderate':
      return '#E79A01' as string;
    case 'high':
      return '#FF6300' as string;
    case 'extreme':
      return '#FF0004' as string;
    default:
      return 'gray' as string;
    }
  }

  switch (riskLevel?.toLowerCase()) {
  case 'low':
  case 'good':
    return '#24CA45' as string;
  case 'medium':
    return '#E79A01' as string;
  case 'high':
    return '#FF6300' as string;
  case 'extreme':
    return '#FF0004' as string;
  default:
    return 'gray' as string;
  }
}

export function getRatingValueByRatingLevel(rating?: string) {
  switch (rating?.toLowerCase()) {
  case 'excellent':
    return 4;
  case 'good':
    return 3;
  case 'mediocre':
    return 2;
  case 'poor':
    return 1;
  default:
    return 0;
  }
}

export function getStartRatingForEGMPV(EGMPVValue: string) {
  switch (EGMPVValue.toLowerCase()) {
  case 'excellent':
    return 5;
  case 'good':
    return 4;
  case 'moderate':
    return 3;
  case 'very poor':
    return 2;
  case 'poor':
    return 1;
  default:
    return 0;
  }
}

export function kebabCaseToTitleCase(kebabCase?: string) {
  return kebabCase?.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function snakeCaseToTitleCase(snakeCase?: string) {
  return snakeCase?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function camelCaseToTitleCase(camelCase?: string) {
  return camelCase?.split(/(?=[A-Z])/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function uppercaseKebabToCapitalizedWords(input: string): string {
  return input
    .toLowerCase()
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
