import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

type Props = {
  backgroundColor?: string,
  color?: string,
  isDropdownBox: boolean,
  isDropdownExpanded: boolean
}

export const useMapTopInfoBoxStyles = createStyles((theme: MantineTheme, props: Props) => ({
  infoBox: {
    all: 'unset',
    display: "flex",
    boxSizing: 'border-box',
    boxShadow: "0 1px 8px rgba(0, 0, 0, 0.05)",
    padding: 8,
    width: "230px",
    minHeight: '66px',
    maxHeight: "66px",
    cursor: "pointer",
    backgroundColor: theme.colors.neutral[8],
    border: `1px solid ${theme.colors.neutral[6]}`,


    '&:hover': {
      backgroundColor: theme.colors.neutral[9] + '!important'
    }
  },

  infoDiv: {
    display: 'flex',
    flex: 1,
    gap: 8,
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative'
  },

  title: {
    color: theme.other.semantic.label[3],
    letterSpacing: '0.5px',
    fontFamily:theme.fontFamily,
    fontSize: theme.fontSizes.xs,
    lineHeight: "18px",
    textTransform: "uppercase"
  },

  value: {
    color: theme.other.semantic.label[1],
    fontSize: theme.fontSizes.md,
    fontFamily: theme.fontFamily,
    width: '180px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  dropdownIconWrapper: {
    fontFamily: theme.fontFamily,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },

  dropdownArrowIcon: {
    transform: props.isDropdownExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: 'transform var(--default-transition-speed)'
  },

  musterCount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '16px',
    height: '16px',
    borderRadius: '100%',
    backgroundColor: theme.colors.primary[6],
    lineHeight: theme.other.lh.s,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[9],
    fontWeight: theme.other.fw.bold,
    paddingTop: '3px',
    marginRight: '4px'
  }
}));
