import area from '@turf/area';
import length from '@turf/length';
import { bbox, Feature, FeatureCollection, Polygon } from '@turf/turf';
import { BBox } from "geojson";
import { createContext, FC, useCallback, useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useSelector } from 'react-redux';

import useContextValue from "@/common/hooks/useContextValue";
import { RootState } from '@/core/store';
import useFeaturesInSideGeofence from '@/tenant-context/control-draw/hooks/useFeaturesInSideGeofence';
import { GeofenceProperty } from '@/tenant-context/control-draw/types/draw';

export type GeofenceVisualizeContextType = {
  currentVisibleGeoFences?: Feature<Polygon>,
  currentPopupCoordinates: [number, number]
  isShownGeofencePopup: boolean,
  popupProperties: GeofenceProperty[],
  geofenceVisualizeBoundingBox?: BBox,
  isNoFill?: boolean,
  withinGeofenceData: {
    locationCount: number;
    peopleLocationCount: number;
    siteLocationsCount: number;
  } | undefined
};

export const GeofenceVisualizeContext = createContext<GeofenceVisualizeContextType>({} as GeofenceVisualizeContextType);
export const GeofenceVisualizeContextProvider: FC = ({
  children
}) => {

  const {
    visibleGeofenceOnBigMap
  } = useSelector((state: RootState) => state.geofence);

  const { numberOfPointsWithinGeofence } = useFeaturesInSideGeofence();

  const customerLocationsGeoData = useSelector((state: RootState) => state.customerLocations.geoData);
  const peopleLocationGeoData = useSelector((state: RootState) => state.peopleLocations.geoData);
  const siteLocationsGeoData = useSelector((state: RootState) => state.siteLocations.geoData);

  const [currentVisibleGeoFences, setCurrentVisibleGeoFences] = useState<Feature>();
  const [coordinates, setCoordinates] = useState<[number, number]>([0, 0]);
  const [popupProperties, setPopupProperties] = useState<GeofenceProperty[]>([]);
  const [isShownGeofencePopup, setIsShownGeofencePopup] = useState<boolean>(false);
  const [boundingBox, setBoundingBox] = useState<BBox | undefined>();
  const [isNoFill, setNoFill] = useState(false);
  const [withinGeofenceData, setWithinGeofenceData] = useState<{
    locationCount: number;
    peopleLocationCount: number;
    siteLocationsCount: number;
  } | undefined>();

  const { current: map } = useMap();

  const roundOff = useCallback((input: number) => {
    return Math.round(input * 100) / 100;
  }, []);


  const calculatePopupLocation = useCallback((
    geoFence?: Feature | FeatureCollection
  ): [number, number] | null => {
    if (geoFence) {
      const geometry = (geoFence?.type === 'FeatureCollection') ? geoFence.features[0]?.geometry : geoFence?.geometry;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return (geometry?.type === 'Polygon') ? geometry.coordinates[0][0] : geometry.coordinates[0];
    }

    return null;
  }, []);

  useEffect(() => {
    if (visibleGeofenceOnBigMap?.geoJson){
      const geoJson = JSON.parse(visibleGeofenceOnBigMap?.geoJson);

      const geofenceFeatureCollection = geoJson as FeatureCollection;
      const geofence = geofenceFeatureCollection.features[0] as Feature<Polygon>;
      setCurrentVisibleGeoFences(geofence);

      const bbox_ = bbox(geofence) as [number, number, number, number];
      map?.fitBounds(bbox_, { padding: 200 });
      setBoundingBox(bbox_);
    } else {
      setCurrentVisibleGeoFences(undefined);
    }
  }, [map, visibleGeofenceOnBigMap]);

  useEffect(() => {
    const popupLocation = calculatePopupLocation(currentVisibleGeoFences);
    if (popupLocation !== null) {
      setCoordinates(popupLocation);
    }
  }, [calculatePopupLocation, currentVisibleGeoFences]);

  useEffect(() => {
    if (currentVisibleGeoFences) {
      setIsShownGeofencePopup(true);
    } else {
      setIsShownGeofencePopup(false);
    }
  }, [currentVisibleGeoFences]);

  useEffect(() => {
    const geofenceProperties: GeofenceProperty[] = [];
    if (currentVisibleGeoFences) {
      const { type } = currentVisibleGeoFences.geometry;

      if (type === 'LineString') {
        setNoFill(true);
      } else {
        setNoFill(false);
      }

      const lengthOfLine = length(currentVisibleGeoFences, { units: 'kilometers' });
      geofenceProperties.push({
        name: type === 'LineString' ? 'Length' : 'Perimeter',
        value: roundOff(lengthOfLine),
        unit: 'Km'
      });

      if (type === 'Polygon') {
        const areaOfGeofence = roundOff(area(currentVisibleGeoFences)/1000000);
        geofenceProperties.push({
          name: 'Area',
          value: areaOfGeofence,
          unit: 'Km2'
        });

        if (currentVisibleGeoFences?.properties?.radiusInKm) {
          const radius = currentVisibleGeoFences?.properties?.radiusInKm;
          geofenceProperties.push({
            name: 'Diameter',
            value: roundOff(radius * 2),
            unit: 'Km'
          });
        }
      }

      setPopupProperties(geofenceProperties);
    }
  }, [currentVisibleGeoFences, roundOff, visibleGeofenceOnBigMap]);

  useEffect(() => {
    if (currentVisibleGeoFences) {
      const inSideGeofenceData = {
        locationCount: 0,
        peopleLocationCount: 0,
        siteLocationsCount: 0
      };

      if (customerLocationsGeoData) {
        inSideGeofenceData.locationCount =
          numberOfPointsWithinGeofence(customerLocationsGeoData, currentVisibleGeoFences);
      }
      if (peopleLocationGeoData) {
        inSideGeofenceData.peopleLocationCount =
          numberOfPointsWithinGeofence(peopleLocationGeoData, currentVisibleGeoFences);
      }
      if (siteLocationsGeoData) {
        inSideGeofenceData.siteLocationsCount =
          numberOfPointsWithinGeofence(siteLocationsGeoData, currentVisibleGeoFences);
      }

      setWithinGeofenceData(inSideGeofenceData);
    }
  }, [
    currentVisibleGeoFences,
    customerLocationsGeoData,
    numberOfPointsWithinGeofence,
    peopleLocationGeoData,
    siteLocationsGeoData
  ]);

  return (
    <GeofenceVisualizeContext.Provider value={ useContextValue({
      currentVisibleGeoFences: currentVisibleGeoFences as Feature<Polygon>,
      currentPopupCoordinates: coordinates,
      isShownGeofencePopup: isShownGeofencePopup,
      popupProperties: popupProperties,
      geofenceVisualizeBoundingBox: boundingBox,
      isNoFill: isNoFill,
      withinGeofenceData: withinGeofenceData
    }) }>
      { children }
    </GeofenceVisualizeContext.Provider>
  );
};

export default GeofenceVisualizeContextProvider;
