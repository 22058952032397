import { Flex, Text } from '@mantine/core';
import { FC } from 'react';

import { ReactComponent as BetaTag } from "@/common/icons/beta-tag.svg";
import { usePageStyles } from '@/tenant-context/common/components/Page/Page.styles';

type Props = {
  heading: string;
  isBeta?: boolean;
}

const Page: FC<Props> = ({
  heading,
  isBeta,
  children
}) => {

  const { classes } = usePageStyles();

  return (
    <Flex direction="row" justify="space-between" align="center" className={ classes.headerRoot }>
      <Flex gap={ 12 }>{ isBeta && <BetaTag /> }<Text size={ 26 } weight={ 700 }>{ heading }</Text></Flex>
      <Flex>{ children }</Flex>
    </Flex>
  );
};

export default Page;
