import { FC, Fragment, ReactNode, useContext } from 'react';
import { IndexRouteProps, LayoutRouteProps, PathRouteProps } from 'react-router';

import usePermission from '@/common/hooks/usePermission';
import { RequiredPolicies } from '@/common/types/permission-control';
import UnauthorizedPageComponent from '@/core/components/UnauthorizedPage';
import { PoliciesContext } from '@/core/context/Policies.context';



type RouterProps = PathRouteProps | LayoutRouteProps | IndexRouteProps;

type PropsRouterPermissionDecorator =  {
  pagePolicies: RequiredPolicies,
  children: ReactNode
}

type Props = RouterProps & PropsRouterPermissionDecorator;

const ProtectedRouteElement: FC<Props> = ({
  pagePolicies, children
}) => {
  const {
    isPoliciesLoading
  } = useContext(PoliciesContext);

  const isAuthorized = usePermission([pagePolicies]);

  if (isAuthorized) {
    return (
      <Fragment>{ children }</Fragment>
    );
  } else {
    return (
      <UnauthorizedPageComponent isLoadingMode={ isPoliciesLoading }/>
    );
  }
};

export default ProtectedRouteElement;
