import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Divider, Tooltip } from "@mantine/core";
import dayjs from "dayjs";
import { FC, ReactNode, useCallback, useMemo } from "react";

import { ReactComponent as GlobeIcon } from "@/common/icons/globe.svg";
import { ReactComponent as SiteIcon } from "@/common/icons/location-site.svg";
import { useMiniProfileStyles } from "@/tenant-context/control-profile/components/ui/MiniProfile/MiniProfile.styles";
import VisitRequestTagLabel from "@/tenant-context/control-visitor-management/components/RequestTag/RequestTag.component";
import { VisitInfo, VisitRequestTag } from "@/tenant-context/control-visitor-management/types/visitorManagement";

type Props = {
  title?: string,
  visitData?: VisitInfo
}
const MiniProfileVisit: FC<Props> = ({
  title = '',
  visitData
}) => {

  const { classes } = useMiniProfileStyles(false);

  const dateRenderer = useCallback((utcTime: string, siteTime: string): ReactNode => {
    return (
      <Tooltip withinPortal label={ <div className={ classes.tooltipWrap }>
        <p><GlobeIcon /> UTC - { utcTime }</p>
        <p><SiteIcon /> Site - { siteTime }</p>
      </div> }>
        <Button className={ classes.tooltipBtn }
          variant="subtle"
          leftIcon={  <FontAwesomeIcon icon={ "circle-exclamation" } className={ classes.dateIcon }/> }>
          { siteTime }
        </Button>
      </Tooltip>
 
    );
  }, [classes.dateIcon, classes.tooltipBtn, classes.tooltipWrap]);

  const arrivalDateTime = useMemo(
    ()=> {
      if(!visitData){
        return null;
      }

      return dateRenderer(
        dayjs(new Date(visitData?.arrivalDateInUtc)).format("HH:mm ddd, DD MMM YYYY"),
        dayjs(new Date(visitData?.arrivalDateInSiteZone)).format("HH:mm ddd, DD MMM YYYY")
      );
    }
    , [dateRenderer, visitData]
  );

  const departureDateTime = useMemo(
    ()=> {
      if(!visitData){
        return null;
      }

      return dateRenderer(
        dayjs(new Date(visitData?.departureDateInUtc)).format("HH:mm ddd, DD MMM YYYY"),
        dayjs(new Date(visitData?.departureDateInSiteZone)).format("HH:mm ddd, DD MMM YYYY")
      );
    }
    , [dateRenderer, visitData]
  );

  if(!visitData?.requestId){
    return null;
  }

  return (
    <div className={ classes.visitorBlock }>
      <div className={ classes.visitorTitle }>{ title }</div>
      <Divider />
      <div className={ classes.visitorInfo }>
        <div className={ classes.visitorInfoRequestId }>{ visitData?.requestId }</div>
        <div >
          <div className={ classes.visitorInfoSubTitle }>Visting Location</div>
          <div className={ classes.visitorInfoText }>{ visitData?.visitingLocation }</div>
        </div>
        <div >
          <div className={ classes.visitorInfoSubTitle }>Arrival Date/Time - Site</div>
          <div className={ classes.visitorInfoText }>
            { arrivalDateTime }
          </div>
        </div>
        <div>
          <div className={ classes.visitorInfoSubTitle }>Departure Date/Time - Site</div>
          <div className={ classes.visitorInfoText }>
            { departureDateTime }
          </div>
        </div>
        <div>
          <div className={ classes.visitorInfoSubTitle }>Status</div>
          <div className={ classes.statusRow }>
            { visitData?.vip && (
              <VisitRequestTagLabel tag={ VisitRequestTag.VIP }/>
            ) }
         
            <VisitRequestTagLabel tag={ visitData?.visitStatus as VisitRequestTag }/>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default MiniProfileVisit;
