import { Select, TextInput } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as SearchIcon } from "@/common/icons/search-light.svg";
import { useImpactTabStyles } from "@/tenant-context/control-action-response-center/components/ImpactTab/ImpactTab.styles";
import { ArcImpactStatus } from "@/tenant-context/control-action-response-center/types/ARC.types";

type Props = {
  selectedImpactStatus: ArcImpactStatus | undefined;
  onSearchStringChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStatusFilterChange: (status: ArcImpactStatus | 'ALL') => void;
  onPersonTypeFilterChange: (personType: string) => void;
  onLocationTypeFilterChange: (locationType: string) => void;
  onResponseFilterChange: (response: string) => void;
}

const ImpactTabGridFiltersComponent: FC<Props> = ({
  selectedImpactStatus,
  onSearchStringChange,
  onStatusFilterChange,
  onPersonTypeFilterChange,
  onLocationTypeFilterChange,
  onResponseFilterChange
}) => {

  const { classes } = useImpactTabStyles();

  return (
    <div className={ classes.filterWrapper }>
      <div>
        <TextInput
          label='Search by name'
          placeholder="Search"
          onChange={ onSearchStringChange }
          icon={ <SearchIcon /> }
        />
      </div>

      <div className={ classes.dropdownFilterWrapper }>
        <Select
          label='Person Type'
          placeholder="Person Type"
          defaultValue={ 'ALL' }
          data={ [
            { value: 'ALL', label: 'All' },
            { value: 'EMPLOYEE', label: 'Employee' },
            { value: 'CONTRACTOR', label: 'Contractors' },
            { value: 'SUB-CONTRACTOR', label: 'Sub-Contractors' },
            { value: 'VISITOR', label: 'Visitors' }
          ] }
          onChange={ onPersonTypeFilterChange }
        />

        <Select
          label='Location Type'
          placeholder="Location Type"
          defaultValue={ 'ALL' }
          data={ [
            { value: 'ALL', label: 'All' },
            { value: 'Other - Country', label: 'Other - Country' },
            { value: 'Other - City', label: 'Other - City' },
            { value: 'Home Address', label: 'Home Address' },
            { value: 'Work Location', label: 'Work Location' },
            { value: 'Travel Itinerary', label: 'Travel Itinerary' },
            { value: 'Access Control', label: 'Access Control' },
            { value: 'Muster Control', label: 'Muster Control' },
            { value: 'Check In (Restrata APP)', label: 'Check In (Restrata APP)' },
            { value: 'GPS Location (Restrata APP)', label: 'GPS Location (Restrata APP)' }
          ] }
          onChange={ onLocationTypeFilterChange }
        />

        <Select
          label='Response'
          placeholder="Response"
          defaultValue={ 'ALL' }
          data={ [
            { value: 'ALL', label: 'All' },
            { value: 'YES', label: 'Yes' },
            { value: 'NO', label: 'NO' }
          ] }
          onChange={ onResponseFilterChange }
        />

        <Select
          label='Safety Status'
          placeholder="Safety Status"
          defaultValue={ 'ALL' }
          value={ selectedImpactStatus }
          onChange={ onStatusFilterChange }
          data={ [
            { value: 'ALL', label: 'All' },
            { value: 'NOT_SAFE', label: 'Not Safe' },
            { value: 'NO_RESPONSE', label: 'No Response' },
            { value: 'SAFE', label: 'Safe' },
            { value: 'OTHER', label: 'Other' }
          ] }
        />
      </div>
    </div>
  );
};

export default ImpactTabGridFiltersComponent;
