import { FC } from "react";

import { PersonalDetailsModalContextProvider } from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/PersonalDetailsModal/context/PersonalDetailsModal.context";
import PersonalDetailsModal from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/PersonalDetailsModal/PersonalDetailsModal.component";

interface Props{
  handleCancelConfirmation?: ()=> void
}

const PersonalDetailsModalContainer: FC<Props> = ({ handleCancelConfirmation }: Props) => {
  return (
    <PersonalDetailsModalContextProvider>
      <PersonalDetailsModal handleCancelConfirmation={ handleCancelConfirmation }/>
    </PersonalDetailsModalContextProvider>
  );
};

export default PersonalDetailsModalContainer;