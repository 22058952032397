import { Margin } from "@visx/xychart";
import { FC } from "react";

import TimeTravelControls from './TimeTravelControls.component';

type Props = {
  chartContentHeight: number,
  chartContentWidth: number,
  chartMargin: Margin,
  onForwardClick: () => void,
  onBackwardClick: () => void
  onForwardForDailyClick?: () => void,
  onBackwardForDailyClick?: () => void
};

const TimeTravelControlsContainer: FC<Props> = ({
  chartContentHeight,
  chartContentWidth,
  chartMargin,
  onForwardClick,
  onBackwardClick,
  onForwardForDailyClick,
  onBackwardForDailyClick
}) => {
  return (
    <TimeTravelControls
      chartContentHeight={ chartContentHeight }
      chartContentWidth={ chartContentWidth }
      chartMargin={ chartMargin }
      onForwardClick={ onForwardClick }
      onBackwardClick={ onBackwardClick }
      onForwardForDailyClick={ onForwardForDailyClick }
      onBackwardForDailyClick={ onBackwardForDailyClick }
    />
  );
};

export default TimeTravelControlsContainer;
