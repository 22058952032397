/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, TextInput } from "@mantine/core";
import { FC, useCallback, useContext, useEffect } from "react";

// import PhoneInput from 'react-phone-number-input';
import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import ModalControlGroup from "@/common/components/ModalControlGroup";
import PhoneInput from "@/common/components/PhoneInput";
import { EMAIL_REGEX_RFC_5322 } from "@/common/util/email";
import { capitalizeWord } from "@/common/util/format/string";
import {
  PersonalContactModalContext
} from "@/tenant-context/control-profile/components/modals/PersonalContactModal/PersonalContactModal.context";
import {
  usePersonalContactModalStyles
} from "@/tenant-context/control-profile/components/modals/PersonalContactModal/PersonalContactModal.style";
import ProfileLoadingOverlay from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay';

import 'react-phone-number-input/style.css';

export type PersonalContactModalFormData = {
  contact: string,
  isPrimary: boolean
};

const PersonalContactModal: FC = () => {
  const {
    onSubmit,
    formControls: { register, formState: { errors }, setValue, watch },
    sectionType,
    editableContact,
    mode,
    handleRemove
  } = useContext(PersonalContactModalContext);

  // TODO optimise
  const {
    contact
  } = watch();

  const setContact = useCallback(
    (value: string) => setValue('contact', value),
    [setValue]
  );

  const { classes } = usePersonalContactModalStyles();

  const sectionLabel = capitalizeWord(sectionType);
  const isEmail = sectionType.toLowerCase() === 'email';

  useEffect(() => {
    if (!editableContact) {
      return;
    }

    setTimeout(() => {
      setContact(editableContact.value);
    }, 0);
  }, [editableContact, setContact]);

  return (
    <form onSubmit={ onSubmit }>

      <ProfileLoadingOverlay />

      <div className={ classes.inputBlock }>
        { !isEmail && (
          <PhoneInput
            value={ contact || '' }
            isRequired={ true }
            placeholder="Enter phone number"
            error={ errors?.contact?.message }
            label={ sectionLabel }
            onChange={ setContact }
            registerData={ register('contact', addErrorMessages(sectionLabel, {
              required: true
            })) }
          />
        ) }

        { isEmail && (
          <TextInput
            value={ contact || '' }
            required={ true }
            label={ sectionLabel }
            error={ errors?.contact?.message }
            { ...register('contact', addErrorMessages(sectionLabel, {
              required: true,
              pattern: {
                value: EMAIL_REGEX_RFC_5322,
                message: 'Email format not recognised'
              }
            })) }
          />
        ) }

        <Checkbox
          label="Is Primary"
          defaultChecked={ editableContact ? editableContact.primary : false }
          { ...register('isPrimary') }
        />
      </div>

      <ModalControlGroup
        additionalLabel={ mode === 'edit' ? "Remove" : undefined }
        onAdditional={ mode === 'edit' ? handleRemove : undefined }
      />
    </form>
  );
};

export default PersonalContactModal;
