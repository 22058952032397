import { Skeleton } from "@mantine/core";
import { FC } from "react";

import { usePeopleLocationPopupStyles } from "@/tenant-context/visualisation-people/components/PeopleLocationPopup/PeopleLocationPopup.styles";


export const PeoplePopupSkeleton: FC = () => {
  const { classes: popupClasses } = usePeopleLocationPopupStyles();

  return (
    <div className={ popupClasses.loaderContainer }>
      <div className={ popupClasses.personDetailLoad }>
        <div className={ popupClasses.personPicLoad }>
          <Skeleton height={ 76 } />
        </div>
        <div className={ popupClasses.profileInfoLoad }>
          <Skeleton height={ 76 } />
        </div>
      </div>
      <div className={ popupClasses.personInfoLoad }>
        <div className={ popupClasses.infoItemLoad }>
          <Skeleton height={ 80 } />
        </div>
        <div className={ popupClasses.infoItemLoad }>
          <Skeleton height={ 80 } />
        </div>
        <div className={ popupClasses.infoItemLoad }>
          <Skeleton height={ 80 } />
        </div>
      </div>
      <div className={ popupClasses.locationInfoLoad }>
        <Skeleton height={ 108 } />
      </div>
      <div className={ popupClasses.locationTimerLoad }>
        <Skeleton height={ 55 } />
      </div>
    </div>
  );
};

export default PeoplePopupSkeleton;
