import { openModal } from '@mantine/modals';
import groupBy from 'lodash/groupBy';
import { FC, Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { capitalizeWord } from '@/common/util/format/string';
import { Dispatch, RootState, RootStateWithLoading } from '@/core/store';
import ProfileEmergencyContactsModal from '@/tenant-context/control-profile/components/modals/ProfileEmergencyContactsModal';
import { useProfileEmergencyContactTabStyles } from '@/tenant-context/control-profile/components/tabs/ProfileEmergencyContactTab/ProfileEmergencyContactTab.style';
import { useProfilePersonalContactTabStyles } from "@/tenant-context/control-profile/components/tabs/ProfilePersonalContactTab/ProfilePersonalContactTab.style";
import EditButton from '@/tenant-context/control-profile/components/ui/EditButton';
import PhoneWithFlag from '@/tenant-context/control-profile/components/ui/PhoneWithFlag';
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock";
import ProfileKeyValue from "@/tenant-context/control-profile/components/ui/ProfileKeyValue";
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock";
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';
import { ProfileEmergencyContact } from "@/tenant-context/control-profile/types/profile";

type Props = {
  isEditable?: boolean
};

const renderAddress = ({
  line1,
  line2,
  line3,
  city,
  country,
  // TODO figure out
  region: _region, // !?
  postCode
}: ProfileEmergencyContact) => {
  const lines = [
    line1,
    line2,
    line3,
    postCode,
    city,
    country
  ].filter(Boolean);

  return (
    <>
      { lines.map((line, index) => (
        <Fragment key={ index }>
          { line }
          <br />
        </Fragment>
      )) }
    </>
  );
};

const ProfileEmergencyContactTabSections: FC<Props> = ({
  isEditable = true
}) => {
  const {
    profile: {
      loadEmergencyContacts,
      isLoggedInProfileId
    }
  } = useDispatch<Dispatch>();

  const profileId = useSelector(
    ({ profile: { id } }: RootState) => id
  );

  const isEmergencyContactsLoading = useSelector(
    (state: RootStateWithLoading) => state.loading.effects.profile.loadEmergencyContacts
  );

  const emergencyContacts = useSelector(
    ({ profile: { emergencyContacts: e } }: RootState) => e
  );

  const params = useParams();

  useEffect(() => {
    if (!isEmergencyContactsLoading && !emergencyContacts) {
      loadEmergencyContacts();
    }
  }, [emergencyContacts, isEmergencyContactsLoading, loadEmergencyContacts, profileId]);

  const gtc = useCallback(
    (mc) => `repeat(${mc - 1}, 1fr) ${isEditable ? '0.5fr' : ''}`,
    [isEditable]
  );

  const { classes } = useProfilePersonalContactTabStyles();
  const { classes: { phoneList } } = useProfileEmergencyContactTabStyles();

  const openEditModal = useCallback((addressId: string) => {
    if (!emergencyContacts) {
      return;
    }

    const contact = emergencyContacts.items.find(({ _id }) => addressId === _id);

    if (!contact) {
      return;
    }

    openModal({
      title: `Edit ${capitalizeWord(contact.type.replace('Contact', ''))} Contact`,
      children: (
        <ProfileEmergencyContactsModal
          mode='edit'
          // TODO
          editableContact={ contact }
        />
      ),
      closeOnClickOutside: false
    });
  }, [emergencyContacts]);

  const renderEdit = useCallback((addressId: string) => (
    <div className={ classes.textRight }>
      <EditButton
      // eslint-disable-next-line react/jsx-no-bind
        onClick={ () => openEditModal(addressId) }
        requiredPolicies={ isLoggedInProfileId(params.userId) ? [] : 
          ProfileRequiredPoliciesConfig.EDIT_EMERGENCY_CONTACT }
      />
    </div>
   
  ), [classes.textRight, isLoggedInProfileId, openEditModal, params.userId]);

  if (!emergencyContacts) {
    return null;
  }

  const sections = groupBy(emergencyContacts.items, 'type');

  return (
    <div>
      { Object.entries(sections).map(([key, section]) => (
        <section className={ classes.section } key={ key }>
          { /* TODO size */ }
          <h6 className={ classes.sectionHeading }>{ key }</h6>

          <ul>
            <ProfileBlock
              mode='legend-header'
            >
              <ProfileKeyValueBlock
                maxColumns={ 7 }
                gtc={ gtc }
              >
                <ProfileKeyValue
                  mode='legend-header'
                  name={ 'RELATIONSHIP' }
                  value={ [
                    'FIRST NAME',
                    'LAST NAME',
                    'ADDRESS',
                    'PHONE',
                    'EMAIL'
                  ] }
                />
              </ProfileKeyValueBlock>
            </ProfileBlock>

            { section.map((contact) => (
              <ProfileBlock key={ contact._id } element='li'>
                <ProfileKeyValueBlock
                  maxColumns={ 7 }
                  // eslint-disable-next-line react/jsx-no-bind
                  gtc={ gtc }
                >
                  <ProfileKeyValue
                    name={ contact.relationship }
                    value={ [
                      contact.firstName,
                      contact.lastName,
                      renderAddress(contact),
                      <div key='ok' className={ phoneList }>
                        { contact.phoneList?.map((phone, index) => (
                          <PhoneWithFlag
                            key={ index+phone }
                            phone={ phone }
                          />
                        )) }
                      </div>,
                      contact.email,
                      isEditable && renderEdit(contact._id)
                    ] }
                  />
                </ProfileKeyValueBlock>
              </ProfileBlock>
            )) }
          </ul>
        </section>
      )) }
    </div>
  );
};

export default ProfileEmergencyContactTabSections;
