import { ChangeEvent, FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";

import TextToSpeechComponent from "./TextToSpeech.component";

const TextToSpeech: FC = () => {

  const { audioContent } = useSelector((state: RootState) => state.massComms.voiceContent);

  const {
    massComms: {
      SET_VOICE_CONTENT
    }
  } = useDispatch<Dispatch>();

  const handleTextUpdate = useCallback((event: ChangeEvent<HTMLTextAreaElement> | string | null) => {
    if (!event) {
      return;
    }

    if (typeof event === 'string') {
      SET_VOICE_CONTENT(event);
    } else {
      SET_VOICE_CONTENT(event.target.value);
    }
    
  }, [ SET_VOICE_CONTENT ]);

  return (
    <TextToSpeechComponent
      audioContent={ audioContent }
      onTextChanged={ handleTextUpdate }
    />
  );
};

export default TextToSpeech;
