import { FileWithPath } from "@mantine/dropzone";
import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";
import { TenantDetails } from "@/tenant-context/navigation/types/user";

import { deleteAccessCard, deleteServiceConfiguration, getAccessCardData, getAccessCardSitesData, getServiceConfigurationData, getServiceConfigurationItem, getSharingData, saveServiceConfiguration, submitAccessCardSitesData, submitSharedData, updateServiceConfiguration, uploadAccessCardsFile } from "../api/serviceConfiguration";
import { AccessCard, AccessCardProvider, AccessCardSharing, AccessCardSites, ServiceConfiguration, ServiceConfigurationItem } from "../types/serviceConfiguration";

type ServiceConfigurationState = {
    serviceConfigurationData?: PaginatedResult<ServiceConfiguration>
    selectedServiceConfigurationItem?: ServiceConfigurationItem,
    accessCardData?: PaginatedResult<AccessCard>,
    uploadAccessCardsPercentage?: number,
    uploadAccessCardsFileUploadingStatus?: 'in-progress' | 'successful' | 'failed',
    uploadAccessCardsFailedMessage?: string,
    uploadAccessCardsSuccessMessage?: string,
    sharingData?: AccessCardSharing,
    sitesData?: AccessCardSites,
    tenantDetails?: TenantDetails,
    sitesLoading: boolean,
    sharingLoading: boolean
};

const serviceConfigurationDataModel = {
  name: 'serviceConfiguration',
  state: {
    serviceConfigurationData: undefined,
    selectedServiceConfigurationItem: undefined,
    accessCardData: undefined,
    uploadAccessCardsPercentage: undefined,
    uploadAccessCardsFileUploadingStatus: undefined,
    uploadAccessCardsFailedMessage: undefined,
    uploadAccessCardsSuccessMessage: undefined,
    sharingData: undefined,
    tenantDetails: undefined,
    sitesLoading: false,
    sharingLoading: false
  } as ServiceConfigurationState,
  reducers: {
    SET_SERVICE_CONFIGURATION: (state: ServiceConfigurationState, serviceConfiguration: ServiceConfigurationState['serviceConfigurationData']) => ({
      ...state,
      serviceConfiguration
    }),
    SET_ACCESS_CARD: (state: ServiceConfigurationState, accessCardData: ServiceConfigurationState['accessCardData']) => ({
      ...state,
      accessCardData
    }),
    SET_SELECTED_SERVICE_CONFIGURATION_ITEM: (state: ServiceConfigurationState, selectedServiceConfigurationItem: ServiceConfigurationState['selectedServiceConfigurationItem']) => ({
      ...state,
      selectedServiceConfigurationItem
    }),
    RESET_SELECTED_SERVICE_CONFIGURATION_ITEM: (state: ServiceConfigurationState) => ({
      ...state,
      selectedServiceConfigurationItem: undefined,
      serviceConfigurationsList: []
    }),
    SET_UPLOAD_ACCESS_CARDS_PERCENTAGE: (state: ServiceConfigurationState, uploadAccessCardsPercentage: ServiceConfigurationState['uploadAccessCardsPercentage']) => ({
      ...state,
      uploadAccessCardsPercentage
    }),
    SET_UPLOAD_ACCESS_CARDS_STATUS: (state: ServiceConfigurationState, uploadAccessCardsFileUploadingStatus: ServiceConfigurationState['uploadAccessCardsFileUploadingStatus']) => ({
      ...state,
      uploadAccessCardsFileUploadingStatus
    }),
    SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE: (state: ServiceConfigurationState, uploadAccessCardsFailedMessage: ServiceConfigurationState['uploadAccessCardsFailedMessage']) => ({
      ...state,
      uploadAccessCardsFailedMessage
    }),
    SET_UPLOAD_ACCESS_CARDS_SUCCESS_MESSAGE: (state: ServiceConfigurationState, uploadAccessCardsSuccessMessage: ServiceConfigurationState['uploadAccessCardsSuccessMessage']) => ({
      ...state,
      uploadAccessCardsSuccessMessage
    }),
    SET_SHARED: (state: ServiceConfigurationState, sharingData: ServiceConfigurationState['sharingData']) => ({
      ...state,
      sharingData
    }),
    RESET_SHARED_DATA: (state: ServiceConfigurationState) => ({
      ...state,
      sharingData: undefined
    }),
    SET_SITES: (state: ServiceConfigurationState, sitesData: ServiceConfigurationState['sitesData']) => ({
      ...state,
      sitesData
    }),
    RESET_SITES: (state: ServiceConfigurationState) => ({
      ...state,
      sitesData: undefined
    }),
    SET_TENANT_DETAILS: (state: ServiceConfigurationState, tenantDetails: ServiceConfigurationState['tenantDetails']) => ({
      ...state,
      tenantDetails
    }),
    TOGGLE_SITES_LOADING: (state: ServiceConfigurationState) => ({
      ...state,
      sitesLoading: !state.sitesLoading
    }),
    TOGGLE_SHARING_LOADING: (state: ServiceConfigurationState) => ({
      ...state,
      sharingLoading: !state.sharingLoading
    })
  },
  effects: (dispatch: Dispatch) => ({
    async getServiceConfigurationData(payload: {
      gridParams: GridParams,
  }, state: RootState): Promise<PaginatedResult<ServiceConfiguration>> {

      const query = payload.gridParams.searchQuery;
      if(query){
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `name LIKE %27%25${query}%25%27 OR type LIKE %27%25${query}%25%27 OR provider LIKE %27%25${query}%25%27`;
      }
      
      const serviceConfigurationData = await getServiceConfigurationData(
        state.serviceConfiguration.tenantDetails?._id || state.commonData.tenantId,
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.serviceConfiguration.SET_SERVICE_CONFIGURATION(serviceConfigurationData);
      return serviceConfigurationData;
    },
    async getAccessCardData(payload: {
      gridParams: GridParams,
  }, state: RootState): Promise<PaginatedResult<ServiceConfiguration>> {

      const query = payload.gridParams.searchQuery;
      // eslint-disable-next-line fp/no-let
      let searchQuery = "";
      if(query){
        searchQuery = `(manufactureId LIKE %27%25${query}%25%27 OR accessCardType LIKE %27%25${query}%25%27 OR friendlyName LIKE %27%25${query}%25%27 OR secondaryId LIKE %27%25${query}%25%27) AND `;
      }

      searchQuery += `accessProviderTid=%27${state.serviceConfiguration.selectedServiceConfigurationItem?._id}%27`;

      const accessCardData = await getAccessCardData(
        state.serviceConfiguration.tenantDetails?._id || "",
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        searchQuery
      );

      dispatch.serviceConfiguration.SET_ACCESS_CARD(accessCardData);
      return accessCardData;
    },
    async deleteAccessCard(accessCardId: string, state: RootState): Promise<boolean> {
      const result = await deleteAccessCard(accessCardId, state.serviceConfiguration.tenantDetails?._id || "").catch(handleError);
      return !!result;
    },  

    async getServiceConfigurationItem(serviceConfigId: string, state: RootState)
    : Promise<void> {

      const serviceConfigurationItem = await 
      getServiceConfigurationItem(serviceConfigId, state.serviceConfiguration.tenantDetails?._id || "");
      
      dispatch.serviceConfiguration.SET_SELECTED_SERVICE_CONFIGURATION_ITEM(serviceConfigurationItem);
    },
    async saveServiceConfiguration(params: ServiceConfiguration, state: RootState): Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let success = true;
      // eslint-disable-next-line fp/no-let
      let result;
      if(state.serviceConfiguration.selectedServiceConfigurationItem){
        //edit serviceConfiguration category
        result = await updateServiceConfiguration(params, state.serviceConfiguration.tenantDetails?._id || "").catch(handleError);
        success = !!result;

        if(result){
          dispatch.serviceConfiguration.SET_SELECTED_SERVICE_CONFIGURATION_ITEM(result);
        }
      } else{
        //new serviceConfiguration category
        result = await saveServiceConfiguration(params, state.serviceConfiguration.tenantDetails?._id || "").catch(handleError);
        success = !!result;
      }

      return success;
    },
    async deleteServiceConfiguration(serviceConfigurationId: string, state: RootState): Promise<boolean> {
      const result = await deleteServiceConfiguration(serviceConfigurationId, state.serviceConfiguration.tenantDetails?._id || "").catch(handleError);
      return !!result;
    },    
    async uploadAccessCardsFile(params: {file: FileWithPath, body: string}, state: RootState): Promise<void> {
      const formData = new FormData();
      formData.append('files', params.file);
      formData.append('body', params.body);

      dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_PERCENTAGE(50);
      try {
        const upload = await uploadAccessCardsFile(formData, state.serviceConfiguration.tenantDetails?._id || "");
        if (upload) {
          if(upload.uploadedCount === 0){
            dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_STATUS('failed');
            dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE(`Failed Cards: ${upload.failedCount}. ${upload.generalMessage}`);
          } else{
            dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_STATUS('successful');
            dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_SUCCESS_MESSAGE(`Uploaded Cards: ${upload.uploadedCount}. Failed Cards: ${upload.failedCount}. ${upload.generalMessage}`);
          }
        } else {
          dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_STATUS('failed');
          dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE('An error occurred while uploading the file.');
        }
      } catch (e) {
        dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_STATUS('failed');
        dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE('An error occurred while uploading the file.');
        if (e instanceof Error) {
          dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE(e?.message ?? 'An error occurred while uploading the file.');
        }
      }
      dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_PERCENTAGE(100);
    },
    resetUploadAccessCardsModal(): void {
      dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_PERCENTAGE(undefined);
      dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_STATUS(undefined);
      dispatch.serviceConfiguration.SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE(undefined);
    },
    async getSharingData(_:void, state: RootState): Promise<void> {
      const tenantRef = state.serviceConfiguration.tenantDetails?._id || "";
      const serviceConfigId = state.serviceConfiguration.selectedServiceConfigurationItem?._id || "";
      dispatch.serviceConfiguration.TOGGLE_SHARING_LOADING();

      const sharingData = await getSharingData(tenantRef, serviceConfigId)
        .finally(dispatch.serviceConfiguration.TOGGLE_SHARING_LOADING);

      dispatch.serviceConfiguration.SET_SHARED(sharingData);
    },
    async submitSharedData(sharedProviderIds: string[], state: RootState): Promise<{status: string} | false> {
      const serviceConfigId = state.serviceConfiguration.selectedServiceConfigurationItem?._id || "";
      return await submitSharedData(sharedProviderIds, serviceConfigId, state.serviceConfiguration.tenantDetails?._id || "")
        .catch(handleError);
    },
    async getAccessCardSitesData(_:void, state: RootState): Promise<void> {
      const tenantRef = state.serviceConfiguration.tenantDetails?._id || "";
      const serviceConfigId = state.serviceConfiguration.selectedServiceConfigurationItem?._id || "";
      dispatch.serviceConfiguration.TOGGLE_SITES_LOADING();
      const sitesData = await getAccessCardSitesData(tenantRef, serviceConfigId)
        .finally(dispatch.serviceConfiguration.TOGGLE_SITES_LOADING);

      dispatch.serviceConfiguration.SET_SITES(sitesData);
    },
    async submitAccessCardSitesData(siteIds: AccessCardProvider[], state: RootState):
     Promise<{status: string} | false> {
      const serviceConfigId = state.serviceConfiguration.selectedServiceConfigurationItem?._id || "";
      return await submitAccessCardSitesData(serviceConfigId, siteIds, state.serviceConfiguration.tenantDetails?._id || "")
        .catch(handleError);
    }
  })
};

export const serviceConfiguration = createModel<RootModel>()(serviceConfigurationDataModel);
