import { ActionIcon } from "@mantine/core";
import { CellClickedEvent, ColDef, ICellRendererParams, RowNode, ValueGetterParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";

import AgGrid from "@/common/components/AgGrid";
import IconSearchSlider from "@/common/components/IconSearchSlider/IconSearchSlider.container";
import TruncatedTextWithTooltip from "@/common/components/TruncatedTextWithTooltip/TruncatedTextWithTooltip.component";
import { ReactComponent as MinusIcon } from "@/common/icons/minus-red.svg";
import {
  useSelectRecipientsPageStyles
} from "@/tenant-context/control-mass-comms/components/SelectRecipientsPage/SelectRecipientsPage.styles";
import { ReactComponent as AppGrayIcon } from "@/tenant-context/control-mass-comms/icons/app-gray.svg";
import { ReactComponent as EmailGrayIcon } from "@/tenant-context/control-mass-comms/icons/email-gray.svg";
import { ReactComponent as PhoneGrayIcon } from "@/tenant-context/control-mass-comms/icons/phone-gray.svg";
import { ReactComponent as SMSGrayIcon } from "@/tenant-context/control-mass-comms/icons/sms-gray.svg";
import {
  DeliveryChannel,
  PickedPeopleEntity,
  ReceiveCountByCommunicationChannel
} from "@/tenant-context/control-mass-comms/types/MassComms.types";

type Props = {
  selectedPeople: Array<PickedPeopleEntity>,
  deliveryChannels: DeliveryChannel,
  onPersonRemoveRequested: (event: CellClickedEvent) => void,
  receiveCountByCommunicationChannel: ReceiveCountByCommunicationChannel,
  isExternalFilterPresent: () => boolean,
  doesExternalFilterPass: (node: RowNode<PickedPeopleEntity>) => boolean,
  onFilterPhraseChange: (filter: string) => void,
  selectedRecipientFilterPhrase: string
}

const RecipientsListViewComponent: FC<Props> = ({
  selectedPeople,
  deliveryChannels,
  onPersonRemoveRequested,
  receiveCountByCommunicationChannel,
  isExternalFilterPresent,
  doesExternalFilterPass,
  onFilterPhraseChange,
  selectedRecipientFilterPhrase
}) => {

  const { classes } = useSelectRecipientsPageStyles();
  const gridRef = useRef<AgGridReact>(null);

  const {
    allEmail,
    allSMS,
    allVoice
  } = receiveCountByCommunicationChannel;

  const defaultColDef = {
    sortable: true,
    autoHeight: true,
    flex: 1
  };

  const channelsCellRenderer = useCallback((params: ICellRendererParams<PickedPeopleEntity>) => {
    return (
      <div className={ classes.channelCell }>
        { (params.data?.isAppInstalled) ? <AppGrayIcon /> : null }
        { (params.data?.isWithEmail) ? <EmailGrayIcon /> : null }
        { (params.data?.isWithPhone) ? <SMSGrayIcon /> : null }
        { (params.data?.isWithPhone) ? <PhoneGrayIcon /> : null }
      </div>
    );
  }, [ classes.channelCell ]);

  const unselectPersonCellRenderer = useCallback((_params: ICellRendererParams<PickedPeopleEntity>) => {
    return <ActionIcon>
      <MinusIcon />
    </ActionIcon>;
  }, []);

  const externalFilterChanged = useCallback((newValue) => {
    onFilterPhraseChange(newValue);
  }, [onFilterPhraseChange]);

  useEffect(() => {
    gridRef?.current?.api?.onFilterChanged();
  }, [selectedRecipientFilterPhrase]);

  const [columnDefs] = useState<ColDef[]>([
    {
      field: 'name',
      valueGetter: (params: ValueGetterParams<PickedPeopleEntity>) => {
        if (!params.data) {
          return 'N/A';
        }

        return `${params.data.firstName} ${params.data.lastName}`;
      },
      cellRenderer: (params: ICellRendererParams): ReactNode => {
        const fullName = `${params.data.firstName} ${params.data.lastName}`;
        return <TruncatedTextWithTooltip maxLength={ 13 } fullText={ fullName } />;
      }
    },
    {
      field: 'reason'
    },
    {
      field: 'channels',
      headerName: 'Available Channels',
      cellRenderer: channelsCellRenderer,
      flex: 2,
      comparator: (_valueA, _valueB, nodeA, nodeB) => {
        // Sort by the number of channels available
        // Get the channel count by referencing node.data.isWithApp, node.data.isWithEmail, node.data.isWithPhone
        const nodeAChannels = [nodeA.data?.isWithApp, nodeA.data?.isWithEmail, nodeA.data?.isWithPhone];
        const nodeBChannels = [nodeB.data?.isWithApp, nodeB.data?.isWithEmail, nodeB.data?.isWithPhone];

        const nodeAChannelCount = nodeAChannels.filter(Boolean).length;
        const nodeBChannelCount = nodeBChannels.filter(Boolean).length;

        if (nodeAChannelCount === nodeBChannelCount) {
          return 0;
        }

        return nodeAChannelCount > nodeBChannelCount ? 1 : -1;
      }
    },
    {
      headerName: '',
      cellRenderer: unselectPersonCellRenderer,
      onCellClicked: onPersonRemoveRequested,
      type: 'rightAligned',
      sortable: false
    }
  ]);

  return (
    <div>
      <div className={ classes.searchBoxWithHeadingWrapper }>
        <div className={ classes.sectionHeader }>Selected Recipients</div>
        <IconSearchSlider onChangeSearch={ externalFilterChanged } />
      </div>

      <div className={ classes.channelCountWrapper }>
        <div className={ classes.totalRecipientCountDiv }>
          Total Recipient Selected
          <div className={ classes.totalRecipientCount }>
            { selectedPeople.length }
          </div>
        </div>

        <div className={ classes.channelCountItemWrapper }>
          { deliveryChannels.email ? (<div className={ classes.channelCountItem }>
            <EmailGrayIcon/>
            { allEmail }
          </div>) : null }

          { deliveryChannels.sms ? (<div className={ classes.channelCountItem }>
            <SMSGrayIcon />
            { allSMS }
          </div>) : null }

          { deliveryChannels.voice ? (<div className={ classes.channelCountItem }>
            <PhoneGrayIcon />
            { allVoice }
          </div>) : null }
        </div>
      </div>

      <div className="ag-theme-alpine-dark ag-theme-rt">
        <AgGrid<PickedPeopleEntity>
          id="recipients-list"
          ref={ gridRef }
          data={ selectedPeople }
          defaultColDef={ defaultColDef }
          columnDefs={ columnDefs }
          pagination={ true }
          paginationPageSize={ 10 }
          animateRows={ true }
          suppressCellFocus={ true }
          suppressRowClickSelection={ true }
          domLayout="autoHeight"
          isExternalFilterPresent={ isExternalFilterPresent }
          doesExternalFilterPass={ doesExternalFilterPass }
        />
      </div>
    </div>
  );
};

export default RecipientsListViewComponent;
