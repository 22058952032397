import { Button, Flex, Stack } from "@mantine/core";
import React from "react";

import {
  riskCategories as bsocRiskCategories, riskLevels
} from "@/tenant-context/control-risk-config/components/BSOCEventsSettings/BSOCEventsSettings.config";
import { useLegendInTree } from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/hooks";
import ProviderEvents
  from "@/tenant-context/control-risk-config/components/RiskProviderTreeContent/ProviderEvents.component";

type Props = {
  onCategoryToggle: (event: React.ChangeEvent<HTMLInputElement>) => void,
  disabledRiskFilterTypes: Set<string>
};

export function BSOCEventsComponent({ onCategoryToggle, disabledRiskFilterTypes }: Props) {
  const { buttonAsLinkClasses, risklineLegendVisible, toggleRisklineLegend } = useLegendInTree();

  return <Stack mt={ 16 } ml={ 32 + 32 }>
    { risklineLegendVisible && <Stack>
      { riskLevels.map(({ Icon, id, name }) => (
        <Flex key={ id } gap={ 8 } display="inline-flex" align="center">
          <Icon />
          <span>{ name }</span>
        </Flex>
      )) }
    </Stack> }
    <Button
      variant="subtle"
      compact
      classNames={ buttonAsLinkClasses }
      onClick={ toggleRisklineLegend }
    >
      { risklineLegendVisible ? "Hide" : "Show" } legend
    </Button>

    <ProviderEvents
      items={ bsocRiskCategories }
      onCategoryToggle={ onCategoryToggle }
      disabledRiskFilterTypes={ disabledRiskFilterTypes }
    />
  </Stack>;
}