import { Flex, Progress, Rating, Stack, Text } from '@mantine/core';
import { FC, Fragment, useMemo } from 'react';

import { ReactComponent as StarEmpty } from '@/common/icons/Components/rating-star-empty.svg';
import { ReactComponent as StarFilled } from '@/common/icons/Components/rating-star-filled.svg';
import {
  useDetailedCountryReportStyles
} from '@/tenant-context/control-reports/components/DetailedCountryReport/DetailedCountryReport.styles';
import { HealixCountryRiskData } from '@/tenant-context/control-reports/types/country-risk-reports';
import { RiskReportProvider } from '@/tenant-context/control-reports/types/risk-reports-common';
import {
  getRiskColorByRiskLevel,
  getRiskLevelValueByRiskLevel, getStartRatingForEGMPV
} from '@/tenant-context/control-reports/util/country-risk-reports';

type Props = {
  countryRiskData: HealixCountryRiskData;
};

const MaxSecurityDetailedCountryReport: FC<Props> = ({
  countryRiskData
}) => {
  const getColorByLevel = (level?: string) => {
    return getRiskColorByRiskLevel(level, RiskReportProvider.HEALIX);
  };

  const { classes } = useDetailedCountryReportStyles( {
    riskLevelColor: getColorByLevel(countryRiskData?.securityRiskRating?.toLowerCase())
  } );

  const riskLevelValue = useMemo(() => {
    return getRiskLevelValueByRiskLevel(countryRiskData?.securityRiskRating, RiskReportProvider.HEALIX);
  }, [countryRiskData?.securityRiskRating]);

  return (
    <Fragment>
      <Stack spacing="sm" className={ classes.riskLevelContainer }>
        <Flex justify="space-between">
          <Text weight={ 400 }>Risk Level</Text>
          <Text weight={ 400 } transform="capitalize">{ countryRiskData?.securityRiskRating }</Text>
        </Flex>
        <Progress value={ riskLevelValue } color="red" size="lg" className={ classes.countryRiskLevel }/>
      </Stack>

      { countryRiskData?.securitySnapshot && <Stack>
        <Text className={ classes.sectionHeading }>Security snapshot</Text>
        <Text size="sm">{ countryRiskData.securitySnapshot }</Text>
      </Stack> }

      { countryRiskData?.advice && <Stack>
        <Text className={ classes.sectionHeading }>Advice</Text>
        { countryRiskData.advice.map((advice, index) => (
          <Fragment key={ index }>
            <Text className={ classes.subSectionHeading }>{ advice.sectionName }</Text>
            <div
              className={ classes.content }
              dangerouslySetInnerHTML={ { __html: advice.text } }
            />
          </Fragment>
        )) }
      </Stack> }

      { countryRiskData?.riskEnvironment && <Stack>
        <Text className={ classes.sectionHeading }>Risk Environment</Text>
        { countryRiskData.riskEnvironment?.map((riskEnvironment, index) => (
          <Fragment key={ index }>
            <Text className={ classes.subSectionHeading }>{ riskEnvironment.name }</Text>
            { riskEnvironment.sections?.map((section, index_nested) => (
              <Fragment key={ index_nested }>
                <Text className={ classes.subSectionHeading }>{ section.type }</Text>
                <Flex gap="md">
                  <Progress value={ section.impact } />
                  <Progress value={ section.likelihood } />
                </Flex>
                <div
                  className={ classes.content }
                  dangerouslySetInnerHTML={ { __html: section.reasoning } }
                />
              </Fragment>
            )) }
          </Fragment>
        )) }
      </Stack> }

      { (countryRiskData?.medicalSnapshot || countryRiskData?.medical || countryRiskData.medicalRiskRating) &&
        <Stack>
          <Text className={ classes.sectionHeading }>Medical</Text>

          { countryRiskData?.medicalRiskRating && <Flex align="center" gap="md">
            <Text className={ classes.subSectionHeading }>Medical Risk Rating</Text>
            <Rating
              value={ getStartRatingForEGMPV(countryRiskData.medicalRiskRating) }
              emptySymbol={ <StarEmpty /> }
              fullSymbol={ <StarFilled /> }
              readOnly
            />
          </Flex> }

          { countryRiskData?.medicalSnapshot && <Stack>
            <Text className={ classes.subSectionHeading }>Medical snapshot</Text>
            <Text size="sm">{ countryRiskData.medicalSnapshot }</Text>
          </Stack> }

          { countryRiskData?.medical?.map((medical, index) => (
            <Stack key={ index }>
              <Text className={ classes.subSectionHeading }>{ medical.sectionName }</Text>
              <div
                className={ classes.content }
                dangerouslySetInnerHTML={ { __html: medical.text } }
              />
            </Stack>
          )) }

        </Stack> }

      { countryRiskData?.logistics && <Stack>
        <Text className={ classes.sectionHeading }>Logistics</Text>
        { countryRiskData.logistics?.map((riskEnvironment, index) => (
          <Fragment key={ index }>
            <Text className={ classes.subSectionHeading }>{ riskEnvironment.name }</Text>
            { riskEnvironment.sections?.map((section, index_nested) => (
              <Fragment key={ index_nested }>
                <Text className={ classes.subSectionHeading }>{ section.sectionName }</Text>
                <div
                  className={ classes.content }
                  dangerouslySetInnerHTML={ { __html: section.text } }
                />
              </Fragment>
            )) }
          </Fragment>
        )) }
      </Stack> }

      { countryRiskData?.citySpecificInfo && <Stack>
        <Text className={ classes.sectionHeading }>City Specific Info</Text>
        { countryRiskData.citySpecificInfo?.map((city, index) => (
          <Stack key={ index }>
            <Text className={ classes.subSectionHeading }>{ city.cityName }</Text>
            <div
              className={ classes.content }
              dangerouslySetInnerHTML={ { __html: city.description } }
            />
          </Stack>
        )) }
      </Stack> }

    </Fragment>
  );
};

export default MaxSecurityDetailedCountryReport;
