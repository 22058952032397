import { ActionIcon } from '@mantine/core';
import { FC } from "react";
import ReactPlayer from 'react-player';

import { ReactComponent as CloseIcon } from '@/common/icons/close.svg';
import { useEvidenceVideoModalStyles } from '@/tenant-context/employee-app-action-response-center/components/ui/EvidenceVideoModal/EvidenceVideoModal.style';
import { EmergencyVideo, VideoMode } from "@/tenant-context/employee-app-action-response-center/types/emergency-video";

type Props = {
  emergencyVideo: EmergencyVideo
  onClose: () => void
};

const EvidenceVideoModalComponent: FC<Props> = ({ emergencyVideo, onClose }) => {
  const { classes } = useEvidenceVideoModalStyles();
  return (
    <div className={ classes.container }>
      <div className={ classes.actions }>
        <div>
        </div>
        <div>
          <ActionIcon onClick={ onClose }>
            <CloseIcon />
          </ActionIcon>
        </div>
      </div>
      <ReactPlayer
        url={ emergencyVideo.url }
        className={ classes.player }
        width={ emergencyVideo.mode === VideoMode.LANDSCAPE ? '400px' : '' }
        height={ emergencyVideo.mode === VideoMode.PORTRAIT ? '500px' : '' }
        playing={ true }
        controls={ true }
      />
    </div>
  );
};

export default EvidenceVideoModalComponent;
