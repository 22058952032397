import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme/types/MantineTheme";

export const useRiskTimelineGraphStyles = createStyles((theme: MantineTheme) => ({
  todayText: {
    fontWeight: 700,
    fontFamily:theme.fontFamily
  },
  currentYear:{
    fontFamily:theme.fontFamily
  },
  axisLine: {
    stroke: theme.colors.neutral[5],
    strokeWidth: 1
  },
  riskChristmasTree: {
    cursor: 'pointer',
    transformOrigin: '50% 50%',

    '& > g': {
      transition: 'scale 350ms',
      transformOrigin: '1% 3%'

    },

    '&:hover > g': {
      scale: '1.5'
    },

    '&:hover > .risk-alert-exclamation': {
      scale: '1.5'
    }
  },
  tooltip: {
    position: "absolute",
    left: '100',
    padding: theme.spacing.xs,
    backgroundColor: theme.colors.turquoiseBlue[0],
    color: theme.colors.neutral[8],
    fontSize: theme.fontSizes.sm,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: theme.radius.sm,
    maxHeight: 200,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}));
