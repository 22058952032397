import { createStyles } from "@mantine/core";

type Props = {
  isNarrowTable?: boolean;
  isResponsive?: boolean;
}

export const usePaginationBlockStyles = createStyles((
  theme,
  { isNarrowTable = false, isResponsive = false }: Props
) => ({
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: "50px",
    marginTop: "16px",
    flexDirection: `${isNarrowTable ? 'column' : 'row'}`,
    [theme.fn.smallerThan('lg')]: {
      flexDirection: isResponsive ? 'column' : undefined,
      alignItems: isResponsive ? 'flex-start' : undefined
    }
  },
  perPageLabel: {
    color: theme.other.semantic.text.secondary,
    margin: `${isNarrowTable ? '0px 12px 0 0' : '0px 12px 0 32px'}`,
    fontSize: '14px !important',
    [theme.fn.smallerThan('lg')]: {
      margin: isResponsive ? '0px 12px 0 0' : undefined
    }
  },
  records: {
    color: theme.other.semantic.text.secondary,
    fontSize: '14px !important',
    marginLeft: '32px'
  },
  itemsPerPageSelect: {
    width: '70px',
    fontSize: '14px !important',
    margin: 0
  },
  countContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: `${isNarrowTable ? '16px' : '0px'}`,
    alignItems: 'center',
    minWidth: 400,
    [theme.fn.smallerThan('lg')]: {
      marginTop: isResponsive ? '16px' : undefined
    }
  }
}));
