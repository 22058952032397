import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Dispatch } from "@/core/store";
import ARC from "@/tenant-context/control-action-response-center/components/ARC/ARC.component";
import { useARCStyles } from "@/tenant-context/control-action-response-center/components/ARC/ARC.style";
import ArcProvider from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";
import RiskAlertDetails from "@/tenant-context/control-risk-config/components/RiskAlertDetails/RiskAlertDetails.container";

const ARCContainer: FC = () => {

  const { classes } = useARCStyles();

  const {
    arc: {
      SET_IS_ARC_OPEN
    },
    playbook: {
      loadActions
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    SET_IS_ARC_OPEN(true);
    loadActions("ARC");

    return () => {
      SET_IS_ARC_OPEN(false);
    };
  }, [SET_IS_ARC_OPEN, loadActions]);

  return (
    <ArcProvider>
      <div className={ classes.arcContainer }>
        <RiskAlertDetails
          isOpenedInArc={ true }
        />
        <ARC />
      </div>
    </ArcProvider>
  );
};

export default ARCContainer;
