import { FC } from "react";
import { Marker } from "react-map-gl";

import { IMarker } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

type Props = {
  marker: IMarker
  handleMarkerClick?: (e: mapboxgl.MapboxEvent<MouseEvent>, marker: IMarker) => void
}
const LocationMarker:FC<Props> = ({  marker, handleMarkerClick }) => {

  return (
    <Marker
      longitude={ marker.lon }
      latitude={ marker.lat }
      /* eslint-disable-next-line react/jsx-no-bind */
      onClick={ (e) => {
        if (handleMarkerClick) {
          handleMarkerClick(e, marker);
        }

        return null;
      } }
    />
  );
};

export default LocationMarker;
