import { Loader } from "@mantine/core";
import { FC, useCallback  } from "react";

import DrawerControl from "@/common/components/DrawerControl";
import { Location } from "@/tenant-context/common/types/location";
import TravelItineraryItem from "@/tenant-context/control-travel/components/TravelItineraryItem";
import { SummaryItineraryItemWithProfile } from "@/tenant-context/control-travel/types/travel.types";

import { useTravelItineraryControlStyles } from "./TravelItineraryControl.styles";


type Props = {
  selectedCountry?: Location;
  date?: string;
  activeDay?: string;
  handleDrawerClose?: () => void;
  travelDirection?: string;
  travelData?: SummaryItineraryItemWithProfile[];
  handleTravelItineraryItemClick?: (profileId: string, correlationId: string) => void;
  isLoading?: boolean;
};

export const TRAVEL_ITINERARY_CONTROL_DRAWER_ID = "travel-itinerary-control";

const TravelItineraryControl: FC<Props> = (
  { 
    selectedCountry, 
    date,
    activeDay, 
    travelDirection, 
    handleDrawerClose, 
    travelData,
    handleTravelItineraryItemClick,
    isLoading
  }
) => {
  const { classes } = useTravelItineraryControlStyles();

  const getTitle = useCallback((country) => {
    return `${travelDirection?.toUpperCase()} ${country ? country.name.toUpperCase() : 'WORLDWIDE'} ${activeDay ? activeDay.toUpperCase() : ''}`;
  }, [activeDay, travelDirection]);

  return (
    <DrawerControl
      id={ TRAVEL_ITINERARY_CONTROL_DRAWER_ID }
      title={ getTitle(selectedCountry) }
      size={ "lg" }
      drawerPosition='right'
      onBackButtonClick={ handleDrawerClose }
      onCloseClick={ handleDrawerClose }
    >
      <div className={ classes.container }>
        { date }
        { isLoading && (<Loader />) }
        { !isLoading &&travelData && travelData.map((travelDataItem, index) => (
          <TravelItineraryItem 
            key={ travelDataItem.profileId + index }
            travelDataItem={ travelDataItem } 
            onClick={ handleTravelItineraryItemClick } />
        )) }
      </div>
    </DrawerControl>
  );
};

export default TravelItineraryControl;
