import { callApi } from "@/common/util/api";
import createServerSentEventStream, { SubscriptionEventHandler } from "@/common/util/sse";
import { ConnectedSiteStatus, SiteLocationSnapshot } from "@/tenant-context/visualisation-site/types/site.types";

export const getAllLastMileSites = () => callApi<SiteLocationSnapshot[]>(
  'location-management-service/v1/categories/All/sub-categories/Site/locations',
  {
    method: 'get',
    queryParams: {
      orderBy: 'name:ASC',
      mergeNonTenant: false
    }
  }
);

export const getAllNoneLastMileSites = () => callApi<SiteLocationSnapshot[]>(
  'location-management-service/v1/categories/Areas/sub-categories/NoneLastMileSite/locations',
  {
    method: 'get',
    queryParams: {
      orderBy: 'name:ASC',
      mergeNonTenant: false
    }
  }
);

export const getAllLocationSites = 
(isOnlyCritLocations: boolean, isGeoPointOnly: boolean) => callApi<SiteLocationSnapshot[]>(
  `location-management-service/v1/locations`,
  {
    method: 'get',
    queryParams: {
      orderBy: 'name:ASC',
      withGeoPointOnly: isGeoPointOnly,
      ...(isOnlyCritLocations ? { criticalStatusFilter: 'CRITICAL' } : {})
    }
  }
);

export const createConnectedSiteStream = (
  handleEvent: SubscriptionEventHandler<ConnectedSiteStatus>,
  tenantId: string
) => createServerSentEventStream(
  `sync-service/v1/stream/connected-sites/heartbeat?tenantId=${tenantId}`,
  handleEvent,
  { isWithAuth: false }
);
