import { callApi } from "@/common/util/api";

export const assignCaseOwner = (
  caseID: string,
  caseOwnerProfileId: string
) => callApi<
  unknown
>(
  `/arc-service/v1/lone-worker/case/${caseID}/assign-case-owner`,
  {
    method: 'post',
    withTenantId: true,
    withAuthorization: true,
    body: JSON.stringify({
      caseOwnerProfileId
    })
  }
);