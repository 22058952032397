import {
  CaseTypesDetailsContextProvider
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/CaseTypeDetails.context";
import RoleDetailsComponent
  from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/RoleDetails/RoleDetails.component";
import {
  RoleDetailsContextTypeProvider
} from "@/platform-context/control-icm-management/components/CaseTypes/Details/components/TeamsAndRoles/components/RoleDetails/RoleDetails.context";

const RoleDetails = () => {
  return (
    <CaseTypesDetailsContextProvider>
      <RoleDetailsContextTypeProvider>
        <RoleDetailsComponent />
      </RoleDetailsContextTypeProvider>
    </CaseTypesDetailsContextProvider>
  );
};

export default RoleDetails;