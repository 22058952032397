import { FC } from "react";
import { ErrorBoundary } from 'react-error-boundary';

import GlobalErrorBoundary from './GlobalErrorBoundary.component';

const GlobalErrorBoundaryContainer: FC = ({
  children
}) => {
  return (
    <ErrorBoundary
      FallbackComponent={ GlobalErrorBoundary }
    >
      { children }
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundaryContainer;