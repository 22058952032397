import { FC } from "react";

import { useArcColumnStyles } from "@/tenant-context/control-action-response-center/components/ArcColumn/ArcColumn.style";

type Props = {
  className?: string
}

const ArcColumn: FC<Props> = ({ children, className }) => {
  const { classes, cx } = useArcColumnStyles();

  return (
    <div className={ cx(classes.column, className) }>
      { children }
    </div>
  );
};

export default ArcColumn;