import { createStyles, MantineTheme } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";


export const useTravellersMiniProfileDrawerStyles = createStyles((theme: MantineTheme) => ({
  backIcon: {
    all: 'unset',
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: 48,

    '& > svg': {
      marginRight: 8
    }
  },
  profileContainer: {
    display: "flex",
    gap: 8,
    marginBottom: 24
  },
  avatar: {
    backgroundColor: 'transparent'
  },
  position: {
    fontSize: 14,
    lineHeight: theme.other.lh.l,
    marginBottom: 16
  },
  resident: {
    fontSize: 14,
    lineHeight: theme.other.lh.l,
    marginBottom: 16,
    color: theme.colors.neutral[5]
  },
  residentCountry: {
    fontSize: 14,
    lineHeight: theme.other.lh.l,
    color: theme.colors.neutral[3]
  },
  email: {
    fontSize: 14,
    lineHeight: theme.other.lh.l,
    color: theme.colors.royalBlue[1]
  },
  viewProfileButton: {
    padding: '14px',
    backgroundColor: `${theme.colors.neutral[9]} !important`,
    color: theme.colors.neutral[0],
    border: `1px solid ${theme.colors.neutral[0]}`,
    cursor: "pointer"
  },


  // legacy itineraries

  iteneraryContainer: {
    display: 'flex',
    gap: '12px'
  },
  locationsWrapper: {
    flex: '1'
  },
  stepper: {
    '& .mantine-Stepper-steps': {
      height: '100%',
      '& .mantine-Stepper-separator ': {
        '&::after': {
          top: 0,
          left: '50%',
          width: '1px',
          height: '100%',
          backgroundColor: theme.colors.neutral[8]
        }
      },
      '& .mantine-Stepper-separatorActive': {
        '&::after': {
          backgroundColor: theme.other.semantic.accessibility.outer
        }
      }
    }
  },
  stepperStep: {
    marginBottom: 0,
    '& > div': {
      marginBottom: 0
    }
  },

  completedStep: {
    [`& .${ CSS_KEY }-Step-stepIcon`]: {
      borderColor: theme.other.semantic.accessibility.outer,
      backgroundColor: theme.other.semantic.accessibility.outer,
      '& svg': {
        fill: theme.colors.neutral[9],

        '& *': {
          fill: theme.colors.neutral[9]
        }
      }
    }
  },

  currentStep: {
    [`& .${ CSS_KEY }-Step-stepIcon`]: {
      borderColor: theme.other.semantic.button.default.default,
      backgroundColor: theme.other.semantic.button.default.default,
      '& svg': {
        fill: theme.colors.white
      }
    }
  },

  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 35
  },

  itineraryStepperContainer: {
    display: 'flex',
    [`& .${CSS_KEY}-Step-stepWrapper`]: {
      marginRight: '24px'
    }
  },

  itineraryLine: {
    position: 'relative',
    left: -46,
    top: 100,
    zIndex: -99
  },

  itineraryLineCompleted: {
    borderTopColor: theme.other.semantic.accessibility.outer,
    borderLeftColor: theme.other.semantic.accessibility.outer
  },

  itineraryLineCurrent: {
    borderTopColor: theme.other.semantic.button.default.default,
    borderLeftColor: theme.other.semantic.button.default.default
  }
}));
