/* eslint-disable react/jsx-props-no-spreading */
import { MultiSelect, Switch, Textarea, TextInput } from "@mantine/core";
import { FC, useCallback, useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import { TestableComponent } from "@/common/types/testable-component";
import {
  useCreateAndUpdatePeopleGroupFormStyles
} from "@/tenant-context/common/components/CreateAndUpdatePeopleGroupForm/CreateAndUpdatePeopleGroupForm.styles";
import { GroupItem } from "@/tenant-context/control-groups/types/group";
import { PaginatedResult, ReferenceDataService } from "@/tenant-context/control-profile/types/profile";

type Props = {
  activeGroupData?: GroupItem,
  formControls:   UseFormReturn<FieldValues, unknown>
  allServices?:   PaginatedResult<ReferenceDataService>
} & TestableComponent;

const CreateAndUpdatePeopleGroupForm: FC<Props> = ({ activeGroupData, formControls, allServices, dataTestId }) => {

  const  { register, watch, setValue, formState: { errors }, unregister } = formControls;
  const {
    isApplicable_to_all_services
  } = watch();
  const { classes: { applicableServicesDropdown, formGroup } } = useCreateAndUpdatePeopleGroupFormStyles();
  const handleApplicableServicesChange = useCallback(
    (services: string[]) => setValue('applicable_services', services, { shouldDirty: true }),
    [setValue]
  );

  useEffect(() => {
    if (!isApplicable_to_all_services) {
      register('applicable_services', addErrorMessages('Applicable Services', {
        validate: (value) => value.length > 0,
        value:  activeGroupData?.applicableServices.split(','),
        required: {
          value: true,
          message: 'There should be at least 1 applicable service'
        }
      }));
    } else {
      unregister('applicable_services');
    }
  }, [activeGroupData?.applicableServices, isApplicable_to_all_services, register, unregister]);

  return (
    <div>
      <TextInput
        label="Group Name"
        error={ errors?.group_name?.message }
        required={ true }
        { ...register('group_name', addErrorMessages('Group Name', {
          required: true,
          maxLength: 256,
          minLength: 3
        })) }
        size="md"
        defaultValue={ activeGroupData?.name }
        data-testid={ `${dataTestId}-group-name-input` }
      />
      <div className={ formGroup }>
        <Switch
          defaultChecked={ activeGroupData?.isApplicableToAllService }
          label="Applicable to all services"
          data-testid={ `${dataTestId}-applicable-to-select` }
          { ...register('isApplicable_to_all_services') }
        />
        <Switch
          label="Active"
          defaultChecked={ activeGroupData ? activeGroupData?.isActive : true }
          data-testid={ `${dataTestId}-active-switch` }
          { ...register('is_active') }
        />
      </div>
    
      { !isApplicable_to_all_services && (
        <MultiSelect
          searchable
          required={ true }
          className={ applicableServicesDropdown }
          label="Select Applicable Services"
          size="md"
          nothingFound="No other services found"
          onChange={ handleApplicableServicesChange }
          defaultValue={  activeGroupData?.applicableServices.split(',') }
          error={ errors?.applicable_services?.message }
          data={ allServices?.items.map((service) => ({
            value: service.code,
            label: service.name
          })) || [] }
          data-testid={ `${dataTestId}-applicable-services-select` }
        />
      ) }
      <Textarea
        maxRows={ 3 }
        minRows={ 3 }
        label="Description"
        defaultValue={ activeGroupData?.groupDescription }
        error={ errors?.group_description?.message }
        { ...register('group_description',  addErrorMessages('Description', {
          maxLength: 500,
          minLength: 3
        })) }
        size="md"
        data-testid={ `${dataTestId}-description-input` }
      />
    </div>
  );
};

export default CreateAndUpdatePeopleGroupForm;
