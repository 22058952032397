import { NativeSelect } from "@mantine/core";
import { ChangeEvent, FC } from "react";

import {
  useTimeScaleDropdownStyles
} from "@/tenant-context/control-location-graph/components/TimeScaleDropdown/TimeScaleDropdown.styles";
import { TimeScale } from "@/tenant-context/control-location-graph/store/location-graph/location-graph.model";

type Props = {
  timeScale: TimeScale,
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
};

const TimeScaleDropdown: FC<Props> = ({
  onChange,
  timeScale
}) => {

  const { classes } = useTimeScaleDropdownStyles();

  return (
    <NativeSelect
      value={ timeScale }
      onChange={ onChange }
      size={ 'xs' }
      data={ ['Hours', 'Days'] }
      className={ classes.select }
    />
  );
};

export default TimeScaleDropdown;
