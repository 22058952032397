import { FC, useContext } from 'react';

import GeofenceDataPopupComponent from '@/tenant-context/control-draw/components/GeofenceDataPopup/GeofenceDataPopup.component';
import { GeofenceContext } from '@/tenant-context/control-draw/context/Geofence.context';

export const GeofenceDataPopupContainer: FC = () => {
  const {
    currentPopupCoordinates,
    isShownGeofencePopup,
    popupProperties
  } = useContext(GeofenceContext);

  return (
    <GeofenceDataPopupComponent
      isShown={ isShownGeofencePopup }
      latitude={ currentPopupCoordinates[1] }
      longitude={ currentPopupCoordinates[0] }
      properties={ popupProperties }
    />
  );
};

export default GeofenceDataPopupContainer;
