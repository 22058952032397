import { callApi } from "@/common/util/api";
import createServerSentEventStream, { SubscriptionEventHandler } from "@/common/util/sse";
import { AssetLocationSnapshot } from "@/tenant-context/asset-location-snapshots/types/asset-location";
import { ImpactPerson } from "@/tenant-context/common/types/asset-ranking";
import {
  ArcCaseEvent,
  ArcImpactDetail,
  ArcImpactStatus,
  ArcLocationImpactDetail,
  ArcPersonImpactDetail,
  ArcSentCommunication,
  ImpactedPeopleTrack
} from "@/tenant-context/control-action-response-center/types/ARC.types";
import { GenericRiskAlertResponse } from "@/tenant-context/visualisation-risk-alerts/types/risk-alert-response";

export const getRiskAlertById = (id: string) => callApi<
  GenericRiskAlertResponse
>(
  `asset-impact-service/v1/asset-impact-events/${id}`,
  {
    method: 'get'
  }
);

export const createArcCaseEventsStream = (
  caseId: string,
  tenantId: string,
  handleEvent: SubscriptionEventHandler<ArcCaseEvent | ArcPersonImpactDetail | ArcLocationImpactDetail>
) => createServerSentEventStream(
  `/arc-service/v1/stream/case/${caseId}?tenantId=${tenantId}`,
  handleEvent
);

export const getCaseDetailsById = (riskAlertId: string) => callApi<Array<ArcImpactDetail>>(
  `arc-service/v1/case/detail/query?caseId=${riskAlertId}`,
  {
    method: 'get'
  }
);

export const createImpactPeopleStream = (
  rankRequestTime: string,
  endTime: string,
  rankingOption: string,
  boundType: string,
  radius: string,
  lat: string,
  lon: string,
  tenantId: string,
  handleEvent: SubscriptionEventHandler<ImpactPerson>
) => createServerSentEventStream(
  `/asset-ranking-service/v1/stream/location-engine/people/impact?rankRequestTime=${rankRequestTime}
  &endTime=${endTime}&tenantId=${tenantId}&rankingOption=${rankingOption}&boundType=${boundType}&radius=${radius}&lat=${lat}&lon=${lon}`,
  handleEvent
);

export const changeArcPersonStatus = (
  personId: string,
  note: string,
  caseId: string,
  status: ArcImpactStatus,
  tenantId: string
) => callApi<AssetLocationSnapshot[]>(
  'arc-service/v1/case/person-status',
  {
    method: 'post',
    body: JSON.stringify({
      personId,
      note,
      caseId,
      status,
      tenantId
    })
  }
);

export const getArcSentCommunicationsList = (
  caseId: string,
  tenantId: string,
  pageNum = 0,
  pageSize = 10,
  orderBy = 'communicationName:DESC'
) => callApi<Array<ArcSentCommunication>>(
  `notification-service/v1/${tenantId}/communications/communication-summery?pageNum=${pageNum}&pageSize=${pageSize}&orderBy=${orderBy}&query=source.id='${caseId}'`
);

export const getPersonTrackingDetailsForClosedAlert = (
  caseId: string,
  tenantId: string
) => callApi<ImpactedPeopleTrack>(
  `arc-service/v1/case/${caseId}/person-status/${tenantId}`
);

export const getPersonTrackingDetailsForOpenAlert = (
  tenantId: string
) => callApi<ImpactedPeopleTrack>(
  `asset-ranking-service/v1/location-engine/people/${tenantId}?rankRequestTime=0&rankingOption=TOP_RANK`
);

export const closeArcCaseById = (
  caseId: string,
  tenantId: string
) => callApi<AssetLocationSnapshot[]>(
  'arc-service/v1/case/close',
  {
    method: 'post',
    body: JSON.stringify({
      tenantId,
      caseId
    })
  }
);

