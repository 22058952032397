import { DatabaseMeta } from '@/tenant-context/control-profile/types/profile';

export type UserPolicy = {
  _id: string;
  policyContext: string;
  policyName: string;
  domainOwner: string;
  domainContext: string;
  apiContext: string;
  dependentPolicies: string[];
  boundary: string;
  brn: string;
  trn: string;
  root: boolean;
  tenantRequirement: string;
  meta: DatabaseMeta;
};

export type RequiredPolicies = {
  policyNames: string[];
}

export enum AdvancedLocationTypes {
  basic = "Basic Connected Sites Service",
  advanced = "Advances Connected Sites Service"
}

export enum PermissionCategory{
  global = "Global",
  site = "Site"
}