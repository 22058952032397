import { Flex } from '@mantine/core';
import { FC } from 'react';

import { usePageStyles } from '@/tenant-context/common/components/Page/Page.styles';

const Page: FC = ({
  children
}) => {

  const { classes } = usePageStyles();

  return <Flex direction="column" className={ classes.pageRoot }>
    { children }
  </Flex>;
};

export default Page;
