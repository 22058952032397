import { createStyles } from '@mantine/core';

import { CSS_KEY } from '@/core/styles/mantine/constants';

export const useCreateGeofenceDrawerStyles = createStyles((theme) => ({
  geoFencesMapGrid: {
    display: 'grid',
    width: 'calc(100% - var(--navigation-sidebar-closed-width))',
    gridTemplateColumns: '566px auto',
    gridTemplateRows: 'auto 1fr',
    height: '100%'
  },

  geoFencesForm: {
    height: '100%'
  },

  title: {
    lineHeight: '31px',
    marginBottom: '55px',
    fontSize: 26,
    fontWeight: theme.other.fw.bold,
    position: 'relative',
    margin: 24,

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-12px',
      left: 0,
      width: '56px',
      height: '2px',
      backgroundColor: theme.colors.blue[6]
    }
  },
  formContainer: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    height: '100%',
    padding: 24
  },

  formInputsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },

  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15
  },

  formLabel: {
    color: theme.colors.neutral[5]
  },

  radioGroup: {
    gap: '16px',
    marginTop: '7px',
    label: {
      fontSize: theme.fontSizes.md,
      color: theme.colors.neutral[4]
    },
    'input:checked': {
      backgroundColor: theme.colors.neutral[9],
      borderColor: theme.colors.neutral[5]
    },
    input: {
      backgroundColor: theme.colors.neutral[8],
      borderColor: theme.colors.neutral[5]
    },
    svg: {
      color: theme.other.semantic.accessibility.outer
    }
  },

  unauthorizedRadio: {
    pointerEvents: 'none',
    opacity: 0.5
  },

  inputItem: {
    input: {
      backgroundColor: 'transparent',
      height: '40px',
      border: `1px solid ${theme.colors.neutral[6]} !important`
    },

    [`.${CSS_KEY}-Input-invalid`]: {
      borderTop: 'none !important',
      borderLeft: 'none !important',
      borderRight: 'none !important',
      borderBottom: `1px solid ${theme.colors.error[0]} !important`
    },

    textarea: {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.colors.neutral[6]} !important`
    },

    marginBottom: '0px'
  },

  configSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 24,
    gap: 24,
    background: theme.colors.neutral[9],
    border: `0.5px solid ${theme.colors.neutral[6]}`
  },

  drawerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 24,
    marginTop: 24
  },

  drawerButtonsSet: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 24
  },

  deleteButton: {
    background: `${theme.colors.error[0]} !important`
  },

  switchWithDescription: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: 'auto auto',
    columnGap: 16,
    rowGap: 10
  },

  switchLabel: {
    fontWeight: 'bold'
  },
  notificationContainer: {
    height: '120px',
    padding: '24px 89px 0 0'
  }
}));
