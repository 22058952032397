export type PopupCoordinates = {
  latitude: number,
  longitude: number
};

export enum BigMapPopupIds {
  PeopleCluster = "people/cluster",
  PeopleLocation = "people/location",
  LocationCluster = "location/cluster",
  LocationMarker = "location/marker",
  RiskAlert = "risk/alert",
}