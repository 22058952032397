export const ICMEventPoliciesConfig = {
  ICM_EVENT_ROUTE_ACCESS: {
    policyNames: [
      'SystemAdmin_ICM-Global_Event_ReadOnly',
      'SystemAdmin_ICM-Global_Event_FullAccess'
    ]
  },
  EDIT_ICM_EVENT: {
    policyNames: [
      'SystemAdmin_ICM-Global_Event_FullAccess'
    ]
  }
};