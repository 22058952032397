import { createStyles } from "@mantine/core";

export const useLocationGraphStyles = createStyles((t) => ({
  root: {
    position: 'relative',
    marginLeft: '12px',
    overflow: 'visible',

    '& svg': {
      overflow: 'visible',

      '& text': {
        fill: t.other.semantic.label[1],
        fontSize: 14,
        letterSpacing: .5
      }
    }
  },

  axisLabel: {
    fontWeight: t.other.fw.bold
  },

  nowBlock: {

  },

  nowBlockLine: {
    stroke: t.other.semantic.accent[1]
  },

  nowBlockGlyphBigSquare: {
    fill: t.other.semantic.label[1]
  },

  nowBlockGlyphSmallSquare: {
    fill: t.other.semantic.accent[1]
  }
}));