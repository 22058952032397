import { Flex, Tooltip, UnstyledButton } from '@mantine/core';
import { FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { convertToNavFormat } from "@/common/util/get-testid";
import { useNavigationSidebarStyles } from "@/core/navigation/components/NavigationSidebar/NavigationSidebar.style";
import NavigationTenantDetailsItem from "@/core/navigation/components/NavigationTenantDetailsItem/NavigationTenantDetailsItem.component";
import NavigationUserDetailsItem from "@/core/navigation/components/NavigationUserDetailsItem";
import ResilienceOSLogo from '@/core/navigation/components/ResillienceOSLogo';
import TenantNavItem from "@/core/navigation/components/SideNavItem";
import { NavigationSidebarContext } from '@/core/navigation/context/NavigationSidebar.context';
import { ReactComponent as Chevron } from "@/core/navigation/icons/v2-icons/chevron.svg";
import { NavigationSidebarItem } from '@/core/navigation/types/navigation';

const dataTestId = "right-navigation";

type Props = {
  navigationSidebarItems?: NavigationSidebarItem[]
  navigationSidebarBottomItems?: NavigationSidebarItem[]
}

const NavigationSidebar: FC<Props> = ({ navigationSidebarItems, navigationSidebarBottomItems }) => {
  const {
    isExpanded,
    setIsExpanded
  } = useContext(NavigationSidebarContext);

  const navigate = useNavigate();

  const handleExpandCollapseClick = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded, setIsExpanded]);

  const handleClickOnLogo = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const { classes } = useNavigationSidebarStyles(isExpanded);

  return (
    <div className={ classes.root }>
      <div className={ classes.menuFixedBlock }>
        <ResilienceOSLogo isExpanded={ isExpanded } onClick={ handleClickOnLogo }/>
      </div>
      <div className={ classes.scrollableContent }>
        <Tooltip.Group openDelay={ 100 }>
          <ul>
            <div className={ classes.navItemContainer }>
              <div>
                <li className={ classes.userDetailsItemContainer }>
                  <NavigationUserDetailsItem />
                </li>

                { navigationSidebarItems?.map((item) => (
                  <li key={ item.itemId } className={ classes.navigationListItem }>
                    <TenantNavItem
                      isRedirectOnClick={ item.type !== 'menu' }
                      item={ item }
                      data-testid={ convertToNavFormat(dataTestId, item.title) }
                    />
                  </li>
                )) }
              </div>
              <div>

                <li>
                  <NavigationTenantDetailsItem isExpanded={ isExpanded } />
                </li>
                { navigationSidebarBottomItems?.map((item) => (
                  <li key={ item.itemId }>
                    <TenantNavItem
                      isRedirectOnClick={ item.type !== 'menu' }
                      item={ item }
                      data-testid={ convertToNavFormat(`${dataTestId}-bottom`, item.title) }
                    />
                  </li>
                )) }
              </div>
            </div>
          </ul>
        </Tooltip.Group>
      </div>

      <Flex align="center" justify="flex-end" className={ classes.bottomBlock }>
        <UnstyledButton
          onClick={ handleExpandCollapseClick }
          data-testid={ `${dataTestId}-expand-collapse` }
          className={ classes.toggleButton }
        >
          <Chevron />
        </UnstyledButton>
      </Flex>

    </div>
  );
};

export default NavigationSidebar;
