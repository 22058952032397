/* eslint-disable no-magic-numbers */
import { useMantineTheme } from "@mantine/core";
import { useForceUpdate } from "@mantine/hooks";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { checkIfStringNotEmpty } from "@/common/util/format/string";
import { Dispatch, RootState } from "@/core/store";
import {
  MassCommsAppCommunicationsResponseItem,
  MassCommsEmailResponseItem,
  MassCommsPhoneResponseItem,
  ResponseMethod
} from "@/tenant-context/control-mass-comms/types/MassComms.types";

import ResponseMethodsComponent from "./ResponseMethods.component";

const ResponseMethods: FC = () => {

  const theme = useMantineTheme();
  const forceUpdate = useForceUpdate();

  const [ pollResponses, setPollResponses ] = useState<Map<number, string>>(new Map<number, string>());
  const [ isResponseItemsValid, setIsResponseItemsValid] = useState(false);

  const {
    selectedResponseMethod,
    deliveryChannels,
    appCommsResponseMethodConfigurations,
    emailResponseMethodConfigurations,
    smsResponseMethodConfigurations,
    voiceResponseMethodConfigurations
  } = useSelector((state: RootState) => state.massComms);

  const [ appCommunicationsResponseItems, setAppCommunicationsResponseItems ] =
    useState<Array<MassCommsAppCommunicationsResponseItem>>(appCommsResponseMethodConfigurations);

  const [ emailResponseItems, setEmailResponseItems ] = useState<Array<MassCommsEmailResponseItem>>(
    emailResponseMethodConfigurations
  );
  const [ smsResponseItems, setSmsResponseItems ] = useState<Array<MassCommsPhoneResponseItem>>(
    smsResponseMethodConfigurations
  );
  const [ voiceResponseItems, setVoiceResponseItems ] = useState<Array<MassCommsPhoneResponseItem>>(
    voiceResponseMethodConfigurations
  );
  const [ activeResponseMethodSettings, setActiveResponseMethodSettings ] = useState<ResponseMethod | undefined>(
    undefined
  );

  const {
    massComms: {
      SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION,
      SET_EMAIL_RESPONSE_METHOD_CONFIGURATION,
      SET_SMS_RESPONSE_METHOD_CONFIGURATION,
      SET_VOICE_RESPONSE_METHOD_CONFIGURATION,
      prePopulateResponseContent,
      changeResponseMethod
    }
  } = useDispatch<Dispatch>();

  useEffect(() => {
    setAppCommunicationsResponseItems(appCommsResponseMethodConfigurations);
    setEmailResponseItems(emailResponseMethodConfigurations);
    setSmsResponseItems(smsResponseMethodConfigurations);
    setVoiceResponseItems(voiceResponseMethodConfigurations);
  }, [
    appCommsResponseMethodConfigurations,
    emailResponseMethodConfigurations,
    smsResponseMethodConfigurations,
    voiceResponseMethodConfigurations
  ]);

  const checkIsResponseItemsValid = useCallback(() => {
    if (selectedResponseMethod === 'NO_RESPONSE') {
      setIsResponseItemsValid(true);
    }

    const isAppCommunicationsItemsValid = deliveryChannels.app ? appCommunicationsResponseItems.every((item, index) => {
      if (selectedResponseMethod === 'CUSTOM_POLL') {
        return checkIfStringNotEmpty(item.buttonLabel)
          && checkIfStringNotEmpty(pollResponses.get(index) || item.responseLabel);
      }

      return checkIfStringNotEmpty(item.buttonLabel);
    }) : true;

    const isEmailItemsValid = deliveryChannels.email ? emailResponseItems.every((item, index) => {
      if (selectedResponseMethod === 'CUSTOM_POLL') {
        return checkIfStringNotEmpty(item.buttonLabel)
          && checkIfStringNotEmpty(pollResponses.get(index) || item.responseLabel);
      }

      return checkIfStringNotEmpty(item.buttonLabel);
    }) : true;

    const isSMSItemsValid = deliveryChannels.sms ? smsResponseItems.every((item, index) => {
      if (selectedResponseMethod === 'CUSTOM_POLL') {
        return checkIfStringNotEmpty(item.replyCode.toString())
          && checkIfStringNotEmpty(pollResponses.get(index) || item.responseLabel);
      }

      return checkIfStringNotEmpty(item.replyCode.toString());
    }) : true;

    const isVoiceItemsValid = deliveryChannels.voice ? voiceResponseItems.every((item, index) => {
      if (selectedResponseMethod === 'CUSTOM_POLL') {
        return checkIfStringNotEmpty(item.replyCode.toString())
          && checkIfStringNotEmpty(pollResponses.get(index) || item.responseLabel);
      }

      return checkIfStringNotEmpty(item.replyCode.toString());
    }) : true;

    // If the selectedResponseMethod is CUSTOM_POLL, we need to ensure that the responseLabels are unique
    const isResponseLabelsUnique = Array.from(pollResponses.values()).every((responseLabel, index, array) => {
      return array.indexOf(responseLabel) === index;
    });

    setIsResponseItemsValid(
      isAppCommunicationsItemsValid &&
      isEmailItemsValid &&
      isSMSItemsValid &&
      isVoiceItemsValid &&
      isResponseLabelsUnique
    );
  }, [
    selectedResponseMethod,
    deliveryChannels.app,
    deliveryChannels.email,
    deliveryChannels.sms,
    deliveryChannels.voice,
    appCommunicationsResponseItems,
    emailResponseItems,
    smsResponseItems,
    voiceResponseItems,
    pollResponses
  ]);

  const handleResponseMethodSettingsRequested = useCallback((responseMethod: ResponseMethod) => {
    setActiveResponseMethodSettings(responseMethod);
  }, []);

  const handleResponseMethodChange = useCallback((responseMethod: ResponseMethod) => {
    changeResponseMethod(responseMethod);

    if (responseMethod !== "NO_RESPONSE") {
      handleResponseMethodSettingsRequested(responseMethod);
    }
  }, [ changeResponseMethod, handleResponseMethodSettingsRequested ]);

  const handleResponseMethodSettingsModalClose = useCallback(() => {
    setActiveResponseMethodSettings(undefined);
  }, []);

  const handleAddNewItemToResponseItems = useCallback((deliveryChannel: string) => {
    if (deliveryChannel === 'app') {
      const lastId = appCommunicationsResponseItems[appCommunicationsResponseItems.length - 1].id || 0;
      SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION([
        ...appCommunicationsResponseItems,
        { id: lastId + 1, buttonLabel: '', buttonColor: theme.colors.purple[10 - appCommunicationsResponseItems.length], safetyStatus: 'NO_MAPPING', responseLabel: 'OTHER' }
      ]);
    }

    if (deliveryChannel === 'email') {
      const lastId = emailResponseItems[emailResponseItems.length - 1].id || 0;
      SET_EMAIL_RESPONSE_METHOD_CONFIGURATION([
        ...emailResponseItems,
        { id: lastId + 1, buttonLabel: '', buttonColor: theme.colors.purple[10 - emailResponseItems.length], safetyStatus: 'NO_MAPPING', responseLabel: 'OTHER' }
      ]);
    }

    if (deliveryChannel === 'sms') {
      const lastId = smsResponseItems[smsResponseItems.length - 1].id || 0;
      const lastReplyCode = smsResponseItems[smsResponseItems.length - 1].replyCode || 0;
      SET_SMS_RESPONSE_METHOD_CONFIGURATION([
        ...smsResponseItems,
        { id: lastId + 1, replyCode: lastReplyCode + 1, safetyStatus: 'NO_MAPPING', responseLabel: 'OTHER' }
      ]);
    }

    if (deliveryChannel === 'voice') {
      const lastId = voiceResponseItems[voiceResponseItems.length - 1].id || 0;
      const lastReplyCode = voiceResponseItems[voiceResponseItems.length - 1].replyCode || 0;
      SET_VOICE_RESPONSE_METHOD_CONFIGURATION([
        ...voiceResponseItems,
        { id: lastId + 1, replyCode: lastReplyCode + 1, safetyStatus: 'NO_MAPPING', responseLabel: 'OTHER' }
      ]);
    }
  }, [ SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION, SET_EMAIL_RESPONSE_METHOD_CONFIGURATION,
    SET_SMS_RESPONSE_METHOD_CONFIGURATION, SET_VOICE_RESPONSE_METHOD_CONFIGURATION, appCommunicationsResponseItems,
    emailResponseItems, smsResponseItems, theme.colors.purple, voiceResponseItems]);

  const handleRemoveItemFromResponseItems = useCallback((
    deliveryChannel: string,
    item: MassCommsEmailResponseItem | MassCommsPhoneResponseItem | MassCommsAppCommunicationsResponseItem
  ) => {
    if (deliveryChannel === 'app') {
      const index = appCommunicationsResponseItems.findIndex((responseItem) => responseItem.id === item.id);
      appCommunicationsResponseItems.splice(index, 1);

      SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION( [ ...appCommunicationsResponseItems ]);
    }

    if (deliveryChannel === 'email') {
      const index = emailResponseItems.findIndex((responseItem) => responseItem.id === item.id);
      emailResponseItems.splice(index, 1);

      SET_EMAIL_RESPONSE_METHOD_CONFIGURATION( [ ...emailResponseItems ]);
    }

    if (deliveryChannel === 'sms') {
      const index = smsResponseItems.findIndex((responseItem) => responseItem.id === item.id);
      smsResponseItems.splice(index, 1);

      SET_SMS_RESPONSE_METHOD_CONFIGURATION( [ ...smsResponseItems ]);
    }

    if (deliveryChannel === 'voice') {
      const index = voiceResponseItems.findIndex((responseItem) => responseItem.id === item.id);
      voiceResponseItems.splice(index, 1);

      SET_VOICE_RESPONSE_METHOD_CONFIGURATION( [ ...voiceResponseItems ]);
    }
  }, [SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION, SET_EMAIL_RESPONSE_METHOD_CONFIGURATION,
    SET_SMS_RESPONSE_METHOD_CONFIGURATION, SET_VOICE_RESPONSE_METHOD_CONFIGURATION, appCommunicationsResponseItems,
    emailResponseItems, smsResponseItems, voiceResponseItems]);

  const handleResponseItemChange = useCallback((
    deliveryChannel: string,
    responseItem: MassCommsEmailResponseItem & MassCommsPhoneResponseItem
  ) => {
    const listOfItemArrays: Record<string, Array<MassCommsEmailResponseItem | MassCommsPhoneResponseItem>> = {
      'app': appCommunicationsResponseItems,
      'email': emailResponseItems,
      'sms': smsResponseItems,
      'voice': voiceResponseItems
    };

    const itemsArray: Array<MassCommsEmailResponseItem | MassCommsPhoneResponseItem> =
      listOfItemArrays[deliveryChannel];

    const currentResponseItemIndex = itemsArray.findIndex((item) => item.id === responseItem.id);

    if (~currentResponseItemIndex) {
      const updatedResponses = itemsArray;
      itemsArray[currentResponseItemIndex] = responseItem;

      if (deliveryChannel === 'app') {
        setAppCommunicationsResponseItems(updatedResponses as MassCommsAppCommunicationsResponseItem[]);
      }

      if (deliveryChannel === 'email') {
        setEmailResponseItems(updatedResponses as MassCommsEmailResponseItem[]);
      }

      if (deliveryChannel === 'sms') {
        setSmsResponseItems(updatedResponses as MassCommsPhoneResponseItem[]);
      }

      if (deliveryChannel === 'voice') {
        setVoiceResponseItems(updatedResponses as MassCommsPhoneResponseItem[]);
      }
    }

    checkIsResponseItemsValid();
  }, [setAppCommunicationsResponseItems, appCommunicationsResponseItems, checkIsResponseItemsValid,
    emailResponseItems, smsResponseItems, voiceResponseItems]);

  const handlePollResponseChange = useCallback((response: string, index) => {
    const updatedPollResponseItems = pollResponses.set(index, response);
    setPollResponses(updatedPollResponseItems);
    checkIsResponseItemsValid();

    forceUpdate();
  }, [ checkIsResponseItemsValid, forceUpdate, pollResponses ]);

  const handleSaveSettings = useCallback(() => {
    const AppCommunicationsResponsesWithMappedPollResponses = appCommunicationsResponseItems.map((item, index) => {
      const responseLabel = pollResponses.get(index) || item.responseLabel;
      return {
        ...item,
        responseLabel
      };
    });

    const emailResponsesWithMappedPollResponses = emailResponseItems.map((item, index) => {
      const responseLabel = pollResponses.get(index) || item.responseLabel;
      return {
        ...item,
        responseLabel
      };
    });

    const smsResponsesWithMappedPollResponses = smsResponseItems.map((item, index) => {
      const responseLabel = pollResponses.get(index) || item.responseLabel;
      return {
        ...item,
        responseLabel
      };
    });

    const voiceResponsesWithMappedPollResponses = voiceResponseItems.map((item, index) => {
      const responseLabel = pollResponses.get(index) || item.responseLabel;
      return {
        ...item,
        responseLabel
      };
    });

    setAppCommunicationsResponseItems(AppCommunicationsResponsesWithMappedPollResponses);
    setEmailResponseItems(emailResponsesWithMappedPollResponses);
    setSmsResponseItems(smsResponsesWithMappedPollResponses);
    setVoiceResponseItems(voiceResponsesWithMappedPollResponses);

    SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION(AppCommunicationsResponsesWithMappedPollResponses);
    SET_EMAIL_RESPONSE_METHOD_CONFIGURATION(emailResponsesWithMappedPollResponses);
    SET_SMS_RESPONSE_METHOD_CONFIGURATION(smsResponsesWithMappedPollResponses);
    SET_VOICE_RESPONSE_METHOD_CONFIGURATION(voiceResponseItems);

    prePopulateResponseContent();
    handleResponseMethodSettingsModalClose();
  }, [
    SET_APP_COMMUNICATIONS_METHOD_CONFIGURATION,
    SET_EMAIL_RESPONSE_METHOD_CONFIGURATION,
    SET_SMS_RESPONSE_METHOD_CONFIGURATION,
    SET_VOICE_RESPONSE_METHOD_CONFIGURATION,
    appCommunicationsResponseItems,
    emailResponseItems,
    handleResponseMethodSettingsModalClose,
    pollResponses,
    prePopulateResponseContent,
    smsResponseItems,
    voiceResponseItems
  ]);

  return (
    <ResponseMethodsComponent
      selectedResponseMethod={ selectedResponseMethod }
      activeResponseMethodSettings={ activeResponseMethodSettings }
      deliveryChannels={ deliveryChannels }
      appCommunicationsResponseItems={ appCommsResponseMethodConfigurations }
      emailResponseItems={ emailResponseMethodConfigurations }
      smsResponseItems={ smsResponseMethodConfigurations }
      voiceResponseItems={ voiceResponseMethodConfigurations }
      pollResponses={ pollResponses }
      isResponseItemsValid={ isResponseItemsValid }
      onResponseMethodChanged={ handleResponseMethodChange }
      onResponseMethodSettingsPageRequested={ handleResponseMethodSettingsRequested }
      onResponseMethodSettingsModalClosed={ handleResponseMethodSettingsModalClose }
      onAddNewResponseMethodRequested={ handleAddNewItemToResponseItems }
      onRemoveResponseMethodRequested={ handleRemoveItemFromResponseItems }
      onResponseItemChanged={ handleResponseItemChange }
      onSaveSettingsRequested={ handleSaveSettings }
      onPollResponseChanged={ handlePollResponseChange }
    />
  );
};

export default ResponseMethods;
