import { createStyles } from "@mantine/core";

export const useRoleRowTemplateStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center'
  },
  container: {
    all: 'unset',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
    cursor: 'pointer'
  },
  title: {
    fontSize: theme.fontSizes.md,
    cursor: "pointer",
    display: 'flex',
    width: '100%'
  },
  content: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '8px'
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  },
  contentItemTitle: {
    fontWeight: theme.other.fw.regular,
    fontFamily: theme.fontFamily,
    fontSize: theme.fontSizes.sm,
    lineHeight: "150%",
    color: theme.other.semantic.text.secondary
  },
  contentItemValue: {
    fontFamily: theme.fontFamily,
    fontSize: theme.fontSizes.sm,
    lineHeight: "150%"
  },
  link:{
    cursor: "pointer",
    color: theme.colors.white[0],
    textDecoration: "none",
    width: '100%'
  },
  deleteButtonContainer: {
    width: '48px'
  },
  deleteButton: {
    all: 'unset',
    backgroundColor: 'unset !important',
    padding: 0,
    width: 'fit-content',
    cursor: 'pointer',
    '&:hover' : {
      backgroundColor: 'unset !important'
    },
    '&:disabled' : {
      backgroundColor: 'unset !important'
    },
    '&:disabled:hover' : {
      backgroundColor: 'unset !important'
    }
  }
}));
