export enum ToggleableLayerType {
  People = 'People',
  Locations = 'Locations',
  Airports = 'Airports',
  Hotels = 'Hotels',
  Police = 'Police',
  Transportation = 'TransportationHub',
  BusinessAccommodation = 'BusinessAccommodation',
  AssetLocations = 'AssetLocations',
  BusStations = 'BusStations',
  CarHires = 'CarHires',
  Camps = 'Camps',
  Embassy = 'Embassy',
  Hospitals = 'Hospitals',
  Offices = 'Offices',
  Ports = 'Ports',
  RailwayStations = 'RailwayStations',
  Suppliers = 'Suppliers',
  EvacuationCenters = 'EvacuationCenters',
  CustomerGeneralAccommodations = 'CustomerGeneralAccommodations',
  CustomerHeliports = 'CustomerHeliports',
  CustomerHarbors = 'CustomerHarbors',
  CustomerRailStations = 'CustomerRailStations',
  CustomerBusStations = 'CustomerBusStations',
  CustomerCarHires = 'CustomerCarHires',
  CustomerEmbarkationPoints = 'CustomerEmbarkationPoints',
  AllCommonLocations = 'AllCommonLocations',
  CommonRegions = 'CommonRegions',
  Geofence = 'Geofence',
  TravelCountriesView = 'TravelCountriesView',
  TravelCountryDetailsView = 'TravelCountryDetailsView',
  CustomSites = 'CustomSites',
  RiskAlerts = 'RiskAlerts',
}

export enum ToggleableContextLayers {
  CountryRiskLevel = 'CountryRiskLevel',
  Traffic = 'Traffic',
  Weather = 'Weather',
  Wind = 'Wind',
  Radar = 'Radar',
  Cyclones = 'Cyclones',
}
