import { createStyles } from "@mantine/core";

export const useUploadedFilesListStyles = createStyles(() => ({
  tabContainer:{
    padding: '56px 0'
  },
  btn: {
    all: 'unset',
    backgroundColor: 'unset !important',
    padding: 0,
    width: 'fit-content',
    cursor: 'pointer'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%'
  }
}));
