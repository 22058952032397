import { Feature, FeatureCollection, Point } from "geojson";

import {
  IndoorPersonAssetRankingSummary,
  IndoorPersonLocationProperties
} from "@/tenant-context/plugin-indoors/types/indoor-map.types";

export type IndoorState = {
  isSiteLoaded: boolean
  currentSiteId?: string
  level?: number,
  indoorPeople: FeatureCollection<Point, IndoorPersonAssetRankingSummary>,
  indoorPeopleLocation: FeatureCollection<Point, IndoorPersonLocationProperties>,
}

export const indoorState: IndoorState = {
  isSiteLoaded: false,
  currentSiteId: undefined,
  level: undefined,
  indoorPeople: {
    type: "FeatureCollection",
    features: [] as Feature<Point, IndoorPersonAssetRankingSummary>[]
  },
  indoorPeopleLocation: {
    type: "FeatureCollection",
    features: [] as Feature<Point, IndoorPersonLocationProperties>[]
  }
};
