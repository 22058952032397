import { Checkbox, Stack, StackProps } from "@mantine/core";
import { FC } from "react";

import { ProviderCategory } from "@/tenant-context/control-risk-config/types/risk";

type Props = StackProps & {
  items: Array<ProviderCategory>;
  onCategoryToggle: React.ChangeEventHandler<HTMLInputElement>;
  disabledRiskFilterTypes: Set<string>;
};

/** General component for displaying list of checkboxes */
const ProviderEvents: FC<Props> = ({
  items,
  onCategoryToggle,
  disabledRiskFilterTypes,
  ...stackProps
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Stack { ...stackProps }>
      { items.map(({ id, name, categoryId }) => (
        <Checkbox
          key={ id }
          value={ id }
          checked={ !disabledRiskFilterTypes.has(categoryId.toString()) }
          onChange={ onCategoryToggle }
          label={ name }
        />
      )) }
    </Stack>
  );
};

export default ProviderEvents;
