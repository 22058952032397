/* eslint-disable react/jsx-props-no-spreading */
import { Select, TextInput } from "@mantine/core";
import { FC, useCallback, useContext, useMemo } from "react";
import { RegisterOptions } from "react-hook-form";
import { useSelector } from "react-redux";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import ModalControlGroup from "@/common/components/ModalControlGroup";
import { RootState } from "@/core/store";
import { unshiftDefaultItem } from '@/tenant-context/common/util/profile-reference-data';
import {
  AttributesModalContext
} from "@/tenant-context/control-profile/components/modals/ProfileProfileModals/AttributesModal/context/AttributesModal.context";
import {
  useEmergencyContactModalContentStyles
} from "@/tenant-context/control-profile/components/ui/EmergencyContactModalContent/EmergencyContactModalContent.style";
import ProfileLoadingOverlay from '@/tenant-context/control-profile/components/ui/ProfileLoadingOverlay';
import { ProfilePersonalAttributes } from "@/tenant-context/control-profile/types/profile";

export type AttributesModalFormData = Pick<ProfilePersonalAttributes,
  | 'eyeColour'
  | 'hairColour'
  | 'ethnicity'
  | 'build'> & {
  dateOfBirth: Date
};

const AttributesModal: FC = () => {
  const { classes } = useEmergencyContactModalContentStyles();
  const {
    onSubmit,
    formControls: { register, setValue, formState: { errors, isDirty, isValid } }
  } = useContext(AttributesModalContext);

  const personalAttributes = useSelector((state: RootState) => state.profile.personalAttributes);
  const referenceData = useSelector((state: RootState) => state.profile.referenceData);

  const build = useMemo(() => {
    return unshiftDefaultItem(referenceData?.build?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    }));
  }, [ referenceData ]);

  const handleBuildChange = useCallback((val) => {
    setValue('build', val, { shouldDirty: true });
  }, [setValue]);

  const eyeColor = useMemo(() => {
    return unshiftDefaultItem(referenceData?.eyeColor?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    }));
  }, [ referenceData ]);

  const handleEyeColorChange = useCallback((val) => {
    setValue('eyeColour', val, { shouldDirty: true });
  }, [setValue]);

  const hairColor = useMemo(() => {
    return unshiftDefaultItem(referenceData?.hairColor?.map((reference) => {
      return { value: reference?.data, label: reference.data };
    }));
  }, [ referenceData ]);

  const handleHairColorhange = useCallback((val) => {
    setValue('hairColour', val, { shouldDirty: true });
  }, [setValue]);

  if (typeof personalAttributes === 'undefined') {
    return (
      <p>Loading...</p>
    );
  }

  const renderTextInput = (
    label: string,
    id: keyof ProfilePersonalAttributes,
    options: RegisterOptions
  ) => (
    <TextInput
      label={ label }
      defaultValue={ personalAttributes?.[id]?.toString() }
      error={ errors?.[id]?.message }
      { ...register(id, addErrorMessages(label, options)) }
    />
  );

  return (
    <form onSubmit={ onSubmit }>

      <ProfileLoadingOverlay/>
      <div className={ classes.grid }>
        <Select
          { ...register('eyeColor') }
          onChange={ handleEyeColorChange }
          data={ eyeColor ? eyeColor : [] }
          label="Eye Colour"
          defaultValue={ personalAttributes?.eyeColour }
          error={ errors?.eyeColour?.message }
        />

        <Select
          { ...register('hairColour') }
          onChange={ handleHairColorhange }
          data={ hairColor ? hairColor : [] }
          label="Hair Colour"
          defaultValue={ personalAttributes?.hairColour }
          error={ errors?.hairColour?.message }
        />

        { renderTextInput('Ethnicity', 'ethnicity', {
          maxLength: 128
        }) }

        <Select
          { ...register('build') }
          onChange={ handleBuildChange }
          data={ build ? build : [] }
          label="Build"
          defaultValue={ personalAttributes?.build }
          error={ errors?.build?.message }
        />
      </div>

      <ModalControlGroup
        primaryButtonDisabled={ !isValid || !isDirty }
      />
    </form>
  );
};

export default AttributesModal;
