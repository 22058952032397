// This is false-positive for tabMap
/* eslint-disable react/no-multi-comp */
import { FC, ReactElement, useContext } from "react";

import { ProfileDocumentsModalContext } from "@/tenant-context/control-profile/components/modals/ProfileDocumentsModal/ProfileDocumentsModal.context";
import ProfileDocumentsBasicInfoTab from "@/tenant-context/control-profile/components/tabs/ProfileDocumentsBasicInfoTab";
import ProfileDocumentsFilesTab from "@/tenant-context/control-profile/components/tabs/ProfileDocumentsFilesTab";
import { ProfileDocumentsTab } from "@/tenant-context/control-profile/components/ui/ProfileDocumentsTabs/ProfileDocumentsTabs.component";

const SelectedProfileDocumentsTab: FC = () => {
  const {
    activeTab
  } = useContext(ProfileDocumentsModalContext);

  // Mapping profile tabs to render functions
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tabMap: Record<ProfileDocumentsTab, () => ReactElement<any, any> | null> = {
    [ProfileDocumentsTab.Files]: () => ( <ProfileDocumentsFilesTab /> ),
    [ProfileDocumentsTab.BasicInfo]: () => ( <ProfileDocumentsBasicInfoTab /> )
  };

  return tabMap[activeTab]();
};

export default SelectedProfileDocumentsTab;