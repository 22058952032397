import { FC, useCallback, useEffect, useState } from "react";

import TextTruncate from "./TextTruncate.component";

const DOTS_COUNT = 3;

type Props = {
  text?:string,
  maxTextLength:number
}

const TextTruncateContainer: FC<Props> = ({
  text,
  maxTextLength
}) => {
  const [isTruncated, setIsTruncated ] = useState(false);
  const [isFullTextShown, setIsFullTextShown] = useState(true);
  const [visibleText, setVisibleText] = useState<string>('');

  const handleOnClick = useCallback(() => {
    if (!text){
      return;
    }

    if (!(text.length > maxTextLength - DOTS_COUNT)){
      setIsFullTextShown(true);
      setVisibleText(text);

      return;
    }

    setIsFullTextShown(false);
    setIsTruncated((prevIsTruncated) => {
      if (!prevIsTruncated){
        const clippedText = text.substring(0, maxTextLength - DOTS_COUNT) + '...';
        setVisibleText(clippedText);
      } else{
        setVisibleText(text);
      }

      return !prevIsTruncated;
    });
  }, [maxTextLength, text]);

  useEffect(()=> {
    handleOnClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <TextTruncate
      isTruncated={ isTruncated }
      isFullTextShown={ isFullTextShown }
      text={ visibleText }
      onClickReadMoreText={ handleOnClick }/>
  );
};

export default TextTruncateContainer;
