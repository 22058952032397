/* eslint-disable max-lines */
import { TickFormatter } from "@visx/axis";
import { AxisScaleOutput } from '@visx/axis';
import { Bounds } from "@visx/brush/lib/types";
import { curveStepAfter } from '@visx/curve';
import { GlyphSquare } from '@visx/glyph';
import { LinearGradient } from "@visx/gradient";
import { Group } from "@visx/group";
import { ScaleConfig, scaleLinear, scaleTime } from '@visx/scale';
import { Line } from '@visx/shape';
import {
  AreaSeries,
  Axis,
  Grid,
  Tooltip,
  XYChart
} from "@visx/xychart";
import { RenderTooltipParams } from "@visx/xychart/lib/components/Tooltip";
import { ReactNode } from 'react';
import { FC, useMemo } from "react";

import { formatDate } from "@/common/util/format/date";
import { formatProbability,NumberValue } from "@/common/util/format/probability";
import { AssetRankingEvent } from "@/tenant-context/common/types/asset-ranking";
import EventInterceptor from "@/tenant-context/control-location-graph/components/LocationGraph/EventInterceptor";
import { dummyEvent } from "@/tenant-context/control-location-graph/components/LocationGraph/LocationGraph.config";
import { useLocationGraphStyles } from '@/tenant-context/control-location-graph/components/LocationGraph/LocationGraph.styles';
import LocationGraphTooltip from "@/tenant-context/control-location-graph/components/LocationGraphTooltip";
import TimeTravelControls from "@/tenant-context/control-location-graph/components/TimeTravelControls";
import { AssetRankingEventGroup } from "@/tenant-context/control-location-graph/util/groupBy";
import { MAX_RANK } from "@/tenant-context/core/config/ranking";

import BrushChart from './Brush';

export const background2 = '#af8baf';

const highestNowVisibilityTreshold = 1;
const lowestNowVisibilityTreshold = .001;

const BIG_GLYPH_BORDER_SIZE = 24;
const SMALL_GLYPH_BORDER_SIZE = 6;
const BRUSH_WIDTH_OFFSET = 8;

const accessors = {
  xAccessor: (d: AssetRankingEvent) => (d && d.rankTime) ?? 0,
  yAccessor: (d: AssetRankingEvent) => d && d.currentRank
};

const renderTooltip = ({
  tooltipData,
  colorScale
}: RenderTooltipParams<AssetRankingEvent>): ReactNode => {
  if (!colorScale || !tooltipData) {
    return null;
  }

  return (
    <LocationGraphTooltip
      accessors={ accessors }
      colorScale={ colorScale }
      tooltipData={ tooltipData }
    />
  );
};

const CHART_Y_PADDING = 2;
const CHART_X_PADDING = 28;

type Props = {
  height: number,
  width: number,
  brushData: AssetRankingEventGroup[],
  chartData: AssetRankingEventGroup[],
  brushDomain: Bounds | null,
  setBrushDomain: (domain: Bounds | null) => void,
  startTime: number,
  endTime: number,
  glyphData: AssetRankingEventGroup[],
  onGlyphClick: (event: AssetRankingEvent, serializedKey: string) => void,
  now: number,
  onTimeTravelForwardClick: () => void,
  onTimeTravelBackwardClick: () => void,
  timeScale: string
}

const LocationGraph: FC<Props> = ({
  width,
  height,
  startTime,
  endTime,
  brushData,
  chartData,
  brushDomain,
  setBrushDomain,
  glyphData,
  onGlyphClick,
  now,
  onTimeTravelForwardClick,
  onTimeTravelBackwardClick,
  timeScale
}) => {
  const tickFormatterY: TickFormatter<NumberValue> = formatProbability;

  const { classes } = useLocationGraphStyles();

  const xDomain = useMemo(() => [
    brushDomain?.x0 ?? startTime,
    brushDomain?.x1 ?? endTime
  ], [brushDomain?.x0, brushDomain?.x1, endTime, startTime]);

  const xScaleConfig: ScaleConfig<AxisScaleOutput> = useMemo(
    () => ({
      type: 'utc',
      domain: xDomain,
      clamp: true
    }),
    [xDomain]
  );

  const yScaleConfig: ScaleConfig<AxisScaleOutput> = useMemo(
    () => ({
      type: 'linear',
      domain: [0, MAX_RANK],
      zero: false,
      clamp: true
    }),
    []
  );

  const yScale = useMemo(
    () => scaleLinear(yScaleConfig),
    [yScaleConfig]
  );

  const xScale = useMemo(
    () => scaleTime(xScaleConfig),
    [xScaleConfig]
  );

  const chartMargin = {
    top: 24 ,
    bottom: 50,
    left: 40,
    right: 0
  };

  const chartHeight = height - CHART_Y_PADDING;
  const chartWidth = width - CHART_X_PADDING;
  const chartContentHeight = chartHeight - chartMargin.top - chartMargin.bottom;
  const chartContentWidth = chartWidth - chartMargin.left;

  const interpolatedNow = (xScale(now)?.valueOf() ?? 0);
  const currentTimeMarkerX: number = interpolatedNow * chartContentWidth;

  const isCurrentTimeMarkerVisible =
    interpolatedNow > lowestNowVisibilityTreshold
    && interpolatedNow < highestNowVisibilityTreshold;

  const calculateGlyphTopOffset = (
    glyphRank: number
  ) => (
    yScale(MAX_RANK - glyphRank)
      ?.valueOf()
      || 0
  ) * chartContentHeight;

  // TODO return this to Y axis
  // These are % values for the left axis
  // eslint-disable-next-line no-magic-numbers
  // const tickValues = [20, 40, 60, 80, 100].map(
  //   (val) => (MAX_RANK / 100) * val
  // );

  const dateNow = useMemo(() => new Date(now), [now]);

  return (
    <div className={ classes.root }>
      <XYChart
        width={ chartWidth }
        height={ chartHeight }
        margin={ chartMargin }

        xScale={ xScaleConfig }
        yScale={ yScaleConfig }
        captureEvents={ false }
      >
        { /* This gradient is used as fill for areas and the brush */ }
        <LinearGradient
          from="#CAE8F6"
          to="#C4C4C400"
          id="lg-area-gradient"
        />

        <Axis
          orientation="left"
          tickFormat={ tickFormatterY }
          hideZero
          numTicks={ 5 }
        />

        <Axis
          orientation="bottom"
          label={ `TIME [ IN ${timeScale.toUpperCase()} ]` }
          numTicks={ 16 }
        />

        { /* Axis visuals, grid, labels */ }
        <g>
          <text
            className={ classes.axisLabel }
            x={ `-${chartHeight / 2}` }
            y="10"
            transform="translate(0 45) rotate(-90)"
            fontSize={ 10 }
          >
            PROBABILITY
          </text>

          <Grid
            columns={ false }
          />
        </g>

        { /* Data visualisation itself */ }
        { chartData.length && chartData.map((group) => (
          <AreaSeries
            fillOpacity={ 1 }
            key={ group.serializedKey }
            dataKey={ group.serializedKey }
            data={ group.events }
            curve={ curveStepAfter }
            xAccessor={ accessors.xAccessor }
            yAccessor={ accessors.yAccessor }
            fill="url(#lg-area-gradient)"
            renderLine={ false }
          />
        )) }

        { /* Default behaviour of VISX is not showing axis when there is no data */ }
        { /* This gives the chart a dummy data point for the axis to get shown */ }
        { !chartData.length && (
          <AreaSeries
            fillOpacity={ 1 }
            dataKey={ 'DUMMY_EVENT_FOR_EMPTY_AXIS' }
            data={ [dummyEvent] }
            curve={ curveStepAfter }
            xAccessor={ accessors.xAccessor }
            yAccessor={ accessors.yAccessor }
            fill="url(#lg-area-gradient)"
            renderLine={ false }
          />
        ) }

        { /* This interceptor captures all the events going to the stuff rendered above */ }
        { /* All the stuff below this component in this file is rendered above it in the SVG */ }
        { /* Therefore, events can impact the stuff below directly and are not intercepted */ }
        <EventInterceptor
          chartMargin={ chartMargin }
          width={ chartWidth }
          height={ chartHeight }
        />

        { /* "NOW" line and glyphs */ }
        { isCurrentTimeMarkerVisible && (
          <Group
            left={ currentTimeMarkerX + chartMargin.left }
            className={ classes.nowBlock }
          >
            <text textAnchor="middle">
              <tspan x="0" dy="1.2em" >
                { /* { dateNow.toUTCString() } */ }

                { formatDate(dateNow) }
              </tspan>
            </text>

            <Line
              className={ classes.nowBlockLine }
              strokeWidth={ 2 }
              from={ {
                x: 0,
                y: chartMargin.top
              } }
              to={ {
                x: 0,
                y: height - chartMargin.bottom - CHART_Y_PADDING
              } }
            />

            { /* Squares for all locations */ }
            { glyphData.map(({
              serializedKey,
              events: [event]
            }) => (
              <Group
                key={ serializedKey }
                left={ 0 }
                top={ chartMargin.top + calculateGlyphTopOffset(event.currentRank) }
              >
                <GlyphSquare
                  className={ classes.nowBlockGlyphBigSquare }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => onGlyphClick(event, serializedKey) }
                  cursor='pointer'
                  size={ BIG_GLYPH_BORDER_SIZE * BIG_GLYPH_BORDER_SIZE }
                />

                <GlyphSquare
                  className={ classes.nowBlockGlyphSmallSquare }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => onGlyphClick(event, serializedKey) }
                  cursor='pointer'
                  fill="#fff"
                  size={ SMALL_GLYPH_BORDER_SIZE * SMALL_GLYPH_BORDER_SIZE }
                />
              </Group>
            )) }
          </Group>
        ) }

        { /* Arrows to go left and right on the timeline */ }
        <Group top={ 5 } left={ -15 }>
          <TimeTravelControls
            chartContentHeight={ chartContentHeight }
            chartContentWidth={ chartContentWidth - 20 }
            chartMargin={ chartMargin }
            onForwardClick={ onTimeTravelForwardClick }
            onBackwardClick={ onTimeTravelBackwardClick }
          />
        </Group>
      

        { /* Tooltip with event details */ }
        { /* Not rendered if no data (== if only dummy datum in chart) */ }
        { chartData.length && (
          <Tooltip<AssetRankingEvent>
            showVerticalCrosshair
            showSeriesGlyphs
            snapTooltipToDatumX
            snapTooltipToDatumY
            verticalCrosshairStyle={ {
              color: '#000',
              stroke: '#000',
              strokeWidth: .5,
              opacity: 1
            } }
            renderTooltip={ renderTooltip }
          />
        ) }
      </XYChart>

      { /* Brush to control zoom */ }
      <BrushChart
        width={ width - BRUSH_WIDTH_OFFSET }
        height={ 30 }
        data={ brushData }
        onChange={ setBrushDomain }
        startTime={ startTime }
        endTime={ endTime }
      />
    </div>
  );
};

export default LocationGraph;
