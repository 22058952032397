import { ColDef } from "ag-grid-community";
import { forwardRef, ForwardRefRenderFunction, useCallback } from "react";

import ServerDataGrid from "@/common/components/AgGrid/ServerSide/ServerDataGrid.container";
import usePermission from '@/common/hooks/usePermission';
import { DataGrid, GridParams } from "@/common/types/ag-grid";
import { RequiredPolicies } from '@/common/types/permission-control';
import GenericProfileTab from "@/tenant-context/control-profile/components/ui/GenericProfileTab";
import ProfileTabActionButton from "@/tenant-context/control-profile/components/ui/ProfileTabActionButton";
import { ProfileGroup } from "@/tenant-context/control-profile/types/profile";

type Props = {
  onAddClick: () => void,
  onUnauthorizedAction: () => void,
  requiredPolicies?: RequiredPolicies
  columnDefs: ColDef[],
  getData: (payload: {
    gridParams: GridParams,
  }) => Promise<{items: Array<ProfileGroup>}>,
}

const ProfileGroupsTab: ForwardRefRenderFunction<DataGrid, Props>  = ({
  onAddClick,
  onUnauthorizedAction,
  requiredPolicies,
  columnDefs,
  getData
}, ref) => {

  const isAuthorizedToEdit = usePermission( requiredPolicies );

  const renderAddDocumentButton = useCallback(() => (
    <ProfileTabActionButton
      onClick={ isAuthorizedToEdit ? onAddClick : onUnauthorizedAction }
      requiredPolicies={ requiredPolicies }
    >
      Add to Group
    </ProfileTabActionButton>
  ), [isAuthorizedToEdit, onAddClick, onUnauthorizedAction, requiredPolicies]);

  return (
    <GenericProfileTab
      heading="People Group"
      renderAction={ renderAddDocumentButton }
    >
      <div className="ag-theme-alpine-dark ag-theme-rt">
        <ServerDataGrid
          id={ "profile-group-grid" }
          columnDefs={ columnDefs }
          ref={ ref }
          getData={ getData }
        />
      </div>
    </GenericProfileTab>
  );

};

export default forwardRef(ProfileGroupsTab) as (
    props: Props & React.RefAttributes<DataGrid>
) => JSX.Element;