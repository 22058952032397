import { callApi } from "@/common/util/api";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import { AssetItem, AssetsList } from "../types/assets";

export const assetsApi = {
  getAssetsListData: function(
    page = 0,
    size = 10,
    sort?: SortData,
    query?: string
  ) {
    return callApi<PaginatedResult<AssetsList>>(
      `managed/icm-service/v1/assets?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${query ? '&search=(' + query + ')&' : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListAsset'
        },
        method: 'get'
      }
    );
  },
  getAssetById: function(id: string) {
    return callApi<AssetItem>(
      `managed/icm-service/v1/assets/${id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetAsset'
        },
        method: 'get'
      }
    );
  },
  deleteAsset: function(id: string) {
    return callApi<AssetItem>(
      `managed/icm-service/v1/assets/${id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeleteAsset'
        },
        method: 'delete'
      }
    );
  },
  saveAsset: function(params: AssetItem) {
    return callApi(
      `managed/icm-service/v1/assets`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateAsset'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  },
  updateAsset: function (params: AssetItem) {
    return callApi(
      `managed/icm-service/v1/assets/${params.tid}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateAsset'
        },
        method: 'post',
        body: JSON.stringify(params)
      }
    );
  }
};