import { MIME_TYPES } from '@mantine/dropzone';

export const APP_COMMS_ATTACHMENT_MAX_FILE_SIZE = 1048576;
export const APP_COMMS_ATTACHMENT_MAX_FILE_COUNT = 10;
export const APP_COMMS_ATTACHMENT_TYPES = [
  MIME_TYPES.jpeg,
  MIME_TYPES.png,
  MIME_TYPES.gif,
  MIME_TYPES.webp,
  'image/bmp',
  MIME_TYPES.mp4,
  'audio/mpeg',
  MIME_TYPES.pdf
];

export const APP_COMMS_ATTACHMENT_TYPES_HUMAN_READABLE = `${ MIME_TYPES.jpeg },  ${ MIME_TYPES.png },  ${ MIME_TYPES.gif },  ${ MIME_TYPES.webp },  image/bmp,  ${ MIME_TYPES.mp4 },  audio/mp3,  ${ MIME_TYPES.pdf }`;
