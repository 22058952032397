import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";


type Props = {
  isDropdownExpanded: boolean,
  type: 'muster' | 'people'
}

export const usePeopleInfoBoxStyles = createStyles((theme: MantineTheme, props: Props) => ({
  wrapper: {
    display: 'flex'
  },

  infoBox: {
    display: "flex",
    border: `1px solid ${theme.colors.neutral[6]}`,
    boxSizing: 'border-box',
    boxShadow: "0 1px 8px rgba(0, 0, 0, 0.05)",
    minHeight: '66px',
    maxHeight: "66px",
    height: '100%',
    backgroundColor: theme.colors.neutral[8],
    transitionDuration: '450ms'
  },

  infoDiv: {
    display: 'flex',
    flex: 1,
    gap: 8,
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    width: '75px',
    padding: '8px 4px 8px 8px'
  },

  onsiteCountExpanded: {
    display: "flex",
    boxSizing: 'border-box',
    boxShadow: "0 1px 8px rgba(0, 0, 0, 0.05)",
    height: '100%',
    width: 490,
    backgroundColor: props.type === 'muster' ? theme.colors.neutral[8] : theme.colors.neutral[9],
    transitionDuration: '450ms',
    overflow: 'hidden',
    '*': {
      opacity: 1
    }
  },

  onsiteCount: {
    display: 'flex',
    backgroundColor: theme.colors.neutral[8],
    width: 0,
    transitionDuration: '450ms',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: `${theme.colors.neutral[8]} !important`
    }
  },

  mainCountDiv: {
    display: 'flex',
    paddingLeft: 8,
    flex: 1,
    gap: 8,
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    width: '135px',
    minWidth: '135px',
    padding: '8px 4px 8px 8px',
    backgroundColor: theme.colors.neutral[8]
  },

  countContainer: {
    display: 'flex',
    alignItems: 'center'
  },

  countDiv: {
    display: 'flex',
    paddingLeft: 8,
    flex: 1,
    gap: 8,
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    width: '135px',
    minWidth: '135px',
    padding: '8px 4px 8px 8px',
    backgroundColor: props.isDropdownExpanded ? theme.colors.neutral[9] : theme.colors.neutral[8]
  },

  title: {
    color: theme.other.semantic.label[3],
    letterSpacing: '0.5px',
    fontFamily:theme.fontFamily,
    fontSize: theme.fontSizes.xs,
    lineHeight: "18px",
    textTransform: "uppercase"
  },

  regionCount: {
    color: theme.other.semantic.accessibility.outer,
    fontSize: theme.fontSizes.md,
    fontFamily:theme.fontFamily
  },

  onsiteCountItem: {
    fontSize: theme.fontSizes.md,
    fontFamily:theme.fontFamily,
    display: 'flex',
    alignItems: 'center'
  },

  dropdownIconWrapper: {
    all: "unset",
    fontFamily: theme.fontFamily,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    backgroundColor: theme.colors.neutral[7],
    margin: 0,
    cursor: "pointer"
  },

  dividerContainer: {
    margin: '8px 0'
  },

  dropdownHidden: {
    all: "unset",
    fontFamily: theme.fontFamily,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 0,
    overflow: 'hidden',
    backgroundColor: theme.colors.neutral[7],
    margin: '-8px -4px -8px 0',
    cursor: "pointer"
  },

  dropdownArrowIcon: {
    transform: props.isDropdownExpanded ? 'rotate(270deg)' : 'rotate(90deg)',
    transition: 'transform var(--default-transition-speed)'
  },

  countBadge: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginRight: 10,

    '&.active': {
      backgroundColor: theme.colors.risk[1]
    },

    '&.warning': {
      backgroundColor: theme.colors.risk[3]
    },

    '&.danger': {
      backgroundColor: theme.colors.risk[4]
    },

    '&.hidden': {
      display: 'none !important'
    }
  }
}));
