import { PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import { MassCommsSummaryChartDataItem } from "@/tenant-context/control-mass-comms/types/MassComms.types";

import MassCommsSummaryGraphComponent from "./MassCommsSummaryGraph.component";

const MassCommsSummaryGraph: FC = () => {

  const [_activeField, setActiveField] = useState<string | undefined>(undefined);

  const {
    massComms: {
      receiveCountByCommunicationChannel
    }
  } = useSelector((state: RootState) => state);

  const summaryData = useMemo(() => ([
    { field: 'app', count: receiveCountByCommunicationChannel.app },
    { field: 'sms', count: receiveCountByCommunicationChannel.sms },
    { field: 'voice', count: receiveCountByCommunicationChannel.voice },
    { field: 'email', count: receiveCountByCommunicationChannel.email },
    { field: 'appAndEmail', count: receiveCountByCommunicationChannel.appAndEmail },
    { field: 'appAndSms', count: receiveCountByCommunicationChannel.appAndSms },
    { field: 'appAndVoice', count: receiveCountByCommunicationChannel.appAndVoice },
    { field: 'appAndEmailAndSms', count: receiveCountByCommunicationChannel.appAndEmailAndSms },
    { field: 'appAndEmailAndVoice', count: receiveCountByCommunicationChannel.appAndEmailAndVoice },
    { field: 'appAndSmsAndVoice', count: receiveCountByCommunicationChannel.appAndSmsAndVoice },
    { field: 'emailAndSmsAndVoice', count: receiveCountByCommunicationChannel.emailAndSmsAndVoice },
    { field: 'emailAndSms', count: receiveCountByCommunicationChannel.emailAndSms },
    { field: 'emailAndVoice', count: receiveCountByCommunicationChannel.emailAndVoice },
    { field: 'smsAndVoice', count: receiveCountByCommunicationChannel.smsAndVoice },
    { field: 'all', count: receiveCountByCommunicationChannel.all },
    { field: 'none', count: receiveCountByCommunicationChannel.none }
  ]), [receiveCountByCommunicationChannel]);

  const totalCount = useMemo(
    () => summaryData.reduce((acc, { count }) => acc + count, 0),
    [summaryData]
  );

  const handleMouseEnterPie = useCallback((event: PieArcDatum<MassCommsSummaryChartDataItem>) => {
    setActiveField(event.data.field);
  }, []);

  const handleMouseLeavePie = useCallback((_event: PieArcDatum<MassCommsSummaryChartDataItem>) => {
    setActiveField(undefined);
  }, []);

  return (
    <MassCommsSummaryGraphComponent
      summaryData={ summaryData }
      width={ 250 }
      height={ 250 }
      activeChannel={ { title: 'Total People', count: totalCount } }
      onMouseEnterPie={ handleMouseEnterPie }
      onMouseLeavePie={ handleMouseLeavePie }
    />
  );
};

export default MassCommsSummaryGraph;
