import { FC, ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock/ProfileBlock.component";
import ProfileKeyValue from "@/tenant-context/control-profile/components/ui/ProfileKeyValue/ProfileKeyValue.component";
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock/ProfileKeyValueBlock.component";
import { ProfileGeneralSection } from "@/tenant-context/control-profile/types/profile";

type Props = {
  createAction?: (section: ProfileGeneralSection) => () => ReactNode;
};

const ProfileMedicalDetails: FC<Props> = ({
  createAction
}) => {
  const {
    profile: {
      loadMedicalDetails
    }
  } = useDispatch<Dispatch>();

  const medical = useSelector(
    ({ profile: { medicalDetails: md } }: RootState) => md
  );

  const profileId = useSelector(
    ({ profile: { id } }: RootState) => id
  );

  useEffect(() => {
    loadMedicalDetails();
  }, [loadMedicalDetails, profileId]);

  return (
    <ProfileBlock
      heading="Medical"
      renderAction={ createAction?.(ProfileGeneralSection.Medical) }
    >
      <ProfileKeyValueBlock
        gtc={ '1fr 2fr' }
        maxColumns={ 2 }
      >
        <ProfileKeyValue firstItem="label" name={ 'Blood Type' } value={ medical?.bloodType }/>
        <ProfileKeyValue firstItem="label" name={ 'Height' } value={ medical?.height ?? '' }/>
        <ProfileKeyValue firstItem="label" name={ 'Weight' } value={ medical?.weight ?? '' }/>
        <ProfileKeyValue firstItem="label" name={ 'Medical Conditions' } value={ medical?.medicalConditionsList }/>
        <ProfileKeyValue firstItem="label" name={ 'Allergies' } value={ medical?.allergiesList }/>
        <ProfileKeyValue firstItem="label" name={ 'Medications' } value={ medical?.medicationsList }/>
      </ProfileKeyValueBlock>
    </ProfileBlock>
  );
};

export default ProfileMedicalDetails;
