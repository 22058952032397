import { PaginatedResult } from "@/common/types/reference-data";
import { callApi, TokenType } from "@/common/util/api";
import browserDatabase from "@/common/util/browser-database";
import { CONTEXT_KEY, ContextTypes } from "@/core/store/shared-data/sharedData.types";

import { CaseFeature } from "../../types/CaseTypes/caseFeatures";

export const caseFeaturesApi = {
  tokenType: browserDatabase.getItem(CONTEXT_KEY) as ContextTypes === "platform" ? "access": "tenant",
  updateCaseFeature: function(caseTypeId: string, params: CaseFeature[]) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateCaseTypeFeatures'
        },
        method: 'post',
        body: JSON.stringify({
          "caseTypeFeatureList": params
        }),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getCaseFeatureListById: function(caseTypeId: string) {
    return callApi<PaginatedResult<CaseFeature>>(
      `managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListCaseTypeFeature'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  }
};