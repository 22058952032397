import { callApi } from '@/common/util/api';
import { PlaybookAction } from '@/tenant-context/employee-app-action-response-center/types/playbook';

export type PlaybookSource = 'ARC' | 'EA-ARC';

export const createPlaybookActionForCase = (
  action: string,
  caseId: string,
  source: PlaybookSource
) =>
  callApi<unknown>(
    source === 'EA-ARC'
      ? `/arc-service/v1/lone-worker/case/${caseId}/playbook-action`
      : `/arc-service/v1/case/${caseId}/playbook-action`,
    {
      method: 'post',
      withTenantId: true,
      withAuthorization: true,
      body: JSON.stringify({
        action
      })
    }
  );

export const getPlaybookActionsForCase = (
  caseId: string,
  source: PlaybookSource
) =>
  callApi<PlaybookAction[]>(
    source === 'EA-ARC'
      ? `/arc-service/v1/lone-worker/case/${caseId}/playbook-action`
      : `/arc-service/v1/case/${caseId}/playbook-action`,
    {
      method: 'get',
      withTenantId: true,
      withAuthorization: true
    }
  );
