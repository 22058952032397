import { createStyles } from "@mantine/core";

type Props = {
  disabled?: boolean
}
export const useProfileTabActionButtonStyles = createStyles((theme, { disabled }: Props) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  menuTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '16px',
    backgroundColor: disabled ? theme.colors.neutral[8] : theme.colors.royalBlue[0],
    border: disabled ? `1px solid ${theme.colors.neutral[7]}` : "none",
    width: '200px',
    height: '50px',
    color: theme.colors.neutral[0]
  },
  menuControl: {
    backgroundColor: theme.colors.royalBlue[0],
    background: 'linear-gradient(0deg, var(--desktop-colours-neutral-black-black-25, rgba(6, 25, 39, 0.25)) 0%, var(--desktop-colours-neutral-black-black-25, rgba(6, 25, 39, 0.25)) 100%), var(--Primary-Colours-Royal-Blue, #0F62FE)',
    '&:hover': {
      backgroundColor: theme.colors.royalBlue[0],
      opacity: '60%'
    }
  }
}));
