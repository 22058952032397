import { FC, useContext } from "react";

import { ArcContext } from "@/tenant-context/control-action-response-center/context/ARC/ARC.context";

import ImpactZone from "./ImpactZone.component";

const ImpactZoneContainer: FC =  () => {
  const { arcCase, riskAlertJsonMeta, impactPeople, impactAssets,riskAlert } = useContext(ArcContext);

  return (
    <ImpactZone
      arcCaseEvent={ arcCase }
      riskAlertsGeoData = { riskAlertJsonMeta }
      impactPeopleGeoData = { impactPeople }
      impactAssetsGeoData = { impactAssets }
      riskAlert = { riskAlert }
    />
  );
};

export default ImpactZoneContainer;