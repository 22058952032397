import { createStyles } from "@mantine/core";

export const useTravelItineraryControlStyles = createStyles(() => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    gap: 16,

    '& *': {
      boxSizing: 'border-box'
    }
  }
}));
