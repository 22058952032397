/* eslint-disable react/no-multi-comp */
import { FC } from "react";

import IconProgressBar from "@/common/components/IconProgressBar/IconProgressBar.component";
import { ReactComponent as RightArrow } from '@/common/icons/Arrows/right-arrow.svg';
import CountryFlag from "@/tenant-context/common/components/CountryFlag/CountryFlag.component";
import { TravelMethods } from "@/tenant-context/control-profile/types/mini-profile";

import { useProfileItineraryItemStyles } from './ProfileItineraryItem.styles';

const FlightSegmentItem = (props: {
  classes: Record<string, string>,
  fromFlagCode: string | undefined,
  fromCountry: string,
  fromDate: string,
  fromTime: string,
  toFlagCode: string | undefined,
  toCountry: string | undefined,
  toDate: string | undefined,
  toTime: string | undefined,
  isWithProgress?: boolean,
  progress: number,
  indicatorIcon: React.ReactNode
}) => {
  return <div className={ props.classes.iteneraryStep }>
    <div className={ props.classes.iteneraryInfo }>
      <div className={ props.classes.iteneraryLocation }>
        <div className={ props.classes.personInfoHeader }>
          FROM<br/>
          <CountryFlag className={ props.classes.flag }
            countryCode={ props.fromFlagCode ? props.fromFlagCode.toUpperCase() : "" }/><br/>
          { props.fromFlagCode && <div className={ props.classes.countryName }>{ props.fromFlagCode }<br/></div> }
          <div className={ props.classes.locName }>{ props.fromCountry }<br/></div>
          { props.fromDate }<br/>
          { props.fromTime }<br/>
        </div>
      </div>
      <div className={ props.classes.iteneraryArrow }><RightArrow/></div>
      <div className={ props.classes.iteneraryLocation }>
        <div className={ props.classes.personInfoHeader }>
          TO<br/>
          <CountryFlag className={ props.classes.flag }
            countryCode={ props.toFlagCode ? props.toFlagCode.toUpperCase() : "" }/><br/>
          { props.toFlagCode && <div className={ props.classes.countryName }>{ props.toFlagCode }<br/></div> }
          <div className={ props.classes.locName }>{ props.toCountry }<br/></div>
          { props.toDate }<br/>
          { props.toTime }<br/>
        </div>
      </div>
    </div>
    { props.isWithProgress && <IconProgressBar progress={ props.progress } indicatorIcon={ props.indicatorIcon }
      containerStyles={ { marginTop: "16px" } }/> }
  </div>;
};

const HotelSegmentItem = (props: {
  classes: Record<string, string>,
  name: string,
  address: string | undefined,
  fromDate: string,
  fromTime: string,
  toDate: string | undefined,
  toTime: string | undefined,
  isWithProgress?: boolean,
  progress: number,
  indicatorIcon: React.ReactNode
}) => {
  return <div className={ props.classes.iteneraryStep }>
    <div className={ props.classes.otherLocationContainer }>
      <div className={ props.classes.otherItineraryLocation }>
        <div className={ props.classes.locName }>{ props.name }</div>
        { props.address && <div>
          <div className={ props.classes.addressHeader }>Address</div>
          <div className={ props.classes.addressFont }>{ props.address }</div>
        </div> }
        <div className={ props.classes.hotelToFromWrapper }>
          <div className={ props.classes.personInfoHeader }>
            { props.fromDate }<br/>
            { props.fromTime }<br/>
          </div>

          <div className={ props.classes.iteneraryArrow }><RightArrow/></div>

          <div className={ props.classes.personInfoHeader }>
            { props.toDate }<br/>
            { props.toTime }<br/>
          </div>
        </div>
      </div>
    </div>
    { props.isWithProgress && <IconProgressBar progress={ props.progress } indicatorIcon={ props.indicatorIcon }
      containerStyles={ { marginTop: "16px" } }/> }
  </div>;
};

type Props = {
  travelType: TravelMethods,
  address?: string,
  timeframe: 'past' | 'present' | 'future',
  from: {
    flagCode: string,
    name: string,
    date: string,
    time: string
  },
  to: {
    flagCode?: string,
    name?: string,
    date?: string,
    time?: string
  },
  progressConfigs: {
    isWithProgress?: boolean,
    percentCompleted: number,
    indicatorIcon: React.ReactNode
  },
}

const ProfileItineraryItem: FC<Props> = ({
  from,
  to,
  timeframe,
  progressConfigs,
  travelType,
  address }) => {
  const { classes } = useProfileItineraryItemStyles({ timeframe });
  const isWithProgress = progressConfigs.isWithProgress === undefined ? true : progressConfigs.isWithProgress;

  if (travelType === 'AIR' || travelType === 'FLIGHT') {
    return <FlightSegmentItem classes={ classes } fromFlagCode={ from?.flagCode } fromCountry={ from.name }
      fromDate={ from.date } fromTime={ from.time } toFlagCode={ to.flagCode } toCountry={ to.name }
      toDate={ to.date } toTime={ to.time } progress={ progressConfigs.percentCompleted }
      indicatorIcon={ progressConfigs.indicatorIcon } isWithProgress={ isWithProgress }/>;
  }

  if (travelType === 'HOTEL') {
    return <HotelSegmentItem classes={ classes } name={ from.name } address={ address } fromDate={ from.date }
      fromTime={ from.time } toDate={ to.date } toTime={ to.time } progress={ progressConfigs.percentCompleted }
      indicatorIcon={ progressConfigs.indicatorIcon } isWithProgress={ isWithProgress }/>;
  }

  return (
    <div className={ classes.iteneraryStep }>
      <div className={ classes.otherLocationContainer }>
        <div className={ classes.otherItineraryLocation }>
          <div className={ classes.personInfoHeader }>
            From
            <div className={ classes.locName }>{ from.name }</div>
          </div>
          { address && <div>
            <div className={ classes.addressHeader }>Address</div>
            <div className={ classes.addressFont }>{ address }</div>
          </div> }
          <div className={ classes.personInfoHeader }>
            { from.date }<br/>
            { from.time }<br/>
          </div>
        </div>
        <div className={ classes.iteneraryArrow }><RightArrow/></div>
        <div className={ classes.otherItineraryLocation }>
          <div className={ classes.personInfoHeader }>
            To
            <div className={ classes.locName }>{ to.name }</div>
          </div>
          <div className={ classes.personInfoHeader }>
            { to.date }<br/>
            { to.time }<br/>
          </div>
        </div>
      </div>
      { isWithProgress && <IconProgressBar progress={ progressConfigs.percentCompleted }
        indicatorIcon={ progressConfigs.indicatorIcon } containerStyles={ { marginTop: '16px' } }/> }
    </div>
  );
};

export default ProfileItineraryItem;
