import { createStyles } from "@mantine/core";

type Args = {
  color: string,
  borderColor: string
}

export const useColorSwatchPaletteStyles = createStyles((theme, { color, borderColor }: Args) => ({
  circle: {
    width: 45,
    height: 45,
    backgroundColor: color,
    border: `4px solid ${color}`,
    borderRadius: 45,
    transitionDuration: '300ms',
    cursor: 'pointer',
    '&:hover': {
      border: `4px solid ${borderColor}`
    }
  }
}));

