import { createStyles, MantineTheme } from "@mantine/core";

export const useTravelSearchControlStyles = createStyles((theme: MantineTheme) => ({
  travelButton: {
    '& > svg > path': {
      fill: theme.colors.neutral[0]
    }
  },

  drawerTitle: {
    lineHeight: theme.other.lh.l,
    marginTop: 60,
    marginBottom: 55,
    fontSize: 26,
    fontWeight: theme.other.fw.bold,
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '-12px',
      left: 0,
      width: '56px',
      height: '2px',
      backgroundColor: theme.colors.royalBlue[0]
    }
  },

  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 35
  }
}));
