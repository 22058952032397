import { createStyles, MantineTheme } from "@mantine/core";

type Params = {
  barColor?: string,
}

export const useRiskAlertStatusStyles = createStyles((theme: MantineTheme, params: Params) => ({
  riskAlertStatusContainer:{
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    backgroundColor: theme.colors.neutral[9],
    height: '87px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '8px 5px'
  },
  riskAlertStatusBar: {
    borderLeft: `6px solid ${params?.barColor}`,
    width: '6px',
    height: '71px'
  },
  riskInfoContainer: {
    maxHeight: '71px'
  },
  riskCriticalityRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px'
  },
  riskInfoRow: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.neutral[5]
  },
  trendCode: {
    marginLeft: '30px',
    fontWeight:theme.other.fw.bold
  }
}));
