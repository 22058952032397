import { AccordionStylesParams, MantineTheme } from "@mantine/core";

import { ReactComponent as Chevron } from "@/common/icons/chevron-small.svg";

import { primitiveNumbers } from "../constants";

const getPadding = (theme: MantineTheme, variant: string) => {
  const paddingMap: Record<string, string> = {
    default: `${theme.other.spacing.sm}px ${theme.other.spacing.md}px`,
    filled: `${theme.other.spacing.sm}px 0`,
    contained: `${theme.other.spacing.sm}px 0`,
    separated: `${theme.other.spacing.sm}px 0`
  };
      
  return paddingMap[variant];
};
    
export const AccordionComponentStyle = {
  defaultProps: {
    variant: 'default',
    chevron: <Chevron />
  },
  styles: (theme: MantineTheme, params: AccordionStylesParams) => ({
    item: {
      border: 'none',
      borderBottom: `1px solid ${theme.other.semantic.border.general.lighter}`,
      '&[data-active]': {
        backgroundColor: theme.other.semantic.surfaceBackground.none
      }
    },
    control: {
      padding: getPadding(theme, params.variant)
    },
    label: {
      fontSize: theme.other.fs.sm,
      fontWeight: theme.other.fw.bold
    },
    content: {
    },
    icon: {
      width: primitiveNumbers['3x'],
      height: primitiveNumbers['3x'],
      color: theme.other.semantic.icon.general.secondary
    }
  })
};
