import { Accordion, Divider, Stepper, Switch } from "@mantine/core";
import { FC } from "react";

import { formatDate } from "@/common/util/format/date";
import { useMiniProfileStyles } from "@/tenant-context/control-profile/components/ui/MiniProfile/MiniProfile.styles";
import TravelSegmentWrapper from "@/tenant-context/control-profile/components/ui/TravelSegmentWrapper";
import { MiniProfileDatails } from "@/tenant-context/control-profile/types/mini-profile";
import { MapboxHoverEvent } from "@/tenant-context/visualisation-people/context/People.context";
import { PersonTravelItenerary } from "@/tenant-context/visualisation-people/types/people.types";
import { handleAdapterSourceDisplay } from "@/tenant-context/visualisation-people/util/utils";

import IconTextMarker from "../../ui/IconTextMarker/IconTextMarker.component";

type Props = {
  clickedPersonEvent: MapboxHoverEvent | undefined,
  travelIteneraryInfo?: PersonTravelItenerary,
  onCalculateTripProgress: (startDate: number, endDate: number) => {
    progress: number,
    status: 'past' | 'present' | 'future'
  },
  onActiveStepper: () => number,
  onCurrentTravelStatus: () => { status: string | null, travelTime: string, currentLocation: string }
  handleTravelItineraryOnMap?: (event: React.ChangeEvent<HTMLInputElement>) => void
  isItineararyOnMap: boolean,
  profileData?: MiniProfileDatails,
  reverseGeoLoc: string
}
const MiniProfileLocationInformationComponent: FC<Props> = ({
  clickedPersonEvent, travelIteneraryInfo,
  onCalculateTripProgress, onActiveStepper: _onActiveStepper, onCurrentTravelStatus,
  handleTravelItineraryOnMap, isItineararyOnMap, profileData, reverseGeoLoc
}) => {
  const { classes, cx } = useMiniProfileStyles(true);

  return (
    <Accordion variant={ 'contained' }>
      <Accordion.Item value={ 'Location' }>
        <Accordion.Control>Location</Accordion.Control>
        <Accordion.Panel className={ classes.locationPanel }>
          <div className={ classes.locationContainer }>
            { clickedPersonEvent?.adapterSource === 'travel-book-adapter-service' || travelIteneraryInfo ?
              <>
                <div className={ classes.sourceInfo }>{ `Via Travel Itinerary` }</div>
                <div className={ classes.personLocationInfo }>
                  <div className={ classes.locationIconContainer }><IconTextMarker
                    iconText={ `${profileData?.firstName} ${profileData?.lastName}` } /></div>
                  <div className={ classes.locationInfoBody }>
                    { onCurrentTravelStatus().currentLocation }<br />
                  </div>
                </div>
                <div className={ cx(classes.personInfoData, classes.standardMarginTop) }>
                  { onCurrentTravelStatus().status }</div>
                <div className={ classes.lastKnownloc }>{ onCurrentTravelStatus().travelTime }</div>
                <div className={ classes.standardMarginTop }>
                  <Divider orientation="horizontal" />
                  <div className={ classes.iteneraryView }>
                    <div>View Itinerary on the map</div>
                    <Switch
                      id={ `prfile-itinerary-switch` }
                      onChange={ handleTravelItineraryOnMap }
                      checked={ isItineararyOnMap }
                    />
                  </div>
                  <Divider orientation="horizontal" />
                </div>
                <div className={ classes.iteneraryContainer }>
                  { /*<Stepper className={ classes.stepper } active={ onActiveStepper() } orientation="vertical" >*/ }
                  { /*  { travelIteneraryInfo?.map(itenerary => {*/ }
                  { /*    return (<Stepper.Step*/ }
                  { /*      key={ itenerary.start_date }*/ }
                  { /*      className={ classes.stepperStep }*/ }
                  { /*      icon={ <TravelIcon iconName={ itenerary.type } /> }*/ }
                  { /*      completedIcon={ <TravelIcon iconName={ itenerary.type } /> } />);*/ }
                  { /*  }) }*/ }
                  { /*</Stepper>*/ }

                  <Stepper className={ classes.stepper } active={ 1 } orientation="vertical">
                    <div className={ classes.locationsWrapper }>
                      { travelIteneraryInfo?.map((location, index) => {
                        const fromDate = new Date(location.start_date);
                        const toDate = new Date(location.end_date);
                        const segmentStatus = onCalculateTripProgress(location.start_date, location.end_date).status;
                        const isCompletedSegment = segmentStatus === 'past';
                        const isCurrentSegment = segmentStatus === 'present';
                        const isLastSegment = index === travelIteneraryInfo.length - 1;

                        return (
                          <TravelSegmentWrapper key={ index }
                            location={ location }
                            isCurrentSegment={ isCurrentSegment }
                            lastSegment={ isLastSegment }
                            completedSegment={ isCompletedSegment }
                            onCalculateTripProgress={ onCalculateTripProgress(location.start_date, location.end_date) }
                            fromDate={ fromDate } toDate={ toDate }/>
                        );
                      }) }
                    </div>
                  </Stepper>
                </div>
              </> :
              <>
                { clickedPersonEvent?.lastKnownLocation &&
                <div className={ cx(classes.personInfoHeader, classes.standardMarginTop) }>LAST KNOWN LOCATION</div> }

                <div className={ cx({
                  [classes.personInfoData]: true,
                  [classes.standardMarginTop]: !clickedPersonEvent?.lastKnownLocation
                }) }>
                  { `Via ${handleAdapterSourceDisplay(clickedPersonEvent?.adapterSource)}` }
                </div>
                <div className={ classes.personLocationInfo }>
                  <div className={ classes.locationIconContainer }><IconTextMarker
                    iconText={ `${profileData?.firstName} ${profileData?.lastName}` }/></div>
                  <div
                    className={ classes.locationInfoBody }>{ reverseGeoLoc }<br/>{ `${clickedPersonEvent?.location.point.lon} - ${clickedPersonEvent?.location.point.lat}` }
                  </div>
                </div>
                <Divider className={ classes.personLocationInfo } orientation="horizontal"/>
                <div
                  className={ classes.travelTimeUtc }>{ clickedPersonEvent?.receivedTime ? formatDate(new Date(clickedPersonEvent?.receivedTime)) : '-' }</div>
              </> }
          </div>
          { /* <MiniProfileLocationTabs /> */ }
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default MiniProfileLocationInformationComponent;
