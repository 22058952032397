import { FC, useCallback, useState } from "react";

import { logger } from "@/common/util/ConsoleLogger";
import { getPersonInitials } from "@/tenant-context/visualisation-people/util/getPersonInitials";

import { usePersonAvatarStyles } from "./PersonAvatar.styles";

type Props = {
  personName?: string,
  personAvatarUrl?:string,
  width?: string,
  height?:string,
  borderRadius?:string,
  className?:string
}

export const RiskImpactPerson: FC<Props> = ({
  personName,
  personAvatarUrl,
  width,
  height,
  borderRadius,
  className
}) => {
  const [isError, setIsError] = useState(false);

  const handleError = useCallback(
    () => {
      logger.error("Error loading image of Person Avatar");
      setIsError(true);
    },
    []
  );

  const { classes, cx } = usePersonAvatarStyles({
    width:width,
    height:height,
    borderRadius:borderRadius
  });

  return(
    <div
      className= { cx(classes.avatarContainer, className) }
    >
      { (personAvatarUrl && !isError) && (
        <img
          className={ classes.personImage }
          alt={ personName }
          src={ personAvatarUrl }
          onError={ handleError }
        />
      ) }

      { (!personAvatarUrl || isError) && (
        <div
          className={ classes.nameText }
        >
          { personName
            ? getPersonInitials(personName).toUpperCase()
            : "N/A" }
        </div>
      ) }

      <div className={ classes.border } />
    </div>
  );
};

export default RiskImpactPerson;