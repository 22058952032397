import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

type Params = {
  fillSpace?: boolean
}

export const useMassCommsSectionStyles = createStyles((theme: MantineTheme, params: Params) => ({
  container: {
    backgroundColor: theme.colors.neutral[8],
    padding: 24,
    flex: params.fillSpace ? 1 : 'none',

    '&:last-child': {
      marginLeft: params.fillSpace ? 16 : 0
    }
  }
}));
