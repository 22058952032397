import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { CSS_KEY } from "@/core/styles/mantine/constants";
import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useTenantAccessCardsStyles = createStyles((theme: MantineTheme) => ({
  headerWrapper: {
    backgroundColor: theme.colors.neutral[9],
    marginBottom: 48
  },
  container: {
    backgroundColor: theme.colors.neutral[9],
    padding: 24
  },
  headerTextWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  createButton: {
    padding: '16px',
    svg: {
      path: {
        fill: `${theme.colors.blue[4]} !important`,
        stroke: `none`
      }
    }
  },
  createButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    gap: 24
  },
  headerText: {
    fontSize: theme.fontSizes.xl,
    fontWeight: theme.other.fw.bold,
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  btn: {
    all: 'unset',
    backgroundColor: 'unset !important',
    padding: 0,
    width: 'fit-content',
    cursor: 'pointer'
  },
  section: {
    marginBottom: 24,
    [`& .${CSS_KEY}-Textarea-input`]:{
      minHeight: 200
    }
  },
  extendedMargin: {
    marginTop: '48px'
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *:not(:first-child)': {
      marginLeft: 24
    }
  },
  asterisk:{
    '&:after':{
      color: genericColorPalette.error[0],
      content: '"*"',
      display: "inline-block",
      marginLeft: 4
    },
    position: "relative"
  },
  relative: {
    position: "relative"
  },
  grid:{
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '0 24px'
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '24px',
    marginRight: 24,
    '& > div':{
      flex: 1,
      marginBottom: 0
    }
  },
  filterWrap:{
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between'
  },
  controlsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center"
  },
  searchBtn:{
    '& svg':{
      marginLeft: theme.other.spacing.sm
    }
  },
  resetFilter: {
    marginLeft: theme.other.spacing.md
  },
  loadingButton: {
    background: `${theme.colors.neutral[7]} !important`,
    borderColor: `${theme.colors.neutral[5]} !important`
  },
  notificationContainer: {
    height: '120px'
  }
}));
