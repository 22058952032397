import { FC } from "react";

import ProfileKeyValueBlockContextProvider from './ProfileKeyValueBlock.context';
import { useProfileKeyValueBlockStyles } from './ProfileKeyValueBlock.style';

type Props = {
  maxColumns?: number,
  gtc?: ((maxColumns: number) => string) | string
};

const ProfileKeyValueBlock: FC<Props> = ({
  children,
  maxColumns = 2,
  gtc
}) => {
  const { classes } = useProfileKeyValueBlockStyles({
    maxColumns,
    gtc
  });

  return (
    <ProfileKeyValueBlockContextProvider maxColumns={ maxColumns }>
      <dl className={ classes.block }>
        { children }
      </dl>
    </ProfileKeyValueBlockContextProvider>
  );
};

export default ProfileKeyValueBlock;
