import { createContext, FC } from "react";

import useContextValue from "@/common/hooks/useContextValue";

export const ProfileKeyValueBlockContext = createContext<
  ProfileKeyValueBlockContextType
>({} as ProfileKeyValueBlockContextType);

export type ProfileKeyValueBlockContextType = {
  maxColumns: number
}

const ProfileKeyValueBlockContextProvider: FC<ProfileKeyValueBlockContextType> = ({
  children,
  maxColumns
}) => {
  const contextValue = useContextValue({
    maxColumns
  });

  return (
    <ProfileKeyValueBlockContext.Provider value={ contextValue }>
      { children }
    </ProfileKeyValueBlockContext.Provider>
  );
};

export default ProfileKeyValueBlockContextProvider;