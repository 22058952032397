import { AssetRankingEvent } from "@/tenant-context/common/types/asset-ranking";

// This event is necessary to show axis when the dataset is empty
export const dummyEvent: AssetRankingEvent = {
  adapterEventId: 'DUMMY_ADAPTER_EVENT_ID',
  adapterSource: '',
  connectorEventId: '',
  connectorSource: '',
  // @ts-expect-error testing
  currentRank: null,
  location: {
    id: 'DUMMY_EVENT_NO_LOCATION',
    isoCountryCode: '',
    labels: [],
    point: {
      lat: 0,
      lon: 0
    },
    type: 'DUMMY_EVENT_FOR_EMPTY_AXIS'
  },
  personFirstName: '',
  personLastName: '',
  personId: '',
  receivedTime: 123,
  endTime: '',
  startTime: '',
  assetId: '',
  isTopRank: false,
  personFullName: '',
  rankTime: 0
};