import { FC, useCallback, useContext, useState } from "react";
import { OnProgressProps } from "react-player/base";

import EmergencyVideoPopupComponent
  from "@/tenant-context/employee-app-action-response-center/components/ui/EmergencyVideoPopup/EmergencyVideoPopup.component";
import {
  useEmergencyVideoPopupStyles
} from "@/tenant-context/employee-app-action-response-center/components/ui/EmergencyVideoPopup/EmergencyVideoPopup.styles";
import { EaArcContext } from "@/tenant-context/employee-app-action-response-center/context/EaArc.context";

const EmergencyVideoPopup: FC = () => {

  const { classes } = useEmergencyVideoPopupStyles();

  const [ videoIndex, setVideoIndex ] = useState<number>(0);
  const [ videoProgress, setVideoProgress ] = useState<OnProgressProps>(
    { played: 0, playedSeconds: 0, loaded: 0, loadedSeconds: 0 }
  );
  const [ playing, setPlaying ] = useState(true);

  const {
    emergencyVideos,
    setIsEmergencyVideoShown
  } = useContext(EaArcContext);

  const onEnded = useCallback(() => {
    setPlaying(false);

    if (videoIndex === emergencyVideos.length - 1) {
      setVideoIndex(0);
      return;
    }

    setVideoIndex((index) => index + 1);

    setTimeout(() => {
      setPlaying(true);
    }, 1000);
  }, [ videoIndex, emergencyVideos.length ]);

  const closeVideo = useCallback(() => {
    setPlaying(false);
    setIsEmergencyVideoShown(false);
  }, [ setIsEmergencyVideoShown ]);

  return <div className={ classes.playerWrapper }>
    <EmergencyVideoPopupComponent
      url={ emergencyVideos[videoIndex].url }
      mode={ emergencyVideos[videoIndex].mode }
      isPlaying={ playing }
      totalVideos={ emergencyVideos.length }
      currentVideoIndex={ videoIndex }
      videoProgress={ videoProgress }
      onEnded={ onEnded }
      onCloseRequested={ closeVideo }
      onProgress={ setVideoProgress }
    />
  </div>;
};

export default EmergencyVideoPopup;
