import { Feature, Point } from "geojson";
import { useCallback } from "react";
import { useMap } from "react-map-gl";

export default function useZoomIntoFeature() {
  const { current: map } = useMap();

  const zoomIntoFeature = useCallback((feature: Feature<Point>) => {
    if (!map) {
      return;
    }

    const {
      geometry: {
        coordinates: [lng, lat]
      }
    } = feature;

    map.flyTo({
      center: {
        lat,
        lng
      },
      zoom: 14,
      speed: 1.8
    });
  }, [map]);

  return zoomIntoFeature;
}