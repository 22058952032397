import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { RiskProviders } from "@/tenant-context/common/types/risk";

import DataminrEventsSettings from "./DataminrEventsSettings.component";

const DataminrEventsSettingsContainer: FC = () => {
  const riskLevelFilters = useSelector(
    (state: RootState) =>
      state.riskEventFilterDataOptions.riskLevelFilters.dataminr
  );

  const {
    riskEventFilterDataOptions: { SET_RISK_LEVEL_FILTER }
  } = useDispatch<Dispatch>();

  const handleRiskLevelToggle = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (ev) => {
      const level = parseInt(ev.target.value, 10);
      SET_RISK_LEVEL_FILTER({
        riskLevel: level,
        checked: ev.target.checked,
        provider: RiskProviders.dataminr
      });
    },
    [SET_RISK_LEVEL_FILTER]
  );

  return (
    <DataminrEventsSettings
      checkedRiskLevels={ riskLevelFilters }
      onRiskLevelToggle={ handleRiskLevelToggle }
    />
  );
};

export default DataminrEventsSettingsContainer;
