import { MantineTheme } from "@mantine/core";

import { primitiveNumbers } from "../constants";


export const PaginationComponentStyle = {
  defaultProps: {},
  styles: (theme: MantineTheme) => ({
    item: {
      borderRadius: 'unset',
      backgroundColor: theme.other.semantic.surfaceBackground.primary,
      border: `1px solid ${theme.other.semantic.border.general.lighter}`,
      fontFamily: theme.fontFamily,
      fontStyle: 'normal',
      fontSize: theme.fontSizes.sm,
      lineHeight: primitiveNumbers['3x'],
      height: primitiveNumbers['4x'],
      minWidth: primitiveNumbers['4x'],
      width: primitiveNumbers['4x'],
      color: theme.other.semantic.text.primary,
      '&:hover': {
        backgroundColor: theme.other.semantic.surfaceBackground.secondary
      },
      '&[data-active]': {
        color: theme.other.semantic.border.general.accent[1],
        border: `1px solid ${theme.other.semantic.border.general.accent[1]}`
      },
      '& > svg': {
        transform: 'scale(1.5)'
      }
    }
  })
};
