import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { CSS_KEY } from "@/core/styles/mantine/constants";


export const useMapSearchStyles = createStyles((theme: MantineTheme, params: {isFullWidth: boolean }) => ({
  searchInput: {
    backgroundColor: theme.colors.neutral[8],
    width: params.isFullWidth ? '100%' : '300px',
    [`& .${CSS_KEY}-Input-rightSection, & .${CSS_KEY}-Select-rightSection`]: {
      display: 'none'
    }
  }
}));
