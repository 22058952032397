import { Tabs, TabsValue } from "@mantine/core";
import { createContext, FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import usePermission from "@/common/hooks/usePermission";
import useUnauthorizedModal from "@/common/hooks/useUnauthorizedModal";
import { RouterConfig } from "@/core/components/Router/Router.config";
import { Dispatch, RootState } from "@/core/store";
import { CONTEXT_TYPES, ContextTypes } from "@/core/store/shared-data/sharedData.types";
import { CaseTypeRequiredPoliciesConfig } from "@/platform-context/control-icm-management/config/CaseTypesRequiredPolicies.config";

import { useCaseTypeDetailsStyles } from "./CaseTypeDetails.styles";

export enum CaseTypeDetailsTabs {
    BasicOverview = 'BasicOverview',
    Features = 'Features',
    TeamsAndRoles = 'TeamsAndRoles',
    Configurations = 'Configurations',
    CaseGuide = 'CaseGuide',
    CustomerAccess = 'CustomerAccess'
  }
  
export type TabProps = {
    value: string;
    label: string;
    isAuthorized?: boolean;
  }

export type CaseTypesDetailsContextType = {
    context: ContextTypes,
    caseTabs: TabProps[],
    activeTab: CaseTypeDetailsTabs,
    renderTab: (tab: TabProps, key: number) => JSX.Element,
    interceptTabChange: (event: TabsValue) => void,
    navigateToCaseTypes: () => void,
    navigateToCaseTypeDetails: (caseTypeId: string) => void,
    isAuthorizedToCreateOrEdit: boolean
  }

const caseTabs: TabProps[] = [
  {
    value: CaseTypeDetailsTabs.BasicOverview,
    label: 'Basic Overview',
    isAuthorized: true
  },
  {
    value: CaseTypeDetailsTabs.Features,
    label: 'Features',
    isAuthorized: true
  },
  {
    value: CaseTypeDetailsTabs.TeamsAndRoles,
    label: 'Teams and Roles',
    isAuthorized: true
  },
  {
    value: CaseTypeDetailsTabs.Configurations,
    label: 'Configurations',
    isAuthorized: true
  },
  {
    value: CaseTypeDetailsTabs.CaseGuide,
    label: 'Case Guide',
    isAuthorized: true
  },
  {
    value: CaseTypeDetailsTabs.CustomerAccess,
    label: 'Customer Access',
    isAuthorized: true
  }
];

export const CaseTypeDetailsContext = createContext<CaseTypesDetailsContextType>({} as CaseTypesDetailsContextType);

export const CaseTypesDetailsContextProvider: FC = ({ children }) => {

  const [activeTab, setActiveTab] = useState(CaseTypeDetailsTabs.BasicOverview);

  const context = useSelector((state: RootState) => state.sharedData.currentContext);

  const { classes } = useCaseTypeDetailsStyles();

  const navigate = useNavigate();

  const handleUnauthorizedAction = useUnauthorizedModal();

  const { id } = useParams();

  const {
    caseTypes: {
      getCaseTypeById,
      SET_CASE_TYPE_RECORD,
      SET_ACTIVE_ROLE
    }
  } = useDispatch<Dispatch>();

  const caseTypeRecord =  useSelector((state: RootState) => state.caseTypes.caseTypeRecord);

  const isAuthorizedByPolicy = usePermission(CaseTypeRequiredPoliciesConfig[context].EDIT_CASE_TYPE);

  const isAuthorizedToCreateOrEdit = isAuthorizedByPolicy && 
  (!caseTypeRecord ? true : context === CONTEXT_TYPES.platform || 
    (context === CONTEXT_TYPES.tenant && !!caseTypeRecord.tenantId));

  const handleTabChange = useCallback((tab: CaseTypeDetailsTabs) => {
    if (!tab) {
      return;
    }

    setActiveTab(tab);
  }, []);

  const interceptTabChange = useCallback((event) => {
    const selectedTab = caseTabs.find((tab) => tab.value === event);

    if (!selectedTab?.isAuthorized)  {
      return;
    }

    handleTabChange(event);

  }, [handleTabChange]);

  const navigateToCaseTypes = useCallback(()=> navigate(RouterConfig.routes.icmConfig.caseTypes), [navigate]);

  const navigateToCaseTypeDetails = useCallback((caseTypeId: string) => {
    navigate(RouterConfig.routes.icmConfig.caseTypeDetails(caseTypeId));
    SET_ACTIVE_ROLE(undefined);
    handleTabChange(CaseTypeDetailsTabs.TeamsAndRoles);
  }, [SET_ACTIVE_ROLE, handleTabChange, navigate]);

  const renderTab = useCallback((tab: TabProps, key: number) => {
    return (
      <Tabs.Tab
        value={ tab.value }
        key={ key }
        onClick={ !tab.isAuthorized ? handleUnauthorizedAction : undefined }
        className={ !tab.isAuthorized ? classes.unauthorizedTab : undefined }
      >
        { tab.label }
      </Tabs.Tab>
    );
  }, [classes.unauthorizedTab, handleUnauthorizedAction]);

  useEffect(()=>{
    return () => {
      SET_CASE_TYPE_RECORD(undefined);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(activeTab === CaseTypeDetailsTabs.BasicOverview && id){
      getCaseTypeById(id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <CaseTypeDetailsContext.Provider value={ { 
      context, 
      activeTab, 
      caseTabs, 
      interceptTabChange, 
      renderTab, 
      navigateToCaseTypes,
      navigateToCaseTypeDetails,
      isAuthorizedToCreateOrEdit
    } }>
      { children }
    </CaseTypeDetailsContext.Provider>
  );
};