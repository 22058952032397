import { FC, useContext } from 'react';

import { GeofenceVisualizeContext } from '@/tenant-context/visualisation-geofences/context/GeofenceVisualize.context';
import GeofenceLayerComponent from '@/tenant-context/visualisation-geofences/layers/GeofenceLayer/GeofenceLayer.component';

const GeofenceLayerContainer: FC = () => {
  
  const {
    currentVisibleGeoFences,
    isNoFill
  } = useContext(GeofenceVisualizeContext);

  return (
    <GeofenceLayerComponent
      currentGeofence={ currentVisibleGeoFences }
      isNoFill={ isNoFill }
    />
  );
};

export default GeofenceLayerContainer;
