
import { GeofenceFilterType, MetaData } from "@/tenant-context/control-draw/types/draw";

export const isEmptyQueryString = (initialString: string, searchString: string) => initialString.length === 0 ? searchString : 'AND' + searchString;

export const geofenceSearchQuery = (filterData?: GeofenceFilterType) => {
  return filterData?.name ?  `&query=name LIKE %22%25${filterData?.name}%25%22 OR type LIKE %22%25${filterData?.name}%25%22 OR createdBy LIKE %22%25${filterData?.name}%25%22 OR status LIKE %22%25${filterData?.name}%25%22 OR sharing LIKE %22%25${filterData?.name}%25%22 OR description LIKE %22%25${filterData?.name}%25%22` : '';
};

export const shortGeofenceSearchQuery = (filterData?: GeofenceFilterType) => {

  // eslint-disable-next-line fp/no-let
  let str = '';

  if (filterData?.name) {
    str = str + isEmptyQueryString(str, `name LIKE %22%25${filterData?.name}%25%22`);
  }
  if (filterData?.type) {
    str = str + isEmptyQueryString(str, `type='${filterData?.type}'`);
  }
  if (filterData?.status) {
    str = str + isEmptyQueryString(str, `status='Active'`);
  }

  return str.length > 0 ? `&query=${str}` : '';
};

export const metaDataQuery = (metaData: MetaData) => {

  return `${metaData.myGeoFences ? 'myGeoFences=' + metaData.myGeoFences + '&' : '' + metaData.sort?.colId ? 'orderBy=' + metaData.sort?.colId + ':' + metaData.sort?.sort + '&' : ''}`;
};





