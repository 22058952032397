import { createStyles,MantineTheme } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const useAddLocationsStyles = createStyles((theme: MantineTheme) => ({
  addBuildingBtnWrap:{
    position: "relative",
    '& > button':{
      position: "absolute",
      right: 24,
      top: 12
    }
  },
  locationCatWrap:{
    '& > div > label':{
      fontSize: theme.fontSizes.sm
    }
  },
  formLabel:{
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.md
  },
  fileInput:{
    [`& .${CSS_KEY}-FileInput-input`]:{
      minHeight: 48,
      fontSize: theme.fontSizes.md
    }
  },
  locationImageWrap:{
    display: "flex",
    alignItems: "center",
    gap: 16
  },
  selectBtn:{
    pointerEvents: "auto"
  },
  locationImageDisplay:{
    maxWidth: "22rem",
    maxHeight: "6rem",
    '& img':{
      borderRadius: "0.4rem",
      maxHeight: "100%",
      maxWidth: "100%"
    }
  },
  textRight:{
    textAlign: "right"
  },
  btn: {
    all: 'unset',
    backgroundColor: 'unset !important',
    padding: 0,
    width: 'fit-content',
    cursor: 'pointer'
  },
  radioGroup: {
    marginBottom: 24,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: "column",
    label: {
      fontSize: theme.fontSizes.md,
      color: theme.colors.neutral[4]
    },
    [`& .${CSS_KEY}-Group-root`]:{
      paddingTop: 7
    },
    'input:checked': {
      backgroundColor: theme.colors.neutral[9],
      borderColor: theme.colors.neutral[5]
    },
    input: {
      backgroundColor: theme.colors.neutral[8],
      borderColor: theme.colors.neutral[5]
    },
    svg: {
      color: theme.other.semantic.accessibility.outer
    }
  },
  unauthorizedTab: {
    cursor: 'not-allowed'
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.link[0],
    textDecoration: 'none',
    marginBottom: 32,
    '&:hover': {
      color: theme.white
    },
    '& > svg': {
      marginRight: 8,
      '& > path': {
        stroke: `${theme.colors.link[0]} !important`,
        fill:  theme.colors.link[0]
      }
    }
  },
  chip:{
    '& label':{
      display: "inline-flex",
      height: 38,
      borderColor: `${theme.colors.neutral[6]} !important`,
      '&:not(:hover)':{
        backgroundColor: `${theme.colors.neutral[8]} !important`
      }
    }
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    padding: "24px 0",
    backgroundColor: theme.colors.neutral[9],
    width: '100%',
    height: 160,
    zIndex: 3
  },
  headerTextWrapper: {
    display: 'flex',
    width: '100%'
  },
  headerInfo: {
    color: theme.other.semantic.text.secondary,
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fw.regular,
    marginTop: 8
  },
  headerText: {
    fontSize: theme.fontSizes.xl,
    fontWeight: theme.other.fw.bold,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  rightSection: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
    backgroundColor: theme.colors.neutral[7],
    boxShadow: theme.other.sdw.top,
    padding: 24,
    marginTop: '24px',
    position: 'sticky',
    bottom: 0
  },
  footerSegment: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: 24
    }
  },
  locationsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px 24px 24px',
    width: '100%',
    backgroundColor: theme.colors.neutral[9]
  },
  tabInner:{
    flex: 1,
    overflowY: 'scroll'
  },
  locationPage: {
    display: 'flex',
    gap: '16px',
    minHeight: '500px',
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
    marginTop: '24px'
  },
  mapPickContainer: {
    backgroundColor: theme.colors.neutral[8],
    height: '100%',
    border: `1px solid ${theme.colors.neutral[6]}`,
    width: "100%"
  },
  formMainContainer: {
    display:  'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  formItemContainer: {
    backgroundColor: theme.colors.neutral[8],
    minHeight: '200px',
    padding: '24px'
  },
  formHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  formTitle: {
    fontSize: theme.fontSizes.md
  },
  formValidationText: {
    fontSize: theme.fontSizes.sm,
    display: 'flex',
    gap: '6px',
    alignItems: 'center'
  },
  formBody: {
    [`.${CSS_KEY}-Divider-root`]: {
      margin: '12px 0px'
    }
  },
  formBodyAddDetails: {
    padding: '24px',
    display: 'flex',
    gap: '7px'
  },
  formItemHeader: {
    color: theme.other.semantic.text.secondary,
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.other.lh.l
  },
  formItemSubLabel: {
    color: theme.other.semantic.text.tertiary,
    fontSize: theme.fontSizes.sm
  },
  formItemText: {
    fontSize: theme.fontSizes.sm,
    lineHeight: theme.other.lh.l
  },
  formItem: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
    width: 'calc(50% - 16px)'
  },
  itemSpanThree: {
    display: 'flex',
    gap: '25px',
    alignItems: 'center'
  },
  formItemFullWidth: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',
    width: '100%'
  },
  mb3:{
    marginBottom: 24
  },
  formSet: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [`.${CSS_KEY}-InputWrapper-root`]: {
      marginBottom: '0px'
    }
  },
  mapContainer: {
    height: '100%'
  },
  addLocationTabs: {
    height: 50
  },
  locationContact: {
    display:'flex',
    gap: '16px',
    [`.${CSS_KEY}-TextInput-root`]: {
      marginBottom: '0px'
    },
    [`.${CSS_KEY}-Menu-dropdown`]: {
      width: '100%',
      marginTop: '5px'
    },
    [`.${CSS_KEY}-Select-root`]: {
      marginBottom: '0px',
      width: '400px'
    },
    marginBottom: '24px',
    marginTop: '7px'
  },
  deleteButton: {
    backgroundColor: 'transparent !important',
    width: 'auto',
    padding: '0px'
  },
  addContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
    gap: '8px',
    cursor: 'pointer'
  },
  externalLinkItem: {
    gap: '7px',
    display: 'flex',
    flexDirection: 'column'
  },
  textExtendedWidth: {
    width: '300px'
  },
  mapOuterContainer: {
    flexDirection: 'column',
    display: 'flex',
    width: "55%"
  },
  addBuildingButton: {
    marginBottom: '24px',
    alignSelf: 'flex-end'
  },
  menuContainer: {
    width: '70%',
    position: 'relative'
  },
  relative:{
    position: "relative"
  },
  menuDropdown: {
    backgroundColor: theme.colors.neutral[8],
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    [`.${CSS_KEY}-Menu-item`]: {
      '&:hover, &[data-hovered]': {
        backgroundColor: theme.colors.neutral[9]
      }
    },
    maxHeight: "35vh",
    overflowY: "auto"
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '7px'
  },
  saveBtn:{
    width: "max-content",
    marginLeft: "auto"
  },
  formDivider: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center'
  }
}));
