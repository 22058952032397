import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";

import { Dispatch } from "@/core/store";
import { MapLayersDrawerProvider } from "@/tenant-context/control-risk-config/context/MapLayersDrawer.context";
import { useTravellersSearchContext } from "@/tenant-context/control-travellers-search/context/TravellersSearchContext/TravellersSearch.context";

import MapLayersControlComponent from "./MapLayersControl.component";

const MapLayersControlContainer: FC = () => {

  const {
    travellersSearch: {
      RESET_TRAVEL_COUNTRY_WISE_TRAVELLERS_STATE
    }
  } = useDispatch<Dispatch>();

  const {
    setCurrentlyOpenedDrawer,
    travellersSearchForm,
    resetContext  } = useTravellersSearchContext();

  const resetSearchState = useCallback(() => {
    RESET_TRAVEL_COUNTRY_WISE_TRAVELLERS_STATE();
    resetContext();
    setCurrentlyOpenedDrawer(undefined);
    travellersSearchForm.reset();
  }, [RESET_TRAVEL_COUNTRY_WISE_TRAVELLERS_STATE, resetContext, setCurrentlyOpenedDrawer, travellersSearchForm]);

  return (
    <MapLayersDrawerProvider>
      <MapLayersControlComponent resetSearchState={ resetSearchState } />
    </MapLayersDrawerProvider>
  );
};

export default MapLayersControlContainer;
