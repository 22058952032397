import { forwardRef, ForwardRefRenderFunction, RefObject, useMemo } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";
import MasterTab from "@/tenant-context/visualisation-site/components/MusterTab";
import { SiteLocationSnapshotProperties, SitePeopleCount } from "@/tenant-context/visualisation-site/types/site.types";

import img from './mocImg.png';
import { useSiteLocationPopupStyles } from "./SiteLocationPopup.styles";

type Props = {
  popupContent: SiteLocationSnapshotProperties,
  ref?: RefObject<HTMLDivElement>
}

const SiteLocationPopup: ForwardRefRenderFunction<HTMLDivElement, Props> = ({
  popupContent
}, ref) => {
  const locationsPeopleCount = useSelector((state: RootState) => state.sitePopup.locationsPeopleCount);
  const activeSite = useMemo(() => {
    // eslint-disable-next-line fp/no-let
    let activeSiteData: SitePeopleCount = {
      code: '',
      isMusterActive: false,
      musterCount: 0,
      total: 0,
      visitorsNext24Hrs: 0,
      visitorsOnSite: 0,
      countryName: '',
      address: {
        firstLine: '',
        secondLine: '',
        thirdLine: '',
        city: '',
        region: '',
        country: '',
        postalCode: ''
      },
      description: ''
    };

    locationsPeopleCount.countries.forEach(
      (country) => {
        return country.sites.forEach((site) => {
          if (site.code === popupContent?.code) {
            activeSiteData = site;
          } else {
            return true;
          }
        });
      }
    );

    return activeSiteData;
  }, [locationsPeopleCount, popupContent]);
  
  const { classes: popupClasses } = useSiteLocationPopupStyles();
  const musterCount = activeSite.musterCount ?? 0;
  const isMuster = activeSite.isMusterActive ?? false;
  
  return (
    <div ref={ ref } className={ popupClasses.popupContainer }>
      <div className={ popupClasses.siteDescriptionContainer }>
        <img src={ img } alt="site"/>
        <div className={ popupClasses.siteDescription }>
          <div className={ popupClasses.siteName }>
            { popupContent.name }
          </div>
          <div className={ popupClasses.siteLocation }>
            { popupContent.address }
          </div>
          <div className={ popupClasses.siteCategory }>
            Connected Site
          </div>
        </div>
      </div>
      { isMuster && <MasterTab siteCode={ popupContent?.code } musterCount={ musterCount }/> }
    </div>
  );
};

export default forwardRef(SiteLocationPopup);
