/* eslint-disable max-lines */
export const countryBoundingBoxes: Record<string, [string, [number, number, number, number]]> = {
  "AD": [
    "Andorra",
    [
      1.41,
      42.43,
      1.79,
      42.66
    ]
  ],
  "AE": [
    "United Arab Emirates",
    [
      51.58,
      22.5,
      56.38,
      26.06
    ]
  ],
  "AF": [
    "Afghanistan",
    [
      60.53,
      29.39,
      74.89,
      38.49
    ]
  ],
  "AG": [
    "Antigua and Barbuda",
    [
      -61.89,
      17,
      -61.67,
      17.73
    ]
  ],
  "AI": [
    "Anguilla",
    [
      -63.43,
      18.16,
      -62.92,
      18.6
    ]
  ],
  "AL": [
    "Albania",
    [
      19.27,
      39.62,
      21.05,
      42.66
    ]
  ],
  "AM": [
    "Armenia",
    [
      43.45,
      38.84,
      46.63,
      41.3
    ]
  ],
  "AN": [
    "Netherlands Antilles",
    [
      -70.06,
      12.02,
      -68.21,
      12.59
    ]
  ],
  "AO": [
    "Angola",
    [
      11.74,
      -18.04,
      24.09,
      -4.39
    ]
  ],
  "AQ": [
    "Antarctica",
    [
      -180,
      -90,
      180,
      -60.52
    ]
  ],
  "AR": [
    "Argentina",
    [
      -73.58,
      -55.06,
      -53.65,
      -21.83
    ]
  ],
  "AS": [
    "American Samoa",
    [
      -171.09,
      -14.6,
      -168.14,
      -11.05
    ]
  ],
  "AT": [
    "Austria",
    [
      9.53,
      46.38,
      16.98,
      49.04
    ]
  ],
  "AU": [
    "Australia",
    [
      113.34,
      -43.63,
      153.57,
      -10.67
    ]
  ],
  "AW": [
    "Aruba",
    [
      -70.06,
      12.41,
      -69.86,
      12.62
    ]
  ],
  "AZ": [
    "Azerbaijan",
    [
      44.79,
      38.27,
      50.39,
      41.86
    ]
  ],
  "BA": [
    "Bosnia and Herzegovina",
    [
      15.73,
      42.55,
      19.62,
      45.23
    ]
  ],
  "BB": [
    "Barbados",
    [
      -59.65,
      13.04,
      -59.43,
      13.34
    ]
  ],
  "BD": [
    "Bangladesh",
    [
      88.03,
      20.74,
      92.67,
      26.63
    ]
  ],
  "BE": [
    "Belgium",
    [
      2.51,
      49.5,
      6.41,
      51.51
    ]
  ],
  "BF": [
    "Burkina Faso",
    [
      -5.52,
      9.61,
      2.4,
      15.08
    ]
  ],
  "BG": [
    "Bulgaria",
    [
      22.36,
      41.23,
      28.61,
      44.24
    ]
  ],
  "BH": [
    "Bahrain",
    [
      50.45,
      25.8,
      50.66,
      26.28
    ]
  ],
  "BI": [
    "Burundi",
    [
      28.99,
      -4.47,
      30.85,
      -2.35
    ]
  ],
  "BJ": [
    "Benin",
    [
      0.77,
      6.14,
      3.85,
      12.41
    ]
  ],
  "BM": [
    "Bermuda",
    [
      -64.89,
      32.26,
      -64.65,
      32.39
    ]
  ],
  "BN": [
    "Brunei",
    [
      114.08,
      4.01,
      115.36,
      5.05
    ]
  ],
  "BO": [
    "Bolivia",
    [
      -69.59,
      -22.9,
      -57.49,
      -9.68
    ]
  ],
  "BR": [
    "Brazil",
    [
      -73.99,
      -33.75,
      -34.79,
      5.27
    ]
  ],
  "BS": [
    "Bahamas",
    [
      -78.99,
      20.91,
      -72.45,
      27.26
    ]
  ],
  "BT": [
    "Bhutan",
    [
      88.76,
      26.71,
      92.13,
      28.25
    ]
  ],
  "BV": [
    "Bouvet Island",
    [
      3.26,
      -54.47,
      3.63,
      -54.31
    ]
  ],
  "BW": [
    "Botswana",
    [
      20,
      -26.83,
      29.36,
      -17.78
    ]
  ],
  "BY": [
    "Belarus",
    [
      23.2,
      51.32,
      32.69,
      56.17
    ]
  ],
  "BZ": [
    "Belize",
    [
      -89.23,
      15.89,
      -87.78,
      18.5
    ]
  ],
  "CA": [
    "Canada",
    [
      -141,
      41.68,
      -52.65,
      83.11
    ]
  ],
  "CC": [
    "Cocos [Keeling] Islands",
    [
      96.83,
      -12.2,
      96.93,
      -12.08
    ]
  ],
  "CD": [
    "Congo [DRC]",
    [
      12.04,
      -13.46,
      31.31,
      5.39
    ]
  ],
  "CF": [
    "Central African Republic",
    [
      14.42,
      2.27,
      27.46,
      11.14
    ]
  ],
  "CG": [
    "Congo [Republic]",
    [
      11.09,
      -5.04,
      18.64,
      3.71
    ]
  ],
  "CH": [
    "Switzerland",
    [
      5.96,
      45.82,
      10.49,
      47.81
    ]
  ],
  "CI": [
    "Côte d'Ivoire",
    [
      -8.6,
      4.34,
      -2.56,
      10.73
    ]
  ],
  "CK": [
    "Cook Islands",
    [
      -165.78,
      -21.94,
      -157.31,
      -10.04
    ]
  ],
  "CL": [
    "Chile",
    [
      -75.64,
      -55.92,
      -66.42,
      -17.51
    ]
  ],
  "CM": [
    "Cameroon",
    [
      8.5,
      1.65,
      16.01,
      13.08
    ]
  ],
  "CN": [
    "China",
    [
      73.68,
      18.2,
      134.77,
      53.56
    ]
  ],
  "CO": [
    "Colombia",
    [
      -79.01,
      -4.23,
      -66.88,
      12.44
    ]
  ],
  "CR": [
    "Costa Rica",
    [
      -85.94,
      8.04,
      -82.55,
      11.22
    ]
  ],
  "CU": [
    "Cuba",
    [
      -84.96,
      19.83,
      -74.13,
      23.27
    ]
  ],
  "CV": [
    "Cape Verde",
    [
      -25.36,
      14.8,
      -22.67,
      17.2
    ]
  ],
  "CX": [
    "Christmas Island",
    [
      105.62,
      -10.57,
      105.73,
      -10.41
    ]
  ],
  "CY": [
    "Cyprus",
    [
      32.27,
      34.57,
      34,
      35.17
    ]
  ],
  "CZ": [
    "Czech Republic",
    [
      12.09,
      48.56,
      18.86,
      51.06
    ]
  ],
  "DE": [
    "Germany",
    [
      5.87,
      47.27,
      15.04,
      55.06
    ]
  ],
  "DJ": [
    "Djibouti",
    [
      41.77,
      10.93,
      43.42,
      12.7
    ]
  ],
  "DK": [
    "Denmark",
    [
      8.09,
      54.57,
      15.15,
      57.75
    ]
  ],
  "DM": [
    "Dominica",
    [
      -61.48,
      15.21,
      -61.26,
      15.64
    ]
  ],
  "DO": [
    "Dominican Republic",
    [
      -72.01,
      17.54,
      -68.32,
      19.94
    ]
  ],
  "DZ": [
    "Algeria",
    [
      -8.68,
      18.96,
      11.97,
      37.1
    ]
  ],
  "EC": [
    "Ecuador",
    [
      -92.01,
      -5.01,
      -75.23,
      1.67
    ]
  ],
  "EE": [
    "Estonia",
    [
      21.83,
      57.52,
      28.21,
      59.92
    ]
  ],
  "EG": [
    "Egypt",
    [
      24.7,
      22,
      35.82,
      31.59
    ]
  ],
  "EH": [
    "Western Sahara",
    [
      -17.1,
      20.77,
      -8.67,
      27.66
    ]
  ],
  "ER": [
    "Eritrea",
    [
      36.43,
      12.36,
      43.11,
      18
    ]
  ],
  "ES": [
    "Spain",
    [
      -18.17,
      27.64,
      4.59,
      43.79
    ]
  ],
  "ET": [
    "Ethiopia",
    [
      32.95,
      3.4,
      47.98,
      14.96
    ]
  ],
  "FI": [
    "Finland",
    [
      19.32,
      59.45,
      31.59,
      70.09
    ]
  ],
  "FJ": [
    "Fiji",
    [
      177.13,
      -19.11,
      -178.42,
      -16.02
    ]
  ],
  "FK": [
    "Falkland Islands [Islas Malvinas]",
    [
      -61.34,
      -52.32,
      -57.7,
      -51.01
    ]
  ],
  "FM": [
    "Micronesia",
    [
      137.22,
      1.03,
      163.05,
      10.09
    ]
  ],
  "FO": [
    "Faroe Islands",
    [
      -7.69,
      61.39,
      -6.26,
      62.39
    ]
  ],
  "FR": [
    "France",
    [
      -5.45,
      41.37,
      9.66,
      51.12
    ]
  ],
  "GA": [
    "Gabon",
    [
      8.8,
      -3.98,
      14.5,
      2.33
    ]
  ],
  "GB": [
    "United Kingdom",
    [
      -8.62,
      49.91,
      1.76,
      60.85
    ]
  ],
  "GD": [
    "Grenada",
    [
      -61.8,
      11.98,
      -61.58,
      12.6
    ]
  ],
  "GE": [
    "Georgia",
    [
      39.99,
      41.06,
      46.69,
      43.58
    ]
  ],
  "GF": [
    "French Guiana",
    [
      -54.54,
      2.11,
      -51.63,
      5.76
    ]
  ],
  "GG": [
    "Guernsey",
    [
      -2.67,
      49.41,
      -2.5,
      49.51
    ]
  ],
  "GH": [
    "Ghana",
    [
      -3.25,
      4.71,
      1.19,
      11.17
    ]
  ],
  "GI": [
    "Gibraltar",
    [
      -5.37,
      36.11,
      -5.34,
      36.16
    ]
  ],
  "GL": [
    "Greenland",
    [
      -73.05,
      59.75,
      -12.21,
      83.62
    ]
  ],
  "GM": [
    "Gambia",
    [
      -16.84,
      13.07,
      -13.79,
      13.83
    ]
  ],
  "GN": [
    "Guinea",
    [
      -15.08,
      7.19,
      -7.64,
      12.68
    ]
  ],
  "GP": [
    "Guadeloupe",
    [
      -61.81,
      15.83,
      -61,
      16.51
    ]
  ],
  "GQ": [
    "Equatorial Guinea",
    [
      5.61,
      0.92,
      11.34,
      2.34
    ]
  ],
  "GR": [
    "Greece",
    [
      19.37,
      34.92,
      28.24,
      41.75
    ]
  ],
  "GS": [
    "South Georgia and the South Sandwich Islands",
    [
      -38,
      -59.49,
      -26.24,
      -53.9
    ]
  ],
  "GT": [
    "Guatemala",
    [
      -92.25,
      13.74,
      -88.22,
      17.82
    ]
  ],
  "GU": [
    "Guam",
    [
      144.62,
      13.18,
      144.95,
      13.65
    ]
  ],
  "GW": [
    "Guinea-Bissau",
    [
      -16.72,
      10.93,
      -13.7,
      12.69
    ]
  ],
  "GY": [
    "Guyana",
    [
      -61.4,
      1.19,
      -56.54,
      8.56
    ]
  ],
  "GZ": [
    "Gaza Strip",
    [
      34.2,
      31.22,
      34.54,
      31.61
    ]
  ],
  "HK": [
    "Hong Kong",
    [
      113.84,
      22.15,
      114.4,
      22.56
    ]
  ],
  "HM": [
    "Heard Island and McDonald Islands",
    [
      73.31,
      -53.19,
      73.5,
      -53.03
    ]
  ],
  "HN": [
    "Honduras",
    [
      -89.35,
      12.98,
      -83.16,
      16.01
    ]
  ],
  "HR": [
    "Croatia",
    [
      13.49,
      42.39,
      19.43,
      46.54
    ]
  ],
  "HT": [
    "Haiti",
    [
      -74.48,
      18.02,
      -71.62,
      19.93
    ]
  ],
  "HU": [
    "Hungary",
    [
      16.11,
      45.74,
      22.71,
      48.59
    ]
  ],
  "ID": [
    "Indonesia",
    [
      95.01,
      -10.36,
      141.02,
      5.48
    ]
  ],
  "IE": [
    "Ireland",
    [
      -10.47,
      51.43,
      -5.43,
      55.38
    ]
  ],
  "IL": [
    "Israel",
    [
      34.27,
      29.5,
      35.88,
      33.28
    ]
  ],
  "IM": [
    "Isle of Man",
    [
      -4.8,
      54.06,
      -4.31,
      54.42
    ]
  ],
  "IN": [
    "India",
    [
      68.18,
      6.75,
      97.4,
      35.67
    ]
  ],
  "IO": [
    "British Indian Ocean Territory",
    [
      71.26,
      -7.44,
      72.5,
      -5.27
    ]
  ],
  "IQ": [
    "Iraq",
    [
      38.79,
      29.1,
      48.57,
      37.39
    ]
  ],
  "IR": [
    "Iran",
    [
      44.04,
      25.06,
      63.32,
      39.78
    ]
  ],
  "IS": [
    "Iceland",
    [
      -24.55,
      63.4,
      -13.49,
      66.54
    ]
  ],
  "IT": [
    "Italy",
    [
      6.61,
      36.65,
      18.51,
      47.09
    ]
  ],
  "JE": [
    "Jersey",
    [
      -2.25,
      49.17,
      -2.01,
      49.26
    ]
  ],
  "JM": [
    "Jamaica",
    [
      -78.37,
      17.7,
      -76.2,
      18.53
    ]
  ],
  "JO": [
    "Jordan",
    [
      34.96,
      29.19,
      39.3,
      33.38
    ]
  ],
  "JP": [
    "Japan",
    [
      122.94,
      24.4,
      153.99,
      45.55
    ]
  ],
  "KE": [
    "Kenya",
    [
      33.91,
      -4.68,
      41.89,
      5.51
    ]
  ],
  "KG": [
    "Kyrgyzstan",
    [
      69.24,
      39.19,
      80.26,
      43.26
    ]
  ],
  "KH": [
    "Cambodia",
    [
      102.31,
      10.42,
      107.61,
      14.71
    ]
  ],
  "KI": [
    "Kiribati",
    [
      -174.54,
      -11.45,
      176.85,
      4.72
    ]
  ],
  "KM": [
    "Comoros",
    [
      43.21,
      -12.39,
      44.54,
      -11.36
    ]
  ],
  "KN": [
    "Saint Kitts and Nevis",
    [
      -62.86,
      17.09,
      -62.52,
      17.42
    ]
  ],
  "KP": [
    "North Korea",
    [
      124.19,
      37.68,
      130.7,
      43.01
    ]
  ],
  "KR": [
    "South Korea",
    [
      126.12,
      33.19,
      129.58,
      38.61
    ]
  ],
  "KW": [
    "Kuwait",
    [
      46.56,
      28.52,
      48.42,
      30.1
    ]
  ],
  "KY": [
    "Cayman Islands",
    [
      -81.43,
      19.26,
      -79.73,
      19.96
    ]
  ],
  "KZ": [
    "Kazakhstan",
    [
      46.49,
      40.57,
      87.32,
      55.45
    ]
  ],
  "LA": [
    "Laos",
    [
      100.1,
      13.92,
      107.64,
      22.51
    ]
  ],
  "LB": [
    "Lebanon",
    [
      35.1,
      33.09,
      36.63,
      34.69
    ]
  ],
  "LC": [
    "Saint Lucia",
    [
      -61.08,
      13.71,
      -60.89,
      14.11
    ]
  ],
  "LI": [
    "Liechtenstein",
    [
      9.48,
      47.05,
      9.64,
      47.27
    ]
  ],
  "LK": [
    "Sri Lanka",
    [
      79.65,
      5.72,
      81.89,
      9.84
    ]
  ],
  "LR": [
    "Liberia",
    [
      -11.49,
      4.35,
      -7.37,
      8.55
    ]
  ],
  "LS": [
    "Lesotho",
    [
      27.01,
      -30.64,
      29.46,
      -28.57
    ]
  ],
  "LT": [
    "Lithuania",
    [
      20.94,
      53.9,
      26.84,
      56.45
    ]
  ],
  "LU": [
    "Luxembourg",
    [
      5.73,
      49.44,
      6.53,
      50.18
    ]
  ],
  "LV": [
    "Latvia",
    [
      20.97,
      55.68,
      28.24,
      58.08
    ]
  ],
  "LY": [
    "Libya",
    [
      9.32,
      19.58,
      25.16,
      33.17
    ]
  ],
  "MA": [
    "Morocco",
    [
      -17.02,
      21.42,
      -1.03,
      36.05
    ]
  ],
  "MC": [
    "Monaco",
    [
      7.37,
      43.72,
      7.44,
      43.78
    ]
  ],
  "MD": [
    "Moldova",
    [
      26.62,
      45.49,
      30.14,
      48.49
    ]
  ],
  "ME": [
    "Montenegro",
    [
      18.42,
      41.85,
      20.36,
      43.56
    ]
  ],
  "MG": [
    "Madagascar",
    [
      43.23,
      -25.61,
      50.49,
      -11.94
    ]
  ],
  "MH": [
    "Marshall Islands",
    [
      165.52,
      4.57,
      172.03,
      14.61
    ]
  ],
  "MK": [
    "Macedonia [FYROM]",
    [
      20.45,
      40.85,
      23.04,
      42.37
    ]
  ],
  "ML": [
    "Mali",
    [
      -12.24,
      10.14,
      4.24,
      25
    ]
  ],
  "MM": [
    "Myanmar [Burma]",
    [
      92.17,
      9.79,
      101.17,
      28.55
    ]
  ],
  "MN": [
    "Mongolia",
    [
      87.75,
      41.6,
      119.77,
      52.13
    ]
  ],
  "MO": [
    "Macau",
    [
      113.53,
      22.11,
      113.61,
      22.22
    ]
  ],
  "MP": [
    "Northern Mariana Islands",
    [
      144.89,
      14.11,
      146.06,
      20.62
    ]
  ],
  "MQ": [
    "Martinique",
    [
      -61.23,
      14.39,
      -60.81,
      14.88
    ]
  ],
  "MR": [
    "Mauritania",
    [
      -17.07,
      14.72,
      -4.83,
      27.29
    ]
  ],
  "MS": [
    "Montserrat",
    [
      -62.24,
      16.67,
      -62.14,
      16.82
    ]
  ],
  "MT": [
    "Malta",
    [
      14.18,
      35.8,
      14.57,
      36.08
    ]
  ],
  "MU": [
    "Mauritius",
    [
      57.32,
      -20.52,
      63.5,
      -10.14
    ]
  ],
  "MV": [
    "Maldives",
    [
      72.67,
      -0.91,
      73.76,
      7.1
    ]
  ],
  "MW": [
    "Malawi",
    [
      32.67,
      -17.14,
      35.92,
      -9.39
    ]
  ],
  "MX": [
    "Mexico",
    [
      -118.45,
      14.53,
      -86.7,
      32.72
    ]
  ],
  "MY": [
    "Malaysia",
    [
      99.65,
      0.85,
      119.28,
      7.36
    ]
  ],
  "MZ": [
    "Mozambique",
    [
      30.21,
      -26.87,
      40.78,
      -10.47
    ]
  ],
  "NA": [
    "Namibia",
    [
      11.72,
      -28.97,
      25.26,
      -16.96
    ]
  ],
  "NC": [
    "New Caledonia",
    [
      163.61,
      -22.7,
      167.28,
      -19.54
    ]
  ],
  "NE": [
    "Niger",
    [
      0.17,
      11.69,
      16,
      23.52
    ]
  ],
  "NF": [
    "Norfolk Island",
    [
      167.92,
      -29.09,
      168,
      -29
    ]
  ],
  "NG": [
    "Nigeria",
    [
      2.7,
      4.27,
      14.66,
      13.9
    ]
  ],
  "NI": [
    "Nicaragua",
    [
      -87.69,
      10.71,
      -82.97,
      15.03
    ]
  ],
  "NL": [
    "Netherlands",
    [
      3.33,
      50.75,
      7.23,
      53.56
    ]
  ],
  "NO": [
    "Norway",
    [
      4.99,
      57.98,
      31.05,
      71.19
    ]
  ],
  "NP": [
    "Nepal",
    [
      80.06,
      26.35,
      88.2,
      30.45
    ]
  ],
  "NR": [
    "Nauru",
    [
      166.91,
      -0.55,
      166.96,
      -0.49
    ]
  ],
  "NU": [
    "Niue",
    [
      -169.95,
      -19.15,
      -169.77,
      -18.92
    ]
  ],
  "NZ": [
    "New Zealand",
    [
      165.85,
      -47.3,
      179.06,
      -33.88
    ]
  ],
  "OM": [
    "Oman",
    [
      52,
      16.64,
      59.84,
      26.4
    ]
  ],
  "PA": [
    "Panama",
    [
      -83.05,
      7.21,
      -77.16,
      9.64
    ]
  ],
  "PE": [
    "Peru",
    [
      -81.33,
      -18.35,
      -68.68,
      -0.04
    ]
  ],
  "PF": [
    "French Polynesia",
    [
      -152.9,
      -27.65,
      -134.94,
      -7.91
    ]
  ],
  "PG": [
    "Papua New Guinea",
    [
      140.85,
      -11.64,
      155.97,
      -1.35
    ]
  ],
  "PH": [
    "Philippines",
    [
      116.95,
      4.66,
      126.61,
      21.12
    ]
  ],
  "PK": [
    "Pakistan",
    [
      60.87,
      23.68,
      77.83,
      37.07
    ]
  ],
  "PL": [
    "Poland",
    [
      14.11,
      49,
      24.15,
      54.83
    ]
  ],
  "PM": [
    "Saint Pierre and Miquelon",
    [
      -56.4,
      46.7,
      -56.11,
      47.15
    ]
  ],
  "PN": [
    "Pitcairn Islands",
    [
      -130.85,
      -25.08,
      -124.77,
      -23.93
    ]
  ],
  "PR": [
    "Puerto Rico",
    [
      -67.94,
      17.88,
      -65.24,
      18.52
    ]
  ],
  "PS": [
    "Palestinian Territories",
    [
      34.2,
      31.22,
      35.57,
      32.55
    ]
  ],
  "PT": [
    "Portugal",
    [
      -31.27,
      30.03,
      -6.19,
      42.15
    ]
  ],
  "PW": [
    "Palau",
    [
      131.12,
      2.95,
      134.72,
      8.09
    ]
  ],
  "PY": [
    "Paraguay",
    [
      -62.65,
      -27.59,
      -54.29,
      -19.29
    ]
  ],
  "QA": [
    "Qatar",
    [
      50.74,
      24.56,
      51.62,
      26.16
    ]
  ],
  "RE": [
    "Réunion",
    [
      55.22,
      -21.39,
      55.84,
      -20.87
    ]
  ],
  "RO": [
    "Romania",
    [
      20.26,
      43.63,
      29.69,
      48.26
    ]
  ],
  "RS": [
    "Serbia",
    [
      18.82,
      42.25,
      23,
      46.19
    ]
  ],
  "RU": [
    "Russia",
    [
      19.64,
      41.19,
      180,
      81.86
    ]
  ],
  "RW": [
    "Rwanda",
    [
      28.86,
      -2.84,
      30.9,
      -1.06
    ]
  ],
  "SA": [
    "Saudi Arabia",
    [
      34.49,
      16.37,
      55.67,
      32.16
    ]
  ],
  "SB": [
    "Solomon Islands",
    [
      155.51,
      -12.29,
      170.4,
      -6.6
    ]
  ],
  "SC": [
    "Seychelles",
    [
      46.2,
      -9.76,
      56.29,
      -3.71
    ]
  ],
  "SD": [
    "Sudan",
    [
      21.83,
      8.68,
      38.6,
      22.23
    ]
  ],
  "SE": [
    "Sweden",
    [
      11.12,
      55.34,
      24.17,
      69.06
    ]
  ],
  "SG": [
    "Singapore",
    [
      103.6,
      1.15,
      104.06,
      1.47
    ]
  ],
  "SH": [
    "Saint Helena",
    [
      -14.42,
      -16.02,
      -5.63,
      -7.89
    ]
  ],
  "SI": [
    "Slovenia",
    [
      13.38,
      45.42,
      16.61,
      46.88
    ]
  ],
  "SJ": [
    "Svalbard and Jan Mayen",
    [
      10.37,
      74.17,
      33.34,
      80.76
    ]
  ],
  "SK": [
    "Slovakia",
    [
      16.83,
      47.73,
      22.56,
      49.6
    ]
  ],
  "SL": [
    "Sierra Leone",
    [
      -13.31,
      6.91,
      -10.28,
      10.05
    ]
  ],
  "SM": [
    "San Marino",
    [
      12.4,
      43.89,
      12.52,
      43.99
    ]
  ],
  "SN": [
    "Senegal",
    [
      -17.54,
      12.31,
      -11.36,
      16.69
    ]
  ],
  "SO": [
    "Somalia",
    [
      40.99,
      -1.69,
      51.41,
      11.98
    ]
  ],
  "SR": [
    "Suriname",
    [
      -58.07,
      1.83,
      -53.95,
      6.01
    ]
  ],
  "ST": [
    "São Tomé and Príncipe",
    [
      6.47,
      0.02,
      7.46,
      1.74
    ]
  ],
  "SV": [
    "El Salvador",
    [
      -90.12,
      13.15,
      -87.69,
      14.45
    ]
  ],
  "SY": [
    "Syria",
    [
      35.69,
      32.32,
      42.37,
      37.32
    ]
  ],
  "SZ": [
    "Swaziland",
    [
      30.79,
      -27.32,
      32.13,
      -25.73
    ]
  ],
  "TC": [
    "Turks and Caicos Islands",
    [
      -72.51,
      21.14,
      -71.09,
      21.96
    ]
  ],
  "TD": [
    "Chad",
    [
      13.47,
      7.44,
      24,
      23.45
    ]
  ],
  "TF": [
    "French Southern Territories",
    [
      40.64,
      -50.02,
      77.58,
      -36.22
    ]
  ],
  "TG": [
    "Togo",
    [
      -0.15,
      5.93,
      1.81,
      11.14
    ]
  ],
  "TH": [
    "Thailand",
    [
      97.35,
      5.63,
      105.64,
      20.46
    ]
  ],
  "TJ": [
    "Tajikistan",
    [
      67.35,
      36.68,
      75.14,
      41.04
    ]
  ],
  "TK": [
    "Tokelau",
    [
      -172.57,
      -9.43,
      -171.21,
      -8.51
    ]
  ],
  "TL": [
    "Timor-Leste",
    [
      124.05,
      -9.5,
      127.34,
      -8.13
    ]
  ],
  "TM": [
    "Turkmenistan",
    [
      52.45,
      35.14,
      66.68,
      42.8
    ]
  ],
  "TN": [
    "Tunisia",
    [
      7.5,
      30.23,
      11.57,
      37.54
    ]
  ],
  "TO": [
    "Tonga",
    [
      -176.22,
      -22.35,
      -173.54,
      -15.56
    ]
  ],
  "TR": [
    "Turkey",
    [
      25.91,
      35.81,
      44.8,
      42.11
    ]
  ],
  "TT": [
    "Trinidad and Tobago",
    [
      -61.93,
      10.04,
      -60.89,
      10.87
    ]
  ],
  "TV": [
    "Tuvalu",
    [
      179.09,
      -8.1,
      179.26,
      -5.64
    ]
  ],
  "TW": [
    "Taiwan",
    [
      119.38,
      21.93,
      122.01,
      25.3
    ]
  ],
  "TZ": [
    "Tanzania",
    [
      29.34,
      -11.74,
      40.44,
      -0.98
    ]
  ],
  "UA": [
    "Ukraine",
    [
      22.13,
      44.19,
      40.23,
      52.38
    ]
  ],
  "UG": [
    "Uganda",
    [
      29.55,
      -1.48,
      35.01,
      4.22
    ]
  ],
  "US": [
    "United States",
    [
      -179.15,
      18.91,
      -66.97,
      71.54
    ]
  ],
  "UY": [
    "Uruguay",
    [
      -58.45,
      -34.97,
      -53.11,
      -30.08
    ]
  ],
  "UZ": [
    "Uzbekistan",
    [
      55.98,
      37.18,
      73.14,
      45.56
    ]
  ],
  "VA": [
    "Vatican City",
    [
      12.45,
      41.9,
      12.46,
      41.91
    ]
  ],
  "VC": [
    "Saint Vincent and the Grenadines",
    [
      -61.47,
      12.58,
      -61.11,
      13.38
    ]
  ],
  "VE": [
    "Venezuela",
    [
      -73.35,
      0.65,
      -59.54,
      12.21
    ]
  ],
  "VG": [
    "British Virgin Islands",
    [
      -64.73,
      18.31,
      -64.27,
      18.76
    ]
  ],
  "VI": [
    "U.S. Virgin Islands",
    [
      -65.08,
      17.67,
      -64.56,
      18.42
    ]
  ],
  "VN": [
    "Vietnam",
    [
      102.14,
      8.18,
      109.46,
      23.35
    ]
  ],
  "VU": [
    "Vanuatu",
    [
      166.52,
      -20.25,
      170.23,
      -13.07
    ]
  ],
  "WF": [
    "Wallis and Futuna",
    [
      -178.19,
      -14.31,
      -176.12,
      -13.21
    ]
  ],
  "WS": [
    "Samoa",
    [
      -173.01,
      -14.05,
      -171.44,
      -13.44
    ]
  ],
  "XK": [
    "Kosovo",
    [
      20.21,
      41.86,
      21.78,
      43.27
    ]
  ],
  "YE": [
    "Yemen",
    [
      42.54,
      12.11,
      54.54,
      19
    ]
  ],
  "YT": [
    "Mayotte",
    [
      45,
      -13,
      45.3,
      -12.64
    ]
  ],
  "ZA": [
    "South Africa",
    [
      16.45,
      -34.84,
      32.89,
      -22.13
    ]
  ],
  "ZM": [
    "Zambia",
    [
      22,
      -18.08,
      33.71,
      -8.22
    ]
  ],
  "ZW": [
    "Zimbabwe",
    [
      25.24,
      -22.41,
      33.06,
      -15.61
    ]
  ]
};
