import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";
import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const usePersonalContactModalStyles = createStyles((theme) => ({
  inputBlock: {
    margin: '0 0 18px 0'
  },

  pseudoInput: {
    [`& .${CSS_KEY}-InputWrapper-label, & .${CSS_KEY}-Input-wrapper`]: {
      display: 'none'
    },
    [`& .${CSS_KEY}-InputWrapper-error`]: {
      marginTop: 5
    }
  },

  label: {
    display: 'inline-block',
    wordBreak: 'break-word',
    cursor: 'default',
    color: '#A1AABA',
    fontSize: '16px',
    marginBottom: 8,
    fontWeight: 500
  },

  phoneInput: {
    '&:focus-within .PhoneInputCountry': {
      borderBottom: `1px solid ${genericColorPalette.blue[6]}`
    },

    '& .PhoneInputCountry': {
      backgroundColor: theme.colors.neutral[6],
      padding: '0 5px',
      margin: 0,
      borderBottom: '1px solid #A1AABA',
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none'
    },

    '& .PhoneInputInput': {
      fontFamily: "'Inter',sans-serif",
      height: '36px',
      lineHeight: '34px',
      appearance: 'none',
      resize: 'none',
      boxSizing: 'border-box',
      fontSize: '14px',
      width: '100%',
      color: '#FFFFFF',
      display: 'block',
      textAlign: 'left',
      minHeight: '36px',
      paddingLeft: '12px',
      paddingRight: '12px',
      borderRadius: 0,
      transition: 'border-color 100ms ease',
      backgroundColor: '#576275',
      borderBottom: '1px solid #A1AABA',
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',

      '&:focus': {
        outline: 'none',
        borderColor: genericColorPalette.blue[6]
      }
    }
  }
}));
