import {
  ActionIcon,
  Box,
  BoxProps,
  Collapse,
  Flex,
  Text,
  UnstyledButton
} from "@mantine/core";
import React, { ButtonHTMLAttributes, useCallback } from "react";

import { ReactComponent as ChevronRight } from "@/common/icons/Tree/chevron-right.svg";

import { useTreeViewItemStyles } from "./TreeViewItem.styles";

type Props = BoxProps &{
  headerText?: string;
  /** Use element instead `headerText` */
  headerElement?: React.ReactNode;
  /** You can use ev.currentTarget.value to get `buttonValue` prop */
  onToggle?: (isExpanded: boolean, ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  collapsedContent?: React.ReactNode;
  /** Value for buttons, you can get it from event in `onToggle()` */
  buttonValue?: ButtonHTMLAttributes<HTMLButtonElement>['value'];
  disabled?: boolean;
  isExpanded?: boolean;
};

const TreeViewItemComponent: React.FC<Props> = ({
  headerText = "Header 1",
  headerElement,
  isExpanded = false,
  onToggle,
  collapsedContent,
  buttonValue,
  disabled,
  ...boxProps
}) => {
  const { classes, cx } = useTreeViewItemStyles();
  const toggleExpand = useCallback((ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onToggle?.(!isExpanded, ev);

  }, [isExpanded, onToggle]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box { ...boxProps } >
      <Flex gap={ 8 } align="center" className={ classes.headerRow }>
        <ActionIcon
          className={ cx(
            classes.chevronIcon,
            isExpanded && classes.chevronIconExpanded
          ) }
          size="sm"
          onClick={ toggleExpand }
          value={ buttonValue }
          disabled={ disabled }
        >
          <ChevronRight />
        </ActionIcon>

        { headerElement ? (
          headerElement
        ) : (
          <UnstyledButton onClick={ toggleExpand } value={ buttonValue }>
            <Text fz={ 18 } fw={ 700 }>
              { headerText }
            </Text>
          </UnstyledButton>
        ) }
      </Flex>

      <Collapse in={ isExpanded }>{ collapsedContent }</Collapse>
    </Box>
  );
};

export default TreeViewItemComponent;
