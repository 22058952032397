import { Divider } from "@mantine/core";
import { FC, useMemo } from "react";

import { Popup } from "@/common/components/Popup/Popup.components";
import { RiskLabel } from "@/common/components/RiskLabel/RiskLabel.component";
import { ReactComponent as RisklineLogo } from "@/common/icons/riskline.svg";
import { removeHTMLTags } from "@/common/util/format/string";
import {
  useCountryRiskLevelPopupStyles
} from "@/tenant-context/visualize-country-risk/components/CountryRiskLevelPopup/CountryRiskLevelPopup.styles";
import { CountryRiskLevel } from "@/tenant-context/visualize-country-risk/types/country-risk.types";

type Props = {
  longitude: number;
  latitude: number;
  data: CountryRiskLevel;
}

const CountryRiskLevelPopupComponent: FC<Props> = ({
  longitude,
  latitude,
  data
}) => {
  const { classes } = useCountryRiskLevelPopupStyles();

  const mappedSummary = useMemo(() => {

    const { summary } = data;

    // ToDo - Make this better
    const splittedSummary = summary.split('<p>');
    const countryOverview = removeHTMLTags(splittedSummary[1]).split(':')[1].trim();
    const securityOverview = removeHTMLTags(splittedSummary[2]).split(':')[2].trim();
    // eslint-disable-next-line no-magic-numbers
    const covidOverview = removeHTMLTags(splittedSummary[3]).split(':')[2].trim();

    return {
      countryOverview,
      securityOverview,
      covidOverview
    };
  }, [data]);

  return (
    <Popup
      longitude={ longitude }
      latitude={ latitude }
    >
      <div className={ classes.wrapper }>
        <div className={ classes.countryWrapper }>
          <h4>{ data.country }</h4>
          <RiskLabel
            riskLevel={ data.risk_level.id }
            riskLevelLabel={ data.risk_level.name }
          />
        </div>

        <div className={ classes.summaryText }>
          { data.country } is a <b>{ data.risk_level.name } Risk</b> destination: &nbsp;
          { mappedSummary.countryOverview }
        </div>

        <div className={ classes.subHeading }>
          <span>Security</span>
          <RiskLabel
            riskLevel={ data.security_risk_level.id }
            riskLevelLabel={ data.security_risk_level.name }
          />
        </div>

        <div className={ classes.summaryText }>
          { mappedSummary.securityOverview }
        </div>

        <div className={ classes.subHeading }>
          <span>COVID-19</span>
          <RiskLabel
            riskLevel={ data.covid_risk_level.id }
            riskLevelLabel={ data.covid_risk_level.name }
          />
        </div>

        <div className={ classes.summaryText }>
          { mappedSummary.covidOverview }
        </div>

        <Divider className={ classes.divider } />

        <div className={ classes.riskSource }>
          <RisklineLogo />
          <span>Alert issued via Riskline</span>
        </div>
      </div>
    </Popup>
  );
};

export default CountryRiskLevelPopupComponent;
