import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const RiskLineEventsSettingsStyles = createStyles((theme) =>({
  riskEventSettingsContainer:{
    fontFamily:theme.fontFamily
  },
  riskLineRiskAlertSwitchContent: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '16px',
    paddingBottom: '16px',
    background: theme.colors.neutral[8],
    'svg': {
      margin: 0
    }
  },
  riskLineRiskAlertIconNDescription: {
    display: 'flex',
    justifyContent: 'space-between',
    gap:'14px',
    alignItems: 'center'
  },
  riskLineRiskAlertDescription: {
    fontWeight: theme.other.fw.bold,
    fontSize: theme.headings.sizes.h4.fontSize,
    lineHeight: '120%',
    color: theme.colors.neutral[0]
  },
  riskLineRiskAlertSubTopic: {
    fontWeight: theme.other.fw.bold,
    fontSize: theme.fontSizes.md,
    lineHeight: '120%',
    marginLeft: '8px',
    marginTop: '48px'
  },
  riskCategoryType: {
    fontFamily:theme.fontFamily,
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.md,
    lineHeight: '150%',
    marginLeft: '3px'
  },
  orderList: {
    marginTop: '32px'
  },
  buttonSeparator: {
    marginTop: '25px',
    marginBottom: '40px',
    left: '0%',
    right: '99.7%',
    top: '100%',
    bottom: '-33600%',
    background: theme.colors.neutral[6],
    opacity: 0.3
  },
  settingBlock: {
    marginTop:'67px',
    marginLeft:'8px',
    marginRight:'8px',
    h3: {
      fontSize: theme.fontSizes.sm,
      fontWeight: 500,
      textTransform: 'uppercase',
      marginBottom: 12
    },
    p: {
      fontSize: theme.fontSizes.sm,
      fontWeight: theme.other.fw.regular,
      marginBottom: 16
    },
    marginBottom: '65px',
    [`.${CSS_KEY}-Checkbox-root`]: {
      marginBottom: 12
    }
  },
  button: {
    background: theme.colors.info[0],
    height: 48
  },
  checkBoxLabel: {
    fontWeight: theme.other.fw.regular,
    fontSize: theme.fontSizes.sm,
    alignItems:'flex-start',
    lineHeight: '150%',
    display:'flex',
    gap:'6px'

  },
  listContent: {
    display: 'flex',
    justifyContent: 'space-between',
    gap:'16px',
    marginTop: '24px'
  },
  sliderSeparator: {
    marginTop: '25px',
    marginBottom: '40px',
    left: '0%',
    right: '99.7%',
    top: '100%',
    bottom: '-33600%',
    background: theme.colors.neutral[6],
    opacity: 0.3
  },
  sectionHeading: {
    fontWeight: theme.other.fw.semibold,
    fontFamily:theme.fontFamily,
    fontSize: theme.headings.sizes.h6.fontSize,
    lineHeight: theme.headings.sizes.h6.lineHeight,
    color:theme.colors.neutral[0],
    marginTop:'48px'
  },
  risklineIcon:{
    marginLeft:'20px',
    width:'24px',
    height:'24px'
  },
  divider:{
    marginTop:'32px'
  },
  riskLevelRangeSlider: {
    [`.${CSS_KEY}-Slider-track::before`]: {
      backgroundImage:`linear-gradient(to right, ${theme.colors.risk[0]}, ${theme.colors.risk[1]}, ${theme.colors.risk[2]},${theme.colors.risk[3]},${theme.colors.risk[4]})`
    },
    [`.${CSS_KEY}-Slider-bar`]:{
      opacity:0
    },
    [`.${CSS_KEY}-Slider-label`]:{
      color:theme.white,
      marginTop:'-10px',
      backgroundColor:theme.colors.neutral[7]
    },
    [`.${CSS_KEY}-Slider-markWrapper:first-of-type`]: {
      marginTop:'-4px !important',
      marginLeft:'-4px'
    },
    [`.${CSS_KEY}-Slider-markWrapper:last-of-type`]: {
      marginTop:'-4px !important',
      marginLeft:'2px'
    }
  },
  showLiveFeedBtn:{
    marginTop: '48px',
    marginBottom:'20px',
    padding:'8px',
    span:{
      color:theme.white,
      fontSize:theme.fontSizes.md,
      fontWeight:theme.other.fw.regular
      
    }
  },
  impactCalculationChkBox:{
    marginLeft:'8px',
    [`.${CSS_KEY}-Checkbox-inner`]:{
      marginTop:'-10px'
    }
  }

}));
