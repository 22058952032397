/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

const browserDatabase = {
  /**
   * Loads data from browser storage
   */
  getItem<T>(location: string): T | null {
    try {
      const entryObject = JSON.parse(localStorage.getItem(location) || '');
      const { data, expiration, createdAt } = entryObject;
      const MILLISECONDS_TO_SECONDS = 1000;

      if (expiration && Date.now() - createdAt > expiration * MILLISECONDS_TO_SECONDS) {
        localStorage.removeItem(location);

        return null;
      }

      return data;
    } catch {
      return null;
    }
  },

  /**
   * Save data to local storage
   */
  setItem<T>(location: string, data: T, expiration?: number) {
    localStorage.setItem(location, JSON.stringify({
      data,
      expiration,
      createdAt: Date.now()
    }));
  },

  /**
   * Delete item from local storage
   */
  deleteItem(location: string) {
    localStorage.removeItem(location);
  }
};

export default browserDatabase;
