import { parsePhoneNumber } from 'awesome-phonenumber';

import { PaginatedResult, ProfileContact } from "@/tenant-context/control-profile/types/profile";

export function addParsedPhonesToContacts(
  contacts?: PaginatedResult<ProfileContact> | null
) {
  if (!contacts) {
    return contacts;
  }

  // Attach parsed phone data to the entries
  const phoneFilter = (item: ProfileContact) => item.contactDetailType.toLowerCase() === 'phone' || 'mobile';
  const phonesWithParsedData = contacts
    .items
    .filter(phoneFilter)
    .map((item) => {
      const parsed = parsePhoneNumber(item.value);

      return {
        ...item,
        parsedPhoneNumber: parsed
      };
    });

  const completeContacts: PaginatedResult<ProfileContact> = {
    ...contacts,
    items: [
      ...contacts.items.filter((item) => !phoneFilter(item)),
      ...phonesWithParsedData
    ]
  };

  return completeContacts;
}