import mapboxgl from "mapbox-gl";
import { FC, useCallback } from "react";
import { useMap } from "react-map-gl";

import ButtonControl from "@/common/components/ButtonControl";
import { ReactComponent as NavigateLocationIcon } from "@/common/icons/navigateLocation.svg";
import { fitToViewportBounds } from "@/tenant-context/common/util/map";
import { drawRiskImpactCircle } from "@/tenant-context/visualisation-risk-details/util/drawRiskImpactCircle";

import { useNavigateToLocationControlStyles } from "./NavigateToLocationControl.styles";

type Props = {
  lat?:number,
  lon?:number,
  radius?:number
};

const NavigateToLocationControl: FC<Props> = ({
  lat, lon, radius
}) => {

  const { current: map } = useMap();
  const { classes } = useNavigateToLocationControlStyles();

  const zoomBackToLocation = useCallback(() => {
    if(!map || !lat || !lon || !radius){
      return;
    }
    const circleCenter = [lon, lat];
    const impactCircle = drawRiskImpactCircle(circleCenter, radius ?? 0, 100);

    // zooming the map to close proximity of the location
    const coords = impactCircle.geometry.coordinates[0].map((feature) =>feature  as [number, number]);
    const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);
    coords.forEach((coordinates) => bounds.extend(coordinates));
    fitToViewportBounds(map, bounds, { right: false });
  }, [lat, lon, map, radius]);

  return (
    <ButtonControl
      onClick={ zoomBackToLocation }
      className={ classes.button }
    >
      <NavigateLocationIcon />
    </ButtonControl>
  );
};

export default NavigateToLocationControl;
