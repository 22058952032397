/* eslint-disable no-magic-numbers */
import { FC, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { timeScaleDomainDeltaMap } from "@/tenant-context/control-location-graph/store/location-graph/location-graph.model";
import RiskTimelineGraphComponent
  from "@/tenant-context/control-risk-timeline/components/RiskTimelineGraph/RiskTimelineGraph.component";
import { riskTimelineGraphMargin } from "@/tenant-context/control-risk-timeline/config/risk-timeline.config";
import { GroupedRiskTimelineEvents } from "@/tenant-context/control-risk-timeline/types/risk-timeline.types";
import { fillDates, getMinMax } from "@/tenant-context/control-risk-timeline/utils/risk-timeline.utils";
import { RiskAlertEvent } from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type Props = {
  height: number;
  width: number;
  chartData: GroupedRiskTimelineEvents[];
}

const RiskTimelineGraph: FC<Props> = ({
  height,
  width: outerWidth,
  chartData
}) => {
  const {
    startTime,
    endTime
  } = useSelector((state: RootState) => state.riskTimeline);

  const {
    riskTimeline: {
      SET_TIME_DOMAIN
    },
    riskAlerts: {
      enableRiskAlertDrawer
    }
  } = useDispatch<Dispatch>();

  const chartWidth = useMemo(
    () => outerWidth - (riskTimelineGraphMargin.left * 4),
    [outerWidth]
  );
  const chartHeight = useMemo(
    () => height - riskTimelineGraphMargin.top - riskTimelineGraphMargin.bottom,
    [height]
  );
  const dates = useMemo(() => fillDates(startTime, endTime), [endTime, startTime]);
  const domain = useMemo(() => {
    const minMax = getMinMax(dates).map((date) => {
      const dt = new Date(date);
      return Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0, 0);
    });

    const minDate = minMax[0];
    const maxDate = new Date(minMax[1]);

    return [
      minDate,
      Date.UTC(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 0, 0, 0, 0)
    ];
  }, [dates]);

  const oneTickWidth = useMemo(
    () => (
      chartWidth / dates.length) + 16, // 16 is the padding of the left right axis ticks
    [chartWidth, dates]
  );

  const handleRiskAlertClicked = useCallback(
    (riskItem: RiskAlertEvent) =>
      enableRiskAlertDrawer(riskItem),
    [ enableRiskAlertDrawer ]
  );

  const handleTimeTravelForward = useCallback(() =>
    SET_TIME_DOMAIN({
      startTime: startTime + (timeScaleDomainDeltaMap.Hours * dates.length),
      endTime: endTime + (timeScaleDomainDeltaMap.Hours * dates.length)
    }), [ SET_TIME_DOMAIN, startTime, endTime, dates ]);

  const handleTimeTravelBackward = useCallback(() =>
    SET_TIME_DOMAIN({
      startTime: startTime - (timeScaleDomainDeltaMap.Hours * dates.length),
      endTime: endTime - (timeScaleDomainDeltaMap.Hours * dates.length)
    }), [ SET_TIME_DOMAIN, startTime, endTime, dates ]);

  const handleTimeTravelForwardForDaily = useCallback(() =>
    SET_TIME_DOMAIN({
      startTime: startTime + (timeScaleDomainDeltaMap.Hours),
      endTime: endTime + (timeScaleDomainDeltaMap.Hours)
    }), [SET_TIME_DOMAIN, startTime, endTime]);

  const handleTimeTravelBackwardForDaily = useCallback(() =>
    SET_TIME_DOMAIN({
      startTime: startTime - (timeScaleDomainDeltaMap.Hours),
      endTime: endTime - (timeScaleDomainDeltaMap.Hours)
    }), [SET_TIME_DOMAIN, startTime, endTime]);

  return <RiskTimelineGraphComponent
    height={ height }
    width={ outerWidth }
    chartData={ chartData }
    chartWidth={ chartWidth }
    chartHeight={ chartHeight }
    dates={ dates }
    domain={ domain }
    oneTickWidth={ oneTickWidth }
    onTimeTravelForward={ handleTimeTravelForward }
    onTimeTravelBackward={ handleTimeTravelBackward }
    onTimeTravelForwardForDaily={ handleTimeTravelForwardForDaily }
    onTimeTravelBackwardForDaily={ handleTimeTravelBackwardForDaily }
    onRiskAlertClicked={ handleRiskAlertClicked }
  />;
};

export default RiskTimelineGraph;
