import { Flex, Text, UnstyledButton } from '@mantine/core';
import { FC, useCallback, useContext } from 'react';

import PersonAvatar from "@/common/components/PersonAvatar";
import { ReactComponent as LogoutIcon } from "@/common/icons/exit.svg";
import {
  useNavigationUserDetailsItemStyles
} from "@/core/navigation/components/NavigationUserDetailsItem/NavigationUserDetailsItem.styles";
import { NavigationSidebarContext } from "@/core/navigation/context/NavigationSidebar.context";
import { useAuthContext } from "@/tenant-context/core/context/Auth.context";

const NavigationUserDetailsItem: FC = () => {
  const {
    userDetails,
    profileDetails,
    isExpanded,
    setIsExpanded
  } = useContext(NavigationSidebarContext);

  const {
    logout
  } = useAuthContext();

  const { classes } = useNavigationUserDetailsItemStyles({ isExpanded: isExpanded });

  const handleClick = useCallback(() => {
    if (!isExpanded) {
      setIsExpanded(true);
    }
  }, [ isExpanded, setIsExpanded ]);

  const handleLogout = useCallback(($event) => {
    $event.stopPropagation();
    logout();
  }, [ logout ]);

  const personName = userDetails ? userDetails.name + " " + userDetails.surName : "";

  return (
    <Flex justify={ isExpanded ? 'space-between' : 'flex-start' } align="center" className={ classes.itemRoot } onClick={ handleClick }>
      <Flex gap={ 4 } align="center" className={ classes.itemContent }>
        <PersonAvatar
          width={ isExpanded ? '20px' : '24px' }
          height={ isExpanded ? '20px' : '24px' }
          personName={ personName }
          personAvatarUrl={ profileDetails?.profilePictureThumbnailUrl }
        />
        <Flex direction="column" className={ classes.shrinkableContent }>
          <Text className={ classes.userName }>{ userDetails?.name } { userDetails?.surName }</Text>
          <Text className={ classes.email }>{ userDetails?.email }</Text>
        </Flex>
      </Flex>
      { isExpanded && <UnstyledButton onClick={ handleLogout } className={ classes.optionsButton }>
        <LogoutIcon />
      </UnstyledButton> }
    </Flex>
  );
};

export default NavigationUserDetailsItem;
