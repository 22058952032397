import { createStyles } from "@mantine/core";

export const useProfilePersonalContactTabStyles = createStyles((theme) => ({
  section: {
    '&:not(:first-of-type)': {
      marginTop: 72
    }
  },
  relative:{
    position: "relative"
  },
  textRight:{
    textAlign: "right"
  },
  primaryContactLabel: {
    color: theme.colors.neutral[5]
  },

  sectionHeading: {
    fontSize: theme.fontSizes.md,
    marginBottom: 12
  },

  sectionHeadingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16
  },

  flag: {
    width: 16,
    height: 16,
    borderRadius: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    marginRight: 16
  },

  popupContainer: {
    color: theme.colors.neutral[8],
    padding: '5px 8px'
  }
}));
