import { Box, Flex, Image, Text } from '@mantine/core';
import { FC, useCallback, useMemo, useRef } from 'react';

import {
  RiskAlertDetailsStyles
} from '@/tenant-context/control-risk-config/components/RiskAlertDetails/RiskAlertDetails.styles';
import { GeneralizedRelatedEvent } from '@/tenant-context/control-risk-config/types/risk';

type Props = {
  relatedEvent: GeneralizedRelatedEvent
  index: number
  relatedEventsCount: number
  onClick: (relatedEvent: GeneralizedRelatedEvent) => void
  selectedEventAlertId?: string
  isInArc: boolean
}

const RelatedEventListItem: FC<Props> = ({
  relatedEvent,
  index,
  relatedEventsCount,
  onClick,
  selectedEventAlertId,
  isInArc
}) => {

  const { classes, cx } = RiskAlertDetailsStyles({ isInArc: isInArc, isOverlayOpen: false, relatedItemIndex: index });

  const clickBox = useRef<HTMLDivElement>(null);

  const riskEventTime = useMemo(() => {
    if (!relatedEvent.eventTime) {
      return null;
    }

    const eventTime = Number(relatedEvent.eventTime);
    const date = new Date(eventTime);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const meridiem = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    return `${formattedHours}:${formattedMinutes} ${meridiem} - ${day} ${month} ${year}`;
  }, [relatedEvent]);

  const handleEventClick = useCallback(() => {
    if (!isInArc) {
      clickBox?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    onClick(relatedEvent);
  }, [isInArc, onClick, relatedEvent]);

  return (
    <Box
      onClick={ handleEventClick }
      className={ cx({
        [classes.listItem]: true,
        [classes.selectedItem]: (selectedEventAlertId === relatedEvent.id)
      }) }
      ref={ clickBox }
    >
      <Flex gap={ 'md' } py={ 'md' }>
        <Flex direction={ 'column' } gap={ 'md' }>
          <Flex gap={ 4 } direction={ 'column' }>
            <Text size={ 10 } className={ classes.textNeutral }>{ riskEventTime }</Text>
            <Text fw={ 'bold' } size={ 10 } className={ classes.textNeutral }>{ relatedEvent.eventLocationName }</Text>
          </Flex>
          <Text size={ 'xs' } lineClamp={ 3 }>{ relatedEvent.caption }</Text>
          { relatedEvent.mediaSource && <Text
            size={ 10 }
            className={ classes.source }
          >
            { relatedEvent.mediaSource }
          </Text> }
        </Flex>
        { relatedEvent?.imageSource && <Image
          fit={ 'cover' }
          h={ '100%' }
          w={ 90 }
          src={ relatedEvent.imageSource }
        /> }
      </Flex>
      { (index < (relatedEventsCount - 1)) && (selectedEventAlertId !== relatedEvent.id) &&
        <div className={ classes.relatedEventsDivider }/> }
    </Box>
  );
};

export default RelatedEventListItem;
