import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logger } from "@/common/util/ConsoleLogger";
import { Dispatch, RootState } from "@/core/store";
import DataMinrEventsSettingsComponent
  from "@/tenant-context/control-risk-config/components/DataMinrEventsSettings/DataMinrEventsSettings.component";

const DataMinrEventsSettings = () => {

  const dataMinrWatchlist = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.dataMinrWatchlist
  );
  const disabledRiskFilterTypes = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.disabledRiskFilterTypes
  );
  const isGlobalCategoryToggleOn = useSelector(
    (state: RootState) => state.riskEventFilterDataOptions.isGlobalCategoryToggleOnDataMinr
  );

  const {
    riskEventFilterDataOptions: {
      ENABLE_FILTER,
      DISABLE_FILTER,
      ENABLE_GLOBAL_CATEGORY_FILTER_DATAMINR,
      DISABLE_GLOBAL_CATEGORY_FILTER_DATAMINR
    }
  } = useDispatch<Dispatch>();

  const handleSwitchToggle = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        checked,
        value: filter
      } = event.currentTarget;

      logger.log('filter', filter);

      if (checked) {
        ENABLE_FILTER(filter);
      } else {
        DISABLE_FILTER(filter);
      }
    },
    [ENABLE_FILTER, DISABLE_FILTER]
  );

  const handleGlobalCategoryToggle = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      checked
    } = event.currentTarget;

    if (checked) {
      ENABLE_GLOBAL_CATEGORY_FILTER_DATAMINR();
    } else {
      DISABLE_GLOBAL_CATEGORY_FILTER_DATAMINR();
    }
  },[ENABLE_GLOBAL_CATEGORY_FILTER_DATAMINR, DISABLE_GLOBAL_CATEGORY_FILTER_DATAMINR]);

  return (
    <DataMinrEventsSettingsComponent
      dataMinrWatchlist={ dataMinrWatchlist }
      onCategoryToggle= { handleSwitchToggle }
      onChangeGlobalCategoryToggle={ handleGlobalCategoryToggle }
      isGlobalCategoryToggleOn = { isGlobalCategoryToggleOn }
      disabledRiskFilterTypes={ disabledRiskFilterTypes }
    />
  );
};

export default DataMinrEventsSettings;
