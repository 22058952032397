import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

type Props = {
  hasData: boolean
  searchInputPosition?: 'left' | 'right',
  alignFilters?: 'flex-end' | 'flex-start'
  wrapFilters?: boolean
}

export const useDataGridStyles = createStyles((
  theme: MantineTheme,
  { searchInputPosition, hasData, alignFilters, wrapFilters }: Props
) => ({
  /**
   * If the parent of the component has a fixed height, then the data-grid will expand
   * to fill the height of the parent.
   */
  root: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%',
    '& .ag-center-cols-container': {
      backgroundColor: hasData ? 'none' : theme.colors.neutral[7]
    }
  },
  rowWrap:{
    '& .ag-cell':{
      display: 'flex',
      alignItems: 'center'
    }
  },
  clearSearch:{
    cursor: 'pointer'
  },
  relative:{
    position: "relative"
  },
  expandButton: {
    all: 'unset',
    position: 'absolute',
    top: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    svg: {
      width: '15px',
      height: '15px'
    }
  },
  searchInput: {
    width: 450,
    marginLeft: searchInputPosition === 'right' ? 'auto' : 'unset',
    '& svg > path': {
      fill: theme.colors.neutral[4]
    }
  },
  gridHeader:{
    display: 'flex',
    alignItems: alignFilters,
    justifyContent: 'flex-start',
    marginBottom: 24,
    flexWrap: wrapFilters ? 'wrap' : 'unset'
  },
  searchWrap: {
    '& > div':{
      marginBottom: 0,
      marginRight: 24
    }
  },
  headerWrap:{
    marginRight: 24,
    flexWrap: wrapFilters ? 'wrap' : 'unset',
    width: wrapFilters ? '100%' : 'inherit'
  },
  flex: {
    flex: 1
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 35
  }
}));
