import { MantineTheme } from "@mantine/core";

export const SegmentedControlStyle = {
  styles: (theme: MantineTheme) => ({
    root: {
      backgroundColor: theme.colors.neutral[7]
    },
    active: {
      backgroundColor: theme.colors.blue[6],
      color: theme.white
    },
    label: {
      color: theme.colors.neutral[4]
    }
  })
};
