import { FC } from "react";

import GlobalSearchBar from "@/tenant-context/control-search/components/GlobalSearchBar";
import GlobalSearchResults from "@/tenant-context/control-search/components/GlobalSearchResults";

const GlobalSearch: FC = () => {
  return (
    <div>
      <GlobalSearchBar />
      <GlobalSearchResults />
    </div>
  );
};

export default GlobalSearch;