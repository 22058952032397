import React from "react";
import { Layer, Source } from "react-map-gl";

import ToggleableLayer from "@/common/components/ToggleableLayer";
import {
  ToggleableContextLayers
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";

const TrafficLayerComponent: React.FC = () => {
  return (
    <ToggleableLayer type={ ToggleableContextLayers.Traffic } layerType="contextual-layer">
      <Source
        id="r__trafficSource"
        type="vector"
        url="mapbox://mapbox.mapbox-traffic-v1"
      >
        <Layer
          id="traffic"
          source="r__trafficSource"
          source-layer="traffic"
          type="line"
          beforeId="waterway-label"
          paint={ {
            "line-width": 2,
            "line-color": [
              "case",
              ["==", "low", ["get", "congestion"]],
              "#52e051",
              ["==", "moderate", ["get", "congestion"]],
              "#ff9503",
              ["==", "heavy", ["get", "congestion"]],
              "#fe4d4c",
              ["==", "severe", ["get", "congestion"]],
              "#8e2447",
              "#000000"
            ]
          } }
        />
      </Source>
    </ToggleableLayer>
  );
};

export default TrafficLayerComponent;
