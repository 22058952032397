import { Flex, Text } from '@mantine/core';
import { MantineNumberSize } from '@mantine/styles';
import { SystemProp } from '@mantine/styles/lib/theme/types/MantineStyleSystem';
import { Property } from 'csstype';
import { CSSProperties, FC, useMemo } from 'react';

import { genericColorPalette } from '@/core/styles/mantine/palettes';

type Props = {
  text: string
  numberFw?: SystemProp<CSSProperties['fontWeight']>
  alphaFw?: SystemProp<CSSProperties['fontWeight']>
  numberFs?: MantineNumberSize
  alphaFs?: MantineNumberSize
  gap?: SystemProp<CSSProperties['gap']>
  align?: SystemProp<CSSProperties['alignItems']>
  numberColor?: Property.Color
  alphaColor?: Property.Color
}
const NumberHighlightedTextComponent: FC<Props> = ({
  text,
  numberFw= 'bold',
  alphaFw= 'xs',
  numberFs= 14,
  alphaFs,
  gap = 4,
  align = 'flex-end',
  numberColor,
  alphaColor = genericColorPalette.neutral[5]
}) => {
  const parts = useMemo(() => {
    if (!text) {
      return [];
    }

    return text.split(/(\d+)/).filter(Boolean);
  }, [text]);

  return <Flex gap={ gap } align={ align }>
    { parts.map((part, index) => {
      if (!isNaN(Number(part))) {
        return <Text style={ { color: numberColor } } fw={ numberFw } size={ numberFs } key={ index }>{ part }</Text>;
      } else {
        return <Text style={ { color: alphaColor } } fw={ alphaFw } size={ alphaFs } key={ index }>{ part }</Text>;
      }
    }) }
  </Flex>;
};

export default NumberHighlightedTextComponent;
