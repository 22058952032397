import { callApi } from "@/common/util/api";
import { handleError, removeRef } from '@/common/util/common-functions';
import { OfflineProxyTokenResponse, OutsystemsTokenResponse } from "@/core/navigation/types/navigation";
import { getTenantToken } from "@/tenant-context/control-profile/api/profile";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";
import { SubscribedProducts, TenantDetails, TenantTokenPayload, TenantUser, UserPolicy } from "@/tenant-context/navigation/types/user";


export const getOutsystemsToken = async (): Promise<OutsystemsTokenResponse> => callApi<OutsystemsTokenResponse>(
  `/managed/token?grant_type=custom_token&access_type=offline`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-userdata': 'outsystems-sso'
    }
  }
);
export const getOfflineProxyToken =
 async (siteId: string): Promise<OutsystemsTokenResponse> => callApi<OutsystemsTokenResponse>(
   `/managed/token?grant_type=custom_token&access_type=offline`,
   {
     method: 'get',
     headers: {
       'Content-Type': 'application/x-www-form-urlencoded',
       'X-userdata': 'proxy-sso',
       'X-SiteId': siteId
     }
   }
 );

export const validateProxyToken =
 async (proxyToken: string, accessToken: string)
 : Promise<OfflineProxyTokenResponse> => callApi<OfflineProxyTokenResponse>(
   `/public/platform-service/v1/users`,
   {
     method: 'post',
     body: JSON.stringify({ proxyToken }),
     headers: {
       'x-domain-model': 'ValidateProxyToken',
       'Content-Type': 'application/json;',
       'Authorization': `Bearer ${accessToken}`
     }
   }
 );

export const getCurrentUsersPermissionsPolicies = (): Promise<PaginatedResult<UserPolicy>> => {
  const tenantToken = getTenantToken();

  if (!tenantToken) {
    return Promise.resolve({
      currentPage: 0,
      items: [],
      totalPages: 0,
      totalItems: 0
    });
  }

  const tokenEncodedPayload = tenantToken.split('.')[1];
  const tokenPayloadJson: TenantTokenPayload = JSON.parse(atob(tokenEncodedPayload));

  const { userId } = tokenPayloadJson;

  return callApi<
    PaginatedResult<UserPolicy>
  >(
    `/managed/platform-service/v1/users/${userId}`,
    {
      method: 'get',
      queryParams: {
        size: 1000
      },
      headers: {
        'Content-Type': 'application/json;domain-model=ListUserPolicies'
      },
      withTenantId: false
    }
  );
};

export const getFormattedTenantSubscribedProducts = async (accessToken: string, tenantId: string):
 Promise<SubscribedProducts[]> => {
  const results = await Promise.all([
    getTenantSubscribedProducts(accessToken, tenantId).catch(handleError),
    getAllProducts(accessToken).catch(handleError)
  ]);
  
  const [tenantProducts, allProducts] = results;

  if(tenantProducts && allProducts){
    return allProducts.items.filter(product => 
      tenantProducts.items.some(tenant => product._id === removeRef(tenant.identifier)));
  }

  return [];
};

export const getTenantSubscribedProducts = (accessToken: string, tenantId: string)
: Promise<PaginatedResult<SubscribedProducts>> => {

  return callApi<
  PaginatedResult<SubscribedProducts>
  >(
    `/managed/platform-service/v1/tenants/${tenantId}/product-references`,
    {
      method: 'get',
      queryParams: {
        size: 1000
      },
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Bearer ${accessToken}`
      },
      withTenantId: false
    }
  );
};

export const getAllProducts = (accessToken?: string)
: Promise<PaginatedResult<SubscribedProducts>> => {

  return callApi<
  PaginatedResult<SubscribedProducts>
  >(
    `/managed/platform-service/v1/products`,
    {
      method: 'get',
      queryParams: {
        size: 1000
      },
      headers: {
        'Content-Type': 'application/json;',
        'Authorization': `Bearer ${accessToken}`
      },
      withTenantId: false
    }
  );
};

export const getTenantUser = (): Promise<TenantUser | null> => {
  const tenantToken = getTenantToken();

  if (!tenantToken) {
    return Promise.resolve(null);
  }

  const tokenEncodedPayload = tenantToken.split('.')[1];
  const tokenPayloadJson: TenantTokenPayload = JSON.parse(atob(tokenEncodedPayload));

  const { userId } = tokenPayloadJson;

  return callApi<
    TenantUser
  >(
    `/managed/platform-service/v1/users/${userId}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json;domain-model=GetUser'
      },
      withTenantId: false
    }
  );
};

export const getCurrentTenantDetails = async (tenantRef?: string): Promise<TenantDetails | null> => {

  // eslint-disable-next-line fp/no-let
  let tenantId = tenantRef;

  if(!tenantId){
    const tenantToken = getTenantToken();

    if (!tenantToken) {
      return null;
    }
  
    const tokenEncodedPayload = tenantToken.split('.')[1];
    const tokenPayloadJson: TenantTokenPayload = JSON.parse(atob(tokenEncodedPayload));

    // eslint-disable-next-line prefer-destructuring
    tenantId = tokenPayloadJson.tenantId;
  }

  return callApi<TenantDetails>(
    `/managed/platform-service/v1/tenants/${tenantId}`,
    {
      method: 'get',
      withTenantId: false
    }
  );
};
