import { Button, Divider } from "@mantine/core";
import { FC } from "react";

import PersonAvatar from "@/common/components/PersonAvatar/PersonAvatar.component";
import { formatDMY } from "@/common/util/format/date";
import { useTravellersSearchContext } from "@/tenant-context/control-travellers-search/context/TravellersSearchContext/TravellersSearch.context";
import {
  useTravellerInfoCardStyles
} from "@/tenant-context/control-travellers-search/drawers/CountryDetailViewDrawer/components/TravellerInfoCard/TravellerInfoCard.styles";
import { TravellerData } from "@/tenant-context/control-travellers-search/types/travelSearch.types";

type Props = {
  travellerId: string,
  traveller: TravellerData,
  onClick: () => void
}
export const TravellerInfoCard: FC<Props> = ({
  travellerId,
  traveller,
  onClick
}) => {

  const {
    highlightedTravellerId,
    setHighlightedTravellerId
  } = useTravellersSearchContext();

  const { classes } = useTravellerInfoCardStyles({
    highlightedTraveller: travellerId === highlightedTravellerId
  });

  return (
    <button className={ classes.card }
      // eslint-disable-next-line react/jsx-no-bind
      onMouseEnter={ () => setHighlightedTravellerId(travellerId) }
      // eslint-disable-next-line react/jsx-no-bind
      onMouseLeave={ () => setHighlightedTravellerId(undefined) }
      onClick={ onClick }
    >
      <div className={ classes.personDetails }>
        <PersonAvatar
          width="35px"
          height="35px"
          personName={ traveller.personName }
          personAvatarUrl={ traveller.personProfilePictureUrl }
        />

        <div className={ classes.personSummaryWrapper }>
          <div className={ classes.personName }>
            { traveller.personName }
          </div>
          { traveller.countryOfResident && <div className={ classes.countryOfResidence }>
            Resident of <b>{ traveller.countryOfResident }</b>
          </div> }
        </div>
      </div>

      <Divider className={ classes.divider } />

      <div className={ classes.infoRow }>
        <div>
          <div className={ classes.infoLabel }>
            Trip Location
          </div>
          <div className={ classes.infoValue }>
            { traveller.tripLocation }
          </div>
        </div>
      </div>

      <div className={ classes.infoRow }>
        <div>
          <div className={ classes.infoLabel }>
            Segment Dates
          </div>
          <div className={ classes.infoValue }>
            { `${ formatDMY(new Date(traveller.tripDates.from), { utc: true }) } - ${ formatDMY(new Date(traveller.tripDates.to), { utc: true }) }` }
          </div>
        </div>

        <Button
          radius='xl'
          size='xs'
        >
          { traveller.tripStatus }
        </Button>
      </div>
    </button>
  );
};
