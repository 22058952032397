import { FC } from "react";

import { useModalAccentedBlockStyles } from "@/common/components/ModalAccentedBlock/ModalAccentedBlock.style";

type Props = {
  accentedPrefix?: string;
};

const ModalAccentedBlock: FC<Props> = ({
  children,
  accentedPrefix
}) => {
  const { classes } = useModalAccentedBlockStyles();

  return (
    <div className={ classes.root }>
      <div>
        { accentedPrefix && (
          <span className={ classes.accentedPrefix }>{ accentedPrefix }&nbsp;</span>
        ) }
        { children }
      </div>
    </div>
  );
};

export default ModalAccentedBlock;