import { MantineTheme } from "@mantine/core";
import { ButtonVariant } from "@mantine/core/lib/Button/Button.styles";

export declare const BADGE_VARIANTS: readonly ["default", "outline", "light"];
export type BadgeVariant = typeof BADGE_VARIANTS[number];

export interface BadgeStylesParams {
  variant: BadgeVariant;
}

const getPressedIconColor = (variant: ButtonVariant, theme: MantineTheme) => {
  const variantMap: Record<string, string> = {
    default: `${theme.other.semantic.surfaceBackground.primary} !important`,
    outline: `${theme.other.semantic.surfaceBackground.primary} !important`,
    light: `${theme.other.semantic.surfaceBackground.tertiary} !important`
  };

  return variantMap[variant] || "";
};

export const BadgeComponentStyle = {
  defaultProps: {},
  styles: (theme: MantineTheme, params: BadgeStylesParams) => ({
    root: {
      backgroundColor: getPressedIconColor(params.variant, theme)
    }
  })
};