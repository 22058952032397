// import { MantineTheme } from '@mantine/styles/lib/theme/types/MantineTheme';

import { ReactComponent as BarsVertical } from '@/common/icons/bars-vertical.svg';

export const RangeSliderComponentStyle = {
  defaultProps: {
    thumbSize: 24,
    thumbChildren: <BarsVertical />
  }
};
