import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useMassCommsStyles = createStyles((theme: MantineTheme, isMassCommsOpen: boolean) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 24px',
    height: '100vh',
    width: '100%',
    backgroundColor: theme.colors.neutral[9],
    ...(isMassCommsOpen && {
      width: 'calc(100% - 335px)',
      marginLeft: 'auto'
    })
  },

  page: {
    display: 'flex',
    flex: 1,
    overflowY: 'auto',
    flexDirection: 'column'
  },

  riskAlertDrawer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '75px'
  }
}));
