import {
  ToggleableContextLayers,
  ToggleableLayerType
} from "@/common/components/ToggleableLayer/ToggleableLayer.config";

export enum ContextualLayerSwitchType {
  CountryRiskLevel = "CountryRiskLevel",
  Traffic = "Traffic",
  Weather = "Weather",
  Wind = 'Wind',
  Radar = 'Radar',
  Cyclones = 'Cyclones'
}

export const defaultContextualLayersOptions: ContextualLayersOptions = {
  [ToggleableContextLayers.CountryRiskLevel]: false,
  [ToggleableContextLayers.Traffic]: false,
  [ToggleableContextLayers.Weather]: false,
  [ToggleableContextLayers.Wind]: false,
  [ToggleableContextLayers.Radar]: false,
  [ToggleableContextLayers.Cyclones]: false
};

export type ContextualLayersOptions = Record<ContextualLayerSwitchType, boolean>;

export type DataOptionsState = {
  disabledLayerTypes: ToggleableLayerType[];
  contextLayerOptions: ContextualLayersOptions;
}

export const drawerState: DataOptionsState = {
  disabledLayerTypes: [
    ToggleableLayerType.TravelCountriesView,
    ToggleableLayerType.TravelCountryDetailsView
  ],
  contextLayerOptions: defaultContextualLayersOptions
};
