import { MapboxMap, MapRef } from "react-map-gl";

import { MapIcon } from "@/common/types/icon";
import useMapIcon from "@/tenant-context/common/hooks/useMapIcon";

const useMapIcons = (
  icons: Readonly<MapIcon[]>,
  map: MapboxMap | MapRef | undefined,
  isOverrideIcons:boolean
) => {
  icons.forEach((icon) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMapIcon(icon.name, icon.image, map,isOverrideIcons);
  });
};

export default useMapIcons;
