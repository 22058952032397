import { createModel } from '@rematch/core';

import { Dispatch, RootModel, RootState } from '@/core/store';
import {
  getGeoLocationAddress,
  getLocationTimezone,
  getReverseGeoLocationPlaceName
} from '@/tenant-context/core/api/reverse-geocoding';
import {
  ReverseGeoData,
  ReverseGeoLocationAddressResponse,
  ReverseGeoLocationPlaceNameResponse,
  ServiceType
} from '@/tenant-context/core/types/reverse-geocoding';

type ReverseGeocodingState = {
  geoLocationCache: Map<string, ReverseGeoData>
}

const initialState: ReverseGeocodingState = {
  geoLocationCache: new Map()
};

const ReversGeocoding = {
  name: 'reverseGeocoding',
  state: initialState,
  reducers: {
    SET_GEOLOCATION_CACHE(state: ReverseGeocodingState, payload: Map<string, ReverseGeoData>) {
      return {
        ...state,
        geoLocationCache: payload
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    async getReverseGeoLocation(
      locationDetails: { lon: number, lat: number, service?: ServiceType },
      state: RootState
    ): Promise<ReverseGeoLocationPlaceNameResponse> {
      const {
        reverseGeocoding: {
          geoLocationCache
        }
      } = state;

      const geoLocationCacheItem = geoLocationCache.get(`${locationDetails.lon},${locationDetails.lat}`);

      if (geoLocationCacheItem) {
        return { place_name: geoLocationCacheItem.place_name };
      } else {
        const personLocation = await getReverseGeoLocationPlaceName(
          locationDetails.lon.toString(),
          locationDetails.lat.toString(),
          locationDetails.service
        );

        const newGeoLocationCache = new Map(geoLocationCache);
        newGeoLocationCache.set(`${locationDetails.lon},${locationDetails.lat}`, {
          place_name: personLocation.place_name,
          lat: locationDetails.lat,
          lon: locationDetails.lon
        });
        dispatch.reverseGeocoding.SET_GEOLOCATION_CACHE(newGeoLocationCache);

        return personLocation;
      }
    },

    async getReverseGeoLocationAddress(
      locationDetails: { lon: number, lat: number },
      state: RootState
    ): Promise<ReverseGeoLocationAddressResponse> {
      const {
        commonData: {
          tenantId
        }
      } = state;

      return await getGeoLocationAddress(
        locationDetails.lon.toString(),
        locationDetails.lat.toString(),
        tenantId
      );
    },

    async getLocationTimezone(
      locationDetails: { lon: number, lat: number }
    ) {
      return await getLocationTimezone(
        locationDetails.lon.toString(),
        locationDetails.lat.toString()
      );
    }
  })
};

export const reverseGeocoding = createModel<RootModel>()(ReversGeocoding);
