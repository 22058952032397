/* eslint-disable react/jsx-props-no-spreading */
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ActionIcon, ColorInput, Select, TextInput, useMantineTheme } from "@mantine/core";
import { FC, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";

import { ReactComponent as TrashIcon } from "@/common/icons/trash.svg";
import { useCreateCommsPageStyles } from "@/tenant-context/control-mass-comms/components/CreateCommsPage/CreateCommsPage.styles";
import {
  useResponseMethodsStyles
} from "@/tenant-context/control-mass-comms/components/CreateCommsPage/Response-Methods/ResponseMethods.styles";
import {
  MassCommsAppCommunicationsResponseItem,
  MassCommsEmailResponseItem,
  MassCommsPhoneResponseItem,
  ResponseMethod
} from "@/tenant-context/control-mass-comms/types/MassComms.types";

import ResponseMethodItem from "./ResponseMethodItem.component";

type Props = {
  index: number,
  deliveryChannel: 'app' | 'email' | 'sms' | 'voice';
  responseMethod: ResponseMethod | undefined;
  responseItem: MassCommsAppCommunicationsResponseItem | MassCommsEmailResponseItem | MassCommsPhoneResponseItem;
  pollResponse: string;
  isWithRemoveButton: boolean;
  onRemoveResponseItemRequested: () => void;
  onResponseItemChanged: (
    deliveryChannel: string,
    responseItem: MassCommsEmailResponseItem & MassCommsPhoneResponseItem
  ) => void;
  onPollResponseChanged: (pollResponse: string, index: number) => void;
}

const GenericResponseItemComponent: FC<Props> = ({
  index,
  deliveryChannel,
  responseMethod,
  responseItem,
  pollResponse,
  isWithRemoveButton,
  onRemoveResponseItemRequested,
  onResponseItemChanged,
  onPollResponseChanged
}) => {

  const { classes: parentPageClasses } = useCreateCommsPageStyles({ isCommsSequenceNoAvailable: true });
  const { classes, cx } = useResponseMethodsStyles();
  const theme = useMantineTheme();

  const getResponseMethodLabel = (): string => {
    if (responseMethod === 'ACKNOWLEDGE') {
      return 'ACKNOWLEDGE';
    }

    if (responseMethod === 'SAFETY_POLL') {
      return responseItem.safetyStatus === 'SAFE' ? 'SAFE' : 'NOT_SAFE';
    }

    return pollResponse || responseItem.responseLabel;
  };

  const { control, register,
    formState, watch } = useForm<MassCommsEmailResponseItem & MassCommsPhoneResponseItem>({
      mode: 'onChange',
      defaultValues: {
        buttonLabel: (responseItem as MassCommsEmailResponseItem).buttonLabel,
        buttonColor: (responseItem as MassCommsEmailResponseItem).buttonColor,
        replyCode: (responseItem as MassCommsPhoneResponseItem).replyCode,
        responseLabel: getResponseMethodLabel(),
        safetyStatus: responseItem.safetyStatus,
        responseLabelColor: responseMethod === 'SAFETY_POLL'
          ? responseItem.safetyStatus === 'SAFE' ? theme.colors.success[0] : theme.colors.risk[4]
          : theme.colors.purple[9]
      }
    });

  const formBody: MassCommsEmailResponseItem & MassCommsPhoneResponseItem = watch();
  const { responseLabel } = formBody;

  useEffect(() => {
    onResponseItemChanged(
      deliveryChannel,
      {
        ...responseItem,
        ...formBody
      }
    );
  }, [deliveryChannel, formBody, index, onResponseItemChanged, responseItem] );

  useEffect(() => {
    onPollResponseChanged(responseLabel, index);
  }, [responseLabel, onPollResponseChanged, index] );

  const statusLabels = useMemo((): Array<
    { value: string, label: string, icon?: IconProp, iconColor?: string }
  > => {
    if (responseMethod === 'SAFETY_POLL') {
      return [
        { value: 'SAFE', label: 'Safe', icon: 'thumbs-up', iconColor: 'green' },
        { value: 'NOT_SAFE', label: 'Not Safe', icon: 'circle-exclamation', iconColor: 'red' }
      ];
    }

    if (responseMethod === 'ACKNOWLEDGE') {
      return [ { value: 'ACKNOWLEDGE', label: 'Acknowledge' } ];
    }

    return [
      { value: 'SAFE', label: 'Safe' },
      { value: 'NOT_SAFE', label: 'Not Safe' },
      { value: 'NO_MAPPING', label: 'No Mapping' }
    ];
  }, [ responseMethod ]);

  return (
    <div className={ classes.responseItem }>
      { (deliveryChannel === 'email' || deliveryChannel === 'app') && (<div>
        <TextInput
          className={ parentPageClasses.inputItem }
          label={ 'Button Label' }
          { ...register('buttonLabel', { required: true }) }
          error={ formState.errors.buttonLabel && formState.errors.buttonLabel.type === "required" && 'Button Label is required' }
        />
      </div> ) }

      { (deliveryChannel === 'email' || deliveryChannel === 'app') && ( <div>
        <Controller
          name="buttonColor"
          control={ control }
          /* eslint-disable-next-line react/jsx-no-bind */
          render={ ({ field }) => <ColorInput
            size={ 'lg' }
            format="hex"
            { ...field }
            className={ parentPageClasses.inputItem }
            disabled={ responseMethod === 'SAFETY_POLL' }
            label={ 'Button Color' }
            disallowInput
            swatches={ ['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14'] }
          /> }
        />
      </div> ) }

      { (deliveryChannel === 'sms' || deliveryChannel === 'voice') && (<div>
        <TextInput
          { ...register('replyCode', { required: true }) }
          label={ 'Reply Code' }
          className={ parentPageClasses.inputItem }
          error={ formState.errors.replyCode && formState.errors.replyCode.type === "required" && 'Reply code is required' }
        />
      </div> ) }

      { responseMethod !== 'SAFETY_POLL' && <div>
        <TextInput
          label={ 'Poll Response Label' }
          { ...register('responseLabel', { required: true }) }
          className={ cx(parentPageClasses.inputItem) }
          value={ pollResponse }
          error={ formState.errors.responseLabel && formState.errors.responseLabel.type === "required" && 'Poll Response Label is required' }
          disabled={ responseMethod === 'ACKNOWLEDGE' }
        />
      </div> }

      { responseMethod === 'CUSTOM_POLL' && <div>
        <Controller
          name="responseLabelColor"
          control={ control }
          /* eslint-disable-next-line react/jsx-no-bind */
          render={ ({ field }) => <ColorInput
            size={ 'lg' }
            format="hex"
            { ...field }
            className={ parentPageClasses.inputItem }
            label={ 'Poll Response Color' }
            disallowInput
            withPicker={ false }
            swatches={ [ theme.colors.purple[9], theme.colors.purple[7], theme.colors.purple[5],
              theme.colors.purple[3], theme.colors.purple[1] ] }
          /> }
        />
      </div> }

      { (responseMethod !== 'ACKNOWLEDGE') && <div>
        <Controller
          name="safetyStatus"
          control={ control }
          /* eslint-disable-next-line react/jsx-no-bind */
          render={ ({ field }) => <Select
            size={ 'lg' }
            { ...field }
            className={ parentPageClasses.inputItem }
            data={ statusLabels }
            itemComponent={ ResponseMethodItem }
            defaultValue={ 'NO_MAPPING' }
            label={ 'Safety Status' }
            disabled={ responseMethod === 'SAFETY_POLL' }
          /> }
        />
      </div> }

      { isWithRemoveButton && (
        <div style={ { flex: 1 } }>
          <ActionIcon size={ 'xl' } disabled={ index === 0 } style={ { float: "right", marginTop: 24 } } onClick={ onRemoveResponseItemRequested }>
            <TrashIcon />
          </ActionIcon>
        </div>
      ) }
    </div>
  );
};

export default GenericResponseItemComponent;
