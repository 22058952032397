import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import NavigationSidebar from '@/core/navigation/components/NavigationSidebar';
import { NavigationSidebarContext } from '@/core/navigation/context/NavigationSidebar.context';
import { Dispatch, RootState } from "@/core/store";
import { contextSwitchItem } from '@/platform-context/navigation/components/NavigationSidebar/NavigationSidebar.config';
import { PaginatedResult, ProfileGeneral } from "@/tenant-context/control-profile/types/profile";
import {
  getCurrentUsersPermissionsPolicies
} from "@/tenant-context/navigation/api/navigation";
import { UserPolicy } from "@/tenant-context/navigation/types/user";

export const NavigationSidebarContextProvider: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [usersPolicies, setUsersPolicies] = useState<PaginatedResult<UserPolicy>>();
  const [profileDetails, setProfileDetails] = useState<ProfileGeneral>();

  const loggedUserInfo = useSelector(({
    profile:{
      loggedUser
    }
  }: RootState) => loggedUser);

  const {
    commonData: {
      SET_IS_NAVIGATION_EXPANDED
    }
  } = useDispatch<Dispatch>();

  const handleNavigationExpand = useCallback((isNavExpanded: boolean) => {
    setIsExpanded(isNavExpanded);
    SET_IS_NAVIGATION_EXPANDED(isNavExpanded);
  }, [ SET_IS_NAVIGATION_EXPANDED ]);

  useEffect(() => {
    getCurrentUsersPermissionsPolicies().then(
      (response) => response && setUsersPolicies(response)
    );
  }, []);

  useEffect(()=>{
    setProfileDetails(loggedUserInfo);
  }, [loggedUserInfo]);

  return (
    <NavigationSidebarContext.Provider value={ {
      isExpanded,
      setIsExpanded: handleNavigationExpand,
      usersPolicies,
      profileDetails
    } }>
      <NavigationSidebar
        navigationSidebarItems={ contextSwitchItem }
      />
    </NavigationSidebarContext.Provider>
  );
};

export default NavigationSidebarContextProvider;
