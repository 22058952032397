import { ActionIcon, Box, Flex, Skeleton, Stack, Text } from '@mantine/core';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from "@/common/icons/Actions/close.svg";
import { ReactComponent as Contract } from "@/common/icons/Actions/contract.svg";
import { ReactComponent as ExpandIcon } from "@/common/icons/Actions/expand.svg";
import { ReactComponent as Healix } from "@/common/icons/RiskProviders/healix.svg";
import { ReactComponent as MaxSecurity } from "@/common/icons/RiskProviders/max-security.svg";
import { RootStateWithLoading } from '@/core/store';
import CountryFlag from '@/tenant-context/common/components/CountryFlag';
import {
  useDetailedCountryReportStyles
} from '@/tenant-context/control-reports/components/DetailedCountryReport/DetailedCountryReport.styles';
import HealixDetailedCountryReportComponent
  from '@/tenant-context/control-reports/components/DetailedCountryReport/HealixDetailedCountryReport.component';
import MaxSecurityDetailedCountryReport
  from '@/tenant-context/control-reports/components/DetailedCountryReport/MaxSecurityDetailedCountryReport.component';
import { CountryRiskData, HealixCountryRiskData } from '@/tenant-context/control-reports/types/country-risk-reports';
import {
  getRiskColorByRiskLevel, kebabCaseToTitleCase
} from '@/tenant-context/control-reports/util/country-risk-reports';

type Props = {
  countryRiskData: CountryRiskData;
  onClose: () => void;
  onExpand: () => void;
  isExpanded: boolean;
};

const DetailedCountryReportComponent: FC<Props> = ({
  countryRiskData,
  onClose: handleClose,
  onExpand: handleExpand,
  isExpanded
}) => {
  const getColorByLevel = (level?: string) => {
    return getRiskColorByRiskLevel(level);
  };
  const { classes, cx } = useDetailedCountryReportStyles( {
    riskLevelColor: getColorByLevel(countryRiskData.riskLevel?.toLowerCase())
  } );

  const isCountryDataLoading = useSelector(
    (state: RootStateWithLoading) => state.loading.effects.countryRiskReports.loadSelectedCountyReport
  );

  const providerName = useMemo(() => {
    return kebabCaseToTitleCase(countryRiskData.providerName);
  }, [countryRiskData.providerName]);

  const providerIcon = useMemo(() => {
    switch (countryRiskData.providerName?.toLowerCase()) {
    case 'max-security':
      return <MaxSecurity/>;
    case 'healix':
      return <Healix/>;
    default:
      return null;
    }
  }, [countryRiskData.providerName]);

  const providerSpecificReportData = useMemo(() => {
    switch (countryRiskData.providerName?.toLowerCase()) {
    case 'max-security':
      return <MaxSecurityDetailedCountryReport countryRiskData={ countryRiskData }/>;
    case 'healix':
      return <HealixDetailedCountryReportComponent countryRiskData={ countryRiskData as HealixCountryRiskData }/>;
    default:
      return null;
    }
  }, [ countryRiskData ]);

  return (
    <Box className={ classes.root }>
      <Flex direction="column" className={ classes.header }>
        <Flex justify="space-between" align="center">
          <Flex gap={ 12 } direction="column">
            <Flex gap={ 12 } align="center">
              <Box className={ classes.flag }>
                <CountryFlag countryCode={ countryRiskData.countryISOCode } />
              </Box>
              <Text size={ 32 } weight={ 700 }>{ countryRiskData.countryName }</Text>
            </Flex>
            <Flex gap={ 4 }>
              <Text>Issued by:</Text>
              <Box className={ classes.riskProviderIcon }>{ providerIcon }</Box>
              <Text>{ providerName }</Text>
            </Flex>
          </Flex>

          <Flex>
            <ActionIcon className={ classes.actionIcon } onClick={ handleExpand }>
              { isExpanded ? <Contract/> : <ExpandIcon/> }
            </ActionIcon>
            <ActionIcon className={ classes.actionIcon } onClick={ handleClose }><CloseIcon/></ActionIcon>
          </Flex>
        </Flex>
      </Flex>

      { !isCountryDataLoading ? (
        <Flex direction="column" gap={ 32 } className={ cx({ [classes.body]: true, [classes.bodyExpanded]: isExpanded }) }>

          { providerSpecificReportData }

        </Flex>
      ) : (
        <Stack spacing="md" className={ classes.loadingContainer }>
          { Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={ index } height={ 100 } radius="sm" opacity={ 0.1 }/>
          )) }
        </Stack>
      ) }
    </Box>
  );
};

export default DetailedCountryReportComponent;
