/* eslint-disable max-lines */
import type { ParsedPhoneNumber } from "awesome-phonenumber";

import { ReferenceData } from "@/common/types/reference-data";

// Utility
export type PaginatedResult<T> = {
  totalItems: number,
  totalPages: number,
  currentPage: number,
  items: T[]
}

export type DatabaseMeta = {
  createdDate: number,
  updatedDate: number,
  lastSeq: number,
  version: string,
  userId: string,
  userName: string
}
//


export type ProfileGeneral = {
  _id: string,
  tenantId: string,
  identifier: string,
  title?: string,
  firstName?: string,
  middleNames?: string,
  initials?: string,
  lastName?: string,
  alternateName?: string,
  dateOfBirth?: string,
  gender?: string,
  nationality?: string,
  countryOfResidenceRef?: string,
  source?: string,
  sourceProfileId?: string,
  active?: true,
  activeStatus: string,
  validationStatus?: string,
  profilePictureUrl?: string,
  profilePictureThumbnailUrl: string,
  trn: string,
  brn: string,
  rootTRN: string,
  root: false,
  meta: DatabaseMeta,
  profileId: string,
  // REQUIRED
  tenantRequirement?: string,
  assignedTenantUserTid?: string
};

// Take keys from request body
export type MutableProfileGeneral = Pick<ProfileGeneral,
  | 'identifier'
  | 'title'
  | 'firstName'
  | 'middleNames'
  | 'initials'
  | 'lastName'
  | 'alternateName'
  | 'dateOfBirth'
  | 'gender'
  | 'nationality'
  | 'countryOfResidenceRef'
  | 'source'
  | 'sourceProfileId'
  | 'active'
  | 'validationStatus'
  | 'profilePictureUrl'
>;

export type MutableProfileCompanyDetails = Pick<ProfileCompany,
  // | 'identifier'
  | 'companyRef'
  | 'employeeId'
  // | 'jobTitle'
  | 'role'
  | 'department'
  | 'departmentHead'
  | 'lineManagerId'
  | 'personType'
  | 'companyName'
>;

export type MutableProfileMedicalDetails = Pick<ProfileMedical,
  // | 'identifier'
  | 'bloodType'
  | 'height'
  // TODO
  // | 'heightUnit'
  | 'weight'
  // TODO
  // | 'weightUnit'
  | 'medicalConditionsList'
  | 'allergiesList'
  | 'medicationsList'
>;

export type MutableProfilePersonalAttributes = Pick<ProfilePersonalAttributes,
  // | 'identifier'
  | 'eyeColour'
  | 'hairColour'
  | 'ethnicity'
  | 'build'>;

export type MutableDistinguishingFeatures = Pick<ProfileDistinguishingFeatures,
  // | 'identifier'
  | 'tattoosList'
  | 'scarsList'
  | 'birthMarksList'>;

export type CreateProfileGeneral = Pick<ProfileGeneral,
  | 'title'
  | 'firstName'
  | 'middleNames'
  | 'initials'
  | 'lastName'
  | 'alternateName'
  | 'dateOfBirth'
  | 'gender'
  | 'nationality'
  | 'countryOfResidenceRef'
  | 'source'
  | 'sourceProfileId'
  | 'active'
  | 'validationStatus'
  | 'profilePictureUrl'>;

// TODO Pick
export type CreateContactDetail = {
  contactDetailType: string
  contactType: string
  value: string
  isPrimary: boolean
  // location: string
  provider: string
};

export type UpdateContactDetail = CreateContactDetail & {
  identifier: string
}

export type CreatePersonAddress = {
  type: string,
  title: string,
  isPrimary: boolean,
  name?: string,
  number: string,
  line1: string,
  line2: string,
  line3: string,
  city: string,
  region: string,
  country: string,
  postCode: string,

  latitude: string,
  longitude: string,
}

export type UpdatePersonAddress = CreatePersonAddress & {
  identifier: string
};

export type CreateEmergencyContact = Pick<
  ProfileEmergencyContact,
  // | 'type'
  | 'relationship'
  // | 'title'
  | 'firstName'
  | 'lastName'
  // | 'name'
  // | 'number'
  | 'line1'
  | 'line2'
  | 'line3'
  | 'city'
  | 'region'
  | 'country'
  | 'postCode'
  // | 'latitude'
  // | 'longitude'
  | 'phoneList'
  | 'email'
>

export type UpdateEmergencyContact = CreateEmergencyContact & {
  identifier: string
}

export type ProfileEmergencyContact = {
  _id: string,
  tenantId: string,
  identifier: string,
  type: string,
  relationship: string,
  title: string,
  firstName: string,
  lastName: string,
  name: string,
  number: string,
  line1: string,
  line2: string,
  line3: string,
  city: string,
  region: string,
  country: string,
  postCode: string,
  latitude: string,
  longitude: string,
  phoneList: string[],
  email: string | null,
  trn: string,
  brn: string,
  rootTRN: string,
  root: false,
  tenantRequirement: string,
  meta: DatabaseMeta,
}

export type ProfileContact = {
  _id: string,
  tenantId: string,
  identifier: string,
  contactDetailType: string,
  contactType: string,
  value: string,
  location: string,
  provider: string,
  primary: true,
  trn: string,
  brn: string,
  rootTRN: string,
  root: false,
  tenantRequirement: string,
  meta: DatabaseMeta,
  parsedPhoneNumber?: ParsedPhoneNumber
};

export type ProfileAddress = {
  _id: string,
  tenantId: string,
  identifier: string,
  type: string,
  title: string,
  name: string,
  number: string,
  line1: string,
  line2: string,
  line3: string,
  city: string,
  region: string,
  country: string,
  postCode: string,
  latitude: string,
  longitude: string,

  // TODO which one?
  primary: true,
  isPrimary: true,
  //

  trn: string,
  brn: string,
  rootTRN: string,
  root: false,
  tenantRequirement: string,
  locationId?: string,
  meta: DatabaseMeta
}

export type ProfileDocument = {
  _id: string,
  identifier: string,
  documentType: string,
  name: string,
  number: string,
  expiryDate: number,
  comments: string,
  documents: string
}

export type ProfileGroup = {
  _id: string,
  name: string,
  applicableToAllService: string,
  applicableServices: string,
  active: string,
  profileCount: number
}

export type ProfileCompany = {
  _id: string,
  tenantId: string,
  identifier: string,
  companyRef: string,
  employeeId: string,
  jobTitle: string,
  role: string,
  department: string,
  departmentHead: string,
  lineManagerId: string
  linManager?: LineManager,
  lineManagerName?: string,
  personType: string,
  trn: string,
  brn: string,
  rootTRN: string,
  root: boolean,
  tenantRequirement: string,
  meta: DatabaseMeta
  companyName?: string
  employeeType?:string
  comment?:string
}

export type LineManager = {
  lineManagerId: string,
  lineManagerProfileId: string
  name: string
}

export type ProfileMedical = {
  _id: string,
  tenantId: string,
  identifier: string,
  bloodType: string,
  height: number | null,
  heightUnit: string,
  weight: number | null
  weightUnit: string,
  medicalConditionsList: string,
  allergiesList: string,
  medicationsList: string,
  trn: string,
  brn: string,
  rootTRN: string,
  root: false,
  tenantRequirement: string,
  meta: DatabaseMeta
}

export type ProfilePersonalAttributes = {
  _id: string,
  tenantId: string,
  identifier: string,
  eyeColour: string,
  hairColour: string,
  ethnicity: string,
  build: string,
  trn: string,
  brn: string,
  rootTRN: string,
  root: false,
  tenantRequirement: string,
  meta: DatabaseMeta
}

export type ProfileDistinguishingFeatures = {
  _id: string,
  tenantId: string,
  identifier: string,
  tattoosList: string,
  scarsList: string,
  birthMarksList: string,
  trn: string,
  brn: string,
  rootTRN: string,
  root: false,
  tenantRequirement: string,
  meta: DatabaseMeta
}

export type ReferenceDataService = {
  _id: string,
  code: string,
  name: string,
  active: true,
  displayOrder: number,
  type: "service",
  data: string
}

export enum ProfileGeneralSection {
  Personal = 'Personal',
  Company = 'Company',
  Medical = 'Medical',
  Attributes = 'Attributes',
  DistinguishingFeatures = 'DistinguishingFeatures'
}

export type CreateDocumentBody = {
  documentType: string,
  name: string,
  number: string,
  expiryDate: number,
  comments: string
}

export type ProfileDocumentFile = {
  fileIdentifier: string,
  nameWithExtension: string,
  type: string,
  contentType: string,
  path: string,
  fileSize: number,
  downloadURL: string,
  bucketName: string,
}

export type ProfileDocumentDetails = {
  _id: string,
  identifier: string,
  documentType: string,
  name: string,
  number: string,
  expiryDate: number,
  comments: string,
  documents: ProfileDocumentFile[],
};

export type UpdateProfileDocumentDetails = Pick<
  ProfileDocumentDetails,
  | 'documentType'
  | 'name'
  | 'number'
  | 'expiryDate'
  | 'comments'
>;

// type that matches object structure above
export type ProfileConfiguration = {
  _id: string,
  identifier: string,
  pictureFileTypes: string[],
  maxPictureFileSize: number,
  documentFileTypes: Record<string, string[]>,
  maxDocumentFileSize: number,
  maxNumberOfFilesPerUpload: number,
  activeBucketName: string,
  brn: string,
  trn: string,
  root: boolean,
  meta: DatabaseMeta,
  tenantRequirement: string,
};

export type ReferenceDataList = {
  [type in ReferenceType]: ReferenceData[]
}


export enum ReferenceType {
  TITLES = 'titles',
  GENDER = 'gender',
  PERSON_TYPE = 'personType',
  BLOOD_TYPE = 'bloodType',
  LENGTH_UNIT = 'lengthUnit',
  WEIGHT_UNIT = 'weightUnit',
  ETHNICITY = 'ethnicity',
  CONTACT_TYPE = 'contactType',
  ADDRESS_TYPE = 'addressType',
  EMERGENCY_CONTACT_TYPE = 'emergencyContacts',
  BUSINESS_CONTACT_RELATIONSHIP = 'BECRelationship',
  RELATIONSHIP = 'relationship',
  DOCUMENT_TYPE = 'documentType',
  BUILD = 'build',
  EYE_COLOUR = 'eyeColor',
  HAIR_COLOR = 'hairColor',
  NATIONALITY = 'nationality',
  COUNTRY = 'country',
  INTEGRATION_PROVIDER = 'IntegrationProvider',
  INTEGRATION_TYPES = 'IntegrationTypes',
  INTEGRATION_STATUS = 'IntegrationStatus',
  ACCESS_CARD_TYPE = 'AccessCardType',
  WHAT_HAPPENED = 'whatHappened',
  ACCESS_CARD_LAYOUT_KEYWORDS = 'card-layout-keyword',
  PRINT_JOB_STATUS = 'print-job-status',
  EMPLOYEE_TYPE = 'employeeType',
  ASSET_CATEGORIES = 'icm-asset-categories'
}

export type Company = {
  _id: string
  code: string
  name: string
  activeStatus: string,
  hasReference: boolean,
  meta: {
    createdDate: number,
    updatedDate: number
  }
}

export type BulkUpdateProfileStatus = {
  deactivateAllProfiles?: boolean,
  reactivateAllProfiles?: boolean,
  profiles: BulkUpdateProfileStatusItem[]
}

export type BulkUpdateProfileStatusItem = {
  profileId: string,
  isActive: boolean
}

export type AccessCard = {
  _id: string
  secondaryId: string
  accessCardType: string
  assignedProfileId: string
  assignedProfileTid: string
  assignedToProfile: boolean
  createdTime: number
  friendlyName: string
  manufactureId: string
  provider: string
  status: string
  assignedProfileName?: string
}

export type AssignAccessCard = {
  tid: string
}

export type ProfileTag = {
  id?: string
  name: string
  color: string
  profileCount?: number
  type?: string
  profileId?: string
}

export type CreateCompany = {
  code: string,
  active: boolean,
  name: string
}

export type CompanyLog = {
  action: string
  changedByName: string
  changedByUserId: string
  changedByUserName: string
  code: string
  createdDate: number
  name: string
  seqNum: number
  _id: string
}

export type AccessCardTabAccessible = {
  accessCardTabAccessible: boolean
}

export type BulkProfilesFileUpload = {
  bulkDataId: string
  status: string
  summary: BulkProfilesFileUploadSummary
  tenantId: string
}
export type BulkProfilesFileUploadSummary = {
  init: number
  error: number,
  success: number
  total: number
}

export type BulkProfilesFileUploadStatus = {
  fileName: string
  tenantId: string
  bulkDataId: string
  status: string
  uploadedTime: number
  summary: BulkProfilesFileUploadSummary
}

export type UploadedProfilesStatus = {
  locationName: string
  description?: string
  category?: string
  latitude?: string
  longitude?: string
  addressLine1?: string
  addressLine2?: string
  errorMessage?: string
}

export type UploadedProfileStatusGridItem = {
  tenantId: string
  bulkDataId: string
  status: string
  createdTime: number
  filename: string
}

export type UploadedProfileStatusGrid =
    PaginatedResult<UploadedProfileStatusGridItem>
    & UploadedProfileStatusGridItem

