import { callApi, TokenType } from "@/common/util/api";
import browserDatabase from "@/common/util/browser-database";
import { CONTEXT_KEY, ContextTypes } from "@/core/store/shared-data/sharedData.types";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import {
  CaseStatusDeleteItem,
  CaseStatusItem,
  PeopleStatusItem, PriorityDeleteItem, PriorityItem
} from "../../types/CaseTypes/caseTypesConfigurations";

export const caseTypesConfigurationsApi = {
  tokenType: browserDatabase.getItem(CONTEXT_KEY) as ContextTypes === "platform" ? "access": "tenant",
  createPeopleStatus: function(data: PeopleStatusItem, caseTypeId: string) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreatePeopleStatus'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updatePeopleStatus: function(data: PeopleStatusItem, caseTypeId: string, caseTypeStatusId: string) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}/people-statuses/${caseTypeStatusId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdatePeopleStatus'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getPeopleStatusById: function(caseTypeId: string, caseTypeStatusId: string) {
    return callApi<PeopleStatusItem>(
      `/managed/icm-service/v1/case-types/${caseTypeId}/people-statuses/${caseTypeStatusId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetPeopleStatus'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  deletePeopleStatus: function(data: CaseStatusDeleteItem, caseTypeId: string) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeletePeopleStatus'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getListPeopleStatusData: function(
    caseTypeId: string,
    page = 0,
    size = 10,
    sort?: SortData,
    query?: string
  ) {
    return callApi<PaginatedResult<PeopleStatusItem>>(
      `/managed/icm-service/v1/case-types/${caseTypeId}/people-statuses?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${query ? '&search=(' + query + ')&' : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListPeopleStatus'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  createCaseStatus: function(data: CaseStatusItem, caseTypeId: string) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateCaseTypeStatus'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updateCaseStatus: function(data: CaseStatusItem, caseTypeId: string, caseTypeStatusId: string) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}/case-type-statuses/${caseTypeStatusId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateCaseTypeStatus'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getCaseStatusById: function(caseTypeId: string, caseTypeStatusId: string) {
    return callApi<PeopleStatusItem>(
      `/managed/icm-service/v1/case-types/${caseTypeId}/case-type-statuses/${caseTypeStatusId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetCaseTypeStatus'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  deleteCaseStatus: function(data: CaseStatusDeleteItem, caseTypeId: string) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeleteCaseTypeStatus'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getListCaseStatusData: function(
    caseTypeId: string,
    page = 0,
    size = 10,
    sort?: SortData,
    query?: string
  ) {
    return callApi<PaginatedResult<CaseStatusItem>>(
      `/managed/icm-service/v1/case-types/${caseTypeId}/case-type-statuses?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${query ? '&search=(' + query + ')&' : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListCaseTypeStatus'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  createPriority: function(data: PriorityItem, caseTypeId: string) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreatePriority'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updatePriority: function(data: PriorityItem, caseTypeId: string, priorityId: string) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}/priorities/${priorityId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdatePriority'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getPriorityById: function(caseTypeId: string, priorityId: string) {
    return callApi<PriorityItem>(
      `/managed/icm-service/v1/case-types/${caseTypeId}/priorities/${priorityId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetPriority'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  deletePriority: function(data: PriorityDeleteItem, caseTypeId: string) {
    return callApi(
      `/managed/icm-service/v1/case-types/${caseTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeletePriority'
        },
        method: 'post',
        body: JSON.stringify(data),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getPriorityListData: function(
    caseTypeId: string,
    page = 0,
    size = 10,
    sort?: SortData,
    query?: string
  ) {
    return callApi<PaginatedResult<PriorityItem>>(
      `/managed/icm-service/v1/case-types/${caseTypeId}/priorities?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${query ? '&search=(' + query + ')&' : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListPriority'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  }
};