/* eslint-disable react/jsx-no-bind */
import { useMantineTheme } from "@mantine/core";
import { animated, to, useTransition } from "@react-spring/web";
import { Annotation, Connector, HtmlLabel } from "@visx/annotation";
import { PieArcDatum, ProvidedProps } from "@visx/shape/lib/shapes/Pie";
import { FC, ReactNode } from "react";

import { getOffsetFromSubject } from "@/tenant-context/common/util/visx-chart";
import { MassCommsSummaryChartDataItem } from "@/tenant-context/control-mass-comms/types/MassComms.types";

import { AnimatedStyles, enterUpdateTransition, fromLeaveTransition } from "./MassCommsSummaryGraph.types";

type Props<MassCommsSummaryChartDataItem> = ProvidedProps<MassCommsSummaryChartDataItem> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<MassCommsSummaryChartDataItem>) => string;
  getColor: (d: PieArcDatum<MassCommsSummaryChartDataItem>) => string;
  delay?: number;
  tooltip?: (data:{ title: string, count: number }) => ReactNode;
  tooltipLine?: 'line' | 'elbow';
  onMouseEnter?: (event: PieArcDatum<MassCommsSummaryChartDataItem>) => void;
  onMouseLeave?: (event: PieArcDatum<MassCommsSummaryChartDataItem>) => void;
  getCustomOffset?: (arc: PieArcDatum<unknown>, pathCentroid: [number, number], axis: 'x' | 'y') => number;
  onClickDatum?: (d: PieArcDatum<MassCommsSummaryChartDataItem>) => void;
}

const AnimatedPie: FC<Props<MassCommsSummaryChartDataItem>> = ({
  animate,
  arcs,
  path,
  tooltip,
  tooltipLine = 'elbow',
  getKey,
  getColor,
  onMouseEnter,
  onMouseLeave,
  getCustomOffset,
  onClickDatum
}) => {
  const theme = useMantineTheme();

  const transitions = useTransition<PieArcDatum<MassCommsSummaryChartDataItem>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey
  });

  const configureOffset = (arc: PieArcDatum<unknown>, pathCentroid: [number, number], axis: 'x' | 'y'): number => {
    if(getCustomOffset){
      return getCustomOffset(arc, pathCentroid, axis);
    } else{
      return getOffsetFromSubject(arc, pathCentroid, axis);
    }
  };

  return transitions((props, arc, { key }) => {
    if (!(arc.data.count > 0)) {
      return;
    }

    return (
      <g key={ key }
        onMouseEnter={ () => onMouseEnter?.(arc) }
        onMouseLeave={ () => onMouseLeave?.(arc) }
      >
        { tooltip && <Annotation
          x={ path.centroid(arc)[0] }
          y={ path.centroid(arc)[1] }
          dx={ configureOffset(arc, path.centroid(arc), 'x') }
          dy={ configureOffset(arc, path.centroid(arc), 'y') }
        >
          <Connector type={ tooltipLine } stroke={ theme.colors.neutral[6] }/>
          <HtmlLabel
            showAnchorLine={ false }
          >
            { tooltip({
              title: arc.data.field,
              count: arc.data.count
            }) }
          </HtmlLabel>
        </Annotation> }

        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={ to(
            [props.startAngle, props.endAngle],
            (startAngle, endAngle) =>
              path({
                ...arc,
                startAngle,
                endAngle
              })
          ) }
          fill={ getColor(arc) }
          onClick={ () => onClickDatum?.(arc) }
          onTouchStart={ () => onClickDatum?.(arc) }
          style={ { cursor: onClickDatum? 'pointer': 'default' } }
        />
      </g>
    );
  });
};

export default AnimatedPie;
