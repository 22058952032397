import { useClickOutside } from "@mantine/hooks";
import { FC, ReactNode, useCallback } from "react";

import DroppableMapTopInfoBoxComponent from "./DroppableMapTopInfoBox.component";

type Props = {
  title: string,
  value: string | number,
  width?: string,
  bgColor?: string,
  fontColor?: string,
  children: ReactNode,
  isExpanded?: boolean,
  onToggle?: (shouldBe?: boolean) => void
  musterCount?: number
}

const DroppableMapTopInfoBox: FC<Props> = ({
  title,
  value,
  width,
  bgColor,
  fontColor,
  isExpanded = false,
  children,
  onToggle,
  musterCount
}) => {
  const ref = useClickOutside(() => {
    if (isExpanded) {
      onToggle?.(false);
    }
  });

  const handleInfoBoxClick = useCallback(
    () => {
      onToggle?.();
    },
    [onToggle]
  );

  return (
    <DroppableMapTopInfoBoxComponent
      ref={ ref }
      onClick={ handleInfoBoxClick }
      title={ title }
      value={ value }
      width={ width }
      bgColor={ bgColor }
      fontColor={ fontColor }
      isExpanded={ isExpanded }
      musterCount={ musterCount }
    >
      { children }
    </DroppableMapTopInfoBoxComponent>
  );
};

export default DroppableMapTopInfoBox;
