import { AssetRankingEvent } from "@/tenant-context/common/types/asset-ranking";
import { AssetRankingEventGroup } from "@/tenant-context/control-location-graph/util/groupBy";

export function isRelevantForTime(
  events: AssetRankingEvent[],
  time: number
): boolean {
  const eventBeforeTime = events.find(
    (ev) => (ev.rankTime ?? NaN) <= time
  );

  return !!eventBeforeTime;
}

export function generateEventForTime(
  events: AssetRankingEvent[],
  time: number
): AssetRankingEvent | undefined {
  const eventsBeforeTime = events
    .filter((ev) => (ev.rankTime ?? NaN) <= time);

  if (!eventsBeforeTime.length) {
    return;
  }

  const lastEventBeforeTime = eventsBeforeTime.reduce(
    (box, ev) => ((ev.rankTime ?? NaN) > (box.rankTime ?? NaN)) ? ev : box
  );

  const eventForTime: AssetRankingEvent = {
    ...lastEventBeforeTime,
    rankTime: time
  };

  return eventForTime;
}

export function filterEventsForTimeDomain(
  eventGroups: AssetRankingEventGroup[],
  timeDomainStart: number,
  timeDomainEnd: number
): AssetRankingEventGroup[] {
  return eventGroups.map((group) => ({
    ...group,
    events: group.events.filter(
      (ev) => (ev.rankTime ?? NaN) >= timeDomainStart
        && (ev.rankTime ?? NaN) <= timeDomainEnd
    )
  }));
}

/**
 * For the last event OF [timeDomainStart, timeDomainEnd]
 *  -> repeat it in the end of the range
 *
 * For the first event AFTER timeDomainEnd
 *  -> repeat it in the end of the range
 *
 * For the first event BEFORE timeDomainStart
 *  -> repeat it in the start of the range
 */
export default function makeInfinite(
  eventGroups: AssetRankingEventGroup[],
  timeDomainStart: number,
  timeDomainEnd: number
) {
  return eventGroups.map((group) => {
    const mutableGroup = { ...group };

    const fakeFirstEvent = generateEventForTime(
      mutableGroup.events,
      timeDomainStart
    );

    if (fakeFirstEvent) {
      // mutableGroupEvents.unshift(fakeFirstEvent);
      mutableGroup.events = [
        fakeFirstEvent,
        ...mutableGroup.events
      ];
    }

    const fakeLastEvent = generateEventForTime(
      mutableGroup.events,
      timeDomainEnd
    );

    if (fakeLastEvent) {
      mutableGroup.events = [
        ...mutableGroup.events,
        fakeLastEvent
      ];
      // mutableGroupEvents.push(fakeLastEvent);
    }

    return mutableGroup;
  });
}