import { Switch, Text } from "@mantine/core";
import { ParentSize } from "@visx/responsive";
import { FC, useMemo } from "react";

import DrawerControl from "@/common/components/DrawerControl/DrawerControl.container";
import RiskTimelineGraph from "@/tenant-context/control-risk-timeline/components/RiskTimelineGraph/RiskTimelineGraph.container";
import RiskDateFilterTriggerControl from "@/tenant-context/control-risk-timeline/controls/RiskDateFilterTriggerControl";
import {
  useRiskTimelineDrawerStyles
} from "@/tenant-context/control-risk-timeline/controls/RiskTimelineDrawer/RiskTimelineDrawer.styles";
import { GroupedRiskTimelineEvents } from "@/tenant-context/control-risk-timeline/types/risk-timeline.types";

type Props = {
  onCloseClick?: () => void,
  riskAlertData: GroupedRiskTimelineEvents[],
  onFilterTimelineByMapViewPortChange: React.ChangeEventHandler<HTMLInputElement>,
  isFilterTimelineByMapViewPortChecked: boolean
}

const RiskTimelineDrawerComponent: FC<Props> = ({
  riskAlertData,
  onCloseClick,
  onFilterTimelineByMapViewPortChange,
  isFilterTimelineByMapViewPortChecked
}) => {
  const { classes } = useRiskTimelineDrawerStyles();

  const drawerHeader = useMemo(() => (
    <div className={ classes.drawerHeadingWrapper }>
      <div className={ classes.leftSpan }>
        <Text
          className={ classes.drawerHeading }
          size={ 'lg' }
        >
          Riskline Risk Events
        </Text>

        <RiskDateFilterTriggerControl />
      </div>
      <div className={ classes.switch }>
        <div>{ 'Filter timeline by map view' }</div>
        <Switch
          onChange={ onFilterTimelineByMapViewPortChange }
          checked={ isFilterTimelineByMapViewPortChecked }
        />
      </div>
    </div>
  ), [classes.drawerHeading, classes.drawerHeadingWrapper, classes.leftSpan, classes.switch,
    isFilterTimelineByMapViewPortChecked, onFilterTimelineByMapViewPortChange]);

  return (
    <DrawerControl
      id="risk-timeline-graph-control"
      header={ drawerHeader }
      drawerPosition="bottom"
      onCloseClick={ onCloseClick }
    >
      <ParentSize className={ classes.drawerBodyWrapper }>
        { ({ width, height }) => (
          <RiskTimelineGraph
            width={ width }
            height={ height - 16 } //padding reduced
            chartData={ riskAlertData }
          />
        ) }
      </ParentSize>
    </DrawerControl>
  );
};

export default RiskTimelineDrawerComponent;
