import { createStyles } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

export const useTreeViewItemStyles = createStyles((theme) => ({
  headerRow: {
    [`.${CSS_KEY}-Checkbox-root`]: {
      position: "relative",
      top: "2px"
    }
  },
  chevronIcon: {
    flexShrink: 0,
    '&:disabled': {
      backgroundColor: 'transparent'
    },
    svg: {
      width: "24px",
      height: "24px",
      path: {
        stroke: theme.colors.neutral[6]
      },
      transition: "transform 200ms ease"
    }
  },
  chevronIconExpanded: {
    svg: {
      transform: "rotate(-90deg)"
    }
  },
  nodeLabel: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "8px"
  }
}));
