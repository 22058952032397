import bbox from "@turf/bbox";
import { lineString } from "@turf/helpers";
import { Feature, FeatureCollection, Geometry } from "geojson";
import { LngLatBoundsLike } from "mapbox-gl";
import { FitBoundsOptions, MapboxMap, MapRef } from "react-map-gl";

export const getBoundingBox = (map: MapboxMap): Array<[number, number]> => {
  const bounds = map.getBounds();

  // Returns bounding box's 4 points (NE, SE, SW, NW) as per the format Array<[number, number]>
  return [
    bounds.getNorthEast().toArray() as [number, number],
    bounds.getSouthEast().toArray() as [number, number],
    bounds.getSouthWest().toArray() as [number, number],
    bounds.getNorthWest().toArray() as [number, number]
  ];
};

// helper function to limit any calculated/mapped latitude value to match the min, max range of the valid latitude
// values (-90, 90)
export const capLatitudeValue = (latValue: number) => {
  const maxLatValue = 90;
  const minLatValue = -90;

  if (latValue < minLatValue) {
    return minLatValue;
  } else if (latValue > maxLatValue) {
    return maxLatValue;
  } else {
    return latValue;
  }
};

// This function takes any defined bounds and set the viewport to that bounds with the padding respective our map
// overlays
export const fitToViewportBounds = (
  map: MapRef | MapboxMap,
  bounds: LngLatBoundsLike,
  isWithDrawers?: {
    bottom?: boolean,
    right?: boolean
  },
  options?: FitBoundsOptions
) => {
  const defaultSidePadding = 75;
  const topInfoBoxPadding = 150;
  const drawerPadding = 400;
  const rightDrawerPadding = drawerPadding + defaultSidePadding;

  const defaultPadding = {
    top: topInfoBoxPadding,
    bottom: isWithDrawers?.bottom ? drawerPadding : defaultSidePadding,
    left: defaultSidePadding,
    right: isWithDrawers?.right ? rightDrawerPadding : defaultSidePadding
  };

  map?.fitBounds(bounds, {
    ...options,
    padding: options?.padding || defaultPadding
  });
};

// Function to get the bounding box of a FeatureCollection/Feature geoJson
export const getAllGeoJsonBounds = (featureGeoJson: FeatureCollection<Geometry> | Feature<Geometry>) => {
  if (featureGeoJson.type === 'FeatureCollection' && !featureGeoJson?.features?.length){
    return null;
  }
  const featuresArray = featureGeoJson.type === 'FeatureCollection' ? featureGeoJson?.features : [featureGeoJson];
  const allCoordinates = featuresArray?.flatMap((feature: Feature<Geometry>) => {
    switch (feature.geometry.type) {
    case 'Point':
      return [feature.geometry.coordinates];
    case 'LineString':
      return feature.geometry.coordinates;
    case 'MultiPoint':
      return feature.geometry.coordinates;
    case 'Polygon':
      return feature.geometry.coordinates.flat();
    case 'MultiLineString':
      return feature.geometry.coordinates.flat();
    case 'MultiPolygon':
      return feature.geometry.coordinates.flat(2);
    default:
      return [];
    }
  });

  if (allCoordinates?.length > 1){
    const line = lineString(allCoordinates);
    return bbox(line);
  } else {
    return null;
  }
};
