import { Button } from "@mantine/core";
import { FC } from "react";
import { FallbackProps } from 'react-error-boundary';

import { useLocalErrorBoundaryStyles } from '@/tenant-context/common/components/LocalErrorBoundary/LocalErrorBoundary.styles';

const LocalErrorBoundaryFallbackComponent: FC<FallbackProps> = ({
  error,
  resetErrorBoundary
}) => {

  const { classes } = useLocalErrorBoundaryStyles();

  return (
    <div role="alert" className={ classes.root }>
      <p>Something went wrong:</p>
      <pre>{ error.message }</pre>
      <div>
        { resetErrorBoundary  && <Button onClick={ resetErrorBoundary }>Try again</Button> }
      </div>
    </div>
  );
};

export default LocalErrorBoundaryFallbackComponent;
