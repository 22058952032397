import { FC, useCallback } from "react";

import ButtonControl from "@/common/components/ButtonControl";
import DrawerControl from "@/common/components/DrawerControl";
import { DrawerControlButtonRenderer } from "@/common/components/DrawerControl/DrawerControl.component";
import { ReactComponent as TenantSelectionIcon } from '@/common/icons/card.svg';
import TenantSelection from "@/tenant-context/control-developer-tools/components/DeveloperTools";

const DeveloperToolsControl: FC = () => {
  const renderButton: DrawerControlButtonRenderer = useCallback(({ onClick }) => (
    <ButtonControl label='Developer Tools' onClick={ onClick }>
      <TenantSelectionIcon />
    </ButtonControl>
  ), []);

  return (
    <DrawerControl
      id="tenant-selection-control"
      title={ 'Developer Tools' }
      drawerPosition="right"
      renderButton={ renderButton }
    >
      <TenantSelection />
    </DrawerControl>
  );
};

export default DeveloperToolsControl;
