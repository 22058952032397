import {
  CurrentLocationSubscription,
  PaginatedResult
} from "@/common/types/reference-data";
import { callApi } from "@/common/util/api";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { ProfileGroup } from "@/tenant-context/control-profile/types/profile";

import {
  AddLocationRequest,
  Building,
  BuildingListItem,
  BulkLocationsFileUpload,
  BulkLocationsFileUploadStatus,
  CreateFloorRequest,
  EnteredLocationDetails,
  FloorListItem,
  ILocation,
  ILocationZoneReferenceData,
  LocationCategories,
  LocationZone,
  LocationZoneReferenceType,
  LocationZoneResponse,
  SitePlanList,
  SubLocations,
  UploadedLocationStatusGrid,
  UploadGeoJsonRequest
} from "../types/ManageLocations.types";

export const getLocSubCategoriesByCategory = (categoryName: string) =>
  callApi<SubLocations>(
    `/location-management-service/v1/categories/${categoryName}/sub-categories?pageNum=0&pageSize=999`,
    {
      method: "get"
    }
  );

export const addLocationToSite = (siteName: string, requestObj: FormData) =>
  callApi<EnteredLocationDetails>(
    `location-management-service/v2/categories/${siteName}/locations`,
    {
      method: "post",
      body: requestObj,
      showApiError: true,
      withContentType: false
    }
  );

export const editLocationToSite = (
  locationId: string,
  siteName: string,
  requestObj: FormData
) =>
  callApi<EnteredLocationDetails>(
    `/location-management-service/v2/categories/${siteName}/locations/${locationId}`,
    {
      method: "post",
      body: requestObj,
      showApiError: true,
      withContentType: false
    }
  );

export const addGeoJsonToLocation = (
  locationId: string,
  locationCategory: string,
  body: UploadGeoJsonRequest
) =>
  callApi<void>(
    `location-management-service/v2/categories/${locationCategory}/locations/${locationId}/geoJson`,
    {
      method: "post",
      body: JSON.stringify(body),
      headers: {}
    }
  );

export const getSitePlansListData = (
  locationId: string,
  locationCategory: string
) =>
  callApi<Array<SitePlanList>>(
    `location-management-service/v2/categories/${locationCategory}/locations/${locationId}/geoJson`,
    {
      method: "get"
    }
  );

export const getLocationsListData = (
  page = 0,
  size = 10,
  additionalParamsQuery: string,
  category: string,
  sort?: SortData,
  query?: string
) =>
  callApi<PaginatedResult<ILocation>>(
    `location-management-service/v2/categories/${category}/locations?${additionalParamsQuery}mergeNonTenant=false&${
      sort?.colId ? "orderBy=" + sort.colId + ":" + sort.sort + "&" : ""
    }${query ? "&query=(" + query + ")&" : ""}pageSize=${size}&pageNum=${page}`,
    {
      method: "get"
    }
  );

export const getSubscribedLocationTypes = () =>
  callApi<Array<Record<string, string>>>(
    `location-management-service/v2/location-types`,
    {
      method: "get"
    }
  );

export const getActiveLocationSubscription = (
  productId: string,
  tenantId: string
) =>
  callApi<CurrentLocationSubscription>(
    `managed/platform-service/v1/Products/${productId}/product-advance-location-types/ref:${tenantId}`,
    {
      method: "get"
    }
  );

export const getAllLocationCategories = (searchQuery: string) =>
  callApi<Array<LocationCategories>>(
    `location-management-service/v2/categories${
      searchQuery ? `?query=(${searchQuery})` : ""
    }`,
    {
      method: "get"
    }
  );

export const deleteLocationCategory = (id: string) =>
  callApi(`/location-management-service/v2/categories`, {
    body: JSON.stringify({ id }),
    method: "delete"
  });

export const editLocationCategory = (id: string, tid: string) =>
  callApi(`/location-management-service/v2/categories/${tid}`, {
    method: "post",
    body: JSON.stringify({ id })
  });

export const createLocationCategory = (id: string) =>
  callApi(`/location-management-service/v2/categories`, {
    method: "post",
    body: JSON.stringify({ id })
  });

export const getLocationDetails = (tid: string, category: string) =>
  callApi<AddLocationRequest>(
    `/location-management-service/v2/categories/${category}/locations/${tid}`,
    {
      method: "get"
    }
  );

export const deleteLocationSitePlan = (
  locationCategory: string,
  locationId: string,
  geoJsonId: string
) =>
  callApi<PaginatedResult<ILocation>>(
    `location-management-service/v2/categories/${locationCategory}/locations/${locationId}/geoJson/${geoJsonId}`,
    {
      method: "delete"
    }
  );

export const getLocationZonesData = (
  page = 0,
  size = 10,
  category: string,
  locationId: string,
  sort?: SortData,
  query?: string,
  additionalQuery?: string
) =>
  callApi<PaginatedResult<LocationZoneResponse>>(
    `/location-management-service/v2/categories/${category}/locations/${locationId}/zones?${
      sort?.colId ? "orderBy=" + sort.colId + ":" + sort.sort + "&" : ""
    }${query ? "&query=(" + query + ")&" : ""}${
      additionalQuery ? additionalQuery + "&" : ""
    }pageSize=${size}&pageNum=${page}`,
    {
      method: "get"
    }
  );

export const deleteLocationZone = (
  subCategoryName: string,
  locationId: string,
  zoneId: string
) =>
  callApi(
    `/location-management-service/v2/categories/${subCategoryName}/locations/${locationId}/zones/${zoneId}`,
    {
      method: "delete"
    }
  );

export const saveLocationZone = (params: LocationZone) =>
  callApi<LocationZoneResponse>(
    `/location-management-service/v2/categories/${params.subCategoryName}/locations/${params.locationId}/zones`,
    {
      headers: {
        "Content-Type": "application/json;"
      },
      method: "post",
      body: JSON.stringify(params)
    }
  );

export const updateLocationZone = (params: LocationZone) =>
  callApi<LocationZoneResponse>(
    `/location-management-service/v2/categories/${params.subCategoryName}/locations/${params.locationId}/zones/${params.id}`,
    {
      headers: {
        "Content-Type": "application/json;"
      },
      method: "post",
      body: JSON.stringify(params)
    }
  );

export const createBuilding = (locationId: string, body: Building) =>
  callApi<void>(
    `location-management-service/v2/location/${locationId}/building`,
    {
      method: "post",
      body: JSON.stringify(body)
    }
  );

export const updateBuilding = (
  locationId: string,
  body: Building,
  buildingId: string
) =>
  callApi<Building>(
    `location-management-service/v2/location/${locationId}/building/${buildingId}`,
    {
      method: "post",
      body: JSON.stringify(body)
    }
  );

export const getBuildingListData = (
  locationId: string,
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) =>
  callApi<PaginatedResult<BuildingListItem>>(
    `location-management-service/v2/location/${locationId}/building?mergeNonTenant=false&${
      sort?.colId ? "orderBy=" + sort.colId + ":" + sort.sort + "&" : ""
    }${query ? "&query=(" + query + ")&" : ""}pageSize=${size}&pageNum=${page}`,
    {
      method: "get"
    }
  );
export const getLocationZoneReferenceData = (
  referenceType: LocationZoneReferenceType,
  page = 0,
  size = 999
) =>
  callApi<ILocationZoneReferenceData[]>(
    `location-management-service/v2/zone/references/${referenceType}?pageSize=${size}&pageNum=${page}&mergeNonTenant=false`,
    {
      method: "get"
    }
  );

export const deleteBuilding = (locationId: string, buildingId: string) =>
  callApi(
    `/location-management-service/v2/location/${locationId}/building/${buildingId}`,
    {
      method: "delete"
    }
  );
export const getProfileGroupsForLocationZone = (page = 0, size = 999) =>
  callApi<PaginatedResult<ProfileGroup>>(
    `managed/platform-service/v1/people-groups?size=${size}&page=${page}&search=${encodeURI('isApplicableToAllService = true OR (applicableServicesList:array IN ["applicable-service-access-control"])')}`,
    {
      method: "get"
    }
  );

export const createFloor = (
  locationId: string,
  buildingId: string,
  body: CreateFloorRequest
) =>
  callApi<void>(`location-management-service/v2/building/${buildingId}/floor`, {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json;",
      LocationTid: locationId
    }
  });

export const updateFloor = (
  locationId: string,
  buildingId: string,
  floorId: string,
  body: CreateFloorRequest
) =>
  callApi<void>(
    `location-management-service/v2/building/${buildingId}/floor/${floorId}`,
    {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json;",
        LocationTid: locationId
      }
    }
  );

export const getFloor = (
  locationId: string,
  buildingId: string,
  floorId: string
) =>
  callApi<FloorListItem>(
    `location-management-service/v2/building/${buildingId}/floor/${floorId}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json;",
        LocationTid: locationId
      }
    }
  );
export const getFloorsListData = (
  locationId: string,
  buildingId: string,
  sort?: SortData
) =>
  callApi<PaginatedResult<FloorListItem>>(
    `location-management-service/v2/building/${buildingId}/floor?mergeNonTenant=false&${
      ( sort?.colId && sort.sort) ? "orderBy=" + sort.colId + ":" + sort.sort + "&" : ""
    }pageSize=10000&pageNum=0`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json;",
        LocationTid: locationId
      }
    }
  );

export const deleteFloor = (
  locationId: string,
  buildingId: string,
  floorId: string
) =>
  callApi(
    `/location-management-service/v2/building/${buildingId}/floor/${floorId}`,
    {
      method: "delete",
      headers: {
        "Content-Type": "application/json;",
        LocationTid: locationId
      }
    }
  );

export const uploadBulkLocationsFile = (formData: FormData) =>
  callApi<BulkLocationsFileUpload>(
    `/managed/platform-service/v1/bulk-data/files`,
    {
      method: "post",
      body: formData,
      headers: {
        "x-domain-model": "UploadBulkData"
      },
      withTenantId: false,
      withContentType: false
    }
  );

export const getBulkLocationTemplate = () =>
  callApi<{ expirationInMillis: number; exportUrl: string }>(
    `managed/platform-service/v1/bulk-data?templateType=location`,
    {
      method: "get",
      headers: {
        "Content-Type":
          "application/json;domain-model=DownloadBulkDataTemplate"
      }
    }
  );

export const getBulkLocationUploadStatus = (bulkDataId: string) =>
  callApi<BulkLocationsFileUploadStatus>(
    `managed/platform-service/v1/bulk-data/${bulkDataId}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json;domain-model=CheckBulkDataStatus"
      }
    }
  );

export const getBulkLocationUploadStatusGrid = (
  bulkDataId: string,
  page = 0,
  size = 10,
  sort?: SortData,
  query?: string
) =>
  callApi<UploadedLocationStatusGrid>(
    `managed/platform-service/v1/bulk-data/${bulkDataId}/row-records?${
      sort?.colId ? "orderBy=" + sort.colId + ":" + sort.sort : ""
    }&search=status='ERROR'${query ? "AND(" + query + ")&" : ""}&size=${size}&page=${page}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json;domain-model=GetRowRecordsByStatus"
      }
    }
  );

export const getBulkLocationUploadStatusCSV = (bulkDataId: string) =>
  callApi<string>(
    `managed/platform-service/v1/bulk-data/${bulkDataId}/row-records/download?search=status=%27ERROR%27&page=0&size=1000`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/csv;domain-model=GetRowRecordsByStatus"
      },
      responseType: "blob"
    }
  );
