import { createStyles, MantineTheme } from "@mantine/core";

import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useTravelSearchControlDrawerStyles = createStyles((theme: MantineTheme) => ({
  description: {
    color: theme.colors.neutral[2],
    fontSize: 12,
    lineHeight: theme.other.lh.l,
    marginBottom: 24
  },

  segment: {
    margin: '0 0 40px 0'
  },

  segmentLabel: {
    fontSize: 12,
    fontWeight: theme.other.fw.bold,
    lineHeight: theme.other.lh.l,
    textTransform: 'uppercase',
    marginBottom: 16
  },

  riskIconWrapper: {
    display: 'flex',
    alignItems: 'center'
  },

  riskLevelIcon:{
    position: 'relative'
  },

  riskText: {
    fontSize: 14,
    marginLeft: 8
  },

  riskCheckbox: {
    '& > .mantine-Checkbox-inner > .mantine-Checkbox-input': {
      height: 18,
      width: 18
    },

    '& > label': {
      display: 'flex',
      alignItems: 'center',
      top: 2,
      position: 'relative'
    }
  },

  calendarMenu: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: genericColorPalette.neutral[10],
    border: `1px solid ${ genericColorPalette.neutral[7] }`,
    padding: '16px 64px 32px 64px',

    '& .footer, .header': {
      display: 'flex',
      justifyContent: 'flex-end'
    },

    '& .header': {
      marginBottom: 48,
      marginRight: -48
    },

    '& .footer': {
      marginRight: -48
    }
  }
}));
