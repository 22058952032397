import { createStyles, MantineTheme } from "@mantine/core";

type Props = {
    timeframe: 'past' | 'present' | 'future'
}

export const useProfileItineraryItemStyles = createStyles((theme:MantineTheme, props: Props) => ({
  iteneraryStep: {
    backgroundColor: theme.colors.neutral[7],
    border: props.timeframe === 'present' ? '' :
      `1px ${props.timeframe === 'past' ? 'solid' : 'dashed'} ${theme.colors.neutral[7]}`,
    padding: '16px',
    marginTop: '24px',
    lineHeight: '18px',
    flex: 1
  },
  iteneraryInfo: {
    display: 'flex',
    gap: '12px',
    width: '100%'
  },
  iteneraryLocation: {
    flex: 1
  },
  flag: {
    width: 16,
    height: 16
  },
  iteneraryArrow: {
    alignSelf: 'center',
    textAlign: 'center',
    flex: 1,
    svg: {
      height: '24px',
      width: '13px'
    },
    path: {
      fill: theme.colors.neutral[5]
    }
  },
  personInfoHeader: {
    color: theme.colors.neutral[5],
    fontSize: theme.fontSizes.xs,
    lineHeight: theme.other.lh.s
  },
  countryName: {
    fontSize: theme.fontSizes.xs,
    color: theme.white,
    marginBottom: 8
  },
  locName: {
    fontSize: theme.fontSizes.xs,
    fontWeight: theme.other.fw.bold,
    color: theme.white,
    marginBottom: 4
  },
  progress: {
    marginTop: '16px'
  },
  addressHeader: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.neutral[5]
  },
  addressFont: {
    fontSize: theme.fontSizes.xs
  },
  otherLocationContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  otherItineraryLocation: {
    justifyContent: 'space-between',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
  hotelToFromWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));
