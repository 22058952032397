import { SelectItem } from '@mantine/core/lib/Select/types';
import { FC, useCallback, useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from '@/core/store';
import AddTagsPopoverComponent from '@/tenant-context/control-profile/components/ui/AddTagsPopover/AddTagsPopover.component';
import {
  DEFAULT_INIT_COLOR
} from '@/tenant-context/control-profile/components/ui/AddTagsPopover/AddTagsPopover.config';
import { ProfileTag } from '@/tenant-context/control-profile/types/profile';

const AddTagsPopoverContainer: FC = () => {
  const [isPopoverOpened, setPopoverOpened] = useState(false);
  const [isColorPalateOpened, setColorPalateOpened] = useState(false);
  const [tagsMultiselectValue, setTagsMultiselectValue] = useState<ProfileTag[]>([]);
  const [multiselectSearchValue, setMultiselectSearchValue] = useState<string>('');
  const multiselectTagsStringValueState = useState<string[]>([]);
  const [multiselectTagsStringValue, setMultiselectTagsStringValue] = multiselectTagsStringValueState;

  const {
    profile: {
      openProfileTagsPopover,
      addTagToListOnPopover,
      loadProfileTags,
      resetProfileTagsPopover,
      submitProfileTags,
      changeColor,
      createTagsFromCsv,
      updateProfileTag,
      SET_SELECTED_TAG_TO_EDIT,
      SET_PROFILE_TAGS_LIST
    }
  } = useDispatch<Dispatch>();

  const profileTagsList =  useSelector((state: RootState) => state.profile.profileTagsList);
  const selectedTags =  useSelector((state: RootState) => state.profile.addedProfileTagsListOnPopover);
  const mode =  useSelector((state: RootState) => state.profile?.profileTagsPopoverMode);
  const initTagColor =  useSelector((state: RootState) => state.profile?.initTagColor);
  const profileTagsListForProfile =  useSelector((state: RootState) => state.profile?.profileTagsListForProfile);

  const formControl = useForm();
  const { getValues } = formControl;

  const handleColorSelect = useCallback((color: string) => {
    changeColor(color);
    if (mode !== 'EDIT') {
      setColorPalateOpened(false);
    }
  }, [changeColor, mode]);

  const handleTrigger = useCallback(() => {
    openProfileTagsPopover('ADD');
  }, [openProfileTagsPopover]);

  const handleColorPalateClose = useCallback(() => {
    setColorPalateOpened(false);
  }, []);

  const onTagSelect = useCallback((index: number) => {
    setColorPalateOpened(true);
    if (selectedTags && selectedTags.length > index) {
      SET_SELECTED_TAG_TO_EDIT(selectedTags[index]);
    }
  }, [SET_SELECTED_TAG_TO_EDIT, selectedTags]);

  const add = useCallback(() => {
    const tagsToAdd: ProfileTag[] = [...tagsMultiselectValue];
    if (mode === 'ADD') {

      if (tagsMultiselectValue.length < multiselectTagsStringValue.length) {
        const tagNames = new Set(tagsMultiselectValue.map(tag => tag.name));
        const missedTags: string[] = multiselectTagsStringValue.filter((tagName) => !tagNames.has(tagName));
        missedTags.forEach(tagName => {
          if (!tagName) {
            return;
          }

          const profileTag: ProfileTag = {
            color: DEFAULT_INIT_COLOR,
            name: tagName,
            id: tagName
          };

          tagsToAdd.push(profileTag);
        });
      }

      addTagToListOnPopover(tagsToAdd);
      setTagsMultiselectValue([]);
      setMultiselectTagsStringValue([]);
    } else if (mode === 'CREATE') {
      const csvTagsString = getValues('csv_tags_string');
      createTagsFromCsv(csvTagsString);
    }
  }, [
    addTagToListOnPopover,
    createTagsFromCsv,
    getValues,
    mode,
    multiselectTagsStringValue,
    setMultiselectTagsStringValue,
    tagsMultiselectValue
  ]);

  const handleCancel = useCallback(() => {
    resetProfileTagsPopover();
    setColorPalateOpened(false);
  }, [resetProfileTagsPopover]);

  const handleDone = useCallback(() => {
    if (mode === 'EDIT') {
      updateProfileTag();
      setColorPalateOpened(false);
      return;
    }
    submitProfileTags();
    setColorPalateOpened(false);
  }, [mode, submitProfileTags, updateProfileTag]);

  const handleSearchChange = useCallback((phrase: string) => {
    setMultiselectSearchValue(phrase);
  }, []);

  const handleEnter = useCallback(($event) => {
    if ($event.key !== 'Enter' || !multiselectSearchValue) {
      return;
    }

    if (profileTagsList && profileTagsList.some((tag) => multiselectSearchValue === tag.name)) {
      return;
    }

    const profileTag: ProfileTag = {
      color: DEFAULT_INIT_COLOR,
      name: multiselectSearchValue,
      id: multiselectSearchValue
    };

    setTagsMultiselectValue((current) => [...current, profileTag]);
    setMultiselectTagsStringValue((current) => [...current, multiselectSearchValue]);
    SET_PROFILE_TAGS_LIST([...profileTagsList || [], profileTag]);

  }, [SET_PROFILE_TAGS_LIST, multiselectSearchValue, profileTagsList, setMultiselectTagsStringValue]);

  const handleCreate = useCallback((query: string): SelectItem => {
    if (profileTagsList && profileTagsList.some((tag) => query === tag.name)) {
      showNotification({
        title: 'Tag already exists',
        message: `Tag with name ${query} already exists`,
        color: 'warning'
      });

      return { } as SelectItem;
    }

    const profileTag: ProfileTag = {
      color: DEFAULT_INIT_COLOR,
      name: query,
      id: query
    };

    SET_PROFILE_TAGS_LIST([...profileTagsList || [], profileTag]);
    setTagsMultiselectValue([...tagsMultiselectValue, profileTag]);
    setMultiselectTagsStringValue((current) => [...current, query]);

    return { value: query, label: query };
  }, [
    SET_PROFILE_TAGS_LIST,
    profileTagsList,
    setMultiselectTagsStringValue,
    tagsMultiselectValue
  ]);

  useEffect(() => {
    loadProfileTags();
    return () => {
      resetProfileTagsPopover();
      setColorPalateOpened(false);
    };
  }, [loadProfileTags, resetProfileTagsPopover]);

  useEffect(() => {
    if (mode && mode === 'EDIT') {
      setColorPalateOpened(true);
    }
  }, [mode]);

  useEffect(() => {
    if (!mode) {
      setPopoverOpened(false);
      return;
    }

    setPopoverOpened(true);
  }, [mode]);

  return (
    <AddTagsPopoverComponent
      isOpened={ isPopoverOpened }
      onColorSelect={ handleColorSelect }
      handleTrigger={ handleTrigger }
      isColorPalateOpened={ isColorPalateOpened }
      onCloseColorPalate={ handleColorPalateClose }
      add={ add }
      handleCancel={ handleCancel }
      handleDone={ handleDone }
      onTagSelect={ onTagSelect }
      profileTagsList={ profileTagsList || [] }
      selectedTags={ selectedTags || [] }
      setTagsMultiselectValue={ setTagsMultiselectValue }
      multiselectTagsStringValueState={ multiselectTagsStringValueState }
      mode={ mode }
      initTagColor={ initTagColor }
      onSearchChange={ handleSearchChange }
      onEnter={ handleEnter }
      onCreate={ handleCreate }
      profileTagsListForProfile={ profileTagsListForProfile || [] }
    />
  );
};

export default AddTagsPopoverContainer;
