import { MapboxGeoJSONFeature } from "mapbox-gl";
import React, { FC, useCallback, useContext, useMemo, useRef } from "react";
import { useMap } from "react-map-gl";

import { Popup } from "@/common/components/Popup/Popup.components";
import { useCurrentPopup } from "@/tenant-context/common/hooks/useMapPopupList";
import { usePeopleLocationPopupStyles } from "@/tenant-context/visualisation-people/components/PeopleLocationPopup/PeopleLocationPopup.styles";
import {
  PersonPopupAssetRankingEvent,
  PopupPositionInfo
} from "@/tenant-context/visualisation-people/types/people.types";

import { PeopleContext } from "../../context/People.context";
import PeoplePopupSkeleton from "./PeoplePopupSkeleton.component";
import PeopleTravelPopup from "./PeopleTravelPopup/PeopleTravelPopup.container";
import { StandardPersonPopup } from "./StandardPersonPopup.component";

type Props = {
  popupId: string;
  onClickZoomTo: (feature: MapboxGeoJSONFeature) => void;
  onClickMoreDetails: (feature: MapboxGeoJSONFeature) => void;
  onClickClose(popupId: string): void;
};

export const PeopleLocationPopup: FC<Props> = ({
  popupId,
  onClickZoomTo,
  onClickMoreDetails,
  onClickClose
}) => {
  const { classes: popupClasses } = usePeopleLocationPopupStyles();
  const { current: map } = useMap();
  const { mapKey } = useContext(PeopleContext);
  const { popup } = useCurrentPopup<
    [PersonPopupAssetRankingEvent, MapboxGeoJSONFeature]
  >(mapKey, popupId);
  const [assetSummary, feature] = popup?.data ?? [];

  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickClose = useCallback(
    () => onClickClose(popupId),
    [onClickClose, popupId]
  );

  const handleClickZoomTo = useCallback(
    () => feature && onClickZoomTo(feature),
    [feature, onClickZoomTo]
  );

  const handleClickMoreDetails = useCallback(
    () => feature && onClickMoreDetails(feature),
    [feature, onClickMoreDetails]
  );

  const popUpDimensions = popupRef?.current?.getBoundingClientRect();

  const popupPosition = useMemo<PopupPositionInfo | undefined>(() => {
    if(!map) {
      return undefined;
    }

    const lngLat = [
      popup?.position[0] ?? 0,
      popup?.position[1] ?? 0
    ] as [number, number];

    const point = map.project(lngLat);

    const mapCanvas = map.getCanvas();
    const rect = mapCanvas.getBoundingClientRect();

    const clientX = rect.left + point.x;
    const clientY = rect.top + point.y;

    return {
      clientX,
      clientY,
      popupMaxHeight: popUpDimensions?.height || 550,
      popupMaxWidth: popUpDimensions?.width || 400
    };
  }, [map, popUpDimensions?.height, popUpDimensions?.width, popup?.position]);

  const content: React.ReactNode = useMemo(() => {
    if (popup?.data === null || !assetSummary ) {
      return <PeoplePopupSkeleton />;
    }

    if (assetSummary.adapterSource === "travel-book-adapter-service") {
      return (
        <PeopleTravelPopup
          assetSummary={ assetSummary }
          onClickZoomTo={ handleClickZoomTo }
          onClickMoreDetails={ handleClickMoreDetails }
          onClickClose={ handleClickClose }
        />
      );
    }

    return (
      <StandardPersonPopup
        assetSummary={ assetSummary }
        onClickZoomTo={ handleClickZoomTo }
        onClickMoreDetails={ handleClickMoreDetails }
        onClickClose={ handleClickClose }
      />
    );
  }, [assetSummary, handleClickClose, handleClickMoreDetails, handleClickZoomTo, popup?.data]);

  return (
    <Popup
      popupPosition={ popupPosition }
      longitude={ popup?.position[0] ?? 0 }
      latitude={ popup?.position[1] ?? 0 }
      padding="0"
      closeButton={ false }
      offset={ 20 }
      closeOnClick={ false }
      anchor={ "right" }
    >
      <div ref={ popupRef } className={ popupClasses.popupContainer }>
        { content }
      </div>
    </Popup>
  );
};

export default PeopleLocationPopup;
