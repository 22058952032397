import { FC, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { TravelContext } from "@/tenant-context/control-travel/context/Travel.context";

import TravelItineraryControl, {
  TRAVEL_ITINERARY_CONTROL_DRAWER_ID
} from "./TravelItineraryControl.component";

const TravelItineraryControlContainer: FC = () => {

  const {
    userProfile:{
      getMiniProfileData,
      RESET_ITINERARY_DATA,
      SET_TRAVEL_CORRELATION_ID
    },
    drawer: {
      closeRightDrawer,
      openRightDrawer
    },
    locationGraph: {
      enableGraphForPerson
    }
  } = useDispatch<Dispatch>();

  const selectedCountry = useSelector(
    (state: RootState) => state.commonData.selectedCountry
  );
  const openDrawerId = useSelector(
    (state: RootState) => state.drawer.currentlyOpenRightDrawerId
  );

  const travelData = useSelector(
    (state: RootState) => state.travel.travelData
  );

  const isTravelLoading = useSelector(
    (state: RootState) => state.travel.travelDataLoading
  );

  const { activeDay, activeTab, setActiveTab } = useContext(TravelContext);

  const date = activeDay === 'today' ?
    new Date().toLocaleDateString() : 
    new Date(new Date().getTime() + 86400000).toLocaleDateString();

  const handleDrawerClose = useCallback(() => {
    setActiveTab(undefined);
  }, [setActiveTab]);

  const handleTravelItineraryItemClick = useCallback((profileId: string, correlationId: string) => {
    enableGraphForPerson(profileId);
    SET_TRAVEL_CORRELATION_ID(correlationId);
    RESET_ITINERARY_DATA();
    closeRightDrawer();
    setActiveTab(undefined);
    getMiniProfileData(profileId);
    openRightDrawer('mini-profile-control');
  }, [RESET_ITINERARY_DATA,
    SET_TRAVEL_CORRELATION_ID,
    closeRightDrawer,
    getMiniProfileData, openRightDrawer, setActiveTab, enableGraphForPerson]);



  if (openDrawerId !== TRAVEL_ITINERARY_CONTROL_DRAWER_ID) {
    return null;
  }

  return (
    <TravelItineraryControl
      selectedCountry={ selectedCountry }
      date={ date }
      activeDay={ activeDay }
      handleDrawerClose={ handleDrawerClose }
      travelDirection={ activeTab && activeTab % 2 === 0 ? "arriving to" : "leaving from" }
      travelData={ travelData }
      handleTravelItineraryItemClick={ handleTravelItineraryItemClick }
      isLoading={ isTravelLoading }
    />
  );
};

export default TravelItineraryControlContainer;
