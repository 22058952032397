import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "@/core/store";

import GlobalSearchResults from './GlobalSearchResults.component';


const GlobalSearchResultsContainer: FC = () => {
  const {
    geoData,
    searchCriteria,
    areResultsLoading
  } = useSelector((state: RootState) => state.search);

  const [selectedSearchResultId, setSelectedSearchResultId] = useState<string>("");

  const handleItemClick = useCallback((id) => {
    setSelectedSearchResultId(id);
  },[]);

  useEffect(() => {
    // reset the setSelectedSearchResultId when the result list is changed
    setSelectedSearchResultId("");
  }, [geoData]);

  return (
    <GlobalSearchResults
      searchResults={ geoData }
      selectedSearchResultId = { selectedSearchResultId ?? "" }
      onHandleItemClick= { handleItemClick }
      searchCriteria={ searchCriteria }
      areResultsLoading={ areResultsLoading }
    />
  );
};

export default GlobalSearchResultsContainer;
