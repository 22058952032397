import { callApi, TokenType } from "@/common/util/api";
import browserDatabase from "@/common/util/browser-database";
import { CONTEXT_KEY, ContextTypes } from "@/core/store/shared-data/sharedData.types";
import { SortData } from "@/tenant-context/control-groups/types/group";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";

import { SituationType, SituationTypeItem } from "../types/situationTypes";

export const situationsApi = {
  tokenType: browserDatabase.getItem(CONTEXT_KEY) as ContextTypes === "platform" ? "access": "tenant",
  getSituationTypesData: function(
    page = 0,
    size = 10,
    sort?: SortData,
    query?: string
  ) {
    return callApi<PaginatedResult<SituationType>>(
      `/managed/icm-service/v1/situation-categories?${sort?.colId ? 'orderBy=' + sort.colId + ':' + sort.sort + '&' : ''}size=${size}&page=${page}${query ? '&search=(' + query + ')&' : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListSituationCategory'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getSituationTypeById: function(id: string) {
    return callApi<SituationTypeItem>(
      `/managed/icm-service/v1/situation-categories/${id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=GetSituationCategory'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  saveSituationType: function(params: SituationType) {
    return callApi(
      `/managed/icm-service/v1/situation-categories`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateSituationCategory'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updateSituationType: function (params: SituationType) {
    return callApi(
      `/managed/icm-service/v1/situation-categories/${params._id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateSituationCategory'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  getSituationsData: function(
    page = 0,
    size = 10,
    situationTypeId: string,
    searchQuery?: string
  ) {
    return callApi<PaginatedResult<SituationType>>(
      `/managed/icm-service/v1/situation-categories/${situationTypeId}?size=${size}&page=${page}&orderBy=situationName:ASC${searchQuery ? '&search=(' + searchQuery + ')' : ''}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=ListSituation'
        },
        method: 'get',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  deleteSituationType: function(situationTypeId: string){
    return callApi(
      `/managed/icm-service/v1/situation-categories/${situationTypeId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeleteSituationCategory'
        },
        method: 'delete',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  deleteSituation: function(situationTypeId: string, situationId: string){
    return callApi(
      `/managed/icm-service/v1/situation-categories/${situationTypeId}/situations/${situationId}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=DeleteSituation'
        },
        method: 'delete',
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  saveSituation:  function(situationTypeId: string, params: SituationType) {
    return callApi(
      `/managed/icm-service/v1/situation-categories/${situationTypeId}/situations`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=CreateSituation'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  },
  updateSituation: function(situationTypeId: string, params: SituationType) {
    return callApi(
      `/managed/icm-service/v1/situation-categories/${situationTypeId}/situations/${params._id}`,
      {
        headers: {
          'Content-Type': 'application/json;domain-model=UpdateSituation'
        },
        method: 'post',
        body: JSON.stringify(params),
        tokenType: this.tokenType as TokenType,
        withTenantId: this.tokenType === "tenant"
      }
    );
  }
};