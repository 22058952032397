import { MantineTheme, NotificationStylesParams } from "@mantine/core";

import { primitiveNumbers } from "../constants";


const getBorderTop = (theme: MantineTheme, color: string) => {
  switch (color) {
  case 'red':
  case 'error':
    return `2px solid ${theme.other.semantic.border.alert.error}`;
  case 'green':
  case 'success':
    return `2px solid ${theme.other.semantic.border.alert.success}`;
  case 'yellow':
  case 'warning':
    return `2px solid ${theme.other.semantic.border.alert.caution}`;
  case 'blue':
  case 'info':
  default:
    return `2px solid ${theme.other.semantic.border.alert.information}`;
  }
};

const getBackgroundColor = (theme: MantineTheme, color: string) => {
  switch (color) {
  case 'red':
  case 'error':
    return `${theme.other.semantic.border.alert.error} 10%`;
  case 'green':
  case 'success':
    return `${theme.other.semantic.border.alert.success} 10%`;
  case 'yellow':
  case 'warning':
    return `${theme.other.semantic.border.alert.caution} 10%`;
  case 'blue':
  case 'info':
  default:
    return `${theme.other.semantic.border.alert.information} 10%`;
  }
};

export const NotificationComponentStyle = {
  defaultProps: {
    color: 'info'
  },
  styles: (theme: MantineTheme, params: NotificationStylesParams) => ({
    root: {
      padding: `13px ${theme.other.spacing.md}px`,
      paddingLeft: `${primitiveNumbers['6x']}px !important`,
      backgroundColor: getBackgroundColor(theme, params.color),
      border: `1px solid ${theme.other.semantic.border.general.lightest}`,
      borderTop: getBorderTop(theme, params.color),
      '&::before': {
        display: 'none'
      },
      position: "relative" as React.CSSProperties["position"]
    },
    body: {
      display: 'flex',
      gap: theme.other.spacing.sm
    },
    title: {
      color: theme.other.semantic.text.primary,
      fontSize: theme.fontSizes.sm,
      fontWeight: theme.other.fw.bold
    },
    description: {
      color: theme.other.semantic.text.secondary
    },
    icon: {
      width: primitiveNumbers['3x'],
      height: primitiveNumbers['3x'],
      marginRight: theme.other.spacing.sm,
      backgroundColor: 'transparent !important',
      position: "absolute" as React.CSSProperties["position"],
      left: primitiveNumbers['2x']
    },
    closeButton: {
      minWidth: primitiveNumbers['2x'],
      minHeight: primitiveNumbers['2x'],
      width: primitiveNumbers['2x'],
      height: primitiveNumbers['2x'],

      '&:hover': {
        backgroundColor: theme.colors.neutral[7]
      }
    }
  })
};
