import { SelectItem } from '@mantine/core';
import { createModel } from '@rematch/core';

import { GridParams } from "@/common/types/ag-grid";
import { PaginatedResult } from '@/common/types/reference-data';
import { handleError } from '@/common/util/common-functions';
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootModel, RootState } from '@/core/store';

import { deleteLocationIntegrationById, getConnectorIntegrations, getIntegrationList, getLocationIntegrationById, getLocationSearchResults, getLocationSubCategories, postIntegrationMapping } from '../api/integration-mapping';
import { IntegrationDetailMetaData, IntegrationListItem, IntegrationListQuery, IntegrationSubmitObj, IntegrationSubmitRequest, LocationSearch, StringifiedLocation } from '../types/IntegrationMapping.types';

type IntegrationMappingState = {
    locationCategories: SelectItem[];
    searchedLocations: LocationSearch[],
    subscribedIntegrations: SelectItem[],
    selectedLocation?: StringifiedLocation,
    retreivedIntegrationItem?: IntegrationListItem
}

const initialState: IntegrationMappingState = {
  locationCategories: [],
  searchedLocations: [],
  subscribedIntegrations: [],
  selectedLocation: undefined,
  retreivedIntegrationItem: undefined
};

const IntegrationMappingModel = {
  name: 'integrationMapping',
  state: initialState,
  reducers: {
    SET_INTEGRATION_DETAIL_META_DATA(state: IntegrationMappingState, payload: IntegrationDetailMetaData) {
      return {
        ...state,
        locationCategories: payload.locationCategories,
        subscribedIntegrations: payload.subscribedIntegrations
      };
    },
    SET_LOCATION_SEARCH_RESULT(state: IntegrationMappingState, payload: LocationSearch[]) {
      return {
        ...state,
        searchedLocations: payload
      };
    },
    SET_SELECTED_LOCATION(state: IntegrationMappingState, payload: StringifiedLocation | undefined) {
      return {
        ...state,
        selectedLocation: payload
      };
    },
    SET_RETRIEVED_INTEGRATION(state: IntegrationMappingState, payload: IntegrationListItem | undefined) {
      return {
        ...state,
        retreivedIntegrationItem: payload
      };
    },
    CLEAR_INTEGRATION_MAPPING(state: IntegrationMappingState) {
      return {
        ...state,
        locationCategories: [],
        searchedLocations: [],
        subscribedIntegrations: [],
        selectedLocation: undefined,
        retreivedIntegrationItem: undefined
      };
    }
  },
  effects: (dispatch: Dispatch) => ({

    async getIntegrationMetaData(): Promise<void> {
      try {
        const locationSubCategories = await getLocationSubCategories();
        const connectorIntegrations = await getConnectorIntegrations();
        if (locationSubCategories && connectorIntegrations){
          dispatch.integrationMapping.SET_INTEGRATION_DETAIL_META_DATA({
            locationCategories: locationSubCategories.items.map(category => 
              ({ label: category.name, value: category.name })),
            subscribedIntegrations: connectorIntegrations.locationAndPeopleConnectorServices.map(connector => 
              ({ value: connector.connectorName, label: connector.connectorName }))
          });
        }

      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            message: e?.message ?? 'An error occurred while loading metadata',
            color: 'error'
          });
        }
      }
    },
    async getLocationSearchResult(searchObj: { searchType: string, searchTerm: string }): Promise<void> {
      try {
        const locationSearchResult = await getLocationSearchResults(searchObj.searchType, searchObj.searchTerm);
        dispatch.integrationMapping.SET_LOCATION_SEARCH_RESULT(locationSearchResult.items);
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            message: e?.message ?? 'An error occurred while searching locations',
            color: 'error'
          });
        }
      }
    },
    async submitIntegrationMapping(searchObj: IntegrationSubmitObj, state: RootState): Promise<boolean> {
      const {
        integrationMapping: {
          selectedLocation,
          retreivedIntegrationItem
        },
        commonData: {
          tenantId
        }
      } = state;

      if (!selectedLocation && !searchObj.id){
        showNotification({
          message: 'Please select a valid location to create integration',
          color: 'error'
        });

        return Promise.reject();
      }

      const requestObj: IntegrationSubmitRequest = {
        connectorProvider: searchObj.connectorProvider,
        externalId: searchObj.externalId,
        locationSubCategoryType: searchObj.locationSubCategoryType,
        locationHierarchy: searchObj?.id && retreivedIntegrationItem ? retreivedIntegrationItem.locationHierarchy : 
          selectedLocation?.locationHierarchy,
        tenantId,
        ...(searchObj?.id && { id: retreivedIntegrationItem?.id })
      };

      try {
        const response = await postIntegrationMapping(requestObj, searchObj.itemId);
        return response ? true : false;
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            message: e?.message ?? 'An error occurred while creating integration',
            color: 'error'
          });
        }

        return false;
      }
    },
    async getIntegrationListData(payload: {
      gridParams: GridParams,
    }, _state: RootState): Promise<PaginatedResult<IntegrationListItem>> {
      const requestObj: IntegrationListQuery = {
        pageNum: payload.gridParams.page,
        pageSize: payload.gridParams.size,
        ...(payload?.gridParams?.searchQuery ? { location: payload.gridParams?.searchQuery } : {}),
        ...(payload?.gridParams?.additionalParams?.connectorProvider ? 
          { connectorProvider: payload.gridParams?.additionalParams?.connectorProvider } : {}),
        ...(payload?.gridParams?.additionalParams?.locationSubType ?
          { locationSubType: payload?.gridParams?.additionalParams?.locationSubType.toUpperCase() } : {}),
        ...(payload?.gridParams?.sort ? { orderBy: `${payload?.gridParams?.sort?.colId}:${payload?.gridParams?.sort?.sort}` } : {})
      };
      const integrationList = await getIntegrationList(requestObj);
      return integrationList;
    },
    async getIntegrationById(id: string): Promise<void> {
      try {
        const integrationItem = await getLocationIntegrationById(id);
        dispatch.integrationMapping.SET_RETRIEVED_INTEGRATION(integrationItem);
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            message: e?.message ?? 'An error occurred while searching locations',
            color: 'error'
          });
        }
      }
    },
    async deleteIntegrationById(id: string): Promise<boolean> {
      const deleteIntegration= await deleteLocationIntegrationById(id).catch(handleError);
      return !!deleteIntegration;
    }
  })
};

export const integrationMapping = createModel<RootModel>()(IntegrationMappingModel);
