import { DefaultProps } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";

import TemporalFilterIndicatorComponent from "./TemporalFilterIndicator.component";

type Props = {
  className?: string;
} & Pick<DefaultProps, "sx">;

const TemporalFilterIndicatorContainer: FC<Props> = ({ sx }) => {
  const { isTimeTravelActive, timeTravelTargetEpoch } = useSelector(
    (state: RootState) => state.rankingSettings
  );

  const {
    rankingSettings: { resetRankingSettingsFilters }
  } = useDispatch<Dispatch>();

  const [date, setDate] = useState(new Date());

  useEffect(() => {
    if (isTimeTravelActive) {
      setDate(new Date(timeTravelTargetEpoch));
    } else {
      setDate(new Date());
    }
  }, [isTimeTravelActive, timeTravelTargetEpoch]);

  useEffect(() => {
    if (isTimeTravelActive) {
      return;
    }
    const intervalId = setInterval(() => {
      // don't update the date if the seconds are not 0
      setDate((prev) => {
        const next = new Date();
        if (next.getSeconds() === 0) {
          return next;
        }

        return prev;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isTimeTravelActive]);

  return (
    <TemporalFilterIndicatorComponent
      isLive={ !isTimeTravelActive }
      date={ date }
      sx={ sx }
      onClickReset={ resetRankingSettingsFilters }
    />
  );
};

export default TemporalFilterIndicatorContainer;
