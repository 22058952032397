import { Stack, Text } from '@mantine/core';
import {
  Dropzone as MantineDropzone,
  FileWithPath } from '@mantine/dropzone';
import { FC, useCallback, useMemo, useState } from "react";
import { ErrorCode, FileRejection } from 'react-dropzone';

import { useDropzoneStyles } from '@/common/components/Dropzone/Dropzone.style';
import usePermission from '@/common/hooks/usePermission';
import { ReactComponent as DocumentOutlineIcon } from '@/common/icons/document-outline.svg';
import { ReactComponent as DocumentTextOutlineIcon } from '@/common/icons/document-text-outline.svg';
import { ReactComponent as ImageOutlineIcon } from '@/common/icons/image-outline.svg';
import { RequiredPolicies } from '@/common/types/permission-control';
import { toHumanReadableFileSize } from '@/common/util/file-size';
import { showNotification } from "@/common/util/notification";

type Props = {
  onAcceptedFiles: (files: FileWithPath[]) => void,
  onRejectedFiles?: (fileRejections: FileRejection[]) => void,
  accept: string[],
  openRef?: React.RefObject<() => void>,
  maxFiles?: number,
  maxSize?: number,
  allFilesMaxSize?: number,
  humanReadableAccept?: string,
  requiredPolicies?: RequiredPolicies | RequiredPolicies[],
  allowDuplicates?: boolean
};

const Dropzone: FC<Props> = ({
  onAcceptedFiles,
  onRejectedFiles,
  accept,
  openRef,
  maxFiles,
  maxSize,
  allFilesMaxSize,
  humanReadableAccept,
  requiredPolicies,
  allowDuplicates = false
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState<FileWithPath[]>([]);
  const { classes } = useDropzoneStyles();

  const isAuthorised = usePermission(requiredPolicies);
  const isDisabled = useCallback(() => {
    return !requiredPolicies ? false : !isAuthorised;
  }, [requiredPolicies, isAuthorised]);

  const acceptedFilesSize = useMemo(() => acceptedFiles.reduce((acc, f) => acc + f.size, 0), [acceptedFiles]);

  const onDrop = useCallback((files: FileWithPath[]) => {
    const overallSize = files.reduce((acc, f) => acc + f.size, acceptedFilesSize);

    if (allFilesMaxSize && overallSize > allFilesMaxSize) {
      showNotification({
        message: `Overall file size ${toHumanReadableFileSize(overallSize)} exceeds the limit of ${toHumanReadableFileSize(allFilesMaxSize)}`,
        color: 'error'
      });

      return;
    }

    setAcceptedFiles(prev => [...prev, ...files]);
    onAcceptedFiles(files);
  }, [acceptedFilesSize, allFilesMaxSize, onAcceptedFiles]);

  const validator = useCallback((file: FileWithPath) => {
    if (!allowDuplicates && acceptedFiles.filter(f => f.name === file.name).length > 0) {
      return {
        code: ErrorCode.FileInvalidType,
        message: 'File already exists'
      };
    }

    return null;
  }, [acceptedFiles, allowDuplicates]);

  return (
    <MantineDropzone
      onDrop={ onDrop }
      onReject={ onRejectedFiles }
      maxSize={ maxSize }
      accept={ accept }
      className={ classes.dropZone }
      openRef={ openRef }
      maxFiles={ maxFiles }
      disabled={ isDisabled() }
      validator={ validator }
    >
      <Stack
        spacing="sm"
        align='center'
        justify='center'
        style={ { minHeight: 220, pointerEvents: 'none' } }
      >
        <MantineDropzone.Accept>
          GOOD
        </MantineDropzone.Accept>

        <MantineDropzone.Reject>
          BAD
        </MantineDropzone.Reject>

        <MantineDropzone.Idle>
          <div />
          <div className={ classes.dropIcon }>
            <DocumentTextOutlineIcon />
            <ImageOutlineIcon />
            <DocumentOutlineIcon />
          </div>
        </MantineDropzone.Idle>

        <Text size="md">
          Drag & drop your file here or <span className={ classes.accentedAction }>browse to upload</span>
        </Text>

        <Text size="sm">
          Maximum individual file size: <span className={ classes.dimmedText }>
            { toHumanReadableFileSize(maxSize ?? 0) }
          </span>
        </Text>

        { allFilesMaxSize && (<Text size="sm" mt="-12px">
          Maximum overall file size: <span className={ classes.dimmedText }>
            { toHumanReadableFileSize(allFilesMaxSize) }
          </span>
        </Text>) }

        <Text size="sm">
          Valid file types: <span className={ classes.dimmedText }>
            { humanReadableAccept ?? accept.join(', ') }
          </span>
        </Text>
      </Stack>
    </MantineDropzone>
  );
};

export default Dropzone;
