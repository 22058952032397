import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

import { CSS_KEY } from "@/core/styles/mantine/constants";
import { genericColorPalette } from "@/core/styles/mantine/palettes";

export const useLocationZonesStyles = createStyles((theme: MantineTheme) => ({
  backLink: {
    background: "transparent",
    border: "0",
    cursor: "pointer",
    fontSize: "15px",
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.link[0],
    textDecoration: 'none',
    marginBottom: 32,
    '&:hover': {
      color: theme.white
    },
    '& > svg': {
      marginRight: 8,
      '& > path': {
        stroke: `${theme.colors.link[0]} !important`,
        fill:  theme.colors.link[0]
      }
    }
  },
  headerWrapper: {
    backgroundColor: theme.colors.neutral[9],
    marginBottom: 24
  },
  container: {
    backgroundColor: theme.colors.neutral[9],
    padding: "0 24px"
  },
  headerTextWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerText: {
    fontSize: theme.fontSizes.xl,
    fontWeight: theme.other.fw.bold,
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  btn: {
    all: 'unset',
    cursor: 'pointer',
    display: 'flex'
  },
  extendedMargin: {
    marginTop: '48px'
  }, modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *:not(:first-child)': {
      marginLeft: 24
    }
  },
  section: {
    marginBottom: 24,
    [`& .${CSS_KEY}-Textarea-input`]:{
      minHeight: 200
    }
  },
  btnRight:{
    position: "absolute",
    right: 0,
    zIndex: 1,
    top: 16
  },
  relative:{
    position: 'relative'
  },
  asterisk:{
    '&:after':{
      color: genericColorPalette.error[0],
      content: '"*"',
      display: "inline-block",
      marginLeft: 4
    },
    position: "relative"
  },

  searchInput: {
    marginTop: 32,
    marginBottom: 16,
    width: "100%",

    '& svg > path': {
      fill: theme.colors.neutral[4]
    }
  }
}));
