import { createStyles } from "@mantine/core";
import { MantineTheme } from "@mantine/styles/lib/theme";

export const useTemporalFilterIndicatorStyles = createStyles((theme: MantineTheme) => ({
  indicatorRoot: {
    width: "max-content",
    display: 'flex',
    backgroundColor: theme.colors.neutral[8],
    boxShadow: theme.other.sdw.bottom,
    color: theme.white,
    border: `1px solid ${theme.colors.neutral[6]}`,
    fontSize: theme.fontSizes.sm
  },
  clockIcon: {
    width: 32,
    height: 32,
    padding: 6,
    flexShrink: 0
  },
  circle: {
    fill: theme.colors.red[5]
  }
}));
