import { callApi } from "@/common/util/api";
import { ServerActivityLog } from "@/tenant-context/employee-app-action-response-center/types/activity-log";

export const getActivityLogForCase = (
  caseTid: string
) => callApi<
  ServerActivityLog[]
>(
  `/audit-service/v1/audit-logs`,
  {
    method: 'get',
    withTenantId: true,
    withAuthorization: true,
    queryParams: {
      pageNumber: 0,
      pageSize: 100,
      orderBy: 'audit_time:ASC',
      query: `tid='${caseTid}'`
    }
  }
);
