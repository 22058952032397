import { createModel } from "@rematch/core";

import { Dispatch, RootModel, RootState } from "@/core/store";
import { AssetRankingEvent, AssetRankingSummary } from "@/tenant-context/common/types/asset-ranking";
import { prepareEvent } from "@/tenant-context/common/util/location-event/prepare";
import { getLocationsForPersonInTimeframe } from "@/tenant-context/control-location-graph/api/location-graph";
import groupAssetRankingEvents, { AssetRankingEventGroup } from "@/tenant-context/control-location-graph/util/groupBy";
import { RankingOption } from "@/tenant-context/visualisation-people/store/people-locations/people-locations.model";

export type TimeScale = 'Hours' | 'Days';

// eslint-disable-next-line no-magic-numbers
export const ONE_DAY = 24 * 60 * 60 * 1000;
// eslint-disable-next-line no-magic-numbers
export const ONE_WEEK = ONE_DAY * 7;

export const timeScaleDomainDeltaMap: Record<TimeScale, number> = {
  Hours: ONE_DAY,
  Days: ONE_WEEK
};

export type LocationGraphState = {
  currentPersonAssetId?: string,
  currentPersonFullName?: string,
  timeMiddle: number,
  startTime: number,
  endTime: number,
  assetRankingSummaries: AssetRankingSummary[],
  assetRankingEvents: AssetRankingEvent[],
  groupedAssetRankingEvents: AssetRankingEventGroup[],
  timeScale: TimeScale,
  timeDomainDelta: number
}

const now = Date.now();

const cleanState: LocationGraphState = {
  currentPersonAssetId: undefined,
  timeMiddle: now,
  startTime: now - timeScaleDomainDeltaMap.Hours,
  endTime: now + timeScaleDomainDeltaMap.Hours,
  assetRankingSummaries: [],
  assetRankingEvents: [],
  groupedAssetRankingEvents: [],
  timeScale: 'Hours',
  timeDomainDelta: timeScaleDomainDeltaMap.Hours
};

// TODO type this, impossible to read
const locationGraphModel = {
  name: 'locationGraph',
  state: cleanState,

  reducers: {
    SET_CURRENT_PERSON: (
      state: LocationGraphState,
      payload: { assetId: string, personFullName: string }
    ) => ({
      ...state,
      currentPersonAssetId: payload.assetId,
      currentPersonFullName: payload.personFullName
    }),

    SET_TIME_DOMAIN: (
      state: LocationGraphState,
      payload: {
        startTime: number,
        endTime: number,
        timeMiddle: number
      }
    ) => ({
      ...state,
      startTime: payload.startTime,
      endTime: payload.endTime,
      timeMiddle: payload.timeMiddle
    }),

    SAVE_RANKS: (
      state: LocationGraphState,
      params: Pick<
        LocationGraphState,
        'currentPersonAssetId' | 'assetRankingSummaries' | 'assetRankingEvents' | 'groupedAssetRankingEvents'
      >
    ) => {
      return {
        ...state,
        ...params
      };
    },

    CLEAR: () => cleanState,

    SET_TIME_SCALE: (
      state: LocationGraphState,
      scale: TimeScale
    ) => {
      const {
        timeMiddle
      } = state;
      const timeDomainDelta = timeScaleDomainDeltaMap[scale];

      return {
        ...state,
        timeScale: scale,
        timeDomainDelta,
        startTime: timeMiddle - timeDomainDelta,
        endTime: timeMiddle + timeDomainDelta
      };
    }
  },
  effects: (dispatch: Dispatch) => ({
    setTimeScale(scale: TimeScale, rootState: RootState): void {
      const {
        locationGraph: {
          currentPersonAssetId
        }
      } = rootState;

      dispatch.locationGraph.SET_TIME_SCALE(scale);

      if (currentPersonAssetId) {
        dispatch.locationGraph.enableGraphForPerson(currentPersonAssetId);
      } else {
        dispatch.locationGraph.disableGraph();
      }
    },

    setTimeDomain(
      domainMiddle: number,
      rootState: RootState
    ): void {
      const {
        locationGraph: {
          currentPersonAssetId,
          timeDomainDelta
        }
      } = rootState;

      const domainStart = domainMiddle - timeDomainDelta;
      const domainEnd = domainMiddle + timeDomainDelta;

      dispatch.locationGraph.SET_TIME_DOMAIN({
        startTime: domainStart,
        endTime: domainEnd,
        timeMiddle: domainMiddle
      });

      if (currentPersonAssetId) {
        dispatch.locationGraph.enableGraphForPerson(currentPersonAssetId);
      }
    },

    disableGraph(_: void, state: RootState): void {
      const {
        rankingSettings: {
          isTimeTravelActive,
          timeTravelTargetEpoch
        }
      } = state;

      dispatch.drawer.closeBottomDrawer();
      dispatch.locationGraph.CLEAR();

      // Apply time travel to chart domain if time travel is active
      if (isTimeTravelActive) {
        dispatch.locationGraph.setTimeDomain(timeTravelTargetEpoch);
      }
    },

    async enableGraphForPerson(assetId: string, state: RootState): Promise<void> {
      const {
        locationGraph: {
          startTime,
          endTime
        },
        peopleLocations: {
          geoData
        }
      } = state;

      const personFeature = geoData.features.find((f) => f.properties.personId === assetId);
      const fullName = personFeature?.properties?.personFullName ?? '';

      dispatch.locationGraph.SET_CURRENT_PERSON({
        assetId,
        personFullName: fullName
      });
      dispatch.drawer.openBottomDrawer('location-graph');
      const precisionInterval = 60;

      const locationRanks = await getLocationsForPersonInTimeframe(
        assetId,
        startTime,
        endTime,
        RankingOption.allRanks,
        precisionInterval
      );

      const locationRanksWithIds = locationRanks.map(prepareEvent);

      const rankingEvents: AssetRankingEvent[] = locationRanksWithIds.flatMap(({
        topRank,
        otherRanks = [],
        rankTime,
        personId
      }) => [
        { ...topRank, rankTime, assetId: personId },
        ...otherRanks.map(
          (rank) => ({ ...rank, rankTime, personId: personId })
        )
      ]);

      const groupedAssetRankingEvents = groupAssetRankingEvents(rankingEvents);

      dispatch.locationGraph.SAVE_RANKS({
        currentPersonAssetId: assetId,
        assetRankingSummaries: locationRanksWithIds,
        assetRankingEvents: rankingEvents,
        groupedAssetRankingEvents
      });
    }
  })
};

export const locationGraph = createModel<RootModel>()(
  locationGraphModel
);
