import { FileWithPath } from "@mantine/dropzone";
import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { handleError } from "@/common/util/common-functions";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { PaginatedResult } from "@/tenant-context/control-profile/types/profile";
import { formatSortingParameters } from "@/tenant-context/control-tenant-admin/util/formatSortingParameters";

import {
  addProfilesToPrintJob,
  deletePrintJob,
  deleteTenantAccessCard,
  deleteTenantAccessCardLayout,
  getAccessCardLayout, getPrintJobAddedProfiles,
  getPrintJobDetails,
  getPrintJobsListData,
  getPrintJobToAddProfiles,
  getTenantAccessCardData,
  getTenantAccessCardHistoryData,
  getTenantAccessCardItem,
  getTenantAccessCardLayoutList,
  getVisitorPrintJobsData,
  reassignTenantAccessCard, removeProfileFromPrintJob,
  saveAccessCardLayout,
  savePrintJobDetails,
  setVisitorPrintJobCount,
  toggleTenantAccessCardActivation,
  updateAccessCardLayout,
  updatePrintJobDetails,
  updatePrintJobStatus,
  updateTenantAccessCard,
  uploadAccessCardsFile
} from "../api/tenantAccessCard";
import {
  AccessCardLayoutParams,
  IVisitorPrintJobsList,
  PrintJobAddEmployees,
  PrintJobDetailsParams,
  PrintJobsList, PrintJobStatusParams,
  TenantAccessCard,
  TenantAccessCardHistory,
  TenantAccessCardLayoutListItem
} from "../types/tenantAccessCard";

type TenantAccessCardState = {
    tenantAccessCardsData?: PaginatedResult<TenantAccessCard>,
    tenantAccessCardHistoryData?: PaginatedResult<TenantAccessCardHistory>,
    selectedTenantAccessCardItem?: TenantAccessCard,
    uploadAccessCardsPercentage?: number,
    uploadAccessCardsFileUploadingStatus?: 'in-progress' | 'successful' | 'failed',
    uploadAccessCardsFailedMessage?: string,
    uploadAccessCardsSuccessMessage?: string,
    isRecordItemLoading: boolean
  tenantAccessCardsLayoutsList?: PaginatedResult<TenantAccessCardLayoutListItem>,
  visitorPrintJobsList?: PaginatedResult<IVisitorPrintJobsList>,
  selectedAccessCardLayout?: AccessCardLayoutParams,
  selectedPrintJob?: PrintJobDetailsParams
  printJobAddEmployeesData?: PaginatedResult<PrintJobAddEmployees>
  isProfilesAdded: boolean
};

const tenantAccessCardsDataModel = {
  name: 'tenantAccessCard',
  state: {
    tenantAccessCardsData: undefined,
    tenantAccessCardHistoryData: undefined,
    selectedTenantAccessCardItem: undefined,
    uploadAccessCardsFailedMessage: undefined,
    uploadAccessCardsSuccessMessage: undefined,
    uploadAccessCardsFileUploadingStatus: undefined,
    uploadAccessCardsPercentage: undefined,
    isRecordItemLoading: false,
    tenantAccessCardsLayoutsList: undefined,
    selectedAccessCardLayout: undefined,
    visitorPrintJobsList: undefined,
    selectedPrintJob: undefined,
    isProfilesAdded: false
  } as TenantAccessCardState,
  reducers: {
    SET_TENANT_ACCESS_CARD: (state: TenantAccessCardState, tenantAccessCardsData: TenantAccessCardState['tenantAccessCardsData']) => ({
      ...state,
      tenantAccessCardsData
    }),
    SET_TENANT_ACCESS_CARD_HISTORY: (state: TenantAccessCardState, tenantAccessCardHistoryData: TenantAccessCardState['tenantAccessCardHistoryData']) => ({
      ...state,
      tenantAccessCardHistoryData
    }),
    SET_SELECTED_TENANT_ACCESS_CARD_ITEM: (state: TenantAccessCardState, selectedTenantAccessCardItem: TenantAccessCardState['selectedTenantAccessCardItem']) => ({
      ...state,
      selectedTenantAccessCardItem
    }),
    RESET_SELECTED_TENANT_ACCESS_CARD_ITEM: (state: TenantAccessCardState) => ({
      ...state,
      selectedTenantAccessCardItem: undefined
    }),
    SET_UPLOAD_ACCESS_CARDS_PERCENTAGE: (state: TenantAccessCardState, uploadAccessCardsPercentage: TenantAccessCardState['uploadAccessCardsPercentage']) => ({
      ...state,
      uploadAccessCardsPercentage
    }),
    SET_UPLOAD_ACCESS_CARDS_STATUS: (state: TenantAccessCardState, uploadAccessCardsFileUploadingStatus: TenantAccessCardState['uploadAccessCardsFileUploadingStatus']) => ({
      ...state,
      uploadAccessCardsFileUploadingStatus
    }),
    SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE: (state: TenantAccessCardState, uploadAccessCardsFailedMessage: TenantAccessCardState['uploadAccessCardsFailedMessage']) => ({
      ...state,
      uploadAccessCardsFailedMessage
    }),
    SET_UPLOAD_ACCESS_CARDS_SUCCESS_MESSAGE: (state: TenantAccessCardState, uploadAccessCardsSuccessMessage: TenantAccessCardState['uploadAccessCardsSuccessMessage']) => ({
      ...state,
      uploadAccessCardsSuccessMessage
    }),
    TOGGLE_LOADING_ITEM: (state: TenantAccessCardState) => ({
      ...state,
      isRecordItemLoading: !state.isRecordItemLoading
    }),
    SET_TENANT_ACCESS_CARDS_LAYOUT_LIST: (state: TenantAccessCardState, tenantAccessCardsLayoutsList: TenantAccessCardState['tenantAccessCardsLayoutsList']) => ({
      ...state,
      tenantAccessCardsLayoutsList
    }),
    SET_SELECTED_ACCESS_CARD_LAYOUT: (state: TenantAccessCardState, selectedAccessCardLayout: TenantAccessCardState['selectedAccessCardLayout']) => ({
      ...state,
      selectedAccessCardLayout
    }),
    SET_VISITOR_PRINT_JOBS_LIST: (state: TenantAccessCardState, visitorPrintJobsList: TenantAccessCardState['visitorPrintJobsList']) => ({
      ...state,
      visitorPrintJobsList
    }),
    SET_SELECTED_PRINT_JOB: (state: TenantAccessCardState, selectedPrintJob: TenantAccessCardState['selectedPrintJob']) => ({
      ...state,
      selectedPrintJob
    }),
    CLEAR_SELECTED_PRINT_JOB: (state: TenantAccessCardState) => ({
      ...state,
      selectedPrintJob: undefined
    }),
    SET_PRINT_JOB_ADD_EMPLOYEE_DATA: (state: TenantAccessCardState, printJobAddEmployeesData: TenantAccessCardState['printJobAddEmployeesData']) => ({
      ...state,
      printJobAddEmployeesData
    }),
    TOGGLE_IS_PROFILE_ADDED: (state: TenantAccessCardState) => ({
      ...state,
      isProfilesAdded: !state.isProfilesAdded
    })
  },
  effects: (dispatch: Dispatch) => ({
    async getTenantAccessCardsData(payload: {
      gridParams: GridParams,
  }, _state: RootState): Promise<PaginatedResult<TenantAccessCard>> {

      const { searchQuery, additionalParams } = payload.gridParams;
      if(searchQuery){
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `manufactureId LIKE %27%25${searchQuery}%25%27 OR accessCardType LIKE %27%25${searchQuery}%25%27 OR friendlyName LIKE %27%25${searchQuery}%25%27 OR secondaryId LIKE %27%25${searchQuery}%25%27`;
      }

      if(typeof additionalParams === 'object'){
        // eslint-disable-next-line fp/no-let
        let additionalFilterQuery = "";

        Object.keys(additionalParams).forEach(function(key, index) {
          if(additionalParams[key]){
            if(index !== 0 && additionalFilterQuery){
              additionalFilterQuery += " AND ";
            }

            if(key === "serviceConfiguration"){
              additionalFilterQuery += `accessProviderTid='${additionalParams[key]}'`;
            } else if (key === "isAssignedToProfile"){
              additionalFilterQuery += `${key}=${additionalParams[key]}`;
            } else{
              additionalFilterQuery += `${key} LIKE %27%25${additionalParams[key]}%25%27`;
            }
          }
        });

        if(additionalFilterQuery){
          // eslint-disable-next-line no-param-reassign
          payload.gridParams.searchQuery = payload.gridParams.searchQuery ?
            payload.gridParams.searchQuery + additionalFilterQuery : additionalFilterQuery;
        }
      }

      const tenantAccessCardsData = await getTenantAccessCardData(
        payload.gridParams.page,
        payload.gridParams.size,
        formatSortingParameters(payload.gridParams.sort),
        payload.gridParams.searchQuery
      );

      dispatch.tenantAccessCard.SET_TENANT_ACCESS_CARD(tenantAccessCardsData);
      return tenantAccessCardsData;
    },
    async getAccessCardHistoryData(payload: {
      gridParams: GridParams,
  }, state: RootState): Promise<PaginatedResult<TenantAccessCardHistory>> {

      const query = payload.gridParams.searchQuery;
      if(query){
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `friendlyName LIKE %27%25${query}%25%27 OR changedBy LIKE %27%25${query}%25%27 OR eventType LIKE %27%25${query}%25%27 OR status LIKE %27%25${query}%25%27`;
      }
      const tenantAccessCardHistoryData = await getTenantAccessCardHistoryData(
        payload.gridParams.page,
        payload.gridParams.size,
        state.commonData.tenantId,
        state.tenantAccessCard.selectedTenantAccessCardItem?._id || "",
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.tenantAccessCard.SET_TENANT_ACCESS_CARD_HISTORY(tenantAccessCardHistoryData);
      return tenantAccessCardHistoryData;
    },
    async getTenantAccessCardItem(accessCardId: string, _state: RootState)
    : Promise<void> {

      dispatch.tenantAccessCard.TOGGLE_LOADING_ITEM();
      const tenantAccessCardItem = await
      getTenantAccessCardItem(accessCardId).finally(dispatch.tenantAccessCard.TOGGLE_LOADING_ITEM);

      dispatch.tenantAccessCard.SET_SELECTED_TENANT_ACCESS_CARD_ITEM(tenantAccessCardItem);
    },
    async updateTenantAccessCard(params: TenantAccessCard, _state: RootState)
    : Promise<boolean> {

      const result = await updateTenantAccessCard(params).catch(handleError);

      return !!result;
    },
    resetUploadAccessCardsModal(): void {
      dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_PERCENTAGE(undefined);
      dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_STATUS(undefined);
      dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE(undefined);
    },
    async uploadAccessCardsFile(params: {file: FileWithPath, body: string}, _state: RootState): Promise<void> {
      const formData = new FormData();
      formData.append('files', params.file);
      formData.append('body', params.body);

      dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_PERCENTAGE(50);
      try {
        const upload = await uploadAccessCardsFile(formData);

        if (upload) {
          if(upload.uploadedCount === 0){
            dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_STATUS('failed');
            dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE(`Failed Cards: ${upload.failedCount}. ${upload.generalMessage}`);
          } else{
            dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_STATUS('successful');
            dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_SUCCESS_MESSAGE(`Uploaded Cards: ${upload.uploadedCount}. Failed Cards: ${upload.failedCount}. ${upload.generalMessage}`);
          }
        } else {
          dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_STATUS('failed');
          dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE('An error occurred while uploading the file.');
        }
      } catch (e) {
        dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_STATUS('failed');
        dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE('An error occurred while uploading the file.');
        if (e instanceof Error) {
          dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_FAILED_MESSAGE(e?.message ?? 'An error occurred while uploading the file.');
        }
      }
      dispatch.tenantAccessCard.SET_UPLOAD_ACCESS_CARDS_PERCENTAGE(100);
    },
    async deleteTenantAccessCard(accessCardId: string, _state: RootState): Promise<boolean> {
      const result = await deleteTenantAccessCard(accessCardId).catch(handleError);
      return !!result;
    },

    async toggleTenantAccessCardActivation(activate: boolean, state: RootState):
     Promise<boolean> {
      // eslint-disable-next-line fp/no-let
      let result = true;
      if(state.tenantAccessCard.selectedTenantAccessCardItem?._id){
        const response = await toggleTenantAccessCardActivation(
          state.tenantAccessCard.selectedTenantAccessCardItem?._id || ""
          , activate
        ).catch(handleError);

        if(response){
          dispatch.tenantAccessCard.getTenantAccessCardItem(state.tenantAccessCard.selectedTenantAccessCardItem?._id);
        }
        result = !!response;
      }

      return result;
    },
    async reassignTenantAccessCard(_: void, state: RootState):
     Promise<boolean> {

      // eslint-disable-next-line fp/no-let
      let result = true;
      if(state.tenantAccessCard.selectedTenantAccessCardItem?._id){
        const response = await reassignTenantAccessCard(state.tenantAccessCard.selectedTenantAccessCardItem._id)
          .catch(handleError);

        if(response){
          dispatch.tenantAccessCard.getTenantAccessCardItem(state.tenantAccessCard.selectedTenantAccessCardItem?._id);
        }
        result = !!response;
      }

      return !!result;
    },

    async getTenantAccessCardsLayoutList(payload: {
      gridParams: GridParams,
    }, _state: RootState): Promise<PaginatedResult<TenantAccessCardLayoutListItem>> {

      const { searchQuery, additionalParams } = payload.gridParams;
      if (searchQuery) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `name LIKE %27%25${searchQuery}%25%27 OR accessCardType LIKE %27%25${searchQuery}%25%27 OR activeStatus LIKE %27%25${searchQuery}%25%27`;
      }
      if (typeof additionalParams === 'object') {
        // eslint-disable-next-line fp/no-let
        let additionalFilterQuery = "";

        Object.keys(additionalParams).forEach(function (key, index) {
          if (additionalParams[key]) {
            if (index !== 0 && additionalFilterQuery) {
              additionalFilterQuery += " AND ";
            } else {
              additionalFilterQuery += `${key} LIKE %27%25${additionalParams[key]}%25%27`;
            }
          }
        });

        if (additionalFilterQuery) {
          // eslint-disable-next-line no-param-reassign
          payload.gridParams.searchQuery = payload.gridParams.searchQuery ?
            payload.gridParams.searchQuery + additionalFilterQuery : additionalFilterQuery;
        }
      }

      dispatch.tenantAccessCard.SET_SELECTED_ACCESS_CARD_LAYOUT(undefined);

      const tenantAccessCardsLayoutList = await getTenantAccessCardLayoutList(
        payload.gridParams.page,
        payload.gridParams.size,
        formatSortingParameters(payload.gridParams.sort),
        payload.gridParams.searchQuery
      );

      dispatch.tenantAccessCard.SET_TENANT_ACCESS_CARDS_LAYOUT_LIST(tenantAccessCardsLayoutList);
      return tenantAccessCardsLayoutList;
    },

    async deleteTenantAccessCardLayout(accessCardLayoutId: string, _state: RootState): Promise<boolean> {
      const result = await deleteTenantAccessCardLayout(accessCardLayoutId).catch(handleError);
      return !!result;
    },

    async saveAccessCardLayout(fieldVals: AccessCardLayoutParams, state: RootState):
    Promise<string> {
      const {
        tenantAccessCard: {
          selectedAccessCardLayout
        }
      } = state;

      const tid = selectedAccessCardLayout?.tid;

      // eslint-disable-next-line fp/no-let
      let savedId = "";
      const result = tid ? await updateAccessCardLayout(fieldVals, tid).catch(handleError)
        : await saveAccessCardLayout(fieldVals).catch(handleError);

      if (result) {
        dispatch.tenantAccessCard.SET_SELECTED_ACCESS_CARD_LAYOUT(result);
        savedId = result.tid || "";
      }

      return savedId;
    },

    async getAccessCardLayoutById(id: string, _state: RootState): Promise<void> {
      dispatch.tenantAccessCard.TOGGLE_LOADING_ITEM();
      getAccessCardLayout(id)
        .then((result) => dispatch.tenantAccessCard.SET_SELECTED_ACCESS_CARD_LAYOUT(result))
        .catch(handleError)
        .finally(() => dispatch.tenantAccessCard.TOGGLE_LOADING_ITEM());
    },

    async getVisitorPrintJobsData(payload: {
      gridParams: GridParams,
    }, state: RootState): Promise<PaginatedResult<IVisitorPrintJobsList>> {

      const { searchQuery } = payload.gridParams;
      if (searchQuery) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `sequenceId LIKE %27%25${searchQuery}%25%27 OR visitorId LIKE %27%25${searchQuery}%25%27 OR status LIKE %27%25${searchQuery}%25%27`;
      }

      const visitorPrintJobsList = await getVisitorPrintJobsData(
        state.tenantAccessCard.selectedPrintJob?.tid || "",
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.tenantAccessCard.SET_VISITOR_PRINT_JOBS_LIST(visitorPrintJobsList);
      return visitorPrintJobsList;
    },

    async getPrintJobsData(payload: {
      gridParams: GridParams,
    }, _state: RootState): Promise<PaginatedResult<PrintJobsList>> {

      const { searchQuery, additionalParams } = payload.gridParams;
      if (searchQuery) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `name LIKE %27%25${searchQuery}%25%27 OR status LIKE %27%25${searchQuery}%25%27 OR accessCardType LIKE %27%25${searchQuery}%25%27`;
      }
      if (typeof additionalParams === 'object') {
        // eslint-disable-next-line fp/no-let
        let additionalFilterQuery = "";

        Object.keys(additionalParams).forEach(function (key, index) {
          if (additionalParams[key]) {
            if (index !== 0 && additionalFilterQuery) {
              additionalFilterQuery += " AND ";
            } else {
              additionalFilterQuery += `${key} LIKE %27%25${additionalParams[key]}%25%27`;
            }
          }
        });

        if (additionalFilterQuery) {
          // eslint-disable-next-line no-param-reassign
          payload.gridParams.searchQuery = payload.gridParams.searchQuery ?
            payload.gridParams.searchQuery + additionalFilterQuery : additionalFilterQuery;
        }
      }

      const printJobsList = await getPrintJobsListData(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      return printJobsList;
    },

    async savePrintJobDetails(fieldVals: PrintJobDetailsParams, state: RootState):
    Promise<string> {
      const {
        tenantAccessCard: {
          selectedPrintJob
        }
      } = state;

      const tid = selectedPrintJob?.tid;

      // eslint-disable-next-line fp/no-let
      let savedId = "";
      const result = tid ? await updatePrintJobDetails(fieldVals, tid).catch(handleError)
        : await savePrintJobDetails(fieldVals).catch(handleError);
 
      if (result) {
        savedId = result.tid || "";
      }
 
      return savedId;
    },
    async setVisitorPrintJobCount(noOfCards: number, state: RootState):
    Promise<boolean> {
      const {
        tenantAccessCard: {
          selectedPrintJob
        }
      } = state;

      const result = await setVisitorPrintJobCount(noOfCards, selectedPrintJob?.tid || "").catch(handleError);
 
      return !!result;
    },

    async getPrintJobDetails(id: string, _state: RootState): Promise<void> {
      const printJob = await getPrintJobDetails(id);
      dispatch.tenantAccessCard.SET_SELECTED_PRINT_JOB(printJob);
    },

    async deletePrintJob(printJobId: string, _state: RootState): Promise<boolean> {
      const result = await deletePrintJob(printJobId).catch(handleError);
      return !!result;
    },

    async getPrintJobToAddProfilesData(payload: {
      gridParams: GridParams,
    }, state: RootState): Promise<PaginatedResult<PrintJobAddEmployees>> {

      const {
        tenantAccessCard: {
          selectedPrintJob
        }
      } = state;

      const { searchQuery } = payload.gridParams;
      if (searchQuery) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `name LIKE %27%25${searchQuery}%25%27 OR email LIKE %27%25${searchQuery}%25%27 OR firstName LIKE %27%25${searchQuery}%25%27 OR lastName LIKE %27%25${searchQuery}%25%27 OR profileId LIKE %27%25${searchQuery}%25%27`;
      }

      const data = await getPrintJobToAddProfiles(
        payload.gridParams.page,
        payload.gridParams.size,
        selectedPrintJob?.accessControlIntegrationTid,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );

      dispatch.tenantAccessCard.SET_PRINT_JOB_ADD_EMPLOYEE_DATA(data);

      return data;
    },

    async addProfilesToPrintJob(profilesTid: string[], state: RootState):
      Promise<void> {
      const {
        tenantAccessCard: {
          selectedPrintJob
        }
      } = state;

      const tid = selectedPrintJob?.tid;
      if (!tid) {
        return;
      }
      await addProfilesToPrintJob(profilesTid, tid).catch(handleError);

      dispatch.tenantAccessCard.TOGGLE_IS_PROFILE_ADDED();
    },

    async getPrintJobToAddedProfilesData(payload: {
      gridParams: GridParams,
    }, state: RootState): Promise<PaginatedResult<PrintJobAddEmployees>> {
      const {
        tenantAccessCard: {
          selectedPrintJob
        }
      } = state;

      const tid = selectedPrintJob?.tid;

      const { searchQuery } = payload.gridParams;
      if (searchQuery) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `personName LIKE %27%25${searchQuery}%25%27 OR email LIKE %27%25${searchQuery}%25%27 OR profileId LIKE %27%25${searchQuery}%25%27 OR employeeLineItemTid LIKE %27%25${searchQuery}%25%27 OR profileId LIKE %27%25${searchQuery}%25%27`;
      }

      return await getPrintJobAddedProfiles(
        payload.gridParams.page,
        payload.gridParams.size,
        tid,
        payload.gridParams.sort,
        payload.gridParams.searchQuery
      );
    },

    async removeProfileFromPrintJob(profileTid: string, state: RootState): Promise<boolean> {
      const {
        tenantAccessCard: {
          selectedPrintJob
        }
      } = state;

      const tid = selectedPrintJob?.tid;

      if (!tid) {
        return false;
      }

      const result = await removeProfileFromPrintJob(profileTid, tid).catch(handleError);

      return !!result;
    },

    async updatePrintJobStatus(payload: {
      printJobId: string,
      status: PrintJobStatusParams
    }, _state: RootState):
      Promise<boolean> {
      const result = await updatePrintJobStatus(payload.printJobId, payload.status).catch(handleError);
      return !!result;
    }
  })
};

export const tenantAccessCard = createModel<RootModel>()(tenantAccessCardsDataModel);
