import { callApi } from "@/common/util/api";
import { PersonTravelItenerary } from "@/tenant-context/visualisation-people/types/people.types";

import { MiniProfileDatails, ReverseGeoLocation } from "../types/mini-profile";

export const getMiniProfileDetails = (personId: string) => callApi<MiniProfileDatails>(
  `managed/platform-service/v1/person-profiles/${personId}?include_binary_data=false`,
  {
    headers: {
      'Content-Type': 'application/json;domain-model=GetMiniProfile'
    },
    method: 'get',
    withTenantId: false
  }
);

export const getReverseGeoLocation = (lon: string, lat: string) => callApi<ReverseGeoLocation>(
  `map-service/v1/get-reverse-geocoding?latitude=${lat}&longitude=${lon}`,
  {
    method: 'get'
  }
);

export const getPersonTravelItenerary = (adapterEventId: string) => callApi<PersonTravelItenerary>(
  `travel-service/v1/travel-books/itinerary/${adapterEventId}`,
  {
    method: 'GET'
  }
);
