import { Divider } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as RiskLine } from "@/common/icons/riskline.svg";
import { getTimeDurationFromCurrentDate } from "@/common/util/format/date";
import CountryFlag from "@/tenant-context/common/components/CountryFlag";
import RiskAlertBasicData from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertBasicData";
import {
  RiskAlert, RiskAlertEvent,
  RiskAlertEventMeta, RiskAlertExternalData,
  RiskAlertJsonMeta
} from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type Props = {
  classes: Record<string, string>,
  meta: RiskAlertEventMeta,
  json: { meta: RiskAlertJsonMeta; alert: RiskAlert; externalData?: RiskAlertExternalData },
  riskAlertData: RiskAlertEvent<RiskAlertExternalData>,
  isImpacted: boolean
}

export const RisklinePopupContent: FC<Props> = ({
  classes,
  meta,
  json,
  riskAlertData,
  isImpacted: _isImpacted
}) => {
  return <>
    <div className={ classes.popupContainer }>
      <div className={ classes.timesAgoText }>
        { (meta.createdTime > 0)
          ? getTimeDurationFromCurrentDate(new Date(meta.createdTime))
          : "" }
      </div>
      <div className={ classes.popupHeader }>
        { json.alert.countries[0].iso_code && (
          <CountryFlag
            width={ 65 }
            height={ 50 }
            countryCode={ json.alert.countries[0].iso_code }
          />
        ) }
        { json.alert.location_explanation.split(",")[0] },
        { json.alert.countries[0].name }
      </div>
      <div className={ classes.riskAlertTitle }> { json.alert.title }</div>
      <RiskAlertBasicData riskAlertData={ riskAlertData }/>

      <Divider
        className={ classes.divider }
      />
      <div className={ classes.riskAlertProvider }>
        <div className={ classes.issuedBy }>Issued by</div>
        <RiskLine className={ classes.riskAlerProviderIcon }/>
        <div className={ classes.riskAlertProviderName }> Riskline Risk</div>
      </div>
    </div>

    { /*{ isImpacted &&*/ }

    { /*  <div className={ classes.responseStatusContainer }>*/ }
    { /*    <div className={ classes.responseBox }>*/ }
    { /*      <div className={ classes.responseProgress }> Response in progress</div>*/ }
    { /*      <div className={ classes.responseMeta }>*/ }
    { /*        <div>*/ }
    { /*          <div className={ classes.responseMetaTxt }>Last Modified :</div>*/ }
    { /*          <div className={ classes.responseMetaValue }>*/ }
    { /*            { meta.updatedTime !== undefined && meta.updatedTime > 0*/ }
    { /*              ? formatDate(new Date(meta.updatedTime))*/ }
    { /*              : formatDate(new Date()) }*/ }
    { /*          </div>*/ }
    { /*        </div>*/ }
    { /*        <div>*/ }
    { /*          <div className={ classes.responseMetaTxt }>Case ID</div>*/ }
    { /*          <div className={ classes.responseMetaValue }>*/ }
    { /*            uiJ23bnXC*/ }
    { /*          </div>*/ }
    { /*        </div>*/ }
    { /*      </div>*/ }

    { /*    </div>*/ }
    { /*  </div> }*/ }
  </>;
};
