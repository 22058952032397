import { createStyles } from "@mantine/core";

import { CSS_KEY } from '@/core/styles/mantine/constants';

type Params = {
  progressColor: string;
}

export const useCountryRiskStatusCardStyles = createStyles((theme, { progressColor }: Params) =>({
  cardRoot: {
    minWidth: 336,
    minHeight: 316,
    backgroundColor: theme.colors.neutral[8],
    borderRadius: 24,
    padding: 20,
    gap: 12,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  flag: {
    borderRadius: 2,
    overflow: 'hidden',
    height: 16,
    'svg': {
      margin: 0,
      width: 'unset !important'
    }
  },
  mapImage: {
    borderRadius: 12,
    height: 162,
    objectFit: 'cover'
  },
  progressBarContainer: {
    width: '100%',
    borderRadius: 12,
    backgroundColor: `${theme.colors.white[0]}05`,
    padding: theme.spacing.sm,
    gap: theme.spacing.xs
  },
  progressBar: {
    width: '100%',
    backgroundColor: `${theme.colors.neutral[6]}33`,
    [`.${CSS_KEY}-Progress-bar`]: {
      backgroundColor: progressColor
    }
  },
  countryStatusBadge: {
    borderRadius: 8,
    padding: 8,
    backgroundColor: `${theme.colors.neutral[6]}33`
  },
  dimText: {
    fontSize: 8,
    fontWeight: 400,
    color: theme.colors.neutral[0]
  },
  lightText: {
    fontSize: 8,
    fontWeight: 700,
    color: theme.colors.neutral[0]
  },
  statusBadge: {
    height: 24,
    padding: '0px 4px'
  },
  rating:{
    [`.${CSS_KEY}-Rating-input[data-active="true"]`]: {
      [`.${CSS_KEY}-Rating-label`]: {
        svg: {
          path: {
            fill: theme.colors.teal[5],
            stroke: theme.colors.teal[5]
          }
        }
      }
    }
  }
}));
