import { DrawerState } from "@/core/store/drawer/drawer.state";

export const drawerReducers = {
  SET_CURRENTLY_OPEN_RIGHT_DRAWER(state: DrawerState, payload: string) {
    return {
      ...state,
      currentlyOpenRightDrawerId: payload
    };
  },

  SET_CURRENTLY_OPEN_BOTTOM_DRAWER(state: DrawerState, payload: string) {
    return {
      ...state,
      currentlyOpenBottomDrawerId: payload
    };
  },

  SET_CURRENTLY_OPEN_LEFT_DRAWER(state: DrawerState, payload: string) {
    return {
      ...state,
      currentlyOpenLeftDrawerId: payload
    };
  },

  CLOSE_CURRENTLY_OPEN_RIGHT_DRAWER(state: DrawerState) {
    return {
      ...state,
      currentlyOpenRightDrawerId: undefined
    };
  },

  CLOSE_CURRENTLY_OPEN_BOTTOM_DRAWER(state: DrawerState) {
    return {
      ...state,
      currentlyOpenBottomDrawerId: undefined
    };
  },

  CLOSE_CURRENTLY_OPEN_LEFT_DRAWER(state: DrawerState) {
    return {
      ...state,
      currentlyOpenLeftDrawerId: undefined
    };
  },

  CLOSE_ALL_DRAWERS(state: DrawerState) {
    return {
      ...state,
      currentlyOpenRightDrawerId: undefined,
      currentlyOpenBottomDrawerId: undefined,
      currentlyOpenLeftDrawerId: undefined
    };
  }
};
