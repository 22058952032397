import { Divider } from "@mantine/core";
import { FC, useCallback } from "react";

import PersonAvatar from "@/common/components/PersonAvatar";
import { ReactComponent as Arrow } from '@/common/icons/Arrows/right-arrow.svg';
import { ReactComponent as ChevronUp } from "@/common/icons/chevron-up-solid.svg";
import CountryFlag from "@/tenant-context/common/components/CountryFlag/CountryFlag.component";
import { MiniProfileDatails } from "@/tenant-context/control-profile/types/mini-profile";

import { useTravelItineraryItemStyles } from "./TravelItineraryItem.styles";

type Props = {
    correlationId: string,
    profileData?: Partial<MiniProfileDatails>;
    origin: {
      isoCountryCode: string;
      airportCode: string;
    };
    destination: {
      isoCountryCode: string;
      airportCode: string;
    };
  onClick?: (profileId: string, correlationId: string) => void;
};

const TravelItineraryItem: FC<Props> = ({ profileData, origin, destination, onClick, correlationId }) => {

  const { classes } = useTravelItineraryItemStyles();
  const profileName = [
    profileData?.title,
    profileData?.firstName,
    profileData?.lastName
  ].filter(Boolean).join(" ");

  const handleClick = useCallback(() => {
    if (onClick && profileData?.profileId) {
      onClick(profileData?.profileId, correlationId);
    }
  }, [correlationId, onClick, profileData?.profileId]);

  return (
    <div className={ classes.container } >
      <div className={ classes.header }>
        <ChevronUp className={ classes.dropdownArrowIcon } onClick={ handleClick }/>
        <PersonAvatar
          width="35px"
          height="35px"
          borderRadius="100%"
          personName={ profileName }
          personAvatarUrl={ profileData?.profilePictureThumbnailUrl }
        />
        <div>
          <div className={ classes.profileName }> { profileName } </div>
          <div className={ classes.position }>  { profileData?.jobTitle }</div>
          <div className={ classes.companyName }> { profileData?.company } </div>
        </div>
      </div>
      <Divider orientation="horizontal" className={ classes.divider } />
      <div className={ classes.body }>

        <div className={ classes.countryCollapsed }>
          <CountryFlag countryCode={ origin.isoCountryCode }/>
          { origin.airportCode }
        </div>
        <Arrow />
        <div className={ classes.countryCollapsed }>
          <CountryFlag countryCode={ destination.isoCountryCode }/>
          { destination.airportCode }
        </div>

      </div>
    </div>
  );
};

export default TravelItineraryItem;
