import { Tooltip } from "@mantine/core";
import { FC, ReactNode } from "react";

import { useButtonControlStyles } from "@/common/components/ButtonControl/ButtonControl.styles";

type Props = {
  children: ReactNode,
  className?: string,
  onClick?: () => void,
  label?: string,
  labelPosition?: 'left' | 'right',
  isUnauthorized?: boolean
  disabled?: boolean
  tooltip?: string
}

const ButtonControl: FC<Props> = ({
  children,
  onClick,
  className = '',
  label = '',
  labelPosition = 'left',
  isUnauthorized,
  disabled,
  tooltip
}) => {
  const { classes, cx } = useButtonControlStyles({ isLabelIncluded: label !== '', isUnauthorized: isUnauthorized || false });

  return (
    <Tooltip
      hidden={ !disabled }
      label={ tooltip }
      withArrow
      position="top"
    >
      <button
        className={ cx(
          className,
          "mapboxgl-ctrl-group",
          "mapboxgl-ctrl",
          "mapboxgl-custom-ctrl",
          classes.wrapper,
          classes.btn,
          labelPosition === "right" && "rightLabel"
        ) }
        data-label={ label }
        onClick={ onClick }
        disabled={ disabled }
      >
        { children }
      </button>
    </Tooltip>
  );
};

export default ButtonControl;
