import { FeatureCollection, LineString } from "geojson";
import { FC } from "react";
import { MapRef } from "react-map-gl";

import FilterableSource from "@/common/components/FilterableSource";
import PopupLine from "@/common/components/PopupLine";
import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import DynamicallyVisibleLayer from "@/tenant-context/common/components/DynamicallyVisibleLayer";
import {
  peopleAlarmMarkerModifierLayers,
  peopleMarkerModifierLayers,
  PERSON_RED_ALERT_STATUSES,
  PERSON_YELLOW_ALERT_STATUSES
} from "@/tenant-context/visualisation-people/layers/PeopleLocationLayer/PeopleLocationLayer.config";

type Props = {
  geoData: FeatureCollection,
  alarmGeoData?: FeatureCollection,
  disabledAdapters: string[],
  minRankThreshold: number,
  maxRankThreshold: number,
  isMinRankThresholdApplied: boolean,
  lineToPopup?: FeatureCollection<LineString>,
  isArcModeOn: boolean,
  mapRef?: MapRef | undefined
}

const PeopleLocationLayerComponent: FC<Props> = ({
  geoData,
  alarmGeoData,
  disabledAdapters,
  minRankThreshold,
  maxRankThreshold,
  isMinRankThresholdApplied,
  lineToPopup,
  isArcModeOn,
  mapRef
}) => {

  return (
    <>
      <FilterableSource
        id="r__peopleLocationSource"
        type="geojson"
        tolerance={ 0 }
        cluster={ true }
        clusterRadius={ 50 }
        clusterMaxZoom={ 22 }
        data={ geoData }
        filter={ isArcModeOn
          ? ['literal', true]
          : [
            'all',
            // Filter by enabled adapters
            ['!', [
              'in',
              ['get', 'adapterSource', ['get', 'topRank']],
              ['literal', disabledAdapters]
            ]],
            // Filter by min rank
            isMinRankThresholdApplied
              ? ['all',
                [
                  '>=',
                  ['get', 'currentRank', ['get', 'topRank']],
                  ['literal', minRankThreshold]
                ],
                [
                  '<=',
                  ['get', 'currentRank', ['get', 'topRank']],
                  ['literal', maxRankThreshold]
                ]
              ]
              : ['literal', true]
          ] }
      >
        <DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.People }
          id={ `r__peopleLocationSnapshotLayer-cluster` }
          type={ 'symbol' }
          filter={ ['has', 'point_count'] }
          paint={ {
            "text-color": '#0085FF'
          } }
          layout={ {
            // TODO make this a circle
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'icon-image': 'people-cluster',
            'icon-size': 0.25,
            'text-font': ['Arial Unicode MS Bold'],
            'text-field': '{point_count_abbreviated}',
            'text-size': 12,
            // eslint-disable-next-line no-magic-numbers
            'text-offset': [0, 0.3],
            'text-anchor': 'center'
          } }
          source="r__peopleLocationSource"
        />

        <DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.People }
          id={ 'r__peopleLocationSnapshotLayer' }
          filter={ ['!', ['has', 'point_count']] }
          type="symbol"
          paint={ {
            "text-color": '#0085FF'
          } }
          layout={ {
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'icon-image': [
              'match',
              ['get', 'status', [
                // Support not having loneWorker in topRank
                'coalesce',
                ['get', 'loneWorker',
                  // Support not having topRank in entity
                  ['coalesce',
                    ['get', 'topRank'],
                    ['literal', { loneWorker: { status: null } }]
                  ]
                ],
                ['literal', { status: null }]
              ]],
              PERSON_YELLOW_ALERT_STATUSES,
              'person-marker-caution-yellow',
              PERSON_RED_ALERT_STATUSES,
              'person-marker-caution-red',
              ['literal', 'person-marker']
            ],
            'icon-offset': [0, -7],
            'text-field': [
              'upcase',
              [
                'get',
                'personInitials'
              ]
            ],
            'text-size': 12,
            'text-font': ['Arial Unicode MS Bold'],
            'text-offset': [0, -1.2],
            'text-anchor': 'center'
            // 'icon-size': 0.25
          } }
          interactive
          source="r__peopleLocationSource"
        />

        { peopleMarkerModifierLayers.map((layer) => (
          <DynamicallyVisibleLayer
            toggleableLayerType={ ToggleableLayerType.People }
            id={ layer.id }
            key={ layer.id }
            type="symbol"
            filter={ layer.filter }
            layout={ {
              ...layer.layout,
              'icon-allow-overlap': true
            } }
          />
        )) }
      </FilterableSource>

      <FilterableSource
        id="r__peopleLocationAlarmSource"
        type="geojson"
        tolerance={ 0 }
        cluster={ false }
        data={ alarmGeoData }
        filter={ isArcModeOn
          ? ['literal', true]
          : [
            'all',
            // Filter by enabled adapters
            ['!', [
              'in',
              ['get', 'adapterSource', ['get', 'topRank']],
              ['literal', disabledAdapters]
            ]],
            // Filter by min rank
            isMinRankThresholdApplied
              ? ['all',
                [
                  '>=',
                  ['get', 'currentRank', ['get', 'topRank']],
                  ['literal', minRankThreshold]
                ],
                [
                  '<=',
                  ['get', 'currentRank', ['get', 'topRank']],
                  ['literal', maxRankThreshold]
                ]
              ]
              : ['literal', true]
          ] }
      >
        <DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.People }
          id={ `r__peopleLocationAlarmSnapshotLayer-cluster` }
          type={ 'symbol' }
          filter={ ['has', 'point_count'] }
          paint={ {
            "text-color": '#0085FF'
          } }
          layout={ {
            // TODO make this a circle
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            // TODO change to warning icon if decided to group wrnings in cluster
            'icon-image': 'people-cluster',
            'icon-size': 0.25,
            'text-font': ['Arial Unicode MS Bold'],
            'text-field': '{point_count_abbreviated}',
            'text-size': 12,
            // eslint-disable-next-line no-magic-numbers
            'text-offset': [0, 0.3],
            'text-anchor': 'center'
          } }
          source="r__peopleLocationSource"
        />
        <DynamicallyVisibleLayer
          toggleableLayerType={ ToggleableLayerType.People }
          id={ 'r__peopleLocationAlarmSnapshotLayer' }
          filter={ ['!', ['has', 'point_count']] }
          type="symbol"
          paint={ {
            "text-color": '#0085FF'
          } }
          layout={ {
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'icon-image': [
              'match',
              ['get', 'status', [
                // Support not having loneWorker in topRank
                'coalesce',
                ['get', 'loneWorker',
                  // Support not having topRank in entity
                  ['coalesce',
                    ['get', 'topRank'],
                    ['literal', { loneWorker: { status: null } }]
                  ]
                ],
                ['literal', { status: null }]
              ]],
              PERSON_YELLOW_ALERT_STATUSES,
              'person-marker-caution-yellow',
              PERSON_RED_ALERT_STATUSES,
              'person-marker-caution-red',
              ['literal', 'person-marker']
            ],
            'icon-offset': [0, 0],
            'text-field': [
              'upcase',
              [
                'get',
                'personInitials'
              ]
            ],
            'text-size': 12,
            'text-font': ['Arial Unicode MS Bold'],
            'text-offset': [0, -0.7],
            'text-anchor': 'center'
            // 'icon-size': 0.25
          } }
          interactive
          source="r__peopleLocationAlarmSource"
        />

        { peopleAlarmMarkerModifierLayers.map((layer) => {

          if (!mapRef?.getLayer(layer.beforeId)) {
            return null;
          }

          return (
            <DynamicallyVisibleLayer
              toggleableLayerType={ ToggleableLayerType.People }
              id={ layer.id }
              key={ layer.id }
              beforeId={ layer?.beforeId }
              type="symbol"
              filter={ layer.filter }
              layout={ {
                ...layer.layout,
                'icon-allow-overlap': true
              } }
            />
          );
        }) }
      </FilterableSource>

      { (lineToPopup && (
        <PopupLine
          lineToPopup={ lineToPopup }
        />
      )) }
    </>
  );
};

export default PeopleLocationLayerComponent;
