import { FeatureCollection } from 'geojson';
import { FC, useMemo } from "react";
import { Marker, MarkerDragEvent, Popup, Source } from "react-map-gl";

import {
  useGeofencePOILocationLayerStyles
} from "@/tenant-context/visualisation-site/layers/GeofencePOILocationLayer/GeofencePOILocationLayer.styles";

type Props = {
  onMarkerDragStart?: (dragEvent: MarkerDragEvent) => void
  onMarkerDrag?: (dragEvent: MarkerDragEvent) => void
  onMarkerDragEnd?: (dragEvent: MarkerDragEvent) => void
  isDraggedLocation?: boolean
  currentLocationName?: string
  currentLocationAddress?: string
  currentLocation?: FeatureCollection
  dragMarkerCoordinates?: { lat: number, lng: number}
}

const DraggableMarkerComponent: FC<Props> = ({
  onMarkerDragStart,
  onMarkerDrag,
  onMarkerDragEnd,
  isDraggedLocation,
  currentLocationName,
  currentLocationAddress,
  currentLocation,
  dragMarkerCoordinates
}) => {
  const offsetX = useMemo(() => currentLocationAddress ? 30 : 5, [currentLocationAddress]);
  const { classes } = useGeofencePOILocationLayerStyles();

  return (
    <Source
      id="r__sitesGeofencePOISource_plain"
      type="geojson"
      tolerance={ 0 }
      data={ currentLocation }
    >
      { dragMarkerCoordinates &&
      <>
        <Marker
          longitude={ dragMarkerCoordinates.lng }
          latitude={ dragMarkerCoordinates.lat }
          draggable
          onDragStart={ onMarkerDragStart }
          onDrag={ onMarkerDrag }
          onDragEnd={ onMarkerDragEnd }
        />
        <Popup
          className={ classes.popup }
          longitude={ dragMarkerCoordinates.lng }
          latitude={ dragMarkerCoordinates.lat }
          anchor="bottom-left"
          closeButton={ false }
          closeOnClick={ false }
          offset={ [15, offsetX] }
        >
          <div className={ classes.popupContentWrapper }>
            <div className={ classes.siteName }>
              { isDraggedLocation && <>
                <p>Long: { dragMarkerCoordinates?.lng }</p>
                <p>Lat: { dragMarkerCoordinates?.lat }</p>
              </> }

              { !isDraggedLocation && currentLocationName }
            </div>
            <div className={ classes.location }>{ isDraggedLocation ? '' : currentLocationAddress }</div>
          </div>
        </Popup>
      </> }
    </Source>
  );
};

export default DraggableMarkerComponent;
