import { createStyles } from '@mantine/core';
import { MantineTheme } from '@mantine/styles/lib/theme';

export const useLocalErrorBoundaryStyles = createStyles((theme: MantineTheme) => ({
  root: {
    backgroundColor: theme.colors.neutral[8],
    padding: 16,
    display: 'grid',
    flexDirection: 'column',
    gap: 8
  }
}));
