import { createStyles,MantineTheme } from "@mantine/core";

export const useOverviewWidgetStyles = createStyles((theme: MantineTheme) => ({
  topLeftContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    margin: 24,
    zIndex: 1,
    [theme.fn.largerThan(1512)]: {
      gap: 16
    }
  }
}));
