import { ToggleableLayerType } from "@/common/components/ToggleableLayer/ToggleableLayer.config";
import { CommonLocationLayerConfig } from "@/tenant-context/visualisation-common-locations/configs/common-location-layer.config";

export const constructCommonLocationQuery = (disabledLayerTypes: ToggleableLayerType[]): string => {

  const query = CommonLocationLayerConfig
    .filter((layer) => !disabledLayerTypes.includes(layer.toggleableLayerType))
    .map((layer) => `subCategoryName='${layer.fieldName}' or `)
    .join('');

  const letterCountToRemoveLastOr = -4;
  return query.slice(0, letterCountToRemoveLastOr);
};
