/* eslint-disable max-lines */
import { Button, Divider, Flex, Tabs } from "@mantine/core";
import { FC } from "react";

import MiniProfileAppData
  from "@/tenant-context/control-profile/components/sections/MiniProfileAppData/MiniProfileAppData.component";
import MiniProfileContacts
  from "@/tenant-context/control-profile/components/sections/MiniProfileContacts/MiniProfileContacts.component";
import MiniProfileHeader
  from "@/tenant-context/control-profile/components/sections/MiniProfileHeader/MiniProfileHeader.component";
import MiniProfileVisit from "@/tenant-context/control-profile/components/sections/MiniProfileVisit";
import { MiniProfileDatails } from "@/tenant-context/control-profile/types/mini-profile";
import { UserVisitsInfo } from "@/tenant-context/control-visitor-management/types/visitorManagement";
import MiniProfileEmergencyBlock
  from "@/tenant-context/employee-app-action-response-center/components/ui/MiniProfileEmergencyBlock";

import MiniProfileLocationInformation
  from "../../sections/MiniProfileLocationInformation/MiniProfileLocationInformation.container";
import { MiniProfileTabs } from "./MiniProfile.container";
import { useMiniProfileStyles } from "./MiniProfile.styles";


type Props = {
  profileData?: MiniProfileDatails
  onCloseClick: () => void,
  isBigProfileOpen: boolean,
  isInFullscreenOverlay: boolean,
  onTabChange: (value: MiniProfileTabs) => void,
  activeTab: MiniProfileTabs,
  userVisitsInfo: UserVisitsInfo | undefined,
  isStandAlone: boolean,
  openBigProfile: () => void
};

const MiniProfile: FC<Props> = ({
  profileData,
  onCloseClick,
  isInFullscreenOverlay,
  onTabChange,
  activeTab,
  userVisitsInfo,
  isStandAlone,
  openBigProfile
}) => {
  const { classes } = useMiniProfileStyles(isInFullscreenOverlay);
  const showVisits = (userVisitsInfo?.future.requestId
    || userVisitsInfo?.inProgress.requestId
     || userVisitsInfo?.past.requestId);

  return (
    <Flex justify={ "space-between" } direction={ 'column' } className={ classes.root }>
      <div>
        <Flex direction={ 'column' } gap={ 32 }>
          { /* Profile Card */ }
          <MiniProfileHeader
            onCloseClick={ onCloseClick }
            isInFullscreenOverlay={ isInFullscreenOverlay }
            profileData={ profileData }
          />

          <MiniProfileEmergencyBlock/>

          <Flex direction={ 'column' }>
            <Divider className={ classes.divider } />

            <div className={ classes.userData }>
              <div className={ classes.userDataColumn }>
                <div className={ classes.userDataHeader }> PERSON TYPE</div>
                <div className={ classes.userDataValue }> { profileData?.personType || "N/A" }</div>
              </div>
              { profileData?.nationality && (
                <div>
                  <div className={ classes.userDataHeader }> NATIONALITY</div>
                  <div className={ classes.userDataValue }> { profileData?.nationality }</div>
                </div>
              ) }
              { profileData?.countryOfResidenceRef && (
                <div>
                  <div className={ classes.userDataHeader }> RESIDENCE</div>
                  <div className={ classes.userDataValue }> { profileData?.countryOfResidenceRef } </div>
                </div>
              ) }
            </div>

            <Divider className={ classes.divider }/>
          </Flex>
        </Flex>

        <Flex direction={ 'column' } gap={ 'xl' } mt={ 48 }>
          <Tabs
            onTabChange={ onTabChange }
            value={ activeTab }
            className={ classes.miniProfileTabs }
          >
            <Tabs.List>
              <Tabs.Tab value={ MiniProfileTabs.Profile }>
                Profile
              </Tabs.Tab>
              <Tabs.Tab value={ MiniProfileTabs.Visits }>
                Visits
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value={ MiniProfileTabs.Profile }>
              <Flex direction={ 'column' } gap={ 'xl' } mt={ 'xl' }>
                <MiniProfileContacts
                  isInFullscreenOverlay={ isInFullscreenOverlay }
                  profileData={ profileData }
                />
                <MiniProfileAppData
                  isInFullscreenOverlay={ isInFullscreenOverlay }
                />
                <MiniProfileLocationInformation profileData={ profileData }
                />
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value={ MiniProfileTabs.Visits }>
              { showVisits  ? (
                <>
                  <MiniProfileVisit
                    title="In Progress"
                    visitData={ userVisitsInfo?.inProgress }
                  />
                  <MiniProfileVisit
                    title="Future"
                    visitData={ userVisitsInfo?.future }
                  />
                  <MiniProfileVisit
                    title="Past"
                    visitData={ userVisitsInfo?.past }
                  />
                </>
              ) : <div className={ classes.emptyText }>No Visits found</div> }
       
            </Tabs.Panel>
          </Tabs>
        </Flex>
      
      </div>
      { isStandAlone && (
        <Button
          fullWidth
          onClick={ openBigProfile }
        >
          View Full Profile
        </Button>
      ) }
    </Flex>
  );
};

export default MiniProfile;
