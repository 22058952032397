import { openModal } from '@mantine/modals';
import groupBy from 'lodash/groupBy';
import { FC, useCallback } from "react";

import CountryFlag from '@/tenant-context/common/components/CountryFlag/CountryFlag.component';
import PersonalContactModal from '@/tenant-context/control-profile/components/modals/PersonalContactModal';
import { useProfilePersonalContactTabStyles } from '@/tenant-context/control-profile/components/tabs/ProfilePersonalContactTab/ProfilePersonalContactTab.style';
import ButtonWithOptions from '@/tenant-context/control-profile/components/ui/ButtonWithOptions';
import EditButton from '@/tenant-context/control-profile/components/ui/EditButton';
import GenericProfileTab from "@/tenant-context/control-profile/components/ui/GenericProfileTab";
import ProfileBlock from "@/tenant-context/control-profile/components/ui/ProfileBlock";
import ProfileKeyValue from "@/tenant-context/control-profile/components/ui/ProfileKeyValue";
import ProfileKeyValueBlock from "@/tenant-context/control-profile/components/ui/ProfileKeyValueBlock";
import { ProfileRequiredPoliciesConfig } from '@/tenant-context/control-profile/config/ProfileRequiredPolicies.config';
import { PaginatedResult, ProfileContact } from "@/tenant-context/control-profile/types/profile";

type Props = {
  personalContacts?: PaginatedResult<ProfileContact> | null,
  isOwnProfile: () => boolean
}

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
const getSafeRegionName = (regionCode?: string) => {
  if (regionCode) {
    try {
      return {
        isValidRegionCode: true,
        regionName: regionNames.of(regionCode)
      };
    } catch {
      // no-op
    }
  }

  return {
    isValidRegionCode: false,
    regionName: regionCode
  };
};

const ProfilePersonalContactTab: FC<Props> = ({
  personalContacts,
  isOwnProfile
}) => {
  const { classes } = useProfilePersonalContactTabStyles();

  const renderPersonalContactModal = useCallback((sectionType: string, type: string) => (
    <PersonalContactModal
      sectionType={ sectionType }
      type={ type }
    />
  ), []);

  const openEditModal = useCallback((contactId: string) => {
    if (!personalContacts) {
      return;
    }

    const contact = personalContacts.items.find(({ _id }) => contactId === _id);

    if (!contact) {
      return;
    }

    openModal({
      title: `Edit Contact`,
      children: (
        <PersonalContactModal
          mode='edit'
          editableContact={ contact }
          sectionType={ contact.contactDetailType }
          type={ contact.contactType }
        />
      ),
      closeOnClickOutside: false
    });
  }, [personalContacts]);

  const renderEdit = useCallback((addressId: string) => (
    <div className={ classes.textRight }>
      <EditButton
      // eslint-disable-next-line react/jsx-no-bind
        onClick={ () => openEditModal(addressId) }
        requiredPolicies={ isOwnProfile() ? [] : ProfileRequiredPoliciesConfig.EDIT_CONTACT_DETAILS }
      />
    </div>
  ), [classes.textRight, isOwnProfile, openEditModal]);

  if (!personalContacts) {
    return <span>Loading...</span>;
  }

  const sections = groupBy(personalContacts.items, 'contactDetailType');

  const landlinePhoneSection: ProfileContact[] = sections['PHONE'] || [];
  const mobileSection: ProfileContact[] = sections['MOBILE'] || [];
  const emailSection: ProfileContact[] = sections['EMAIL'] || [];

  const renderCountryNameWithFlag = (regionCode: string) => {
    const {
      isValidRegionCode,
      regionName
    } = getSafeRegionName(regionCode);

    return (
      <span>
        { isValidRegionCode && (
          <CountryFlag
            className={ classes.flag }
            countryCode={ regionCode }
          />
        ) }
        { regionName }
      </span>
    );
  };

  return (
    <GenericProfileTab
      heading="Personal Contact"
    >
      <section className={ classes.section }>
        { /* TODO size */ }
        <div className={ classes.sectionHeadingContainer }>
          <h6 className={ classes.sectionHeading }>Landline Phone</h6>
          <ButtonWithOptions
            renderModal={ renderPersonalContactModal }
            sectionType={ 'PHONE' }
            requiredPolicies={ isOwnProfile() ? [] : ProfileRequiredPoliciesConfig.EDIT_CONTACT_DETAILS }
          />
        </div>

        <ul>
          { landlinePhoneSection.map(({
            parsedPhoneNumber: {
              regionCode,
              number: {
                international = undefined
              } = {}
            } = {},
            ...contact
          }) => (
            <ProfileBlock key={ contact._id } element='li'>
              <ProfileKeyValueBlock
                maxColumns={ 5 }
                // eslint-disable-next-line react/jsx-no-bind
                gtc={ (mc) => `repeat(${mc - 1}, 1fr) 0.5fr` }
              >
                <ProfileKeyValue
                  name={ `${contact.contactType.toUpperCase()} ${contact.contactDetailType}` }
                  value={ [
                    renderCountryNameWithFlag(regionCode || contact.location),
                    international || contact.value,
                    contact.primary
                      ? <span className={ classes.primaryContactLabel }>Primary Contact</span>
                      : "",
                    renderEdit(contact._id)
                  ] }
                />
              </ProfileKeyValueBlock>
            </ProfileBlock>
          )) }
        </ul>
      </section>

      <section className={ classes.section }>
        { /* TODO size */ }
        <div className={ classes.sectionHeadingContainer }>
          <h6 className={ classes.sectionHeading }>Mobile</h6>
          <ButtonWithOptions
            renderModal={ renderPersonalContactModal }
            sectionType={ 'MOBILE' }
            requiredPolicies={ isOwnProfile() ? [] : ProfileRequiredPoliciesConfig.EDIT_CONTACT_DETAILS }
          />
        </div>

        <ul>
          { mobileSection.map(({
            parsedPhoneNumber: {
              regionCode,
              number: {
                international = undefined
              } = {}
            } = {},
            ...contact
          }) => (
            <ProfileBlock key={ contact._id } element='li'>
              <ProfileKeyValueBlock
                maxColumns={ 5 }
                // eslint-disable-next-line react/jsx-no-bind
                gtc={ (mc) => `repeat(${mc - 1}, 1fr) 0.5fr` }
              >
                <ProfileKeyValue
                  name={ `${contact.contactType.toUpperCase()} ${contact.contactDetailType}` }
                  value={ [
                    renderCountryNameWithFlag(regionCode || contact.location),
                    international || contact.value,
                    contact.primary
                      ? <span className={ classes.primaryContactLabel }>Primary Contact</span>
                      : "",
                    renderEdit(contact._id)
                  ] }
                />
              </ProfileKeyValueBlock>
            </ProfileBlock>
          )) }
        </ul>
      </section>

      <section className={ classes.section }>
        <div className={ classes.sectionHeadingContainer }>
          <h6 className={ classes.sectionHeading }>Email</h6>
          <ButtonWithOptions
            renderModal={ renderPersonalContactModal }
            sectionType={ 'EMAIL' }
            requiredPolicies={ isOwnProfile()  ? [] : ProfileRequiredPoliciesConfig.EDIT_CONTACT_DETAILS }
          />
        </div>

        <ul>
          { emailSection.map((contact) => (
            <ProfileBlock key={ contact._id } element='li'>
              <ProfileKeyValueBlock
                maxColumns={ 5 }
                // eslint-disable-next-line react/jsx-no-bind
                gtc={ (mc) => `repeat(${mc - 1}, 1fr) 0.5fr` }
              >
                <ProfileKeyValue
                  name={ `${contact.contactType.toUpperCase()} ${contact.contactDetailType}` }
                  value={ [
                    contact.value,
                    null,
                    contact.primary
                      ? <span className={ classes.primaryContactLabel }>Primary Email</span>
                      : "",
                    renderEdit(contact._id)
                  ] }
                />
              </ProfileKeyValueBlock>
            </ProfileBlock>
          )) }
        </ul>
      </section>
    </GenericProfileTab>
  );
};

export default ProfilePersonalContactTab;
