import { FC, useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { RiskProviders } from "@/tenant-context/common/types/risk";
import { ArcContext } from '@/tenant-context/control-action-response-center/context/ARC/ARC.context';
import RiskEventsControl from "@/tenant-context/control-risk-config/controls/RiskEventsControl/RiskEventsControl.component";
import type {  RiskProviderItem } from "@/tenant-context/control-risk-config/types/risk";
import { riskProvidersMeta } from "@/tenant-context/control-risk-config/util/riskProvidersMeta";

const RiskEventsControlContainer: FC = () => {
  const [selectedRiskProvider, setSelectedRiskProvider] = useState<string>();
  const riskAlertsGeoData = useSelector((state: RootState) => state.riskAlerts.geoData);
  const currentRiskAlertsGeoData = useSelector((state: RootState) => state.riskAlerts.currentRiskAlert);
  const { riskAlert } = useContext(ArcContext);
  const subscribedProviderList = useSelector((state:RootState) => state.commonData.riskProviders);
  const isImapctCircleEnabled = useSelector((state:RootState) => state.riskDetails.isRiskImpactCircleEnabled);
  const isArcOpen = useSelector((state:RootState) => state.arc.isArcOpen);
  const isMassCommsOpen = useSelector((state: RootState) => state.massComms.massCommsNavigation.isOpen);
  const massCommsNav = useSelector((state: RootState) => state.massComms.massCommsNavigation);

  const {
    arc: {
      SET_IS_ARC_OPEN,
      SET_CURRENT_CASE_ID
    },
    riskDetails: {
      resetCurrentImpact
    }
  } = useDispatch<Dispatch>();

  const caseId: string | undefined = useMemo(() => {
    if (!currentRiskAlertsGeoData) {
      return undefined;
    }

    const currentCaseId = `${ currentRiskAlertsGeoData.source }_${ currentRiskAlertsGeoData.json.alert.id }`;
    SET_CURRENT_CASE_ID(currentCaseId);

    return currentCaseId;
  }, [ currentRiskAlertsGeoData, SET_CURRENT_CASE_ID ]);

  const getActiveRiskAlertCount = useCallback((providerName:string) => {
    switch(providerName?.toLowerCase()){
    case RiskProviders.riskline:
      return riskAlertsGeoData.features?.reduce((acc, curr) => {
        if(curr.properties?.source === RiskProviders.riskline){
          // eslint-disable-next-line no-param-reassign
          acc = acc + 1;
        }

        return acc;
      }, 0);
    case RiskProviders.bsoc:
      return riskAlertsGeoData.features?.reduce((acc, curr) => {
        if(curr.properties?.source === RiskProviders.bsoc){
          // eslint-disable-next-line no-param-reassign
          acc = acc + 1;
        }

        return acc;
      }, 0);
    case RiskProviders.dataminr:
      return riskAlertsGeoData.features?.reduce((acc, curr) => {
        if (curr.properties?.source === RiskProviders.dataminr){
          // eslint-disable-next-line no-param-reassign
          acc = acc + 1;
        }

        return acc;
      }, 0);
    case RiskProviders.maxSecurity:
      return riskAlertsGeoData.features?.reduce((acc, curr) => {
        if(curr.properties?.source === RiskProviders.maxSecurity){
          return acc + 1;
        }

        return acc;
      }, 0);
    default:
      return 0;
    }

  }, [riskAlertsGeoData.features]);

  const formattedRiskProviders: RiskProviderItem[] = useMemo(
    () =>
      subscribedProviderList && subscribedProviderList.map(
        (provider) => {
          const providerIcon = riskProvidersMeta.get(provider.providerName)?.Icon;

          const activeRiskAlertCount = getActiveRiskAlertCount(provider.providerName);

          return {
            ...provider,
            activeNumber: activeRiskAlertCount,
            Icon:providerIcon
          };
        }
      ),
    [getActiveRiskAlertCount, subscribedProviderList]
  );

  const handleSelectRiskProvider = useCallback((riskProviderId?: string) => {
    setSelectedRiskProvider(riskProviderId);
  }, [ setSelectedRiskProvider ]);

  const handleDrawerClose = useCallback(() => {
    resetCurrentImpact();

    if (!isArcOpen) {
      return;
    }

    SET_IS_ARC_OPEN(false);
  }, [ isArcOpen, SET_IS_ARC_OPEN, resetCurrentImpact ]);

  const handleRequestToToggleArc = useCallback(() => {
    if (!caseId) {
      return;
    }

    SET_IS_ARC_OPEN(!isArcOpen);

    // onRequestToOpenArc?.(caseId); //TODO: remove this line
  }, [caseId, isArcOpen, SET_IS_ARC_OPEN]);

  return (
    <RiskEventsControl
      onRiskProviderSelect={ handleSelectRiskProvider }
      selectedRiskProvider={ selectedRiskProvider }
      riskProviders={ formattedRiskProviders || [] }
      isImpactCircleEnabled={ isImapctCircleEnabled }
      isArcOpen={ isArcOpen }
      onDrawerClose={ handleDrawerClose }
      isMassCommsOpen={ isMassCommsOpen }
      isAnotherOverlayModuleOpen={ massCommsNav.isOpen }
      riskAlert={ riskAlert || currentRiskAlertsGeoData }
      onRequestToToggleArc={ handleRequestToToggleArc }
    />
  );
};

export default RiskEventsControlContainer;
